import React, { Component } from 'react'
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  // VictoryLabel,
  VictoryLine
} from 'victory'
import moment from 'moment'
import HexagonText from '../UI/HexagonText'
import { detailColors } from '../../utils/constant'
import ContainerWidthSizer from '../UI/ContainerWidthSizer'

const grid = (min) => ({
  stroke: (tick) => {
    return tick - min === 0 ? 'transparent' : 'rgba(128, 151, 177, 0.375)'
  },
  strokeDasharray: '8, 6',
  strokeWidth: 0.825
})
const axis = {
  stroke: 'transparent'
  // strokeWidth: 1.65
}

const hexagon_size = {
  width: 52,
  height: 60
}

// type Range = {
//   id: number
//   bio_marker_id: number
//   min: number
//   max: number
//   color: string // has moved to (fe)male_age_scope
//   name: string
//   male_age_scope: { [k: string]: [] }
//   female_age_scope: { [k: string]: [] }
//   order?: number
// }

// type SimplifiedRange = {
//   min: number
//   max: number
//   color: string
//   order: number
// }

// type Profile = {
//   gender: 'male' | 'female'
//   city: string
//   state: string
//   date_of_birth: Date
//   height_inch: number
// }
// type Person = {
//   profile: Profile
// }
// type History = {
//   test_date: Date
//   value: number
// }

// type Props = {
//   person: Person
//   biomarker: {
//     unit: string
//     options: Object[]
//     conventional_ranges: Range[]
//     functional_ranges: Range[]
//     ranges: Range[]
//     personal_data: {
//       created_at: Date
//       value: number
//       prefer_functional: boolean
//       prefer_conventional: boolean
//       optimal_range: number[]
//     }
//   }
//   histories: History[]
//   showConv: boolean
//   showFunc: boolean
// }

class TrendGraph extends Component {
  // (v: number, ranges: SimplifiedRange[])
  calculateColor(v, ranges) {
    const range = ranges.filter((range) => {
      return (!range.min || range.min <= v) && (!range.max || range.max >= v)
    })
    return range.length ? range[0].color : '#D2D2D2'
  }

  calculateDomain(ranges) {
    //SimplifiedRange[]
    let min = Number.MAX_VALUE
    let min_ext = false
    let max = Number.MIN_VALUE
    let max_ext = false
    ranges.forEach((range) => {
      if (range.min < min) {
        min = range.min
        min_ext = false
      } else if (range.max > max) {
        max = range.max
        max_ext = false
      }
      if (range.max < min) {
        min = range.max
        min_ext = true
      } else if (range.min > max) {
        max = range.min
        max_ext = true
      }
    })

    const span = max - min
    const each = span / ranges.length
    return [min - (min_ext ? each : 0), max + (max_ext ? each : 0)]
  }

  render() {
    const { biomarker, histories, showConv, showFunc } = this.props
    let isMetric = false
    if (!biomarker) return <div />
    let {
      personal_data: { optimal_range },
      conventional_ranges: _conventional_ranges,
      functional_ranges: _functional_ranges
    } = biomarker
    if ((optimal_range && biomarker.options) || !_conventional_ranges) {
      _conventional_ranges = biomarker.ranges
      isMetric = true
    }
    const showConventionalAxis = showConv || isMetric
    const showFunctionalAxis = !showConventionalAxis && showFunc
    const _ranges = showConventionalAxis
      ? _conventional_ranges
      : showFunctionalAxis
      ? _functional_ranges
      : []
    const ranges = _ranges.map((range) => {
      const colorObj = detailColors.find((item) => item.key === range.color)
      return {
        min: range.min,
        max: range.max,
        color: colorObj && colorObj.value,
        order: range.order
      }
    })
    // if (ranges.every(r => typeof(r.order) === 'number')) {
    // ranges.sort((a, b) => a.order - b.order)
    // } else {
    ranges.sort(
      (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
    )
    // }

    const dataSet = []
    const test_dates = [null]
    const test_date_ticks = [0, 1, 2, 3, 4, 5, 6, 7, 8]

    const range_ticks = ranges.map((r, idx) => idx)
    range_ticks.push(range_ticks.length)
    range_ticks.push(range_ticks.length)

    const height = 460
    const padding = {
      left: 100,
      right: 45,
      top: 50,
      bottom: 35
    }

    const ext_padding = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
    {
      let min_y = ranges.length + 1
      if (histories && histories.length > 0) {
        if (histories.length >= 8) {
          ext_padding.right = hexagon_size.width / 2
        }
        histories.map((item, index) => {
          const amount = Number(item.value)
          let idx = 1
          for (const range of ranges) {
            if (
              (!range.min || range.min <= amount) &&
              (!range.max || range.max >= amount)
            ) {
              break
            }
            idx++
          }
          const nextRange = ranges[idx]
          const currRange = ranges[idx - 1]
          let y = idx
          if (nextRange && nextRange.max && nextRange.min) {
            y -= (currRange.max - amount) / (nextRange.max - nextRange.min)
          } else if (currRange && currRange.max && currRange.min) {
            y += (amount - currRange.min) / (currRange.max - currRange.min)
          }
          dataSet.push({
            x: test_dates.length,
            amount,
            y
          })
          if (y < min_y) {
            min_y = y
          }
          test_dates.push(item.test_date)
          return item
        })
      }
      if (min_y < ((ranges.length / height) * hexagon_size.height) / 2) {
        ext_padding.bottom = hexagon_size.height / 2
      }
    }

    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart
            domainPadding={{
              x: [ext_padding.left, ext_padding.right],
              y: [ext_padding.bottom, ext_padding.top]
            }}
            singleQuadrantDomainPadding={false}
            height={height + padding.bottom}
            width={width}
            padding={padding}
            // containerComponent={<VictoryZoomContainer zoomDomain={{y: merged_domain}}/>}
          >
            <VictoryAxis
              offsetY={padding.bottom}
              tickValues={test_date_ticks}
              style={{
                grid: grid(test_date_ticks[0]),
                axis,
                ticks: {
                  strokeWidth: 0
                },
                tickLabels: {
                  fontFamily: 'Lato',
                  fontSize: '18px',
                  fill: '#B0BAC9'
                }
              }}
              tickFormat={(x) => {
                return (
                  test_dates[x] &&
                  moment(test_dates[x])
                    .utcOffset(0)
                    .format('M/D/YY')
                )
              }}
            />
            <VictoryAxis
              dependentAxis
              offsetX={padding.left}
              orientation="left"
              tickValues={range_ticks}
              tickFormat={(idx) => {
                const range = ranges[idx - 1]
                if (!range) return ''
                if (range.max && range.min) {
                  return `${range.min}-${range.max}`
                } else if (range.max) {
                  return `< ${range.max}`
                } else {
                  return `> ${range.min}`
                }
              }}
              style={{
                grid: grid(range_ticks[0]),
                axis,
                ticks: { strokeWidth: 0 },
                tickLabels: {
                  fill: (idx) => ranges[idx - 1] && ranges[idx - 1].color,
                  fontFamily: 'Lato',
                  fontWeight: 'bold',
                  fontSize: 18,
                  padding: 5
                }
              }}
            />
            {dataSet.length > 1 && (
              <VictoryLine
                bubbleProperty="amount"
                style={{
                  data: { stroke: '#264382', opacity: 0.3, strokeWidth: 3.5 }
                }}
                data={dataSet}
              />
            )}
            <VictoryScatter
              data={dataSet.map((d) => {
                // format it to 0-1-2-3....
                const idx = ranges.findIndex((r) => d.amount <= r.max)
                const r = ranges[idx]
                if (r) {
                  if (r.max) {
                    d.y = (d.amount - r.min) / (r.max - r.min) + idx
                  } else {
                    d.y = d.amount / r.min
                  }
                }
                return d
              })}
              dataComponent={
                <HexagonText
                  color={(value) => this.calculateColor(value, ranges)}
                  size={60}
                  borderSize={5}
                />
              }
            />
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default TrendGraph
