import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Tabs, Empty } from 'antd'
import Notes from '../../notes'
import { reactActivityStyle } from './styles/reactActivity.sass'

const { TabPane } = Tabs

class RecentActivity extends Component {
  static propTypes = {
    changeLog: PropTypes.array,
    person: PropTypes.object
  }

  state = {
    showAll: false
  }

  processEditDate = (date) => {
    const today = moment()
    const editDate = moment(date)
    if (today && editDate) {
      if (editDate.isSame(today.format('YYYY-MM-DD'), 'day')) {
        return `Today ${editDate.format('h:mmA')}`
      } else {
        return editDate.fromNow()
      }
    }
  }

  getEmailList(log) {
    const { person } = this.props
    let email = [person.email]
    if (log.notes) {
      email = email.concat(log.notes.split(';'))
    }
    return (
      <>
        {email.map((item, index) => (
          <span key={index}>
            <br />
            <span className="bold" style={{ textTransform: 'none' }}>
              {item}
              {index < email.length - 1 && ','}
            </span>
          </span>
        ))}
      </>
    )
  }

  getLogItem(item) {
    let action = ` has ${item.action} `
    let target = (
      <span className="bold">
        {item.action === 'created' && 'New '}{' '}
        {item.notes ? item.notes : 'Email'}
      </span>
    )
    if (item.action === 'sent' && item.notes) {
      action = ' has sent email to '
      const reveivers = item.notes.split(';')

      target = (
        <span className="bold" style={{ textTransform: 'none' }}>
          {reveivers.map((item, index) => (
            <div key={index}>
              {item}
              {index < reveivers.length - 1 && ','}
            </div>
          ))}
        </span>
      )
    }

    return (
      <div className="">
        <span className="bold">
          {' '}
          {(item.profile && item.profile.nickname) || item.first_name}
        </span>
        {action}
        {target}
        {item.position && this.props.handleUndo && (
          <span
            className="undo"
            onClick={() =>
              this.props.handleUndo(item.position, item.notes, item.content)
            }
          >
            Undo
          </span>
        )}
        <div className="date">{this.processEditDate(item.date)}</div>
      </div>
    )
  }

  renderActivities() {
    const { changeLog } = this.props
    const { showAll } = this.state
    if (!changeLog || !changeLog.length) {
      return <Empty />
    }
    const showLogs = showAll ? changeLog : changeLog.slice(0, 10)
    return (
      <>
        <ul className="activity-list">
          {showLogs &&
            showLogs.map((item, index) => (
              <li className="item" key={index}>
                {item.avatar ? (
                  <img className="avatar" src={item.avatar} alt="Avatar" />
                ) : (
                  <div
                    className="avatar-placeholder"
                    style={{ backgroundColor: 'rgb(233, 237, 243)' }}
                  />
                )}
                {this.getLogItem(item)}
              </li>
            ))}
        </ul>
        {!showAll && changeLog.length > 10 && (
          <span
            className="show-all"
            onClick={() => this.setState({ showAll: true })}
          >
            Show all activities
          </span>
        )}
      </>
    )
  }

  renderStatisticsLog() {
    const { statisticsLog } = this.props
    const { showAllStatistics } = this.state
    if (!statisticsLog || !statisticsLog.length) {
      return <Empty />
    }
    const showLogs = showAllStatistics
      ? statisticsLog
      : statisticsLog.slice(0, 10)
    return (
      <>
        <ul className="statistics-list">
          {showLogs.map(({ date, status }, index) => (
            <li key={index}>
              <div>{moment(date).format('MMM DD, YYYY')}</div>
              <div className={`status-circle ${status}`}>
                {status === 'Opened' ? status : 'Unopened'}
              </div>
            </li>
          ))}
        </ul>
        {!showAllStatistics && statisticsLog.length > 10 && (
          <span
            className="show-all"
            onClick={() => this.setState({ showAllStatistics: true })}
          >
            Show all history
          </span>
        )}
      </>
    )
  }

  render() {
    const { person, startDate, endDate } = this.props
    return (
      <div className={reactActivityStyle}>
        <div className="recent-activity">
          <Tabs
            defaultActiveKey="notes"
            tabPosition="top"
            onChange={(activeKey) => this.setState({ visibleTab: activeKey })}
          >
            <TabPane tab="NOTES" key="notes">
              {startDate && endDate && (
                <Notes person={person} showPart={true} noFilter onSummary />
              )}
            </TabPane>
            <TabPane tab="ACTIVITY" key="activity">
              {this.renderActivities()}
            </TabPane>
            <TabPane tab="STATISTICS" key="statistics">
              {this.renderStatisticsLog()}
            </TabPane>
          </Tabs>
        </div>
      </div>
    )
  }
}

export default RecentActivity
