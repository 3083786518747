import React from 'react'
import _ from 'lodash'
import {
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryGroup,
  VictoryScatter,
  VictoryArea,
  VictoryTooltip
} from 'victory'
import ContainerWidthSizer from '../UI/ContainerWidthSizer'
import { tickFormatY } from './chartHandle'
import CustomTooltip from './customTooltip'
// import { Scatter as RangeScatter } from './rangeAxisChart'
import { renderTravel } from './travel'

function LineChart(props) {
  const {
    charts,
    showIndex,
    showNumber,
    active,
    dateRange,
    strokeColor,
    fontFamily,
    strokeActiveColor,
    fontSize,
    isCompared,
    tooltipMetrics,
    chartPadding,
    travelData,
    lineArr
  } = props
  const [metric, compared] = charts
  const minDomain = { y: !metric.ranges ? metric.axisY[0] : compared.axisY[0] }
  const rangeMetric = charts.find((item) => item.ranges)

  let tooltips = metric

  if (!rangeMetric) {
    //  calculate tooltip dots
    if (compared) {
      tooltips = calculateTooltips(metric, compared)
    }
  } else {
    tooltips = [metric, compared].find((item) => !item.ranges)
    tooltips = _.cloneDeep(tooltips)
    tooltips.data = _.concat(
      [],
      tooltips.data.filter((item) => item.y),
      rangeMetric.dataSet.filter((item) => item.y)
    )
  }

  function Group(metric) {
    const { metric_key, data, theme } = metric
    const { dashLines, areaData } = handleLineData(data)
    const hasData = data.filter((item) => item.y)
    const gradientId = `lineGradient_${showIndex}_${metric_key
      .split(' ')
      .join('_')}`

    const labels = (datum) =>
      showNumber ? tickFormatY(datum.value || datum.y, metric, 'data') : null
    return (
      <VictoryGroup>
        <VictoryArea
          style={{
            data: {
              fill: `url(#${gradientId}})`
            },
            labels: {
              fill: 'transparent',
              fontSize
            }
          }}
          data={areaData}
        />
        {dashLines.map((item, index) => (
          <VictoryLine
            key={`${metric_key}_${showIndex}_${index}`}
            data={item}
            labels={labels}
            style={{
              data: {
                stroke: theme.primary,
                strokeDasharray: '6, 3'
              },
              labels: {
                fill: 'transparent',
                fontSize
              }
            }}
          />
        ))}
        <VictoryLine
          name="data"
          data={data}
          labels={labels}
          labelComponent={
            <VictoryLabel
              renderInPortal={true}
              dy={(datum) => {
                const index = hasData.findIndex(({ x }) => x === datum.x)
                if (index >= 0) {
                  return index % 2 === 0 ? 0 : 30
                }
                return 0
              }}
            />
          }
          style={{
            data: {
              stroke: theme.primary
            },
            labels: {
              fill: theme.primary,
              fontSize
            }
          }}
        />
        <VictoryScatter
          name="data"
          data={data}
          dataComponent={<Scatter color={theme.primary} />}
          labels={labels}
          style={{
            labels: {
              fill: 'transparent',
              fontSize
            }
          }}
        />
      </VictoryGroup>
    )
  }

  function renderChartContainer({
    containerStyle,
    isCompared,
    targetMetric,
    isTooltipContainer,
    travelData
  }) {
    const hideStroke = isTooltipContainer || (!rangeMetric && isCompared)
    return (
      <ContainerWidthSizer style={containerStyle || {}}>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={200}
            minDomain={rangeMetric ? minDomain : { y: targetMetric.axisY[0] }}
            padding={chartPadding}
          >
            <VictoryAxis
              name="axis_x"
              tickValues={targetMetric.axisX}
              style={{
                grid: {
                  stroke: hideStroke
                    ? 'transparent'
                    : (tick) =>
                        active
                          ? active.x === tick
                            ? strokeActiveColor
                            : strokeColor
                          : strokeColor,
                  strokeDasharray: '6, 3'
                },
                axis: { stroke: hideStroke ? 'transparent' : strokeColor },
                tickLabels: {
                  fill: 'transparent'
                }
              }}
            />

            <VictoryAxis
              dependentAxis
              orientation={isCompared ? 'right' : 'left'}
              tickValues={targetMetric.axisY}
              tickFormat={(t) => tickFormatY(t, targetMetric)}
              style={{
                axis: {
                  stroke: isTooltipContainer ? 'transparent' : strokeColor
                },
                grid: {
                  stroke: hideStroke ? 'transparent' : strokeColor,
                  strokeDasharray: '6, 3'
                },
                tickLabels: {
                  fontFamily,
                  fontSize,
                  fill: isTooltipContainer
                    ? 'transparent'
                    : targetMetric.theme.primary
                }
              }}
            />

            {!isTooltipContainer && Group(targetMetric)}
            {/* one more scatter to show tooltip */}
            <VictoryGroup>
              {rangeMetric && (
                <VictoryScatter
                  data={rangeMetric.dataSet}
                  // dataComponent={<RangeScatter active={active} />}
                  dataComponent={<Scatter active={active} />}
                  style={{
                    labels: {
                      fill: isTooltipContainer
                        ? 'transparent'
                        : (datum) => datum.color,
                      fontSize,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    }
                  }}
                  labels={(datum) => (showNumber ? datum.value : null)}
                />
              )}
              {rangeMetric &&
                lineArr.map((item, index) => (
                  <VictoryLine
                    key={`${rangeMetric.metric_key}_${item.keyIndex}_${index}`}
                    data={item.data}
                    style={{
                      data: item.dataStyle
                    }}
                  />
                ))}

              {isTooltipContainer && (
                <VictoryScatter
                  data={targetMetric.data}
                  size={10}
                  labels={(datum) => (showNumber ? datum.y : null)}
                  labelComponent={
                    <VictoryTooltip
                      flyoutComponent={
                        <CustomTooltip
                          containerWidth={width}
                          setActive={props.setActive}
                          metrics={
                            tooltipMetrics ||
                            (compared ? [metric, compared] : [metric])
                          }
                          dateRange={dateRange}
                        />
                      }
                    />
                  }
                  style={{
                    data: {
                      fill: 'transparent'
                    },
                    labels: {
                      fill: 'transparent',
                      fontSize
                    }
                  }}
                />
              )}
            </VictoryGroup>
            {travelData && renderTravel(travelData, targetMetric.axisY[0])}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }

  return (
    <div
      className="line-chart"
      style={isCompared ? { position: 'absolute', width: '100%' } : {}}
    >
      {charts
        .filter((item) => !item.ranges)
        .map((item, index) => (
          <svg style={{ height: 200, position: 'absolute' }} key={index}>
            <defs>
              <linearGradient
                id={`lineGradient_${showIndex}_${item.metric_key
                  .split(' ')
                  .join('_')}}`}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0"
                  stopColor={item.theme.lineBg || item.theme.primary}
                  stopOpacity="0.1"
                />
                <stop
                  offset="0.875"
                  stopColor={item.theme.lineBg || item.theme.primary}
                  stopOpacity="0"
                />
              </linearGradient>
            </defs>
          </svg>
        ))}
      {renderChartContainer({
        containerStyle: { position: 'absolute', width: '100%', zIndex: 1000 },
        isTooltipContainer: true,
        targetMetric: tooltips,
        travelData
      })}
      {compared &&
        !compared.ranges &&
        renderChartContainer({
          containerStyle: { position: 'absolute', width: '100%' },
          isCompared: true,
          targetMetric: compared
          // travelData: !metric.ranges? travelData: null
        })}

      {metric &&
        !metric.ranges &&
        renderChartContainer({ targetMetric: metric })}
    </div>
  )
}

function Scatter(props) {
  return (
    <svg
      width="6"
      height="6"
      x={props.x - 3}
      y={props.y - 3}
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="3"
        r="1.75"
        fill="white"
        stroke={props.color}
        strokeWidth="1.5"
      />
    </svg>
  )
}

function calculateTooltips(metric, compared) {
  const tooltips = _.cloneDeep(metric)
  const [y1_min, y1_max] = metric.axisY,
    [y2_min, y2_max] = compared.axisY
  const ratio = (y2_max - y2_min) / (y1_max - y1_min)
  for (const datum of compared.data) {
    if (_.isNumber(datum.y)) {
      tooltips.data.push({
        x: datum.x,
        y: _.round(datum.y / ratio + y1_min, 2)
      })
    }
  }
  // tooltips.data = tooltips.data.filter((item) => item.y)
  return tooltips
}

function handleLineData(data) {
  let nullIndexArr = []
  let dashLines = []
  let dots = new Map()
  data.map((item, index) => {
    dots.set(index, item)
    if (item.y !== null) {
      nullIndexArr.push(index)
    }
    return item
  })
  if (nullIndexArr.length < data.length) {
    let index = nullIndexArr[0]
    for (let i = 0; i < nullIndexArr.length; i++) {
      if (index + i !== nullIndexArr[i]) {
        dashLines.push([nullIndexArr[i - 1], nullIndexArr[i]])
        index = nullIndexArr[i] - i
      }
    }
    dashLines.map((item) => {
      const [i0, i1] = item
      const [v1, v2] = [data[i0], data[i1]]
      const diff = (v2.y - v1.y) / (i1 - i0)
      let i = i0,
        y = v1.y

      while (i + 1 < i1) {
        ++i
        y += diff
        dots.set(i, { x: data[i].x, y })
      }
      return item
    })

    dashLines = dashLines.map((item) => {
      const [i0, i1] = item
      return [data[i0], data[i1]]
    })
  }

  const areaData = [...dots.values()]
  return { dashLines, areaData }
}

export default LineChart
