import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Modal, Upload, Typography, Button } from 'antd'
import { VectorPlusIcon } from '../../icons'
import TouchTextInput from './touchInput'
import AttachThumb from './thumb'
import { attachStyle } from './index.sass'
import { ModalStyled } from '../commonStyled'

const { Title, Footer } = ModalStyled

const { Paragraph } = Typography

class UploadModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    editAttachTitle: PropTypes.func,
    removeAttach: PropTypes.func,
    //...
    attachments: PropTypes.array
  }

  state = {
    percent: 0,
    status: '',
    file: null
  }

  uploadFile = (request) => {
    const { file } = request
    this.setState({ status: 'active', file })
    this.props
      .uploadFile(file, (percent) => {
        this.setState({ percent: parseInt(percent) })
      })
      .then((result) => this.setState({ status: 'success', file: null }))
      .catch((err) => console.error(err))
  }

  cancelUpload = () => {
    const { status } = this.state
    if (status === 'active') {
      this.props.cancelUpload()
      this.setState({ status: 'cancel' })
    }
  }
  cancel = () => {
    const { closeModal } = this.props
    this.cancelUpload()
    closeModal()
  }

  render() {
    const { visible, closeModal, removeAttach, editAttachTitle } = this.props
    const { status, percent } = this.state
    const attachments = this.props.attachments
      ? this.props.attachments.filter((item) => item.type === 'upload')
      : []
    return (
      <Modal
        visible={visible}
        onCancel={this.cancel}
        className={attachStyle}
        footer={null}
        width={667}
        bodyStyle={{ padding: '36px 40px' }}
      >
        <Title> Attach Files</Title>
        <div className="attach-list">
          {attachments.map((item, index) => (
            <div className="file-item" key={index}>
              <AttachThumb attach={item} />
              <div className="name">
                <div className="title">
                  <TouchTextInput
                    placeholder="Click to add a title"
                    initailValue={item.title}
                    save={async (title) => await editAttachTitle(item, title)}
                  />
                </div>
                <Paragraph ellipsis>{item.oss_name}</Paragraph>
              </div>
              <span className="rate finish">100%</span>
              <CloseOutlined onClick={() => removeAttach(item)} />
            </div>
          ))}
          {status === 'active' && (
            <div className="file-item">
              <span className="thumb" />
              <div className="name">
                {/* <div className="title">{item.title}</div> */}
                {/* <Paragraph ellipsis>{item.oss_name}</Paragraph> */}
              </div>
              <span className="rate">{percent}%</span>
              <CloseOutlined onClick={this.cancelUpload} />
            </div>
          )}
        </div>
        <Upload
          className="upload-text"
          customRequest={this.uploadFile}
          accept="image/*,.pdf,video/*,.doc,.docx"
        >
          <VectorPlusIcon />
          <div>Attach Files</div>
        </Upload>
        <Footer>
          <Button ghost onClick={this.cancel}>
            CANCEL
          </Button>
          <Button
            type="primary"
            onClick={closeModal}
            loading={status === 'active'}
          >
            CONFIRM
          </Button>
        </Footer>
      </Modal>
    )
  }
}

export default UploadModal
