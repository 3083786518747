import React from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
// import { range, selectRange } from '../../models/adminEditor.form'

let renderId = 0
const ButtonGroup = Button.Group
const fieldsOfRangeAgeNumber = ['min', 'max', 'color']
const fieldsOfRangeAgeOption = [null, 'output', 'color']
export default (WrappedComponent) =>
  class EditableForm extends React.Component {
    static propTypes = {
      defaultValues: PropTypes.array,
      editKey: PropTypes.string,
      ageScopes: PropTypes.array
    }

    state = {
      values: [],
      showTabIndex: 0
    }

    remove = async (key, index) => {
      let { showTabIndex } = this.state
      const { valueType, optionsOpr } = this.props
      delete this.refObj[key]
      const values = await Promise.all(
        Object.keys(this.refObj).map((form) =>
          this.refObj[form].getRangeFieldsValue()
        )
      )
      if (!values[showTabIndex]) {
        showTabIndex = 0
      }
      if (valueType === 'multi-select') {
        optionsOpr('remove', index)
      }
      this.state.values.splice(index, 1)
      this.setState({ showTabIndex })
    }

    add = () => {
      const { values } = this.state
      const { editKey, valueType, optionsOpr } = this.props
      this.refObj[editKey + renderId] = null
      renderId++
      values.push({})

      if (valueType === 'multi-select') {
        optionsOpr('add')
      }
      this.setState({ values })
    }

    updateFormsData = (changedValue, index, form) => {
      let { values } = this.state
      const { valueType, optionsOpr } = this.props
      if (changedValue.name) {
        values[index].name = changedValue.name
      }
      const fieldName = Object.keys(changedValue)[0]
      const field = changedValue[fieldName]
      if (!field) return

      values = values || []
      if (!values[index]) {
        values[index] = {}
      }
      const vo = values[index]
      if (form) {
        vo.form = form
      }
      vo[field.name] = field.value // for form display update
      const gender = (vo.gender = vo.gender || 'male')
      const ageTag = (vo.ageTag = vo.ageTag || 'A')
      const fieldsOfRangeAge =
        this.props.valueType === 'number'
          ? fieldsOfRangeAgeNumber
          : fieldsOfRangeAgeOption
      if (field.name === 'gender' || field.name === 'ageTag') {
        //set exist values of form to the current gender of ageTag , then clear forms
        fieldsOfRangeAge.forEach((f) => {
          if (f && vo[f]) {
            const key = `${gender}_${ageTag}_${f}`
            vo[key] = vo[f]
            form.setFieldsValue({
              [f]: null
            })
          }
        })
      } else {
        // update value to gender_ageTag key
        if (fieldsOfRangeAge.includes(field.name)) {
          vo[`${gender}_${ageTag}_${field.name}`] = field.value // record value to specific gender and ageTag
        }
        if (field.name === 'option' && valueType === 'multi-select') {
          // need gender and ageTag info?.............
          optionsOpr('update', index, field.value)
        }
      }

      this.setState({ values })
    }

    changeOptimal = (checked, index) => {
      const { values } = this.state
      if (checked) {
        values.map((item) => {
          item.is_optimal = false
          return item
        })
        values[index].is_threshold = false
      }
      values[index].is_optimal = checked
      this.setState({ values })
    }

    changeThreshold = (checked, index) => {
      const { values } = this.state
      if (checked) {
        values.map((item) => {
          // item.is_threshold = false
          return item
        })
        values[index].is_optimal = false
      }
      values[index].is_threshold = checked
      this.setState({ values })
    }

    UNSAFE_componentWillMount() {
      // const { defaultValues, onRefChange, editKey } = this.props
      const { defaultValues, editKey } = this.props
      if (!this.refObj) {
        this.refObj = {}
        for (let i = 0, l = defaultValues.length; i < l; i++) {
          this.refObj[editKey + renderId] = null
          renderId++
        }
        // onRefChange(this.refObj)
      }
      const values = defaultValues.map((range) => {
        if (range.male_age_scope || range.female_age_scope) {
          // income data, convert it to 'male_A_min': xxx,'male_B_color': 'red'...
          const blank = {}
          const male_age_scope = range.male_age_scope || blank
          const female_age_scope = range.female_age_scope || blank
          delete range.male_age_scope
          delete range.female_age_scope
          const fieldsOfRangeAge =
            this.props.valueType === 'number'
              ? fieldsOfRangeAgeNumber
              : fieldsOfRangeAgeOption

          Object.keys(male_age_scope).forEach((ageTag) => {
            const scope = male_age_scope[ageTag] // [min, max, color]
            fieldsOfRangeAge.forEach((k, idx) => {
              k && (range[`male_${ageTag}_${k}`] = scope[idx])
            })
          })
          Object.keys(female_age_scope).forEach((ageTag) => {
            const scope = female_age_scope[ageTag]
            fieldsOfRangeAge.forEach((k, idx) => {
              k && (range[`female_${ageTag}_${k}`] = scope[idx])
            })
          })
        }
        return range
      })
      this.setState({ values })
    }

    getPostRanges = () =>
      Promise.all(
        Object.keys(this.refObj).map((key) => this.refObj[key].postRangeValue())
      )

    render() {
      const { values, showTabIndex } = this.state
      // ageScopes: {tag: 'A'|'B'|'C'|'D'|'E'|'F'|'G'; label: '20-30'|....}[]
      const refKeys = Object.keys(this.refObj)
      return (
        <div className="ranges-container">
          {refKeys.map((k, index) => (
            <ButtonGroup key={k}>
              <Button
                className="range-btn"
                onClick={() => this.setState({ showTabIndex: index })}
                type={index === showTabIndex ? 'highlight' : 'dashed'}
              >
                {(values[index] &&
                  (values[index].name && values[index].name.trim())) ||
                  'unknown'}
              </Button>
              <Button
                icon={<CloseOutlined />}
                danger
                onClick={() => this.remove(k, index)}
              />
            </ButtonGroup>
          ))}

          <Button className="range-btn range-add" onClick={() => this.add()}>
            <PlusOutlined />
            Add
          </Button>

          {refKeys.map((k, index) => (
            <div
              key={k}
              className="range-panel"
              style={{
                display: index === showTabIndex ? 'block' : 'none'
              }}
            >
              <WrappedComponent
                refkey={k}
                wrappedComponentRef={(form) => {
                  if (form) {
                    this.refObj[k] = form
                  }
                }}
                value={values[index]}
                {...this.props}
                changeOptimal={(checked) => this.changeOptimal(checked, index)}
                changeThreshold={(checked) =>
                  this.changeThreshold(checked, index)
                }
                updateFormsData={(changedValue) =>
                  this.updateFormsData(changedValue, index)
                }
              />
            </div>
          ))}
        </div>
      )
    }
  }
