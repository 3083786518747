import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Button, Modal, Popover } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import classNames from 'classnames'
import mapDispatchToProps from '../dispatch'
import { questionnaireConfig } from '../../../utils/constant'
import { skipOnboardingStep } from '../../../api/onboardingSkip'
import IconCompleted from '../../../asserts/icon/icon-completed.svg'
import Header from './header'
import { logOutAction } from '../../../utils'

export const WaiverFormKey = {
  LIABILITY: 'liability_release_form',
  MEDICAL: 'medical_release_form'
}

function SetUpScreen(props) {
  const { account, history, logOut } = props
  const [activeStep, setActiveStep] = useState(0)

  const {
    user: {
      profile: {
        // waiver_required,
        questionnaire_required,
        // payment_required,
        completed_signature,
        introductory_required,
        pre_screening_completed,
        // membership,
        pre_screening_locked,
        onboarding_steps: {
          introductory_completed_steps,
          onboarding_required,
          waiver_forms_skipped,
          // billing_info_skipped,
          questionnaire_skipped
        }
      }
    }
  } = account

  useEffect(() => {
    let activeStep = 0
    if (pre_screening_locked) {
      Modal.info({
        // title: 'This is a notification message',
        width: 530,
        content: (
          <div>
            <p style={{ lineHeight: '25px' }}>
              Due to your medical history, our Medical Director Dr. Dev Mishra
              will reach out to you for discussion prior to participation in our
              Apeiron Life program.
            </p>
          </div>
        ),
        okText: 'Log Out',
        onOk() {
          logOutAction()
        }
      })
    }
    if (!pre_screening_completed) {
      activeStep = 0
    } else if (!(completed_signature || []).includes(WaiverFormKey.LIABILITY)) {
      activeStep = 1
    } else if (
      introductory_required ||
      !(
        introductory_completed_steps &&
        introductory_completed_steps.length === 2
      )
    ) {
      activeStep = 2
    } else if (
      !(completed_signature || []).includes(WaiverFormKey.MEDICAL) &&
      (waiver_forms_skipped || []).length === 0
    ) {
      activeStep = 3
    } else if (
      questionnaire_required &&
      questionnaire_skipped.length < questionnaireConfig.sections.length
    ) {
      activeStep = 4
    } else {
      activeStep = 5
    }
    setActiveStep(activeStep)
  }, [])

  const steps = [
    {
      showIndex: 1,
      tag: 'Pre-Assessment Medical Review',
      description:
        'Your safety is our priority. We follow evidence-based guidelines to evaluate each client for participation in our physical assessments',
      introduction: 'Why we need you to do this Pre-Assessment Medical Review?',
      isDone: pre_screening_completed,
      btnText: 'GET STARTED',
      targetUrl: '/onboarding/pre-screening',
      min: '1 min',
      disabledBack: true
    },
    {
      showIndex: 2,
      tag: 'Liability Release',
      description:
        'This form releases Apeiron Life from liability for all membership-related activities.',
      introduction: 'Why do we need a liability release?',
      min: '5 min',
      isDone: (completed_signature || []).includes(WaiverFormKey.LIABILITY),
      btnText: 'CONTINUE',
      disabledBack: true,
      targetUrl: '/onboarding/waiver/liabilities'
    },
    {
      showIndex: 3,
      tag: 'Member Information',
      description:
        "We'll start with some logistical information so we can start scheduling your appointments",
      introduction: 'Why we need you to do this Member Information?',
      min: '3 min',
      isDone:
        introductory_completed_steps &&
        introductory_completed_steps.length === 2,
      btnText: 'CONTINUE',
      targetUrl: '/onboarding/introductory'
    },
    {
      showIndex: 4,
      tag: 'Medical Release',
      description:
        'This form allows us to communicate with the doctor listed on the form about your results (as you request).',
      introduction: 'Why do we need a medical release?',
      min: '5 min',
      isDone:
        (completed_signature || []).includes(WaiverFormKey.MEDICAL) ||
        (waiver_forms_skipped || []).length > 0,
      btnText: 'CONTINUE',
      targetUrl: '/onboarding/waiver/medical'
    },
    {
      showIndex: 5,
      tag: 'Initial Intake Questionnaire',
      description:
        'Alongside your assessment, these questionnaires factor into your guidance and action plan. This final step will take 10 minutes.',
      introduction: 'Why do we need Health and Lifestyle Information?',
      min: '10 min',
      isDone: !questionnaire_required,
      isSkipped: questionnaire_skipped.length >= 5 || !onboarding_required,
      btnText: 'CONTINUE',
      targetUrl: '/onboarding/questionnaire/lifestyle'
    },
    {
      tag: 'Finished',
      title: "You're all done. \nAnd on your way to a better you.",
      introduction: "We'll use this information to design your action plan.",
      btnText: 'HOMEPAGE', //onboarding_required ? 'HOMEPAGE' : '',
      btnHandle: () => skipOnboardingStep('onboarding_required', account.user),
      targetUrl: '/',
      disabledBack: true
    },
    {
      tag: 'ready',
      targetUrl: '/onboarding'
    }
  ]

  const nextStep = async () => {
    const { account, history } = props

    const step = steps[activeStep]
    if (account) {
      if (step.btnHandle) {
        await step.btnHandle()
      }
      history.push(step.targetUrl)
    }
  }

  const backStep = () => {
    setActiveStep(activeStep - 1)
  }

  const { tag, introduction, description, btnText, disabledBack } =
    steps[activeStep] || {}

  return (
    <div className="set-up-screen">
      <Header history={history} logOut={logOut} />
      <div className="flow-container">
        <div
          className={classNames('flow', {
            'sign-image': activeStep === 1 || activeStep === 3
          })}
        >
          <div className="left">
            {_.cloneDeep(steps)
              .filter((item) => item.showIndex)
              .map((item, index) => (
                <div key={index} className="item">
                  <div className="wrap">
                    <div
                      className={classNames(
                        'circle',
                        { active: activeStep === index },
                        { completed: item.isDone }
                      )}
                    >
                      {item.isDone ? (
                        <img src={IconCompleted} alt="" />
                      ) : (
                        item.showIndex
                      )}
                    </div>{' '}
                    {item.tag}
                  </div>
                  <div className="min">{item.min}</div>
                </div>
              ))}
          </div>
          <div className="right">
            <div className="title">
              {tag &&
                tag.split('\n').map((t) => (
                  <span key={t}>
                    {t}
                    <br />
                  </span>
                ))}
            </div>
            <div className="question">{introduction}</div>
            <div className="answer">{description}</div>
          </div>
        </div>
        <div className="btn-box">
          {!disabledBack && (
            <Button
              type="default"
              onClick={backStep}
              className="btn-back"
              style={{ width: 130, height: 48 }}
            >
              BACK
            </Button>
          )}
          {btnText && (
            <Button type="primary" className="cta" onClick={nextStep}>
              {btnText}
              <RightOutlined />
            </Button>
          )}
        </div>
      </div>
      <div className="set-up-container"></div>
    </div>
  )
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(SetUpScreen)

export function PopIcon(props) {
  return (
    <Popover
      placement="top"
      overlayClassName="popover-hide-arrow"
      content={
        <div
          style={{
            width: 245,
            background: '#98A9BC',
            borderRadius: 8,
            color: '#FFFFFF',
            fontSize: 10,
            padding: 12
          }}
        >
          {props.description}
        </div>
      }
    >
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 0C2.9159 0 0 2.9159 0 6.5C0 10.0841 2.9159 13 6.5 13C10.0841 13 13 10.0841 13 6.5C13 2.9159 10.0841 0 6.5 0ZM6.5 2.665C6.66712 2.665 6.8305 2.71456 6.96946 2.80741C7.10842 2.90026 7.21672 3.03223 7.28068 3.18663C7.34463 3.34104 7.36137 3.51094 7.32876 3.67485C7.29616 3.83876 7.21568 3.98933 7.0975 4.10751C6.97933 4.22568 6.82877 4.30616 6.66485 4.33876C6.50094 4.37137 6.33104 4.35463 6.17663 4.29068C6.02223 4.22672 5.89026 4.11842 5.79741 3.97946C5.70456 3.8405 5.655 3.67712 5.655 3.51C5.655 3.28589 5.74403 3.07096 5.90249 2.91249C6.06096 2.75403 6.27589 2.665 6.5 2.665ZM8.58 10.01H4.68V8.97H6.11V6.11H5.07V5.07H7.15V8.97H8.58V10.01Z"
          fill="#98A9BC"
        />
      </svg>
    </Popover>
  )
}
