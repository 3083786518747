import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Divider } from 'antd'
import { deleteAttachment } from '../../../api/file'
import TouchInput from '../../../components/business/creport/touchInput'
import SingleImage from '../../../components/business/creport/singleImage'

export class ReportDeviIn extends Component {
  static propTypes = {
    updateReport: PropTypes.func,
    addAttachment: PropTypes.func,
    report: PropTypes.object,
    deleteAttachmentSync: PropTypes.func
  }

  state = {
    percent: 0,
    uploading: false
  }

  upload = async (file, progressCallback, source = null) => {
    const { addAttachment } = this.props
    await addAttachment(
      'report.intro_cover_photo',
      file,
      progressCallback,
      source
    )
  }

  deletePhoto = async (id) => {
    const { person, deleteAttachmentSync } = this.props
    const { isDeleting } = this.state
    if (isDeleting) return
    this.setState({ isDeleting: true })
    try {
      await deleteAttachment(id, person.id)
      this.setState({ isDeleting: false })
      deleteAttachmentSync('intro_cover_photo', id)
    } catch (err) {
      console.error(err)
    }
  }

  coverImage = async (request) => {
    const { report } = this.props
    const { file } = request
    const { id } = report.intro_cover_photo
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ uploading: true, source })
      await this.upload(file, (percent) => {
        this.setState({ percent })
        if (percent === 100) {
          this.setState({ uploading: false })
        }
      })
      await deleteAttachment(id)
    } catch (err) {
      console.error(err)
    }
  }

  saveIntro = async (value) => {
    const { updateReport } = this.props
    updateReport({
      intro_intro: value
    })
  }

  render() {
    const { report } = this.props
    return (
      <div className="report-dive-in">
        <Divider className="full" />
        <TouchInput
          initialValue={report && report.intro_intro}
          placeholder="Add Introduction"
          save={this.saveIntro}
        />
        <SingleImage
          photo={report && report.intro_cover_photo}
          upload={this.upload}
          deletePhoto={this.deletePhoto}
        />
      </div>
    )
  }
}

export default ReportDeviIn
