import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { RangeBar } from './strength'
import Spine from '../../../asserts/images/placeholder/img-1-spine.png'
import Hip from '../../../asserts/images/placeholder/img-2-hip.png'
import { RangeColors } from '../../../utils/constant'
import { RenderImageUrl } from '../../../components/assessmentReview/comp/GenericPdfDownloader'
import UploadImage from '../../../components/assessmentReview/comp/uploadImage'
import Information from '../../../components/UI/extraInformation'

const DXAContainer = styled.div`
  .container {
    display: flex;
    justify-content: space-between;
    padding: 30px;
  }
  .media-container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-left: -30px;
    padding-right: 30px;
  }
  .summary-container {
    min-width: 50%;
    text-align: right;
    font-size: 15px;
    color: #4c6072;
    margin-right: -30px;
    div[class^='summary'] {
      display: flex;
      justify-content: space-between;

      & > div {
        min-width: 100px;
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
    }

    .summary-header {
      padding-bottom: 32px;
      border-bottom: 1px solid #e3e6ea;
      &.bold {
        font-family: Gilroy-Bold;
      }
    }
    .summary-body {
      border-bottom: 1px solid #e3e6ea;
      padding: 20px 0;
      &.bold {
        font-family: Gilroy-Bold;
      }
      & > div {
        min-width: 50px;
        &:first-child {
          text-align: left;
        }
      }
    }
    .summary-footer {
      padding-top: 16px;
      color: #70808e;
    }
  }
  .disclaimer {
    text-align: right;
    color: #bac2c9;
    font-size: 15px;
    margin-bottom: 40px;
  }
`
const RangeBarContainer = styled.div`
  width: calc(100% - 120px);
  .ranges {
    margin-top: 10px;
  }
  .level-label {
    font-size: 15px;
    font-family: 'Gilroy-Bold';
    line-height: 21px;
    text-align: right;
  }
`

export default function DxaSummary(props) {
  const {
    dxaSummary,
    view,
    person,
    detail,
    handleSave,
    ui_version,
    ageScopes,
    notes,
    handleCommendSave,
    updateEditingCount
  } = props
  const getAXAValue = (key) => {
    const target = dxaSummary.find((item) => item.label === key)
    return _.isNumber(target && target.value)
      ? target.value
      : (target && target.value) || '--'
  }

  const renderRangeBar = (targetKey) => {
    const arg = getAXAValue(targetKey)
    return (
      <RangeBarContainer>
        <RangeBar {...arg} {...{ ageScopes, person }} hideValue />
        <div className="level-label" style={{ color: RangeColors[arg.color] }}>
          {arg.level}
        </div>
      </RangeBarContainer>
    )
  }

  const dxa = notes.find((item) => item.pillar === 'dxa')

  return (
    <DXAContainer>
      <div className="container">
        <div className="media-container">
          <div>
            {view === 'Preview' ? (
              <RenderImageUrl url={detail.DXA && detail.DXA.spine_chart} />
            ) : (
              <UploadImage
                onlyView={view !== 'Draft'}
                personId={person.id}
                url={detail.DXA && detail.DXA.spine_chart}
                uploadSync={(url) =>
                  handleSave({
                    detail: {
                      DXA: _.assign(detail.DXA, { spine_chart: url })
                    }
                  })
                }
              />
            )}
          </div>
          <div>
            <UploadImage
              placeholder={Spine}
              onlyView={view !== 'Draft'}
              personId={person.id}
              url={detail.DXA && detail.DXA.spine}
              uploadSync={(url) =>
                handleSave({
                  detail: { DXA: _.assign(detail.DXA, { spine: url }) }
                })
              }
            />
          </div>
        </div>
        <div className="summary-container">
          <div className={`summary-header ${ui_version ? 'bold' : ''}`}>
            <div>Region</div>
            <div>
              BMD (g/cm<sup>2</sup>)
            </div>
            <div>T-score</div>
            <div>Z-score</div>
          </div>
          <div className={`summary-body ${ui_version ? '' : 'bold'}`}>
            <div>Total</div>
            <div>{getAXAValue('spine_total_bmd')}</div>
            <div>{getAXAValue('lumbar_spine_t-score')}</div>
            <div>{getAXAValue('lumbar_spine_z-score')}</div>
          </div>
          {ui_version && (
            <div className="summary-body">
              <div>Age-ranking</div>
              {renderRangeBar('lumbar_spine_z_score_age_ranking')}
            </div>
          )}
          <div className="summary-footer">
            <div>Total BMD CV 1.0%</div>
            <div>
              {getAXAValue('who_classification_spine') !== '--' && (
                <>
                  WHO Classification: {getAXAValue('who_classification_spine')}
                </>
              )}
              <br />
              {getAXAValue('frax_bone_risk') !== '--' && (
                <>Fracture Risk: {getAXAValue('frax_bone_risk')}</>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="media-container">
          <div>
            {view === 'Preview' ? (
              <RenderImageUrl url={detail.DXA && detail.DXA.neck_chart} />
            ) : (
              <UploadImage
                onlyView={view !== 'Draft'}
                personId={person.id}
                url={detail.DXA && detail.DXA.neck_chart}
                uploadSync={(url) =>
                  handleSave({
                    detail: {
                      DXA: _.assign(detail.DXA, { neck_chart: url })
                    }
                  })
                }
              />
            )}
          </div>
          <div>
            <UploadImage
              placeholder={Hip}
              onlyView={view !== 'Draft'}
              personId={person.id}
              url={detail.DXA && detail.DXA.neck}
              uploadSync={(url) =>
                handleSave({
                  detail: { DXA: _.assign(detail.DXA, { neck: url }) }
                })
              }
            />
          </div>
        </div>
        <div className="summary-container">
          <div className={`summary-header ${ui_version ? 'bold' : ''}`}>
            <div>Region</div>
            <div>
              BMD (g/cm<sup>2</sup>)
            </div>
            <div>T-score</div>
            <div>Z-score</div>
          </div>
          <div className="summary-body">
            <div>Neck</div>
            <div>{getAXAValue('femoral_neck_bmd')}</div>
            <div>{getAXAValue('femoral_neck_t-score')}</div>
            <div>{getAXAValue('femoral_neck_z-score')}</div>
          </div>

          <div className={`summary-body ${ui_version ? '' : 'bold'}`}>
            <div>Total</div>
            <div>{getAXAValue('total_hip_bmd')}</div>
            <div>{getAXAValue('total_hip_t-score')}</div>
            <div>{getAXAValue('total_hip_z-socre')}</div>
          </div>
          <div className="summary-body">
            <div>Age-ranking</div>
            {renderRangeBar('femoral_neck_z_score_age_ranking')}
          </div>
          <div className="summary-footer">
            <div>Total BMD CV 1.0%</div>
            <div>
              {getAXAValue('who_classification_hip') !== '--' && (
                <>WHO Classification: {getAXAValue('who_classification_hip')}</>
              )}
              <br />
              {getAXAValue('frax_hip_risk') !== '--' && (
                <>Fracture Risk: {getAXAValue('frax_hip_risk')}</>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="disclaimer">Results not for diagnostic use</div>

      <Information
        title="COMMENT/NOTE"
        disMarkDone={true}
        initialValue={dxa ? dxa.memo : ''}
        status={view === 'Draft' ? 'Draft' : 'Opened'}
        updateCommend={(value, status) =>
          handleCommendSave(value, 'dxa', status)
        }
        updateEditingCount={updateEditingCount}
      />
    </DXAContainer>
  )
}
