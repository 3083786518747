import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
// import { animateScroll as scroll } from 'react-scroll'
// import RateInfo from '../progress/rateInfo'
import GoalCategoryTrackingView from './goalCategoryTrackingView'
import EditView from './dataEdit'
import ActivityContainer from '../activity'
// import { LinkIcon } from '../../../icons'
import AttachThumb from '../../UI/attachment/thumb'
import DateRange from '../../UI/mgComponents/dateRange'
// import PageLoading from '../../UI/status/loading'
import TodayTag from '../../UI/todayTag'
import RightHalfModalContainer from '../../UI/rightHalfModalContainer'

import EditIcon from '../../../asserts/icon/goals/edit.svg'
import TrashIcon from '../../../asserts/icon/goals/trash.svg'
import { GoalsMethods } from '../../../utils/common'

import { detailStyle } from './index.sass'
const { Paragraph } = Typography

class GoalDetail extends Component {
  static propTypes = {
    editGoal: PropTypes.object,
    openModal: PropTypes.func,
    deleteGoal: PropTypes.func,
    showDateRange: PropTypes.bool,
    showTodayTag: PropTypes.bool
  }

  state = { showActivity: false, activityEntry: null, statistics: null }

  getGoal = () => {
    const { editGoal, openModal } = this.props
    const statistics = this.state.statistics || this.props.statistics
    if (!editGoal) {
      openModal({ visible: 0 })
    }
    const id = editGoal.id
    const pillar = editGoal.category.pillar
    const target =
      statistics[pillar] &&
      statistics[pillar].find((item) => item.micro_goal.id === id)
    if (target) {
      target.micro_goal = GoalsMethods.matchGoalInfo(target.micro_goal)
      // this.setState({ editGoal: target.micro_goal })
    }
    return target
  }

  getAchievingData = (achieving) => {
    const { period, startDate, endDate } = this.props
    let achievingData = {
      achieved: 0,
      expected: 1,
      missed: 0,
      no_data: 0,
      entries: []
    }
    if (period === 'weekly') {
      achievingData = achieving.filter(
        (item) =>
          item.from === startDate.format('YYYY-MM-DD') &&
          item.to === endDate.format('YYYY-MM-DD')
      )[0]
    } else {
      for (const item of achieving) {
        for (const key in achievingData) {
          if (key === 'entries') {
            achievingData.entries = achievingData.entries.concat(item.entries)
          } else {
            achievingData[key] += item[key]
          }
        }
      }
    }
    return achievingData
  }

  componentDidMount() {
    const {
      editGoal: { goal_type },
      targetDate
    } = this.props
    // scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
    const showActivity = goal_type === 'heartRateMetrics' || targetDate
    this.setState({ showActivity })
  }

  render() {
    const {
      period,
      openModal,
      loading,
      startDate,
      endDate,
      saveEntry,
      saveManualRPE,
      deleteEntry,
      loginRole,
      setGlobalState,
      // type,
      position,
      role,
      showDateRange,
      showTodayTag,
      prevDate,
      nextDate,
      showDate,
      origin, // from overview
      originPeriod // from overview
    } = this.props
    let { editGoal } = this.props
    const { showActivity, activityEntry, statistics } = this.state
    const { micro_goal: goal, achieving } = this.getGoal() || {
      micro_goal: { attachments: [] },
      achieving: []
    }
    if (statistics) {
      editGoal = statistics[editGoal.category.pillar].find(
        (item) => item.micro_goal.id === editGoal.id
      ).micro_goal
    }

    const childProps = {
      period,
      achieving,
      startDate,
      endDate,
      goal,
      role: role || loginRole,
      position,
      saveEntry,
      deleteEntry,
      loading,
      setPropState: (state) => {
        this.setState(state)
      }
    }
    return (
      <RightHalfModalContainer extraClass="goal-detail-bg">
        <div className={`${detailStyle} container-body`}>
          <div className="header">
            <div className="title">
              {editGoal.goal_type !== 'heartRateMetrics' && showActivity && (
                <LeftOutlined
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    this.setState({ activityEntry: null, showActivity: false })
                  }
                />
              )}
              <Paragraph>{goal.micro_goal}</Paragraph>
            </div>
            <div className="operate">
              {loginRole !== 'User' &&
                loginRole !== 'Service_Provider' &&
                !goal.archived && (
                  <>
                    <img
                      src={EditIcon}
                      alt="edit"
                      onClick={() => {
                        openModal({
                          visible: 2,
                          targetDate: null
                        })
                        setGlobalState({
                          type:
                            goal.input_type === 'binary'
                              ? 'question'
                              : 'tracking',
                          pillar: goal.category && goal.category.pillar,
                          editGoal: goal
                        })
                      }}
                    />
                    <img
                      src={TrashIcon}
                      alt="trash"
                      onClick={() => {
                        openModal({
                          visible: 4,
                          targetDate: null
                        })
                        setGlobalState({
                          targetGoal: goal
                        })
                      }}
                    />
                  </>
                )}
              <CloseOutlined
                onClick={() => {
                  openModal({ visible: 0, targetDate: null })
                }}
                style={{ fontSize: 20, color: '#383E48' }}
              />
            </div>
          </div>

          {(showDateRange || showTodayTag) && (
            <div className="date-range">
              {showDateRange && (
                <DateRange
                  prevDate={prevDate}
                  nextDate={nextDate}
                  showDate={showDate}
                />
              )}
              {showTodayTag && <TodayTag />}
            </div>
          )}

          {showActivity ? (
            <div>
              <ActivityContainer
                entry={activityEntry}
                trackingActivityMetrics={editGoal.tracking_activity_metric}
                {...{
                  editGoal,
                  statistics: this.props.statistics,
                  targetDate: this.state.targetDate || this.props.targetDate,
                  person: this.props.person,
                  origin,
                  originPeriod,
                  startDate,
                  endDate,
                  saveManualRPE,
                  setDetailStatistics: (statistics) =>
                    this.setState({ statistics })
                }}
              />
            </div>
          ) : (
            <EditView {...childProps} />
          )}

          <div>
            <div className="section-name">Metric detail</div>
            <GoalCategoryTrackingView goal={goal} />
          </div>
          <div>
            <div className="section-name">Description</div>
            <div className="text-info">{goal.additional_information}</div>
          </div>
          <div>
            <div className="section-name">Attachments</div>
            {goal.attachments.map((item, index) => (
              <div className="attach-item-container" key={index}>
                <div
                  className="attach-item"
                  onClick={() => window.open(item.oss_url || item.url)}
                >
                  <AttachThumb attach={item} />
                  <div className="name">
                    <div className="title">
                      <Paragraph ellipsis>{item.title}</Paragraph>
                    </div>
                    <Paragraph ellipsis>
                      {item.type === 'url' ? item.url : item.name}
                    </Paragraph>
                  </div>
                  <div
                    className="close"
                    onClick={() => this.removeAttach(item)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </RightHalfModalContainer>
    )
  }
}

export default GoalDetail
