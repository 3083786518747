import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import { Route, Switch } from 'react-router-dom'
import Dataset from './dataset'
import ShareLinkDetail from './detail'
import { hashRequest } from '../../api/axios'
import { getSlideMenu } from '../../api/dataset'

import { sharaLinkStyle } from './index.sass'

class IosShareLink extends Component {
  static propTypes = {}
  state = {}

  getPerson = async (hash) => {
    const result = await hashRequest({
      hash,
      method: 'get',
      path: '/people/me'
    })
    const person = result.data.person
    this.setState({ person })
  }

  getHrefs = (hash) => {
    const url = '/share-link'
    const hrefs = {
      bodyCom: `${url}/body-comp/${hash}`,
      lifestyle: `${url}/life-style/${hash}`,
      range_of_motions: `${url}/range-of-motions/${hash}`,
      force_place: `${url}/force-plate/${hash}`,
      strength: `${url}/strength/${hash}`,
      cardiorespiratory: `${url}/cardiorespiratory/${hash}`,
      cognitive_health: `${url}/cognitive-health/${hash}`,
      movement_efficiency: `${url}/movement-efficiency/${hash}`,
      vital: `${url}/vital/${hash}`,
      nutrition: `${url}/nutrition/${hash}`,
      blood_test: `${url}/blood-test/${hash}`
    }
    this.setState({ hrefs })
  }

  getCount = async (hash) => {
    const result = await hashRequest({
      hash,
      method: 'get',
      path: '/datasets/count'
    })
    const count = result.data
    this.setState({ count })
  }

  getMenu = async (hash) => {
    const { setSubMenu } = this.props
    const result = await getSlideMenu(hash)
    setSubMenu('groups', result.groups)
  }

  componentDidMount() {
    const {
      match: {
        params: { hash }
      },
      slideMenu
    } = this.props
    if (!slideMenu.groups) {
      this.getMenu(hash)
    }
    this.getPerson(hash)
    this.getHrefs(hash)
    this.getCount(hash)
  }

  render() {
    const {
      match: {
        url,
        params: { type, hash }
      },
      slideMenu,
      location,
      setClientBiomarkerCategories
    } = this.props
    const { person } = this.state
    const isDetail = location.pathname.split('/').length >= 7
    const childProps = {
      setClientBiomarkerCategories,
      person,
      type,
      hash,
      slideMenu,
      hideHeader: true
    }
    return (
      <div className={sharaLinkStyle}>
        <div className="main-content ios">
          <div className={`${isDetail ? 'detail-right' : ''} right`}>
            <Switch>
              <Route
                path={`${url}`}
                exact
                render={(props) => <Dataset {...props} {...childProps} />}
              />
              <Route
                path={`${url}/:category/:datasetId/:metricId`}
                render={(props) => (
                  <ShareLinkDetail {...props} {...childProps} />
                )}
              />
              <Route
                path={`${url}/:datasetId/:metricId`}
                render={(props) => (
                  <ShareLinkDetail {...props} {...childProps} />
                )}
              />
              <Route
                path={`${url}/:category`}
                render={(props) => <Dataset {...props} {...childProps} />}
              />
            </Switch>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ client, slideMenu }) => ({ client, slideMenu }),
  mapDispatchToProps
)(IosShareLink)
