import moment from 'moment'
import { pattern } from '../utils/constant'

const ethnicities = [
  {
    label: 'Black / African American',
    value: 'Black / African American'
  },
  {
    label: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native'
  },
  { label: 'Asian', value: 'Asian' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  {
    label: 'Native Hawaiian or Other  Pacific Islander',
    value: 'Native Hawaiian or Other  Pacific Islander'
  },
  { label: 'Caucasian', value: 'Caucasian' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
  { label: 'Other', value: 'Other' }
]

const TimeZones = [
  {
    value: 'America/Chicago',
    label: 'Central Time'
  },
  {
    value: 'America/Denver',
    label: 'Mountain Time'
  },
  {
    value: 'America/New_York',
    label: 'Eastern Time'
  },
  {
    value: 'America/Los_Angeles',
    label: 'Pacific Time'
  },
  {
    value: 'America/Anchorage',
    label: 'Alaska Time'
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Hawaii-Aleutian Time'
  }
]

export const getClient = (otherEthnicityValidator, clientValue) => {
  const clientInfo = [
    {
      type: 'input',
      label: 'first name',
      dbField: 'first_name',
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'last name',
      dbField: 'last_name',
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'email',
      dbField: 'email',
      colSpan: 12,
      rules: [
        { required: true, message: 'This field is required' },
        { pattern: pattern.email, message: 'Please input a valid email' }
      ]
    },
    {
      type: 'radios',
      label: 'gender',
      dbField: ['profile', 'gender'],
      colSpan: 12,
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' }
      ],
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'select',
      options: [
        {
          value: '0',
          label: 'Core'
        },
        {
          value: '1',
          label: 'Non-Core'
        },
        {
          value: '2',
          label: 'Test User'
        },
        {
          value: '3',
          label: 'Partners'
        },
        {
          value: '6',
          label: 'Archive'
        },
        {
          value: '-1',
          label: 'Prospect'
        }
      ],
      dbField: ['profile', 'account_type'],
      label: 'ACCOUNT TYPE',
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'select',
      options: [
        {
          value: 'insights',
          label: 'Healthspan Insights'
        },
        {
          value: 'body recomposition',
          label: 'Body Recomposition'
        },
        {
          value: 'evaluation2',
          label: 'Healthspan Assessment'
        },
        {
          value: 'gold',
          label: 'Gold Membership'
        },
        {
          value: 'silver',
          label: 'Silver Membership'
        },
        {
          value: 'bronze',
          label: 'Bronze Membership'
        },
        {
          value: 'remote',
          label: 'Remote Membership'
        },
        {
          value: 'remote lite',
          label: 'Remote Lite Membership'
        },
        {
          value: 'module',
          label: 'Module Membership'
        },
        {
          value: 'core',
          label: 'Core A La Carte',
          hidden: true
        },
        {
          value: 'core+',
          label: 'Core',
          hidden: true
        }
      ],
      dbField: ['profile', 'membership'],
      label: 'MEMBERSHIP',
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    }
  ]
  const additionalInfo = [
    {
      type: 'input',
      label: 'nickname',
      dbField: ['profile', 'nickname'],
      colSpan: 12
      // rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'date',
      label: 'date of birth',
      dbField: ['profile', 'date_of_birth'],
      disabledDate: (currentDate) => currentDate.isAfter(moment()),
      colSpan: 12,
      format: 'M/D/YYYY',
      rules: [{ message: 'Please select date!' }]
    },
    {
      type: 'input',
      label: 'phone number',
      colSpan: 12,
      dbField: ['profile', 'phone_number'],
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'city',
      dbField: ['profile', 'city'],
      colSpan: 12,
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'state',
      dbField: ['profile', 'state'],
      colSpan: 12,
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'input',
      // inputType: 'number',
      label: 'height(inch)',
      colSpan: 3,
      dbField: ['profile', 'height_ft'],
      suffix: 'ft',
      rules: [
        {
          validator: (rule, value, callback) => {
            value = Number(value)
            if (value > 999) {
              callback('Your input has exceeded the limit')
            }
            if (value < 0) {
              callback('Invalid value')
            }
            callback()
          }
        }
      ]
    },
    {
      type: 'input',
      colSpan: 3,
      dbField: ['profile', 'height_in'],
      suffix: 'in',
      rules: [
        {
          validator: (rule, value, callback) => {
            value = Number(value)
            if (value > 999) {
              callback('Your input has exceeded the limit')
            }
            if (value < 0) {
              callback('Invalid value')
            }
            callback()
          }
        }
      ]
    },
    {
      type: 'input',
      // inputType: 'number',
      label: 'weight(lbs)',
      colSpan: 6,
      dbField: ['profile', 'weight_lbs'],
      rules: [
        {
          validator: (rule, value, callback) => {
            value = Number(value)
            if (value > 999) {
              callback('Your input has exceeded the limit')
            }
            if (value < 0) {
              callback('Invalid value')
            }
            callback()
          }
        }
      ]
    },
    {
      type: 'select',
      options: ethnicities,
      dbField: ['profile', 'ethnicity'],
      label: 'ethnicity',
      colSpan: 12,
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'input',
      dbField: 'other_ethnicity',
      label: 'ethnicity',
      colSpan: 12,
      hideField: true,
      unless: { key: ['profile', 'ethnicity'], value: 'Other' },
      rules: [
        {
          validator: otherEthnicityValidator
        }
      ]
    },
    {
      type: 'select',
      options: TimeZones,
      dbField: ['profile', 'time_zone'],
      label: 'Timezone',
      colSpan: 12,
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'date',
      label: 'client since',
      dbField: ['profile', 'client_since'],
      disabledDate: (currentDate) => currentDate.isAfter(moment()),
      colSpan: 12,
      format: 'M/D/YYYY'
    },
    {
      type: 'input',
      label: 'Executive Assistant Email',
      dbField: ['profile', 'executive_assistant_email'],
      colSpan: 12,
      rules: [{ pattern: pattern.email, message: 'Please input a valid email' }]
    },
    {
      type: 'select',
      options: [
        {
          value: '0',
          label: 'Paid'
        },
        {
          value: '1',
          label: 'Free',
          disabled:
            !clientValue.id &&
            clientValue.profile &&
            clientValue.profile.account_type !== '2'
        }
      ],
      dbField: ['profile', 'payment_status'],
      label: 'Payment Status',
      colSpan: 12,
      rules: [{ message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'max heart rate',
      dbField: ['profile', 'max_heart_rate'],
      colSpan: 12,
      rules: [{ pattern: pattern.integer, message: 'Please type an integer' }]
    },
    {
      type: 'input',
      label: 'trainer email',
      dbField: ['profile', 'trainer_email'],
      colSpan: 12,
      rules: [{ pattern: pattern.email, message: 'Please input a valid email' }]
    },
    {
      type: 'textarea',
      label: 'notes',
      dbField: ['profile', 'notes'],
      rules: [{ max: 255, message: 'This field is limited to 255 characters' }]
    },
    {
      type: 'select',
      options: [
        {
          value: 'no',
          label: 'No discount'
        },
        {
          value: 'family',
          label: 'Family discount'
        },
        {
          value: 'crescendo',
          label: 'Crescendo discount'
        }
      ],
      dbField: ['profile', 'discount'],
      label: 'DISCOUNT',
      colSpan: 12
    },
    {
      type: 'checkbox',
      colSpan: 12,
      initialValue: true,
      text: 'Include in data aggregation',
      dbField: ['profile', 'in_data_aggregation']
    }
  ]

  return { clientInfo, additionalInfo }
}

export const testDate = [
  {
    type: 'date',
    disabledDate: (current) => current && current >= moment().endOf('day'),
    label: 'test date',
    dbField: 'test_date',
    rules: [{ required: true, message: 'Please select date!' }]
  }
]

export const fileUploadForm = (onlyFile) =>
  onlyFile
    ? [
        {
          type: 'file',
          label: "file's location",
          dbField: 'files',
          id: 'upload-file',
          colSpan: 16,
          rules: [{ required: true, message: 'Please select a file!' }]
        }
      ]
    : [
        {
          type: 'file',
          label: "file's location",
          dbField: 'files',
          id: 'upload-file',
          colSpan: 16,
          rules: [{ required: true, message: 'Please select a file!' }]
        },
        {
          type: 'date',
          label: 'test date',
          dbField: 'test_date',
          format: 'M/D/YYYY',
          disabledDate: (currentDate) => currentDate.isAfter(moment()),
          colSpan: 8,
          rules: [{ required: true, message: 'Please select date!' }]
        }
      ]

export const basicInfo = ({ editing, loginRole }) => [
  {
    type: 'input',
    label: 'first name',
    dbField: 'first_name',
    colSpan: 6,
    forceDisabled: !editing,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'last name',
    dbField: 'last_name',
    colSpan: 6,
    forceDisabled: !editing,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'nick name',
    dbField: ['profile', 'nickname'],
    colSpan: 6,
    disabled: !editing,
    forceDisabled: !editing
  },
  {
    type: 'date',
    label: 'DATE OF BIRTH',
    dbField: ['profile', 'date_of_birth'],
    colSpan: 6,
    disabled: !editing,
    format: 'M/D/YYYY'
  },
  {
    type: 'input',
    label: 'phone number',
    colSpan: 6,
    disabled: !editing,
    forceDisabled: !editing,
    dbField: ['profile', 'phone_number']
  },
  {
    type: 'select',
    options: TimeZones,
    dbField: ['profile', 'time_zone'],
    label: 'Timezone',
    colSpan: 6,
    disabled: !editing
  },
  {
    initialValues: 'no',
    type: 'select',
    options: [
      {
        value: 'no',
        label: 'No discount'
      },
      {
        value: 'family',
        label: 'Family discount'
      },
      {
        value: 'crescendo',
        label: 'Crescendo discount'
      }
    ],
    dbField: ['profile', 'discount'],
    label: 'DISCOUNT',
    disabled: (loginRole || 'User') === 'User' || !editing,
    colSpan: 12
  }
  // {
  //   type: 'select',
  //   options: [
  //     {
  //       value: 'annually',
  //       label: 'Annually'
  //     },
  //     {
  //       value: 'quarterly',
  //       label: 'Quarterly'
  //     }
  //   ],
  //   dbField: ['profile', 'billing_preference'],
  //   label: 'BILLING PREFERENCE',
  //   disabled: (loginRole || 'User') === 'User' || !editing,
  //   colSpan: 12
  // }
]

/**
 * {
            value: 'evaluation2',
            label: 'Healthspan Assessment' 
          },
          {
            value: 'gold',
            label: 'Gold Membership'
          },
          {
            value: 'silver',
            label: 'Silver Membership'
          },
          {
            value: 'bronze',
            label: 'Bronze Membership'
          },
          {
            value: 'remote',
            label: 'Remote Membership'
          },
          {
            value: 'remote lite',
            label: 'Remote Lite Membership'
          },
          {
            value: 'core+',
            label: 'Core A La Carte'
          },
          {
            value: 'lite',
            label: 'Apeiron Lite'
          },
          {
            value: 'module',
            label: 'Module Membership'
          }
 * 
 * **/
const membershipObj = {
  gold: 'Gold',
  silver: 'Silver',
  bronze: 'Bronze',
  remote: 'Remote',
  'remote lite': 'Remote Lite',
  core: 'Core A La Carte',
  'core+': 'Core',
  lite: 'Apeiron Lite',
  module: 'Module',
  evaluation2: 'Assessment Only',
  evaluation: '360 Evaluation'
}

// used in calendar appointment
const clientTypes = []
for (let key in membershipObj) {
  clientTypes.push({
    label: membershipObj[key],
    text: membershipObj[key],
    value: key
  })
}

export { ethnicities, membershipObj, clientTypes }
