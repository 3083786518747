import styled from 'styled-components'

export const NutritionCardContainer = styled.div`
  display: flex;
  .qqt-card-container {
    width: 33%;
    padding-top: 23px;
    .qqt-card-null-state {
      padding: 16px;
      background-color: #fafafa;
      border-radius: 8px;
      color: #323f4a;
      font-size: 18px;
      margin-bottom: 24px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .n-a {
        font-size: 24px;
        color: #a5abb2;
      }
    }
  }
  .nutrition-items-container {
    width: 67%;
    margin-left: 20px;
    border-left: 1px solid #e5e5e5;
    .skiped-container-header {
      background-color: #f1f3f6;
      font-size: 14px;
      font-weight: 600;
      color: #8596a4;
      padding: 14px 24px;
      display: flex;
      justify-content: space-between;
    }
    .null-state {
      display: flex;
      align-items: center;
      padding: 100px;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      color: #8596a4;
      font-size: 16px;
      font-weight: 600;
      background: #f1f3f6;
      min-height: 600px;
      span {
        margin-top: 16px;
      }
      img {
        width: 120px;
        border: 1px solid rgba(0, 0, 0, 1);
        border-radius: 4px;
      }
      .view-in-app-label {
        font-weight: 600;
        color: rgba(50, 63, 74, 1);
        font-size: 14px;
        margin-top: 16px;
      }
      .view-in-app-desc-label {
        font-size: 12px;
        color: rgba(82, 96, 108, 1);
        font-weight: 400;
      }
    }
  }
`
