import React, { useState, useEffect } from 'react'
import { Divider, Switch, Button, Select } from 'antd'
import TrendsWrapper from './wrapper'
import styled from 'styled-components'
import MetricContainer from './metric'
import { AxisYLabel } from './chart'
import DatePickerByRange from '../UI/dateGroupPicker/datePickByRange'
import PageLoading from '../UI/status/loading'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { getAxisPadding } from './chartHandle'
import { getRole } from '../../utils/common'
import { loadUser } from '../../utils/storage'
import moment from 'moment'

const colorArr = {
  BloodPanel: {
    primary: '#CF072A',
    barBg: ['#CF4658', '#CF072A']
    // lighter: {}
  },
  Healthspan: {
    primary: '#1D5FF5',
    barBg: ['#1D96F5', '#1D5FF5']
  },
  Nutrition: {
    primary: '#7BAD2D',
    barBg: ['#A9BB37', '#7BAD2D'],
    lighter: {
      primary: '#ADCB38',
      barBg: ['#CDDF86', '#BCD55D']
    }
  },
  Exercise: {
    primary: '#FD8F3E',
    barBg: ['#FBAF59', '#FF8B35'],
    lineBg: '#FF8933',
    lighter: {
      primary: '#F7AD69',
      barBg: ['#FBD7B6', '#F9BD86']
    }
  },
  Sleep: {
    primary: '#5C00D1',
    barBg: ['#8D33FF', '#5C00D1'],
    lighter: {
      primary: '#A966FF',
      barBg: ['#BA84FF', '#9D51FF']
    }
  }
}

const Container = styled.div`
  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 99999;
  }
  // .tools {
  //   position: relative;
  //   z-index: 9999;
  // }
  .tips {
    list-style: none;
    text-align: right;
    right: 40px;
    position: absolute;
    margin-top: -50px;
    li {
      padding: 0 5px;
      display: inline-block;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: gray;
        display: inline-block;
        margin-right: 6px;
        vertical-align: baseline;
      }
      &.blood:before {
        background-color: ${colorArr.BloodPanel.primary};
      }
      &.health:before,
      &.healthspan:before {
        background-color: ${colorArr.Healthspan.primary};
      }
      &.nutrition:before {
        background-color: ${colorArr.Nutrition.primary};
      }
      &.exercise:before {
        background-color: ${colorArr.Exercise.primary};
      }
      &.sleep:before {
        background-color: ${colorArr.Sleep.primary};
      }
    }
  }

  .drop-container {
    position: relative;
    z-index: 9999;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    .ant-select-selector {
      padding-left: 20px !important;
      border-radius: 12px;
    }
  }

  .main-content {
    padding: 0 0 20px 0;
    background: #ffffff;
    border: 1px solid #e4e7eb;
    border-radius: 12px;
    & > * {
      padding: 0 20px;
    }

    .tool-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date-tips {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        li {
          display: inline-block;
          padding: 10px 8px;
          border: 1px solid #e4e7eb;
          color: #7b8794;
          cursor: pointer;
          &:not(:last-child) {
            border-right: none;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          &.active {
            color: #3e4c59;
            background: #cbd2d9;
          }
          &:first-child {
            border-radius: 6px 0px 0px 6px;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
      .ant-calendar-picker {
        width: 227px;
        input {
          color: #616e7c;
        }
      }
      .divider {
        border-left: 1px solid #e3e7eb;
        height: 40px;
      }
      .show-number-container {
        // border-left: 1px solid #e3e7eb;
        // padding-left: 22px;
        line-height: 40px;
      }
      .chart-type {
        background: #ebecf2;
        border-radius: 4px;
        color: #9aa5b1;
        font-size: 14px;
        padding: 2px;
        span {
          display: inline-block;
          padding: 8px;
          cursor: pointer;
          &.active {
            color: #3e4c59;
            background: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
    .chart-container {
      position: relative;
      width: 75%;
    }
    .axis-bar {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      button {
        background: #dceefb;
        border-radius: 6px;
        color: #0f609b;
      }
      button[disabled] {
        border-color: #d9d9d9;
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }
      // .chart-container {
      //   padding: 0 40px 0 10px;
      // }
    }
  }
`

function TrendsContainer(props) {
  const {
    selectedDate,
    dateRanges,
    switchDate,
    switchShowNumber,
    showNumber,
    showGraphType,
    switchGraphType,
    setMetricsEditing,
    allMetrics,
    onAdd,
    from,
    to,
    onRangeChange,
    statistics,
    loading,
    // displayMetrics,
    metricGroup,
    activeGroup,
    onGroupChange,
    countCategory
    // setToClientView
  } = props

  const [displayMetrics, setDisplayMetrics] = useState([])
  // const [isShowingToClient, setIsShowingToClient] = useState(false)

  useEffect(() => {
    setDisplayMetrics(
      (metricGroup[activeGroup] && metricGroup[activeGroup].display_metrics) ||
        []
    )
  }, [metricGroup, activeGroup])

  const dateRange = dateRanges[selectedDate || 0]
  if (!statistics) {
    return <PageLoading />
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    props.onDragEnd(result.source.index, result.destination.index)
  }

  const renderMetricContainer = () => {
    return displayMetrics.map((item, index) => (
      <Draggable
        key={item.metric_key}
        draggableId={item.metric_key}
        index={index}
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              background: 'white',
              ...provided.draggableProps.style
            }}
          >
            <MetricContainer
              key={index}
              {...{
                showIndex: index,
                draggableProvided: provided,
                showNumber,
                showGraphType,
                dateRange,
                allMetrics,
                onAdd,
                colorArr,
                metric: item
              }}
            />
          </div>
        )}
      </Draggable>
    ))
  }

  const loginRole = getRole(loadUser().role)

  return (
    <Container>
      {loading && (
        <>
          <div className="ant-modal-mask" />
          <div
            className="ant-modal-wrap"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <PageLoading />
          </div>
        </>
      )}
      <div className="tools">
        <ul className="tips">
          <li className="blood">Blood Panel</li>
          <li className="health">Healthspan</li>
          <li className="nutrition">Nutrition</li>
          <li className="exercise">Exercise</li>
          <li className="sleep">Sleep</li>
        </ul>
      </div>
      <Divider />
      {loginRole !== 'User' && (
        <div className="drop-container">
          <DropDown
            metricGroup={metricGroup}
            activeGroup={activeGroup}
            onGroupChange={onGroupChange}
            countCategory={countCategory}
          />
          {/* <Button
            type="primary"
            loading={isShowingToClient}
            onClick={async () => {
              setIsShowingToClient(true)
              await setToClientView()
              setIsShowingToClient(false)
              message.success('Success! ')
            }}
          >
            Show to Client
          </Button> */}
          {loginRole !== 'User' && (
            <Button type="primary" onClick={() => setMetricsEditing(true)}>
              Edit Metrics
            </Button>
          )}
        </div>
      )}
      <div className="main-content">
        <div
          style={{
            zIndex: 9998,
            top: 0,
            position: 'sticky',
            background: 'white',
            borderRadius: '12px 12px 0 0',
            paddingTop: 20,
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)'
          }}
        >
          <div className="tool-bar">
            <ul className="date-tips">
              {dateRanges.map((item, index) => (
                <li
                  key={index}
                  className={`${selectedDate === index ? 'active' : ''} ${
                    item.disabled ? 'disabled' : ''
                  }`}
                  onClick={() => !item.disabled && switchDate(index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            {dateRange.picker === 'blank' && (
              <DatePickerBlank dateRange={dateRange} />
            )}
            {dateRange.name !== 'Lifetime' && dateRange.picker !== 'blank' && (
              <DatePickerByRange {...{ from, to, dateRange, onRangeChange }} />
            )}
            <Divider type="vertical" className="divider" />
            <div className="show-number-container">
              Show Number{' '}
              <Switch
                size="small"
                checked={showNumber}
                onChange={switchShowNumber}
              />
            </div>
            <div className="chart-type">
              <span
                className={showGraphType === 'line' ? 'active' : ''}
                onClick={() => switchGraphType('line')}
              >
                Line Chart
              </span>
              <span
                className={showGraphType === 'bar' ? 'active' : ''}
                onClick={() => switchGraphType('bar')}
              >
                Bar Chart
              </span>
            </div>
          </div>

          <div className="axis-bar">
            <div>
              {/* {loginRole !== 'User' && (
                <Button type="primary" onClick={() => setMetricsEditing(true)}>
                  Edit Metrics
                </Button>
              )} */}
            </div>

            <div
              className="chart-container"
              style={
                displayMetrics[0]
                  ? getAxisPadding(displayMetrics[0], dateRange, showGraphType)
                  : {}
              }
            >
              {displayMetrics[0] && (
                <AxisYLabel
                  {...{
                    metric: displayMetrics[0],
                    dateRange,
                    showGraphType
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderMetricContainer()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Container>
  )
}

export default TrendsWrapper(TrendsContainer)

const DropDownContainer = styled.div`
  border-bottom: 1px solid #e3e7eb;
  cursor: pointer;
  color: #a3b3c2;
  font-size: 12px;
  padding: 14px 0 10px;
  &:last-child {
    border: none;
  }
  & > div,
  .tips {
    position: relative;
    margin-top: 5px;
    right: 0;
    display: flex;
    justify-content: space-between;
  }
  .tips {
    padding: 0;
  }
  .label {
    color: #4c6072;
    font-size: 15px;
  }
`

function DropDown(props) {
  const { metricGroup, onGroupChange, activeGroup, countCategory } = props
  const [selectOpen, setSelectOpen] = useState(false)
  const [value, setValue] = useState(
    metricGroup[activeGroup] && metricGroup[activeGroup].name
  )

  useEffect(() => {
    setValue(metricGroup[activeGroup] && metricGroup[activeGroup].name)
  }, [activeGroup, metricGroup])

  return (
    <Select
      style={{ width: 500 }}
      size="large"
      dropdownStyle={{
        width: 500,
        borderRadius: 12,
        padding: '10px 16px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
        maxHeight: 500,
        overflowY: 'auto'
      }}
      dropdownMatchSelectWidth={500}
      open={selectOpen}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      value={value}
      onDropdownVisibleChange={(open) => setSelectOpen(open)}
      dropdownRender={(ReactNode) => {
        return metricGroup.map((item, index) => (
          <DropDownContainer
            key={item.id}
            onClick={() => {
              setValue(item.name)
              onGroupChange(index)
              setSelectOpen(false)
            }}
          >
            <div>
              <div className="label">{item.name}</div>
              <div className="name">by {item.created_person}</div>
            </div>
            <ul className="tips">
              {countCategory(item.display_metrics).map((item, index) => (
                <li key={index} className={item.name.toLowerCase()}>
                  {item.name === 'Blood Metrics' ? 'Blood Panel' : item.name} (
                  {item.count})
                </li>
              ))}
            </ul>
          </DropDownContainer>
        ))
      }}
    ></Select>
  )
}

export const BlankContainer = styled.div`
  font-family: Gilroy;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #52606c;
  border: 1px solid #dee2ec;
  border-radius: 6px;
  padding: 8px 15px;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 18px;
  background: #ffffff;
`

export function DatePickerBlank(props) {
  const {
    dateRange: { name }
  } = props

  const matchResult = name.match(/\d+/)
  const days = matchResult ? parseInt(matchResult[0], 10) : null
  let str = ''
  switch (days) {
    case 7:
      str = 'LAST 7-DAYS '
      break
    case 14:
      str = 'Last 14-days '
      break
    case 30:
      str = 'LAST 30-dAYs '
      break
    case 90:
      str = 'LAST 90-dAYs '
      break
    default:
      break
  }

  return (
    <BlankContainer>
      {str} ({moment().format('MM/DD/YYYY')})
    </BlankContainer>
  )
}
