import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { modalStyle } from './index.sass'

const { Paragraph } = Typography

class HalfPageModal extends Component {
  static propTypes = {
    containerStyle: PropTypes.object,
    position: PropTypes.string,
    title: PropTypes.string,
    prefix: PropTypes.object,
    suffix: PropTypes.array,
    close: PropTypes.func
  }

  render() {
    const {
      containerStyle,
      position,
      title,
      prefix,
      suffix,
      close,
      extraTitle
    } = this.props
    return (
      <div
        className={`${modalStyle} ${position}-position`}
        style={containerStyle || {}}
      >
        <div className="container-body">
          <div className="header">
            <div className="title">
              <div>
                {prefix}
                <Paragraph>{title}</Paragraph>
              </div>
              <div className="sub-title">{extraTitle}</div>
            </div>
            <div className="operate">
              {suffix &&
                suffix.map((item, index) => <div key={index}>{item}</div>)}
              <CloseOutlined
                onClick={close}
                style={{ fontSize: 20, color: '#383E48' }}
              />
            </div>
          </div>
          <div className="main-container">{this.props.children}</div>
        </div>
      </div>
    )
  }
}

export default HalfPageModal
