import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider, Empty } from 'antd'
import ClientBloodTestCategories from '../dataset/categories/clientBloodTest'
import ClientCategory from '../dataset/categories/clientCategory'
import PinchZoomPan from 'react-responsive-pinch-zoom-pan'

// import BodyCompLogo from '../../../asserts/images/body-com-dataset.svg'
// import LifeStyleLogo from '../../../asserts/images/life-style-dataset.svg'

import { clientCategoriesStyle } from './mobile-category.sass'

class MobileCategories extends Component {
  static propTypes = {}

  hasData = (categories) => {
    const {
      dataset,
      type,
      dbField: { metricsField }
    } = this.props
    let result = true
    if (!categories) {
      return false
    }
    if (categories.length === 0) {
      result = false
    } else {
      const [category] = categories
      if (
        category[metricsField] &&
        category[metricsField].length === 0 &&
        ((category.child_categories &&
          category.child_categories.length === 0) ||
          (category.sub_categories && category.sub_categories.length === 0))
      ) {
        result = false
      }
      if (
        type === 'body-comp' &&
        category.name === 'Total Body Composition' &&
        dataset.trend_graph
      ) {
        result = true
      }
      if (type === 'life-style') {
        if (
          category.name === 'Stress & Sleep' &&
          (dataset.stress_summary || dataset.recovery_summary)
        ) {
          result = true
        } else if (
          category.name === 'Physical Activity' &&
          dataset.exercise_summary
        ) {
          result = true
        }
      }
    }
    return result
  }

  renderImage(imageUrl) {
    const imageZoomProp = {
      zoomButtons: false,
      maxScale: 2
    }

    return (
      <div className="image-content">
        <PinchZoomPan {...imageZoomProp}>
          <img src={imageUrl} alt="attachment" />
        </PinchZoomPan>
      </div>
    )
  }

  renderBodyCompContainer(categories) {
    const {
      dbField: { metricsField },
      dataset,
      ageScopes,
      person,
      turnTo
    } = this.props
    const [category] = categories
    return (
      <div className="category-container">
        <div className="root-category">
          {/* <h1>
            <img src={BodyCompLogo} alt="logo" />
          </h1> */}
          {category && (
            <ClientCategory
              category={category}
              {...{ ageScopes, person, metricsField, turnTo }}
            />
          )}
          {category && category.name === 'Total Body Composition' && (
            <div className="root-category">
              <Divider orientation="left">
                Composition Trend: Total Graph
              </Divider>
              {(dataset.trend_graph &&
                this.renderImage(dataset.trend_graph.oss_url)) || (
                <div className="sec-no-data card-list">no data</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderLifeStyleContainer(categories) {
    const {
      dataset,
      dbField: { metricsField },
      ageScopes,
      person,
      turnTo
    } = this.props
    const [category] = categories
    return (
      <div className="category-container">
        <div className="root-category">
          {/* <h1>
            <img src={LifeStyleLogo} alt="logo" />
          </h1> */}
          {category && (
            <ClientCategory
              category={category}
              {...{ ageScopes, person, metricsField, turnTo }}
            />
          )}
          {category && category.name === 'Stress & Sleep' && (
            <div className="attachment-container">
              <Divider orientation="left">Stress Summary</Divider>
              {dataset.stress_summary ? (
                this.renderImage(dataset.stress_summary.oss_url)
              ) : (
                <div className="sec-no-data card-list">no data</div>
              )}
              <Divider orientation="left">Recovery Summary</Divider>
              {dataset.recovery_summary ? (
                this.renderImage(dataset.recovery_summary.oss_url)
              ) : (
                <div className="sec-no-data card-list">no data</div>
              )}
            </div>
          )}
          {category && category.name === 'Physical Activity' && (
            <div className="attachment-container">
              <Divider orientation="left">Exercise Summary</Divider>
              {dataset.exercise_summary ? (
                this.renderImage(dataset.exercise_summary.oss_url)
              ) : (
                <div className="sec-no-data card-list">no data</div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  renderCommonCategories(categories) {
    const {
      turnTo,
      ageScopes,
      person,
      dbField: { metricsField }
    } = this.props
    return (
      <div className="category-container">
        {categories.map((category, index) => (
          <div className="root-category extra" key={index}>
            <ClientCategory
              category={category}
              {...{ ageScopes, person, metricsField, turnTo }}
            />
          </div>
        ))}
      </div>
    )
  }

  renderBloodTestContainer() {
    return <ClientBloodTestCategories {...this.props} />
  }

  render() {
    const { type } = this.props
    let { categories: propCategories, selectCategory } = this.props
    const categories = propCategories.filter(
      (item) =>
        item.name === selectCategory ||
        ((!selectCategory || selectCategory === 'undefined') &&
          item.name === propCategories[0].name)
    )
    let children
    if (!this.hasData(categories)) {
      return <Empty style={{ marginTop: 50 }} />
    }
    switch (type) {
      case 'blood-test':
        children = this.renderBloodTestContainer()
        break
      case 'body-comp':
        children = this.renderBodyCompContainer(categories)
        break
      case 'life-style':
        children = this.renderLifeStyleContainer(categories)
        break
      default:
        children = this.renderCommonCategories(categories)
    }
    return (
      <div className={clientCategoriesStyle}>
        <div
          style={{
            backgroundColor: 'white'
          }}
        >
          <span className="section-title">
            Category
            {/* <img src="" alt="" /> */}
          </span>
          {type === 'body-comp' && type === 'life-style' && (
            <div className="separator" />
          )}
        </div>
        {children}
      </div>
    )
  }
}

export default MobileCategories
