import React, { useState, useEffect } from 'react'
import { Popover, Button, TreeSelect } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import Icon from '@ant-design/icons'
import {
  AddButtonArrow,
  CategoryLeftArrow,
  CategoryRightArrow,
  TreeSwitcherArrow
} from '../icons/trendsIcons'
import { CheckedColor } from './metricsEditor'

const AddButton = styled(Button)`
  font-family: 'Gilroy';
  font-size: 10px;
  width: 103px;
  height: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: ${(props) => (props.selected ? 'none' : '1px solid #E4E7EB')};
  background: ${(props) => (props.selected ? '#EBEDEF' : '#FFF')};
  color: ${(props) => (props.selected ? '#334E68' : '#7B8794')};
  :hover {
    background: ${(props) => (props.selected ? 'darkgray' : '#FFF')};
    color: ${(props) => (props.selected ? '#334E68' : '#40a9ff')};
  }
`
const Container = styled.div`
  background: #ffffff;
  width: 216px;
  height: 166px;
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 10px;
  .main {
    border-bottom: 1px solid #dee2ec;
    padding: 12px;
    height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .metric-container {
      height: 30px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #dee2ec;
      border-radius: 6px;
      padding: 10px;
      color: #4c6072;
      .metric-color-dot {
        width: 8px;
        height: 8px;
        margin-right: 8px;
        display: inline-block;
        border-radius: 4px;
        flex-shrink: 0;
      }
      span {
        margin-top: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .vs-text {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.3px;
      color: #4c6072;
      opacity: 0.5;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #dee2ec;
      border-radius: 6px;
      height: 30px;
      font-size: 10px;
    }
    .ant-select-selector::before {
      width: 8px;
      height: 8px;
      background: ${(props) => props.valueColor || '#1D5FF5'};
      content: '';
      border-radius: 4px;
      margin-top: 10px;
      margin-right: 6px;
    }
  }
  .action-container {
    display: flex;
    padding-right: 18px;
    height: 53px;
    align-items: center;
    justify-content: space-between;
    font-family: Gilroy;
    button {
      font-size: 13px;
    }
  }
`

const DropdownContainer = styled.div`
  font-family: 'Gilroy';
  .category-switch-container {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.3px;
    color: #212121;
    span {
      margin-top: 2px;
    }
  }
  .ant-select-tree-switcher {
    order: 5;
  }
  .ant-select-tree-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    color: #262626;
  }
  .ant-select-tree .ant-select-tree-treenode {
    min-height: 34px;
  }
  .ant-select-tree-switcher-noop {
    width: 0px;
  }
  .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
    transform: rotate(-180deg);
  }
`

export default function AddMetric(props) {
  const { allMetrics, metric, onAdd } = props

  const [value, setValue] = useState(metric.add_metric)
  const [valueColor, setValueColor] = useState()
  const [treeData, setTreeData] = useState([])
  const [categoryIndex, setCategoryIndex] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const metrics = allMetrics[categoryIndex].children
    const convertMetricsToTreeData = (children) => {
      return children.map((child) => {
        if (child.children) {
          return {
            title: child.category,
            value: `${child.category}_ignore`,
            selectable: false,
            children: convertMetricsToTreeData(child.children)
          }
        } else {
          return {
            title: child.name + (child.unit ? ` (${child.unit})` : ''),
            value: child.name + (child.unit ? ` (${child.unit})` : '')
          }
        }
      })
    }
    setTreeData(convertMetricsToTreeData(metrics))
  }, [categoryIndex])

  useEffect(() => {
    if (metric.add_metrics && metric.add_metrics.length === 0) {
      setValue(null)
      setValueColor(null)
    }
  }, [metric.add_metrics])

  const onChange = (newValue) => {
    setValue(newValue)
    setValueColor(CheckedColor[allMetrics[categoryIndex].category])
  }

  const onReset = () => {
    setValue(null)
    setValueColor(null)
    onAdd(null)
    setVisible(false)
  }

  const onSave = () => {
    setVisible(false)

    //Find
    let result
    const recursiveSearch = (children) => {
      return children.find((child) => {
        if (child.children) {
          return recursiveSearch(child.children)
        } else {
          const predicateValue =
            child.name + (child.unit ? ` (${child.unit})` : '')
          if (predicateValue === value) {
            result = child
          }
          return predicateValue === value
        }
      })
    }

    allMetrics.map((e) => e.children).find(recursiveSearch)

    const category = allMetrics[categoryIndex].category
    result.categories = [category]

    onAdd(result)
  }

  const renderContent = () => (
    <Container valueColor={valueColor}>
      <div className="main">
        <div className="metric-container">
          <div
            className="metric-color-dot"
            style={{
              backgroundColor: CheckedColor[metric.categories[0]]
            }}
          ></div>
          <span>{metric.name + (metric.unit ? ` (${metric.unit})` : '')}</span>
        </div>
        <div className="vs-text">vs</div>
        <TreeSelect
          style={{ width: '100%' }}
          value={value}
          dropdownStyle={{
            maxHeight: 400,
            overflow: 'auto',
            paddingTop: '0px',
            zIndex: 9999
          }}
          treeData={treeData}
          placeholder="Click to choose data"
          onChange={onChange}
          dropdownRender={reRenderDropdown}
          switcherIcon={<Icon component={TreeSwitcherArrow} />}
        />
      </div>
      <div className="action-container">
        <Button danger type="text" onClick={onReset}>
          Reset
        </Button>
        <Button
          type="primary"
          style={{
            background: 'rgba(38, 67, 130, 0.1)',
            color: '#264382',
            boxShadow: 'none',
            textShadow: 'none'
          }}
          onClick={onSave}
        >
          Save
        </Button>
      </div>
    </Container>
  )

  const reRenderDropdown = (originNode, props) => {
    return (
      <DropdownContainer>
        <div className="category-switch-container">
          <Button
            type="text"
            disabled={categoryIndex === 0}
            onClick={() => setCategoryIndex(categoryIndex - 1)}
          >
            <CategoryLeftArrow />
          </Button>
          <span>{allMetrics[categoryIndex].category}</span>
          <Button
            type="text"
            disabled={categoryIndex === allMetrics.length - 1}
            onClick={() => setCategoryIndex(categoryIndex + 1)}
          >
            <CategoryRightArrow />
          </Button>
        </div>
        {originNode}
      </DropdownContainer>
    )
  }

  return (
    <Popover
      overlayClassName=""
      overlayStyle={{
        paddingTop: '0px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: '6px'
      }}
      placement="bottomLeft"
      trigger="click"
      content={renderContent()}
      visible={visible}
      onVisibleChange={(newVisible) => setVisible(newVisible)}
    >
      <AddButton
        onClick={() => setVisible(!visible)}
        selected={_.get(metric, ['add_metrics', 'length'], 0) > 0}
      >
        <span>Add</span>
        <Icon component={AddButtonArrow} />
      </AddButton>
    </Popover>
  )
}
