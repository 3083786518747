import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { loadUser } from '../../../utils/storage'
import ProgramDashboard from '../../../components/program/dashboard'

const Container = styled.div`
  padding-right: 50px;
`

class ProgramDetail extends Component {
  static propTypes = {}

  render() {
    const person = loadUser()
    return person ? (
      <Container>
        {/* <h1>Programs</h1> */}
        <ProgramDashboard
          person={person}
          position="clientView"
          {...this.props}
        />
      </Container>
    ) : (
      <div />
    )
  }
}

export default ProgramDetail
