import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Pluralize } from '../../utils/common'
import { userInfoStyle } from './styles/userInfo.sass'
import _ from 'lodash'

class UserInfo extends Component {
  static propTypes = {
    person: PropTypes.object,
    bmi: PropTypes.number
  }

  render() {
    const { person, bmi } = this.props
    const years = person.profile.date_of_birth
      ? moment().diff(person.profile.date_of_birth, 'years')
      : null
    const months = person.profile.client_since
      ? moment().diff(
          moment(person.profile.client_since).format('YYYY-MM-DD'),
          'months'
        )
      : null
    return (
      <div className={userInfoStyle}>
        {person && (
          <>
            <h1>{`${person.profile.nickname || person.first_name} ${
              person.last_name
            }`}</h1>
            <div className="content">
              <div className="info-list">
                <div className="info-item">
                  <div>Age</div>
                  <div>Gender</div>
                  <div>Height</div>
                </div>
                <div className="info-value">
                  <div>
                    {typeof years === 'number' ? years : '--'}{' '}
                    {typeof years === 'number' &&
                      `${Pluralize('years', years || 1)} old`}
                  </div>
                  <div>{person.profile.gender}</div>
                  <div>
                    {person.profile.height_inch || '--'}{' '}
                    {person.profile.height_inch > 0 && 'inches'}
                  </div>
                </div>
              </div>
              <div className="info-list">
                <div className="info-item">
                  <div>Weight</div>
                  <div>BMI</div>
                  <div>Apeiron Life</div>
                </div>
                <div className="info-value">
                  <div>
                    {_.round(person.profile.weight_lbs) || '--'}{' '}
                    {person.profile.weight_lbs && 'lbs'}
                  </div>
                  <div>
                    {typeof bmi === 'number' ? bmi : '--'}{' '}
                    {typeof bmi === 'number' && 'kg/m²'}
                  </div>
                  <div>
                    {typeof months === 'number' ? months : '--'}{' '}
                    {typeof months === 'number' &&
                      Pluralize('months', months || 1)}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

export default UserInfo
