import moment from 'moment'
import Decimal from 'decimal.js'
import { roleLevel, pattern, baseURL } from './constant'
import { clearStorage, loadUser } from './storage'
import pluralize from 'pluralize'
import { logOut } from '../api'
const { URL, DomainRegex } = pattern

export const getBlogUrl = (path) => baseURL + '/recommends/blog/' + path

export const getRole = (roleNumber) => {
  let result
  if (!roleNumber) {
    // default return login user
    const user = loadUser()
    roleNumber = user.role
  }
  for (let role in roleLevel) {
    if (roleNumber >= roleLevel[role]) {
      result = role
    }
  }
  return result
}

export const getAgeTag = (profile = {}, ageScopes) => {
  const age = profile.testDate
    ? moment(profile.testDate).diff(profile.date_of_birth, 'years')
    : moment().diff(profile.date_of_birth, 'years')
  let ageTag
  if (ageScopes) {
    ageTag = Object.keys(ageScopes).find((t) => {
      const [min, max] = ageScopes[t].scope
      return min <= age && age <= max
    })
  }
  return ageTag || 'A'
}

export function logOutAction() {
  logOut()
  clearStorage()
  const { origin } = window.location
  window.location.href = origin + '/sign-in'
}

export const matchRangeByType = (value, range, profile, ageScopes) => {
  const ageTag = getAgeTag(profile, ageScopes)
  if (ageTag) {
    const [min, max, color] = range[`${profile.gender}_age_scope`][ageTag] || []
    return {
      result:
        (min || max) &&
        ((value >= min && value <= max) ||
          (!min && value <= max) ||
          (!max && value >= min)),
      min,
      max,
      color
    }
  } else {
    return {
      result: false
    }
  }

  // const min = range[`${profile.gender}_min`]
  // const max = range[`${profile.gender}_max`]
}

export const getMultiSelectOptions = (ranges, profile, ageScopes) => {
  const ageTag = getAgeTag(profile, ageScopes)
  let options = []
  if (ageTag) {
    for (const range of ranges) {
      // eslint-disable-next-line no-unused-vars
      const [_, option] = range[`${profile.gender}_age_scope`][ageTag] || []
      option && options.push(option)
    }
  }
  return options
}

export const rangeNonlinearPercent = (value, range, gender = 'male') => {
  let total, percent
  if (value) {
    const min = range[`${gender}_min`]
    const max = range[`${gender}_max`]
    if (min && max) {
      total = max - min
      percent = ((value - min) / total) * 100
    } else if (min && !max) {
      total = min + 20 > value - min ? min : value - min
      percent = ((value - min) / total) * 100
    } else if (!min && max) {
      total = max
      percent = (value / total) * 100
    }
  }
  return { total, percent }
}

export const formatInch = (inch) => {
  const _inch = inch % 12
  return {
    height_ft: (inch - _inch) / 12,
    height_in: _inch
  }
}

export const firstUpperCase = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('')

export const parseInch = (ft, inch) => {
  return Number(ft || 0) * 12 + Number(inch || 0)
  // // const _inch = inch % 12

  // if (_inch > 0) {
  //   return `${(inch - _inch) / 12}’${_inch}”`
  // } else {
  //   return `${inch / 12}’`
  // }
}

export const getClientType = () => {
  const userAgentInfo = navigator.userAgent.toLowerCase()
  const Agents = [
    // 'android',
    'iphone',
    // 'symbianos',
    // 'windows phone',
    'ipad',
    'ipod'
  ]

  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      return 'app'
    }
  }

  return 'pc'
}

export const GoalsMethods = {
  getWeekDayByNumber: (day) => {
    switch (day) {
      case '1':
        return 'M'
      case '2':
        return 'T'
      case '3':
        return 'W'
      case '4':
        return 'T'
      case '5':
        return 'F'
      case '6':
        return 'S'
      case '7':
        return 'S'
      default:
        return ''
    }
  },
  matchGoalInfo: (goal) => {
    const { cron_day_month, cron_day_week, repeat_num, auto_range, unit } = goal

    goal.period = 'daily'
    goal.target_goal = 'tracking'
    if (cron_day_month !== '*') {
      goal.period = 'monthly'
    } else {
      if (cron_day_week === '1' && repeat_num > 0) {
        goal.period = 'weekly'
      } else if (cron_day_week !== '*') {
        goal.period = 'On specific days per week'
        goal.days = cron_day_week.split(',')
      }
    }
    let ranges = (auto_range && auto_range.split(',')) || []
    if (ranges.length === 1) {
      goal.target_goal = 'fixed'
      goal.range1 = ranges[0]
    } else if (Number(ranges[0]) && Number(ranges[1])) {
      goal.target_goal = 'range'
      goal.range1 = ranges[0]
      goal.range2 = ranges[1]
    } else if (Number(ranges[0]) && !Number(ranges[1])) {
      goal.target_goal = 'greater'
      goal.range1 = ranges[0]
    } else if (!Number(ranges[0]) && Number(ranges[1])) {
      goal.target_goal = 'lesser'
      goal.range1 = ranges[1]
    }
    switch (goal.target_goal) {
      case 'tracking':
        goal.target_goal_info = 'Tracking only'
        break
      case 'fixed':
        goal.target_goal_info = `Fixed on ${ranges[0]} ${unit || ''}`
        break
      case 'range':
        goal.target_goal_info = `Range ${ranges[0]} to ${ranges[1]} ${unit ||
          ''}`
        break
      case 'greater':
        goal.target_goal_info = `Greater than or equal to ${ranges[0]} ${unit ||
          ''}`
        break
      case 'lesser':
        goal.target_goal_info = `Lesser than or equal to ${ranges[1]} ${unit ||
          ''}`
        break
      default:
        break
    }

    return goal
  },
  getChatData: ({ entries, startDate, endDate, auto_range }) => {
    const data = []
    let count = 1
    data.push({
      x: moment(startDate),
      y: null
    })
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      data.push({
        x: moment(startDate).add(count, 'days'),
        y: null
      })
      count++
    }
    data.push({
      x: moment(endDate),
      y: null
    })
    data.map((item) => {
      const target = entries.find(
        (entry) => item.x.format('YYYY-MM-DD') === entry.created_at // item.x.utcOffset(0).format('YYYY-MM-DD')
      )
      if (target) {
        item.y = null
        if (target.input && target.input !== 'NaN') {
          item.y = Number(target.input)
        } else if (target.input_auto && target.input_auto !== 'NaN') {
          item.y = Number(target.input_auto)
        }
        item.isSuccess = target.achieved
      }
      return item
    })
    const ranges = (auto_range && auto_range.split(',')) || []
    let areaData
    if (ranges.length === 2 && ranges[0] && ranges[1]) {
      areaData = []
      for (const item of data) {
        areaData.push({
          x: item.x,
          y: Number(ranges[0]),
          y0: Number(ranges[1])
        })
      }
    }
    return { data, areaData }
  },
  getAxisyTickValues: (auto_range) => {
    let values
    let max, min, target
    if (auto_range) {
      values = [0]
      const ranges = auto_range.split(',')
      if (ranges.length === 1) {
        target = Number(ranges[0])
        values.push(target)
      } else {
        min = ranges[0] && Number(ranges[0])
        max = ranges[1] && Number(ranges[1])
        min && values.push(min)
        max && values.push(max)
      }
    }

    return { values, max, min, target }
  },
  matchPeriod: () => {},

  formatVal: (v, unit, place) => {
    if (unit === 'Time') {
      if (place === 'line') {
        return `${parseInt(v / 60)}:${v % 60}`
      }
      return moment.unix(v).format('HH:mm')
    } else if (unit === 'Minutes') {
      const m = Math.round(v)
      const h = (Math.floor(m / 60) % 24) + ''
      const mm = (m % 60) + ''
      return `${h}h${mm}m`
    }
    return (y) => (y >= 1000 ? y / 1000 + 'k' : y)
  }
}

export function base64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)
  var byteArrays = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    var byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  var blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const equalJudgment = (obj1, obj2) => {
  const result = Object.keys(obj1).map((item) => obj1[item] === obj2[item])
  return result.filter((item) => !item).length === 0
}

const getYesterday = () => {
  return moment().subtract(1, 'days')
}

export const dateFormat = (date, referYesterday, includeTime) => {
  date = moment(date)
  if (referYesterday) {
    const yesterday = getYesterday()
    if (date.isSame(moment().format('YYYY-MM-DD'), 'day')) {
      return `Today ${includeTime ? ' at ' + date.format('h:mm A') : ''}`
    } else if (date.isSame(yesterday.format('YYYY-MM-DD'), 'day')) {
      return `Yesterday ${includeTime ? ' at ' + date.format('h:mm A') : ''}`
    }
  }

  return (
    date.format('ddd, MMM D, YYYY') +
    (includeTime ? ' at ' + date.format('h:mm A') : '')
  )
}

export const getSum = (inputArr) => {
  if (toString.call(inputArr) !== '[object Array]') return false

  var total = new Decimal(0)
  for (var i = 0; i < inputArr.length; i++) {
    if (!inputArr[i]) {
      continue
    }
    const x = new Decimal(inputArr[i])
    if (x.isNaN()) {
      continue
    }
    // total += Number(inputArr[i])
    total = total.add(x)
  }
  return Math.round(total.toNumber() * 10) / 10
}

const handleMinAndMax = (min, max) => {
  let _min,
    _max,
    step = 1
  if (max > 1000) {
    step = 1000
  } else if (max > 10) {
    step = 10
  } else if (max < 10) {
    step = Math.ceil(max / 2)
  }
  _max = Math.ceil(max / step) * step
  _min = Math.floor(min / step) * step
  if (_max === _min) {
    if (_max % step === 0) {
      //such as _max === 100
      _min = _max - step * 10
    } else {
      _min = _max - step
    }
  }
  let diff = _max - _min
  if (_min <= 0) {
    diff = Math.floor(_max / (2 * step)) * step
  }
  return { diff, _max, _min }
}

export const calculateAxisYData = (min, max) => {
  let { diff, _max } = handleMinAndMax(min, max)
  let originPoint = _max - 2 * diff
  if (originPoint < 0) {
    // y0 !== y1
    originPoint = 0
    diff = Math.round(_max / 20) * 10
    _max = 2 * diff
  }

  return [originPoint, _max - diff, _max, _max + diff]
}

const irregulars = [
  'bpm',
  'ml',
  'BMI',
  'G',
  'ms',
  'Boolean',
  'Unknown',
  '%',
  'cm',
  'ml/kg/min',
  'String',
  'mg/dl',
  'ft'
]
for (let item of irregulars) {
  pluralize.addIrregularRule(item, item)
}

export const Pluralize = pluralize

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const timesFormat = (seconds) => {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.round((seconds - hours * 60 * 60) / 60)
  if (minutes === 60) {
    minutes = 0
    hours += 1
  }
  return { hours, minutes }
}

export const parseUrlSearch = (search) => {
  const searchArr = search.replace('?', '').split('&')
  let searchObj = {}
  for (const item of searchArr) {
    const itemArr = item.split('=')
    searchObj[itemArr[0]] = itemArr[1]
  }
  return searchObj
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const remoteImgToBase64 = (url) =>
  new Promise((resolve, reject) => {
    var request = require('request').defaults({ encoding: null })
    request.get(url, function(error, response, body) {
      if (!error && response.statusCode === 200) {
        const data =
          'data:' +
          response.headers['content-type'] +
          ';base64,' +
          Buffer.from(body).toString('base64')
        resolve(data)
      } else {
        reject(error)
      }
    })
  })

export const imageRenderInfo = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve({ width: this.width, height: this.height })
    }
    img.onerror = (error) => reject(error)
    img.src = url
  })

export const linkify = (inputText) => {
  const allUrl = []
  try {
    let replaceText = inputText.replaceAll(URL, (url) => {
      const index = inputText.indexOf(url)
      const key = Math.random()
        .toString(36)
        .substring(2)
      const value = `<a href=${url} target="_blank">${url}</a>`
      const topStr = inputText.substr(index - 5, 5)

      if (!topStr.includes('src=') && !topStr.includes('ref=')) {
        // no change for img and a tags
        allUrl.push({ key: key, value: value, index })
      } else {
        allUrl.push({ key: key, value: url, index })
      }
      return key
    })

    replaceText = replaceText.replaceAll(
      DomainRegex,
      '<a href="http://$&" >$&</a>'
    )

    allUrl.forEach((obj) => {
      replaceText = replaceText.replace(obj.key, obj.value)
    })

    return replaceText
  } catch (err) {
    console.error(err)
    return inputText
  }
}

export const PreviewAbleType = [
  'pdf',
  'mp4',
  'mov',
  'jpeg',
  'jpg',
  'bmp',
  'png'
]

/*
ColorLuminance("#69c", 0);		// returns "#6699cc"
ColorLuminance("6699CC", 0.2);	// "#7ab8f5" - 20% lighter
ColorLuminance("69C", -0.5);	// "#334d66" - 50% darker
ColorLuminance("000", 1);		// "#000000" - true black cannot be made lighter!
*/

export function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '')
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  lum = lum || 0

  // convert to decimal and change luminosity
  var rgb = '#',
    c,
    i
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16)
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
    rgb += ('00' + c).substr(c.length)
  }

  return rgb
}

export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const amountFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0
})
