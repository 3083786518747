import axios from './axios'

export const createNote = (params) => axios.postJson('/note', params)

export const getNotes = (person_id, queries) => {
  let url = `/note?client_id=${person_id}`
  if (queries.length) {
    url += '&' + queries.join('&')
  }
  return axios.get(url)
}

export const editNote = (id, params) => axios.putJson(`/note/${id}`, params)

export const deleteNots = (id) => axios.delete(`/note/${id}`)
