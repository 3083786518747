import React, { Component } from 'react'
import ClientProfile from '../../components/client/profile'
import { loadUser, saveUser } from '../../utils/storage'
import { getPeopleById } from '../../api/people'
import { userType } from '../../utils/constant'
import { connect } from 'react-redux'

class ProfileOverview extends Component {
  static propTypes = {}

  state = { person: loadUser() }

  initial = async () => {
    const person = loadUser()
    const result = await getPeopleById(person.id)
    saveUser(result.person)
    this.setState({ person: result.person })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    const isNonCore =
      person && person.profile.account_type === userType.Non_Core
    return (
      <div className="profile-contant">
        <ClientProfile {...this.props} person={person} isNonCore={isNonCore} />
      </div>
    )
  }
}

export default connect(({ client }) => ({ client }))(ProfileOverview)
