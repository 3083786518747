import React, { useState, useEffect, useCallback } from 'react'
import { DatePickerBlank } from '../../trends'
import DatePickerByRange from '../../UI/dateGroupPicker/datePickByRange'
import { Divider, Switch } from 'antd'

import styled from 'styled-components'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import CardiometabolicTrendsMetric from './CardiometabolicTrendsMetric'
import PageLoading from '../../UI/status/loading'
import moment from 'moment'
import { TrendsDateRanges } from '../../trends/wrapper'
import { getStatistics } from '../../../api/trends'
import _, { cloneDeep, remove } from 'lodash'
import { useImmerReducer } from 'use-immer'

const colorArr = {
  BloodPanel: {
    primary: '#CF072A',
    barBg: ['#CF4658', '#CF072A']
    // lighter: {}
  }
}

const Container = styled.div`
  margin-top: 30px;
  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 99999;
  }
  .tips {
    list-style: none;
    text-align: right;
    right: 40px;
    position: absolute;
    margin-top: -50px;
    li {
      padding: 0 5px;
      display: inline-block;
      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: gray;
        display: inline-block;
        margin-right: 6px;
        vertical-align: baseline;
      }
      &.blood:before {
        background-color: ${colorArr.BloodPanel.primary};
      }
    }
  }

  .main-content {
    padding: 0 0 20px 0;
    background: #ffffff;
    border-radius: 12px;
    & > * {
      padding: 0 20px;
    }

    .tool-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .date-tips {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        li {
          display: inline-block;
          padding: 10px 8px;
          border: 1px solid #e4e7eb;
          color: #7b8794;
          cursor: pointer;
          &:not(:last-child) {
            border-right: none;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          &.active {
            color: #3e4c59;
            background: #cbd2d9;
          }
          &:first-child {
            border-radius: 6px 0px 0px 6px;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0px;
          }
        }
      }
      .ant-calendar-picker {
        width: 227px;
        input {
          color: #616e7c;
        }
      }
      .divider {
        border-left: 1px solid #e3e7eb;
        height: 40px;
      }
      .show-number-container {
        // border-left: 1px solid #e3e7eb;
        // padding-left: 22px;
        line-height: 40px;
      }
      .chart-type {
        background: #ebecf2;
        border-radius: 4px;
        color: #9aa5b1;
        font-size: 14px;
        padding: 2px;
        span {
          display: inline-block;
          padding: 8px;
          cursor: pointer;
          &.active {
            color: #3e4c59;
            background: #ffffff;
            border-radius: 2px;
          }
        }
      }
    }
    .chart-container {
      position: relative;
      width: 75%;
    }
    .axis-bar {
      display: flex;
      margin-top: 16px;
      justify-content: space-between;
      button {
        background: #dceefb;
        border-radius: 6px;
        color: #0f609b;
      }
      button[disabled] {
        border-color: #d9d9d9;
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
      }
      // .chart-container {
      //   padding: 0 40px 0 10px;
      // }
    }
  }
`

const CardiometabolicTrends = (props) => {
  const {
    person: { id },
    metrics,
    view,
    storeCallback
  } = props

  const [state, dispatch] = useImmerReducer(
    (draft, action) => {
      _.assign(draft, action)
    },
    {
      from: '',
      to: '',
      selectedDate: 0,
      showNumber: true,
      showGraphType: 'line'
    }
  )
  const [loading, setLoading] = useState(false)
  const [mappedMetrics, setMappedMetrics] = useState([])

  const initial = useCallback(async () => {
    setLoading(true)
    const from = moment()
      .subtract(6, 'days')
      .format('YYYY-MM-DD')
    const to = moment().format('YYYY-MM-DD')
    const selectedDate = 0
    const allKeys = metrics.map((item) => item.metric_key).join(',')
    const dateRange = TrendsDateRanges[selectedDate]
    const statistics = await getStatistics({
      personId: id,
      keys: allKeys,
      type: dateRange.type || dateRange.picker,
      from,
      to
    })
    dispatch({
      from,
      to,
      selectedDate
    })
    setMappedMetrics(processMetrics(metrics, statistics))
    setLoading(false)
  }, [])

  const update = async () => {
    setLoading(true)
    const dateRange = TrendsDateRanges[state.selectedDate]
    const mappedMetrics = await fetchData(
      state.from,
      state.to,
      dateRange.type || dateRange.picker
    )
    setMappedMetrics(mappedMetrics)
    setLoading(false)
  }

  useEffect(() => {
    if (state.from) {
      update()
    } else {
      if (props.storedData) {
        dispatch({
          from: props.storedData.from,
          to: props.storedData.to,
          showNumber: props.storedData.show_number,
          showGraphType: props.storedData.show_graph_type,
          selectedDate: TrendsDateRanges.findIndex(
            (range) => range.type === props.storedData.rangeType
          )
        })
        setMappedMetrics(metrics)
      } else {
        initial()
      }
    }
  }, [metrics])

  useEffect(() => {
    if (state.from) {
      storeCallback(state, metrics)
    }
  }, [state, metrics])

  const dateRange = TrendsDateRanges[state.selectedDate || 0]

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return
      }
      const sourceIndex = result.source.index
      const destinationIndex = result.destination.index
      const draftCopy = cloneDeep(mappedMetrics)
      const [removed] = draftCopy.splice(sourceIndex, 1)
      draftCopy.splice(destinationIndex, 0, removed)
      setMappedMetrics(draftCopy)
      storeCallback(state, draftCopy)
    },
    [state, mappedMetrics, setMappedMetrics, storeCallback]
  )

  const onRemove = useCallback(
    (metric) => {
      const draftCopy = cloneDeep(mappedMetrics)
      remove(draftCopy, (item) => {
        return item.metric_key === metric.metric_key
      })
      setMappedMetrics(draftCopy)
      storeCallback(state, draftCopy)
    },
    [state, mappedMetrics, setMappedMetrics, storeCallback]
  )

  const renderMetricContainer = () => {
    return mappedMetrics.map((item, index) =>
      view === 'Preview' ? (
        <CardiometabolicTrendsMetric
          key={index}
          isFirst={index === 0}
          {...{
            view: view,
            showIndex: index,
            showNumber: state.showNumber,
            showGraphType: state.showGraphType,
            dateRange,
            colorArr,
            metric: item
          }}
        />
      ) : (
        <Draggable
          key={item.metric_key}
          draggableId={item.metric_key}
          index={index}
        >
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                background: 'white',
                ...provided.draggableProps.style
              }}
            >
              <CardiometabolicTrendsMetric
                key={index}
                isFirst={index === 0}
                onRemove={onRemove}
                {...{
                  view: view,
                  showIndex: index,
                  draggableProvided: provided,
                  showNumber: state.showNumber,
                  showGraphType: state.showGraphType,
                  dateRange,
                  colorArr,
                  metric: item
                }}
              />
            </div>
          )}
        </Draggable>
      )
    )
  }

  return (
    <Container>
      {loading && (
        <>
          <div className="ant-modal-mask" />
          <div
            className="ant-modal-wrap"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <PageLoading />
          </div>
        </>
      )}
      <div className="main-content">
        {view === 'Draft' && (
          <div className="tool-bar">
            <ul className="date-tips">
              {TrendsDateRanges.map((item, index) => (
                <li
                  key={index}
                  className={`${state.selectedDate === index ? 'active' : ''} ${
                    item.disabled ? 'disabled' : ''
                  }`}
                  onClick={() => !item.disabled && switchDate(index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
            {dateRange.picker === 'blank' && (
              <DatePickerBlank dateRange={dateRange} />
            )}
            {dateRange.name !== 'Lifetime' && dateRange.picker !== 'blank' && (
              <DatePickerByRange
                {...{
                  from: state.from,
                  to: state.to,
                  dateRange,
                  onRangeChange
                }}
              />
            )}
            <Divider type="vertical" className="divider" />
            <div className="show-number-container">
              Show Number{' '}
              <Switch
                size="small"
                checked={state.showNumber}
                onChange={() => {
                  dispatch({
                    showNumber: !state.showNumber
                  })
                }}
              />
            </div>
            <div className="chart-type">
              <span
                className={state.showGraphType === 'line' ? 'active' : ''}
                onClick={() => {
                  dispatch({
                    showGraphType: 'line'
                  })
                }}
              >
                Line Chart
              </span>
              <span
                className={state.showGraphType === 'bar' ? 'active' : ''}
                onClick={() => {
                  dispatch({
                    showGraphType: 'bar'
                  })
                }}
              >
                Bar Chart
              </span>
            </div>
          </div>
        )}

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {renderMetricContainer()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Container>
  )

  async function switchDate(index) {
    const dateRange = TrendsDateRanges[index]
    let { from, to } = state
    setLoading(true)
    if (dateRange.picker === 'blank') {
      const matchResult = dateRange.name.match(/\d+/)
      const days = matchResult ? parseInt(matchResult[0], 10) : null
      to = moment().format('YYYY-MM-DD')
      from = moment()
        .subtract(days - 1, 'days')
        .format('YYYY-MM-DD')
    } else {
      switch (dateRange.name) {
        case 'Week':
          from = moment()
            .startOf('week')
            .format('YYYY-MM-DD')
          to = moment()
            .endOf('week')
            .format('YYYY-MM-DD')
          break
        case 'Bi-Weekly':
          from = moment()
            .subtract(7, 'days')
            .startOf('week')
            .format('YYYY-MM-DD')
          to = moment()
            .endOf('week')
            .format('YYYY-MM-DD')
          break
        case 'Month':
          from = moment()
            .startOf('month')
            .format('YYYY-MM-DD')
          to = moment()
            .endOf('month')
            .format('YYYY-MM-DD')
          break
        case 'Quarterly':
          from = moment()
            .startOf('quarter')
            .format('YYYY-MM-DD')
          to = moment()
            .endOf('quarter')
            .format('YYYY-MM-DD')
          break
        case 'Annual':
        case '365 days':
          from = moment()
            .startOf('year')
            .format('YYYY-MM-DD')
          to = moment()
            .endOf('year')
            .format('YYYY-MM-DD')
          break
        case 'Custom':
          dispatch({
            from,
            to,
            selectedDate: index
          })
          setLoading(false)
          return
        default:
          break
      }
    }

    const mappedMetrics = await fetchData(
      from,
      to,
      dateRange.type || dateRange.picker
    )

    dispatch({
      from,
      to,
      selectedDate: index
    })
    setLoading(false)
    setMappedMetrics(mappedMetrics)
  }

  async function fetchData(from, to, type) {
    const statistics = await getStatistics({
      personId: id,
      keys: metrics.map((item) => item.metric_key).join(','),
      type,
      from,
      to
    })
    return processMetrics(metrics, statistics)
  }

  async function onRangeChange([from, to]) {
    setLoading(true)
    const dateRange = TrendsDateRanges[state.selectedDate]
    const mappedMetrics = await fetchData(
      from,
      to,
      dateRange.type || dateRange.picker
    )
    setLoading(false)
    setMappedMetrics(mappedMetrics)
    dispatch({
      from,
      to
    })
  }

  function processMetrics(metrics, statistics) {
    return metrics.map((item) => {
      let { metric_key, add_metrics } = item
      const { values, detail, aggregate_mode } =
        _.find(statistics, {
          metric_key
        }) || {}
      return _.assign(item, {
        values,
        detail,
        aggregate_mode,
        add_metrics: add_metrics || [],
        categories: ['Blood Metrics']
      })
    })
  }
}

export default CardiometabolicTrends
