import React from 'react'

// interface State {
//   width: number
// }

// interface Props {
//   children: (state: State) => React.ReactNode,
//   style?: Object
// }

class ContainerWidthSizer extends React.Component {
  state = {
    width: 0
  }

  // container: HTMLDivElement

  componentDidMount() {
    this.setState({
      width: this.container.getBoundingClientRect().width
    })
  }

  render() {
    return (
      <div ref={(c) => (this.container = c)} style={this.props.style || {}}>
        {this.props.children(this.state)}
      </div>
    )
  }
}

export default ContainerWidthSizer
