import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import BasicForm from '../../../components/formV4'
import { editClient } from '../../../api/team'
import { pattern } from '../../../utils/constant'
import { saveUser } from '../../../utils/storage'

const emailForm = [
  {
    type: 'input',
    label: 'email',
    dbField: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: pattern.email, message: 'Please input a valid email' }
    ]
  }
]
class ChangeEmail extends Component {
  static propTypes = {
    person: PropTypes.object,
    closeModal: PropTypes.func,
    loginRole: PropTypes.string,
    updateEmailSync: PropTypes.func
  }

  state = {
    valueForm: null,
    loading: false
  }

  validate = async () => {
    const { person, closeModal, loginRole, updateEmailSync } = this.props
    this.setState({ loading: true })
    let params
    try {
      params = await this.formRef.handleSubmit()
      try {
        const result = await editClient(params, person.id)
        if (loginRole !== 'Expert') {
          saveUser(result.team.person)
        }
        updateEmailSync && updateEmailSync(result.team.person)
        this.setState({ loading: false })
        closeModal()
      } catch (err) {
        console.error(err)
        this.setState({ loading: false })
      }
    } catch (err) {
      this.setState({ loading: false })
      console.error(err)
    }
  }

  componentDidMount() {
    const {
      person: { email }
    } = this.props
    this.setState({
      valueForm: { email }
    })
  }

  render() {
    const { closeModal } = this.props
    const { valueForm, loading } = this.state

    return (
      <Modal
        title="Change your email"
        onCancel={closeModal}
        confirmLoading={loading}
        visible={true}
        onOk={() => this.validate()}
      >
        {valueForm && (
          <BasicForm
            formData={emailForm}
            initialValues={valueForm}
            // onChange={this.updateValueForm}
            wrappedComponentRef={(inst) => (this.formRef = inst)}
          />
        )}
      </Modal>
    )
  }
}

export default ChangeEmail
