import React from 'react'
import { Divider } from 'antd'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import BodyCompResult from '../comp/bodyComp'

export default function BodyCompResultSection(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    // handleSave,
    compareData,
    dataList,
    view,
    infinityLoadCount,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status },
    dynamicData: { ui_version, bodyCompResults }
  } = props

  const bodyCompData =
    dataList.find((item) => item.type === 'body_composition') || {}

  const bodyCompCompare =
    compareData.find((item) => item.type === 'body_composition') || {}

  const bodyComp = notes.find((item) => item.pillar === 'body comp')
  const footerNote = notes.find(
    (item) => item.pillar === 'body_comp_footer_note'
  )

  return (
    <>
      {(infinityLoadCount > 2 || status === 1) && (
        <HideWrap
          name="Body Composition"
          isSection={true}
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <section>
            <h1 className="section-title">Body Composition</h1>
            <HideWrap
              name="Body Composition - summary"
              title="summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <div className="sub-title">Summary</div>
              <HealthCard
                {...bodyCompData}
                compare={bodyCompCompare}
                save={save}
                loginRole={loginRole}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                onChangeHandle={onScoreChangeHandle}
                disEdit={status === 1 || view === 'Preview'}
                cancel={cancelEdit}
              />
              <Information
                title="COMMENT/NOTE"
                disMarkDone={true}
                initialValue={bodyComp ? bodyComp.memo : ''}
                status={view === 'Draft' ? 'Draft' : 'Opened'}
                updateCommend={(value, status) =>
                  handleCommendSave(value, 'body comp', status)
                }
                updateEditingCount={updateEditingCount}
              />
            </HideWrap>
            {bodyCompResults && (
              <>
                <Divider />
                <HideWrap
                  name="Body Composition - Result"
                  title="Body Composition"
                  syncStatus={hideSectionHandle}
                  status={hideSection}
                  view={view}
                >
                  <div className="sub-title">Body Composition</div>
                  <BodyCompResult
                    {...{
                      ui_version,
                      bodyCompResults,
                      compareData,
                      dataList,
                      person,
                      ageScopes,
                      notes,
                      handleCommendSave,
                      updateEditingCount
                    }}
                  />
                </HideWrap>{' '}
              </>
            )}
            <Information
              title="COMMENT/NOTE"
              disMarkDone={true}
              initialValue={footerNote ? footerNote.memo : ''}
              status={view === 'Draft' ? 'Draft' : 'Opened'}
              updateCommend={(value, status) =>
                handleCommendSave(value, 'body_comp_footer_note', status)
              }
              updateEditingCount={updateEditingCount}
            />
          </section>
        </HideWrap>
      )}
    </>
  )
}
