import React from 'react'
import NutritionScoreContainer from '../../../components/nutritionScore'
import LockStatus from '../../../components/UI/lockStatus'
import { loadUser } from '../../../utils/storage'
import { getRole } from '../../../utils/common'
import { userType } from '../../../utils/constant'
import { nutritionScoreStyle } from './index.sass'

function NutritionScore(props) {
  const person = loadUser()
  const role = getRole(person.role)
  const isPartner = person.profile.account_type === userType.Partners
  return (
    <div className={`${role} ${nutritionScoreStyle}`}>
      <h1 className="title">Nutrition Signals Report</h1>
      {isPartner ? (
        <>
          <LockStatus
            description={
              <div className="description">
                Your nutrition is unique and personal to you and we’ve
                quantified it! Based on your unique goals, bloodwork,
                <br />
                wearable data, nutrition signals and other assessment results,
                we’ve tailored your nutrition recommendations below.
              </div>
            }
          />
        </>
      ) : (
        <NutritionScoreContainer person={person} loginRole="User" {...props} />
      )}
    </div>
  )
}

export default NutritionScore
