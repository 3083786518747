import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, Button } from 'antd'
import Confirm from '../../UI/confirmModal'
import BasicForm from '../../formV4'
import { addProgram, editProgram, deleteProgram } from '../../../api/program'
import style from '../style/programEditor.module.sass'

const getFormData = (valueForm) => [
  {
    type: 'input',
    label: 'name',
    dbField: 'name',
    // maxLength: 24,
    rules: [
      { required: true, message: 'This field is required' },
      { max: 24, message: 'Limit 24 characters' }
    ]
  },
  {
    type: 'date',
    label: 'from date',
    dbField: 'from',
    format: 'MMM D, YYYY',
    extraAttr: {
      disabledDate: (currentDate) =>
        valueForm.to
          ? moment(valueForm.to).isBefore(currentDate) ||
            currentDate.format('dd') !== 'Mo'
          : currentDate.format('dd') !== 'Mo'
    },
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'date',
    label: 'to date',
    dbField: 'to',
    format: 'MMM D, YYYY',
    extraAttr: {
      disabledDate: (currentDate) =>
        valueForm.from
          ? moment(valueForm.from).isAfter(currentDate) ||
            currentDate.format('dd') !== 'Su'
          : currentDate.format('dd') !== 'Su'
    },
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'textarea',
    label: 'description',
    dbField: 'description',
    rules: [{ required: true, message: 'This field is required' }],
    extraAttr: {
      autoSize: { minRows: 8 }
    }
  }
]

class ProgramEditor extends Component {
  static propTypes = {
    personId: PropTypes.number,
    confirmCallback: PropTypes.func,
    program: PropTypes.object
  }

  state = { valueForm: null, isSaving: false, showConfirm: false }

  handleCancel = () => this.props.cancel()

  handleOk = async () => {
    const {
      personId,
      program,
      confirmCallback,
      forkProgram,
      position
    } = this.props

    try {
      const params = await this.basicFormRef.handleSubmit()
      params.from = moment(params.from).format('YYYY-MM-DD')
      params.to = moment(params.to).format('YYYY-MM-DD')
      this.setState({ isSaving: true })
      const querry = {}
      if (forkProgram) {
        querry.fork_id = forkProgram.id
        querry.status = forkProgram.status === 1
      }
      if (position && position === 'overview') {
        params.auto_publish = true
      }
      if (program) {
        await editProgram(params, personId, program.id)
      } else {
        await addProgram(params, personId, querry)
      }
      confirmCallback && confirmCallback()
    } catch (err) {
      console.error(err)
      this.setState({ isSaving: false })
    }
  }

  handleDelete = async () => {
    const { deleteCallback, program } = this.props
    await deleteProgram(program.id)
    deleteCallback && deleteCallback()
  }

  updateFormsData = (changeValue, allValues) => {
    this.setState({ valueForm: allValues })
  }

  componentDidMount() {
    const { program, forkProgram } = this.props

    if (program || forkProgram) {
      const { name, from, to, description } = program || forkProgram
      this.setState({
        valueForm: {
          name,
          from: program ? moment(from).format('YYYY-MM-DD') : null,
          to: program ? moment(to).format('YYYY-MM-DD') : null,
          description
        }
      })
    } else {
      this.setState({ valueForm: {} })
    }
  }

  render() {
    const { valueForm, isSaving, showConfirm } = this.state
    const propsProgram = this.props.program || {}
    return (
      <Modal
        open={true}
        width={890}
        bodyStyle={{ padding: '36px 45px' }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
      >
        <h1 className={style.title}>
          {propsProgram.id ? 'Edit' : 'Add'} Program
        </h1>
        {valueForm && (
          <BasicForm
            formData={getFormData(valueForm)}
            initialValues={valueForm}
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            onChange={this.updateFormsData}
          />
        )}
        <footer className={style.footer}>
          <div>
            {propsProgram.id && (
              <Button
                danger
                ghost
                onClick={() => this.setState({ showConfirm: true })}
              >
                DELETE PROGRAM
              </Button>
            )}
          </div>
          <div>
            <Button ghost onClick={this.handleCancel}>
              CANCEL
            </Button>
            <Button type="primary" onClick={this.handleOk} loading={isSaving}>
              CONFIRM
            </Button>
          </div>
        </footer>
        {showConfirm && (
          <Confirm
            {...{
              onCancel: () => this.setState({ showConfirm: false }),
              description: 'Do you want to delete the program permanently?',
              title: 'Delete program confirmation',
              onConfirm: this.handleDelete,
              confirmText: 'DELETE',
              confirmStyle: { background: '#FF0000' },
              disabled: isSaving,
              loading: isSaving
            }}
          />
        )}
      </Modal>
    )
  }
}

export default ProgramEditor
