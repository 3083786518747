import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory'

const strokeDasharray = '6, 3'
const stroke = 'rgba(0, 0, 0, 0.1)'
const axis = {
  strokeDasharray,
  stroke
}
const tickLabels = {
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fill: '#C0C7D1'
}
const axiosXNum = 28

class ActivityTrend extends Component {
  static propTypes = {
    detail: PropTypes.object,
    id: PropTypes.any
  }

  state = {}

  getZoneColor = () => {
    const heartRateZoneSource =
      (this.props.detail && this.props.detail.heartRateZoneSource) || []
    const index = heartRateZoneSource.findIndex((item) => item > 0)
    const colors = [
      'rgba(255,184,0,0.6)',
      'rgba(255,149,51,.7)',
      'rgba(255,107,0,.8)',
      'rgba(255,88,51,.9)',
      '#FF3D00'
    ]
    this.setState({ zoneColor: colors[index] })
  }

  getData = (detail) => {
    try {
      detail = detail || this.props.detail
      const { samples } = detail
      let data = []
      if (samples.length === 0) {
        data.push({ x: 0, y: 0 })
      } else {
        const startTime = samples[0][0]
        data = samples.map(([time, rate]) => ({
          x: time - startTime,
          y: rate
        }))
      }
      let axiosY = data.map((item) => item.y)
      const _min = Math.min(...axiosY),
        _max = Math.max(...axiosY)
      if (_min === _max) {
        this.getZoneColor(_max)
      }
      const yMin = Math.floor(_min / 10) * 10
      let yMax = Math.ceil(_max / 10) * 10
      if (yMax === yMin) {
        yMax += 10
      }
      axiosY = [yMin, (yMin + yMax) / 2, yMax]
      const axiosX = []
      const xMax = Math.max(...data.map((item) => item.x))
      let xStemp = xMax / axiosXNum
      xStemp = Math.ceil(Math.max(xStemp, 1))
      for (let i = 0; i < axiosXNum; i++) {
        axiosX.push(i * xStemp)
      }
      this.setState({ data, axiosY, axiosX, xMax, xStemp })
    } catch (err) {
      console.info('Data volume overflow, can not calculate')
      console.log(err)
    }
  }

  xAxisFormat = (x) => {
    const { xStemp, xMax } = this.state
    let item, value
    if (xMax < 60 * 5) {
      // less then 5 min
      value = Math.round(x)
      if (x < 60) {
        value = value < 10 ? '0' + value : value
        item = `0:${value}`
      } else {
        const sec = value % 60
        item = `${Math.floor(value / 60)}: ${sec < 10 ? '0' + sec : sec}`
      }
    } else if (xMax < 60 * 60) {
      // less then 1 hour and more then 5 min
      value = Math.round(x / 60)
      value = value < 10 ? '0' + value : value
      item = `0:${value}`
    } else {
      // more then 1 hour
      value = Math.round((x % (60 * 60)) / 60)
      value = value < 10 ? '0' + value : value
      item = `${parseInt(x / (60 * 60))}:${value}`
    }
    if ((x / xStemp) % 5 === 0 && x !== 0) {
      return item
    }
    return null
  }

  xGridStroken = (x) => {
    const { xStemp } = this.state
    if ((x / xStemp) % 5 === 0) {
      return 'rgba(0,0,0,.5)'
    } else {
      return stroke
    }
  }

  componentDidMount() {
    const { id } = this.props
    this.getData()
    this.setState({ id })
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props
    if (nextProps.id !== prevProps.id) {
      this.getData(nextProps.detail)
      this.setState({ id: nextProps.id })
    }
  }

  render() {
    const { data, axiosY, axiosX, zoneColor } = this.state //this.getData()
    return (
      <div className="activity-trend">
        {data && (
          <VictoryChart padding={{ top: 0, left: 50, right: 50, bottom: 0 }}>
            <VictoryAxis
              style={{
                axis,
                grid: {
                  stroke: this.xGridStroken,
                  strokeDasharray
                },
                tickLabels
              }}
              tickValues={axiosX}
              tickFormat={this.xAxisFormat}
            />
            <VictoryAxis
              dependentAxis
              tickValues={axiosY}
              style={{
                axis,
                grid: {
                  stroke,
                  strokeDasharray
                },
                tickLabels
              }}
            />
            <VictoryLine
              // samples={25}
              gradientAttributes="userSpaceOnUse"
              style={{
                data: {
                  stroke: zoneColor ? zoneColor : 'url(#linear)'
                }
                // parent: { border: '1px solid #ccc' }
              }}
              // data={data}
              data={data.map((item) => item.y && item).filter((item) => item)}
            />
          </VictoryChart>
        )}
        <svg>
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#FF3D00" />
              <stop offset="100%" stopColor="#FFB800" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    )
  }
}

export default ActivityTrend
