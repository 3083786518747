import React from 'react'
// import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { detailStyle } from '../../styles/detailPopup.sass'

export default function PopUpModal(props) {
  return (
    <Modal
      width={850}
      visible={true}
      footer={null}
      className={detailStyle}
      onCancel={props.onCancel}
    >
      {props.children}
    </Modal>
  )
}
