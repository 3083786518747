import React from 'react'
import { Divider, Button, Row, Col } from 'antd' // Progress
import { CollapsePlusIcon } from '../../icons'
import ProgramCard from './programCard'
import { getRole } from '../../../utils/common'
import Empty from '../../UI/status/empty'
import EmptyImage from '../../../asserts/images/empty-status.png'

export default function NutritionProgram(props) {
  const {
    position,
    addItem,
    weekRange,
    nutrition,
    setGlobalState,
    program: { status }
    // extra
  } = props
  const loginRole = getRole()
  const showList =
    loginRole === 'Expert' || loginRole === 'InternalAdmin'
      ? nutrition
      : nutrition.filter(
          (item) => item.micro_goal.active || item.micro_goal.published_at
        )
  return (
    <>
      <Divider />
      <div className="section-top">
        <div className="title nutrition">Nutrition Action Items</div>
        <div className="right-opr">
          {/* {showList.length > 0 && (
            <div className="program-container">
              <span>
                <Progress
                  type="circle"
                  strokeWidth={18}
                  strokeColor="#7FB800"
                  percent={(extra && extra.nutrition_program_achieved) || 0}
                  width={24}
                  format={() => null}
                />
                <strong>
                  {extra && (extra.nutrition_program_achieved || 0) + '%'}
                </strong>
                Completed rate
              </span>
            </div>
          )} */}
          {position !== 'clientView' && (
            <Button type="primary" onClick={() => addItem('nutrition')}>
              <CollapsePlusIcon />
              ACTION ITEM
            </Button>
          )}
        </div>
      </div>

      {showList.length > 0 ? (
        <Row type="flex" align="top" gutter={16}>
          {showList.map((item, index) => (
            <Col span={8} key={index}>
              <ProgramCard
                goal={item}
                {...{
                  ...weekRange,
                  setGlobalState,
                  isProgramActive: status === 1
                }}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Empty
            message="There is no nutrition action items yet."
            emptyImage={EmptyImage}
          />
          {position !== 'clientView' && (
            <Button type="primary" onClick={() => addItem('nutrition')}>
              ACTION ITEM
            </Button>
          )}
        </div>
      )}
    </>
  )
}
