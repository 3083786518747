import React from 'react'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import { LogoutIcon } from '../../../components/icons/onboarding'

const Header = ({ history, logOut }) => {
            return <div className="header">
                    <img src={phsLogo} alt="logo" />
                    <div className="log-out" onClick={() => logOut(history)}>
                    <LogoutIcon />
                    LOG OUT
                    </div>
                </div>
}

export default Header
