import React from 'react'
import SocketIo, { clearEmailEditStatus } from '../../../server/socket.io'
import { getRole } from '../../../utils/common'
import { loadUser } from '../../../utils/storage'
import ConfirmPopUp from '../../UI/extraInformation/confirm'
// import { notification } from 'antd' //Button

export default (WrappedComponent) =>
  class SocketWrapper extends React.Component {
    state = {
      connecting: true,
      disConnect: null,
      hasReceivedLocked: false,
      status: 'locked', //'locked', 'locked_self'
      emailEditor: null,
      accessed: false,
      confirmPopUpVisible: false,
      loginRole: getRole()
    }

    disConnect = () => this.socket && this.socket.socket.close()

    initial = async () => {
      const { id } = this.props
      const { loginRole } = this.state
      if (id === 'new') return
      const loginUser = loadUser()
      const params = { id: Number(id) }

      if (loginRole === 'Expert') {
        this.socket = new SocketIo({
          emailId: id,
          onConnecting: (socket) => {
            socket.emit('apply_edit_email', params)
            this.setState({ connecting: false, disConnect: null })
          },
          disConnect: (reason) => {
            this.props.lostConnect()
            this.setState({
              disConnect: reason,
              accessed: false,
              status: 'locked'
            })
            // notification.error({
            //   message: 'Lost connect',
            //   description: reason,
            //   duration: 15
            // })
          },
          onData: (data) => {
            console.log('on data', data)
          },
          sync: async (data) => {
            if (data.id === params.id && this.state.status !== 'open') {
              await this.props.syncData()
            }
          },
          locked: (data) => {
            const {
              person: { id, socket_id }
            } = data
            let { status, emailEditor, hasReceivedLocked } = this.state
            if (this.socket.socket.id === socket_id) {
              if (hasReceivedLocked) {
                status = 'open'
              }
            } else {
              if (loginUser.id === id) {
                status = 'locked_by_self'
              } else {
                status = 'locked'
              }
            }
            const state = {
              status,
              hasReceivedLocked: true,
              accessed: false
            }
            if (
              emailEditor &&
              emailEditor.id !== data.person.id &&
              loginUser.id === id &&
              status === 'open'
            ) {
              state.accessed = true
            }
            this.setState({
              ...state,
              emailEditor: data.person
            })
          },
          unlocked: (data, socket) => {
            if (data.id === params.id || data.emailId === params.id) {
              socket.emit('apply_edit_email', params)
            }
          },
          accessed: async (data) => {
            // await this.props.syncData()
            this.setState({ status: 'open' })
          }
        })
      } else {
        this.socket = null
      }

      console.log('initial===, email socket io', this.socket)
    }

    forceEdit = () => this.setState({ confirmPopUpVisible: true })

    forceEditConfirm = async () => {
      const { id } = this.props
      await this.props.syncData()
      await clearEmailEditStatus(id)
      // this.socket.emit('apply_edit_email', { id: Number(id) })
      this.setState({ confirmPopUpVisible: false })
    }

    componentDidMount() {
      this.initial()
    }

    componentDidUpdate(prevProps) {
      const { id } = this.props
      if (id !== prevProps.id && prevProps.id === 'new') {
        this.initial()
      }
    }

    componentWillUnmount() {
      this.socket && this.socket.socket.close()
    }

    render() {
      const { loginRole } = this.state
      const childProps = {
        socket: loginRole === 'Expert' ? this.state : null,
        forceEdit: this.forceEdit
      }

      return (
        <>
          {/* <Button onClick={this.disConnect} type="primary">
            dis connect
          </Button> */}
          <ConfirmPopUp
            {...{
              title: 'Request edit permission confirmation',
              content:
                'Unsaved data may be lost! Do you want to get permission on this page?',
              okText: 'Confirm',
              handleCancel: () => this.setState({ confirmPopUpVisible: false }),
              handleOk: this.forceEditConfirm
            }}
            visible={this.state.confirmPopUpVisible}
          />
          <WrappedComponent {...childProps} {...this.props} />
        </>
      )
    }
  }
