import React, { Component } from 'react'
import {
  getClientList,
  getClientDetail,
  getProgram,
  getProgramDetail
} from '../../../models/breadcrumb.model'
import ProgramDashboard from '../../../components/program/dashboard'

class ProgramDetail extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const {
      getPerson,
      setClientPageTitle,
      setClientBreadcrumb
      // match: { params }
    } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }

    setClientPageTitle && setClientPageTitle(null)
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getProgram(person)
        // ,
        // getProgramDetail(person, params.id)
      ])
  }

  setBreadcrums = (programTitle) => {
    const {
      setClientBreadcrumb,
      match: { params }
    } = this.props
    let { person } = this.state
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getProgram(person),
        getProgramDetail(person, params.id, true, programTitle)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  routerWillLeave(nextLocation) {
    console.log('routerWillLeave nextLocation', nextLocation)
    return false
  }

  render() {
    const { person } = this.state
    return person ? (
      <ProgramDashboard
        setBreadcrums={this.setBreadcrums}
        person={person}
        {...this.props}
      />
    ) : (
      <div />
    )
  }
}

export default ProgramDetail
