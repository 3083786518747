import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, message, Modal, Spin, Typography, Empty } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { UploadIcon } from '../../../components/icons'
import UploadModal from './uploadModal'
import { getFiles, deleteFile } from '../../../api/file'
import { loadUser } from '../../../utils/storage'
import filesize from '../../../utils/filesize'
import getFileIcon from '../../../models/fileIcon.model'
import { fileStyle } from '../styles/file.sass'

// import FileMask from '../../../asserts/icon/client/Mask.svg'
import { ViewCircleIcon } from '../../../components/icons/weeklySummary'
import { PreviewAbleType } from '../../../utils/common'
import _ from 'lodash'
import IconDel from '../../../asserts/icon/icon-del.svg'
import IconDownload from '../../../asserts/icon/icon-download.svg'

const confirm = Modal.confirm
const { Paragraph } = Typography

const defaultDataSize = 5

class FileManager extends Component {
  static propTypes = {
    loginRole: PropTypes.string,
    person: PropTypes.object
  }

  state = {
    files: [],
    loading: true,
    modalVisible: false,
    page: 0,
    scrollDataLength: 1
  }

  getFiles = async (size, page = 0) => {
    const { person, hash } = this.props
    if (person) {
      const personId = person.id
      const result = await getFiles(
        hash,
        'list',
        personId,
        page,
        size,
        'shown_in_files'
        // 'profile,note'
      )
      return result
    }
    return {}
  }

  initial = async () => {
    const { count, files } = await this.getFiles(defaultDataSize)
    this.setState({ count, files, loading: false })
  }

  loadMore = async () => {
    let { page, files, scrollDataLength } = this.state

    const result = await this.getFiles(defaultDataSize, page + 1)
    this.setState({
      page: page + 1,
      files: files.concat(result.files),
      count: result.count,
      scrollDataLength: scrollDataLength + 1
    })
  }

  addFileSync = async () => {
    const { page } = this.state
    const result = await this.getFiles((page + 1) * defaultDataSize)
    this.setState({ ...result })
  }

  delFiles = async (id) => {
    const { person } = this.props
    const { page } = this.state
    try {
      await deleteFile(id, person.id)
      const result = await this.getFiles((page + 1) * defaultDataSize)
      this.setState({ ...result })
    } catch (err) {
      console.log(err)
      message(err.message)
    }
  }

  componentDidMount() {
    this.initial()
  }

  getDownLoadICon = (attachment) => {
    const fileType = attachment.oss_name.split('.').pop()
    if (PreviewAbleType.includes(fileType)) {
      return <ViewCircleIcon style={{ fontSize: 40, color: '#75879D' }} />
    } else {
      return <img src={IconDownload} alt="download" />
    }
  }

  renderFileItem(file, index) {
    const { loginRole, person } = this.props
    const name = file.attachment.name || file.attachment.oss_name
    const fileType = name.split('.').pop()
    const isOwnFile = Number(file.uploaded_by) === loadUser().id
    const canBeDelete =
      (loginRole !== 'Service_Provider' &&
        (loginRole === 'Expert' || file.uploaded_by === person.id)) ||
      (loginRole === 'Service_Provider' && isOwnFile)
    const size = file.size || file.attachment.size
    return (
      <div className="file-container" key={index}>
        <div className="file-info">
          <div className="name">
            <img
              src={getFileIcon(
                file.attachment.name || file.attachment.oss_name
              )}
              alt="file"
            />
            <div>
              <Paragraph ellipsis>
                {file.attachment.title ||
                  file.attachment.name ||
                  file.attachment.oss_name}
              </Paragraph>
              <span className="uppercase">{fileType}</span> •{' '}
              {size ? filesize(Number(size), { round: 1 }) : '--'}
              {/* <div>
                {file.attachment.description && (
                  <pre className="file-description">
                    {file.attachment.description}
                  </pre>
                )}
              </div> */}
            </div>
          </div>

          <div className="left-border">
            <span>UPLOAD DATE</span>
            <br />
            {moment(file.created_at || file.attachment.created_at).format(
              'MMM DD,YYYY'
            )}
          </div>

          <div className="left-border">
            <span>UPLOAD BY</span>
            <br />
            {file.created_by
              ? isOwnFile
                ? 'You'
                : file.created_by
              : file.attachment.uploaded_by}
          </div>
          <div className="opr">
            <a
              href={file.attachment.oss_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.getDownLoadICon(file.attachment)}
            </a>
            {canBeDelete && (
              <img
                className="phs-pointer"
                onClick={() => {
                  const deleteFile = this.delFiles
                  confirm({
                    title: 'Do you want to delete this file?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteFile(file.id, index)
                    }
                  })
                }}
                src={IconDel}
                alt="del"
              />
            )}
          </div>
        </div>

        {/* {file.attachment.title && (
          <div className="file-title">{file.attachment.title}</div>
        )} */}

        <pre
          className="file-description"
          style={{ paddingTop: file.attachment.description ? 10 : 0 }}
        >
          {file.attachment.description}
        </pre>
      </div>
    )
  }

  render() {
    const {
      loading,
      submitting,
      modalVisible,
      count,
      scrollDataLength
    } = this.state
    let { files } = this.state
    const { person, insuranceFiles, hideTitle } = this.props
    if (insuranceFiles) {
      files = _.concat(
        insuranceFiles.map((e) => {
          return { attachment: e }
        }),
        files
      )
    }
    return (
      <div className={fileStyle}>
        <div className="title">
          <span>{!hideTitle && <>Files Upload</>}</span>
          <Button
            type="primary upload"
            onClick={() => this.setState({ modalVisible: true })}
            disabled={loading || submitting}
          >
            <UploadIcon />
            Upload
          </Button>
        </div>

        <Spin spinning={loading}>
          {files.length > 0 ? (
            <InfiniteScroll
              dataLength={scrollDataLength}
              next={this.loadMore}
              hasMore={count > files.length}
              loader={
                <div className="load-more">
                  <LoadingOutlined style={{ marginRight: 15 }} />
                  Loading more...
                </div>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {files.map((file, index) => this.renderFileItem(file, index))}
            </InfiniteScroll>
          ) : (
            <div className="empty-container">
              <Empty />
            </div>
          )}
        </Spin>

        {modalVisible && (
          <UploadModal
            personId={person.id}
            close={() => this.setState({ modalVisible: false })}
            addFileSync={this.addFileSync}
          />
        )}
      </div>
    )
  }
}

export default FileManager
