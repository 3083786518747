import React, { useEffect, useState, useCallback } from 'react'
import Icon, {
  SearchOutlined,
  EditFilled,
  DeleteFilled
} from '@ant-design/icons'
import { Modal, Tree, Button, Input, Row, Col, Select, message } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'
import {
  TreeSwitcherArrow,
  ApplyIcon,
  SaveProfileIcon,
  WarningIcon
} from '../icons/trendsIcons'
import BasicForm from '../formV4'
import Confirm from '../UI/confirmModal'
import { createTemplate, editTemplate, deleteTemplate } from '../../api/trends'
import { loadUser } from '../../utils/storage'

export const CheckedColor = {
  Sleep: '#5c00d1',
  Exercise: '#FD8F3E',
  Nutrition: '#7BAD2D',
  Healthspan: '#1D5FF5',
  'Blood Metrics': '#1D5FF5'
}

const Container = styled.div`
  .ant-col-5 {
    max-width: 20%;
    border-right: 1px solid #e3e7eb;
    padding-right: 4px !important;
    :last-child {
      border-right: none;
    }
    .ant-tree-title {
      font-size: 12px;
      color: #616e7c;
      font-family: Gilroy;
      font-weight: 500;
      word-break: break-word;
    }
  }
`
const Header = styled.div`
  height: 86px;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #7b8794;
  padding: 32px 18px 24px 24px;
  border-bottom: 1px solid #e3e7eb;
  .dropdown {
    color: #4c6072;
    padding: 10px 0;
    div {
      display: flex;
      justify-content: space-between;
      .anticon {
        color: #000000;
        opacity: 0.2;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
`
const Footer = styled.div`
  height: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 23px;
  button {
    font-family: 'Gilroy';
    :last-child {
      width: 132px;
      height: 34px;
      font-weight: 600;
    }
  }
`
const StyledInput = styled(Input)`
  height: 30px;
  width: 408px;
  margin-left: 57px;
`
const Body = styled.div`
  padding: 8px 15px 8px 19px;
  border-bottom: 1px solid #e3e7eb;
`
const TreeContainer = styled.div`
  max-height: 365px;
  min-height: 350px;
  margin-top: 10px;
  .ant-tree {
    max-height: 310px;
    overflow: auto;
    margin-top: 12px;
    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #afafaf;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #5c626a;
    }
    ::-webkit-scrollbar-corner {
      background: #179a16;
    }
    .ant-tree-indent {
      margin-left: -24px;
    }
    .ant-tree-list-holder-inner .ant-tree-treenode:first-child {
      position: sticky;
      top: 0;
      background: #ffffff;
      z-index: 9;
      .ant-tree-indent {
        margin-left: 0;
      }
      .ant-tree-switcher {
        display: none;
      }
    }
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${(props) => props.inputColor || '#5c00d1'};
    border-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .title,
  .top-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #616e7c;
  }
  .top-title {
    background: #ffffff;
    text-wrap: nowrap;
  }
  .ant-tree-switcher {
    order: 5;
  }
  .ant-tree-switcher-noop {
    width: 0px;
  }
  .ant-tree .ant-tree-treenode {
    width: 100%;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    flex-grow: 1;
  }
  .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(-180deg);
  }
`

export default function MetricsEditor(props) {
  const {
    allMetrics,
    onEdit,
    onCancel,
    metricGroup,
    activeGroup,
    changeName,
    deleteCallback
  } = props

  const [selectGroup, setSelectGroup] = useState(activeGroup)
  const [selectedMetrics, setSelectedMetrics] = useState(
    _.cloneDeep(metricGroup[selectGroup].display_metrics)
  )
  const [filteredMetrics, setFilteredMetrics] = useState(allMetrics)
  const [searchText, setSearchText] = useState('')
  const [expandedKeys, setExpandedKeys] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteSubmitting, setDeleteSubmitting] = useState(false)

  const loginUser = loadUser()

  const debounceTextChanged = useCallback(
    _.debounce((value) => {
      setSearchText(value.toLowerCase())
    }, 700),
    []
  )

  function searchTextChanged(event) {
    debounceTextChanged(event.target.value)
  }

  useEffect(() => {
    setSelectedMetrics(_.cloneDeep(metricGroup[selectGroup].display_metrics))
  }, [selectGroup])

  useEffect(() => {
    let keys = []
    const filterChildren = (children, pCategory) => {
      return children
        .map((child) => {
          if (pCategory) {
            keys.push(`${pCategory}_ignore`)
          }
          if (child.children) {
            if (child.category.toLowerCase().includes(searchText)) {
              return child
            } else {
              const result = filterChildren(child.children, child.category)
              return result.length > 0
                ? {
                    category: child.category,
                    children: result
                  }
                : null
            }
          } else {
            return child.name.toLowerCase().includes(searchText) ? child : null
          }
        })
        .filter((e) => e)
    }
    setFilteredMetrics(
      allMetrics.map((metric) => {
        return {
          category: metric.category,
          children: filterChildren(metric.children)
        }
      })
    )
    setExpandedKeys(keys)
  }, [searchText])

  return (
    <>
      <Modal
        visible={true}
        footer={null}
        onCancel={props.onCancel}
        width={900}
        height={541}
        zIndex={9999}
        bodyStyle={{
          padding: '0px 9px'
        }}
      >
        <Container>
          <Header>
            <DropDown
              loginUser={loginUser}
              changeName={changeName}
              metricGroup={metricGroup}
              selectGroup={selectGroup}
              onChange={(index) => setSelectGroup(index)}
            />
            <StyledInput
              placeholder="Search"
              allowClear
              onChange={searchTextChanged}
              prefix={
                <SearchOutlined
                  style={{
                    color: '#A6B2CB'
                  }}
                />
              }
            />
          </Header>
          <Body>
            <Row gutter={24}>
              {filteredMetrics.map((e, index) => renderMetrics(e, index))}
            </Row>
          </Body>
          <Footer>
            <div>
              <Button
                type="primary"
                ghost
                style={{
                  borderWidth: 2,
                  width: 160
                }}
                onClick={() => changeName({ display_metrics: selectedMetrics })}
              >
                <SaveProfileIcon />
                Save Profile As
              </Button>
              {metricGroup.length > 1 &&
                metricGroup[selectGroup].created_by === loginUser.id &&
                !metricGroup[selectGroup].show_to_client && (
                  <Button
                    type="primary"
                    ghost
                    style={{
                      border: 'none',
                      boxShadow: 'none'
                    }}
                    onClick={() => setIsDeleting(true)}
                  >
                    <DeleteFilled /> Delete View
                  </Button>
                )}
            </div>
            <div>
              <Button
                type="primary"
                ghost
                style={{
                  border: 'none',
                  boxShadow: 'none'
                }}
                onClick={() => setSelectedMetrics([])}
              >
                Clear all
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  onEdit(selectedMetrics, selectGroup)
                  onCancel()
                }}
                disabled={
                  metricGroup[selectGroup] &&
                  metricGroup[selectGroup].show_to_client
                }
              >
                <ApplyIcon />
                Apply
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
      {isDeleting && (
        <Confirm
          zIndex={10000}
          description={
            <div
              style={{
                color: '#262626',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <WarningIcon /> Do you want to delete trend:{' '}
              {metricGroup[selectGroup].name}?
            </div>
          }
          footer={
            <>
              <Button
                type="text"
                style={{ marginRight: 10, height: 34, color: '#60656D' }}
                onClick={() => setIsDeleting(false)}
                disabled={deleteSubmitting}
              >
                Cancel
              </Button>
              <Button
                danger
                style={{ height: 34 }}
                onClick={submitDelete}
                loading={deleteSubmitting}
              >
                Delete
              </Button>
            </>
          }
        />
      )}
    </>
  )

  async function submitDelete() {
    setDeleteSubmitting(true)
    const group = metricGroup[selectGroup]
    await deleteTemplate(group.id)
    message.success('Metrics profile has been deleted!')
    deleteCallback(selectGroup, group)
  }

  function renderTreeNode(children, category, length) {
    const result = children.map((child) => {
      if (child.children) {
        return {
          title: child.category,
          key: `${child.category}_ignore`,
          children: renderTreeNode(child.children)
        }
      } else {
        return {
          title: child.name,
          key: child.metric_key
        }
      }
    })

    return category
      ? [
          {
            title: `${category}${length > 0 ? ` (${length})` : ''}`,
            key: category,
            titleClassName: 'top-title',
            children: result
          }
        ]
      : result
  }

  function renderMetrics(metric, index) {
    const curCategorySelectedMetrics = selectedMetrics.filter(
      (e) => _.head(e.categories) === metric.category
    )
    return (
      <Col className="gutter-row" span={5} key={index}>
        <TreeContainer inputColor={CheckedColor[metric.category]}>
          <Tree
            checkable
            switcherIcon={<Icon component={TreeSwitcherArrow} />}
            treeData={renderTreeNode(
              metric.children,
              metric.category,
              curCategorySelectedMetrics.length
            )}
            selectable={false}
            onCheck={(_, e) => {
              onCheck(e.node, metric.category, e.checked)
            }}
            checkedKeys={curCategorySelectedMetrics.map((e) => e.metric_key)}
            expandedKeys={[...expandedKeys, metric.category]}
            onExpand={(keys) => setExpandedKeys(keys)}
            autoExpandParent={false}
            titleRender={({ titleClassName, title }) => (
              <div className={titleClassName}>{title}</div>
            )}
          />
        </TreeContainer>
      </Col>
    )
  }

  function onCheck(node, category, checked) {
    const allKeys = node.children
      ? _.flattenDeep(recursiveFn(node.children, (e) => e.key))
      : [node.key]

    if (checked) {
      const shouldSearchCateMetrics = _.flattenDeep(
        recursiveFn(
          allMetrics.find((e) => e.category === category).children,
          (e) => e
        )
      )
      const shouldDisplayMetrics = shouldSearchCateMetrics
        .filter((metric) => allKeys.includes(metric.metric_key))
        .map(({ ranges, unit, name, metric_key, up_color }) => {
          return {
            add_metrics: [],
            categories: [category],
            metric_key,
            name,
            unit,
            ranges,
            up_color
          }
        })

      setSelectedMetrics([...selectedMetrics, ...shouldDisplayMetrics])
    } else {
      setSelectedMetrics(
        selectedMetrics.filter((metric) => !allKeys.includes(metric.metric_key))
      )
    }
  }

  function recursiveFn(children, returnBlock) {
    return children.map((child) => {
      if (child.children) {
        return recursiveFn(child.children, returnBlock)
      } else {
        return returnBlock(child)
      }
    })
  }
}

function DropDown(props) {
  const { loginUser, changeName, metricGroup, selectGroup, onChange } = props
  const [selectOpen, setSelectOpen] = useState(false)
  const [value, setValue] = useState(null)
  return (
    <Select
      style={{ width: 300, height: 30 }}
      bordered={false}
      dropdownStyle={{
        borderRadius: 12,
        padding: '10px 16px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)'
      }}
      dropdownMatchSelectWidth={273}
      open={selectOpen}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      value={value || metricGroup[selectGroup].name}
      onDropdownVisibleChange={(open) => setSelectOpen(open)}
      dropdownRender={(ReactNode) => {
        return metricGroup.map((item, index) => (
          <div
            className="dropdown"
            key={item.id}
            onClick={() => {
              setValue(item.name)
              setSelectOpen(false)
              onChange(index)
            }}
          >
            <div>
              <div className="label">{item.name}</div>
              {loginUser.id === item.created_by && !item.show_to_client && (
                <EditFilled onClick={() => changeName(item)} />
              )}
            </div>
          </div>
        ))
      }}
    ></Select>
  )
}

const MetricsNamedContainer = styled.div`
  header {
    color: #4c6072;
    font-size: 36px;
    padding-bottom: 36px;
  }
  footer {
    text-align: right;
    padding-top: 15px;
    padding-bottom: 10px;
    .ant-btn-text {
      color: #274382;
      margin-right: 10px;
    }
  }
  .main-container {
    .description {
      color: #7b8794;
      font-size: 16px;
      padding-bottom: 40px;
      margin-top: -20px;
    }
    .tips {
      list-style: none;
      padding: 0;
      margin-top: -15px;
      color: #a3b3c2;
      li {
        padding: 0 6px;
        display: inline-block;
        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background: gray;
          display: inline-block;
          margin-right: 6px;
          vertical-align: baseline;
        }
        &.blood:before {
          background-color: ${CheckedColor['Blood Metrics']};
        }
        &.health:before {
          background-color: ${CheckedColor.Healthspan};
        }
        &.nutrition:before {
          background-color: ${CheckedColor.Nutrition};
        }
        &.exercise:before {
          background-color: ${CheckedColor.Exercise};
        }
        &.sleep:before {
          background-color: ${CheckedColor.Sleep};
        }
      }
    }
  }
`

export function MetricsNamed(props) {
  const {
    metrics,
    onCancel,
    onConfirm,
    onReturn,
    person,
    countCategory
  } = props
  const { name, id, metric_keys, display_metrics, description } = metrics
  const [formRef, setFormRef] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  return (
    <Modal
      visible={true}
      footer={null}
      onCancel={onCancel}
      width={631}
      height={292}
      zIndex={9999}
      bodyStyle={{
        padding: 40
      }}
    >
      <MetricsNamedContainer>
        <header>{id ? 'Rename Profile' : 'Save Profile As...'}</header>
        <div className="main-container">
          {description && <div className="description">{description}</div>}
          <BasicForm
            wrappedComponentRef={(inst) => setFormRef(inst)}
            initialValues={{ name }}
            formData={[
              {
                type: 'input',
                dbField: 'name',
                label: 'name',
                rules: [{ required: true }]
              }
            ]}
          />
          <ul className="tips">
            {countCategory(display_metrics).map((item, index) => (
              <li key={index} className={item.name.toLowerCase()}>
                {item.name === 'Blood Metrics' ? 'Blood Panel' : item.name} (
                {item.count})
              </li>
            ))}
          </ul>
        </div>
        <footer>
          <Button type="text" onClick={onReturn} disabled={isSubmitting}>
            Return to Edit Metrics
          </Button>
          <Button type="primary" onClick={confirm} loading={isSubmitting}>
            Confirm
          </Button>
        </footer>
      </MetricsNamedContainer>
    </Modal>
  )

  async function confirm() {
    setIsSubmitting(true)
    try {
      const { name } = await formRef.handleSubmit()
      let result
      if (id) {
        await editTemplate({ name }, id)
        result = { name }
      } else {
        result = await createTemplate({
          name,
          metric_keys,
          client_id: person.id
        })
      }
      await onConfirm(result)
      setIsSubmitting(false)
    } catch (err) {
      setIsSubmitting(false)
    }
  }
}
