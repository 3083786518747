import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel,
  VictoryArea
} from 'victory'
import { GoalsMethods, calculateAxisYData } from '../../../utils/common'

const hhmmUnits = ['Time', 'Minutes']
const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}

const green = '#7BAD2D'
const red = '#E75F25'

function Dot(props) {
  const { target, datum, min, max } = props
  let stroke
  if (target || min || max) {
    stroke = datum.isSuccess ? green : red
  } else {
    stroke = 'rgba(0,0,0,.5)'
  }

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill="white"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}
class ProgressLine extends Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    period: PropTypes.string,
    achievingData: PropTypes.object,
    autoRange: PropTypes.string,
    unit: PropTypes.string
  }

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'weekly') {
      return moment(x).format('MMM D')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).format('ddd') === 'Sun') {
        result = moment(x).format('M/D')
      }
      return result
    }
  }

  formatTimeData(data) {
    return data.map((item) => {
      if (item.y) {
        const date = moment.unix(item.y),
          hour = date.hour(),
          minute = date.minute()
        item.y = hour * 60 + minute
      }
      return item
    })
  }

  getTickValues = (data, extraCondition) => {
    const { unit } = this.props
    const dataY = data.filter((item) => item.y).map((item) => item.y)
    const min = Math.min(...dataY, ...extraCondition)
    const max = Math.max(...dataY, ...extraCondition)
    let tickValues = []

    if (min && max) {
      if (unit === 'Minutes') {
        tickValues = []
      } else {
        tickValues = calculateAxisYData(min, max)
      }
    }
    return tickValues
  }

  render() {
    const {
      period,
      achievingData: { entries },
      startDate,
      endDate,
      auto_range,
      fixed_target,
      unit,
      loginRole
    } = this.props
    const isExpert = loginRole === 'Expert' || loginRole === 'InternalAdmin'
    let timeTickValues = []
    if (unit === 'Time') {
      timeTickValues = [0, 12 * 60, 23 * 60 + 59]
    }
    let { data, areaData } = GoalsMethods.getChatData({
      entries,
      startDate,
      endDate,
      auto_range
    })
    let defaultTickValues = [0]

    let { values, max, min, target } = GoalsMethods.getAxisyTickValues(
      auto_range
    )

    if (fixed_target && isExpert) {
      if (values) {
        values.push(fixed_target)
      } else {
        values = [0, fixed_target]
      }
    }

    if (unit === 'Time') {
      data = this.formatTimeData(data)
    } else {
      if (data.filter((item) => item.y).length > 0)
        defaultTickValues = this.getTickValues(
          data,
          [max, min, target].filter((item) => item)
        )
      if (values) values[0] = defaultTickValues[0]
    }

    const gridStroken = (t) => {
      if (moment(t).format('ddd') === 'Sun') {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }

    const _tickLabels = {
      fontFamily: 'Lato',
      fontSize: '18px',
      fill: (t) => (t === fixed_target && isExpert ? '#C0C7D1' : 'transparent')
    }

    if (unit === 'Minutes' && values) {
      values = values.filter((item) => item >= 0)
    }

    return (
      <VictoryChart>
        <VictoryAxis
          style={{
            axis,
            grid: {
              stroke: gridStroken,
              strokeDasharray
            },
            ticks: {
              strokeWidth: 0
            },
            tickLabels: {
              fontFamily: 'Lato',
              fontSize: '18px',
              fill: (x) => {
                if (
                  moment(x).format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                ) {
                  if (moment(x).format('ddd') === 'Sun') {
                    return '#3561C0'
                  }
                  return 'rgba(53,97,192,.6)'
                }
                return '#B0BAC9'
              },
              padding: (x) => {
                if (
                  period === 'monthly' &&
                  moment(x).format('ddd') !== 'Sun' &&
                  moment(x).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD') &&
                  moment(x).date() % 5 !== 0
                ) {
                  return 0
                } else {
                  return 10
                }
              }
            }
          }}
          tickValues={data.map((item) => item.x)}
          // tickFormat={this.xAxisFormat}
          tickLabelComponent={
            <VictoryLabel
              text={(datum) => {
                let result = this.xAxisFormat(datum)
                if (period === 'weekly') {
                  result = result.split(' ').join('\n')
                }
                return result
              }}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          tickValues={values || []}
          orientation="right"
          tickLabelComponent={<VictoryLabel dy={-10} />}
          style={{
            axis: {
              stroke: 'transparent'
            },
            grid: {
              stroke: (t) =>
                [max, min, target].find((item) => item === t)
                  ? '#FFB800'
                  : 'transparent',
              strokeDasharray: (t) =>
                t === fixed_target && isExpert ? null : strokeDasharray
            },
            ticks: {
              strokeWidth: 0
            },
            tickLabels: _tickLabels
          }}
          tickFormat={(y) => GoalsMethods.formatVal(y, unit, 'line')}
        />
        <VictoryAxis
          dependentAxis
          tickValues={unit === 'Time' ? timeTickValues : defaultTickValues}
          style={{
            axis: {
              strokeDasharray,
              stroke: 'rgba(0,0,0,.5)'
            },
            ticks: {
              strokeWidth: 0
            },
            tickLabels: {
              fontFamily: 'Lato',
              fontSize: unit === 'Minutes' ? '16px' : '18px',
              fill: (v) => {
                if (v < 2e-9) {
                  return 'transparent'
                }
                return '#B0BAC9'
              },
              padding: hhmmUnits.includes(unit) ? 4 : 10
            }
          }}
          tickFormat={(y) => GoalsMethods.formatVal(y, unit, 'line')}
        />

        {fixed_target && isExpert && (
          <VictoryAxis
            dependentAxis
            tickValues={values}
            orientation="right"
            tickLabelComponent={<VictoryLabel dy={10} />}
            style={{
              axis: {
                stroke: 'transparent'
              },
              grid: {
                stroke: (t) =>
                  [max, min, target, fixed_target].find((item) => {
                    if (item === fixed_target) return isExpert
                    return item === t
                  })
                    ? '#FFB800'
                    : 'transparent',
                strokeDasharray: (t) =>
                  t === fixed_target && isExpert ? null : strokeDasharray
              },
              ticks: {
                strokeWidth: 0
              },
              tickLabels: _tickLabels
            }}
            tickFormat={(y) => unit}
          />
        )}

        <VictoryLine
          style={{
            data: {
              stroke: 'rgba(0,0,0,.5)'
            },
            parent: { border: '1px solid #ccc' }
          }}
          data={data.filter((item) => item.y && item)}
        />
        <VictoryScatter
          data={data.filter((item) => item.y && item)}
          dataComponent={<Dot {...{ min, max, target }} />}
        />
        {areaData && (
          <VictoryArea
            style={{ data: { fill: 'rgba(123, 173, 45, 0.1)' } }}
            padding={{ left: 0, right: 0 }}
            data={areaData}
          />
        )}
      </VictoryChart>
    )
  }
}

export default ProgressLine
