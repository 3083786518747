import { Button, Checkbox, Col, Input, Row, Typography, message } from 'antd'
import React, { useState, useReducer, useEffect } from 'react'

import styled from 'styled-components'
import UploadModal from '../../UI/attachment/upload'
import AttachThumb from '../../UI/attachment/thumb'
import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import Confirm from '../../UI/confirmModal'
import _ from 'lodash'
import Clipboard from 'react-clipboard.js'

const { Paragraph } = Typography

const Container = styled.div`
  font-family: Gilroy;

  .subtitle {
    color: #4c6072;
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-top: 16px;
  }
  .upload-file {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #264382;
    font-size: 14px;
    font-weight: 600;
    padding-left: 0;
  }
  .attach-list {
    margin-top: ${(props) => (props.inForm ? 0 : '8px')};
    .attach-item {
      .thumb {
        min-width: 47px;
      }
      .name {
        font-family: Heebo;
        letter-spacing: 0.7px;
        font-size: 12px;
        overflow: hidden;
        width: auto;
        & > div {
          margin-left: 12px;
          margin-right: 12px;
        }
        & > div:first-child {
          font-weight: 600;
        }
        .ant-typography {
          color: #bac3ce;
          margin-top: 3px;
        }
        .title > .ant-typography {
          font-family: Gilroy;
          color: black;
        }
      }
    }
  }
`

const LinkContainer = styled.div`
  display: flex;
  margin-top: 12px;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  .link-type-label {
    font-size: 14px;
    color: #4c6072;
    font-weight: 600;
    margin-left: ${(props) => (props.editable ? '16px' : '0')};
  }
  input {
    width: 83%;
    height: 100%;
  }
`

const Title = styled.div`
  color: ${(props) => (props.inForm ? '#4C6072' : '#383E48')};
  font-size: ${(props) => (props.inForm ? '21px' : '17px')};
  font-weight: 600;
`

const LinkTypes = [
  {
    key: 'bridge',
    label: 'Bridge App'
  },
  {
    key: 'vald',
    label: 'Vald App'
  },
  {
    key: 'others',
    label: 'Others'
  }
]

function ItemResources(props) {
  const {
    inForm = true,
    attachments = [],
    removeAttach,
    prefixUpload,
    targetId,
    onLinkChange
  } = props

  const [linkType, setLinkType] = useState(null)
  const [state, dispatch] = useReducer(
    (state, action) => {
      if (action.payload) {
        return _.assign({}, state, action.payload)
      }
      return state
    },
    {
      visible: false,
      prefixConfirmVisible: false,
      prefixConfirm: false,
      isConfirming: false,
      others: '',
      vald: '',
      bridge: ''
    }
  )

  useEffect(() => {
    // Find any match bridge/vald/others
    const specialLink = _.find(attachments, (attachment) => {
      return (
        attachment.type === 'url' &&
        (attachment.name === 'others' ||
          attachment.name === 'bridge' ||
          attachment.name === 'vald')
      )
    })
    setLinkType(specialLink ? specialLink.name : null)
    const name = _.get(specialLink, 'name')
    if (inForm) {
      dispatch({
        type: 'update',
        payload: {
          others: name === 'others' ? specialLink.url : '',
          vald:
            name === 'vald'
              ? specialLink.url
              : 'https://apps.apple.com/us/app/telehab/id1439042849',
          bridge:
            name === 'bridge'
              ? specialLink.url
              : 'https://apps.apple.com/us/app/bridgetracker/id1024299963'
        }
      })
      if (specialLink) {
        onLinkChange(specialLink.name, specialLink.url)
      }
    } else {
      dispatch({
        type: 'update',
        payload: {
          others: name === 'others' ? specialLink.url : '',
          vald: name === 'vald' ? specialLink.url : '',
          bridge: name === 'bridge' ? specialLink.url : ''
        }
      })
    }
  }, [targetId, inForm])

  const validAttachments = attachments.filter((attachment) => {
    const isSpecialLink =
      attachment.type === 'url' &&
      (attachment.name === 'others' ||
        attachment.name === 'bridge' ||
        attachment.name === 'vald')

    return !isSpecialLink
  })

  return (
    <Container inForm={inForm}>
      <Title inForm={inForm}>Resources</Title>
      <section>
        <div className="subtitle">LINKS</div>
        {LinkTypes.filter((obj) => {
          return inForm || state[obj.key]
        }).map((obj, index) => {
          return (
            <LinkContainer key={index} editable={inForm}>
              <div>
                {inForm && (
                  <Checkbox
                    checked={linkType === obj.key}
                    onChange={(e) => {
                      setLinkType(e.target.checked ? obj.key : null)
                      onLinkChange(
                        e.target.checked ? obj.key : null,
                        state[obj.key]
                      )
                    }}
                  />
                )}
                <span className="link-type-label">{obj.label}</span>
              </div>
              {inForm ? (
                <Input
                  placeholder="https://"
                  disabled={linkType !== obj.key}
                  value={state[obj.key]}
                  onChange={(e) => {
                    dispatch({
                      type: 'update',
                      payload: { [obj.key]: e.target.value }
                    })
                    onLinkChange(linkType, e.target.value)
                  }}
                />
              ) : (
                <div>
                  <a href={state[obj.key]}>{state[obj.key]}</a>
                  <Clipboard
                    data-clipboard-text={state[obj.key]}
                    onSuccess={() => message.success('Link copied')}
                    style={{
                      background: 'transparent',
                      border: 'none'
                    }}
                  >
                    <Button
                      icon={<CopyOutlined />}
                      type="text"
                      style={{ marginLeft: 8 }}
                    />
                  </Clipboard>
                </div>
              )}
            </LinkContainer>
          )
        })}
      </section>
      {(inForm || validAttachments.length > 0) && (
        <section>
          <div className="subtitle">FILES</div>
          <UploadModal
            visible={state.visible}
            {...props}
            closeModal={() =>
              dispatch({
                type: 'update',
                payload: { visible: false }
              })
            }
          />
          {inForm && (
            <Button
              className="upload-file"
              type="text"
              onClick={() => {
                if (props.targetId) {
                  dispatch({
                    type: 'update',
                    payload: { visible: true }
                  })
                } else {
                  dispatch({
                    type: 'update',
                    payload: { prefixConfirmVisible: true }
                  })
                }
              }}
            >
              + Upload Files
            </Button>
          )}

          {validAttachments && (
            <Row gutter={10} className="attach-list">
              {validAttachments.map((item, index) => (
                <Col key={index} span={8}>
                  <div
                    className="attach-item"
                    onClick={() => window.open(item.oss_url || item.url)}
                  >
                    <AttachThumb attach={item} />
                    <div className="name">
                      <div className="title">
                        <Paragraph ellipsis>{item.title}</Paragraph>
                      </div>
                      <Paragraph ellipsis>
                        {item.type === 'url' ? item.url : item.name}
                      </Paragraph>
                    </div>
                    {inForm && (
                      <div className="close" onClick={() => removeAttach(item)}>
                        <CloseOutlined />
                      </div>
                    )}
                  </div>
                </Col>
              ))}
            </Row>
          )}
        </section>
      )}

      {state.prefixConfirmVisible && (
        <Confirm
          modalWidth={600}
          loading={state.isConfirming}
          onCancel={() =>
            dispatch({
              type: 'update',
              payload: { prefixConfirmVisible: false }
            })
          }
          onConfirm={async () => {
            if (state.isConfirming) {
              return
            }
            try {
              dispatch({
                type: 'update',
                payload: { isConfirming: true }
              })
              if (prefixUpload) {
                await prefixUpload()
              }
              dispatch({
                type: 'update',
                payload: {
                  visible: state.prefixConfirmVisible,
                  prefixConfirmVisible: null,
                  isConfirming: false
                }
              })
            } catch (err) {
              dispatch({
                type: 'update',
                payload: {
                  prefixConfirmVisible: null,
                  isConfirming: false
                }
              })
            }
          }}
          title={'Save Action Item before attaching file?'}
          description={
            'Action Items must be saved before attaching a file or URL.'
          }
        />
      )}
    </Container>
  )
}

export default ItemResources
