import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from 'antd'
import { getEmailDetailByHash } from '../../api/summaryEmail'
import Information from '../../components/UI/extraInformation'
import PageLoading from '../../components/UI/status/loading'
import EmailRichTextEditor from '../../components/summaryEmail/comp/richTextEditor'
import { AOFIcon, LTAIcon } from '../../components/icons/weeklySummary'
import LockStatus from '../../components/UI/lockStatus'
import { userType } from '../../utils/constant'
import EmailBG from '../../asserts/images/email-bg.png'
import { emailStyle } from './styles/email.sass'

const DEFAULT_SECTION_SHOW = {
  exercise: true,
  nutrition: true,
  rejuvenation: true,
  weight: true
}
class EmailMobileView extends Component {
  static propTypes = {}

  state = {
    loading: true,
    body: {
      overview: {},
      rejuvenation: {},
      deleted_section: {},
      section_show: DEFAULT_SECTION_SHOW
    }
  }

  initial = async () => {
    const {
      match: {
        params: { id, hash }
      }
    } = this.props
    const result = await getEmailDetailByHash(id, hash)
    console.log('initial', result)
    // if (!result.body.section_show) {
    //   result.body.section_show = DEFAULT_SECTION_SHOW
    // }
    this.setState({ ...result, loading: false })
  }

  componentDidMount() {
    this.initial()
  }

  renderOverview() {
    const {
      body: { overview, date_range },
      from,
      to
    } = this.state
    return (
      <div className="section-container">
        <div className="summary-date">
          {date_range ||
            `${from && moment(from).format('MMM DD')} - ${to &&
              moment(to).format('MMM DD')}`}
        </div>
        <h3 className="title Overview">{overview.title}</h3>
        {overview.summary_introduction && (
          <div className="extra-information">
            <Information
              status="Opened"
              initialValue={overview.summary_introduction}
            />
          </div>
        )}
        <div className="profile-container">
          {overview.areas_of_focus && (
            <div className="profile-info">
              <EmailRichTextEditor
                status={'Opened'}
                initialValue={overview.areas_of_focus}
                icon={<AOFIcon style={{ color: '#FF6B00' }} />}
                title="Goals"
              />
            </div>
          )}
          {overview.long_term_aspirations && (
            <div className="profile-info">
              <EmailRichTextEditor
                icon={<LTAIcon style={{ color: '#FF6B00' }} />}
                title="Aspirations"
                status={'Opened'}
                initialValue={overview.long_term_aspirations}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderRejuvenation() {
    const {
      body: { rejuvenation, deleted_section }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Rejuvenation">Rejuvenation</h3>
        <Information
          status="Opened"
          initialValue={rejuvenation.recommendation}
        />
        {!deleted_section.rejuvenation_quality && (
          <>
            <h4 className="sub-title">Quality of sleep</h4>
            <img src={rejuvenation.sleep_chart_url} alt="sleep_chart_url" />
          </>
        )}

        {rejuvenation.sleep_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  renderExercise() {
    const {
      body: { exercise, deleted_section }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Exercise">Exercise</h3>
        <Information status="Opened" initialValue={exercise.recommendation} />
        {!deleted_section.exercise_target && (
          <>
            <h4 className="sub-title">Apeiron Life Training Target</h4>
            <img src={exercise.heart_chart_url} alt="heart_chart_url" />
            <div className="extra-information">
              <h5>What is my Aerobic Training Target?</h5>
              <p>
                For optimal health benefits, we recommend you aim for at least
                150 minutes per week of moderate-intensity activity (zone 2 -
                zone 3; 60-75% max heart rate). This includes running, cycling,
                swimming, and hiking.
              </p>
            </div>
            <Divider />
          </>
        )}

        {!deleted_section.exercise_workout && (
          <>
            <h4 className="sub-title">Workout</h4>

            <img src={exercise.workout_chart_url} alt="heart_chart_url" />

            {exercise.activity_goals.length > 0 &&
              !deleted_section.exercise_workout && <Divider />}
          </>
        )}
        {exercise.activity_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  renderWeight() {
    const {
      body: { weight, weight_recommendation }
    } = this.state

    return (
      <div className="section-container">
        <h3 className="Weight">Body Weight</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information status="Opened" initialValue={weight_recommendation} />
        </div>
        {weight.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }

  renderNutrition() {
    const {
      body: { nutrition, nutrition_recommendation }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Nutrition">Nutrition</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information
            status="Opened"
            initialValue={nutrition_recommendation}
          />
        </div>
        {nutrition.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      loading,
      body: { section_show, deleted_section, nutrition, weight }
    } = this.state
    const { person } = this.props
    if (!person) return null
    const isPartner = person.profile.account_type === userType.Partners
    if (isPartner) return <LockStatus />
    return loading ? (
      <PageLoading />
    ) : (
      <div className={emailStyle}>
        <img className="bg-image" src={EmailBG} alt="background" />
        {this.renderOverview()}
        {(section_show
          ? section_show.rejuvenation
          : !deleted_section.rejuvenation) && this.renderRejuvenation()}
        {(section_show ? section_show.exercise : !deleted_section.exercise) &&
          this.renderExercise()}
        {(section_show
          ? section_show.weight
          : !deleted_section.weight && weight && weight.length > 0) &&
          this.renderWeight()}
        {(section_show
          ? section_show.nutrition
          : !deleted_section.nutrition && nutrition.length > 0) &&
          this.renderNutrition()}
      </div>
    )
  }
}

export default EmailMobileView
