import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { calendarStyle } from './calendar.sass'

const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
class Calendar extends Component {
  static propTypes = {
    period: PropTypes.string,
    startDate: PropTypes.object,
    entries: PropTypes.array,
    notState: PropTypes.bool,
    auto_activity: PropTypes.string
    // endDate: PropTypes.object
  }

  renderDateItem(date) {
    const { entries, notState, showActivity, auto_activity } = this.props
    let classNmae = ''
    const hasData =
      entries &&
      entries.find((item) => item.created_at === date.format('YYYY-MM-DD'))
    let activities = [],
      types = []
    if (hasData) {
      if (hasData.input === 'yes') {
        classNmae = 'green'
      } else if (hasData.input === 'no') {
        classNmae = 'red'
      } else if (hasData.input_auto === 'yes') {
        classNmae = 'green'
      } else if (hasData.input_auto === 'no') {
        classNmae = 'red'
      }
      activities = hasData.activity_details
      // const input = hasData.input_auto || hasData.input
      // const detail =
      if (notState && hasData) {
        //input && Number(input) > 0
        classNmae = 'blue'
        activities = []
      }
    }
    if (activities.length > 0 && auto_activity === 'Any') {
      types = Array.from(
        new Set(
          activities.map(
            (item) =>
              item.shift_activity_classification || item.activity_classification
          )
        )
      )
      types = types.filter(
        (item) =>
          item === 'Resistance' ||
          item === 'Aerobic' ||
          item === 'Activity' ||
          item === 'Movement Quality'
      )
    }
    const hasAutoData =
      activities.length > 0 &&
      activities.find(
        (activity) =>
          (activity.source && activity.source !== 'manual') ||
          (activity.sleep && activity.sleep.source !== 'manual')
      )
    return (
      <>
        <div className={`input-cicle ${hasAutoData ? 'activity' : ''}`}>
          <div
            className={classNmae}
            onClick={(e) => {
              e.stopPropagation()
              showActivity(date.format('YYYY-MM-DD'))
            }}
          >
            {date.format('D')}
            {date.isSame(moment(), 'day') && <span className="today" />}
          </div>
        </div>
        {types.length > 0 && (
          <div className="color-cicle">
            {types.map((item, index) => {
              if (item === 'Movement Quality') {
                item = 'Movement-Quality'
              }
              return <span className={item} key={index} />
            })}
          </div>
        )}
      </>
    )
  }

  renderWeekly() {
    const { startDate } = this.props
    const days = new Array(7).fill(null)
    return (
      <div>
        {days.map((item, index) => (
          <div className="date-item" key={index}>
            {this.renderDateItem(moment(startDate).add(index, 'days'))}
          </div>
        ))}
      </div>
    )
  }

  renderMonthly() {
    const { startDate } = this.props
    let days = startDate && startDate.daysInMonth()
    if (days) {
      days = new Array(days)
        .fill(null)
        .map((item, index) => moment(startDate).add(index, 'days'))
      let firstWeekday = startDate.day()
      if (firstWeekday === 0) {
        firstWeekday = 7
      }
      const emptyDays = new Array(firstWeekday - 1).fill(null)
      days = emptyDays.concat(days)
    }

    return (
      <div>
        {days &&
          days.map((item, index) => (
            <div className="date-item" key={index}>
              {item && this.renderDateItem(item)}
            </div>
          ))}
      </div>
    )
  }

  render() {
    const { period, auto_activity } = this.props
    return (
      <div className={calendarStyle}>
        <div
          className={`calendar-container ${
            auto_activity === 'Any' ? 'any-activity' : ''
          }`}
        >
          {labels.map((item, index) => (
            <div className="label-item" key={index}>
              {item}
            </div>
          ))}
          {period === 'weekly' ? this.renderWeekly() : this.renderMonthly()}
          {auto_activity === 'Any' && (
            <div className="color-cicle">
              <div>
                <span className="Aerobic" />
                Aerobic
              </div>
              <div>
                <span className="Resistance" />
                Resistance
              </div>
              <div>
                <span className="Activity" />
                Activity
              </div>
              <div>
                <span className="Movement-Quality" />
                Movement Quality
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Calendar
