import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import Empty from '../../UI/status/empty'
import Loading from '../../UI/status/loading'

const Container = styled.div`
  font-family: Gilroy;
  .title {
    margin-top: 40px;
    color: #383e48;
    font-size: 24px;
    margin-bottom: 25px;
  }
  .list-item {
    display: flex;
    margin-bottom: 20px;
    .avatar {
      margin-right: 24px;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      overflow: hidden;
      background: #97a0a7;
      img {
        max-width: 100%;
      }
    }
    .container {
      width: calc(100% - 32px - 24px);
      .action {
        font-size: 13px;
        color: #323f4a;
        line-height: 20px;
      }
      .date {
        color: #97a0a7;
        font-size: 12px;
        line-height: 18px;
      }
      .note-content {
        margin-top: 10px;
        background: #ffffff;
        border: 1px solid #dee2ec;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px 24px 12px 16px;
        white-space: pre-line;
      }
      .attach-content {
        &.loading {
          position: relative;
          .page-loading {
            position: absolute;
            width: 100%;
            background: rgba(255, 255, 255, 0.9);
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
        img {
          max-width: 100%;
        }
        span {
          display: inline-block;
          background: rgba(38, 67, 130, 0.05);
          border-radius: 22px;
          padding: 6px 15px;
          text-decoration: unset;
          color: #264382;
          margin-top: 10px;
          cursor: pointer;
          svg {
            margin-right: 10px;
            vertical-align: middle;
          }
          &.link-loading {
            opacity: 0.5;
          }
        }
        .error {
          color: #ff5151;
        }
      }
    }
  }
`

function NoteAttachItem(props) {
  const { loading, error, name, url, type, width } = props.note || {}
  return (
    <div
      className={`attach-content ${loading ? 'loading' : ''} ${
        error ? 'error' : ''
      }`}
    >
      {type === 'image' ? (
        <>
          <img src={url} alt="img" style={{ width }} />
          {loading && <Loading style={{ width, minHeight: 100 }} />}
        </>
      ) : (
        <>
          <span
            onClick={() => !loading && window.open(url)}
            className={loading ? 'link-loading' : ''}
          >
            {loading ? <LoadingOutlined /> : <FileIcon />}
            {name}
          </span>
        </>
      )}
      {error && <div className="error">{error}</div>}
    </div>
  )
}

export default function Activities(props) {
  const { list, title, timelineName } = props

  return (
    <Container>
      {title && <h1 className="title">{title}</h1>}
      {list.length === 0 && <Empty message="No comment yet" />}
      {list.map((item, index) => {
        const { action, created_at, creator, note } = item
        let actionString = action
        switch (action) {
          case 'AddItem':
            actionString = (
              <>
                has added <strong>an item</strong> in{' '}
                <strong>{timelineName}</strong>
              </>
            )
            break
          case 'DelItem':
            actionString = (
              <>
                has removes <strong>an item</strong> in{' '}
                <strong>{timelineName}</strong>
              </>
            )
            break
          case 'Note':
            actionString =
              typeof note.note === 'string' ? (
                <>
                  has left <strong>a note</strong>
                </>
              ) : (
                <>
                  attached <strong>a file</strong>
                </>
              )
            break
          case 'MarkDone':
            actionString = (
              <>
                has marked <strong>{timelineName}</strong> as{' '}
                <strong>Done</strong>
              </>
            )
            break
          case 'MarkUnDone':
            actionString = (
              <>
                has marked <strong>{timelineName}</strong> as{' '}
                <strong>Undone</strong>
              </>
            )
            break
          default:
            break
        }
        return (
          <div key={index} className="list-item">
            <div className="avatar">
              {creator && creator.avatar && (
                <img src={creator.avatar.oss_url} alt="avatar" />
              )}
            </div>
            <div className="container">
              <div className="action">
                <strong>{creator && creator.full_name}</strong> {actionString}
                {/* timeline_id: {timeline_id} */}
              </div>
              <span className="date">
                {moment(created_at).format('MMM DD, YYYY h:mm a')}
              </span>
              {action === 'Note' &&
                (typeof note.note === 'string' ? (
                  <div className="note-content">{note.note} </div>
                ) : (
                  <NoteAttachItem note={note.note} />
                ))}
            </div>
          </div>
        )
      })}
    </Container>
  )
}

function FileIcon(props) {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0.5V5.5H11.5"
        stroke="#264382"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 0.5H0.5V15.5H11.5V5.5L6.5 0.5Z"
        stroke="#264382"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
