import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { Select } from 'antd'
import _ from 'lodash'
import classNames from 'classnames'

const SelectQuestion = (props, ref) => {
  const {
    className,
    question: { options },
    answer = {},
    update,
    ...args
  } = props

  const [value, setValue] = useState(null)

  useEffect(() => {
    const an = answer.answer.value
    if (typeof an === 'string' || typeof an === 'number') {
      setValue(an)
    }
  }, [answer])

  useImperativeHandle(ref, () => {
    return {
      clearValue: () => {
        setValue(null)
      }
    }
  })

  // const value = useMemo(() => {
  //   const an = answer.answer.value
  //   if (typeof an === 'string' || typeof an === 'number') {
  //     return an
  //   }
  //   return null
  // }, [answer])

  return (
    <>
      <Select
        placeholder="Please select"
        className={classNames(
          `q-form-item ${answer.verify ? 'invalid' : ''}`,
          className
        )}
        onChange={(value) => {
          setValue(value)
          update(_.assignIn(answer, { answer: { value } }), props.question)
        }}
        value={value || undefined}
        dropdownStyle={{ zIndex: 1000 }}
        {...args}
      >
        {options.map(({ value, label }, index) => (
          <Select.Option value={value} key={index}>
            {label}
          </Select.Option>
        ))}
      </Select>
      {answer && answer.verify && (
        <p className="invalid">
          {answer.verifyMsg || 'Please select at least 1 option'}
        </p>
      )}
    </>
  )
}

export default forwardRef(SelectQuestion)
