import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import InjectedEditCreditCard from './form'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { LogoutIcon } from '../../../components/icons/onboarding'
import { getCustomer } from '../../../api/people'
import { skipOnboardingStep } from '../../../api/onboardingSkip'
// import { saveUser } from '../../../utils/storage'
import { paymentStyle } from './index.sass'
import { loadUser, saveUser } from '../../../utils/storage'
import phsLogo from '../../../asserts/icon/latest-logo.svg'

class Payment extends Component {
  static propTypes = {
    loginRole: PropTypes.string,
    back: PropTypes.func,
    client: PropTypes.object
  }

  state = {
    step: 0,
    client: null,
    loading: false,
    stripe: null,
    checkedStripe: false,
    changeCard: false,
    customer: null
  }

  initial = async () => {
    const { client, account } = this.props
    try {
      const { customer } = await getCustomer(
        client ? client.id : account.user.id
      )
      this.setState({ checkedStripe: true, customer })
    } catch (err) {
      this.setState({ checkedStripe: true, account: client })
    }
  }

  setPropsState = (state) => {
    const {
      history,
      setLoginAccount,
      account: { user },
      loginRole,
      back
    } = this.props
    const { callback } = this.state
    if (state.finished) {
      if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
        back && back()
      } else {
        if (callback) {
          history.push(callback)
        } else {
          user.profile.payment_required = false
          setLoginAccount(user)
          saveUser(user)
          history.push('/onboarding/successful')
        }
      }
    }
    this.setState({ ...state })
  }

  skip = () => {
    const {
      location: { search },
      setLoginAccount,
      back,
      loginRole
    } = this.props

    if (search.match('callback')) {
      // goback
      const callback = search.split('=')[1]
      this.props.history.push(callback)
    } else {
      if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
        back && back()
      } else {
        // skip
        const person = loadUser()
        // person.profile.payment_required = false
        skipOnboardingStep('billing_info_skipped', person)
        // skip last step -> set whole onboarding required false
        skipOnboardingStep('onboarding_required', person)

        saveUser(person)
        setLoginAccount(person)
        this.props.history.push('/onboarding/ready')
      }
    }
  }

  success = () => {
    const person = loadUser()
    skipOnboardingStep('onboarding_required', person)
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props
    const callback = search.includes('callback') && search.split('=')[1]
    this.setState({ agreePrivacy: true, callback })
    this.initial()
    const key = process.env.REACT_APP_STRIPE_KEY
    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(key)
      })
    } else {
      const elem = document.querySelector('#stripe-js')
      elem &&
        elem.addEventListener('load', () => {
          this.setState({
            stripe: window.Stripe(key)
          })
        })
    }
  }

  render() {
    const { logOut, history, setLoginAccount, loginRole, client } = this.props
    const { loading, agreePrivacy, customer, changeCard, callback } = this.state

    return (
      <div className={`${paymentStyle} main-container`}>
        {loginRole !== 'Expert' && (
          <>
            <header>
              <img src={phsLogo} alt="logo" />
              <div onClick={() => logOut(history)}>
                <LogoutIcon />
                LOG OUT
              </div>
            </header>
            <div className="step-title">
              {!callback && <span>3</span>}
              Billing Info
            </div>
          </>
        )}
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <InjectedEditCreditCard
              {...{
                agreePrivacy,
                loading,
                customer,
                changeCard,
                history,
                setLoginAccount,
                loginRole,
                client
              }}
              // skipPayment={search.match('callback') ? null : this.skip}
              skipPayment={this.skip}
              successCallback={this.success}
              setPropsState={(state) => this.setPropsState(state)}
            />
          </Elements>
        </StripeProvider>
      </div>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Payment)
