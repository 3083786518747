import React, { useState } from 'react'
import { Input } from 'antd'

export const NumericInput = (props) => {
  const [value, setValue] = useState(props.defaultValue || '')
  const handleChange = (e) => {
    const { value: inputValue } = e.target
    const reg = /^\d*(\.\d*)?$/
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      setValue(inputValue)
    }
  }

  return (
    <Input {...props} value={value} onChange={handleChange} maxLength={4} />
  )
}
