import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Goals from '../../../components/goals'
import {
  getClientList,
  getClientDetail,
  getTrackingMetrics
} from '../../../models/breadcrumb.model'
import NullImage from '../../../asserts/images/client-empty.png'

class GoalsPage extends Component {
  static propTypes = {}

  state = {
    person: null
  }

  initial = async (type) => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    const title =
      type === 'archived'
        ? `${person.profile.nickname || person.first_name}’s Archived Metrics`
        : `${person.profile.nickname || person.first_name}’s Trends`
    setClientPageTitle && setClientPageTitle(title)
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getTrackingMetrics(person, true)
      ])
  }

  switchStatus = (type) => {
    const {
      history,
      match: { url }
    } = this.props
    const targetPath = url.split('/goals')[0]
    history.replace(`${targetPath}/goals/${type}`)
  }

  componentDidMount() {
    const {
      match: {
        params: { type }
      }
    } = this.props

    this.initial(type)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { type }
      }
    } = this.props
    const nextType = nextProps.match.params.type
    if (nextType !== type) {
      this.initial(nextType)
    }
  }

  renderNullStatus(type) {
    return (
      <div className="null-status">
        <img src={NullImage} alt="null status" />
        {type === 'archived' ? (
          <p>Your archived microgoals will appear here</p>
        ) : (
          <p>
            Your microgoals will appear here when your
            <br />
            Client Advocate sets them for you.
          </p>
        )}
      </div>
    )
  }

  render() {
    const {
      match: {
        params: { type }
      }
    } = this.props
    const { person } = this.state
    return (
      (person && (
        <Goals
          person={person}
          switchStatus={this.switchStatus}
          // renderNullStatus={this.renderNullStatus}
          type={type || 'active'}
          loginRole="Expert"
        />
      )) || <div />
    )
  }
}

export default GoalsPage
