import styled, { css } from 'styled-components'

const common = css`
  margin-top: 32px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  color: rgba(19, 35, 68, 0.7);
  letter-spacing: 0.13px;
  font-family: Gilroy;
  font-size: 14px;
  .center {
    text-align: center;
  }
  .subject-text {
    font-size: 18px;
    color: #313251;
    white-space: pre-line;
    font-weight: 600;
  }
  .bold-text {
    color: #3a3e46;
    font-size: 18px;
    font-family: Gilroy-Bold;
    white-space: pre-wrap;
    letter-spacing: 1px;
    line-height: 135%;
  }
  .bold-text-selected {
    background: linear-gradient(to right, #86c2d8, #264382);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .tiny-text {
    font-size: 12px;
    color: #313251;
    letter-spacing: 0.18px;
  }
  .price-text {
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    letter-spacing: 0.13285px;
    color: #313251;
    font-weight: 600;
    .discount-text {
      font-size: 10px;
      line-height: 140%;
      letter-spacing: 0.13285px;
      color: #313251;
      opacity: 0.3;
      transform: scale(0.83);
      .line-through {
        text-decoration: line-through;
      }
    }
  }
  .include-desc-text {
    font-family: 'Gilroy-Bold';
    font-size: 12px;
    letter-spacing: 1px;
    color: #6d7d8c;
    border: 1px solid #e2f0f5;
    border-radius: 4px;
    background: #fbfefe;
    margin: 6px 5px 0px 5px;
    span {
      transform: scale(0.84);
      display: block;
    }
  }
  .row {
    display: flex;
    .price-tip {
      font-size: 12px;
      color: #132344;
      padding-right: 32px;
      .ant-legacy-form-item {
        margin-bottom: 0px;
      }
    }
    .select-form {
      .ant-form-item {
        margin: 0;
      }
    }
  }
  .no-selected-background {
    color: rgba(19, 35, 68, 0.7);
  }
  ul {
    padding-left: 15px;
    margin-top: 10px;
    color: rgba(19, 35, 68, 0.7);
    li {
      margin-bottom: 5px;
      line-height: 1.5;
      font-weight: 400;
      ul {
        opacity: 1;
      }
    }
  }
`

const ServicesContainer = styled.div`
  ${common}
  &>div {
    &.bold-text {
      &:first-child {
        font-size: 20px;
      }
    }
  }

  .row {
    border-top: 1px solid rgba(145, 159, 171, 0.1);
    white-space: pre-wrap;

    &.single {
      .info {
        height: auto;
      }
    }

    & > div {
      width: 200px;
      padding: 20px 0;
      display: flex;

      &:first-child {
        width: calc(100% - 480px);
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }

      &:nth-child(3) {
        text-align: right;
        justify-content: flex-end;
        width: 110px;
      }

      &:last-child {
        text-align: right;
        justify-content: flex-end;
        width: 200px;
      }

      &.bold-text {
        font-size: 16px;
        align-items: self-start;

        &:first-child {
          font-size: 20px;
        }
      }

      .service-name {
        font-size: 18px;
        color: #313251;
        white-space: pre-wrap;
        font-weight: 600;
      }

      .normal {
        font-family: 'Gilroy';
        font-size: 13px;
        color: rgba(19, 35, 68, 0.7);
        line-height: 130%;
        letter-spacing: 0.13px;
        margin-top: 15px;
        padding-right: 100px;
      }
    }

    .info {
      width: 100%;
      height: 100%;

      & > div {
        &:first-child {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(145, 159, 171, 0.1);
          height: calc(100% - 41px);

          &:last-child {
            padding: 0;
            border: none;
            height: auto;
          }
        }

        &:last-child {
          padding-top: 20px;
        }
      }

      .contact {
        font-family: Gilroy;
        padding: 20px 0px !important;
      }
    }

    &.premium {
      &.Services {
        .service-name {
          font-size: 20px;
          text-transform: uppercase;
        }
      }
    }
  }

  .extra-text {
    border-top: 1px solid rgba(145, 159, 171, 0.1);
    font-size: 13px;
    color: rgba(19, 35, 68, 0.7);
    letter-spacing: 0.13px;
    font-family: Gilroy;
    font-size: 14px;
    padding: 32px 0;
  }

  .payment-policy {
    border-top: 1px solid rgba(145, 159, 171, 0.1);
    margin: 0 -32px;
    margin-bottom: -32px;
    padding: 36px 32px;

    & > ul {
      list-style: none;
      padding: 0;

      & > li {
        display: flex;
        padding: 32px 0;
        border-bottom: 1px solid rgba(145, 159, 171, 0.1);

        div {
          &:first-child {
            width: 180px;
          }

          &:last-child {
            width: calc(100% - 180px);
          }
        }

        &:last-child {
          padding-bottom: 0;
          border: none;
        }

        .bold-text {
          font-size: 14px;
        }

        .description {
          line-height: 1.7;
        }
      }

      ul {
        list-style: disc;

        &:first-child {
          margin-top: 0;
        }

        li {
          & > span {
            color: #313251;
            font-family: Gilroy-Bold;
          }
        }
      }
    }
  }

  .tabs {
    & > .tab {
      padding-bottom: 0;
      margin-bottom: 20px;
      width: auto !important;
      margin-right: 32px;
      font-size: 14px;
      font-weight: 700;
      color: #a2a7ac;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      border-bottom: 3px solid transparent;
      cursor: pointer;

      &.active {
        color: #264382;
        border-color: #264382;
      }
    }
  }
`

const MemberShipContainer = styled.div`
  ${common}
  .row {
    border-bottom: 1px solid rgba(145, 159, 171, 0.1);

    & > div {
      position: relative;
      vertical-align: middle;
      width: 220px;
      padding: 20px 0;
      align-items: flex-start;
      display: flex;
      justify-content: space-around;
      opacity: 0.6;

      &.align-bottom {
        align-items: flex-end;
      }

      &:first-child {
        width: calc(100% - 400px);
        display: block;
        opacity: 1;
      }

      button {
        width: 132px;
        height: 48px;
        border: 1px solid #bec7d9;
        font-weight: 600;
        letter-spacing: 2px;
        box-shadow: none;
      }

      .ant-btn-primary:hover,
      .ant-btn-primary:focus {
        background-color: #264382;
        color: white;
      }
    }

    &:last-child {
      border-bottom: 0px;
    }

    &:first-child {
      & > .center {
        & > div {
          &:last-child {
            min-height: 69px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .no-selected-background {
      background: none !important;
      border: none !important;
    }

    .selected-plan {
      position: absolute;
      width: 96px;
      height: 22px;
      background: #264382;
      border-radius: 4px;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #ffffff;
      top: 0;
      margin-top: -11px;

      .text {
        transform: scale(0.8333);
        font-weight: 600;
        font-size: 10px;
      }
    }

    .list-item {
      opacity: 0.3;
    }
  }

  .selected-border-top {
    border-top: 1px solid #448ef7;
    border-radius: 4px 4px 0 0;
  }

  .selected-border-bottom {
    border-bottom: 1px solid #448ef7;
    border-radius: 0 0 4px 4px;
  }

  .mark-evaluation,
  .mark-evaluation2 {
    & > div:nth-child(2) {
      background: rgba(102, 187, 218, 0.2);
      margin-bottom: -1px;
      opacity: 1;
      border-left: 1px solid #448ef7;
      border-right: 1px solid #448ef7;
    }
  }

  .mark-core,
  .mark-silver {
    & > div:nth-child(3) {
      background: rgba(102, 187, 218, 0.2);
      margin-bottom: -1px;
      opacity: 1;
      border-left: 1px solid #448ef7;
      border-right: 1px solid #448ef7;
    }
  }

  .mark-core-plus,
  .mark-gold {
    & > div:nth-child(4) {
      background: rgba(102, 187, 218, 0.2);
      margin-bottom: -1px;
      opacity: 1;
      border-left: 1px solid #448ef7;
      border-right: 1px solid #448ef7;
    }
  }

  .should-float {
    position: sticky;
    z-index: 99;
    background: white;

    &:nth-child(1) {
      top: -61px;
    }

    &:nth-child(2) {
      top: 77px;
    }

    &:nth-child(3) {
      top: 146.2px;
    }
  }

  &.membership-v1 .should-float {
    &:nth-child(2) {
      top: 50px;
    }

    &:nth-child(3) {
      top: 130.2px;
    }
  }
`

export { ServicesContainer, MemberShipContainer }
