import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryScatter
} from 'victory'
import moment from 'moment'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { calculateAxisYData } from '../../../utils/common'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}

function Dot(props) {
  const { stroke } = props

  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      x={props.x - 4}
      y={(props.y && props.y - 4) || 0}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="4"
        cy="4"
        r="3"
        fill="white"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

class ActivityMultiLine extends Component {
  static propTypes = {
    list: PropTypes.array,
    period: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    datas: PropTypes.array
  }

  state = {
    dataArr: [],
    dataX: []
  }

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'week') {
      return moment(x).format('ddd')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).date() % 5 === 0) {
        result = moment(x).format('M/D')
      }

      return result
    }
  }

  initialMetaData = (dataArr) => {
    const { startDate, endDate, maxAxis, minAxis } = this.props
    const dataX = []
    let count = 1,
      day = moment(startDate)
    dataX.push(day)
    const dataHandle = (day) => {
      dataArr.map((data) => {
        const entry = data.entriesOrigin.find(
          (item) => item.created_at === day.format('YYYY-MM-DD')
        )
        if (entry) {
          const y =
            (entry.input_auto && Number(entry.input_auto)) ||
            (entry.input !== -Infinity ? Number(entry.input) || 0 : 0)
          data.entries.push({
            x: moment(entry.created_at),
            y
          })
        }
        return data
      })
    }
    dataHandle(day)
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      day = moment(startDate).add(count, 'days')
      dataX.push(day)
      dataHandle(day)
      count++
    }
    day = moment(endDate)
    dataX.push(day)
    dataHandle(day)

    const entries = [].concat(...dataArr.map((item) => item.entries))
    let maxValue = Math.max(...entries.map((item) => item.y), maxAxis || 100)
    let minValue = Math.min(...entries.map((item) => item.y), minAxis || 20)
    let dataY = []
    // maxValue = Math.ceil(maxValue / 10) * 10
    // minValue = Math.floor(minValue / 10) * 10
    // if (minValue > 20) {
    //   minValue -= 20
    // }
    dataY = calculateAxisYData(minValue, maxValue)
    this.setState({ dataX, dataArr, dataY })
  }

  componentDidMount() {
    const { list, datas } = this.props
    const dataArr = []
    if (list) {
      for (const item of list) {
        if (item.goal) {
          const { achieving } = item.goal
          let entriesOrigin = []
          for (const achiev of achieving) {
            entriesOrigin = entriesOrigin.concat(achiev.entries)
          }
          dataArr.push({
            entriesOrigin,
            color: item.color,
            entries: []
          })
        }
      }
    } else if (datas) {
      for (const item of datas) {
        dataArr.push({
          entriesOrigin: item.data,
          color: item.color,
          entries: []
        })
      }
    }
    this.initialMetaData(dataArr)
  }

  render() {
    const { period } = this.props
    const { dataArr, dataX, dataY } = this.state
    const gridStroken = (t) => {
      if (period === 'month' && moment(t).date() % 5 === 0) {
        return 'rgba(0,0,0,.5)'
      } else if (period === 'week') {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={width / 3}
            padding={{ top: 20, left: 50, bottom: 50, right: 50 }}
            domainPadding={0}
          >
            <VictoryAxis
              style={{
                axis,
                grid: {
                  strokeDasharray,
                  stroke: gridStroken
                },
                tickLabels: {
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fill: (x) => {
                    if (
                      moment(x).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                    ) {
                      return '#3561C0'
                    }
                    return '#B0BAC9'
                  },
                  padding: (x) => {
                    if (
                      period === 'monthly' &&
                      moment(x).format('ddd') !== 'Sun' &&
                      moment(x).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD') &&
                      moment(x).date() % 5 !== 0
                    ) {
                      return 0
                    } else {
                      return 10
                    }
                  }
                }
              }}
              tickValues={dataX}
              tickLabelComponent={
                <VictoryLabel text={(datum) => this.xAxisFormat(datum)} />
              }
            />
            <VictoryAxis
              dependentAxis
              style={{
                axis: {
                  strokeDasharray,
                  stroke: 'rgba(0,0,0,.5)'
                },
                grid: {
                  strokeDasharray,
                  stroke: 'rgba(0,0,0,.1)'
                },
                tickLabels: {
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fill: (v) => {
                    if (v < 2e-9) {
                      return 'transparent'
                    }
                    return '#B0BAC9'
                  }
                }
              }}
              tickValues={dataY}
            />
            {dataArr.map((data, index) => (
              <VictoryLine
                key={index}
                style={{
                  data: {
                    stroke: data.color
                  },
                  parent: { border: '1px solid #ccc' }
                }}
                data={data.entries}
              />
            ))}
            {dataArr.map((data, index) => {
              return (
                <VictoryScatter
                  key={index}
                  data={data.entries}
                  dataComponent={<Dot stroke={data.color} />}
                />
              )
            })}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default ActivityMultiLine
