import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Divider, Select, Row, Col, Button, message } from 'antd'
import Card from './card'
import { CollapsePlusIcon } from '../../../components/icons'
import ChallengeForm from './challengeForm'
import {
  getChallenges,
  createChallenge,
  updateChallenge,
  deleteChallenge,
  getChallengeMetrics
} from '../../../api/challenge'
import { uploadPublicFile } from '../../../api/file'
import PageLoading from '../../../components/UI/status/loading'
import ChallengeDetail from './detail'

const { Option } = Select

const ChallengeContainer = styled.div`
  .page-title {
    margin-top: 20px;
    .ant-btn {
      float: right;
    }
  }
  .filter {
    color: #8d99a5;
    letter-spacing: 0.4px;
  }
  .sub-title {
    font-size: 24px;
    color: #383e48;
  }
  .lighter {
    color: #919dad;
    font-size: 13px;
    margin-bottom: 60px;
  }

  .list-container {
    margin-bottom: 60px;
    padding-top: 24px;
  }
`

export default function Challenge(props) {
  const [openModal, setOpenModal] = useState(null)
  const [loading, setLoading] = useState(true)
  const [metrics, setMetrics] = useState([])
  const [filterKey, setFilterKey] = useState('all')
  const [filterChallenges, setFilterChallenge] = useState(null)
  const [challenges, setChallenges] = useState({
    active: [],
    past: [],
    scheduled: []
  })
  const [selectedChallenge, setSelectedChallenge] = useState(null)

  useEffect(() => {
    initial()
  }, [])

  useEffect(() => {
    let _filterChallenge = _.cloneDeep(challenges)
    if (filterKey !== 'all') {
      for (const key in _filterChallenge) {
        _filterChallenge[key] = _filterChallenge[key].filter(
          (item) => item.pillar === filterKey
        )
      }
    }
    setFilterChallenge(_filterChallenge)
  }, [filterKey, challenges])

  const { active, past, scheduled } = filterChallenges || challenges
  return (
    <ChallengeContainer>
      <div className="page-title">
        Challenge
        <Button type="primary" onClick={() => setOpenModal('form')}>
          <CollapsePlusIcon />
          ADD CHALLENGE
        </Button>
      </div>
      <div className="filter">
        FILTER BY:
        <Select
          value={filterKey}
          style={{ width: 120, marginLeft: 15 }}
          onChange={(value) => setFilterKey(value)}
        >
          <Option value="all">All</Option>
          <Option value="exercise">Exercise</Option>
          <Option value="nutrition">Nutrition</Option>
          <Option value="rejuvenation">Rejuvenation</Option>
        </Select>
      </div>
      <Divider />
      {loading ? (
        <PageLoading />
      ) : (
        <>
          <div className="sub-title">Active Challenge</div>
          {active.length > 0 ? (
            <div className="list-container">
              <Row gutter={[34, 24]}>
                {active.map((item) => (
                  <Col span={8} key={item.id}>
                    <Card
                      status="active"
                      item={item}
                      handleClick={handleClick}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="lighter">No active challenges</div>
          )}

          <div className="sub-title">Scheduled Challenges</div>
          {scheduled.length > 0 ? (
            <div className="list-container">
              <Row gutter={[34, 24]}>
                {scheduled.map((item) => (
                  <Col span={8} key={item.id}>
                    <Card
                      status="inActive"
                      item={item}
                      handleClick={handleClick}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="lighter">No scheduled challenges</div>
          )}

          <div className="sub-title">Past Challenges</div>
          {past.length > 0 ? (
            <div className="list-container">
              <Row gutter={[34, 24]}>
                {past.map((item) => (
                  <Col span={8} key={item.id}>
                    <Card status="past" item={item} handleClick={handleClick} />
                  </Col>
                ))}
              </Row>
            </div>
          ) : (
            <div className="lighter">No past challenges</div>
          )}
        </>
      )}

      {openModal === 'form' && (
        <ChallengeForm
          metrics={metrics}
          onCancel={() => {
            setOpenModal(false)
            setSelectedChallenge(null)
          }}
          submit={formSubmit}
          selectedChallenge={selectedChallenge}
          handleDelete={handleDelete}
        />
      )}
      {openModal === 'detail' && (
        <ChallengeDetail
          challenge={selectedChallenge}
          onCancel={() => {
            setOpenModal(false)
            setSelectedChallenge(null)
          }}
          handleEdit={handleDetailEdit}
          handleDelete={handleDelete}
        />
      )}
    </ChallengeContainer>
  )

  async function handleDelete() {
    if (!selectedChallenge) return
    await deleteChallenge(selectedChallenge.id)
    setOpenModal(null)
    await fetchChallenge()
  }

  function handleDetailEdit() {
    setOpenModal('form')
  }

  function handleClick(challenge, status) {
    setSelectedChallenge(challenge)
    if (status === 'inActive') {
      setOpenModal('form')
    } else {
      setOpenModal('detail')
    }
  }

  async function formSubmit(params) {
    const { badge_image } = params
    if (!badge_image.url) {
      const { uid, name, size, type } = badge_image
      const { url } = await uploadPublicFile({
        file: badge_image,
        path: 'challenge'
      })
      params.badge_image = { uid, name, size, type, url }
    }
    if (selectedChallenge && selectedChallenge.status !== 'past') {
      selectedChallenge.badge_image = params.badge_image
      await updateChallenge(params, selectedChallenge.id)
      message.success('Challenge successfully edited')
    } else {
      await createChallenge(params)
      message.success('Challenge successfully created')
    }

    setLoading(true)
    fetchChallenge()
  }

  async function initial() {
    const metrics = await getChallengeMetrics()
    setMetrics(groupMetrics(metrics))
    const challenges = await fetchChallenge(true)
    const {
      match: {
        params: { id }
      }
    } = props
    if (id) {
      const { active, past, scheduled } = challenges
      const selectedChallenge = _.concat(active, past, scheduled).find(
        (item) => item.id === Number(id)
      )
      if (selectedChallenge) {
        setSelectedChallenge(selectedChallenge)
        setOpenModal('detail')
      }
    }
  }
  async function fetchChallenge(needReturnData) {
    const challenges = await getChallenges()
    setChallenges(challenges)
    // setFilterKey('all')
    setLoading(false)
    if (needReturnData) {
      return challenges
    }
  }

  function groupMetrics(metrics) {
    let result = metrics
    const normalMetrics = metrics.filter((item) => !item.group)
    let groups = _.unionBy(
      metrics.filter((item) => item.group),
      (item) => item.group
    )
    groups = groups.map((item) => {
      item = _.cloneDeep(item)
      item.metric = item.group
      item.children = metrics
        .filter((metric) => metric.group === item.group)
        .map(({ metric }) => metric)
      return item
    })
    result = _.concat(normalMetrics, groups)
    return result
  }
}
