import axios from './axios'
import moment from 'moment'

export const getSectionModules = (personId) =>
  axios.get(`/survey/section/modules?person_id=${personId}`)

export const getSectionDetails = (personId, ids) =>
  axios.get(`/survey/section/details?original_ids=${ids}&person_id=${personId}`)

export const getSectionsSessions = (personId, ids) =>
  axios.get(
    `/survey/section/sessions?person_id=${personId}&original_ids=${ids}`
  )

export const getSessionOrInit = (personId, ids, record_date) => {
  if (!record_date) {
    record_date = moment().format('YYYY-MM-DD')
  }
  return axios.get(
    `/survey/section/sessions/init?person_id=${personId}&original_ids=${ids}&record_date=${record_date}`
  )
}

export const updateAnswer = (answer) =>
  axios.putJson('/survey/section/session/answer', answer)

export const getSurveyProgress = (personId) =>
  axios.get(`/survey/section/completion-rate?person_id=${personId}`)

export const getRecordSessions = (ids, date, personId) =>
  axios.get(
    `/survey/section/sessions?original_ids=${ids}&record_date=${date}${
      personId ? '&person_id=' + personId : ''
    }`
  )

export const sendNewSessionEmail = (params, personId) =>
  axios.postJson(
    `/survey/section/send-new-sessions-email?person_id=${personId}`,
    params
  )

export const createSectionsSession = (params) =>
  axios.postJson('/survey/section/sessions', params)

export const completeNewSessionEmail = (params) =>
  axios.postJson('/survey/section/complete-sessions-email', params)
