import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import { sendOnboardingCompletedEmail } from '../../api/people'
import phsLogo from '../../asserts/icon/latest-logo.svg'
import finishImg from '../../asserts/images/onboarding-intro-logistics-finish.svg'

const View = styled.div`
  padding: 35px 390px 80px;
  text-align: center;
  .content {
    margin-top: 84px;
    background-color: white;
    border-radius: 18px;
    padding: 45px 102px 55px;
    h2 {
      font-family: 'Gilroy';
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.3px;
      color: #545f6d;
      margin-top: 60px;
      margin-bottom: 20px;
    }
    p,
    ul {
      text-align: left;
      font-family: Heebo;
      letter-spacing: 0.3px;
      color: #5c626a;
      opacity: 0.8;
    }
    button {
      width: 186px;
      height: 48px;
      margin-top: 32px;
    }
  }
`

function Successful(props) {
  const [waiting, setWaiting] = useState(false)
  const handleContinue = async () => {
    const {
      user: { id }
    } = props.account
    setWaiting(true)
    try {
      await sendOnboardingCompletedEmail(id)
      setWaiting(false)
      props.history.push('/')
    } catch (err) {
      setWaiting(false)
    }
  }

  return (
    <View>
      <img src={phsLogo} alt="logo" />
      <div className="content">
        <img src={finishImg} alt="finished" />
        <h2>
          Welcome to your <br /> Apeiron Life program!
        </h2>
        <p>
          Thank you for taking the time to tell us a bit about you.{' '}
          <a
            href="mailto:concierge@apeiron.life"
            target="_bank"
            style={{
              color: '#0068f2',
              textDecoration: 'underline'
            }}
          >
            Our Concierge team
          </a>{' '}
          will be reaching out to you (and your assistant if applicable) via
          email to schedule your next appointments. These include:
        </p>
        <ul>
          <li>Initial Consultation</li>
          <li>Blood Panel</li>
          <li>Initial 360 Physical Evaluation</li>
          <li>Assessment Review & Milestone Planning</li>
        </ul>
        <p>
          In the meantime, please download your{' '}
          <a
            href="itms-apps://itunes.apple.com/us/app/my-app/1471466406"
            target="_bank"
            style={{
              color: '#0068f2',
              textDecoration: 'underline'
            }}
          >
            Apeiron Life app
          </a>{' '}
          and reach out to{' '}
          <a
            href="mailto:concierge@apeiron.life"
            target="_bank"
            style={{
              color: '#0068f2',
              textDecoration: 'underline'
            }}
          >
            concierge@apeiron.life
          </a>{' '}
          with any questions. You'll notice your app will populate with your
          data and info from your team as we learn more about you. Stay tuned.
        </p>
        <p>
          We very much look forward to working with you and supporting you on
          your health and wellness journey!
        </p>
        <Button type="primary" loading={waiting} onClick={handleContinue}>
          Continue
        </Button>
      </div>
    </View>
  )
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Successful)
