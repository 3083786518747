import React from 'react'
import styled from 'styled-components'

const StyledH1 = styled.div`
  line-height: 120%;
  font-size: 32px;
  color: #4c6072;
  letter-spacing: 0.3px;
`

const PageTitle = (props) => {
  return <StyledH1 style={props.style}>{props.title}</StyledH1>
}

export default PageTitle
