import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { datasetTypes as types } from '../../UI/clientSlideMenu/menu'
import { hashRequest } from '../../../api/axios'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'
import { getDataset, getCategories } from '../../../api/dataset'

import {
  getDbFields,
  injectCategories,
  injectRootCategories,
  filterBiomarkerWithoutPersonValue
} from '../dataset/common'

import { setBiomarkerCategoriesItem } from '../../../utils/storage'

// const compatibleTypes = [
//   {
//     path: 'life-style',
//     originKey: 'lifestyle_assessment'
//   }
// ]

export default (WrappedComponent) =>
  class ContainerWrapper extends React.Component {
    static propTypes = {
      type: PropTypes.string,
      hash: PropTypes.string
    }
    state = {
      hrefs: [],
      ageScopes: null
    }

    propsVersion = 1

    requestResult = async (path, hash) =>
      await hashRequest({
        hash,
        method: 'get',
        path: path
      })

    initial = async (type, hash, propsVersion) => {
      const {
        person,
        setClientBiomarkerCategories,
        setMobileHeaderState,
        groups,
        url
      } = this.props
      const targetType =
        _.find([...types], (item) => _.includes(url, item.path)) || {}

      let { category } = this.props
      let { ageScopes } = this.state
      try {
        let categories,
          datasets,
          dataset,
          breadcrumb = []
        const dbField = getDbFields(type)

        if (!ageScopes) {
          ageScopes = (await getRangeAgeScopes()).age_scope
        }
        categories = await getCategories({
          type: targetType.originKey || targetType.menuKey || dbField.type,
          hash,
          personId: person.id,
          ids: _.get(groups, `${targetType.menuKey}.categories`, []).map(
            (item) => item.id
          )
        })

        datasets = await getDataset({
          type: targetType.originKey || targetType.menuKey || dbField.type,
          hash,
          personId: person.id,
          group: dbField.groupType
        })

        if (propsVersion === this.propsVersion) {
          const title = targetType.menuTitle
          dataset = datasets && datasets[0]
          if (type !== 'blood-test') {
            const { metricsField, biomarkerIdField } = dbField
            if (dataset && categories) {
              categories = injectCategories(
                categories,
                dataset.snapshot,
                metricsField,
                biomarkerIdField
              )
              categories = filterBiomarkerWithoutPersonValue(
                categories,
                metricsField
              )
            }
            setMobileHeaderState && setMobileHeaderState({ title })
          } else {
            setClientBiomarkerCategories(categories)
            const categoriesMenu = categories.map(
              ({ id, name, sub_categories }) => ({
                id,
                name,
                sub_categories: sub_categories.map(({ id, name }) => ({
                  id,
                  name
                }))
              })
            )
            setBiomarkerCategoriesItem(categoriesMenu)
            if (dataset && categories) {
              categories = injectRootCategories(categories, dataset.snapshot)
              for (let category of categories) {
                for (let sub_category of category.sub_categories) {
                  sub_category.bio_markers = sub_category.bio_markers.filter(
                    (item) => {
                      const personBiomarker = item.personal_data
                      return (
                        personBiomarker &&
                        (personBiomarker.prefer_conventional ||
                          personBiomarker.prefer_functional)
                      )
                    }
                  )
                }
              }
            }
            if (!category) {
              category = categories[0]
            } else {
              category = { name: decodeURI(category) }
            }
            setMobileHeaderState &&
              setMobileHeaderState({
                bioMarkersCategories: categories,
                // selectCategory: category.nmae,
                title: `${title}`
              })
            // breadcrumb = [
            //   {
            //     id: 'ClIENT_BLOOD_TEST',
            //     title: 'blood test'
            //   }
            // ]
          }

          this.setState({
            dataLoading: false,
            dbField,
            categories,
            dataset,
            title,
            breadcrumb,
            ageScopes
          })
        }
      } catch (err) {
        console.error(err)
        this.setState({
          dataLoading: false
        })
      }
    }

    turnTo = (id) => {
      const { url, history } = this.props
      const { dataset } = this.state
      history.push(`${url}/${dataset.id}/${id}`)
    }

    componentDidMount() {
      this.setState({ dataLoading: true })
      const { hash, type } = this.props
      this.initial(type, hash, this.propsVersion)
    }

    componentDidUpdate(prevProps) {
      const { type: prevType, hash: prevHash } = prevProps
      const { type, hash } = this.props
      if (prevType !== type || prevHash !== hash) {
        this.setState({ dataLoading: true, showDetail: false })
        this.propsVersion++
        this.initial(type, hash, this.propsVersion)
      }
    }

    render() {
      const { category } = this.props
      const childProps = {
        turnTo: this.turnTo,
        selectCategory: decodeURI(category)
      }
      return (
        <WrappedComponent {...this.state} {...this.props} {...childProps} />
      )
    }
  }
