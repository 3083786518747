import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import { changeLog } from '../../../api/assessmentReview'
import PageLoading from '../../../components/UI/status/loading'

const ActivityContainer = styled.div`
  font-family: Gilroy;
  font-size: 13px;
  color: #323f4a;
  letter-spacing: 0.3px;
  ul {
    padding: 0;
    list-style: none;
    li {
      display: flex;
      margin-bottom: 30px;
      & > div:last-child {
        width: calc(100% - 32px - 16px);
      }
    }
  }
  strong {
    font-family: Gilroy-Bold;
  }
  .content {
    color: #60656d;
  }
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    margin-right: 16px;
    background: #97a0a7;
  }
  .time {
    color: #97a0a7;
    font-size: 12px;
  }
`

export default function Activities(props) {
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(true)
  const { id } = props

  const processEditDate = (date) => {
    const today = moment()
    const editDate = moment(date)
    if (today && editDate) {
      if (editDate.isSame(today.format('YYYY-MM-DD'), 'day')) {
        return `Today ${editDate.format('h:mmA')}`
      } else {
        return editDate.fromNow()
      }
    }
  }

  const getLogs = async () => {
    const changeLogs = await changeLog(id)
    setLoading(false)
    setLogs(changeLogs)
  }

  if (loading) {
    getLogs()
  }

  return (
    <ActivityContainer>
      {loading ? (
        <PageLoading />
      ) : (
        <ul>
          {logs.map((item, index) => (
            <li>
              {item.avatar ? (
                <img className="avatar" src={item.avatar} alt="Avatar" />
              ) : (
                <div
                  className="avatar"
                  style={{ backgroundColor: 'rgb(233, 237, 243)' }}
                />
              )}
              <div>
                <div>
                  <strong>{item.first_name}</strong>{' '}
                  <span className="content">
                    has {item.action} {item.action === 'created' ? '' : 'the'}
                  </span>{' '}
                  <strong>
                    {item.action === 'created' ? 'New ' : ''}Comprehensive
                    Report
                  </strong>
                </div>
                <div className="time">{processEditDate(item.date)}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </ActivityContainer>
  )
}
