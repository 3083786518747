import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import RichText from '../../richText'
import { EditCircleIcon, PlusIcon } from '../../icons/weeklySummary'
import DelIcon from '../../../asserts/icon/weekly-summary/delete.svg'
import ConfirmPopUp from '../../UI/extraInformation/confirm' //'./confirm'
import { richTextStyle } from './styles/richText.sass'

class EmailRichTextEditor extends Component {
  static propTypes = {
    updateEditingCount: PropTypes.func,
    status: PropTypes.string,
    initialValue: PropTypes.string,
    updateCommend: PropTypes.func,
    icon: PropTypes.object,
    title: PropTypes.string,
    updateEmailAction: PropTypes.func
  }

  state = {
    initialValue: '',
    isEditing: false
  }

  clear = () => {
    this.setState({ contentHtml: '', initialValue: '' })
    this.props.updateCommend('', 'delete')
    this.props.updateEmailAction(1)
  }

  submit = async () => {
    const { initialValue } = this.state
    this.props.updateCommend(
      initialValue
        .replace(/<ul>/g, '<ul style="padding-left: 15px">')
        .replace(/<ol>/g, '<ol style="padding-left: 15px">'),
      'save'
    )
    this.cancel()
  }

  cancel = () => {
    this.props.updateEditingCount(-1)
    this.props.updateEmailAction(1)
    this.setState({
      isEditing: false,
      showConfirm: false,
      initialValue: this.props.initialValue
    })
  }

  componentDidMount() {
    const { initialValue } = this.props
    this.setState({ initialValue })
  }

  componentDidUpdate(prevProps) {
    const { initialValue, status } = this.props
    if (initialValue !== prevProps.initialValue) {
      this.setState({ initialValue: initialValue })
    }
    if (status === 'Locked' && this.state.isEditing) {
      this.setState({ isEditing: false })
    }
  }

  render() {
    const { icon, title, status } = this.props
    const { initialValue, isEditing, showConfirm } = this.state
    return (
      <div className={`${status} ${richTextStyle}`}>
        {!isEditing && status === 'Draft' && initialValue && (
          <div className="opr">
            <EditCircleIcon
              className="opr-button"
              onClick={() => {
                this.props.updateEditingCount(1)
                this.props.updateEmailAction(0)
                this.setState({ isEditing: true })
              }}
            />
            <img
              className="opr-button"
              onClick={() => this.clear()}
              src={DelIcon}
              alt=""
            />
          </div>
        )}
        {icon}
        <div>
          <h4>{title}</h4>

          {isEditing ? (
            <div className="editor-container">
              <RichText
                value={initialValue}
                onChange={(value) =>
                  this.setState({
                    initialValue: value
                  })
                }
                fontsizeOnly={true}
                toolbar={{
                  options: ['fontSize', 'list'],
                  list: {
                    options: ['unordered', 'ordered']
                  }
                  // inline: {
                  //   colorPicker: {
                  //     colors: [
                  //       '#70808E',
                  //       '#383E48',
                  //       '#2B4B8F',
                  //       '#1890ff',
                  //       '#7FB800',
                  //       '#E75F25'
                  //     ]
                  //   }
                  // }
                }}
              />
              <div className="buttons">
                <Button
                  type="primary"
                  ghost
                  onClick={() => this.setState({ showConfirm: true })}
                >
                  CANCEL
                </Button>
                {/* {!disMarkDone && (
                <Button type="primary" ghost onClick={this.markDone}>
                  MARK AS DONE
                </Button>
              )} */}
                <Button
                  type="primary"
                  ghost
                  onClick={this.submit}
                  // disabled={loading || overviewLoading}
                >
                  SAVE
                </Button>
              </div>
            </div>
          ) : initialValue ? (
            <p
              dangerouslySetInnerHTML={{
                __html: initialValue
              }}
            />
          ) : (
            <div
              className="null-state"
              onClick={() => {
                this.props.updateEditingCount(1)
                this.props.updateEmailAction(0)
                this.setState({ isEditing: true })
              }}
            >
              <PlusIcon />
              ADD CONTENT
            </div>
          )}

          <ConfirmPopUp
            title="Cancel editing confirmation"
            content="Changes you made to this section will not be saved. Are you sure you want to continue?"
            okText="DISCARD"
            visible={showConfirm}
            handleCancel={() => this.setState({ showConfirm: false })}
            handleOk={this.cancel}
          />
        </div>
      </div>
    )
  }
}

export default EmailRichTextEditor
