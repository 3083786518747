import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PageLoading from '../../UI/status/loading'
import DateRange from '../../UI/mgComponents/dateRange'
import ReportDashboard from './comp/dashboard'
import ListTabs from './comp/listTabs'
import NotesContainer from './comp/notesContainer'
import {
  getThisWeekRange,
  getShowWeeklyDate,
  prevWeekDate,
  nextWeekDate
} from '../../../utils/dateHandles'
import { getEmailReport, markReportAsDone } from '../../../api/summaryEmail'

import { emailReportStyle } from './styles/index.sass'

class EmailReportContainer extends Component {
  static propTypes = {
    turnTo: PropTypes.func
  }

  state = {
    loading: true,
    showNotes: false,
    notesRecord: null
  }

  prevDate = () => {
    if (this.state.loading) return
    let { startDate, endDate } = prevWeekDate(
      this.state.startDate,
      this.state.endDate
    )
    this.getShowDate(startDate, endDate)
    this.setState({
      startDate,
      endDate
    })
    this.fetchEmailData(startDate, endDate)
  }

  nextDate = () => {
    if (this.state.loading) return
    let { startDate, endDate } = nextWeekDate(
      this.state.startDate,
      this.state.endDate
    )
    this.getShowDate(startDate, endDate)
    this.setState({
      startDate,
      endDate
    })
    this.fetchEmailData(startDate, endDate)
  }

  getShowDate = (startDate, endDate) => {
    let showDate = getShowWeeklyDate(startDate, endDate)
    let hideArrow = ''
    if (showDate === 'This week') {
      hideArrow = 'right'
    }
    this.setState({
      showDate,
      hideArrow
    })
  }

  fetchEmailData = async (startDate, endDate) => {
    this.setState({ loading: true })
    const data = await getEmailReport(
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD')
    )
    this.setState({ ...data, loading: false })
  }

  markAsDone = async (emailId, status) => {
    // markReportAsDone
    const { entries } = this.state
    try {
      const index = entries.findIndex(
        (item) => item.email && item.email.id === emailId
      )
      const target = entries[index]
      const params = Object.assign(
        target.email.section_status || {
          intro: 'Draft',
          sleep: 'Draft',
          exercise: 'Draft',
          nutrition: 'Draft'
        },
        status
      )
      await markReportAsDone(emailId, params)
      target.email.section_status = params

      entries.splice(index, 1, target)
      this.setState({ entries })
    } catch (err) {
      console.error(err)
    }
  }

  openNotesModal = (notesRecord) => {
    console.log('openNotesModal', notesRecord)
    this.setState({ showNotes: true, notesRecord })
  }

  componentDidMount() {
    const thisWeekRange = getThisWeekRange()
    const { startDate, endDate } = prevWeekDate(
      thisWeekRange.startDate,
      thisWeekRange.endDate
    )
    this.getShowDate(startDate, endDate)
    this.fetchEmailData(startDate, endDate)
    this.setState({ startDate, endDate })
  }

  render() {
    const {
      showDate,
      hideArrow,
      entries,
      loading,
      total_clients,
      email_sent,
      email_opened,
      open_rate,
      not_sent,
      statistics,
      startDate,
      endDate,
      showNotes,
      notesRecord
    } = this.state
    return (
      <div className={emailReportStyle}>
        <div className="date-range">
          <DateRange
            {...{
              prevDate: this.prevDate,
              nextDate: this.nextDate,
              showDate: showDate,
              hideArrow: hideArrow
            }}
          />
        </div>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <ReportDashboard
              {...{
                total_clients,
                email_sent,
                email_opened,
                open_rate,
                not_sent,
                statistics,
                startDate,
                endDate
              }}
            />
            <ListTabs
              entries={entries}
              turnTo={this.props.turnTo}
              markAsDone={this.markAsDone}
              openNotesModal={this.openNotesModal}
            />
          </>
        )}
        {/*  todo notes */}
        {showNotes && (
          <NotesContainer
            title={`${notesRecord.client.name}'s notes`}
            closeModal={() => this.setState({ showNotes: false })}
            {...{ startDate, endDate, notesRecord }}
          />
        )}
      </div>
    )
  }
}

export default EmailReportContainer
