import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Tabs } from 'antd' // Divider
import { EditCircleIcon } from '../../../../components/icons/weeklySummary'
import { SectionStyle } from '../../../../components/questionnaire/styles/sectionStyle.js'
import _ from 'lodash'
import { SectionV2Container } from './style'

export default function Section({
  section,
  answers,
  person,
  editAnswer,
  answerVersionIndex
}) {
  const [activeKey, setActiveKey] = useState('0')
  const [questions, setQuestions] = useState([])

  useEffect(() => {
    setQuestions(
      filterQuestions(
        section,
        _.get(answers, [activeKey, 'answers'], []),
        person
      )
    )
  }, [activeKey, answers])

  const renderAnswer = (answer, sectionId, date) => {
    return (
      <>
        <Row type="flex" className="sub-title">
          <Col span={12}>Questions</Col>
          <Col span={12}>Answers</Col>
        </Row>

        {questions.map((question, index) => (
          <Row type="flex" className="container" key={index}>
            <Col span={12} className="question" data-index={question.id}>
              {question.required && <i className="required">*</i>}
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    section.original_id === 'nutrition_eating_habits' &&
                    question.question_html
                      ? question.question_html
                      : question.question
                }}
              />
            </Col>
            <Col span={12} className="answer">
              <EditCircleIcon
                onClick={() => editAnswer(question, sectionId, date)}
              />
              {answers && getAnswer(question, sectionId, answer)}
            </Col>
          </Row>
        ))}
      </>
    )
  }

  return (
    <SectionStyle>
      <SectionV2Container>
        {section.multi_answer_version ? (
          <Tabs
            defaultActiveKey="0"
            tabPosition="top"
            type="card"
            onChange={(activeKey) => {
              answerVersionIndex(activeKey)
              setActiveKey(activeKey)
            }}
            items={answers.map((answer, _index) => {
              return {
                key: _index,
                label: moment(
                  answer.completion_date || answer.record_date
                ).format('MMM DD,YYYY'),
                children: (
                  <>{renderAnswer(answer, section.id, answer.record_date)}</>
                )
              }
            })}
          />
        ) : (
          renderAnswer(_.get(answers, 0), section.id)
        )}
      </SectionV2Container>
    </SectionStyle>
  )
}

function filterQuestions(section, answers, person) {
  return section.questions.filter((item) => {
    if (
      typeof item === 'string' ||
      item.type === 'sub_title' ||
      item.type === 'banner' ||
      item.type === 'description' ||
      item.type === 'section_title'
    ) {
      return false
    }
    if (item.if) {
      for (const condition of item.if) {
        const answerObj = answers.find(
          (a) => a.question_original_id === condition.original_id
        )
        let answer = answerObj && answerObj.answer && answerObj.answer.value
        if (!answer && answer !== false) {
          answer = {}
        }
        if (
          condition.answer === answer ||
          (typeof answer === 'object' &&
            Object.keys(answer).indexOf(condition.answer.toString()) !== -1)
        ) {
          return true
        }
      }
      return false
    }
    if (item.any) {
      for (const condition of item.any) {
        let answer =
          (answers &&
            answers[section.id] &&
            answers[section.id][condition.id]) ||
          {}
        if (answer[condition.answer]) return true
      }
      return false
    }
    if (item.condition) {
      const condition = JSON.parse(item.condition)
      if (condition.gender && condition.gender !== person.profile.gender)
        return false
    }
    return true
  })
}

function getAnswer(question, sectionId, answer) {
  const answers = _.get(answer, 'answers', [])
  const answerObj = (
    answers.find(
      (answer) => answer.question_original_id === question.original_id
    ) || {}
  ).answer

  let answerElem = <div />
  let index, choice
  switch (question.type) {
    case 'text_entry':
    case 'date':
    case 'time':
    case 'number':
      answerElem = answerObj ? <i>"{answerObj.value}"</i> : answerElem
      break
    case 'single_choice':
      answerElem = answerObj && (
        <span>
          {answerObj.value}
          {answerObj.text && <i> - "{answerObj.text}"</i>}
        </span>
      )
      break
    case 'yes_no':
      if (answerObj && answerObj.value === false) answerElem = 'No'
      else if (answerObj && answerObj.value === true) answerElem = 'Yes'
      break
    case 'multi_choice':
      answerElem = (
        <div>
          {answerObj &&
            answerObj.map((item, index) => {
              if (item.sub_value) {
                choice = question.choices.find(
                  (_choice) => _choice.title === item.value
                )
              }
              return (
                <div key={index}>
                  {item.value} {item.text && <i> - "{item.text}"</i>}
                  {item.sub_value && (
                    <ul>
                      {choice.subChoices.map((_subChoice, index) => {
                        if (item.sub_value[index]) {
                          return (
                            <li key={index}>
                              {_subChoice.title.replace(
                                '%value',
                                item.sub_value[index]
                              )}
                            </li>
                          )
                        }
                        return null
                      })}
                    </ul>
                  )}
                </div>
              )
            })}
        </div>
      )
      break
    case 'label_level_bar':
    case 'level_bar':
      answerObj && (answerElem = answerObj.value)
      break
    case 'question_group':
      answerElem = getGroupAnswer(question, answerObj)
      break
    case 'slider':
      answerElem = answerObj ? <span>{answerObj.value}</span> : answerElem
      break
    case 'weekly_activiy_group':
      answerElem = getWeeklyActivityGroupAnswer(answerObj)
      break
    case 'additional_form':
      const values = answerObj && answerObj.map((i) => i.value)
      if (!_.isEmpty(values)) {
        if (_.isArray(values)) {
          answerElem = (
            <>
              {values.map((item, index) => {
                return (
                  <div className="list-group" key={index}>
                    <ul>
                      {Object.keys(item).map((key) => {
                        return (
                          <li key={key}>
                            <i>
                              {key}: "{item[key]}"
                            </i>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </>
          )
        } else {
          answerElem = (
            <div key={index}>
              <ul>
                {Object.keys(values).map((key) => {
                  return (
                    <li>
                      <i>
                        {key}: "{values[key]}"
                      </i>
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        }
      }
      break
    default:
      break
  }
  return answerElem
}

function getGroupAnswer(question, answerObj) {
  if (_.isEmpty(answerObj)) return ''
  const { sub_questions } = question
  return (
    <>
      {sub_questions.map((subQ, index) => {
        const answer = answerObj[subQ.original_id]
        if (!answer) return null
        return (
          <div key={index}>
            <strong>{subQ.question}: </strong>
            <div key={index}>
              {answer.answer.value}{' '}
              {answer.answer.text && <i> - "{answer.answer.text}"</i>}
            </div>
          </div>
        )
      })}
    </>
  )
}

function getWeeklyActivityGroupAnswer(answerObj) {
  return answerObj ? (
    Object.keys(answerObj).map((key) => {
      const day = _.capitalize(key.split('_')[0])
      const activities = answerObj[key]
      return (
        <div>
          {day}
          {activities.map((activity, index) => {
            return (
              <div>
                <ul>
                  <li>Session: {activity.session}</li>
                  <li>Duration: {activity.duration} mins</li>
                  <li>Intensity: {activity.intensity}</li>
                </ul>
              </div>
            )
          })}
        </div>
      )
    })
  ) : (
    <></>
  )
}
