import moment from 'moment'
import axios from './axios'

export const getCircuitTrainingSessions = () =>
  axios.get('/circuit_training_session/list')

export const exportSessions = () =>
  axios.get(
    `circuit_training_session/export?zero_unix=${moment()
      .startOf('day')
      .unix()}`
  )
