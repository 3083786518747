import React from 'react'
import IconDocx from '../../../asserts/icon/goals/attach-docx.svg'
import IconLink from '../../../asserts/icon/goals/attach-link.svg'
import IconPdf from '../../../asserts/icon/goals/attach-pdf.svg'
import IconVideo from '../../../asserts/icon/goals/attach-video.svg'

export default function AttachThumb(props) {
  const { attach } = props
  if (attach.type === 'url') {
    return (
      <span className="thumb link">
        <img src={IconLink} alt="link" />
      </span>
    )
  } else {
    if (attach.name.match(/(.png|.jpe?g|.gif|.bmp|.psd|.tiff|.tga|.eps)/i)) {
      return <img src={attach.oss_url} alt="thumb" className="thumb" />
    } else if (attach.name.match(/(.mp4|rmvb|flv|mpeg|avi)/i)) {
      return (
        <span className="thumb link">
          <img src={IconVideo} alt="video" />
        </span>
      )
    } else if (attach.name.match(/(.pdf)/i)) {
      return (
        <span className="thumb link">
          <img src={IconPdf} alt="pdf" />
        </span>
      )
    } else {
      return (
        <span className="thumb link">
          <img src={IconDocx} alt="doc" />
        </span>
      )
    }
  }
}
