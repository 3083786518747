import React, { Component } from 'react'
import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  UpOutlined
} from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { DragDropContext } from 'react-beautiful-dnd'
import {
  getCategories,
  deleteCategory,
  editCategory,
  createCategory
} from '../../../api/categories'
import {
  deleteBiomarker,
  createBiomarker,
  editBiomarker,
  reOrderBiomarker,
  reOrderCategory,
  reOrderRootCategory
} from '../../../api/biomarkers'
import ModalForm from '../../../components/modalFrom'
import { createCategoryForm } from '../../../models/bioMarkers.form'
import Category from './category'
import { equalJudgment } from '../../../utils/common'
import PageLoading from '../../../components/UI/status/loading'

export class BloodTest extends Component {
  static propTypes = {}

  state = {
    visible: false,
    addType: '',
    categoryId: null,
    categories: [],
    modalTitle: 'modal title',
    valueForm: {},
    loading: false,
    pageLoading: false,
    formData: createCategoryForm
  }

  initial = async () => {
    this.setState({ pageLoading: true })
    const categories = await getCategories(null, true)
    this.setState({
      categories,
      pageLoading: false
    })
  }

  componentDidMount() {
    this.initial()
  }

  findCategory = (categoryId, rootCategoryId) => {
    const { categories } = this.state
    let result
    if (rootCategoryId) {
      const rooCategory = categories.find((item) => item.id === rootCategoryId)
      result = rooCategory.sub_categories.find((item) => item.id === categoryId)
    } else {
      result = categories.find((item) => item.id === categoryId)
    }
    return result
  }

  getReOrderParams = (sourceOrder, destinationOrder, isSameOrigin, dragObj) => {
    const { dragOrigin, source, destination } = dragObj
    let params = []
    if (isSameOrigin) {
      params = sourceOrder.map(({ id, sequence }, index) => {
        if (dragOrigin.id === id) {
          return { id, sequence: destination.order }
        } else if (
          source.order < destination.order &&
          sequence > source.order &&
          sequence <= destination.order
        ) {
          return { id, sequence: --sequence }
        } else if (
          source.order > destination.order &&
          sequence < source.order &&
          sequence >= destination.order
        ) {
          return { id, sequence: ++sequence }
        }
        return { id, sequence }
      })
    } else {
      params = sourceOrder.map(({ id, sequence }) => {
        if (sequence > source.order) {
          return { id, sequence: --sequence }
        }
        return { id, sequence }
      })
      params = params.concat(
        destinationOrder.map(({ id, sequence }) => {
          if (sequence > destination.order) {
            return { id, sequence: ++sequence }
          }
          return { id, sequence }
        })
      )
    }

    return params
  }

  reOrderRootCategory = async (categoryId, upOrdown) => {
    const { categories } = this.state
    let sourceIndex = categories.findIndex((item) => item.id === categoryId)
    let params = categories.map(({ id }, index) => {
      return { id, sequence: index }
    })
    if (upOrdown === 'up') {
      params[sourceIndex - 1].sequence++
      params[sourceIndex].sequence--
    } else {
      params[sourceIndex].sequence++
      params[sourceIndex + 1].sequence--
    }
    this.setState({ reOrdering: true })
    try {
      await reOrderRootCategory(params)
      const categories = await getCategories()
      this.setState({ reOrdering: false, categories })
    } catch (err) {
      this.setState({ reOrdering: false })
    }
  }

  onDragMetricHandle = async (result) => {
    const { draggableId, destination, source } = result
    if (!source.rootCategoryId) {
      const arr = source.droppableId.split('-')
      source.rootCategoryId = parseInt(arr.pop())
    }
    if (!destination.rootCategoryId) {
      const arr = destination.droppableId.split('-')
      destination.rootCategoryId = parseInt(arr.pop())
    }
    const sourceCategory = this.findCategory(
      source.categoryId,
      source.rootCategoryId
    )
    const destinationCategory = this.findCategory(
      destination.categoryId,
      destination.rootCategoryId
    )
    const dragMetric = sourceCategory.bio_markers.find(
      (item) => item.id === Number(draggableId.split('-')[1])
    )
    let sourceOrder = sourceCategory.bio_markers,
      destinationOrder = destinationCategory.bio_markers
    // if (!sourceOrder[0].sequence) {
    sourceOrder = sourceOrder.map((item, index) => {
      return { ...item, sequence: index }
    })
    // }
    // if (destinationOrder[0] && !destinationOrder[0].sequence) {
    destinationOrder = destinationOrder.map((item, index) => {
      return { ...item, sequence: index }
    })
    // }

    const params = this.getReOrderParams(
      sourceOrder,
      destinationOrder,
      sourceOrder.id === destinationOrder.id,
      { dragOrigin: dragMetric, ...result }
    )

    try {
      if (sourceCategory.id !== destinationCategory.id) {
        await editBiomarker(dragMetric.id, {
          category_id: destinationCategory.id,
          sequence: destination.order
        })
      }
      await reOrderBiomarker(params)
      const categories = await getCategories()
      this.setState({ categories })
    } catch (err) {
      console.error(err)
    }
  }

  onDragCategoryHandle = async (result) => {
    const { draggableId, destination, source } = result
    const sourceCategory = this.findCategory(source.categoryId)
    const destinationCategory = this.findCategory(destination.categoryId)
    const dragSubCategory = sourceCategory.sub_categories.find(
      (item) => item.id === Number(draggableId.split('-')[1])
    )
    let sourceOrder = sourceCategory.sub_categories,
      destinationOrder = destinationCategory.sub_categories
    // if (!sourceOrder[0].sequence) {
    sourceOrder = sourceOrder.map(({ id }, index) => {
      return { id, sequence: index }
    })
    // }

    // if (!destinationOrder[0].sequence) {
    destinationOrder = destinationOrder.map(({ id }, index) => {
      return { id, sequence: index }
    })
    // }
    const params = this.getReOrderParams(
      sourceOrder,
      destinationOrder,
      sourceOrder.id === destinationOrder.id,
      { dragOrigin: dragSubCategory, ...result }
    )
    try {
      if (sourceCategory.id !== destinationCategory.id) {
        await editCategory(dragSubCategory.id, '', {
          parent_id: destinationCategory.id,
          sequence: destination.order
        })
      }
      await reOrderCategory(params)
      const categories = await getCategories()
      this.setState({ categories })
    } catch (err) {
      console.error(err)
    }
  }

  onDragEnd = async (result) => {
    const { destination, source, type } = result
    if (equalJudgment(source, destination)) {
      return
    }
    if (destination && source) {
      const [
        // eslint-disable-next-line no-unused-vars
        sourceType,
        sourceCategoryId
      ] = source.droppableId.split('-')
      const [
        // eslint-disable-next-line no-unused-vars
        destinationType,
        destinationCategoryId
      ] = destination.droppableId.split('-')

      source.categoryId = Number(sourceCategoryId)
      destination.categoryId = Number(destinationCategoryId)
      source.order = source.index
      destination.order = destination.index

      if (type === 'metric') {
        return await this.onDragMetricHandle(result)
      } else {
        source.rootCategoryId = Number(sourceCategoryId)
        destination.rootCategoryId = Number(destinationCategoryId)
        return await this.onDragCategoryHandle(result)
      }
    }
  }

  deleteBiomarker = (id, categoryId) => {
    deleteBiomarker(id).then((_) => {
      const { categories } = this.state
      categories.forEach((category) => {
        if (category.id === categoryId) {
          category.bio_markers = category.bio_markers.filter(
            (item) => item.id !== id
          )
        } else if (
          category.sub_categories.filter(
            (sub_category) => sub_category.id === categoryId
          ).length > 0
        ) {
          category.sub_categories.forEach((sub_category) => {
            if (sub_category.id === categoryId) {
              sub_category.bio_markers = sub_category.bio_markers.filter(
                (item) => item.id !== id
              )
            }
          })
        }
      })
      this.setState({ categories })
    })
  }

  deleteCategory = (category) => {
    let { categories } = this.state
    deleteCategory(category).then((_) => {
      let length = categories.length
      categories = categories.filter((item) => item.id !== category.id)
      if (length === categories.length) {
        categories.map((item) => {
          item.sub_categories = item.sub_categories.filter(
            (item) => item.id !== category.id
          )
          return item
        })
      }
      this.setState({ categories })
    })
  }

  editCategoryName = (name, description) => {
    const { categoryId, addType } = this.state
    editCategory(categoryId, addType, { name, description })
      .then((_) => {
        const { categories } = this.state
        let isFind = false
        categories.map((item) => {
          if (item.id === categoryId) {
            item.name = name
            item.description = description
            isFind = true
          }
          return item
        })
        if (!isFind) {
          for (let category of categories) {
            isFind = false
            const index = category.sub_categories.findIndex(
              (item) => item.id === categoryId
            )
            if (category.sub_categories[index]) {
              category.sub_categories[index].name = name
              category.sub_categories[index].description = description
              isFind = true
            }
            if (isFind) break
          }
        }
        this.setState({ categories, visible: false, loading: false })
      })
      .catch((err) => {
        console.error(err)
        this.setState({ loading: false })
      })
  }

  submit = async (params) => {
    this.setState({ loading: true })
    const { addType, categoryId, parentCategoryId, categories } = this.state
    let result, sequence, category, index, biomarkers
    try {
      switch (addType) {
        case 'category':
          sequence = categories.length
            ? Math.max(...categories.map((item) => item.sequence)) + 1
            : 0
          result = await createCategory({ ...params, sequence })
          categories.push(result.root_category)
          break
        case 'sub_category':
          params.parent_id = parentCategoryId
          category = categories.find((item) => item.id === parentCategoryId)
          sequence =
            category.sub_categories && category.sub_categories.length
              ? Math.max(
                  ...category.sub_categories.map((item) => item.sequence)
                ) + 1
              : 0
          result = await createCategory({ ...params, sequence })
          result.category.bio_markers = []
          categories
            .find((item) => item.id === parentCategoryId)
            .sub_categories.push(result.category)
          break
        case 'bio_marker':
          params.category_id = categoryId
          const rootIndex = categories.findIndex((item) =>
            item.sub_categories.find(
              (sub_categories) => sub_categories.id === categoryId
            )
          )
          category = categories[rootIndex]
          index = category.sub_categories.findIndex(
            (item) => item.id === categoryId
          )
          const targetCategory = category.sub_categories[index]
          biomarkers = targetCategory.bio_markers
          sequence = biomarkers.length
            ? Math.max(...biomarkers.map((item) => item.sequence)) + 1
            : 0
          result = await createBiomarker({
            ...params,
            sequence
          })
          biomarkers
            ? biomarkers.push(result.bio_marker)
            : (biomarkers = [result.bio_marker])
          category.sub_categories[index].bio_markers = biomarkers
          categories.splice(rootIndex, 1, category)
          break
        default:
          this.editCategoryName(params.name, params.description)
          break
      }
      this.setState({ visible: false, loading: false })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  renderPreview() {
    const { categories } = this.state
    const childProps = {
      setPropsState: (state) => this.setState({ ...state }),
      deleteCategory: this.deleteCategory,
      deleteBiomarker: this.deleteBiomarker,
      path: 'blood-test',
      history: this.props.history
    }
    return (
      <div>
        <Button
          type="primary"
          ghost
          onClick={() =>
            this.setState({
              modalTitle: 'Create a category',
              addType: 'category',
              valueForm: {},
              visible: true,
              formData: createCategoryForm
            })
          }
        >
          Add Category{' '}
        </Button>
        {categories &&
          categories.map((item, index) => (
            <div key={index} className="category">
              <h5>
                <Popover
                  placement="bottomLeft"
                  content={
                    <p style={{ maxWidth: '200px' }}>
                      {item && item.description}
                    </p>
                  }
                >
                  <span>{item.name}</span>
                </Popover>
                {(!item.bio_markers || !item.bio_markers.length) &&
                  (!item.sub_categories || !item.sub_categories.length) && (
                    <Button
                      icon={<CloseOutlined />}
                      danger
                      onClick={(_) => {
                        this.deleteCategory(item)
                      }}
                    />
                  )}
                <Button
                  onClick={(_) =>
                    this.setState({
                      visible: true,
                      addType: 'edit root category',
                      valueForm: item,
                      categoryId: item.id,
                      modalTitle: 'Edit category\'s name',
                      formData: createCategoryForm
                    })
                  }
                >
                  <EditOutlined />
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() =>
                    this.setState({
                      visible: true,
                      addType: 'sub_category',
                      valueForm: {},
                      parentCategoryId: item.id,
                      modalTitle: `Add sub category for ${item.name}`,
                      formData: createCategoryForm
                    })
                  }
                >
                  Add Sub Category
                </Button>
                {index !== 0 && (
                  <Button
                    type="primary"
                    ghost
                    className="white-btn"
                    onClick={() => this.reOrderRootCategory(item.id, 'up')}
                    disabled={this.state.reOrdering}
                  >
                    <UpOutlined />
                  </Button>
                )}
                {index !== categories.length - 1 && (
                  <Button
                    type="primary"
                    ghost
                    className="white-btn"
                    onClick={() => this.reOrderRootCategory(item.id, 'down')}
                    disabled={this.state.reOrdering}
                  >
                    <DownOutlined />
                  </Button>
                )}
              </h5>
              <div>
                <Category category={item} {...childProps} />
              </div>
            </div>
          ))}

        <ModalForm
          title={this.state.modalTitle}
          visible={this.state.visible}
          formData={this.state.formData}
          valueForm={this.state.valueForm || {}}
          onSubmit={this.submit}
          onCancel={() => this.setState({ visible: false })}
          loading={this.state.loading}
        />
      </div>
    )
  }

  render() {
    const { pageLoading, categories } = this.state
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {pageLoading && categories ? <PageLoading /> : this.renderPreview()}
      </DragDropContext>
    )
  }
}

export default BloodTest
