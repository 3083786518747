import React, { useEffect, useState } from 'react'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { style } from './index.sass'

const toolbar = {
  options: ['inline', 'fontSize', 'list', 'link']
}

function removeHTMLTag(str) {
  str = str.replace(/<\/?[^>]*>/g, '')
  str = str.replace(/[ | ]*\n/g, '\n')
  str = str.replace(/\n[\s| | ]*\r/g, '\n')
  str = str.replace(/&nbsp;/gi, '')

  console.log('str: ', str)
  return str
}

export default function RichText({
  value,
  onChange,
  fontsizeOnly,
  toolbar: customToolbar,
  toolbarHidden
}) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const initial = () => {
    const contentBlock = htmlToDraft(value || '')
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }

  useEffect(() => {
    const currentContent = editorState.getCurrentContent()
    const rawContent = convertToRaw(currentContent)
    const currentHtml = draftToHtml(handleExtraStyle(rawContent))

    console.log('RawContent HTML:', rawContent)
    console.log('Converted HTML:', currentHtml)
    console.log('Value:', value)

    if (currentHtml !== value) {
      initial()
    }
  }, [value])

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue)
    }
  }

  const handleExtraStyle = (content) => {
    content.blocks = content.blocks.map((block) => {
      block.inlineStyleRanges = block.inlineStyleRanges.map((item) => {
        if (item.style.includes('fontsize')) {
          if (item && !item.style.includes('px')) {
            item.style = [
              'fontsize',
              parseInt(item.style.split('-')[1]) + 'px'
            ].join('-')
          }
        }
        return item
      })
      if (fontsizeOnly) {
        block.inlineStyleRanges = block.inlineStyleRanges.filter((item) =>
          item.style.includes('fontsize')
        )
      }
      return block
    })
    return content
  }

  const onEditorStateChange = (newEditorState) => {
    const html = draftToHtml(
      handleExtraStyle(convertToRaw(newEditorState.getCurrentContent()))
    )
    if (!!removeHTMLTag(html).trim()) {
      triggerChange(html)
    } else {
      triggerChange('')
    }
    setEditorState(newEditorState)
  }

  return (
    <div className={`${style} rich-text-container`}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar"
        wrapperClassName="wrapper"
        editorClassName="editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={customToolbar || toolbar}
        toolbarHidden={toolbarHidden}
        spellCheck={true}
      />
    </div>
  )
}

/*
export class RichText extends Component {
  static propTypes = {
    value: PropTypes.string,
    extraAttr: PropTypes.object
  }

  state = {
    editorState: EditorState.createEmpty()
  }

  initial = () => {
    const contentBlock = htmlToDraft(this.props.value || '')
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      )
      const editorState = EditorState.createWithContent(contentState)
      this.setState({ editorState })
    }
  }

  componentDidMount() {
    this.initial()
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  handleExtraStyle = (content) => {
    const { fontsizeOnly } = this.props
    content.blocks = content.blocks.map((block) => {
      block.inlineStyleRanges = block.inlineStyleRanges.map((item) => {
        if (item.style.includes('fontsize')) {
          if (item && !item.style.includes('px')) {
            item.style = [
              'fontsize',
              parseInt(item.style.split('-')[1]) + 'px'
            ].join('-')
          }
        }
        return item
      })
      if (fontsizeOnly) {
        block.inlineStyleRanges = block.inlineStyleRanges.filter((item) =>
          item.style.includes('fontsize')
        )
      }
      // if (!toolbar.options.find((option) => option === 'color')) {
      //   block.inlineStyleRanges = block.inlineStyleRanges.filter(
      //     (item) => !item.style.includes('color')
      //   )
      // }
      return block
    })
    return content
  }

  onEditorStateChange = (editorState) => {
    const html = draftToHtml(
      this.handleExtraStyle(convertToRaw(editorState.getCurrentContent()))
    )
    if (!!removeHTMLTag(html).trim()) {
      this.triggerChange(html)
      // console.log('onEditorStateChange', editorState.getCurrentInlineStyle())
      // if (editorState.getLastChangeType() === 'insert-characters') {
      //   EditorState.set(editorState, [])
      //   const contentBlock = htmlToDraft(html)
      //   if (contentBlock) {
      //     const contentState = ContentState.createFromBlockArray(
      //       contentBlock.contentBlocks
      //     )
      //     const selection = editorState.getSelection()
      //     // editorState = EditorState.createWithContent(contentState)
      //     // EditorState.forceSelection(editorState, selection)
      //     // EditorState.forceSelection(editorState, selection)
      //   }
      // }
    } else {
      this.triggerChange('')
    }
    this.setState({ editorState })
  }

  render() {
    return (
      <div className={`${style} rich-text-container`}>
        <Editor
          editorState={this.state.editorState}
          toolbarClassName="toolbar"
          wrapperClassName="wrapper"
          editorClassName="editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={this.props.toolbar || toolbar}
          toolbarHidden={this.props.toolbarHidden}
        />
      </div>
    )
  }
}

export default RichText
*/
