import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import moment from 'moment'
import Information from '../UI/extraInformation'
import CheckDocs from './comp/checkDots'
import BodyMass from './comp/bodyMass'
import NormalBar from './comp/normalBar'
import SubTip from './comp/subTip'
import { calculateElementWidth } from './common'
import { SvgText } from '../icons/weeklySummary'
import { nutrition } from './directions.json'
import { nutritionStyle } from './styles/nutrition.sass'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const gray = '#b7bfc6',
  // green = '#7BAD2D',
  // red = '#FF6B00',

  // normal = '#70808E',
  primary = '#383E48'

class Nutrition extends Component {
  static propTypes = {
    goals: PropTypes.array
  }

  renderGoal(item, index, bottomLine) {
    const {
      deleteNutrition,
      startDate,
      endDate,
      originInformation,
      templates,
      loginRole,
      setSubmitData,
      updateEditingCount
    } = this.props
    if (item.description.toLowerCase().includes('weight')) {
      const latest = item.details[0]
      if (latest) {
        item.showLatestData = latest
      } else {
        item.showLatestData = { summary_date: null, value: '--' }
      }
    }

    const SevenDayText =
      item.average || item.average === 0
        ? item.input_type === 'binary'
          ? Math.round(item.average * 100) + '%'
          : item.showLatestData
          ? item.showLatestData.value
          : item.average
        : '--'
    return (
      <div className="goal-item" key={index}>
        <div
          className="delete"
          onClick={() =>
            deleteNutrition('nutrition', item.id, item.description)
          }
        >
          <img className="delete-icon" src={DelIcon} alt="del" />
        </div>
        <div className="name">{item.description}</div>

        <div
          className="data-content ref-container"
          ref={`nutrition.chart_url.${item.id}`}
        >
          <div>
            <div className="days">
              <SvgText
                text={SevenDayText}
                height={33}
                fontSize={30}
                width={
                  calculateElementWidth('span', SevenDayText, '30px').width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
              <SubTip
                text={
                  item.showLatestData
                    ? item.showLatestData.summary_date
                      ? `${moment(item.showLatestData.summary_date).format(
                          'MMM DD, YYYY'
                        )}`
                      : '--'
                    : '7 DAY AVG'
                }
                unit={item.unit}
              />
            </div>
            <div className="weeks">
              <SvgText
                text={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? item.input_type === 'binary'
                      ? Math.round(item.previous_weeks_average * 100) + '%'
                      : item.previous_weeks_average
                    : '--'
                }
                fill={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? gray
                    : primary
                }
                fontSize={30}
                height={33}
                width={
                  calculateElementWidth(
                    'span',
                    item.previous_weeks_average ||
                      item.previous_weeks_average === 0
                      ? item.input_type === 'binary'
                        ? Math.round(item.previous_weeks_average * 100) + '%'
                        : item.previous_weeks_average
                      : '--',
                    '30px'
                  ).width + 5
                }
                textAttr={{
                  x: 0,
                  textAnchor: 'start',
                  dominantBaseline: 'mathematical'
                }}
              />
              <SubTip text="3-WEEK AVG" unit={item.unit} />
            </div>
          </div>
          <div className="chart">
            {item.input_type === 'binary' ? (
              <CheckDocs
                size="large"
                showLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            ) : item.description.toLowerCase().includes('weight') ? (
              <BodyMass
                showXLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            ) : (
              <NormalBar
                barWidth={16}
                showXLabel={true}
                showLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            )}
          </div>
        </div>
        <Information
          updateEditingCount={updateEditingCount}
          status={originInformation && originInformation.status}
          title="Custom Text"
          templates={templates}
          showDone={loginRole !== 'User'}
          initialValue={item.custom_text}
          doneStatus={item.custom_text_status}
          updateCommend={(value, status) =>
            setSubmitData({
              part: 'nutrition',
              field: 'custom_text',
              value,
              status,
              id: item.id,
              position: `Nutrition - ${item.description}`
            })
          }
        />
        {bottomLine && <Divider />}
      </div>
    )
  }

  render() {
    const {
      setSubmitData,
      originInformation,
      submitData,
      goals,
      loginRole,
      templates,
      updateEditingCount
    } = this.props

    const { deleted_nutritions } = submitData

    const renderGoals = goals.filter(
      (item) => !deleted_nutritions.find((id) => id === item.id)
    )
    // .sort((a, b) => {
    //   if (a.description.toLowerCase().includes('weight')) {
    //     return -1
    //   } else {
    //     return 0
    //   }
    // })

    return (
      <div className={nutritionStyle}>
        <div style={{ marginBottom: 20 }}>
          <Information
            direction={nutrition}
            updateEditingCount={updateEditingCount}
            status={originInformation && originInformation.status}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            initialValue={submitData.nutrition_recommendation}
            doneStatus={submitData.nutrition_recommendation_status}
            updateCommend={(value, status) =>
              setSubmitData({
                part: null,
                field: 'nutrition_recommendation',
                value,
                status,
                position: 'Nutrition'
              })
            }
          />
        </div>
        {renderGoals.map((item, index) =>
          this.renderGoal(item, index, index !== renderGoals.length - 1)
        )}
      </div>
    )
  }
}

export default Nutrition
