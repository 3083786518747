import * as actionType from './action-type'

export const setClientBreadcrumb = (breadcrumb) => ({
  type: actionType.SET_CLIENT_BREADCRUMB,
  payload: { breadcrumb }
})

export const setClient = (person) => ({
  type: actionType.SET_CLIENT_PEOPLE,
  payload: { person }
})

export const setClientPageTitle = (pageTitle) => ({
  type: actionType.SET_CLIENT_PAGE_TITLE,
  payload: { pageTitle }
})

export const setClientBiomarker = (biomarker) => ({
  type: actionType.SET_CLIENT_BIOMARKER,
  payload: { biomarker }
})

export const setClientBiomarkerCategories = (categories) => ({
  type: actionType.SET_CLIENT_BIOMARKER_CATEGORIES,
  payload: { categories }
})

export const setClientSelecCategory = (category) => ({
  type: actionType.SET_CLIENT_SELECT_CATEGORY,
  payload: { category }
})

export const setClientBloodTestID = (bloodTestId) => ({
  type: actionType.SET_CLIENT_REPORT_ID,
  payload: { bloodTestId }
})

export const setClientBodyCompId = (bodyCompId) => ({
  type: actionType.SET_CLIENT_BODY_BOMP_ID,
  payload: { bodyCompId }
})

export const setClientLifeStyleId = (lifeStyleId) => ({
  type: actionType.SET_CLIENT_LIFE_STYLE_ID,
  payload: { lifeStyleId }
})

export const setClientUnreadCount = (unreadCount) => ({
  type: actionType.SET_CLIENT_UNREAD_MESSAGE_COUNT,
  payload: { unreadCount }
})

export const setClientLeftNavigateBarHidden = (hidden) => ({
  type: actionType.SET_CLIENT_LET_NAVIGATE_BAR_HIDDEN,
  payload: { hidden }
})
