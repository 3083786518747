import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider, Button, Row, Col, Tag } from 'antd'
import ProgramWrapper from './wrapper'
import { EditPanIcon } from '../icons'
import DateRange from '../UI/mgComponents/dateRange'
import TodayTag from '../UI/todayTag'
import ProgramEditor from './comp/programEditor'
import Confirm from '../UI/confirmModal'
import ExerciseProgram from './comp/exerciseProgram'
import NutritionProgram from './comp/nutritionProgram'
import RejuvenationProgram from './comp/rejuvenationProgram'
import NotifyConfirm from './comp/notifyConfirm'
import GoalDetail from '../goals/detail'
import { PublishIcon } from '../icons/program'
import PageLoading from '../UI/status/loading'
import Empty from '../UI/status/empty'
import { getRole } from '../../utils/common'
import ActionItemEditor from './comp/actionItemEditor'
import { programStyle } from './style/index.sass'
import EmptyImage from '../../asserts/images/empty-status.png'

class ProgramDashboard extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object
  }

  state = {
    showProgramEditor: false,
    showPublishConfirm: false,
    showNotifyConfirm: false,
    showExerciseEditor: false,
    pageLoading: true,
    program: null,
    showDate: ''
  }

  EditCallback = () => {
    const { fetchProgram, weekRange } = this.props
    this.setState({ showProgramEditor: false, showExerciseEditor: false })
    fetchProgram(weekRange)
  }

  publish = async () => {
    this.setState({ isPublishing: true })
    await this.props.publish()
    this.setState({ isPublishing: false, showPublishConfirm: false })
  }

  activityDetailOpr = ({ visible }) => {
    const { editGoal } = this.props
    if (visible === 4) {
      /* delete nutrition/rejuvenation item */
      this.setState({
        confirmDeleteActionItem: true
      })
    } else if (visible === 2) {
      /* edit nutrition/rejuvenation item */
      const pillar = editGoal.category.pillar
      this.setState({
        showActionItemEditor: pillar
      })
    } else if (visible === 0) {
      this.setState({ showActivityDetail: false })
    }
  }

  deleteAction = async () => {
    const { editGoal, deleteGoal } = this.props
    await deleteGoal(editGoal)
    this.setState({ confirmDeleteActionItem: false, showActivityDetail: false })
  }

  addItem = (pillar) => {
    this.props.setEditGoal(null)
    this.setState({ showActionItemEditor: pillar })
  }

  setGlobalState = (state) => {
    if (state.editGoal) {
      this.props.setEditGoal(state.editGoal)
    }
    this.setState({ ...state })
  }

  componentDidMount() {
    const { status } = this.props.program || {}
    if (this.props.position !== 'clientView' && status) {
      window.addEventListener('beforeunload', this.props.beforeunload)
    }
  }

  componentWillUnmount() {
    const { status } = this.props.program || {}
    if (this.props.position !== 'clientView' && status) {
      window.removeEventListener('beforeunload', this.props.beforeunload)
    }
  }

  renderWeekPosition() {
    const { program, weekRange } = this.props
    const from = moment(program.from),
      to = moment(program.to)
    const totalWeek = to.diff(from, 'week') + 1
    const currentWeek =
      weekRange &&
      moment(weekRange.startDate.format('YYYY-MM-DD')).diff(from, 'week') + 1

    if (currentWeek < 1) return ''
    return ` • Week ${currentWeek} of ${totalWeek}`
  }

  render() {
    const {
      program,
      pageLoading,
      showDate,
      hideArrow,
      errorMessage,
      weekRange,
      prevDate,
      nextDate,
      checkPublishable,
      nutrition,
      rejuvenation,
      editGoal
      // experts,
      // changeExpert
    } = this.props
    const {
      confirmDeleteActionItem,
      showProgramEditor,
      showPublishConfirm,
      isPublishing,
      isDeleting,
      showActionItemEditor,
      showActivityDetail,
      targetDate,
      showNotifyConfirm
    } = this.state
    const publishable = checkPublishable()
    const loginRole = getRole()
    const { published_at, updated_at } = program || {}
    const position =
      this.props.position === 'clientView' || loginRole === 'Service_Provider'
        ? 'clientView'
        : ''
    return (
      <div className={programStyle}>
        <div className="detail-top">
          <div className="title">
            {program && (
              <>
                {program.name}{' '}
                <Tag color={program.status === 1 ? '#7BAD2D' : '#A5A8AD'}>
                  {program.status === 1 ? 'Active' : 'Inactive'}
                </Tag>
                {/* <span className="date-range">
                  {moment(program.from).format('MMM D')} -{' '}
                  {moment(program.to).format('MMM D, YYYY')}
                  {this.renderWeekPosition()}
                </span> */}
              </>
            )}
          </div>
        </div>
        <Divider />
        {pageLoading ? (
          <PageLoading />
        ) : program ? (
          <>
            <div className="date-top-bar">
              <DateRange
                prevDate={prevDate}
                nextDate={nextDate}
                showDate={showDate}
                hideArrow={hideArrow}
              />
              <TodayTag />
            </div>
            <Row className="detail-container" type="flex" gutter={16}>
              <Col span={24}>
                <div className="detail-description">
                  <h4>
                    <img
                      src="https://public-source.s3.us-west-2.amazonaws.com/icons/icon-program-goal.svg"
                      alt="icon"
                    />
                    Program goal
                  </h4>
                  <pre>{program.description}</pre>
                </div>
              </Col>
              {/* <Col span={6}>
                <div
                  className={`detail-description select-expert ${
                    program.draft_data && program.draft_data.expert
                      ? 'draft'
                      : ''
                  }`}
                >
                  <SelectExperts
                    releation={{ pillar: 'nutrition' }}
                    experts={experts}
                    expert={
                      loginRole === 'Expert' || loginRole === 'InternalAdmin'
                        ? (program.draft_data && program.draft_data.expert) ||
                          program.expert
                        : program.expert
                    }
                    changeExpert={changeExpert}
                  />
                  {position === 'clientView' &&
                    loginRole !== 'Service_Provider' && (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => this.props.history.push('/app/message')}
                      >
                        Chat with your team
                      </Button>
                    )}
                </div>
              </Col> */}
            </Row>
            <ExerciseProgram
              {...{
                ...this.props,
                position,
                EditCallback: this.EditCallback
              }}
            />
            {nutrition && (
              <NutritionProgram
                {...{
                  ...this.props,
                  position,
                  setGlobalState: this.setGlobalState,
                  addItem: this.addItem
                }}
              />
            )}
            {rejuvenation && (
              <RejuvenationProgram
                {...{
                  ...this.props,
                  position,
                  setGlobalState: this.setGlobalState,
                  addItem: this.addItem
                }}
              />
            )}
          </>
        ) : errorMessage ? (
          <Empty message={errorMessage} emptyImage={EmptyImage} />
        ) : null}
        {showProgramEditor && (
          <ProgramEditor
            cancel={() => this.setState({ showProgramEditor: false })}
            program={program}
            personId={this.props.person.id}
            confirmCallback={this.EditCallback}
            deleteCallback={() => this.props.deleteCallback(this.props.history)}
          />
        )}

        {showPublishConfirm && (
          <Confirm
            {...{
              modalWidth: 600,
              // title: 'Notify client confirmation',
              // description:
              //   'Do you want to notify the client of the recent changes?',
              // confirmText: 'CONFIRM',
              title: 'Publish program confirmation',
              description: 'Do you want to publish this program?',
              confirmText: 'PUBLISH',
              loading: isPublishing,
              onConfirm: this.publish,
              onCancel: () => this.setState({ showPublishConfirm: false })
            }}
          />
        )}

        {/* add nutrition and rejuvenation action item */}
        {showActionItemEditor && (
          <ActionItemEditor
            {...this.props}
            closeModal={() => this.setState({ showActionItemEditor: null })}
            pillar={showActionItemEditor}
            editGoal={editGoal || {}}
          />
        )}

        {showActivityDetail && (
          <GoalDetail
            role={loginRole}
            {...this.props}
            {...weekRange}
            setGlobalState={this.setGlobalState}
            openModal={this.activityDetailOpr}
            targetDate={targetDate}
            editGoal={editGoal}
            statistics={{ nutrition, rejuvenation }}
          />
        )}

        {confirmDeleteActionItem && (
          <Confirm
            {...{
              onCancel: () => this.setState({ confirmDeleteActionItem: false }),
              description: `This is "${editGoal.micro_goal}" belongs to ${editGoal.category.pillar}`,
              title: 'Are you sure you want to delete ?',
              onConfirm: this.deleteAction,
              // disabled:isDeleting,
              loading: isDeleting
            }}
          />
        )}

        {showNotifyConfirm && (
          <NotifyConfirm
            modalWidth={600}
            onCancel={() => this.setState({ showNotifyConfirm: false })}
            onConfirm={async (message) => {
              await this.props.notifyClient(message)
              this.setState({ showNotifyConfirm: false })
            }}
          />
        )}
        {program && position !== 'clientView' && (
          <footer>
            <div>
              {published_at &&
                `Last published ${moment(published_at).format(
                  'MMM DD, YYYY [at] H:mm A'
                )} ${updated_at && '• '}`}
              {updated_at &&
                `Last updated ${moment(updated_at).format(
                  'MMM DD, YYYY [at] H:mm A'
                )}`}
            </div>
            <div>
              <Button
                type="primary"
                ghost
                onClick={() => this.setState({ showProgramEditor: true })}
              >
                <EditPanIcon />
                EDIT PROGRAM
              </Button>
              <Button
                type="primary"
                disabled={!publishable}
                onClick={() => this.setState({ showPublishConfirm: true })}
              >
                <PublishIcon /> PUBLISH
              </Button>
              <Button
                type="primary"
                disabled={publishable}
                onClick={() => this.setState({ showNotifyConfirm: true })}
              >
                <PublishIcon /> NOTIFY CLIENT
              </Button>
            </div>
          </footer>
        )}
      </div>
    )
  }
}

export default ProgramWrapper(ProgramDashboard)
