import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined, PaperClipOutlined } from '@ant-design/icons'
import { Card, Col, Row, Upload, Button, Progress, Typography } from 'antd'
import axios from 'axios'
import moment from 'moment'
import TouchInput from '../../../components/business/creport/touchInput'
import Dragger from '../../../components/business/files/dragger'
import FileDetail from './fileDetail'
import playIcon from '../../../asserts/icon/button-play.svg'
import folderIcon from '../../../asserts/icon/folder.svg'
import './index.sass'

const { Paragraph } = Typography

export class ReportFiles extends Component {
  static propTypes = {
    files: PropTypes.array,
    uploadIcon: PropTypes.any,
    introduction: PropTypes.string,
    save: PropTypes.func,
    upload: PropTypes.func,
    deleteFile: PropTypes.func,
    readOnly: PropTypes.bool
  }

  state = {
    uploading: false,
    percent: 0,
    source: null,
    showDetail: false,
    selectFile: null,
    type: ''
  }

  graggered = (file, progressCallback, source = null) => {
    const { upload } = this.props
    upload(file, progressCallback, source)
  }

  upload = (request) => {
    const { uploading } = this.state
    const { upload } = this.props
    if (uploading) return
    try {
      const { file } = request
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ uploading: true, source })
      upload(
        file,
        (percent) => {
          this.setState({ percent })
          if (percent === 100) {
            this.setState({ uploading: false })
          }
        },
        source
      )
    } catch (err) {
      console.error(err)
    }
  }

  cancel = (event) => {
    event.stopPropagation()
    const { source } = this.props
    source && source.cancel()
    this.setState({ uploading: false, percent: 0 })
  }

  renderCover(file) {
    const { name } = file
    const splits = name.split('.')
    const type = splits[splits.length - 1]
    if (type.match(/png|jp?eg|gif|heic/i)) {
      return (
        <img
          alt="example"
          src={file.oss_url}
          onClick={() =>
            this.setState({
              type: 'image',
              selectFile: file,
              showDetail: true
            })
          }
        />
      )
    } else if (type.match(/mp4|WebM|ogg/i)) {
      return (
        <div
          className="video-thum"
          onClick={() =>
            this.setState({
              type: 'video',
              selectFile: file,
              showDetail: true
            })
          }
        >
          <img src={playIcon} alt="video" />
        </div>
      )
    } else {
      return (
        <a href={file.oss_url} target="_blank" rel="noopener noreferrer">
          <div className="file-content">
            <img src={folderIcon} alt="file icon" />
          </div>
        </a>
      )
    }
  }

  render() {
    const {
      files,
      introduction,
      save,
      deleteFile,
      uploadIcon,
      readOnly
    } = this.props
    const { uploading, percent, selectFile, showDetail, type } = this.state
    return (
      <div className="report-files">
        <h1 className="section-sub-title">
          Files
          {!readOnly && files.length > 0 && (
            <Upload
              showUploadList={false}
              customRequest={this.upload}
              className="upload-btn"
            >
              <Button type="primary" ghost>
                <PaperClipOutlined rotate={180} /> ADD FILE
              </Button>
            </Upload>
          )}
        </h1>
        {readOnly ? (
          <pre>{introduction}</pre>
        ) : (
          <TouchInput
            initialValue={introduction}
            placeholder="click to Add introduction"
            save={save}
          />
        )}
        <div>
          {files.length === 0 ? (
            !readOnly && (
              <Dragger upload={this.graggered} uploadIcon={uploadIcon} />
            )
          ) : (
            <Row gutter={16}>
              {files.map((item, index) => (
                <Col span={6} key={index}>
                  <Card
                    bordered={false}
                    className="files-card"
                    cover={
                      <div className="card-cover">
                        {/* <img alt="example" src={item.oss_url} /> */}
                        {this.renderCover(item)}
                      </div>
                    }
                  >
                    <div className="card-content">
                      <div className="file-info">
                        <Paragraph ellipsis>{item.name}</Paragraph>
                        <time>
                          {moment(item.created_at).format('MM/D/YYYY HH:mm')}
                        </time>
                      </div>
                      {!readOnly && (
                        <DeleteOutlined
                          onClick={() => {
                            deleteFile(item.id)
                          }} />
                      )}
                    </div>
                  </Card>
                </Col>
              ))}
              {uploading && (
                <Col span={6}>
                  <Card
                    bordered={false}
                    cover={
                      <div>
                        <Progress
                          percent={percent}
                          status={'active'}
                          showInfo={false}
                        />
                      </div>
                    }
                  >
                    uploading... {percent}%{' '}
                    <Button type="primary" ghost onClick={this.cancel}>
                      cancel
                    </Button>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </div>
        {showDetail && (
          <FileDetail
            file={selectFile}
            type={type}
            cancel={() =>
              this.setState({ selectFile: null, showDetail: false })
            }
          />
        )}
      </div>
    )
  }
}

export default ReportFiles
