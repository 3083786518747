import axios, { hashRequest } from './axios'
import { getDatasetById } from './dataset'

export const getMetricData = async (id, type, datasetId, personID, hash) => {
  let metric, dataset
  switch (type) {
    case 'body-comp':
    case 'body_comps':
    case 'vitals':
    case 'bone-density':
      metric = await getMetricById(id, hash, 'body_comps')
      metric = metric.body_comp
      dataset = await getDatasetById('body_comp', datasetId, personID, hash)
      break

    case 'physical':
    case 'movement-quality':
    case 'muscle-fitness':
      metric = await getMetricById(id, hash, 'muscle_fitness')
      metric = metric.physical_assessment
      dataset = await getDatasetById(
        'physical_assessment',
        datasetId,
        personID,
        hash
      )
      break
    case 'cardiorespiratory':
    case 'aerobic-fitness':
      metric = await getMetricById(id, hash, 'aerobic_fitness')
      metric = metric.cardiorespiratory
      dataset = await getDatasetById(
        'cardiorespiratory',
        datasetId,
        personID,
        hash
      )
      break
    case 'cognitive-health':
      metric = await getMetricById(id, hash, 'cognitive_health')
      metric = metric.cognitive_health
      dataset = await getDatasetById(
        'cognitive_health',
        datasetId,
        personID,
        hash
      )
      break
    default:
      break
  }

  return { metric, dataset }
}

const getHistory = async (id, person_id, hash, type) => {
  const url = `${getPath(type)}/${id}/history?person_id=${person_id}&limit=8`
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  } else {
    return axios.get(url)
  }
}

export const getMetricHistories = async (id, type, personId, hash) =>
  await getHistory(id, personId, hash, type)

function getPath(type) {
  let path = ''
  switch (type) {
    case 'body-comps':
    case 'body-comp':
    case 'body_comps':
    case 'body_comp':
    case 'vitals':
    case 'bone_density':
    case 'bone-density':
      path = '/body_comps'
      break
    case 'movement-quality':
    case 'movement_quality':
    case 'muscle-fitness':
    case 'muscle_fitness':
    case 'physical_assessments':
      path = '/physical_assessments'
      break
    case 'aerobic_fitness':
    case 'aerobic-fitness':
      path = '/cardiorespiratories'
      break
    case 'cognitive-health':
    case 'cognitive_health':
      path = '/cognitive_healths'
      break
    default:
      break
  }
  return path
}

export const getMetricById = async (id, hash, type) => {
  const url = `${getPath(type)}/${id}`
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    result = result.data
  } else {
    result = await axios.get(url)
  }
  return result
}

export const getCategories = async (type, ids) => {
  let url = `${getPath(type)}/categories`
  if (ids) {
    url += `?category_ids=${ids.join(',')}`
  }

  const result = await axios.get(url)
  return result.categories
}

export const createCategory = async (params, type, group) => {
  let url = `${getPath(type)}/categories`
  if (group) {
    params.group = group
    // url += `?group=${group}`
  }
  const result = await axios.postJson(url, params)
  return result.category
}

export const editCategory = async (params, id, type) => {
  const result = await axios.putJson(
    `${getPath(type)}/categories/${id}`,
    params
  )
  return result.category
}

export const createMetric = async (params, id, type, field) => {
  params.category_id = id
  const url = `${getPath(type)}`
  const result = await axios.postJson(url, params)
  return result[field]
}

export const editMetric = async (params, id, type, field) => {
  const url = `${getPath(type)}/${id}`
  const result = await axios.putJson(url, params)
  return result[field]
}

export const delCategory = (id, type) =>
  axios.delete(`${getPath(type)}/categories/${id}`)

export const delMetric = (id, type) => axios.delete(`${getPath(type)}/${id}`)

export const reOrderMetric = async (params, type) =>
  axios.putJson(`${getPath(type)}/order`, params)

export const reOrderCategory = async (params, type) =>
  axios.put(`${getPath(type)}/categories/order`, params)
