import React from 'react'
import Icon from '@ant-design/icons'

export const AddButtonArrow = (props) => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3.39419L7.11125 0L8 0.969572L4 5.33333L0 0.969572L0.888749 0L4 3.39419Z"
        fill={props.fill || '#9AA5B1'}
      />
    </svg>
  )
}

export const CategoryLeftArrow = () => (
  <svg
    width="4"
    height="6"
    viewBox="0 0 4 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4.95L2.07407 3L4 1.05L2.96296 -9.06606e-08L2.62268e-07 3L2.96296 6L4 4.95Z"
      fill="#797E85"
    />
  </svg>
)

export const CategoryRightArrow = () => (
  <svg
    width="4"
    height="6"
    viewBox="0 0 4 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.05L1.92593 3L0 4.95L1.03704 6L4 3L1.03704 0L0 1.05Z"
      fill="#797E85"
    />
  </svg>
)

export const TreeSwitcherArrow = () => {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.6"
        d="M4.5 1.81795L0.999842 5L-2.8775e-08 4.09103L4.5 1.00509e-06L9 4.09103L8.00016 5L4.5 1.81795Z"
        fill="#9AA5B1"
      />
    </svg>
  )
}

export const VsDiscardIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.208953 0.218063C0.342786 0.0841552 0.524278 0.00892986 0.713518 0.00892986C0.902757 0.00892986 1.08425 0.0841552 1.21808 0.218063L4.99554 3.99878L8.77299 0.218063C8.83883 0.149842 8.91758 0.0954256 9.00465 0.0579906C9.09172 0.0205556 9.18537 0.000851124 9.28013 2.69693e-05C9.37489 -0.000797185 9.46886 0.0172753 9.55657 0.0531902C9.64428 0.0891051 9.72396 0.142143 9.79097 0.209209C9.85798 0.276275 9.91097 0.356027 9.94686 0.44381C9.98274 0.531593 10.0008 0.62565 9.99997 0.720492C9.99915 0.815335 9.97946 0.909063 9.94206 0.996209C9.90466 1.08335 9.85029 1.16217 9.78212 1.22806L6.00467 5.00878L9.78212 8.78949C9.91213 8.92421 9.98406 9.10464 9.98243 9.29192C9.98081 9.4792 9.90575 9.65836 9.77343 9.79079C9.64111 9.92322 9.46211 9.99835 9.27499 9.99997C9.08787 10.0016 8.90759 9.9296 8.77299 9.79949L4.99554 6.01878L1.21808 9.79949C1.08348 9.9296 0.903208 10.0016 0.716086 9.99997C0.528964 9.99835 0.349967 9.92322 0.217646 9.79079C0.0853262 9.65836 0.0102701 9.4792 0.00864407 9.29192C0.00701803 9.10464 0.0789523 8.92421 0.208953 8.78949L3.98641 5.00878L0.208953 1.22806C0.0751606 1.09411 0 0.912466 0 0.723063C0 0.53366 0.0751606 0.352011 0.208953 0.218063Z"
        fill="#7B8794"
      />
    </svg>
  )
}

export const WarningIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2C14.411 2 18 5.589 18 10C18 14.411 14.411 18 10 18C5.589 18 2 14.411 2 10C2 5.589 5.589 2 10 2ZM10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM11 13H9V15H11V13ZM9 11H11L11.5 5H8.5L9 11Z"
      fill="#F9B300"
    />
  </svg>
)

const Apply = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.91667 0.75H1.91667C1.60725 0.75 1.3105 0.872916 1.09171 1.09171C0.872916 1.3105 0.75 1.60725 0.75 1.91667V10.0833C0.75 10.3928 0.872916 10.6895 1.09171 10.9083C1.3105 11.1271 1.60725 11.25 1.91667 11.25H10.0833C10.725 11.25 11.25 10.725 11.25 10.0833V3.08333L8.91667 0.75ZM6 10.0833C5.03167 10.0833 4.25 9.30167 4.25 8.33333C4.25 7.365 5.03167 6.58333 6 6.58333C6.96833 6.58333 7.75 7.365 7.75 8.33333C7.75 9.30167 6.96833 10.0833 6 10.0833ZM7.75 4.25H1.91667V1.91667H7.75V4.25Z" />
    </svg>
  )
}

export const ApplyIcon = (props) => <Icon {...props} component={Apply} />

const SaveProfile = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.33317 0.582764H1.33317C0.691504 0.582764 0.166504 1.10776 0.166504 1.74943V9.9161H1.33317V1.74943H8.33317V0.582764ZM7.74984 2.9161H3.6665C3.02484 2.9161 2.50567 3.4411 2.50567 4.08276L2.49984 12.2494C2.49984 12.8911 3.019 13.4161 3.66067 13.4161H10.0832C10.7248 13.4161 11.2498 12.8911 11.2498 12.2494V6.4161L7.74984 2.9161ZM3.6665 12.2494V4.08276H7.1665V6.99943H10.0832V12.2494H3.6665Z" />
  </svg>
)

export const SaveProfileIcon = (props) => (
  <Icon {...props} component={SaveProfile} />
)
