import React from 'react'
import { BackIcon } from '../../components/icons'
import LatestLogo from '../../asserts/icon/latest-logo.svg'

export default function CoverHeader(props) {
  const { header } = props

  return (
    <div className={`${header} cover-header`}>
      {header === 'back' ? (
        <header className="cover-back">
          <BackIcon
            onClick={() => props.history.go(-1)}
            className="return-icon"
          />
          <div className="center">
            <img src={LatestLogo} alt="logo" />
          </div>
        </header>
      ) : (
        <header className="cover-icon">
          <img
            src={LatestLogo}
            alt="logo"
            onClick={() => props.history.push('/')}
          />
        </header>
      )}
    </div>
  )
}
