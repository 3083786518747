import React from 'react'
import { WhiteAddressIcon } from '../../../components/icons/facility'

export default function Location(props) {
  return (
    <div className="location">
      <WhiteAddressIcon /> {props.location} facility
    </div>
  )
}
