import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import TipBar from '../../UI/tipBarCom'
import { Slider, Tooltip } from 'antd'
import { PaymentTipsIcon } from '../../icons/onboarding'
import { loadUser } from '../../../utils/storage'
import RPEStyle from './styles/rpe.module.sass'
import { getRole } from '../../../utils/common'

// 0 - Nothing at all
// 1 - Very easy
// 2 - Easy
// 3 - Moderate
// 4 - Somewhat hard
// 5 - Hard
// 6 - Hard
// 7 - Very hard
// 8 - Very hard
// 9 - Very hard
// 10 - Maximal
const RPERange = [
  {
    title: 'No exertion',
    description: 'You’re at complete rest.'
  },
  {
    title: 'Extremely easy',
    description: 'Your breathing is restful and you could sing if you want to.'
  },
  {
    title: 'Very easy',
    description:
      'Your breathing is deeper but comfortable. You can talk in complete sentences. '
  },
  {
    title: 'Easy',
    description:
      'Your breathing is noticeably deeper. It’s harder to hold a conversation. '
  },
  {
    title: 'Moderate',
    description: 'You’re breathing deeply and your talking becomes broken.'
  },
  {
    title: 'Somewhat hard',
    description:
      'You’re breathing hard and just now starting to get uncomfortable.'
  },
  {
    title: 'Moderately hard',
    description: 'You’re breathing hard and getting noticeably uncomfortable.'
  },
  {
    title: 'Hard',
    description:
      'You’re taking deep, forceful breaths. You’re uncomfortable but you can still push through.'
  },
  {
    title: 'Very hard',
    description:
      'You can only maintain this activity for a few minutes. You’re breathing forcefully and can’t talk.'
  },
  {
    title: 'Very, very hard',
    description: 'You’re breathless and can only hang on for ~1 minute. '
  },
  {
    title: 'Extremely hard',
    description:
      'This is your maximal exertion. You’re gasping for air and can only hang on for 5-20 seconds.'
  }
]

class RPE extends Component {
  static propTypes = {
    activityDetail: PropTypes.object,
    setManualRpe: PropTypes.func
  }

  state = {
    value: 1
  }

  setManualRpe = async (value) => {
    const { activityDetail } = this.props
    const { rpe_manual, rpe_auto, record_id } = activityDetail
    const defaultValue =
      typeof rpe_manual === 'number' ? rpe_manual : rpe_auto || 0
    if (value === defaultValue) return
    this.setState({
      value
      // checked: value ? this.state.checked : false
    })
    const result = await this.props.setManualRpe(record_id, value)
    if (result && result.rpe_manual) {
      this.setState({ rpeEntry: result, value: result.rpe_manual })
    }
  }

  undo = async () => {
    const { activityDetail } = this.props
    const result = await this.props.setManualRpe(activityDetail.record_id, null)
    this.setState({
      rpeEntry: result,
      value: activityDetail.rpe_auto
    })
  }

  componentDidMount() {
    const { activityDetail } = this.props
    const { rpe_manual, rpe_auto } = activityDetail || {}
    activityDetail &&
      this.setState({
        rpeEntry: activityDetail || {},
        value: typeof rpe_manual === 'number' ? rpe_manual : rpe_auto || 1
      })
  }

  componentDidUpdate(prevProps) {
    const { activityDetail } = prevProps
    const nextProps = this.props
    if (activityDetail !== nextProps.activityDetail) {
      const { rpe_manual, rpe_auto } = nextProps.activityDetail
      this.setState({
        rpeEntry: nextProps.activityDetail || {},
        value: typeof rpe_manual === 'number' ? rpe_manual : rpe_auto || 1
      })
    }
  }

  processRPEEntry = (entry) => {
    const { rpe_manual, manual, source, rpe_updated_by } = entry
    return {
      isManualWorkout:
        (manual && manual) || (source && source === 'manual') || false,
      hasManualValue:
        rpe_updated_by && typeof rpe_manual === 'number' ? true : false
    }
  }

  render() {
    const { rpeEntry, value } = this.state
    const { isManualWorkout, hasManualValue } = this.processRPEEntry(
      rpeEntry || {}
    )
    const {
      training_load,
      rpe_updated_at,
      rpe_created_at,
      rpe_updated_by
    } = rpeEntry || { rpe_updated_by: {} }

    const user = loadUser()
    const disabled = getRole() === 'Service_Provider'
    return (
      <div className={RPEStyle.rpeContainer}>
        <div className={RPEStyle.title}>
          How did your workout feel?
          <Tooltip
            title={
              <>
                <h3>What is it?</h3>
                <p>
                  Perceived exertion is how hard you feel like your body is
                  working. It provide a fairly good estimate of your actual
                  heart rate which helps us to calculate the training load
                  during physical activity.
                </p>
              </>
            }
            className={RPEStyle.anticon}
          >
            <PaymentTipsIcon />
          </Tooltip>
        </div>
        {/* <div className={RPEStyle.explainInfo}>
          Perceived Exertion is automatically estimated based on your average
          heart rate data. You can overwrite by using manual input below.
        </div> */}

        <TipBar text="Identify the appropriate rating of perceived exertion that reflects how hard your training session was on average." />

        <div className={RPEStyle.sliderContainer}>
          <div className={RPEStyle.score}>
            <div className={`${RPEStyle.bold} ${RPEStyle.rateLevel}`}>
              {`${value || 1} - ${RPERange[value || 1].title}`}
            </div>
            <div>
              Training Load (RPEs):
              <span className={`${RPEStyle.bold} ${RPEStyle.rateLevel}`}>
                {training_load}
              </span>
            </div>
          </div>
          <div className={RPEStyle.sliderBg}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Slider
            max={10}
            min={1}
            step={1}
            disabled={disabled}
            tooltipVisible={false}
            onChange={(value) => !disabled && this.setState({ value })}
            onAfterChange={(value) => !disabled && this.setManualRpe(value)}
            value={value || 1}
          />
          <div className={RPEStyle.explainText}>
            <div>Nothing at all</div>
            <div>Moderate</div>
            <div>Maximal</div>
          </div>
          {!isManualWorkout && (
            <div
              className={`${RPEStyle.rpeValueUpdateInfoBox} ${RPEStyle.topBottomLine}`}
            >
              <span className={RPEStyle.infoLabel}>
                {hasManualValue
                  ? `Modified by ${
                      user && user.full_name === rpe_updated_by.full_name
                        ? 'you'
                        : `${(rpe_updated_by.profile &&
                            rpe_updated_by.profile.nickname) ||
                            rpe_updated_by.first_name} ${
                            rpe_updated_by.last_name
                          }`
                    } on ${moment(rpe_updated_at).format('MMM D, YYYY')}`
                  : `Calculated on ${moment(rpe_created_at).format(
                      'MMM D, YYYY'
                    )}`}
              </span>
              {hasManualValue && !disabled && (
                <span
                  type="text"
                  className={RPEStyle.undo}
                  onClick={() => this.undo()}
                >
                  Undo
                </span>
              )}
            </div>
          )}
          <div className={RPEStyle.extraInfo}>
            <h4>What is {RPERange[value || 1].title}?</h4>
            <div>{RPERange[value || 1].description}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default RPE
