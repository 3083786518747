import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryGroup,
  VictoryLine,
  VictoryTooltip,
  VictorySharedEvents,
  VictoryBar
  // VictoryVoronoiContainer
} from 'victory'
import ContainerWidthSizer from '../../../components/UI/ContainerWidthSizer'
import { concat } from 'lodash'

const DEFAULT_DATA_LENGTH = 8

export default function TimeLineChart(props) {
  const { totals } = props
  const [targetIndex, setTargetIndex] = useState(DEFAULT_DATA_LENGTH - 1)

  const tickValuesX = geTickValuesX(totals)
  const chartData = getData(tickValuesX, totals, DEFAULT_DATA_LENGTH)
  return (
    <>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="rgba(75, 89, 105,0)" />
            <stop offset="10%" stopColor="rgba(75, 89, 105, 0.5)" />
            <stop offset="100%" stopColor="rgb(75, 89, 105)" />
          </linearGradient>
        </defs>
      </svg>
      <ContainerWidthSizer>
        {({ width }) => (
          <VictorySharedEvents
            name="ShareEvents"
            events={[
              {
                childName: 'axis2',
                target: 'grid',
                eventHandlers: {
                  onClick: () => {
                    return [
                      {
                        childName: 'axis2',
                        target: 'grid',
                        mutation: (props) => {
                          clickGridHandle(props.datum)
                          return {}
                        }
                      }
                    ]
                  },
                  onMouseOver: () => {
                    return [
                      {
                        childName: 'axis1',
                        target: 'grid',
                        mutation: (props) => {
                          const { datum, style } = props
                          if (DEFAULT_DATA_LENGTH - datum <= totals.length) {
                            return {
                              style: Object.assign({}, style, {
                                stroke: 'rgba(255,255,255,0.85)',
                                strokeWidth: 1
                              })
                            }
                          } else {
                            return {
                              style: Object.assign({}, props.style, {
                                stroke: 'transparent',
                                strokeWidth: 6
                              })
                            }
                          }
                        }
                      }
                    ]
                  },
                  onMouseOut: () => {
                    return [
                      {
                        childName: 'axis1',
                        target: 'grid',
                        mutation: (props) => {
                          return targetIndex
                            ? {}
                            : {
                                style: Object.assign({}, props.style, {
                                  stroke: 'transparent',
                                  strokeWidth: 6
                                })
                              }
                        }
                      }
                    ]
                  }
                }
              }
            ]}
          >
            <VictoryChart width={width} height={150}>
              <VictoryBar
                barWidth={25}
                data={tickValuesX.map((_, index) => ({
                  x: index,
                  y: 450
                }))}
                style={{
                  data: {
                    fill: (e) => {
                      if (e.x === DEFAULT_DATA_LENGTH - 1) {
                        return 'url(#myGradient)'
                      } else {
                        return 'none'
                      }
                    }
                  },
                  labels: {
                    fontSize: 8,
                    fontWeight: 700
                  }
                }}
              />
              <VictoryAxis
                name="axis1"
                tickValues={tickValuesX.map((_, index) => index)}
                style={{
                  axis: { stroke: 'transparent' },
                  grid: {
                    stroke: (datum) => {
                      if (datum === targetIndex) return 'rgba(255,255,255,0.95)'
                      return 'transparent'
                    },
                    strokeDasharray: '3,3',
                    strokeWidth: (datum) => {
                      if (datum === targetIndex) return 1
                      return 6
                    },
                    cursor: 'pointer'
                  },
                  tickLabels: { fill: 'transparent' }
                }}
              />

              <VictoryAxis
                dependentAxis
                tickValues={[0, 100, 200, 300, 400, 450]}
                orientation="right"
                style={{
                  axis: {
                    stroke: 'transparent'
                  },
                  tickLabels: {
                    fill: 'transparent' //'#ffffff'
                  }
                }}
              />
              {renderChartGroup(chartData)}
              <VictoryAxis
                name="axis2"
                tickValues={tickValuesX.map((_, index) => index)}
                tickFormat={(t) =>
                  chartData.data[t].y ? tickValuesX[t].format('MM/DD') : ''
                }
                style={{
                  axis: {
                    stroke: 'transparent'
                  },
                  grid: {
                    stroke: 'transparent',
                    strokeWidth: 6,
                    cursor: (datum) =>
                      DEFAULT_DATA_LENGTH - datum <= totals.length
                        ? 'pointer'
                        : 'none'
                  },
                  ticks: { stroke: 'grey', size: 0 },
                  tickLabels: { fill: 'white', fontSize: 12 }
                }}
              />
            </VictoryChart>
          </VictorySharedEvents>
        )}
      </ContainerWidthSizer>
      <div className="label-list">
        <div className="label-item">
          <div>
            <div>Total</div>{' '}
            <div
              style={{
                color:
                  chartData.data[targetIndex] &&
                  props.getLabelColor(chartData.data[targetIndex].value)
              }}
            >
              {chartData.data[targetIndex] && chartData.data[targetIndex].value}
            </div>
          </div>
        </div>
      </div>
    </>
  )

  function geTickValuesX(list) {
    let tickValues = list.map((item) => moment(item.date))
    const l = tickValues.length
    const latestData = tickValues[0]
    if (l < DEFAULT_DATA_LENGTH) {
      tickValues = concat(
        new Array(DEFAULT_DATA_LENGTH - l)
          .fill(0)
          .map((_, index) => moment(latestData).subtract(index + 1, 'months')),
        tickValues
      )
    }

    return tickValues
  }

  function getData(tickValuesX, list, targetIndex) {
    const chart = {}

    const latestRecord = list[DEFAULT_DATA_LENGTH - targetIndex]
    const latestData = latestRecord.total_score

    chart.data = tickValuesX.map((x, _idx) => {
      const target = list.find(
        (record) => record.date === x.format('YYYY-MM-DD')
      )
      const value = target ? target.total_score : null

      let y = value

      if (y) {
        if (y < 0) {
          y = 150
        } else {
          y =
            latestData > 0
              ? 150 + (value - latestData) //(y * (index + 1) * 100) / latestData
              : y + 150
        }
      } else if (y === 0) {
        y = 150
      }
      return {
        x: _idx,
        y,
        value,
        highlight: _idx === DEFAULT_DATA_LENGTH - 1
      }
    })

    const latestDataIndex = chart.data.findIndex((data) => data.y)
    chart.emptyData = chart.data.map(({ x }, _idx) => {
      return {
        x,
        y: _idx <= latestDataIndex ? chart.data[latestDataIndex].y : null
      }
    })

    chart.latestData = latestData

    return chart
  }

  function clickGridHandle(index) {
    const targetScore = totals[totals.length - (DEFAULT_DATA_LENGTH - index)]
    if (targetScore) {
      setTargetIndex(index)
      props.selectScore(targetScore)
    }
  }

  function renderChartGroup(item) {
    return (
      <VictoryGroup>
        <VictoryLine
          name="data-line"
          data={item.data}
          style={{
            data: { stroke: '#ffffff' }
          }}
          labelComponent={<VictoryTooltip active={true} />}
        />
        <VictoryLine
          data={item.emptyData}
          style={{
            data: {
              stroke: 'rgba(255,255,255,0.4)',
              strokeDasharray: '3,3'
            }
          }}
        />
        <VictoryScatter
          data={item.data}
          size={({ highlight }) => {
            return highlight ? 6 : 3
          }}
          style={{
            data: {
              fill: ({ highlight }) => {
                return highlight ? '#ffffff' : '#000000'
              },
              stroke: ({ highlight }) => {
                return highlight ? '#838C98' : '#ffffff'
              },
              strokeWidth: ({ highlight }) => {
                return highlight ? 4 : 2
              }
            }
          }}
        />
      </VictoryGroup>
    )
  }
}
