import React, { useMemo } from 'react'
import _, { get } from 'lodash'
import moment from 'moment'
import ContainerWidthSizer from '../../../components/UI/ContainerWidthSizer'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel
} from 'victory'
import { calcTextWidth } from '../../../utils/String+Extension'
import { MicrobiomeChartContainer } from '../index.style'

export default function FixedRangeChart(props) {
  const { choices, details, subject, unit, className, selectTag } = props

  const { data, tickValuesX, tickValuesY } = getMetaData()

  const hoverTextWidths = useMemo(() => {
    return data.map((obj) => calcTextWidth(obj.text))
  }, [data])

  return (
    <MicrobiomeChartContainer className={`${className} ${selectTag}`}>
      <div className="header">
        <div className="name">
          {subject}{' '}
          {selectTag && (
            <span className={`${selectTag} tag`}> {selectTag}</span>
          )}
        </div>
        {getCurrentStatus()}
      </div>
      <div className="chart">
        <div className="chart-label">
          <div className="label-text">{unit}</div>
        </div>
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart
              width={width}
              height={220}
              minDomain={{ x: 0.5, y: tickValuesY[0] }}
              padding={{ top: 40, right: 20, bottom: 40, left: 60 }}
              containerComponent={<VictoryVoronoiContainer />}
            >
              <VictoryAxis
                tickValues={tickValuesX}
                tickFormat={(t) => {
                  if (data[t - 1]) {
                    return moment(
                      data[t - 1].completion_date || data[t - 1].date
                    ).format('MMM DD')
                  } else {
                    return null
                  }
                }}
                style={{
                  tickLabels: {
                    fill: '#70808E',
                    fontSize: 10, //device === 'mobile' ? 10 : 14,
                    fontFamily: 'Gilroy'
                  },
                  axis: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  }
                }}
              />

              <VictoryAxis
                dependentAxis
                tickValues={tickValuesY}
                tickFormat={(t) => {
                  if (t === 0) {
                    return ''
                  }
                  const choice = choices.find((item) => item.score === t)
                  return choice ? choice.axis_label : t.toString()
                }}
                tickLabelComponent={<VictoryLabel dy={23} />}
                style={{
                  axis: {
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  },
                  grid: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  },
                  tickLabels: {
                    fill: (e) => {
                      if (e === 0) {
                        return '#52606C'
                      }
                      return getStatus(e)
                    },
                    padding: 15,
                    fontSize: 10,
                    fontFamily: 'Gilroy',
                    fontWeight: 600
                  }
                }}
              />

              <VictoryLine
                data={data}
                style={{
                  data: { stroke: '#E2E5E9', strokeWidth: 2 }
                }}
              />

              <VictoryScatter
                style={{
                  labels: {
                    fontSize: 15,
                    fill: ({ datum }) => datum.status
                  }
                }}
                data={data}
                dataComponent={
                  <Dot
                    name="data-scatter"
                    maxY={_.cloneDeep(tickValuesY).pop()}
                  />
                }
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
              />

              <VictoryScatter
                data={data}
                dataComponent={<Dot name="data-scatter" noLabel />}
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
                labelComponent={
                  <VictoryTooltip
                    labelComponent={<HoverInfoPoint widths={hoverTextWidths} />}
                    dy={-15}
                    dx={110}
                    flyoutPadding={10}
                    flyoutHeight={70}
                    flyoutWidth={190}
                    pointerLength={0}
                    flyoutStyle={{
                      stroke: '#E6E9F2',
                      strokeWidth: 2,
                      fill: 'white'
                    }}
                  />
                }
              />
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      </div>
    </MicrobiomeChartContainer>
  )

  function getCurrentStatus() {
    const currentRecord = details[0] || {}
    let status = 'optimal'
    switch (currentRecord.score) {
      case 1:
        status = 'poor'
        break
      case 2:
        status = 'fair'
        break
      default:
        break
    }
    return <div className={status}>{status.toUpperCase()}</div>
  }

  function getMetaData() {
    let tickValuesY = [],
      rangeArr = [],
      data = []

    // if (props.type === 'Food type') {
    tickValuesY = [0, 1, 2, 3]
    rangeArr = [
      { values: [0, 1], score: 1, status: getStatus(1) },
      { values: [1, 2], score: 2, status: getStatus(2) },
      { values: [2, 3], score: 3, status: getStatus(3) }
    ]

    data = _.cloneDeep(details)
      .reverse()
      .map((detail, index) => {
        const { range, date, score, score_value, answer, type } = detail
        const isVirtual = answer && typeof answer.value === 'string'
        let value
        if (_.isNumber(score_value)) {
          value = score_value || (!isVirtual && answer.value) || 0
        } else {
          if (range.length === 2) {
            value = (range[0] + range[1]) / 2
          } else {
            value = range[0]
          }
        }

        let percent = 0
        let percent_color
        if (index !== 0) {
          const previousValue = details[details.length - index].score_value
          if (previousValue === 0) {
            percent = score_value * 100
          } else {
            percent = ((score_value - previousValue) / previousValue) * 100
            percent = percent.toFixed(0)
          }
          percent_color = getPercentColor(previousValue, score_value, rangeArr)
        } else {
          percent_color = getPercentColor(null, score_value, rangeArr)
        }

        return {
          y: value - 0.5,
          x: index + 1,
          date: date || detail.completion_date,
          score,
          score_value: value,
          percent,
          percent_color,
          choice: choices.find((item) => item.score === score),
          status: getStatus(score),
          isVirtual,
          type,
          text: get(answer, 'value')
        }
      })

    let n = 8

    if (data.length > n) {
      data = data.slice(data.length - n)
      data.forEach((item, index) => {
        item.x = index + 1
      })
    }
    const tickValuesX = new Array(n + 1).fill(0).map((_, index) => index)

    return {
      tickValuesX,
      data,
      tickValuesY,
      stackDomain: []
    }
  }

  function getPercentColor(prev, cur, range, cur_score) {
    const temp = range.find((e) => e.score === 3).values
    if (prev === cur || prev === null) {
      if (cur_score === 3) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }

    const average = (temp[0] + temp[1]) / 2
    const riseValue = cur - prev
    if (prev >= average) {
      // if previous score is greater than average, current score is better to down
      if (riseValue <= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue <= 0 && cur >= temp[1]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    } else {
      // if previous score is greater than average, current score is better to up
      if (riseValue >= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue >= 0 && cur <= temp[0]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }
  }
}

function getStatus(score) {
  let status
  switch (score) {
    case 1:
      status = '#FF6B00'
      break
    case 2:
      status = '#F5BA40'
      break
    case 3:
      status = '#40C47C'
      break
    default:
      break
  }
  
  return status
}

function Dot(props) {
  const {
    noLabel,
    maxY,
    datum: { score_value, status, y, choice }
  } = props

  const dx = y === maxY ? 10 : 0
  const additionalOffsetX = choice && choice.axis_label ? 10 : 0

  return (
    <svg
      width="200"
      height="35"
      viewBox="0 0 200 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 7 - additionalOffsetX}
      y={(props.y && props.y - 18) || 0}
    >
      {!noLabel && (
        <text
          style={{ textAnchor: 'middle' }}
          dy={-5 + dx}
          dx={dx + additionalOffsetX}
          x={7}
          fontSize={9}
          fill={status}
        >
          {choice
            ? choice.axis_label
            : Number(score_value)
            ? Math.round(score_value)
            : score_value}
        </text>
      )}
      <circle
        cx={7 + additionalOffsetX}
        cy="5"
        r="4"
        fill={status} //"white"
        stroke={status}
        strokeWidth="2"
      />
    </svg>
  )
}

function HoverInfoPoint(props) {
  const { x, y, datum, widths } = props
  const dx = -75,
    dy = -12

  const width = widths[datum.x - 1] + 30
  return (
    <g>
      <rect
        dx={dx}
        dy={dy}
        x={x - 90}
        y={y - 32}
        height="68"
        rx="6"
        width={width > 198 ? width : 198}
        stroke="#E6E9F2"
        strokeWidth="2"
        fill="white"
      />
      <circle
        cx={x - 70}
        cy={y - 11}
        r="5"
        stroke={datum.status}
        strokeWidth="2"
        fill={datum.status}
      />
      <text dx={dx} dy={dy} x={x + 17} y={y + 5} fontSize={13} fill="#323F4A">
        {moment(datum.date).format('MMM DD')}
      </text>

      <text
        dx={dx}
        dy={dy}
        x={datum.percent_color ? x + 100 : x + 160}
        y={y + 6}
        fontSize={13}
        fill={datum.status}
        textAnchor="end"
        width="100"
        style={{ fontWeight: 600 }}
      >
        {Number(datum.score_value)
          ? Math.round(datum.score_value)
          : datum.score_value}
      </text>
      {datum.percent_color && (
        <>
          <rect
            dx={dx}
            x={x + 30}
            y={y - 23}
            fontSize={13}
            height="26"
            rx="4"
            width="62"
            fill={datum.percent_color}
            style={{ opacity: 0.1 }}
          ></rect>
          {datum.percent !== 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={x + 40}
              y={y - 13}
              // dy={-10}
              width="6"
              height="8"
              viewBox="0 0 4 6"
              fill="none"
            >
              <path
                fill={datum.percent_color}
                transform={datum.percent > 0 ? 'rotate(-180)' : ''}
                style={{ transformOrigin: 'center' }}
                dx={dx}
                // dy={dy}
                x={x + 120}
                y={y}
                fontSize={13}
                d="M3.7388 4.04064L4 3.74631L3.4776 3.15763L3.2164 3.45197L3.7388 4.04064ZM2 5.41133L1.7388 5.70571L2 6L2.26121 5.70571L2 5.41133ZM0.783612 3.45197L0.52241 3.15763L0 3.74631L0.261209 4.04064L0.783612 3.45197ZM3.2164 3.45197L1.7388 5.11695L2.26121 5.70571L3.7388 4.04064L3.2164 3.45197ZM2.26121 5.11695L0.783612 3.45197L0.261209 4.04064L1.7388 5.70571L2.26121 5.11695ZM2.3694 5.41133L2.3694 0L1.63061 0L1.63061 5.41133H2.3694Z"
              />
            </svg>
          )}
          <text
            dx={dx}
            dy={-5}
            x={x + 125}
            y={y}
            fontSize={13}
            fill={datum.percent_color}
            style={{ fontWeight: 600 }}
          >
            {Math.abs(datum.percent)}%
          </text>
        </>
      )}
      <text dx={dx} dy={20} x={x} y={y} fontSize={13} fill="#9AA5B1">
        {datum.text}
      </text>
    </g>
  )
}
