import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Tabs, Checkbox, Button } from 'antd'
import DataTable from '../../components/UI/table'
import DateRange from '../../components/UI/mgComponents/dateRange'
import { getReport, exportReport } from '../../api/timeline'

const { TabPane } = Tabs

const Container = styled.div`
  .page-title {
    .opr {
      float: right;
      display: flex;
      align-items: center;
      .switch-date-range {
        width: 230px;
      }
      .ant-btn {
        width: 150px;
        margin-left: 24px;
      }
    }
  }
  .ant-tabs-tab {
    text-transform: uppercase;
  }
  .table-container {
    overflow: auto;
    & > div {
      width: 1720px;
    }
  }
  .ant-table-cell {
    color: #4c6072;
    font-size: 10px;
    vertical-align: top;
    strong {
      text-transform: uppercase;
      font-size: 14px;
    }
    .lighter {
      color: rgba(76, 96, 114, 0.7);
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`

export default function TimelineReport(props) {
  const [showYear, setShowYear] = useState()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState(null)
  const [exporting, setExporting] = useState(false)

  useEffect(() => {
    const year = new Date().getFullYear()
    setShowYear(year)
    fetchData(year)
  }, [])

  const renderColumns = (text) => (
    <CheckedColumn value={text} date={formatDate(text)} />
  )

  const columns = [
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'client_name',
      width: 200,
      render: (text) => <strong>{text}</strong>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (
          a.client_name.toLowerCase().charCodeAt() -
          b.client_name.toLowerCase().charCodeAt()
        )
      }
    },
    {
      title: 'YEAR',
      key: 'year',
      dataIndex: 'version',
      width: 80
      // render: (text) => formatDate(text)
    },
    {
      title: 'DATE',
      key: 'date',
      dataIndex: 'date',
      width: 150,
      render: (text) => formatDate(text)
    },
    {
      title: 'DXA',
      key: 'dxa',
      width: 150,
      render: renderColumns,
      dataIndex: 'dxa'
    },
    {
      title: 'BLOOD',
      key: 'blood',
      width: 150,
      render: renderColumns,
      dataIndex: 'blood'
    },
    {
      title: 'COGNITIVE HEALTH',
      key: 'cognitive_health',
      width: 150,
      render: renderColumns,
      dataIndex: 'cognitive_health'
    },
    {
      title: 'MOVEMENT QUALITY',
      key: 'movement_quality',
      width: 150,
      render: renderColumns,
      dataIndex: 'movement_quality'
    },
    {
      title: 'BALANCE',
      key: 'balance',
      render: renderColumns,
      width: 150,
      dataIndex: 'balance'
    },
    {
      title: 'MUSCLE FITNESS',
      key: 'muscle_fitness',
      render: renderColumns,
      width: 150,
      dataIndex: 'muscle_fitness'
    },
    {
      title: 'AEROBIC CAPACITY',
      key: 'aerobic_capacity',
      render: renderColumns,
      width: 150,
      dataIndex: 'aerobic_capacity'
    },
    {
      title: 'NUTRITION SIGNALS',
      key: 'nutrition_qqt',
      render: renderColumns,
      width: 150,
      dataIndex: 'nutrition_qqt'
    },
    {
      title: 'SLEEP QQT',
      key: 'sleep_qqt',
      render: renderColumns,
      width: 150,
      dataIndex: 'sleep_qqt'
    }
  ]

  return (
    <Container>
      <div className="page-title">
        Timeline Report
        <div className="opr">
          <DateRange
            prevDate={prevDate}
            nextDate={nextDate}
            showDate={showYear}
          />
          <Button
            type="primary"
            loading={exporting}
            disabled={exporting}
            onClick={exportData}
          >
            EXPORT
          </Button>
        </div>
      </div>

      <Tabs defaultActiveKey="1">
        {list &&
          Object.keys(list).map((name, index) => {
            const source = list[name]
            return (
              <TabPane tab={`${name} (${source.length})`} key={index}>
                <div className="table-container">
                  <DataTable
                    columns={columns}
                    dataSource={source}
                    hidePagination={true}
                    rowKey={(record) =>
                      `${index}_${record.client_id}_${record.version}`
                    }
                    loading={loading}
                  />
                </div>
              </TabPane>
            )
          })}
      </Tabs>
    </Container>
  )

  function formatDate(date) {
    return date && moment(date).format('MMM DD, YYYY')
  }

  async function exportData() {
    setExporting(true)
    const report = await exportReport(showYear)
    window.open(report.url)
    setExporting(false)
  }

  async function fetchData(year) {
    setLoading(true)
    setList({ qme1: [], qme2: [], qme3: [] })
    const list = await getReport(year)
    setList(list)
    setLoading(false)
  }

  function prevDate() {
    const newYear = moment(`${showYear}-01-01`)
      .subtract(1, 'year')
      .format('YYYY')
    setShowYear(newYear)
    fetchData(newYear)
  }

  function nextDate() {
    const newYear = moment(`${showYear}-01-01`)
      .add(1, 'year')
      .format('YYYY')
    setShowYear(newYear)
    fetchData(newYear)
  }
}

function CheckedColumn(props) {
  return (
    <div>
      <Checkbox checked={props.value} disabled />
      <br />
      <span className="lighter">{props.date}</span>
    </div>
  )
}
