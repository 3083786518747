import axios from './axios'

export const getReports = personId =>
  axios.get(`/reports?person_id=${personId}`)

export const getReportById = (personId, reportId) =>
  axios.get(`/reports?id=${reportId}&person_id=${personId}`)

export const createReport = (personId, params) =>
  axios.postJson(`/reports?person_id=${personId}`, params)

export const updateReport = (reportId, params) =>
  axios.putJson(`/reports/${reportId}`, params)

export const deleteReport = id => axios.delete(`/reports/${id}`)
