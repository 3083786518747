import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { hashRequest } from '../../api/axios'
import NutritionScore from './nutritionScore'
import HealthScore from './healthScore'
import NutritionScoreDetail from './nutritionScoreDetail'
import EmailMobileView from './summaryEmail'
import BackupEmailMobileView from './summaryBackupEmail'

import { mobileContainer } from './styles/index.sass'

class MobileView extends Component {
  static propTypes = {}

  state = {}

  getPerson = async (hash) => {
    const result = await hashRequest({
      hash,
      method: 'get',
      path: '/people/me'
    })
    const person = result.data.person
    this.setState({ person })
  }

  componentDidMount() {
    const {
      location: { pathname }
    } = this.props
    const hash = pathname.split('/').pop()
    this.getPerson(hash)
  }

  render() {
    const { match } = this.props
    return (
      <div className={mobileContainer}>
        <Switch>
          <Route path={`${match.url}`} exact component={NutritionScore} />
          <Route
            path={`${
              match.url
            }/nutrition-score-detail/:personId/:questionId/:hash`}
            render={(props) => <NutritionScoreDetail {...props} />}
          />
          <Route
            path={`${match.url}/nutrition-score/:personId/:hash`}
            // component={NutritionScore}
            render={(props) => (
              <NutritionScore {...props} person={this.state.person} />
            )}
          />
          <Route
            path={`${match.url}/health-score/:personId/:hash`}
            render={(props) => (
              <HealthScore {...props} person={this.state.person} />
            )}
          />
          <Route
            path={`${match.url}/email/:id/:hash`}
            render={(props) => (
              <EmailMobileView {...props} person={this.state.person} />
            )}
          />
          <Route
            path={`${match.url}/email-backup/:id/:hash`}
            render={(props) => (
              <BackupEmailMobileView {...props} person={this.state.person} />
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default MobileView
