import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { GoalsMethods } from '../../../utils/common'
import HealthkitLogo from '../../../asserts/images/health.jpeg'
import GarminLogo from '../../../asserts/icon/goals/garmin-large.png'
import OuraLogo from '../../../asserts/icon/goals/oura-large.png'
import PolarLogo from '../../../asserts/icon/goals/polar-large.png'
import WithingsLogo from '../../../asserts/icon/goals/withings-large.png'

class GoalCategoryTrackingView extends Component {
  static propTypes = {
    goal: PropTypes.object,
    openModal: PropTypes.func,
    deleteGoal: PropTypes.func
  }

  renderSource(source) {
    if (source === 'manual' || source === 'any') {
      return <span className="device">{source}</span>
    }
    let logo
    switch (source) {
      case 'healthkit':
        logo = HealthkitLogo
        break
      case 'garmin':
        logo = GarminLogo
        break
      case 'ouraring':
        logo = OuraLogo
        break
      case 'polar':
        logo = PolarLogo
        break
      case 'withings':
        logo = WithingsLogo
        break
      default:
        break
    }
    return (
      <span className="device-auto">
        <img src={logo} alt="logo" />
        <div className="source">{source}</div>
      </span>
    )
  }

  render() {
    const { goal } = this.props
    return (
      <div className="goal-category-tracking-view">
        <div className="container">
          <div className="middle">
            <div>Tracking Source</div>
            {this.renderSource(goal.source)}
          </div>
          {/* {goal.category.category !== 'Default' && (
            <>
              <div>
                <div>Category</div>
                <div>{goal.category.category}</div>
              </div>
              <div>
                <div>Sub-category</div>
                <div>{goal.category.sub_category}</div>
              </div>
            </>
          )} */}
          <div>
            <div>Target Metric</div>
            <div>{goal.target_goal_info}</div>
          </div>

          <div>
            <div>Input type</div>
            <div>
              {goal.input_type === 'binary' ? 'Yes/No' : goal.input_type}
            </div>
          </div>
          {goal.unit && (
            <div>
              <div>Unit</div>
              <div>{goal.unit}</div>
            </div>
          )}
          <div>
            <div>metric period</div>
            <div>{goal.period}</div>
          </div>
          {(goal.period === 'weekly' || goal.period === 'monthly') && (
            <div>
              <div>repeat</div>
              <div>{goal.repeat_num} time</div>
            </div>
          )}
          {goal.period === 'On specific days per week' && (
            <div className="middle">
              <div>Days of the week</div>
              <div>
                {goal.days.map((item, index) => (
                  <span className="week-day" key={index}>
                    {GoalsMethods.getWeekDayByNumber(item)}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default GoalCategoryTrackingView
