import React from 'react'
import BarChart from './bar'
import HeartRateTips from './heartRateTips'
import { heartRateBarsStyle } from './styles/heartRateBars.sass'

export default function HeartRateBarsChart(props) {
  return (
    <div className={`${props.extraClass} ${heartRateBarsStyle}`}>
      <BarChart {...props} pillar="Exercise" />
      <HeartRateTips tips={props.totalHeartRate || []} />
    </div>
  )
}
