import React from 'react'
import Icon from '@ant-design/icons'

const arrowRight = (props) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11.707 5.29306L7 0.58606L5.586 2.00006L8.586 5.00006H0V7.00006H8.586L5.586 10.0001L7 11.4141L11.707 6.70706C11.8945 6.51953 11.9998 6.26522 11.9998 6.00006C11.9998 5.7349 11.8945 5.48059 11.707 5.29306Z" />
  </svg>
)

export const ArrowRightIcon = (props) => (
  <Icon component={arrowRight} {...props} />
)

const view = (props) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23.8468 7.468C23.6548 7.163 19.0708 0 11.9998 0C4.92877 0 0.344767 7.163 0.152767 7.468C0.052633 7.62738 -0.000488281 7.81178 -0.000488281 8C-0.000488281 8.18822 0.052633 8.37262 0.152767 8.532C0.344767 8.837 4.92877 16 11.9998 16C19.0708 16 23.6548 8.837 23.8468 8.532C23.9469 8.37262 24 8.18822 24 8C24 7.81178 23.9469 7.62738 23.8468 7.468ZM11.9998 12C11.2086 12 10.4353 11.7654 9.77749 11.3259C9.11969 10.8864 8.607 10.2616 8.30425 9.53073C8.0015 8.79983 7.92229 7.99556 8.07663 7.21964C8.23097 6.44371 8.61193 5.73098 9.17134 5.17157C9.73075 4.61216 10.4435 4.2312 11.2194 4.07686C11.9953 3.92252 12.7996 4.00173 13.5305 4.30448C14.2614 4.60723 14.8861 5.11992 15.3256 5.77772C15.7652 6.43552 15.9998 7.20887 15.9998 8C15.9998 9.06087 15.5783 10.0783 14.8282 10.8284C14.078 11.5786 13.0606 12 11.9998 12Z" />
  </svg>
)

export const ViewIcon = (props) => <Icon component={view} {...props} />

const vector = (props) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 7.5625C8.63533 7.5625 8.28559 7.70737 8.02773 7.96523C7.76987 8.22309 7.625 8.57283 7.625 8.9375V10.3125H10.375V8.9375C10.375 8.57283 10.2301 8.22309 9.97227 7.96523C9.71441 7.70737 9.36467 7.5625 9 7.5625Z"
      // fill="#5E687D"
    />
    <path
      d="M17.4171 2.77064L9.16706 0.708142C9.05747 0.679968 8.94253 0.679968 8.83294 0.708142L0.582937 2.77064C0.434268 2.80788 0.302306 2.89374 0.208013 3.01456C0.11372 3.13539 0.062504 3.28425 0.0625 3.43752V13.0625C0.0625 15.4329 1.00413 17.7062 2.68023 19.3823C4.35634 21.0584 6.62963 22 9 22C11.3704 22 13.6437 21.0584 15.3198 19.3823C16.9959 17.7062 17.9375 15.4329 17.9375 13.0625V3.43752C17.9375 3.28425 17.8863 3.13539 17.792 3.01456C17.6977 2.89374 17.5657 2.80788 17.4171 2.77064ZM13.125 15.8125C13.125 15.9949 13.0526 16.1697 12.9236 16.2987C12.7947 16.4276 12.6198 16.5 12.4375 16.5H5.5625C5.38016 16.5 5.2053 16.4276 5.07636 16.2987C4.94743 16.1697 4.875 15.9949 4.875 15.8125V11C4.875 10.8177 4.94743 10.6428 5.07636 10.5139C5.2053 10.3849 5.38016 10.3125 5.5625 10.3125H6.25V8.93752C6.25 8.20817 6.53973 7.5087 7.05546 6.99297C7.57118 6.47725 8.27065 6.18752 9 6.18752C9.72935 6.18752 10.4288 6.47725 10.9445 6.99297C11.4603 7.5087 11.75 8.20817 11.75 8.93752V10.3125H12.4375C12.6198 10.3125 12.7947 10.3849 12.9236 10.5139C13.0526 10.6428 13.125 10.8177 13.125 11V15.8125Z"
      // fill="#5E687D"
    />
  </svg>
)

export const VectorIcon = (props) => <Icon component={vector} {...props} />

const signed = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 0.9375C9.99219 0.9375 11.6797 1.62891 13.0625 3.01172L12.2891 3.82031C11.1172 2.64844 9.6875 2.0625 8 2.0625C6.28906 2.0625 4.82422 2.67187 3.60547 3.89062C2.41016 5.08594 1.8125 6.53906 1.8125 8.25C1.8125 9.96094 2.41016 11.4258 3.60547 12.6445C4.82422 13.8398 6.28906 14.4375 8 14.4375C9.71094 14.4375 11.1641 13.8398 12.3594 12.6445C13.5781 11.4258 14.1875 9.96094 14.1875 8.25C14.1875 7.78125 14.1289 7.3125 14.0117 6.84375L14.9258 5.92969C15.1836 6.60938 15.3125 7.38281 15.3125 8.25C15.3125 10.2656 14.5977 11.9883 13.168 13.418C11.7383 14.8477 10.0156 15.5625 8 15.5625C5.98438 15.5625 4.26172 14.8477 2.83203 13.418C1.40234 11.9883 0.6875 10.2656 0.6875 8.25C0.6875 6.23438 1.40234 4.51172 2.83203 3.08203C4.26172 1.65234 5.98438 0.9375 8 0.9375ZM14.3281 3.32812L15.1719 4.17188L8.42188 10.9219L8 11.2734L7.57812 10.9219L4.76562 8.10938L5.60938 7.26562L8 9.69141L14.3281 3.32812Z"
      // fill="#40C47C"
    />
  </svg>
)

export const SignedIcon = (props) => <Icon component={signed} {...props} />

const checkedCircle = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      // fill="#05B800"
    />
    <path
      d="M4.5 8.5L7 11L11.5 6.5"
      stroke="#FCFDFD"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CheckedCircleIcon = (props) => (
  <Icon component={checkedCircle} {...props} />
)

const error = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z"
      fill="#FC2D2E"
    />
  </svg>
)
export const ErrorIcon = (props) => <Icon component={error} {...props} />

const paymentCheckItem = (props) => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97696 17.9938C8.84375 18.0021 8.71016 18.0021 8.57695 17.9938C6.41967 17.9134 4.36593 17.0483 2.80141 15.5608C1.92954 14.7306 1.23258 13.7343 0.75158 12.6307C0.270582 11.527 0.0152651 10.3382 0.00066382 9.13441C-0.0139374 7.93058 0.212471 6.73599 0.666559 5.62098C1.12065 4.50598 1.79323 3.4931 2.64472 2.64198C3.4962 1.79086 4.50938 1.11871 5.62458 0.665099C6.73977 0.211491 7.93446 -0.0144066 9.13829 0.000711658C10.3421 0.0158299 11.5308 0.271657 12.6342 0.753129C13.7377 1.2346 14.7336 1.93198 15.5635 2.80422C15.6242 2.85368 15.6736 2.91557 15.7083 2.98573C15.7431 3.05589 15.7624 3.13269 15.765 3.21094C15.7676 3.2892 15.7534 3.3671 15.7233 3.43939C15.6932 3.51169 15.648 3.57671 15.5907 3.63006C15.5334 3.68342 15.4653 3.72387 15.3911 3.7487C15.3168 3.77352 15.2381 3.78215 15.1602 3.77399C15.0824 3.76583 15.0072 3.74108 14.9397 3.7014C14.8722 3.66173 14.8139 3.60804 14.7689 3.54397C14.0461 2.75013 13.1698 2.11113 12.1929 1.66561C11.2161 1.22009 10.1591 0.977302 9.08575 0.95193C8.01242 0.926558 6.94509 1.11913 5.9483 1.51799C4.95151 1.91686 4.04597 2.51374 3.28643 3.27254C2.52689 4.03134 1.92913 4.9363 1.52929 5.93269C1.12944 6.92909 0.935832 7.99623 0.960156 9.06958C0.984479 10.1429 1.22623 11.2002 1.6708 12.1775C2.11537 13.1547 2.75351 14.0317 3.54664 14.7553C4.92776 16.0688 6.74322 16.8293 8.64818 16.8924C8.75766 16.8993 8.86748 16.8993 8.97696 16.8924C11.0843 16.8895 13.1044 16.0507 14.594 14.56C16.0836 13.0694 16.921 11.0487 16.9224 8.94141C16.9306 8.85027 16.9306 8.75857 16.9224 8.66743C16.9043 8.5221 16.9446 8.3755 17.0345 8.2599C17.1244 8.14429 17.2566 8.06913 17.4019 8.05097C17.5472 8.0328 17.6938 8.07311 17.8094 8.16303C17.925 8.25295 18.0002 8.38511 18.0184 8.53044C18.0265 8.66731 18.0265 8.80454 18.0184 8.94141C18.0198 10.1297 17.787 11.3065 17.3333 12.4048C16.8795 13.503 16.2138 14.501 15.3741 15.3417C14.5344 16.1825 13.5372 16.8494 12.4395 17.3045C11.3418 17.7595 10.1652 17.9938 8.97696 17.9938Z"
      fill="#05B800"
    />
    <path
      d="M8.88931 14.0705L3.25625 8.43743C3.15584 8.335 3.09961 8.19728 3.09961 8.05385C3.09961 7.91042 3.15584 7.77271 3.25625 7.67028C3.35868 7.56988 3.49639 7.51364 3.63982 7.51364C3.78325 7.51364 3.92096 7.56988 4.02339 7.67028L8.88931 12.5307L18.7198 2.69477C18.7669 2.63197 18.8269 2.58002 18.8959 2.54244C18.9648 2.50486 19.041 2.48253 19.1193 2.47696C19.1976 2.4714 19.2762 2.48273 19.3498 2.51018C19.4233 2.53763 19.4901 2.58057 19.5456 2.63608C19.6011 2.69159 19.6441 2.75839 19.6715 2.83194C19.699 2.90549 19.7103 2.98408 19.7047 3.06239C19.6992 3.14071 19.6768 3.2169 19.6393 3.28583C19.6017 3.35476 19.5497 3.41482 19.4869 3.46192L8.88931 14.0705Z"
      fill="#05B800"
    />
  </svg>
)

export const PaymentCheckItemIcon = (props) => (
  <Icon component={paymentCheckItem} {...props} />
)

const logout = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 5.5V2.5C6.5 1.948 6.948 1.5 7.5 1.5H14.5C15.052 1.5 15.5 1.948 15.5 2.5V14.5C15.5 15.052 15.052 15.5 14.5 15.5H7.5C6.948 15.5 6.5 15.052 6.5 14.5V11.5"
      stroke="#264382"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 8.5H0.5"
      stroke="#264382"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 5.5L0.5 8.5L3.5 11.5"
      stroke="#264382"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LogoutIcon = (props) => <Icon component={logout} {...props} />

const tip = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9 13H11V15H9V13ZM9 5H11V11H9V5ZM9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
  </svg>
)

export const PaymentTipsIcon = (props) => <Icon component={tip} {...props} />

const membershipV3LightChecked = (props) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.699 8.39805C20.899 9.19805 20.999 10.098 20.999 10.998C20.999 16.498 16.499 20.998 10.999 20.998C5.49902 20.998 0.999023 16.498 0.999023 10.998C0.999023 5.49805 5.49902 0.998047 10.999 0.998047C12.899 0.998047 14.699 1.49805 16.299 2.49805"
      stroke="#5E87DD"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M6.99902 9L10.999 13L21.999 2"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const MembershipV3LightChecked = (props) => (
  <Icon component={membershipV3LightChecked} {...props} />
)

const membershipV3DarkChecked = (props) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8366 8.50671C20.0282 9.27337 20.1241 10.1359 20.1241 10.9984C20.1241 16.2692 15.8116 20.5817 10.5407 20.5817C5.2699 20.5817 0.957397 16.2692 0.957397 10.9984C0.957397 5.72754 5.2699 1.41504 10.5407 1.41504C12.3616 1.41504 14.0866 1.89421 15.6199 2.85254"
      stroke="#223064"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M6.7074 9.08333L10.5407 12.9167L21.0824 2.375"
      stroke="#86C2D8"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const MembershipV3DarkChecked = (props) => (
  <Icon component={membershipV3DarkChecked} {...props} />
)

const clientLogoutIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 12H21" stroke="#0A0A0A" strokeWidth="2" strokeMiterlimit="10" />
    <path
      d="M17 16L21 12L17 8"
      stroke="#0A0A0A"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M19 4V3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H7C6.46957 1 5.96086 1.21071 5.58579 1.58579C5.21071 1.96086 5 2.46957 5 3V21C5 21.5304 5.21071 22.0391 5.58579 22.4142C5.96086 22.7893 6.46957 23 7 23H17C17.5304 23 18.0391 22.7893 18.4142 22.4142C18.7893 22.0391 19 21.5304 19 21V20"
      stroke="#0A0A0A"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
)

export const ClientLogoutIcon = (props) => (
  <Icon component={clientLogoutIcon} {...props} />
)
