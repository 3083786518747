import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import MembershipContainer from '../../components/membership'

class MembershipPage extends Component {
  static propTypes = {}

  render() {
    return (
      <>
        <h1 className="title" style={{ paddingTop: 40 }}>
          Membership & Pricing
        </h1>
        <MembershipContainer />
      </>
    )
  }
}

export default MembershipPage
