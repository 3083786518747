import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { detailColors } from '../../../utils/constant'
import { Typography } from 'antd'
import { rectangular } from './index.sass'

const { Paragraph } = Typography
class RectangularText extends Component {
  render() {
    const { text, color = '#C9D0E0' } = this.props
    const colorObj = detailColors.find((item) => item.key === color)
    const colorValue = (colorObj && colorObj.value) || color
    return (
      <div className={rectangular}>
        <div
          className="rectangular-container"
          style={{ backgroundColor: colorValue }}
        >
          <Paragraph
            className="rectangular-text"
            style={{ marginBottom: 0, padding: '0 15px' }}
            ellipsis={{ rows: 3 }}
          >
            {text}
          </Paragraph>
        </div>
      </div>
    )
  }
}

RectangularText.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string
}

export default RectangularText
