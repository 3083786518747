import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import moment from 'moment'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}
const blue = '#264382'
const green = '#7BAD2D'
const gray = '#BAC3CE'

class ProgressMultiBar extends Component {
  static propTypes = {
    goal: PropTypes.object
  }
  state = {
    dataArr: []
  }

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'weekly') {
      return moment(x).format('MMM D')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).date() % 5 === 0) {
        result = moment(x).format('M/D')
      }
      return result
    }
  }

  initialSleepTime = () => {
    const {
      goal: { children }
    } = this.props
    let inbedData = [],
      asleepData = []
    const dataArr = []
    for (const child of children) {
      const dataItem = {}
      const {
        micro_goal: { micro_goal },
        achieving: [achiev]
      } = child

      const entries = achiev.entries.map((item) => ({
        x: moment(item.created_at),
        y: Number(item.input_auto)
      }))
      dataItem.entries = entries
      if (micro_goal === 'Sleep Time - Asleep') {
        dataItem.color = green
        asleepData = dataItem
      } else {
        dataItem.color = gray
        inbedData = dataItem
      }
      dataArr.push(dataItem)
    }
    const entries = [].concat(...dataArr.map((item) => item.entries))
    const maxValue = Math.max(...entries.map((item) => item.y), 10)
    let dataY = []
    if (maxValue) {
      dataY = [0, 5, 10]
    }
    if (maxValue > 10) {
      dataY.push(parseInt(maxValue))
    }
    this.setState({
      asleepData,
      inbedData,
      dataY,
      type: 'sleepTime'
    })
  }

  initialBadTime = () => {
    const {
      goal: { children }
    } = this.props
    const dataArr = []
    for (const child of children) {
      const {
        achieving: [achiev]
      } = child
      const entries = [].concat(
        ...achiev.entries.map((item) => {
          const inputs = JSON.parse(item.input_auto)
          return inputs.map((input) => {
            const startTime = moment(input.bedtime_start),
              endTime = moment(input.bedtime_end),
              y0 = startTime.hour() * 60 + startTime.minute()
            let y = endTime.hour() * 60 + endTime.minute()
            if (endTime.date() > startTime.date()) {
              y = y + 24 * 60
            }
            return {
              x: moment(item.created_at),
              y,
              y0
            }
          })
        })
      )
      dataArr.push({
        color: blue,
        entries
      })
    }

    this.setState({
      dataY: [],
      dataArr
    })
  }

  initialMetaData = () => {
    const { startDate, endDate } = this.props
    const dataX = []
    let count = 1
    dataX.push(moment(startDate))
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      dataX.push(moment(startDate).add(count, 'days'))
      count++
    }
    dataX.push(moment(endDate))
    this.setState({ dataX })
  }

  timeValueHandle = (v) => {
    if (v > 24 * 60) {
      v = v - 24 * 60
    }
    const hour = parseInt(v / 60),
      minute = v % 60
    return moment(`${hour}:${minute}`, 'HH:mm').format('hhA')
  }

  componentDidMount() {
    const {
      goal: {
        micro_goal: { micro_goal }
      }
    } = this.props
    if (micro_goal === 'Total Sleep Time') {
      this.initialSleepTime()
    } else {
      this.initialBadTime()
    }
    this.setState({
      type: micro_goal
    })
    this.initialMetaData()
  }

  render() {
    const { period } = this.props
    const { asleepData, inbedData, type, dataX, dataY } = this.state
    const dataArr =
      type === 'Total Sleep Time' ? [inbedData, asleepData] : this.state.dataArr
    const gridStroken = (t) => {
      if (period === 'monthly' && moment(t).date() % 5 === 0) {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }
    return (
      <VictoryChart>
        <VictoryAxis
          style={{
            axis,
            grid: {
              strokeDasharray,
              stroke: gridStroken
            },
            tickLabels: {
              fontFamily: 'Lato',
              fontSize: '18px',
              fill: (x) => {
                if (
                  moment(x).format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                ) {
                  return '#3561C0'
                }
                return '#B0BAC9'
              },
              padding: (x) => {
                if (
                  period === 'monthly' &&
                  moment(x).format('ddd') !== 'Sun' &&
                  moment(x).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD') &&
                  moment(x).date() % 5 !== 0
                ) {
                  return 0
                } else {
                  return 10
                }
              }
            }
          }}
          tickValues={dataX}
          tickLabelComponent={
            <VictoryLabel
              text={(datum) => {
                let result = this.xAxisFormat(datum)
                if (period === 'weekly') {
                  result = result.split(' ').join('\n')
                }
                return result
              }}
            />
          }
        />
        {type === 'Total Sleep Time' ? (
          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                strokeDasharray,
                stroke: 'rgba(0,0,0,.5)'
              },
              grid: {
                strokeDasharray,
                stroke: 'rgba(0,0,0,.1)'
              },
              tickLabels: {
                fontFamily: 'Lato',
                fontSize: '18px',
                fill: (v) => {
                  if (v < 2e-9) {
                    return 'transparent'
                  }
                  return '#B0BAC9'
                }
              }
            }}
            tickValues={dataY}
          />
        ) : (
          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                strokeDasharray,
                stroke: 'rgba(0,0,0,.5)'
              },
              // grid: {
              //   strokeDasharray,
              //   stroke: 'rgba(0,0,0,.1)'
              // },
              tickLabels: {
                fontFamily: 'Lato',
                fontSize: '14px',
                fill: (v) => {
                  if (v < 2e-9) {
                    return 'transparent'
                  }
                  return '#B0BAC9'
                }
              }
            }}
            // tickValues={dataY}
            tickFormat={this.timeValueHandle}
          />
        )}

        {dataArr.map((data, index) => (
          <VictoryBar
            key={index}
            data={data.entries}
            alignment="start"
            // barRatio={4}
            cornerRadius={{ top: 4, bottom: 4 }}
            barWidth={period === 'monthly' ? 10 : 20}
            style={{
              data: {
                fill: data.color
              },
              axis
            }}
          />
        ))}
      </VictoryChart>
    )
  }
}

export default ProgressMultiBar
