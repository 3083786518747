import {
  // setClientBreadcrumb,
  // setClient,
  // setClientPageTitle,
  // setClientBiomarker,
  // setClientSelecCategory,
  setClientBiomarkerCategories,
  setClientLeftNavigateBarHidden,
  setClientUnreadCount
} from '../../redux/client/action'
import { setSubMenu } from '../../redux/slideMenu/action'

export default (dispatch) => ({
  setClientBiomarkerCategories: (categories) =>
    dispatch(setClientBiomarkerCategories(categories)),
  setSubMenu: (type, subMenu) => dispatch(setSubMenu(type, subMenu)),
  setClientUnreadCount: (count) => dispatch(setClientUnreadCount(count)),
  setClientLeftNavigateBarHidden: (hidden) =>
    dispatch(setClientLeftNavigateBarHidden(hidden))
})
