import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Upload, Button, Progress } from 'antd'
import Dragger from '../../../components/business/files/dragger'
import { singleFileStyle } from './index.sass'

export class SingleImage extends Component {
  static propTypes = {
    photo: PropTypes.object,
    upload: PropTypes.func,
    deletePhoto: PropTypes.func
  }

  state = {
    uploading: false,
    percent: 0
  }

  cancel = () => {
    const { source } = this.state
    source && source.cancel()
    this.setState({ uploading: false, percent: 0 })
  }

  coverImage = (request) => {
    const { upload } = this.props
    const { file } = request
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ uploading: true, source })
      upload(
        file,
        (percent) => {
          this.setState({ percent })
          if (percent === 100) {
            this.setState({ uploading: false })
          }
        },
        source
      )
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { photo, upload, deletePhoto } = this.props
    const { uploading, percent } = this.state
    return (
      <div className={`single-file ${singleFileStyle}`}>
        {photo ? (
          <div className="cover-photo">
            <div className="tool-bar">
              <Upload showUploadList={false} customRequest={this.coverImage}>
                <Button type="primary" ghost className="white" icon={<EditOutlined />}>
                  UPLOAD IMAGE
                </Button>
              </Upload>
              {uploading ? (
                <Button
                  type="primary"
                  ghost
                  className="white cancel"
                  onClick={this.cancel}
                >
                  CANCEL
                </Button>
              ) : (
                <DeleteOutlined
                  onClick={(e) => {
                    e.stopPropagation()
                    deletePhoto(photo.id)
                  }} />
              )}

              {uploading && (
                <div className="upload-progress">
                  <Progress
                    percent={percent}
                    status={'active'}
                    showInfo={false}
                  />
                </div>
              )}
            </div>
            <img src={photo.oss_url} alt="placeholder" />
          </div>
        ) : (
          <Dragger upload={upload} />
        )}
      </div>
    )
  }
}

export default SingleImage
