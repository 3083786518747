import axios from './axios'

const createQueryString = (params, path) => {
  const queryString = Object.entries(params)
    .map(([key, value]) =>
      value ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}` : null
    )
    .filter((item) => item)
    .join('&')
  return path + '?' + queryString
}

const getAppointmentsList = async (params) =>
  axios.get(createQueryString(params, '/appointments'))
// const {
//   service_type,
//   start_date,
//   end_date,
//   size,
//   afterCursor,
//   client_name
// } = params

const getSessionStatistics = async (params) =>
  axios.get(createQueryString(params, '/appointments/session_statistics'))
// const { start_date, end_date, client_name } = params

const getClientsStatistics = async (params) =>
  axios.get(createQueryString(params, '/appointments/clients_statistics'))
// const { start_date, end_date } = params

const deleteAppointment = async (id) => axios.delete(`/appointments/${id}`)

const updateAppointment = async (id, body) =>
  axios.putJson(`/appointments/${id}`, body)
// body: {
//   client_name,service_provider_name, summary,service,duration,sessions_used,client_pay,location,start_at,end_at,notes
//   }

const createAppointment = async (body) => axios.postJson('/appointments', body)
// body: {
//   client_name,service_provider_name, summary,service,duration,sessions_used,client_pay,location,start_at,end_at,notes
//   }

// const { start_date, end_date, client_name, service_type, client_id } = params
const exportAppointmentsToCSV = async (params) =>
  await axios.get(createQueryString(params, '/appointments/export_csv'))

export {
  getAppointmentsList,
  getSessionStatistics,
  getClientsStatistics,
  deleteAppointment,
  updateAppointment,
  createAppointment,
  exportAppointmentsToCSV
}
