import React, { useEffect } from 'react'
import './setup.css'
import { Button } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Header from './header'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import { loadUser } from '../../../utils/storage'

const Ready = ({ history, logOut }) => {
  useEffect(() => {
    const {
      profile: {
        pre_screening_completed,
        waiver_required,
        questionnaire_required,
        introductory_required
      }
    } = loadUser()
    if (
      pre_screening_completed ||
      !waiver_required ||
      !questionnaire_required ||
      !introductory_required
    ) {
      history.push('/onboarding')
    }
  }, [])

  const onClickStart = () => {
    history.push('/onboarding')
  }

  return (
    <div className="set-up-screen">
      <Header history={history} logOut={logOut} />
      <div className="ready-container">
        <div className="ready">
          <div className="title">Ready to get started?</div>
          <div className="desc">
            Our advanced scientific testing gives you a deeper understanding
            <br /> of your body and performance. In conjunction with your
            <br /> personal goals and assessment, we are gathering this
            information <br /> to best design your action plan.
          </div>
          <div className="item">
            <div className="circle">1</div> Pre-Assessment Medical Review
            <div className="min">1 min</div>
          </div>
          <div className="item">
            <div className="circle">2</div> Liability Release
            <div className="min">5 min</div>
          </div>
          <div className="item">
            <div className="circle">3</div> Member Information
            <div className="min">3 min</div>
          </div>
          <div className="item">
            <div className="circle">4</div> Medical Release
            <div className="min">5 min</div>
          </div>
          <div className="item">
            <div className="circle">5</div> Initial Intake Questionnaire
            <div className="min">10 min</div>
          </div>
        </div>
        <Button type="primary" className="cta" onClick={onClickStart}>
          GET STARTED <RightOutlined />
        </Button>
      </div>
    </div>
  )
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Ready)
