import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import BloosTestContainer from '../../../components/business/dataset/clientBloodTestContainer'
import BloodTestContainerWrapper from '../../../components/business/dataset/clientBloodTestContainerWrapper'
import { getPeopleById } from '../../../api/people'
import LockStatus from '../../../components/UI/lockStatus'
import { userType } from '../../../utils/constant'

const Container = BloodTestContainerWrapper(BloosTestContainer)

class BloodTestDataset extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { person } = await getPeopleById('me')
    this.setState({ person })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    const {
      match: { url },
      history
    } = this.props
    const isPartner =
      person && person.profile.account_type === userType.Partners
    return isPartner ? (
      <>
        <h1 className="page-title">Blood Tests</h1>
        <LockStatus />
      </>
    ) : (
      <div style={{ paddingRight: 63, paddingTop: 40, paddingBottom: 60 }}>
        {(person && (
          <Container {...this.props} {...{ person, url, history }} />
        )) || <div />}
      </div>
    )
  }
}

export default BloodTestDataset
