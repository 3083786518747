import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Row, Col } from 'antd'
import Editor from './editor'
import MedicalInfo from './medicalInfo'
import TopFilter from './topFilter'
import NotesList from './list'
import { createNote, getNotes, editNote, deleteNots } from '../../api/notes'
import Empty from '../UI/status/empty'
import { getAllExperts } from '../../api/team'
import PageLoading from '../UI/status/loading'
import { getRole } from '../../utils/common'

const NotesContainer = styled.div`
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
  // border-radius: 10px;
  &.summary {
    box-shadow: none;
    padding: 0;
    .note-operate {
      flex-wrap: wrap;
      & > div:first-child {
        flex-wrap: wrap-reverse;
        display: flex;
        position: relative;
        z-index: 1;
        & > div {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      & > div:last-child {
        width: 100%;
        text-align: right;
        margin-top: -28px;
      }
    }
    .info-container {
      flex-wrap: wrap;
      line-height: 1.7;
    }
  }
`

export default function Notes(props) {
  const { person, noFilter, onSummary } = props
  const [notes, setNotes] = useState([])
  const [experts, setExperts] = useState([])
  const [searchText, setSearchText] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [condition, setCondition] = useState(null)
  useEffect(() => {
    getExperts()
    fetchData()
  }, [])
  if (!loaded) {
    return <PageLoading />
  }
  const role = getRole()

  return (
    <Row gutter={20}>
      <Col span={noFilter ? 24 : 15}>
        <NotesContainer className={noFilter ? 'summary' : ''}>
          {!noFilter && (
            <TopFilter
              experts={experts}
              onConditionChange={onConditionChange}
            />
          )}
          {experts.length > 0 && (
            <Editor submitNote={submitNote} person={person} experts={experts} />
          )}
          {notes.length > 0 ? (
            <NotesList
              list={notes}
              person={person}
              searchText={searchText}
              submitNote={submitNote}
              deleteNote={deleteNote}
              onSummary={onSummary}
            />
          ) : (
            <Empty style={{ marginTop: 60 }} message="No note yet" />
          )}
        </NotesContainer>
      </Col>
      {!noFilter && role !== 'Service_Provider' && (
        <Col span={9}>
          <MedicalInfo person={person} refreshNotes={fetchData} />
          {/* <Filter experts={experts} onConditionChange={onConditionChange} /> */}
        </Col>
      )}
    </Row>
  )

  function onConditionChange(condition) {
    let { selectTypes, selectPeople, selectDate, search } = condition
      ? _.cloneDeep(condition)
      : { selectTypes: [], selectPeople: [] }
    const queries = []
    if (_.includes(selectTypes, 'Files')) {
      queries.push('has_files=true')
      selectTypes = selectTypes.filter((item) => item !== 'Files')
    }
    if (selectTypes.length) {
      queries.push(`pillars=${selectTypes.join(',')}`)
    }
    if (selectPeople.length) {
      queries.push(`people=${selectPeople.join(',')}`)
    }
    if (selectDate) {
      queries.push(`end=${selectDate}`)
    }
    if (search) {
      queries.push(`search=${search}`)
    }
    if (queries.length === 0) {
      setCondition(null)
    } else {
      setCondition(condition)
    }
    setSearchText(search)
    fetchData(queries)
  }

  async function getExperts() {
    const { clients } = await getAllExperts()

    setExperts(clients)
  }
  async function fetchData(queries = []) {
    const notes = await getNotes(person.id, queries)
    setNotes(notes)
    setLoaded(true)
  }

  async function submitNote(params) {
    if (params.id) {
      const id = params.id
      delete params.id
      await editNote(id, params)
    } else {
      await createNote(params)
    }

    await onConditionChange(condition)
  }

  async function deleteNote(note) {
    await deleteNots(note.id)
    await onConditionChange(condition)
  }
}
