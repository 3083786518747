export const facilityForm = [
  {
    type: 'input',
    label: 'Facility name',
    dbField: 'name',
    colSpan: 8,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Address',
    dbField: 'address',
    colSpan: 16,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'timeArea',
    colSpan: 16,
    dbField: 'time_area',
    rules: [{ required: true, message: 'Please select time' }],
    extraAttr: {
      format: 'HH:mm',
      // use12Hours: true,
      minuteStep: 1
    },
    labels: ['Open time', 'Close time']
  },
  {
    colSpan: 8
  },
  {
    type: 'time',
    label: 'Open time',
    dbField: 'open_time',
    extraAttr: {
      format: 'HH:mm',
      // use12Hours: true,
      minuteStep: 1
    },

    colSpan: 8,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'time',
    label: 'Close time',
    dbField: 'close_time',
    extraAttr: {
      format: 'HH:mm',
      // use12Hours: true,
      minuteStep: 1
    },
    colSpan: 8,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    colSpan: 8
  }
]

export const getFacilityForm = (disabled, validateTime) => [
  {
    type: 'input',
    label: 'Facility name',
    dbField: 'name',
    colSpan: 8,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Address',
    dbField: 'address',
    colSpan: 16,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'time',
    label: 'Open time',
    dbField: 'open_time',
    extraAttr: {
      format: 'h:mm A',
      disabledTime: (now) => ({
        disabledHours: () => disabled('hours', 'open'),
        disabledMinutes: (selectedHour) =>
          disabled('minutes', 'open', selectedHour)
      }),
      use12Hours: true,
      minuteStep: 1
    },

    colSpan: 8,
    rules: [
      { required: true, message: 'This field is required' },
      {
        validator: (rule, value, callback) =>
          validateTime('open', value, callback)
      }
    ]
  },
  {
    type: 'time',
    label: 'Close time',
    dbField: 'close_time',
    extraAttr: {
      format: 'h:mm A',
      use12Hours: true,
      disabledTime: (now) => ({
        disabledHours: () => disabled('hours', 'close'),
        disabledMinutes: (selectedHour) =>
          disabled('minutes', 'close', selectedHour)
      }),

      minuteStep: 1
    },
    colSpan: 8,
    rules: [
      { required: true, message: 'This field is required' },
      {
        validator: (rule, value, callback) =>
          validateTime('close', value, callback)
      }
    ]
  },
  {
    colSpan: 8
  }
]
