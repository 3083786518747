import React, { Component } from 'react'
import CoverHeader from './cover-header'
import { style } from './iframe.sass'

class FacilityWaiver extends Component {
  static propTypes = {}

  render() {
    const {
      match: {
        params: { header }
      },
      history
    } = this.props
    return (
      <div className={style}>
        <CoverHeader header={header || 'back'} history={history} />
        <iframe
          onClick={this.props.clickHandle}
          title="pp"
          // src="https://apeiron.life/facility-waiver/"
          src="https://apeiron.life/privacy-policy/"
          frameBorder="no"
          scrolling="yes"
        />
      </div>
    )
  }
}

export default FacilityWaiver
