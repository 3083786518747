import React, { Component } from 'react'
import { resultContainerStyle } from './styles/resultContainer.sass'
import { PlaceHolder, ScalePoint } from '../icons/questionnaireResultIcons'
import { Row, Col, Button } from 'antd'
import ResultScoreSection from './comp/resultScoreSection'
import TriangleGraph from './comp/triangleGraph'
import PageLoading from '../../components/UI/status/loading'
import { getQuestionnaireResult } from '../../api/people'
import RecommendProgramBg from '../../asserts/icon/recommend-program-bg.png'
import Empty from '../UI/status/empty'
import { sumReduce } from '../summaryEmailBackup/common'
import { SvgText } from '../icons/weeklySummary'

const resilienceScaleDesc = {
  Low: 'vastly improving your overall health and gently pushing yourself',
  'Below Average': 'improving your overall health and pushing yourself',
  Average: 'maintaining your overall health and pushing yourself',
  High: 'maintaining your overall health, and keeping your excellent health'
}

class QuestionResultContainer extends Component {
  static propTypes = {}
  state = {
    loading: true
  }

  initial = async () => {
    const result = await getQuestionnaireResult(this.props.personId)
    this.setState({ loading: false, result })
  }

  componentDidMount() {
    this.initial()
  }

  renderRankedGoals(items) {
    const Rank = ['ST', 'ND', 'RD', 'TH', 'TH']
    return (
      <Row gutter={24} type="flex" align="bottom">
        {items.map((item, _index) => (
          <Col span={8} key={_index}>
            <div className="ranked-goals-container">
              <div className="ranked-goal">
                <img src={item.icon} alt="" />
                <span className="ranked-goal-title">{item.title}</span>
              </div>
              <div className={`ranked-block ${Rank[item.rank]}`}>
                <div className="rank-index-container">
                  {item.rank + 1}
                  <span className="super-index">
                    <SvgText
                      text={Rank[item.rank]}
                      fill={'#46515A'}
                      height={14}
                      fontSize={8.5}
                      width={14}
                      textAttr={{
                        textAnchor: 'start',
                        x: 0
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  renderFutureGoals(items) {
    return (
      <div className="future-goals-container">
        <Row>
          {items.map((item, _index) => (
            <Col span={12} key={_index}>
              <div className="future-goal">
                <img src={item.icon} alt="" />
                <div className="future-goal-title">{item.title}</div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  renderResilienceScale() {
    const {
      result: { resilienceScale }
    } = this.state
    const points = ['Low', 'Below Average', 'Average', 'High']
    const pointIndex = points.findIndex((point) => point === resilienceScale)
    return (
      <div className="resilience-scale-container">
        <div className="level-line-container">
          <div className="level-line" />
          <ScalePoint
            className="point"
            style={{ left: (pointIndex / 3) * 100 + '%' }}
          />
        </div>
        <div className="level-title-container">
          <span className="level-title">Low</span>
          <span className="level-title">Below Average</span>
          <span className="level-title">Average</span>
          <span className="level-title">High</span>
        </div>
      </div>
    )
  }

  renderRecommendProgram() {
    const {
      result: { recommendedProgram }
    } = this.state
    return (
      <div
        className="recommend-program-container"
        style={{
          backgroundImage: `url(${RecommendProgramBg})`,
          backgroundSize: '100% 100%'
        }}
      >
        <div className="title">{recommendedProgram} weeks program</div>
        <p className="desc">
          Integer pellentesque gravida elementum nullam nunc enim vitae leo
          pellentesque. Faucibus ultricies tellus volutpat rhoncus urna odio.
          Amet, neque nam scelerisque urna sit. Pulvinar viverra eleifend ornare
          aliquam mauris. Integer pellentesque gravida elementum nullam nunc
          enim vitae leo pellentesque. Pulvinar viverra eleifend ornare aliquam
          mauris. Integer pellentesque gravida elementum nullam nunc enim vitae
          leo pellentesque.
        </p>
        <div className="view-program-container">
          <span className="label">View program</span>
        </div>
        {/* <Button className="view-program-container">
          <span className="label">View program</span>
        </Button> */}
      </div>
    )
  }

  renderGoalsQuestion(question) {
    const { type, answer, answerIcon } = question
    switch (type) {
      case 'multi_choice_rank':
        if (!Array.isArray(answer)) {
          return null
        }
        return this.renderRankedGoals(answer.sort((a, b) => a.rank - b.rank))
      case 'multi_choice':
        if (!Array.isArray(answer)) {
          return null
        }
        return this.renderFutureGoals(answer)
      case 'text_entry':
        return answer ? (
          <div className="ranked-goals-container">
            <div className="ranked-goal">
              {(answerIcon && <img src={answerIcon} alt="" />) || (
                <PlaceHolder />
              )}
              <div
                style={{
                  marginLeft: '12px'
                }}
                className="goal-title"
              >
                {answer}
              </div>
            </div>
          </div>
        ) : null
      default:
        return null
    }
  }

  renderGoalsList() {
    const {
      result: { goals }
    } = this.state
    return (
      <div className="result-group">
        <span className="group-title">Goals</span>
        <p className="group-desc">
          Your goals will directly influence your training program build. Below
          is a summary of your short and long term goals.
        </p>
        <div className="target-container">
          {goals &&
            goals.map((item, index) => (
              <div className="target-group-container bottom-line" key={index}>
                <p>{item.question}</p>
                {this.renderGoalsQuestion(item)}
              </div>
            ))}
        </div>
      </div>
    )
  }

  renderEmpty() {
    return (
      <Empty
        message={
          <span sytle={{ fontFamily: 'Heebo' }}>
            Your questionnaire score will appear here when you <br /> complete
            the{' '}
            <b style={{ color: '#383E48', fontWeight: 700 }}>Questionnaire</b>
            .
            <br />
            <Button
              className="send-btn"
              type="primary"
              style={{ float: 'none', marginTop: 35 }}
              onClick={this.linkToQuestionnaire}
            >
              complete Questionnaire
            </Button>
          </span>
        }
      />
    )
  }

  linkToQuestionnaire = () => {
    const { personId } = this.props
    if (personId) {
      let url = `/client-questionnaire/${personId}?callback=/client/${personId}/detail/questionnaire`
      this.props.history.push(url)
    }
  }

  processScoreDesc(result) {
    const prioritizingPillar =
      result &&
      result.summary &&
      ['Exercise', 'Nutrition', 'Rejuvenation']
        .map((pillar) => {
          return {
            name: pillar,
            score: result.summary[pillar.toLowerCase()]
          }
        })
        .filter((e) => {
          return e.score
        })
        .sort((a, b) => {
          return a.score < b.score ? -1 : 0
        })[0].name

    const { exercise, nutrition, rejuvenation } =
      (result && result.summary) || {}
    const scores = [exercise, nutrition, rejuvenation].filter((x) => x)
    const average =
      scores.length > 0
        ? Math.round(scores.reduce(sumReduce) / scores.length)
        : 0

    let healthStatus
    if (average < 61) {
      healthStatus = 'needs attention'
    } else if (average < 81) {
      healthStatus = 'is satisfactory'
    } else {
      healthStatus = 'is ideal'
    }
    return { healthStatus, prioritizingPillar }
  }

  render() {
    const { loading, result } = this.state
    const resilienceScale = (result && result.resilienceScale) || 'Normal'
    const { healthStatus, prioritizingPillar } = this.processScoreDesc(result)

    return loading ? (
      <PageLoading />
    ) : (
      <div className={resultContainerStyle}>
        {(result.isEmpty && this.renderEmpty()) || (
          <>
            {this.renderGoalsList()}
            <div className="result-group">
              <span className="group-title">Resilience scale</span>
              <p className="group-desc">
                Based on your medical history, you’re in the{' '}
                <span className="highlight">{resilienceScale}</span> range on
                the resilience scale. That means your training program will
                focus on {resilienceScaleDesc[resilienceScale]} in certain areas
                of exercise, diet and sleep.
              </p>
              {this.renderResilienceScale()}
            </div>
            <div className="result-group">
              <span className="group-title">Questionnaire Score</span>
              <p className="group-desc">
                Overall, you’re health{' '}
                <span className="highlight">{healthStatus}</span>. The area of
                focus will be prioritizing{' '}
                <span className="highlight">{prioritizingPillar}</span> health.
              </p>
              <TriangleGraph {...result.summary} />
              <ResultScoreSection {...result} />
            </div>
            {result.recommendedProgram && (
              <div className="result-group">
                <span className="group-title">Recommended program</span>
                {this.renderRecommendProgram()}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

export default QuestionResultContainer
