import React, { Component } from 'react'
import { Divider } from 'antd'
import UserInfo from '../../components/business/setting/userInfo'
import SignedCard from '../../components/business/setting/signed'
import ProfileSetting from '../../components/business/setting/index'
import { saveUser, loadUser } from '../../utils/storage'
import PageLoading from '../../components/UI/status/loading'
import {
  getCustomer,
  getPeopleById,
  getNotificationStatus,
  getIntroductory
} from '../../api/people'

import { profileAndSettingStyle } from './styles/index.sass'

class Profile extends Component {
  static propTypes = {}

  state = {
    editing: false,
    needupdateHeader: false,
    updating: false,
    card: {},
    showEmailForm: false
  }

  componentDidMount() {
    this.getCard()
    this.getPeople()
    this.getClientIntroductory()
  }

  getPeople = async () => {
    const user = loadUser()
    const { person } = await getPeopleById(user.id)
    const result = await getNotificationStatus(person.id)
    if (person.profile.date_of_birth) {
      person.profile.date_of_birth = (
        person.profile.date_of_birth || ''
      ).substr(0, 10)
    }
    person.profile = Object.assign(person.profile, result)
    this.setState({ user: person })
    saveUser(person)
  }

  getCard = async () => {
    const data = await getCustomer()
    const card =
      data.customer &&
      data.customer.sources &&
      data.customer.sources.data[0].card
    if (card) {
      this.setState({ card })
    }
  }

  getClientIntroductory = async () => {
    const user = loadUser()
    const data = await getIntroductory(user.id)
    if (data) {
      this.setState({ introductory: data })
    }
  }

  changePassword = () => {
    const user = this.state.user || loadUser()
    if (user.profile.enabled_2fa) {
      this.props.history.push('/2fa?callback=/app/setting/change-password')
    } else {
      this.props.history.push('/app/setting/change-password')
    }
  }

  changePayment = () => {
    const user = this.state.user || loadUser()
    if (user.profile.enabled_2fa) {
      this.props.history.push(
        '/2fa?callback=/onboarding/payment&finalback=/app/setting'
      )
    } else {
      this.props.history.push('/onboarding/payment?callback=/app/setting')
    }
  }

  linkTo2FA = () => this.props.history.push('/2fa?callback=/app/setting')

  userUpdate = (user) => {
    saveUser(user)
    this.setState({ needupdateHeader: true, user })
  }

  render() {
    const { history } = this.props
    const { card, user, introductory } = this.state
    return (
      <div>
        {user && introductory ? (
          <div className={profileAndSettingStyle}>
            <h1 className="title"> Profile & Settings</h1>
            <Divider />
            <div className="container">
              <div className="sub-container">
                <UserInfo
                  user={user}
                  introductory={introductory}
                  updateSync={this.userUpdate}
                />
                <SignedCard
                  user={user}
                  viewSigned={() =>
                    history.push('/app/setting/view-signed-forms')
                  }
                />
              </div>
              <div className="sub-container">
                <ProfileSetting
                  user={user}
                  card={card}
                  updateSync={this.userUpdate}
                  changePassword={this.changePassword}
                  changePayment={this.changePayment}
                  linkTo2FA={this.linkTo2FA}
                />
              </div>
            </div>
          </div>
        ) : (
          <PageLoading />
        )}
      </div>
    )
  }
}

export default Profile
