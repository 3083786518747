import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from 'antd'
import { EditCircleIcon } from '../../icons/weeklySummary'
import ConfirmPopUp from './confirm'

import { Style } from './styles/extraInfo.sass'
import DelIcon from '../../../asserts/icon/weekly-summary/delete.svg'

class TextEditor extends Component {
  static propTypes = {
    loginRole: PropTypes.string,
    initialValue: PropTypes.string,
    renderResult: PropTypes.func,
    disDeletable: PropTypes.bool
  }

  state = { value: '', isEdit: false }

  cancel = (status) => {
    this.props.updateEditingCount(-1)
    this.props.updateEmailAction(1)
    this.setState({
      isEdit: false,
      showConfirm: false,
      initialValue: this.props.initialValue
    })
    if (status === 'done') {
      this.props.markAsDone()
    }
  }

  clear = () => {
    this.setState({ contentHtml: '', initialValue: '' })
    this.props.updateCommend('', 'delete')
    this.props.updateEmailAction(1)
  }

  submit = async () => {
    // const params = await this.refForm.handleSubmit()
    const { value } = this.state
    this.props.updateCommend(value, 'save')
    this.props.updateEmailAction(1)
    this.cancel()
  }

  componentDidMount() {
    const { initialValue } = this.props
    this.setState({ value: initialValue })
  }

  renderEditor() {
    const { limit } = this.props
    const { value, showConfirm } = this.state
    return (
      <div className={Style}>
        <div className="information-editor text-only">
          <Input
            maxLength={50}
            value={value}
            onChange={(e) => this.setState({ value: e.target.value })}
          />
          <div className="editor-footer">
            <div className="limit">
              {limit && `${limit - value.length} character(s) remaining`}
            </div>
            <div className="buttons">
              <Button
                type="primary"
                ghost
                onClick={() => this.setState({ showConfirm: true })}
              >
                CANCEL
              </Button>
              <Button
                type="primary"
                ghost
                onClick={this.submit}
                disabled={!value.trim()}
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
        <ConfirmPopUp
          title="Cancel editing confirmation"
          content="Changes you made to this section will not be saved. Are you sure you want to continue?"
          okText="DISCARD"
          visible={showConfirm}
          handleCancel={() => this.setState({ showConfirm: false })}
          handleOk={this.cancel}
        />
      </div>
    )
  }
  render() {
    const { initialValue, renderResult, loginRole, disDeletable } = this.props
    const { value, isEdit } = this.state
    if (loginRole === 'User') {
      return renderResult(value || initialValue)
    }
    if (isEdit) {
      return this.renderEditor()
    }
    return (
      <div className={Style}>
        <div className="information-container text-only">
          <div className="opr">
            <EditCircleIcon
              className="opr-button"
              onClick={() => {
                this.props.updateEditingCount(1)
                this.props.updateEmailAction(0)
                this.setState({ isEdit: true })
              }}
            />
            {!disDeletable && (
              <img
                className="opr-button"
                onClick={() => this.clear()}
                src={DelIcon}
                alt=""
              />
            )}
          </div>
          <div className="container">{initialValue}</div>
        </div>
      </div>
    )
  }
}

export default TextEditor
