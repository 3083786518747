import React from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'

import { loadUser } from '../../../../utils/storage'

import { getDbFields, getTitleByType } from '../common'
import { matchRangeByType, getAgeTag } from '../../../../utils/common'
import {
  getBiomarkerById,
  getBloodTestsHistory
} from '../../../../api/biomarkers'
import { getBloodTestById } from '../../../../api/bloodTests'
import { getMetricHistories, getMetricData } from '../../../../api/metric'
import { getRangeAgeScopes } from '../../../../api/rangeAgeScope'

import IconReturn from '../../../../asserts/icon/icon-return.svg'

export default (WrappedComponent) =>
  (class DatasetDetailWrapper extends React.Component {
    state = { dataLoading: true, show: 'about' }

    updateMatchRange = (personalBioMarkerObj) => {
      let functionalMatchRange, conventionalMatchRange, optimalRange

      conventionalMatchRange =
        personalBioMarkerObj.conventional_output_range || {}
      optimalRange = personalBioMarkerObj.conventional_optimal_range || []
      conventionalMatchRange.min = optimalRange[0]
      conventionalMatchRange.max = optimalRange[1]

      functionalMatchRange = personalBioMarkerObj.functional_output_range || {}
      optimalRange = personalBioMarkerObj.functional_optimal_range || []
      functionalMatchRange.min = optimalRange[0]
      functionalMatchRange.max = optimalRange[1]
      this.setState({ functionalMatchRange, conventionalMatchRange })
    }

    initial = async () => {
      const {
        datasetId,
        metricId,
        type,
        hash,
        setMobileHeaderState
        // groups
      } = this.props

      const person = this.props.person || loadUser()
      const dbFields = getDbFields(type || 'blood-test')
      this.setState({ person, dbFields })
      try {
        let biomarker, histories, dataset, value
        const ageScopes = (await getRangeAgeScopes()).age_scope
        const ageTag = getAgeTag(person.profile, ageScopes)
        if (!type || type === 'blood-test') {
          biomarker = await getBiomarkerById(metricId, hash)
          const result = await getBloodTestById(datasetId, person.id, hash)
          dataset = result.dataset
          const snapshot = result.dataset.snapshot
          biomarker.personal_data = snapshot.filter(
            (item) => item.bio_marker_id === biomarker.id
          )[0]
          if (!biomarker.options) {
            const res = await getBloodTestsHistory(metricId, person.id, hash)
            histories = res.history
            biomarker.conventional_ranges = biomarker.conventional_ranges.map(
              (range) => {
                const scope =
                  range[`${person.profile.gender}_age_scope`][ageTag]
                const [min, max, color] = scope || []
                range.min = min
                range.max = max
                range.color = color
                return range
              }
            )
            biomarker.functional_ranges = biomarker.functional_ranges.map(
              (range) => {
                const scope =
                  range[`${person.profile.gender}_age_scope`][ageTag]
                const [min, max, color] = scope || []
                range.min = min
                range.max = max
                range.color = color
                return range
              }
            )
          }
          value = biomarker.personal_data && biomarker.personal_data.value
          await this.updateMatchRange(biomarker.personal_data)
        } else {
          const result = await getMetricData(
            metricId,
            type,
            datasetId,
            person.id,
            hash
          )

          biomarker = result.metric
          dataset = result.dataset.dataset
          biomarker.ranges.sort((a, b) => a.order - b.order)
          biomarker.ranges = biomarker.ranges.map((range) => {
            const scope = range[`${person.profile.gender}_age_scope`][ageTag]
            const [min, max, color] = scope || []
            range.min = min
            range.max = max
            range.color = color
            return range
          })
          biomarker.personal_data =
            dataset.snapshot.filter(
              (item) => item[dbFields.biomarkerIdField] === biomarker.id
            )[0] || {}
          let range
          value = biomarker.personal_data && biomarker.personal_data.value
          if (biomarker.options) {
            range = biomarker.ranges.find(
              (item) => item.name === value.toString()
            )
          } else {
            const res = await getMetricHistories(
              metricId,
              type,
              person.id,
              hash
            )
            histories = res && res.history

            range = biomarker.ranges.filter((item) => {
              const { result: result1, min, max, color } = matchRangeByType(
                value,
                item,
                person.profile,
                ageScopes
              )
              if (result1) {
                item.min = min
                item.max = max
                item.color = color
              }
              return result1
            })[0]
          }
          this.setState({ range })
        }

        if (setMobileHeaderState) {
          const title = getTitleByType(type)
          setMobileHeaderState({ title: `${title}/${biomarker.name}` })
        }

        if (histories) {
          histories = histories.sort((a, b) => {
            return moment(a.test_date) - moment(b.test_date)
          })
        }

        this.setState({
          html: biomarker.introduction,
          biomarker,
          dataLoading: false,
          histories,
          dataset,
          ageScopes
        })
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
      }
    }

    UNSAFE_componentWillMount() {
      this.initial()
    }

    render() {
      const { history } = this.props
      const { biomarker } = this.state
      return (
        <>
          {biomarker && (
            <h1 className="page-title desktop-show">
              <img
                src={IconReturn}
                alt="return "
                onClick={() => history.go(-1)}
              />
              {biomarker.name}
            </h1>
          )}
          <WrappedComponent
            {...this.props}
            {...this.state}
            setPropsState={(state) => this.setState(state)}
          />
        </>
      )
    }
  })
