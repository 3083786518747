import React from 'react'
import Icon from '@ant-design/icons'

export const NotFocusNutritionIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.698 0.999023H13.302L1 13.301V30.697L13.302 42.999H30.698L43 30.697V13.301L30.698 0.999023Z"
          stroke="#8596A4"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M22 9.99902V25.999"
          stroke="#8596A4"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M22 33.999C22.5523 33.999 23 33.5513 23 32.999C23 32.4467 22.5523 31.999 22 31.999C21.4477 31.999 21 32.4467 21 32.999C21 33.5513 21.4477 33.999 22 33.999Z"
          stroke="#8596A4"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </svg>
    )}
    {...props}
  />
)

export const HSDSkipHistoryDateIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.92675 2.27L5.73 0.07325C5.68313 0.0263627 5.61955 1.41594e-05 5.55325 0L2.44675 0C2.38045 1.41594e-05 2.31687 0.0263627 2.27 0.07325L0.07325 2.27C0.0263627 2.31687 1.41594e-05 2.38045 0 2.44675L0 5.55325C1.41594e-05 5.61955 0.0263627 5.68313 0.07325 5.73L2.27 7.92675C2.31687 7.97364 2.38045 7.99999 2.44675 8H5.55325C5.61955 7.99999 5.68313 7.97364 5.73 7.92675L7.92675 5.73C7.97364 5.68313 7.99999 5.61955 8 5.55325V2.44675C7.99999 2.38045 7.97364 2.31687 7.92675 2.27ZM4.48225 1.75L4.25 4.75H3.75L3.51775 1.75H4.48225ZM4 6.25C3.90111 6.25 3.80444 6.22068 3.72221 6.16573C3.63999 6.11079 3.5759 6.0327 3.53806 5.94134C3.50022 5.84998 3.49031 5.74945 3.50961 5.65245C3.5289 5.55546 3.57652 5.46637 3.64645 5.39645C3.71637 5.32652 3.80546 5.2789 3.90245 5.25961C3.99945 5.24031 4.09998 5.25022 4.19134 5.28806C4.2827 5.3259 4.36079 5.38999 4.41573 5.47221C4.47068 5.55444 4.5 5.65111 4.5 5.75C4.5 5.88261 4.44732 6.00979 4.35355 6.10355C4.25979 6.19732 4.13261 6.25 4 6.25Z"
          fill="#8596A4"
        />
      </svg>
    )}
    {...props}
  />
)
