import { Button, Card, Checkbox } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getExportTxtForBodyComps } from '../../api/healthScore'

const Container = styled.div`
.header-tool{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ant-card{
    border-radius: 12px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.ant-card-body{
    padding: 0px;
    .ant-checkbox-group{
        width: 100%;
        > div{
            border-bottom: 1px solid #E6E9F2;
            padding: 16px 24px;
        }
    }
}
`

const items = [
    {label:'Vitals', value:'vitals'},
    {label:'Body Composition', value:'body_composition'},
    {label:'Bone Density', value:'bone_density'},
    {label:'Movement Quality Balance', value:'movement_quality_balance'},
    {label:'Muscle Fitness', value:'muscle_fitness'},
    {label:'Aerobic Fitness', value:'aerobic_fitness'},
    {label:'Cognitive Health', value:'cognitive_health'},
    {label:'Blood Panel', value:'blood_panel'}
]

export default function ExportData({person}) {

    const [value, setValue] = useState(items.map(e => e.value))
    const [loading, setLoading] = useState(false)

    const checkAll = value.length === items.length
    const indeterminate = value.length > 0 & value.length < items.length

    const onClickExport = async () => {
        setLoading(true)
        getExportTxtForBodyComps(person.id, value.toString()).then(e => {
            const blobFile = new Blob([e], { type: 'text/plain' })
            const url = window.URL.createObjectURL(blobFile)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = url
            a.download = person.full_name
            a.click()
        }).finally(() => {
            setLoading(false)
        })
    }

    const onSelectAll = (e) => {
        if(e.target.checked){
            setValue(items.map(e => e.value))
        }else{
            setValue([])
        }
    }

  useEffect(() => {
  }, [])
  return <Container>
            <div className='header-tool'>
                <h1 className="page-title">Export Data</h1>
                <Button loading={loading} type="primary" disabled={value.length === 0} onClick={onClickExport}>EXPORT TXT</Button>
            </div>
            <Card title={<Checkbox checked={checkAll} indeterminate={indeterminate} onChange={onSelectAll}>{checkAll ? 'Deselect All' : 'Select All'}</Checkbox>}>
            <Checkbox.Group value={value} onChange={setValue}>
                {items.map(e => <div><Checkbox value={e.value}>{e.label}</Checkbox></div>)}
            </Checkbox.Group>
            </Card>
        </Container>
}
