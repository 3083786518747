import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import {
  getClientList,
  getClientDetail,
  getWeeklySummary,
  getSummaryEmail
} from '../../../models/breadcrumb.model'
import WeeklySummaryBackupLayout from '../../../components/summaryEmailBackup/weeklySummary'

class BackupEmailLayout extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const {
      getPerson,
      setClientPageTitle,
      setClientBreadcrumb
      // match
    } = this.props
    let { person } = this.state
    // const {
    //   params: { id }
    // } = match
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }

    setClientPageTitle('Summary Email Detail')
    // id === 'new' ? 'Create New Email' :
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getSummaryEmail(person),
        getWeeklySummary(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const {
      match: { params, path },
      history
    } = this.props
    const { person } = this.state
    return person ? (
      <WeeklySummaryBackupLayout
        person={person}
        id={params.id}
        createSync={(id) => history.replace(path.replace(':id', id))}
        saveSync={() => history.replace(path.replace(':id', ''))}
      />
    ) : (
      <></>
    )
  }
}

export default BackupEmailLayout
