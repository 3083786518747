import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import { Button } from 'antd'
import {
  ArrowRightIcon,
  // CheckedCircleIcon,
  LogoutIcon
} from '../../../components/icons/onboarding'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import setUpQuestionnaire from '../../../asserts/images/client-setup-questionnaire.svg'

class Landing extends Component {
  static propTypes = {}
  nextStep = () => {
    const {
      location: { search },
      history
    } = this.props
    history.push(`/questionnaire/nutrition-score${search}`)
  }

  render() {
    const { history, logOut } = this.props
    return (
      <div className="set-up-screen">
        <div className="header">
          <img src={phsLogo} alt="logo" />
          <div className="log-out" onClick={() => logOut(history)}>
            <LogoutIcon />
            LOG OUT
          </div>
        </div>
        <div className="set-up-container">
          <div className="landing">
            <div>
              <div className="title">
                <span>Let's talk about your </span>
                <br />
                <span>nutrition habits</span>
              </div>
              <div style={{ marginTop: 30 }}>
                We're going to ask questions about your food habits over
              </div>
              <div>
                the past ~3 months. Please answer as honestly as possible.
              </div>
              <div> Heads up: this will take 10 minutes or less.</div>
              <Button
                type="primary"
                onClick={this.nextStep}
                style={{ width: 150, height: 48, marginTop: 20 }}
              >
                LET’S DO IT
                <ArrowRightIcon />
              </Button>
            </div>
            <div className="img-container">
              <img src={setUpQuestionnaire} alt="set up" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Landing)
