import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Element } from 'react-scroll'
import SingleImage from '../../creport/singleImage'
import Category from './category'
import { injectCategories } from '../common'
// import LifeStyleLogo from '../../../../asserts/images/life-style-dataset.svg'
// import BodyCompLogo from '../../../../asserts/images/body-com-dataset.svg'

import { categoriesStyle } from './index.sass'

class Categories extends Component {
  static propTypes = {}

  componentDidMount() {
    const {
      location: { search }
    } = this.props
    const metricName = decodeURI(search.replace('?metric=', ''))
    const targetMetric = document.getElementsByName(metricName)
    if (targetMetric[0]) {
      window.scrollTo(0, targetMetric[0].offsetTop)
    }
  }

  renderCommonCategories(categories, dataset) {
    const {
      containerTitle,
      person,
      dbField: { biomarkerIdField, metricsField },
      dataHandle,
      ageScopes
    } = this.props
    const categoryProps = {
      ...dataHandle,
      ageScopes,
      person,
      biomarkerIdField,
      datasetId: dataset.id,
      testDate: dataset.test_date,
      biomarkerType: metricsField
    }
    return (
      <div className={categoriesStyle}>
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="root-category extra">
              {index === 0 && containerTitle && (
                <h1 className="container-title">{containerTitle}</h1>
              )}
              {category && <Category category={category} {...categoryProps} />}
            </div>
          </Element>
        ))}
      </div>
    )
  }

  renderBodyCompContainer(categories, dataset) {
    const {
      person,
      dbField: { biomarkerIdField, metricsField },
      dataHandle,
      ageScopes
    } = this.props
    // const [category] = categories || []
    const categoryProps = {
      ...dataHandle,
      ageScopes,
      person,
      biomarkerIdField,
      datasetId: dataset.id,
      testDate: dataset.test_date,
      biomarkerType: metricsField
    }
    const { upload, deletePhoto } = dataHandle
    return (
      <div className={categoriesStyle}>
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="root-category">
              <Category category={category} {...categoryProps} />
            </div>
            {category.name === 'Total Body Composition' && (
              <div className="root-category">
                <div className="full-width">
                  <h1>Composition Trend: Total Graph</h1>
                  <SingleImage
                    photo={dataset.trend_graph}
                    upload={(...argument) => upload('trend_graph', ...argument)}
                    deletePhoto={() =>
                      deletePhoto('trend_graph', dataset.trend_graph)
                    }
                  />
                </div>
              </div>
            )}
          </Element>
        ))}
      </div>
    )
  }

  renderLifeStyleContainer(categories, dataset) {
    const {
      person,
      dbField: { biomarkerIdField, metricsField },
      dataHandle,
      ageScopes
    } = this.props
    // const [category] = categories || []
    const categoryProps = {
      ...dataHandle,
      ageScopes,
      person,
      biomarkerIdField,
      datasetId: dataset.id,
      testDate: dataset.test_date,
      biomarkerType: metricsField
    }
    const { upload, deletePhoto } = dataHandle
    return (
      <div className={categoriesStyle}>
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="root-category">
              <div className="full-width">
                <p>{/* {category.description} */}</p>

                {category.name === 'Stress & Sleep' && (
                  <div className="attachment-container">
                    <h1>Stress Summary</h1>

                    <SingleImage
                      photo={dataset.stress_summary}
                      upload={(...argument) =>
                        upload('stress_summary', ...argument)
                      }
                      deletePhoto={() =>
                        deletePhoto('stress_summary', dataset.stress_summary)
                      }
                    />
                  </div>
                )}

                {category.name === 'Recovery' && (
                  <div className="attachment-container">
                    <h1>Recovery Summary</h1>

                    <SingleImage
                      photo={dataset.recovery_summary}
                      upload={(...argument) =>
                        upload('recovery_summary', ...argument)
                      }
                      deletePhoto={() =>
                        deletePhoto(
                          'recovery_summary',
                          dataset.recovery_summary
                        )
                      }
                    />
                  </div>
                )}

                {category.name === 'Exercise' && (
                  <div className="attachment-container">
                    <h1>Exercise Summary</h1>

                    <SingleImage
                      photo={dataset.exercise_summary}
                      upload={(...argument) =>
                        upload('exercise_summary', ...argument)
                      }
                      deletePhoto={() =>
                        deletePhoto(
                          'exercise_summary',
                          dataset.exercise_summary
                        )
                      }
                    />
                  </div>
                )}
                {<Category category={category} {...categoryProps} />}
              </div>
            </div>
          </Element>
        ))}
      </div>
    )
  }

  render() {
    const {
      showDataset,
      datasets,
      // selectCategory,
      dbField: { metricsField, biomarkerIdField, type }
    } = this.props
    let { categories } = this.props
    const dataset =
      datasets && datasets.find((item) => item.test_date === showDataset)
    if (dataset && categories) {
      categories = injectCategories(
        categories,
        dataset.snapshot,
        metricsField,
        biomarkerIdField
      )
    }

    if (!dataset || !categories) return ''

    switch (type) {
      case 'lifestyle_assessment':
        return this.renderLifeStyleContainer(categories, dataset)
      case 'body_comp':
        return this.renderBodyCompContainer(categories, dataset)
      default:
        return this.renderCommonCategories(categories, dataset)
    }
  }
}

export default Categories
