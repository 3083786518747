import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import DraftReport from './draft'
import PreviewReport from './preview'
import NoMatch from '../noMatch'
import { reportStyle } from './index.sass'

export class ComprehensiveReport extends Component {
  static propTypes = {}

  render() {
    const { match } = this.props
    return (
      <div className={reportStyle}>
        <Switch>
          <Route
            path={`${match.url}/:personId/draft/:reportId`}
            component={DraftReport}
          />
          <Route
            path={`${match.url}/:personId/preview/:reportId`}
            component={PreviewReport}
          />
          <Route
            path={`${match.url}/preview/:hash`}
            component={PreviewReport}
          />
          <Route component={NoMatch} />
        </Switch>
      </div>
    )
  }
}

export default ComprehensiveReport
