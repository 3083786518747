import React, { Component } from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import { templateStyle } from './styles/templates.sass'

class TemplatesCascader extends Component {
  static propTypes = {
    template: PropTypes.array,
    selectTemplate: PropTypes.func
  }
  state = { cascader: [] }

  select = (text) => {
    this.props.selectTemplate(text)
    this.setState({ showList: false, subCategory: null })
  }

  getCascader = (templates) => {
    const categories = Array.from(
      new Set(templates.map((item) => item.category))
    )
    const cascader = categories.map((item) => {
      const subCategoriesArr = templates.filter((tem) => tem.category === item)
      const subCategories = Array.from(
        new Set(subCategoriesArr.map((item) => item.subcategory))
      ).map((sub) => ({
        title: sub,
        children: subCategoriesArr.filter((child) => child.subcategory === sub)
      }))
      return {
        title: item,
        children: subCategories
      }
    })

    return cascader
  }

  componentDidMount() {
    const { templates } = this.props
    const cascader = this.getCascader(templates)
    this.setState({ cascader })
  }

  render() {
    const {
      cascader,
      category,
      subCategory,
      titles,
      selectIndex,
      selectSubIndex,
      showList
    } = this.state

    return (
      <div className={templateStyle}>
        <span onClick={() => this.setState({ showList: !showList })}>
          {' '}
          ADD pre-made template
          <CaretRightOutlined rotate={showList ? 90 : 0} />
        </span>
        {showList && (
          <>
            <ul
              className="category-list"
              onMouseLeave={() =>
                this.setState({ showList: false, subCategory: null })
              }
            >
              {cascader.map((item, index) => (
                <li
                  className={category === item.title ? 'active' : ''}
                  key={index}
                  onMouseEnter={() =>
                    this.setState({
                      category: item.title,
                      subCategory: item.children,
                      selectIndex: index,
                      titles: null
                    })
                  }
                >
                  <span>{item.title}</span>
                  <CaretRightOutlined />
                </li>
              ))}

              {subCategory && (
                <li className="sub-container">
                  <ul
                    className="subcategory-list"
                    style={{ marginTop: selectIndex * 50 }}
                  >
                    {subCategory.map((item, index) => (
                      <li
                        key={index}
                        onMouseEnter={() =>
                          this.setState({
                            titles: item.children,
                            selectSubIndex: index
                          })
                        }
                      >
                        {item.title}
                      </li>
                    ))}

                    {titles && (
                      <li className="title-container">
                        <ul
                          className="title-list"
                          style={{ marginTop: selectSubIndex * 50 }}
                        >
                          {titles.map((item, index) => (
                            <li
                              key={index}
                              onClick={() => this.select(item.text)}
                            >
                              {item.title}
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                  </ul>
                </li>
              )}
            </ul>
          </>
        )}
      </div>
    )
  }
}

export default TemplatesCascader
