import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  PlusOutlined,
  SearchOutlined,
  LockOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { Button, message, Tabs, Input, Modal, Checkbox } from 'antd'
import Highlighter from 'react-highlight-words'
import RightDrawer from '../../components/UI/rightDrawer'
import DataTable from '../../components/UI/table'
import PolygonAvatar from '../../components/UI/polygonAvatar'
import { getClients, editClient } from '../../api/team'
import { getClientList } from '../../models/breadcrumb.model'
import ClientForm from '../../components/modalFrom/clientForm'
import WelcomeEmail from '../../components/modalFrom/welcomeEmail'
import { deletePeople } from '../../api/people'
import { getRole } from '../../utils/common'
import { ChartIcon, ExportIcon } from '../../components/icons'
import ExportClientsDataModal from '../../components/UI/exportClientsDataModal'
import Message from '../../components/message'
import { userType } from '../../utils/constant'

import { clientStyle, deleteClientStyle } from './index.sass'

import HealthkitLogo from '../../asserts/icon/goals/hk-large.png'
import GarminLogo from '../../asserts/icon/goals/garmin-large.png'
import OuraLogo from '../../asserts/icon/goals/oura-large.png'
import PolarLogo from '../../asserts/icon/goals/polar-large.png'
import WithingsLogo from '../../asserts/icon/goals/withings-large.png'

import ClientActionEditIcon from '../../asserts/icon/client/icon-client-edit.svg'
import ClientMessageIcon from '../../asserts/icon/client/icon-client-message.svg'
import ClientUnreadMessageIcon from '../../asserts/icon/client/icon-client-unread-message.svg'
import ActionNoteIcon from '../../asserts/icon/client/icon-client-note.svg'
import { loadUser } from '../../utils/storage'
import Notes from '../../components/notes'

const confirm = Modal.confirm
const { TabPane } = Tabs

export class ClientList extends Component {
  static propTypes = {}

  state = {
    assessment: [],
    coreClient: [],
    nonCoreClient: [],
    partner: [],
    testClient: [],
    admin: [],
    archive: [],
    prospect: [],
    implementation: [],
    loading: false,
    createClient: false,
    editWelcomeEmail: false,
    showMembership: false,
    initClient: null,
    editIndex: null,
    showType: 'coreClient',
    showMessage: false,
    showComment: false,
    loginRole: getRole()
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      const values = value.split(',')
      let filterValue = record[dataIndex]
      if (dataIndex === 'location') {
        filterValue =
          record.profile && record.profile.city ? record.profile.city : ''
      } else if (dataIndex === 'name') {
        filterValue = `${record.profile.nickname || record.first_name} ${
          record.last_name
        }`
      }
      return values.find((_value) =>
        filterValue
          .toString()
          .toLowerCase()
          .includes(_value.toLowerCase().trim())
      )
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={this.getSearchWords()}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      )
  })

  getSearchWords = () =>
    this.state.searchText
      ? this.state.searchText.split(',').map((item) => item.trim())
      : []

  aColumns = [
    {
      key: 'avatar',
      render: (text, record) => (
        <PolygonAvatar
          width={46}
          image={record.avatar && record.avatar.oss_url}
          innerId={`list-inner-${record.id}`}
          hexId={`list-hex-${record.id}`}
        />
      )
    },
    {
      title: 'NAME',
      key: 'name',
      ...this.getColumnSearchProps('name'),
      sorter: (a, b) => {
        const aName = `${a.profile.nickname || a.first_name} ${a.last_name}`
        const bName = `${b.profile.nickname || b.first_name} ${b.last_name}`

        return (
          aName.toLowerCase().charCodeAt() - bName.toLowerCase().charCodeAt()
        )
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        const name = `${record.profile.nickname || record.first_name} ${
          record.last_name
        }`
        const isLocked = record.profile.pre_screening_locked
        return (
          <>
            {this.state.searchedColumn === 'name' ? (
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={this.getSearchWords()}
                autoEscape
                textToHighlight={name.toString()}
              />
            ) : (
              name
            )}
            {/* <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={this.getSearchWords()}
              autoEscape
              textToHighlight={name.toString()}
            /> */}
            {isLocked && (
              <LockOutlined
                style={{
                  color: '#EA5400',
                  marginLeft: 5,
                  cursor: 'pointer',
                  fontSize: 24,
                  verticalAlign: 'top'
                }}
                onClick={(e) => {
                  e.stopPropagation()
                  Modal.confirm({
                    icon: (
                      <ExclamationCircleOutlined style={{ color: '#EA5400' }} />
                    ),
                    width: 480,
                    title: `Allow ${name} to start the onboarding process`,
                    content: (
                      <div>
                        Please direct the client to{' '}
                        <a
                          href="https://app.apeiron.life/sign-in"
                          _blank="target"
                        >
                          https://app.apeiron.life/sign-in
                        </a>{' '}
                        to continue with the logistics form
                      </div>
                    ),
                    okText: 'Confirm',
                    onOk: async () => {
                      const result = await editClient(
                        {
                          profile: {
                            pre_screening_locked: false
                          }
                        },
                        record.id
                      )
                      this.submitSync(result.team.person, 'update')
                    }
                  })
                }}
              />
            )}
            <br />{' '}
            <span
              style={{
                color: '#4C6072',
                opacity: 0.6,
                fontSize: 10
              }}
            >
              {record.id}
            </span>
          </>
        )
      }
    },
    {
      title: 'MEDICAL',
      key: 'profile.medical_status',
      filterMultiple: false,
      filters: [
        { text: 'Recorded', value: '1' },
        { text: 'UnRecorded', value: '0' }
      ],
      onFilter: (value, record) => {
        return `${record.profile.medical_status}` === value
      },
      render: (text, record) => {
        const role = getRole()
        return (
          <div className="medical-status">
            <Checkbox
              checked={record.profile.medical_status}
              disabled={role === 'Service_Provider'}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                this.editClientMedicalStatus(
                  record.id,
                  e.target.checked ? 1 : 0
                )
              }}
            />
          </div>
        )
      }
    },
    {
      title: 'DATA AGGREGATION',
      key: 'profile.in_data_aggregation',
      filterMultiple: false,
      filters: [
        { text: 'Included', value: true },
        { text: 'Not Included', value: false }
      ],
      onFilter: (value, record) => {
        return (record.profile.in_data_aggregation || false) === value
      },
      render: (text, record) => {
        const role = getRole()
        return (
          <div className="medical-status">
            <Checkbox
              checked={record.profile.in_data_aggregation}
              disabled={role === 'Service_Provider'}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                this.editClientDataAggregationStatus(
                  record.id,
                  e.target.checked
                )
              }}
            />
          </div>
        )
      }
    },
    {
      title: 'AGE',
      key: 'age',
      filters: [
        { text: '>61', value: '60,' },
        { text: '51-60', value: '50,60' },
        { text: '41-50', value: '40,50' },
        { text: '31-40', value: '30,40' },
        { text: '21-30', value: '20,30' },
        { text: '<=20', value: ',20' }
      ],
      onFilter: (value, record) => {
        const age =
          record.profile && record.profile.date_of_birth
            ? moment().diff(record.profile.date_of_birth, 'years')
            : 0
        const valueArr = value.split(',').map((item) => Number(item))
        if (valueArr[0] && !valueArr[1]) {
          return age > valueArr[0]
        } else if (valueArr[0] && valueArr[1]) {
          return age > valueArr[0] && age <= valueArr[1]
        } else if (!valueArr[0] && valueArr[1]) {
          return age <= valueArr[1]
        }
        return age
      },
      filterMultiple: false,
      render: (text, record, index) =>
        record.profile && record.profile.date_of_birth
          ? moment().diff(record.profile.date_of_birth, 'years')
          : '--'
    },
    {
      title: 'GENDER',
      dataIndex: 'profile.gender',
      filters: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        const gender =
          record.profile && record.profile.gender ? record.profile.gender : null
        return gender === value
      },
      render: (text, record, index) => (
        <span style={{ textTransform: 'capitalize' }}>
          {record.profile && record.profile.gender
            ? record.profile.gender
            : '--'}
        </span>
      )
    },
    {
      title: 'LOCATION',
      dataIndex: 'profile.city',
      key: 'location', //['profile', 'city'],
      ...this.getColumnSearchProps('location'),
      render: (text, record, index) => {
        const location =
          record.profile && record.profile.city ? record.profile.city : '--'
        return this.state.searchedColumn === 'location' ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={this.getSearchWords()}
            autoEscape
            textToHighlight={location.toString()}
          />
        ) : (
          location
        )
      }
    },
    {
      title: 'LAST WEEKLY SUMMARY',
      render: (text, record, index) => {
        const [email] = record.summary_emails || []
        if (email && email.log) {
          const { log } = email
          if (log.opened_at) {
            return `Opened on ${moment(log.opened_at.substr(0, 10)).format(
              'MMM DD, YYYY'
            )}`
          } else if (log.sent_at) {
            return `Sent on ${moment(log.sent_at.substr(0, 10)).format(
              'MMM DD, YYYY'
            )}`
          }
        }
        return '--'
      }
    },
    {
      title: 'TIME SINCE FIRST PA',
      render: (text, record, index) => {
        const clientSince =
          record && record.profile && record.profile.client_since
        if (clientSince) {
          const clientSinceMoment = moment(clientSince.substr(0, 10))
          const diff = moment().diff(clientSinceMoment, 'months')
          return `${clientSinceMoment.format('MMM DD, YYYY')} (${diff +
            (diff > 1 ? ' months' : ' month')})`
        } else {
          return '---'
        }
      }
    },
    {
      title: 'CONNECTED DEVICES',
      dataIndex: 'profile.devices',
      key: 'devices',
      render: (text, record, index) => {
        const devices =
          (record.profile && record.profile.device_connection_status) || {}
        const devicesList = Object.keys(devices).filter((item) => devices[item])
        return devicesList.length > 0 ? (
          <div className="devices-list">
            {devicesList.map((item, index) => {
              let source
              switch (item) {
                case 'ouraring':
                  source = OuraLogo
                  break
                case 'garmin':
                  source = GarminLogo
                  break
                case 'polar':
                  source = PolarLogo
                  break
                case 'withings':
                  source = WithingsLogo
                  break
                default:
                  source = HealthkitLogo
                  break
              }
              return <img src={source} key={index} alt="device" />
            })}
          </div>
        ) : (
          '--'
        )
      }
      // record.profile && record.profile.devices ? record.profile.devices : '--'
    },
    {
      key: 'action',
      title: 'ACTIONS',
      render: (text, record, index) => {
        const disabled = getRole(record.role) === 'Expert'
        const loginRole = this.state.loginRole
        const loginUser = loadUser()
        return (
          <div className="link-group">
            {/* {loginRole !== 'Service_Provider' && (
              <span
                className={`${disabled ? 'disabled' : ''} as-link`}
                onClick={(e) => {
                  e.stopPropagation()
                  if (disabled) return
                  const deleteClient = this.deleteClient
                  confirm({
                    title: `Do you want to delete client: ${record.profile
                      .nickname || record.first_name} ${record.last_name}?`,
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteClient(record.id, index)
                    }
                  })
                }}
              >
                <img src={ClientActionTrashIcon} alt="trash" />
              </span>
            )} */}
            <span
              className={`${disabled ? 'disabled' : ''} as-link`}
              style={{ marginLeft: 15 }}
              onClick={(e) => {
                e.stopPropagation()
                if (disabled) return
                this.setState({
                  initClient: record,
                  showComment: true,
                  editIndex: index
                })
              }}
            >
              <img src={ActionNoteIcon} alt="comment" />
            </span>
            <span
              className={`${disabled ? 'disabled' : ''} as-link`}
              style={{ marginLeft: 15 }}
              onClick={(e) => {
                e.stopPropagation()
                if (disabled) return
                this.setState({
                  initClient: record,
                  showMessage: true,
                  editIndex: index
                })
              }}
            >
              {record.new_message ? (
                <img src={ClientUnreadMessageIcon} alt="unread message" />
              ) : (
                <img src={ClientMessageIcon} alt="message" />
              )}
            </span>

            {loginRole !== 'Service_Provider' && (
              <span
                className={`${
                  disabled && !loginUser.can_edit_expert ? 'disabled' : ''
                } as-link`}
                style={{ marginLeft: 15 }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (disabled && !loginUser.can_edit_expert) return
                  this.setState({
                    initClient: record,
                    createClient: true,
                    editIndex: index
                  })
                }}
              >
                <img src={ClientActionEditIcon} alt="edit" />
              </span>
            )}
            {/* <span
              className={`${disabled ? 'disabled' : ''} as-link`}
              style={{ marginLeft: 15 }}
              onClick={() => {
                if (disabled) return
                const { history } = this.props
                history.push(`/client/${record.id}/detail`)
              }}
            >
              <img src={ClientActionDetailIcon} alt="detail" />
            </span> */}
          </div>
        )
      }
    }
  ]

  markRead = () => {
    const { initClient, editIndex, assessment } = this.state
    initClient.new_message = false
    assessment.splice(editIndex, 1, initClient)
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
    confirm()
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  handleDeleteClient = (record) => {
    const deleteClient = this.deleteClient
    confirm({
      title: `Do you want to delete client: ${record.profile.nickname ||
        record.first_name} ${record.last_name}?`,
      icon: <></>,
      className: deleteClientStyle,
      width: '546px',
      content: (
        <>
          <p>Please be aware when this account is deleted:</p>
          <ul>
            <li>You can't access this account anymore</li>
            <li>This account will no longer be able to log in</li>
            <li>
              You will not be able to retrieve this account data or reactivate
              this account. This is a permanent action
            </li>
            <li>Programs (if any) of this account will be canceled</li>
          </ul>
        </>
      ),
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return deleteClient(record.id)
      },
      onCancel() {}
    })
  }

  deleteClient = async (id, index) => {
    let {
      assessment,
      coreClient,
      nonCoreClient,
      testClient,
      partner,
      archive
    } = this.state
    try {
      await deletePeople(id)
      assessment = assessment.filter((item) => item.id !== id)
      coreClient = coreClient.filter((item) => item.id !== id)
      nonCoreClient = nonCoreClient.filter((item) => item.id !== id)
      testClient = testClient.filter((item) => item.id !== id)
      partner = nonCoreClient.filter((item) => item.id !== id)
      archive = archive.filter((item) => item.id !== id)
      this.setState({
        assessment,
        coreClient,
        nonCoreClient,
        testClient,
        partner,
        archive,
        createClient: false
      })
      message.success('The account has been deleted!')
    } catch (err) {
      console.error(err)
      message.warning(err.message)
    }
  }

  editClientMedicalStatus = async (id, status) => {
    try {
      const result = await editClient(
        {
          profile: {
            medical_status: status
          }
        },
        id
      )
      this.submitSync(result.team.person, 'update')
    } catch (err) {
      console.error(err)
      message.error(err.message)
    }
  }

  editClientDataAggregationStatus = async (id, status) => {
    try {
      const result = await editClient(
        {
          profile: {
            in_data_aggregation: status
          }
        },
        id
      )
      this.submitSync(result.team.person, 'update')
    } catch (err) {
      console.error(err)
      message.error(err.message)
    }
  }

  initial = async (showWelcomePersonId) => {
    const result = await getClients()
    const user = loadUser()

    const loginRole = getRole(user.role)
    /*
      User account type
      0 -> Core
      1 -> No-Core
      2 -> Test User
      3 -> Partners
      4 ->
      5 ->
      6 -> Archive
     */
    this.setState({
      assessment: result.clients,
      coreClient: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Core
      ),
      nonCoreClient: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Non_Core
      ),
      testClient: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Test_User
      ),
      partner: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Partners
      ),
      archive: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Archive
      ),
      prospect: result.clients.filter(
        (item) =>
          getRole(item.role) === 'User' &&
          item.profile &&
          item.profile.account_type === userType.Prospect
      ),
      admin: result.clients.filter((item) => getRole(item.role) === 'Expert'),
      implementation: result.clients,
      loading: false,
      loginRole: loginRole
    })
    if (showWelcomePersonId) {
      const client = result.clients.find(
        (e) => e.id === parseInt(showWelcomePersonId)
      )
      client &&
        this.setState({
          initClient: client,
          editWelcomeEmail: true
        })
    }
  }

  submitSync = (person, type) => {
    const {
      assessment,
      coreClient,
      nonCoreClient,
      testClient,
      partner,
      admin,
      archive,
      prospect
    } = this.state
    let { initClient } = this.state
    if (type === 'create') {
      assessment.push(person)
      this.props.history.push(`/send-membership-selection/${person.id}`)
      switch (person.profile.account_type) {
        case 0:
          coreClient.push(person)
          break
        case 1:
          nonCoreClient.push(person)
          break
        case 2:
          testClient.push(person)
          break
        case -1:
          prospect.push(person)
          break
        default:
          break
      }
    } else {
      const editIndex = assessment.findIndex((item) => item.id === person.id)

      assessment.splice(editIndex, 1, person)

      const typesLists = {
        coreClient,
        nonCoreClient,
        testClient,
        partner,
        admin,
        archive,
        prospect
      }

      const handleData = (targetList) => {
        let isTypeChanged = false
        for (const key in typesLists) {
          if (targetList !== key) {
            const editIndex = typesLists[key].findIndex(
              (item) => item.id === person.id
            )
            if (editIndex >= 0) {
              typesLists[targetList].push(person)
              typesLists[key].splice(editIndex, 1)
              isTypeChanged = true
              break
            }
          }
        }
        if (!isTypeChanged) {
          const editIndex = typesLists[targetList].findIndex(
            (item) => item.id === person.id
          )
          typesLists[targetList].splice(editIndex, 1, person)
        }
      }
      if (getRole(person.role) === 'Expert') {
        handleData('admin')
      } else {
        switch (person.profile.account_type) {
          case 0:
            handleData('coreClient')
            break
          case 1:
            handleData('nonCoreClient')
            break
          case 2:
            handleData('testClient')
            break
          case 3:
            handleData('partner')
            break
          // case 4:
          //   handleData('coreClient')
          // break
          // case 5:
          //   handleData('coreClient')
          // break
          case 6:
            handleData('archive')
            break
          case -1:
            handleData('prospect')
            break
          default:
            break
        }
      }

      if (initClient && initClient.id === person.id) {
        initClient = person
      }
    }
    this.setState({
      assessment,
      coreClient,
      nonCoreClient,
      testClient,
      partner,
      archive,
      prospect,
      initClient
    }) // createClient: false
  }

  componentDidMount() {
    const { setClientBreadcrumb, location } = this.props
    const queryParams = new URLSearchParams(location.search)
    const showWelcomePersonId = queryParams.get('show-welcom')
    setClientBreadcrumb([getClientList(true)])
    this.setState({ loading: true })
    this.initial(showWelcomePersonId)
  }

  showGenderNumber() {
    const { coreClient, nonCoreClient, showType } = this.state
    let clients
    if (showType === 'coreClient') {
      clients = coreClient
    } else if (showType === 'nonCoreClient') {
      clients = nonCoreClient
    } else {
      return null
    }

    const male = clients.filter((client) => client.profile.gender === 'male')
    const female = clients.filter(
      (client) => client.profile.gender === 'female'
    )

    return (
      <div className="male-vs-female">
        <span>Total male: {male.length}</span>
        <span>Total female: {female.length}</span>
      </div>
    )
  }

  render() {
    const {
      assessment,
      coreClient,
      nonCoreClient,
      partner,
      testClient,
      admin,
      archive,
      prospect,
      loading,
      createClient,
      initClient,
      editWelcomeEmail,
      showMembership,
      showedExportModal,
      showMessage,
      showComment,
      loginRole
    } = this.state
    const { history } = this.props
    let tabs = [
      {
        tab: `CLIENTS (${coreClient.length})`,
        key: 'coreClient',
        dataSource: coreClient
      },
      {
        tab: `NON-CORE (${nonCoreClient.length})`,
        key: 'nonCoreClient',
        dataSource: nonCoreClient
      },
      {
        tab: `PARTNERS (${partner.length})`,
        key: 'Partner',
        dataSource: partner
      },
      {
        tab: `TEST USERS (${testClient.length})`,
        key: 'testClient',
        dataSource: testClient
      }
    ]
    if (loginRole !== 'Service_Provider') {
      tabs = tabs.concat([
        {
          tab: `ADMIN (${admin.length})`,
          key: 'admin',
          dataSource: admin
        },
        {
          tab: `ARCHIVE (${archive.length})`,
          key: 'archive',
          dataSource: archive
        },
        {
          tab: `PROSPECTS (${prospect.length})`,
          key: 'prospect',
          dataSource: prospect
        },
        {
          tab: `ALL (${assessment.length})`,
          key: 'assessment',
          dataSource: assessment
        }
      ])
    }
    const allTypeList = _.cloneDeep(this.aColumns)
    allTypeList.splice(0, 1, {
      key: 'avatar',
      render: (text, record) => (
        <PolygonAvatar
          width={46}
          image={record.avatar && record.avatar.oss_url}
          innerId={`all-list-inner-${record.id}`}
          hexId={`all-list-hex-${record.id}`}
        />
      )
    })
    return (
      <div className={clientStyle}>
        <h2 className="section-title" style={{ textAlign: 'right' }}>
          {loginRole !== 'Service_Provider' && (
            <>
              <Button
                type="primary"
                ghost
                onClick={() =>
                  this.setState({
                    showedExportModal: true
                  })
                }
              >
                <ExportIcon />
                EXPORT AD HOC REPORTS
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  !showMessage &&
                    this.setState({ createClient: true, initClient: null })
                }}
              >
                <PlusOutlined />
                ADD CLIENT
              </Button>
            </>
          )}
        </h2>
        {loginRole !== 'Service_Provider' && this.showGenderNumber()}
        <div className="list-section">
          <Tabs
            defaultActiveKey="coreClient"
            onChange={(showType) => this.setState({ showType })}
          >
            {tabs.map(({ tab, key, dataSource }) => (
              <TabPane tab={tab} key={key}>
                <DataTable
                  columns={key === 'assessment' ? allTypeList : this.aColumns}
                  // key = assessment allTypeList
                  dataSource={dataSource}
                  loading={loading}
                  hidePagination={true}
                  onClickRow={(record) => {
                    const disabled = getRole(record.role) === 'Expert'
                    if (disabled) return
                    history.push(`/client/${record.id}/detail`)
                  }}
                />
              </TabPane>
            ))}
          </Tabs>
        </div>
        {createClient && (
          <ClientForm
            initValue={initClient}
            loginRole={loginRole}
            onCancel={() => this.setState({ createClient: false })}
            submitSync={this.submitSync}
            editEmail={() =>
              this.setState({ createClient: false, editWelcomeEmail: true })
            }
            sendMemeberShip={() =>
              this.setState({ createClient: false, showMembership: true })
            }
            onDelete={() => this.handleDeleteClient(initClient)}
          />
        )}
        {editWelcomeEmail && (
          <WelcomeEmail
            onCancel={() => this.setState({ editWelcomeEmail: false })}
            person={initClient}
            experts={admin}
          />
        )}
        {showMembership && (
          <WelcomeEmail
            onCancel={() => this.setState({ showMembership: false })}
            isMembership={true}
            experts={admin}
            person={initClient}
          />
        )}
        {showedExportModal && (
          <ExportClientsDataModal
            assessment={assessment}
            close={() => this.setState({ showedExportModal: false })}
          />
        )}
        {initClient && (
          <RightDrawer
            title={
              <div>
                <span
                  style={{
                    color: '#E75F25',
                    background: '#FDEEE6',
                    borderRadius: '100%',
                    display: 'inline-block',
                    marginRight: 12,
                    width: 32,
                    height: 32,
                    textAlign: 'center',
                    lineHeight: '32px'
                  }}
                >
                  <ChartIcon />
                </span>
                {`${initClient.profile.nickname || initClient.first_name} ${
                  initClient.last_name
                }'s `}
                <span style={{ color: '#E75F25' }}>Chat</span>
              </div>
            }
            closeModal={() => this.setState({ showMessage: false })}
            visible={showMessage}
          >
            <Message
              clientId={initClient.id}
              markRead={initClient.new_message ? this.markRead : null}
              sender={loadUser()}
            />
          </RightDrawer>
        )}

        {initClient && (
          <RightDrawer
            title={`${initClient.profile.nickname || initClient.first_name} ${
              initClient.last_name
            }'s notes`}
            closeModal={() => this.setState({ showComment: false })}
            visible={showComment}
          >
            <Notes person={initClient} noFilter />
          </RightDrawer>
        )}
      </div>
    )
  }
}

export default ClientList
