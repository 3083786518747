export const basic = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [
      { required: true, message: 'This field is required' },
      { max: 50, message: 'The max lengtn is 50' }
    ]
  },
  {
    type: 'input',
    label: 'Unit',
    dbField: 'unit'
  },
  {
    type: 'input',
    label: 'Summary',
    dbField: 'summary'
  },
  {
    type: 'richText',
    label: 'Introduction',
    dbField: 'introduction'
  }
]

export const metricBasic = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [
      { required: true, message: 'This field is required' },
      { max: 50, message: 'The max length is 50' }
    ]
  },
  {
    type: 'input',
    label: 'Unit',
    dbField: 'unit'
  },
  // {
  //   type: 'input',
  //   label: 'Summary',
  //   dbField: 'summary'
  // },
  {
    type: 'richText',
    label: 'Introduction',
    dbField: 'introduction'
  }
]

export const selectBasic = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [
      { required: true, message: 'This field is required' },
      { max: 50, message: 'The max lengtn is 50' }
    ]
  },
  // {
  //   type: 'input',
  //   label: 'Summary',
  //   dbField: 'summary'
  // },
  {
    type: 'richText',
    label: 'Introduction',
    dbField: 'introduction'
  }
]

export const range = gender => [
  {
    colSpan: 6,
    type: 'selectColor',
    label: 'Color(male)',
    dbField: 'male_color',
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },
  {
    colSpan: 6,
    type: 'selectColor',
    label: 'Color(female)',
    dbField: 'female_color',
    style: {
      display: gender === 'male' ? 'none' : 'block'
    }
  },
  {
    colSpan: 18
  },

  {
    label: 'Range Value (male)',
    colSpan: 6,
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },
  {
    label: 'Min',
    type: 'input',
    dbField: 'male_min',
    colSpan: 8,
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },
  {
    label: 'Max',
    type: 'input',
    dbField: 'male_max',
    colSpan: 8,
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },

  {
    label: 'Range Value (female)',
    colSpan: 6,
    style: {
      display: gender === 'male' ? 'none' : 'block'
    }
  },
  {
    label: 'Min',
    type: 'input',
    dbField: 'female_min',
    style: {
      display: gender === 'male' ? 'none' : 'block'
    },
    colSpan: 8
  },
  {
    label: 'Max',
    type: 'input',
    dbField: 'female_max',
    style: {
      display: gender === 'male' ? 'none' : 'block'
    },
    colSpan: 8
  },
  {
    colSpan: 2,
    type: 'text',
    dbField: 'unit'
  }
]

export const selectRange = gender => [
  {
    type: 'input',
    label: 'Choice value(male)',
    dbField: 'male_output',
    rules: [
      // { required: true, message: 'This field is required' },
      { max: 50, message: 'The max lengtn is 50' }
    ],
    colSpan: 12,
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },
  {
    type: 'input',
    label: 'Choice value(female)',
    dbField: 'female_output',
    rules: [
      // { required: true, message: 'This field is required' },
      { max: 50, message: 'The max lengtn is 50' }
    ],
    colSpan: 12,
    style: {
      display: gender === 'male' ? 'none' : 'block'
    }
  },
  {
    colSpan: 6,
    type: 'selectColor',
    label: 'Color(male)',
    dbField: 'male_color',
    style: {
      display: gender === 'female' ? 'none' : 'block'
    }
  },
  {
    colSpan: 6,
    type: 'selectColor',
    label: 'Color(female)',
    dbField: 'female_color',
    style: {
      display: gender === 'male' ? 'none' : 'block'
    }
  },
  {
    colSpan: 6
  }
]
