import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { headerStyle } from './index.sass'
import { RightOutlined } from '@ant-design/icons'
import { Breadcrumb, Menu, Dropdown, Button } from 'antd'
// import { UserIcon } from '../../icons'
import { logOut } from '../../../api/people'
import { ClientLogoutIcon, LogoutIcon } from '../../icons/onboarding'
import { loadUser } from '../../../utils/storage'
import { getRole } from '../../../utils/common'
import { clearStorage } from '../../../utils/storage'
import PolygonAvatar from '../../UI/polygonAvatar'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import { DisplayClient } from './displayClient'

export class PHSHeader extends Component {
  static propTypes = {
    history: PropTypes.object,
    breadcrumb: PropTypes.string,
    isPublicLink: PropTypes.bool,
    person: PropTypes.object,
    hideBreadCrumb: PropTypes.bool,
    hideSubHeader: PropTypes.bool
    // needupdate: PropTypes.bool
  }

  state = {
    user: null
  }

  signOut = () => {
    const { history, logOutAction, summaryEmail } = this.props
    if (
      window.location.pathname.includes('/detail/summary-email/') &&
      summaryEmail.editingTextNumber > 0
    ) {
      window.alert(
        'There have some text editing unfinished! Please check the summary email page.'
      )
    } else {
      clearStorage()
      logOutAction()
      history.push('/sign-in')
    }
  }

  expertMenus = [
    {
      key: '2',
      label: 'Metrics Management',
      path: '/admin'
    },
    {
      key: '3',
      label: 'Goal Category',
      path: '/goals'
    },
    {
      key: '6',
      label: 'Facilities & Experts',
      path: '/common-tools/facilities'
    },
    {
      key: '7',
      label: 'Resources',
      path: '/common-tools/recommended'
    },
    {
      key: '11',
      label: 'Challenge',
      path: '/common-tools/challenge'
    },
    {
      key: '8',
      label: 'Summary Email Report',
      path: '/common-tools/email-report'
    },
    {
      key: '9',
      label: 'Summary Email Template',
      path: '/common-tools/email-template'
    },
    {
      key: '10',
      label: 'Data Aggregation',
      path: '/common-tools/data-aggregation'
    },
    {
      key: '12',
      label: 'Timeline Report',
      path: '/common-tools/timeline-report'
    },
    {
      key: '13',
      label: 'HSD Weighting',
      path: '/common-tools/hsd-weighting'
    },
    {
      key: '14',
      label: 'Event Registration',
      path: '/common-tools/event-registration'
    },
    {
      key: '15',
      label: 'Partnership Registration',
      path: '/common-tools/partnership-registration'
    },
    {
      key: '16',
      path: '/common-tools/appointments',
      label: 'Appointments'
    },
    {
      key: '17',
      path: '/common-tools/download-csvs',
      label: 'Download CSVs'
    },
    {
      key: '4',
      label: 'Build Versions',
      path: '/ios-version',
      hide: process.env.REACT_APP_WORKFLOW === 'production'
    },
    {
      key: '1',
      label: 'Sign Out',
      handle: this.signOut
    }
  ]

  onMenuClick = (menu) => {
    const { history } = this.props
    const menuItem = this.expertMenus.find((item) => item.key === menu.key)
    if (menuItem) {
      const { path, handle } = menuItem
      if (path) {
        history.push(path)
      } else if (handle) {
        handle()
      }
    }
  }

  getMenu = () => {
    const user = loadUser()
    const role = getRole(user.role)
    if (role === 'User' || role === 'Service_Provider') {
      return (
        <Menu
          className="header-opr"
          onClick={this.signOut}
          items={[{ label: 'Sign Out', key: '1', icon: <LogoutIcon /> }]}
        />
      )
    } else {
      return (
        <Menu
          className="header-opr"
          items={this.expertMenus
            .filter((item) => !item.hide)
            .map(({ key, label }) => ({ key, label }))}
          onClick={this.onMenuClick}
        />
      )
    }
  }

  renderClientInfo = (user, selectedClient) => {
    const role = getRole(user.role)
    const { history } = this.props
    if (role === 'User' || role === 'Service_Provider') {
      return user ? <DisplayClient client={{ person: user }} /> : <></>
    } else {
      return selectedClient ? (
        <DisplayClient
          client={selectedClient}
          onClick={() => {
            history.push(`/client/${selectedClient.person.id}/detail`)
          }}
        />
      ) : (
        <></>
      )
    }
  }

  renderTopRightAction = (user) => {
    const role = getRole(user.role)
    if (role === 'User' || role === 'Service_Provider') {
      return (
        <Button
          style={{
            marginTop: 15,
            marginBottom: 15
          }}
          onClick={this.signOut}
          icon={<ClientLogoutIcon />}
          type="text"
        />
      )
    } else {
      return (
        <Dropdown
          overlay={this.getMenu()}
          placement="bottomRight"
          trigger={['click', 'tap']}
          overlayStyle={{
            zIndex: 9999
          }}
        >
          <span>
            <PolygonAvatar
              image={user && user.avatar && user.avatar.oss_url}
              width={46}
              height={50}
              innerId={`header-inner-${user.id}`}
              hexId={`header-hex-${user.id}`}
            />
          </span>
        </Dropdown>
      )
    }
  }

  render() {
    const {
      breadcrumb,
      isPublicLink,
      hideSubHeader,
      person,
      selectedClient
    } = this.props
    const user = isPublicLink ? person : loadUser()
    const breadcrumbList = breadcrumb && JSON.parse(breadcrumb).list
    const role = getRole(user.role)
    return (
      <div
        className={`phs-header ${headerStyle} ${hideSubHeader ? 'signle' : ''}`}
      >
        <div className="phs-header-content">
          <div className="main-content">
            <a className="phs-text" href="/">
              <img src={phsLogo} alt="logo" />
            </a>
            {this.renderClientInfo(user, selectedClient)}
            <div className="login-user">
              {user && role !== 'User' && role !== 'Service_Provider' && (
                <span className="role-name">
                  {(user.profile && user.profile.nickname) || user.first_name}
                </span>
              )}
              {user &&
                (isPublicLink ? (
                  <PolygonAvatar
                    image={user && user.avatar && user.avatar.oss_url}
                    width={46}
                    innerId={`header-inner-${user.id}`}
                    hexId={`header-hex-${user.id}`}
                  />
                ) : (
                  this.renderTopRightAction(user)
                ))}
            </div>
          </div>
          {!hideSubHeader && (
            <div className="sub-header">
              <div className="ant-tabs ant-tabs-top ant-tabs-line">
                <div className="ant-tabs-content ant-tabs-content-animated ant-tabs-top-content">
                  <Breadcrumb separator={<RightOutlined />}>
                    {breadcrumbList &&
                      breadcrumbList.length > 0 &&
                      breadcrumbList.map((item, index) => (
                        <Breadcrumb.Item key={index}>
                          {item.href ? (
                            <Link to={item.href}>{item.title}</Link>
                          ) : (
                            item.title
                          )}
                        </Breadcrumb.Item>
                      ))}
                  </Breadcrumb>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  ({ summaryEmail }) => ({ summaryEmail }),
  (dispatch) => ({
    logOutAction: () =>
      logOut() && clearStorage() && dispatch({ type: 'USER_LOGOUT' })
  })
)(PHSHeader)
