import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import PHSHeader from '../../components/UI/header'
import NoMatch from '../noMatch'
import SlideMenu from '../../components/UI/clientSlideMenu/ficilities-slide'
import FacilitiesManagement from './facilities'
import Recommended from './recommended/recommended'
import EmailReport from './emailReport.js'
import EmailTemplate from './emailTemplate.js'
import DataAggregation from './dataAggregation'
import Challenge from './challenge'
import Appointments from './appointments'
import TimelineReport from './timelineReport'
import { commonToolsStyle } from './index.sass'
import HSDWeighting from './hsdWeighting'
import EventRegistration from './eventRegistration'
import PartnershipRegistration from './partnershipRegistration'
import DownloadCSVs from '../../components/downloadCSVs/index.js'

class CommonTools extends Component {
  static propTypes = {}

  render() {
    const { history, match, location } = this.props
    return (
      <>
        <PHSHeader
          hideSubHeader={true}
          hideBreadCrumb={true}
          history={history}
        />
        <div className={commonToolsStyle}>
          <div className="left">
            <SlideMenu location={location} history={history} />
          </div>
          <div className="right">
            <Switch>
              <Route
                path={`${match.url}/facilities`}
                component={FacilitiesManagement}
              />
              <Route
                path={`${match.url}/recommended`}
                component={Recommended}
              />
              <Route
                path={`${match.url}/challenge/:id`}
                component={Challenge}
              />

              <Route path={`${match.url}/challenge`} component={Challenge} />

              <Route
                path={`${match.url}/appointments`}
                component={Appointments}
              />

              <Route
                path={`${match.url}/email-report`}
                component={EmailReport}
              />
              <Route
                path={`${match.url}/email-template`}
                component={EmailTemplate}
              />
              <Route
                path={`${match.url}/data-aggregation`}
                component={DataAggregation}
              />
              <Route
                path={`${match.url}/timeline-report`}
                component={TimelineReport}
              />
              <Route
                path={`${match.url}/hsd-weighting`}
                component={HSDWeighting}
              />
              <Route
                path={`${match.url}/event-registration`}
                component={EventRegistration}
              />
              <Route
                path={`${match.url}/partnership-registration`}
                component={PartnershipRegistration}
              />
              <Route
                path={`${match.url}/download-csvs`}
                component={DownloadCSVs}
              />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </div>
      </>
    )
  }
}

export default CommonTools
