import _ from 'lodash'
import { Button, message } from 'antd'
import React, { useState, useEffect, useRef } from 'react'
import { uploadToGetStaticUrl } from '../../api/file'
import ReactQuill, { Quill } from 'react-quill'
import { base64toBlob, toBase64 } from '../../utils/common'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { updateMessages, sendMessage } from '../../api/message'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import 'react-quill/dist/quill.snow.css'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image'
]

export default function Editor(props) {
  const { value, id, text, close } = props
  const [readOnly, setRadOnly] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  let editorRef = useRef(null)

  const modules = {
    toolbar: {
      handlers: {
        image: imageHandler
      },
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image']
      ]
    },
    // imageDrop: {
    //   handler: imageDropHandler
    // },
    imageDropAndPaste: {
      handler: imageDropHandler
    }
  }

  let preRange = { index: 0, length: 0 }

  useEffect(() => {
    const { quillEditor } = getEditor()
    if (quillEditor) {
      if (id) {
        let initialValue = value
        if (!value && text) {
          if (typeof text === 'string') {
            initialValue = `<p>${text}</p>`
          } else if (text.type === 'image') {
            initialValue = `<img src=${text.url} alt="image"/>`
          }
        }
        quillEditor.clipboard.dangerouslyPasteHTML(initialValue)
        quillEditor.setSelection({
          index: quillEditor.getContents().length(),
          length: 0
        })
      }
    }
  }, [editorRef.current])

  return (
    <>
      <ReactQuill
        ref={editorRef}
        theme="snow"
        modules={modules}
        formats={formats}
        placeholder="Leave a message"
        onKeyDown={onKeyDown}
        readOnly={readOnly}
        defaultValue={value}
      />
      <div className="submit-button">
        <div />
        <div className="btns">
          {id && (
            <Button size="large" disabled={submitting} onClick={close}>
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            size="large"
            onClick={submit}
            disabled={submitting || props.loading}
            loading={submitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  )

  function getEditor() {
    const getEditor = _.get(editorRef, 'current.getEditor')
    const quillEditor = getEditor && getEditor()
    let delta, range
    if (quillEditor) {
      delta = quillEditor.getContents()
      range = quillEditor.getSelection()
    }
    return { quillEditor, delta, range }
  }

  async function submit() {
    const { clientId, id, onSubmitCallback } = props
    const { quillEditor, delta } = getEditor()
    // await test()
    const _delta = delta //await handleSubmitImages(_.cloneDeep(delta))
    const converter = new QuillDeltaToHtmlConverter(delta.ops, {})
    const richText = converter.convert()
    const text = quillEditor.getText()
    if (!text.trim() && !_.includes(richText, '<img')) {
      return
    }
    setSubmitting(true)
    setRadOnly(true)
    try {
      if (id) {
        // update message
        await updateMessages(
          { rich_text: richText, rich_text_ops: _delta.ops },
          clientId,
          id
        )
        onSubmitCallback(richText)
        close()
      } else {
        quillEditor.setContents([{ insert: '\n' }])
        const { messages } = await sendMessage(
          { rich_text: richText, rich_text_ops: _delta.ops },
          clientId
        )
        onSubmitCallback(messages.reverse())
      }
      setSubmitting(false)
      setRadOnly(false)
    } catch (err) {
      message.error(err.message)
      setSubmitting(false)
      setRadOnly(false)
    }
  }

  function onKeyDown(event) {
    const { range } = getEditor()
    preRange = range
  }

  async function imageDropHandler(dataUrl, type, imageData) {
    const { quillEditor } = getEditor()
    if (quillEditor) {
      try {
        let [contentType, realData] = dataUrl.split(';')
        contentType = contentType.replace('data:', '')
        realData = realData.split(',')[1]
        const file = base64toBlob(realData, contentType)
        let url
        if (file.type === 'image/svg+xml') {
          url = dataUrl
        } else {
          url = await uploadImage(props.clientId, file)
        }

        quillEditor.insertEmbed(preRange.index, 'image', url)
        quillEditor.setSelection(preRange)
      } catch (err) {
        console.error(err)
        message.error('Upload image failed, please try again.')
      }
    }
  }

  function imageHandler() {
    const { quillEditor, range: editorRange } = getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      try {
        const file = input.files[0]
        let url
        if (file.type === 'image/svg+xml') {
          url = await toBase64(file)
        } else {
          url = await uploadImage(props.clientId, file)
        }
        const range = editorRange || preRange
        quillEditor.insertEmbed(range.index, 'image', url)
        quillEditor.setSelection(range)
      } catch (err) {
        message.error('Upload image failed, please try again.')
      }
    }
  }

  async function uploadImage(clientId, file) {
    const { url } = await uploadToGetStaticUrl({
      personId: clientId,
      file
    })
    return url
  }
}
