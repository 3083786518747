import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Progress } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  padding: 20px 25px;
  letter-spacing: 0.3px;
  .title {
    color: #4c6072;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 16px;
  }
  .ant-progress-text {
    display: none;
  }
  .percent-text {
    margin-top: -6px;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    font-weight: bold;
    position: absolute;
  }
  .ant-progress-show-info {
    .ant-progress-outer {
      margin: 0;
      padding: 0;
      width: calc(100% - 48px - 36px);
      margin-left: 84px;
      .ant-progress-inner {
        height: 18px;
        background: #eaedf3;
        border-radius: 4px;
        .ant-progress-bg {
          background: #7bad2d;
          height: 18px !important;
          border-radius: 4px !important;
        }
      }
    }
  }
`

class AnswerProgress extends Component {
  static propTypes = {
    survey: PropTypes.object,
    answers: PropTypes.object
  }

  render() {
    const { survey, answers, percent } = this.props

    let Percent
    if (percent) {
      Percent = percent
    } else {
      Percent = questionCompletion(survey, answers)
    }

    return (
      <Container>
        <div className="title">Answer Progress</div>
        <div className="percent-text">{Percent} %</div>
        <Progress percent={Number(Percent)} />
      </Container>
    )
  }
}

export default AnswerProgress

export function questionCompletion(survey, answers) {
  let requestTotalCount = 0
  let hasAnswerCount = 0
  for (const section of survey.sections) {
    let answerObj = answers && answers[section.id]
    if (answerObj instanceof Array) {
      answerObj = answerObj[0].detail && answerObj[0].detail[section.id]
    }
    const questions = section.questions.filter((item) => Number(item.id))
    for (const question of questions) {
      if (question.required) {
        const answer = answerObj && answerObj[question.id]
        requestTotalCount++
        if (answer || answer === false) hasAnswerCount++
      }
    }
  }
  // return 0 ~ 100
  return ((hasAnswerCount / requestTotalCount) * 100).toFixed()
}
