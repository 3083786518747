import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import { BackTop } from 'antd'
import mapDispatchToProps from '../dispatch'
// import Communication from '../../components/business/communication'
// import ClientDashboard from './dashboard'
import BloodTest from './bloodTest'
import BiomarkerDetail from './biomarkerDetail'
import CreportList from './creport'
import MetricDetail from './metricDetail'
import GoalsPage from './goals'
import QuestionnaireV1 from './questionnaire'
import QuestionnaireV2, { QuestionnaireGroup } from './questionnaireV2'
import QuestionScore from './questionScore'
import Dataset from './dataset'
import ProfileOverview from './profile'
import Setting from './setting'
import WeeklySummary from './weeklySummary'
import SummaryEmailPreview from './previewEmail'
import BackupEmailLayout from './backupEmailLayout'
import SummaryEmail from './summaryEmail'
import HealthScore from './healthScore'
import NutritionScore from './nutritionScore'
import NutritionScoreV2 from './nutritionScoreV2'
import AssessmentReview from './assessmentReview'
import MessagePage from './message'
import Program from './program'
import ProgramDetail from './programDetail'
import SupportTeamView from './supportTeam'
import FilePage from './filePage'
import Trends from './trends'
import NotesPage from './notes'
import ClientSlideMenu from '../../../components/UI/clientSlideMenu/menu'
import ScrollPositionManager from '../../../components/UI/scrollManager'
import { clientStyle, linkStyle } from '../index.sass'
// import { getBiomarkerCategories } from '../../../utils/storage'
import { getUnreadMessage } from '../../../api/message'
import { getClient } from '../../../api/team'
import { getBloodTests, getBloodTestById } from '../../../api/bloodTests'
import { getSlideMenu } from '../../../api/dataset'
import { getCategories } from '../../../api/categories'
import { getBiomarkerById } from '../../../api/biomarkers'
import { getAllReportLinkHash } from '../../../api/bloodTests'
import { setBiomarkerCategoriesItem } from '../../../utils/storage'
import NoMatch from '../../noMatch'
import MobileMenu from './mobileMenu'
import { getRole } from '../../../utils/common'
import ExportData from './exportData'
import TimeLine from '../../../components/client/timeline'
import classNames from 'classnames'
import SiderBarCollapse from '../../../asserts/icon/sidebar-collapse.svg'
import SiderBarExpand from '../../../asserts/icon/sidebar-expand.svg'
import { MicrobiomeAnalysis } from '../../../pages'

export class ClientDetail extends Component {
  static propTypes = {}

  state = {
    initData: '1',
    show: false,
    navClose: true
  }

  getPerson = async () => {
    const { client, match, setClient } = this.props
    const { id } = match.params
    if (client.person && client.person.id === id) return client.person
    const result = await getClient(id)
    const person = result.clients[0]
    setClient(person)
    return person
  }

  /**** ready to remove blow *** */
  getCategoriesAndPersonBiomarkers = async (biomarkerId) => {
    const blood_tests = await this.getPersonBioMarkers()
    const bioMarkersCategories = await this.getBiomarkerCategories()
    let personalBioMarkers = blood_tests[0] && blood_tests[0].snapshot
    if (biomarkerId) {
      const biomarker = await getBiomarkerById(Number(biomarkerId))
      biomarker.personal_data = personalBioMarkers.filter(
        (item) => item.bio_marker_id === biomarker.id
      )[0]
      return { biomarker, bioMarkersCategories }
    }
    return { bioMarkersCategories, blood_tests }
  }

  getDetailDatasetAndBiomarker = async (biomarkerId, datasetId) => {
    const { match } = this.props
    const id = match.params.id
    const biomarker = await getBiomarkerById(Number(biomarkerId))
    const result = await getBloodTestById(datasetId, id)
    const snapshot = result.dataset.snapshot
    biomarker.personal_data = snapshot.filter(
      (item) => item.bio_marker_id === biomarker.id
    )[0]
    return {
      biomarker,
      status: result.dataset.status,
      testDate: result.dataset.test_date
    }
  }

  // get it
  getPersonBioMarkers = async () => {
    const { match } = this.props
    const id = match.params.id
    let result, blood_tests
    result = await getBloodTests(id)
    blood_tests = result.datasets
    return blood_tests
  }

  // get it
  getBiomarkerCategories = async () => {
    const {
      setClientSelecCategory,
      client,
      setClientBiomarkerCategories
    } = this.props
    let bioMarkersCategories
    if (client.categories.length > 0) {
      bioMarkersCategories = client.categories
    } else {
      const result = await getCategories()
      bioMarkersCategories = result
      setClientBiomarkerCategories(bioMarkersCategories)
    }
    const categoriesMenu = bioMarkersCategories.map(({ id, name }) => ({
      id,
      name
    }))
    setBiomarkerCategoriesItem(categoriesMenu)

    if (!client.selectCategory && bioMarkersCategories.length > 0) {
      const { id, name } = bioMarkersCategories[0]
      setClientSelecCategory({ id, name })
    }

    return bioMarkersCategories
  }
  /****stop remove *** */

  getShareLink = async (personId, path) => {
    const result = await getAllReportLinkHash(personId)
    window.open(`/ios/share-link/${path}/${result.permanent_link.hash}`)
  }

  getHrefs = () => {
    const {
      match: { url }
    } = this.props
    return {
      micro_goals: `${url}/goals/active`,
      trends: `${url}/trends`,
      nutrition: `${url}/nutrition`,
      questionnaire: `${url}/questionnaire`,
      profile: `${url}/profile`,
      timeline: `${url}/timeline`,
      setting: `${url}/setting`,
      email: `${url}/summary-email`,
      health_score: `${url}/health-score`,
      export_data: `${url}/export-data`,
      nutrition_score: `${url}/nutrition-score`,
      nutrition_trends: `${url}/nutrition-trends`,
      microbiome_analysis: `${url}/microbiome-analysis`,
      message: `${url}/message`,
      notes: `${url}/notes`,
      program: `${url}/program`,
      ass_report: `${url}/ass-report`,
      support_team: `${url}/support-team`,
      file: `${url}/file`,
      // datasets list
      vitals: `${url}/vitals`,
      bodyCom: `${url}/body-comp`,
      boneDensity: `${url}/bone-density`,
      movementQuality: `${url}/movement-quality`,
      muscleFitness: `${url}/muscle-fitness`,
      aerobicFitness: `${url}/aerobic-fitness`,
      cognitive_health: `${url}/cognitive-health`,
      blood_test: `${url}/blood-test`
    }
  }

  getMenu = async () => {
    const { setSubMenu } = this.props
    const result = await getSlideMenu()
    setSubMenu('groups', result.groups)
  }

  getUnReadCount = async () => {
    const {
      match: {
        params: { id }
      },
      setClientUnreadCount
    } = this.props

    const { count } = await getUnreadMessage(id)
    setClientUnreadCount(count)
  }

  componentDidMount() {
    const { slideMenu, setClientLeftNavigateBarHidden } = this.props
    this.getUnReadCount()
    if (!slideMenu.groups) {
      this.getMenu()
    }
    setClientLeftNavigateBarHidden(true)
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props
    if (nextProps.location.pathname !== prevProps.location.pathname) {
      this.setState({ show: false })
      this.getUnReadCount()
    }
  }

  render() {
    const {
      match,
      client,
      setClientBreadcrumb,
      setClientPageTitle,
      setClientBiomarkerCategories,
      setClientLeftNavigateBarHidden,
      history,
      location
    } = this.props
    const { show } = this.state

    const childProps = {
      getPerson: this.getPerson,
      setClientBreadcrumb,
      setClientPageTitle,
      client,
      getShareLink: this.getShareLink
    }
    const noDetailPadding =
      location.pathname.includes('/ass-report/draft') ||
      location.pathname.includes('/ass-report/preview')
    const isDetail =
      (!location.pathname.match('goals') &&
        !location.pathname.match('email') &&
        location.pathname.split('/').length === 7) ||
      noDetailPadding
    const hrefs = this.getHrefs()
    const loginRole = getRole()
    const isSPAccount = loginRole === 'Service_Provider'
    return (
      <div className={(clientStyle, linkStyle)}>
        <div className="detail-content">
          {!isDetail && (
            <div className={classNames('left', { close: this.state.navClose })}>
              <ClientSlideMenu
                location={location}
                hrefs={hrefs}
                history={history}
                loginRole={loginRole}
                // bloodTestCategories={client.categories}
              />
              <div
                className={classNames('swift-left-btn', {
                  close: this.state.navClose
                })}
                onClick={() => {
                  setClientLeftNavigateBarHidden(!this.state.navClose)
                  this.setState({ navClose: !this.state.navClose })
                }}
              >
                <img
                  src={this.state.navClose ? SiderBarExpand : SiderBarCollapse}
                  alt="action"
                />
              </div>
            </div>
          )}
          <div
            className={`right ${isDetail ? 'detail-right' : ''} ${
              noDetailPadding ? 'no-padding' : ''
            }`}
          >
            <div className="mobile-menu">
              {show ? (
                <CloseOutlined onClick={() => this.setState({ show: false })} />
              ) : (
                <MenuOutlined onClick={() => this.setState({ show: true })} />
              )}
            </div>
            {client.pageTitle && (
              <h1 className="page-title">{client.pageTitle}</h1>
            )}
            <div>
              {show ? (
                <MobileMenu setPropsState={(state) => this.setState(state)}>
                  <div
                    className="menu-mask"
                    onClick={() => this.setState({ show: false })}
                  >
                    <div
                      className="foo-menu"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ClientSlideMenu
                        location={location}
                        hrefs={hrefs}
                        history={history}
                        bloodTestCategories={client.categories}
                      />
                    </div>
                  </div>
                </MobileMenu>
              ) : null}
              <ScrollPositionManager scrollKey="dataset">
                {({
                  saveScrollPosition,
                  restoreScrollPosition,
                  clearScrollPosition
                }) => (
                  <Switch>
                    <Route
                      exact
                      path={`${match.url}`}
                      render={(props) => (
                        <ProfileOverview
                          {...props}
                          getPerson={this.getPerson}
                          {...this.props}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/profile`}
                      render={(props) => (
                        <ProfileOverview
                          {...props}
                          getPerson={this.getPerson}
                          {...this.props}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/timeline`}
                      render={(props) => (
                        <TimeLine
                          {...props}
                          getPerson={this.getPerson}
                          {...this.props}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/notes`}
                      render={(props) => (
                        <NotesPage
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />

                    <Route
                      path={`${match.url}/file`}
                      render={(props) => (
                        <FilePage
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />

                    <Route
                      path={`${match.url}/message`}
                      render={(props) => (
                        <MessagePage
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/program/:id`}
                      render={(props) => (
                        <ProgramDetail
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />

                    <Route
                      path={`${match.url}/program`}
                      render={(props) => (
                        <Program
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />

                    <Route
                      path={`${match.url}/support-team`}
                      render={(props) => (
                        <SupportTeamView
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/summary-email/backup/:id`}
                      render={(props) => (
                        <BackupEmailLayout
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/summary-email/preview/:id`}
                      render={(props) => (
                        <SummaryEmailPreview
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/summary-email/:id`}
                      render={(props) => (
                        <WeeklySummary
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/summary-email`}
                      render={(props) => (
                        <SummaryEmail
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/health-score`}
                      render={(props) => (
                        <HealthScore
                          {...props}
                          getPerson={this.getPerson}
                          {...{
                            setClientBreadcrumb,
                            setClientPageTitle
                          }}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/export-data`}
                      render={(props) => (
                        <ExportData
                          setClientPageTitle={setClientPageTitle}
                          getPerson={this.getPerson}
                        />
                      )}
                    />
                    <Route
                      path={`${match.url}/ass-report`}
                      render={(props) => (
                        <AssessmentReview
                          {...props}
                          getPerson={this.getPerson}
                        />
                      )}
                    />

                    {!isSPAccount && (
                      <Switch>
                        <Route
                          path={`${match.url}/questionnaire/score`}
                          render={(props) => (
                            <QuestionScore
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/questionnaire/group`}
                          render={(props) => (
                            <QuestionnaireGroup
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/questionnaire`}
                          render={(props) => (
                            <QuestionnaireV2
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/questionnaire-v1`}
                          render={(props) => (
                            <QuestionnaireV1
                              {...props}
                              getPerson={this.getPerson}
                            />
                          )}
                        />
                        <Route
                          path={`${match.url}/goals/:type`}
                          render={(props) => (
                            <GoalsPage
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />
                        <Route
                          path={`${match.url}/setting`}
                          render={(props) => (
                            <Setting
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />
                        <Route
                          path={`${match.url}/trends`}
                          render={(props) => (
                            <Trends
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/nutrition-score`}
                          render={(props) => (
                            <NutritionScore
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/nutrition-trends`}
                          render={(props) => (
                            <NutritionScoreV2
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/microbiome-analysis`}
                          render={(props) => (
                            <MicrobiomeAnalysis
                              {...props}
                              {...childProps}
                              getPerson={this.getPerson}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/biomarker/:id/:datasetId`}
                          render={(props) => (
                            <BiomarkerDetail
                              {...props}
                              {...childProps}
                              getMetaData={this.getDetailDatasetAndBiomarker}
                            />
                          )}
                        />
                        <Route
                          path={`${match.url}/biomarker/:id`}
                          render={(props) => (
                            <BiomarkerDetail
                              {...props}
                              {...childProps}
                              getMetaData={
                                this.getCategoriesAndPersonBiomarkers
                              }
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/:type/:id/:datasetId`}
                          render={(props) => (
                            <MetricDetail {...props} {...childProps} />
                          )}
                        />
                        <Route
                          path={`${match.url}/creport`}
                          render={(props) => (
                            <CreportList {...props} {...childProps} />
                          )}
                        />
                        <Route
                          path={`${match.url}/blood-test/:category`}
                          render={(props) => (
                            <BloodTest
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                client,
                                setClientBreadcrumb,
                                setClientPageTitle,
                                setClientBiomarkerCategories,
                                getShareLink: this.getShareLink,
                                saveScrollPosition,
                                restoreScrollPosition,
                                clearScrollPosition
                              }}
                            />
                          )}
                        />
                        <Route
                          path={`${match.url}/blood-test`}
                          render={(props) => (
                            <BloodTest
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                client,
                                setClientBreadcrumb,
                                setClientPageTitle,
                                setClientBiomarkerCategories,
                                getShareLink: this.getShareLink,
                                saveScrollPosition,
                                restoreScrollPosition,
                                clearScrollPosition
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/:type/:category`}
                          render={(props) => (
                            <Dataset
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle,
                                getShareLink: this.getShareLink,
                                saveScrollPosition,
                                restoreScrollPosition,
                                clearScrollPosition
                              }}
                            />
                          )}
                        />

                        <Route
                          path={`${match.url}/:type`}
                          render={(props) => (
                            <Dataset
                              {...props}
                              getPerson={this.getPerson}
                              {...{
                                setClientBreadcrumb,
                                setClientPageTitle,
                                getShareLink: this.getShareLink,
                                saveScrollPosition,
                                restoreScrollPosition,
                                clearScrollPosition
                              }}
                            />
                          )}
                        />
                      </Switch>
                    )}

                    <Route component={NoMatch} />
                  </Switch>
                )}
              </ScrollPositionManager>

              <BackTop />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ client, slideMenu }) => ({ client, slideMenu }),
  mapDispatchToProps
)(ClientDetail)
