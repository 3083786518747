import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { RightOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import Calendar from './calendar'
// import RateInfo from './rateInfo'
import TextList from './textList'
import ProgressBar from './bar'
import ProgressLine from './line'
import ProgressMultiBar from './multi-bar'
import ProgressMultiLine from './multi-line'
import AvgEtc from './avg'
import { ViewIcon } from '../../icons'
import { dateFormat } from '../../../utils/common'

import healthkitIcon from '../../../asserts/icon/goals/goals_source_hk_icon.png'
import garminIcon from '../../../asserts/icon/goals/goals_source_garmin_icon.png'
import ouraIcon from '../../../asserts/icon/goals/goals_source_oura_icon.png'
import PolarLogo from '../../../asserts/icon/goals/goals_source_polar_icon.png'
import WithingsLogo from '../../../asserts/icon/goals/goals_source_withings_icon.png'
import TrashIcon from '../../../asserts/icon/goals/trash-b.svg'
import TimeBack from '../../../asserts/icon/goals/time-back.svg'

const confirm = Modal.confirm

class ProgressCard extends Component {
  static propTypes = {
    type: PropTypes.string,
    goal: PropTypes.object,
    loginRole: PropTypes.string,
    openModal: PropTypes.func,
    archivedGoal: PropTypes.func,
    deleteGoal: PropTypes.func
  }

  state = {}

  deleteConfirm = (goal) => {
    const { deleteGoal } = this.props
    confirm({
      title: 'Do you want to delete this goal?',
      onOk() {
        deleteGoal(goal, 'archived')
      }
    })
  }

  getEntries() {
    const { goal } = this.props
    const { achieving } = goal

    let entries = []
    for (const achiev of achieving) {
      entries = entries.concat(achiev.entries)
    }
    return entries
  }

  openDetail = () => {
    const { openModal, setGlobalState, goal } = this.props
    const { micro_goal: microGoal } = goal
    if (!microGoal.category || microGoal.has_parent) {
      return
    }
    openModal({
      visible: 3
    })

    if (
      microGoal.tracking_activity_metric &&
      typeof microGoal.tracking_activity_metric === 'string'
    ) {
      microGoal.tracking_activity_metric = microGoal.tracking_activity_metric.split(
        ','
      )
    }
    setGlobalState({
      editGoal: microGoal,
      editGoalType: microGoal.input_type === 'binary' ? 'question' : 'tracking'
    })
  }

  renderNumberBody(achievingData) {
    const { goal, period, startDate, endDate, loginRole } = this.props
    const {
      micro_goal: { chart_type, auto_range, id, unit, children, fixed_target } // cron_day_month
    } = goal

    const childProps = {
      loginRole,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      period, //: cron_day_month !== '*' ? 'monthly' : 'weekly',
      achievingData,
      auto_range,
      unit,
      children,
      fixed_target
    }
    switch (chart_type) {
      case 'bar':
        return <ProgressBar {...childProps} id={id} />
      case 'line':
        return <ProgressLine {...childProps} />
      case 'multi-bar':
        return <ProgressMultiBar {...childProps} goal={goal} />
      case 'multi-line':
        return <ProgressMultiLine {...childProps} goal={goal} />
      default:
        return 'No Graph'
    }
  }

  renderBody(achievingData) {
    const {
      goal,
      startDate,
      endDate,
      period,
      openModal,
      setGlobalState
    } = this.props
    const {
      micro_goal: { input_type, goal_type, auto_activity } // cron_day_month, source
    } = goal
    // const period = cron_day_month !== '*' ? 'monthly' : 'weekly'
    let start = startDate,
      end = endDate
    if (period === 'monthly' || goal_type === 'heartRateMetrics') {
      const year = moment(startDate).year()
      const month = moment(startDate).month()

      start = moment([year, month, 1])
      end = moment([year, month, startDate.daysInMonth()])
    }
    const showActivity = (date) => {
      openModal({ visible: 3, targetDate: date })
      setGlobalState({
        editGoal: goal.micro_goal,
        editGoalType:
          goal.micro_goal.input_type === 'binary' ? 'question' : 'tracking'
      })
    }
    const childProps = {
      startDate: start,
      endDate: end,
      period,
      showActivity
    }
    switch (input_type) {
      case 'binary':
        return (
          <Calendar
            entries={achievingData && achievingData.entries}
            {...childProps}
            auto_activity={auto_activity}
          />
        )
      case 'text':
        return (
          <TextList
            entries={achievingData && achievingData.entries}
            {...{
              startDate: start,
              endDate: end,
              showActivity: this.openDetail
            }}
          />
        )
      case 'number':
        if (goal_type === 'heartRateMetrics') {
          return (
            <Calendar
              entries={achievingData && achievingData.entries}
              {...{
                startDate: start,
                endDate: end,
                period: 'monthly',
                notState: true,
                showActivity
              }}
            />
          )
        } else {
          return this.renderNumberBody(achievingData)
        }

      default:
        return input_type
    }
  }

  renderLastSync(goal, entries) {
    let { source } = goal
    const len = entries ? entries.length : 0
    if (len > 0) {
      entries.sort(
        (a, b) =>
          new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime()
      )
    }
    const lastEntry = len > 0 && entries[len - 1]
    let lastestTime = lastEntry && lastEntry.updated_at
    if (source === 'any') {
      const lastestDetail =
        lastEntry.activity_details &&
        Object.assign([], lastEntry.activity_details).sort(
          (a, b) => b.start_time - a.start_time
        )[0]
      if (lastestDetail) {
        source = lastestDetail.source || lastestDetail.sleep.source
      }
    }
    let sourceIcon = ''
    if (source) {
      switch (source.toLowerCase()) {
        case 'garmin':
          sourceIcon = <img src={garminIcon} alt="healthkit" />
          break
        case 'polar':
          sourceIcon = <img src={PolarLogo} alt="polar" />
          break
        case 'ouraring':
          sourceIcon = <img src={ouraIcon} alt="ouraring" />
          break
        case 'withings':
          sourceIcon = <img src={WithingsLogo} alt="withings" />
          break
        case 'manual':
          break
        default:
          sourceIcon = <img src={healthkitIcon} alt="healthkit" />
          break
      }
    }

    return lastEntry ? (
      <div className="data-sync">
        {lastEntry && sourceIcon}
        {lastEntry &&
          `Last updated: ${dateFormat(
            lastestTime,
            'referYesterday',
            'includeTime'
          )}`}
      </div>
    ) : (
      ''
    )
  }

  renderChildrenTags(goal) {
    const {
      micro_goal: { micro_goal },
      children
    } = goal
    if (micro_goal === 'Bed Time') return <div />
    if (children.length > 1) {
      const tags = []
      for (const child of children) {
        const {
          micro_goal: { micro_goal: type }
        } = child
        if (type.match('Asleep')) {
          tags.push({
            title: 'Asleep',
            color: '#7BAD2D'
          })
        } else if (type.match('In Bed')) {
          tags.push({
            title: 'Inbed',
            color: '#BAC3CE'
          })
        } else if (type.match('Max')) {
          tags.push({
            title: 'Max',
            color: '#9CA5B8'
          })
        } else if (type.match('Min')) {
          tags.push({
            title: 'Min',
            color: '#7BAD2D'
          })
        } else {
          tags.push({
            title: 'Avg',
            color: '#264382'
          })
        }
      }
      return (
        <div className="chart-tags">
          {tags.map((item, index) => (
            <span key={index}>
              <span style={{ background: item.color }} />
              {item.title}
            </span>
          ))}
        </div>
      )
    }
    return <div />
  }

  render() {
    const { goal, type, loginRole, archivedGoal } = this.props
    const entries = this.getEntries()
    const { micro_goal: microGoal, aggregation } = goal

    const achievingData = { entries: entries || [] }
    return (
      <div className={`card ${type}`} onClick={this.openDetail}>
        {type === 'archived' &&
          (loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
            <div className="archived-opr">
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  archivedGoal(microGoal, false)
                }}
              >
                <img src={TimeBack} alt="colok" />
                Unarchive
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  this.deleteConfirm(microGoal)
                }}
              >
                <img src={TrashIcon} alt="trash" />
                Delete
              </span>
            </div>
          )}

        <div className="title">
          {microGoal.micro_goal}
          {microGoal.has_parent ? (
            this.renderChildrenTags(goal)
          ) : typeof microGoal.id === 'number' ? (
            <RightOutlined />
          ) : (
            ''
          )}
        </div>
        <div className="body">
          {achievingData ? this.renderBody(achievingData) : 'No Data'}
        </div>
        {microGoal.input_type === 'number' &&
          !microGoal.achievable &&
          !microGoal.goal_type &&
          aggregation && (
            <AvgEtc
              aggregation={aggregation}
              unit={microGoal.unit}
              key={`avg-${microGoal.id}`}
            />
          )}
        {/* {microGoal.input_type !== 'text' &&
          microGoal.achievable &&
          loginRole === 'Expert' && (
            <RateInfo achieving={achievingData} type={type} />
          )} */}
        {microGoal.source !== 'manual' &&
          this.renderLastSync(microGoal, entries)}
        {microGoal.is_expert_only && (
          <div className="only-expert">
            <ViewIcon /> Shown only to expert
          </div>
        )}
      </div>
    )
  }
}

export default ProgressCard
