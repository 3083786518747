import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider } from 'antd'
import ProgramDataContainer from './programData'
import { CollapsePlusIcon } from '../../icons'
import { SessionsIcon, MinutesIcon } from '../../icons/program'
import ProgramItemEditor from './itemEditor'
import { Pluralize } from '../../../utils/common'
import { programCompletedInfoBoxStyle } from '../style/index.sass'

class ExerciseProgram extends Component {
  static propTypes = {
    expiredDate: PropTypes.bool,
    program: PropTypes.object,
    weekRange: PropTypes.object,
    person: PropTypes.object,
    position: PropTypes.string,
    summary: PropTypes.object,
    selectExercise: PropTypes.object,
    changeExpert: PropTypes.func
  }

  state = {}

  renderWorkoutDetail() {
    const { summary } = this.props
    const {
      pillar_item_count,
      achievement: { duration }
    } = summary
    const {
      actual: actualSessions,
      target: targetSessions
    } = pillar_item_count || { actual: [], target: [] }
    const {
      actual: actualDurations,
      target: targetDurations
    } = duration.pillar || { actual: [], target: [] }
    const [aerobicTS, resistanceTS, activityTS] = targetSessions || [0, 0, 0]
    const [aerobicAS, resistanceAS, activityAS] = actualSessions || [0, 0, 0]
    const [aerobicTD, resistanceTD, activityTD] = targetDurations || [0, 0, 0]
    const [aerobicAD, resistanceAD, activityAD] = actualDurations || [0, 0, 0]
    const details = [
      {
        type: 'Aerobic',
        dotClass:
          aerobicAS >= aerobicTS &&
          Math.round(aerobicAD / 60) >= Math.round(aerobicTD / 60)
            ? 'dot-green'
            : 'dot-red',
        sessionsT: aerobicTS,
        durationsT: Math.round(aerobicTD / 60),
        sessionsA: aerobicAS,
        durationsA: Math.round(aerobicAD / 60)
      },
      {
        type: 'Resistance',
        dotClass:
          resistanceAS >= resistanceTS &&
          Math.round(resistanceAD / 60) >= Math.round(resistanceTD / 60)
            ? 'dot-green'
            : 'dot-red',
        sessionsT: resistanceTS,
        durationsT: Math.round(resistanceTD / 60),
        sessionsA: resistanceAS,
        durationsA: Math.round(resistanceAD / 60)
      },
      {
        type: 'Activity',
        dotClass:
          activityAS >= activityTS &&
          Math.round(activityAD / 60) >= Math.round(activityTD / 60)
            ? 'dot-green'
            : 'dot-red',
        sessionsT: activityTS,
        durationsT: Math.round(activityTD / 60),
        sessionsA: activityAS,
        durationsA: Math.round(activityAD / 60)
      }
    ]

    const allGreen =
      details[0].dotClass === 'dot-green' &&
      details[1].dotClass === 'dot-green' &&
      details[2].dotClass === 'dot-green'

    return (
      <>
        <div className="dot">
          <div className={allGreen ? 'dot-green' : 'dot-red'}>
            <strong>Overall</strong>
          </div>
          <div>{targetSessions.join('-')}</div>
          <div>{actualSessions.join('-')}</div>
        </div>
        {details.map((detail, index) => (
          <div className="dot" key={index}>
            <div className={detail.dotClass}>
              <strong>{detail.type}</strong>
            </div>
            <div>
              {detail.sessionsT}{' '}
              <span className="text-unit">
                {Pluralize('session', detail.sessionsT || 1)}
              </span>{' '}
              /{detail.durationsT}{' '}
              <span className="text-unit">
                {Pluralize('min', detail.durationsT || 1)}
              </span>
            </div>
            <div>
              {detail.sessionsA}{' '}
              <span className="text-unit">
                {Pluralize('session', detail.sessionsA || 1)}
              </span>{' '}
              /{detail.durationsA}{' '}
              <span className="text-unit">
                {Pluralize('min', detail.durationsA || 1)}
              </span>
            </div>
          </div>
        ))}
      </>
    )
  }

  renderCompletedInfo() {
    const { summary, loginRole, program } = this.props
    const {
      achievement: { duration, session }
      // pillar_item_count
    } = summary
    // const { actual, target } = pillar_item_count || { actual: [], target: [] }
    const iconStyle = {
      fontSize: '28px',
      verticalAlign: 'middle',
      marginRight: '16px',
      color: 'white'
    }
    const nonActualDuration = Math.round(duration.non_actual_duration / 60)
    if (loginRole === 'User') {
      return (
        <div className={programCompletedInfoBoxStyle}>
          <span>
            <SessionsIcon style={iconStyle} />
            <strong>
              {session.completed} of {session.target}
            </strong>
            Sessions completed
          </span>
          <Divider type="vertical" />
          <span>
            <MinutesIcon style={iconStyle} />
            <strong>
              {Math.round(duration.completed / 60)} of{' '}
              {Math.round(duration.target / 60)}
            </strong>
            Minutes completed
          </span>
        </div>
      )
    } else {
      return (
        <div
          className={programCompletedInfoBoxStyle}
          style={{ paddingRight: 30, paddingTop: 20, paddingBottom: 20 }}
        >
          <div className="align-bottom">
            <div>Program</div>
            <div>Actual</div>
          </div>

          {this.renderWorkoutDetail()}
          <div>
            <div>
              <strong>Non-program</strong>
            </div>
            <div className="text-unit">--</div>
            <div>
              {program.status === 1 ? (
                <>
                  {session.non_actual_sessions}{' '}
                  <span className="text-unit">
                    {Pluralize('session', session.non_actual_sessions || 1)}
                  </span>{' '}
                  /{nonActualDuration}
                  <span className="text-unit">
                    {Pluralize('min', nonActualDuration || 1)}
                  </span>
                </>
              ) : (
                <div className="text-unit">--</div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    const {
      expiredDate,
      program,
      weekRange,
      person,
      position,
      summary,
      loginRole,
      selectExercise,
      experts,
      changeExpert,
      releations,
      itemDurationMapping,
      intensityRange,
      workoutTypes
    } = this.props
    const { showExerciseEditor } = this.state
    return (
      <>
        <div className="section-top">
          <div className="title exercise">Exercise Action Items</div>
          <div className="right-opr">
            {/* {summary.achievement &&
              loginRole === 'User' &&
              this.renderCompletedInfo()} */}
            {position !== 'clientView' && (
              <Button
                type="primary"
                // disabled={expiredDate}
                onClick={() => this.setState({ showExerciseEditor: true })}
              >
                <CollapsePlusIcon />
                ACTION ITEM
              </Button>
            )}
          </div>
        </div>
        {summary.achievement &&
          (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
          program.status === 1 &&
          this.renderCompletedInfo()}
        <ProgramDataContainer
          {...{
            summary,
            program,
            weekRange,
            experts,
            changeExpert,
            releations,
            itemDurationMapping,
            intensityRange,
            workoutTypes
          }}
          position={position || 'dashboard'}
          person={person}
          editItemCallback={this.props.EditCallback}
          addAttachSync={this.props.addAttachSync}
          removeAttachSync={this.props.removeAttachSync}
          modalContainerStyle={{ left: -275 }}
          expiredDate={expiredDate}
          editAttachTitleSync={this.props.editAttachTitleSync}
        />
        {/* when add a new program item will use below editor */}
        {showExerciseEditor && (
          <ProgramItemEditor
            workoutTypes={workoutTypes}
            cancel={() => this.setState({ showExerciseEditor: false })}
            exercise={selectExercise}
            itemDurationMapping={itemDurationMapping}
            position={position || 'dashboard'}
            programsId={program.id}
            personId={person.id}
            confirmCallback={() => {
              this.setState({ showExerciseEditor: false })
              this.props.EditCallback()
            }}
            addAttachSync={this.props.addAttachSync}
            removeAttachSync={this.props.removeAttachSync}
            editAttachTitleSync={this.props.editAttachTitleSync}
            // summary.program_items
          />
        )}
      </>
    )
  }
}

export default ExerciseProgram
