import React, { useState, useEffect } from 'react'
import { TimePicker, DatePicker } from 'antd'
import moment from 'moment'
import _ from 'lodash'

export default function TimeOrDatePicker(props) {
  const {
    question: { type, format },
    answer,
    update
  } = props

  const [value, setValue] = useState(null)

  useEffect(() => {
    if (answer && !value) {
      setValue(answer.answer.value)
    }
  }, [answer])

  return (
    <>
      {type === 'time' ? (
        <TimePicker
          className={`q-form-item ${answer && answer.verify ? 'invalid' : ''}`}
          use12Hours
          minuteStep={15}
          format="h:mm A"
          onChange={preUpdate}
          value={value && moment(value, 'h:mm A')}
          // defaultValue={value ? moment(value, 'h:mm A') : null}
          allowClear={false}
        />
      ) : (
        <DatePicker
          value={value && moment(value)}
          className={`q-form-item ${answer && answer.verify ? 'invalid' : ''}`}
          format={format || 'YYYY-MM-DD'}
          onChange={preUpdate}
          placeholder={props.question.placeholder || format}
        />
      )}
      {answer && answer.verify && <p className="invalid">Required </p>}
    </>
  )

  function preUpdate(value, valueString) {
    if (type === 'date' && format) {
      valueString = value.format('YYYY-MM-DD')
    }
    const newAnswer = _.assignIn(answer, { answer: { value: valueString } })

    setValue(valueString)
    update(newAnswer, props.question)
  }
}
