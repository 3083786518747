import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider } from 'antd'
import {
  getClientList,
  getClientDetail,
  getClientSetting,
  getSettingPayment
} from '../../../../models/breadcrumb.model'
import Payment from '../../../onboarding/payment'
import PageLoading from '../../../../components/UI/status/loading'

class PaymentSetting extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    setClientPageTitle('Payment Method')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getClientSetting(person),
        getSettingPayment(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { history, match } = this.props
    const { person } = this.state
    return (
      <>
        <Divider />
        {person ? (
          <Payment
            loginRole="Expert"
            back={() => history.push(match.url)}
            client={person}
            location={{ search: '' }}
          />
        ) : (
          <PageLoading />
        )}
      </>
    )
  }
}

export default PaymentSetting
