import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
// import { Divider } from 'antd'
import Question from '../questionComponentsV2/question'
import { pattern } from '../../../utils/constant'
// import { loadUser } from '../../../utils'
// import { PopIcon } from '../setup/screen'
import { ErrorIcon } from '../../../components/icons/onboarding'

const InformationContainer = styled.div`
  .membership-container {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #98a9bc;
    border-radius: 8px;
    margin-bottom: 95px;
    .bold-text {
      color: #3a3e46;
      font-size: 18px;
      font-weight: 700;
      white-space: pre-wrap;
      letter-spacing: 1px;
      line-height: 135%;
    }
    .tiny-text {
      font-size: 12px;
      color: #313251;
      letter-spacing: 0.18px;
      text-transform: capitalize;
    }
    .include-desc-text {
      display: inline-block;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1px;
      color: #6d7d8c;
      border: 1px solid #e2f0f5;
      border-radius: 4px;
      background: #fbfefe;
      margin-top: 6px;
      span {
        transform: scale(0.84);
        display: block;
      }
    }
  }
  .q-body.ant-col-24 {
    padding-left: 0;
  }
`
export default function Information(props) {
  const { section, answers, introductory, showError } = props //updateAnswers person
  // const {
  //   profile: { membership }
  // } = loadUser()
  const updateAnswer = (answer) => {
    const {
      question_id: qid,
      answer: { value: body }
    } = answer
    const question = _.find(section.questions, { id: qid })
    if (!question) return
    if (answer.verify) {
      delete answer.verify
    }
    if (question.required && !body) {
      answer.verify = { required: true }
    } else if (question.answer_format) {
      if (answer.verify && question.id === 7) {
        delete answer.verify
      }
      if (question.items && question.id === 2) {
        // 1-2  and skip verify  1-7
        _.forIn(question.items, (item, index) => {
          let _verify
          _verify = verify(body[index], item)
          answer.verify = answer.verify || {}
          _.assignIn(answer.verify, { items: answer.verify.items || [] })
          answer.verify.items[index] = _verify
          if (answer.verify.items.filter((item) => item).length === 0) {
            delete answer.verify
          }
        })
      } else if (question.additional_information) {
        // 1-6
        _.forIn(question.additional_information, (info, index) => {
          const _verify = verify(body[index], info)
          answer.verify = answer.verify || {}
          delete answer.verify.required
          _.assignIn(answer.verify, {
            additional: answer.verify.additional || []
          })
          answer.verify.additional[index] = _verify
          if (answer.verify.additional.filter((item) => item).length === 0) {
            delete answer.verify
          }
        })
      } else if (question.choices) {
        // single choice?

        _.forIn(question.choices, (choice, index) => {
          if (body[index]) {
            if (choice.additional_information) {
              //question position 2-1-2
              answer.verify = answer.verify || {}
              delete answer.verify.required
              _.assignIn(answer.verify, {
                additional: answer.verify.additional || []
              })
              if (body[index].additional_answer) {
                _.forIn(choice.additional_information, (info, _index) => {
                  const _verify = verify(
                    body[index].additional_answer[_index],
                    info
                  )
                  answer.verify.additional[_index] = _verify
                })
              }

              if (
                answer.verify.additional.filter((item) => item).length === 0
              ) {
                delete answer.verify
              }
            } else if (choice.type === 'attachment' && choice.required) {
              // check attachment -> question position 2-1--1
              if (choice.attachment_key) {
                body[index] = body[index] || introductory[choice.attachment_key]
              }
              if (_.isEmpty(body[index])) {
                body[index] = { required: true }
              }
            }
          }
        })
      }
    }

    const params = inDestructuringAnswers(
      question.answer_format,
      body,
      props.handleAttachment
    )
    props.updateAnswers(answer, params)
  }

  return (
    <InformationContainer>
      <h1 className="title">{section.name}</h1>
      {showError && (
        <div className="error-message">
          <ErrorIcon />
          Error: Please answer all required questions.
        </div>
      )}
      {section.questions.map((question, index) => (
        <Question
          key={index}
          question={question}
          answers={answers || []}
          updateAnswer={updateAnswer}
          extraInfo={{ formatSource: introductory }}
        />
      ))}
      {/* {section.id === 1 && (
        <>
          <Divider style={{ margin: '40px 0' }} />
          <MemberShipInfo type={membership} person={person} />
        </>
      )} */}
    </InformationContainer>
  )
}

function inDestructuringAnswers(format, answer, handleAttachment) {
  const params = {}
  function formatting(format, answer, params, allowNull) {
    if (!answer && answer !== false) {
      if (allowNull) {
        params[format] = null
      }
      return
    }
    if (_.isString(format)) {
      params[format] = answer
    } else if (_.isArray(format)) {
      // question position: 1-8 --> change to 1-6
      _.forIn(format, (value, index) => {
        formatting(value, answer[index], params, true)
      })
    } else {
      /* format is object */
      if (format.type === 'choice') {
        _.forIn(answer, (value, key) => {
          const targetFormat = format.format[key]
          //question position 1-9 --> this question is removed
          params[targetFormat.key] = targetFormat.value
          if (targetFormat.text_key) {
            params[targetFormat.text_key] = value.text || { required: true }
          } else if (targetFormat.additional_key) {
            // question position 2-1
            formatting(
              targetFormat.additional_key.map((item) => item.key || item),
              value.additional_answer || value,
              params
            )
          } else if (targetFormat.attachment_key) {
            handleAttachment &&
              handleAttachment(targetFormat.attachment_key, answer[key])
          }
        })
      } else if (format.key) {
        if (_.isArray(answer)) {
          // question 1-7 --> this question is removed
          params[format.key] = answer
        } else if (format.format_key) {
          if (format.isChecked) {
            params[format.key] = answer.isChecked
          }
          params[format.format_key] = answer.additional_answer
        } else {
          params[format.key] = {}
          formatting(format.format, answer || [], params[format.key])
        }
      } else {
        _.forIn(format, (value, key) => {
          formatting(value, answer[key], params)
        })
      }
    }
  }

  formatting(format, answer, params)

  return params
}

function verify(answer, question) {
  let verify
  if (question.required && !answer) {
    verify = { required: true }
  }
  if (question.rule && answer) {
    if (!answer.toString().match(pattern[question.rule])) {
      verify = {
        message: question.error_message
      }
    }
  }

  return verify
}

// function MemberShipInfo(props) {
//   const billingPreference =
//     (props.person && props.person.profile.billing_preference) || 'Annual'
//   const membersType = {
//     evaluation: {
//       label: (
//         <div className="bold-text">
//           360 <br />
//           Evaluation
//         </div>
//       )
//     },
//     core: {
//       label: (
//         <div className="bold-text">
//           CORE A <br />
//           LA CARTE{' '}
//           <PopIcon description="Includes 360 Evaluation and Core program" />
//         </div>
//       ),
//       tinyText: '1 Year Membership'
//     },
//     'core+': {
//       label: (
//         <div className="bold-text">
//           CORE{' '}
//           <PopIcon description="Includes 360 Evaluation, Core program, and 150 service appointments" />
//         </div>
//       ),
//       tinyText: '1 Year Membership'
//     },
//     evaluation2: {
//       label: (
//         <div className="bold-text">
//           360 <br />
//           EVALUATION
//         </div>
//       ),
//       tinyText: `${billingPreference} Membership`
//     },
//     silver: {
//       label: (
//         <div className="bold-text">
//           SILVER <br />
//           MEMBERSHIP{' '}
//           <PopIcon description="Core Program, and 100 Standard Service appointments" />
//         </div>
//       ),
//       tinyText: `${billingPreference} Membership`,
//       descText: 'Includes 360 Evaluation'
//     },
//     gold: {
//       label: (
//         <div className="bold-text">
//           GOLD <br />
//           MEMBERSHIP{' '}
//           <PopIcon description="Core Program, and 125 Standard and Premium service appointments" />
//         </div>
//       ),
//       tinyText: `${billingPreference} Membership`,
//       descText: 'Includes 360 Evaluation'
//     },
//     module: {
//       label: (
//         <div className="bold-text">
//           Module <br />
//           MEMBERSHIP{' '}
//         </div>
//       )
//     }
//   }
//   const type = membersType[props.type]

//   return (
//     <div className="membership-container">
//       {type.label}
//       <span className="tiny-text">{type.tinyText}</span>
//       <br />
//       {type.descText && (
//         <div className="include-desc-text">
//           <span>{type.descText}</span>
//         </div>
//       )}
//     </div>
//   )
// }
