import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryLabel,
  VictoryScatter
} from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
// import Arrow from './arrow'
import { tickLabels, getAxisX, splitLine } from '../common'
import { fontFamily } from '../../../utils/constant'

function Dot(props) {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 3}
      y={(props.y && props.y - 3) || 0}
    >
      <circle
        cx="3"
        cy="3"
        r="1.75"
        fill="white"
        stroke="#5C00D1"
        strokeWidth="1.5"
      />
    </svg>
  )
}

class HRV extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    sleep: PropTypes.object
  }

  lineDataHandle(tickValuesX = []) {
    const { sleep, field } = this.props
    let values = tickValuesX.map((date) => {
      if (sleep.details) {
        let detail = sleep.details.find(
          (item) => item.summary_date === date.format('YYYY-MM-DD')
        )
        if (detail) {
          return { x: date, y: detail[field] }
        } else {
          return { x: date, y: null }
        }
      }
      return { x: date, y: null }
    })
    let { solidGroup, dashGroup } = splitLine(values)
    solidGroup = solidGroup.map((item) => ({
      data: item,
      dataStyle: {
        stroke: '#5C00D1',
        strokeWidth: 1.5
      }
    }))
    dashGroup = dashGroup.map((item) => ({
      data: item,
      dataStyle: {
        stroke: '#5C00D1',
        strokeDasharray: '3,3',
        strokeWidth: 1.5
      }
    }))
    return solidGroup.concat(dashGroup)
  }

  getOptimal() {
    const { ranges } = this.props
    return ranges || [20, 200]
  }

  getDots(tickValuesX) {
    const { sleep, field } = this.props
    const entries = sleep.details
    const data = tickValuesX
      .map((item, index) => {
        const target = entries.find(
          (entry) => entry.summary_date === item.format('YYYY-MM-DD')
        )
        return {
          x: item,
          y: target && target[field]
        }
      })
      .filter((item) => item.y)
    return data
  }

  render() {
    const { startDate, endDate, unit } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    let lineData = [],
      entries,
      hasData
    if (tickValuesX) {
      lineData = this.lineDataHandle(tickValuesX)
      hasData = lineData.length > 0
      if (!hasData) {
        lineData = [
          {
            data: tickValuesX.map((date) => {
              return { x: date, y: 0.5 }
            }),
            dataStyle: {
              stroke: '#5C00D1',
              strokeDasharray: '3,3',
              strokeWidth: 1.5
            }
          }
        ]
      } else {
        entries = this.getDots(tickValuesX)
      }
    }

    return (
      <ContainerWidthSizer>
        {({ width }) => (
          //Optimal Area and
          <VictoryChart width={width} height={60}>
            <VictoryAxis
              style={{
                axis: {
                  stroke: 'transparent'
                },
                tickLabels: {
                  fill: 'transparent'
                }
              }}
              tickValues={tickValuesX}
              tickLabelComponent={<VictoryLabel text={''} />}
            />
            {hasData && (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                orientation="right"
                tickValues={this.getOptimal()}
                tickFormat={(t) => `${t} ${unit}`}
                style={{
                  axis: { stroke: 'transparent' },
                  grid: {
                    stroke: '#5C00D1',
                    opacity: '0.2'
                  },
                  tickLabels: tickLabels
                }}
              />
            )}
            {/* {hasData && (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                tickValues={[110]}
                tickLabelComponent={<Arrow />}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fill: '#B7BFC6'
                  }
                }}
              />
            )} */}
            {/* {hasData ? (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                tickValues={[110]}
                tickLabelComponent={
                  <VictoryLabel
                    text={'Optimal'}
                    angle={-90}
                    dy={-8}
                    textAnchor="middle"
                  />
                }
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: tickLabels
                }}
              />
            ) : (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                tickValues={[0, 1]}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: { fill: 'transparent' }
                }}
              />
            )} */}
            {lineData &&
              lineData.map((item, index) => (
                <VictoryLine
                  key={index}
                  style={{
                    data: item.dataStyle
                  }}
                  data={item.data}
                />
              ))}
            {entries && (
              <VictoryScatter data={entries} dataComponent={<Dot />} />
            )}
            <VictoryAxis
              offsetY={20}
              tickValues={tickValuesX}
              tickFormat={(x) => x.format && x.format('ddd').toUpperCase()}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: {
                  fontFamily: `"Gilroy",${fontFamily}`,
                  fontSize: '10px',
                  fill: '#70808E'
                }
              }}
            />
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default HRV
