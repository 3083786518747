import React, { useState, useEffect } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

function DatePickerItem(props) {
  const { format, disabledDate, disabled, extraAttr, onChange } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    props.value && setValue(moment(props.value))
  }, [props.value])

  const triggerChange = (date, dateString) => {
    setValue(date)
    if (onChange) {
      if (extraAttr.returnDate) {
        onChange(date)
      } else {
        onChange(dateString)
      }
    }
  }

  return (
    <div>
      <DatePicker
        format={format}
        style={{ width: '100%' }}
        onChange={triggerChange}
        value={value}
        disabledDate={disabledDate}
        disabled={disabled}
        {...extraAttr}
      />
    </div>
  )
}

export default DatePickerItem
