import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-duration-format'
import { Row, Col, Tabs, Divider } from 'antd'
import ActivityTrend from './trend'
import DateRange from '../../UI/mgComponents/dateRange'
import PageLoading from '../../UI/status/loading'
import ActivityMultiLine from './multi-line'
import SleepBars from './sleep-bars'
import { getMicroGoalById } from '../../../api/microGoals'

import ActivityBar from './bar'
import DetailItems from './detailItems'
import HeartRateZoneStage from './heartRateZoneStage'
import HeartRateInfo from './heartRateInfo'
import OuraSleep from './ouraSleep'
import RPE from './rpe'
import { loadUser } from '../../../utils/storage'
import { getRole, Pluralize } from '../../../utils/common'
import { getSum } from '../../../utils/common'
import { activityStyle } from './styles/activity.sass'
import NullStatus from '../../../asserts/images/mg-input-null-status.svg'

const { TabPane } = Tabs

class ActivityContainer extends Component {
  static propTypes = {
    trackingActivityMetrics: PropTypes.any,
    editGoal: PropTypes.object,
    statistics: PropTypes.object,
    targetDate: PropTypes.string,
    person: PropTypes.object,
    origin: PropTypes.string,
    setDetailStatistics: PropTypes.func,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    saveManualRPE: PropTypes.func
  }

  state = {
    activityDetailIndex: 0,
    showHRZIndex: 0,
    period: 'day',
    targetDate: null,
    metaData: null,
    loading: false,
    entries: null,
    childrenGoal: null
  }

  hourFormat = (hour) =>
    hour && `${parseInt(hour)}h ${Math.round((hour % 1) * 60)}m`

  getTime = (bedTime, point) => {
    const obj = JSON.parse(bedTime) && JSON.parse(bedTime)[0]
    if (!obj[point]) {
      return
    }

    const time = obj[point].split('T')[1]
    return moment(time, 'HH:mm:ss').format('hh:mmA')
  }

  selectPeriod = (period) => {
    let targetDate = this.props.targetDate
      ? moment(this.props.targetDate)
      : moment()
    if (period === 'week') {
      let startDate
      if (moment(targetDate).format('dddd') === 'Sunday') {
        startDate = moment(targetDate)
          .subtract(1, 'week')
          .day('Monday')
      } else {
        startDate = moment(targetDate).day('Monday')
      }
      targetDate = startDate
    } else if (period === 'month') {
      const showDate = moment(targetDate).format('MM-YYYY')
      const startDate = moment(`01-${showDate}`, 'DD-MM-YYYY')
      targetDate = startDate
    }
    this.getGoalMeta(targetDate, period)
    this.setState({ period, targetDate, showHRZIndex: 0 })
  }

  prevDate = async () => {
    let { period, targetDate } = this.state
    if (period === 'day') {
      targetDate = targetDate.subtract(1, 'days')
    } else if (period === 'week') {
      targetDate = targetDate.subtract(1, 'week')
    } else if (period === 'month') {
      targetDate = targetDate.subtract(1, 'month')
    }
    await this.getGoalMeta(targetDate, period)
    this.setState({ targetDate, activityDetailIndex: 0, showHRZIndex: 0 })
  }

  nextDate = async () => {
    let { period, targetDate } = this.state
    if (period === 'day') {
      targetDate = targetDate.add(1, 'days')
    } else if (period === 'week') {
      targetDate = targetDate.add(1, 'week')
    } else if (period === 'month') {
      targetDate = targetDate.add(1, 'month')
    }
    await this.getGoalMeta(targetDate, period)
    this.setState({ targetDate, activityDetailIndex: 0, showHRZIndex: 0 })
  }

  getStartAndEndDate = (targetDate, period) => {
    let startDate, endDate, days
    targetDate = targetDate.format ? targetDate : moment(targetDate)
    if (period === 'week') {
      startDate = targetDate.clone().day('Monday')
      endDate = startDate
        .clone()
        .add(1, 'week')
        .day('Sunday')
      days = endDate.daysInMonth()
    } else {
      startDate = moment(`01-${targetDate.format('MM-YYYY')}`, 'DD-MM-YYYY')
      days = startDate.daysInMonth()
      endDate = moment(`${days}-${startDate.format('MM-YYYY')}`, 'DD-MM-YYYY')
    }
    return { startDate, endDate, days }
  }

  getGoalMeta = async (targetDate, period) => {
    targetDate = targetDate.format ? targetDate : moment(targetDate)
    const { editGoal, person, origin, setDetailStatistics } = this.props
    let from, to
    const { startDate, endDate, days } = this.getStartAndEndDate(
      targetDate,
      period
    )

    if (period === 'month') {
      from = moment(`01-${startDate.format('MM-YYYY')}`, 'DD-MM-YYYY')
      to = moment(`${days}-${endDate.format('MM-YYYY')}`, 'DD-MM-YYYY')
    } else if (period === 'day') {
      to = targetDate
      from = targetDate
    } else {
      from = startDate
      to = endDate
    }
    this.setState({ loading: true })
    try {
      const result = await getMicroGoalById(
        from.format('YYYY-MM-DD'),
        to.format('YYYY-MM-DD'),
        person.id,
        editGoal.id
      )
      const pillor = editGoal.category.pillar
      const { achieving, achieving_summary: achievingSummary } = result[
        pillor
      ].find((item) => item.micro_goal.id === editGoal.id)
      let entries = []
      for (const achiev of achieving) {
        entries = entries.concat(achiev.entries)
      }
      if (period === 'month') {
        entries = entries.filter(
          (entry) =>
            startDate.isSameOrBefore(entry.created_at, 'month') &&
            endDate.isSameOrAfter(entry.created_at, 'month')
        )
      }
      const childrenGoal = result[pillor].filter(
        (item) => item.micro_goal.id !== editGoal.id
      )
      if (origin === 'Overview') {
        setDetailStatistics(result)
      }
      this.setState({
        loading: false,
        entries,
        achievingSummary,
        childrenGoal
      })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  showActivityLocation = (detail) => {
    const { targetDate } = this.state
    let showTime
    if (detail.start_date) {
      showTime = moment(targetDate).add(detail.start_date, 's')
    } else {
      showTime = moment((detail.start_time_local || detail.start_time) * 1000)
    }
    if (detail && detail.location && detail.location !== 'null') {
      return (
        <div className="location-date-info">
          <div className="location-info">{detail.location || 'Suzhou'}</div>
          <div className="date-info">
            {showTime.format('ddd,MMM DD - hh:mm A')}
          </div>
        </div>
      )
    }
    return null
  }

  getTrackingActivityMetrics = () => {
    let { trackingActivityMetrics } = this.props
    trackingActivityMetrics =
      trackingActivityMetrics ||
      'type,distance,duration,caloriesBurnt,avgHeartRate,maxHeartRate,heartRate,heartRateZone'
    return trackingActivityMetrics
  }

  judgeNullStatus = () => {
    const { period, targetDate, entries } = this.state
    if (!entries) {
      return true
    }
    let isNull = false
    if (period === 'day') {
      const entry = entries.find(
        (item) =>
          (item.input_auto || item.activity_details.length > 0) &&
          item.created_at === targetDate.format('YYYY-MM-DD')
      )
      isNull = !entry
      if (this.props.editGoal.goal_type === 'sleep') {
        isNull = !entries.find((item) => item.activity_details)
      }
    } else {
      isNull =
        entries.filter((item) => item.input_auto || item.activity_details)
          .length === 0
    }
    return isNull
  }

  getEntry = (entryDate) => {
    const { entries } = this.state
    const entry =
      entries && entries.find((item) => item.created_at === entryDate)
    return entry
  }

  getShowDate = () => {
    const { period, targetDate } = this.state
    if (!targetDate) return
    let result = ''
    if (period === 'day') {
      result = targetDate.format('ddd, MMM D, YYYY')
    } else if (period === 'week') {
      const startDate = moment(targetDate).day('Monday')
      const endDate = startDate.clone().day(7)
      result = `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`
    } else if (period === 'month') {
      result = targetDate.format('MMM, YYYY')
    }
    return result
  }

  getCurrentWeekDate = () => {
    let startDate = moment().day('Monday')
    if (
      moment()
        .weekday(0)
        .format('dddd') === 'Sunday'
    ) {
      if (moment().format('dddd') === 'Sunday') {
        startDate = moment()
          .subtract(1, 'days')
          .day('Monday')
      }
    }
    const endDate = startDate.clone().day(7)
    return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`
  }

  getActicityDetailArrByAttr = (attr) => {
    const { entries } = this.state
    let input
    return entries
      .filter((item) => item.input_auto || item.activity_details.length > 0)
      .map((entry) => {
        const { activity_details: details, created_at } = entry || {
          activity_details: []
        }
        if (attr === 'max_heart_rate') {
          input = Math.max(...details.map((detail) => detail[attr] || 0))
        } else if (attr === 'average_heart_rate') {
          const filterDetails = details.filter(
            (item) => item.source !== 'manual'
          )
          input = getSum(filterDetails.map((detail) => detail[attr]))
          input =
            filterDetails.length > 0 && Number(input)
              ? input / filterDetails.length
              : input
        } else {
          input = getSum(details.map((detail) => detail[attr] || 0))
        }
        return {
          created_at,
          input: (input && Number(input)) || null
        }
      })
      .filter((item) => item.input || item.input === 0)
  }

  setManualRpe = async (id, rpe) => {
    const { saveManualRPE, editGoal } = this.props
    const result = await saveManualRPE(editGoal, id, rpe)
    const { entries } = this.state
    if (entries && entries.length > 0 && result) {
      const targetEntry = entries.find((entry) =>
        entry.activity_details.find((detail) => detail.record_id === result.id)
      )
      if (targetEntry) {
        const targetDetail = targetEntry.activity_details.find(
          (detail) => detail.record_id === result.id
        )
        if (targetDetail) {
          targetDetail.rpe_manual = result.rpe_manual
          targetDetail.training_load = result.training_load
        }
      }
    }
    this.setState({ entries })
    return result
  }

  componentDidMount() {
    const { targetDate, editGoal, statistics, originPeriod } = this.props
    const pillor = editGoal.category.pillar
    const { achieving } = (statistics[pillor] &&
      statistics[pillor].find(
        (item) => item.micro_goal.id === editGoal.id
      )) || {
      achieving: []
    }
    let entries = []
    for (const achiev of achieving) {
      entries = entries.concat(achiev.entries)
    }
    const state = {
      targetDate: targetDate ? moment(targetDate) : moment(),
      entries
    }
    if (originPeriod) {
      state.period = originPeriod
    }
    this.setState(state)
    if (
      editGoal.goal_type === 'heartRateMetrics' ||
      editGoal.goal_type === 'sleep'
    ) {
      this.getGoalMeta(targetDate || moment(), originPeriod || 'day')
    }
  }

  renderActivityCharts() {
    const { period, targetDate } = this.state
    const { editGoal } = this.props
    const trackingActivityMetrics = this.getTrackingActivityMetrics()
    const { source, input_type } = editGoal
    let distanceDatas,
      durationDatas,
      activeCalDatas,
      avgHeartRateDatas,
      maxHeartRateDatas
    let startDate, endDate
    if (source !== 'manual' && input_type === 'binary') {
      distanceDatas = this.getActicityDetailArrByAttr('distance')
      distanceDatas = distanceDatas.map((item) => {
        item.input = Math.round((item.input || 0) / 16.09344) / 100
        return item
      })
      durationDatas = this.getActicityDetailArrByAttr('duration')
      durationDatas = durationDatas.map((item) => {
        item.input = Math.round((item.input / 60) * 10) / 10
        return item
      })
      activeCalDatas = this.getActicityDetailArrByAttr('energy_burned')
      const dates = this.getStartAndEndDate(targetDate, period)
      startDate = dates.startDate
      endDate = dates.endDate

      avgHeartRateDatas = this.getActicityDetailArrByAttr('average_heart_rate')
      maxHeartRateDatas = this.getActicityDetailArrByAttr('max_heart_rate')
    }
    return (
      <div style={{ marginTop: 20 }}>
        {trackingActivityMetrics &&
          trackingActivityMetrics.split &&
          trackingActivityMetrics
            .split(',')
            .find((item) => item === 'distance') && (
            <>
              <div className="chart-title">
                <div>Distance (mi)</div>
              </div>
              <ActivityBar
                {...{
                  period,
                  startDate: startDate.format('YYYY-MM-DD'),
                  endDate: endDate.format('YYYY-MM-DD'),
                  datas: distanceDatas,
                  maxAxis: 0.2
                }}
              />
            </>
          )}
        <div className="chart-title">
          <div>Duration (minutes)</div>
        </div>
        <ActivityBar
          {...{
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            datas: durationDatas,
            maxAxis: 90
          }}
        />
        <div className="chart-title">
          <div>Active Calories (Cal)</div>
        </div>
        <ActivityBar
          {...{
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            datas: activeCalDatas,
            maxAxis: 200,
            barColor: '#FFB570'
          }}
        />
        <div className="chart-title">
          <div>Heart rate (bpm)</div>
          <div>
            <span>
              <span className="circle" style={{ background: '#264382' }} />
              Avg
            </span>
            <span>
              <span className="circle" style={{ background: '#9CA5B8' }} />
              Max
            </span>
          </div>
        </div>
        <ActivityMultiLine
          {...{
            datas: [
              {
                data: avgHeartRateDatas,
                color: '#264382'
              },
              {
                data: maxHeartRateDatas,
                color: '#9CA5B8'
              }
            ],
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            maxAxis: 100,
            minAxis: 60
          }}
        />
      </div>
    )
  }

  renderSleepCharts() {
    const { period, targetDate } = this.state
    const { editGoal, statistics } = this.props
    const pillor = editGoal.category.pillar
    let startDate, endDate
    const dates = this.getStartAndEndDate(targetDate, period)
    startDate = dates.startDate
    endDate = dates.endDate
    let childrenGoal = this.state.childrenGoal
    if (!childrenGoal) {
      statistics[pillor].filter(
        (item) => item.micro_goal.parent_micro_goal_id === editGoal.id
      )
    }
    const totalSleepList = [
      {
        chartLabel: 'Inbed',
        color: '#9CA5B8',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'Sleep Time - In Bed'
        )
      },
      {
        chartLabel: 'Asleep',
        color: '#7BAD2D',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'Sleep Time - Asleep'
        )
      }
    ]
    const bedTimeList = [
      {
        color: '#264382',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'Bed Time'
        )
      },
      {
        color: '#264382',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'Wake-up Time'
        )
      }
    ]
    const efficiencyList = [
      {
        title: 'Sleep Efficiency',
        color: '#264382',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepEfficiency'
        )
      }
    ]
    const heartRateList = [
      {
        chartLabel: 'Avg',
        color: '#264382',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepAvgHeartRate'
        )
      },
      {
        chartLabel: 'Max',
        color: '#9CA5B8',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepMaxHeartRate'
        )
      },
      {
        chartLabel: 'Min',
        color: '#7BAD2D',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepMinHeartRate'
        )
      }
    ]
    const HRVRateList = [
      {
        chartLabel: 'Avg',
        color: '#264382',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepHeartRateVariation'
        )
      },
      {
        chartLabel: 'Max',
        color: '#9CA5B8',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepMaxHeartRateVariation'
        )
      },
      {
        chartLabel: 'Min',
        color: '#7BAD2D',
        goal: childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === 'SleepMinHeartRateVariation'
        )
      }
    ]

    return (
      <div className="bottom-line" style={{ marginTop: 20 }}>
        <div className="chart-title">
          <div>Total Sleep Time</div>
          <div>
            {totalSleepList.map((item, index) => (
              <span key={index}>
                <span className="circle" style={{ background: item.color }} />
                {item.chartLabel}
              </span>
            ))}
          </div>
        </div>

        <SleepBars
          type="Total Sleep Time"
          {...{
            list: totalSleepList,
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            parentEntries: this.state.entries
          }}
        />

        <div className="chart-title">
          <div>Bed Time</div>
        </div>

        <SleepBars
          type="Bed Time"
          {...{
            list: bedTimeList,
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
          }}
        />

        <div className="chart-title">
          <div>Sleep Efficiency</div>
        </div>

        <ActivityMultiLine
          {...{
            list: efficiencyList,
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            minAxis: 60
          }}
        />

        <div className="chart-title">
          <div>Heart Rate</div>
          <div>
            {heartRateList.map((item, index) => (
              <span key={index}>
                <span className="circle" style={{ background: item.color }} />
                {item.chartLabel}
              </span>
            ))}
          </div>
        </div>

        <ActivityMultiLine
          {...{
            list: heartRateList,
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            minAxis: 40
          }}
        />

        <div className="chart-title">
          <div>Heart Rate Variation</div>
          <div>
            {HRVRateList.map((item, index) => (
              <span key={index}>
                <span className="circle" style={{ background: item.color }} />
                {item.chartLabel}
              </span>
            ))}
          </div>
        </div>

        <ActivityMultiLine
          {...{
            list: HRVRateList,
            period,
            startDate: startDate.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD'),
            minAxis: 60
          }}
        />
      </div>
    )
  }

  renderNullStatus(period) {
    const role = getRole(loadUser().role)
    return (
      <div className="input-null-status">
        <img src={NullStatus} alt="null status" />
        <h3>No activity data available for this {period}</h3>
        <p>
          {role === 'Expert'
            ? 'Remind clients to wear their devices and sync data regularly to view activity metrics'
            : 'Please wear your devices and sync data regularly to view activity metrics'}
        </p>
      </div>
    )
  }

  renderActivitySummary(summarize) {
    if (!summarize.length) return null
    const Aerobic = summarize.find((item) => item.name === 'Aerobic')
    const Resistance = summarize.find((item) => item.name === 'Resistance')
    const Activity = summarize.find((item) => item.name === 'Activity')
    const MovementQuality = summarize.find(
      (item) => item.name === 'Movement Quality'
    )
    return (
      <>
        <div className="heart-rate-title" style={{ paddingTop: 25 }}>
          Activity Summary
        </div>
        <Row className="activity-type-text-container bottom-line">
          {Aerobic && (
            <>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Aerobic sessions</div>
                <div className="activity-text-content">
                  {Aerobic.count} {Pluralize('sessions', Aerobic.count)}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Aerobic duration</div>
                <div className="activity-text-content">
                  {Math.round(Aerobic.duration / 60)}{' '}
                  {Pluralize('mins', Math.round(Aerobic.duration / 60))}
                </div>
              </Col>
            </>
          )}
          {Resistance && (
            <>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Resistance sessions</div>
                <div className="activity-text-content">
                  {Resistance.count} {Pluralize('sessions', Resistance.count)}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Resistance duration</div>
                <div className="activity-text-content">
                  {Math.round(Resistance.duration / 60)}{' '}
                  {Pluralize('mins', Math.round(Resistance.duration / 60))}
                </div>
              </Col>
            </>
          )}

          {Activity && (
            <>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Activity sessions</div>
                <div className="activity-text-content">
                  {Activity.count} {Pluralize('sessions', Activity.count)}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Activity duration</div>
                <div className="activity-text-content">
                  {Math.round(Activity.duration / 60)}{' '}
                  {Pluralize('mins', Math.round(Activity.duration / 60))}
                </div>
              </Col>
            </>
          )}
          {MovementQuality && (
            <>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">
                  Movement quality sessions
                </div>
                <div className="activity-text-content">
                  {MovementQuality.count}{' '}
                  {Pluralize('sessions', MovementQuality.count)}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">
                  Movement quality duration
                </div>
                <div className="activity-text-content">
                  {Math.round(MovementQuality.duration / 60)}{' '}
                  {Pluralize('mins', Math.round(MovementQuality.duration / 60))}
                </div>
              </Col>
            </>
          )}
        </Row>
      </>
    )
  }

  renderDays() {
    const {
      activityDetailIndex,
      targetDate,
      achievingSummary,
      period,
      entries,
      childrenGoal
    } = this.state
    const { editGoal, statistics } = this.props
    const trackingActivityMetrics = this.getTrackingActivityMetrics()
    const entry = this.getEntry(targetDate && targetDate.format('YYYY-MM-DD'))
    const activityDetail =
      entry &&
      entry.activity_details &&
      entry.activity_details[activityDetailIndex]
    const showHeartRate =
      activityDetail &&
      (getSum(activityDetail.heartRateZoneSource) > 0 ||
        (activityDetail.samples && activityDetail.samples.length > 0))
    return (
      <>
        {editGoal.goal_type === 'heartRateMetrics' && (
          <HeartRateInfo
            {...{
              entries,
              editGoal,
              statistics,
              period,
              targetDate,
              achievingSummary,
              childrenGoal,
              renderNullStatus: this.renderNullStatus
            }}
          />
        )}
        {entry && entry.activity_details.length > 1 && (
          <div className="activity-toggle-container">
            <Tabs
              defaultActiveKey="0"
              tabPosition="top"
              onChange={(activeKey) =>
                this.setState({ activityDetailIndex: activeKey })
              }
            >
              {[...Array(entry.activity_details.length).keys()].map((i) => (
                <TabPane tab={`Activity ${i + 1}`} key={i} />
              ))}
            </Tabs>
          </div>
        )}

        {activityDetail &&
          (editGoal.goal_type === 'sleep' ? (
            <OuraSleep
              sleepDetail={activityDetail.sleep || achievingSummary}
              microGoalSource={editGoal.source}
            />
          ) : (
            this.showActivityLocation(activityDetail)
          ))}
        {editGoal.input_type === 'binary' && editGoal.source !== 'manual' && (
          <>
            {/* {entry && entry.input && !entry.input_auto && (
              <Row className="activity-type-text-container bottom-line">
                <Col style={{ paddingTop: 16 }} span={12}>
                  <div className="activity-text-title">Source</div>
                  <div className="activity-text-content">Manual</div>
                </Col>
              </Row>
            )} */}
            {entry &&
            (entry.input_auto ||
              entry.activity_details ||
              (entry.manual && editGoal.goal_type === 'sleep'))
              ? (editGoal.goal_type !== 'sleep' && (
                  <>
                    <div className="bottom-line">
                      <DetailItems
                        showHeartRate={showHeartRate}
                        activityDetail={activityDetail}
                      />

                      {trackingActivityMetrics &&
                        activityDetail &&
                        showHeartRate && (
                          <>
                            <Divider />
                            <div className="activity-chart-container">
                              {trackingActivityMetrics.includes(
                                'heartRate'
                              ) && (
                                <div className="activity-heart-rate-container">
                                  <div className="heart-rate-title">
                                    Heart Rate
                                  </div>
                                  <ActivityTrend
                                    id={activityDetailIndex}
                                    detail={activityDetail}
                                  />
                                </div>
                              )}
                              {trackingActivityMetrics.includes(
                                'heartRateZone'
                              ) && (
                                <HeartRateZoneStage
                                  editGoal={editGoal}
                                  activityDetail={activityDetail}
                                  calculatePercentage={true}
                                />
                              )}
                            </div>
                          </>
                        )}
                    </div>
                    <Divider />
                    <RPE
                      activityDetail={activityDetail}
                      setManualRpe={this.setManualRpe}
                    />
                  </>
                )) || <></>
              : this.renderNullStatus('day')}
          </>
        )}
      </>
    )
  }

  renderWeek(isCurrentWeek, showDate) {
    const { achievingSummary, period, targetDate, childrenGoal } = this.state
    const { editGoal, statistics } = this.props
    return (
      <>
        {editGoal.goal_type === 'heartRateMetrics' && (
          <HeartRateInfo
            {...{
              editGoal,
              statistics,
              period,
              targetDate,
              achievingSummary,
              childrenGoal,
              renderNullStatus: this.renderNullStatus,
              isCurrentWeek,
              showDate
            }}
          />
        )}

        {editGoal.source !== 'manual' && editGoal.input_type === 'binary' && (
          <>
            <Row className="activity-type-text-container bottom-line">
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Type</div>
                <div className="activity-text-content">
                  {editGoal.goal_type === 'sleep'
                    ? 'Sleep'
                    : editGoal.auto_activity}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Source</div>
                <div className="activity-text-content">
                  {editGoal.source === 'ouraring'
                    ? 'Oura Ring'
                    : editGoal.source}
                </div>
              </Col>
            </Row>
          </>
        )}
        {achievingSummary &&
          achievingSummary.summarize_classification &&
          this.renderActivitySummary(achievingSummary.summarize_classification)}

        {!editGoal.goal_type && (
          <div style={{ paddingTop: 25 }}>
            <HeartRateZoneStage
              editGoal={editGoal}
              activityDetail={achievingSummary}
              totalWidth={520}
            />
          </div>
        )}
        {editGoal.source !== 'manual' && editGoal.input_type === 'binary' && (
          <>
            {editGoal.goal_type === 'sleep'
              ? this.renderSleepCharts()
              : this.renderActivityCharts()}
          </>
        )}

        {achievingSummary && editGoal.goal_type === 'sleep' && (
          <div style={{ paddingTop: 25 }}>
            <OuraSleep onlyStage={true} sleepDetail={achievingSummary} />
          </div>
        )}
      </>
    )
  }

  renderMonth() {
    const { achievingSummary, period, targetDate, childrenGoal } = this.state
    const { editGoal, statistics } = this.props
    return (
      <>
        {editGoal.goal_type === 'heartRateMetrics' && (
          <HeartRateInfo
            {...{
              editGoal,
              statistics,
              period,
              targetDate,
              achievingSummary,
              childrenGoal,
              renderNullStatus: this.renderNullStatus
            }}
          />
        )}
        {editGoal.source !== 'manual' && editGoal.input_type === 'binary' && (
          <>
            <Row className="activity-type-text-container bottom-line">
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Type</div>
                <div className="activity-text-content">
                  {editGoal.goal_type === 'sleep'
                    ? 'Sleep'
                    : editGoal.auto_activity}
                </div>
              </Col>
              <Col style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">Source</div>
                <div className="activity-text-content">
                  {editGoal.source === 'ouraring'
                    ? 'Oura Ring'
                    : editGoal.source}
                </div>
              </Col>
            </Row>
          </>
        )}
        {achievingSummary &&
          achievingSummary.summarize_classification &&
          this.renderActivitySummary(achievingSummary.summarize_classification)}
        {!editGoal.goal_type && (
          <div style={{ paddingTop: 25 }}>
            <HeartRateZoneStage
              editGoal={editGoal}
              activityDetail={achievingSummary}
            />
          </div>
        )}
        {editGoal.source !== 'manual' && editGoal.input_type === 'binary' && (
          <>
            {editGoal.goal_type === 'sleep'
              ? this.renderSleepCharts()
              : this.renderActivityCharts()}
          </>
        )}
        {achievingSummary && editGoal.goal_type === 'sleep' && (
          <div style={{ paddingTop: 25 }}>
            <OuraSleep onlyStage={true} sleepDetail={achievingSummary} />
          </div>
        )}
      </>
    )
  }

  render() {
    const { period, loading } = this.state
    const showDate = this.getShowDate()
    const isCurrentWeek = this.getCurrentWeekDate() === showDate
    const isNull = this.judgeNullStatus()
    return (
      <div className={activityStyle}>
        <div className="peroid-bar">
          <ul className="select-peroid">
            <li onClick={() => this.selectPeriod('day')}>
              <span className={period === 'day' ? 'active' : ''}>Day</span>
            </li>
            <li onClick={() => this.selectPeriod('week')}>
              <span className={period === 'week' ? 'active' : ''}>Week</span>
            </li>
            <li onClick={() => this.selectPeriod('month')}>
              <span className={period === 'month' ? 'active' : ''}>Month</span>
            </li>
          </ul>
          <DateRange
            prevDate={this.prevDate}
            nextDate={this.nextDate}
            showDate={showDate}
          />
        </div>
        {loading ? (
          <PageLoading />
        ) : isNull ? (
          this.renderNullStatus(period)
        ) : (
          <>
            {period === 'day' && this.renderDays()}
            {period === 'week' && this.renderWeek(isCurrentWeek, showDate)}
            {period === 'month' && this.renderMonth()}
          </>
        )}
      </div>
    )
  }
}

export default ActivityContainer
