import React, { Component } from 'react'
import {
  VictoryPie,
  VictoryStack,
  VictoryBar,
  VictoryLabel,
  VictoryAxis,
  VictoryChart
  // VictoryPortal
} from 'victory'
import './index.sass'

// type CategorizedBloodTest = {
//   root_category: string
//   bio_marker: string
//   optimal_range: [number, number]
//   unit: string
//   real: boolean
//   output: string
//   value: number
// }

// type Props = {
//   bloodTestResult: CategorizedBloodTest[]
// }

const Green = '#40C47C'
const Yellow = '#FFB800'
const Red = '#FF6B00'
class ReportBloodTestCharts extends Component {
  categoryStyle() {
    return {
      grid: {
        stroke: 'transparent'
      },
      axis: {
        stroke: 'transparent'
      },
      tickLabels: {
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: 12,
        fill: '#323A46',
        opacity: 0.8
      }
    }
  }

  barStyle() {
    return {
      labels: {
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: 14,
        fill: '#ffffff',
        opacity: 0.8
      }
    }
  }

  percentageStyle() {
    return {
      grid: {
        stroke: 'rgb(0, 0, 0, 0.04)',
        strokeWidth: 1
      },
      axis: {
        stroke: 'transparent'
      },
      ticks: {
        strokeWidth: 0
      },
      tickLabels: {
        padding: 40,
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSize: '12',
        fill: '#323A46',
        opacity: '0.6'
      }
    }
  }

  //(green: number, yellow: number, red: number)
  renderPie(green, yellow, red) {
    return (
      <div className="pie">
        <VictoryPie
          colorScale={[Green, Yellow, Red]}
          padding={0}
          radius={70}
          width={141}
          height={141}
          labelRadius={0}
          innerRadius={50}
          data={[{ y: green }, { y: yellow }, { y: red }]}
        />
      </div>
    )
  }

  // (category: string, percentage: number, color: string)
  renderSummary(category, percentage, color) {
    return (
      <div className="pie-number">
        <svg
          width="114"
          height="88"
          viewBox="0 0 114 88"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <text
            opacity={0.8}
            dx={20}
            dy={25}
            fontSize={14}
            fontFamily="Lato"
            fontWeight="bold"
            fill="#4C6072"
          >
            {category.toUpperCase()}
          </text>
          <text
            textAnchor="start"
            alignmentBaseline="hanging"
            dy={40}
            fontSize={36}
            fontFamily="Lato"
            fill="#333333"
          >
            {percentage <= 1 ? Math.round(percentage * 1000) / 10 + '%' : '--%'}
          </text>
          <circle cx="7.5" cy="19.5" r="5.5" stroke={color} strokeWidth="4" />
        </svg>
      </div>
    )
  }

  // (
  //   groups: { [key: string]: [number, number, number] },
  //   index: number,
  //   horizontal: boolean
  // )
  renderBar(groups, index, horizontal) {
    return (
      <VictoryBar
        labelComponent={
          <VictoryLabel
            dy={horizontal ? 0 : 30}
            dx={horizontal ? -25 : 0}
            // text={(d: any) => groups[d.x] && groups[d.x][index]}
          />
        }
        labels={(d) =>
          (groups[d.x.replace('\n', ' ')] &&
            groups[d.x.replace('\n', ' ')][index]) ||
          ''
        }
        style={this.barStyle()}
        barWidth={20}
        data={(Object.keys(groups) || []).map((key) => ({
          x: key.replace(' ', '\n'),
          y:
            groups[key][index] /
            (groups[key][0] + groups[key][1] + groups[key][2])
        }))}
      />
    )
  }

  // groups: { [key: string]: [number, number, number] },
  // horizontal: boolean
  renderStack(groups, horizontal) {
    return (
      <VictoryChart
        domain={{ y: [0, 1] }}
        singleQuadrantDomainPadding={false}
        height={horizontal ? Object.keys(groups).length * 50 + 100 : 434}
        width={(horizontal ? 3 : Object.keys(groups).length) * 100 + 100}
        padding={{
          left: horizontal ? 100 : 120,
          top: 30,
          bottom: 60,
          right: 80
        }}
      >
        <VictoryAxis
          orientation={horizontal ? 'left' : 'bottom'}
          style={this.categoryStyle()}
        />
        <VictoryAxis
          orientation={horizontal ? 'left' : 'bottom'}
          offsetY={horizontal ? 0 : 30}
          offsetX={horizontal ? 30 : 0}
          style={this.categoryStyle()}
          tickFormat={(key) => {
            const group = groups[key.replace('\n', ' ')]
            if (group) {
              return group[0] + group[1] + group[2]
            } else {
              return '--'
            }
          }}
        />
        <VictoryAxis
          orientation={horizontal ? 'bottom' : 'left'}
          dependentAxis
          crossAxis={false}
          tickFormat={(percent) => {
            return Math.round(percent * 100) + '%'
          }}
          style={this.percentageStyle()}
        />
        <VictoryStack colorScale={[Green, Yellow, Red]} horizontal={horizontal}>
          {this.renderBar(groups, 0, horizontal)}
          {this.renderBar(groups, 1, horizontal)}
          {this.renderBar(groups, 2, horizontal)}
        </VictoryStack>
      </VictoryChart>
    )
  }

  render() {
    const { bloodTestResult } = this.props
    let green = 0
    let yellow = 0
    let red = 0
    const groups = {}
    for (const test of bloodTestResult) {
      if (!groups[test.root_category]) {
        groups[test.root_category] = [0, 0, 0]
      }
      if (test.output === 'Optimal') {
        groups[test.root_category][0]++
        green++
      } else if (
        ['Low', 'High', 'At Risk Low', 'At Risk High'].find(
          (item) => item === test.output
        )
      ) {
        groups[test.root_category][2]++
        red++
      } else {
        groups[test.root_category][1]++
        yellow++
      }
    }

    return (
      <div className="report-blood-chats">
        <div className="summary">
          {this.renderPie(green, yellow, red)}
          {this.renderSummary('Optimal', green / bloodTestResult.length, Green)}
          {this.renderSummary(
            'Concern',
            yellow / bloodTestResult.length,
            Yellow
          )}
          {!!red &&
            this.renderSummary('At Risk', red / bloodTestResult.length, Red)}
        </div>
        <div className="line" />
        <div className="bars desktop-show">
          {this.renderStack(groups, false)}
        </div>
        <div className="bars mobile-show">{this.renderStack(groups, true)}</div>
      </div>
    )
  }
}

export default ReportBloodTestCharts
