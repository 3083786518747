import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'

class FileDetail extends Component {
  static propTypes = {
    type: PropTypes.string,
    file: PropTypes.object
  }

  renderfile = () => {
    const { type, file } = this.props
    if (type === 'image') {
      return <img src={file.oss_url} alt="file" />
    } else {
      return (
        <video controls>
          <source src={file.oss_url} />
        </video>
      )
    }
  }

  render() {
    const renderFile = this.renderfile
    return (
      <Modal
        visible={true}
        onCancel={() => this.props.cancel()}
        footer={null}
        className="modal-report-file-detail has-mobile-view"
      >
        {renderFile()}
      </Modal>
    )
  }
}

export default FileDetail
