import React, { Component } from 'react'
import navagations from '../../../models/clientNavagation.model'
import { styles } from './index.sass'

import PropTypes from 'prop-types'

export class ClientSlideMenu extends Component {
  static propTypes = {
    title: PropTypes.any,
    subMenu: PropTypes.object,
    onUpdateSelectCategory: PropTypes.func,
    propNavagations: PropTypes.array,
    matchUrl: PropTypes.string,
    navagitions: PropTypes.func,
    history: PropTypes.object,
    linkTo: PropTypes.func
  }

  renderSubMenu() {
    const { subMenu, onUpdateSelectCategory } = this.props
    const { menus, select } = subMenu
    return (
      <ul className="sub-list">
        {menus.map((item, index) => (
          <li
            className={`sub-list-item ${
              select && select.id === item.id ? 'active' : ''
            }`}
            key={index}
            onClick={() => {
              onUpdateSelectCategory(item)
            }}
          >
            {item.name}
          </li>
        ))}
      </ul>
    )
  }

  render() {
    const {
      title,
      subMenu,
      propNavagations,
      matchUrl,
      navagitions,
      history,
      linkTo
    } = this.props
    const loopNavagation = propNavagations || navagations
    return (
      <div className={styles}>
        <ul className="list">
          {loopNavagation.map((item, index) => (
            <li
              className={`list-item ${(item.title === title ||
                item.id === title) &&
                'active'}`}
              key={index}
              onClick={() => {
                if (item.href) {
                  history && history.push(item.href.replace('{url}', matchUrl))
                } else {
                  navagitions && navagitions(item)
                }
                linkTo && linkTo(item.id)
              }}
            >
              <div className="item-container">
                <span>{item.name}</span>
              </div>
              <div className="sub-list-container">
                {subMenu &&
                  subMenu.parentId === item.id &&
                  this.renderSubMenu()}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default ClientSlideMenu
