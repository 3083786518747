import React, { Component } from 'react'
import _ from 'lodash'
import { message } from 'antd'
import { getQuestions, postAnswer } from '../../api/people'
import { getNutritionAnswer } from '../../api/nutrition'
import { questionnaireConfig } from '../../utils/constant'
import { checkAnswer } from '../../view/onboarding/questionnaireWrapper'

export default (WrappedComponent) =>
  class QuestionnaireWrapper extends Component {
    static propTypes = {}

    state = {
      dataLoading: true
    }

    getAnswer = (question, sectionId, answer, groupDate) => {
      if (groupDate && _.isEmpty(answer)) {
        answer = _.get(this.state.answer, `answer[${sectionId}]`)
        answer = _.find(answer, (an) => an.date === groupDate)
        answer = answer.detail
      }
      if (!answer) {
        answer = this.state.answer
        answer = answer.answer[sectionId] || {}
      }

      let answerElem = <div />
      let answerObj = answer[question.id]
      let index, choice
      switch (question.type) {
        case 'text_entry':
        case 'date':
        case 'time':
        case 'number':
          answerElem = answerObj ? <i>"{answerObj}"</i> : answerElem
          break
        case 'single_choice':
          index =
            answerObj && typeof answerObj === 'object'
              ? Object.keys(answerObj)[0]
              : undefined
          if (!isNaN(index)) {
            choice = question.choices[index]
            if (choice) {
              answerElem = (
                <span>
                  {choice.title}
                  {choice.additional_information && (
                    <>
                      : <br />
                    </>
                  )}
                  {choice.allow_text_entry && (
                    <i> - "{answerObj[index].text}"</i>
                  )}
                  {choice.additional_information &&
                    choice.additional_information.map((item, _idx) => {
                      if (answerObj[index][_idx]) {
                        return (
                          <>
                            <span key={_idx}>
                              - {item.title}: {answerObj[index][_idx]}
                            </span>
                            <br />
                          </>
                        )
                      } else {
                        return null
                      }
                    })}
                </span>
              )
            }
          } else if (answerObj) {
            answerElem = <i>"{answerObj}"</i>
          }
          break
        case 'yes_no':
          if (answerObj === false) answerElem = 'No'
          else if (answerObj === true) answerElem = 'Yes'
          break
        case 'multi_choice':
        case 'multi_choice_rank':
          const Rank = ['1ST', '2ND', '3RD', '4TH', '5TH']
          const keys = answerObj ? Object.keys(answerObj) : []
          if (keys.find((key) => answerObj[key].rank)) {
            keys.sort((a, b) => answerObj[a].rank - answerObj[b].rank)
          }
          answerElem = (
            <div>
              {answerObj &&
                Object.keys(answerObj).map((item, index) => {
                  choice = question.choices[item]
                  const subChoicesAnswerkeys = Object.keys(answerObj[item])
                  return (
                    <div key={index}>
                      {choice.title}

                      {answerObj[item] &&
                        typeof answerObj[item].rank === 'number' && (
                          <span className="ant-badge ant-badge-not-a-wrapper">
                            <sup
                              data-show="true"
                              className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                              title="109"
                              style={{
                                backgroundColor: 'rgb(82, 196, 26)',
                                marginLeft: 10
                              }}
                            >
                              {Rank[answerObj[item].rank]}
                            </sup>
                          </span>
                        )}

                      {choice.subChoices && choice.subType === 'number' ? (
                        <i key={index} className="sub-choice">
                          {' '}
                          -{' '}
                          {choice.subChoices[0].title.replace(
                            '%value',
                            `"${answerObj[item].value}"`
                          )}
                        </i>
                      ) : (
                        subChoicesAnswerkeys.map((e, index) => {
                          return (
                            choice.subChoices &&
                            choice.subChoices[e] && (
                              <i key={index} className="sub-choice">
                                {' '}
                                - "{choice.subChoices[e].title}"
                              </i>
                            )
                          )
                        })
                      )}

                      {choice.allow_text_entry && (
                        <i> - "{answerObj[item].text}"</i>
                      )}
                    </div>
                  )
                })}
            </div>
          )
          break
        case 'rank_order':
          const sortArr =
            (answerObj &&
              Object.keys(answerObj).sort(
                (a, b) => answerObj[a].rank - answerObj[b].rank
              )) ||
            []
          answerElem = (
            <div>
              {sortArr.map((item, index) => {
                choice = question.items[item]
                return (
                  <div key={index}>
                    {answerObj[item].rank}. {choice.title}
                    {choice.allow_text_entry && (
                      <i> - "{answerObj[item].text}"</i>
                    )}
                  </div>
                )
              })}
            </div>
          )
          break
        case 'level_bar':
          answerElem = answerObj
          break
        case 'question_group':
          answerElem = this.getGroupAnswer(question, answerObj, sectionId)
          break
        case 'slider':
          answerElem = answerObj ? <span>{answerObj.value}</span> : answerElem
          break
        default:
          break
      }
      return answerElem
    }

    getGroupAnswer = (question, answerObj, sectionId) => {
      if (!answerObj) return ''
      const {
        survey: { sections }
      } = this.state
      const section = sections.find((item) => item.id === sectionId)
      const subQuestion = section.questions.filter(
        (item) => item.id && item.id.toString().includes(`${question.id}_`)
      )
      return (
        <>
          {Object.keys(answerObj).map((item, index) => {
            const answer = answerObj[item]
            const subQ = subQuestion.find(
              (sub) => sub.id === `${question.id}_${item}`
            )
            return (
              <div key={index}>
                <strong>{subQ.question}: </strong>
                {subQ.type === 'text_entry' ? (
                  <i> - "{answer}"</i>
                ) : (
                  Object.keys(answer).map((an, _idx) => {
                    const option = (subQ.choices || subQ.items)[an]

                    return (
                      <div key={index}>
                        {option.title}{' '}
                        {option.allow_text_entry && (
                          <i> - "{answer[an].text}"</i>
                        )}
                      </div>
                    )
                  })
                )}
              </div>
            )
          })}
        </>
      )
    }

    getQuestions = async () => {
      const { person } = this.props
      try {
        let { answer, survey, questionnaire_sections } = await getQuestions(
          person.id
        )
        const nutritionPart = await getNutritionAnswer(person.id)
        if (!_.has(answer, 'answer')) {
          answer = { answer: {} }
        }

        _.forIn(nutritionPart.answer, (item) => {
          if (!item.detail) item.detail = {}
        })
        answer.answer[questionnaireConfig.nutritionId] = nutritionPart.answer

        let sections = []
        sections = survey.sections

        survey.sections = sections
        this.setState({
          answer,
          survey,
          questionnaire_sections,
          dataLoading: false
        })
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
        message.error(err.message)
      }
    }

    editAnswer = (currentQuestion, currentSectionId, versionDate) => {
      this.setState({
        currentQuestion,
        currentSectionId,
        versionDate
      })
    }

    updateAnswers = (qid, body) => {
      let updateAnswer = this.state.updateAnswer || {}
      if (_.isString(qid)) {
        const { versionDate, currentSectionId, answer } = this.state
        const [id, subId] = qid.split('_')
        let currentAnswer = answer.answer[currentSectionId]
        if (versionDate) {
          currentAnswer = currentAnswer.find(
            (item) => item.date === versionDate
          )
          currentAnswer = _.get(currentAnswer, `detail.${currentSectionId}`, {})
        }
        currentAnswer = _.assign(currentAnswer[id], updateAnswer[id])
        updateAnswer[id] = updateAnswer[id] || {}
        _.assign(updateAnswer[id], currentAnswer, { [subId]: body })
      } else {
        updateAnswer = { [qid]: body }
      }
      this.setState({ updateAnswer })
    }

    saveAnswer = async () => {
      const { person } = this.props
      const {
        survey,
        updateAnswer,
        currentSectionId,
        answer,
        versionDate,
        currentQuestion
      } = this.state
      this.setState({ answersUpdating: true })
      // verify
      const questions = _.get(
        _.find(survey.sections, (section) => section.id === currentSectionId),
        'questions'
      )
      const checkedResult = checkAnswer(currentQuestion, updateAnswer, {
        questions
      })
      if (_.includes(JSON.stringify(checkedResult).toLowerCase(), 'required')) {
        this.setState({
          updateAnswer: checkedResult.answers,
          answersUpdating: false
        })
      } else {
        try {
          const result = await postAnswer(
            {
              id: survey.id,
              answer: updateAnswer,
              section_id: currentSectionId,
              nutrition_date: versionDate
            },
            this.props.person.id
          )
          if (currentSectionId === 7 && versionDate) {
            // update nutrition
            const nutritionPart = await getNutritionAnswer(person.id)
            _.forIn(nutritionPart.answer, (item) => {
              if (!item.detail) item.detail = {}
            })
            result.answer.answer[questionnaireConfig.nutritionId] =
              nutritionPart.answer
          } else {
            result.answer.answer[questionnaireConfig.nutritionId] =
              answer.answer[questionnaireConfig.nutritionId]
          }
          this.setState({
            answer: result.answer,
            answersUpdating: false,
            currentQuestion: null,
            versionDate: null,
            updateAnswer: null
          })
        } catch (err) {
          console.error(err)
          this.setState({ answersUpdating: false })
        }
      }
    }

    getTargetAnswer = () => {
      const {
        currentQuestion,
        currentSectionId,
        answer,
        updateAnswer,
        versionDate
      } = this.state
      if (!currentQuestion) return null
      let targetAnswer
      if (answer.answer[currentSectionId] instanceof Array) {
        targetAnswer = answer.answer[currentSectionId].find(
          (item) => item.date === versionDate
        )
        targetAnswer = _.get(targetAnswer, `detail[${currentSectionId}]`, {})
      } else {
        targetAnswer = answer.answer[currentSectionId] || {}
      }

      if (targetAnswer && currentQuestion.type === 'question_group') {
        const result = _.cloneDeep(targetAnswer)
        _.forIn(updateAnswer, (value, key) => {
          result[key] = result[key] || {}
          _.assign(result[key], value)
        })
        return result
      } else {
        return targetAnswer && _.assign(_.cloneDeep(targetAnswer), updateAnswer)
      }
    }

    componentDidMount() {
      this.getQuestions()
    }

    render() {
      const childProps = {
        getQuestions: this.getQuestions,
        getAnswer: this.getAnswer,
        editAnswer: this.editAnswer
      }
      return (
        <WrappedComponent {...childProps} {...this.props} {...this.state} />
      )
    }
  }
