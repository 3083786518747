import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { BackTop } from 'antd'
import mapDispatchToProps from './dispatch'
import PHSHeader from '../../components/UI/header'
import ClientSlideMenu from '../../components/UI/clientSlideMenu/menu'
import ClientMicroGoals from './micro-goals'
import Dataset from './datasets'
import BloodTestDataset from './datasets/bloodTest'
import DatasetDetail from './datasets/detail'
import QuestionnaireV1 from './questionnaire'
import QuestionnaireV2, {
  QuestionnaireGroup
} from '../client/detail/questionnaireV2'
import QuestionScore from './questionnaire/score'
import ProfileOverview from './profile'
import ResourcePage from './resource'
import SummaryEmailList from './summaryEmail/list'
import SummaryEmail from './summaryEmail'
import SummaryEmailBackup from './summaryEmail/backupEmailLayout'
import NutritionScore from './nutritionScore'
import ProfileSetting from '../profile'
import SettingChangePassword from '../profile/settings/change-password'
import MessagePage from './message'
import ProgramView from './program/program'
import ProgramDetail from './program/detail'
import HealthScorePage from './healthScore'
import AssessmentReview from './assessmentReview'
import SupportTeamView from './supportTeam'
import FilePage from '../client/detail/filePage'
import Trends from './trends'
import NoMatch from '../noMatch'

import {
  getDatasetsCount,
  getSlideMenu,
  getFileAvailableTypes
} from '../../api/dataset'
import { getUnreadMessage } from '../../api/message'

import { appStyle } from './index.sass'
import { loadUser } from '../../utils/storage'
import MembershipPage from './membership'
import ExportData from '../client/detail/exportData'
import TimeLine from '../../components/client/timeline'
import SiderBarCollapse from '../../asserts/icon/sidebar-collapse.svg'
import SiderBarExpand from '../../asserts/icon/sidebar-expand.svg'
import classNames from 'classnames'
import { MicrobiomeAnalysis } from '../../pages'

class ClientApp extends Component {
  static propTypes = {}

  state = {
    navClose: true
  }

  getHrefs = () => {
    const url = '/app'
    return {
      profile: `${url}/profile`,
      micro_goals: `${url}/goals/active`,
      trends: `${url}/trends`,
      resource: `${url}/resource`,
      nutrition_score: `${url}/nutrition-score`,
      questionnaire: `${url}/questionnaire`,
      email: `${url}/summary-email`,
      health_score: `${url}/health-score`,
      export_data: `${url}/export-data`,
      membership: `${url}/membership`,
      setting: `${url}/setting`,
      message: `${url}/message`,
      program: `${url}/program`,
      ass_report: `${url}/ass-report`,
      support_team: `${url}/support-team`,
      file: `${url}/file`,
      // datasets list
      vitals: `${url}/dataset/vitals`,
      bodyCom: `${url}/dataset/body-comp`,
      boneDensity: `${url}/dataset/bone-density`,
      movementQuality: `${url}/dataset/movement-quality`,
      muscleFitness: `${url}/dataset/muscle-fitness`,
      aerobicFitness: `${url}/dataset/aerobic-fitness`,
      cognitive_health: `${url}/dataset/cognitive-health`,
      blood_test: `${url}/dataset/blood-test`,
      microbiome_analysis: `${url}/microbiome-analysis`
    }
  }

  getDatasetState = async () => {
    const count = await getDatasetsCount()
    this.setState({ count })
  }
  getMenu = async () => {
    const { setSubMenu } = this.props
    const result = await getSlideMenu()
    setSubMenu('groups', result.groups)
  }

  getFileAvailableTypes = async () => {
    const user = loadUser()
    if (user) {
      try {
        const { types } = await getFileAvailableTypes(user.id)
        this.setState({ fileAvailableTypes: types })
      } catch (error) {
        console.log(error)
      }
    }
  }
  getUnReadCount = async () => {
    const { setClientUnreadCount } = this.props

    const user = loadUser()

    const { count } = await getUnreadMessage(user.id)
    setClientUnreadCount(count)
  }

  componentDidMount() {
    const { slideMenu, setClientLeftNavigateBarHidden } = this.props
    if (!slideMenu.groups) {
      this.getMenu()
    }
    this.getDatasetState()
    this.getFileAvailableTypes()
    this.getUnReadCount()
    setClientLeftNavigateBarHidden(true)
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props
    if (nextProps.location.pathname !== prevProps.location.pathname) {
      this.getUnReadCount()
    }
  }

  render() {
    const {
      history,
      match,
      location,
      setClientBiomarkerCategories,
      setClientLeftNavigateBarHidden,
      client
    } = this.props
    const noDetailPadding =
      location.pathname.includes('/ass-report/draft') ||
      location.pathname.includes('/ass-report/preview')
    const isDetail =
      (location.pathname.match('dataset') &&
        location.pathname.split('/').length >= 6) ||
      noDetailPadding
    const hrefs = this.getHrefs()
    return (
      <div className={appStyle}>
        <PHSHeader
          hideBreadCrumb={true}
          hideSubHeader={true}
          history={history}
        />
        <div className="client-view-main-content">
          {!isDetail && (
            <div className={classNames('left', { close: this.state.navClose })}>
              <ClientSlideMenu
                location={location}
                hrefs={hrefs}
                history={history}
                // bloodTestCategories={client.categories}
                loginRole="User"
                datasetCount={this.state.count}
                fileAvailableTypes={this.state.fileAvailableTypes}
              />
              <div
                className={classNames('swift-left-btn', {
                  close: this.state.navClose
                })}
                onClick={() => {
                  setClientLeftNavigateBarHidden(!this.state.navClose)
                  this.setState({ navClose: !this.state.navClose })
                }}
              >
                <img
                  src={this.state.navClose ? SiderBarExpand : SiderBarCollapse}
                  alt="action"
                />
              </div>
            </div>
          )}
          <div className={`right ${isDetail ? 'detail-right' : ''}`}>
            <Switch>
              <Route path={`${match.url}`} exact component={ProfileOverview} />
              <Route
                path={`${match.url}/profile`}
                component={ProfileOverview}
              />
              <Route path={`${match.url}/timeline`} component={TimeLine} />
              <Route
                path={`${match.url}/goals/:type`}
                component={ClientMicroGoals}
              />
              <Route
                path={`${match.url}/summary-email/backup/:id`}
                component={SummaryEmailBackup}
              />
              <Route
                path={`${match.url}/summary-email/:id`}
                component={SummaryEmail}
              />
              <Route
                path={`${match.url}/summary-email`}
                component={SummaryEmailList}
              />
              <Route
                path={`${match.url}/nutrition-score`}
                component={NutritionScore}
              />
              <Route
                path={`${match.url}/ass-report`}
                component={AssessmentReview}
              />
              <Route
                path={`${match.url}/support-team`}
                component={SupportTeamView}
              />
              <Route path={`${match.url}/file`} component={FilePage} />
              <Route path={`${match.url}/message`} component={MessagePage} />
              <Route path={`${match.url}/trends`} component={Trends} />
              <Route
                path={`${match.url}/program/:id`}
                component={ProgramDetail}
              />
              <Route path={`${match.url}/program`} component={ProgramView} />
              <Route path={`${match.url}/resource`} component={ResourcePage} />
              <Route
                path={`${match.url}/setting/change-password`}
                component={SettingChangePassword}
              />
              <Route path={`${match.url}/setting`} component={ProfileSetting} />
              <Route
                path={`${match.url}/questionnaire/score`}
                component={QuestionScore}
              />
              <Route
                path={`${match.url}/questionnaire/group`}
                render={(props) => (
                  <QuestionnaireGroup {...props} getPerson={loadUser} />
                )}
              />
              <Route
                path={`${match.url}/questionnaire`}
                render={(props) => (
                  <QuestionnaireV2 {...props} getPerson={loadUser} />
                )}
              />
              <Route
                path={`${match.url}/questionnaire-v1`}
                component={QuestionnaireV1}
              />
              <Route
                path={`${match.url}/dataset/blood-test/:category/:datasetId/:metricId`}
                component={DatasetDetail}
              />
              <Route
                path={`${match.url}/dataset/blood-test/:datasetId/:metricId`}
                component={DatasetDetail}
              />
              <Route
                path={`${match.url}/dataset/blood-test/:category`}
                render={(props) => (
                  <BloodTestDataset
                    {...props}
                    {...{ setClientBiomarkerCategories, client }}
                  />
                )}
              />
              <Route
                path={`${match.url}/dataset/blood-test`}
                render={(props) => (
                  <BloodTestDataset
                    {...props}
                    {...{ setClientBiomarkerCategories, client }}
                  />
                )}
              />
              <Route
                path={`${match.url}/dataset/:type/:category/:datasetId/:metricId`}
                component={DatasetDetail}
              />
              <Route
                path={`${match.url}/dataset/:type/:datasetId/:metricId`}
                component={DatasetDetail}
              />
              <Route
                path={`${match.url}/dataset/:type/:category`}
                render={(props) => <Dataset {...props} />}
              />
              <Route
                path={`${match.url}/dataset/:type`}
                render={(props) => <Dataset {...props} />}
              />
              <Route
                path={`${match.url}/health-score`}
                component={HealthScorePage}
              />
              <Route
                path={`${match.url}/export-data`}
                render={() => <ExportData />}
              />
              <Route
                path={`${match.url}/membership`}
                component={MembershipPage}
              />
              <Route
                path={`${match.url}/microbiome-analysis`}
                render={(props) => <MicrobiomeAnalysis getPerson={loadUser} />}
              />
              <Route component={NoMatch} />
            </Switch>

            <BackTop />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ client, slideMenu }) => ({ client, slideMenu }),
  mapDispatchToProps
)(ClientApp)
