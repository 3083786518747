import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Modal } from 'antd'
import DataTable from '../UI/table'
import PageLoading from '../UI/status/loading'
import { EditCircleIcon, ViewCircleIcon } from '../icons/weeklySummary'
import { getEmailDetail, deleteEamilSummary } from '../../api/summaryEmail'
import Empty from '../UI/status/empty'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'
import { listStyle } from './styles/list.sass'

const { confirm } = Modal

class EmailList extends Component {
  static propTypes = {
    person: PropTypes.object,
    linkToWeeklySummary: PropTypes.func,
    loginRole: PropTypes.string
  }

  state = {
    list: [],
    loading: true
  }

  peroidColumn = [
    {
      key: 'time',
      title: 'Time Period',
      render: (text, record, index) => (
        <>
          <span className="bold">
            {moment(record.from).format('MMM DD')} -{' '}
            {moment(record.to).format('MMM DD, YYYY')}
          </span>
        </>
      )
    }
  ]

  userColumns = [
    {
      key: 'empty',
      render: () => <div style={{ width: 288 }} />
    },
    {
      title: 'Date REceived',
      key: 'sent_at',
      render: (text, record, index) =>
        record.log && record.log.sent_at ? (
          <>
            <span>{moment(record.log.sent_at).format('MMM DD, YYYY')}</span>
          </>
        ) : (
          '--'
        )
    }
  ]
  expertColumns = [
    {
      title: 'Date CREATED',
      key: 'created_at',
      render: (text, record, index) => (
        <>
          <span>
            {record.log && record.log.created_at
              ? moment(record.log.created_at).format('MMM DD, YYYY')
              : '--'}
          </span>
        </>
      )
    },
    {
      title: 'Date SEnt',
      key: 'sent_at',
      render: (text, record, index) =>
        record.log && record.log.sent_at ? (
          <>
            <span>{moment(record.log.sent_at).format('MMM DD, YYYY')}</span>
          </>
        ) : (
          '--'
        )
    },
    {
      title: 'Sent by',
      dataIndex: 'log.sent_by',
      key: 'log.sent_by',
      render: (text, record, index) => (
        <>{(record.log && record.log.sent_by) || '--'}</>
      )
    },
    // { title: 'Created by', dataIndex: 'log.created_by', key: 'log.created_by' },
    {
      title: 'Status',
      key: 'status',
      render: (text, record, index) => (
        <>
          <span className={`status-circle ${record.status}`} />
          {record.status}{' '}
          {record.status === 'Opened' &&
            record.log &&
            record.log.opened_at &&
            `on ${moment(record.log.opened_at).format('MMM DD, YYYY')}`}
        </>
      )
    }
  ]
  actionColumn = [
    {
      title: 'Actions',
      key: 'action',
      render: (text, record, index) => (
        <div className={`actions ${this.props.loginRole}`}>
          {record.status === 'Draft' ? (
            <EditCircleIcon
              onClick={() => this.linkToWeeklySummary(record, 'edit')}
            />
          ) : (
            <ViewCircleIcon
              onClick={() => this.linkToWeeklySummary(record, 'preview')}
            />
          )}
          {this.props.loginRole === 'Expert' && (
            <img
              src={DelIcon}
              alt="del"
              onClick={() => this.deleteEmailSummary(record.id)}
            />
          )}
        </div>
      )
    }
  ]

  deleteEmailSummary = (emailId) => {
    let { list } = this.state
    confirm({
      title: 'Are you sure delete this summary?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await deleteEamilSummary(emailId)
          list = list.filter((item) => item.id !== emailId)
          this.setState({ list })
        } catch (err) {
          console.error(err)
        }
      }
    })
  }

  linkToWeeklySummary = (email, action) => {
    email = email || { id: 'new' }
    this.props.linkToWeeklySummary(email, action)
  }

  initial = async () => {
    let { person } = this.props
    const list = await getEmailDetail(person.id)
    this.setState({ list, loading: false })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { list, loading } = this.state

    const { loginRole } = this.props
    const columns = this.peroidColumn
      .concat(
        loginRole === 'User' || loginRole === 'Service_Provider'
          ? this.userColumns
          : this.expertColumns
      )
      .concat(this.actionColumn)
    // .apply(
    //   ...[
    //     this.peroidColumn,
    //     loginRole === 'User' ? this.userColumns : this.expertColumns,
    //     this.actionColumn
    //   ]
    // )
    return <>
      {!loading && loginRole !== 'User' && loginRole !== 'Service_Provider' && (
        <Button
          type="primary"
          style={{
            float: 'right',
            marginTop: -70,
            position: 'relative',
            zIndex: 3
          }}
          disabled={loading}
          onClick={() => this.linkToWeeklySummary({ id: 'new' })}
        >
          <PlusOutlined /> CREATE NEW EMAIL
        </Button>
      )}
      <Divider />
      {loading ? (
        <PageLoading />
      ) : list.length > 0 ? (
        <div className={listStyle}>
          <DataTable
            columns={columns}
            dataSource={list}
            // loading={loading}
            hidePagination={true}
          />
        </div>
      ) : (
        <Empty message="No summary email yet" />
      )}
    </>
  }
}

export default EmailList
