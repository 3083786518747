import styled from 'styled-components'

export const QuestionniaresContainer = styled.div`
  position: relative;

  .page-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #3c4e5f;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .questionnaire-action-container {
    position: absolute;
    right: 0px;
    top: 9px;
    z-index: 2;
  }

  .module-container {
    background-color: white;
    border-radius: 8px;
    margin-top: 24px;
    & > div {
      border-bottom: 1px solid #e6e9f2;
    }
    & > div:last-child {
      border-bottom: none;
    }
    .group-header {
      font-size: 14px;
      letter-spacing: 0.3px;
      color: #a5abb2;
      display: flex;
      padding: 12px;
      align-items: center;
      justify-content: space-between;
      button {
        color: #264382;
        border-color: #264382;
      }
    }
    .group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      font-size: 18px;
      &:hover {
        background: #f1f3f6;
      }
      .group-name {
        margin-left: 12px;
      }
      .anticon {
        cursor: pointer;
      }
      .group-base-info {
        display: flex;
        .group-base-info-date {
          font-size: 14px;
          color: #474747;
          margin-right: 36px;
          .group-base-info-title {
            font-size: 12px;
            color: #a5abb2;
          }
          .group-base-info-content {
            min-width: 79px;
          }
        }
      }
    }
    .no-sessions {
      &:hover {
        background: white;
        cursor: not-allowed;
      }
    }
  }
`

export const QuestionnaireGroupContainer = styled.div`
  .group-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    h1 {
      font-size: 36px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: 0.3px;
      color: #474747;
    }
    button {
      margin-left: 8px;
    }
    .ant-btn {
      line-height: 1;
      padding: 8px 24px 9px;
    }
  }
  .section {
    background-color: white;
    border-radius: 8px;
  }
`

export const SendQuestionnaireModalBody = styled.div`
  .title {
    margin: 20px 0 28px 0;
  }
  .ant-segmented {
    margin-bottom: 35px;
  }
  .link-container {
    background: #f1f3f6;
    border: 1px solid #e6e9f2;
    border-radius: 12px;
    display: flex;
    img {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }
    .desc-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .link-title {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.3px;
        color: #323f4a;
        padding-left: 24px;
      }
      .link {
        font-size: 14px;
        line-height: 120%;
        letter-spacing: 0.3px;
        color: #a5abb2;
        padding-left: 24px;
      }
    }
  }
`
export const SectionV2Container = styled.div`
  border-top: 1px solid #e6e9f2;
  padding-top: 16px;
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 20px 0;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 30px;
    background: white;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    border: 1px solid #e6e9f2;
    background: #eaedf3;
    border-radius: 30px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 10px;
  }
`
