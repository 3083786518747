import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Button, message, Alert } from 'antd'
import { Trash } from '../../icons'
import WeeklySummaryWrapper from '../weeklySummaryWrapper'
import SocketWrapper from './socketWrapper'
import RecentActivity from '../comp/recentActivity'
import DateRange from '../../UI/mgComponents/dateRange'
import PageLoading from '../../UI/status/loading'

import { getAxisX } from '../common'
import ConfirmPopUp from '../../UI/extraInformation/confirm' //'./comp/confirm'
import SubjectEditor from '../comp/subjectEditor'
import SummaryEmailOverView from '../overview'
import Weight from '../weight'
import Nutrition from '../nutrition'
import Exercise from '../exercise'
import Rejuvenation from '../rejuvenation'
import ConfirmSendEmail from '../comp/confirmSendEmail'
import TextEditor from '../../UI/extraInformation/textEditor'
import { summaryStyle } from '../styles/summary.sass'

import Empty from '../../UI/status/empty'
import EmailBG from '../../../asserts/images/email-bg.png'

const green = '#7BAD2D',
  red = '#FF6B00',
  // gray = '#b7bfc6',
  normal = '#70808E'
// primary = '#383E48'

class WeeklySummaryContainer extends Component {
  static propTypes = {
    person: PropTypes.object,
    data: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      submiting: false,
      sendConfirmVisible: false,
      clearConfirmVisible: false
      // sendConfirmInfo: {
      //   title: 'Send summary confirmation',
      //   content: 'Are you sure to send this summary email to client@domain.com?',
      //   handleOk: 'SEND NOW'
      // }
    }

    this.exerciseRef = React.createRef()
    this.nutritionRef = React.createRef()
    this.rejuvenationRef = React.createRef()
    // this.overviewRef = React.createRef()
    this.weightRef = React.createRef()
  }

  getObjString(obj) {
    let result = obj
    if (typeof obj !== 'string') {
      result = JSON.stringify(obj)
    }
    return result
  }

  screenshot = async () => {
    function getUrls(elements) {
      const toCanvasTasks = elements.map((item) => {
        const { elem, key } = item
        return new Promise((resolve, rejects) => {
          window
            .html2canvas(elem, { backgroundColor: '#fff' })
            .then((canvas) => {
              /* start extra test code*/
              // const link = document.createElement('a')
              // link.download = 'text.png'
              // canvas.toBlob(function(blob) {
              //   link.href = URL.createObjectURL(blob)
              //   link.click()
              // })
              /* end extra test code */
              return resolve({ base64_data: canvas.toDataURL(), id: key })
            })
            .catch((err) => rejects(err))
        })
      })

      return Promise.all(toCanvasTasks)
    }

    try {
      console.log('try to get screenshots')

      const refsArr = [
        this.weightRef.current ? this.weightRef.current.refs : [],
        this.exerciseRef.current ? this.exerciseRef.current.refs : [],
        this.nutritionRef.current ? this.nutritionRef.current.refs : [],
        this.rejuvenationRef.current ? this.rejuvenationRef.current.refs : []
      ].map((item) => {
        return Object.keys(item).map((key) => ({ key, elem: item[key] }))
      })
      const urls = await getUrls([].concat(...refsArr))
      return urls
    } catch (err) {
      console.log('we met something wrong, pleasse try again.')
      console.error(err)
    }
  }

  preSend = async () => {
    const { submitData } = this.props
    if (!submitData.email_subject) {
      return message.error('Please type in email subject...')
    }
    this.setState({ sendConfirmVisible: true })
  }

  submit = async (type, ccList) => {
    if (this.state.submiting) {
      return
    }
    const hide = message.loading(
      `${type === 'draft' ? 'saving' : 'sending'}`,
      0
    )

    if (this.state.submiting) {
      return setTimeout(hide)
    }

    this.setState({ submiting: true })

    setTimeout(async () => {
      let urls = await this.screenshot()
      this.submitHandle(type, ccList, urls, hide)
    }, 1000)
  }

  submitHandle = async (type, ccList, urls, hide) => {
    let { submitData } = this.props
    const { EmailAction } = this.props
    let action
    switch (type) {
      case 'sent':
        action = EmailAction.sent
        break
      case 'test':
        action = EmailAction.send_test_email
        break
      default:
        action = EmailAction.save_as_draft
        break
    }
    try {
      await this.props.submit(action, null, submitData, urls, ccList)
    } catch (err) {
      this.setState({ submiting: false })
    }

    setTimeout(hide)
    if (type === 'test') {
      this.setState({ submiting: false })
    }
  }

  processNormalGoal(startDate, endDate, goal, barColor) {
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    if (tickValuesX) {
      let isYesNoGoal = false
      const result = {
        entries: tickValuesX.map((date) => {
          let specificDay = goal.details.find((detail) => {
            return date.format('YYYY-MM-DD') === detail.summary_date
          })
          if (specificDay) {
            return specificDay.value
          } else {
            return null
          }
        })
      }
      result.isYesNoGoal = isYesNoGoal
      const ranges = goal.auto_range ? goal.auto_range.split(',') : []
      const [min, max] = ranges
      let colorFunc
      if (ranges.length === 1) {
        result.target = Number(goal.auto_range)
        colorFunc = (t) => {
          if (t.y === Number(goal.auto_range)) {
            return barColor || green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Goal'
      } else if (Number(min) && Number(max)) {
        result.min = Number(min)
        result.max = Number(max)
        colorFunc = (t) => {
          if (t.y >= min && t.y <= max) {
            return barColor || green
          } else {
            return red
          }
        }
      } else if (Number(min)) {
        result.target = Number(min)
        colorFunc = (t) => {
          if (t.y >= min) {
            return barColor || green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Goal'
      } else if (Number(max)) {
        result.target = Number(max)
        colorFunc = (t) => {
          if (t.y <= max) {
            return barColor || green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Limit'
      } else {
        colorFunc = barColor || green
      }
      result.barColor = colorFunc

      result.changeColor = colorFunc

      if (
        goal.description &&
        goal.description.toLowerCase().includes('weight')
      ) {
        result.target = goal.average
        result.extraAxisText = 'Avg'
      }
      result.tickFormat = (y) => (y > 1000 ? Math.round(y / 100) / 10 + 'k' : y)
      return result
    }
  }

  updateSubject = (subject, isSave) => {
    const { setSubmitData } = this.props

    if (isSave) {
      setSubmitData({
        field: 'email_subject',
        value: subject,
        status: 'save',
        section: 'subject'
      })
    }

    this.props.changeSubject(subject)
  }

  getSectionShow = (isEditable, type, goals) => {
    const {
      submitData: {
        deleted_section,
        exercise,
        rejuvenation,
        weight_recommendation,
        nutrition_recommendation
      }
    } = this.props
    let deleteAllSection = false
    switch (type) {
      case 'rejuvenation': {
        const showSleep =
          rejuvenation.deleted_sleep_goal.length <
          rejuvenation.sleep_goals.length
        deleteAllSection =
          !showSleep &&
          !rejuvenation.recommendation &&
          deleted_section.rejuvenation_quality
        break
      }
      case 'exercise': {
        const { exercise_target, exercise_workout } = deleted_section
        deleteAllSection =
          exercise_target &&
          exercise_workout &&
          exercise.activity_goals.length === 0 &&
          !exercise.heart_recommendation
        break
      }
      case 'weight': {
        deleteAllSection = !weight_recommendation && goals.length === 0
        break
      }
      case 'nutrition': {
        deleteAllSection = !nutrition_recommendation && goals.length === 0
        break
      }
      default:
        break
    }
    return !deleteAllSection || isEditable
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.props.beforeunload)
  }

  componentWillUnmount() {
    this.props.beforeunload()
    window.removeEventListener('beforeunload', this.props.beforeunload)
  }

  renderNotFound() {
    return (
      <div>
        <Empty message="This email not found." />
      </div>
    )
  }

  renderAlert() {
    const { socket } = this.props
    const isLocked = socket.status !== 'open'
    let description, message
    if (!socket.hasReceivedLocked) {
      description = 'Requesting edit permission... '
    } else if (socket.disConnect) {
      message = 'Connection expired.'
      description = 'Please refresh and check whether data is lost.'
    } else {
      if (socket.status === 'locked_by_self') {
        message = 'You have another tab open.'
        description = (
          <div>
            Please continue editing there or close it before opening a new one.
          </div>
        )
      } else {
        message = 'Email is currently being edited.'
        description = (
          <div>
            <strong style={{ fontFamily: 'Gilroy-Bold' }}>
              {socket.emailEditor ? socket.emailEditor.full_name : 'Someone'}
            </strong>{' '}
            is currently editing this page, please come back later.
          </div>
        )
      }
    }
    return (
      <>
        {isLocked && (
          <Alert
            style={{ marginBottom: 20 }}
            message={message}
            description={description}
            type="warning"
            showIcon
            closable
          />
        )}

        {socket.accessed && (
          <Alert
            style={{ marginBottom: 20 }}
            // title="Email locked"
            description="You have obtained edit permission"
            type="success"
            showIcon
            closable
          />
        )}
      </>
    )
  }

  renderFooter(isLocked) {
    const { submiting } = this.state
    const {
      submitData,
      editingTextNumber,
      changedSubject,
      originInformation,
      allowEmailNotification
    } = this.props
    const disSend =
      !allowEmailNotification ||
      submiting ||
      editingTextNumber > 0 ||
      (changedSubject && changedSubject !== submitData.email_subject)
    return (
      <footer>
        <div />
        <div>
          <Button
            type="primary"
            ghost
            disabled={disSend || isLocked}
            onClick={() => this.submit('test')}
          >
            SEND TEST EMAIL
          </Button>

          <Button
            type="primary"
            disabled={disSend || isLocked}
            onClick={this.preSend}
          >
            {!originInformation ||
            (originInformation.status !== 'Sent' &&
              originInformation.status !== 'Opened')
              ? 'Send'
              : 'Resend'}
          </Button>
        </div>
      </footer>
    )
  }

  render() {
    const {
      showDate,
      startDate,
      endDate,
      hideArrow,
      prevDate,
      nextDate,
      loading,
      clearAllChanges,
      originInformation,
      data,
      person,
      experts,
      changeLog,
      statisticsLog,
      // ouraRingConnected,
      deleteNutrition,
      deleteWeight,
      oprRejuvenation,
      oprExercise,
      setSubmitData,
      submitData,
      deleteSections,
      loginRole,
      templates,
      updateEditingCount,
      updateEmailAction,
      handleUndo,
      emailNotFound,
      programData,
      socket
    } = this.props

    if (emailNotFound) return this.renderNotFound()

    const {
      clearConfirmVisible,
      sendConfirmVisible,
      showEditingError,
      abnormalMessage
    } = this.state

    const { overview, nutrition, deleted_section, deleted_nutritions } =
      submitData || statisticsLog

    const goals =
      (data &&
        data.nutrition.map((item) => {
          item.barProps = this.processNormalGoal(
            startDate,
            endDate,
            item,
            false
          )
          const target = nutrition.find((goal) => goal.id === item.id)
          if (target) {
            target.description = item.description
            return Object.assign(item, target)
          }
          item.changeColor = item.auto_range
            ? item.barProps.changeColor({ y: item.average })
            : normal
          return item
        })) ||
      []

    const nutritionGoals = goals.filter(
      (item) =>
        !deleted_nutritions.find((id) => id === item.id) &&
        !item.description.toLowerCase().includes('weight')
    )

    const weightGoals = goals.filter(
      (item) =>
        !overview.deleted_weight.find((id) => id === item.id) &&
        item.description.toLowerCase().includes('weight')
    )

    let isEditable =
      !originInformation ||
      (originInformation.status !== 'Sent' &&
        originInformation.status !== 'Opened')

    const isLocked = socket && socket.status !== 'open'

    return loading ? (
      <PageLoading />
    ) : (
      <div className={`${summaryStyle} ${loginRole}`}>
        {loginRole !== 'User' && (
          <DateRange
            {...{
              hideArrow: isEditable && !isLocked ? hideArrow : 'left, right',
              showDate,
              prevDate,
              nextDate
            }}
          />
        )}

        {loginRole === 'User' && (
          <h1 className="title">{`${startDate &&
            startDate.format('MMM DD')} - ${endDate &&
            endDate.format('MMM DD, YYYY')}`}</h1>
        )}
        {loginRole === 'User' && <Divider style={{ marginTop: 0 }} />}

        {socket && this.renderAlert()}

        {socket && showEditingError && (
          <div style={{ paddingBottom: 20 }}>
            <Alert
              description={abnormalMessage}
              type="error"
              showIcon
              closable
            />
          </div>
        )}

        <div className="email-content">
          <div style={{ opacity: isLocked ? 0.7 : 1 }}>
            {loginRole !== 'User' && ( //isEditable &&
              <SubjectEditor
                disabled={!isEditable || isLocked}
                value={submitData.email_subject}
                updateValue={this.updateSubject}
                updateEditingCount={updateEditingCount}
                updateEmailAction={updateEmailAction}
              />
            )}

            <div className="main-container">
              <div className="section-container">
                {loginRole === 'User' && (
                  <img className="bg-image" src={EmailBG} alt="background" />
                )}
                <div className="summary-date">
                  {!isEditable &&
                    (submitData.date_range ||
                      `${startDate && startDate.format('MMM DD')} - ${endDate &&
                        endDate.format('MMM DD')}`)}
                </div>
                {!isEditable || isLocked ? (
                  <h3 className="Overview">
                    {submitData.overview.title ||
                      'Let’s take a look at your progress'}
                  </h3>
                ) : (
                  <TextEditor
                    {...{
                      limit: 50,
                      updateEditingCount,
                      loginRole,
                      updateEmailAction,
                      updateCommend: (value, status) =>
                        setSubmitData({
                          part: 'overview',
                          field: 'title',
                          value,
                          status,
                          section: 'Title'
                        }),
                      renderResult: (value) => (
                        <h3 className="Overview">{value}</h3>
                      ),
                      disDeletable: true,
                      initialValue:
                        submitData.overview.title ||
                        'Let’s take a look at your progress'
                    }}
                  />
                )}

                <SummaryEmailOverView
                  goals={goals}
                  {...this.props}
                  isEditable={isEditable}
                  isLocked={isLocked}
                  // ref={this.overviewRef}
                />
              </div>
              {/* (ouraRingConnected || (data && data.sleep.details)) && */}
              {!deleted_section.rejuvenation &&
                this.getSectionShow(isEditable, 'rejuvenation') && (
                  <div className="section-container">
                    <h3 className="Rejuvenation">
                      Rejuvenation
                      {isEditable && !isLocked && (
                        <Trash onClick={() => deleteSections('rejuvenation')} />
                      )}
                    </h3>
                    <Rejuvenation
                      ref={this.rejuvenationRef}
                      processNormalGoal={this.processNormalGoal}
                      {...{
                        isLocked,
                        isEditable,
                        startDate,
                        endDate,
                        data,
                        setSubmitData,
                        originInformation,
                        submitData,
                        deleteSections,
                        oprRejuvenation,
                        loginRole,
                        templates,
                        updateEditingCount,
                        updateEmailAction
                      }}
                    />
                  </div>
                )}
              {!deleted_section.exercise &&
                data &&
                this.getSectionShow(isEditable, 'exercise') && (
                  <div className="section-container">
                    <h3 className="Exercise">
                      Exercise
                      {isEditable && !isLocked && (
                        <Trash onClick={() => deleteSections('exercise')} />
                      )}
                    </h3>
                    <Exercise
                      ref={this.exerciseRef}
                      {...{
                        isLocked,
                        isEditable,
                        startDate,
                        endDate,
                        data,
                        setSubmitData,
                        originInformation,
                        deleteSections,
                        submitData,
                        oprExercise,
                        loginRole,
                        templates,
                        updateEditingCount,
                        updateEmailAction,
                        programData
                      }}
                    />
                  </div>
                )}

              {!deleted_section.weight &&
              this.getSectionShow(isEditable, 'weight', weightGoals) ? (
                <div className="section-container">
                  <h3 className="Weight">
                    Body Weight
                    {isEditable && !isLocked && (
                      <Trash onClick={() => deleteSections('weight')} />
                    )}
                  </h3>
                  <Weight
                    ref={this.weightRef}
                    goals={weightGoals}
                    {...{
                      isLocked,
                      isEditable,
                      originInformation,
                      startDate,
                      endDate,
                      deleteWeight,
                      setSubmitData,
                      submitData,
                      loginRole,
                      templates,
                      updateEditingCount,
                      updateEmailAction
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
              {!deleted_section.nutrition &&
              this.getSectionShow(isEditable, 'nutrition', nutritionGoals) ? (
                <div className="section-container">
                  <h3 className="Nutrition">
                    Nutrition
                    {isEditable && !isLocked && (
                      <Trash onClick={() => deleteSections('nutrition')} />
                    )}
                  </h3>
                  <Nutrition
                    ref={this.nutritionRef}
                    goals={nutritionGoals}
                    {...{
                      isLocked,
                      isEditable,
                      originInformation,
                      startDate,
                      endDate,
                      deleteNutrition,
                      setSubmitData,
                      submitData,
                      loginRole,
                      templates,
                      updateEditingCount,
                      updateEmailAction
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {loginRole !== 'User' && (
            <RecentActivity
              {...{
                startDate,
                endDate,
                changeLog,
                person,
                statisticsLog,
                handleUndo
              }}
            />
          )}
        </div>
        {loginRole !== 'User' && this.renderFooter(isLocked)}

        {sendConfirmVisible && (
          <ConfirmSendEmail
            {...{
              person: person,
              experts,
              handleCancel: () => this.setState({ sendConfirmVisible: false }),
              handleOk: (ccList) => {
                this.setState({ sendConfirmVisible: false })
                this.submit('sent', ccList)
              }
            }}
          />
        )}
        <ConfirmPopUp
          {...{
            title: 'Clear all changes confirmation',
            content:
              'All changes you made so far will not be saved. Are you sure you want to continue?',
            okText: 'CLEAR',
            handleCancel: () => this.setState({ clearConfirmVisible: false }),
            handleOk: () => {
              this.setState({ clearConfirmVisible: false })
              clearAllChanges()
            }
          }}
          visible={clearConfirmVisible}
        />
      </div>
    )
  }
}

export default WeeklySummaryWrapper(SocketWrapper(WeeklySummaryContainer))
