import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Upload, Progress, Button } from 'antd'
import axios from 'axios'
import ButtonIcon from '../../../asserts/images/upload-image-button.svg'

export class Dragger extends Component {
  static propTypes = {
    uploadIcon: PropTypes.any,
    upload: PropTypes.func
  }

  state = {
    source: null,
    uploading: false,
    percent: 0,
    failed: false
  }

  upload = request => {
    const { file } = request
    const { upload } = this.props
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    this.setState({ uploading: true, source })
    try {
      upload(
        file,
        percent => {
          this.setState({ percent })
          // if (percent === 100) {
          //   this.setState({ uploading: false })
          // }
        },
        source
      )
    } catch (err) {
      this.setState({ failed: true })
    }
  }

  cancel = event => {
    event.stopPropagation()
    const { source } = this.props
    source && source.cancel()
    this.setState({ uploading: false, percent: 0 })
  }

  render() {
    const { uploadIcon } = this.props
    const { percent, uploading, failed } = this.state
    return (
      <Upload.Dragger showUploadList={false} customRequest={this.upload}>
        {uploading ? (
          <div className="uploading">
            <Progress
              percent={percent}
              status={failed ? 'exception' : 'active'}
              showInfo={false}
            />
            {!failed && <>uploading... {percent}%</>}
            {percent < 100 && (
              <Button type="primary" ghost onClick={this.cancel}>
                Cancel
              </Button>
            )}
            {failed && (
              <>
                Somthing has errored
                <Button
                  style={{ marginLeft: 10 }}
                  type="primary"
                  ghost
                  onClick={() =>
                    this.setState({ uploading: false, failed: false })
                  }
                >
                  Retry
                </Button>
              </>
            )}
          </div>
        ) : (
          <img src={uploadIcon || ButtonIcon} alt="upload" />
        )}
      </Upload.Dragger>
    )
  }
}

export default Dragger
