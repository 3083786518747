import * as actionType from './action-type'
import initialState from './initial-state'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.SET_SENDBIRD_USER_LIST:
      return {
        ...state,
        users: payload.users
      }
    case actionType.SET_SENDBIRD_CONNECT_STATE:
      return {
        ...state,
        isConnect: payload.isConnect
      }
    case actionType.SET_SENDBIRD_CLIENT_USER_LIST:
      return {
        ...state,
        clientUsers: payload.clientUsers
      }
    case actionType.SET_PROFILE_SENDBIRD:
      return {
        ...state,
        profileSB: payload.profileSB
      }
    case actionType.SET_CLIENT_SENDBIRD:
      return {
        ...state,
        clinetSB: payload.clinetSB
      }
    default:
      return state
  }
}
