import React from 'react'

import TrendsContainer from '../../components/trends'
import { loadUser } from '../../utils/storage'

export default function Trends(props) {
  const person = loadUser()

  return (
    <>
      <h1 className="title" style={{ paddingTop: 40 }}>
        Trends
      </h1>
      {person && <TrendsContainer person={person} />}
    </>
  )
}
