import React from 'react'
import SimpleHeader from '../../components/UI/header/simpleClientHeader'
import SignedForms from '../../components/business/setting/signedForms'

export default function WaiverFormRedirection(props) {
  const { history } = props
  function toWaiver(step) {
    let url = '/onboarding/waiver?callback=/app/setting'
    if (step) {
      url += `&step=${step}`
    }
    history.push(url)
  }

  function moreForms(type) {
    let step = 0
    switch (type) {
      case 'Medical Release Form':
        step = 0
        break
      case 'Liability Release Form':
        step = 1
        break
      case 'Membership Agreement':
        step = 2
        break
      default:
        break
    }
    history.push(
      `/onboarding/waiver?step=${step}&callback=/redirect-sign-waiver-form`
    )
  }

  return (
    <div style={{ paddingTop: 200 }}>
      <SimpleHeader history={history} />
      <SignedForms toWaiver={toWaiver} moreForms={moreForms} />
    </div>
  )
}
