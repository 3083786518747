import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BasicForm from './index'
import { Checkbox, Radio } from 'antd'
import { range, selectRange } from '../../models/adminEditor.form'

const fieldsOfRangeAgeNumber = [
  'male_min',
  'male_max',
  'female_min',
  'female_max',
  'male_color',
  'female_color'
]
const fieldsOfRangeAgeOption = [
  'male_output',
  'male_color',
  'female_output',
  'female_color'
]
class RangeForm extends Component {
  static propTypes = {
    refkey: PropTypes.string
  }

  state = {
    showGender: 'male',
    showAgeTag: 'A'
  }

  getRangeFieldsValue = async () => {
    const nameObj = await this.nameRef.getFieldsValue()
    const extraObj = await this.extraObj.getFieldsValue()
    return {
      ...nameObj,
      ...extraObj
    }
  }

  postRangeValue = async () => {
    const { value, valueType } = this.props
    try {
      const nameObj = await this.nameRef.handleSubmit()
      const extraObj = await this.extraObj.handleSubmit()
      let female_age_scope = {},
        male_age_scope = {}
      for (const key in this.refObj) {
        if (this.refObj[key]) {
          const tag = key.split('-')[1]
          const valueObj = await this.refObj[key].handleSubmit()
          const {
            male_min,
            male_max,
            female_min,
            female_max,
            male_color,
            female_color,
            male_output,
            female_output
          } = valueObj

          if (valueType === 'number') {
            if (male_min || male_max || male_color) {
              male_age_scope[tag] = [
                male_min || null,
                male_max || null,
                male_color || 'yellow'
              ]
            }
            if (female_min || female_max || female_color) {
              female_age_scope[tag] = [
                female_min || null,
                female_max || null,
                female_color || 'yellow'
              ]
            }
          } else {
            if (male_output || male_color) {
              male_age_scope[tag] = [
                null,
                male_output || null,
                male_color || 'yellow'
              ]
            }

            if (female_output || female_color) {
              female_age_scope[tag] = [
                null,
                female_output || null,
                female_color || 'yellow'
              ]
            }
          }
        }
      }
      return {
        ...nameObj,
        ...extraObj,
        female_age_scope,
        male_age_scope,
        is_optimal: value.is_optimal || false,
        is_threshold: value.is_threshold || false,
        is_option: valueType !== 'number',
        order: value.order
      }
    } catch (err) {
      throw new Error('Form validate error')
    }
  }

  getFormValue = (ageTag) => {
    const { valueType, value } = this.props

    const isNumber = valueType === 'number'
    const fieldsOfRangeAge = isNumber
      ? fieldsOfRangeAgeNumber
      : fieldsOfRangeAgeOption
    const range = {}
    fieldsOfRangeAge.forEach((k) => {
      if (k) {
        const [gender, v] = k.split('_')
        range[k] = value[`${gender}_${ageTag}_${v}`]
      }
    })

    return range
  }

  UNSAFE_componentWillMount() {
    const { refkey, ageScopes } = this.props
    if (!this.refObj) {
      this.refObj = {}
      for (let age in ageScopes) {
        if (age) {
          this.refObj[`${refkey}-${age.tag}`] = null
        }
      }
    }
  }

  componentDidMount() {
    this.props.wrappedComponentRef(this)
  }

  render() {
    const {
      refkey,
      ageScopes,
      changeOptimal,
      changeThreshold,
      value,
      valueType,
      // type,
      updateFormsData
    } = this.props
    const { showGender, showAgeTag } = this.state
    return (
      <>
        {changeOptimal && (
          <div className="optimal-judge">
            <Checkbox
              checked={value.is_optimal}
              onChange={(e) => changeOptimal(e.target.checked)}
            >
              This is Optimal {valueType === 'number' ? 'range' : 'output'}
            </Checkbox>
            {valueType === 'number' && (
              <Checkbox
                checked={value.is_threshold}
                onChange={(e) => changeThreshold(e.target.checked)}
              >
                This is Threshold range
              </Checkbox>
            )}
          </div>
        )}

        <BasicForm
          wrappedComponentRef={(form) => {
            if (form) {
              this.nameRef = form
            }
          }}
          formData={[
            {
              type: 'input',
              label: valueType === 'number' ? 'Name' : 'Output',
              dbField: 'name',
              rules: [
                { required: true, message: 'This field is required' },
                { max: 50, message: 'The max lengtn is 50' }
              ]
            }
          ]}
          onChange={(changedValue, form) => {
            updateFormsData(changedValue)
          }}
          initialValues={{ name: value.name }}
        />

        <Radio.Group
          className="form-radios optimal-judge"
          onChange={(e) => this.setState({ showGender: e.target.value })}
          value={showGender}
          buttonStyle="solid"
        >
          <Radio.Button value="male">MALE</Radio.Button>
          <Radio.Button value="female">FEMALE</Radio.Button>
        </Radio.Group>
        <br />
        <Radio.Group
          className="form-radios optimal-judge"
          onChange={(e) => this.setState({ showAgeTag: e.target.value })}
          value={showAgeTag}
          buttonStyle="solid"
        >
          {ageScopes.map((item, index) => (
            <Radio.Button value={item.tag} key={index}>
              {item.label}
            </Radio.Button>
          ))}
        </Radio.Group>

        {ageScopes.map((item) => (
          <div
            style={{
              display: showAgeTag === item.tag ? 'block' : 'none'
            }}
            key={`${refkey}-${item.tag}`}
          >
            <BasicForm
              wrappedComponentRef={(form) => {
                if (form) {
                  this.refObj[`${refkey}-${item.tag}`] = form
                }
              }}
              formData={
                valueType === 'number'
                  ? range(showGender)
                  : selectRange(showGender)
              }
              initialValues={this.getFormValue(item.tag) || {}}
            />
          </div>
        ))}

        <BasicForm
          wrappedComponentRef={(form) => {
            if (form) {
              this.extraObj = form
            }
          }}
          formData={[
            {
              type: 'richText',
              label: 'Health effect',
              dbField: 'health_effect'
            },
            {
              type: 'richText',
              label: 'Life suggestion',
              dbField: 'life_suggestion'
            }
          ]}
          initialValues={{
            health_effect: value.health_effect,
            life_suggestion: value.life_suggestion
          }}
        />
      </>
    )
  }
}

export default RangeForm
