import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Button } from 'antd'
import BasicForm from '../formV4'
import {
  getExperts,
  sendMembershipEmail,
  sendWelcomeEmail
} from '../../api/team'
import PageLoading from '../UI/status/loading'
import _ from 'lodash'
import { loadUser } from '../../utils/storage'

function getFormData(experts) {
  return [
    {
      type: 'select',
      options: experts.map((expert) => {
        return {
          label: expert.first_name + ` (${expert.email})`,
          value: expert.email
        }
      }),
      dbField: 'from',
      label: 'from',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'subject',
      dbField: 'subject',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'richText',
      extraAttr: {
        toolbarHidden: true
      },
      dbField: 'content',
      rules: [{ required: true, message: 'Please input email content' }]
    }
  ]
}

class WelcomeEmail extends Component {
  static propTypes = {
    onCancel: PropTypes.func,
    person: PropTypes.object,
    experts: PropTypes.array
  }

  state = {
    loading: false,
    pageloading: true,
    valueForm: {},
    experts: this.props.experts,
    isMembership: this.props.isMembership || false
  }

  sendEmail = async () => {
    const { onCancel, person, isMembership, onSend } = this.props
    this.setState({ loading: true })
    try {
      const params = await this.refForm.handleSubmit()
      if (isMembership) {
        const { experts } = this.state
        const seller = experts.find((e) => e.email === params.from).first_name
        params.seller_admin = seller
        await sendMembershipEmail(params, person.id)
      } else {
        await sendWelcomeEmail(params, person.id)
      }
      message.success('Success!')
      onCancel()
      this.setState({ loading: false })
      if (isMembership) {
        onSend()
      }
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  getTemplete = (from) => {
    const { person } = this.props
    const { isMembership, experts } = this.state
    const env = process.env.REACT_APP_WORKFLOW
    const signInLink =
      env === 'production'
        ? 'https://app.apeiron.life/sign-in'
        : 'https://app-staging.apeiron.life/sign-in'
    if (isMembership) {
      const seller = experts.find((e) => e.email === from).first_name
      return `<p>Hi ${person.first_name},</p>
      <p>We are very excited to have you join Apeiron Life! To get started, please use your new Apeiron Life account to sign up for your membership. This account will also keep all of your Apeiron Life reports, trends and guidance in one secure place.</p>
      <p>You can access your account through the web or iOS app <a href="${signInLink}" _blank="target" style="text-decoration: underline">here</a>. Please log in from your computer or iPhone using the following credentials:</p>
      <p>Username: ${person.email}<br>Password: ${_.upperFirst(
        person.first_name
      )}password (please note that first letter is in CAPS)</p>
      <p>Once you log into your account, you will be prompted to change your password for security purposes. Then, you can proceed with membership selection and our accounting team will invoice you.</p>
      <p>Please let us know if you have any questions.</p>
      <p>Thanks!${seller ? ` ${seller}` : ''}</p>`
    } else {
      const admin = experts.find((e) => e.email === from).first_name
      return `<p>Hi ${(person.profile && person.profile.nickname) ||
        person.first_name},</p>
      <p>Nice to meet you through ${person.profile &&
        person.profile
          .seller_admin}! Welcome on board! We are very excited to have you join the Apeiron Life program.</p>
      <p>To get started with your physical evaluation, please login to your account <a href="${signInLink}" _blank="target" style="text-decoration: underline">here</a></p>
      <p>Username: ${person.email}</p>
      <p>Once you log into your account, you will be prompted to complete our pre-assessment medical review, sign two waivers, and fill out a short questionnaire so we can get to know you more. Please complete this process in the next day or two so we can continue your assessment process.</p>
      <p>In the meantime, we will coordinate the scheduling of your blood draw and 360 Physical Evaluation appointments separately.</p>
      <p>Thanks!${admin ? `<br>${admin}` : ''}</p>`
    }
  }

  updateValueForm = async (changedValues) => {
    if (!this.refForm) return
    if (changedValues.from) {
      const { valueForm } = this.state
      const template = this.getTemplete(changedValues.from)
      valueForm.content = template
      this.refForm.setFieldValue('content', template)
      this.setState({
        valueForm: _.cloneDeep(valueForm)
      })
    }
  }

  getExperts = async () => {
    const result = await getExperts()
    this.setState({ experts: result, pageloading: false })
    this.initial()
  }

  initial = () => {
    const env = process.env.REACT_APP_WORKFLOW
    const loggedInUser = loadUser();
    const from = loggedInUser ? loggedInUser.email : ""
    const template = this.getTemplete(from)
    this.setState({
      valueForm: {
        content: template,
        subject: 'Welcome to Apeiron Life!',
        from
      }
    })
  }

  componentDidMount() {
    const { experts } = this.state
    if (experts && experts.length > 0) {
      this.setState({ pageloading: false })
      this.initial()
    } else {
      this.getExperts()
    }
  }

  render() {
    const { onCancel, isMembership } = this.props
    const { loading, valueForm, pageloading, experts } = this.state
    return (
      <Modal
        open={true}
        onCancel={onCancel}
        className="phs-modal client-creation"
        width={'-'}
        footer={
          <>
            <Button onClick={onCancel}>CANCEL</Button>
            <Button onClick={this.sendEmail} type="primary" loading={loading}>
              SEND
            </Button>
          </>
        }
      >
        <h1 className="title">
          {isMembership
            ? 'Send Membership Selection Page'
            : 'Send Welcome Email'}
        </h1>
        {pageloading ? (
          <PageLoading />
        ) : (
          valueForm.content && (
            <BasicForm
              wrappedComponentRef={(inst) => (this.refForm = inst)}
              initialValues={valueForm}
              formData={getFormData(experts)}
              onChange={this.updateValueForm}
            />
          )
        )}
      </Modal>
    )
  }
}

export default WelcomeEmail
