import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, message, Checkbox, Row, Col } from 'antd'
import { updateExperts } from '../../../../api/facility'
import './index.sass'

class ExpertModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    service: PropTypes.object,
    facility: PropTypes.object,
    experts: PropTypes.array,
    submitSync: PropTypes.func,
    closeModal: PropTypes.func
  }

  state = {
    loading: false,
    selected: []
  }

  confirm = async () => {
    const { service, facility, submitSync, closeModal } = this.props
    const { selected } = this.state
    this.setState({ loading: true })
    try {
      const { experts } = await updateExperts(facility.id, service.id, {
        expert_ids: selected
      })
      service.experts = experts
      const index = facility.services.findIndex(
        (item) => item.id === service.id
      )
      facility.services.splice(index, 1, service)
      this.setState({ loading: false })
      submitSync(facility)
      message.success('Experts updated!')
      closeModal()
    } catch (error) {
      console.error(error)
      this.setState({ loading: false })
    }
  }

  onChange = (expert) => {
    let { selected } = this.state
    const selectedId = selected.find((id) => id === expert.id)
    if (selectedId) {
      selected = selected.filter((id) => id !== selectedId)
    } else {
      selected.push(expert.id)
    }
    this.setState({ selected })
  }

  componentDidMount() {
    const { service } = this.props
    const selected = (service && service.experts.map((item) => item.id)) || []
    this.setState({ selected })
  }

  render() {
    const { visible, experts, closeModal } = this.props
    const { loading, selected } = this.state
    return (
      <Modal
        visible={visible}
        className="phs-modal experts-modal"
        onCancel={closeModal}
        footer={
          <>
            <Button onClick={() => closeModal()}>CANCEL</Button>
            <Button onClick={this.confirm} type="primary" loading={loading}>
              CONFIRM
            </Button>
          </>
        }
      >
        <Row gutter={18} className="experts-list">
          {experts.map((item, index) => (
            <Col span={12} key={index}>
              <Checkbox
                onChange={() => this.onChange(item)}
                checked={selected.find((id) => id === item.id)}
              >
                <div className="avatar">
                  {item.avatar && item.avatar.oss_url && (
                    <img src={item.avatar.oss_url} alt="avatar" />
                  )}
                </div>
                <span className="name">{`${(item.profile &&
                  item.profile.nickname) ||
                  item.first_name} ${item.last_name}`}</span>
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Modal>
    )
  }
}

export default ExpertModal
