import React from 'react'
import { Popover, Divider } from 'antd'
import styled from 'styled-components'
import { Pluralize } from '../../../utils/common'
import stripeBg from '../../../asserts/images/Stripe-line.png'
// import gradientBg from '../../../asserts/images/Gradient-line.png'
import moment from 'moment'
import { getColor } from './healthyCompactView'

const Container = styled.div`
  padding-right: 52px;
  position: relative;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    &:last-child {
      padding: 0;
    }
  }
  .range-bar {
    color: #c0c7d1;
    font-size: 12px;
    width: 358px;
    display: flex;
    justify-content: space-between;
  }
  .range-gride {
    position: absolute;
    right: 52px;
    top: -10px;
    .bar-gride {
      width: 358px;
      display: flex;
      justify-content: space-between;
      .ant-divider-vertical {
        height: 115px;
        border-color: #c9c9c9;
        margin: 0;
        border-style: dashed;
        border-width: 1px;
      }
    }
  }
`

// const ranges = [0, 25, 50, 100, 200, 300, 400, 500]

export default function HealthyDataView(props) {
  let { data, barLabels, target } = props // hightestValue
  data = data || {}
  const hightestValue = Math.max(target, data.seg_pct, data.more_seg_pct_avg)
  const step = Math.round((hightestValue * 1.25) / 3)
  const ranges = [0, step, step * 2, step * 3]
  const bars = [
    {
      label: barLabels[0],
      widthReference: 'seg_pct',
      seg_pct: data.seg_pct,
      workouts: data.seg_workouts,
      target,
      hightestValue
    },
    {
      label: barLabels[1],
      widthReference: 'more_seg_pct_avg',
      seg_pct: data.more_seg_pct_avg,
      hightestValue
    }
  ]
  return (
    <Container>
      <div className="range-gride">
        <div />
        <div className="bar-gride">
          {ranges.map((_, index) => (
            <Divider type="vertical" key={index} />
          ))}
        </div>
      </div>
      {bars.map((bar, index) => (
        <HealthySegment {...bar} key={index} data={data} ranges={ranges} />
      ))}
      <div>
        <div />
        <div className="range-bar">
          {ranges.map((value, index) => (
            <span key={index}>{value}</span>
          ))}
        </div>
      </div>
    </Container>
  )
}

export const getGradientRanges = (target) => {
  const gray = '#BBCCDE',
    blue = '#2F8CE9',
    green = '#A7C22A',
    yellow = '#FFC402'
  if (target <= 100) {
    return [
      { color: gray, offset: 0 },
      { color: blue, offset: 1 }
    ]
  } else if (target <= 200) {
    return [
      { color: blue, offset: 0 },
      { color: green, offset: 1 }
    ]
  } else {
    return [
      { color: blue, offset: 0 },
      { color: green, offset: 0.7098 },
      { color: yellow, offset: 1 }
    ]
  }
}

function SEGPCT(props) {
  const {
    width,
    hidePCTText,
    text: pct,
    target,
    targetWidth,
    showTargetLine,
    hightestValue
  } = props
  let x = (358 * Number(width.replace('%', ''))) / 100
  let targetX = 0
  if (targetWidth) {
    targetX = Math.round((358 * Number(targetWidth.replace('%', ''))) / 100) - 5
  }

  let textAnchor = 'start'

  const pctColor = getColor(pct, hightestValue),
    targetColor = getColor(target, hightestValue)

  const pctTextWidth = pct > 99 ? 35 : pct > 9 ? 25 : 16
  const deviation = !(targetX + 10 < x || targetX > x + pctTextWidth)
  const linearRanges = getGradientRanges(hightestValue)

  return (
    <svg
      width="400" //"358"
      height="70"
      viewBox="0 0 400 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!hidePCTText && pct > 0 && (
        <>
          {targetX > x && (
            <rect width={pctTextWidth} height="14" x={x} y={18} fill="white" />
          )}
          <text
            textAnchor={textAnchor}
            fontSize={14}
            x={x}
            y="30"
            fill={pctColor}
            transform="translate(5, 0)"
          >
            {pct}
          </text>
        </>
      )}
      <rect width="2" height="14" x={x - 2} y={18} fill={pctColor} />

      {targetWidth &&
        (showTargetLine ? (
          // <line
          //   x1={targetX + 4}
          //   y1="34"
          //   x2={targetX + 4}
          //   y2="14"
          //   stroke={targetColor}
          //   strokeWidth="2"
          //   strokeLinecap="round"
          //   strokeDasharray="4 4"
          // />
          <g
            // transform={`translate(0, ${deviation ? 0 : 20})`}
            transform={'translate(0, 20)'}
            fill={target <= pct && !deviation ? 'white' : targetColor}
          >
            <circle cx={5 + targetX} cy="5" r="5" />
            <circle
              cx={4.99998 + targetX}
              cy="4.99998"
              r="3.4"
              fill={target <= pct && !deviation ? targetColor : 'white'}
            />
            <circle cx={5 + targetX} cy="5" r="2" />
          </g>
        ) : (
          <g
            transform={`translate(0, ${deviation ? 0 : 20})`}
            fill={target <= pct && !deviation ? 'white' : targetColor}
          >
            <text
              x={targetX + 5}
              y={-10}
              textAnchor={targetX + 5 > 32 ? 'middle' : 'start'}
              fontSize={11}
              fill="#52606C"
            >
              Target: {target}
            </text>
            <circle cx={5 + targetX} cy="5" r="5" />
            <circle
              cx={4.99998 + targetX}
              cy="4.99998"
              r="3.4"
              fill={target <= pct && !deviation ? targetColor : 'white'}
            />
            <circle cx={5 + targetX} cy="5" r="2" />
          </g>
        ))}

      <defs>
        <linearGradient id="paint0_linear_48924_37147">
          {linearRanges.map(({ color, offset }, index) => (
            <stop stopColor={color} offset={offset} key={index} />
          ))}
        </linearGradient>
      </defs>
    </svg>
  )
}

const PopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #5b6177;
  font-size: 10px;
  font-weight: 600;
  .score {
    width: 46px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin-left: 32px;
    border-radius: 100%;
    background: #f6f6f7;
    color: #3d4358;
    font-size: 17px;
    font-weight: 700;
    font-family: 'Inter';
  }
  .name {
    margin-bottom: 10px;
    span {
      color: #94a3af;
      font-size: 8px;
    }
  }
  .info {
    display: flex;
    & > div {
      min-width: 42px;
      margin-right: 15px;
    }
    span {
      color: #94a3af;
      font-size: 8px;
    }
  }
`

const getGradient = (target) => {
  if (target <= 100) {
    return '90deg, #BBCCDE 0%, #2F8CE9 100%'
  } else if (target <= 200) {
    return '90deg, #2F8CE9 0%, #A7C22A 100%'
  } else {
    return '90deg, #2F8CE9 0%, #A7C22A 70.98%, #FFC402 100%'
  }
}
const SegmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  color: #5b6177;
  font-size: 10px;
  font-weight: 500;
  .bar-container {
    width: 358px;
    height: 8px;
    position: relative;
    & > div {
      width: 100%;
      height: 8px;
    }
    .stripe-bg {
      background: url(${stripeBg});
      background-size: 358px;
      border-radius: 13px;
    }
    .gradient-bg-container {
      overflow: hidden;
      border-radius: 13px 0 0 13px;
      position: absolute;
      top: 0;
      height: 8px;
    }
    .gradient-bg {
      background: linear-gradient(${(props) => getGradient(props.target)});
      width: 358px;
      height: 8px;
    }
    svg {
      position: absolute;
      top: -52px;
    }
  }
  .workout-container {
    position: absolute;
    top: -1px;
    display: flex;
    z-index: 9;
    .workout-item {
      border: 1px solid #ffffff;
      height: 10px;
      position: absolute;
      &:first-child {
        border-radius: 13px 0 0 13px;
      }
      &:last-child {
        border-right: none;
        border-width: 0;
      }
      &:hover {
        border-color: #0272e1;
        border: 1px solid;
      }
    }
  }
`

export function HealthySegment(props) {
  const {
    label,
    data,
    target,
    widthReference,
    workouts,
    seg_pct,
    hidePCTText,
    showTargetLine,
    ranges,
    hightestValue
  } = props
  const width = getBarPCTWidth(data[widthReference], ranges) + '%'
  const targetWidth = target && getBarPCTWidth(target, ranges) + '%'
  return (
    <SegmentContainer target={hightestValue}>
      <div>{label}</div>
      <div className="bar-container">
        {target && (
          <div className="stripe-bg" style={{ width: targetWidth }}></div>
        )}
        <div className="gradient-bg-container" style={{ width }}>
          <div className="gradient-bg"></div>
        </div>
        <SEGPCT
          width={width}
          hidePCTText={hidePCTText}
          text={seg_pct}
          target={target}
          hightestValue={hightestValue}
          targetWidth={targetWidth}
          showTargetLine={showTargetLine}
        />
        {workouts && renderWorkouts(workouts, seg_pct)}
      </div>
    </SegmentContainer>
  )

  function renderWorkouts(workouts, totalPCT) {
    let ratio = 1
    let totalPct = 0,
      position = 0
    for (const workout of workouts) {
      const { hsd_pct } = workout
      totalPct += hsd_pct
      const width = getBarPCTWidth(totalPct * ratio, ranges)
      workout.left = position + '%'
      workout.width = width - position + '%'
      position = width
    }

    return (
      <div className="workout-container">
        {workouts.map((workout, index) => {
          const { start_time, record_date } = workout
          let showDate
          if (start_time) {
            showDate = moment(start_time * 1000).format('h:mm A - MMM D, YYYY')
          } else if (record_date) {
            showDate = moment(record_date).format('MMM D, YYYY')
          }
          return (
            <Popover
              placement="top"
              overlayClassName="popover-no-arrow popover-for-HSD"
              title={null}
              content={
                <PopContent>
                  <div>
                    <div className="name">
                      {workout.activity_type_shown || workout.type}
                      <br />
                      {showDate && <span>{showDate}</span>}
                    </div>

                    <div className="info">
                      <div>
                        <span>Time</span>
                        <br />
                        {Math.round(workout.duration / 60)}{' '}
                        {Pluralize(
                          'min',
                          Math.round(workout.duration / 60) || 1
                        )}
                      </div>
                      <div>
                        <span>Intensity</span>
                        <br />
                        {workout.intensity}
                      </div>
                    </div>
                  </div>
                  <div className="score">{workout.hsd_pct}</div>
                </PopContent>
              }
              key={index}
            >
              <div
                className="workout-item"
                style={{
                  width:
                    index === workouts.length - 1
                      ? `calc(${workout.width} - 2px)`
                      : workout.width,
                  left: workout.left
                }}
              ></div>
            </Popover>
          )
        })}
      </div>
    )
  }
}

export function getBarPCTWidth(pct = 0, ranges = []) {
  // if (pct > 500) {
  //   pct = 500
  // }
  const rangeCount = ranges.length - 1
  const targetRangeIndex = ranges.findIndex(
    (value, index) =>
      value <= pct && (ranges[index + 1] > pct || !ranges[index + 1])
  )

  let widthPCT = targetRangeIndex * (100 / rangeCount)
  if (pct > ranges[targetRangeIndex] && ranges[targetRangeIndex + 1]) {
    const extra = pct - ranges[targetRangeIndex]
    widthPCT +=
      ((extra / (ranges[targetRangeIndex + 1] - ranges[targetRangeIndex])) *
        100) /
      rangeCount
  }

  return widthPCT
}
