import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { TimePicker } from 'antd'

class TimePickerItem extends Component {
  static propTypes = {}

  state = { value: null }

  componentDidMount() {
    const { value, format } = this.props
    this.setState({
      value: moment(value, format)
    })
  }

  triggerChange = (date, dateString) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(dateString)
    }
  }

  render() {
    const { value, attrs } = this.props
    return (
      <div>
        <TimePicker
          style={{ width: '100%' }}
          onChange={this.triggerChange}
          defaultValue={value && moment(value, 'HH:mm:ss')}
          {...attrs}
        />
      </div>
    )
  }
}

export default TimePickerItem
