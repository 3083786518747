import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  getClientList,
  getClientDetail,
  getFilePage
} from '../../../models/breadcrumb.model'
import FileManager from '../../profile/comp/fileManager'
import { getIntroductory } from '../../../api/people'
import { getRole } from '../../../utils/common'
import { loadUser } from '../../../utils/storage'

export default function FilePage(props) {
  const [introductory, setIntro] = useState(null)
  const [person, setPerson] = useState(null)
  const role = getRole()
  const initial = async () => {
    const { setClientPageTitle, setClientBreadcrumb, getPerson } = props
    const person = getPerson ? await getPerson() : loadUser()
    setPerson(person)
    if (setClientPageTitle && setClientBreadcrumb) {
      setClientPageTitle('Files Upload')
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getFilePage(person, true)
      ])
    }
    const data = await getIntroductory(person.id)
    setIntro(data)
  }
  useEffect(() => {
    initial()
  }, [])

  return (
    <div
      style={{
        paddingRight: role === 'User' ? 50 : 0
      }}
    >
      {role === 'User' && (
        <h1 className="title" style={{ paddingTop: 40 }}>
          Files Upload
        </h1>
      )}
      {person && (
        <FileManager
          hideTitle={true}
          person={person}
          loginRole={role}
          insuranceFiles={
            _.get(introductory, 'insurance_type') === 'photo'
              ? _.get(introductory, 'insurance_photos', [])
              : []
          }
        />
      )}
    </div>
  )
}
