import moment from 'moment'
import { fontFamily } from '../../utils/constant'

export const getAxisX = (startDate, endDate) => {
  const axisX = []
  let count = 1
  axisX.push(moment(startDate))
  while (
    moment(startDate)
      .add(count, 'days')
      .isBefore(endDate)
  ) {
    axisX.push(moment(startDate).add(count, 'days'))
    count++
  }
  axisX.push(moment(endDate))
  return axisX
}

export const xAxisFormat = (datum) =>
  moment(datum)
    .format('ddd')
    .toUpperCase()

export const sumReduce = (prev, curr) => {
  return prev + curr
}

export const splitLine = (datas) => {
  let solidGroup = [],
    dashGroup = []
  let startIndex = datas.findIndex((element) => element.y)
  const newDatas = datas.slice(startIndex)
  startIndex = 0
  newDatas.forEach((element, index) => {
    if (startIndex !== index || startIndex > newDatas.length - 1) {
      return
    }
    let subDatas = newDatas.slice(index)
    if (element.y) {
      let firstNullDataIndex = subDatas.findIndex((element) => {
        if (element.y) {
          return false
        }
        return true
      })
      let solid
      if (firstNullDataIndex === -1) {
        solid = subDatas
      } else {
        solid = subDatas.slice(0, firstNullDataIndex)
      }
      solidGroup.push(solid)
      startIndex = index + solid.length
    } else {
      let firstExistDataIndex = subDatas.findIndex((element) => {
        if (element.y) {
          return true
        }
        return false
      })
      if (firstExistDataIndex === -1) {
        return
      }
      let dash = [newDatas[index - 1], subDatas[firstExistDataIndex]]
      dashGroup.push(dash)
      startIndex = index + firstExistDataIndex
    }
  })
  return { solidGroup, dashGroup }
}

export const splitLineStyle = (datas) => {
  const existData = datas.filter((item) => item.y)
  let solidGroup = [],
    dashGroup = []
  for (const item of existData) {
    const length = solidGroup.length
    if (length === 0) {
      solidGroup.push([item])
    } else {
      const targetArray = solidGroup[length - 1]
      const target = targetArray[targetArray.length - 1]
      if (
        moment(target.x)
          .add(1, 'days')
          .isSame(moment(item.x))
      ) {
        targetArray.push(item)
      } else {
        solidGroup.push([item])
      }
    }
  }
  dashGroup = solidGroup.filter((item) => item.length === 1)
  solidGroup = solidGroup.filter((item) => item.length > 1)
  dashGroup = dashGroup.map((group) => {
    const target = group[0]
    const index = existData.findIndex(
      (item) => item.x.format('YYYY-MM-DD') === target.x.format('YYYY-MM-DD')
    )
    if (index > 0) {
      group.unshift(existData[index - 1])
    }
    if (index < existData.length - 1) {
      group.push(existData[index + 1])
    }
    return group
  })
  return { solidGroup, dashGroup }
}

export const tickLabels = {
  fontFamily: `"Gilroy",${fontFamily}`,
  fontSize: '9px',
  fill: '#70808E'
}

export const calculateElementWidth = (tag, text, fontSize, width) => {
  const element = document.createElement(tag.toUpperCase())
  const style = `font-size: ${fontSize}; width: ${width ||
    'auto'};color: transparent; position: absolute;`
  element.setAttribute('style', style)
  element.innerText = text
  document.body.append(element)
  const elementWidth = element.offsetWidth,
    elementHeight = element.offsetHeight
  document.body.removeChild(element)
  return { width: elementWidth, height: elementHeight }
}

export const getFloat = (num, n) => {
  n = n ? parseInt(n) : 0
  if (n <= 0) {
    return Math.round(num)
  }
  num = Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
  num = Number(num).toFixed(n)
  return num
}
