import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Tooltip } from 'antd'
import { getSum } from '../../../utils/common'
import HeartRateZoneStage from './heartRateZoneStage'
import ZoneTabs from './zoneTabs'
import ACSMHeartTarget from '../../summaryEmail/comp/acsmHeartTarget'
import ACSMHeartTargetBreakDown from '../../summaryEmail/comp/acsmHeartTargetBreakDown'
import { PaymentTipsIcon } from '../../icons/onboarding'
import moment from 'moment'

class HeartRateInfo extends Component {
  static propTypes = {
    editGoal: PropTypes.object,
    statistics: PropTypes.object,
    period: PropTypes.string,
    targetDate: PropTypes.any,
    achievingSummary: PropTypes.object,
    childrenGoal: PropTypes.array,
    renderNullStatus: PropTypes.func,
    isCurrentWeek: PropTypes.bool
  }

  state = {
    zoneType: null
  }

  getHRZSource() {
    const { targetDate, entries } = this.props
    const target = entries.find(
      (entry) => entry.created_at === targetDate.format('YYYY-MM-DD')
    )
    return (
      (target &&
        target.activity_details[0] &&
        target.activity_details[0].source) ||
      'any'
    )
  }

  render() {
    const {
      editGoal,
      statistics,
      period,
      targetDate,
      achievingSummary,
      isCurrentWeek,
      showDate
    } = this.props
    const pillor = editGoal.category.pillar
    let infoList = [
      {
        title: 'Average Heart Rate',
        metricName: 'AvgHeartRate',
        color: '#264382',
        chartLabel: 'Avg',
        unit: 'bpm'
      },
      {
        metricName: 'MaxHeartRate',
        title: 'Max Heart Rate',
        color: '#9CA5B8',
        chartLabel: 'Max',
        unit: 'bpm'
      },
      {
        title: 'Min Heart Rate',
        metricName: 'MinHeartRate',
        color: '#7BAD2D',
        chartLabel: 'Min',
        unit: 'bpm'
      },
      {
        title: 'Resting Heart Rate',
        metricName: 'RestingHeartRate',
        color: '#FFB800',
        chartLabel: 'Resting',
        unit: 'bpm'
      },
      {
        title: 'Heart Rate Variability',
        metricName: 'HeartRateVariability',
        color: '#FF3E01',
        chartLabel: 'Variability',
        unit: 'ms'
      }
    ]
    let childrenGoal = this.props.childrenGoal
    if (!childrenGoal) {
      statistics[pillor].filter(
        (item) => item.micro_goal.parent_micro_goal_id === editGoal.id
      )
    }
    if (period === 'day') {
      infoList.map((item) => {
        const { achieving, micro_goal } = (childrenGoal &&
          childrenGoal.find(
            (goal) => goal.micro_goal.auto_metric === item.metricName
          )) || { achieving: [] }
        let entries = []
        for (const achiev of achieving) {
          entries = entries.concat(achiev.entries)
        }
        item.unit = (micro_goal && micro_goal.unit) || item.unit
        item.entry =
          targetDate &&
          entries.find(
            (entry) => entry.created_at === targetDate.format('YYYY-MM-DD')
          )
        return item
      })
      infoList = infoList.filter((item) => item.entry && item.entry.input_auto)
      const totalInputs = infoList
        .filter(
          (item) =>
            item.metricName === 'AvgHeartRate' ||
            item.metricName === 'MaxHeartRate'
        )
        .map((item) => (item.entry && Number(item.entry.input_auto)) || 0)
      if (getSum(totalInputs) === 0) {
        return this.props.renderNullStatus('day')
      }
    } else {
      infoList.map((item) => {
        const child = childrenGoal.find(
          (goal) => goal.micro_goal.auto_metric === item.metricName
        )
        item.goal = child
        return item
      })
    }

    let acsmDetail, acsmSource, hrzMessage, startDate, endDate
    if (period === 'week') {
      acsmDetail =
        achievingSummary.heart_rate_zones[this.state.zoneType || 'All']
      if (!acsmDetail.heartRateZoneSource) {
        acsmDetail.heartRateZoneSource = new Array(5).fill(0)
      }
      acsmSource = Array.from(new Set(acsmDetail.heartRateZoneSource))
      acsmSource.shift()
      hrzMessage = JSON.parse(achievingSummary.hrz_message || null)
      const [date, year] = showDate.split(',')
      const dates = date.split('-')
      startDate = moment(`${dates[0].trim()}, ${year}`)
      endDate = moment(`${dates[1].trim()}, ${year}`)
    }

    return (
      <div style={{ marginTop: 20 }}>
        {period === 'day' ? (
          <>
            <Row className="activity-type-text-container bottom-line">
              <Col span={24}>
                <div className="activity-section-title">Heart Rate Metrics</div>
              </Col>
              {editGoal.source === 'any' && (
                <Col span={12} style={{ paddingTop: 16 }}>
                  <div className="activity-text-title">Source</div>
                  <div
                    className="activity-text-content"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {this.getHRZSource()}
                  </div>
                </Col>
              )}
              {infoList
                .filter((item) => item.chartLabel !== 'Min')
                .map((item, index) => (
                  <Col span={12} key={index} style={{ paddingTop: 16 }}>
                    <div className="activity-text-title">{item.title}</div>
                    <div className="activity-text-content">
                      {item.entry
                        ? `${Math.round(item.entry.input_auto)} ${item.unit}`
                        : 'N/A'}
                    </div>
                  </Col>
                ))}
            </Row>
            {editGoal.goal_type !== 'sleep' && (
              <div style={{ paddingTop: 25 }}>
                <HeartRateZoneStage
                  editGoal={editGoal}
                  activityDetail={achievingSummary}
                  totalWidth={520}
                  calculatePercentage={true}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            {period === 'week' && (
              <>
                <div className="heart-rate-title">
                  Apeiron Life Training Target
                  <Tooltip
                    title={
                      <>
                        <h3>What is Apeiron Life Training Target?</h3>
                        <p>
                          Your Apeiron heart health is based on ACSM guideline
                          which requires adults to get at least 150 minutes of
                          moderate-intensity exercise per week.
                        </p>
                      </>
                    }
                  >
                    <PaymentTipsIcon />
                  </Tooltip>
                </div>
                <div className="bottom-line" style={{ marginBottom: 26 }}>
                  <ACSMHeartTarget
                    containerStyle={{
                      marginLeft: 0,
                      marginRight: 0,
                      marginBottom: 24
                    }}
                    {...{ startDate, endDate }}
                    checkToday={true}
                    exercise={{
                      acsm_target: acsmDetail.acsmSource, //[300, 220, 150, 65],
                      current_acsm_percentage: acsmDetail.acsmPercentage, //[11, 3, 0, 0],
                      previous_week_acsm_percentage:
                        achievingSummary.previous_week_acsm
                    }}
                  />
                  <ACSMHeartTarget
                    containerStyle={{
                      marginLeft: 0,
                      marginRight: 0,
                      marginBottom: 24
                    }}
                    previousWeek={true}
                    {...{ startDate, endDate }}
                    exercise={{
                      acsm_target: acsmDetail.acsmSource, //[300, 220, 150, 65],
                      current_acsm_percentage: acsmDetail.acsmPercentage, //[11, 3, 0, 0],
                      previous_week_acsm_percentage:
                        achievingSummary.previous_week_acsm
                    }}
                  />
                </div>
              </>
            )}
            <div style={{ marginBottom: 25 }}>
              {period === 'week' ? (
                <>
                  <div className="heart-rate-title">Target Breakdown</div>

                  <ZoneTabs
                    zoneTypes={[
                      'All',
                      'Aerobic',
                      'Resistance',
                      'Activity',
                      'Movement Quality',
                      'Active'
                    ]}
                    onTabChange={(_, zoneType) => this.setState({ zoneType })}
                  />
                  <ACSMHeartTargetBreakDown
                    exercise={{
                      acsm_target: achievingSummary.acsm_target,
                      current_acsm_percentage: acsmDetail.acsmPercentage,
                      heart_rate_zone_definition: [
                        acsmDetail.heartRateZoneDefinition[1],
                        acsmDetail.heartRateZoneDefinition[2],
                        acsmDetail.heartRateZoneDefinition[3],
                        acsmDetail.heartRateZoneDefinition[4]
                      ],

                      totalZones: [
                        acsmDetail.heartRateZoneSource[1],
                        acsmDetail.heartRateZoneSource[2],
                        acsmDetail.heartRateZoneSource[3],
                        acsmDetail.heartRateZoneSource[4]
                      ]
                      //  Array.from(
                      //   new Set(
                      //     acsmDetail.heartRateZoneSource.filter(
                      //       (_, index) => index > 0
                      //     )
                      //   )
                      // )
                    }}
                  />
                </>
              ) : (
                <HeartRateZoneStage
                  showACMSPercentage={period === 'week' ? true : false}
                  editGoal={editGoal}
                  activityDetail={achievingSummary}
                  totalWidth={520}
                  syncTabChange={
                    period === 'week' &&
                    ((zoneType) => {
                      this.setState({ zoneType })
                    })
                  }
                />
              )}
            </div>

            {period === 'week' && !isCurrentWeek && hrzMessage && (
              <div className="hrz-message">
                <div className="title">{hrzMessage.title}</div>
                <ul>
                  {hrzMessage.message.map((item, index) => (
                    <li key={index}>
                      <span className={`message-state-${item.status}`} />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default HeartRateInfo
