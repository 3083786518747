import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import BodyMass from './comp/bodyMass'
import Information from '../UI/extraInformation'
import { SvgText } from '../icons/weeklySummary'
import { calculateElementWidth } from './common'
import SubTip from './comp/subTip'
import { nutritionStyle } from './styles/nutrition.sass'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const gray = '#b7bfc6',
  primary = '#383E48',
  blue = '#3194EB'

class Weight extends Component {
  static propTypes = {}

  getGoals = () => {
    const { goals } = this.props
    return goals.map((item) => {
      item = _.cloneDeep(item)
      item.barProps = item.barProps || {}
      if (!item.isYesNoGoal) {
        item.barProps.target = item.average
      }
      item.barProps.min = null
      item.barProps.max = null
      item.barProps.extraAxisText = 'Avg'
      item.barProps.barColor = blue
      return item
    })
  }

  renderGoal(item, index) {
    const { deleteWeight, startDate, endDate, isLocked } = this.props

    const isEditable = !isLocked && this.props.isEditable
    // if (item.description.toLowerCase().includes('weight')) {
    const latest = item.details[0]
    if (latest) {
      item.showLatestData = latest
    } else {
      item.showLatestData = { summary_date: null, value: '--' }
    }
    // }

    const SevenDayText =
      item.average || item.average === 0
        ? item.input_type === 'binary'
          ? Math.round(item.average * 100) + '%'
          : item.showLatestData
          ? item.showLatestData.value
          : item.average
        : '--'
    return (
      <div
        className="goal-item"
        key={index}
        style={{ borderTopWidth: index > 0 ? 1 : 0 }}
      >
        {isEditable && (
          <div
            className="delete"
            onClick={() => deleteWeight('weight', item.id, item.description)}
          >
            <img className="delete-icon" src={DelIcon} alt="del" />
          </div>
        )}
        <div className="name">{item.description}</div>

        <div
          className="data-content ref-container"
          ref={`weight.chart_url.${item.id}`}
        >
          <div>
            <div className="days">
              <SvgText
                text={SevenDayText}
                height={33}
                fontSize={30}
                width={
                  calculateElementWidth('span', SevenDayText, '30px').width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
              <SubTip
                text={
                  item.showLatestData
                    ? item.showLatestData.summary_date
                      ? `${moment(item.showLatestData.summary_date).format(
                          'MMM DD, YYYY'
                        )}`
                      : '--'
                    : '7 DAY AVG'
                }
                unit={item.unit}
              />
            </div>
            <div className="weeks">
              <SvgText
                text={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? item.input_type === 'binary'
                      ? Math.round(item.previous_weeks_average * 100) + '%'
                      : item.previous_weeks_average
                    : '--'
                }
                fill={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? gray
                    : primary
                }
                fontSize={30}
                height={33}
                width={
                  calculateElementWidth(
                    'span',
                    item.previous_weeks_average ||
                      item.previous_weeks_average === 0
                      ? item.input_type === 'binary'
                        ? Math.round(item.previous_weeks_average * 100) + '%'
                        : item.previous_weeks_average
                      : '--',
                    '30px'
                  ).width + 5
                }
                textAttr={{
                  x: 0,
                  textAnchor: 'start',
                  dominantBaseline: 'mathematical'
                }}
              />
              <SubTip text="3-WEEK AVG" unit={item.unit} />
            </div>
          </div>
          <div className="chart">
            <BodyMass
              showXLabel={true}
              {...{ startDate, endDate, ...item.barProps }}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      setSubmitData,
      originInformation,
      submitData,
      loginRole,
      templates,
      updateEditingCount,
      updateEmailAction,
      isLocked
    } = this.props
    const renderGoals = this.getGoals()
    const status = isLocked
      ? 'Locked'
      : (originInformation && originInformation.status) || 'Draft'
    return (
      <div className={nutritionStyle}>
        <div style={{ marginBottom: 20 }}>
          <Information
            // direction={nutrition}
            updateEditingCount={updateEditingCount}
            updateEmailAction={updateEmailAction}
            status={status}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            initialValue={submitData.weight_recommendation}
            doneStatus={submitData.weight_recommendation_status}
            updateCommend={(value, status) =>
              setSubmitData({
                part: null,
                field: 'weight_recommendation',
                value,
                status,
                section: 'Weight'
              })
            }
          />
        </div>
        {renderGoals.map((item, index) => this.renderGoal(item, index))}
      </div>
    )
  }
}

export default Weight
