import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter } from 'victory'
import { axisStyles, SmallPointer, LargePointer } from './aerobic'
import {
  PolyFit,
  getY
} from '../../../components/assessmentReview/comp/polynomial'

export const dotColors = ['#316F89', '#3AA081', '#ACE1C8']

export default function Logarithmic(props) {
  const { percentFatSeries, percentFatHistory, person, ui_version } = props
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const axisX = ui_version
      ? [age - 5, age, age + 5]
      : [15, 25, 35, 45, 55, 65, 75, 85],
    axisY = [0, 10, 20, 30, 40, 50, 60]
  const data = []
  for (const { level, color, type, series } of percentFatSeries) {
    const len = series.y.length
    const m = 3
    const a = new Array(series.x.length)
    const aa = PolyFit(series.x, series.y, len, a, m)
    data.push({
      aa,
      level,
      color,
      type,
      arrX: series.x,
      m
    })
  }
  const scatter = _.cloneDeep(percentFatHistory)
    .reverse()
    .map((item, index) => {
      const result = {
        data: [{ x: item[0], y: item[1] }],
        date: moment(item[2]).format('MM/DD/YYYY'),
        color: dotColors[index - 1] || '#BFBFBF'
      }
      if (index === 0) {
        result.dataComponent = <LargePointer />
      } else {
        result.style = { data: { fill: dotColors[index - 1] || '#BFBFBF' } }
        result.size = 5
      }
      return result
    })

  const historyMax = Math.max(...percentFatHistory.map((item) => item[1]))
  while (historyMax && historyMax > axisY[axisY.length - 1]) {
    axisY.push(axisY[axisY.length - 1] + 10)
  }

  return (
    <>
      <div className="tip-label">
        {data.map(({ level, color, type }, index) => (
          <div key={index}>
            {type === 'solid' ? (
              <span className="tip-line" style={{ background: color }} />
            ) : (
              <span>
                <span>
                  <svg
                    width="12"
                    height="2"
                    viewBox="0 0 12 2"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="1"
                      x2="12"
                      y2="1"
                      stroke={color}
                      strokeWidth="2"
                      strokeDasharray="2 2"
                    />
                  </svg>
                </span>
              </span>
            )}
            {level}
          </div>
        ))}
      </div>
      <div className="tip-label">
        {_.cloneDeep(scatter)
          .reverse()
          .map((item, index) => (
            <div key={index}>
              {item.dataComponent ? (
                <span>
                  <SmallPointer />
                </span>
              ) : (
                <span
                  className="tip-circle"
                  style={{ background: item.color }}
                />
              )}
              {item.date}
            </div>
          ))}
      </div>

      <VictoryChart width={600} height={400} minDomain={{ x: age - 5, y: 0 }}>
        <VictoryAxis tickValues={axisX} label="AGE" style={axisStyles} />
        <VictoryAxis
          tickValues={axisY}
          dependentAxis
          style={axisStyles}
          label="BODY FAT %"
        />

        {data.map(({ arrX, aa, m, color, type }, index) => (
          <VictoryLine
            key={index}
            y={(d) => {
              return d.x >= 20 ? getY(d.x, arrX, aa, m) : null
            }}
            style={{
              data: {
                stroke: color,
                strokeDasharray: type === 'dotted' ? '3,3' : null
              }
            }}
          />
        ))}

        {scatter.map((item, index) => (
          <VictoryScatter key={index} {...item} />
        ))}
      </VictoryChart>
    </>
  )
}
