import React from 'react'
import Icon from '@ant-design/icons'
import { fontFamily } from '../../utils/constant'

const mail = (props) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 0H1C0.4 0 0 0.4 0 1V2.4L8 6.9L16 2.5V1C16 0.4 15.6 0 15 0Z" />
    <path d="M7.5 8.9002L0 4.7002V13.0002C0 13.6002 0.4 14.0002 1 14.0002H15C15.6 14.0002 16 13.6002 16 13.0002V4.7002L8.5 8.9002C8.22 9.04019 7.78 9.04019 7.5 8.9002Z" />
  </svg>
)

export const MailIcon = (props) => <Icon component={mail} {...props} />

const edit = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14.1 1.20114C13.7 0.809254 13.1 0.809254 12.7 1.20114L6 7.76528V10.7044H9L15.7 4.14031C16.1 3.74842 16.1 3.16059 15.7 2.7687L14.1 1.20114Z" />
    <path d="M14 14.6233V10.7044H16V15.6031C16 16.1909 15.6 16.5828 15 16.5828H1C0.4 16.5828 0 16.1909 0 15.6031L0 1.88695C0 1.29912 0.4 0.907227 1 0.907227L6 0.907227V2.86667L2 2.86667L2 14.6233L14 14.6233Z" />
  </svg>
)

export const EditIcon = (props) => <Icon component={edit} {...props} />

const plus = (props) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M7.29167 0.117188H5.20833V5.32552H0V7.40885H5.20833V12.6172H7.29167V7.40885H12.5V5.32552H7.29167V0.117188Z" />
  </svg>
)

export const PlusIcon = (props) => <Icon component={plus} {...props} />

const del = (props) => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    // fill="currentColor"
    fill="none"
    // stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.1" cx="16" cy="16.7393" r="16" fill="#EA5400" />
    <g clipPath="url(#clip0)">
      <path
        d="M20.7999 14.9385L20.2797 22.2237C20.2582 22.5264 20.1227 22.8098 19.9006 23.0166C19.6785 23.2235 19.3863 23.3385 19.0828 23.3385H12.9172C12.6136 23.3385 12.3214 23.2235 12.0993 23.0166C11.8772 22.8098 11.7417 22.5264 11.7202 22.2237L11.2 14.9385"
        stroke="#EA5400"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M9.39996 12.7383L22.6 12.7383"
        stroke="#EA5400"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.6 12.5387V10.7387C13.6 10.5795 13.6632 10.4269 13.7757 10.3144C13.8882 10.2019 14.0408 10.1387 14.2 10.1387H17.8C17.9591 10.1387 18.1117 10.2019 18.2242 10.3144C18.3368 10.4269 18.4 10.5795 18.4 10.7387V12.5387"
        stroke="#EA5400"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect x="8.79999" y="9.53906" width="14.4" height="14.4" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const DelIcon = (props) => <Icon component={del} {...props} />

const arrowDown = (props) => (
  <svg
    width="5"
    height="4"
    viewBox="0 0 5 4"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M2.5 3.92822L0.334936 0.989057L4.66506 0.989057L2.5 3.92822Z"
    />
  </svg>
)

export const ArrowDownIcon = (props) => (
  <Icon component={arrowDown} {...props} />
)

const editCircle = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.1" cx="16" cy="16" r="16" fill="#75879D" />
    <path
      d="M14.707 18.7068L20.707 12.7068C20.8945 12.5193 20.9998 12.265 20.9998 11.9998C20.9998 11.7346 20.8945 11.4803 20.707 11.2928L19.707 10.2928C19.5195 10.1053 19.2652 10 19 10C18.7348 10 18.4805 10.1053 18.293 10.2928L12.293 16.2928C12.2167 16.3688 12.1533 16.4566 12.105 16.5528L11.293 19.7068L14.447 18.8998C14.5436 18.85 14.6314 18.7848 14.707 18.7068Z"
      // fill="#264382"
    />
    <path
      d="M21.5 21H10.5C10.3674 21 10.2402 21.0527 10.1464 21.1464C10.0527 21.2402 10 21.3674 10 21.5C10 21.6326 10.0527 21.7598 10.1464 21.8536C10.2402 21.9473 10.3674 22 10.5 22H21.5C21.6326 22 21.7598 21.9473 21.8536 21.8536C21.9473 21.7598 22 21.6326 22 21.5C22 21.3674 21.9473 21.2402 21.8536 21.1464C21.7598 21.0527 21.6326 21 21.5 21Z"
      // fill="#264382"
    />
  </svg>
)

export const EditCircleIcon = (props) => (
  <Icon component={editCircle} {...props} />
)

export const viewCircle = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity="0.1" cx="16" cy="16" r="16" fill="#75879D" />
    <path
      d="M16 22C20.707 22 23.744 16.716 23.871 16.492C24.042 16.188 24.043 15.816 23.872 15.512C23.746 15.287 20.731 10 16 10C11.245 10 8.25101 15.289 8.12601 15.514C7.95701 15.817 7.95801 16.186 8.12801 16.489C8.25401 16.713 11.269 22 16 22ZM16 12C18.839 12 21.036 14.835 21.818 16C21.034 17.166 18.837 20 16 20C13.159 20 10.962 17.162 10.181 15.999C10.958 14.835 13.146 12 16 12Z"
      // fill="#264382"
    />
    <path
      d="M16 18C17.1046 18 18 17.1046 18 16C18 14.8954 17.1046 14 16 14C14.8954 14 14 14.8954 14 16C14 17.1046 14.8954 18 16 18Z"
      // fill="#264382"
    />
  </svg>
)

export const ViewCircleIcon = (props) => (
  <Icon component={viewCircle} {...props} />
)

const avatar = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM12.1 12.3C11.7 11.6 10.9 11.3 10 11C9.3 10.8 9.1 10.4 9 9.8C10.1 9.4 11 8.3 11 7C11 5.3 9.7 4 8 4C6.3 4 5 5.3 5 7C5 8.3 5.8 9.4 7 9.8C6.9 10.4 6.7 10.8 6 11C5.1 11.3 4.3 11.6 3.9 12.3C2.7 11.2 2 9.7 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 9.7 13.3 11.2 12.1 12.3Z" />
  </svg>
)

export const AvatarIcon = (props) => <Icon component={avatar} {...props} />

const plan = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="#FF6B00"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.6462 14.4658L11.7409 14.6465L11.8852 14.5023L12.5395 13.8479L12.5983 13.7891L12.5796 13.7081L11.6168 9.53593L13.3574 7.79529C13.6876 7.46516 13.6876 6.93178 13.3574 6.60165C13.0273 6.27152 12.4939 6.27152 12.1638 6.60165L10.4231 8.34229L6.25095 7.37948L6.16994 7.36079L6.11115 7.41957L5.45682 8.07391L5.31255 8.21818L5.49328 8.31285L8.74782 10.0176L7.1457 11.6197L6.07485 11.4668L6.0006 11.4562L5.94757 11.5092L5.45682 11.9999L5.3162 12.1406L5.49004 12.2371L6.92483 13.0342L7.72194 14.469L7.81852 14.6429L7.95913 14.5023L8.44989 14.0115L8.50292 13.9585L8.49231 13.8842L8.33933 12.8134L9.94146 11.2112L11.6462 14.4658Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}

export const PlanIcon = (props) => <Icon component={plan} {...props} />

const scatter = (props) => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line y1="5.91309" x2="16" y2="5.91309" stroke="#FF6B00" strokeWidth="2" />
    <rect
      x="4"
      y="1.99414"
      width="8"
      height="7.87833"
      rx="3.93917"
      fill="white"
      stroke="#FF6B00"
      strokeWidth="2"
    />
  </svg>
)

export const ScatterIcon = (props) => <Icon component={scatter} {...props} />

export const PieChartLabel = (props) => (
  <svg width="200" height="22">
    <text
      x="100"
      y="20"
      fontSize="24"
      fontFamily={`${props.fontFamily || 'Gilroy-Bold'},${fontFamily}`}
      fill="#383E48"
      fontWeight="bold"
      textAnchor="middle"
    >
      {props.value}
      <tspan fontSize="18" fontWeight="normal" fill="#E4EAEF">
        {props.unit}
      </tspan>
    </text>
    Sorry, your browser does not support inline SVG.
  </svg>
)

const HeartTargetTip = (props) => {
  return (
    <svg
      width="14"
      height="3"
      viewBox="0 0 14 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.508789" width="2" height="2.06785" fill="#383E48" />
      <rect x="4" y="0.508789" width="2" height="2.06785" fill="#383E48" />
      <rect x="8" y="0.508789" width="2" height="2.06785" fill="#383E48" />
      <rect x="12" y="0.508789" width="2" height="2.06785" fill="#383E48" />
    </svg>
  )
}

export const HeartTargetTipIcon = (props) => (
  <Icon component={HeartTargetTip} {...props} />
)

const HeartTargeSummarytHitMinimumLine = (props) => {
  return (
    <svg
      width="2"
      height="36"
      viewBox="0 0 2 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="4.37121e-08"
        x2="0.999998"
        y2="36"
        stroke="#383E48"
        strokeWidth="2"
        strokeDasharray="2 2"
      />
    </svg>
  )
}

export const HeartTargetSummaryHitMinimumLineIcon = (props) => (
  <Icon component={HeartTargeSummarytHitMinimumLine} {...props} />
)

const HeartTargetHited = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#7BAD2D" />
      <path
        d="M5.33398 8L7.33398 10L10.6673 6"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const HeartTargetHitedIcon = (props) => (
  <Icon component={HeartTargetHited} {...props} />
)

const Succeed = (porps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12L11 15L16 9"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const SucceedIcon = (props) => <Icon component={Succeed} {...props} />

const Close = (props) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 1.33325L2 10.6666"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.3333 10.6666L2 1.33325"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const CloseIcon = (props) => <Icon component={Close} {...props} />

export function SvgText(props) {
  const textAttr = {
    x: '50%',
    y: '50%',
    dominantBaseline: 'mathematical',
    textAnchor: 'middle',
    fontFamily: `${props.fontFamily || 'Gilroy-Bold'},${fontFamily}`,
    fontWeight: props.fontWeight || 'bold',
    fontSize: props.fontSize || 12,
    fill: props.fill || '#383E48'
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={props.width || 60}
      height={props.height || 18}
      style={{ overflow: 'visible' }}
    >
      <text {...Object.assign(textAttr, props.textAttr)}>{props.text}</text>
    </svg>
  )
}
export function WrapText(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={props.width || 100}
      height={props.height || 66}
    >
      <switch>
        <foreignObject
          x="0"
          y={5}
          width="100%"
          height={props.height || 66}
          fill={props.fill || '#383E48'}
          fontFamily={`${props.fontFamily || 'Gilroy-Bold'},${fontFamily}`}
          fontWeight="bold"
          fontSize={props.fontSize || 12}
          dominantBaseline={props.dominantBaseline || 'middle'}
          style={{ overflow: 'visible' }}
          {...props.textAttr}
        >
          <span
            xmlns="http://www.w3.org/1999/xhtml"
            style={{
              color: props.textAttr ? props.textAttr.fill : '',
              ...(props.textStyle ? props.textStyle : {})
            }}
          >
            {props.text}
          </span>
        </foreignObject>

        <text x="20" y="40" {...props.textAttr}>
          {props.text}
        </text>
      </switch>
    </svg>
  )
}

export function GreenCheck() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#7BAD2D" />
      <path
        d="M5.33337 8L7.33337 10L10.6667 6"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const Image = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 16V2C18 0.9 17.1 0 16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16ZM5.5 10.5L8 13.51L11.5 9L16 15H2L5.5 10.5Z"
      // fill="#264382"
    />
  </svg>
)

export const ImageIcon = (props) => <Icon component={Image} {...props} />

const AOF = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0ZM8 14C4.7 14 2 11.3 2 8C2 4.7 4.7 2 8 2C11.3 2 14 4.7 14 8C14 11.3 11.3 14 8 14Z" />
    <path d="M8 4C5.8 4 4 5.8 4 8C4 10.2 5.8 12 8 12C10.2 12 12 10.2 12 8C12 5.8 10.2 4 8 4ZM8 10C6.9 10 6 9.1 6 8C6 6.9 6.9 6 8 6C9.1 6 10 6.9 10 8C10 9.1 9.1 10 8 10Z" />
  </svg>
)

export const AOFIcon = (props) => <Icon component={AOF} {...props} />

const LTA = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 0V4C0 6.06548 1.60369 8 4.14142 8C4.48578 9.32947 5.49066 10.3786 6.79016 10.7935C6.612 11.7306 6.28198 12.9044 5.66724 14H4V16H12V14H10.3328C9.71802 12.9044 9.388 11.7306 9.20984 10.7935C10.5093 10.3786 11.5142 9.32947 11.8586 8C14.3879 8 16 6.07178 16 4V0H0ZM2 4V2H4V6C2.89746 6 2 5.10303 2 4ZM14 4C14 5.10303 13.1025 6 12 6V2H14V4Z" />
  </svg>
)

export const LTAIcon = (props) => <Icon component={LTA} {...props} />

const attach = (props) => {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 20.8164C2.96 20.8164 0.5 18.3564 0.5 15.3164L0.499999 4.81641C0.499999 2.60641 2.29 0.816406 4.5 0.816406C6.71 0.816406 8.5 2.60641 8.5 4.81641L8.5 13.3164C8.5 14.6964 7.38 15.8164 6 15.8164C4.62 15.8164 3.5 14.6964 3.5 13.3164L3.5 5.81641L5.5 5.81641L5.5 13.4064C5.5 13.9564 6.5 13.9564 6.5 13.4064L6.5 4.81641C6.5 3.71641 5.6 2.81641 4.5 2.81641C3.4 2.81641 2.5 3.71641 2.5 4.81641L2.5 15.3164C2.5 17.2464 4.07 18.8164 6 18.8164C7.93 18.8164 9.5 17.2464 9.5 15.3164L9.5 5.81641L11.5 5.81641L11.5 15.3164C11.5 18.3564 9.04 20.8164 6 20.8164Z"
      />
    </svg>
  )
}

export const AttachIcon = (props) => <Icon component={attach} {...props} />
