import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
import { Input, message, Button } from 'antd'
import EditICon from '../../../../asserts/icon/icon-edit.svg'
import { valueStyle } from './value.sass'

export class PersonalValue extends Component {
  static propTypes = {
    initialValue: PropTypes.object,
    personId: PropTypes.number,
    biomarker: PropTypes.object,
    savePersonalValue: PropTypes.func,
    delPersonalValue: PropTypes.func,
    showInput: PropTypes.bool,
    datasetId: PropTypes.number
  }

  state = {
    personalBioMarkerObj: null,
    showInput: false,
    value: null,
    loading: false,
    updated: false,
    error: false,
    updateChange: false
  }

  timeoutReference = null

  componentDidMount() {
    const { biomarker } = this.props
    const personalValue =
      biomarker && (biomarker.personal_data || biomarker.person_value)
    const showInput = !personalValue
    // if (biomarker.compute) {
    // }
    this.setState({ showInput })
  }

  saveBloodTestValue = async () => {
    const { datasetId, biomarker, savePersonalValue } = this.props
    const { value } = this.state
    const personalValue = biomarker && biomarker.person_data
    if (isNaN(Number(value))) {
      // message.error('"value" must be a number!')
      return this.setState({
        error: true,
        errorMessage: '"value" must be a number!'
      })
    }
    this.setState({ loading: true })

    try {
      let param1
      if (personalValue) {
        const { prefer_conventional, prefer_functional } = personalValue
        param1 = {
          id: biomarker.id,
          value: value === '' ? null : value,
          prefer_conventional: !!prefer_conventional,
          prefer_functional: !!prefer_functional
        }
      } else {
        param1 = {
          id: biomarker.id,
          value: value === '' ? null : value,
          prefer_conventional: true, // false -> true on sprint 23, default conventional value is shown
          prefer_functional: false
        }
      }

      await savePersonalValue(param1, datasetId)
      // result = await updateHistoryDataset(datasetId, param1)

      // message.success('Save successful!')
      this.setState({
        loading: false,
        showInput: false,
        updated: true,
        error: false
      })
      setTimeout(() => {
        this.setState({ updated: false })
      }, 5000)
    } catch (err) {
      console.error(err)
      message.error(err.message)
      this.setState({ updated: false })
    }
  }

  saveValue = async () => {
    const { loading } = this.state
    if (loading) return
    if (this.timeoutReference) {
      clearTimeout(this.timeoutReference)
    }
    const {
      biomarker,
      savePersonalValue,
      datasetId,
      biomarkerIdField,
      type
    } = this.props
    if (type === 'blood-test') {
      return this.saveBloodTestValue()
    }
    const { value } = this.state
    const personalValue = biomarker && biomarker.person_value

    if (isNaN(Number(value))) {
      // message.error('"value" must be a number!')
      return this.setState({
        error: true,
        errorMessage: '"value" must be a number!'
      })
    }
    this.setState({ loading: true })

    try {
      let param1
      if (personalValue) {
        const { on } = personalValue
        param1 = {
          on,
          value: value === '' ? null : value,
          id: personalValue[biomarkerIdField]
        }
      } else {
        param1 = {
          on: true,
          value: value === '' ? null : value,
          id: biomarker.id
        }
      }
      await savePersonalValue(param1, datasetId)

      // message.success('Save successful!')
      this.setState({
        loading: false,
        showInput: false,
        updated: true,
        error: false
      })
      setTimeout(() => {
        this.setState({ updated: false })
      }, 5000)
    } catch (err) {
      console.error(err)
      message.error(err.message)
      this.setState({ updated: false, loading: false })
    }
  }

  renderText() {
    const { updated } = this.state
    const { biomarker } = this.props
    const personalValue =
      (biomarker && biomarker.person_value) || biomarker.personal_data
    return (
      <span
        className={`personal-value ${
          biomarker.compute && !biomarker.allow_manual_update ? 'disabled' : ''
        }`}
      >
        {personalValue ? (
          <span className="text">
            {Number(personalValue.value) +
              ' ' +
              (biomarker && biomarker.unit ? biomarker.unit : '')}
          </span>
        ) : (
          <span className="unknown text">{'unknown '}</span>
        )}
        {updated ? (
          <Button className="phs-btn-success">
            <CheckOutlined />
            UPDATED
          </Button>
        ) : (
          <img
            src={EditICon}
            alt="edit"
            onClick={() => {
              if (biomarker.compute && !biomarker.allow_manual_update) return
              this.setState({
                showInput: true,
                value: personalValue && Number(personalValue.value)
              })
            }}
          />
        )}
      </span>
    )
  }

  renderEditable() {
    const { error, value, loading, errorMessage } = this.state
    const { biomarker } = this.props
    return (
      <div className={`personal-value-edit ${error ? 'error' : ''}`}>
        <Input
          type="text"
          value={value}
          disabled={loading}
          placeholder="unknown"
          onChange={(e) => {
            this.setState({
              value: e.target.value,
              error: false,
              errorMessage: ''
            })
            if (this.timeoutReference) {
              clearTimeout(this.timeoutReference)
            }
            this.timeoutReference = setTimeout(() => {
              this.saveValue()
            }, 5e3)
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              this.saveValue()
            }
          }}
          suffix={<span>{biomarker && biomarker.unit}</span>}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <Button
          type="primary"
          className="phs-btn-primary"
          onClick={() => this.saveValue()}
        >
          {loading && <LoadingOutlined />}
          UPDATE
        </Button>
        <Button
          className="phs-btn-primary btn-text"
          disabled={loading}
          onClick={() => {
            this.setState({ showInput: false, error: false })
          }}
        >
          CANCEL
        </Button>
      </div>
    )
  }
  render() {
    const { showInput } = this.state

    return (
      <div className={valueStyle}>
        {showInput &&
        (!this.props.biomarker.compute ||
          this.props.biomarker.allow_manual_update)
          ? this.renderEditable()
          : this.renderText()}
      </div>
    )
  }
}

export default PersonalValue
