import React, { useState } from 'react'
import { Popover } from 'antd'

export default function WomanBack(props) {
  const { opacities, getContent, quality, overlayStyle, colorMaps } = props
  const [hoverIndex, setHover] = useState(null)
  return (
    <svg
      width="350"
      height="630"
      viewBox="0 0 262 505"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M117.529 258.356C117.786 262.218 117.187 265.512 117.187 269.288C117.187 271.519 117.273 273.836 117.187 276.067C116.929 287.223 117.702 298.293 117.702 309.449C117.702 312.795 117.874 316.228 117.53 319.575C117.015 324.809 115.985 329.958 116.414 335.193C116.844 340.17 116.929 345.233 117.445 350.211C117.874 355.016 118.045 359.993 116.929 364.885C116.5 366.687 116.243 367.331 116.414 369.305"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.2057 267.266C95.4828 269.553 95.2917 269.373 94.8624 270.317C93.4027 273.749 93.1451 277.439 92.8875 281.044C92.3723 288.509 92.8017 295.975 94.1755 303.269C95.9786 312.709 95.8069 322.235 95.6352 331.674C95.5493 335.278 95.6352 338.129 95.6352 341.733C95.6352 342.677 95.8928 343.96 95.8928 345.061"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.481 79.6367C122.94 80.3232 123.799 81.5246 124.572 82.8977C125.345 84.2707 126.032 85.7295 126.976 87.0168C127.749 88.0465 127.663 89.4196 127.148 90.4493C125.602 93.6245 126.117 96.9713 126.633 100.146C126.976 101.863 126.89 103.579 126.89 105.295C126.89 107.183 127.234 108.899 127.749 110.616C128.35 112.504 128.779 114.477 128.522 116.365C128.178 118.682 128.264 120.913 129.123 122.887C130.239 125.547 130.067 128.294 129.294 130.696C128.264 133.871 128.436 136.875 129.123 139.793C129.895 142.882 129.466 145.628 128.35 148.203C127.491 150.176 127.149 151.127 126.805 153.186"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.853 86.2451C109.03 85.3011 112.207 84.5288 115.298 83.3274C119.677 81.6111 122.253 78.7792 123.112 73.7161C124.4 66.0786 124.228 58.4412 125.259 50.8037C125.774 47.3711 125.946 43.8527 125.774 40.3343C125.688 38.3606 124.915 36.4726 122.769 35.7861"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.3552 184.641C44.5217 190.133 41.455 196.43 39.6518 202.437C38.1063 207.5 37.1617 212.735 35.9596 217.884C35.101 221.745 34.3282 225.693 31.7523 229.04C30.9795 230.069 30.5349 230.343 29.6762 231.115"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.2438 195.401C48.2385 202.867 43.6019 209.561 40.1673 216.855C38.7935 219.687 37.9348 222.605 37.162 225.694C36.3893 228.869 35.0426 231.389 33.4111 234.307"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.846 241.682C81.9622 244.342 83.5287 246.461 82.9277 249.551C81.7256 256.502 82.5842 263.624 81.2963 270.575C80.7811 273.321 79.8656 275.968 80.1231 278.885"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.228 150.091C123.284 152.322 122.167 154.553 122.167 157.128C122.167 157.814 121.824 158.329 121.48 158.758C120.45 160.303 119.763 161.847 119.591 163.735C119.506 164.679 119.162 165.881 118.561 166.567C116.93 168.455 116.415 170.515 116.5 173.003C116.586 174.634 115.728 176.093 114.697 177.294C113.667 178.495 113.066 179.868 112.551 181.413C112.035 183.215 110.592 183.832 109.819 185.635"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.723 235.133C129.809 236.592 129.38 238.05 128.693 239.252C127.577 241.054 126.718 243.028 125.602 244.83C123.971 247.49 121.824 249.635 118.819 251.008C112.207 254.098 105.853 257.445 100.014 261.907"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.938 227.754C91.6856 227.496 94.1757 227.668 95.7213 230.414C96.5799 232.045 97.0951 233.847 97.6961 235.563C98.9841 238.995 100.014 242.6 100.186 246.29C100.272 249.293 99.1558 252.125 98.2972 254.957C97.782 256.673 96.2365 258.046 96.7516 260.106"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.853 86.6748C108.086 88.1337 110.662 88.7344 113.066 89.7641C117.359 91.6521 120.107 94.4839 119.334 100.405C118.991 103.323 118.819 106.155 118.218 109.072"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.461 228.097C124.915 230.843 123.713 233.76 121.566 236.163C116.415 241.913 110.232 246.547 103.792 250.752"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.602 254.527C125.001 260.792 123.284 266.713 121.223 272.634C120.794 273.921 119.85 275.232 118.734 276.176"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.409 272.685C118.697 275.688 119.678 279.328 120.107 282.503C120.794 287.652 120.622 292.801 121.309 297.95"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.259 213.681C119.162 218.744 114.096 224.751 108.944 230.758C108.172 231.616 107.399 232.56 106.712 233.418"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6864 234.274C24.9691 236.077 24.0246 238.393 22.5649 240.453C19.8172 244.315 16.8978 248.176 14.7512 252.381"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4036 235.991C27.459 241.827 25.2266 247.233 23.08 252.639C22.9941 252.897 22.8224 252.897 22.6506 252.811"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8382 238.052C31.1513 243.716 30.7219 249.465 28.7471 254.957"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.8569 133.787C93.3166 137.82 95.2915 141.339 98.9837 143.913C101.044 145.372 102.676 147.432 104.994 148.547"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.606 188.192C111.863 191.11 110.49 193.17 108.343 194.972C106.282 196.688 104.565 198.662 103.878 201.494C103.62 202.695 103.019 204.154 101.731 205.012"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3672 81.8691C94.6908 83.4996 99.7567 85.9024 105.424 86.4173"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.769 37.8457C119.248 41.0208 117.96 45.1399 118.046 49.7739C118.132 54.1505 118.046 58.527 118.304 62.9894"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.344 252.469C104.823 253.67 102.247 256.33 99.242 258.39C95.0346 261.308 93.7467 265.856 92.4587 270.318C92.0294 271.777 91.7217 273.479 91.2065 274.852"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.545 99.2031C105.08 99.9755 101.302 102.035 98.6406 105.811C97.7819 106.926 96.8374 108.042 95.9788 109.072"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.938 128.896C91.1705 130.955 92.6301 133.701 95.2919 135.246C97.181 136.361 99.07 137.391 101.302 137.906"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.918 105.812C94.6049 108.558 96.8374 110.188 98.2112 112.419C101.302 117.482 105.853 121.258 110.06 125.377C110.833 126.15 110.783 126.158 112.048 126.94"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7053 110.531C88.9378 111.99 91.1703 113.535 93.4886 114.822C95.8069 116.109 98.3829 116.796 101.131 116.882"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.5997 114.221C90.14 115.508 89.1545 115.786 87.3513 116.044"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.389 52.2627C119.42 53.6357 119.935 55.2662 120.621 56.8109"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.279 41.707C121.309 42.9084 121.91 44.3673 122.511 45.8261"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1179 149.833C61.2896 151.292 61.633 152.665 62.749 153.78"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.388 258.358C143.13 262.22 143.729 265.514 143.729 269.289C143.729 271.521 143.643 273.838 143.729 276.069C143.987 287.225 143.214 298.295 143.214 309.451C143.214 312.797 143.042 316.23 143.386 319.577C143.901 324.811 144.931 329.96 144.502 335.195C144.073 340.172 143.987 345.235 143.472 350.213C143.042 355.018 142.871 359.995 143.987 364.887C144.416 366.689 144.674 367.333 144.502 369.307"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.711 267.268C165.434 269.555 165.625 269.375 166.054 270.319C167.514 273.751 167.771 277.441 168.029 281.045C168.544 288.511 168.115 295.977 166.741 303.271C164.938 312.711 165.11 322.236 165.281 331.676C165.367 335.28 165.281 338.131 165.281 341.735C165.281 342.679 165.024 343.962 165.024 345.063"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.436 79.6387C137.976 80.3252 137.117 81.5266 136.345 82.8996C135.572 84.2727 134.885 85.7315 133.94 87.0187C133.168 88.0485 133.254 89.4215 133.769 90.4513C135.314 93.6264 134.799 96.9732 134.284 100.148C133.94 101.865 134.026 103.581 134.026 105.297C134.026 107.185 133.683 108.901 133.168 110.618C132.567 112.506 132.137 114.479 132.395 116.367C132.738 118.684 132.653 120.915 131.794 122.889C130.678 125.549 130.849 128.295 131.622 130.698C132.653 133.873 132.481 136.877 131.794 139.795C131.021 142.884 131.45 145.63 132.567 148.204C133.425 150.178 133.768 151.129 134.111 153.188"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.063 86.247C151.886 85.303 148.71 84.5307 145.618 83.3293C141.239 81.613 138.663 78.7811 137.805 73.7181C136.517 66.0806 136.689 58.4431 135.658 50.8056C135.143 47.373 134.971 43.8546 135.143 40.3363C135.229 38.3625 136.002 36.4746 138.148 35.7881"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.561 184.643C216.395 190.135 219.462 196.432 221.265 202.439C222.81 207.502 223.755 212.737 224.957 217.886C225.816 221.747 226.588 225.695 229.164 229.041C229.937 230.071 230.382 230.345 231.24 231.117"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M209.673 195.403C212.678 202.869 217.315 209.563 220.749 216.857C222.123 219.689 222.982 222.607 223.754 225.696C224.527 228.871 225.874 231.391 227.505 234.309"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.071 241.684C178.954 244.344 177.388 246.463 177.989 249.553C179.191 256.504 178.332 263.626 179.62 270.577C180.135 273.323 181.051 275.97 180.793 278.887"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.688 150.093C137.633 152.324 138.749 154.555 138.749 157.13C138.749 157.816 139.093 158.331 139.436 158.76C140.466 160.305 141.153 161.849 141.325 163.737C141.411 164.681 141.754 165.883 142.355 166.569C143.987 168.457 144.502 170.517 144.416 173.005C144.33 174.636 145.189 176.095 146.219 177.296C147.25 178.497 147.851 179.87 148.366 181.415C148.881 183.217 150.325 183.834 151.098 185.636"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.193 235.135C131.107 236.594 131.537 238.052 132.223 239.254C133.34 241.056 134.198 243.03 135.315 244.832C136.946 247.492 139.093 249.637 142.098 251.01C148.709 254.1 155.063 257.447 160.902 261.909"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.979 227.756C169.231 227.498 166.741 227.67 165.195 230.416C164.337 232.046 163.821 233.849 163.22 235.565C161.932 238.997 160.902 242.602 160.73 246.292C160.644 249.295 161.761 252.127 162.619 254.959C163.135 256.675 164.68 258.048 164.165 260.108"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.063 86.6768C152.831 88.1356 150.255 88.7363 147.851 89.7661C143.558 91.654 140.81 94.4859 141.583 100.407C141.926 103.325 142.098 106.157 142.699 109.074"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.456 228.099C136.001 230.845 137.203 233.762 139.35 236.165C144.502 241.915 150.684 246.549 157.124 250.754"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.314 254.529C135.915 260.794 137.633 266.715 139.693 272.636C140.123 273.923 141.066 275.234 142.183 276.178"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.508 272.687C142.22 275.69 141.239 279.33 140.81 282.505C140.123 287.654 140.294 292.803 139.608 297.952"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.658 213.683C141.754 218.746 146.82 224.753 151.972 230.76C152.745 231.618 153.518 232.562 154.205 233.42"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M234.23 234.276C235.948 236.078 236.892 238.395 238.352 240.455C241.1 244.317 244.019 248.178 246.166 252.383"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.513 235.993C233.457 241.829 235.69 247.235 237.836 252.641C237.922 252.899 238.094 252.899 238.266 252.813"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.078 238.054C229.765 243.717 230.195 249.467 232.169 254.959"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.06 133.789C167.6 137.822 165.625 141.341 161.933 143.915C159.872 145.374 158.241 147.434 155.922 148.549"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.31 188.194C149.053 191.112 150.427 193.172 152.573 194.974C154.634 196.69 156.351 198.664 157.038 201.496C157.296 202.697 157.897 204.156 159.185 205.014"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.549 81.8711C166.226 83.5016 161.16 85.9044 155.493 86.4193"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.148 37.8477C141.668 41.0228 142.956 45.1419 142.871 49.7759C142.785 54.1524 142.871 58.529 142.613 62.9913"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.573 252.471C156.093 253.672 158.669 256.332 161.675 258.392C165.882 261.31 167.17 265.858 168.458 270.32C168.887 271.779 169.195 273.481 169.71 274.854"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.371 99.2051C155.836 99.9774 159.614 102.037 162.276 105.813C163.135 106.928 164.079 108.044 164.938 109.074"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.979 128.897C169.746 130.957 168.286 133.703 165.625 135.248C163.736 136.363 161.847 137.393 159.614 137.908"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.999 105.813C166.312 108.56 164.079 110.19 162.705 112.421C159.614 117.484 155.063 121.26 150.856 125.379C150.083 126.152 150.134 126.16 148.869 126.942"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.211 110.532C171.978 111.991 169.746 113.536 167.428 114.823C165.109 116.11 162.533 116.797 159.786 116.882"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.317 114.222C170.776 115.509 171.762 115.787 173.565 116.045"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.527 52.2646C141.497 53.6377 140.982 55.2682 140.295 56.8128"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.638 41.709C139.608 42.9104 139.007 44.3692 138.406 45.8281"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.799 149.836C199.627 151.295 199.284 152.668 198.168 153.783"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.8807 275.979C92.4262 270.401 96.0325 266.711 100.927 263.793C106.336 260.618 112.347 259.417 118.271 257.7C121.878 256.671 125.055 254.697 127.631 252.208C129.262 250.664 129.605 247.832 130.206 245.515C131.065 242.168 130.979 238.821 130.292 235.389C130.121 234.359 129.52 233.672 129.004 232.9C126.686 229.553 125.999 225.692 125.742 221.744C125.656 221.143 125.656 220.543 125.742 219.856C126.085 216.166 125.484 212.476 124.454 208.958C123.853 206.555 123.938 204.152 123.681 201.749C123.423 199.861 123.509 197.802 122.822 196C120.761 190.164 116.554 186.989 111.059 187.418C108.483 187.676 105.993 188.877 103.417 189.478C100.154 190.164 97.1488 191.366 95.2597 194.541C94.5728 195.742 92.9414 196.429 91.9969 197.63C89.5068 200.891 89.0775 205.01 87.4461 208.615C85.4712 213.077 83.7539 217.797 82.9812 222.774C82.3801 226.292 82.1225 229.897 81.7791 233.501C81.4356 237.191 80.9204 240.795 80.4911 244.399C79.89 248.776 79.1173 253.152 79.1173 257.529C79.0314 268.341 79.6325 279.154 81.6073 289.881C82.8953 297.175 84.0115 304.469 85.8147 311.678C87.1026 316.741 89.1634 321.461 90.5372 326.524C92.0828 332.016 93.8001 337.508 95.4315 343C96.2901 345.918 96.2042 349.007 96.4618 352.096C96.7194 356.044 96.6336 360.077 96.5477 364.025C96.5477 367.028 95.8608 370.117 95.2597 373.121C94.0576 379.557 93.1131 385.993 92.6838 392.429C92.2545 399.294 91.3959 406.16 91.4817 413.025C91.5676 415.685 91.9969 418.345 92.7697 420.834C93.8001 424.095 96.4618 425.983 99.4671 427.442C100.154 427.785 100.583 427.527 101.099 427.184C102.988 425.983 103.932 424.181 104.619 422.035C106.165 417.487 106.336 412.767 106.25 408.048C106.165 397.578 106.079 387.023 106.25 376.554C106.336 371.92 107.109 367.2 107.367 362.566C107.624 358.533 107.796 354.499 107.109 350.38C106.766 348.664 105.563 347.977 104.619 346.862"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.545 252.638C128.06 254.526 127.287 256.328 126.858 257.958C125.999 261.133 125.913 264.48 125.312 267.655C124.196 274.177 124.11 280.871 123.767 287.393C123.509 292.112 123.08 296.832 122.736 301.552C122.393 307.216 122.221 312.794 121.62 318.457C121.191 322.92 120.762 327.468 121.191 332.016C121.62 336.736 121.792 341.456 122.05 346.261C122.307 349.522 122.393 352.697 121.964 355.958C121.792 357.417 121.191 358.704 120.676 360.077C119.388 363.253 118.257 367.358 116.626 370.447"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.766 336.307C108.827 339.911 110.458 343.773 113.807 346.347C114.665 347.033 114.665 347.806 114.579 348.664C114.322 352.611 114.408 356.473 114.408 360.421C114.408 363.767 115.181 367.028 116.554 370.118C116.469 371.23 116.383 371.233 116.469 371.748C117.327 377.669 117.842 383.59 118.272 389.426C118.959 398.522 120.762 407.618 120.504 416.801C120.418 419.718 119.903 422.464 119.044 425.21C118.873 425.811 118.615 426.412 118.186 426.841C115.181 429.844 114.665 429.415 111.832 427.27C108.827 424.953 108.14 421.435 107.281 418.002C107.195 417.659 106.533 414.16 106.189 413.902"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.948 44.4532C111.488 45.1397 110.63 44.11 109.857 43.166C107.195 39.8192 105.907 35.7859 104.877 31.6669C104.705 30.9803 104.791 30.1222 105.049 29.4357C105.564 27.9768 106.508 27.6336 107.71 28.4059C109.599 29.5215 110.458 30.8945 110.2 33.1257C109.857 37.159 111.488 40.9348 113.12 44.3674C114.751 47.7142 115.352 51.2326 116.125 54.751C116.554 56.8963 116.898 59.1275 117.07 61.3587C117.327 64.9629 116.812 66.6792 113.463 68.3096C107.109 71.399 101.099 75.1748 94.4872 77.8351C92.3406 78.6932 90.0223 78.8648 87.8757 79.6372C81.5217 82.1258 76.7992 86.1591 74.0515 92.4235C71.8191 97.6582 69.5866 102.893 68.2128 108.471C67.3541 111.903 66.9248 115.26 68.2128 118.692C68.4704 119.293 68.7389 119.415 69.1003 120.558"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.4207 129.495C91.5673 131.726 91.9107 134.73 92.6835 137.562C94.7442 145.371 96.6333 153.266 98.7799 161.075C99.5526 163.993 100.068 167.168 99.982 170.171C99.8961 174.204 99.5526 178.238 98.8657 182.185C98.7799 182.872 98.3505 183.387 98.0929 184.073C96.8908 187.162 95.6887 190.252 94.3149 193.341C93.9715 194.113 93.0914 195.457 93.4348 196.23"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.794 425.771C118.794 429.263 118.271 431.647 118.186 433.878C118.1 438.598 117.499 443.318 117.241 448.124C117.069 450.955 117.327 453.873 116.726 456.533C114.837 464.514 115.696 472.581 115.266 480.647C115.095 483.651 115.781 486.74 116.64 489.744C116.898 490.688 117.327 491.46 118.271 491.803"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.195 261.306C107.624 263.194 107.281 265.167 107.195 267.055C107.109 271.26 107.452 275.465 107.796 279.584C107.882 280.614 107.624 281.644 107.624 282.673C107.624 287.307 107.796 291.856 107.796 296.49C107.796 303.441 107.796 310.392 107.624 317.257C107.538 320.689 106.937 324.294 106.508 327.812C106.25 329.871 105.821 332.017 106.164 334.162C106.594 336.393 105.649 338.539 105.306 340.77C104.791 344.46 104.19 348.15 103.073 351.668C101.871 355.787 100.068 359.649 98.0932 363.425C97.8356 363.854 97.4922 364.369 96.8911 364.455"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.0753 176.865C55.2471 178.41 54.8177 179.869 54.646 181.413C54.5601 181.928 54.4743 182.529 54.4743 183.044C55.0753 193.342 51.9842 202.438 46.2313 210.848C42.9685 215.653 39.9632 220.716 38.5894 226.466C38.1601 228.354 36.8721 230.156 37.1297 232.216"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.362 193.941C117.842 192.568 114.837 194.284 112.347 196.258C109.685 198.403 106.765 200.291 104.876 203.209C104.533 203.81 103.932 203.896 103.417 204.239C93.9717 209.302 89.0774 217.626 86.3297 227.58C85.6428 230.155 84.0114 232.3 82.2083 234.188"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.184 484.337C99.1237 486.826 96.6336 488.542 93.1132 488.027C91.8252 487.856 91.0524 489.143 89.7645 488.971C89.3352 488.886 89.0776 489.744 89.1634 490.344C89.2493 491.117 89.2493 491.546 90.1938 491.803C92.1687 492.49 94.4011 492.661 96.2043 494.034C98.3509 495.665 100.927 496.78 103.159 498.154C106.766 500.385 110.544 501.157 114.751 500.814C116.64 500.642 118.958 498.754 118.271 496.266C117.756 494.464 118.014 492.661 118.872 490.945C119.817 489.057 120.332 487.169 119.989 484.938C119.731 483.393 119.731 481.763 119.989 480.218C120.16 478.931 120.16 477.815 119.731 476.7C117.241 471.036 117.499 465.115 117.499 459.194C117.499 458.593 117.047 457.196 117.047 453.945"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.622 134.644C75.5969 134.729 75.0817 136.102 75.0817 137.304C75.0817 141.08 74.3948 144.941 75.8545 148.631C76.3697 149.919 76.6273 151.206 77.9153 151.892C79.976 153.094 79.976 155.067 79.5467 157.041C78.9456 159.873 77.9153 162.619 76.4556 165.022C75.1676 167.081 75.0817 169.398 74.5666 171.63C74.309 173.088 74.2231 174.805 71.9048 175.062C70.9603 175.148 69.7582 175.835 68.9854 176.778C65.8084 180.812 62.2021 184.502 59.3686 188.793C57.3078 191.882 56.2775 195.486 55.2471 199.09C54.7319 200.721 54.045 202.265 53.3581 203.81C50.7822 209.56 47.4335 214.794 44.0847 220.115C43.7413 220.716 43.0185 222.788 43.0186 223.921"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.7631 175.235C74.3405 173.174 73.1066 175.664 72.7631 176.093C68.9851 180.556 67.6971 186.134 65.894 191.54C64.2626 196.603 61.2573 201.065 58.3379 205.528C55.5903 209.647 52.2416 213.337 48.8928 217.027C45.63 220.545 42.5389 224.235 40.4782 228.612C39.5336 230.671 37.8164 232.216 36.2708 233.675C34.8111 235.134 34.9828 237.107 34.0383 238.652"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.9165 442.146C99.9576 444.147 100.583 445.378 101.013 446.665C104.361 455.161 107.195 463.742 109.256 472.581C110.114 476.357 110.286 480.133 109.771 483.909C109.513 486.14 109.513 488.628 107.109 490.087"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.134 231.698C24.3361 232.042 25.3665 232.642 26.2251 233.586C28.0282 235.474 30.2607 236.761 32.4073 238.049C33.9529 238.992 34.4681 240.28 34.4681 241.996C34.4681 244.656 34.2963 247.231 33.4377 249.805C31.7204 255.126 30.1749 260.618 27.5989 265.595C26.7403 267.311 25.8816 269.113 24.5078 270.572C23.735 271.345 23.735 271.345 21.9319 271.173C23.4774 267.311 25.1947 263.621 26.9979 259.845"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.616 422.582C93.7018 425.328 95.2599 429.074 95.8609 431.648C96.6337 435.252 97.8358 438.685 98.7803 442.203C99.5531 445.293 100.154 448.554 100.583 451.815C101.013 455.333 102.129 458.68 102.73 462.112C103.331 465.202 103.675 468.291 104.276 471.38C104.705 473.612 104.877 475.928 104.447 478.16C104.018 480.391 103.009 482.476 101.206 484.106C103.867 487.882 106.165 491.289 107.109 496.267C105.134 495.237 103.589 494.379 101.871 493.52C102.902 492.491 104.447 492.577 105.478 491.718"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.1969 171.287C55.2471 176.35 51.0398 181.242 46.3172 185.618C42.2816 189.308 40.2209 193.856 38.7612 198.919C37.6449 202.953 35.9277 206.729 34.0386 210.419C31.9779 214.538 30.6041 219.086 28.3716 223.119C26.225 227.152 24.0784 231.443 18.7548 232.473C16.093 232.988 14.1181 234.618 13.0019 237.193C11.4563 240.539 9.22388 243.457 6.64795 246.032C5.96103 246.718 5.53171 247.49 5.01653 248.349C4.58721 249.035 4.24375 249.807 5.01653 250.58C5.70344 251.266 6.47622 251.095 7.249 250.751C9.99665 249.464 11.7998 247.233 13.0019 244.573"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.299 1C124.817 1.36125 122.307 2.31803 119.903 3.60525C113.978 6.78038 110.629 11.586 109.427 18.1079C108.826 21.1972 108.998 24.1149 109.427 27.2043C109.513 27.9766 109.942 28.7489 109.427 29.4354"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.472 91.8223C107.796 95.0832 111.316 99.6314 113.12 105.81C114.064 108.985 115.438 112.16 116.554 115.335C118.529 121.171 120.075 127.178 121.191 133.185C121.62 135.588 121.448 137.99 122.049 140.393C123.337 145.8 124.711 151.12 128.575 155.411C129.176 156.097 129.434 157.041 129.863 157.899"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5727 246.459C13.0879 248.089 12.6585 249.634 12.401 251.179C11.6282 255.04 11.0271 258.988 10.4261 262.935C9.91089 266.368 9.05225 269.715 9.05225 273.233C9.05225 275.293 9.3957 275.979 11.5423 275.722"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.7593 109.758C86.7593 113.362 87.4462 116.795 88.3907 120.313C88.4766 120.742 88.6483 121.343 88.9059 121.514C92.3405 123.831 95.4316 126.663 99.8106 127.35C105.306 128.294 110.715 128.036 115.867 125.805C116.898 125.376 118.1 125.204 118.958 124.432"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.796 318.201C110.715 323.093 113.549 328.07 114.15 333.819C114.579 337.681 115.18 341.629 114.579 345.576C114.579 345.833 114.322 346.091 114.15 346.434"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.08 35.7861C123.595 41.7931 123.337 47.8002 121.878 53.7214C121.191 56.4674 120.332 59.2135 119.044 61.7879C118.701 62.3886 118.529 63.0752 117.756 63.2468"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.1212 142.882C64.9495 145.971 63.8332 148.889 63.4039 151.892C63.0604 154.467 62.8029 157.127 62.2877 159.701C61.7725 162.619 61.6008 165.537 60.6562 168.369"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.82 121.686C88.1331 122.887 89.0776 124.003 88.9917 125.204C88.9058 132.327 87.6179 139.02 83.754 145.199C82.3802 147.344 81.5215 150.004 80.4053 152.321C80.2335 152.75 80.0618 153.179 79.5466 153.437"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.939 164.766C53.873 167.941 50.9536 172.918 47.8625 177.809C46.0594 180.641 44.5138 183.645 43.2259 186.82C42.9683 187.592 42.7773 188.254 42.7773 189.669"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.714 113.322C76.7088 115.124 72.5917 116.537 70.2734 119.454C67.5257 122.801 65.7226 126.577 64.7781 130.782C64.0053 133.871 63.4042 136.961 62.7173 140.05C62.288 141.938 63.3184 143.139 65.2074 142.71C66.3236 142.453 67.4398 142.024 68.2985 141.166C70.1875 139.363 71.9906 137.561 72.9352 135.159C73.7938 133.099 74.309 130.782 74.5666 128.551C75.1676 123.831 77.2284 119.626 78.6881 115.164C79.375 113.104 80.7488 112.246 82.6378 111.388C87.9614 109.071 93.1991 106.668 97.7499 102.806C99.0379 101.691 99.8965 100.232 101.184 99.2881"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5526 256.586C15.6636 262.765 13.6887 268.943 11.7997 275.122C11.1986 277.181 12.0573 278.554 13.6028 278.211C14.6332 277.954 15.3201 277.181 15.6636 276.152C17.5526 270.402 20.3002 264.91 22.4468 259.246C22.5327 258.989 22.7044 258.989 22.8762 259.075"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.4593 142.282C61.5148 144.342 61.3431 146.487 61.0855 148.633C60.5703 152.494 59.5399 156.27 58.853 160.132C58.5954 161.676 57.4792 163.049 57.0499 164.68C56.964 164.937 56.4792 166.169 56.7368 166.341"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.8849 151.722C76.6273 157.471 74.5666 162.706 72.0765 167.769C71.8189 168.284 71.9906 168.713 71.9048 169.228"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.583 170.687C102.644 175.321 106.422 178.925 108.654 183.473C109.256 184.674 109.771 185.962 110.458 187.163"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.1689 489.143C93.1993 490.087 94.4873 490.601 95.8611 490.344C97.2349 490.087 98.3512 491.031 99.5533 491.116C101.356 491.288 103.16 491.202 104.877 490.516"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9675 227.751C28.7152 230.583 31.6346 233.157 34.8115 235.388"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8458 271.603C20.9013 272.976 20.3861 274.606 19.3557 275.893C17.896 277.867 17.295 278.124 15.6636 276.923"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.644 482.535C104.533 484.766 105.649 487.427 106.851 490.001C107.882 492.146 109.084 494.292 110.028 496.523"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.939 160.988C59.1965 161.332 59.3682 161.589 59.3682 162.018C58.939 164.936 59.7116 167.596 61.2568 170.085"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.8244 168.025C72.8495 168.369 71.7333 170.171 69.8442 170.686"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.777 275.979C169.232 270.401 165.625 266.711 160.731 263.793C155.322 260.618 149.311 259.417 143.387 257.7C139.78 256.671 136.603 254.697 134.027 252.208C132.396 250.664 132.053 247.832 131.451 245.515C130.593 242.168 130.679 238.821 131.366 235.389C131.537 234.359 132.138 233.672 132.654 232.9C134.972 229.553 135.659 225.692 135.916 221.744C136.002 221.143 136.002 220.543 135.916 219.856C135.573 216.166 136.174 212.476 137.204 208.958C137.805 206.555 137.72 204.152 137.977 201.749C138.235 199.861 138.149 197.802 138.836 196C140.897 190.164 145.104 186.989 150.599 187.418C153.175 187.676 155.665 188.877 158.241 189.478C161.504 190.164 164.509 191.366 166.398 194.541C167.085 195.742 168.717 196.429 169.661 197.63C172.151 200.891 172.58 205.01 174.212 208.615C176.187 213.077 177.904 217.797 178.677 222.774C179.278 226.292 179.535 229.897 179.879 233.501C180.222 237.191 180.738 240.795 181.167 244.399C181.768 248.776 182.541 253.152 182.541 257.529C182.627 268.341 182.026 279.154 180.051 289.881C178.763 297.175 177.646 304.469 175.843 311.678C174.555 316.741 172.495 321.461 171.121 326.524C169.575 332.016 167.858 337.508 166.226 343C165.368 345.918 165.454 349.007 165.196 352.096C164.939 356.044 165.024 360.077 165.11 364.025C165.11 367.028 165.797 370.117 166.398 373.121C167.6 379.557 168.545 385.993 168.974 392.429C169.403 399.294 170.262 406.16 170.176 413.025C170.09 415.685 169.661 418.345 168.888 420.834C167.858 424.095 165.196 425.983 162.191 427.442C161.504 427.785 161.075 427.527 160.559 427.184C158.67 425.983 157.726 424.181 157.039 422.035C155.493 417.487 155.322 412.767 155.408 408.048C155.493 397.578 155.579 387.023 155.408 376.554C155.322 371.92 154.549 367.2 154.291 362.566C154.034 358.533 153.862 354.499 154.549 350.38C154.892 348.664 156.095 347.977 157.039 346.862"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.113 252.638C133.598 254.526 134.371 256.328 134.8 257.958C135.659 261.133 135.745 264.48 136.346 267.655C137.462 274.177 137.548 280.871 137.891 287.393C138.149 292.112 138.578 296.832 138.922 301.552C139.265 307.216 139.437 312.794 140.038 318.457C140.467 322.92 140.897 327.468 140.467 332.016C140.038 336.736 139.866 341.456 139.609 346.261C139.351 349.522 139.265 352.697 139.694 355.958C139.866 357.417 140.467 358.704 140.982 360.077C142.27 363.253 143.401 367.358 145.032 370.447"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.892 336.307C152.831 339.911 151.2 343.773 147.851 346.347C146.993 347.033 146.993 347.806 147.078 348.664C147.336 352.611 147.25 356.473 147.25 360.421C147.25 363.767 146.477 367.028 145.104 370.118C145.189 371.23 145.275 371.233 145.189 371.748C144.331 377.669 143.816 383.59 143.386 389.426C142.699 398.522 140.896 407.618 141.154 416.801C141.24 419.718 141.755 422.464 142.614 425.21C142.785 425.811 143.043 426.412 143.472 426.841C146.477 429.844 146.993 429.415 149.826 427.27C152.831 424.953 153.518 421.435 154.377 418.002C154.463 417.659 155.125 414.16 155.469 413.902"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.71 44.4522C150.17 45.1388 151.028 44.109 151.801 43.165C154.463 39.8183 155.751 35.785 156.781 31.6659C156.953 30.9794 156.867 30.1212 156.61 29.4347C156.094 27.9759 155.15 27.6326 153.948 28.4049C152.059 29.5205 151.2 30.8935 151.458 33.1247C151.801 37.158 150.17 40.9338 148.538 44.3664C146.907 47.7132 146.306 51.2316 145.533 54.75C145.104 56.8953 144.76 59.1265 144.589 61.3577C144.331 64.9619 144.846 66.6782 148.195 68.3087C154.549 71.398 160.559 75.1738 167.171 77.8341C169.318 78.6922 171.636 78.8638 173.783 79.6362C180.136 82.1248 184.859 86.1581 187.607 92.4225C189.839 97.6572 192.072 102.892 193.445 108.47C194.304 111.902 194.733 115.259 193.445 118.691C193.188 119.292 192.919 119.414 192.558 120.557"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.237 129.494C170.091 131.725 169.747 134.729 168.974 137.561C166.914 145.37 165.025 153.265 162.878 161.074C162.105 163.992 161.59 167.167 161.676 170.17C161.762 174.203 162.105 178.237 162.792 182.184C162.878 182.871 163.307 183.386 163.565 184.072C164.767 187.161 165.969 190.251 167.343 193.34C167.687 194.112 168.567 195.456 168.223 196.229"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.864 425.771C142.864 429.263 143.386 431.647 143.472 433.878C143.558 438.598 144.159 443.318 144.417 448.124C144.589 450.955 144.331 453.873 144.932 456.533C146.821 464.514 145.962 472.581 146.392 480.647C146.563 483.651 145.877 486.74 145.018 489.744C144.76 490.688 144.331 491.46 143.386 491.803"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.463 261.306C154.034 263.194 154.377 265.167 154.463 267.055C154.549 271.26 154.206 275.465 153.862 279.584C153.776 280.614 154.034 281.644 154.034 282.673C154.034 287.307 153.862 291.856 153.862 296.49C153.862 303.441 153.862 310.392 154.034 317.257C154.12 320.689 154.721 324.294 155.15 327.812C155.408 329.871 155.837 332.017 155.494 334.162C155.064 336.393 156.009 338.539 156.352 340.77C156.867 344.46 157.468 348.15 158.585 351.668C159.787 355.787 161.59 359.649 163.565 363.425C163.822 363.854 164.166 364.369 164.767 364.455"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.583 176.864C206.411 178.409 206.84 179.868 207.012 181.412C207.098 181.927 207.184 182.528 207.184 183.043C206.583 193.341 209.674 202.437 215.427 210.847C218.689 215.652 221.695 220.715 223.069 226.465C223.498 228.353 224.786 230.155 224.528 232.215"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.295 193.941C143.816 192.568 146.821 194.284 149.311 196.258C151.973 198.403 154.892 200.291 156.781 203.209C157.125 203.81 157.726 203.896 158.241 204.239C167.686 209.302 172.58 217.626 175.328 227.58C176.015 230.155 177.646 232.3 179.449 234.188"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.474 484.337C162.534 486.826 165.024 488.542 168.545 488.027C169.833 487.856 170.606 489.143 171.893 488.971C172.323 488.886 172.58 489.744 172.495 490.344C172.409 491.117 172.409 491.546 171.464 491.803C169.489 492.49 167.257 492.661 165.454 494.034C163.307 495.665 160.731 496.78 158.499 498.154C154.892 500.385 151.114 501.157 146.907 500.814C145.018 500.642 142.7 498.754 143.387 496.266C143.902 494.464 143.644 492.661 142.785 490.945C141.841 489.057 141.326 487.169 141.669 484.938C141.927 483.393 141.927 481.763 141.669 480.218C141.498 478.931 141.498 477.815 141.927 476.7C144.417 471.036 144.159 465.115 144.159 459.194C144.159 458.593 144.611 457.196 144.611 453.945"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.036 134.643C186.061 134.728 186.576 136.101 186.576 137.303C186.576 141.079 187.263 144.94 185.803 148.63C185.288 149.918 185.03 151.205 183.742 151.891C181.682 153.093 181.682 155.066 182.111 157.04C182.712 159.872 183.742 162.618 185.202 165.021C186.49 167.08 186.576 169.397 187.091 171.629C187.349 173.087 187.435 174.804 189.753 175.061C190.697 175.147 191.9 175.834 192.672 176.778C195.849 180.811 199.456 184.501 202.289 188.792C204.35 191.881 205.38 195.485 206.411 199.089C206.926 200.72 207.613 202.264 208.3 203.809C210.876 209.559 214.224 214.793 217.573 220.114C217.916 220.715 218.639 222.787 218.639 223.92"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.895 175.234C187.317 173.173 188.551 175.663 188.895 176.092C192.673 180.555 193.961 186.133 195.764 191.539C197.395 196.602 200.4 201.064 203.32 205.527C206.067 209.646 209.416 213.336 212.765 217.026C216.028 220.544 219.119 224.234 221.18 228.611C222.124 230.67 223.841 232.215 225.387 233.674C226.847 235.133 226.675 237.106 227.619 238.651"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.741 442.146C161.7 444.147 161.074 445.378 160.645 446.665C157.296 455.161 154.463 463.742 152.402 472.581C151.543 476.357 151.372 480.133 151.887 483.909C152.145 486.14 152.145 488.628 154.549 490.087"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M238.524 231.698C237.322 232.042 236.292 232.642 235.433 233.586C233.63 235.474 231.397 236.761 229.251 238.049C227.705 238.992 227.19 240.28 227.19 241.996C227.19 244.656 227.362 247.231 228.221 249.805C229.938 255.126 231.483 260.618 234.059 265.595C234.918 267.311 235.777 269.113 237.15 270.572C237.923 271.345 237.923 271.345 239.726 271.173C238.181 267.311 236.463 263.621 234.66 259.845"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.042 422.582C167.956 425.328 166.398 429.074 165.797 431.648C165.024 435.252 163.822 438.685 162.878 442.203C162.105 445.293 161.504 448.554 161.075 451.815C160.645 455.333 159.529 458.68 158.928 462.112C158.327 465.202 157.984 468.291 157.383 471.38C156.953 473.612 156.782 475.928 157.211 478.16C157.64 480.391 158.649 482.476 160.453 484.106C157.791 487.882 155.494 491.289 154.549 496.267C156.524 495.237 158.069 494.379 159.787 493.52C158.756 492.491 157.211 492.577 156.18 491.718"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.461 171.286C206.411 176.349 210.618 181.241 215.341 185.617C219.376 189.307 221.437 193.855 222.897 198.918C224.013 202.952 225.73 206.728 227.619 210.418C229.68 214.537 231.054 219.085 233.286 223.118C235.433 227.151 237.58 231.442 242.903 232.472C245.565 232.987 247.54 234.617 248.656 237.192C250.202 240.538 252.434 243.456 255.01 246.031C255.697 246.717 256.126 247.489 256.641 248.348C257.071 249.034 257.414 249.806 256.641 250.579C255.955 251.265 255.182 251.094 254.409 250.75C251.661 249.463 249.858 247.232 248.656 244.572"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.299 1C136.503 1 139.351 2.31699 141.755 3.60421C147.68 6.77934 151.029 11.585 152.231 18.1069C152.832 21.1962 152.66 24.1139 152.231 27.2032C152.145 27.9755 151.716 28.7479 152.231 29.4344"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.185 91.8223C153.862 95.0832 150.341 99.6314 148.538 105.81C147.594 108.985 146.22 112.16 145.104 115.335C143.129 121.171 141.583 127.178 140.467 133.185C140.038 135.588 140.209 137.99 139.608 140.393C138.32 145.8 136.946 151.12 133.083 155.411C132.482 156.097 132.224 157.041 131.795 157.899"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M249.085 246.459C248.57 248.089 248.999 249.634 249.257 251.179C250.03 255.04 250.631 258.988 251.232 262.935C251.747 266.368 252.606 269.715 252.606 273.233C252.606 275.293 252.262 275.979 250.116 275.722"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.899 109.758C174.899 113.362 174.212 116.795 173.267 120.313C173.181 120.742 173.01 121.343 172.752 121.514C169.318 123.831 166.226 126.663 161.847 127.35C156.352 128.294 150.943 128.036 145.791 125.805C144.76 125.376 143.558 125.204 142.7 124.432"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.862 318.201C150.943 323.093 148.109 328.07 147.508 333.819C147.079 337.681 146.478 341.629 147.079 345.576C147.079 345.833 147.336 346.091 147.508 346.434"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.578 35.7852C138.063 41.7922 138.321 47.7992 139.78 53.7204C140.467 56.4665 141.326 59.2125 142.614 61.787C142.957 62.3877 143.129 63.0742 143.902 63.2458"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.537 142.881C196.708 145.97 197.825 148.888 198.254 151.891C198.598 154.466 198.855 157.126 199.37 159.7C199.885 162.618 200.057 165.536 201.002 168.368"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.838 121.686C173.525 122.887 172.58 124.003 172.666 125.204C172.752 132.327 174.04 139.02 177.904 145.199C179.278 147.344 180.136 150.004 181.253 152.321C181.424 152.75 181.596 153.179 182.111 153.437"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.719 164.765C207.785 167.94 210.704 172.917 213.795 177.808C215.598 180.64 217.144 183.644 218.432 186.819C218.689 187.591 218.88 188.253 218.88 189.668"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.944 113.321C184.949 115.123 189.066 116.536 191.385 119.454C194.132 122.8 195.935 126.576 196.88 130.781C197.653 133.87 198.254 136.96 198.941 140.049C199.37 141.937 198.34 143.138 196.451 142.709C195.334 142.452 194.218 142.023 193.359 141.165C191.47 139.362 189.667 137.56 188.723 135.158C187.864 133.098 187.349 130.781 187.091 128.55C186.49 123.83 184.43 119.625 182.97 115.163C182.283 113.103 180.909 112.245 179.02 111.387C173.697 109.07 168.459 106.667 163.908 102.806C162.62 101.69 161.761 100.231 160.473 99.2871"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M244.105 256.586C245.994 262.765 247.969 268.943 249.858 275.122C250.459 277.181 249.601 278.554 248.055 278.211C247.025 277.954 246.338 277.181 245.994 276.152C244.105 270.402 241.358 264.91 239.211 259.246C239.125 258.989 238.954 258.989 238.782 259.075"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.198 142.281C200.143 144.341 200.314 146.486 200.572 148.632C201.087 152.493 202.118 156.269 202.804 160.131C203.062 161.675 204.178 163.048 204.608 164.679C204.693 164.936 205.178 166.168 204.921 166.34"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.773 151.721C185.03 157.47 187.091 162.705 189.581 167.768C189.839 168.283 189.667 168.712 189.753 169.227"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.074 170.686C159.014 175.32 155.236 178.924 153.003 183.472C152.402 184.673 151.887 185.961 151.2 187.162"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.489 489.143C168.459 490.087 167.171 490.601 165.797 490.344C164.423 490.087 163.307 491.031 162.105 491.116C160.302 491.288 158.499 491.202 156.781 490.516"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M235.69 227.753C232.943 230.585 230.023 233.159 226.846 235.39"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.812 271.604C240.756 272.977 241.272 274.607 242.302 275.894C243.762 277.868 244.363 278.125 245.994 276.924"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.014 482.535C157.125 484.766 156.008 487.427 154.806 490.001C153.776 492.146 152.574 494.292 151.629 496.523"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.719 160.988C202.461 161.332 202.29 161.589 202.29 162.018C202.719 164.936 201.946 167.596 200.401 170.085"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.834 168.024C188.808 168.368 189.925 170.17 191.814 170.685"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* part 1 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[0])}
      >
        <g
          filter="url(#filter0_f_33395_33761)"
          onMouseEnter={() => setHover(0)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 0 ? 1 : 0}
            d="M138.193 35.7891C133.175 40.0708 126.193 37.5731 123.466 35.7891C125.866 54.8427 123.92 73.4325 121.011 79.1415C122.647 81.996 124.066 85.5641 130.829 85.5641C137.593 85.5641 138.92 81.996 140.92 79.1415C135.465 71.1133 137.375 44.8877 138.193 35.7891Z"
            fill="url(#paint0_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 4 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[3])}
      >
        <g
          filter="url(#filter1_f_33395_33761)"
          onMouseEnter={() => setHover(3)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 3 ? 1 : 0}
            d="M121.011 79.1415C125.375 72.1837 125.102 48.099 123.466 36.0566C124.557 51.6849 119.92 60.7657 117.466 63.3526C116.375 66.3498 115.011 67.4559 114.466 67.6343L93.4658 78.3386C91.284 78.5527 88.5567 79.4983 87.4658 79.9443C104.92 91.0768 117.102 84.0476 121.011 79.1415Z"
            fill="url(#paint1_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 3 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[2])}
      >
        <g
          filter="url(#filter2_f_33395_33761)"
          onMouseEnter={() => setHover(2)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 2 ? 1 : 0}
            d="M140.656 78.8739C136.292 71.9161 136.829 49.7047 138.193 35.7891C137.102 51.4173 141.747 60.4982 144.202 63.085C145.292 66.0822 146.656 67.1884 147.202 67.3668L168.201 78.0711C170.383 78.2851 173.111 79.2307 174.201 79.6767C156.747 90.8092 144.565 83.78 140.656 78.8739Z"
            fill="url(#paint2_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 7 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[6])}
      >
        <g
          filter="url(#filter3_f_33395_33761)"
          onMouseEnter={() => setHover(6)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 6 ? 1 : 0}
            d="M130.829 85.5642V188.325C123.193 185.739 104.975 183.669 93.1931 196.086C94.6476 191.894 97.8294 183.402 98.9203 182.973C99.3749 180.386 100.229 174.249 100.011 170.396C99.6476 149.433 96.6294 101.995 87.4658 79.9444C94.8294 85.029 110.375 90.6487 121.011 79.1416C121.738 81.2825 124.72 85.5642 130.829 85.5642Z"
            fill="url(#paint3_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 6 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[5])}
      >
        <g
          filter="url(#filter4_f_33395_33761)"
          onMouseEnter={() => setHover(5)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 5 ? 1 : 0}
            d="M130.829 85.5642V188.325C138.465 185.739 156.956 183.669 168.738 196.086C167.284 191.894 164.102 183.402 163.011 182.973C162.556 180.386 161.702 174.249 161.92 170.396C162.284 149.433 165.029 101.728 174.193 79.6768C166.284 85.2966 150.465 89.8459 140.92 79.1416C140.193 81.2825 136.938 85.5642 130.829 85.5642Z"
            fill="url(#paint4_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 9 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[8])}
      >
        <g
          filter="url(#filter5_f_33395_33761)"
          onMouseEnter={() => setHover(8)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 8 ? 1 : 0}
            d="M130.829 245.326V188.325C111.193 182.438 97.5566 191.001 93.1929 195.818C74.4293 232.427 77.9202 276.011 82.0111 293.227C88.0111 267.537 106.556 260.847 107.375 260.847C125.92 257.636 130.738 249.161 130.829 245.326Z"
            fill="url(#paint5_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 8 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[7])}
      >
        <g
          filter="url(#filter6_f_33395_33761)"
          onMouseEnter={() => setHover(7)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 7 ? 1 : 0}
            d="M130.829 245.229V188.325C150.193 182.438 163.556 190.466 168.738 196.086C187.502 232.695 184.011 276.012 179.92 293.228C173.92 267.537 155.375 260.847 154.557 260.847C136.011 257.636 130.829 251.748 130.829 245.229Z"
            fill="url(#paint6_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 11 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[10])}
      >
        <g
          filter="url(#filter7_f_33395_33761)"
          onMouseEnter={() => setHover(10)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 10 ? 1 : 0}
            d="M129.886 248.323C129.869 248.377 129.85 248.432 129.83 248.488L126.616 257.959C124.527 281.781 120.459 329.48 120.895 329.694C121.331 329.908 122.166 342.809 122.529 349.233C122.529 355.443 118.533 366.275 116.535 370.914L119.532 399.554C121.712 413.045 119.896 423.555 118.715 427.124L116.808 456.3C117.026 460.796 118.352 471.377 118.715 474.5C112.612 470.86 106.498 476.199 104.5 479.5C104.936 471.577 97.1002 437.474 93.1042 422.841C87.8731 408.28 93.1042 377.874 96.3737 364.49C96.5917 359.351 95.738 348.073 95.2839 343.077C88.9629 326.16 83.75 302.659 81.9336 293.023C87.6007 267.97 105.001 260.279 112.993 259.565C124.493 256.218 128.953 250.895 129.83 248.488L129.886 248.323Z"
            fill="url(#paint7_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 13 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[12])}
      >
        <g
          filter="url(#filter8_f_33395_33761)"
          onMouseEnter={() => setHover(12)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 12 ? 1 : 0}
            d="M101.804 482.721C112.275 471.159 117.801 472.268 119.255 474.268C120.782 475.577 120.074 480.085 119.528 482.175C120.837 484.357 119.892 488.72 119.255 490.629C117.947 491.719 117.983 495.083 118.165 496.628C118.983 502.354 108.348 500.173 108.075 500.173C107.857 500.173 96.5317 494.719 90.8962 491.992C87.624 490.901 89.1692 489.356 90.3508 488.72C97.3316 488.72 100.895 484.721 101.804 482.721Z"
            fill="url(#paint8_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 12 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[11])}
      >
        <g
          filter="url(#filter9_f_33395_33761)"
          onMouseEnter={() => setHover(11)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 11 ? 1 : 0}
            d="M159.613 482.721C149.142 471.159 143.616 472.268 142.161 474.268C140.634 475.577 141.343 480.085 141.889 482.175C140.58 484.357 141.525 488.72 142.161 490.629C143.47 491.719 143.434 495.083 143.252 496.628C142.434 502.354 153.069 500.173 153.341 500.173C153.559 500.173 164.885 494.719 170.521 491.992C173.793 490.901 172.248 489.356 171.066 488.72C164.085 488.72 160.522 484.721 159.613 482.721Z"
            fill="url(#paint9_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 10 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[9])}
      >
        <g
          filter="url(#filter10_f_33395_33761)"
          onMouseEnter={() => setHover(9)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 9 ? 1 : 0}
            d="M131.52 248.323C131.537 248.377 131.556 248.432 131.576 248.488L134.79 257.959C136.879 281.781 140.947 329.48 140.511 329.694C140.075 329.908 139.24 342.809 138.877 349.233C138.877 355.443 142.873 366.275 144.871 370.914L141.874 399.554C139.694 413.045 141.51 423.555 142.691 427.124L144.598 456.3C144.38 460.796 143.054 471.877 142.691 475C148.794 471.36 155.002 475.199 157 478.5C156.564 470.577 164.306 437.474 168.302 422.841C173.533 408.28 168.302 377.874 165.032 364.49C164.814 359.351 165.668 348.073 166.122 343.077C172.443 326.16 177.656 302.659 179.472 293.023C173.805 267.97 156.405 260.279 148.413 259.565C136.913 256.218 132.453 250.895 131.576 248.488L131.52 248.323Z"
            fill="url(#paint10_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 5 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[4])}
      >
        <g
          filter="url(#filter11_f_33395_33761)"
          onMouseEnter={() => setHover(4)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 4 ? 1 : 0}
            d="M70.5569 101.085C73.6115 86.3134 83.1023 80.8364 87.466 79.9443C95.3747 95.4656 100.466 152.288 99.7383 163.706C94.0656 141.012 90.2838 131.236 89.102 129.184C89.7566 133.252 83.193 147.292 79.8293 153.804C80.7021 156.159 77.2839 164.419 75.4657 168.255C75.2475 171.466 73.7384 174.945 73.0112 176.283C70.8294 177.782 68.6476 183.152 67.8294 185.649C65.6476 198.066 54.5567 211.696 49.284 216.959C44.7022 220.599 40.284 228.467 38.6477 231.945C36.2477 232.16 34.375 236.852 33.7386 239.171C35.9204 243.988 29.375 263.791 26.9205 267.805C24.9568 271.016 23.0114 271.641 22.2841 271.551L19.8296 275.565C18.0841 278.135 16.1932 277.35 15.466 276.636C12.1932 280.061 11.5569 277.35 11.6478 275.565C9.02961 277.064 8.73871 274.406 8.92052 272.889L12.466 246.396L8.64791 250.143C2.53884 252.284 3.73884 249.251 5.10247 247.467C6.62974 245.968 10.2843 240.776 11.9206 238.368C15.1933 232.16 20.0115 231.143 22.0115 231.41C31.8296 220.278 39.5569 199.119 42.1932 189.931C44.375 180.297 52.7386 169.86 56.6477 165.846L62.9205 141.762C62.7023 132.77 66.8296 123.743 68.9206 120.353C66.3024 115.643 68.9206 105.545 70.5569 101.085Z"
            fill="url(#paint11_radial_33395_33761)"
          />
        </g>
      </Popover>

      {/* part 2 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[1])}
      >
        <g
          filter="url(#filter12_f_33395_33761)"
          onMouseEnter={() => setHover(1)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 1 ? 1 : 0}
            d="M191.443 101.353C188.389 86.581 178.556 80.5688 174.193 79.6768C165.738 96.2684 161.465 155.499 162.193 166.917C167.865 144.224 171.716 131.503 172.898 129.452C172.243 133.519 178.807 147.56 182.171 154.072C181.298 156.427 184.716 164.687 186.534 168.522C186.752 171.734 188.262 175.213 188.989 176.551C191.171 178.049 193.352 183.419 194.171 185.917C196.352 198.334 207.443 211.964 212.716 217.227C217.298 220.867 221.716 228.734 223.352 232.213C225.752 232.427 227.625 237.119 228.261 239.438C226.08 244.255 232.625 264.058 235.08 268.072C237.043 271.284 238.989 271.908 239.716 271.819L242.17 275.833C243.916 278.402 245.807 277.617 246.534 276.904C249.807 280.329 250.443 277.617 250.352 275.833C252.97 277.332 253.261 274.673 253.079 273.157L249.534 246.664L253.352 250.41C259.461 252.551 258.261 249.518 256.898 247.734C255.37 246.236 251.716 241.044 250.079 238.636C246.807 232.427 241.988 231.41 239.989 231.678C230.17 220.545 222.443 199.387 219.807 190.199C217.625 180.565 209.261 170.128 205.352 166.114L199.08 142.029C199.298 133.038 195.17 124.01 193.079 120.621C195.698 115.911 193.079 105.813 191.443 101.353Z"
            fill="url(#paint12_radial_33395_33761)"
          />
        </g>
      </Popover>
      <defs>
        <filter
          id="filter0_f_33395_33761"
          x="117.011"
          y="31.7891"
          width="27.9089"
          height="57.7749"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter1_f_33395_33761"
          x="83.4658"
          y="32.0566"
          width="45.0996"
          height="57.8291"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter2_f_33395_33761"
          x="133.213"
          y="31.7891"
          width="44.988"
          height="57.8291"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter3_f_33395_33761"
          x="83.4658"
          y="75.1416"
          width="51.3635"
          height="124.944"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter4_f_33395_33761"
          x="126.829"
          y="75.1416"
          width="51.3635"
          height="124.944"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter5_f_33395_33761"
          x="74.7742"
          y="182.332"
          width="60.0549"
          height="114.896"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter6_f_33395_33761"
          x="126.829"
          y="182.264"
          width="60.3276"
          height="114.964"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter7_f_33395_33761"
          x="77.9336"
          y="244.323"
          width="55.9521"
          height="239.177"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter8_f_33395_33761"
          x="84.9331"
          y="469"
          width="39.252"
          height="35.5806"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter9_f_33395_33761"
          x="137.232"
          y="469"
          width="39.252"
          height="35.5806"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter10_f_33395_33761"
          x="127.52"
          y="244.323"
          width="55.9521"
          height="238.177"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter11_f_33395_33761"
          x="0"
          y="75.9443"
          width="103.807"
          height="206.336"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>
        <filter
          id="filter12_f_33395_33761"
          x="158.113"
          y="75.6768"
          width="103.887"
          height="206.871"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33761"
          />
        </filter>

        {/* part 1 */}
        <radialGradient
          id="paint0_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(131.254 58.8811) rotate(90) scale(31.4691 20.1182)"
        >
          <stop stopColor={colorMaps[opacities[0]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[0]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 4 */}
        <radialGradient
          id="paint1_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.469 59.1737) rotate(90) scale(31.5032 37.3227)"
        >
          <stop stopColor={colorMaps[opacities[3]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[3]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 3 */}
        <radialGradient
          id="paint2_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(155.198 58.9062) rotate(90) scale(31.5032 37.3227)"
        >
          <stop stopColor={colorMaps[opacities[2]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[2]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 7 */}
        <radialGradient
          id="paint3_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.777 133.396) rotate(90) scale(73.9356 43.8191)"
        >
          <stop stopColor={colorMaps[opacities[6]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[6]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 6 */}
        <radialGradient
          id="paint4_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(152.154 133.396) rotate(90) scale(73.9356 43.8191)"
        >
          <stop stopColor={colorMaps[opacities[5]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[5]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 9 */}
        <radialGradient
          id="paint5_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(105.557 236.154) rotate(90) scale(67.3112 52.6019)"
        >
          <stop stopColor={colorMaps[opacities[8]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[8]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 8 */}
        <radialGradient
          id="paint6_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(156.374 236.154) rotate(90) scale(67.3112 52.6019)"
        >
          <stop stopColor={colorMaps[opacities[7]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[7]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 11 */}
        <radialGradient
          id="paint7_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.605 354.247) rotate(90) scale(144.35 48.4559)"
        >
          <stop stopColor={colorMaps[opacities[10]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[10]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 13 */}
        <radialGradient
          id="paint8_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(103.838 486.043) rotate(90) scale(21.023 26.7615)"
        >
          <stop stopColor={colorMaps[opacities[12]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[12]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 12 */}
        <radialGradient
          id="paint9_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(157.578 486.043) rotate(90) scale(21.023 26.7615)"
        >
          <stop stopColor={colorMaps[opacities[11]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[11]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 10 */}
        <radialGradient
          id="paint10_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(154.801 354.247) rotate(90) scale(144.35 48.4559)"
        >
          <stop stopColor={colorMaps[opacities[9]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[9]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 5 */}
        <radialGradient
          id="paint11_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(53.3391 171.958) rotate(90) scale(125.393 96.9028)"
        >
          <stop stopColor={colorMaps[opacities[4]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[4]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 2 */}
        <radialGradient
          id="paint12_radial_33395_33761"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(208.661 172.226) rotate(90) scale(125.393 96.9028)"
        >
          <stop stopColor={colorMaps[opacities[1]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[1]]}
            stopOpacity={0.2}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}
