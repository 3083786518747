import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import { SvgText, WrapText } from '../../icons/weeklySummary'
import {
  getAxisX,
  xAxisFormat,
  tickLabels,
  calculateElementWidth
} from '../common'
import { getSum } from '../../../utils/common'

class WorkoutChart extends Component {
  static propTypes = {}

  barDataHandle = (tickValuesX, barData) => {
    // const barData = [].concat.apply(...data)
    return tickValuesX.map((item) => {
      const entry = barData
        .filter((entry) => entry.summary_date === item.format('YYYY-MM-DD'))
        .map((entry) =>
          getSum(entry.activities.map((activity) => activity.duration))
        )
      const y = getSum(entry)
      return {
        x: item,
        y
      }
    })
  }

  getEmptyDate = (tickValuesX, barDatas, maxDuration) => {
    const data = [].concat.apply(...barDatas.map((item) => item.data))
    return tickValuesX.map((x) => {
      const hasData = data.find((item) => item.x.isSame(x) && item.y > 0)
      return {
        x,
        y: hasData ? 0 : maxDuration * 0.02
      }
    })
  }

  labelsHandle = ({ Aerobic, Resistance, Activity, MovementQuality }) => {
    const groupHandle = (data) => {
      const activities =
        data.length > 0
          ? [].concat.call(...data.map((item) => item.activities))
          : []
      const names = Array.from(
        new Set(activities.map((activity) => activity.name))
      )
      return names.map((name) => {
        const durations = getSum(
          activities
            .filter((activity) => activity.name === name)
            .map((activity) => Math.round(activity.duration / 60) * 60)
        )

        const hours = Math.floor(durations / (60 * 60))
        const minutes = Math.round((durations - hours * 60 * 60) / 60)

        return {
          name,
          duration: hours * 60 * 60 + minutes * 60
        }
      })
    }
    const aerobic = groupHandle(Aerobic)
    const resistance = groupHandle(Resistance)
    const activity = groupHandle(Activity)
    const movementQuality = groupHandle(MovementQuality)
    return { aerobic, resistance, activity, movementQuality }
  }

  dataHandle(tickValuesX = [], exercise) {
    const workouts = exercise && exercise.workouts
    // console.log('workouts', workouts)
    // console.log('exercise.workouts', exercise.workouts)
    const filterDatas = (datas) => {
      const result = datas.map((data) => data.details)
      for (const index in result) {
        result[index] = result[index].filter(
          (item) => item.activities.length > 0
        )
      }
      return result
    }
    const Aerobic = [].concat.apply(
      ...filterDatas(workouts.filter((workout) => workout.type === 'Aerobic'))
    )

    const Resistance = [].concat.apply(
      ...filterDatas(
        workouts.filter((workout) => workout.type === 'Resistance')
      )
    )

    const Activity = [].concat.apply(
      ...filterDatas(workouts.filter((workout) => workout.type === 'Activity'))
    )

    const MovementQuality = [].concat.apply(
      ...filterDatas(
        workouts.filter((workout) => workout.type === 'Movement Quality')
      )
    )
    const barDatas = [
      {
        type: 'Movement Quality',
        barColor: '#C29E7C',
        data: this.barDataHandle(tickValuesX, MovementQuality)
      },
      {
        type: 'Activity',
        barColor: '#BCCA70',
        data: this.barDataHandle(tickValuesX, Activity)
      },
      {
        type: 'Resistance',
        barColor: '#58646B',
        data: this.barDataHandle(tickValuesX, Resistance)
      },
      {
        type: 'Aerobic',
        barColor: '#FF6B00',
        data: this.barDataHandle(tickValuesX, Aerobic)
      }
    ]

    for (const index in barDatas) {
      if (index > 0) {
        barDatas[index].data = barDatas[index].data.map((item, _index) => {
          const y0 = barDatas[index - 1].data[_index].y
          item.y += y0
          return {
            ...item,
            y0
          }
        })
      } else {
        barDatas[index].data = barDatas[index].data.map((item) => ({
          ...item,
          y0: 0
        }))
      }
    }

    const maxDuration = Math.max(
      ...[].concat.call(...barDatas.map((item) => item.data.map((d) => d.y)))
    )

    let tickValues = [0, 1200, 2400, 3600]

    if (maxDuration) {
      const step = Math.ceil(maxDuration / 60 / 30) * 10
      tickValues = [0, step * 60, step * 2 * 60, step * 3 * 60]
    }

    barDatas.push({
      type: 'Empty',
      barColor: '#C4C4C4',
      data: this.getEmptyDate(tickValuesX, barDatas, maxDuration || 3600)
    })

    const labels = this.labelsHandle({
      Aerobic,
      Resistance,
      Activity,
      MovementQuality
    })

    return { barDatas, tickValues, labels }
  }

  renderChart(barDatas, tickValues, tickValuesX) {
    return (
      <VictoryChart width={500} height={250} domainPadding={20}>
        <VictoryAxis
          style={{
            axis: {
              stroke: 'transparent'
            },
            tickLabels
          }}
          tickValues={tickValuesX}
          tickLabelComponent={
            <VictoryLabel text={(datum) => xAxisFormat(datum)} />
          }
        />

        <VictoryAxis
          dependentAxis
          tickValues={tickValues}
          tickLabelComponent={
            <VictoryLabel text={(datum) => datum && datum / 60} />
          }
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: '#E4EAEF',
              strokeDasharray: '3,3'
            },
            tickLabels
          }}
        />

        {barDatas &&
          barDatas.map((item, index) => (
            <VictoryBar
              key={index}
              cornerRadius={{ bottom: 6, top: 6 }}
              style={{
                data: {
                  fill: item.barColor,
                  stroke: '#fff',
                  strokeWidth: 1
                }
              }}
              barWidth={36}
              data={item.data}
            />
          ))}
      </VictoryChart>
    )
  }

  renderLabel(typeData, maxNameWidth) {
    const formatTime = (duration) => {
      const hours = Math.floor(duration / (60 * 60))
      const minutes = Math.round((duration - hours * 60 * 60) / 60)
      return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`
    }
    return (
      <>
        <div className="activity-type">
          <div className="name">
            <span className={typeData.name} />
            <WrapText
              height={
                calculateElementWidth(
                  'span',
                  typeData.name.toUpperCase(),
                  '12px',
                  maxNameWidth + 'px'
                ).height
              }
              width={maxNameWidth}
              fontFamily="Gilroy-bold"
              textAttr={{
                x: 0,
                y: 0,
                fontSize: 12,
                fontWeight: 'bold',
                fill: '#383E48',
                letterSpacing: 0.5,
                textAnchor: 'start',
                dominantBaseline: 'middle'
              }}
              text={typeData.name.toUpperCase()}
            />
          </div>
          <SvgText
            height={18}
            width={
              calculateElementWidth(
                'span',
                formatTime(typeData.totalDuration),
                '12px'
              ).width + 5
            }
            fontFamily="Gilroy-bold"
            textAttr={{
              x: 0,
              y: 10,
              fontSize: 12,
              fontWeight: 'bold',
              fill: '#383E48',
              letterSpacing: 0.5,
              textAnchor: 'start',
              dominantBaseline: 'middle'
            }}
            text={formatTime(typeData.totalDuration)}
          />
        </div>
        {typeData.data.map((item, index) => (
          <div key={index} className="activity-name">
            <div className="name">
              <WrapText
                height={
                  calculateElementWidth(
                    'span',
                    item.name.toUpperCase(),
                    '12px',
                    maxNameWidth + 'px'
                  ).height
                }
                width={maxNameWidth}
                fontFamily="Gilroy"
                textAttr={{
                  x: 0,
                  y: 0,
                  fontSize: 12,
                  fontWeight: 'bold',
                  fill: '#70808E',
                  letterSpacing: 0.5,
                  textAnchor: 'start',
                  dominantBaseline: 'middle'
                }}
                text={item.name.toUpperCase()}
              />
            </div>
            <SvgText
              height={18}
              width={
                calculateElementWidth('span', formatTime(item.duration), '12px')
                  .width + 5
              }
              fontFamily="Gilroy"
              textAttr={{
                x: 0,
                y: 10,
                fontSize: 12,
                fontWeight: 'bold',
                fill: '#70808E',
                letterSpacing: 0.5,
                textAnchor: 'start',
                dominantBaseline: 'middle'
              }}
              text={formatTime(item.duration)}
            />
          </div>
        ))}
      </>
    )
  }

  renderLabels(labels) {
    const { aerobic, resistance, activity, movementQuality } = labels
    let labelData = [
      {
        name: 'Aerobic',
        data: aerobic,
        totalDuration: getSum(aerobic.map((item) => item.duration))
      },
      {
        name: 'Resistance',
        data: resistance,
        totalDuration: getSum(resistance.map((item) => item.duration))
      },
      {
        name: 'Activity',
        data: activity,
        totalDuration: getSum(activity.map((item) => item.duration))
      },
      {
        name: 'Movement Quality',
        data: movementQuality,
        totalDuration: getSum(movementQuality.map((item) => item.duration))
      }
    ]
    // .sort((a, b) => b.length - a.length)
    labelData = labelData.filter((item) => item.data.length > 0)
    return (
      <div className="workout-labels" align="top">
        <Row gutter={32} type="flex">
          {labelData.map((item, index) => {
            const fullfWidth = labelData.length === 1
            return (
              <Col span={fullfWidth ? 24 : 12} key={index}>
                {this.renderLabel(item, fullfWidth ? 415 : 150)}
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }

  render() {
    const { startDate, endDate, exercise } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    // if(tickValuesX)
    const { barDatas, tickValues, labels } = this.dataHandle(
      tickValuesX,
      exercise
    )

    return (
      <>
        <div className="chart-container">
          <div className="label left-axis">
            <SvgText
              height={140}
              width={30}
              fontFamily="Gilroy"
              textAttr={{
                x: -100,
                y: 20,
                fontSize: 12,
                fill: '#70808E',
                fontWeight: 'normal',
                transform: 'rotate(-90) ',
                textAnchor: 'start',
                dominantBaseline: 'top'
              }}
              text="Minutes"
            />
          </div>
          {this.renderChart(barDatas, tickValues, tickValuesX)}
        </div>
        {this.renderLabels(labels)}
      </>
    )
  }
}

export default WorkoutChart
