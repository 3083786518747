import React from 'react'
import _ from 'lodash'
import MetricsEditor, { MetricsNamed } from './metricsEditor'
import {
  getStatistics,
  getMetrics,
  showToClient,
  getMetricGroup,
  editTemplate
} from '../../api/trends'
import {
  loadUser,
  // setTrendsDisplayMetrics,
  setTrendsCatch,
  geTrendsCatch
} from '../../utils/storage'

import moment from 'moment'

export const TrendsDateRanges = [
  {
    name: '7 days',
    barWidth: 20,
    // picker: 'week',
    formatX: 'ddd',
    picker: 'blank',
    formatTooltip: 'ddd D',
    type: 'week'
  },
  {
    name: '14 days',
    barWidth: 12,
    formatX: 'M/DD',
    // picker: 'week',
    picker: 'blank',
    type: 'bi_weekly'
  },
  {
    name: '30 days',
    barWidth: 8,
    // picker: 'month',
    type: 'custom',
    picker: 'blank',
    formatX: 'MMM/D'
  },
  {
    name: '90 days',
    barWidth: 12,
    // picker: 'quarter',
    picker: 'blank',
    // type: 'quarterly',
    type: 'custom',
    formatX: 'M/DD'
  },
  {
    name: '365 days',
    barWidth: 20,
    type: 'annual',
    // type: 'blank',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Lifetime',
    barWidth: 8,
    picker: 'date',
    type: 'lifetime'
    // formatX: 'M/D/YY'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
    // formatX: ''
  }
]

export default (WrappedComponent) =>
  class TrendsWrapper extends React.Component {
    state = {
      showNumber: true,
      showGraphType: 'line',
      selectedDate: 0,
      isMetricsEditing: false,
      statistics: null,
      from: '',
      to: '',
      loading: false,
      editedMetrics: null,
      metricGroup: [],
      activeGroup: 0
    }

    setToClientView = async () => {
      const { activeGroup, metricGroup } = this.state
      await showToClient(metricGroup[activeGroup].id)
    }
    changeMetricsName = (editedMetrics) => {
      if (!editedMetrics.metric_keys) {
        editedMetrics.metric_keys = this.getMetricsKeys(
          editedMetrics.display_metrics
        )
      }
      this.setState({
        editedMetrics,
        isMetricsEditing: false
      })
    }

    deleteCallback = (selectGroup, group) => {
      let { activeGroup, metricGroup } = this.state
      metricGroup = metricGroup.filter((item) => item.id !== group.id)
      if (activeGroup === selectGroup || activeGroup > metricGroup.length - 1) {
        activeGroup = 0
      }
      this.setState({
        activeGroup,
        metricGroup: _.cloneDeep(metricGroup),
        isMetricsEditing: false
      })
    }

    setCatch = ({ selectedDate, from, to }) => {
      const account = loadUser()
      if (
        (process.env.REACT_APP_WORKFLOW !== 'production' &&
          account.id === 393) ||
        (process.env.REACT_APP_WORKFLOW !== 'production' &&
          process.env.REACT_APP_WORKFLOW !== 'staging')
      ) {
        setTrendsCatch({
          selectedDate: selectedDate || this.state.selectedDate,
          from: from || this.state.from,
          to: to || this.state.to
        })
      }
    }

    switchDate = async (index) => {
      const dateRange = TrendsDateRanges[index]
      let { from, to } = this.state
      this.setState({ loading: true })
      if (dateRange.picker === 'blank') {
        const matchResult = dateRange.name.match(/\d+/)
        const days = matchResult ? parseInt(matchResult[0], 10) : null
        to = moment().format('YYYY-MM-DD')
        from = moment()
          .subtract(days - 1, 'days')
          .format('YYYY-MM-DD')
      } else {
        switch (dateRange.name) {
          case 'Week':
            from = moment()
              .startOf('week')
              .format('YYYY-MM-DD')
            to = moment()
              .endOf('week')
              .format('YYYY-MM-DD')
            break
          case 'Bi-Weekly':
            from = moment()
              .subtract(7, 'days')
              .startOf('week')
              .format('YYYY-MM-DD')
            to = moment()
              .endOf('week')
              .format('YYYY-MM-DD')
            break
          case 'Month':
            from = moment()
              .startOf('month')
              .format('YYYY-MM-DD')
            to = moment()
              .endOf('month')
              .format('YYYY-MM-DD')
            break
          case 'Quarterly':
            from = moment()
              .startOf('quarter')
              .format('YYYY-MM-DD')
            to = moment()
              .endOf('quarter')
              .format('YYYY-MM-DD')
            break
          case 'Annual':
          case '365 days':
            from = moment()
              .startOf('year')
              .format('YYYY-MM-DD')
            to = moment()
              .endOf('year')
              .format('YYYY-MM-DD')
            break
          case 'Custom':
            this.setState({
              selectedDate: index,
              from,
              to,
              loading: false
            })
            return
          default:
            break
        }
      }

      const { statistics, metricGroup } = await this.fetchData(
        from,
        to,
        dateRange.type || dateRange.picker
      )

      this.setState({
        selectedDate: index,
        from,
        to,
        loading: false,
        statistics,
        metricGroup
      })
      this.setCatch({ from, to, selectedDate: index })
    }

    onRangeChange = async ([from, to]) => {
      this.setState({ loading: true })
      const { selectedDate: index } = this.state
      const dateRange = TrendsDateRanges[index]
      const { statistics, metricGroup } = await this.fetchData(
        from,
        to,
        dateRange.type || dateRange.picker
      )
      this.setState({ loading: false, statistics, metricGroup, from, to })
      this.setCatch({ from, to })
    }

    onAdd = (metric) => {
      const { activeGroup, metricGroup } = this.state
      const displayMetrics = _.unionBy(
        metricGroup[activeGroup].display_metrics,
        metric,
        'metric_key'
      )
      this.onKeysChange(displayMetrics, activeGroup, 'add metric')
    }

    getStatisticsByMetrics = async (displayMetrics) => {
      const {
        person: { id }
      } = this.props
      const { allKeys, selectedDate, from, to } = this.state
      let { statistics } = this.state
      const newKeys = this.getMetricsKeys(displayMetrics)
      const unknownKeys = _.differenceBy(newKeys, allKeys)
      if (unknownKeys.length) {
        this.setState({ loading: true })
        const dateRange = TrendsDateRanges[selectedDate]
        const newStatistics = await getStatistics({
          personId: id,
          keys: unknownKeys.join(','),
          type: dateRange.type || dateRange.picker,
          from,
          to
        })
        statistics = [...statistics, ...newStatistics]
      }
      return { statistics, allKeys: allKeys + ',' + unknownKeys.join(',') }
    }

    countCategory = (displayMetrics) => {
      const { allMetrics } = this.state
      const categories = allMetrics.map((item) => ({ name: item.category }))
      return categories.map((category) => {
        category.count = displayMetrics.filter(
          (e) => _.head(e.categories) === category.name
        ).length
        return category
      })
    }

    onApply = (displayMetrics, selectGroup) => {
      const { metricGroup } = this.state
      const group = metricGroup[selectGroup]
      let isChanged = displayMetrics.length !== group.display_metrics.length

      if (displayMetrics.length === group.display_metrics.length) {
        isChanged =
          _.difference(
            this.getMetricsKeys(displayMetrics),
            this.getMetricsKeys(group.display_metrics)
          ).length > 0
      }

      if (isChanged) {
        const loginUser = loadUser()
        if (group.created_by !== loginUser.id) {
          // apply new group
          this.changeMetricsName({
            display_metrics: displayMetrics,
            description:
              "Since you made changes to another expert's template, it will auto save as a new template."
          })
        } else {
          this.onKeysChange(displayMetrics, selectGroup)
        }
      }
    }

    onKeysChange = async (displayMetrics, selectGroup, isAddMetric) => {
      const { metricGroup } = this.state
      const { statistics, allKeys } = await this.getStatisticsByMetrics(
        displayMetrics
      )
      if (!isAddMetric) {
        const metric_keys = this.getMetricsKeys(displayMetrics)
        await editTemplate({ metric_keys }, metricGroup[selectGroup].id)
      }
      metricGroup[selectGroup].display_metrics = this.handleList(
        displayMetrics,
        statistics
      )
      this.setState({
        allKeys,
        statistics,
        loading: false,
        metricGroup: _.cloneDeep(metricGroup),
        activeGroup: selectGroup
      })
    }

    onDragEnd = (startIndex, endIndex) => {
      const { activeGroup, metricGroup } = this.state
      const displayMetrics = metricGroup[activeGroup].display_metrics
      const result = Array.from(displayMetrics)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      metricGroup[activeGroup].display_metrics = result
      this.setState({ metricGroup: _.cloneDeep(metricGroup) })
    }

    getMetricsKeys = (displayMetrics) => {
      const keys = displayMetrics.map((item) => {
        if (item.add_metrics.length) {
          return item.metric_key + ',' + item.add_metrics[0].metric_key
        } else {
          return item.metric_key
        }
      })
      return keys
    }

    handleList = (displayMetrics, statistics) =>
      displayMetrics.map((item) => {
        let { metric_key, add_metrics } = item
        const { values, detail, aggregate_mode } =
          _.find(statistics, {
            metric_key
          }) || {}
        if (add_metrics && add_metrics.length) {
          add_metrics = add_metrics.map((addItem) => {
            const { values, detail, aggregate_mode } =
              _.find(statistics, {
                metric_key: addItem.metric_key
              }) || {}
            return _.assign(addItem, { values, detail, aggregate_mode })
          })
        }

        return _.assign(item, {
          values,
          detail,
          aggregate_mode,
          add_metrics
        })
      })

    onGroupChange = (index) => {
      this.setState({ activeGroup: index })
    }

    onNamed = async (group) => {
      const { editedMetrics, metricGroup, activeGroup } = this.state
      let index = activeGroup
      if (editedMetrics.id) {
        index = metricGroup.findIndex((item) => item.id === editedMetrics.id)
        metricGroup[index].name = group.name
      } else {
        metricGroup.push(group)
        index = metricGroup.length - 1
        const { statistics, allKeys } = await this.getStatisticsByMetrics(
          editedMetrics.display_metrics
        )
        this.setState({ statistics, allKeys })
        metricGroup[index].display_metrics = this.handleList(
          metricGroup[index].display_metrics,
          statistics
        )
      }

      this.setState({
        editedMetrics: null,
        metricGroup: _.cloneDeep(metricGroup),
        activeGroup: index,
        loading: false
      })
    }

    fetchData = async (from, to, type) => {
      const {
        person: { id }
      } = this.props
      const { allKeys, metricGroup } = this.state
      const statistics = await getStatistics({
        personId: id,
        keys: allKeys,
        type,
        from,
        to
      })
      const _metricGroup = metricGroup.map((item) => {
        item.display_metrics = this.handleList(item.display_metrics, statistics)
        return item
      })
      return {
        statistics,
        metricGroup: _metricGroup
      }
    }

    initial = async (from, to, index) => {
      const {
        person: { id }
      } = this.props
      const allMetrics = await getMetrics(id)
      const _metricGroup = await getMetricGroup(id)
      const allKeys = _.uniq(
        _.concat([], ..._metricGroup.map((item) => item.metric_keys))
      ).join(',')

      const dateRange = TrendsDateRanges[index]
      const statistics = await getStatistics({
        personId: id,
        keys: allKeys,
        type: dateRange.type || dateRange.picker,
        from,
        to
      })

      const metricGroup = _metricGroup.map((item) => {
        item.display_metrics = this.handleList(item.display_metrics, statistics)
        return item
      })
      // const activeGroup = Math.max(
      //   metricGroup.findIndex((item) => item.name === 'Default'),
      //   0
      // )

      this.setState({
        allMetrics,
        statistics,
        allKeys,
        metricGroup
        // activeGroup
      })
    }

    componentDidMount() {
      let trendsCatch
      if (
        process.env.REACT_APP_WORKFLOW !== 'production' &&
        process.env.REACT_APP_WORKFLOW !== 'staging'
      ) {
        trendsCatch = geTrendsCatch()
      }
      const from = trendsCatch
        ? trendsCatch.from
        : moment()
            .subtract(6, 'days')
            .format('YYYY-MM-DD')
      const to = trendsCatch ? trendsCatch.to : moment().format('YYYY-MM-DD')
      const selectedDate = trendsCatch ? trendsCatch.selectedDate : 0
      this.setState({
        from,
        to,
        selectedDate
      })
      this.initial(from, to, selectedDate)
    }

    render() {
      const { person } = this.props
      const {
        allMetrics,
        displayMetrics,
        metricGroup,
        activeGroup,
        editedMetrics,
        isMetricsEditing
      } = this.state
      const childProps = {
        ...this.state,
        dateRanges: TrendsDateRanges,
        switchDate: (index) => this.switchDate(index),
        switchShowNumber: (checked) => this.setState({ showNumber: checked }),
        switchGraphType: (type) => this.setState({ showGraphType: type }),
        setMetricsEditing: (isMetricsEditing) =>
          this.setState({ isMetricsEditing }),
        onRangeChange: this.onRangeChange,
        onAdd: this.onAdd,
        onDragEnd: this.onDragEnd,
        onGroupChange: this.onGroupChange,
        countCategory: this.countCategory,
        setToClientView: this.setToClientView
      }

      return (
        <>
          <WrappedComponent {...childProps} {...this.props} />
          {isMetricsEditing && (
            <MetricsEditor
              {...{
                allMetrics,
                displayMetrics,
                metricGroup,
                activeGroup,
                changeName: this.changeMetricsName,
                deleteCallback: this.deleteCallback
              }}
              onEdit={this.onApply}
              onCancel={() => this.setState({ isMetricsEditing: false })}
            />
          )}

          {editedMetrics && (
            <MetricsNamed
              {...{
                person,
                metrics: editedMetrics,
                countCategory: this.countCategory,
                onCancel: () =>
                  this.setState({
                    editedMetrics: null
                  }),
                onReturn: () =>
                  this.setState({
                    isMetricsEditing: true,
                    editedMetrics: null
                  }),
                onConfirm: this.onNamed
              }}
            />
          )}
        </>
      )
    }
  }
