import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Row, Col, Modal } from 'antd'
import {
  AddressIcon,
  AddUserIcon,
  TrashIcon
} from '../../../../components/icons/facility'
import PageLoading from '../../../../components/UI/status/loading'
import FacilityModal from './modal'
import ExpertModal from './expert'
import {
  getFacilityDetail,
  getService,
  updateFacility,
  getExperts,
  delFacility
} from '../../../../api/facility'
import { Pluralize } from '../../../../utils/common'

import ArrowLeft from '../../../../asserts/icon/facility/arrow-left.svg'

import { Style } from './index.sass'

const { confirm } = Modal

class Services extends Component {
  static propTypes = {}

  state = {
    pageLoading: true,
    facility: null,
    services: [],
    visible: false,
    expertModalVisible: false,
    service: null,
    editPart: 'facility',
    experts: []
  }

  initial = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { facility } = await getFacilityDetail(id)
    const { services } = await getService()
    const { experts } = await getExperts()
    this.setState({ facility, pageLoading: false, services, experts })
  }

  delete = async () => {
    const { history } = this.props
    const { facility } = this.state
    let loading = false
    confirm({
      title: 'Do you want to delete these facility?',
      onOk: async () => {
        try {
          loading = true
          await delFacility(facility.id)
          history.replace('/common-tools/facilities')
        } catch (error) {
          console.error(error)
          loading = false
          // message.error(error)
        }
      },
      okButtonProps: {
        loading
      }
    })
  }

  submitSync = (facility) => {
    this.setState({ facility })
  }

  deleteService = async (service) => {
    const {
      facility: { name, address, open_time, close_time, id, services }
    } = this.state
    const { facility } = await updateFacility(
      {
        name,
        address,
        open_time,
        close_time,
        service_ids: services
          .filter((item) => item.id !== service)
          .map((item) => item.id)
      },
      id
    )
    this.setState({ facility })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { history } = this.props
    const {
      facility,
      pageLoading,
      services,
      editPart,
      visible,
      expertModalVisible,
      service,
      experts
    } = this.state
    let initValue = {}
    if (facility) {
      initValue = Object.assign({}, facility)
    }

    return pageLoading ? (
      <PageLoading />
    ) : (
      <div className={Style}>
        <div className="page-title">
          <img
            className="arrow"
            src={ArrowLeft}
            alt="left"
            onClick={() => history.push('/common-tools/facilities')}
          />
          {facility.name}
          <Button
            type="primary"
            style={{ float: 'right', marginTop: 10 }}
            onClick={() =>
              this.setState({ visible: true, editPart: 'service' })
            }
          >
            <PlusOutlined />
            {facility.services.length > 0 ? 'EDIT SERVICES' : 'ADD SERVICES'}
          </Button>
        </div>

        <div className="service">
          <div className="facility-info">
            <div>
              <div className="time-area">
                Open: {moment(facility.open_time, 'HH:mm:ss').format('hh:mm A')}{' '}
                – Close:{' '}
                {moment(facility.close_time, 'HH:mm:ss').format('hh:mm A')}
              </div>
              <div className="address">
                <AddressIcon />
                {facility.address}
              </div>
            </div>

            <div className="opr">
              <span className="del" onClick={this.delete}>
                DELETE FACILITY
              </span>
              <span
                onClick={() =>
                  this.setState({ visible: true, editPart: 'facility' })
                }
              >
                EDIT FACILITY INFO
              </span>
            </div>
          </div>
          {!facility.services ||
            (facility.services.length === 0 && (
              <p className="service-empty">No service available.</p>
            ))}
          <Row gutter={18}>
            {facility.services.map((service, serviceIndex) => (
              <Col span={12} key={serviceIndex}>
                <div className="service-item">
                  <div className="title">
                    <span>{service.name}</span>
                    <div>
                      <AddUserIcon
                        onClick={() =>
                          this.setState({
                            expertModalVisible: true,
                            service
                          })
                        }
                      />
                      <TrashIcon
                        onClick={() => this.deleteService(service.id)}
                      />
                    </div>
                  </div>
                  <div className="duration">
                    Duration: {service.duration || 1}{' '}
                    {Pluralize('hour', service.duration || 1)}
                  </div>
                  <Row>
                    {service.experts.map((item, index) => (
                      <Col key={index} span={8} className="expert">
                        <div className="avatar">
                          {item.avatar && item.avatar.oss_url && (
                            <img src={item.avatar.oss_url} alt="avatar" />
                          )}
                        </div>
                        <div className="name">{`${(item.profile &&
                          item.profile.nickname) ||
                          item.first_name} ${item.last_name}`}</div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        {visible && (
          <FacilityModal
            visible={visible}
            services={services}
            editPart={editPart}
            initValue={initValue}
            closeModal={() => this.setState({ visible: false })}
            submitSync={this.submitSync}
          />
        )}
        {expertModalVisible && (
          <ExpertModal
            visible={expertModalVisible}
            {...{ service, facility, experts }}
            closeModal={() => this.setState({ expertModalVisible: false })}
            submitSync={this.submitSync}
          />
        )}
      </div>
    )
  }
}

export default Services
