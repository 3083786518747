import React, { Component } from 'react'
import ProgramList from '../../../components/program/programList'
import {
  getClientList,
  getClientDetail,
  getProgram
} from '../../../models/breadcrumb.model'

class Program extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }

    setClientPageTitle &&
      setClientPageTitle(
        `${person.profile.nickname || person.first_name}’s Program`
      )
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getProgram(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    return person ? <ProgramList person={person} {...this.props} /> : <div />
  }
}

export default Program
