import React, { useState, useEffect } from 'react'
import { Tabs } from 'antd'
import { getMembershipFees } from '../../api/team'
import MemberShip from './membership'
// import MembershipV2 from './V2/membership'
import Services from './service'
// import MemberShipV1 from './v1/membership'
// import ServicesV1 from './v1/service'
import PageLoading from '../UI/status/loading'
import { loadUser } from '../../utils/storage'

const { TabPane } = Tabs

function MembershipContainer(props) {
  const [price, setPrice] = useState(null)
  const user = loadUser()

  useEffect(() => {
    const getFees = async () => {
      const result = await getMembershipFees(user.id)
      setPrice(result)
    }
    getFees()
  }, [])

  const tabs = [
    {
      name: 'MEMBERSHIP',
      key: 'Membership',
      pane: (
        <MemberShip
          editable={false}
          price={price}
          current={user.profile.membership}
        />
      )
    },
    {
      name: 'SERVICES',
      key: 'Services',
      pane: <Services />
    }
  ]

  return (
    <div style={{ paddingRight: 4 }}>
      <Tabs defaultActiveKey="Membership">
        {tabs.map((item) => (
          <TabPane tab={item.name} key={item.key}>
            {price ? item.pane : <PageLoading />}
          </TabPane>
        ))}
      </Tabs>
    </div>
  )
}

export default MembershipContainer
