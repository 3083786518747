import React from 'react'
import Icon from '@ant-design/icons'

const calendar = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 6h15"
      stroke="#85C1D7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 3h-12A1.5 1.5 0 001 4.5v9A1.5 1.5 0 002.5 15h12a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 3v0z"
      stroke="#2B4B8F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 1v2M12 1v2"
      stroke="#85C1D7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const CalendarIcon = (props) => <Icon component={calendar} {...props} />

const dumbbell = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 6.09h7.273M2.455 6.09H1M17 6.09h-1.455"
      stroke="#85C1D7"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <path
      d="M5.364 2.455a1.455 1.455 0 10-2.91 0v7.272a1.455 1.455 0 102.91 0V2.455zM12.636 9.73a1.455 1.455 0 102.91 0V2.455a1.455 1.455 0 10-2.91 0V9.73z"
      stroke="#2B4B8F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </svg>
)

export const DumbbellIcon = (props) => <Icon component={dumbbell} {...props} />

export function Start(props) {
  return (
    <svg
      width="18"
      height="33"
      viewBox="0 0 18 33"
      fill={props.fill || '#0D2350'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5628 32.3L9 29.375L3.43723 32.3L4.5 26.1103L0 21.7183L6.21861 20.8162L9 15.1797L11.7814 20.8162L18 21.7183L13.5 26.1103L14.5628 32.3Z" />
      <path d="M16.9014 0.695312H1.09802V4.64615H16.9014V0.695312Z" />
      <path d="M1.09814 7.28125V11.2321L8.99981 15.1829L16.9015 11.2321V7.28125H1.09814Z" />
    </svg>
  )
}

export function Hourglass(props) {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill={props.fill || '#0D2350'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.9949 2.47509H1.9027C1.63613 2.47509 1.38047 2.36919 1.19198 2.18069C1.00348 1.9922 0.897583 1.73654 0.897583 1.46996C0.897583 1.20339 1.00348 0.947733 1.19198 0.759237C1.38047 0.57074 1.63613 0.464844 1.9027 0.464844L19.9949 0.464844C20.2614 0.464844 20.5171 0.57074 20.7056 0.759237C20.8941 0.947733 21 1.20339 21 1.46996C21 1.73654 20.8941 1.9922 20.7056 2.18069C20.5171 2.36919 20.2614 2.47509 19.9949 2.47509Z" />
      <path d="M19.9949 24.7016H1.9027C1.63613 24.7016 1.38047 24.5958 1.19198 24.4073C1.00348 24.2188 0.897583 23.9631 0.897583 23.6965C0.897583 23.43 1.00348 23.1743 1.19198 22.9858C1.38047 22.7973 1.63613 22.6914 1.9027 22.6914H19.9949C20.2614 22.6914 20.5171 22.7973 20.7056 22.9858C20.8941 23.1743 21 23.43 21 23.6965C21 23.9631 20.8941 24.2188 20.7056 24.4073C20.5171 24.5958 20.2614 24.7016 19.9949 24.7016Z" />
      <path d="M17.9849 6.55712V4.54688H3.91321V6.55712C3.91352 6.75561 3.97225 6.94962 4.08207 7.11496L7.73267 12.5878L4.08408 18.0607C3.97355 18.2258 3.91412 18.4199 3.91321 18.6186V20.6288H17.9849V18.6186C17.9846 18.4201 17.9259 18.2261 17.816 18.0607L14.1654 12.5878L17.814 7.11496C17.9246 6.94984 17.984 6.75581 17.9849 6.55712Z" />
    </svg>
  )
}

const publish = () => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0.144531V1.85882H12V0.144531H0ZM0 8.71596H3.42857V13.8588H8.57143V8.71596H12L6 2.71596L0 8.71596Z" />
  </svg>
)

export const PublishIcon = (props) => <Icon component={publish} {...props} />

const sessions = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill="#FFFFFF" />
    <path
      d="M11.467 10.533l2.6-.867 5.2 5.2L21 13.133"
      stroke="#2B4B8F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.833 10.534a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6zM11.467 16.6L8 20.066"
      stroke="#85C1D7"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.233 11.834l-3.05 3.05a.866.866 0 00.226 1.386l2.39 1.197-2.6 2.6"
      stroke="#2B4B8F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const SessionsIcon = (props) => <Icon component={sessions} {...props} />

export const minutes = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={14} cy={14} r={14} fill="#FFFFFF" />
    <path
      d="M7.75 14c0-3.45 2.794-6.25 6.243-6.25 3.457 0 6.257 2.8 6.257 6.25s-2.8 6.25-6.257 6.25A6.247 6.247 0 017.75 14z"
      stroke="#2B4B8F"
      strokeWidth={1.5}
    />
    <path
      d="M14.438 10.5h-1.313v4.375l2.844 1.881.656-1.076-2.188-1.461V10.5z"
      fill="#85C1D7"
    />
  </svg>
)

export const MinutesIcon = (props) => <Icon component={minutes} {...props} />

const programPercent = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_57960_21144" fill="white">
      <path d="M8 0.108398C9.88663 0.108398 11.7126 0.775167 13.1552 1.99092C14.5979 3.20667 15.5645 4.89318 15.8843 6.75251C16.204 8.61185 15.8564 10.5244 14.9027 12.1522C13.9491 13.7801 12.4508 15.0185 10.6725 15.6488C8.8943 16.2791 6.95053 16.2606 5.18459 15.5966C3.41865 14.9327 1.94419 13.666 1.02165 12.0203C0.0991242 10.3746 -0.212107 8.45579 0.142941 6.60287C0.497989 4.74995 1.49647 3.08212 2.962 1.89402L3.99873 3.17283C2.83478 4.11644 2.04177 5.44106 1.75979 6.91268C1.4778 8.3843 1.72499 9.90823 2.45768 11.2153C3.19037 12.5223 4.36141 13.5284 5.76395 14.0557C7.16649 14.583 8.71027 14.5977 10.1226 14.0971C11.5349 13.5965 12.7249 12.613 13.4823 11.3201C14.2397 10.0272 14.5158 8.50825 14.2618 7.03153C14.0079 5.55481 13.2402 4.21536 12.0944 3.24979C10.9486 2.28422 9.49839 1.75466 8 1.75466V0.108398Z" />
    </mask>
    <path
      d="M8 0.108398C9.88663 0.108398 11.7126 0.775167 13.1552 1.99092C14.5979 3.20667 15.5645 4.89318 15.8843 6.75251C16.204 8.61185 15.8564 10.5244 14.9027 12.1522C13.9491 13.7801 12.4508 15.0185 10.6725 15.6488C8.8943 16.2791 6.95053 16.2606 5.18459 15.5966C3.41865 14.9327 1.94419 13.666 1.02165 12.0203C0.0991242 10.3746 -0.212107 8.45579 0.142941 6.60287C0.497989 4.74995 1.49647 3.08212 2.962 1.89402L3.99873 3.17283C2.83478 4.11644 2.04177 5.44106 1.75979 6.91268C1.4778 8.3843 1.72499 9.90823 2.45768 11.2153C3.19037 12.5223 4.36141 13.5284 5.76395 14.0557C7.16649 14.583 8.71027 14.5977 10.1226 14.0971C11.5349 13.5965 12.7249 12.613 13.4823 11.3201C14.2397 10.0272 14.5158 8.50825 14.2618 7.03153C14.0079 5.55481 13.2402 4.21536 12.0944 3.24979C10.9486 2.28422 9.49839 1.75466 8 1.75466V0.108398Z"
      stroke="#2B4B8F"
      strokeWidth="3"
      mask="url(#path-1-inside-1_57960_21144)"
    />
    <mask id="path-2-inside-2_57960_21144" fill="white">
      <path d="M8 3.1084C8.76807 3.1084 9.52581 3.28535 10.2144 3.62552C10.9031 3.96568 11.5041 4.45993 11.9708 5.0699C12.4376 5.67988 12.7575 6.38918 12.9059 7.14279C13.0542 7.8964 13.0269 8.67405 12.8261 9.41541C12.6254 10.1568 12.2565 10.8419 11.7481 11.4177C11.2398 11.9935 10.6056 12.4444 9.89486 12.7354C9.18408 13.0265 8.4158 13.1499 7.64962 13.0961C6.88344 13.0423 6.13996 12.8127 5.47686 12.4251L6.41663 10.8173C6.83276 11.0605 7.29932 11.2046 7.78012 11.2384C8.26093 11.2722 8.74306 11.1947 9.1891 11.012C9.63514 10.8294 10.0331 10.5464 10.3521 10.1851C10.6711 9.82379 10.9026 9.39383 11.0286 8.9286C11.1546 8.46337 11.1717 7.97536 11.0786 7.50244C10.9855 7.02953 10.7848 6.58441 10.4919 6.20163C10.1989 5.81884 9.82179 5.50869 9.38965 5.29522C8.9575 5.08175 8.48199 4.97071 8 4.97071V3.1084Z" />
    </mask>
    <path
      d="M8 3.1084C8.76807 3.1084 9.52581 3.28535 10.2144 3.62552C10.9031 3.96568 11.5041 4.45993 11.9708 5.0699C12.4376 5.67988 12.7575 6.38918 12.9059 7.14279C13.0542 7.8964 13.0269 8.67405 12.8261 9.41541C12.6254 10.1568 12.2565 10.8419 11.7481 11.4177C11.2398 11.9935 10.6056 12.4444 9.89486 12.7354C9.18408 13.0265 8.4158 13.1499 7.64962 13.0961C6.88344 13.0423 6.13996 12.8127 5.47686 12.4251L6.41663 10.8173C6.83276 11.0605 7.29932 11.2046 7.78012 11.2384C8.26093 11.2722 8.74306 11.1947 9.1891 11.012C9.63514 10.8294 10.0331 10.5464 10.3521 10.1851C10.6711 9.82379 10.9026 9.39383 11.0286 8.9286C11.1546 8.46337 11.1717 7.97536 11.0786 7.50244C10.9855 7.02953 10.7848 6.58441 10.4919 6.20163C10.1989 5.81884 9.82179 5.50869 9.38965 5.29522C8.9575 5.08175 8.48199 4.97071 8 4.97071V3.1084Z"
      stroke="#85C1D7"
      strokeWidth="3"
      mask="url(#path-2-inside-2_57960_21144)"
    />
  </svg>
)

export const ProgramPercentIcon = (props) => (
  <Icon component={programPercent} {...props} />
)

const duplicate = ({ width, height }) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...{ width, height }}
  >
    <path
      d="M17 5H1V23H17V5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 1H21V21"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 10H13"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 14H13"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 18H9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DuplicateIcon = (props) => (
  <Icon component={duplicate} {...props} />
)

const empty = ({ width, height }) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    fill="none"
    {...{ width, height }}
  >
    <path d="M19 17H13V23L19 17Z" fill="currentColor" />
    <path
      d="M19 17H13V23"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      // stroke="currentColor"
    />
    <path
      d="M19 17V1H1V23H13L19 17Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      // stroke="currentColor"
    />
  </svg>
)

export const EmptyIcon = (props) => <Icon component={empty} {...props} />

const actionItemCount = (props) => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6.10645H12.2727"
        stroke="#85C1D7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M2.45455 6.10645H1"
        stroke="#85C1D7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 6.10645H15.5454"
        stroke="#85C1D7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M5.36368 2.47115C5.36368 1.66782 4.71246 1.0166 3.90914 1.0166C3.10581 1.0166 2.45459 1.66782 2.45459 2.47115V9.74387C2.45459 10.5472 3.10581 11.1984 3.90914 11.1984C4.71246 11.1984 5.36368 10.5472 5.36368 9.74387V2.47115Z"
        stroke="#2B4B8F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M12.6363 9.74565C12.6363 10.549 13.2875 11.2002 14.0909 11.2002C14.8942 11.2002 15.5454 10.549 15.5454 9.74565L15.5454 2.47292C15.5454 1.6696 14.8942 1.01838 14.0909 1.01838C13.2875 1.01838 12.6363 1.6696 12.6363 2.47292V9.74565Z"
        stroke="#2B4B8F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  )
}

export const ActionItemCountIcon = (props) => (
  <Icon component={actionItemCount} {...props} />
)
