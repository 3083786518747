import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Pagination } from 'antd'
import { animateScroll as scroll } from 'react-scroll'
import { table } from './index.sass'

export class DataTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    total: PropTypes.number,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    hidePagination: PropTypes.bool,
    onClickRow: PropTypes.func
  }

  onChange = (page, pageSize) => {
    scroll.scrollToTop({ containerId: 'container' })
    this.props.onChange(page, pageSize)
  }

  render() {
    const {
      columns,
      dataSource,
      total,
      loading,
      hidePagination,
      className,
      onClickRow,
      rowKey,
      scroll,
      extra
    } = this.props
    return (
      <div className={table}>
        <Table
          scroll={scroll}
          className={className}
          columns={columns}
          dataSource={[...dataSource]}
          rowKey={rowKey || 'id'}
          pagination={false}
          loading={loading}
          onRow={(record, index) => ({
            index,
            record,
            onClick: (e) => onClickRow && onClickRow(record)
          })}
          {...extra}
        />
        {!hidePagination && (
          <div style={{ textAlign: 'right', paddingTop: '20px' }}>
            <Pagination total={total} onChange={this.onChange} />
          </div>
        )}
      </div>
    )
  }
}

export default DataTable
