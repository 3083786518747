import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ProgressCard from './card'
import { GoalsMethods } from '../../../utils/common'

class GoalsList extends Component {
  static propTypes = {
    period: PropTypes.string,
    list: PropTypes.array,
    openModal: PropTypes.func,
    trackingOuraMetric: PropTypes.array,
    extra: PropTypes.object
  }

  ouraSleepHandle = (ouraSleep) => {
    const { list, trackingOuraMetric } = this.props
    const result = [ouraSleep]
    if (ouraSleep.micro_goal.archived) {
      return result
    }
    const children = list.filter(
      (item) => item.micro_goal.parent_micro_goal_id === ouraSleep.micro_goal.id
    )
    for (const metric of (trackingOuraMetric || []).filter(
      (item) => item.title !== 'Sleep Stages'
    )) {
      const targerts = children.filter(
        (item) => item.micro_goal.group_name === metric.title
      )

      if (targerts.length > 0) {
        const targetGoal = {
          micro_goal: {
            input_type: 'number',
            micro_goal: metric.title,
            id:
              metric.title + ((targerts[0] && targerts[0].id) || Math.random()),
            has_parent: true,
            cron_day_month:
              (targerts[0] && targerts[0].micro_goal.cron_day_month) || '*',
            cron_day_week:
              (targerts[0] && targerts[0].micro_goal.cron_day_week) || '*',
            source: 'ouraring',
            chart_type:
              metric.title === 'Total Sleep Time' || metric.title === 'Bed Time'
                ? 'multi-bar'
                : 'multi-line'
          },
          children: targerts,
          achieving: (targerts[0] && targerts[0].achieving) || []
        }

        if (metric.title === 'Sleep Efficiency') {
          targetGoal.aggregation = targerts[0] && targerts[0].aggregation
        }
        result.push(targetGoal)
      }
    }
    return result
  }

  getList() {
    const { list, extra } = this.props
    let goals = list.filter((item) => !item.micro_goal.parent_micro_goal_id)
    goals = goals.map((item) => {
      const unit = item.micro_goal.unit
      if (item.aggregation && (unit === 'Time' || unit === 'Minutes')) {
        const [avg, min, max] = [
          item.aggregation.avg,
          item.aggregation.min,
          item.aggregation.max
        ].map((t) =>
          t || t === 0 ? GoalsMethods.formatVal(parseInt(t), unit) : 'n/a'
        )
        item.aggregation = Object.assign(item.aggregation, { avg, min, max })
        if (unit === 'Minutes') {
          item.unit = '' // hide minutes unit
        }
      }

      if (item.micro_goal.auto_metric === 'MaxHeartRate') {
        item.micro_goal.fixed_target = extra.max_heart_rate
      }

      return item
    })
    return goals
  }

  render() {
    const list = this.getList()
    return (
      <div className="list-container">
        {list &&
          list.map((item) => (
            <ProgressCard
              {...this.props}
              goal={item}
              key={item.micro_goal.id}
            />
          ))}
      </div>
    )
  }
}

export default GoalsList
