import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryLabel,
  VictoryScatter
} from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { getAxisX, splitLine, tickLabels, xAxisFormat } from '../common'
import { fontFamily } from '../../../utils/constant'
import Arrow from './arrow'

// const MINUTE_DATA = [null, 131.3, null, 128, null, 129, 130]

function Dot(props) {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 3}
      y={(props.y && props.y - 3) || 0}
    >
      <circle
        cx="3"
        cy="3"
        r="1.75"
        fill="white"
        stroke={props.stroke || '#3194EB'}
        strokeWidth="1.5"
      />
    </svg>
  )
}

function Label(props) {
  const { isMax, isMin } = props.datum
  if (!isMax && !isMin) return null
  return (
    <>
      <rect
        rx="2"
        ry="2"
        width="32"
        height="14"
        x={props.x}
        y={isMin ? props.y + 24 : props.y}
        fill="#70808E"
        opacity="0.2"
        transform="translate(-16, -10)"
      />
      <text
        id="Text"
        fontSize={9}
        x={props.x}
        y={isMin ? props.y + 24 : props.y}
        fill="#70808E"
      >
        <tspan textAnchor="middle">{props.datum.y.toFixed(1)}</tspan>
      </text>
    </>
  )
}

class BodyMass extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    entries: PropTypes.array,
    extraAxisText: PropTypes.string,
    target: PropTypes.number,
    tickFormat: PropTypes.func,
    showXLabel: PropTypes.bool,
    showMaxAndMinLabel: PropTypes.bool
  }
  getTickLabels = (fill) => Object.assign(_.cloneDeep(tickLabels), { fill })

  getTickValuesY() {
    const { target, entries } = this.props
    let _min, _max
    let refferData = [target]
    if (entries) {
      refferData = [target].concat(entries.filter((item) => item))
    }
    _min = Math.min(...refferData)
    _max = Math.max(...refferData)
    if (_max === 0) _max = 100
    if (_min === 0 && target) _max = target * 2
    if (_min >= 1) _min -= 1
    _max += 1
    const result = [_min, target, _max].filter((item) => item || item === 0)
    return result
  }

  getGridStroke = (t) => {
    const { target } = this.props
    if (t === target) {
      return '#E4EAEF'
    }
    return 'transparent'
  }

  lineDataHandle(tickValuesX = []) {
    const { entries, stroke } = this.props
    const durations = tickValuesX.map((x, index) => {
      return { x, y: entries[index] }
    })
    let { solidGroup, dashGroup } = splitLine(durations)
    solidGroup = solidGroup.map((item) => ({
      data: item,
      dataStyle: {
        stroke: stroke || '#3194EB'
      }
    }))
    dashGroup = dashGroup.map((item) => ({
      data: item,
      dataStyle: {
        stroke: stroke || '#3194EB',
        strokeDasharray: '3,3'
      }
    }))
    const lineData = solidGroup.concat(dashGroup)
    return lineData
  }

  emptyDataHandle(tickValuesX = []) {
    const { stroke } = this.props
    const data = tickValuesX.map((item) => ({ x: item, y: 50 }))
    return [
      {
        data,
        dataStyle: {
          stroke: stroke || '#3194EB',
          strokeDasharray: '3,3'
        }
      }
    ]
  }

  getDots(tickValuesX) {
    const { entries } = this.props
    const validEntries = entries.filter((item) => item)
    let max, min
    if (validEntries.length > 1) {
      max = Math.max(...validEntries)
      min = Math.min(...validEntries)
    }
    let hasMax, hasMin
    const data = tickValuesX
      .map((item, index) => {
        const y = entries[index]
        const isMax = !hasMax && max === y
        hasMax = hasMax || isMax
        const isMin = !hasMin && !isMax && min === y
        hasMin = hasMin || isMin
        return {
          x: item,
          y,
          isMax,
          isMin
        }
      })
      .filter((item) => item.y)
    return data
  }

  render() {
    const { startDate, endDate, target, showXLabel, stroke } = this.props
    const tickValuesX = startDate && endDate && getAxisX(startDate, endDate)
    // const avg = 131
    let lineData, entries
    if (tickValuesX) {
      lineData = this.lineDataHandle(tickValuesX)
      if (lineData.length === 0) {
        lineData = this.emptyDataHandle(tickValuesX)
      } else {
        entries = this.getDots(tickValuesX)
      }
    }
    const tickValuesY = this.getTickValuesY()
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart width={width} height={70}>
            <VictoryAxis
              style={{
                axis: {
                  stroke: 'transparent'
                },
                tickLabels: this.getTickLabels('transparent')
              }}
              tickValues={tickValuesX}
              tickLabelComponent={
                <VictoryLabel text={(datum) => xAxisFormat(datum)} />
              }
            />
            <VictoryAxis
              invertAxis={true}
              dependentAxis
              orientation="right"
              tickValues={tickValuesY}
              style={{
                axis: { stroke: 'transparent' },
                grid: {
                  stroke: (t) => (t === target ? '#E4EAEF' : 'transparent'),
                  strokeDasharray: '3,3'
                },
                tickLabels: this.getTickLabels((t) =>
                  t === target ? '#70808E' : 'transparent'
                )
              }}
            />
            <VictoryAxis
              invertAxis={true}
              dependentAxis
              tickValues={tickValuesY}
              tickLabelComponent={<Arrow />}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: this.getTickLabels((t) =>
                  t === target ? '#70808E' : 'transparent'
                )
              }}
            />
            <VictoryAxis
              invertAxis={true}
              dependentAxis
              tickValues={tickValuesY}
              tickLabelComponent={
                <VictoryLabel
                  text={'Avg'}
                  angle={-90}
                  dy={-8}
                  textAnchor="middle"
                />
              }
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: this.getTickLabels((t) =>
                  t === target ? '#70808E' : 'transparent'
                )
              }}
            />
            {lineData &&
              lineData.map((item, index) => (
                <VictoryLine
                  key={index}
                  style={{
                    data: item.dataStyle
                  }}
                  data={item.data}
                />
              ))}

            {entries && (
              <VictoryScatter
                data={entries}
                style={{
                  labels: {
                    fontSize: 9,
                    fill: '#70808E'
                  }
                }}
                dataComponent={<Dot stroke={stroke} />}
                {...(showXLabel
                  ? {
                      labels: (datum) => datum.y,
                      labelComponent: <Label />
                    }
                  : {})}
              />
            )}
            {showXLabel && (
              <VictoryAxis
                offsetY={20}
                tickValues={tickValuesX}
                tickFormat={(x) => x.format && x.format('ddd').toUpperCase()}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fontFamily: `"Gilroy",${fontFamily}`,
                    fontSize: '10px',
                    fill: '#70808E'
                  }
                }}
              />
            )}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default BodyMass
