import React, { useMemo } from 'react'
import _, { get, head } from 'lodash'
import moment from 'moment'
import ContainerWidthSizer from '../../../components/UI/ContainerWidthSizer'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory'
import { calcTextWidth } from '../../../utils/String+Extension'
import { MicrobiomeChartContainer } from '../index.style'

export default function ConstantChart(props) {
  const { details, subject } = props

  const { data, tickValuesX, tickValuesY } = getMetaData()

  const hoverTextWidths = useMemo(() => {
    return data.map((obj) => calcTextWidth(obj.text))
  }, [data])

  const optionalMsg = get(head(details), 'answer.value')

  return (
    <MicrobiomeChartContainer>
      <div className="header">
        <div className="name">{subject} </div>
      </div>
      {optionalMsg && <div className="optional-message">{optionalMsg}</div>}
      <div className="chart">
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart
              width={width}
              height={220}
              minDomain={{ x: 0.5, y: tickValuesY[0] }}
              padding={{ top: 40, right: 20, bottom: 40, left: 60 }}
              containerComponent={<VictoryVoronoiContainer />}
            >
              <VictoryAxis
                tickValues={tickValuesX}
                tickFormat={(t) => {
                  if (data[t - 1]) {
                    return moment(
                      data[t - 1].completion_date || data[t - 1].date
                    ).format('MMM DD')
                  } else {
                    return null
                  }
                }}
                style={{
                  tickLabels: {
                    fill: '#70808E',
                    fontSize: 10, //device === 'mobile' ? 10 : 14,
                    fontFamily: 'Gilroy'
                  },
                  axis: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  }
                }}
              />

              <VictoryAxis
                dependentAxis
                tickValues={tickValuesY}
                tickFormat={(t) => {
                  return ''
                }}
                style={{
                  axis: {
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  },
                  grid: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  }
                }}
              />

              <VictoryLine
                data={data}
                style={{
                  data: { stroke: '#E2E5E9', strokeWidth: 2 }
                }}
              />

              <VictoryScatter
                style={{
                  labels: {
                    fontSize: 15,
                    fill: ({ datum }) => datum.status
                  }
                }}
                data={data}
                dataComponent={
                  <Dot
                    name="data-scatter"
                    maxY={_.cloneDeep(tickValuesY).pop()}
                  />
                }
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
              />

              <VictoryScatter
                data={data}
                dataComponent={<Dot name="data-scatter" noLabel />}
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
                labelComponent={
                  <VictoryTooltip
                    labelComponent={<HoverInfoPoint widths={hoverTextWidths} />}
                    dx={110}
                    flyoutPadding={10}
                    pointerLength={0}
                    flyoutStyle={{
                      stroke: 'transparent',
                      fill: 'transparent'
                    }}
                  />
                }
              />
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      </div>
    </MicrobiomeChartContainer>
  )

  function getMetaData() {
    let tickValuesY = [],
      rangeArr = [],
      data = []

    tickValuesY = [0, 1, 2, 3]
    rangeArr = [
      { values: [0, 1], score: 1 },
      { values: [1, 2], score: 2 },
      { values: [2, 3], score: 3 }
    ]

    data = _.cloneDeep(details)
      .reverse()
      .map((detail, index) => {
        const { range, date, score, score_value, answer, type } = detail
        const isVirtual = answer && typeof answer.value === 'string'
        let value
        if (_.isNumber(score_value)) {
          value = score_value || (!isVirtual && answer.value) || 0
        } else {
          if (range.length === 2) {
            value = (range[0] + range[1]) / 2
          } else {
            value = range[0]
          }
        }

        return {
          y: 1.5,
          x: index + 1,
          date: date || detail.completion_date,
          score,
          score_value: value,
          type,
          text: get(answer, 'value')
        }
      })

    let n = 8

    if (data.length > n) {
      data = data.slice(data.length - n)
      data.forEach((item, index) => {
        item.x = index + 1
      })
    }
    const tickValuesX = new Array(n + 1).fill(0).map((_, index) => index)

    return {
      tickValuesX,
      data,
      tickValuesY,
      stackDomain: []
    }
  }
}

function Dot(props) {
  return (
    <svg
      width="200"
      height="35"
      viewBox="0 0 200 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 7}
      y={(props.y && props.y - 18) || 0}
    >
      <circle
        cx="7"
        cy="5"
        r="4"
        fill="#264382" //"white"
        stroke="#264382"
        strokeWidth="2"
      />
    </svg>
  )
}

function HoverInfoPoint(props) {
  const { x, y, datum, widths } = props

  const dx = -75,
    dy = -14

  const width = widths[datum.x - 1] + 30
  return (
    <g>
      <rect
        dx={dx}
        dy={dy}
        x={x - 90}
        y={y - 32}
        height="68"
        rx="6"
        width={width < 135 ? 135 : width}
        stroke="#E6E9F2"
        strokeWidth="2"
        fill="white"
      />
      <circle
        cx={x - 70}
        cy={y - 11}
        r="3"
        // stroke={datum.status}
        // strokeWidth="2"
        fill={'#274382'}
      />
      <text dx={dx} dy={dy} x={x + 17} y={y + 7} fontSize={13} fill="#323F4A">
        {moment(datum.date).format('MMM DD')}
      </text>

      <text dx={dx} dy={dy} x={x} y={y + 29} fontSize={13} fill="#9AA5B1">
        {datum.text}
      </text>
    </g>
  )
}
