import React from 'react'
import PropTypes from 'prop-types'
import { getCategories, getDataset } from '../../../api/dataset'
// import { getRangeAgeScopes } from '../../../api/rangeAgeScope'
import {
  getDbFields,
  injectCategories,
  filterBiomarkerWithoutPersonValue
} from './common'

export default (WrappedComponent) =>
  class ClientContainerWrapper extends React.Component {
    static propTypes = {
      type: PropTypes.string
    }

    state = {
      dataLoading: true,
      isInitialDone: false
    }

    initial = async (type) => {
      if (!this.state.isInitialDone) {
        this.setState({ isInitialDone: true })
      }
      const {
        slideMenu: { groups }
      } = this.props
      const dbField = getDbFields(type)
      this.setState({ dbField, dataLoading: true })
      const { metricsField, biomarkerIdField } = dbField
      try {
        const targetMenu = groups[type.replace('-', '_')] || { categories: [] }
        let categories = await getCategories({
          type,
          ids: targetMenu.categories.map((item) => item.id)
        })
        const datasets = await getDataset({
          type,
          group: dbField.groupType
        })
        const dataset = datasets[0]
        if (dataset && categories) {
          categories = injectCategories(
            categories,
            dataset.snapshot,
            metricsField,
            biomarkerIdField
          )
          categories = filterBiomarkerWithoutPersonValue(
            categories,
            metricsField
          )
        }

        this.setState({
          dbField,
          categories,
          dataset,
          dataLoading: false
        })
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
      }
    }

    turnTo = (id) => {
      const { url, history } = this.props
      const { dataset } = this.state
      history.push(`${url}/${dataset.id}/${id}`)
    }

    componentDidUpdate(prevProps) {
      const {
        type,
        slideMenu: { groups }
      } = this.props
      if ((prevProps.type !== type || !this.state.isInitialDone) && groups) {
        this.initial(type)
      }
    }

    render() {
      const childProps = {
        ...this.state,
        turnTo: this.turnTo
      }
      return <WrappedComponent {...this.props} {...childProps} />
    }
  }
