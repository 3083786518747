import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Information from '../../UI/extraInformation'
import { VictoryPie } from 'victory'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import TimingChart from './timingChart'
import { scoreSectionStyle } from '../styles/scoreSection.sass'
import IconCheckGrey from '../../../asserts/icon/check-grey.svg'

class ScoreSection extends Component {
  static propTypes = {}

  getPipeData = (entries, type) => {
    if (type === 'timing') {
      entries = entries.filter((item) => item.score)
    }
    const pipeData = [
      { x: 'AT RISK', y: 0, color: '#FF6B00', score: 1 },
      { x: 'CONCERN', y: 0, color: '#F5BA40', score: 2 },
      { x: 'OPTIMAL', y: 0, color: '#40C47C', score: 3 },
      { x: 'UNKNOW', y: 0, color: 'rgb(201, 208, 224)', score: null }
    ]
    return pipeData
      .map((data) => {
        if (data.score) {
          data.y = entries.filter((entry) => entry.score === data.score).length
        } else {
          data.y = entries.filter((entry) => !entry.score).length
        }
        return data
      })
      .filter((data) => data.y)
  }
  renderSection(item, showScore, index) {
    const { loginRole } = this.props
    const pipeData = this.getPipeData(item.entries, item.type)
    const version = showScore.section_version
    const isQuestionnaire = showScore.type === 'nutrition_questionnaire'
    return (
      <section className={scoreSectionStyle} key={index}>
        <div className="score-left">
          <div className="title">
            <div>{item.type}</div>
            <div className="value">
              {item.percentage_score || item.percentage_score === 0
                ? item.percentage_score + '%'
                : '--'}
            </div>
          </div>
          <div className="pipe-container">
            <div className="pipe">
              <VictoryPie
                innerRadius={100}
                data={pipeData}
                style={{
                  labels: { fill: 'transparent' },
                  data: {
                    fill: (datum) => datum.color
                  }
                }}
              />
            </div>
            <ul className="pipe-list">
              {pipeData.map((item, index) => (
                <li key={index}>
                  <div className="label-name">
                    <span style={{ background: item.color }} />
                    {item.x}
                  </div>
                  <div>{item.y}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="score-right">
          {isQuestionnaire && (
            <Information
              disMarkDone={true}
              title="RECOMMENDATION"
              initialValue={
                showScore.recommendation
                  ? showScore.recommendation[item.type]
                  : ''
              }
              updateEditingCount={(count) => {
                this.props.updateEditingCount(count)
              }}
              updateCommend={(value, status) => {
                this.props.saveRecommendation(
                  showScore.date,
                  {
                    [item.type]: value
                  },
                  showScore.section_version
                )
              }}
              status={loginRole === 'User' ? 'Opened' : 'Draft'}
            />
          )}
          {item.type === 'timing' && showScore.section_version >= 2 && (
            <TimingChart
              entries={item.entries}
              version={showScore.section_version}
            />
          )}
          <Entries
            entries={
              item.entries
              // item.type === 'timing'
              //   ? item.entries.filter((entry) => entry.score)
              //   : item.entries
            }
            showDetail={this.props.showDetail}
            version={version}
          />
        </div>
      </section>
    )
  }

  render() {
    const { score, targetScore } = this.props
    const showScore = targetScore || score[0]
    return (
      <>
        {showScore.answers.map((item, index) =>
          this.renderSection(item, showScore, index)
        )}
      </>
    )
  }
}

export default ScoreSection

class Entries extends Component {
  state = { showAll: false }

  renderState(score) {
    let scoreStr = '--'
    switch (score) {
      case 1:
        scoreStr = 'AT RISK'
        break
      case 2:
        scoreStr = 'CONCERN'
        break
      case 3:
        scoreStr = 'OPTIMAL'
        break
      default:
        break
    }
    return <div className={`status score-${score}`}>{scoreStr}</div>
  }

  renderAnswer(answer) {
    return <div className="status">{answer.value || answer}</div>
  }

  render() {
    const entries = this.state.showAll
      ? this.props.entries
      : this.props.entries.slice(0, 8)
    const { version } = this.props
    return (
      <>
        <div className="item-list">
          {entries.map((entry, _index) => (
            <div className="item" key={_index}>
              <div
                className="name"
                onClick={() => this.props.showDetail(entry, version)}
              >
                {entry.selected && <img src={IconCheckGrey} alt="" />}
                {entry.subject}
              </div>
              {entry.original_id && entry.original_id.includes('eating_window_')
                ? this.renderAnswer(entry.answer)
                : this.renderState(entry.score)}
            </div>
          ))}
        </div>
        {this.props.entries.length > 8 &&
          (this.state.showAll ? (
            <div
              className="more-opr"
              onClick={() => this.setState({ showAll: false })}
            >
              View Less <CaretUpOutlined />
            </div>
          ) : (
            <div
              className="more-opr"
              onClick={() => this.setState({ showAll: true })}
            >
              View More <CaretDownOutlined />
            </div>
          ))}
      </>
    )
  }
}
