import React from 'react'
import LockStatus from '../../components/UI/lockStatus'
import { loadUser } from '../../utils/storage'
import { userType } from '../../utils/constant'
import HealthScore from '../client/detail/healthScore'

export default function HealthScorePage(props) {
  const person = loadUser()
  const isPartner = person.profile.account_type === userType.Partners
  return (
    <div style={{ marginRight: 50, paddingTop: 40 }}>
      <h1 className="title">Health Compass</h1>
      {isPartner ? <LockStatus /> : <HealthScore {...props} />}
    </div>
  )
}
