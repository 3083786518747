import React from 'react'
import _, { get, max, min } from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBar,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory'

const Container = styled.div`
  padding: 16px 24px;
  &.Selected {
    background: rgba(38, 67, 130, 0.1);
  }
  &.Previously {
    background: #f5f5f4;
  }
  .header {
    display: flex;
    justify-content: space-between;
    line-height: 29px; /* 207.143% */
    letter-spacing: 0.3px;
    font-size: 12px;
    .name {
      color: #4c6072;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
      .tag {
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 10px;
        &.Selected {
          color: #ffffff;
          background: #264382;
        }
        &.Previously {
          color: #264382;
          background: #e2f2ff;
        }
      }
    }
  }
  .optimal {
    color: #40c47c;
    border-color: #40c47c;
  }
  .concern {
    color: #f5ba40;
    border-color: #f5ba40;
  }
  .at-risk,
  .risk {
    color: #ff6b00;
    border-color: #ff6b00;
  }
  .status-group {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    margin-bottom: 0;
    font-size: 14px;
    transform: scale(0.7);
    width: 117%;
    li:before {
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 2px solid;
      border-radius: 9px;
      margin-left: 17px;
      margin-right: 5px;
      background: currentColor;
    }
  }
  .chart {
    margin: 0 -20px;
    position: relative;
    padding-left: 20px;
    .chart-label {
      position: absolute;
      font-family: Gilroy-Bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808e;
      height: 13px;
      left: 25px;
      top: 50%;
      margin-top: -5px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .label-text {
        transform: translateX(-50%) rotate(-90deg) scale(0.72);
      }
    }
  }
`

export default function GeneralChart(props) {
  const {
    choices,
    details,
    subject,
    unit,
    className,
    selectTag,
    original_id,
    showStatusGroup = true
  } = props

  const isEatingWindow =
    (original_id && original_id.includes('eating_window')) ||
    ['Start of Eating Window', 'End of Eating Window'].includes(subject)
  const { data, tickValuesX, tickValuesY, highlightData } = getMetaData(
    isEatingWindow
  )
  return (
    <Container className={`${className} ${selectTag}`}>
      <div className="header">
        <div className="name">
          {subject}{' '}
          {selectTag && (
            <span className={`${selectTag} tag`}> {selectTag}</span>
          )}
        </div>
        {getCurrentStatus()}
      </div>
      {!isEatingWindow && showStatusGroup && (
        <ul className="status-group">
          <li className="optimal">OPTIMAL</li>
          <li className="concern">CONCERN</li>
          <li className="at-risk">AT RISK</li>
        </ul>
      )}
      <div className="chart">
        <div className="chart-label">
          <div className="label-text">{unit}</div>
        </div>
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart
              width={width}
              height={220}
              minDomain={{ x: 0.5, y: tickValuesY[0] }}
              padding={{ top: 40, right: 20, bottom: 40, left: 50 }}
              containerComponent={<VictoryVoronoiContainer />}
            >
              <VictoryAxis
                tickValues={tickValuesX}
                tickFormat={(t) => {
                  if (data[t - 1]) {
                    return moment(
                      data[t - 1].completion_date || data[t - 1].date
                    ).format('MMM DD')
                  } else {
                    return null
                  }
                }}
                style={{
                  tickLabels: {
                    fill: '#70808E',
                    fontSize: 10, //device === 'mobile' ? 10 : 14,
                    fontFamily: 'Gilroy'
                  },
                  axis: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  }
                }}
              />

              <VictoryAxis
                dependentAxis
                tickValues={tickValuesY}
                tickFormat={(t) => {
                  if (isEatingWindow) {
                    return moment((t + 17000000) * 100000).format('h A')
                  }
                  if (
                    props.type &&
                    props.type.toLowerCase().includes('percentage') &&
                    t === 100
                  ) {
                    return t + '%'
                  }
                  return t.toString()
                }}
                style={{
                  axis: {
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  },
                  grid: {
                    strokeDasharray: '3,3',
                    stroke: 'rgba(0, 0, 0, 0.1)'
                  },
                  tickLabels: {
                    fill: (e) => {
                      for (const { range, score } of choices) {
                        const [start, end] = range
                        if (Number(e) >= start && Number(e) <= end) {
                          return getStatus(score)
                        }
                      }
                      return '#52606C'
                    },
                    padding: 15,
                    fontSize: 10,
                    fontFamily: 'Gilroy',
                    fontWeight: 600
                  }
                }}
              />

              <VictoryBar
                barWidth={17}
                data={highlightData}
                labels={() => ['Nutrition', 'Questionnaire']}
                style={{
                  data: {
                    fill: 'rgba(217,217,217,0.2)'
                  },
                  labels: {
                    fontSize: 8,
                    fontWeight: 700,
                    fontFamily: 'Gilroy'
                  }
                }}
              />

              <VictoryBar
                barWidth={17}
                data={highlightData.map(({ x, y }) => ({
                  x,
                  y: tickValuesY[0]
                }))}
                style={{
                  data: {
                    stroke: '#656F77',
                    strokeWidth: 3
                  },
                  labels: {
                    fontSize: 8,
                    fontWeight: 700,
                    fontFamily: 'Gilroy'
                  }
                }}
              />

              <VictoryLine
                data={data}
                style={{
                  data: { stroke: '#E2E5E9', strokeWidth: 2 }
                }}
              />

              <VictoryScatter
                style={{
                  labels: {
                    fontSize: 15,
                    fill: ({ datum }) => datum.status
                  }
                }}
                data={data}
                dataComponent={
                  <Dot
                    name="data-scatter"
                    maxY={_.cloneDeep(tickValuesY).pop()}
                  />
                }
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
              />

              <VictoryScatter
                data={data}
                dataComponent={<Dot name="data-scatter" noLabel />}
                labels={({ datum }) => {
                  if (datum) {
                    return datum.score_value
                  }
                }}
                labelComponent={
                  <VictoryTooltip
                    labelComponent={<HoverInfoPoint />}
                    dy={-15}
                    dx={110}
                    flyoutPadding={10}
                    flyoutHeight={70}
                    flyoutWidth={190}
                    pointerLength={0}
                    flyoutStyle={{
                      stroke: '#E6E9F2',
                      strokeWidth: 2,
                      fill: 'white'
                    }}
                  />
                }
              />
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      </div>
    </Container>
  )

  function getCurrentStatus() {
    const currentRecord = details[0] || {}
    let status = 'optimal'
    if (isEatingWindow) {
      return <div>{currentRecord.score_value}</div>
    }
    switch (currentRecord.score) {
      case 1:
        status = 'at risk'
        break
      case 2:
        status = 'concern'
        break
      default:
        break
    }
    return <div className={status}>{status.toUpperCase()}</div>
  }

  function getMetaData(isEatingWindow) {
    let tickValuesY = [],
      rangeArr = [],
      data = []

    if (isEatingWindow) {
      const today = moment().format('YYYY-MM-DD')
      data = _.cloneDeep(details)
        .reverse()
        .map((detail, index) => {
          const { date, score, score_value, type } = detail
          const value = moment(`${today} ${score_value}`)

          return {
            y: value._d.getTime() / 100000 - 17000000,
            x: index + 1,
            date,
            score,
            score_value,
            isEatingWindow,
            // percent,
            percent_color: null,
            status: '#2B4B8E',
            // isVirtual,
            type
          }
        })
      const values = [
        ...data.map(({ y }) => {
          const minute = Number(moment((y + 17000000) * 100000).format('m'))
          const hour = Number(moment((y + 17000000) * 100000).format('H'))
          return minute > 0 ? hour + 1 : hour
        }),
        ..._.flatten(choices.map((item) => item.range)).map((item) =>
          parseInt(item)
        )
      ]
      const _max = max(values),
        _min = min(values)
      const step = (_max - _min) / 3

      tickValuesY = [_min, _min + step, _min + step * 2, _max].map((item) => {
        const H = Math.floor(item)
        const M = Math.round((item % 1) * 60)
        return (
          moment(
            `${today} ${H > 10 ? H : '0' + H}:${M > 10 ? M : '0' + M}`,
            'YYYY-MM-DD HH:mm'
          )._d.getTime() /
            100000 -
          17000000
        )
      })
    } else {
      if (props.type === 'Food type') {
        tickValuesY = [0, 1, 2, 3]
        rangeArr = [
          { values: [0, 1], score: 1, status: getStatus(1) },
          { values: [1, 2], score: 2, status: getStatus(2) },
          { values: [2, 3], score: 3, status: getStatus(3) }
        ]
      } else {
        for (let i = 0, l = choices.length; i < l; i++) {
          const nextIndex = i + 1
          const { range, score } = choices[i]
          const nextChoice = choices[nextIndex]
          let _min, _max
          _min = range[0]
          if (nextChoice) {
            _max = nextChoice.range[0]
          } else if (range[1]) {
            _max = range[1]
          } else {
            _max = range[0] + (range[0] - choices[i - 1].range[0])
          }
          rangeArr.push({
            values: [_min, _max],
            score,
            status: getStatus(score)
          })
        }

        choices.forEach((e) => {
          tickValuesY = [...tickValuesY, ...e.range]
        })

        tickValuesY = _.uniq(tickValuesY)
          .filter((item) => _.isInteger(item))
          .sort((a, b) => a - b)
      }
      data = _.cloneDeep(details)
        .reverse()
        .map((detail, index) => {
          const { range, date, score, score_value, answer, type } = detail
          const isVirtual = answer && typeof answer.value === 'string'
          let value
          if (_.isNumber(score_value)) {
            value = score_value || (!isVirtual && answer.value) || 0
          } else {
            if (range.length === 2) {
              value = (range[0] + range[1]) / 2
            } else {
              value = range[0]
            }
          }

          let percent = 0
          let percent_color
          if (index !== 0) {
            const previousValue = details[index - 1].score_value
            if (previousValue === 0) {
              percent = score_value * 100
            } else {
              percent = ((score_value - previousValue) / previousValue) * 100
              percent = percent.toFixed(0)
            }
            percent_color = getPercentColor(
              previousValue,
              score_value,
              rangeArr
            )
          } else {
            percent_color = getPercentColor(null, score_value, rangeArr)
          }

          return {
            y: value,
            x: index + 1,
            date: date || get(detail, 'record_date'),
            score,
            score_value: value,
            percent,
            percent_color,
            status: getStatus(score),
            isVirtual,
            type
          }
        })
    }

    let n = 8

    if (data.length > n) {
      data = data.slice(data.length - n)
      data.forEach((item, index) => {
        item.x = index + 1
      })
    }
    const tickValuesX = new Array(n + 1).fill(0).map((_, index) => index)

    return {
      tickValuesX,
      data,
      highlightData: data
        .filter((item) => item.type === 'nutrition_questionnaire')
        .map(({ x, y, status }) => ({
          x,
          y: max(tickValuesY),
          status: transformStatus(status)
        })),
      tickValuesY,
      stackDomain: []
    }
  }

  function getPercentColor(prev, cur, range, cur_score) {
    const temp = range.find((e) => e.score === 3).values
    if (prev === cur || prev === null) {
      if (cur_score === 3) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }

    const average = (temp[0] + temp[1]) / 2
    const riseValue = cur - prev
    if (prev >= average) {
      // if previous score is greater than average, current score is better to down
      if (riseValue <= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue <= 0 && cur >= temp[1]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    } else {
      // if previous score is greater than average, current score is better to up
      if (riseValue >= 0 && cur >= temp[0] && cur < temp[1]) {
        return '#40C47C'
      } else if (riseValue >= 0 && cur <= temp[0]) {
        return '#40C47C'
      } else {
        return '#FF6B00'
      }
    }
  }
}

function getStatus(score) {
  let status
  switch (score) {
    case 1:
      status = '#FF6B00'
      break
    case 2:
      status = '#F5BA40'
      break
    case 3:
      status = '#40C47C'
      break
    default:
      break
  }
  return status
}

function transformStatus(status) {
  switch (status) {
    case '#40C47C':
      return 'rgba(64,196,124,0.2)'
    case '#F5BA40':
      return 'rgba(245,186,64,0.2)'
    case '#FF6B00':
      return 'rgba(255,107,0,0.2)'
    default:
      return 'transparent'
  }
}

function Dot(props) {
  const {
    noLabel,
    maxY,
    datum: { isVirtual, score_value, status, isEatingWindow, type, y }
  } = props
  const dx = type === 'nutrition_questionnaire' && y === maxY ? 10 : 0
  return (
    <svg
      width="200"
      height="35"
      viewBox="0 0 200 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 7}
      y={(props.y && props.y - 18) || 0}
    >
      {!noLabel && (
        <text
          style={{ textAnchor: isEatingWindow ? 'start' : 'middle' }}
          dy={-5 + dx}
          dx={dx}
          x={isEatingWindow ? 0 : 7}
          fontSize={9}
          fill={status}
        >
          {Number(score_value) ? Math.round(score_value) : score_value}
        </text>
      )}
      <circle
        cx="7"
        cy="5"
        r="4"
        fill={status} //"white"
        stroke={status}
        strokeWidth="2"
        strokeDasharray={isVirtual ? 2 : 0}
      />
    </svg>
  )
}

function HoverInfoPoint(props) {
  const { x, y, datum } = props
  const dx = -75,
    dy = -12
  return (
    <g>
      <rect
        dx={dx}
        dy={dy}
        x={x - 90}
        y={y - 32}
        height="68"
        rx="6"
        width="190"
        stroke="#E6E9F2"
        strokeWidth="2"
        fill="white"
      />
      <text
        dx={dx}
        dy={dy}
        x={x}
        y={y}
        fontSize={13}
        fill="#616E7C"
        style={{ fontWeight: 600 }}
      >
        Servings per day
      </text>
      <circle
        cx={x - 70}
        cy={y + 13}
        r="5"
        stroke={datum.status}
        strokeWidth="2"
        fill={datum.status}
      />
      <text dx={dx} dy={dy} x={x + 17} y={y + 29} fontSize={11} fill="#9AA5B1">
        {moment(datum.date).format('MMM DD')}
      </text>

      <text
        dx={dx}
        dy={dy}
        x={datum.percent_color ? x + 100 : x + 160}
        y={y + 29}
        fontSize={13}
        fill={datum.status}
        textAnchor="end"
        width="100"
        style={{ fontWeight: 600 }}
      >
        {Number(datum.score_value)
          ? Math.round(datum.score_value)
          : datum.score_value}
      </text>
      {datum.percent_color && (
        <>
          <rect
            dx={dx}
            dy={dy}
            x={x + 30}
            y={y - 1}
            fontSize={13}
            height="26"
            rx="4"
            width="62"
            fill={datum.percent_color}
            style={{ opacity: 0.1 }}
          ></rect>
          {datum.percent !== 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x={x + 40}
              y={y + 8}
              width="6"
              height="8"
              viewBox="0 0 4 6"
              fill="none"
            >
              <path
                fill={datum.percent_color}
                transform={datum.percent > 0 ? 'rotate(-180)' : ''}
                style={{ transformOrigin: 'center' }}
                dx={dx}
                dy={dy}
                x={x + 120}
                y={y + 14}
                fontSize={13}
                d="M3.7388 4.04064L4 3.74631L3.4776 3.15763L3.2164 3.45197L3.7388 4.04064ZM2 5.41133L1.7388 5.70571L2 6L2.26121 5.70571L2 5.41133ZM0.783612 3.45197L0.52241 3.15763L0 3.74631L0.261209 4.04064L0.783612 3.45197ZM3.2164 3.45197L1.7388 5.11695L2.26121 5.70571L3.7388 4.04064L3.2164 3.45197ZM2.26121 5.11695L0.783612 3.45197L0.261209 4.04064L1.7388 5.70571L2.26121 5.11695ZM2.3694 5.41133L2.3694 0L1.63061 0L1.63061 5.41133H2.3694Z"
              />
            </svg>
          )}
          <text
            dx={dx}
            dy={dy}
            x={x + 125}
            y={y + 29}
            fontSize={13}
            fill={datum.percent_color}
            style={{ fontWeight: 600 }}
          >
            {Math.abs(datum.percent)}%
          </text>
        </>
      )}
    </g>
  )
}
