import React, { useState } from 'react'
import Confirm from '../../../components/UI/confirmModal'
import { Button } from 'antd'

export default function ConfirmDelete(props) {
  const { handleDelete, onCancel, challenge } = props
  const [isDeleting, setIsDeleting] = useState(false)
  async function onConfirm() {
    setIsDeleting(true)
    await handleDelete()
    setIsDeleting(false)
    onCancel()
  }
  return (
    <Confirm
      {...{
        modalWidth: 564,
        title: (
          <span
            style={{
              fontSize: 16,
              color: '#262626',
              lineHeight: 1.7,
              display: 'inline-block'
            }}
          >
            {getModalTitle()}
          </span>
        ),
        description: getModalDescription(),
        onCancel: onCancel,
        footer: (
          <>
            <Button onClick={onCancel} style={{ height: 'auto' }}>
              Cancel
            </Button>
            <Button
              danger
              loading={isDeleting}
              disabled={isDeleting}
              style={{ height: 'auto', marginLeft: 20 }}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </>
        )
      }}
    />
  )

  function getModalTitle() {
    switch (challenge.status) {
      case 'past':
        return `Do you want to delete this past challenge: ”${challenge.name}”?`
      case 'active':
        return `Do you want to delete this active challenge: ”${challenge.name}”?`
      default:
        return 'Do you want to delete this scheduled Challenge?'
    }
  }

  function getModalDescription() {
    switch (challenge.status) {
      case 'past':
        return (
          <div>
            Please be aware when this challenge is deleted:
            <ul style={{ paddingLeft: 20 }}>
              <li>You can't access to this challenge anymore</li>
              <li>
                You will lose all the challenge data and not be able to retrieve
                this challenge data
              </li>
            </ul>
          </div>
        )
      case 'active':
        return (
          <div>
            Please be aware when this challenge is deleted:
            <ul style={{ paddingLeft: 20 }}>
              <li>You can't access to this challenge anymore</li>
              <li>All participants will be force to quit the challenge</li>
              <li>
                You will lose all the challenge data and not be able to retrieve
                this challenge data
              </li>
            </ul>
          </div>
        )
      default:
        return null
    }
  }
}
