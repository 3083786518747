import React, { Component } from 'react'
import PropTypes from 'prop-types'
import KeyTakeaway from '../../../components/business/creport/keyTakeaway'
import ReportFiles from '../../../components/business/creport/files'

class LifestylePreview extends Component {
  static propTypes = {
    report: PropTypes.object,
    dealHtml: PropTypes.func
  }

  render() {
    const { report, dealHtml } = this.props
    return (
      <div className="report-physical report-section">
        <h1 className="section-title">3. LIFESTYLE ASSESSMENT</h1>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.lifestyle_assessment_intro)
          }}
        />
        <h3 className="section-sub-title">Stress Summary</h3>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.lifestyle_assessment_stress_summary)
          }}
        />
        <h3 className="section-sub-title">Recovery Summary</h3>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.lifestyle_assessment_recovery_summary)
          }}
        />
        <h3 className="section-sub-title">Key Takeaway</h3>
        {report.lifestyle_assessment_takeaway && (
          <KeyTakeaway initialValue={report.lifestyle_assessment_takeaway} />
        )}
        {(report.lifestyle_assessment_media_files ||
          report.lifestyle_assessment_media_intro) && (
          <ReportFiles
            readOnly={true}
            files={report.lifestyle_assessment_media_files || []}
            introduction={report.lifestyle_assessment_media_intro}
          />
        )}
      </div>
    )
  }
}

export default LifestylePreview
