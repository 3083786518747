import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryGroup,
  VictoryLine,
  VictoryTooltip,
  VictorySharedEvents,
  VictoryBar
  // VictoryVoronoiContainer
} from 'victory'

import ContainerWidthSizer from '../../UI/ContainerWidthSizer'

class TimeLineGroup extends Component {
  static propTypes = {}

  defaultDataLength = 9
  state = {
    targetScore: null,
    targetIndex: this.props.defaultTargetIndex || this.defaultDataLength
  }

  geTickValuesX = (list) => {
    let tickValues = list.map((item) => moment(item.completion_date))
    const l = tickValues.length
    const latestData = tickValues[l - 1]
    if (l < this.defaultDataLength) {
      tickValues = tickValues.concat(
        new Array(this.defaultDataLength - l)
          .fill(0)
          .map((_, index) => moment(latestData).subtract(index + 1, 'months'))
      )
    }

    return tickValues.reverse()
  }

  getData = (tickValuesX, list, targetIndex) => {
    return this.props.items.map((item, index) => {
      const latestRecord = list[this.defaultDataLength - targetIndex] //.find((record) => record[item.name] >= 0)
      // console.log('list and latestRecord', list, latestRecord)
      const latestData = latestRecord[item.name]

      item.data = tickValuesX.map((x, _idx) => {
        const target = list.find(
          (record) => record.completion_date === x.format('YYYY-MM-DD')
        )
        const value =
          target && typeof target[item.name] === 'number'
            ? target[item.name]
            : null

        const heightlight =
          target && target['type'] === 'nutrition_questionnaire' ? true : false

        let y = value

        if (y) {
          if (y < 0) {
            y = (index + 1) * 100
          } else {
            y =
              latestData > 0
                ? (index + 1) * 100 + (value - latestData) //(y * (index + 1) * 100) / latestData
                : y + (index + 1) * 100
          }
        } else if (y === 0) {
          y = (index + 1) * 100
        }
        return { x: _idx, y, value, heightlight }
      })

      const latestDataIndex = item.data.findIndex((data) => data.y)
      item.emptyData = item.data.map(({ x }, _idx) => {
        return {
          x,
          y: _idx <= latestDataIndex ? item.data[latestDataIndex].y : null
        }
      })

      item.latestData = latestData

      return item
    })
  }

  clickGridHandle = (index) => {
    const { score } = this.props
    const targetScore = score[this.defaultDataLength - index - 1]
    if (targetScore) {
      this.setState({ targetScore, targetIndex: index })
      this.props.selectScore(targetScore)
    }
  }

  renderChartGroup(item, index) {
    return (
      <VictoryGroup key={index}>
        <VictoryLine
          name="data-line"
          data={item.data}
          style={{
            data: { stroke: '#ffffff' }
          }}
          labelComponent={<VictoryTooltip active={true} />}
        />
        <VictoryLine
          data={item.emptyData}
          style={{
            data: {
              stroke: 'rgba(255,255,255,0.4)',
              strokeDasharray: '3,3'
            }
          }}
        />
        <VictoryScatter
          data={item.data}
          size={({ heightlight }) => {
            return heightlight ? 6 : 3
          }}
          style={{
            data: {
              fill: ({ heightlight }) => {
                return heightlight ? '#ffffff' : '#000000'
              },
              stroke: ({ heightlight }) => {
                return heightlight ? '#838C98' : '#ffffff'
              },
              strokeWidth: ({ heightlight }) => {
                return heightlight ? 4 : 2
              }
            }
          }}
        />
      </VictoryGroup>
    )
  }

  isNutritionQuestionnaire(date) {
    const find = this.props.score.find(
      (e) =>
        e.completion_date === date.format('YYYY-MM-DD') &&
        e.type === 'nutrition_questionnaire'
    )
    return find
  }

  render() {
    const { score } = this.props
    const { targetIndex } = this.state
    const tickValuesX = this.geTickValuesX(score)
    const data = this.getData(tickValuesX, score, this.defaultDataLength) //this.defaultDataLength  --> targetIndex)
    console.log('data: ', data)
    return (
      <>
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="myGradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="rgba(75, 89, 105,0)" />
              <stop offset="10%" stopColor="rgba(75, 89, 105, 0.5)" />
              <stop offset="100%" stopColor="rgb(75, 89, 105)" />
            </linearGradient>
          </defs>
        </svg>
        <ContainerWidthSizer>
          {({ width }) => (
            <VictorySharedEvents
              name="ShareEvents"
              events={[
                {
                  childName: 'axis2',
                  target: 'grid',
                  eventHandlers: {
                    onClick: () => {
                      return [
                        {
                          childName: 'axis2',
                          target: 'grid',
                          mutation: (props) => {
                            this.clickGridHandle(props.datum)
                            return {}
                          }
                        }
                      ]
                    },
                    onMouseOver: () => {
                      return [
                        {
                          childName: 'axis1',
                          target: 'grid',
                          mutation: (props) => {
                            const { datum, style } = props
                            if (
                              this.defaultDataLength - datum <=
                              score.length
                            ) {
                              return {
                                style: Object.assign({}, style, {
                                  stroke: 'rgba(255,255,255,0.85)',
                                  strokeWidth: 1
                                })
                              }
                            } else {
                              return {
                                style: Object.assign({}, props.style, {
                                  stroke: 'transparent',
                                  strokeWidth: 6
                                })
                              }
                            }
                          }
                        }
                      ]
                    },
                    onMouseOut: () => {
                      return [
                        {
                          childName: 'axis1',
                          target: 'grid',
                          mutation: (props) => {
                            return targetIndex
                              ? {}
                              : {
                                  style: Object.assign({}, props.style, {
                                    stroke: 'transparent',
                                    strokeWidth: 6
                                  })
                                }
                          }
                        }
                      ]
                    }
                  }
                }
              ]}
            >
              <VictoryChart width={width} height={380}>
                <VictoryBar
                  barWidth={25}
                  offsetY={110}
                  data={tickValuesX.map((_, index) => ({
                    x: index,
                    y: 450,
                    y0: 45
                  }))}
                  style={{
                    data: {
                      fill: (e) => {
                        if (this.isNutritionQuestionnaire(tickValuesX[e.x])) {
                          return 'url(#myGradient)'
                        } else {
                          return 'none'
                        }
                      }
                    },
                    labels: {
                      fontSize: 8,
                      fontWeight: 700
                    }
                  }}
                />
                <VictoryAxis
                  name="axis1"
                  tickValues={tickValuesX.map((_, index) => index)}
                  style={{
                    axis: { stroke: 'transparent' },
                    grid: {
                      stroke: (datum) => {
                        if (datum === targetIndex)
                          return 'rgba(255,255,255,0.95)'
                        return 'transparent'
                      },
                      strokeDasharray: '3,3',
                      strokeWidth: (datum) => {
                        if (datum === targetIndex) return 1
                        return 6
                      },
                      cursor: 'pointer'
                    },
                    tickLabels: { fill: 'transparent' }
                  }}
                />

                <VictoryAxis
                  dependentAxis
                  tickValues={[0, 100, 200, 300, 400, 450]}
                  orientation="right"
                  style={{
                    axis: {
                      // stroke: 'rgba(255,255,255,0.95)',
                      // strokeDasharray: '3,3'
                      stroke: 'transparent'
                    },
                    tickLabels: {
                      fill: 'transparent' //'#ffffff'
                    }
                  }}
                />
                {data.map((item, index) => this.renderChartGroup(item, index))}
                <VictoryAxis
                  name="axis2"
                  tickValues={tickValuesX.map((_, index) => index)}
                  tickFormat={(t) =>
                    data[0].data[t].y ? tickValuesX[t].format('MM/DD') : ''
                  }
                  style={{
                    axis: {
                      stroke: 'transparent'
                    },
                    grid: {
                      stroke: 'transparent',
                      strokeWidth: 6,
                      cursor: (datum) =>
                        this.defaultDataLength - datum <= score.length
                          ? 'pointer'
                          : 'none'
                    },
                    ticks: { stroke: 'grey', size: 0 },
                    tickLabels: { fill: 'white', fontSize: 12, padding: -15 }
                  }}
                />
              </VictoryChart>
            </VictorySharedEvents>
          )}
        </ContainerWidthSizer>
        <div className="label-list">
          {data.reverse().map((item, index) => (
            <div key={index} className="label-item">
              <div>
                <div>{item.name}</div>{' '}
                <div
                  style={{
                    color:
                      item.data[targetIndex] &&
                      this.props.getLabelColor(item.data[targetIndex].value)
                  }}
                >
                  {item.data[targetIndex] && item.data[targetIndex].value + '%'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }
}

export default TimeLineGroup
