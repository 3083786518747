import { get } from 'lodash'
import React, { useState } from 'react'
import {
    VictoryArea,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryPolarAxis,
    VictoryScatter
} from 'victory'
import moment from 'moment'
import { Divider } from 'antd'
import './HSDSpiderGraph.css'
import { HSDScoreConfig, OFFSET_NAME_COMPARE_Y, OFFSET_SORCE_COMPARE_Y, SCORE_X, LABEL_SCORE_ANCHOR, LABEL_TEXT_ANCHOR } from './HSDContainer'

const Tooltip = ({ x, y, visible, text, color }) => (
    <div
        className={`tooltip ${color}`}
        style={{
            left: x,
            top: y,
            visibility: visible ? 'visible' : 'hidden'
        }}
    >
        {text}
    </div>
)

const CustomLabel = ({ text, index, onMouseEnter, onMouseLeave, ...rest }) => (
    <VictoryLabel
        text={text}
        events={{
            onMouseEnter: (e) => onMouseEnter(e, index),
            onMouseLeave: onMouseLeave
        }}
        {...rest}
    />
)

export const HSDSpiderGraphTrend = ({ latestData, previousData }) => {
    
    const [tooltip, setTooltip] = useState({
        visible: false,
        text: '',
        x: 0,
        y: 0
    })

    const createData = (list, defaultColor) =>
        list.map((domain, index) => {
            const score = getScore(domain)
            const config = HSDScoreConfig(score)
            return {
                x: index,
                y: score || 0,
                score,
                color: config ? config.color : defaultColor,
                text: score !== null ? score : '--'
            }
        })

    const getScore = (domain) =>
        get(domain, 'score_manual.value') || get(domain, 'score_auto')

    const filteredlatestDataLatest = latestData.filter(
        (item) => item.name !== 'Cardiometabolic'
    )
    const filteredlatestDataPrevious = previousData.filter(
        (item) => item.name !== 'Cardiometabolic'
    )

    const data = createData(filteredlatestDataLatest, 'rgba(77, 77, 77, 1)')
    const data_previous = createData(
        filteredlatestDataPrevious,
        'rgba(180, 180, 180, 1)'
    )
    const handleMouseEnter = (event, index) => {
        const domain = filteredlatestDataLatest[index]
        const previousDomain = filteredlatestDataPrevious[index]

        const formatDate = (date) =>
            date ? moment(date).format('MMM D, YYYY') : ''
        const latestConfig = HSDScoreConfig(getScore(domain))
        const previousConfig = HSDScoreConfig(getScore(previousDomain))

        setTooltip({
            visible: true,
            text: (
                <>
                    <span
                        style={{
                            color: '#4c6072',
                            fontWeight: 'bold',
                            fontSize: '18px'
                        }}
                    >
                        {domain.name}
                    </span>
                    <Divider />
                    <div className='tooltip-container'>
                        <span className='date-text'>
                            {formatDate(domain.latest_test_date)}
                        </span>
                        <span style={{ color: latestConfig.color, fontWeight: 'bold', fontSize: '16px' }}>
                            {getScore(domain)}&nbsp;
                            {domain.latest_test_date && (
                                <span
                                    style={{
                                        color: latestConfig.color,
                                        fontWeight: 'normal',
                                        fontSize: '16px'
                                    }}
                                >
                                    ( {latestConfig.text} )
                                </span>
                            )}
                        </span>
                    </div>
                    <br />
                    <div className='tooltip-container'>
                        <span className='date-text'>
                            {formatDate(previousDomain.latest_test_date)}
                        </span>
                        <span style={{ color: previousConfig.color, fontWeight: 'bold' }}>
                            {getScore(previousDomain)}&nbsp;
                            {previousDomain.latest_test_date && (
                                <span
                                    style={{
                                        color: previousConfig.color,
                                        fontWeight: 'normal',
                                        fontSize: '16px'
                                    }}
                                >
                                    ( {previousConfig.text} )
                                </span>
                            )}
                        </span>
                    </div>
                </>
            ),
            x: event.clientX - 200,
            y: event.clientY - 50
        })
    }
    const handleMouseLeave = () =>
        setTooltip({ visible: false, text: '', x: 0, y: 0 })

    return (
      <div style={{ position: 'relative' }}>
        <Tooltip
          x={tooltip.x}
          y={tooltip.y}
          visible={tooltip.visible}
          text={tooltip.text}
        />

        <VictoryChart
          polar
          domain={{ y: [0, 100] }}
          startAngle={90}
          endAngle={450}
        >
          <VictoryPolarAxis
            labelPlacement="vertical"
            tickValues={filteredlatestDataLatest.map((_, index) => index)}
            tickFormat={(t) => {
              const latestScore =
                get(filteredlatestDataLatest[t], 'score_manual.value') ||
                get(filteredlatestDataLatest[t], 'score_auto')
              const previousScore =
                get(filteredlatestDataPrevious[t], 'score_manual.value') ||
                get(filteredlatestDataPrevious[t], 'score_auto')
              if (latestScore !== null && previousScore !== null) {
                return `${previousScore} / ${latestScore}`
              } else if (latestScore !== null) {
                return `${latestScore}`
              } else if (previousScore !== null) {
                return `${previousScore}`
              } else {
                return '--'
              }
            }}
            tickLabelComponent={
              <VictoryLabel
                dx={(t) => SCORE_X[t]}
                dy={(y) => OFFSET_SORCE_COMPARE_Y[y || 0]}
                textAnchor={(t) => LABEL_SCORE_ANCHOR[t]}
                style={[
                  {
                    fill: (t = 0) => {
                      const latestScore =
                        get(filteredlatestDataLatest[t], 'score_manual.value') ||
                        get(filteredlatestDataLatest[t], 'score_auto')
                      const previousScore =
                        get(
                          filteredlatestDataPrevious[t],
                          'score_manual.value'
                        ) || get(filteredlatestDataPrevious[t], 'score_auto')
                      if (latestScore !== null && previousScore !== null) {
                        return data[t].color
                      } else if (latestScore !== null) {
                        return data[t].color
                      } else if (previousScore !== null) {
                        return data_previous[t].color
                      } else {
                        return '#ccc'
                      }
                    },
                    fontSize: 10,
                    fontFamily: 'Gilroy',
                    fontWeight: 600
                  }
                ]}
              />
            }
            style={{
              axis: {
                fill: 'rgba(205, 208, 214, 0.2)' // background
              },
              grid: {
                stroke: '#B1B8BD'
              }
            }}
          />

          <VictoryPolarAxis
            tickValues={filteredlatestDataLatest.map((_, index) => index)}
            tickFormat={(t) =>
              filteredlatestDataLatest[t]
                ? filteredlatestDataLatest[t].name.toUpperCase()
                : ''
            }
            tickLabelComponent={
              <CustomLabel
                dy={(y) => OFFSET_NAME_COMPARE_Y[y || 0]}
                dx={(t) => SCORE_X[t]}
                textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{
                  fontSize: 8,
                  cursor: 'pointer',
                  fill: 'rgba(76,96,114,.6)'
                }}
              />
            }
            labelPlacement="vertical"
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: '#516960' },
              tickLabels: {
                fontSize: 8,
                fill: 'rgba(76,96,114,.6)'
              }
            }}
          />

          <VictoryPolarAxis
            dependentAxis
            tickFormat={(t) => null}
            style={{
              grid: {
                stroke: (t) => (t === 100 ? '#0D2350' : '#B1B8BD')
              },
              axis: {
                stroke: 'transparent'
              }
            }}
          />
          <VictoryArea
            style={{ data: { fill: 'rgba(180, 232, 195, 0.2)' } }}
            data={data}
          />
          <VictoryLine
            data={data}
            style={{ data: { stroke: '#7FB800', strokeWidth: 1.5 } }}
          />
          <VictoryScatter
            data={data.filter((i) => i.score)}
            style={{
              data: { stroke: (t) => t.color, strokeWidth: 2, fill: 'white' }
            }}
          />

          <VictoryArea
            style={{ data: { fill: 'rgba(232, 180, 217, 0.2)' } }}
            data={data_previous}
          />
          <VictoryLine
            data={data_previous}
            style={{ data: { stroke: 'black', strokeWidth: 1.5 } }}
          />
          <VictoryScatter
            data={data_previous.filter((i) => i.score)}
            style={{
              data: { stroke: (t) => t.color, strokeWidth: 2, fill: 'white' }
            }}
          />
        </VictoryChart>
      </div>
    )
}
