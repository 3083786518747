import React, { Component } from 'react'
import { Button } from 'antd'
// import VerifyCode from '../profile/settings/verifyCode'
import BasicForm from '../../components/formV4'
import VerifyCode from './verifyCode'
import { settingContainer } from '../profile/settings/index.sass'
import { loadUser, saveUser } from '../../utils/storage'
import { pattern } from '../../utils/constant'
import { enable2FA } from '../../api/people'
import { editClient } from '../../api/team'
import { whiteBoxView } from './index.sass'
import phsLogo from '../../asserts/icon/latest-logo.svg'
import leftArrow from '../../asserts/icon/btn-left-arrow.svg'
import enable2fa from '../../asserts/icon/2fa/icon-enable2fa.svg'
import enable2faPhone from '../../asserts/icon/2fa/icon-enable2fa-phone.svg'
import success from '../../asserts/images/success.png'

const PhoneNumberFormInfo = [
  {
    type: 'input',
    dbField: 'phone_number',
    placeholder: '4085555555',
    addonBefore: (
      <div
        style={{
          fontFamily: 'Heebo',
          fontSize: '16px',
          paddingTop: '1px'
        }}
      >
        +1
      </div>
    ),
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: pattern.usPhoneNumber,
        message: 'Please input a valid phone number'
      }
    ]
  }
]

class TwoFA extends Component {
  static propTypes = {}

  state = {
    step: 'ENABLE',
    codeIsValid: false,
    loading: false,
    phoneNumber: ''
  }

  handleBackAction = () => {
    const {
      history,
      location: { search } // query
    } = this.props
    const { step } = this.state
    const person = loadUser()

    switch (step) {
      case 'ENABLE':
        history.go(-1)
        break
      case 'PHONE_NUMBER':
        this.setState({ step: 'ENABLE' })
        break
      case 'ENTER_VERIFY_CODE':
        if (search.match('callback') && person.profile.enabled_2fa) {
          history.go(-1)
        } else {
          this.setState({ step: 'PHONE_NUMBER' })
        }
        break
      default:
        break
    }
  }

  nextAction = () => {
    const {
      profile: {
        onboarding_steps: { onboarding_required },
        membership_selected
      }
    } = loadUser()
    const {
      history,
      location: { query }
    } = this.props
    if (!membership_selected) {
      history.push('/membership-selection')
    } else if (onboarding_required) {
      history.push('/onboarding/ready')
    } else {
      const callback = this.getSearch('callback')
      if (callback) {
        history.push(callback)
      } else if (query && query.path) {
        history.push(query.path)
      } else {
        history.push('/')
      }
    }
  }

  handlePhoneNumberNextAction = async () => {
    this.setState({ loading: true })
    const person = loadUser()
    try {
      const params = await this.basicFormRef.handleSubmit()
      params.phone_number = `+1${params.phone_number}`
      const result = await editClient({ profile: params }, person.id)
      saveUser(result.team.person)
      await enable2FA({ profile: params }, person.id)
      this.setState({
        loading: false,
        step: 'ENTER_VERIFY_CODE',
        phoneNumber: params.phone_number
      })
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  getSearch = (targetKey) => {
    const {
      location: { search }
    } = this.props
    if (!search) return
    const items = search.replace('?', '').split('&')
    const obj = {}
    for (const item of items) {
      const [key, value] = item.split('=')
      obj[key] = value
    }
    if (targetKey) {
      return obj[targetKey]
    }
  }

  handleVerifyCodeSuccess = async () => {
    const { history } = this.props
    const person = loadUser()
    const callback = this.getSearch('callback')
    const finalback = this.getSearch('finalback')
    if (callback) {
      if (person.profile.enabled_2fa) {
        history.push(finalback ? `${callback}?callback=${finalback}` : callback)
      } else {
        person.profile.enabled_2fa = true
        saveUser(person)
        this.setState({ step: 'DONE' })
      }
    } else {
      const {
        profile: {
          onboarding_steps: { onboarding_required }
        }
      } = person
      if (person.profile.enabled_2fa) {
        if (onboarding_required) {
          history.push('/onboarding/ready')
        } else {
          history.push('/')
        }
      } else {
        person.profile.enabled_2fa = true
        saveUser(person)
        this.setState({ step: 'DONE' })
      }
    }
  }

  componentDidMount() {
    const person = loadUser()
    let phoneNumber = person.profile.phone_number
    if (phoneNumber && phoneNumber.startsWith('+1')) {
      phoneNumber = phoneNumber.slice(2)
    } else if (
      phoneNumber &&
      (phoneNumber.startsWith('+') || phoneNumber.startsWith('1'))
    ) {
      phoneNumber = phoneNumber.slice(1)
    }
    this.setState({ phoneNumber: phoneNumber })
    if (person.profile.enabled_2fa) {
      this.setState({ step: 'ENTER_VERIFY_CODE' })
      enable2FA({ profile: { phone_number: phoneNumber } }, person.id)
    }
  }

  renderEnable2FA() {
    return (
      <div className="twoFAContainer columnContainer">
        <img src={enable2fa} alt="" className="top-image" />
        <div className="main-title enable-2fa-main-title">
          Protect your account with 2 Factor Authentication
        </div>
        <div className="sub-title enable-2fa-sub-title">
          Two-factor (2FA) is an additional security layer for your account to
          protect you from vulnerabilities of a standard password-only
          approach.
        </div>
        <Button
          type="primary"
          className="submit-button main-button"
          onClick={() => this.setState({ step: 'PHONE_NUMBER' })}
        >
          Enable 2FA
        </Button>
        <span className="enable-2fa-skip" onClick={this.nextAction}>
          Skip, I'll do it later
        </span>
      </div>
    )
  }

  renderEnterPhoneNumber() {
    const { loading, phoneNumber } = this.state
    return (
      <div className="columnContainer enterPhoneNumberContainer">
        <img src={enable2faPhone} alt="" style={{ paddingTop: 20 }} />
        <div className="enter-phone-number-top-title">
          2 FACTOR Authentication
        </div>
        <div className="main-title">Enter Your Phone Number</div>
        <div className="sub-title enter-phone-number-desc">
          Enter your phone number in here and get a verification code from the
          Apeiron Life App
        </div>
        <div className="form-container">
          <BasicForm
            normalLayout
            formData={PhoneNumberFormInfo}
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            initialValues={{ phone_number: phoneNumber }}
          />
        </div>
        <div className="enter-phone-main-button">
          <Button
            type="primary"
            className="submit-button main-button"
            loading={loading}
            disabled={loading}
            onClick={this.handlePhoneNumberNextAction}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }

  renderDone() {
    return (
      <div className="columnContainer">
        <img src={success} alt="success" style={{ paddingTop: 88 }} />
        <div className="main-title" style={{ paddingTop: 30 }}>
          All in set!
        </div>
        <div className="sub-title" style={{ paddingTop: 14 }}>
          You have successfully completed 2FA.
        </div>
        <div className="enter-phone-main-button" style={{ paddingTop: 24 }}>
          <Button
            type="primary"
            className="submit-button main-button"
            onClick={this.nextAction}
          >
            Done
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { step, phoneNumber } = this.state
    let component, height
    switch (step) {
      case 'ENABLE':
        component = this.renderEnable2FA()
        height = 580
        break
      case 'PHONE_NUMBER':
        component = this.renderEnterPhoneNumber()
        height = 590
        break
      case 'ENTER_VERIFY_CODE':
        component = (
          <VerifyCode
            phoneNumber={phoneNumber}
            onSuccess={this.handleVerifyCodeSuccess}
          />
        )
        height = 590
        break
      case 'DONE':
        component = this.renderDone()
        height = 400
        break
      default:
        break
    }

    return (
      <div className={whiteBoxView}>
        <img src={phsLogo} alt="logo" className="logo" />
        <div className="white-box-container" style={{ height: height }}>
          {step === 'DONE' ? (
            <></>
          ) : (
            <div className="back" onClick={this.handleBackAction}>
              <img
                src={leftArrow}
                alt=""
                style={{ marginRight: 10, marginTop: -1 }}
              />
              <span className="back-title">BACK</span>
            </div>
          )}
          <div className={settingContainer}>{component && component}</div>
        </div>
      </div>
    )
  }
}

export default TwoFA
