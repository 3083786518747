/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect } from 'react'
import _, { get } from 'lodash'
import { Checkbox } from 'antd'
import SearchMap from './searchMap'

export default function ResidenceAddress(props) {
  let { question, update: updateAnswers, answer } = props
  const [values, setValues] = useState(get(answer, 'answer.value') || {})
  useEffect(() => {
    if (answer) {
      setValues(answer.answer.value)
    }
  }, [answer])

  return (
    <div className="q-form-group">
      {question.items &&
        question.items.map((item, index) => {
          const an = values[index]
          return (
            <div key={`group_${index}`}>
              <Item
                {...{ item, an }}
                format={question.answer_format && question.answer_format[index]}
                update={(value) => {
                  values[index] = value
                  const newAnswer = _.assign(answer, {
                    answer: { value: values }
                  })
                  setValues(values)
                  updateAnswers(newAnswer, question)
                }}
              />
            </div>
          )
        })}

      {_.get(answer, 'verify.required') && (
        <p className="invalid">Residence Address Required</p>
      )}
    </div>
  )
}

function Item(props) {
  const { item, an, update } = props //format
  const [isChecked, setChecked] = useState((an && an.isChecked) || false)
  const { is_multiple, title, description, type } = item

  useEffect(() => {
    setChecked((an && an.isChecked) || false)
  })

  return (
    <div className={'q-form-item '}>
      <div className="q-text-group">
        {is_multiple && (
          <Checkbox
            checked={isChecked}
            onChange={(e) => {
              setChecked(e.target.checked)
              let anObj = an && an.additional_answer
              if (e.target.checked) {
                if (_.isEmpty(anObj) && e.target.checked) {
                  anObj = { required: true }
                } else {
                  anObj.required && _.unset(anObj, 'required')
                }
              } else {
                anObj = null
              }

              update({
                isChecked: e.target.checked,
                additional_answer: anObj
              })
            }}
          />
        )}
        <div>
          {title && <div className="q-text-group-title">{title}</div>}
          {description && (
            <p className="q-text-group-description">{description}</p>
          )}
        </div>
      </div>
      {(isChecked || !is_multiple) && (
        <>
          {type === 'search_map' && (
            <SearchMap
              item={item}
              an={
                _.has(an, 'additional_answer')
                  ? isChecked && !an.additional_answer
                    ? { required: true }
                    : an.additional_answer
                  : an
              }
              update={(an) => {
                let answerObj = an
                if (is_multiple) {
                  answerObj = {
                    isChecked,
                    additional_answer: an
                  }
                }
                update(answerObj)
              }}
            />
          )}
        </>
      )}
    </div>
  )
}
