import axios, { formDataRequest } from './axios'

const url = '/recommends'

export const getRecommendedContent = (category) => {
  return axios.get(`${url}/list${category ? '?category_id=' + category : ''}`)
}
export const addRecommendedContent = (form) => {
  return formDataRequest({
    path: url,
    data: form,
    method: 'post'
  })
}

export const editRecommendedContent = (form, id) => {
  return formDataRequest({
    path: `${url}/${id}`,
    data: form,
    method: 'put'
  })
}

export const delRecommendedContent = (id) => {
  return axios.delete(`${url}/${id}`)
}

export const getUrlTitleCover = (link) => {
  return axios.get(`/html_page?url=${encodeURIComponent(link)}`)
}

export const countViewNumber = (id) => {
  return axios.put(`/recommends/number_of_view/${id}`)
}

export const getCategories = (_) => axios.get('/recommends/categories')
