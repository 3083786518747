import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from './calendar'
// import { dateFormat } from '../../../utils/common'

class TextList extends Component {
  static propTypes = {
    entries: PropTypes.array
  }

  render() {
    let { entries, startDate, endDate, showActivity } = this.props
    entries = entries.filter((item) => item.input)
    return (
      <Calendar
        {...{
          entries,
          startDate,
          endDate,
          period: 'monthly',
          notState: true,
          showActivity
        }}
      />
    )
    // return (
    //   <div className="text-list">
    //     {entries && entries.length > 0 ? (
    //       entries.reverse().map((item, index) => (
    //         <div className="text-item" key={index}>
    //           <span>{item.input}</span>
    //           <br />
    //           <span>{dateFormat(item.created_at, true, 'includeTime')}</span>
    //         </div>
    //       ))
    //     ) : (
    //       <div>No Data</div>
    //     )}
    //   </div>
    // )
  }
}

export default TextList
