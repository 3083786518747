import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import HelloSign from 'hellosign-embedded'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Checkbox, message } from 'antd'
import { OnboardingHeader } from '../style'
import {
  ViewIcon,
  VectorIcon,
  SignedIcon
} from '../../../components/icons/onboarding'
import { loadUser, saveUser } from '../../../utils/storage'
import pdfIcon from '../../../asserts/images/pdf.svg'
import { getLiabilityForm, getWaiverSignedForm } from '../../../api'
import { WaiverFormKey } from '../setup/screen'

class Liabilities extends Component {
  static propTypes = {}

  state = {
    step: 0,
    client: null,
    loading: false,
    stepForm: [
      {
        type: 'LIABILITY RELEASE',
        name: 'Liability Release',
        signed: function() {
          return this.signs[this.signs.length - 1]
        },
        des:
          'By signing this form, you acknowledge and accept the risks involved in participating in the Apeiron program, such as ones relating to physical activity.',
        getUrl: getLiabilityForm,
        isChecked: true,
        allowMulti: false,
        signs: [false],
        reminder: '5 mins'
      }
    ],
    hasOtherProviders: false
  }

  sign = async (getUrl, suffix) => {
    const { client } = this.state
    this.setState({ loading: true })
    const {
      embedded: { sign_url }
    } = await getUrl(suffix)
    client.open(sign_url)
    this.setState({ loading: false })
  }

  checked = () => {
    const { step, stepForm } = this.state
    const formInfo = stepForm[step]
    formInfo.isChecked = !formInfo.isChecked
    stepForm[step] = formInfo
    this.setState({ stepForm })
    if (formInfo.signed() && formInfo.isChecked) {
      this.autoDirecting()
    }
  }

  linkNextPage = () => {
    const {
      location: { search }
    } = this.props
    if (search.match('callback')) {
      const query = search
        .substr(1)
        .split('&')
        .reduce((kv, cur) => {
          const [k, v] = cur.split('=')
          kv[k] = v
          return kv
        }, {})
      const callback = query.callback
      this.props.history.push(callback)
    } else if (search.match('directive')) {
      this.props.history.push('/onboarding/questionnaire')
    } else {
      this.props.history.push('/onboarding/ready')
    }
  }

  autoDirecting = async () => {
    this.linkNextPage()
  }

  loadSignedForms = async (step) => {
    const {
      location: { search }
    } = this.props
    let { stepForm } = this.state

    const res = await getWaiverSignedForm(false)
    if (res) {
      if (res.liability_release_form.length) {
        stepForm[0].signs = res.liability_release_form
      }
      if (!search.match('step')) {
        step = stepForm.findIndex((item) => !item.signed())
        if (step < 0) {
          step = 0
        }
      }

      this.setState({ stepForm, step })
    }
  }

  componentDidMount() {
    const {
      account,
      location: { search }
    } = this.props
    let { stepForm } = this.state

    let step = 0
    if (search.match('step')) {
      const query = search
        .substr(1)
        .split('&')
        .reduce((kv, cur) => {
          const [k, v] = cur.split('=')
          kv[k] = v
          return kv
        }, {})
      step = parseInt(query.step)
      this.setState({ step })
    }

    this.loadSignedForms(step)
    const client = new HelloSign({
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.REACT_APP_WORKFLOW === 'develop'
    })
    if (account && !account.user.profile.waiver_required) {
      stepForm[0].isChecked = true
    }
    client.on('sign', async () => {
      // in runtime, the step and stepForm was freshed now, so regain here
      let { step, stepForm } = this.state

      stepForm[step].signs[stepForm[step].signs.length - 1] = true
      this.setState({ stepForm })

      const user = loadUser()
      user.profile.completed_signature = user.profile.completed_signature || []
      if (!user.profile.completed_signature.includes(WaiverFormKey.LIABILITY)) {
        user.profile.completed_signature.push(WaiverFormKey.LIABILITY)
        this.props.setLoginAccount(user)
        saveUser(user)
      }
    })

    client.on('close', () => {})

    client.on('error', (data) => {
      const errStr = `Something went wrong! Please try again. Error code: ${data.code}`
      console.error(errStr)
      message.error(errStr)
      console.log(data)
      client.close()
    })

    this.setState({ client })
  }

  skipOrNext = () => {
    const { step, stepForm } = this.state
    const formInfo = stepForm[step]
    if (formInfo.signed()) {
      this.autoDirecting()
    }
  }

  disableNextButton = () => {
    const { step, stepForm } = this.state
    const formInfo = stepForm[step]
    return !formInfo.signed()
  }

  render() {
    const { step, loading, stepForm } = this.state
    const formInfo = stepForm[step]

    return (
      <div className="main-container">
        <OnboardingHeader />
        <div className="step-title">
          <span>2</span>
          Liability Release
        </div>
        <div className="main-content">
          <div className="introduction bottom-line notice share-medical-select">
            <h1>{formInfo.name}</h1>
            <p>{formInfo.des}</p>
          </div>
          <div className="sign bottom-line">
            <div className="sub-title">Sign with e-Signature</div>
            {formInfo.signs.map((hasSigned, index) => {
              // hasSigned = typeof hasSigned === 'object'?hasSigned: false
              return (
                <div className="sign-content" key={index}>
                  <div>
                    <img className="icon" src={pdfIcon} alt="pdf" />
                    <div className="sign-title">
                      <h5>
                        {formInfo.name} Form - {index + 1}
                      </h5>
                      {!hasSigned && <p>Please preview and sign document</p>}
                    </div>
                  </div>
                  {hasSigned ? (
                    <span className="signed">
                      <SignedIcon />
                      Signed
                    </span>
                  ) : (
                    <Button
                      loading={loading}
                      type="primary"
                      onClick={() =>
                        this.sign(formInfo.getUrl, formInfo.signs.length - 1)
                      }
                      disabled={!formInfo.isChecked}
                    >
                      <ViewIcon />
                      Preview & Sign
                    </Button>
                  )}
                </div>
              )
            })}
          </div>
          {!formInfo.signed() && (
            <div className="check bottom-line">
              <div>
                <Checkbox checked={formInfo.isChecked} onClick={this.checked} />
                I understand this is a legal representation of my signature
              </div>
              <div className="vector">
                <VectorIcon />
                <p>
                  Apeiron Life is compliant with all major eSignature laws
                  including ESIGN, UETA, European eIDAS, and eSignature service
                  provided by HelloSign
                </p>
              </div>
            </div>
          )}
        </div>
        <footer>
          <span
            className="back"
            onClick={() => {
              const {
                location: { search },
                history
              } = this.props
              if (search.match('callback')) {
                const query = search
                  .substr(1)
                  .split('&')
                  .reduce((kv, cur) => {
                    const [k, v] = cur.split('=')
                    kv[k] = v
                    return kv
                  }, {})
                const callback = query.callback
                history.push(callback)
              } else {
                history.push('/onboarding/ready')
              }
            }}
          >
            <LeftOutlined /> back
          </span>
          <Button
            loading={loading}
            type="primary"
            onClick={this.skipOrNext}
            disabled={this.disableNextButton()}
            style={{
              width: '117px',
              height: '48px'
            }}
          >
            NEXT
            <RightOutlined />
          </Button>
        </footer>
      </div>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Liabilities)
