import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryScatter,
  VictoryBar
} from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'

const Container = styled.div`
  margin-top: 9px;
  position: relative;
  overflow: hidden;
  .ant-divider-horizontal {
    margin: 12px 24px;
    margin-bottom: 0;
    min-width: 80%;
    width: calc(100% - 48px);
  }
`

const getOriginalIdsByVersion = (version) => {
  let startId = 'nutrition_eating_habits_eating_window_start',
    endId = 'nutrition_eating_habits_eating_window_end',
    largestMealId = 'nutrition_eating_habits_largest_meal'
  if (version > 2) {
    startId = 'eating_window_start'
    endId = 'eating_window_end'
    largestMealId = 'largest_meal'
  }
  return { startId, endId, largestMealId }
}

export default function TimingChart(props) {
  const entries = _.cloneDeep(props.entries).map((item) => {
    item.answer = item.answer.value || item.answer
    return item
  })

  const { startId, endId, largestMealId } = getOriginalIdsByVersion(
    props.version
  )
  const startInfo = entries.find((item) => item.original_id === startId)
  const endInfo = entries.find((item) => item.original_id === endId)
  const largestMealInfo = entries.find(
    (item) => item.original_id === largestMealId
  )
  const { startTime, endTime, idealMealTime, largestMealTime } = getIdealMeal()

  return (
    <Container>
      <Chart
        {...{
          startTime: startTime._d.getTime(),
          endTime: endTime._d.getTime(),
          idealMealTime: idealMealTime._d.getTime(),
          largestMealTime: largestMealTime._d.getTime()
        }}
      />
    </Container>
  )

  function getIdealMeal() {
    const date = moment().format('YYYY-MM-DD')
    const startTime = moment(date + ' ' + startInfo.answer, 'YYYY-MM-DD h:mm A')
    let endTime = moment(date + ' ' + endInfo.answer, 'YYYY-MM-DD h:mm A')
    if (endTime.isBefore(startTime)) {
      endTime = moment(
        startTime
          .clone()
          .add(1, 'days')
          .format('YYYY-MM-DD') +
          ' ' +
          endInfo.answer,
        'YYYY-MM-DD h:mm A'
      )
    }
    const largestMealTime = moment(
      date + ' ' + largestMealInfo.answer,
      'YYYY-MM-DD h:mm A'
    )
    const idealMealTime = startTime
      .clone()
      .add((endTime - startTime) / (1000 * 2), 's')
    return {
      startTime,
      endTime,
      idealMealTime,
      largestMealTime
    }
  }
}

const leftPoints = [
    [0, 0],
    [1, 0],
    [0, 1],
    [1, 1]
  ],
  rightPoints = [
    [0, 1],
    [1, 1],
    [0, 0],
    [1, 0]
  ]

function Chart(props) {
  const { startTime, endTime, idealMealTime, largestMealTime } = props
  const leftCurve = bezier.apply(null, leftPoints),
    rightCurve = bezier.apply(null, rightPoints)
  let tickValuesX = _.union(
    [startTime, endTime, idealMealTime, largestMealTime].sort((a, b) => a - b)
  )
  const x0 = startTime,
    x1 = endTime
  return (
    <>
      <ContainerWidthSizer>
        {({ width }) => {
          const dots = getDots(width)
          return (
            <VictoryChart
              width={width}
              height={180}
              minDomain={{ x: x0, y: 0 }}
              padding={{
                top: 50,
                bottom: 0,
                left: 30,
                right: 30
              }}
            >
              <VictoryAxis
                tickValues={[0, 1]}
                dependentAxis
                style={{
                  axis: {
                    stroke: 'transparent'
                  },
                  tickLabels: {
                    fill: 'transparent'
                  }
                }}
              />
              <VictoryArea
                style={{
                  data: {
                    fill: 'url(#lg2)'
                  }
                }}
                y={(d) => {
                  if (d.x === largestMealTime) {
                    return 1
                  }
                  if (d.x >= x0 && d.x <= x1) {
                    return d.x > largestMealTime
                      ? rightCurve(
                          getT(d.x, {
                            start: largestMealTime,
                            end: endTime,
                            points: leftPoints
                          })
                        ).y
                      : leftCurve(
                          getT(d.x, {
                            start: startTime,
                            end: largestMealTime,
                            points: rightPoints
                          })
                        ).y
                  } else {
                    return null
                  }
                }}
              />
              <VictoryArea
                style={{
                  data: {
                    fill: 'url(#lg1)'
                  }
                }}
                y={(d) => {
                  if (d.x >= x0 && d.x <= x1) {
                    return d.x > idealMealTime
                      ? rightCurve(
                          getT(d.x, {
                            start: idealMealTime,
                            end: endTime,
                            points: leftPoints
                          })
                        ).y
                      : leftCurve(
                          getT(d.x, {
                            start: startTime,
                            end: idealMealTime,
                            points: rightPoints
                          })
                        ).y
                  } else {
                    return null
                  }
                }}
              />
              <VictoryAxis
                tickValues={tickValuesX}
                // tickFormat={(t) => moment(t).format('h:mm A')}
                style={{
                  grid: {
                    strokeWidth: 1.5,
                    stroke: (tickValue) =>
                      tickValue === idealMealTime ||
                      tickValue === largestMealTime
                        ? '#ffffff'
                        : 'transparent',
                    strokeDasharray: '3, 3'
                  },
                  axis: {
                    stroke: 'transparent'
                  },
                  tickLabels: {
                    fill: 'transparent'
                  }
                }}
              />
              <VictoryScatter
                data={dots}
                dataComponent={<IdealScatter />}
                style={{
                  labels: {
                    fill: 'transparent'
                  }
                }}
              />
              {_.cloneDeep(dots).map((dot, index) => {
                dot.y = 1
                return (
                  <VictoryBar
                    key={index}
                    data={[dot]}
                    style={{
                      data: {
                        fill: 'transparent'
                      },
                      labels: {
                        fill: (datum) => datum.labelColor,
                        padding: dot.labelOffset ? 30 : 10
                      }
                    }}
                    labels={(datum) => moment(datum.x).format('h:mm A')}
                  />
                )
              })}
              <VictoryBar
                data={[
                  { x: x0, y: (200 * 0.04) / 100 },
                  { x: x1, y: (200 * 0.04) / 100 }
                ]}
                barWidth={1.5}
                style={{
                  data: {
                    fill: '#9DACBB'
                  },
                  labels: {
                    fill: '#9DACBB',
                    textAnchor: (datum) => (datum.x === x0 ? 'start' : 'end')
                  }
                }}
                labels={(datum) => moment(datum.x).format('h:mm A')}
              />
            </VictoryChart>
          )
        }}
      </ContainerWidthSizer>
      <svg style={{ height: 200, position: 'absolute' }}>
        <defs>
          <linearGradient id="lg1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0" stopColor="#ABD5B2" />
            <stop offset="1" stopColor="rgba(123, 173, 45, 0)" />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient
            id="lg2"
            x1="279"
            y1="0"
            x2="279"
            y2="343.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0E3076" />
            <stop offset="1" stopColor="#2B4B8E" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )

  function getDots(wholeWidth) {
    const limit = (38 + 66) / (2 * wholeWidth),
      labelLimit = 100 / (2 * wholeWidth)
    const distancePCT =
      Math.abs(idealMealTime - largestMealTime) / (endTime - startTime)
    let offset = 0,
      labelOffset = false
    if (distancePCT === 0) {
      return [
        {
          x: idealMealTime,
          y: 0.5,
          type: 'Reached Ideal LM',
          offset,
          labelColor: '#468527',
          isEqual: true
        }
      ]
    }
    if (distancePCT < limit) {
      offset = 20
    }
    if (distancePCT < labelLimit) {
      labelOffset = true
    }
    const dots = [
      {
        x: idealMealTime,
        y: 0.5,
        type: 'Ideal LM',
        offset,
        labelColor: '#468527'
      },
      {
        x: largestMealTime,
        y: 0.5,
        type: 'LM',
        offset: offset * -1,
        labelOffset,
        labelColor: '#405D99'
      }
    ]
    return dots
  }
}

function IdealScatter(props) {
  const { x, y, datum } = props
  if (datum.isEqual) {
    return (
      <svg
        width="112"
        height="34"
        viewBox="0 0 112 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...{ x: x - 56, y: y - 17 }}
      >
        <rect
          x="4"
          width="104"
          height="26"
          rx="13"
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect
          x="4.5"
          y="0.5"
          width="103"
          height="25"
          rx="12.5"
          stroke="#468527"
          shapeRendering="crispEdges"
        />

        <text x="15" y="16" fontSize={10} fontWeight={600}>
          <tspan fill="#468527">{datum.type}</tspan>
        </text>
      </svg>
    )
  }
  if (datum.type === 'LM') {
    return (
      <svg
        width="38"
        height="34"
        viewBox="0 0 38 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...{ x: x - 19, y: y - 17 + datum.offset || 0 }}
      >
        <rect
          x="4"
          width="30"
          height="26"
          rx="13"
          fill="white"
          shapeRendering="crispEdges"
        />
        <rect
          x="4.5"
          y="0.5"
          width="29"
          height="25"
          rx="12.5"
          stroke="#365594"
          shapeRendering="crispEdges"
        />
        <text x="12" y="16" fontSize={10} fontWeight={600}>
          <tspan fill="#365594">{datum.type}</tspan>
        </text>
      </svg>
    )
  }
  return (
    <svg
      width="66"
      height="34"
      viewBox="0 0 66 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 33, y: y - 17 + datum.offset || 0 }}
    >
      <rect
        x="4.5"
        y="0.5"
        width="57"
        height="25"
        rx="12.5"
        stroke="#73C54A"
        shapeRendering="crispEdges"
      />
      <rect
        x="4"
        width="58"
        height="26"
        rx="13"
        fill="white"
        shapeRendering="crispEdges"
      />
      <text x="14" y="16" fontSize={10} fontWeight={600}>
        <tspan fill="#73C54A">{datum.type}</tspan>
      </text>
    </svg>
  )
}

function getT(x, { start, end, points }) {
  const scale = end - start
  return (x - start) / scale
}

function bezier(p1, cp1, cp2, p2) {
  const [x1, y1] = p1
  const [x2, y2] = p2
  const [cx1, cy1] = cp1
  const [cx2, cy2] = cp2
  return (t) => {
    let x =
      x1 * (1 - t) * (1 - t) * (1 - t) +
      3 * cx1 * t * (1 - t) * (1 - t) +
      3 * cx2 * t * t * (1 - t) +
      x2 * t * t * t
    let y =
      y1 * (1 - t) * (1 - t) * (1 - t) +
      3 * cy1 * t * (1 - t) * (1 - t) +
      3 * cy2 * t * t * (1 - t) +
      y2 * t * t * t
    return { x, y }
  }
}
