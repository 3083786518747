import axios, { formDataRequest } from './axios'

export const getCategories = (_) => axios.get('/micro_goals/categories')

export const createCategories = async (param) => {
  const result = await axios.postJson('/micro_goals/categories', param)
  return result.category
}

export const editCategories = async (param, id) => {
  const result = await axios.putJson(`/micro_goals/categories/${id}`, param)
  return result.category
}

export const deleteSubcategories = (id) =>
  axios.delete(`/micro_goals/categories/${id}`)

export const getGoals = (id) => axios.get(`/micro_goals?person_id=${id}`)

export const getarchivedGoals = (id) =>
  axios.get(`/micro_goals/archived?person_id=${id}`)

export const createGoal = (params, type) =>
  axios.postJson(`/micro_goals?type=${type || 0}`, params)

export const updateGoal = (params, id, type) =>
  axios.putJson(`/micro_goals/${id}?type=${type || 0}`, params)

export const deleteGoal = (id, type) =>
  axios.delete(`/micro_goals/${id}?type=${type || 0}`)

export const getMetaData = () => axios.get('/micro_goals/units')

export const getStatistics = (
  from,
  to,
  personId,
  isArchived,
  request_pillar
) => {
  let url = `/micro_goals/statistics?from=${from}&to=${to}&person_id=${personId}&strict=false`
  if (isArchived) {
    url += '&archived=true'
  }
  if (request_pillar) {
    url += `&request_pillar=${request_pillar}`
  }
  return axios.get(url)
}

export const getMicroGoalById = (from, to, personId, id) => {
  let url = `/micro_goals/statistics?from=${from}&to=${to}&person_id=${personId}&id=${id}&strict=false`
  return axios.get(url)
}

export const saveEntry = (id, params, personId) => {
  return axios.postJson(
    `/micro_goals/${id}/entries_achieving?person_id=${personId}`,
    params
  )
}

export const deleteEntry = (id, date, personId, period) => {
  const params = {
    created_at: date,
    input: null,
    unit: period
  }
  return axios.postJson(
    `/micro_goals/${id}/entries_achieving?person_id=${personId}`,
    params
  )
}
// axios.delete(`/micro_goals/${id}/entries/${date}?person_id=${personId}`)

export const attachFile = ({
  personId,
  goalId,
  files,
  source,
  progressCallback
}) => {
  const path = `/attachments/micro_goal.attachments/${goalId}?person_id=${personId}`
  const formData = new FormData()
  formData.append('file', files[0])
  return formDataRequest({
    method: 'post',
    path,
    data: formData,
    onUploadProgress: (process) => {
      const percent = (process.loaded / process.total).toFixed(2) * 100
      return progressCallback && progressCallback(percent)
    },
    cancelSource: source
  })
}

export const attachLink = (goalId, personId, url) =>
  axios.postJson(
    `/attachments/url/micro_goal.attachments/${goalId}?person_id=${personId}`,
    { url }
  )

export const removeAttachment = (attachId, personId) =>
  axios.delete(`/attachments/${attachId}?person_id=${personId}`)

export const editAttachment = (attachId, personId, params) =>
  axios.putJson(`/attachments/${attachId}?person_id=${personId}`, params)

export const creatGoalBulk = (params, type) =>
  axios.postJson(`/micro_goals/bulk?type=${type || 0}`, params)

export const editGoalBulk = (params, id, type) =>
  axios.putJson(`/micro_goals/bulk/${id}?type=${type || 0}`, params)

/*
    type === 0 -- delete micro goal
    type === 1 -- delete program item
  */
export const deleteGoalBulk = (id, type) =>
  axios.delete(`/micro_goals/bulk/${id}?type=${type || 0}`)

export const getOverviewSummary = (from, to, personId) =>
  axios.get(`/summary_card?from=${from}&to=${to}&person_id=${personId}`)

export const saveOverviewSummary = (id, dataArr) =>
  axios.postJson(`/summary_card/${id}`, dataArr)

export const getActivityNames = () => axios.get('/summary_card/activity_names')
