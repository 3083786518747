import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryLine,
  VictoryScatter
} from 'victory'
import { PlanIcon, SvgText } from '../../icons/weeklySummary'
import { getAxisX, xAxisFormat, splitLine, tickLabels } from '../common'
import { timesFormat } from '../../../utils/common'

function PlanIconScatter(props) {
  // const x = props.x && Number(props.x) - 10
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={(props.x && props.x - 10) || 0}
      y={(props.y && props.y - 10) || 0}
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="#FF6B00"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.6462 14.4658L11.7409 14.6465L11.8852 14.5023L12.5395 13.8479L12.5983 13.7891L12.5796 13.7081L11.6168 9.53593L13.3574 7.79529C13.6876 7.46516 13.6876 6.93178 13.3574 6.60165C13.0273 6.27152 12.4939 6.27152 12.1638 6.60165L10.4231 8.34229L6.25095 7.37948L6.16994 7.36079L6.11115 7.41957L5.45682 8.07391L5.31255 8.21818L5.49328 8.31285L8.74782 10.0176L7.1457 11.6197L6.07485 11.4668L6.0006 11.4562L5.94757 11.5092L5.45682 11.9999L5.3162 12.1406L5.49004 12.2371L6.92483 13.0342L7.72194 14.469L7.81852 14.6429L7.95913 14.5023L8.44989 14.0115L8.50292 13.9585L8.49231 13.8842L8.33933 12.8134L9.94146 11.2112L11.6462 14.4658Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}

function Dot(props) {
  // const { target, datum, min, max } = props
  // let stroke
  // if (target || min || max) {
  //   stroke = datum.isSuccess ? green : red
  // } else {
  //   stroke = 'rgba(0,0,0,.5)'
  // }

  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="5"
        cy="5"
        r="4"
        fill="white"
        stroke="#479FEC"
        strokeWidth="2"
      />
    </svg>
  )
}

class SleepChart extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    sleep: PropTypes.object
  }

  state = {
    barDatas: []
  }

  // componentDidMount() {
  //   const { startDate, endDate } = this.props
  // }

  calculateSleepDuration = (hours) => {
    const filtedHours = hours.filter((hour) => hour)
    let min, max
    if (filtedHours.length === 0) {
      min = 0
      max = 0
    } else {
      min = Math.floor(Math.min.apply(null, filtedHours))
      max = Math.ceil(Math.max.apply(null, filtedHours))
    }
    if (min > 1) min -= 1
    if (max - min <= 2 && min - 1 >= 0) {
      return { min: min - 1, max: min + 3, space: 1 }
    }
    if (min + 4 >= max) {
      max = min + 4
    }
    const space = Math.ceil((max - min) / 4)
    return { min, max: space * 4 + min, space }
  }

  calculateChartData = () => {
    const { startDate, endDate, sleep } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    let travelData = []
    let bars, lines
    if (sleep.details) {
      bars = tickValuesX.map((date) => {
        let detail = sleep.details.find(
          (item) => item.summary_date === date.format('YYYY-MM-DD')
        )
        if (detail) {
          return detail.asleep_time / 3600
        } else {
          return null
        }
      })
      lines = tickValuesX.map((date) => {
        let detail = sleep.details.find(
          (item) => item.summary_date === date.format('YYYY-MM-DD')
        )
        if (detail) {
          return detail.sleep_efficiency
        } else {
          return null
        }
      })
      travelData = tickValuesX
        .map((date) => {
          let detail = sleep.details.find(
            (item) => item.summary_date === date.format('YYYY-MM-DD')
          )
          if (detail) {
            return { x: date, y: detail.is_traveling }
          } else {
            return { x: date, y: null }
          }
        })
        .filter((item) => item.y)
    } else {
      bars = [0, 0, 0, 0, 0, 0, 0]
      lines = [null, null, null, null, null, null, null]
    }
    const {
      min: barMin,
      max: barMax,
      space: barSpace
    } = this.calculateSleepDuration(bars)
    let barData = [],
      lineData = [],
      scatterData = []
    if (tickValuesX) {
      barData = bars.map((item, index) => ({
        x: tickValuesX[index],
        y: (item && ((item - barMin) / (barMax - barMin)) * 100) || 0,
        asleep_time: item * 3600
      }))
      const mappedLineData = lines.map((item, index) => ({
        x: tickValuesX[index],
        y: item
      }))
      let { solidGroup, dashGroup } = splitLine(mappedLineData)
      solidGroup = solidGroup.map((item) => ({
        data: item,
        dataStyle: {
          stroke: '#479FEC'
        }
      }))
      dashGroup = dashGroup.map((item) => ({
        data: item,
        dataStyle: {
          stroke: '#479FEC',
          strokeDasharray: '3,3'
        }
      }))
      lineData = solidGroup.concat(dashGroup)
      scatterData = mappedLineData.filter((item) => item.y)
    }

    return {
      tickValuesX,
      barData,
      travelData,
      lineData,
      scatterData,
      barMin,
      barSpace
    }
  }

  renderChart(chartData) {
    const {
      tickValuesX,
      barData,
      travelData,
      lineData,
      scatterData,
      barMin,
      barSpace
    } = chartData
    return (
      <VictoryChart width={550} height={250} domainPadding={20}>
        <VictoryAxis
          style={{
            axis: {
              stroke: 'transparent'
            },
            tickLabels
          }}
          tickValues={tickValuesX}
          tickLabelComponent={
            <VictoryLabel text={(datum) => xAxisFormat(datum)} />
          }
        />

        {scatterData.length > 0 && (
          <VictoryAxis
            dependentAxis
            tickValues={[0, 25, 50, 75, 100]}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels
            }}
          />
        )}

        <VictoryAxis
          dependentAxis
          tickValues={[0, 25, 50, 75, 100]}
          tickLabelComponent={
            <VictoryLabel
              text={(datum) => {
                let result = barMin
                if (datum) {
                  result = result + parseInt(datum / 25) * barSpace
                }
                return result
              }}
            />
          }
          orientation="right"
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: '#E4EAEF',
              strokeDasharray: '3,3'
            },
            tickLabels
          }}
        />

        <VictoryBar
          offsetX={10}
          alignment="middle"
          style={{
            data: {
              fill: '#5C00D1'
            },
            labels: {
              fontSize: 10,
              fill: '#70808E'
            }
          }}
          barWidth={36}
          data={barData}
          cornerRadius={{ bottom: 6, top: 6 }}
          labels={(datum) => {
            if (!datum.asleep_time) return null
            const { hours, minutes } = timesFormat(datum.asleep_time)
            return `${hours ? hours + 'h' : ''}${minutes}m`
          }}
          labelComponent={<VictoryLabel dy={8} />}
        />
        <VictoryScatter data={travelData} dataComponent={<PlanIconScatter />} />
        {lineData &&
          lineData.map((item, index) => (
            <VictoryLine
              key={index}
              style={{
                data: item.dataStyle,
                parent: { border: '1px solid #ccc' }
              }}
              data={item.data}
            />
          ))}
        <VictoryScatter data={scatterData} dataComponent={<Dot />} />
      </VictoryChart>
    )
  }

  render() {
    const chartData = this.calculateChartData()
    const { scatterData, travelData } = chartData
    return (
      <>
        <div className="chart-container">
          <div className="label left-axis">
            {scatterData.length > 0 && (
              <SvgText
                height={140}
                width={30}
                fontFamily="Gilroy"
                textAttr={{
                  x: -110,
                  y: 20,
                  fontSize: 12,
                  fill: '#70808E',
                  fontWeight: 'normal',
                  transform: 'rotate(-90) ',
                  textAnchor: 'start',
                  dominantBaseline: 'top'
                }}
                text="SLEEP SCORE"
              />
            )}
          </div>
          {this.renderChart(chartData)}
          <div className="label right-axis">
            <SvgText
              height={200}
              width={30}
              fontFamily="Gilroy"
              textAttr={{
                x: 50,
                y: -10,
                fontSize: 12,
                fontWeight: 'normal',
                fill: '#70808E',
                transform: 'rotate(90) ',
                textAnchor: 'start',
                dominantBaseline: 'top'
              }}
              text="HOURS"
            />
          </div>
        </div>
        {(scatterData.length > 0 || travelData.length > 0) && (
          <div className="chart-label" style={{ width: 360 }}>
            <span className="tip">
              <span className="circle" />
              {/* Sleep score */}
              <SvgText
                height={18}
                width={90}
                fontFamily="Gilroy"
                textAttr={{
                  x: 0,
                  y: 10,
                  fontSize: 12,
                  fontWeight: 'normal',
                  fill: '#70808E',
                  letterSpacing: 0.5,
                  textAnchor: 'start',
                  dominantBaseline: 'middle'
                }}
                text="SLEEP SCORE"
              />
            </span>
            <span className="tip">
              <span className="rect sleep-duration" />
              <SvgText
                height={18}
                width={110}
                fontFamily="Gilroy"
                textAttr={{
                  x: 0,
                  y: 10,
                  fontSize: 12,
                  fontWeight: 'normal',
                  fill: '#70808E',
                  letterSpacing: 0.5,
                  textAnchor: 'start',
                  dominantBaseline: 'middle'
                }}
                text="SLEEP DURATION"
              />
            </span>
            <span className="tip">
              <PlanIcon className="icon" />
              <SvgText
                height={18}
                width={50}
                fontFamily="Gilroy"
                textAttr={{
                  x: 0,
                  y: 10,
                  fontSize: 12,
                  fontWeight: 'normal',
                  fill: '#70808E',
                  letterSpacing: 0.5,
                  textAnchor: 'start',
                  dominantBaseline: 'middle'
                }}
                text="TRAVEL"
              />
            </span>
          </div>
        )}
      </>
    )
  }
}

export default SleepChart
