import React, { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
// import PropTypes from 'prop-types'
import { Button } from 'antd'
import BloodTestContainerWrapper from './bloodTestContainerWrapper'
import FileManager from '../files/management'
import Container from './container/index'
import { containerStyle } from './container.sass'

class BloosTestContainer extends Component {
  static propTypes = {}

  render() {
    const {
      person,
      dataLoading,
      createDataset,
      datasets,
      fileUploadAsync
      // showDataset,
      // getShareLink
    } = this.props

    return (
      <div className={containerStyle}>
        {/* <Button
          className="btn-generate-report"
          type="primary"
          onClick={() => getShareLink(person.id, 'blood-test')}
          ghost
          disabled={!showDataset}
        >
          <Icon type="link" /> generate share link
        </Button> */}

        {person && (
          <FileManager
            datasetVersions={datasets && datasets.map((item) => item.test_date)}
            title="Assessment files"
            type="bio_marker"
            person={person}
            editable={true}
            fileUploadAsync={fileUploadAsync}
          />
        )}
        {!dataLoading && (
          <div className="btn-date-set">
            <Button type="primary" ghost onClick={createDataset}>
              <EditOutlined />
              create data set
            </Button>
          </div>
        )}
        <Container {...this.props} type={'blood-test'} />
      </div>
    )
  }
}

export default BloodTestContainerWrapper(BloosTestContainer)
