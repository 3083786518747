import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import PersonalBioMarkerValue from './personalBiomarkerValue'
import HexagonText from '../../../UI/HexagonText'
import { rangeNonlinearPercent } from '../../../../utils/common'
import { detailColors } from '../../../../utils/constant'
import { rangeAreaStyle } from './index.sass'

export class RangesArea extends Component {
  static propTypes = {
    value: PropTypes.number,
    ranges: PropTypes.array,
    person: PropTypes.object,
    matchRange: PropTypes.object,
    biomarker: PropTypes.object,
    updateValue: PropTypes.func
  }

  state = {
    key: ''
  }

  // componentDidMount() {
  //   const { person } = this.props
  //   const gender = person.profile.gender
  //   const key = `${gender}_`
  //   this.setState({
  //     key
  //   })
  // }

  renderRangeAreaBar(range) {
    const { value } = this.props
    let barStyle = {}
    const colorObj = detailColors.find((item) => item.key === range.color)
    if (value || value === 0) {
      barStyle = {
        background: colorObj && colorObj.value
      }
    }
    return (
      <div className="progress-container">
        <div className="progress-bar" style={barStyle} />
      </div>
    )
  }

  renderRangeArea(range) {
    // const { key } = this.state
    let area = '',
      areaStyle = {}
    if (range.min && range.max) {
      area = `${range.min} - ${range.max}`
    } else if (range.min && !range.max) {
      area = `> ${range.min}`
    } else if (!range.min && range.max) {
      area = `< ${range.max}`
    }
    const colorObj = detailColors.find((item) => item.key === range.color)
    areaStyle = { color: colorObj && colorObj.value }
    return (
      <div className="range-info">
        <span className="area" style={areaStyle}>
          {area}
        </span>
        <span className="name">{range.name.toLowerCase()}</span>
      </div>
    )
  }

  renderPolygonValue(range) {
    const { value, person } = this.props
    const gender = person.profile.gender
    const style = {}
    if (value) {
      let { percent } = rangeNonlinearPercent(value, range, gender)
      if (percent === 0) {
        style.left = '0%'
      } else if (percent === 100) {
        style.right = '0%'
      } else {
        style.left = '50%'
        style.transform = 'translateX(-50%)'
      }
    }
    const colorObj = detailColors.find((item) => item.key === range.color)
    return (
      <div className="polygon-value" style={style}>
        <HexagonText
          text={(value && value.toString()) || '0'}
          color={colorObj && colorObj.value}
          size={60}
        />
      </div>
    )
  }

  render() {
    const { value, ranges, biomarker, type, matchRange } = this.props
    let colorObj
    if (matchRange) {
      colorObj = detailColors.find((item) => item.key === matchRange.color)
    }
    ranges.sort(
      (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
    )
    const rangeLengths = ranges.map((item) => item.name.length)
    const maxLength = Math.max(0, ...rangeLengths)
    return (
      (
        <div className={`ranges-area ${rangeAreaStyle}`}>
          <div className="range-title">
            <span>{type}</span>
            <span>your value</span>
          </div>
          <div className="range-level">
            {value || value === 0 ? (
              <>
                {(matchRange && (
                  <span>
                    Your levels are{' '}
                    {
                      <span className="name">
                        {matchRange.name
                          ? matchRange.name.toLowerCase()
                          : 'n/a'}
                      </span>
                    }
                  </span>
                )) || <span>Levels unknown</span>}
                <span
                  style={{
                    color: (colorObj && colorObj.value) || '#4C6072'
                  }}
                >
                  {value} {biomarker.unit}
                </span>
              </>
            ) : (
              <>
                <span>Levels unknown</span>
                <span
                  style={{
                    color: '#4C6072'
                  }}
                >
                  {value} {biomarker.unit}
                </span>
                {/* <PersonalBioMarkerValue
                  status={status}
                  initialValue={biomarker.personal_data}
                  bioMarker={biomarker}
                  personId={person.id}
                  datasetId={datasetId}
                  biomarkerId={biomarkerId}
                  personBioMarkerSync={personBioMarker => {
                    updateValue(personBioMarker)
                  }}
                /> */}
              </>
            )}
          </div>
          {/* <div
            className="range-value mobile-show"
            style={{
              color: (colorObj && colorObj.value) || '#4C6072'
            }}
          >
            {value} {biomarker.unit}
          </div> */}

          <div className="ranges-container">
            {ranges.map((range, index) => (
              <div
                className="range-area"
                key={index}
                style={{ width: 100 / ranges.length + '%' }}
              >
                {((matchRange &&
                  (matchRange.id === range.id ||
                    matchRange.name === range.name)) ||
                  (!matchRange && !value && index === 0)) &&
                  this.renderPolygonValue(range)}
                {this.renderRangeAreaBar(range)}
              </div>
            ))}
          </div>
          <div className="range-info-container desktop-show">
            {ranges.map((range, index) => (
              <div
                className="range-area"
                style={{ width: 100 / ranges.length + '%' }}
                key={index}
              >
                {this.renderRangeArea(range)}
              </div>
            ))}
          </div>
          <div className="range-info-container mobile-show">
            {ranges.map((range, index) => (
              <div
                className={`${maxLength > 12 ? 'long' : 'short'} range-area`}
                style={
                  maxLength < 12 && ranges.length < 5
                    ? { width: 100 / ranges.length + '%' }
                    : {}
                }
                key={index}
              >
                {this.renderRangeArea(range)}
              </div>
            ))}
          </div>
        </div>
      ) || <div />
    )
  }
}

export default RangesArea
