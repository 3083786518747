import React from 'react'

export default function SubTip(props) {
  return (
    <span
      style={{
        fontFamily: 'Gilroy',
        fontSize: 12,
        color: '#70808E',
        display: 'block',
        whiteSpace: 'normal'
      }}
    >
      {props.text}
      {props.unit && (
        <span
          className="unit"
          style={{
            fontFamily: 'Gilroy',
            fontSize: 12,
            color: '#70808E',
            whiteSpace: 'normal'
          }}
        >
          • {props.unit.toUpperCase()}
        </span>
      )}
    </span>
  )
}
