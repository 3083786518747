import React from 'react'
import { OnBoardingContainer, OnboardingHeader } from '../style'
import styled from 'styled-components'

const Container = styled.div`
  padding: 60px 205px 0px;
  font-size: 16px;
  .title {
    text-align: center;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: 0.3px;
    color: rgb(41, 48, 62);
  }

  ul.brackets-latin {
    list-style: brackets-latin;
  }

  ul.brackets-roman {
    list-style: brackets-roman;
  }

  ul.align-left {
    padding-left: 20px;
  }

  .indent {
    text-indent: 2em;
    li {
      text-indent: 2em;
    }
  }
  .no-indent {
    li {
      text-indent: 0;
    }
  }
  ul li {
    padding-bottom: 16px;
  }
`

export default function MembershipAgreement(props) {
  return (
    <OnBoardingContainer>
      <div className="main-container">
        <OnboardingHeader />
        <Container>
          <h1 className="title">APEIRON LIFE QUICK GUIDE TO YOUR MEMBERSHIP</h1>

          <p>
            <strong>Welcome to Apeiron Life!</strong> <br />
            Below is some information regarding your Membership to get you
            started. Please review this Membership Agreement and our online{' '}
            <a
              href="https://www.apeiron.life/terms-of-use/"
              target="_black"
              rel="noopener noreferrer"
            >
              <u>Terms of Use</u>
            </a>{' '}
            and{' '}
            <a
              href="https://www.apeiron.life/privacy-policy/"
              target="_black"
              rel="noopener noreferrer"
            >
              <u>Privacy Policy</u>
            </a>{' '}
            for more information about our facilities, products, and all
            services.
          </p>
          <p>
            <strong>
              Personal Training Sessions, Classes, and other Services:
            </strong>
            <br />
            <ul className="align-left">
              <li>
                You can schedule all sessions through your personal Apeiron Life
                Concierge by contacting concierge@apeiron.life.
              </li>
              <li>
                Your instructor/trainer/service provider may change with prior
                notice if practicable.
              </li>
              <li>
                If you need to re-schedule an appointment, please provide at
                least 24 hours' notice to avoid being charged for the full
                session.
              </li>
            </ul>
          </p>

          <p>
            <strong>Membership Pause:</strong>
            <br />
            You may be eligible to pause your membership for a temporary or
            extended period upon certain proof. Please see the Membership
            Agreement/Policies for more information
          </p>

          <p>
            <strong>Cancellation:</strong>
            <br />
            Review Period Cancellation Right: You are entitled to a Review
            Period of 10 days following the effective date of your initial
            membership to notify Apeiron Life that you wish to cancel your
            Membership Agreement and receive a full refund. Please see your
            Membership Agreement for more details.
            <br />
            After the Review Period: You may not cancel your membership during
            the term of your membership (or get a refund), unless specifically
            stated otherwise in the Membership Agreement.
            <br />
            <strong>Annual Fee:</strong>Each year, you will be required to pay a
            non-refundable Annual Fee plus applicable taxes, if any, in order to
            maintain your membership. Please see the pricing schedule, order
            form and your membership agreement for more details.
          </p>

          <p>
            <strong>
              Arbitration Agreement and Waiver of Class Action and Jury Rights:
            </strong>
            <br />
            <ul className="align-left">
              <li>
                All disputes between you and Apeiron Life, except personal
                injury claims (which will be handled in the courts), will be
                resolved through binding arbitration or small claims court, if
                applicable
              </li>
              <li>
                Any arbitration under the membership agreement will take place
                on an individual basis; you and Apeiron Life agree that class
                arbitrations and class actions are not permitted
              </li>
              <li>
                If you do not want to be bound by the arbitration provision in
                your membership agreement you must notify Apeiron Life within 90
                days of signing your Agreement by providing written notice as
                detailed in Section 11(a).
              </li>
              <li>Please see Section 11 of your agreement for more details.</li>
            </ul>
            <br />
            <strong>Release of Liability and Assumption of Risk: </strong> Your
            membership agreement includes a Release of Liability and Assumption
            of Risk Provision.
          </p>

          <p>
            <strong>Contact Information:</strong>
            <br />
            <ul className="align-left">
              <li>General: concierge@apeiron.life</li>
              <li>Regarding your membership: concierge@apeiron.life</li>
              <li>
                Regarding our facilities, services and/or product:
                concierge@apeiron.life
              </li>
            </ul>
          </p>
        </Container>

        <Container>
          <h1 className="title">APEIRON LIFE MEMBERSHIP AGREEMENT</h1>
          <p>
            This Apeiron Life Membership Agreement and the Terms of Service{' '}
            <a
              href="https://apeiron.life/terms-of-use/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>https://apeiron.life/terms-of-use/</u>
            </a>{' '}
            (the “<strong>Terms of Service</strong>,” and together with this
            Apeiron Life Membership Agreement, the “<strong>Agreement</strong>
            ”) govern your (“
            <strong>you</strong>” or “<strong>Participant</strong>
            ”) use of our website located at{' '}
            <a
              href="https://apeiron.life"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>https://apeiron.life</u>
            </a>{' '}
            (the “<strong>Site</strong>
            ”), our Facilities (as defined below), the products we offer (“
            <strong>Products</strong>
            ”), our mobile application (“
            <strong>App</strong>
            ”), and all services provided by or on behalf of Performance Health
            Sciences, Inc. (d/b/a “<strong>Apeiron Life,</strong>” “
            <strong>we,</strong>” or “<strong>us</strong>
            ”), including without limitation, any training, physical therapy,
            instruction, consultation, activities or other services provided by
            or on behalf of Apeiron Life under this Agreement (the Site,
            Facilities, Products, our services and App are collectively called
            the “<strong>Services</strong>
            ”). Please refer to our Privacy Notice at{' '}
            <a
              href="https://apeiron.life/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>https://apeiron.life/privacy-policy/</u>
            </a>{' '}
            for information on how Apeiron Life collects, processes and uses
            personal information. This Agreement is effective upon the earlier
            date of your acceptance of this Agreement, or your access to (or use
            of) any of the Services (the “
            <strong>Membership Effective Date</strong>
            ”).
          </p>

          <p>
            BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT OR ACCESSING OR
            USING ANY SERVICES, YOU HAVE INDICATED THAT YOU UNDERSTAND THIS
            AGREEMENT AND ACCEPT ALL OF ITS TERMS.
          </p>

          <p>
            <strong>
              ARBITRATION NOTICE: WHEN YOU AGREE TO THIS AGREEMENT YOU ARE
              AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN
              YOU AND APEIRON LIFE THROUGH BINDING, INDIVIDUAL ARBITRATION
              RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 11 (DISPUTE
              RESOLUTION) BELOW FOR DETAILS REGARDING ARBITRATION (INCLUDING THE
              PROCEDURE TO OPT OUT OF ARBITRATION).
            </strong>
          </p>

          <p>
            <ul style={{ listStyle: 'decimal' }}>
              <li>
                <p className="indent">APEIRON LIFE OFFERINGS</p>

                <ul className="indent brackets-latin">
                  <li>
                    <p className="indent">
                      <u>Services.</u>
                    </p>
                    <ul className="indent brackets-roman ">
                      <li>
                        Access to Services. Payment of the fees set forth under
                        Section 3 permits you to access Services and purchase
                        Products, subject to the terms and conditions of this
                        Agreement. Your membership is subject to all current
                        Apeiron Life Policies (as defined below). Your
                        membership gives you no rights in Apeiron Life, its
                        management, property or operation. PLEASE READ THE
                        DISCLAIMERS IN SECTION 8 BELOW BEFORE COMMENCING YOUR
                        USE OF THE SERVICES.
                      </li>
                      <li>
                        On-Site Services. When you order Services at a location
                        outside of Apeiron Life’s Facilities (as defined below)
                        (the “<strong>On-Site Services</strong>
                        ”), you agree to (a) provide safe access for Apeiron
                        Life representatives’ visit to the location specified
                        for the On-Site Services (“
                        <strong>On-Site Service Location</strong>
                        ”), (b) obtain all necessary consents for Apeiron Life
                        representatives to access the On-Site Service Location;
                        and (c) provide a safe and adequate space for the
                        Apeiron Life representatives to perform such On-Site
                        Services.
                      </li>
                      <li>
                        Modification to Services. Apeiron Life reserves the
                        right to make reasonable changes to the features, type
                        or quantity of Services, including classes, available
                        equipment (including portable devices) offered, or the
                        times of certain Services, and to update the cost of
                        Services. Apeiron Life may add, modify, limit and/or
                        eliminate any Product offering, program, equipment,
                        facility, activity, class or other service at its
                        discretion. Any part of the Services, including without
                        limitation classes and equipment, shall be available
                        subject to demand. Any of the Services may have limited
                        hours or may be discontinued at any time and may be
                        offered on a “first come first serve basis.” If Apeiron
                        Life cancels a Service that charges a fee in addition to
                        the Membership Fee, you will not be charged such fee and
                        will be reimbursed any amounts you have paid toward that
                        fee.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <u>Website; Apps. </u>Certain Services are provided, and
                    certain Products may be available, through the Site or the
                    App.
                  </li>
                  <li>
                    <u>Third Party Services.</u> The Services may allow you to
                    access third-party services, websites or other resources,
                    including without limitation data from those websites or
                    other resources, (“
                    <strong>Third Party Services</strong>
                    ”). Apeiron Life provides access to such Third Party
                    Services only as a convenience for you and is not
                    responsible for any content, data, links, products or
                    services on or available from Third Party Services. You
                    acknowledge sole responsibility for and assume all risk
                    arising from, your use of any third-party resources,
                    including any Third Party Services. Subject to the
                    foregoing, you agree that as part of its data management
                    Services, Apeiron Life may combine your data from Third
                    Party Services with the data it collects via the Services.
                    Access to Third Party Services may be subject to Apeiron
                    Life’s agreements with such third parties and may be
                    modified or canceled at any time.
                  </li>
                  <li>
                    <u>Restrictions.</u> Apeiron Life and its licensors
                    exclusively own and shall retain all right, title and
                    interest in and to the Services, including all associated
                    intellectual property rights. You acknowledge that the
                    Services are protected by patent, copyright, trademark, and
                    other laws of the United States and foreign countries. You
                    agree not to remove, alter or obscure any copyright,
                    trademark, service mark or other proprietary rights notices
                    incorporated in or accompanying the Services. You will not
                    (i) reproduce, distribute, publicly display, publicly
                    perform, sell, resell, rent, lease, create derivative works
                    from, decompile, reverse engineer or disassemble any part of
                    the Services, or use the Services on a timesharing basis; or
                    (ii) use the Services in any unlawful manner, for any
                    unlawful purpose, or in any manner inconsistent with this
                    Agreement or applicable documentation.
                  </li>
                  <li>
                    {' '}
                    <u>Non-commercial Use.</u> Use of the Services is for
                    personal, non-commercial use only. You may not use the
                    Services in connection with any commercial purposes,
                    including, but not limited to, (i) advertising or soliciting
                    any user to buy or sell any products or services; or (ii)
                    accessing the Services in order to build a competitive
                    product or services or for other competitive purposes. You
                    may not use any information obtained from the Services to
                    contact, advertise to, solicit, or sell to any person.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">TERM OF MEMBERSHIP</p>
                Unless expired or terminated in accordance with this Agreement,
                your membership commences on the Membership Effective Date,
                continues for one (1) year and will automatically renew for an
                additional successive one (1) year period (each such 1-year
                period, the “<strong>Membership Period</strong>
                ”). You may elect non-renewal of your membership by notifying
                Apeiron Life at least thirty (30) days prior to the end of the
                then current Membership Period in accordance with Section 7(a).
              </li>
              <li>
                <p className="indent">FEES AND PAYMENTS</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Membership Fee.</u> You agree to pay Apeiron Life the
                    annual membership fee (“
                    <strong>Membership Fee</strong>
                    ”), as set forth in the current pricing schedule or your
                    order form, if applicable and on each anniversary of your
                    Membership Effective Date at the then-current Membership Fee
                    if you elect to renew your membership.
                  </li>
                  <li>
                    <u>Assessment Fee.</u> Your annual Membership Fee includes
                    an assessment of your physical capabilities which will be
                    used to develop an individualized performance and wellness
                    plan (“
                    <strong>Assessment</strong>
                    ”).
                  </li>
                  <li>
                    <u>Supplemental Services.</u> Apeiron Life offers
                    supplemental services to our members, including without
                    limitation, On-Site Services (“
                    <strong>Supplemental Services</strong>
                    ”), which are subject to additional fees as set forth in the
                    then-current pricing schedule (“
                    <strong>Supplemental Fee</strong>
                    ”) and applicable Policies for each Supplemental Service.
                  </li>
                  <li>
                    <u>Products.</u> You agree to pay charges for the full
                    price, shipment and other applicable charges of the Products
                    you purchase (“
                    <strong>Product Price</strong>
                    ”). Returns of any Products are subject to the applicable
                    Policies.
                  </li>
                  <li>
                    <p className="indent">
                      <u>Authorization.</u>
                    </p>
                    <ul className="indent brackets-roman">
                      <li>
                        You expressly authorize Apeiron Life (or its third-party
                        payment processor) to charge you for any payments under
                        this Agreement, including, without limitation, payments
                        for Membership Fees, Supplemental Fees and Product
                        Prices (plus any applicable taxes, and other charges in
                        each case) and to reimburse your account for accepted
                        returns of Products as set forth under this Agreement
                        (each a “<strong>Transaction</strong>
                        ”). We may ask you to supply additional information
                        relevant to your Transaction, including your credit card
                        number, the expiration date of your credit card and your
                        email and postal addresses for billing and notification
                        (such information, “<strong>Payment Information</strong>
                        ”). You represent and warrant that you have the legal
                        right to use all payment method(s) represented by any
                        such Payment Information. When you initiate a
                        Transaction, you authorize us to provide your Payment
                        Information to third party payment processors so we can
                        complete your Transaction and to charge your payment
                        method for the type of Transaction you have selected.
                        You may need to provide additional information to verify
                        your identity before completing your Transaction (such
                        information is included within the definition of Payment
                        Information). By initiating a Transaction, you agree to
                        the pricing, payment and billing policies applicable to
                        such fees and charges, as posted or otherwise
                        communicated to you before you place your order and to
                        pay any overdraft charges and fees, if applicable. All
                        payments for Transactions are non-refundable and
                        non-transferable except as expressly provided in this
                        Agreement. All fees and applicable taxes and other
                        charges, if any, are payable in United States dollars.
                      </li>
                      <li>
                        YOU AUTHORIZE APEIRON LIFE TO INITIATE RECURRING NON-
                        REFUNDABLE PAYMENTS FOR YOUR MEMBERSHIP FEES. We (or our
                        third-party payment processor) will automatically charge
                        you each year for your Membership Fee on the anniversary
                        of your Membership Effective Date, using the Payment
                        Information you have provided until the expiration or
                        termination of your membership. No less than thirty (30)
                        days and no more than sixty (60) days before your
                        then-current Membership Period ends, or otherwise in
                        accordance with applicable law, Apeiron Life will send
                        you a reminder with the then-current Membership Fee. You
                        acknowledge that your membership has recurring payment
                        features and you accept responsibility for all recurring
                        payment obligations prior to the expiration or
                        termination of your membership in accordance with this
                        Agreement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <u>Taxes.</u> Prices for Services are quoted and payable in
                    US Dollars. Prices are quoted exclusive of any applicable
                    taxes, which shall be the Participant’s responsibility.
                  </li>
                  <li>
                    <u>Changes to Fees.</u> Apeiron Life reserves the right to
                    change the fees for the Services any time and we will notify
                    you in advance of such changes becoming effective. Changes
                    to our fees for the Services will not apply retroactively
                    for Services you have paid for. Any special promotional
                    membership or rate regarding privileges, usage, hours,
                    benefits or facilities will be subject to the terms and
                    conditions of such promotional membership or rate.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">FACILITY</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Locations.</u> Your membership entitles you to access the
                    Apeiron Life facility at 1700 El Camino Real, Menlo Park, CA
                    94027 (your “<strong>Home Facility</strong>
                    ”) during open hours. Apeiron Life may currently or in the
                    future have other facilities where Services are offered
                    (each, including Home Facility, a “<strong>Facility</strong>
                    ”). Access to other Facilities by members of a different
                    Home Facility shall be subject to the Policies and rules and
                    policies of each such Facility.
                  </li>
                  <li>
                    <u>Hours.</u> Open hours for a Facility shall be as
                    specified in the Policies applicable to that Facility.
                  </li>
                  <li>
                    <u>Closures.</u> Apeiron Life reserves the right to close
                    its Facilities, in whole or in part, on a temporary basis
                    for maintenance, selected holidays, and other hours based on
                    municipal requirements or other business reasons. In
                    addition, if an Apeiron Life Facility is forced to close for
                    thirty (30) days or less by events or occurrences beyond
                    Apeiron Life’s control, such as, natural disasters, riots or
                    unrest, or a court order, you will not be entitled to a
                    refund, a credit or to terminate your membership.
                  </li>
                </ul>
              </li>
              <li className="indent">
                POLICIES. Apeiron Life provides and updates from time to time
                membership policies and facility rules (collectively, “
                <strong>Policies</strong>
                ”) to ensure a safe and enjoyable environment for all members.
                You agree to follow the Policies. Apeiron Life may, in its sole
                discretion, modify the Policies without notice at any time. It’s
                your responsibility to know and follow the most current
                Policies. All signs posted by Apeiron Life in a Facility or on
                its premises shall be considered a part of the Policies. In case
                of a conflict between this Agreement and Policies, this
                Agreement shall govern. The current Policies are available on{' '}
                <a
                  href="https://apeiron.life/privacy-policy/"
                  target="_black"
                  rel="noopener noreferrer"
                >
                  <u>https://apeiron.life/privacy-policy/</u>
                </a>
                .
              </li>
              <li>
                <p className="indent">REPRESENTATIONS AND WARRANTIES</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Physical Condition & No Medical Advice:</u> You represent
                    that you are in good physical condition and have no medical
                    reason or impairment that might prevent you from your
                    intended use of Services. As such, you acknowledge that
                    Apeiron Life did not give you medical advice before the date
                    of this Agreement, and cannot give you any after you join,
                    relating to your physical condition and ability to use the
                    Services. You represent and warrant that you have discussed
                    and will discuss any health or medical concerns you may now
                    or in the future have with your physician before using the
                    Services. You further represent and warrant that either (A)
                    all of the following statements are true: (i) no physician
                    has ever informed you that you have a heart condition or
                    that you should only do physical activities recommended by a
                    physician; (ii) you have never felt chest pain when engaging
                    in physical activity; (iii) you have not experienced chest
                    pain when not engaged in physical activity at any time
                    within the past several months; (iv) you have never lost
                    your balance because of dizziness and you have never lost
                    consciousness; (v) you do not have a bone or joint problem
                    that could be made worse by a change in your physical
                    activity; (vi) your physician is not currently prescribing
                    drugs for your blood pressure or heart condition; (vii) you
                    do not have a history of high blood pressure, and no one in
                    your immediate family has a history of high blood pressure
                    or heart problems; and (viii) you do not know of any other
                    reason you should not exercise or, if applicable engage in a
                    change in diet, including, without limitation pregnancy; or
                    (B) your physician has specifically approved of your use of
                    the Services.
                  </li>
                  <li>
                    <u>Liability for Property; Goodwill:</u> Apeiron Life is not
                    liable to you or any third party for any personal property
                    that is damaged, lost, or stolen while on or around Apeiron
                    Life’s premises including, but not limited to, a vehicle or
                    its contents or any property left in a locker. If you or
                    your guest cause any damage to Apeiron Life’s facilities,
                    you are liable to Apeiron Life for its cost of repair or
                    replacement. You represent and warrant that you will not
                    engage in conduct that may damage Apeiron Life’s reputation
                    or goodwill.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">CANCELLATION; TERMINATION.</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Cancellation and Non-renewal of Membership.</u> You may
                    cancel your Membership for a full refund within ten (10)
                    days following the Membership Effective Date (the “Review
                    Period”). AFTER THE REVIEW PERIOD, YOUR TRANSACTION IS FINAL
                    AND YOU WILL NOT BE ABLE TO CANCEL THE TRANSACTION FOR THE
                    MEMBERSHIP FEE OR RECEIVE A REFUND OF YOUR MEMBERSHIP FEE.
                    Cancellation of other Transactions are subject to the
                    Policies applicable to such Transaction, which may include,
                    without limitation, cancellation periods for scheduled
                    Services and ordered Products. Apeiron Life reserves the
                    right to cancel your membership for any reason; if our
                    cancellation of your membership is not for cause, we’ll
                    refund the pro rata Membership Fee payment you remitted to
                    us for the remainder of your current Membership Period.
                    Without limiting the foregoing, if you want to cancel your
                    membership at any time, YOU WILL NOT RECEIVE A REFUND OF ANY
                    PORTION OF THE MEMBERSHIP FEE PAID FOR THE THEN CURRENT
                    MEMBERSHIP PERIOD. To cancel your membership, you can send
                    an email to{' '}
                    <a href="mailto:concierge@apeiron.life">
                      <u>concierge@apeiron.life</u>
                    </a>{' '}
                    or submit a cancelation ticket at your Home Facility. You
                    will be responsible for the Membership Fees and any other
                    fees (plus any applicable taxes and other charges) incurred
                    for the then-current Membership Period. If you cancel your
                    membership, your right to use the Services will cease on the
                    date of cancellation; if you elect non-renewal of your
                    membership, your right to use the Services will continue
                    until the end of your then current Membership Period and
                    will then terminate.
                  </li>
                  <li>
                    <u>Medical Pause.</u> If you are in good standing and
                    current on all fees due, you may be eligible to pause your
                    membership for a temporary period for medical disability
                    upon at least ten (10) days prior written request. You must
                    provide Apeiron Life, with your pause request, written
                    verification from your physician stating your medical
                    disability prevents you from using Apeiron Life’s facilities
                    and services. The minimum term for a medical pause is three
                    (3) months and the maximum is six (6) months. If Apeiron
                    Life and you agree to pause your membership in accordance
                    with this Section 7(b), your then-current Membership Period
                    will be extended for the amount of time your membership was
                    on medical pause.
                  </li>
                  <li>
                    <u>Cancellation for Cause.</u> You may cancel this Agreement
                    and receive a refund of unused prepaid fees if you (i)
                    become disabled or (ii) pass away. You must provide a
                    licensed physician’s written verification that your
                    disability physically prevents you from using Apeiron Life's
                    facilities. For temporary disabilities, you may qualify for
                    a medical pause as set forth in Section 7(b). In case of
                    relocation, you must provide written evidence of your move.
                    In case of death, your estate must provide written evidence.
                    You or your estate must send written notice and proof of the
                    event within thirty (30) days following the event.
                    Cancellation is effective as of the date of the event.
                  </li>
                  <li>
                    <u>Termination for Breach.</u> Apeiron Life may terminate
                    your membership and/or your access to and use of all or part
                    of the Services for your breach of this Agreement or
                    violation of any Policies, or at its sole discretion, upon
                    written notice (including email) to you. Apeiron Life is not
                    required to disclose, and may be prohibited by law from
                    disclosing, the reason for the termination of your
                    membership other than for your breach.
                  </li>
                  <li>
                    <u>Effect of Termination and Cancellation.</u> If Apeiron
                    Life terminates your membership, it will reimburse you for
                    the pro rata portion of your Membership Fees, unless it
                    determines that you have breached or violated any provision
                    of this Agreement or Policies. Upon any termination,
                    discontinuation or cancellation of the Services or your
                    membership, the following Sections of this Agreement will
                    survive: 1(e), 1(f), 3, 6, 7(e), 8, 9, 10, 11, 12 and 13.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">DISCLAIMERS.</p>
                <ul className="indent brackets-latin">
                  <li>
                    SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE”
                    BASIS, WITHOUT WARRANTY OF ANY KIND. YOU THEREFORE USE THE
                    SERVICES AT YOUR OWN RISK. WITHOUT LIMITING THE FOREGOING,
                    APEIRON LIFE EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
                    ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING
                    OUT OF COURSE OF DEALING OR USAGE OF TRADE. Apeiron Life
                    makes no warranty that the Services will meet your
                    requirements or be available on an uninterrupted, secure, or
                    error-free basis. Apeiron Life makes no warranty regarding
                    the quality, accuracy, timeliness, truthfulness,
                    completeness or reliability of any information or content
                    made available through the Services. Apeiron Life makes no
                    warranty or representation with respect to the quality or
                    shelf-life of any Product and warranties for any Product are
                    subject solely to the manufacturer’s warranty.
                  </li>
                  <li>
                    APEIRON LIFE DOES NOT WARRANT OR REPRESENT IN ANY WAY AND
                    YOU MAY NOT ASSUME THAT ANY PARTICULAR SERVICE, INCLUDING
                    WITHOUT LIMITATION ANY NUTRITIONAL OR OTHER CONSULTATION
                    PROVIDED BY AN APEIRON LIFE REPRESENTATIVE CONSTITUTES
                    PROFESSIONAL OR QUALIFIED EXPERT ADVICE OR IS PROVIDED BY A
                    LICENSED OR QUALIFIED MEDICAL PROVIDER, UNLESS SPECIFICALLY
                    EXPRESSED IN WRITING BY AN AUTHORIZED APEIRON LIFE
                    REPRESENTATIVE PRIOR TO THE DELIVERY OF AND PAYMENT FOR THE
                    APPLICABLE SERVICE.
                  </li>
                  <li>
                    The content made available on or through the Site or through
                    any Services, should not be relied upon when making medical
                    decisions, or to diagnose or treat a medical or health
                    condition. Any health-related content you may find in the
                    Services is not intended to be used to diagnose, treat, cure
                    or prevent diseases, as a self-medication guide or as a
                    substitute for consulting with your health care
                    professional. For medical advice, you should consult a
                    medical professional. Your use of the Services or any
                    communication with Apeiron Life does not create a
                    doctor-patient relationship between you and Apeiron Life,
                    even if you made Apeiron Life aware of any health concerns
                    or exercise or diet related condition that may be impacted
                    by change in diet or exercise. In addition, nothing
                    contained in the Services or in the sale or delivery of
                    Products shall be construed as a promotion or solicitation
                    of any product or medical device prohibited by United States
                    laws. YOU SHOULD CONSULT YOUR PHYSICIAN OR OTHER HEALTH CARE
                    PRACTITIONER BEFORE STARTING THE SERVICES. YOUR USE OF THE
                    SERVICES IS AT YOUR OWN RISK.
                  </li>
                  <li>
                    <p className="indent">
                      Without limiting the foregoing, Apeiron Life makes no
                      representations or warranties:
                    </p>
                    <ul className="no-indent">
                      <li>
                        that the Services, or any particular fitness or
                        weight-loss program is suitable for you;
                      </li>
                      <li>
                        regarding the adequacy or safety of the Services for any
                        particular user;
                      </li>
                      <li>that the Services will meet your personal needs;</li>
                      <li>
                        concerning any content submitted by any third party;
                      </li>
                      <li>
                        concerning any third party’s use of content that you
                        submit;
                      </li>
                      <li>
                        that Apeiron Life will continue to support any
                        particular feature or part of the Services, or that any
                        particular feature or part of the Services will continue
                        to be available through Apeiron Life; or
                      </li>
                      <li>
                        concerning sites and resources outside of the Services,
                        even if linked to/available through the Services.
                      </li>
                    </ul>
                    <br />
                    You are responsible for providing your own access (e.g.,
                    computer, mobile device, Internet connection, etc.) and
                    transportation to the Services. To the extent any disclaimer
                    or limitation of liability in this Agreement does not apply,
                    all applicable express, implied, and statutory warranties
                    will be limited in duration to a period of thirty (30) days
                    after the date on which you first used the Services, and no
                    warranties shall apply after such period.
                  </li>
                  <li>
                    You understand and acknowledge that Apeiron Life does not
                    manufacture any equipment or device at its facilities, and
                    that Apeiron Life only purchases and/or leases such
                    equipment and device. You agree that Apeiron Life may not be
                    held liable for any defective equipment or device offered at
                    its facilities.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">LIMITATION OF LIABILITY</p>
                <ul className="indent brackets-latin">
                  <li>
                    TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER APEIRON LIFE
                    NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
                    DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL,
                    SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR
                    LOST SAVINGS, LOSS OF DATA, SERVICE INTERRUPTION, COMPUTER
                    DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES
                    OF ANY KIND, DAMAGES FOR PERSONAL INJURY, DEATH, LOSS OF
                    LIVELIHOOD, LOSS OF ENJOYMENT, PAIN AND SUFFERING, EMOTIONAL
                    DISTRESS, LOSS OF PROFITS, LOSS OF FUTURE EARNINGS OR LOSS
                    OF GOODWILL ARISING OUT OF OR IN CONNECTION WITH THIS
                    AGREEMENT OR FROM THE USE OF OR INABILITY TO USE THE
                    SERVICES, PRODUCTS OR FACILITIES, WHETHER BASED ON WARRANTY,
                    CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR
                    ANY OTHER LEGAL THEORY, AND WHETHER OR NOT APEIRON LIFE OR
                    ANY OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
                    DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND
                    TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                  </li>
                  <li>
                    TO THE MAXIMUM EXTENT PERMITTED BY THE LAW OF THE APPLICABLE
                    JURISDICTION, IN NO EVENT WILL APEIRON LIFE’S TOTAL
                    LIABILITY ARISING OUT OF OR IN CONNECTION WITH THIS
                    AGREEMENT OR FROM THE USE OF OR INABILITY TO USE THE
                    SERVICES OR FACILITIES EXCEED THE AMOUNTS YOU HAVE PAID TO
                    APEIRON LIFE FOR USE OF THE SERVICES DURING THE TWELVE (12)
                    MONTH PERIOD PRECEDING THE FIRST EVENT GIVING RISE TO SUCH
                    LIABILITY.
                  </li>
                  <li>
                    THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE
                    ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN
                    APEIRON LIFE AND YOU.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">
                  INDEMNIFICATION. You agree to indemnify, defend, and hold
                  harmless Apeiron Life and its affiliates, directors, officers,
                  employees, and agents, from and against any liabilities,
                  claims, damages, disputes, demands, losses and costs and
                  expenses (including, without limitation, reasonable legal and
                  attorney’s fees) that arise from your use of the Services,
                  your violation of any term of this Agreement or Policies, or
                  you negligence, other wrongful acts or omissions. Apeiron Life
                  reserves the right to assume the exclusive defense and control
                  of any matter subject to indemnification by you, in which
                  event you will fully assist and cooperate with Apeiron Life.
                </p>
              </li>
              <li>
                <p className="indent">DISPUTE RESOLUTION.</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Agreement to Arbitrate Disputes.</u> Any dispute, claim
                    or controversy arising out of or relating to this Agreement
                    or the breach, termination, enforcement, interpretation or
                    validity thereof or the use of the Services (collectively, “
                    <strong>Disputes</strong>
                    ”) will be resolved{' '}
                    <strong>
                      solely by binding, individual arbitration and not in a
                      class, representative or consolidated action or
                      proceeding,
                    </strong>
                    except for Disputes that involve personal injury or filed in
                    small claims court if it qualifies. The U.S. Federal
                    Arbitration Act shall govern the interpretation and
                    enforcement of this Agreement, and that you and Apeiron Life
                    are each waiving the right to a trial by jury or to
                    participate in a class action. This arbitration provision
                    shall survive termination of this Agreement. Notwithstanding
                    this Section 11, Apeiron Life retains the right to seek
                    injunctive or other equitable relief from a court to prevent
                    (or enjoin) the infringement or misappropriation of our
                    intellectual property rights.
                  </li>
                  <li>
                    <u>Right to Opt-out Arbitration.</u>{' '}
                    <strong>
                      You have the right to opt out of arbitration and litigate
                      any Dispute
                    </strong>{' '}
                    if you provide Apeiron Life with written notice of your
                    desire to do so by email at{' '}
                    <a href="mailto:concierge@apeiron.life">
                      concierge@apeiron.life
                    </a>{' '}
                    or by regular mail at 1700 El Camino Real, Menlo Park, CA
                    94027 within thirty (30) days following the date you first
                    agree to this Agreement.
                  </li>
                  <li>
                    <u>Conducting Arbitration and Arbitration Rules.</u> The
                    arbitration will be conducted by the American Arbitration
                    Association (“
                    <strong>AAA</strong>
                    ”) under its Consumer Arbitration Rules (the “
                    <strong>AAA Rules</strong>
                    ”) then in effect, except as modified by this Agreement. The
                    AAA Rules are available at{' '}
                    <a
                      href="https://www.adr.org/"
                      target="_black"
                      rel="noopener noreferrer"
                    >
                      <u>www.adr.org</u>
                    </a>{' '}
                    or by calling 1-800-778-7879. A party who wishes to start
                    arbitration must submit a written Demand for Arbitration to
                    AAA and give notice to the other party as specified in the
                    AAA Rules. The AAA provides a form Demand for Arbitration at{' '}
                    <a
                      href="https://www.adr.org/"
                      target="_black"
                      rel="noopener noreferrer"
                    >
                      <u>www.adr.org</u>
                    </a>
                    .
                    <p style={{ paddingLeft: '4em' }}>
                      If your claim is for U.S. $10,000 or less, you may choose
                      whether the arbitration will be conducted solely on the
                      basis of documents submitted to the arbitrator, through a
                      telephonic or video-conference hearing, or by an in-person
                      hearing as established by the AAA Rules. If your claim
                      exceeds U.S. $10,000, the right to a hearing will be
                      determined by the AAA Rules. Any arbitration hearings will
                      take place in the county (or parish) of your Home
                      Facility, unless Apeiron Life and you both agree to a
                      different location. The parties agree that the arbitrator
                      shall have exclusive authority to decide all issues
                      relating to the interpretation, applicability,
                      enforceability and scope of this arbitration agreement.
                    </p>
                  </li>
                  <li>
                    <u>Arbitration Costs.</u> Payment of all filing,
                    administration and arbitrator fees will be governed by the
                    AAA Rules. We’ll pay for all filing, administration and
                    arbitrator fees and expenses if your Dispute is for less
                    than $10,000, unless the arbitrator finds your Dispute
                    frivolous. If we prevail in arbitration, we’ll pay all our
                    attorneys’ fees and costs and won’t seek to recover them
                    from you. If you prevail in arbitration you will be entitled
                    to an award of attorneys’ fees and expenses to the extent
                    provided under applicable law.
                  </li>
                  <li>
                    <u>Class Action Waiver.</u> YOU AND APEIRON LIFE AGREE THAT
                    EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS
                    INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER
                    IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                    Further, if the parties’ dispute is resolved through
                    arbitration, the arbitrator may not consolidate another
                    person's claims with your claims, and may not otherwise
                    preside over any form of a representative or class
                    proceeding. If this specific provision is found to be
                    unenforceable, then the entirety of this Dispute Resolution
                    section shall be null and void.
                  </li>
                </ul>
              </li>
              <li>
                <p className="indent">MISCELLANEOUS</p>
                <ul className="indent brackets-latin">
                  <li>
                    <u>Governing Law; Venue.</u> This Agreement will be governed
                    by and construed in accordance with the laws of California,
                    excluding its body of law controlling conflict of laws.
                    Subject to Section 11, any legal action or proceeding
                    arising under this Agreement will be brought exclusively in
                    the federal or state courts located in the Northern District
                    of California and the parties irrevocably consent to the
                    personal jurisdiction and venue therein.
                  </li>
                  <li>
                    <u>Feedback.</u> Apeiron Life appreciates feedback,
                    comments, ideas, proposals and suggestions for improvements
                    to the Services and Facilities (“
                    <strong>Feedback</strong>
                    ”). If you choose to submit Feedback, you agree that we are
                    free to use it without any restriction or compensation to
                    you.
                  </li>
                  <li>
                    <u>Entire Agreement.</u> This Agreement, together with the
                    Terms of Service and, if applicable, any Non-Disclosure
                    Agreement between the parties (the “<strong>NDA</strong>
                    ”), constitute the entire and exclusive understanding and
                    agreement between Apeiron Life and you regarding the
                    Services, Facilities and Products. This Agreement supersedes
                    and replaces all prior oral or written understandings or
                    agreements between Apeiron Life and you regarding the
                    Services, Facilities and Products. In the event of any
                    conflict, the terms in the body of this Agreement shall
                    prevail over the Terms of Service. In the event of any
                    conflict between the terms of the NDA and this Agreement,
                    the terms of this Agreement shall prevail except for that
                    the NDA shall prevail over this Agreement with respect to
                    confidentiality terms.
                  </li>
                  <li>
                    <u>Severability.</u> Subject to the provisions of Section
                    11(e) (Class Action Waiver), if any provision of this
                    Agreement is held invalid, illegal or unenforceable by an
                    arbitrator or a court of competent jurisdiction, that
                    provision will be enforced to the maximum extent permissible
                    and the other provisions of this Agreement will remain in
                    full force and effect.
                  </li>

                  <li>
                    <u>Assignment.</u> You may not assign or transfer this
                    Agreement, by operation of law or otherwise, without Apeiron
                    Life’s prior written consent. Any attempt by you to assign
                    or transfer this Agreement, without such consent, will be
                    null. Apeiron Life may freely assign or transfer this
                    Agreement without restriction. Subject to the foregoing,
                    this Agreement will bind and inure to the benefit of the
                    parties, their successors and permitted assigns.
                  </li>

                  <li>
                    <u>Notices.</u> Any notices or other communications provided
                    by Apeiron Life under this Agreement will be given: (i) via
                    email; or (ii) by posting to the Services. For notices made
                    by email, the date of receipt will be deemed the date on
                    which such notice is transmitted. Any Participant notice
                    shall be given (x) via email to{' '}
                    <a href="mailto:concierge@apeiron.life">
                      concierge@apeiron.life
                    </a>
                    ; (y) in writing with receipt confirmed at 1700 El Camino
                    Real, Menlo Park, CA 94027 or (z) to such other address
                    Apeiron Life may substitute by notice to the Participant.
                  </li>

                  <li>
                    <u>Modification; Waiver of Rights.</u> This Agreement may
                    only be amended or modified by a writing signed by both
                    parties. Apeiron Life’s failure to enforce any right or
                    provision of this Agreement will not be considered a waiver
                    of such right or provision. Apeiron Life’s waiver of or
                    forbearance to enforce any term or provision shall not
                    constitute a waiver as to any subsequent breach or failure
                    of the same term or provision or a waiver of any other term
                    or provision of this Agreement. The waiver of any such right
                    or provision will be effective only if in writing and signed
                    by a duly authorized representative of Apeiron Life. Except
                    as expressly set forth in this Agreement, the exercise by
                    either party of any of its remedies under this Agreement
                    will be without prejudice to its other remedies under this
                    Agreement or otherwise.
                  </li>

                  <li>
                    <u>Counterparts.</u> This Agreement and any amendment hereto
                    may be executed in one or more counterparts. All executed
                    counterparts shall be deemed to be one and the same
                    instrument. Delivery of a signed copy of this Agreement or
                    any amendment hereto by fax or e- mail/pdf shall have the
                    same effect as delivery of an original.
                  </li>
                </ul>
              </li>

              <li>
                <p className="indent">
                  WARNING. Use of steroids to increase strength or growth can
                  cause serious health problems. Steroids can keep teenagers
                  from growing to their full height; they can also cause heart
                  disease, stroke, and damage liver function. Men and women
                  using steroids may develop fertility problems, personality
                  changes, and acne. Men can also experience premature balding
                  and development of breast tissue. In addition to these health
                  hazards, there are also civil and criminal penalties for the
                  unauthorized sale, use, or exchange of anabolic steroids.
                </p>
              </li>
            </ul>
          </p>
        </Container>
      </div>
    </OnBoardingContainer>
  )
}
