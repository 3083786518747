import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'

export default function CheckBoxItem(props) {
  const { text, onChange, initialValue } = props
  const [value, setValue] = useState(
    _.isBoolean(props.value) ? props.value : initialValue
  )

  useEffect(() => {
    props.value && setValue(props.value)
  }, [props.value])

  return (
    <Checkbox
      checked={value}
      onChange={(e) => {
        setValue(e.target.checked)
        onChange(e.target.checked)
      }}
    >
      {text}
    </Checkbox>
  )
}
