import React from 'react'
import _ from 'lodash'
import { Typography } from 'antd'
import style from '../style/programItem.module.sass'
import BirdgeIcon from '../../../asserts/icon/program/item-bridge-link-icon.png'
import ValdIcon from '../../../asserts/icon/program/item-vald-link-icon.png'
import OthersIcon from '../../../asserts/icon/program/item-others-link-icon.png'
// import DocsIcon from '../../../asserts/icon/program/item-docs-link-icon.png'

const { Paragraph } = Typography

export default function ExerciseCard(props) {
  const { hasProgram = true } = props
  let exercise = _.cloneDeep(props.exercise)
  let inActive =
    props.isProgramActive &&
    !props.isWorkout &&
    !(exercise.status || (exercise.published_at ? true : false))
  if (props.position === 'dashboard' && exercise.draft_data) {
    exercise = Object.assign(exercise, exercise.draft_data)
    inActive = true
  }
  const {
    duration,
    left_times,
    intensity,
    type,
    program_item_id,
    meet,
    category,
    workout_type
  } = exercise || {}
  const { itemDurationMapping, items } = props

  const renderDuration = () => {
    const render = (value) => {
      return (
        <>
          {value}
          <span className={style.duration}>M</span>
        </>
      )
    }
    if (props.isWorkout) {
      return duration && render(Math.round(duration / 60))
    } else {
      const mapper =
        itemDurationMapping &&
        itemDurationMapping.find((e) => e.value === duration)
      const label = mapper && mapper.label.match(/[0-9\-\\+]+/)
      return render(
        (label && label[0]) || (duration && Math.round(duration / 60))
      )
    }
  }

  return (
    <div
      className={`${style.exerciseCard} ${inActive ? style.inActive : ''}`}
      onClick={props.clickHandle}
      style={{ width: props.width || 163 }}
    >
      <div
        className={style.cardBox}
        style={{
          borderColor: props.isWorkout
            ? meet || !program_item_id || props.position === 'clientView'
              ? '#0D2350' //'#7FB800'
              : '#FF6F03'
            : '#C4C4C4'
        }}
      >
        {hasProgram && renderTag()}
        <div className={style.cardTitle}>
          <span>
            {props.isWorkout ? type : `${category}${workout_type && ':'}`}
          </span>
          <span>{left_times && `x${left_times}`}</span>
        </div>
        {workout_type && (
          <div className={style.cardWorkoutTitle}>
            <Paragraph ellipsis>{workout_type}</Paragraph>
          </div>
        )}
        <div className={style.cardBody}>
          <div>
            <div className={style.cardSubTitle}>Time</div>
            {renderDuration()}
          </div>
          {hasProgram && (
            <div>
              <div className={style.cardSubTitle}>Intensity</div>
              {intensity}
            </div>
          )}
        </div>
      </div>
    </div>
  )

  function getIcon(attachment) {
    switch (attachment.name) {
      case 'bridge':
        return BirdgeIcon
      case 'vald':
        return ValdIcon
      case 'others':
        return OthersIcon
      default:
        return OthersIcon
    }
  }

  function renderTag() {
    if (props.isWorkout) {
      // check if there any releate action item
      const releatedItem = _.find(items, (item) => item.id === program_item_id)
      const specialLink = releatedItem
        ? _.find(releatedItem.attachments, (attachment) => {
            return (
              attachment.type === 'url' &&
              (attachment.name === 'others' ||
                attachment.name === 'bridge' ||
                attachment.name === 'vald')
            )
          })
        : null
      return (
        <div className={style.cardTagContainer}>
          {specialLink && (
            <img
              src={getIcon(specialLink)}
              alt=""
              style={{ width: 32, height: 32 }}
            />
          )}
          <div
            style={{ background: program_item_id ? '#7FB800' : '#A5A8AD' }} // program_item_id ? '#7FB800' : '#A5A8AD'
            className={style.cardTag}
          >
            {program_item_id ? 'Program' : 'NON-Program'}
          </div>
        </div>
      )
    } else {
      const specialLink = _.find(exercise.attachments, (attachment) => {
        return (
          attachment.type === 'url' &&
          (attachment.name === 'others' ||
            attachment.name === 'bridge' ||
            attachment.name === 'vald')
        )
      })
      return specialLink ? (
        <div className={style.cardTagContainer}>
          <img
            src={getIcon(specialLink)}
            alt=""
            style={{ width: 32, height: 32 }}
          />
        </div>
      ) : (
        <></>
      )
    }
  }
}
