import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { getAxisX } from '../../summaryEmail/common'
import { fontFamily } from '../../../utils/constant'

function Arrow(props) {
  return (
    <svg
      width="3"
      height="7"
      viewBox="0 0 3 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x}
      y={(props.y && props.y - 4) || 0}
    >
      <path
        d="M3 3.4587L6.67564e-08 6.00409L4.79289e-09 0.913305L3 3.4587Z"
        fill={props.style.fill}
      />
    </svg>
  )
}

class TrainingLoad extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    trainingLoad: PropTypes.object,
    avgTrainingLoad: PropTypes.number,
    barWidth: PropTypes.number,
    extraAxisText: PropTypes.string
  }

  state = {}

  xAxisFormat = (x) => {
    const Weeks = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    return Weeks[moment(x).format('d')]
  }

  dataHandle(axisX) {
    const { trainingLoad } = this.props
    const entries = trainingLoad.entries || []
    const data = axisX.map((x) => {
      const target = entries.find(
        (entry) => entry.record_date === x.format('YYYY-MM-DD')
      )
      return {
        x,
        y: target && target.training_load >= 0 ? target.training_load : null
      }
    })
    return data
  }

  getGridStroke = (t) => {
    const { avgTrainingLoad } = this.props
    if (t === avgTrainingLoad && avgTrainingLoad > 0) {
      return '#FF6B00'
    }
    return 'transparent'
  }

  renderPlaceholderBar(data, maxInput) {
    const placeholderData = data.map((item) => {
      if (item.y || (this.props.showLabel && typeof item.y === 'number')) {
        return {
          x: item.x,
          y: null
        }
      } else {
        return {
          x: item.x,
          y: maxInput / 100 || 1
        }
      }
    })

    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: '#DAE1EA'
            // stroke: '#fff',
            // strokeWidth: 3
          }
        }}
        barWidth={this.props.barWidth || 15}
        data={placeholderData}
        cornerRadius={0}
      />
    )
  }

  render() {
    const {
      startDate,
      endDate,
      avgTrainingLoad,
      totalTrainingLoad,
      trainingLoad,
      showLabel,
      barWidth,
      paddingLeft,
      paddingRight,
      extraAxisText,
      height
    } = this.props
    const axisX = getAxisX(startDate, endDate)
    const data = this.dataHandle(axisX)
    let maxInput = Math.max(...data.map((item) => item.y || 0)) || 100
    maxInput = showLabel ? maxInput + maxInput * 0.2 : maxInput
    let avg = avgTrainingLoad
    if (extraAxisText === 'AVG') {
      avg = trainingLoad.aggregation.avg
    }
    const tickValuesY = [0, avg, maxInput].filter(
      (item) => typeof item === 'number'
    )

    const avgLabelFill = (t) => {
      if (t === avg && avg > 0) {
        return '#70808E'
      }
      return 'transparent'
    }

    return (
      <div className="chart">
        {axisX && data && (
          <ContainerWidthSizer>
            {({ width }) => (
              <VictoryChart
                width={width}
                height={height || 100}
                padding={{
                  top: 10,
                  left: paddingLeft || 10,
                  bottom: 20,
                  right: paddingRight || 10
                }}
              >
                <VictoryAxis
                  padding={{ left: 10, right: 10 }}
                  style={
                    this.props.xAxisStyle || {
                      axis: {
                        stroke: 'transparent'
                      },
                      tickLabels: {
                        fontFamily,
                        fontSize: '10px',
                        fill: '#B0BAC9'
                      }
                    }
                  }
                  tickValues={axisX}
                  tickLabelComponent={
                    <VictoryLabel
                      text={(datum) => {
                        let result = this.props.xAxisFormat
                          ? this.props.xAxisFormat(datum)
                          : this.xAxisFormat(datum)
                        return result
                      }}
                    />
                  }
                />
                <VictoryAxis
                  dependentAxis
                  tickValues={tickValuesY}
                  style={{
                    axis: { stroke: 'transparent' },
                    grid: {
                      stroke: this.getGridStroke,
                      strokeDasharray: '3,3'
                    },
                    tickLabels: {
                      fill: 'transparent'
                    }
                  }}
                />

                {extraAxisText && (
                  <VictoryAxis
                    dependentAxis
                    tickValues={tickValuesY}
                    orientation="right"
                    style={{
                      axis: { stroke: 'transparent' },
                      // grid: {
                      //   stroke: this.getGridStroke,
                      //   strokeDasharray: '3,3'
                      // },
                      tickLabels: {
                        fontFamily,
                        fontSize: 9,
                        fill: avgLabelFill
                      }
                    }}
                  />
                )}

                {extraAxisText && (
                  <VictoryAxis
                    // invertAxis={true}
                    dependentAxis
                    tickValues={tickValuesY}
                    tickLabelComponent={<Arrow text={extraAxisText} />}
                    style={{
                      axis: { stroke: 'transparent' },
                      grid: {
                        stroke: avgLabelFill,
                        strokeDasharray: '3,3'
                      },
                      tickLabels: {
                        fontFamily: `"Gilroy",${fontFamily}`,
                        fontSize: '9px',
                        fill: avgLabelFill
                      }
                    }}
                  />
                )}

                {extraAxisText && (
                  <VictoryAxis
                    // invertAxis={true}
                    dependentAxis
                    tickValues={tickValuesY}
                    tickLabelComponent={
                      <VictoryLabel
                        text={extraAxisText}
                        angle={-90}
                        dy={-8}
                        textAnchor="middle"
                      />
                    }
                    style={{
                      axis: { stroke: 'transparent' },
                      tickLabels: {
                        fontFamily: `"Gilroy",${fontFamily}`,
                        fontSize: '9px',
                        fill: avgLabelFill
                      }
                    }}
                  />
                )}

                <VictoryBar
                  offsetX={10}
                  // labels={({ datum }) => datum.y}
                  labels={(datum) => showLabel && datum.y}
                  labelComponent={<VictoryLabel dy={8} />}
                  style={{
                    labels: { fill: '#383E48', fontSize: 10, fontFamily },
                    data: {
                      fill: '#FF6B00'
                    }
                  }}
                  barWidth={barWidth ? barWidth : 12}
                  data={data}
                  cornerRadius={{
                    top: 4,
                    bottom: 4
                  }}
                />
                {this.renderPlaceholderBar(data, maxInput)}
              </VictoryChart>
            )}
          </ContainerWidthSizer>
        )}
        {typeof totalTrainingLoad === 'number' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontFamily,
              fontSize: 12
            }}
          >
            <div
              style={{
                color: '#8596A4',
                fontFamily: 'Gilroy'
              }}
            >
              <span
                style={{
                  display: 'inline-block',
                  width: 6,
                  height: 6,
                  borderRadius: 6,
                  marginRight: 6,
                  verticalAlign: 'middle',
                  background: '#FF6B00'
                }}
              />
              Total training load
            </div>
            <div
              style={{
                color: '#383E48',
                fontFamily: 'Gilroy-bold'
              }}
            >
              {totalTrainingLoad}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default TrainingLoad
