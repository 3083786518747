import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker

class DateRange extends Component {
  static propTypes = {}

  componentDidMount() {
    const { value, format } = this.props
    this.setState({
      value: moment(value, format)
    })
  }

  triggerChange = (date, dateString) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(dateString)
    }
  }

  render() {
    const { extraAttr } = this.props
    return <RangePicker onChange={this.triggerChange} {...extraAttr} />
  }
}

export default DateRange
