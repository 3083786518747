import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import BarChart from './barChart'

class ReportDashboard extends Component {
  static propTypes = {}

  render() {
    const {
      total_clients,
      email_sent,
      email_opened,
      open_rate,
      not_sent
      // statistics,
      // startDate,
      // endDate
    } = this.props
    return (
      <div className="dashboard-container">
        <div className="dashboard-info">
          <div className="large-text">
            <span>Total clients</span>
            {total_clients}
          </div>
          <div className="large-text">
            <span>Email sent</span>
            {email_sent}
          </div>
          <div className="large-text">
            <span>Email opened</span>
            {email_opened}
          </div>
          <div className="large-text">
            <span>Open rate</span>
            {open_rate}%
          </div>
          <div className="large-text">
            <span>Not sent</span>
            {not_sent}
          </div>
        </div>
        {/* <div className="chart">
          <BarChart {...{ startDate, endDate, statistics }} />
        </div> */}
      </div>
    )
  }
}

export default ReportDashboard
