import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from 'antd'
import WorkoutChart from './comp/workoutChart'
import ACSMHeartTarget from './comp/acsmHeartTarget'
import AcsmBreakDownContainer from './comp/acsmBreakDownContainer'
import Information from '../UI/extraInformation'
import SubTip from './comp/subTip'
import { SvgText } from '../icons/weeklySummary'
import { calculateElementWidth } from './common'
// import Modality from '../client/comp/modality'
import TrainingLoad from '../client/comp/trainingLoad'
import { getSum } from '../../utils/common'
import { fontFamily } from '../../utils/constant'

import { exercise_session } from './directions.json'

import { nutritionStyle } from './styles/nutrition.sass'

import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const normal = '#70808E',
  primary = '#383E48',
  gray = '#b7bfc6'

class Exercise extends Component {
  static propTypes = {}

  getGoals = (startDate, endDate) => {
    const {
      data: {
        overview,
        exercise: { extra }
      }
    } = this.props
    const { heart_rate } = overview
    const entries = (heart_rate && heart_rate.achieving) || []
    let totalHeartRate = [
      { name: 'Z1', color: '#C9CDD2' },
      { name: 'Z2', color: '#F7AD69' },
      { name: 'Z3', color: '#FB9043' },
      { name: 'Z4', color: '#FC7558' },
      { name: 'Z5', color: '#FF501A' }
    ]
    totalHeartRate = totalHeartRate.map((item, index) => {
      const value = getSum(
        entries.map((item) => Math.round(item.heart_rate_zone[index] / 60))
      )
      return {
        ...item,
        value: value && value + 'm'
      }
    })

    totalHeartRate.shift()
    totalHeartRate = totalHeartRate.reverse()

    const trainingLoadEntry = extra.training_load.entries.map(
      (item) => item.training_load
    )
    const totalTrainingLoad =
      trainingLoadEntry.length > 0
        ? getSum(extra.training_load.entries.map((item) => item.training_load))
        : '--'

    const xAxisStyle = {
      axis: { stroke: 'transparent' },
      tickLabels: {
        fontFamily: `"Gilroy",${fontFamily}`,
        fontSize: '10px',
        fill: '#70808E'
      }
    }

    return [
      {
        id: 3,
        description: 'Training Load (RPES)',
        total: totalTrainingLoad,
        previous_weeks_average: extra.previous_weeks_tl_average,
        chart: (
          <div style={{ padding: '0 10px' }}>
            <TrainingLoad
              barWidth={16}
              height={60}
              paddingLeft={50}
              paddingRight={50}
              showLabel={true}
              xAxisFormat={(x) =>
                moment(x)
                  .format('ddd')
                  .toUpperCase()
              }
              xAxisStyle={xAxisStyle}
              {...{
                startDate,
                endDate,
                trainingLoad: extra.training_load,
                extraAxisText: 'AVG'
              }}
            />
          </div>
        )
      }
    ]
  }

  renderGoals() {
    const {
      startDate,
      endDate,
      submitData: { exercise },
      oprExercise,
      isLocked
    } = this.props

    const isEditable = !isLocked && this.props.isEditable

    let goals = this.getGoals(startDate, endDate)

    if (exercise.activity_goals) {
      goals.map((item) => {
        const target = exercise.activity_goals.find(
          (goal) => goal.id === item.id
        )
        if (target) {
          target.description = item.description
          return Object.assign(item, target)
        }
        return item
      })
    }

    if (exercise.deleted_activity_goal) {
      goals = goals.filter(
        (item) => !exercise.deleted_activity_goal.find((id) => id === item.id)
      )
    }

    return (
      <div className={nutritionStyle} style={{ paddingTop: 0 }}>
        {goals.map((item, index) => (
          <div className="goal-item" style={{ borderTopWidth: 1 }} key={index}>
            {isEditable && (
              <div
                className="delete"
                onClick={() =>
                  oprExercise('delete', {
                    id: item.id,
                    section: `Exercise - ${item.description}`
                  })
                }
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}

            <div className="name">{item.description}</div>
            <div
              className="data-content ref-container"
              ref={`exercise.chart_url.${item.id}`}
            >
              <div>
                <div className="days">
                  <SvgText
                    text={
                      item.total ||
                      (item.id === 3 && typeof item.total === 'number')
                        ? item.total
                        : '--'
                    }
                    height={33}
                    fontSize={30}
                    width={
                      calculateElementWidth(
                        'span',
                        item.total ||
                          (item.id === 3 && typeof item.total === 'number')
                          ? item.total
                          : '--',
                        '30px'
                      ).width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                  {item.total > 0 &&
                    (item.formatChange || item.formatChange === 0) && (
                      <SvgText
                        text={`${item.formatChange > 0 ? '+' : ''}${
                          item.formatChange
                        }`}
                        fill={normal}
                        fontSize={12}
                        height={18}
                        fontFamily="Gilroy"
                        width={
                          calculateElementWidth(
                            'span',
                            `${item.formatChange > 0 ? '+' : ''}${
                              item.formatChange
                            }`,
                            '12px'
                          ).width + 5
                        }
                        textAttr={{
                          x: 0,
                          textAnchor: 'start',
                          dominantBaseline: 'mathematical',
                          fontWeight: 'normal'
                        }}
                      />
                    )}
                  <SubTip text="7 DAY TOTAL" unit={item.unit} />
                </div>

                <div className="weeks">
                  <SvgText
                    text={
                      item.previous_weeks_average
                        ? item.previous_weeks_average
                        : '--'
                    }
                    fill={item.previous_weeks_average ? gray : primary}
                    height={33}
                    fontSize={30}
                    width={
                      calculateElementWidth(
                        'span',
                        item.previous_weeks_average
                          ? item.previous_weeks_average
                          : '--',
                        '30px'
                      ).width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                  <SubTip text="3 WEEK AVG" unit={item.unit} />
                </div>
              </div>
              <div>{item.chart}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      startDate,
      endDate,
      loginRole,
      data,
      setSubmitData,
      originInformation,
      deleteSections,
      templates,
      updateEditingCount,
      updateEmailAction,
      programData,
      isLocked,
      submitData: { exercise, deleted_section }
    } = this.props
    const isEditable = !isLocked && this.props.isEditable
    const status = isLocked
      ? 'Locked'
      : (originInformation && originInformation.status) || 'Draft'

    return (
      <>
        <Information
          direction={exercise_session}
          updateEmailAction={updateEmailAction}
          updateEditingCount={updateEditingCount}
          status={status}
          title="Overview"
          templates={templates}
          showDone={loginRole !== 'User'}
          initialValue={exercise.recommendation}
          doneStatus={exercise.recommendation_status}
          updateCommend={(value, status) =>
            setSubmitData({
              part: 'exercise',
              field: 'recommendation',
              value,
              status,
              section: 'Recommendation of Exercise'
            })
          }
        />
        {!deleted_section.exercise_target && (
          <div className="sub-section">
            {isEditable && (
              <div
                className="delete"
                onClick={() => deleteSections('exercise_target')}
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}
            <h4 className="sub-title">Apeiron Life Training Target</h4>

            <div ref="exercise.heart_chart_url" className="ref-container">
              {!deleted_section.exercise_training_target && (
                <div className="deletable-part">
                  {isEditable && (
                    <div
                      className="deletable-part-opr"
                      onClick={() => deleteSections('exercise_training_target')}
                    >
                      <img className="delete-icon" src={DelIcon} alt="del" />
                    </div>
                  )}
                  <div className="big-chart">
                    <ACSMHeartTarget
                      {...{
                        exercise: data && data.exercise,
                        previousWeek: false,
                        startDate,
                        endDate,
                        containerStyle: { margin: 0 }
                      }}
                    />
                    <ACSMHeartTarget
                      {...{
                        exercise: data && data.exercise,
                        previousWeek: true,
                        containerStyle: { margin: 0 }
                      }}
                    />
                  </div>
                </div>
              )}

              {!deleted_section.exercise_target_breakdown && (
                <div className="deletable-part">
                  {isEditable && (
                    <div
                      className="deletable-part-opr"
                      onClick={() =>
                        deleteSections('exercise_target_breakdown')
                      }
                    >
                      <img className="delete-icon" src={DelIcon} alt="del" />
                    </div>
                  )}
                  <AcsmBreakDownContainer
                    {...{ exercise: data && data.exercise }}
                  />
                </div>
              )}
            </div>
            <div className="extra-information">
              <h5>What is my Aerobic Training Target?</h5>
              <p>
                For optimal health benefits, we recommend you aim for at least
                150 minutes per week of moderate-intensity activity (zone 2 -
                zone 3; 60-75% max heart rate). This includes running, cycling,
                swimming, and hiking.
              </p>
            </div>
            <Divider />
          </div>
        )}
        {!deleted_section.exercise_workout && (
          <div className="sub-section">
            {isEditable && (
              <div
                className="delete"
                onClick={() => deleteSections('exercise_workout')}
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}

            <h4 className="sub-title">Workouts</h4>
            <div
              className="big-chart ref-container"
              ref="exercise.workout_chart_url"
            >
              <WorkoutChart
                {...{
                  startDate,
                  endDate,
                  exercise: data ? data.exercise : {},
                  programData
                }}
              />
            </div>
          </div>
        )}

        {this.renderGoals()}
      </>
    )
  }
}

export default Exercise
