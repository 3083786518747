import axios from './axios'

export const getMessages = (id) => axios.get(`/message?client_id=${id}`)

export const updateMessages = (params, id, messageId) =>
  axios.putJson(`/message/${messageId}?client_id=${id}`, params)

export const sendMessage = (params, id) =>
  axios.postJson(`/message?client_id=${id}`, params)

export const getUnreadMessage = (personId) =>
  axios.get(`/message/status?client_id=${personId}`)
