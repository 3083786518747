import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import HelloSign from 'hellosign-embedded'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Checkbox, message, Radio } from 'antd'
import { animateScroll as scroll } from 'react-scroll'
import { OnboardingHeader } from '../style'
import {
  ViewIcon,
  VectorIcon,
  SignedIcon
  // CheckedCircleIcon
} from '../../../components/icons/onboarding'
import { loadUser, saveUser } from '../../../utils/storage'
import pdfIcon from '../../../asserts/images/pdf.svg'
import {
  skipWaiverSection,
  skipNotification,
  getWaiverForm,
  getWaiverSignedForm
} from '../../../api'
import _ from 'lodash'
import { WaiverFormKey } from '../setup/screen'
import MemberInfoDone from '../introductory/completed'

const RadioGroup = Radio.Group

class Medical extends Component {
  static propTypes = {}

  state = {
    step: 0,
    client: null,
    loading: false,
    stepForm: [
      {
        type: 'MEDICAL RELEASE',
        name: 'Medical Release',
        signed: function() {
          return this.signs[this.signs.length - 1]
        },
        atLeastOneSigned: function() {
          return this.signs[0]
        },
        des: `To design your action plan, we’ll need to look over your medical
        history. This form authorizes your health care provider to release
        it to us.`,
        getUrl: getWaiverForm,
        isChecked: true,
        allowMulti: true,
        signs: [false],
        reminder: '3 mins'
      }
    ],
    hasOtherProviders: false
  }

  hasOtherMedicalProviders = () => {
    const { step, stepForm, hasOtherProviders } = this.state
    const formInfo = stepForm[step]
    if (hasOtherProviders) {
      formInfo.signs.push(false)
      stepForm[step] = formInfo
      this.setState({ stepForm, hasOtherProviders: false })
    } else {
      if (stepForm[step].isChecked && stepForm[step].signed()) {
        this.autoDirecting()
      }
    }
  }

  sign = async (getUrl, suffix) => {
    const { client } = this.state
    this.setState({ loading: true })
    const {
      embedded: { sign_url }
    } = await getUrl(suffix)
    client.open(sign_url)
    this.setState({ loading: false })
  }

  checked = () => {
    const { step, stepForm } = this.state
    const formInfo = stepForm[step]
    formInfo.isChecked = !formInfo.isChecked
    stepForm[step] = formInfo
    this.setState({ stepForm })
    if (formInfo.signed() && formInfo.isChecked) {
      this.autoDirecting()
    }
  }

  linkNextPage = () => {
    const {
      location: { search }
    } = this.props
    if (search.match('callback')) {
      const query = search
        .substr(1)
        .split('&')
        .reduce((kv, cur) => {
          const [k, v] = cur.split('=')
          kv[k] = v
          return kv
        }, {})
      const callback = query.callback
      this.props.history.push(callback)
    } else if (search.match('directive')) {
      this.props.history.push('/onboarding/questionnaire')
    } else {
      this.props.history.push('/onboarding/ready')
    }
  }

  autoDirecting = async () => {
    // const { step } = this.state //stepForm
    // if (step < 1) {
    //   this.setState({ step: step + 1 })
    // } else {

    // }
    const {
      location: { search }
    } = this.props
    if (search.match('callback')) {
      this.linkNextPage()
    } else {
      this.setState({ done: true })
    }
  }

  skipWaiver = async () => {
    let person = loadUser()
    // Medical skip, pass 1
    const { team } = await skipWaiverSection(1, person)
    const form_name = 'Medical Release Form'

    skipNotification({ form_name, operation_type: 2 }, person.id)
    const { completed_signature } = person.profile
    if (team) {
      person = team.person
      person.profile.completed_signature = completed_signature // api callback takes time
    }

    saveUser(person)
    this.props.setLoginAccount(person)
    this.autoDirecting()
  }

  loadSignedForms = async (step) => {
    const {
      location: { search }
    } = this.props
    let { stepForm } = this.state
    // const result = await getWaiverSignedForm()
    // if (result && result.file_urls.length > 0) {

    //   stepForm[0].signs = result.file_urls
    //   this.setState({ stepForm })
    // }

    const res = await getWaiverSignedForm(false)
    if (res) {
      if (res.medical_release_form.length) {
        stepForm[0].signs = res.medical_release_form
      }
      if (!search.match('step')) {
        step = stepForm.findIndex((item) => !item.signed())
        if (step < 0) {
          step = 0
        }
      }

      this.setState({ stepForm, step })
    }
  }

  componentDidMount() {
    const {
      account,
      location: { search }
    } = this.props
    let { stepForm } = this.state

    let step = 0
    if (search.match('step')) {
      const query = search
        .substr(1)
        .split('&')
        .reduce((kv, cur) => {
          const [k, v] = cur.split('=')
          kv[k] = v
          return kv
        }, {})
      step = parseInt(query.step)
      this.setState({ step })
    }

    this.loadSignedForms(step)
    const client = new HelloSign({
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.REACT_APP_WORKFLOW === 'develop'
    })
    if (account && !account.user.profile.waiver_required) {
      // stepForm[0].signs[stepForm[0].signs.length - 1] = true
      stepForm[0].isChecked = true
    }
    client.on('sign', async () => {
      // in runtime, the step and stepForm was freshed now, so regain here
      let { step, stepForm } = this.state

      stepForm[step].signs[stepForm[step].signs.length - 1] = true
      this.setState({ stepForm })

      const user = loadUser()
      user.profile.completed_signature = user.profile.completed_signature || []
      if (!user.profile.completed_signature.includes(WaiverFormKey.MEDICAL)) {
        user.profile.completed_signature.push(WaiverFormKey.MEDICAL)
        user.profile.waiver_required = false
        this.props.setLoginAccount(user)
        saveUser(user)
      }
    })

    client.on('close', () => {})

    client.on('error', (data) => {
      const errStr = `Something went wrong! Please try again. Error code: ${data.code}`
      console.error(errStr)
      message.error(errStr)

      client.close()
    })

    this.setState({ client })
  }

  skipOrNext = () => {
    const { step, stepForm } = this.state
    const formInfo = stepForm[step]
    if (formInfo.signed()) {
      this.autoDirecting()
    } else {
      this.skipWaiver()
    }
  }

  disableSignCard = () => {
    const { step, shareMedicalReleaseForm } = this.state
    return (
      step === 0 &&
      _.isBoolean(shareMedicalReleaseForm) &&
      !shareMedicalReleaseForm
    )
  }

  disableNextButton = () => {
    const { step, stepForm, shareMedicalReleaseForm } = this.state
    const formInfo = stepForm[step]
    if (step === 0) {
      if (_.isBoolean(shareMedicalReleaseForm) || formInfo.signed()) {
        return shareMedicalReleaseForm ? !formInfo.signed() : false
      } else {
        return true
      }
    } else {
      return !formInfo.signed()
    }
  }

  render() {
    const {
      step,
      loading,
      stepForm,
      hasOtherProviders,
      shareMedicalReleaseForm
    } = this.state
    const formInfo = stepForm[step]

    return (
      <div className="main-container">
        <OnboardingHeader />
        {this.state.done ? (
          <MemberInfoDone
            goNext={() => {
              const person = loadUser()
              person.profile.waiver_required = false
              saveUser(person)
              this.linkNextPage()
            }}
          />
        ) : (
          <>
            <div className="step-title">
              <span>4</span>
              Medical Release
            </div>
            <div className="main-content">
              {step === 0 && (
                <div className="bottom-line other-provider-select share-medical-select">
                  <h1>{formInfo.name}</h1>
                  <p className="explain">
                    As part of your Apeiron Life membership, we would love to
                    integrate with your current care team to share assessment
                    results, health information, and ongoing updates on your
                    progress. This way, we can all coordinate on how to best
                    assist you in your health and wellness journey. Members
                    often enjoy us collaborating with:
                  </p>
                  <ul className="explain">
                    <li>
                      <strong>
                        Primary Care Physicians (highly encouraged)
                      </strong>
                    </li>
                    <li>Specialist Physicians</li>
                    <li>Personal Trainers</li>
                    <li>Massage Therapists</li>
                    <li>Chiropractors</li>
                    <li>Other medical and wellness providers</li>
                  </ul>

                  <p className="question">
                    In order to do so, we need your permission to release such
                    information. Do you want your health information, data, or
                    recommendations released to anyone?
                  </p>
                  <RadioGroup
                    disabled={formInfo.atLeastOneSigned() ? true : false}
                    onChange={(e) => {
                      this.setState({ shareMedicalReleaseForm: e.target.value })
                      scroll.scrollToBottom()
                    }}
                    value={
                      _.isBoolean(shareMedicalReleaseForm)
                        ? shareMedicalReleaseForm
                        : formInfo.atLeastOneSigned()
                        ? true
                        : null
                    }
                  >
                    <Radio value={true} className="radio">
                      Yes
                    </Radio>
                    <Radio value={false} className="radio">
                      No
                    </Radio>
                  </RadioGroup>

                  {_.isBoolean(shareMedicalReleaseForm) &&
                    !shareMedicalReleaseForm && (
                      <p className="question">
                        You have confirmed you do not want to release your
                        health information, data, or recommendations. Select the
                        “Next” button to continue.
                      </p>
                    )}
                </div>
              )}
              {(step === 0
                ? _.isBoolean(shareMedicalReleaseForm) ||
                  formInfo.atLeastOneSigned()
                : true) && (
                <>
                  <div className="sign bottom-line">
                    <div
                      className={`sub-title ${
                        this.disableSignCard() ? 'disable' : ''
                      }`}
                    >
                      Sign with e-Signature
                    </div>
                    {formInfo.signs.map((hasSigned, index) => {
                      // hasSigned = typeof hasSigned === 'object'?hasSigned: false
                      return (
                        <div
                          className={`sign-content ${
                            this.disableSignCard() ? 'disable' : ''
                          }`}
                          key={index}
                        >
                          <div>
                            <img className="icon" src={pdfIcon} alt="pdf" />
                            <div className="sign-title">
                              <h5>
                                {formInfo.name} Form - {index + 1}
                              </h5>
                              {!hasSigned && (
                                <p>Please preview and sign document</p>
                              )}
                            </div>
                          </div>
                          {hasSigned ? (
                            <span className="signed">
                              <SignedIcon />
                              Signed
                            </span>
                          ) : (
                            <Button
                              loading={loading}
                              type="primary"
                              onClick={() =>
                                this.sign(
                                  formInfo.getUrl,
                                  formInfo.signs.length - 1
                                )
                              }
                              disabled={!formInfo.isChecked}
                            >
                              <ViewIcon />
                              Preview & Sign
                            </Button>
                          )}
                        </div>
                      )
                    })}
                  </div>
                  {!formInfo.signed() && (
                    <div className="check bottom-line">
                      <div>
                        <Checkbox
                          checked={formInfo.isChecked}
                          onClick={this.checked}
                        />
                        I understand this is a legal representation of my
                        signature
                      </div>
                      <div className="vector">
                        <VectorIcon />
                        <p>
                          Apeiron Life is compliant with all major eSignature
                          laws including ESIGN, UETA, European eIDAS, and
                          eSignature service provided by HelloSign
                        </p>
                      </div>
                    </div>
                  )}
                  {formInfo.allowMulti && formInfo.signed() && (
                    <div className="other-provider-select">
                      <p className="sub-title">
                        DO YOU HAVE any OTHER PROVIDER(S) that need a{' '}
                        {formInfo.name} ?
                      </p>
                      <div>
                        <RadioGroup
                          onChange={(e) =>
                            this.setState({ hasOtherProviders: e.target.value })
                          }
                          defaultValue={hasOtherProviders}
                        >
                          <Radio value={false} className="radio">
                            No
                          </Radio>
                          <Radio value={true} className="radio">
                            Yes
                          </Radio>
                        </RadioGroup>
                      </div>
                      <Button
                        type="primary"
                        style={{
                          width: '186px',
                          height: '48px',
                          marginTop: '24px'
                        }}
                        onClick={() => this.hasOtherMedicalProviders()}
                      >
                        Proceed
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <footer>
              <span
                className="back"
                onClick={() => {
                  const {
                    location: { search },
                    history
                  } = this.props
                  if (search.match('callback')) {
                    const query = search
                      .substr(1)
                      .split('&')
                      .reduce((kv, cur) => {
                        const [k, v] = cur.split('=')
                        kv[k] = v
                        return kv
                      }, {})
                    const callback = query.callback
                    history.push(callback)
                  } else {
                    history.push('/onboarding/ready')
                  }
                }}
              >
                <LeftOutlined /> back
              </span>
              <Button
                loading={loading}
                type="primary"
                onClick={this.skipOrNext}
                disabled={this.disableNextButton()}
                style={{
                  width: '117px',
                  height: '48px'
                }}
              >
                NEXT
                <RightOutlined />
              </Button>
            </footer>
          </>
        )}
      </div>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Medical)
