import React, { useCallback, useMemo, useRef } from 'react'
import { Col, Row } from 'antd'
import SelectQuestion from '../select'
import NumberEntry from '../number'
import classNames from 'classnames'
import { chunk, some } from 'lodash'
import { DayActivityContainer } from './index.styled'

export const ActivityAnswerKeys = {
  SESSION: 'session',
  DURATION: 'duration',
  INTENSITY: 'intensity'
}

export const NO_ACTIVITY_VALUE = 'No Activity'

const ActivityItem = ({ questions, answer, onUpdate }) => {
  const numberEntryRef = useRef(null)
  const intensityRef = useRef(null)
  const { activityAnswer, durationAnswer, intensityAnswer } = useMemo(() => {
    const hasVaildValue =
      some(answer, (v) => v) &&
      answer[ActivityAnswerKeys.SESSION] !== NO_ACTIVITY_VALUE
    const [activityAnswer, durationAnswer, intensityAnswer] = Object.values(
      ActivityAnswerKeys
    ).map((key) => {
      const value = answer[key] || null
      return { answer: { value }, verify: hasVaildValue && !value }
    })
    return { activityAnswer, durationAnswer, intensityAnswer }
  }, [answer])

  const handleUpdateValue = useCallback(
    (newAnswer, question) => {
      const { SESSION, DURATION, INTENSITY } = ActivityAnswerKeys
      let newValues = {
        ...answer,
        [question.answerKey]: newAnswer.answer.value
      }
      if (
        question.answerKey === SESSION &&
        newAnswer.answer.value === NO_ACTIVITY_VALUE
      ) {
        newValues = { ...newValues, [DURATION]: null, [INTENSITY]: null }
        numberEntryRef.current.clearValue()
        intensityRef.current.clearValue()
      }
      onUpdate(newValues)
    },
    [answer, onUpdate]
  )

  const selectedNoActivity = useMemo(
    () => activityAnswer.answer.value === NO_ACTIVITY_VALUE,
    [activityAnswer]
  )

  const { activityProps, durationProps, intensityProps } = useMemo(() => {
    const [activity, , intensity] = questions || []
    const activityProps = {
      placeholder: 'Activity Sessions',
      answer: activityAnswer,
      question: {
        answerKey: ActivityAnswerKeys.SESSION,
        options: activity.options
      },
      update: handleUpdateValue
    }
    const durationProps = {
      placeholder: 'Minutes',
      answer: durationAnswer,
      question: {
        answerKey: ActivityAnswerKeys.DURATION
      },
      disabled: selectedNoActivity,
      update: handleUpdateValue
    }
    const intensityProps = {
      placeholder: intensity.label,
      answer: intensityAnswer,
      question: {
        answerKey: ActivityAnswerKeys.INTENSITY,
        options: intensity.options
      },
      disabled: selectedNoActivity,
      update: handleUpdateValue
    }
    return { activityProps, durationProps, intensityProps }
  }, [
    activityAnswer,
    durationAnswer,
    handleUpdateValue,
    intensityAnswer,
    questions,
    selectedNoActivity
  ])

  return (
    <Row gutter={16}>
      <Col span={12}>
        <SelectQuestion {...activityProps} />
      </Col>
      <Col span={6} className="flex-col">
        <NumberEntry ref={numberEntryRef} {...durationProps} />
      </Col>
      <Col span={6}>
        <SelectQuestion ref={intensityRef} {...intensityProps} />
      </Col>
    </Row>
  )
}

export default function DayActivity({
  className,
  index,
  title,
  question,
  answer,
  onUpdateAnswer
}) {
  const { original_id, exercise_information } = question

  const handleUpdateValue = useCallback(
    (index, newValue) => {
      const newAnswer = [...answer]
      newAnswer[index] = {
        ...newAnswer[index],
        ...newValue
      }
      onUpdateAnswer({ [original_id]: newAnswer })
    },
    [answer, original_id, onUpdateAnswer]
  )

  const renderActivities = useMemo(() => {
    const activities = chunk(
      exercise_information,
      exercise_information.length / 2
    )
    return activities.map((item, index) => (
      <ActivityItem
        key={index}
        questions={item}
        answer={answer[index] || {}}
        onUpdate={(answer) => handleUpdateValue(index, answer)}
      />
    ))
  }, [answer, exercise_information, handleUpdateValue])

  return (
    <DayActivityContainer className={classNames(className)}>
      <div className="title-section">
        {index && <div className="serial-number">{index}</div>}
        {title && <div className="title">{title}</div>}
      </div>
      <div className="activity-section">{renderActivities}</div>
    </DayActivityContainer>
  )
}
