import React, { useState, useEffect } from 'react'
import { Button, Checkbox } from 'antd'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import ProgramPlan from '../../../components/membership/membership'
import BillingSelection from './billingSelection'
// import ProgramPlanV2 from '../../../components/membership/V2/membership'
// import ProgramPlanV1 from '../../../components/membership/v1/membership'
// import PageLoading from '../../../components/UI/status/loading'

import { loadUser, saveUser } from '../../../utils/storage'
// import { logOut as logOutAction } from '../../../api/people'
import { getMembershipFees, editClient } from '../../../api/team'

import { OnBoardingContainer, OnboardingHeader } from '../style'
import Successful from '../questionnaire/successful'

const description = (
  <>
    Thank you for joining Apeiron Life. We are excited <br /> to welcome you as
    a new member! <br /> <br />
    Your next step is to fill out our pre-assessment <br /> medical review. Once
    your medical clearance is completed, your Apeiron Life Concierge will <br />
    reach out to begin scheduling.
  </>
)

export default function MembershipSelection(props) {
  const { history } = props
  const user = loadUser()
  const [billingPreference, setBillingPreference] = useState('quarterly')
  const [type, setType] = useState(user.profile.membership)
  const [isChecked, setIsChecked] = useState(true)
  const [confirming, setConfirming] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [version, setVersion] = useState(3)

  const confirmAble =
    billingPreference &&
    ['evaluation2', 'gold', 'silver', 'bronze', 'remote', 'remote lite', 'insights'].find(
      (item) => item === type
    ) &&
    isChecked

  return (
    <OnBoardingContainer>
      <div className="main-container">
        <OnboardingHeader />
        {showSuccess ? (
          <Successful
            description={description}
            renderBtn={() => (
              <>
                <Button
                  style={{ width: 229 }}
                  size="large"
                  type="primary"
                  onClick={() => history.push('/onboarding/ready')}
                >
                  DO IT NOW
                </Button>
                {/* <br /> */}
                {/* <Button
                  style={{ width: 229, padding: 0, marginTop: 16 }}
                  size="large"
                  type="primary"
                  ghost
                  onClick={logOut}
                >
                  I WILL COME BACK LATER
                </Button> */}
              </>
            )}
          />
        ) : (
          <>
            <MemberShip
              handleMembership={handleMembership}
              handleBillingPreference={handleBillingPreference}
              user={user}
              setVersion={setVersion}
              version={version}
            />
            <div
              className={version === 3 ? 'footer-text' : ''}
              style={{ padding: version === 3 ? '32px 125px' : '32px 205px' }}
            >
              <Checkbox
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
                style={{ marginRight: 12 }}
              />
              By purchasing an Apeiron Life Membership, you agree to the Apeiron
              Life{' '}
              <Link
                to="/membership-agreement"
                target="_bank"
                style={{
                  textDecoration: 'underline'
                }}
              >
                Membership Agreement
              </Link>
              ,{' '}
              <a
                href="https://apeiron.life/terms-of-use/"
                target="_bank"
                style={{
                  textDecoration: 'underline'
                }}
              >
                Terms of Service{' '}
              </a>
              and{' '}
              <a
                href="https://apeiron.life/privacy-policy/"
                target="_bank"
                style={{
                  textDecoration: 'underline'
                }}
              >
                Privacy Policy{' '}
              </a>
            </div>
            <footer>
              <span className="back">{/* <LeftOutlined /> back */}</span>
              <Button
                type="primary"
                disabled={!confirmAble || confirming}
                className="btn-next"
                onClick={handleConfirm}
                // loading={updating || !!Object.keys(postQueue).length}
              >
                CONFIRM
              </Button>
            </footer>
          </>
        )}
      </div>
    </OnBoardingContainer>
  )

  async function handleConfirm() {
    const params = {
      profile: {
        membership: type,
        billing_preference: billingPreference
      }
    }
    setConfirming(true)
    await editClient(params, user.id, '?client_select_membership=true')
    _.assign(user.profile, params.profile)
    saveUser(user)
    setShowSuccess(true)
  }

  function handleMembership(value, isOldVersion) {
    if (isOldVersion && !billingPreference) {
      setBillingPreference('annually')
    }
    setType(value)
  }

  function handleBillingPreference(value) {
    setBillingPreference(value)
  }

  // function logOut() {
  //   clearStorage()
  //   logOutAction()
  //   history.push('/sign-in')
  // }
}

const Container = styled.div`
  padding: 60px 205px 80px;
  height: calc(100vh - 230px);
  overflow: auto;
  padding-bottom: 0;
  &.v3 {
    padding: 60px 120px 80px;
    height: auto;
  }
  h1 {
    font-size: 36px;
    line-height: 100%;
    letter-spacing: 0.3px;
    color: #29303e;
  }
  p {
    font-family: Heebo;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #5c626a;
    opacity: 0.8;
  }
`

function MemberShip(props) {
  const [price, setPrice] = useState(null)
  const user = props.user
  useEffect(() => {
    const getFees = async () => {
      const result = await getMembershipFees(user.id)
      setPrice(result)
      props.setVersion(result[0].version)
    }
    getFees()
  }, [])

  return (
    <MembershipV3
      price={price}
      current={user.profile.membership}
      handleMembership={props.handleMembership}
      handleBillingPreference={props.handleBillingPreference}
      user={user}
    />
  )
}

function MembershipV3(props) {
  const { handleMembership, handleBillingPreference, user, price } = props
  const [current, setCurrent] = useState(props.current)

  return (
    <Container className="v3">
      <h1>Membership Packages</h1>
      <p>
        Please select which program you would like to join. We highly recommend
        the Gold or Silver Membership in order to maximize the benefits of your
        membership, but if you would prefer to start with the assessment and add
        one of our annual membership packages as you learn more about the
        program, you can upgrade at any time. If you have any questions, please
        reach out to{' '}
        <a
          href="mailto:membership@apeiron.life"
          target="_bank"
          style={{
            color: '#0068f2',
            textDecoration: 'underline'
          }}
        >
          membership@apeiron.life
        </a>
        .
      </p>
      <BillingSelection {...{ handleBillingPreference, user, plan: current }} />
      <ProgramPlan
        selectable
        onChange={onChange}
        current={current}
        price={price}
      />
    </Container>
  )

  function onChange(plan) {
    handleMembership(plan.membership)
    setCurrent(plan.membership)
  }
}
