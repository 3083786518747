import React from 'react'

class MediaTypeSwitch extends React.Component {
  render() {
    return (
      <div className="media-switch">
        <span className="out-title">MEDIA TYPE</span>
        <div
          onClick={(e) => this.switch('article')}
          className={this.props.type === 'article' ? 'active' : ''}
        >
          Article
        </div>
        <i />
        <div
          onClick={(e) => this.switch('video')}
          className={this.props.type === 'video' ? 'active' : ''}
        >
          Video
        </div>
      </div>
    )
  }

  switch = (type) => {
    this.props.switch(type)
  }
}

export default MediaTypeSwitch
