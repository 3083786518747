import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Input, Row, Col, Checkbox } from 'antd'
import RichText from '../richText'
import Markdown from './items/markdown'
import InputArray from './items/inputArr'
import DatePickerItem from './items/datePicker'
import FileInput from './items/fileInput'
import SelectColor from './items/selectColor'
import InputNumber from './items/inputNumber'
import NormalSelect from './items/select'
import CustomizedSelect from './items/customizedSelect'
import SelectDaysOfWeek from './items/daysOfWeek'
import ComboSelect from './items/comboSelect'
import Radios from './items/radios'
import TimePickerItem from './items/timePicker'
import TreeSelectItem from './items/tree'
import MultiTagInput from './items/mutilTagInput'
import DateRange from './items/dateRange'
import FileUpload from './items/fileUpload'
import { phsForm } from './index.sass'
import _ from 'lodash'

class BasicForm extends Component {
  static propTypes = {
    onEnter: PropTypes.func,
    valueForm: PropTypes.object,
    formData: PropTypes.array,
    onChange: PropTypes.func
  }

  ref = React.createRef()

  handleSubmit = () => {
    return new Promise((resolve, reject) => {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          resolve(values)
        } else {
          reject(err)
        }
      })
    })
  }

  setFieldsValue = this.props.form.setFieldsValue
  getFieldsValue = this.props.form.getFieldsValue
  getFieldValue = this.props.form.getFieldValue
  // resetFields =

  reset = () => {
    this.props.form.resetFields()
    // this.setFieldsValue(this.state.valueForm)
  }

  enter = (event) => {
    if (
      event.keyCode === 13 &&
      event.target.nodeName.toLowerCase() === 'input'
    ) {
      const { onEnter } = this.props
      onEnter && onEnter()
    }
  }

  getItemValue = (item) => {
    const { valueForm } = this.props
    const dbFields = item.dbField.split('.')
    if (dbFields.length > 1) {
      let value = valueForm
      for (const key of dbFields) {
        if (!value) return null
        value = value[key]
      }
      return value
    }
    return valueForm && valueForm[item.dbField]
  }

  formItem = (data, value) => {
    const { type, inputType } = data
    const {
      form: { getFieldDecorator }
    } = this.props
    switch (type) {
      case 'input':
        return inputType === 'number' ? (
          <InputNumber
            disabled={data.disabled && !!value}
            prefix={data.prefix}
            suffix={data.suffix}
            maxLength={data.maxLength}
            placeholder={data.placeholder}
          />
        ) : (
          <Input
            type={inputType || 'text'}
            disabled={(data.disabled && !!value) || data.forceDisabled}
            prefix={data.prefix}
            suffix={data.suffix}
            addonBefore={data.addonBefore}
            maxLength={data.maxLength}
            placeholder={data.placeholder}
          />
        )
      case 'inputArray':
        return <InputArray eachColSpan={data.eachColSpan || 24} />
      case 'richText':
        return <RichText {...data.extraAttr} />
      case 'markdown':
        return <Markdown {...data.extraAttr} />
      case 'textarea':
        return <Input.TextArea {...data.extraAttr} />
      case 'select':
        return (
          <NormalSelect
            options={data.options}
            initialValue={data.defaultValue}
            disabled={!!data.disabled}
            placeholder={data.placeholder}
            extraAttr={data.extraAttr || {}}
          />
        )
      case 'customizedSelect':
        return (
          <CustomizedSelect
            options={data.options}
            initialValue={data.defaultValue}
            disabled={!!data.disabled}
            placeholder={data.placeholder}
            extraAttr={data.extraAttr || {}}
          />
        )
      case 'treeSelect':
        return (
          <TreeSelectItem
            treeData={data.treeData}
            // initialValue={data.defaultValue}
            // disabled={data.disabled}
            extraAttr={data.extraAttr || {}}
          />
        )
      case 'multiTag':
        return <MultiTagInput disabled={data.disabled} {...data.itemProps} />
      case 'date':
        return (
          <DatePickerItem
            format={data.format}
            disabledDate={data.disabledDate}
            disabled={data.disabled}
            extraAttr={data.extraAttr || {}}
          />
        )
      case 'dateRange':
        return <DateRange extraAttr={data.extraAttr || {}} />
      case 'time':
        return <TimePickerItem attrs={data.attrs} />
      case 'file':
        return (
          <FileInput
            id={data.id}
            multiple={data.multiple}
            extraAttr={data.extraAttr || {}}
          />
        )
      case 'selectColor':
        return <SelectColor />
      case 'daysOfWeek':
        return <SelectDaysOfWeek />
      case 'comboSelect':
        return (
          <ComboSelect
            include={data.include}
            {...data.source}
            getFieldDecorator={getFieldDecorator}
          />
        )
      case 'text':
        return <div style={{ textAlign: data.align }}>{data.text || value}</div>
      case 'checkbox':
        return (
          <Checkbox checked={_.isBoolean(value) ? value : data.defaultValue}>
            {data.text}
          </Checkbox>
        )
      case 'radios':
        return (
          <Radios
            options={data.options}
            initialValue={data.initialValue}
            buttonStyle={data.buttonStyle}
          />
        )
      case 'image':
        return <img src={data.image} alt="" style={data.style} />
      case 'upload':
        return <FileUpload extraAttr={data.extraAttr || {}} />
      default:
        return <div>{type}</div>
    }
  }

  renderFormItem(item) {
    const {
      form: { getFieldDecorator }
    } = this.props
    if (item.dbField) {
      const value = this.getItemValue(item)
      return getFieldDecorator(item.dbField, {
        rules: item.rules || [],
        initialValue: value,
        validateTrigger: item.trigger || 'onChange' //['onBlur', 'onChange']
      })(this.formItem(item, value))
    }
    return this.formItem(item)
  }

  isHide = (unless) => {
    let value
    let result = false
    const findIndex = (data, value) => {
      return data.value.findIndex((item) => item === value)
    }
    if (unless.and) {
      for (let item of unless.and) {
        value = this.getItemValue({ dbField: item.key })
        if (typeof item.value === 'string') {
          result = !!(value === item.value)
        } else {
          result = !!(findIndex(item, value) !== -1)
        }
        if (item.not) {
          result = !result
        }
        if (!result) {
          return false
        }
      }
      return true
    } else if (unless.or) {
      for (let item of unless.or) {
        value = this.getItemValue({ dbField: item.key })
        if (typeof item.value === 'string') {
          result = !!(value === item.value)
        } else {
          result = !!(findIndex(item, value) !== -1)
        }
        if (item.not) {
          result = !result
        }
        if (result) {
          break
        }
      }
      return result
    } else {
      value = this.getItemValue({ dbField: unless.key })
      if (typeof unless.value === 'string') {
        result = !!(value === unless.value)
      } else if (!unless.value) {
        return !!value
      } else {
        result = !!(unless.value.findIndex((item) => item === value) !== -1)
      }
      if (unless.not) {
        result = !result
      }
      return result
    }
  }

  render() {
    const { formData, justify, gutter } = this.props
    return (
      <Form onKeyUp={this.enter} className={phsForm}>
        <Row
          type="flex"
          justify={justify || 'space-between'}
          gutter={gutter || 16}
        >
          {formData.map((item, index) => {
            if (!item.hideField || (item.unless && this.isHide(item.unless))) {
              return (
                <Col
                  span={item.colSpan || 24}
                  key={index}
                  style={item.style || {}}
                >
                  <Form.Item
                    label={item.label || ''}
                    className={item.hideColon ? 'hide-label-colon' : ''}
                  >
                    {this.renderFormItem(item)}
                  </Form.Item>
                </Col>
              )
            } else if (item.include) {
              return this.renderFormItem(item)
            }
            return ''
          })}
        </Row>
      </Form>
    )
  }
}

export default Form.create({
  onFieldsChange(props, changedFields) {
    props.onChange && props.onChange(changedFields, props.form)
  }
})(BasicForm)
