import React from 'react'
import { Modal } from 'antd'
import { confirmPopupStyle } from './index.sass'

/* @props
    onOk
    onCancel
    type: email / app
    name:
    gender: female/male

*/

export default function ConfirmPopUp(props) {
  return (
    <Modal
      wrapClassName={confirmPopupStyle}
      visible={true}
      onOk={props.onOk}
      onCancel={props.onCancel}
      okText="PAUSE NOW"
      cancelText="CANCEL"
    >
      <h3 className="title">Please confirm before you pause</h3>
      <p>
        You're about to pause all {props.type} notifications for{' '}
        <strong>{props.name}.</strong>{' '}
        {props.gender === 'female' ? 'She' : 'He'} will not receive any{' '}
        {props.type} notifications from now on until you turn it back.
      </p>
    </Modal>
  )
}
