import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import {
  getClientList,
  getClientDetail,
  getHealthStatus
} from '../../../models/breadcrumb.model'
import HealthScoreContainer from '../../../components/healthScore'
import { loadUser } from '../../../utils/storage'
import { getRole } from '../../../utils/common'

class HealthScore extends Component {
  static propTypes = {}

  state = {}

  initail = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state

    if (!person) {
      const loginUser = loadUser()
      const loginRole = getRole(loginUser.role)
      person = (getPerson && (await getPerson())) || loginUser
      this.setState({ person, loginRole })
    }
    setClientPageTitle && setClientPageTitle('Health Compass')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getHealthStatus(person, true)
      ])
  }

  jumpToDataset = (type) => {
    const {
      history,
      match: { url }
    } = this.props
    const types = {
      bio_marker: 'blood-test',
      body_comp: 'body-comp',
      vitals: 'vitals',
      bone_density: 'bone-density',
      muscle_fitness: 'muscle-fitness',
      cognitive_health: 'cognitive-health',
      aerobic_fitness: 'aerobic-fitness',
      movement_quality: 'movement-quality',
      sport_specific: 'sport-specific',
      lifestyle_assessment: 'life-style',
      cardiorespiratory: 'cardiorespiratory',
      physical_assessment: 'physical'
    }

    const path = types[type]

    if (path) {
      history.push(`${url.split('detail/')[0]}detail/${path}`)
    }
  }

  componentDidMount() {
    this.initail()
  }

  render() {
    const { person, loginRole } = this.state
    // types = ['bio_marker','body_comp','lifestyle_assessment','cardiorespiratory','cognitive_health','physical_assessment']
    return person ? (
      <HealthScoreContainer
        person={person}
        jumpToDataset={this.jumpToDataset}
        routeTypes="bio_marker,body_comp,lifestyle_assessment,cardiorespiratory,cognitive_health,physical_assessment"
        mobile={false}
        loginRole={loginRole}
      />
    ) : (
      <></>
    )
  }
}

export default HealthScore
