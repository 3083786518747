import React from 'react'
import { connect } from 'react-redux'
import WeeklySummaryContainer from '../../../components/summaryEmailBackup/weeklySummary'
import { summaryEmailStyle } from './index.sass'

function SummaryEmailBackup(props) {
  const {
    match: { params },
    account
  } = props
  return (
    <div className={`backup ${summaryEmailStyle}`}>
      <WeeklySummaryContainer
        person={account.user}
        id={params.id}
        loginRole="User"
      />
    </div>
  )
}

export default connect(
  ({ account }) => ({ account }),
  null
)(SummaryEmailBackup)
