import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Input } from 'antd'
import { pattern } from '../../../utils/constant'

const { positiveNumber, positiveNumberWithOneDigits } = pattern

class NumberInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    formatter: PropTypes.func, // when got a formatter, display the formatted str
    unit: PropTypes.string,
    decimalDigits: PropTypes.number
  }

  state = {
    value: null,
    error: false,
    autoFocus: false
  }

  getRangesValues = () => {
    const { auto_range } = this.props
    let max, min, target
    if (auto_range) {
      const ranges = auto_range.split(',')
      if (ranges.length === 1) {
        target = Number(ranges[0])
      } else {
        min = ranges[0] && Number(ranges[0])
        max = ranges[1] && Number(ranges[1])
      }
    }

    return { max, min, target }
  }

  getColor = () => {
    const { auto_range } = this.props
    const { entry } = this.state
    const defaultColor = '#383E48' //'#C0C7D1'
    const green = '#7BAD2D'
    const red = '#E75F25'
    if (entry && auto_range) {
      return entry.achieved ? green : red
    } else {
      return defaultColor
    }
  }

  onKeyDown = async (event) => {
    const { error } = this.state
    const target = event.target
    if (event.keyCode === 13 && !error) {
      await this.props.saveEntry(event.target.value)
      target && target.blur()
    }
  }

  onChange = (event) => {
    const { decimalDigits } = this.props
    const value = event.target.value
    this.setState({ value })
    let valid
    if (decimalDigits === 1) {
      valid = positiveNumberWithOneDigits.test(value)
    } else {
      valid = positiveNumber.test(value)
    }
    if (value && !valid) {
      let less0 = false
      if (Number(value)) {
        less0 = true
      }
      this.setState({ error: true, less0 })
    } else {
      this.setState({ error: false, less0: false })
    }
  }

  enterEdit = () => {
    const { disabled } = this.props
    if (this.state.formattedValue && !disabled) {
      // delete formattedValue
      this.setState({ formattedValue: undefined, autoFocus: true })
    }
  }

  UNSAFE_componentWillMount() {
    this.copyPropToState(this.props)
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.copyPropToState(props)
  }

  copyPropToState = (props) => {
    const { value, entry, formatter, unit } = props
    const formattedValue = formatter ? formatter(value) : value
    let newValue
    if (unit === 'Time') {
      const time = moment(value * 1000)
      newValue = time.hour() + Math.round((time.minute() / 60) * 100) / 100
    }
    Object.assign(this.state, {
      value: newValue || value,
      formattedValue,
      entry
    })
  }

  onInputBlur = () => {
    const { value, unit, formatter } = this.props
    let newValue
    if (unit === 'Time') {
      const time = moment(value * 1000)
      newValue = time.hour() + Math.round((time.minute() / 60) * 100) / 100
    }
    this.setState({
      formattedValue: formatter ? formatter(value) : undefined,
      value: newValue || value,
      error: false,
      autoFocus: false
    })
  }

  render() {
    const { disabled } = this.props
    const { value, autoFocus, formattedValue, error, less0 } = this.state
    const color = this.getColor()
    return (
      <div className={`number-input ${error || less0 ? 'has-error' : ''}`}>
        {error && !less0 && <span>*Number only</span>}
        {error && less0 && <span>*Invalid number</span>}
        {this.props.formatter && formattedValue ? (
          <div
            className="number-input-formatter-con"
            style={{ color }}
            dangerouslySetInnerHTML={formattedValue}
            onClick={this.enterEdit}
          />
        ) : (
          <Input
            style={{ color }}
            placeholder="n/a"
            value={value}
            autoFocus={autoFocus}
            onChange={this.onChange}
            disabled={disabled}
            onBlur={this.onInputBlur}
            onKeyDown={this.onKeyDown}
          />
        )}
      </div>
    )
  }
}

export default NumberInput
