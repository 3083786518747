import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import SetUpScreen from './setup/screen'
import Ready from './setup/ready'
import Waiver from './waiver'
import MembershipAgreement from './waiver/membership'
import Liabilities from './waiver/liabilities'
// import Questionnaire from './questionnaire' // todo remove this component
import QuestionSections from './questionnaire/sections'
import QuestionnaireRecord from './questionnaire/record'
import Landing from './setup/landing'
import Payment from './payment'
import PersonalIntroductory from './introductory'
import Directive from './setup/directive'
import PreScreening from './preScreening'
import Successful from './successful'
import { loadUser, saveUser } from '../../utils/storage'
import { getPeopleById } from '../../api/people'

import { OnBoardingContainer } from './style'
import Medical from './waiver/medical'

class Onboarding extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const person = await this.getPerson()
    const {
      account: { user }
    } = this.props
    if (
      person.profile.updated_at !== user.profile.updated_at ||
      person.id !== user.id
    ) {
      saveUser(person)
      this.props.setLoginAccount(person)
    }
  }

  getPerson = async () => {
    let person = loadUser()
    if (!person) {
      const result = await getPeopleById('me')
      person = result.person
    }
    return person
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { match, setLoginAccount, logOut } = this.props
    return (
      <OnBoardingContainer>
        <Switch>
          <Route path={`${match.url}/ready`} component={Ready} />
          <Route
            path={`${match.url}/pre-screening`}
            render={(props) => (
              <PreScreening
                {...props}
                setLoginAccount={setLoginAccount}
                logOut={logOut}
              />
            )}
          />
          <Route
            path={`${match.url}/waiver/membership`}
            component={MembershipAgreement}
          />
          <Route
            path={`${match.url}/waiver/liabilities`}
            component={Liabilities}
          />
          <Route path={`${match.url}/waiver/medical`} component={Medical} />
          <Route path={`${match.url}/waiver`} component={Waiver} />
          <Route
            path={`${match.url}/questionnaire-record/:hash`}
            render={(props) => (
              <QuestionnaireRecord
                {...props}
                setLoginAccount={setLoginAccount}
                logOut={logOut}
              />
            )}
          />
          <Route
            path={`${match.url}/questionnaire/:section`}
            render={(props) => (
              <QuestionSections {...props} setLoginAccount={setLoginAccount} />
            )}
          />
          <Route
            path={`${match.url}/questionnaire`}
            render={(props) => <QuestionSections {...props} />}
          />
          <Route path={`${match.url}/payment`} component={Payment} />
          <Route
            path={`${match.url}/introductory`}
            render={(props) => (
              <PersonalIntroductory {...props} logOut={logOut} />
            )}
          />
          <Route path={`${match.url}/successful`} component={Successful} />
          <Route path={`${match.url}/landing`} component={Landing} />
          <Route
            path={`${match.url}/directive`}
            render={(props) => (
              <Directive getPerson={this.getPerson} {...props} />
            )}
          />
          <Route path={`${match.url}`} extra component={SetUpScreen} />
        </Switch>
      </OnBoardingContainer>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(Onboarding)
