import React, { useState } from 'react'
import styled from 'styled-components'
import Icon from '@ant-design/icons' // { UpOutlined }
import { Button, Row, Col, Divider } from 'antd'
import Chart from './chart'
import AddMetric from './addMetric'
import _ from 'lodash'
import { VsDiscardIcon } from '../icons/trendsIcons'
import { Growth } from './customTooltip'

const Container = styled.div`
  border-top: 1px solid #e4e7eb;
  &.open-detail {
    border-bottom: 1px solid #e4e7eb;
  }
  .main-container {
    display: flex;
    justify-content: space-between;
  }
  .main-container {
    display: flex;
  }
  .metric-info {
    position: relative;
    padding: 25px;
    max-width: 22%;
    .draggable-area {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .name {
      color: #616e7c;
      font-size: 14px;
      & > span {
        margin-right: 10px;
      }
    }
    .vs {
      color: #ced3d9;
      font-size: 14px;
    }
    .add {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .discard {
        margin-left: 8px;
        width: 30px;
        height: 30px;
        background: rgba(154, 165, 177, 0.2);
        border: none;
        border-radius: 6px;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line-chart {
    svg {
      overflow: visible;
      position: relative;
      z-index: 3;
    }
  }
  .detail-container {
    padding: 12px 0;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .metric-note {
      padding: 0 6px;
      color: #7b8794;
      font-size: 9px;
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-right: 8px;
      }
    }

    .ant-btn {
      border-radius: 6px;
      margin-left: 16px;
    }
  }
`

const DetailContainer = styled.div`
  background: #e4e7eb;
  box-shadow: inset 0px 7px 20px rgba(0, 0, 0, 0.05);
  padding: 33px;
  padding-top: 0;
  padding-bottom: 16px !important;
  .name {
    color: #616e7c;
    font-size: 14px;
  }
  .detail-container {
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    color: #9aa5b1;
    border-bottom: 1px solid #e4e7eb;
    &:nth-child(2) {
      border-radius: 0px 0px 6px 6px;
    }
    .metric-detail {
      width: 44%;
      padding-bottom: 4px;
      .ant-row {
        padding: 0px 24px;
        margin: 12px 0;
      }
      .detail-header {
        border-bottom: 1px solid #e4e7eb;
        padding: 12px 24px;
        margin: 0;
      }
      .value {
        display: inline-block;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 14px;
        color: #ffffff;
      }
    }
    .ant-divider {
      display: block;
      height: auto;
      margin: 0 20px;
    }

    .trends-summary {
      width: 54%;
      text-align: center;
      .name {
        padding: 12px 0;
        border-bottom: 1px solid #e4e7eb;
      }
      .ant-row-flex {
        min-height: 136px;
        padding: 28px 0;
        .ant-col {
          border-right: 1px solid #e4e7eb;
          span:first-child {
            margin-top: -11px;
            display: block;
          }
          &:last-child {
            border: none;
          }
        }
      }
      .value {
        display: block;
        margin-top: 12px;
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.02em;
      }
      .growth {
        margin: 0;
        position: relative;
        bottom: -15px;
      }
    }
  }
`

export default function MetricContainer(props) {
  const [showDetail] = useState(false) // setShowDetail
  const [notes, setNotes] = useState(null)
  const {
    showIndex,
    showNumber,
    showGraphType,
    metric,
    comparedMetric,
    dateRange,
    allMetrics,
    colorArr,
    onAdd,
    draggableProvided
  } = props

  const getDetail = (metric) => {
    const theme = getTheme(metric.categories)
    return (
      <div className="detail-container">
        <div className="metric-detail">
          <Row className="detail-header">
            <Col span={12}>
              <div className="name">Metric's Detail</div>
            </Col>
            <Col span={5}>
              <span>Previous</span>
            </Col>
            <Col span={7}>
              <span>Current</span>
            </Col>
          </Row>
          <Row>
            <Col span={12}>Avg.</Col>
            <Col span={5}>
              <span className="value" style={{ background: theme.primary }}>
                8.5hr
              </span>
            </Col>
            <Col span={7}>
              <span className="value" style={{ background: theme.primary }}>
                8.06hr
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12}>Max.</Col>
            <Col span={5}>
              <span className="value" style={{ background: theme.primary }}>
                8.5hr
              </span>
            </Col>
            <Col span={7}>
              <span className="value" style={{ background: theme.primary }}>
                8.06hr
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={12}>Min.</Col>
            <Col span={5}>
              <span className="value" style={{ background: theme.primary }}>
                8.5hr
              </span>
            </Col>
            <Col span={7}>
              <span className="value" style={{ background: theme.primary }}>
                8.06hr
              </span>
            </Col>
          </Row>
        </div>
        <Divider type="vertical" />
        <div className="trends-summary">
          <div className="name ">Trends Summary</div>
          <Row type="flex">
            <Col span={6}>
              <span>Current</span>
              <span className="value" style={{ color: theme.primary }}>
                240 hr
              </span>
            </Col>
            <Col span={6}>
              <span>1 month ago</span>
              <span className="value" style={{ color: theme.primary }}>
                240 hr
              </span>
              <span className="growth increase ">30%</span>
            </Col>
            <Col span={6}>
              <span>3 month ago</span>
              <span className="value" style={{ color: theme.primary }}>
                240 hr
              </span>
              <span className="growth reduce">30%</span>
            </Col>
            <Col span={6}>
              <span>1 year ago</span>
              <span className="value" style={{ color: theme.primary }}>
                240 hr
              </span>
              <span className="growth reduce">30%</span>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  return (
    <>
      <Container className={showDetail ? 'open-detail' : ''}>
        <div className="main-container">
          <div className="metric-info">
            <div
              className="draggable-area"
              {...draggableProvided.dragHandleProps}
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 14C4 15.1 3.1 16 2 16C0.9 16 0 15.1 0 14C0 12.9 0.9 12 2 12C3.1 12 4 12.9 4 14ZM2 6C0.9 6 0 6.9 0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6ZM2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0ZM8 4C9.1 4 10 3.1 10 2C10 0.9 9.1 0 8 0C6.9 0 6 0.9 6 2C6 3.1 6.9 4 8 4ZM8 6C6.9 6 6 6.9 6 8C6 9.1 6.9 10 8 10C9.1 10 10 9.1 10 8C10 6.9 9.1 6 8 6ZM8 12C6.9 12 6 12.9 6 14C6 15.1 6.9 16 8 16C9.1 16 10 15.1 10 14C10 12.9 9.1 12 8 12Z"
                  fill="#CBD2D9"
                />
              </svg>
            </div>

            <div>
              <div className="name">
                <span>
                  {metric.name} {metric.unit && ` (${metric.unit})`}
                </span>
                <Growth
                  ratio={_.get(metric, 'detail.overall_ratio')}
                  up_color={metric.up_color}
                />
              </div>
              {metric.add_metrics.length > 0 && (
                <>
                  <div className="vs">VS</div>
                  <div className="name">
                    <span>
                      {metric.add_metrics[0].name}{' '}
                      {metric.add_metrics[0].unit &&
                        ` (${metric.add_metrics[0].unit})`}
                    </span>
                    <Growth
                      ratio={_.get(
                        metric.add_metrics[0],
                        'detail.overall_ratio'
                      )}
                      up_color={metric.add_metrics[0].up_color}
                    />
                  </div>
                </>
              )}
              <div className="add">
                <AddMetric
                  allMetrics={allMetrics}
                  metric={metric}
                  onAdd={(addMetric) => {
                    metric.add_metrics = addMetric ? [addMetric] : []
                    onAdd(metric)
                  }}
                />
                {_.get(metric, ['add_metrics', 'length'], 0) > 0 && (
                  <Button
                    className="discard"
                    onClick={() => {
                      metric.add_metrics = []
                      onAdd(metric)
                    }}
                  >
                    <Icon component={VsDiscardIcon} />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="chart-container">
            <Chart
              {...{
                setNotes,
                showIndex,
                showNumber,
                showGraphType,
                getTheme,
                metric,
                comparedMetric,
                dateRange
              }}
            />
          </div>
        </div>
        <div className="detail-container">
          {notes &&
            notes.map((note, index) => (
              <div className="metric-note" key={index}>
                <span style={{ background: note.theme.primary }}></span>
                {note.name}
              </div>
            ))}
          {/* <Button onClick={() => setShowDetail(!showDetail)}>
            Details
            <UpOutlined rotate={showDetail ? 180 : 0} />
          </Button> */}
        </div>
      </Container>
      {showDetail && (
        <DetailContainer>
          {getDetail(metric)}
          {comparedMetric && getDetail(comparedMetric)}
        </DetailContainer>
      )}
    </>
  )

  function getTheme(categories) {
    if (categories[0] === 'Blood Metrics') {
      return colorArr.BloodPanel
    }
    return colorArr[categories[0]]
  }
}
