import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { deleteAttachment } from '../../../api/file'
import { Divider } from 'antd'
import TouchInput from '../../../components/business/creport/touchInput'
import ReportFiles from '../../../components/business/creport/files'
import KeyTakeaway from '../../../components/business/creport/keyTakeaway'
import imgaeICon from '../../../asserts/icon/drag-file-icon.svg'

class PhysicalTests extends Component {
  static propTypes = {
    report: PropTypes.object,
    updateReport: PropTypes.func,
    addAttachment: PropTypes.func,
    deleteAttachmentSync: PropTypes.func
  }

  state = {
    isDeleting: false
  }

  uploadFile = (file, progressCallback, source = null) => {
    const { addAttachment } = this.props
    addAttachment('report.physical_media_files', file, progressCallback, source)
  }

  deleteFile = async (id) => {
    const { deleteAttachmentSync, person } = this.props
    const { isDeleting } = this.state
    if (isDeleting) return
    this.setState({ isDeleting: true })
    try {
      await deleteAttachment(id, person.id)
      this.setState({ isDeleting: false })
      deleteAttachmentSync('physical_media_files', id)
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { report, updateReport } = this.props
    return (
      <div>
        <TouchInput
          initialValue={report && report.physical_intro}
          placeholder="click to Add introduction"
          save={(value) => updateReport({ physical_intro: value })}
        />
        <Divider className="full" />
        <h1 className="section-title">Summary</h1>
        <TouchInput
          initialValue={report && report.physical_summary}
          placeholder="click to Add introduction"
          save={(value) => updateReport({ physical_summary: value })}
        />
        <Divider className="full" />
        <h1 className="section-title">key takeaway</h1>
        <KeyTakeaway
          initialValue={report && report.physical_takeaway}
          update={(value) => updateReport({ physical_takeaway: value })}
        />
        <Divider className="full" />
        <ReportFiles
          uploadIcon={imgaeICon}
          save={(value) => updateReport({ physical_media_intro: value })}
          files={report.physical_media_files || []}
          introduction={report && report.physical_media_intro}
          upload={this.uploadFile}
          deleteFile={this.deleteFile}
        />
      </div>
    )
  }
}

export default PhysicalTests
