export const basic = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Unit',
    dbField: 'unit'
  },
  {
    type: 'input',
    label: 'Summary',
    dbField: 'summary'
  },
  {
    type: 'richText',
    label: 'Introduction',
    dbField: 'introduction'
  },
  {
    type: 'inputArray',
    label: 'Support links',
    dbField: 'support_links'
  }
]

export const range = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  // {
  //   type: 'input',
  //   label: 'Color',
  //   dbField: 'color'
  // },
  {
    colSpan: 5,
    label: 'Male Conventional'
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Min',
    dbField: 'male_conventional_min',
    colSpan: 8
    // rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Max',
    dbField: 'male_conventional_max',
    colSpan: 8
    // rules: [{ required: true, message: 'This field is required' }]
  },
  {
    colSpan: 5,
    label: 'Male Functional'
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Min',
    dbField: 'male_functional_min',
    colSpan: 8
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Max',
    dbField: 'male_functional_max',
    colSpan: 8
  },
  {
    colSpan: 5,
    label: 'Female Conventional'
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Min',
    dbField: 'female_conventional_min',
    colSpan: 8
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Max',
    dbField: 'female_conventional_max',
    colSpan: 8
  },
  {
    colSpan: 5,
    label: 'Female Functional'
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Min',
    dbField: 'female_functional_min',
    colSpan: 8
  },
  {
    type: 'input',
    inputType: 'number',
    label: 'Max',
    dbField: 'female_functional_max',
    colSpan: 8
  },
  {
    type: 'richText',
    label: 'Health effect',
    dbField: 'health_effect'
  },
  {
    type: 'inputArray',
    label: 'Health effect support links',
    dbField: 'health_effect_support_links'
  },
  {
    type: 'richText',
    label: 'Life suggestion',
    dbField: 'life_suggestion'
  },
  {
    type: 'inputArray',
    label: 'Life suggestion support links',
    dbField: 'life_suggestion_support_links'
  }
]

export const createCategoryForm = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'textarea',
    label: 'Description',
    dbField: 'description',
    rules: [{ required: true, message: 'This field is required' }]
  }
]

export const createBiomarker = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Unit',
    dbField: 'unit',
    rules: [{ required: true, message: 'This field is required' }]
  }
]

export const createMetric = [
  {
    type: 'input',
    label: 'Name',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  }
]
