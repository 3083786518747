import React from 'react'
import moment from 'moment'
import { PlusOutlined } from '@ant-design/icons'
import { pattern } from '../utils/constant'

export const targetGoals = [
  { label: 'Fixed', value: 'eq' },
  { label: 'Range', value: 'range', disabled: true },
  { label: 'Greater than or equal to', value: 'gte' },
  { label: 'Lesser than or equal to', value: 'lte'}
]

export const challengeModel = ({
  target_goal,
  dateDuplicate,
  metricOptions,
  metrics,
  isActive,
  groups,
  valueForm
}) => {
  let targetMetric = metrics.find((item) => item.metric === valueForm.metric)
  if (valueForm.is_group_metrics === 'yes') {
    targetMetric = metrics.find(
      (item) => item.group === (valueForm.metric || valueForm.metric_group)
    )
  }

  const targetType = targetMetric && targetMetric.target_type

  return [
    {
      label: 'TRACKING SOURCE',
      dbField: 'source',
      type: 'select',
      disabled: isActive,
      options: [
        { label: 'Auto', value: 'auto' },
        { label: 'Manual', value: 'manual' }
      ],
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'PILLAR',
      dbField: 'pillar',
      disabled: isActive,
      type: 'select',
      options: [
        { label: 'Exercise', value: 'exercise' },
        { label: 'Nutrition', value: 'nutrition' },
        { label: 'Rejuvenation', value: 'rejuvenation' }
      ],
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'METRIC',
      dbField: 'metric',
      disabled: isActive,
      type: 'select',
      defaultValue: 'step',
      options: metricOptions,
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: '',
      dbField: 'metrics',
      type: 'checkboxGroup',
      disabled: isActive,
      options: groups.map((group) => ({ label: group, value: group })),
      colSpan: 12,
      hideField: true,
      unless: {
        key: 'is_group_metrics',
        value: 'yes'
      },
      rules: [{ required: true, message: 'Please select at least 1 option' }]
    },
    {
      label: 'FREQUENCY TO GET DATA',
      dbField: 'frequency',
      disabled: isActive,
      type: 'select',
      defaultValue: 'daily',
      options: [{ label: 'Daily', value: 'daily' }],
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'TARGET' + (targetType ? `(${targetType})` : ''),
      type: 'select',
      dbField: 'target_comparison',
      disabled: isActive,
      options: targetGoals,
      colSpan: 12,
      rules: [{ required: true, message: 'Target goal must be selected' }],
      hideField: true,
      unless: {
        key: 'metric',
        value: 'Yes/No',
        not: true
      }
    },
    {
      type: 'number',
      label: 'Target' + (targetType ? `(${targetType})` : ''),
      dbField: 'target',
      disabled: isActive,
      colSpan: target_goal === 'range' ? 5 : 12,
      extraAttr: {
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value.replace(/\$\s?|(,*)/g, '')
      },
      rules: [
        // { required: true, message: 'This field is required' },
        {
          pattern: pattern.integer,
          message: 'Invalid number'
        }
      ],
      hideField: true,
      unless: {
        key: 'metric',
        value: 'Yes/No',
        not: true
      }
    },
    {
      type: 'input',
      dbField: 'target_name',
      label: 'target',
      disabled: isActive,
      colSpan: 24,
      // rules: [{ required: true, message: 'This field is required' }],
      hideField: true,
      unless: {
        key: 'metric',
        value: 'Yes/No'
      }
    },
    {
      type: 'text',
      hideField: true,
      unless: {
        key: 'target_goal',
        value: 'range'
      },
      text: '-',
      align: 'center',
      colSpan: 2
    },
    {
      type: 'number',
      dbField: 'target_upper',
      disabled: isActive,
      colSpan: 5,
      hideField: true,
      extraAttr: {
        formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        parser: (value) => value.replace(/\$\s?|(,*)/g, '')
      },
      unless: {
        key: 'target_goal',
        value: 'range'
      },
      rules: [
        // { required: true, message: 'This field is required' },
        {
          pattern: pattern.integer,
          message: 'Invalid number'
        }
      ]
    },
    {
      type: 'date',
      label: 'MONTH',
      colSpan: 12,
      dbField: 'month',
      disabled: isActive,
      format: 'MMMM',
      extraAttr: {
        picker: 'month',
        returnDate: true
      },
      extraValidate: dateDuplicate ? { validateStatus: 'error' } : null,
      disabledDate: (currentDate) => currentDate.isBefore(moment()),
      rules: [{ required: true, message: 'Please select date!' }]
    },
    {
      type: 'date',
      label: 'YEAR',
      colSpan: 12,
      dbField: 'year',
      disabled: isActive,
      format: 'YYYY',
      extraAttr: {
        picker: 'year',
        returnDate: true
      },
      extraValidate: dateDuplicate ? { validateStatus: 'error' } : null,
      disabledDate: (currentDate) => currentDate.isBefore(moment()),
      rules: [{ required: true, message: 'Please select date!' }]
    },
    {
      label: 'RANKING',
      dbField: 'ranking',
      type: 'select',
      disabled: isActive,
      // extraAttr: {
      //   disabled: true
      // },
      options: [
        {
          label: 'Cumulative feet',
          value: 'Cumulative feet'
        },
        {
          label: 'Cumulative time',
          value: 'Cumulative time'
        },
        {
          label: 'Longest hang time',
          value: 'Longest hang time'
        },
        {
          label: 'Shortest achieve time',
          value: 'Shortest achieve time'
        },
        {
          label: 'Most total days in the month',
          value: 'Most total days in the month'
        },
        {
          label: 'Cumulative time spent in HRZ',
          value: 'Cumulative time spent in HRZ'
        },
        {
          label: 'Cumulative time spent in Mindfulness',
          value: 'Cumulative time spent in Mindfulness'
        }
      ],
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'NAME(max characters:255)',
      dbField: 'name',
      type: 'input',
      rules: [
        { required: true, message: 'This field is required' },
        { max: 255, message: 'Name can not be over 255 characters' }
      ]
    },
    // {
    //   label: 'Target text displayed on iOS',
    //   type: 'input',
    //   dbField: 'target_shown'
    // },
    {
      label: 'DESCRIPTION(max characters:1000)',
      dbField: 'description',
      type: 'textarea',
      rules: [
        { max: 1000, message: 'Description can not be over 1000 characters' }
      ]
    },
    {
      label: 'Advisory & Benefits of Challenge(max characters:1000)',
      dbField: 'benefit',
      type: 'textarea',
      rules: [
        { required: true, message: 'This field is required' },
        {
          max: 1000,
          message:
            'Advisory & Benefits of Challenge can not be over 1000 characters'
        }
      ]
    },
    {
      label: 'ADDITIONAL INFORMATION (MAX CHARACTER: 27)',
      dbField: 'instruction_title_0',
      type: 'input',
      placeholder: 'Please enter a title',
      colSpan: 12,
      rules: [
        {
          max: 27,
          message: 'Cannot be longer than 27 characters'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_0')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    {
      dbField: 'instruction_0',
      type: 'input',
      placeholder: 'Please enter a web link',
      colSpan: 12,
      rules: [
        {
          pattern: pattern.URL,
          message: 'Invalid web link'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_title_0')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    {
      label: 'ADDITIONAL INFORMATION (MAX CHARACTER: 27)',
      dbField: 'instruction_title_1',
      type: 'input',
      placeholder: 'Please enter a title',
      colSpan: 12,
      rules: [
        {
          max: 27,
          message: 'Cannot be longer than 27 characters'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_1')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    {
      dbField: 'instruction_1',
      type: 'input',
      placeholder: 'Please enter a web link',
      colSpan: 12,
      rules: [
        {
          pattern: pattern.URL,
          message: 'Invalid web link'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_title_1')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    {
      label: 'ADDITIONAL INFORMATION (MAX CHARACTER: 27)',
      dbField: 'instruction_title_2',
      type: 'input',
      placeholder: 'Please enter a title',
      colSpan: 12,
      rules: [
        {
          max: 27,
          message: 'Cannot be longer than 27 characters'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_2')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    {
      dbField: 'instruction_2',
      type: 'input',
      placeholder: 'Please enter a web link',
      colSpan: 12,
      rules: [
        {
          pattern: pattern.URL,
          message: 'Invalid web link'
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value && getFieldValue('instruction_title_2')) {
              return Promise.reject(new Error('This field is required'))
            }
            return Promise.resolve()
          }
        })
      ]
    },
    // {
    //   label: 'Instruction description',
    //   dbField: 'instruction_description',
    //   type: 'textarea',
    //   rules: [
    //     {
    //       max: 1000,
    //       message: 'Instruction description can not be over 1000 characters'
    //     }
    //   ]
    // },
    {
      type: 'upload',
      label: 'BADGE IMAGE',
      dbField: 'badge_image',
      colSpan: 6,
      extraAttr: {
        placeholder: (
          <div
            style={{
              fontSize: '12px',
              color: '#3561C0',
              padding: '3px 11px 0px 11px',
              lineHeight: '40px'
            }}
          >
            <PlusOutlined />
            <span> Attach Files</span>
          </div>
        ),
        accept: 'image/jpeg,image/png,image/jpg,image/wepb',
        maxCount: 1,
        listType: 'picture'
      },
      rules: [{ required: true, message: 'Please select a file!' }]
    }
  ]
}
