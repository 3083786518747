import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import moment from 'moment'
import RangesAreas from '../../../components/business/dataset/ranges'
import PageLoading from '../../../components/UI/status/loading'
import { getAgeTag } from '../../../utils/common' //matchRangeByType
import { getBloodTestsHistory } from '../../../api/biomarkers'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'
import {
  getClientList,
  getClientDetail,
  getBloodTest,
  getClientBiomarker
} from '../../../models/breadcrumb.model'
import { colors } from '../../../utils/constant'
import { About, Lifestyle } from '../../../components/icons' // Heartbeat
import IconReturn from '../../../asserts/icon/icon-return.svg'
import Calendar from '../../../asserts/icon/icon-calendar.svg'
import TrendGraph from '../../../components/trendGraph'
import MobileTrendGraph from '../../../components/trendGraph/mobile'

class BiomarkerDetail extends Component {
  static propTypes = {
    clientBiomarker: PropTypes.object,
    isPublicLink: PropTypes.bool,
    selectBiomarker: PropTypes.object,
    person: PropTypes.object,
    getPerson: PropTypes.func,
    getMetaData: PropTypes.func,
    isMobile: PropTypes.bool
  }

  state = {
    showConv: true,
    showFunc: true,
    show: 'about'
  }

  initial = async (biomarker, person) => {
    const {
      getPerson,
      setClientBreadcrumb,
      getMetaData,
      match,
      isPublicLink,
      history,
      setClientPageTitle,
      hash
    } = this.props
    let value,
      showConv,
      showFunc,
      status = 'closed',
      testDate
    if (!isPublicLink) {
      const { id, datasetId } = match.params
      const result = await getMetaData(id, datasetId)
      biomarker = result.biomarker
      status = result.status
      testDate = result.testDate
      const personalData = biomarker && biomarker.personal_data
      showConv = personalData && personalData.prefer_conventional
      showFunc = personalData && personalData.prefer_functional
      setClientPageTitle(
        <div>
          {' '}
          <img
            src={IconReturn}
            alt="return "
            onClick={() => history.go(-1)}
          />{' '}
          {biomarker.name}
        </div>
      )
    }
    if (!person) {
      person = await getPerson()
    }
    setClientBreadcrumb([
      getClientList(),
      getClientDetail(person),
      getBloodTest(person.id),
      getClientBiomarker(person.id, biomarker)
    ])

    const res = await getBloodTestsHistory(biomarker.id, person.id, hash)
    let histories = res.history
    if (histories) {
      histories = histories.sort((a, b) => {
        return moment(a.test_date) - moment(b.test_date)
      })
    }
    if (biomarker && !biomarker.personal_data) {
      biomarker.personal_data = {}
    }
    const ageScopes = (await getRangeAgeScopes()).age_scope
    const ageTag = getAgeTag(person.profile, ageScopes)
    biomarker.conventional_ranges = biomarker.conventional_ranges.map(
      (range) => {
        const scope = range[`${person.profile.gender}_age_scope`][ageTag]
        const [min, max, color] = scope || []
        range.min = min
        range.max = max
        range.color = color
        return range
      }
    )
    biomarker.functional_ranges = biomarker.functional_ranges.map((range) => {
      const scope = range[`${person.profile.gender}_age_scope`][ageTag]
      const [min, max, color] = scope || []
      range.min = min
      range.max = max
      range.color = color
      return range
    })
    this.setState({
      html: biomarker.introduction,
      // range,
      ageScopes,
      personal_data: biomarker.personal_data,
      biomarker,
      person,
      loading: false,
      histories,
      status,
      testDate,
      showConv,
      showFunc
    })
    value = biomarker.personal_data && biomarker.personal_data.value
    if (value || value === 0) {
      this.updateMatchRange(biomarker.personal_data)
    }
  }

  updateMatchRange = async (personalBioMarkerObj, refresh) => {
    const { biomarker, person } = this.state
    personalBioMarkerObj = personalBioMarkerObj || biomarker.personal_data
    let functionalMatchRange, conventionalMatchRange, optimalRange

    conventionalMatchRange =
      personalBioMarkerObj.conventional_output_range || {}
    optimalRange = personalBioMarkerObj.conventional_optimal_range || []
    conventionalMatchRange.min = optimalRange[0]
    conventionalMatchRange.max = optimalRange[1]

    functionalMatchRange = personalBioMarkerObj.functional_output_range || {}
    optimalRange = personalBioMarkerObj.functional_optimal_range || []
    functionalMatchRange.min = optimalRange[0]
    functionalMatchRange.max = optimalRange[1]

    this.setState({ functionalMatchRange, conventionalMatchRange })

    if (refresh) {
      const res = await getBloodTestsHistory(
        biomarker.id,
        person.id,
        this.props.hash
      )
      let histories = res.history
      if (histories) {
        histories = histories.sort((a, b) => {
          return moment(a.test_date) - moment(b.test_date)
        })
      }
      this.setState({ histories })
    }
  }

  changePrefer = (state) => {
    this.setState(state)
    const type = Object.keys(state)[0]
    const {
      show,
      showConv,
      showFunc,
      biomarker,
      functionalMatchRange,
      conventionalMatchRange
    } = this.state
    let matchRange,
      html = ''
    if (
      (showConv && type !== 'showConv') ||
      (type === 'showConv' && state[type] === true)
    ) {
      matchRange = biomarker.conventional_ranges.find(
        (item) => item.name === conventionalMatchRange.name
      )
    } else if (showFunc || (type === 'showFunc' && state[type] === true)) {
      matchRange = biomarker.functional_ranges.find(
        (item) => item.name === functionalMatchRange.name
      )
      // matchRange = functionalMatchRange
    }
    if (matchRange) {
      if (show === 'health') {
        html = matchRange.health_effect
      } else if (show === 'life') {
        html = matchRange.life_suggestion
      }
    }
    show !== 'about' && this.setState({ html })
  }

  componentDidMount() {
    const { selectBiomarker, person, isPublicLink } = this.props
    this.setState({ loading: true })
    let biomarker
    if (isPublicLink) {
      biomarker = selectBiomarker
    }
    this.initial(biomarker, person)
  }

  renderRangeContainter() {
    const { isPublicLink, match } = this.props
    const {
      showConv,
      showFunc,
      biomarker,
      person,
      functionalMatchRange,
      conventionalMatchRange,
      status,
      personal_data,
      testDate
    } = this.state
    // const personalData = biomarker && biomarker.personal_data // comment on sprint 23
    const childProps = {
      functionalMatchRange,
      conventionalMatchRange,
      // value: personal_data && personal_data.value,
      showConv: true,
      // isPublicLink
      //   ? personalData && personalData.prefer_conventional
      //   : showConv,
      // comment on sprint 23
      showFunc: false,
      // isPublicLink
      //   ? personalData && personalData.prefer_functional
      //   : showFunc,
      // comment on sprint 23
      biomarker,
      person,
      isPublicLink,
      status,
      updateMatchRange: this.updateMatchRange,
      datasetId: match && match.params.datasetId,
      biomarkerId: match && match.params.id
    }
    return (
      <div className="ranges-info">
        <div className="header desktop-show">
          <h1 className="result">
            Result
            <br />
            <span className="date">
              <img src={Calendar} alt="calendar" />
              {biomarker && biomarker.personal_data
                ? moment(testDate).format('MM/DD/YYYY')
                : '-/-/-'}
            </span>
          </h1>
          {/* comment on sprint 23 */}
          {/* <div className="show-options">
            {!isPublicLink && personalData && (
              <>
                <Checkbox
                  checked={showConv}
                  onChange={e =>
                    this.changePrefer({ showConv: e.target.checked })
                  }
                >
                  Conventional
                </Checkbox>
                <Checkbox
                  checked={showFunc}
                  onChange={e =>
                    this.changePrefer({ showFunc: e.target.checked })
                  }
                >
                  Functional
                </Checkbox>
              </>
            )}
          </div> */}
        </div>
        <div className="ranges-area-container">
          {biomarker && !biomarker.options ? (
            <RangesAreas
              {...childProps}
              updateRangeEffects={(range, personalBiomarker) => {
                if (personalBiomarker) {
                  biomarker.personal_data = personalBiomarker
                }
                this.setState({ biomarker })
              }}
            />
          ) : (
            <>
              {(showConv || childProps.showConv) && (
                <div className="options-container">
                  <div>
                    <span className="label">CONVENTIONAL</span>
                    <br />
                    {(conventionalMatchRange && conventionalMatchRange.name) ||
                      'N/A'}
                  </div>
                  <div
                    className="value"
                    style={{
                      color:
                        (conventionalMatchRange &&
                          colors.find(
                            (item) => item.key === conventionalMatchRange.color
                          ).value) ||
                        'rgb(76, 96, 114)'
                    }}
                  >
                    <span className="label">your value</span>
                    <br />
                    {personal_data &&
                    (personal_data.value || personal_data.value === 0)
                      ? personal_data.value
                      : 'N/A'}
                  </div>
                </div>
              )}
              {(showFunc || childProps.showFunc) && (
                <div className="options-container">
                  <div>
                    <span className="label">FUNCTIONAL</span>
                    <br />
                    {(functionalMatchRange && functionalMatchRange.name) ||
                      'N/A'}
                  </div>
                  <div
                    className="value"
                    style={{
                      color:
                        (functionalMatchRange &&
                          colors.find(
                            (item) => item.key === functionalMatchRange.color
                          ).value) ||
                        'rgb(76, 96, 114)'
                    }}
                  >
                    <span className="label">your value</span>
                    <br />
                    {(personal_data && personal_data.value) || 'N/A'}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    )
  }

  renderBiomarkerInfo() {
    const {
      biomarker,
      // functionalMatchRange,
      conventionalMatchRange,
      html,
      show
    } = this.state
    let range = null
    range =
      biomarker &&
      conventionalMatchRange &&
      biomarker.conventional_ranges.find(
        (item) => item.name === conventionalMatchRange.name
      )

    /** comment on sprint 23 hide functional **/
    // if (biomarker) {
    //   const {
    //     personal_data: { prefer_functional }
    //   } = biomarker
    //   if (prefer_functional) {
    //     range = functionalMatchRange
    //   }
    // }
    return (
      <div className="biomarker-info">
        <div className="tabs">
          <ul>
            <li
              className={show === 'about' ? 'active' : ''}
              onClick={() =>
                this.setState({ html: biomarker.introduction, show: 'about' })
              }
            >
              <About color={show === 'about' ? '#264382' : '#B2B2B2'} />
              <br />
              About
            </li>
            {/* <li
              className={show === 'health' ? 'active' : ''}
              onClick={() =>
                this.setState({
                  html: range && range.health_effect,
                  show: 'health'
                })
              }
            >
              <Heartbeat color={show === 'health' ? '#264382' : '#B2B2B2'} />
              <br />
              Health Effects
            </li> */}
            <li
              className={show === 'life' ? 'active' : ''}
              onClick={() =>
                this.setState({
                  html: range && range.life_suggestion,
                  show: 'life'
                })
              }
            >
              <Lifestyle color={show === 'life' ? '#264382' : '#B2B2B2'} />
              <br />
              Lifestyle Suggestions
            </li>
          </ul>
        </div>
        <div className="information-container">
          <div
            className="information"
            dangerouslySetInnerHTML={{
              __html: html && html.replace(/style="(.*?)"/g, '')
            }}
          />
        </div>
      </div>
    )
  }

  render() {
    const { isPublicLink, isMobile } = this.props
    let {
      loading,
      showConv,
      showFunc,
      person,
      biomarker,
      histories
    } = this.state
    const personalData = biomarker && biomarker.personal_data
    showConv = isPublicLink
      ? personalData && personalData.prefer_conventional
      : showConv
    showFunc = isPublicLink
      ? personalData && personalData.prefer_functional
      : showFunc
    return loading ? (
      <PageLoading />
    ) : (
      <>
        <div className="client-biomarker-container">
          {this.renderRangeContainter()}
          {(showConv || showFunc) &&
            isMobile &&
            biomarker &&
            !biomarker.options && (
              <div className="trend-graph mobile-show">
                <MobileTrendGraph
                  person={person}
                  biomarker={biomarker}
                  histories={histories}
                  {...{ showConv, showFunc }}
                />
              </div>
            )}
          {this.renderBiomarkerInfo()}
        </div>
        {//(showConv || showFunc) && // comment on sprint 23
        !isMobile && biomarker && !biomarker.options && (
          <>
            <div className="trend-graph desktop-show">
              <h1>Trend Graph</h1>
              <TrendGraph
                person={person}
                biomarker={biomarker}
                histories={histories}
                {...{ showConv: true, showFunc: false }} // comment on sprint 23
              />
            </div>
          </>
        )}
      </>
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(BiomarkerDetail)
