import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import PHSHeader from '../../components/UI/header'
import ClientList from './list'
import ClientDetail from './detail/index'
import NoMatch from '../noMatch'
// import Goals from './detail/goals'
import { clientStyle } from './index.sass'

export class ClientView extends Component {
  static propTypes = {}

  render() {
    const {
      match,
      client,
      setClientBreadcrumb,
      setClient,
      history,
      location
    } = this.props
    return (
      <div className={clientStyle}>
        <PHSHeader
          breadcrumb={client.breadcrumb}
          hideBreadCrumb={Boolean(location.pathname.match(/goals/))}
          hideSubHeader={true}
          history={history}
          selectedClient={
            Boolean(location.pathname.match(/\/client\/\d+/)) && client
              ? client
              : null
          }
        />

        <div className="client-content">
          <Switch>
            <Route
              exact
              path={`${match.url}`}
              render={(props) => (
                <ClientList
                  {...props}
                  setClientBreadcrumb={setClientBreadcrumb}
                  setClient={setClient}
                />
              )}
            />
            <Route path={`${match.url}/:id/detail`} component={ClientDetail} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(ClientView)
