import React from 'react'
import { Modal, Button } from 'antd'
import { ModalStyled } from './commonStyled'

const { Title, Description, Footer } = ModalStyled

export default function Confirm(props) {
  return (
    <Modal
      open={true}
      footer={null}
      bodyStyle={{ padding: '36px 45px' }}
      zIndex={props.zIndex}
      onCancel={props.onCancel}
      width={props.modalWidth}
    >
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
      {props.children}
      <Footer>
        {props.footer ? (
          props.footer
        ) : (
          <>
            <Button ghost onClick={props.onCancel}>
              {props.cancelText || 'CANCEL'}
            </Button>
            <Button
              type="primary"
              style={props.confirmStyle || {}}
              onClick={props.onConfirm}
              disabled={props.loading} //props.waitingConfirm}
              loading={props.loading}
            >
              {props.confirmText || 'CONFIRM'}
            </Button>
          </>
        )}
      </Footer>
    </Modal>
  )
}
