import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import BasicForm from '../../BasicForm'

class SubjectEditor extends Component {
  static propTypes = {
    value: PropTypes.string,
    updateValue: PropTypes.func,
    updateEditingCount: PropTypes.func
  }

  state = {
    isChanged: false,
    value: this.props.value
  }

  save = () => {
    const { value } = this.state
    this.setState({ isChanged: false })
    this.props.updateValue(value, 'save')
  }

  cancel = () => {
    this.setState({ value: this.props.value })
    this.basicFormRef.setFieldsValue({ value: this.props.value })
    this.props.updateValue('')
  }

  updateValueForm = async (changedFields) => {
    let fieldName = Object.keys(changedFields)[0]
    let field = changedFields[fieldName]
    const value = field.value
    this.setState({ value })
    if (!this.state.isChanged) {
      this.setState({ isChanged: true })
      this.props.updateValue(value)
    } else if (value === this.props.value) {
      this.setState({ isChanged: false })
      this.props.updateValue('')
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ value: value })
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value ||
      (this.props.disabled && this.state.isChanged)
    ) {
      this.setState({ value: this.props.value, isChanged: false })
    }
  }

  render() {
    return (
      <div
        className="subject-container"
        style={{ paddingTop: 32, paddingBottom: 0 }}
      >
        <BasicForm
          wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          formData={[
            {
              type: 'input',
              label: 'Email Subject',
              dbField: 'value',
              disabled: this.props.disabled,
              rules: [{ required: true, message: 'This field is required' }],
              suffix:
                this.state.value !== this.props.value ? (
                  <>
                    <Button type="primary" ghost onClick={this.cancel}>
                      CANCEL
                    </Button>
                    <Button
                      type="primary"
                      onClick={this.save}
                      disabled={!this.state.value}
                    >
                      SAVE
                    </Button>
                  </>
                ) : null
            }
          ]}
          valueForm={{
            value: this.props.value
          }}
          onChange={this.updateValueForm}
        />
      </div>
    )
  }
}

export default SubjectEditor
