import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { InputNumber } from 'antd'
import _ from 'lodash'

const NumberEntry = (props, ref) => {
  const { answer, question, update, ...args } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (answer && !value) {
      setValue(answer.answer.value)
    }
  }, [answer])

  useImperativeHandle(ref, () => {
    return {
      clearValue: () => {
        setValue(null)
      }
    }
  })

  return (
    <>
      <InputNumber
        className={`q-form-item ${answer && answer.verify ? 'invalid' : ''}`}
        onChange={(value) => {
          setValue(value)
          update(_.assignIn(answer, { answer: { value } }), question)
        }}
        value={value}
        precision={1}
        min={1}
        formatter={(value) => (Number(value) && Math.round(value)) || value}
        {...args}
      />
      {answer && answer.verify && (
        <p className="invalid">Please type number here</p>
      )}
    </>
  )
}

export default forwardRef(NumberEntry)
