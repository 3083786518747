import styled from 'styled-components'

// 0-25 "Needs Attention"
// 26-40 "Below Average"
// 41-59 "Average"
// 60-74"Very Good"
// 75-89 "Excellent"
// 90-100 "Exceptional"
const statusColor = {
  NONE: '#e75f25',
  'Needs Attention': '#ff6b00',
  'Below Average': 'sandybrown',
  Average: 'gold',
  'Very Good': 'rgb(123, 173, 45)',
  Excellent: '#40c47c',
  Exceptional: 'rgb(134, 194, 216)'
}
const CardContainer = styled.div`
  .health-card {
    margin-bottom: 24px;
    border: 1px solid #e3e6ea;
    border-radius: 4px;
    border-top-width: 4px;
    color: #70808e;
    font-family: Gilroy;
    letter-spacing: 0.3px;
    height: calc(100% - 24px);
    border-top-color: ${({ status }) => statusColor[status]};

    &:hover:not(.editing):not(.disEdit):not(.User):not(.Service_Provider) {
      header,
      footer,
      .description,
      .ant-divider {
        opacity: 0.5;
      }

      .hover {
        display: block;
      }
    }

    &.NONE {
      background: rgba(255, 107, 0, 0.05);
      border-color: ${({ status }) => statusColor[status]};

      .description {
        color: ${({ status }) => statusColor[status]};

        ul {
          padding-left: 20px;
          text-decoration: underline;
          line-height: 24px;
        }
      }

      &.User,
      &.Service_Provider {
        border-color: #e3e6ea;
        background: #ffffff;

        .description {
          color: inherit;
        }
      }
    }

    .ant-card-body {
      padding: 16px 24px;
      padding-bottom: 16px;
      height: 100%;

      .ant-card-meta,
      .ant-card-meta-detail,
      .ant-card-meta-description,
      .meta-content {
        height: 100%;
        width: 100%;
      }
      .ant-card-meta-description {
        width: auto;
      }
    }

    &.editing {
      .meta-content {
        height: calc(100% - 30px);
      }
    }

    .hover {
      display: none;

      img {
        position: absolute;
        right: 15px;
        bottom: 10px;
        cursor: pointer;
      }
    }

    .meta-content {
      position: relative;
      padding-bottom: 10px;

      .ant-input {
        padding: 4px 5px;
        border: none;
        border-radius: 4px;
        width: 60px;
        height: 36px;
        text-align: right;
        color: #7fb800;
        font-size: 24px;
        font-family: 'Gilroy-bold';
        background-color: #edf0f4;

        &:first-child {
          margin-right: 8px;
        }

        &:last-child {
          color: #0d2350;
        }
      }
    }

    header {
      display: flex;
      justify-content: space-between;

      .name {
        font-size: 18px;
        color: #4c6072;
        text-transform: capitalize;
        line-height: 150%;
      }

      .status {
        font-size: 12px;
        font-family: 'Gilroy-bold';
        margin-top: 7px;
        color: ${({ status }) => statusColor[status]};
        text-transform: uppercase;
        &.NONE {
          color: #94a0aa;
        }
      }

      .score {
        font-family: 'Gilroy-bold';
        font-size: 24px;
        line-height: 32px;
        color: #7fb800;
        text-align: right;
        white-space: nowrap;

        .tip-input {
          border: 1px solid #e75f25;
        }

        .tip-text {
          float: right;
          font-size: 12px;
          color: #e75f25;
          height: 25px;
        }
      }

      .split {
        opacity: 0.1;
      }

      .compare-data {
        color: #0d2350;
      }

      .change {
        font-size: 14px;
        display: block;
      }
    }

    .ant-divider-horizontal {
      margin: 20px 0;
    }

    .description {
      font-size: 15px;
      line-height: 19px;
      min-height: 100px;
    }

    footer {
      display: flex;
      justify-content: space-between;
      padding-bottom: 5px;
      transform: translateY(10px);
      width: 100%;
      font-size: 12px;
      color: #97a0a7;
      letter-spacing: 0.3px;
    }
  }

  .ant-card-meta-detail {
    overflow: visible;
  }

  .ant-card-meta-description {
    margin: 0 -24px;
    padding: 0 24px;

    .actions {
      margin: 0 -24px;
      padding: 13px 12px;
      padding-bottom: 0;
      border-top: 1px solid #e8ecf0;
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      font-family: 'Gilroy-bold';
      text-transform: uppercase;
      letter-spacing: 0.3px;
      color: #264382;

      .btn {
        cursor: pointer;
        padding: 0 12px;

        &.disabled {
          color: #97a0a7;
          cursor: not-allowed;
        }
      }
    }
  }
`

export { CardContainer }
