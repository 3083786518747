import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Button, Divider, Row, Col, Progress } from 'antd' //Progress
import { CollapsePlusIcon } from '../../components/icons'
import { Start, Hourglass } from '../../components/icons/program'
import ProgramItem from './comp/programItem'
import ProgramEditor from './comp/programEditor'
import CreateProgram from './comp/programCreater'
import PageLoading from '../UI/status/loading'
import Empty from '../UI/status/empty'
import { getAllProgram } from '../../api/program'
import { getThisWeekRange } from '../../utils/dateHandles'
import { getRole, Pluralize } from '../../utils/common'
import { programStyle } from './style/index.sass'
import EmptyImage from '../../asserts/images/empty-status.png'

class ProgramList extends Component {
  static propTypes = {
    person: PropTypes.object,
    position: PropTypes.string
  }

  state = {
    showEditor: false,
    showCreator: false,
    programs: [],
    summary: null,
    pageLoading: true
  }

  linkToDetail = (id) => {
    const { match, history } = this.props
    const program = this.state.programs.find((item) => item.id === id)
    const { from, to } = program
    history.push(`${match.url}/${id}?from=${from}&to=${to}`)
  }

  initial = async () => {
    const { person } = this.props
    const { startDate, endDate } = getThisWeekRange()
    const result = await getAllProgram(
      person.id,
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD')
    )
    this.setState({ ...result, pageLoading: false })
  }

  componentDidMount() {
    this.initial()
  }

  addProgramCallback = async () => {
    this.initial()
    this.setState({ showEditor: false })
  }

  render() {
    const {
      showEditor,
      showCreator,
      pageLoading,
      programs,
      summary,
      forkProgram
    } = this.state //summary
    const activePrograms = programs.find((item) => item.status === 1)
    const inActivePrograms = programs.filter((item) => item.status === 0)
    const {
      achievement: { duration, session },
      pillar_item_count
    } = summary || { achievement: { duration: {}, session: {} } }
    const loginRole = getRole()
    const isClientView =
      this.props.position === 'clientView' || loginRole === 'Service_Provider'
    return (
      <div className={programStyle}>
        <div className="top-opr" style={{ marginTop: isClientView ? 0 : -70 }}>
          {!isClientView && (
            <Button
              type="primary"
              onClick={() => this.setState({ showCreator: true })}
            >
              <CollapsePlusIcon />
              ADD PROGRAM
            </Button>
          )}
          <Divider />
        </div>
        {pageLoading ? (
          <PageLoading />
        ) : programs.length === 0 ? (
          <Empty message="There is no program yet." emptyImage={EmptyImage} />
        ) : (
          <>
            {activePrograms && (
              <div className="section">
                <div className="program-title">Active program</div>
                <div>
                  <ProgramItem
                    status="active"
                    position={isClientView ? 'clientView' : ''}
                    pillarItemCount={pillar_item_count}
                    {...activePrograms}
                    onClickHandle={() => this.linkToDetail(activePrograms.id)}
                    extraElem={
                      summary && (
                        <>
                          <div className="pipe-chart">
                            <Divider type="vertical" />
                            <Progress
                              type="circle"
                              percent={
                                (session.completed / session.target) * 100
                              }
                              width={100}
                              format={() => <Start fill="#0D2350" />}
                            />
                            <div className="description">
                              <div className="progress">
                                {session.completed} of {session.target}
                              </div>
                              <div className="text">
                                Total sessions completed
                              </div>
                            </div>
                          </div>
                          <div className="pipe-chart">
                            <Divider type="vertical" />
                            <Progress
                              type="circle"
                              percent={
                                (duration.completed / duration.target) * 100
                              }
                              strokeColor="#0D2350"
                              width={100}
                              format={() => <Hourglass fill="#0D2350" />}
                            />
                            <div className="description">
                              <div className="progress">
                                {/* {duration.completed} of {duration.target} */}
                                {Math.round(duration.completed / 60)} of{' '}
                                {Math.round(duration.target / 60)}
                              </div>
                              <div className="text">Minutes completed</div>
                            </div>
                          </div>
                        </>
                      )
                    }
                  />
                </div>
              </div>
            )}
            {inActivePrograms.length > 0 && (
              <div className="section">
                <div className="program-title">
                  Inactive {Pluralize('programs', inActivePrograms.length)}
                </div>
                <Row gutter={33}>
                  {inActivePrograms.map((item, index) => (
                    <Col span={8} key={index}>
                      <ProgramItem
                        {...item}
                        onClickHandle={() => this.linkToDetail(item.id)}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </>
        )}

        {showCreator && (
          <CreateProgram
            programs={programs}
            handleOk={(forkProgram) => {
              const targetProgram = forkProgram && _.cloneDeep(forkProgram)
              if (targetProgram) {
                delete targetProgram.from
                delete targetProgram.to
              }
              this.setState({
                forkProgram: targetProgram,
                showCreator: false,
                showEditor: true
              })
            }}
            handleCancel={() => this.setState({ showCreator: false })}
          />
        )}
        {showEditor && (
          <ProgramEditor
            cancel={() => this.setState({ showEditor: false })}
            personId={this.props.person.id}
            confirmCallback={this.addProgramCallback}
            forkProgram={forkProgram}
          />
        )}
      </div>
    )
  }
}

export default ProgramList
