import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LeftOutlined } from '@ant-design/icons'
import { Layout, Menu, Button } from 'antd'
import { getCategories } from '../../../api/categories'
import '../layout.sass'

const { Header, Sider, Content } = Layout

export class BiomarkerLayout extends Component {
  static propTypes = {
    categories: PropTypes.array,
    biomarker: PropTypes.object,
    categoryIdField: PropTypes.string,
    id: PropTypes.string,
    // metricsField: PropTypes.string,
    turnTo: PropTypes.func,
    goBack: PropTypes.func
  }

  state = {
    collapsed: false,
    // categories: [],
    panelType: 'edit'
    // bioMarker: null
  }

  componentDidMount() {
    getCategories().then((res) => this.setState({ categories: res }))
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  getDefaultOpenKeys = (category_id) => {
    const { categories } = this.props
    const result = (category_id && [`sub_category_${category_id}`]) || []
    if (categories) {
      let parentCategory = categories.find(
        (item) =>
          item.id === category_id ||
          item.sub_categories.find((subItem) => subItem.id === category_id)
      )
      parentCategory && result.push(`category_${parentCategory.id}`)
    }

    return result
  }

  getItems = () => {
    const { categories, biomarker } = this.props
    if (categories && categories.length > 0 && biomarker) {
      return categories.map((_category) => {
        const { id, name, sub_categories } = _category
        const category = { label: name, key: `category_${id}` }
        category.children = sub_categories.map((_subCategory) => {
          const { id, name } = _subCategory
          const subCategory = { label: name, key: `sub_category_${id}` }
          subCategory.children = _subCategory.bio_markers.map(
            ({ id, name }) => ({ label: name, key: id })
          )
          return subCategory
        })
        return category
      })
    }
    return []
  }

  render() {
    const { id, categories, biomarker, turnTo, goBack } = this.props
    const { collapsed } = this.state
    const items = this.getItems()

    return (
      <Layout style={{ height: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo">P H S</div>
          {categories && categories.length > 0 && biomarker && (
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[id]}
              defaultOpenKeys={this.getDefaultOpenKeys(biomarker.category_id)}
              items={items}
              onClick={(item) => turnTo(item.key)}
            ></Menu>
          )}
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Button className="btn-back" onClick={() => goBack()}>
              <LeftOutlined />
              Back
            </Button>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280
            }}
          >
            {this.props.children}
            {/* <div
              style={{ display: panelType === 'preview' ? 'block' : 'none' }}
            >
              <PreviewBiomarker
                biomarker={bioMarker}
                backEdit={() => {
                  this.setState({ panelType: 'edit' })
                }}
              />
            </div> */}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default BiomarkerLayout
