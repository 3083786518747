import React, { Component } from 'react'
import { Upload } from 'antd'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  .ant-upload-list-picture .ant-upload-list-item {
    border: none;
  }
`

class FileUpload extends Component {
  static propTypes = {
    id: PropTypes.string,
    multiple: PropTypes.bool
  }

  state = {
    files: [],
    filename: ''
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      files: [value]
    })
  }

  triggerChange = (file) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(file)
    }
    return false
  }

  render() {
    const { extraAttr } = this.props
    const { files } = this.state
    return (
      <Container>
        <Upload
          {...extraAttr}
          defaultFileList={files}
          beforeUpload={this.triggerChange}
        >
          {extraAttr && extraAttr.placeholder}
        </Upload>
      </Container>
    )
  }
}

export default FileUpload
