import React, { useState, useEffect, useReducer } from 'react'
import _, { get, has, isFunction } from 'lodash'
import { animateScroll as scroll } from 'react-scroll'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PageLoading from '../../../components/UI/status/loading'
import QuestionnaireProgress from '../questionnaire/progress'
import Information from './information'
import {
  getIntroductory,
  saveIntroductory,
  removeAttachment,
  editAttachment,
  introductoryNotification
} from '../../../api'
import {
  baseURL,
  getRole,
  saveUser,
  formatInch,
  parseInch
} from '../../../utils'
import surveyData from './survey.json'
import { OnboardingHeader } from '../style'
import axios from 'axios'
import moment from 'moment'
// import MemberInfoDone from './completed'

const GOOGLE_TIMEZONE_KEY = process.env.REACT_APP_GOOGLE_TIMEZONE_KEY

function PersonalIntroductory(props) {
  const {
    history,
    // logOut,
    location: { search },
    account: { user }
  } = props
  const [state, dispatch] = useReducer(reducer, {})
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(true)
  const [showError, setShowError] = useState(false)
  const [savingAnswer, setSavingAnswer] = useState(false)
  // const [done, setDone] = useState(false)

  useEffect(() => {
    const initial = async () => {
      const {
        account: { user }
      } = props
      const survey = surveyData
      const role = getRole()
      if (role === 'Expert') {
        survey.sections[0].questions.push({
          id: 10,
          required: true,
          question: 'Expert notes',
          type: 'text_entry',
          answer_format: 'expert_notes'
        })
      }
      const introductory = await getIntroductory(user.id)
      if (introductory.height_inch) {
        const { height_ft, height_in } = formatInch(introductory.height_inch)
        introductory.height_ft = height_ft
        introductory.height_in = height_in
      }
      const answers = destructuringAnswers(survey.sections, introductory)
      // initial attachment handle
      _.forIn(survey.sections, (section, index) => {
        let question = _.find(section.questions, (question) =>
          _.includes(JSON.stringify(question), 'attachment')
        )
        if (question) {
          const target = _.find(question.choices, ['type', 'attachment'])
          if (target) {
            target.handleAttachment = {
              action: `${baseURL}/attachments/introductory.insurance_photos/${introductory.id}?person_id=${user.id}`,
              removeAttach: async (attach) => {
                await removeAttachment(attach.id, user.id)
              },
              editAttach: async (attach, obj) => {
                await editAttachment(attach.id, user.id, obj)
              }
            }
          }
        }
      })

      dispatch({
        type: 'initial',
        payload: { introductory, answers, survey }
      })
    }
    initial()
  }, [])

  const sections = surveyData.sections

  const { survey, answers, introductory } = state

  const disabledNext =
    _.includes(JSON.stringify(answers), 'verify') ||
    _.includes(JSON.stringify(answers), 'required')

  return (
    <div className="main-container">
      <OnboardingHeader />
      {/* {done ? (
        <MemberInfoDone goNext={next} logOut={() => logOut(history)} />
      ) : (
        <> */}
      <div className="step-title">
        <span>3</span>
        Member Information
      </div>
      <QuestionnaireProgress
        sections={sections}
        step={step}
        answers={getProgressAnswer(surveyData.sections, answers)}
      />

      <div className="main-content">
        {loading ? (
          <PageLoading />
        ) : (
          <Information
            introductory={introductory}
            section={survey.sections[step]}
            updateAnswers={updateAnswers}
            answers={answers[survey.sections[step].id]}
            showError={showError}
            person={user}
            // handleAttachment={this.handleAttachment}
          />
        )}
      </div>
      <footer>
        <span
          className="back"
          onClick={() => {
            if (step === 0) {
              history.go(-1)
            } else {
              setStep(step - 1)
            }
          }}
        >
          <LeftOutlined /> back
        </span>
        <Button
          type="primary"
          disabled={disabledNext}
          className="btn-next"
          onClick={next}
          loading={savingAnswer}
        >
          next
          <RightOutlined />
        </Button>
      </footer>
      {/* </>
      )} */}
    </div>
  )

  function reducer(state, action) {
    if (action.type === 'initial') {
      if (loading) {
        setLoading(false)
      }
      return { ...action.payload }
    } else if (action.payload) {
      return _.assign({}, state, action.payload)
    }

    return state
  }

  function updateAnswers(answer, params) {
    const section = survey.sections[step]
    const replaceIndex = answers[section.id].findIndex(
      (item) => item.question_id === answer.question_id
    )
    if (replaceIndex !== -1) {
      answers[section.id][replaceIndex] = answer
    }
    _.assignIn(answers[section.id])
    if (has(params, 'residence_address.geometry.location')) {
      fetchTimezone(params)
    }
    dispatch({ type: 'update', payload: { answers } })
    if (answer.verify) {
      return
    }
    if (params.height_ft || params.height_in) {
      params.height_inch = parseInch(
        params.height_ft || 0,
        params.height_in || 0
      )
      delete params.height_ft
      delete params.height_in
    }
    const paramsStr = JSON.stringify(params)

    if (
      !_.isEmpty(params) &&
      !(
        _.includes(paramsStr, 'required') ||
        _.includes(paramsStr, 'textRequired')
      )
    ) {
      saveAnswer(params)
    }
  }

  async function fetchTimezone(params) {
    const lat = get(params, 'residence_address.geometry.location.lat')
    const lng = get(params, 'residence_address.geometry.location.lng')
    if (!isFunction(lat) || !isFunction(lng)) {
      return
    }
    const result = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat()},${lng()}&timestamp=${moment().unix()}&key=${GOOGLE_TIMEZONE_KEY}`
    )
    const timeZone = get(result, 'data.timeZoneId')
    if (timeZone) {
      answers[1][7] = {
        session_id: 1,
        question_type: 'select',
        question_id: 8,
        answer: {
          value: timeZone
        }
      }
      _.assignIn(answers[1])
      dispatch({ type: 'update', payload: { answers } })
      saveAnswer({
        time_zone: timeZone
      })
    }
  }

  async function saveAnswer(params) {
    setSavingAnswer(true)
    setShowError(false)
    try {
      await saveIntroductory(introductory.id, params)
      _.assignIn(introductory, params)
      setSavingAnswer(false)
      dispatch({ type: 'update', payload: { introductory } })
    } catch (err) {
      console.error(err)
      setSavingAnswer(false)
    }
  }

  async function next() {
    // if (done) {
    //   if (search.match('callback')) {
    //     const callback = search.split('=')[1]
    //     history.push(callback)
    //   } else {
    //     history.push('/onboarding/ready')
    //   }
    //   return
    // }
    const section = surveyData.sections[step]
    const isCompleted = checkCompleted(section.questions, answers[section.id])
    if (isCompleted) {
      if (search.match('callback') && step === 1) {
        const callback = search.split('=')[1]
        return history.push(callback)
      }
      if (step === 0) {
        setStep(step + 1)
      }
      // save completed filed
      const completedField = [
        'personal_information_completed',
        'insurance_information_completed'
      ]
      await saveAnswer({ [completedField[step]]: true })
      if (step >= 1) {
        try {
          introductoryNotification(user.id)
        } catch (err) {
          console.error(err)
        }
        _.assign(user.profile, {
          introductory_required: false,
          onboarding_steps: _.assign(user.profile.onboarding_steps, {
            introductory_completed_steps: [1, 2]
          })
        })
        saveUser(user)
        history.push('/onboarding/ready')
        // if (!done) {
        //   setDone(true)
        // }
      }
    } else {
      setShowError(true)
      dispatch({ type: 'update', payload: { answers } })
      scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
    }
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(PersonalIntroductory)

function destructuringAnswers(sections, answer) {
  let answers = {}
  _.forIn(sections, (section) => {
    answers[section.id] = []
    _.forIn(section.questions, (question) => {
      let obj = destructuring(question.answer_format, answer)
      answers[section.id].push({
        answer: { value: obj },
        session_id: section.id,
        question_type: question.type,
        question_id: question.id
      })
    })
  })
  return answers
}

function destructuring(format, answer) {
  if (!format) return null
  let answerObj
  if (format.type === 'choice') {
    answerObj = answerObj || {}
    _.forIn(format.format, (_format, key) => {
      if (_.isString(_format)) {
        answerObj[key] = formatting(_format)
      } else if (answer[_format.key] === _format.value) {
        answerObj[key] = {}
        if (_format.text_key) {
          answerObj[key].text = answer[_format.text_key]
        } else if (_format.additional_key) {
          answerObj[key].additional_answer = formatting(_format.additional_key)
        } else if (_format.attachment_key) {
          answerObj[key] = formatting(_format.attachment_key)
        }
      }
    })
  } else {
    answerObj = formatting(format)
  }

  function formatting(format) {
    if (_.isString(format)) {
      return answer[format]
    } else if (_.isArray(format)) {
      return format.map((item) => formatting(item))
    } else {
      if (format.key) {
        if (format.isChecked) {
          // question position 1-3, 1-6
          let additional_answer
          if (format.format_key) {
            const obj = formatting(format.format_key)
            additional_answer = obj
          }
          return {
            isChecked: formatting(format.key),
            additional_answer
          }
        } else {
          return formatting(format.key)
        }
      } else {
        let obj = {}
        _.forIn(format, (value, key) => {
          obj[key] = formatting(value.isChecked ? value : value.key || value)
        })
        return obj
      }
    }
  }

  return answerObj
}

function checkCompleted(questions, answer) {
  let completed = true
  _.forIn(
    _.filter(questions, (question) => question.required),
    (question, index) => {
      const anObj = answer[index]
      if (_.isEmpty(anObj.answer.value)) {
        completed = false
        anObj.verify = { required: true }
      }
      let an = anObj.answer.value
      if (question.id === 2) {
        // check 1-2
        if (anObj.verify && anObj.verify.items) {
          completed = false
        }
        _.forIn(an, (_an, index) => {
          anObj.verify = anObj.verify || { items: [] }
          if (typeof _an !== 'string' && typeof _an !== 'number') {
            completed = false
            anObj.verify.items[index] = { required: true }
          } else {
            anObj.verify.items[index] = null
          }
        })
        if (anObj.verify.items.filter((item) => item).length === 0) {
          delete anObj.verify
        }
      } else if (question.id === 6) {
        // check 1-6
        if (_.isEmpty(_.filter(an, (_an) => _an))) {
          anObj.verify = { required: true }
          completed = false
        }
      } else if (question.id === 7) {
        // check 1-7
        // debugger
        // if (!an[0] && !an[1].isChecked && !an[2].isChecked) {
        // residence is required
        if (!an[0]) {
          anObj.verify = { required: true }
          completed = false
        }
      } else if (question.id === 1 && question.type === 'intro_single_choice') {
        // check 2-1
        if (an[1]) {
          anObj.verify = anObj.verify || { additional: [] }
          _.forIn(an[1].additional_answer, (_an, index) => {
            if (!_an) {
              anObj.verify.additional[index] = { required: true }
              completed = false
            }
          })
        } else if (an[0]) {
          if (_.isEmpty(an[0])) {
            an[0] = { required: true }
            completed = false
          }
        } else {
          anObj.verify = { required: true }
          completed = false
        }
      }
    }
  )
  return completed
}

function getProgressAnswer(sections, answers) {
  const proAnswers = { 1: {}, 2: {} }
  if (!answers) return {}
  _.forIn(proAnswers, (_answers, key) => {
    _.forIn(answers[key], (an, index) => {
      proAnswers[key][Number(index) + 1] = an.answer && an.answer.value
    })
  })
  _.forIn(sections, (section) => {
    const questions = section.questions
    _.forIn(
      _.filter(questions, (question) => question.required),
      (question) => {
        const an = proAnswers[section.id] && proAnswers[section.id][question.id]
        if (an) {
          if (section.id === 1 && question.id === 2) {
            const validAn = an.filter(
              (_a) => _a && (typeof _a === 'string' || typeof _a === 'number')
            )
            if (validAn.length === 0) {
              _.unset(proAnswers, '1.2')
            }
          }
          if (section.id === 2 && question.id === 1) {
            let empty = false
            if (_.includes(JSON.stringify(an), 'required')) {
              empty = true
            } else if (an[1]) {
              empty = _.isEmpty(_.filter(an[1].additional_answer, (_an) => _an))
            } else if (an[0]) {
              empty = _.isEmpty(an[0])
            } else {
              empty = true
            }
            empty && _.unset(proAnswers, '2.1')
          }
        }
      }
    )
  })
  return proAnswers
}
