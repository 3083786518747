import React, { Component } from 'react'
import { PlusOutlined } from '@ant-design/icons'
// import PropTypes from 'prop-types'
import { Button, Row, Col } from 'antd'
import FacilityModal from './modal'
import Facility from './facility'
import { getFacilities, getService } from '../../../../api/facility'
import PageLoading from '../../../../components/UI/status/loading'
import { Style } from './index.sass'

class ExpertView extends Component {
  static propTypes = {}

  state = {
    visible: false,
    facilities: [],
    services: [],
    pageLoading: true
  }

  initail = async () => {
    const { facility } = await getFacilities()
    const { services } = await getService()
    this.setState({
      facilities: facility,
      services,
      pageLoading: false
    })
  }

  submitSync = (facility, type) => {
    let { facilities } = this.state
    switch (type) {
      case 'create':
        facilities.push(facility)
        break
      case 'update':
        const index = facilities.findIndex((item) => item.id === facility.id)
        facilities.splice(index, 1, facility)
        break
      case 'delete':
        facilities = facilities.filter((item) => item.id !== facility.id)
        break
      default:
        break
    }
    this.setState({ facilities })
  }

  componentDidMount() {
    this.initail()
  }

  render() {
    const { visible, facilities, services, pageLoading } = this.state
    return (
      <div className={Style}>
        <div className="page-title">
          Facilities & Experts
          <Button
            type="primary"
            style={{ float: 'right', marginTop: 10 }}
            onClick={() => this.setState({ visible: true })}
          >
            <PlusOutlined />
            ADD FACILITY
          </Button>
        </div>
        {pageLoading ? (
          <PageLoading />
        ) : (
          <Row gutter={10} type="flex">
            {facilities.map((item, index) => (
              <Col span={12} key={index}>
                <Facility
                  facility={item}
                  turnToDetail={() =>
                    this.props.history.push(
                      `/common-tools/facilities/${item.id}`
                    )
                  }
                />
              </Col>
            ))}
          </Row>
        )}

        {visible && (
          <FacilityModal
            visible={visible}
            services={services}
            closeModal={() => this.setState({ visible: false })}
            submitSync={this.submitSync}
          />
        )}
      </div>
    )
  }
}

export default ExpertView
