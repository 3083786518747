import React, { useEffect } from 'react'

import PageLoading from '../../../components/UI/status/loading'

export default function Directive(props) {
  useEffect(() => {
    initial()
  }, [])

  const initial = async () => {
    const person = await props.getPerson()
    const {
      profile: { waiver_required, completed_signature }
    } = person
    const waiver_active =
      waiver_required &&
      (completed_signature ? completed_signature.length : 0) < 3
    if (waiver_active) {
      props.history.replace('/onboarding/waiver?directive')
    } else {
      props.history.replace('/onboarding/questionnaire')
    }
  }
  return (
    <>
      <PageLoading />
    </>
  )
}
