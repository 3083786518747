import React, { Component } from 'react'
import { root } from './index.sass'
import bg404 from '../../asserts/images/404.png'

export class NoMatch extends Component {
  static propTypes = {}

  render() {
    return (
      <div className={root}>
        <div className="center">
          <h1 className="title">Error:404 Page Not Found</h1>
          <div className="center-item">
            <img src={bg404} alt="404" />
          </div>
          <div className="description">
            Sorry,the page you're looking for cannot be accessed.
          </div>
          <div style={{ marginTop: '10px' }}>
            <span
              className="refresh"
              onClick={() => this.props.history.push('/')}
            >
              Refresh Page
            </span>
          </div>
        </div>
      </div>
    )
  }

  refresh() {
    window.location.reload()
  }
}

export default NoMatch
