import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from 'antd'
import { getEmailDetailByHash } from '../../api/summaryEmail'
import Information from '../../components/UI/extraInformation'
import PageLoading from '../../components/UI/status/loading'
import LockStatus from '../../components/UI/lockStatus'
import { userType } from '../../utils/constant'
import EmailBG from '../../asserts/images/email-bg.png'
import { emailStyle } from './styles/email.sass'

class BackupEmailMobileView extends Component {
  static propTypes = {}

  state = {
    loading: true,
    body: {
      overview: {},
      rejuvenation: {},
      deleted_section: {}
    }
  }

  initial = async () => {
    const {
      match: {
        params: { id, hash }
      }
    } = this.props
    const result = await getEmailDetailByHash(id, hash)
    this.setState({ ...result, loading: false })
  }

  componentDidMount() {
    this.initial()
  }

  renderOverview() {
    const {
      body: { overview },
      from,
      to
    } = this.state
    return (
      <div className="section-container">
        <div className="summary-date">
          {`${from && moment(from).format('MMM DD')} - ${to &&
            moment(to).format('MMM DD')}`}
        </div>
        <h3 className="title Overview">{overview.title}</h3>
        <Information
          status="Opened"
          initialValue={overview.summary_introduction}
        />

        {overview.rejuvenation_chart_url && (
          <>
            <img
              src={overview.rejuvenation_chart_url}
              alt="rejuvenation_chart_url"
            />
            <Information
              status="Opened"
              initialValue={overview.rejuvenation_recommendation}
            />
          </>
        )}
        {overview.exercise_chart_url && (
          <>
            <img src={overview.exercise_chart_url} alt="exercise_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.exercise_recommendation}
            />
          </>
        )}
        {overview.weight_chart_url && (
          <>
            <img src={overview.weight_chart_url} alt="weight_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.weight_recommendation}
            />
          </>
        )}
        {overview.nutrition_chart_url && (
          <>
            <img src={overview.nutrition_chart_url} alt="nutrition_chart_url" />
            <Information
              status="Opened"
              initialValue={overview.nutrition_recommendation}
            />
          </>
        )}
      </div>
    )
  }

  renderRejuvenation() {
    const {
      body: { rejuvenation, deleted_section }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Rejuvenation">Rejuvenation</h3>
        {!deleted_section.rejuvenation_quality && (
          <>
            <h4 className="sub-title">Quality of sleep</h4>
            <Information
              status="Opened"
              initialValue={rejuvenation.recommendation}
            />
            <img src={rejuvenation.sleep_chart_url} alt="sleep_chart_url" />
            <div className="extra-information">
              <h5>Analyzing Your Sleep Data</h5>
              <p>
                To determine how well you slept week after week, we take into
                account your average heart rate variability (HRV), sleep
                duration, and bedtime.
              </p>
            </div>
            <div style={{ marginBottom: 20 }}>
              <Information
                status="Opened"
                initialValue={rejuvenation.custom_text}
              />
            </div>
          </>
        )}

        {rejuvenation.sleep_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <Information status="Opened" initialValue={item.custom_text} />
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  renderExercise() {
    const {
      body: { exercise, deleted_section }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Exercise">Exercise</h3>
        {!deleted_section.exercise_target && (
          <>
            <h4 className="sub-title">Apeiron Life Training Target</h4>
            <Information
              status="Opened"
              initialValue={exercise.heart_recommendation}
            />
            <img src={exercise.heart_chart_url} alt="heart_chart_url" />
            <div className="extra-information">
              <h5>What is my Aerobic Training Target?</h5>
              <p>
                For optimal health benefits, we recommend you aim for at least
                150 minutes per week of moderate-intensity activity (zone 2 -
                zone 3; 60-75% max heart rate). This includes running, cycling,
                swimming, and hiking.
              </p>
            </div>
          </>
        )}

        <Information
          status="Opened"
          initialValue={exercise.heart_custom_text}
        />
        <Divider />

        {!deleted_section.exercise_workout && (
          <>
            <h4 className="sub-title">Workout</h4>
            <Information
              status="Opened"
              initialValue={exercise.workout_recommendation}
            />
            <img src={exercise.workout_chart_url} alt="heart_chart_url" />
            <Information
              status="Opened"
              initialValue={exercise.workout_custom_text}
            />
            {exercise.activity_goals.length > 0 &&
              !deleted_section.exercise_workout && <Divider />}
          </>
        )}
        {exercise.activity_goals.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <Information status="Opened" initialValue={item.custom_text} />
            <img src={item.chart_url} alt="chart_url" />
          </div>
        ))}
      </div>
    )
  }

  renderNutrition() {
    const {
      body: { nutrition, nutrition_recommendation }
    } = this.state
    return (
      <div className="section-container">
        <h3 className="Nutrition">Nutrition</h3>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <Information
            status="Opened"
            initialValue={nutrition_recommendation}
          />
        </div>
        {nutrition.map((item, index) => (
          <div key={index}>
            {index !== 0 && <Divider />}
            <div className="goal-name">{item.description}</div>
            <img src={item.chart_url} alt="chart_url" />
            <Information status="Opened" initialValue={item.custom_text} />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      loading,
      body: { deleted_section, nutrition }
    } = this.state
    const { person } = this.props
    if (!person) return null
    const isPartner = person.profile.account_type === userType.Partners
    if (isPartner) return <LockStatus />
    return loading ? (
      <PageLoading />
    ) : (
      <div className={`${emailStyle} backup-style`}>
        <img className="bg-image" src={EmailBG} alt="background" />
        {this.renderOverview()}
        {!deleted_section.rejuvenation && this.renderRejuvenation()}
        {!deleted_section.exercise && this.renderExercise()}
        {!deleted_section.nutrition &&
          nutrition.length > 0 &&
          this.renderNutrition()}
      </div>
    )
  }
}

export default BackupEmailMobileView
