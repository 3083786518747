import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import PropTypes from 'prop-types'

import SettingOverview from './settingComp/index'
import ChangePassword from './settingComp/changePassword'
import PaymentSetting from './settingComp/payment'

class Setting extends Component {
  static propTypes = {}

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route
          exact
          path={`${match.url}`}
          render={props => <SettingOverview {...props} {...this.props} />}
        />
        <Route
          exact
          path={`${match.url}/change-password`}
          render={props => <ChangePassword {...props} {...this.props} />}
        />

        <Route
          exact
          path={`${match.url}/payment`}
          render={props => <PaymentSetting {...props} {...this.props} />}
        />
      </Switch>
    )
  }
}

export default Setting
