import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReportBloodTestCharts from '../../../components/business/creport/charts'
import BloodTestResult from '../../../components/business/creport/bloodTest'
import KeyTakeaway from '../../../components/business/creport/keyTakeaway'
import ReportFiles from '../../../components/business/creport/files'

class BloodTestPreview extends Component {
  static propTypes = {
    report: PropTypes.object,
    dealHtml: PropTypes.func
  }

  render() {
    const { report, dealHtml } = this.props
    return (
      <div className="report-blood-test report-section">
        <h1 className="section-title">1. Blood Tests Result</h1>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.blood_test_intro)
          }}
        />
        {/* <Divider /> */}
        <h3 className="section-sub-title">Summary</h3>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.blood_test_summary)
          }}
        />
        {report.categorized_blood_test && (
          <ReportBloodTestCharts
            bloodTestResult={report.categorized_blood_test}
          />
        )}
        <h3 className="section-sub-title">significant blood test</h3>
        <p
          className="text-section"
          dangerouslySetInnerHTML={{
            __html: dealHtml(report.significant_blood_test_intro)
          }}
        />
        {report.categorized_blood_test && (
          <BloodTestResult result={report.categorized_blood_test} />
        )}
        <h3 className="section-sub-title">Key Takeaway</h3>
        {report.blood_test_takeaway && (
          <KeyTakeaway initialValue={report.blood_test_takeaway} />
        )}
        {(report.blood_test_media_files || report.blood_test_media_intro) && (
          <ReportFiles
            readOnly={true}
            files={report.blood_test_media_files || []}
            introduction={report.blood_test_media_intro}
          />
        )}
      </div>
    )
  }
}

export default BloodTestPreview
