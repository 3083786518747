import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Typography, Divider } from 'antd'
import PersonalValue from '../dataInput/personalValue'
import MultiSelect from '../dataInput/multiSelect'
import RectangularText from '../../../UI/RectangularText'
import HistoryChart from './chat'
// import { matchRangeByType } from '../../../../utils/common'

import { biomarkerStyle } from './index.sass'

const { Paragraph } = Typography

export class Biomarker extends Component {
  static propTypes = {
    index: PropTypes.number,
    biomarker: PropTypes.object,
    turnTo: PropTypes.func,
    person: PropTypes.object,
    isPublicLink: PropTypes.bool,
    personalBioMarkerSync: PropTypes.func,
    status: PropTypes.string,
    newBloodTest: PropTypes.bool
  }

  state = {
    personalBioMarker: null,
    conventionalLoading: false,
    functionalLoading: false
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      this.setState({ personalBioMarker: null })
    }
  }

  switchHandle = async (checked, type) => {
    const { biomarker, datasetId, savePersonalValue } = this.props
    if (!biomarker.personal_data) return
    const personalBioMarker = Object.assign({}, biomarker.personal_data)
    const {
      bio_marker_id,
      prefer_conventional,
      prefer_functional,
      value
    } = personalBioMarker
    let state = {},
      param = {
        id: bio_marker_id,
        value,
        prefer_conventional,
        prefer_functional
      }
    state[`${type}Loading`] = true
    this.setState(state)
    param[`prefer_${type}`] = checked

    try {
      this.setState(state)
      await savePersonalValue(param, datasetId)
      state[`${type}Loading`] = false
      this.setState(state)
    } catch (err) {
      console.error(err)
    }
  }

  getOptionValue = (value, stateOptions) => {
    if (!value) return
    let {
      biomarker: { options }
    } = this.props
    // const { stateOptions } = this.state
    const optionsValues = options.map((item) =>
      item.replace('conv:', '').replace('func:', '')
    )
    let targetIndex
    if (value < 10) {
      for (const index in stateOptions) {
        const item = stateOptions[index]
        if (item.key === optionsValues[value]) {
          targetIndex = index
          break
        }
      }
    } else {
      const [value1, value2] = value.toString().split('')
      for (const index in stateOptions) {
        const item = stateOptions[index]
        if (
          (item.conv === value1 && item.func === value2) ||
          (item.func === value1 && item.conv === value2)
        ) {
          targetIndex = index
          break
        }
      }
    }
    return targetIndex
  }

  renderOptimal(range, unit) {
    let rangeStr
    if (range.min || range.max) {
      if (range.min && (!range.max || range.max === Number.MAX_SAFE_INTEGER)) {
        rangeStr = `> ${range.min} ${unit}`
      } else if (
        range.max &&
        (!range.min || range.min === Number.MIN_SAFE_INTEGER)
      ) {
        rangeStr = `< ${range.max} ${unit}`
      } else {
        rangeStr = `${range.min} - ${range.max} ${unit}`
      }
    }

    return (
      <span className={`ranges ${rangeStr && range.name}`}>
        {(rangeStr && rangeStr.trim()) || <span className="unknown">n/a</span>}
      </span>
    )
  }

  renderRange(range) {
    const { biomarker } = this.props
    return (
      <div className="ranges-box">
        {(range &&
          (biomarker.options
            ? range.name
            : this.renderOptimal(range, biomarker.unit))) || (
          <span className="unknown">n/a</span>
        )}
      </div>
    )
  }

  renderRangeByType(type) {
    const { biomarker, isPublicLink } = this.props
    const personalBioMarker = biomarker.personal_data
    const personalBioMarkerObj =
      this.state.personalBioMarker || personalBioMarker
    let range

    if (personalBioMarkerObj) {
      range = personalBioMarkerObj[`${type}_output_range`] || {}
      const optimalRange = personalBioMarkerObj[`${type}_optimal_range`] || []
      range.min = optimalRange[0]
      range.max = optimalRange[1]
    }

    return (
      <div className={type}>
        {!isPublicLink && (
          <Switch
            loading={this.state[`${type}Loading`]}
            onClick={(checked) => this.switchHandle(checked, type)}
            checked={
              personalBioMarkerObj && personalBioMarkerObj[`prefer_${type}`]
            }
          />
        )}
        <br />
        <span>
          {type}
          <br />
          {this.renderRange(range)}
        </span>
        <RectangularText
          text={(range && range.name) || 'N/A'}
          color={range && range.color}
        />
      </div>
    )
  }

  render() {
    const {
      index,
      biomarker,
      turnTo,
      person,
      datasetId,
      ageScopes
    } = this.props
    const { personalBioMarker } = this.state

    const personalBioMarkerObj =
      personalBioMarker || (biomarker && biomarker.personal_data)
    return person || personalBioMarkerObj ? (
      <div
        className={`biomarker-container ${biomarkerStyle}`}
        name={biomarker.name}
        key={index}
      >
        <div className="personal-value-container">
          <div
            className="name"
            onClick={() => {
              biomarker.personal_data = personalBioMarkerObj
              turnTo(biomarker.id, datasetId)
            }}
          >
            {biomarker.name}
          </div>
          {biomarker.summary && (
            <Paragraph className="summary desktop-show" ellipsis>
              {biomarker.summary}
            </Paragraph>
          )}
          <div className="value">
            <span>your value</span>
            {biomarker.options ? (
              <MultiSelect {...this.props} getValue={this.getOptionValue} />
            ) : (
              <PersonalValue {...this.props} />
            )}
          </div>
        </div>

        <Divider type="vertical" />
        <div className="range">
          {this.renderRangeByType('conventional')}
          {/* comment on sprint 23 */}
          {/* {this.renderRangeByType('functional')} */}
        </div>
        {/* <div className="chat">chat</div> */}
        <HistoryChart
          biomarker={biomarker}
          person={person}
          ageScopes={ageScopes}
        />
      </div>
    ) : (
      <div />
    )
  }
}

export default Biomarker
