import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { message } from 'antd'
import mapDispatchToProps from '../dispatch'
import {
  getClientList,
  getClientDetail,
  getQuestionnaire
} from '../../../models/breadcrumb.model'
import navagations from '../../../models/clientNavagation.model'
import QuestionsContainer from '../../../components/questionnaire/container'

const BtnGroup = styled.div`
  font-family: Gilroy;
  font-size: 16;
  color: #264382;
  float: right;
  margin-top: -80px;
`
const BtnItem = styled.div`
  display: inline-block;
  margin-left: 20px;
  min-width: 202px;
  padding: 0 20px;
  height: 44px;
  border-radius: 38px;
  cursor: pointer;
  border: 2px solid #264382;
  text-align: center;
  line-height: 40px;
`

class Quetionnaire extends Component {
  static propTypes = {
    getPerson: PropTypes.func
  }

  state = {
    person: null,
    answer: null,
    survey: null
  }

  initial = async () => {
    const { getPerson, setClientBreadcrumb } = this.props
    const person = await getPerson()
    this.setState({ person })
    setClientBreadcrumb([
      getClientList(),
      getClientDetail(person),
      getQuestionnaire(person.id, true)
    ])
  }

  toEditAnswer = () => {
    const { person, editSection } = this.state
    let url = `/client-questionnaire/${person.id}?callback=/client/${person.id}/detail/questionnaire`
    if (editSection) {
      url += `&section=${editSection > 0 ? editSection - 1 : editSection}`
    }
    if (person) {
      this.props.history.push(url)
    }
    // if (person) {
    //   this.props.history.push(
    //     `/client-questionnaire/${person.id}?callback=/client/${person.id}/detail/questionnaire`
    //   )
    // }
    // todo: confirm callback url /client/125/detail/questionnaire
    // this.props.history.push(
    //   `/onboarding/questionnaire?callback=${this.props.location.pathname}`
    // )
  }

  viewScore = () => {
    const { person } = this.state
    this.props.history.push(`/client/${person.id}/detail/questionnaire/score`)
  }

  copyLink = () => {
    const env = process.env.REACT_APP_WORKFLOW
    let domain = ''
    if (env === 'production') {
      domain = 'https://app.apeiron.life'
    } else {
      domain = 'https://app-staging.apeiron.life'
    }
    navigator.clipboard.writeText(domain + '/onboarding/directive')
    message.success('Link copied!')
  }

  componentDidMount() {
    const { setClientPageTitle } = this.props
    setClientPageTitle(
      navagations.filter((item) => item.id === 'questionnaire')[0].title
    )
    this.initial()
  }

  render() {
    const { person } = this.state
    const env = process.env.REACT_APP_WORKFLOW
    const haveScore = env !== 'staging' && env !== 'production'
    return (
      <div>
        {person && (
          <>
            <BtnGroup>
              <BtnItem onClick={this.copyLink}>
                Copy Agreement/Questionnaire Link
              </BtnItem>
              {haveScore && (
                <BtnItem onClick={this.viewScore}>View Score</BtnItem>
              )}
              <BtnItem onClick={this.toEditAnswer}>Edit Answers</BtnItem>
            </BtnGroup>
            <QuestionsContainer
              person={person}
              setEditSection={(editSection) => this.setState({ editSection })}
            />
          </>
        )}
      </div>
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(Quetionnaire)
