export const getDbFields = (type) => {
  let dbFields = {}
  switch (type) {
    case 'blood-test':
      dbFields = {
        metricsField: 'blood_test',
        biomarkerIdField: 'blood_test_id',
        type: 'blood_test',
        groupType: 'blood_test',
        fileType: 'bio_marker'
      }
      break
    case 'body-comp':
    case 'vitals':
    case 'bone-density':
      dbFields = {
        metricsField: 'body_comps',
        biomarkerIdField: 'body_comp_id',
        type: 'body_comp',
        category_type: 'body_comps',
        groupType: 'body_comp'
      }
      break
    case 'physical':
    case 'movement-quality':
    case 'muscle-fitness':
      dbFields = {
        metricsField: 'physical_assessments',
        biomarkerIdField: 'physical_assessment_id',
        type: 'physical_assessment',
        groupType: 'muscle_fitness',
        category_type: 'muscle_fitness'
      }
      break
    case 'cardiorespiratory':
    case 'aerobic-fitness':
      dbFields = {
        metricsField: 'cardiorespiratories',
        biomarkerIdField: 'cardiorespiratory_id',
        type: 'cardiorespiratory',
        groupType: 'aerobic_fitness',
        category_type: 'aerobic_fitness'
      }
      break
    case 'cognitive-health':
    case 'cognitive_health':
      dbFields = {
        metricsField: 'cognitive_healths',
        biomarkerIdField: 'cognitive_health_id',
        type: 'cognitive_health',
        groupType: 'cognitive_health',
        category_type: 'cognitive_health'
      }
      break
    /* split line */
    case 'life-style':
    case 'lifestyle-assessment':
      dbFields = {
        metricsField: 'lifestyle_assessments',
        biomarkerIdField: 'lifestyle_assessment_id',
        type: 'lifestyle_assessment'
      }
      break
    case 'range-of-motions':
      dbFields = {
        metricsField: 'range_of_motions',
        biomarkerIdField: 'range_of_motion_id',
        type: 'range_of_motion'
      }
      break
    case 'force-plate':
      dbFields = {
        metricsField: 'force_plates',
        biomarkerIdField: 'force_plate_id',
        type: 'force_plate'
      }
      break
    case 'strength':
      dbFields = {
        metricsField: 'strengths',
        biomarkerIdField: 'strength_id',
        type: 'strengths',
        fileType: 'strength'
      }
      break
    case 'movement-efficiency':
      dbFields = {
        metricsField: 'movement_efficiencies',
        biomarkerIdField: 'movement_efficiency_id',
        type: 'movement_efficiency'
      }
      break
    case 'vital':
      dbFields = {
        metricsField: 'vitals',
        biomarkerIdField: 'vital_id',
        type: 'vital'
      }
      break
    case 'nutrition':
      dbFields = {
        metricsField: 'nutrition',
        biomarkerIdField: 'nutrition_id',
        type: 'nutrition'
      }
      break
    default:
      break
  }
  switch (type) {
    case 'vitals':
      dbFields.fileType = 'vitals'
      dbFields.category_type = 'vitals'
      dbFields.groupType = 'vitals'
      break
    case 'bone-density':
      dbFields.fileType = 'bone_density'
      dbFields.category_type = 'bone_density'
      dbFields.groupType = 'bone_density'
      break
    case 'movement-quality':
      dbFields.fileType = 'movement_quality'
      dbFields.category_type = 'movement_quality'
      dbFields.groupType = 'movement_quality'
      break

    // Compatibility with older versions
    case 'life-style':
    case 'physical':
    case 'cardiorespiratory':
      dbFields.groupType = null
      break
    default:
      break
  }
  return dbFields
}

export const getTitleByType = (type) => {
  let title
  switch (type) {
    case 'vitals':
      title = 'Vitals'
      break
    case 'body-comp':
      title = 'Body Composition'
      break
    case 'bone-density':
      title = 'Bone Density'
      break
    case 'movement-quality':
      title = 'Movement Quality & Balance'
      break
    case 'muscle-fitness':
      title = 'Muscle Fitness'
      break
    case 'aerobic-fitness':
      title = 'Aerobic Fitness'
      break
    case 'cognitive-health':
      title = 'Cognitive Health'
      break
    case 'blood-test':
      title = 'Blood Panel'
      break
    /* split line */
    case 'life-style':
    case 'lifestyle-assessment':
      title = 'Lifestyle Assessment'
      break
    case 'range-of-motions':
    case 'range-of-motion':
      title = 'Range of Motions'
      break
    case 'force-plate':
      title = 'Force plate'
      break
    case 'strength':
    case 'strengths':
      title = 'Strength'
      break
    case 'cardiorespiratory':
      title = 'Cardiovascular Fitness'
      break
    case 'movement-efficiency':
      title = 'Movement Efficiency'
      break
    case 'nutrition':
      title = 'Nutrition'
      break
    case 'physical':
      title = 'Initial 360 Physical Evaluation'
      break
    default:
      break
  }
  return title
}

export const injectRootCategories = (rootCategories, personalBiomarkers) => {
  for (const rootCategory of rootCategories) {
    for (const category of rootCategory.sub_categories) {
      for (const biomarker of category.bio_markers) {
        biomarker.personal_data = personalBiomarkers.filter(
          (item) => item && item.bio_marker_id === biomarker.id
        )[0]
      }
    }
  }
  return rootCategories
}

// export const injectCategories = (
//   categories,
//   datasets,
//   biomarkerType,
//   parentType
// ) => {
//   for (const category of categories) {
//     if (category) {
//       for (const biomarker of category[biomarkerType]) {
//         biomarker.person_value = datasets.find(
//           item => item[parentType] === biomarker.id
//         )
//       }
//     }
//   }
//   return categories
// }

export const injectCategories = (
  categories,
  datasets,
  biomarkerType,
  parentType
) => {
  for (const category of categories) {
    if (category) {
      if (category.child_categories) {
        for (const subCategory of category.child_categories) {
          if (subCategory[biomarkerType]) {
            for (const biomarker of subCategory[biomarkerType]) {
              biomarker.person_value = datasets.find(
                (item) => item[parentType] === biomarker.id
              )
            }
          }
        }
      }
      if (category[biomarkerType]) {
        for (const biomarker of category[biomarkerType]) {
          biomarker.person_value = datasets.find(
            (item) => item[parentType] === biomarker.id
          )
        }
      }
    }
  }
  return categories
}

export const filterBiomarkerWithoutPersonValue = (categories, type) => {
  for (const category of categories) {
    category[type] = category[type].filter(
      (item) => item.person_value && item.person_value.on === true
    )
    for (const subCategory of category.child_categories) {
      subCategory[type] = subCategory[type].filter(
        (item) => item.person_value && item.person_value.on === true
      )
    }
  }
  return categories
}
