import React, { Component } from 'react'
import { List, Row, Col, message } from 'antd'
import moment from 'moment'
import Clipboard from 'react-clipboard.js'
import getAppVersionBuilds from '../../api/appVersion'

class AppVersion extends Component {
  static propTypes = {}

  state = {
    versions: []
  }

  initial = async () => {
    const result = await getAppVersionBuilds()
    this.setState({ versions: result.data })
  }
  componentDidMount() {
    this.initial()
  }
  render() {
    const { versions } = this.state
    const header = (
      <Row
        gutter={16}
        type="flex"
        justify="space-between"
        style={{ width: '100%' }}
      >
        <Col span={4}>Build Version</Col>
        <Col span={3}>Size</Col>
        <Col span={3}>Build At</Col>
        <Col span={14}>Install Page URL</Col>
      </Row>
    )
    return (
      <div>
        <List
          header={header}
          // footer={<div>Footer</div>}
          bordered
          style={{ background: '#fff', marginTop: 80 }}
          dataSource={versions}
          renderItem={(item) => (
            <List.Item>
              {/* <Typography.Text mark>[ITEM]</Typography.Text> {item} */}
              <Row
                gutter={16}
                type="flex"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <Col span={4}>
                  {item.title}_{item.build_number}
                </Col>
                <Col span={3}>
                  {(item.file_size_bytes / 1024 / 1024).toFixed(2)} MB
                </Col>
                <Col span={3}>
                  {moment(item.finished_at).format('YYYY-MM-DD')}
                </Col>
                <Col span={10}>{item.public_install_page_url}</Col>
                <Col span={4} style={{ color: 'blue', cursor: 'pointer' }}>
                  <Clipboard
                    data-clipboard-text={item.public_install_page_url}
                    onSuccess={() => message.success('Link copied')}
                    style={{
                      background: 'transparent',
                      border: 'none'
                    }}
                  >
                    Copy link to clipboard
                  </Clipboard>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </div>
    )
  }
}

export default AppVersion
