import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Modal, Upload, Typography, Button, message, Progress } from 'antd'
import BasicForm from '../../../components/BasicForm'
import filesize from '../../../utils/filesize'
import { upload } from '../../../api/file'
import { uploadModalStyle } from '../styles/uploadModal.sass'
import FileMask from '../../../asserts/icon/client/Mask.svg'

const { Paragraph } = Typography

const formData = [
  {
    type: 'input',
    label: 'Title',
    dbField: 'title'
  },
  {
    type: 'textarea',
    label: 'Description',
    dbField: 'description',
    extraAttr: {
      autoSize: { minRows: 2 }
    }
  }
]

class UploadModal extends Component {
  static propTypes = {
    onCancel: PropTypes.func
  }

  state = {
    fileObj: null,
    status: 'normal',
    percent: 0,
    source: null,
    fileRequired: false
  }

  customRequest = (request) => {
    const { file } = request
    const { name, size } = file
    const type = name.split('.').pop()
    this.setState({
      fileRequired: false,
      fileObj: {
        file,
        name,
        size,
        type
      }
    })
  }

  upload = async () => {
    const { personId, addFileSync, close } = this.props
    const { fileObj } = this.state
    if (!fileObj) {
      return this.setState({ fileRequired: true })
    }
    try {
      const params = await this.basicFormRef.handleSubmit()
      for (const attr in params) {
        if (!params[attr]) delete params[attr]
      }
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ status: 'active', source })
      const result = await upload({
        type: 'profile',
        personId,
        files: [fileObj.file],
        size: '',
        extraInfo: {
          ...params, // title, description
          size: fileObj.size
        },
        source,
        onBeforeUploadCallback: () => {
          this.timeStamp = Date.now()
          this.prevProgress = 0
        },
        progressCallback: (process) => {
          const percent = process.loaded / process.total
          const time = Date.now() - this.timeStamp

          // const percent = (progress - this.prevProgress) / 100;
          const [chunk, unit] = filesize(percent * fileObj.file.size, {
            round: 1
          }).split(' ')
          const speed = (chunk / (time / 1000)).toFixed(2)
          const speedUnit = `${unit}/sec`
          this.timeStamp = Date.now()
          this.prevProgress = process

          this.setState({
            percent: (percent * 100).toFixed(2),
            speed,
            speedUnit
          })
        }
        // progressCallback: (percent) => {
        //   this.setState({ percent })
        //   if (percent === 100) {
        //     this.setState({ status: 'success' })
        //   }
        // }
      })
      const file = result.file
      addFileSync(file)
      close()
    } catch (err) {
      if (err.files || err.test_date) {
        return
      } else if (!axios.isCancel(err)) {
        console.error(err)
        message.error(err.message)
        this.setState({
          status: 'exception',
          errMessage: err.message
        })
      }
    }
  }

  cancel = (isClose) => {
    const { source, status } = this.state
    const { close } = this.props
    if (status === 'active') {
      source.cancel()
      this.setState({ status: 'exception', percent: 0 })
      if (isClose) close()
    } else {
      close()
    }
  }

  render() {
    const {
      fileObj,
      status,
      fileRequired,
      percent,
      speed,
      speedUnit
    } = this.state
    return (
      <Modal
        className={uploadModalStyle}
        width={890}
        title="Upload file"
        visible={true}
        maskClosable={false}
        onCancel={() => {
          this.cancel('close')
        }}
        footer={
          <div className="upload-footer">
            <Button type="link" onClick={this.cancel}>
              CANCEL
            </Button>

            <Button
              type="primary"
              disabled={status === 'active' || status === 'success'}
              onClick={this.upload}
            >
              UPLOAD
            </Button>
          </div>
        }
      >
        <Upload
          showUploadList={false}
          customRequest={this.customRequest}
          disabled={status === 'active' || status === 'success'}
        >
          <div
            className={`upload-container ${fileObj ? '' : 'empty'} ${
              fileRequired ? 'has-error' : ''
            }`}
          >
            {fileObj ? (
              <>
                <div className="name">
                  <img src={FileMask} alt="mask" />
                  <div>
                    <Paragraph ellipsis>{fileObj.name}</Paragraph>
                    <span>
                      {fileObj.type} • {filesize(fileObj.size, { round: 1 })}
                    </span>
                    <span className="speed">
                      {percent < 100 && speed ? `${speed} ${speedUnit} - ` : ''}
                      {percent > 0 && `${percent}%`}
                    </span>
                  </div>
                </div>
                {!(status === 'active' || status === 'success') && (
                  <div className="opr">Change file</div>
                )}
              </>
            ) : (
              <div className="opr select">Select file to upload</div>
            )}
          </div>
          {percent > 0 && (
            <Progress percent={percent} status={status} showInfo={false} />
          )}
          {fileRequired && (
            <div className="file-required">Please select a file</div>
          )}
        </Upload>

        <p>
          [<span style={{ color: 'deepskyblue' }}>File Descriptor</span>][
          <span style={{ color: 'mediumpurple' }}>
            Date Created in MM/DD/YYYY
          </span>
          ][<span style={{ color: 'red' }}>4-letter legal FNLN</span>][
          <span style={{ color: 'limegreen' }}>4-Digit ID#</span>]
          <br />
          Example: Blood Panel - 03.10.2023 - DOGR0197
        </p>

        <BasicForm
          formData={formData}
          wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
        />
      </Modal>
    )
  }
}

export default UploadModal
