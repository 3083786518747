import React from 'react'
import styled, { css } from 'styled-components'
import { LogoutIcon } from '../../components/icons/onboarding'
import phsLogo from '../../asserts/icon/latest-logo.svg'
import { logOutAction } from '../../utils'

const numberIndex = css`
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #98a9bc;
  font-size: 12px;
  color: #98a9bc;
  text-align: center;
  line-height: 30px;
  margin-right: 15px;
`

const numberIndexSmall = css`
  color: #ffffff;
  width: 14px;
  height: 14px;
  display: inline-block;
  font-size: 12px;
  text-align: center;
  line-height: 14px;
  background: #98a9bc;
  border-radius: 100%;
  margin: 0 10px;
`

export const OnBoardingContainer = styled.div`
  .contact-container {
    font-size: 14px;
    line-height: 150%;
    padding-top: 16px;
    margin-top: 16px;
    color: #5e687d;
    font-style: italic;
    width: 559px;
    border-top: 1px solid rgba(152, 169, 188, 0.2);
    span {
      color: #264382;
      font-weight: bold;
    }
  }
  h1.title {
    margin-top: 60px;
    margin-bottom: 80px;
    font-size: 36px;
    line-height: 36px;
  }
  .footer-text {
    position: fixed;
    bottom: 15px;
    z-index: 999;
  }
  .main-container {
    position: relative;
    padding: 74px 0;
    header,
    footer {
      background: #ffffff;
      height: 74px;
      position: fixed;
      width: 1440px;
      padding: 0 32px;
      z-index: 99;
      max-width: 100%;
    }
    header {
      top: 0;
      border: 1px solid #eaedf3;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 99;
      img {
        width: 150px;
        margin: 21px auto auto 23px;
      }
      div {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-transform: uppercase;
        color: #264382;
        cursor: pointer;
        .anticon {
          margin-right: 8px;
        }
      }
    }
    footer {
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 60px;
      span {
        cursor: pointer;
        text-transform: uppercase;
      }
      .back {
        color: #264382;
        font-size: 14px;
        letter-spacing: 2.8px;
        font-weight: bold;
      }
      .btn-next {
        width: 120px;
        height: 40px;
      }
      .auto-directing {
        color: #264382;
        opacity: 0.3;
      }
      .skip-section {
        color: #264382;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 2.8px;
        font-weight: bold;
        position: absolute;
        right: 222px;
        top: 31px;
        text-align: right;
        height: 12px;
        line-height: 12px;
      }
      .tip-updating {
        color: #264382;
        font: 12px bold;
        visibility: hidden;
        opacity: 0.3;
        &.show {
          visibility: visible;
        }
      }
      .ant-btn-primary {
        box-shadow: 10px 10px 30px rgba(38, 67, 130, 0.1);
      }

      .ant-btn-primary[disabled] {
        background-color: #bac3ce;
        border-color: #fff;
        color: #fff;
      }

      .invoiced-text {
        text-transform: none;
        font-weight: 400;
        letter-spacing: 0.13px;
        color: #132344;
        opacity: 0.7;
      }
    }
    .step-title {
      height: 76px;
      line-height: 76px;
      border: 1px solid #eaedf3;
      padding-left: 56px;
      background: #ffffff;
      font-size: 18px;
      color: #29303e;
      span {
        ${numberIndex}
      }
    }
    .progress {
      padding: 25px 0;
      padding-left: 56px;
      background: #ffffff;
      &.questionnaire ul > li {
        margin-left: 20px;
        &:before {
          width: 50px;
          margin-left: 0;
          margin-right: 10px;
        }
      }
      ul {
        height: 100%;
        list-style: none;
        display: flex;
        align-items: center;
        padding-left: 56px;
        margin-bottom: 0;
        li {
          margin-left: 198px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: rgba(56, 67, 94, 0.3);
          .reminder {
            text-transform: none;
            padding-left: 20px;
          }
          .anticon {
            font-size: 16px;
            margin: 0 10px;
          }
          &.active {
            color: #38435e;
            span {
              opacity: 1;
            }
          }
          span {
            ${numberIndexSmall}
            opacity: .3;
          }
          &:before {
            content: '';
            display: inline-block;
            width: 168px;
            margin-left: -183px;
            background: #98a9bc;
            height: 3px;
            vertical-align: middle;
            opacity: 0.2;
          }

          &:first-child {
            margin-left: 0;
            .reminder {
              padding-left: 35px;
            }

            &:before {
              content: none;
            }
          }
        }
      }
    }
  }

  .main-content {
    padding: 0 390px;
    .error-message {
      height: 61px;
      line-height: 61px;
      margin: 30px 0;
      background: rgba(255, 64, 64, 0.1);
      color: #ff0000;
      border-radius: 8px;
      padding: 0 20px;
      .anticon {
        margin-right: 8px;
      }
    }
    &.score {
      margin: 48px 0;
    }
    &.questionnaire {
      padding: 0 390px;
      padding-bottom: 80px;
      @media screen and (max-width: 1024px) {
        // iPad pro
        max-width: 660px;
        padding: 0;
        margin: 0 auto;
      }
    }
    &.questionnaire.lifestyle {
      padding-left: 250px;
      padding-right: 250px;
    }
    .bottom-line {
      border-bottom: 1px solid rgba(152, 169, 188, 0.2);
    }
    .sub-title {
      text-transform: uppercase;
      color: #38435e;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 15px;
    }
    .introduction {
      padding-top: 18px;
      padding-bottom: 24px;
      width: 100%;
      p {
        font-size: 12px;
        color: #98a9bc;
        letter-spacing: 0.6px;
      }

      &:not(.banner) p {
        font-family: Lato;
        text-transform: none;
        color: #38435e;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.3px;
      }
    }

    .sign {
      padding-top: 35px;
      padding-bottom: 20px;
      .sign-content {
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        padding: 18px 30px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        .signed {
          color: #22a95f;
        }
        .anticon {
          margin-right: 10px;
        }
        .icon {
          float: left;
          margin-top: 14px;
          margin-right: 28px;
        }
        .sign-title {
          display: flex;
          flex-direction: column;
          height: 59px;
          justify-content: center;
          h5 {
            font-size: 18px;
            color: #29303e;
            height: 38px;
            line-height: 38px;
            margin: 0;
          }
          p {
            height: 25px;
            font-size: 14px;
            line-height: 150%;
            color: #5e687d;
            width: 329px;
            margin: 0;
          }
        }
        .btn-primary {
          width: 160px;
          height: 40px;
          border-radius: 30px;
        }
      }
      .disable {
        opacity: 0.1;
        pointer-events: none;
      }
    }
    .check {
      padding-top: 30px;
      margin-bottom: 30px;
      .ant-checkbox-wrapper {
        margin-right: 11px;
      }
      .vector {
        display: flex;
        margin-top: 30px;
        .anticon {
          margin-right: 11px;
          font-size: 18px;
          margin-top: 3px;
        }
        p {
          font-style: italic;
          color: #5e687d;
          width: 694px;
        }
      }
    }
    .other-provider-select {
      padding-top: 25px;
      padding-bottom: 40px;
      .radio {
        display: block;
        height: 54px;
        width: 658px;
        padding-top: 10px;
        padding-left: 17px;
        margin-bottom: 8px;
        background: white;
        border-radius: 8px;
      }
    }
    .share-medical-select {
      padding-bottom: 20px;
      h1 {
        font-weight: 600;
        font-style: normal;
        font-size: 36px;
        letter-spacing: 0.3px;
        color: #29303e;
        padding-bottom: 10px;
        padding-top: 30px;
      }
      .question {
        font-weight: 400;
        font-size: 18px;
        line-height: 135%;
        color: #5e687d;
      }
      .explain {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #5e687d;
        opacity: 0.6;
      }
      .ant-radio-group {
        padding-bottom: 20px;
      }
      .ant-radio-wrapper-checked {
        border: 1px solid rgba(5, 184, 0, 0.5);
      }
    }
  }
  .title + .section-container {
    & > div:first-child .section-title {
      margin-top: -45px;
    }
  }
`

export function OnboardingHeader() {
  return (
    <header>
      <img src={phsLogo} alt="logo" />
      <div
        onClick={() => logOutAction()}
        style={{ fontFamily: 'Lato', display: 'flex', alignItems: 'center' }}
      >
        <LogoutIcon /> LOG OUT
      </div>
    </header>
  )
}
