import React from 'react'

export const PlaceHolder = (props) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="17"
        cy="17"
        r="16"
        fill="#fff"
        stroke="#E6E6E6"
        strokeWidth={1.5}
      />
    </svg>
  )
}

export const ScalePoint = (props) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="17"
        cy="17"
        r="13"
        fill="#223064"
        stroke="#F1F2F4"
        strokeWidth="8"
      />
    </svg>
  )
}
