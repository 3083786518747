import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
`

/**
 * FlexBox component that provides a flexible box model layout.
 * @param {Object} props
 * @param {'row' | 'row-reverse' | 'column' | 'column-reverse'} [props.flexDirection='row'] - The flex direction of the container.
 * @param {'nowrap' | 'wrap' | 'wrap-reverse'} [props.flexWrap='nowrap'] - The flex wrap style of the container.
 * @param {'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'} [props.justifyContent='flex-start'] - The justification of content.
 * @param {'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'} [props.alignItems='flex-start'] - The alignment of items along the cross axis.
 * @param {'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch'} [props.alignContent='flex-start'] - The alignment of content lines when there is extra space in the cross axis.
 */
export const FlexBox = ({
  flexDirection = 'row',
  flexWrap = 'nowrap',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  alignContent = 'flex-start',
  className = '',
  style = {},
  onClick,
  children
}) => {
  return (
    <StyledDiv
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      alignContent={alignContent}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledDiv>
  )
}
