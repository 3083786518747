import React, { Component } from 'react'
import { Button, message } from 'antd'
import { SHA256 } from 'crypto-js'
import { pattern } from '../../utils/constant'
import BasicForm from '../../components/formV4'
import { sendSupportEmail } from '../../api/public'
import phsLogo from '../../asserts/icon/latest-logo.svg'
import succeedIcon from '../../asserts/images/succeed-o.svg'
import { supportStyle } from './support.sass'

const supportForm = [
  {
    type: 'input',
    dbField: 'name',
    label: 'Your Name',
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    dbField: 'email',
    label: 'Email',
    colSpan: 12,
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: pattern.email, message: 'Please input a valid email' }
    ]
  },
  {
    type: 'textarea',
    label: 'Message',
    placeholder: 'Type your message here',
    dbField: 'message',
    rules: [{ required: true, message: 'This field is required' }]
  }
]

// const client = new Grecaptcha(pubKey, { action: 'homepage' })

class SupportView extends Component {
  static propTypes = {}

  state = {
    formValue: {},
    loading: false,
    succeed: false,
    captchaResponse: '123',
    clientName: ''
  }

  submit = async () => {
    const params = await this.basicFormRef.handleSubmit()
    this.setState({ loading: true })
    try {
      const key =
        SHA256(new Date().toString())
          .toString()
          .substring(0, 4) +
        SHA256('phs-apeiron.life') +
        SHA256(Math.random() + '')
      const newParams = {
        captcha_response: key,
        from: params.email,
        subject: 'Apeiron Life -  Support Request',
        text: `
        name: ${params.name}
        message: ${params.message}
      `
      }
      await sendSupportEmail(newParams)
      this.setState({ loading: false, succeed: true, clientName: params.name })
    } catch (err) {
      message.error(err)
      this.setState({ loading: false })
    }
  }

  close = () => {
    if (window.webkit !== undefined) {
      window.webkit.messageHandlers.contactSupportClose.postMessage(null)
    } else {
      this.setState({ succeed: false })
    }
  }

  componentDidMount() {
    window.setDefaultClient = (name, email) => {
      this.setState({
        formValue: {
          name,
          email
        }
      })
    }
  }

  render() {
    const { succeed, loading, clientName } = this.state
    return (
      <div className={supportStyle}>
        <header>
          <img src={phsLogo} alt="logo" />
        </header>
        {succeed ? (
          <div className="content succeed">
            <h1>Email Sent</h1>
            <img src={succeedIcon} alt="succeed" />
            <p>
              Thanks for writing to us {clientName}.
              <br />
              We will reply to you shortly.
            </p>
            <Button type="primary" onClick={this.close}>
              Close
            </Button>
          </div>
        ) : (
          <div className="content">
            <h1>Contact Us</h1>
            <p>
              Fill out this form for questions, support, or other inquiries.
              We'll get back to you shortly.
            </p>
            <BasicForm
              normalLayout
              wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
              formData={supportForm}
              onEnter={this.submit}
            />
            <div className="form-btn">
              <Button type="primary" loading={loading} onClick={this.submit}>
                Submit
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default SupportView
