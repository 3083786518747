import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getBloodTests } from '../../../api/bloodTests'
import { getCategories } from '../../../api/categories'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'
import { getDbFields, injectRootCategories } from './common'

export default WrappedComponent =>
  class BloodTestContainerWrapper extends Component {
    static propTypes = {
      person: PropTypes.object,
      category: PropTypes.string
    }

    state = {
      dataLoading: true,
      ageScopes: null
    }

    getDatasets = async () => {
      let result, datasets
      result = await getBloodTests()
      datasets = result.datasets
      this.setState({
        dataset: datasets[0],
        showDataset: datasets[0] && datasets[0].test_date
      })
      return datasets[0]
    }

    getBiomarkerCategories = async () => {
      const { client, setClientBiomarkerCategories } = this.props
      let bioMarkersCategories

      if (client.categories.length > 0) {
        bioMarkersCategories = client.categories
      } else {
        const result = await getCategories()
        bioMarkersCategories = result
        setClientBiomarkerCategories(bioMarkersCategories)
      }
      return bioMarkersCategories
    }

    initial = async () => {
      const dbField = getDbFields('blood-test')
      this.setState({ dbField })
      let { ageScopes } = this.state
      try {
        let categories = await this.getBiomarkerCategories()
        const dataset = await this.getDatasets()
        if (dataset && dataset.snapshot) {
          categories = injectRootCategories(categories, dataset.snapshot)
          for (let category of categories) {
            for (let sub_category of category.sub_categories) {
              sub_category.bio_markers = sub_category.bio_markers.filter(
                item => {
                  const personBiomarker = item.personal_data
                  return (
                    personBiomarker &&
                    (personBiomarker.prefer_conventional ||
                      personBiomarker.prefer_functional)
                  )
                }
              )
            }
          }
        }
        if (!ageScopes) {
          ageScopes = (await getRangeAgeScopes()).age_scope
        }
        this.setState({
          ageScopes,
          categories,
          dataset,
          dataLoading: false
        })
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
      }
    }

    turnTo = id => {
      const { url, history } = this.props
      const { dataset } = this.state
      history.push(`${url}/${dataset.id}/${id}`)
    }

    componentDidMount() {
      this.initial()
    }

    render() {
      const {
        match: {
          params: { category }
        }
      } = this.props
      const childProps = {
        ...this.state,
        category: decodeURI(category),
        turnTo: this.turnTo
      }
      return <WrappedComponent {...this.props} {...childProps} />
    }
  }
