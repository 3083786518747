import React from 'react'

export const Yes = (props) => {
  const { selected, stroke, fillColor } = props
  const circleStatus = selected
    ? { fill: fillColor ? fillColor : '#7BAD2D' }
    : { stroke: stroke || '#565656' }
  const pathStroke = selected ? 'white' : stroke || '#565656'
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="14.9999" r="14.5" {...circleStatus} />
      <path
        d="M8 15.9999L12 19.9999L22 9.99994"
        stroke={pathStroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const No = (props) => {
  const { selected } = props
  const circleStatus = selected ? { fill: '#E75F25' } : { stroke: '#565656' }
  const pathStroke = selected ? 'white' : '#565656'
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="14.9999" r="15" {...circleStatus} />
      <path
        d="M20.5 9.49994L9.5 20.4999"
        stroke={pathStroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9.49994L20.5 20.4999"
        stroke={pathStroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
