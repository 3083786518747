import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider, Button } from 'antd' // Progress
import ExerciseCard from './exerciseCard'
import ItemDetail from './itemDetail'
import ProgramItemEditor from './itemEditor'
// import { Start, Hourglass } from '../../../components/icons/program'
import Empty from '../../UI/status/empty'
// import PolygonAvatar from '../../UI/polygonAvatar'
// import SelectExperts from './selectExperts'
import DataStyle from '../style/programData.module.sass'
import EmptyImage from '../../../asserts/images/empty-status.png'
import _ from 'lodash'

class ProgramDataContainer extends Component {
  static propTypes = {
    summary: PropTypes.object,
    program: PropTypes.object,
    position: PropTypes.string,
    experts: PropTypes.array,
    changeExpert: PropTypes.func,
    releations: PropTypes.object
  }

  state = {
    showItemDetail: false,
    selectExercise: null,
    showExerciseEditor: false
  }

  getDateList = (weekRange, workouts) => {
    const { program } = this.props
    for (const workout of workouts) {
      if (workout.program_item_id) {
        const targetItem = program.items.find(
          (item) => item.id === workout.program_item_id
        )
        workout.name = targetItem && targetItem.name
      }
    }
    const { startDate, endDate } = weekRange
    const filterWorkouts = (targetDate) =>
      workouts.filter(
        (item) =>
          moment(item.record_date.split('T')[0]).format('YYYY-MM-DD') ===
          targetDate.format('YYYY-MM-DD')
      )

    const list = []
    let currentDate = startDate.clone()

    while (currentDate.isSameOrBefore(endDate)) {
      const listItem = {
        date: currentDate,
        workouts: filterWorkouts(currentDate)
      }
      list.push(listItem)
      currentDate = currentDate.clone().add(1, 'days')
    }
    return list
  }

  showDivider = (workouts, showItems, expiredDate) => {
    const show =
      (workouts && workouts.length > 0) ||
      (showItems && showItems.length > 0 && !expiredDate)
    return show ? <Divider /> : null
  }

  render() {
    const {
      summary,
      program,
      weekRange,
      person,
      editItemCallback,
      addAttachSync,
      removeAttachSync,
      editAttachTitleSync,
      modalContainerStyle,
      origin,
      position,
      expiredDate,
      itemDurationMapping,
      intensityRange,
      workoutTypes
    } = this.props
    let { showExerciseEditor, selectExercise, showItemDetail } = this.state
    const listDate = this.getDateList(weekRange, summary.workouts)
    if (selectExercise && !selectExercise.record_date) {
      selectExercise = summary.program_items.find(
        (item) => item.id === selectExercise.id
      )
    }
    if (!summary && !program) {
      return <>program</>
    }
    let showItems =
      program &&
      program.items &&
      (position === 'clientView'
        ? summary.program_items.filter(
            (item) => item.status === 1 || item.published_at ///
          )
        : summary.program_items)

    const hasContent =
      (showItems && showItems.length > 0) ||
      (summary.workouts && summary.workouts.length > 0)

    return (
      <>
        {hasContent ? (
          <>
            {((program && program.status === 1) || origin === 'overview') &&
              summary.workouts &&
              summary.workouts.length > 0 && (
                <div className={DataStyle.exerciseContainer}>
                  <div className={DataStyle.exerciseList}>
                    {listDate.map((item, index) => (
                      <div
                        className={DataStyle.exerciseItem}
                        key={`data-${index}`}
                      >
                        <div className={DataStyle.title}>
                          {moment(item.date).format('ddd, MMM DD')}
                        </div>
                        {item.workouts.map((workout, _index) => (
                          <ExerciseCard
                            isProgramActive={
                              (program && program.status === 1) || false
                            }
                            hasProgram={!_.isNil(program)}
                            width={139}
                            position={position}
                            key={`card-${_index}`}
                            exercise={workout}
                            isWorkout={true}
                            clickHandle={() =>
                              this.setState({
                                showItemDetail: true,
                                selectExercise: workout
                              })
                            }
                            items={(program && program.items) || []}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                  {showItems && showItems.length > 0 && !expiredDate && (
                    <Divider />
                  )}
                </div>
              )}

            {showItems && showItems.length > 0 && !expiredDate && (
              <div className={DataStyle.exerciseContainer}>
                <div className={DataStyle.title}>TO DO</div>
                <div className={`${DataStyle.exerciseList} ${DataStyle.todo}`}>
                  {showItems.map((item, index) => (
                    <ExerciseCard
                      isProgramActive={program.status === 1}
                      position={position}
                      key={`workout-${index}`}
                      exercise={item}
                      itemDurationMapping={itemDurationMapping}
                      clickHandle={() =>
                        this.setState({
                          showItemDetail: true,
                          selectExercise: item
                        })
                      }
                    />
                  ))}
                </div>
              </div>
            )}
            {origin === 'overview' &&
              this.showDivider(summary.workouts, showItems, expiredDate)}
          </>
        ) : (
          <div className={DataStyle.emptyStatus}>
            <Empty
              message="There is no exercise action items yet."
              emptyImage={EmptyImage}
            />
            {position !== 'clientView' && origin !== 'overview' && (
              <Button
                type="primary"
                onClick={() => this.setState({ showExerciseEditor: true })}
              >
                {/* ADD EXERCISE */}
                ACTION ITEM
              </Button>
            )}
            {origin === 'overview' && <Divider />}
          </div>
        )}

        {showExerciseEditor && (
          <ProgramItemEditor
            isProgramActive={program.status === 1}
            workoutTypes={workoutTypes}
            cancel={() => this.setState({ showExerciseEditor: false })}
            itemDurationMapping={itemDurationMapping}
            position={position || origin}
            exercise={selectExercise}
            programsId={program.id}
            personId={person.id}
            confirmCallback={() => {
              this.setState({ showExerciseEditor: false })
              editItemCallback()
            }}
            addAttachSync={addAttachSync}
            removeAttachSync={removeAttachSync}
            editAttachTitleSync={editAttachTitleSync}
          />
        )}

        {showItemDetail && (
          <ItemDetail
            item={selectExercise}
            position={position}
            programItems={(program && program.items) || []}
            modalContainerStyle={modalContainerStyle}
            itemDurationMapping={itemDurationMapping}
            intensityRange={intensityRange}
            closeHandle={() => this.setState({ showItemDetail: false })}
            operateHandle={(state) => this.setState(state)}
            deleteAsync={(workoutEdited) => {
              this.setState({ showItemDetail: false })
              editItemCallback(workoutEdited)
            }}
          />
        )}
      </>
    )
  }
}

export default ProgramDataContainer
