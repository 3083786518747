import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { CaretDownOutlined } from '@ant-design/icons'
import { Modal, Button, message } from 'antd'
import BasicForm from '../../formV4'
// import Attachments from '../../UI/attachment'
import {
  addProgramItem,
  editProgramItem,
  // deleteProgramItem,
  attachFile,
  attachLink,
  removeAttachment
} from '../../../api/program'
import { editAttachment } from '../../../api/microGoals'
// import { publishProgram } from '../../../api/program'
import { pattern } from '../../../utils/constant'
import style from '../style/programEditor.module.sass'
import ItemResources from './itemResources'

const formData = [
  {
    type: 'textarea',
    label: 'description',
    dbField: 'description',
    extraAttr: {
      row: 8
    }
  },
  {
    type: 'select',
    label: 'category',
    dbField: 'category',
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    options: [
      { label: 'Aerobic', value: 'Aerobic' },
      { label: 'Resistance', value: 'Resistance' },
      { label: 'Activity', value: 'Activity' }
    ],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'customizedSelect',
    label: 'workout type',
    dbField: 'workout_type',
    hideField: true,
    rules: [{ required: true, message: 'This field is required' }],
    unless: {
      or: [
        {
          key: 'category',
          value: 'Aerobic'
        },
        {
          key: 'category',
          value: 'Resistance'
        },
        {
          key: 'category',
          value: 'Activity'
        }
      ]
    },
    options: [
      // { label: 'Walking', value: 'Walking' },
      // { label: 'Running', value: 'Running' },
      // { label: 'Swimming', value: 'Swimming' },
      // { label: 'Cycling', value: 'Cycling' },
      // { label: 'Cardio', value: 'Cardio' },
      // { label: 'Cross Training', value: 'Cross Training' },
      // { label: 'Strength', value: 'Strength' },
      // { label: 'Yoga', value: 'Yoga' },
      // { label: 'Others', value: 'Others' }
    ],
    colSpan: 12,
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      ),
      mode: 'multiple',
      extraoptions: [
        {
          label: 'Personalized',
          value: 'Personalized',
          allowExtraText: true,
          placeholder: 'Personalized workout'
        }
      ]
    }
  },
  {
    type: 'select',
    label: 'duration',
    dbField: 'duration',
    suffix: <span style={{ color: '#8D99A5' }}>min</span>,
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'select',
    label: 'intensity',
    dbField: 'intensity',
    options: [
      { label: 'Low', value: 'Low' },
      { label: 'Moderate', value: 'Moderate' },
      { label: 'High', value: 'High' },
      { label: 'Very High', value: 'Very High' }
    ],
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }],
    extraAttr: {
      suffixIcon: (
        <CaretDownOutlined
          style={{ color: '#3F3F3F', pointerEvents: 'none' }}
        />
      )
    }
  },
  {
    type: 'input',
    label: 'frequency',
    dbField: 'frequency',
    colSpan: 12,
    rules: [
      { required: true, message: 'This field is required' },
      {
        pattern: pattern.integer,
        message: 'Please input an integer greater than zero'
      }
    ]
  }
]

class ProgramItemEditor extends Component {
  static propTypes = {
    programId: PropTypes.number,
    confirmCallback: PropTypes.func,
    exercise: PropTypes.object,
    personId: PropTypes.number,
    saveItemAttachCallback: PropTypes.func,
    position: PropTypes.string,
    editAttachTitleSync: PropTypes.func
  }

  state = { isSaving: false, formData }

  handleCancel = () => {
    const { confirmCallback, cancel } = this.props
    if (!this.props.exercise && this.state.exercise.id) {
      confirmCallback && confirmCallback()
    } else {
      cancel()
    }
  }

  handleOk = async (addAttach) => {
    const { programsId, confirmCallback, position } = this.props
    const { exercise } = this.state || this.props
    const { attachmentUrl } = this.state
    if (this.state.isSaving) return
    this.setState({ isSaving: true })
    try {
      const params = await this.basicFormRef.handleSubmit()
      if (params.workout_type) {
        params.workout_type = params.workout_type.join(',')
      } else {
        delete params.workout_type
      }
      if (attachmentUrl) {
        params.attachment_url = attachmentUrl
      }
      if (position === 'overview') {
        // publish program on overview page
        params.auto_publish = true
      }
      params.duration = params.duration / 60
      if (exercise && exercise.id) {
        await editProgramItem(params, programsId, exercise.id)
      } else {
        const { item } = await addProgramItem(params, programsId)
        if (addAttach) {
          this.setState({ exercise: item, isSaving: false })
        }
      }
      if (!addAttach) {
        confirmCallback && confirmCallback()
      }
      // if (position === 'overview') {
      //   // publish program on overview page
      //   await publishProgram(programsId)
      // }
    } catch (err) {
      console.error(err)
      this.setState({ isSaving: false })
      if (addAttach) {
        throw err
      }
    }
  }

  prefixUpload = async () => {
    const { prefixUploadCallback } = this.props
    try {
      await this.handleOk('addAttach')
      prefixUploadCallback && prefixUploadCallback()
    } catch (err) {
      throw err
    }
  }

  removeAttach = async (attach) => {
    const { removeAttachSync, personId, exercise } = this.props
    await removeAttachment(attach.id, personId)
    removeAttachSync && removeAttachSync(attach, exercise)
  }

  cancelUpload = () => {
    const { source } = this.state
    source && source.cancel()
    this.setState({ source: null })
  }

  saveLink = async (value) => {
    const { personId, addAttachSync } = this.props
    const { exercise } = this.state
    const result = await attachLink(exercise.id, personId, value)
    addAttachSync(result.attachment, exercise)
    exercise.attachments = exercise.attachments || []
    exercise.attachments.push(result.attachment)
    this.setState({ exercise })
  }

  uploadFile = async (file, callBack) => {
    const { personId, addAttachSync } = this.props
    const { exercise } = this.state
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ source, file })
      const result = await attachFile({
        personId,
        itemId: exercise.id,
        files: [file],
        source,
        extraInfo: {
          size: file.size
        },
        progressCallback: (percent) => {
          callBack(percent)
          if (percent === 100) {
            this.setState({ source: null })
          }
        }
      })
      addAttachSync(result.attachment, exercise)
      exercise.attachments = exercise.attachments || []
      exercise.attachments.push(result.attachment)
      this.setState({ exercise })
      return result
    } catch (err) {
      console.error(err)
      if (!axios.isCancel(err)) {
        message.error(err.message)
      }
    }
  }

  editAttachTitle = async (attach, title) => {
    const { personId, editAttachTitleSync } = this.props
    const { exercise } = this.state
    const { attachment } = await editAttachment(attach.id, personId, { title })
    const index = exercise.attachments.findIndex(
      (item) => item.id === attach.id
    )
    exercise.attachments.splice(index, 1, attachment)
    this.setState({ exercise })
    editAttachTitleSync(exercise)
  }

  onFormChange = (changedValues, allValues) => {
    const { workoutTypes } = this.props
    const { exercise: valueForm, formData } = this.state
    if (
      changedValues.category &&
      valueForm.category !== changedValues.category
    ) {
      // valueForm[fieldName] = field.value
      const targetField = formData.find(
        (item) => item.dbField === 'workout_type'
      )
      const targetOptions = workoutTypes.find(
        (item) => item.category === changedValues.category
      )
      const groupNames = targetOptions.types.map(({ group_name }) => group_name)
      targetField.options = groupNames.map((group_name) => ({
        label: group_name,
        value: group_name
      }))

      this.basicFormRef.setFieldsValue({
        workout_type: []
      })
    }

    if (changedValues.workout_type) {
      const addOptions = changedValues.workout_type.filter((item) =>
        item.includes('Personalized')
      )
      const targetFormData = formData.find(
        (item) => item.dbField === 'workout_type'
      )

      targetFormData.options = _.unionBy(
        targetFormData.options,
        addOptions.map((item) => ({ label: item, value: item })),
        'value'
      )
    }

    this.setState({ exercise: allValues })
  }

  onLinkChange = (linkType, value) => {
    if (linkType) {
      this.setState({
        attachmentUrl: {
          name: linkType,
          url: value
        }
      })
    } else {
      this.setState({
        attachmentUrl: null
      })
    }
  }

  componentDidMount() {
    const { position, itemDurationMapping, workoutTypes } = this.props
    let exercise = this.props.exercise ? this.props.exercise : {}
    exercise = _.cloneDeep(exercise)
    if (position === 'dashboard') {
      exercise = Object.assign(exercise, exercise.draft_data || {})
    }

    if (exercise.workout_type) {
      exercise.workout_type = exercise.workout_type.split(',')
    }
    if (itemDurationMapping) {
      formData.find(
        (item) => item.label === 'duration'
      ).options = itemDurationMapping
    }
    const workoutTypeField = formData.find(
      (item) => item.dbField === 'workout_type'
    )
    if (exercise.category) {
      const targetOptions = workoutTypes.find(
        (item) => item.category === exercise.category
      )
      const groupNames = targetOptions.types.map(({ group_name }) => group_name)
      workoutTypeField.options = groupNames.map((group_name) => ({
        label: group_name,
        value: group_name
      }))
    }
    this.setState({ exercise, formData })
  }

  render() {
    // const { position } = this.props
    const { exercise } = this.state || this.props
    const { formData, isSaving } = this.state
    const valueForm = exercise

    return (
      <Modal
        open={true}
        width={890}
        bodyStyle={{ padding: '36px 45px' }}
        onCancel={this.handleCancel}
        footer={null}
      >
        <h1 className={style.title}>
          {exercise && exercise.id ? 'Edit' : 'Add'} Action Item
        </h1>
        {valueForm && (
          <BasicForm
            formData={formData}
            initialValues={valueForm}
            onChange={this.onFormChange}
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          />
        )}
        <ItemResources
          attachments={
            (this.props.exercise && this.props.exercise.attachments) ||
            (exercise && exercise.attachments)
          }
          attachmentUrl={(exercise && exercise.attachment_url) || {}}
          prefixUpload={this.prefixUpload}
          removeAttach={this.removeAttach}
          cancelUpload={this.cancelUpload}
          uploadFile={this.uploadFile}
          saveLink={this.saveLink}
          description="Attach Files"
          targetId={exercise && exercise.id}
          editAttachTitle={this.editAttachTitle}
          onLinkChange={this.onLinkChange}
        />
        {/* <Attachments
          attachments={
            (this.props.exercise && this.props.exercise.attachments) ||
            (exercise && exercise.attachments)
          }
          prefixUpload={this.prefixUpload}
          removeAttach={this.removeAttach}
          cancelUpload={this.cancelUpload}
          uploadFile={this.uploadFile}
          saveLink={this.saveLink}
          description="Attach Files"
          targetId={exercise && exercise.id}
          editAttachTitle={this.editAttachTitle}
          confirmObj={{
            title: 'Save Action Item before attaching file?',
            description:
              'Action Items must be saved before attaching a file or URL.'
          }}
        /> */}

        <footer className={style.footer}>
          <div />
          <div>
            <Button ghost onClick={this.handleCancel}>
              CANCEL
            </Button>
            <Button
              type="primary"
              onClick={() => this.handleOk()}
              loading={isSaving}
            >
              {/* {position === 'overview' ? 'CONFIRM AND PUBLISH' : 'CONFIRM'} */}
              CONFIRM
            </Button>
          </div>
        </footer>
      </Modal>
    )
  }
}

export default ProgramItemEditor
