import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}
const n = 3

class ActivityBar extends Component {
  static propTypes = {
    period: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    datas: PropTypes.array,
    dataY: PropTypes.array,
    maxAxis: PropTypes.number,
    barColor: PropTypes.string
  }

  state = {}

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'week') {
      return moment(x).format('ddd')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).date() % 5 === 0) {
        result = moment(x).format('M/D')
      }

      return result
    }
  }

  initialMetaData = () => {
    const { datas, startDate, endDate, maxAxis } = this.props
    const dataX = [],
      dataArr = [],
      dataY = []
    let count = 1,
      day
    const dataHandle = (day) => {
      const data = datas.find(
        (item) => item.created_at === day.format('YYYY-MM-DD')
      )
      if (data) {
        dataArr.push({
          x: moment(data.created_at),
          y: Number(data.input)
        })
      }
    }
    dataX.push(moment(startDate))
    day = moment(startDate)
    dataHandle(day)
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      day = moment(startDate).add(count, 'days')
      dataX.push(day)
      dataHandle(day)
      count++
    }
    day = moment(endDate)
    dataX.push(day)
    dataHandle(day)
    let maxValue = Math.max(...dataArr.map((item) => item.y || 0))

    maxValue = (maxValue !== -Infinity && maxValue) || maxAxis
    maxValue =
      maxValue / n < 1
        ? (maxValue / n).toFixed(1) * n
        : parseInt(maxValue / n) * n
    let step = maxValue / n
    if (step < 10) {
      step = Math.ceil(step)
    } else {
      step = Math.ceil(step / 10) * 10
    }
    for (let i = 0; i <= n; i++) {
      dataY.push(i * step)
    }
    this.setState({ dataX, dataArr, dataY })
  }

  componentDidMount() {
    this.initialMetaData()
  }

  render() {
    const { period, barColor } = this.props
    const { dataArr, dataX, dataY } = this.state
    const gridStroken = (t) => {
      if (period === 'month' && moment(t).date() % 5 === 0) {
        return 'rgba(0,0,0,.5)'
      } else if (period === 'week') {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={width / 3}
            padding={{ top: 20, left: 50, bottom: 50, right: 50 }}
            domainPadding={20}
          >
            <VictoryAxis
              style={{
                axis,
                grid: {
                  strokeDasharray,
                  stroke: gridStroken
                },
                tickLabels: {
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fill: '#B0BAC9',
                  padding: (x) => {
                    if (
                      period === 'monthly' &&
                      moment(x).format('ddd') !== 'Sun' &&
                      moment(x).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD') &&
                      moment(x).date() % 5 !== 0
                    ) {
                      return 0
                    } else {
                      return 10
                    }
                  }
                }
              }}
              tickValues={dataX}
              tickLabelComponent={
                <VictoryLabel
                  text={(datum) => {
                    let result = this.xAxisFormat(datum)
                    return result
                  }}
                />
              }
            />
            <VictoryAxis
              dependentAxis
              style={{
                axis: {
                  strokeDasharray,
                  stroke: 'rgba(0,0,0,.5)'
                },
                grid: {
                  strokeDasharray,
                  stroke: 'rgba(0,0,0,.1)'
                },
                tickLabels: {
                  fontFamily: 'Gilroy',
                  fontSize: '10px',
                  fill: (v) => {
                    if (v < 2e-9) {
                      return 'transparent'
                    }
                    return '#B0BAC9'
                  }
                }
              }}
              tickValues={dataY}
            />

            <VictoryBar
              offsetX={10}
              style={{
                data: {
                  fill: barColor || '#264382'
                },
                axis
              }}
              cornerRadius={{ top: 2, bottom: 2 }}
              barWidth={period === 'month' ? 14 : 40}
              data={dataArr}
            />
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default ActivityBar
