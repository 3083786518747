import React, { useState } from 'react'
import { VictoryPie } from 'victory'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { resultScoreSectionStyle } from '../styles/resultScoreSection.sass'

function BreakdownContainer(props) {
  const [showAll, setShowAll] = useState(false)
  const { entries } = props
  let list
  if (entries.length > 4 && !showAll) {
    list = entries.slice(0, 4)
  } else {
    list = entries
  }
  return (
    <>
      <div className="entries-container">
        {list.map((entry, _index) => (
          <div className="entry" key={_index}>
            <span className="name">{entry.shortName}</span>
            <div className={`status ${entry.level}`}>{entry.level}</div>
          </div>
        ))}
      </div>
      {entries.length > 4 &&
        (showAll ? (
          <div className="more-opr" onClick={() => setShowAll(false)}>
            View Less <CaretUpOutlined />
          </div>
        ) : (
          <div className="more-opr" onClick={() => setShowAll(true)}>
            View More <CaretDownOutlined />
          </div>
        ))}
    </>
  )
}

export default function ResultScoreSection(props) {
  const getPipeData = (type) => {
    const { breakDown } = props
    const pipeData = [
      { x: 'AT RISK', y: 0, color: '#FF6B00', score: 1 },
      { x: 'CONCERN', y: 0, color: '#F5BA40', score: 2 },
      { x: 'OPTIMAL', y: 0, color: '#40C47C', score: 3 },
      { x: 'UNKNOW', y: 0, color: 'rgb(201, 208, 224)', score: null }
    ]
    const scoreEmun = {
      OPTIMAL: 3,
      CONCERN: 2,
      'AT RISK': 1
    }
    const entries = breakDown[type]
      ? breakDown[type].map((entry) => ({
          score: scoreEmun[entry.level]
        }))
      : [{ score: 1 }, { score: 2 }, { score: 3 }]
    return pipeData
      .map((data) => {
        if (data.score) {
          data.y = entries.filter((entry) => entry.score === data.score).length
        } else {
          data.y = entries.filter((entry) => !entry.score).length
        }

        return data
      })
      .filter((data) => data.y)
  }

  const renderSection = (type) => {
    const { summary, breakDown } = props
    const pipeData = getPipeData(type)
    return (
      <div className={resultScoreSectionStyle}>
        <div className="score-left">
          <div className="title">
            <span>{type}</span>
            <span className="value">
              {typeof summary[type] === 'number'
                ? Math.round(summary[type])
                : '--'}
              <span>/100</span>
            </span>
          </div>
          <div className="pipe-container">
            <div className="pipe">
              <VictoryPie
                innerRadius={100}
                data={pipeData}
                style={{
                  labels: { fill: 'transparent' },
                  data: {
                    fill: (datum) => datum.color
                  }
                }}
              />
            </div>
            <ul className="pipe-list">
              {pipeData.map((item, index) => (
                <li key={index}>
                  <div className="label-name">
                    <span style={{ background: item.color }} />
                    {item.x}
                  </div>
                  <div>{item.y}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="score-right">
          <div className="title">Breakdown</div>
          {breakDown[type] && <BreakdownContainer entries={breakDown[type]} />}
        </div>
      </div>
    )
  }
  return (
    <>
      {renderSection('exercise')}
      {renderSection('rejuvenation')}
      {renderSection('nutrition')}
    </>
  )
}
