import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import NutritionScoreContainer from '../../components/nutritionScore'
import LockStatus from '../../components/UI/lockStatus'
import { userType } from '../../utils/constant'
import { mobileNutritionScore } from './styles/nutritionScore.sass'

class NutritionScore extends Component {
  static propTypes = {}

  render() {
    const {
      match: {
        params: { personId, hash }
      },
      person
    } = this.props
    if (!person) return null
    const isPartner = person.profile.account_type === userType.Partners
    return isPartner ? (
      <LockStatus />
    ) : (
      <div className={mobileNutritionScore}>
        <NutritionScoreContainer
          person={{ id: personId }}
          loginRole="User"
          hideDescription={true}
          hash={hash}
          device="mobile"
        />
      </div>
    )
  }
}

export default NutritionScore
