import React from 'react'
import { VictoryScatter } from 'victory'

export function renderTravel(travelData, minY) {
  const data = travelData.map(({ date }) => ({ x: date, y: minY }))
  return <VictoryScatter data={data} dataComponent={<TravelIcon />} />
}

export function TravelIcon(props) {
  // const x = props.x && Number(props.x) - 10
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={(props.x && props.x - 10) || 0}
      y={(props.y && props.y - 10) || 0}
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="#FF6B00"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M11.6462 14.4658L11.7409 14.6465L11.8852 14.5023L12.5395 13.8479L12.5983 13.7891L12.5796 13.7081L11.6168 9.53593L13.3574 7.79529C13.6876 7.46516 13.6876 6.93178 13.3574 6.60165C13.0273 6.27152 12.4939 6.27152 12.1638 6.60165L10.4231 8.34229L6.25095 7.37948L6.16994 7.36079L6.11115 7.41957L5.45682 8.07391L5.31255 8.21818L5.49328 8.31285L8.74782 10.0176L7.1457 11.6197L6.07485 11.4668L6.0006 11.4562L5.94757 11.5092L5.45682 11.9999L5.3162 12.1406L5.49004 12.2371L6.92483 13.0342L7.72194 14.469L7.81852 14.6429L7.95913 14.5023L8.44989 14.0115L8.50292 13.9585L8.49231 13.8842L8.33933 12.8134L9.94146 11.2112L11.6462 14.4658Z"
        fill="white"
        stroke="white"
        strokeWidth="0.3"
      />
    </svg>
  )
}
