import React, { useState, useEffect } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'

function DatePickerItem(props) {
  const { format, disabledDate, disabled, extraAttr, onChange } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (props.value) {
      const date = moment(props.value, 'YYYY-MM-DD')
      setValue(date)
    }
  }, [props.value])

  const triggerChange = (date, dateString) => {
    setValue(date)
    if (onChange) {
      if (extraAttr && extraAttr.returnDate) {
        onChange(date)
      } else {
        onChange(dateString && moment(dateString).format('YYYY-MM-DD'))
      }
    }
  }

  return (
    <div>
      <DatePicker
        format={format || 'M/D/YYYY'}
        style={{ width: '100%' }}
        onChange={triggerChange}
        value={value}
        disabledDate={disabledDate}
        disabled={disabled}
        {...extraAttr}
      />
    </div>
  )
}

export default DatePickerItem
