import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'

const barWidth = 138
const BarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  .bar-item {
    width: ${barWidth}px;
    height: 6px;
    margin-left: 3px;
    position: relative;
    .label {
      position: absolute;
      color: #989ca2;
      font-size: 14px;
      margin-top: 22px;
    }
    .text-right {
      width: 100%;
      text-align: right;
    }
    .text-left {
      width: 100%;
      text-align: left;
    }
    .text-center {
      width: 100%;
      text-align: center;
    }
    .value {
      position: absolute;
      margin-top: -17px;
    }
  }
`

export default function RangeBar(props) {
  const { ranges, data } = props

  return (
    <BarContainer>
      {ranges.map((item, index) => (
        <div
          className="bar-item"
          key={index}
          style={{ background: item.color }}
        >
          <RangeItem {...item} data={data} />
        </div>
      ))}
    </BarContainer>
  )
}

function RangeItem(props) {
  const { data, name, color } = props
  const type = name.replace(' ', '_')
  let range = data.ranges[type]
  let rangeText, rangeClass
  if (Array.isArray(range)) {
    rangeText = range[0] > -Number.MAX_VALUE ? range[0] : ''
    rangeClass = 'text-left'
  } else {
    rangeText = range.label
    rangeClass = 'text-center'
    range = range.range
  }
  const hasHexagon =
    _.isFinite(data.value) && data.value >= range[0] && data.value < range[1]
  return (
    <>
      <div className={`label ${rangeClass}`}>{rangeText}</div>
      {hasHexagon && (
        <ValueIcon
          color={color}
          text={data.value}
          range={range}
          rangeClass={rangeClass}
        />
      )}
    </>
  )
}

function ValueIcon(props) {
  const { range, rangeClass, color, text } = props
  const offsetLeft = 8,
    offsetRight = 7,
    hexagonWidth = 12
  const marginLeftMin = -1 * offsetLeft,
    marginLeftMax = barWidth - hexagonWidth + offsetRight
  const totalWidthReference = marginLeftMax - marginLeftMin
  let marginLeft = 0
  if (rangeClass.includes('center')) {
    marginLeft = marginLeftMax / 2
  } else {
    let [min, max] = range
    min = min < 0 ? 0 : min
    max = max > 100 ? 100 : max
    marginLeft =
      (totalWidthReference * (Number(text) - min)) / (max - min) - offsetLeft
    if (text <= 0) {
      marginLeft = marginLeftMin
    } else if (text >= 100) {
      marginLeft = marginLeftMax
    } else if (
      (text >= 80 && text < 81) ||
      (text >= 90 && text < 91) ||
      (text >= 95 && text < 96)
    ) {
      marginLeft += 10
    } else if (
      (text > 70 && text < 80) ||
      (text > 89 && text < 90) ||
      (text > 94 && text < 95)
    ) {
      marginLeft -= 10
    }
  }
  return (
    <div className="value" style={{ marginLeft, marginTop: -4 }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="8"
          cy="7.99994"
          r="6"
          fill="white"
          stroke={color}
          strokeWidth="4"
        />
      </svg>
    </div>
  )
}
