import moment from 'moment'

export const categories = {
  medication: 'medication',
  supplement: 'supplement',
  allergy: 'allergy',
  medical_pre_screening: 'medical_pre_screening',
  active: 'active',
  inactive: 'inactive'
}

const formatDate = 'M/D/YYYY'

const unitsOptions = [
  {
    label: 'mcg',
    value: 'mcg'
  },
  {
    label: 'mg',
    value: 'mg'
  },
  {
    label: 'g',
    value: 'g'
  },
  {
    label: 'mL',
    value: 'mL'
  },
  {
    label: 'IU',
    value: 'IU'
  },
  {
    label: 'other',
    value: 'other'
  }
]

export const fixedUnits = unitsOptions
  .map(({ value }) => value)
  .filter((unit) => unit !== 'other')

const frequencyOptions = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'BID',
    value: 'BID'
  },
  {
    label: 'TID',
    value: 'TID'
  },
  {
    label: 'QID',
    value: 'QID'
  },
  {
    label: 'PRN - as needed',
    value: 'PRN - as needed'
  },
  {
    label: '1x/week',
    value: '1x/week'
  },
  {
    label: '2x/week',
    value: '2x/week'
  },
  {
    label: '3x/week',
    value: '3x/week'
  },
  {
    label: '4x/week',
    value: '4x/week'
  },
  {
    label: '5x/week',
    value: '5x/week'
  },
  {
    label: '6x/week',
    value: '6x/week'
  },
  {
    label: '7x/week',
    value: '7x/week'
  },
  {
    label: 'rarely/occasionally',
    value: 'rarely/occasionally'
  },
  {
    label: 'only when traveling',
    value: 'only when traveling'
  },
  {
    label: 'other',
    value: 'other'
  }
]

export function getMedicalForm(valueForm) {
  return [
    {
      type: valueForm.isMultiple ? 'textarea' : 'input',
      label: 'medication name',
      dbField: 'name',
      colSpan: valueForm.isMultiple ? 24 : 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      // type: 'number',
      type: 'input',
      label: 'dose',
      dbField: 'dose',
      colSpan: 12
    },
    {
      type: 'select',
      label: 'units',
      options: unitsOptions,
      dbField: 'unit',
      colSpan: valueForm.unit === 'other' ? 6 : 12
    },
    {
      type: 'input',
      label: 'unit text',
      dbField: 'unit_supplement',
      colSpan: 6,
      hideField: valueForm.unit !== 'other',
      rules: [{ required: true, message: 'Please input unit' }]
    },
    {
      type: 'select',
      label: 'FREQUENCY',
      dbField: 'frequency',
      options: frequencyOptions,
      colSpan: valueForm.frequency === 'other' ? 6 : 12
    },
    {
      type: 'input',
      label: 'frequency text',
      dbField: 'frequency_supplement',
      colSpan: 6,
      hideField: valueForm.frequency !== 'other',
      rules: [{ required: true, message: 'Please input frequency' }]
    },
    {
      type: 'date',
      label: 'Start date',
      dbField: 'start',
      format: formatDate,
      colSpan: 12,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.end &&
          moment(valueForm.end, formatDate).isBefore(currentDate)
      }
    },
    {
      type: 'date',
      label: 'end date',
      dbField: 'end',
      format: formatDate,
      colSpan: 12,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.start &&
          moment(valueForm.start, formatDate).isAfter(currentDate)
      }
    },
    {
      type: 'input',
      label: 'prescribed by',
      dbField: 'prescribing_by',
      colSpan: 12
    },
    {
      type: 'checkbox',
      text: 'Baseline Medication',
      dbField: 'baseline',
      colSpan: 12
    }
  ]
}

export function getSupplementsForm(valueForm) {
  return [
    {
      type: valueForm.isMultiple ? 'textarea' : 'input',
      label: 'Supplement name',
      dbField: 'name',
      colSpan: valueForm.isMultiple ? 24 : 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'BRAND',
      dbField: 'brand',
      colSpan: 12
    },
    {
      type: 'input',
      label: 'dose',
      dbField: 'dose',
      colSpan: valueForm.unit === 'other' ? 12 : 6
    },
    {
      type: 'select',
      label: 'units',
      options: unitsOptions,
      dbField: 'unit',
      colSpan: 6
    },
    {
      type: 'input',
      label: 'unit text',
      dbField: 'unit_supplement',
      colSpan: 6,
      hideField: valueForm.unit !== 'other',
      rules: [{ required: true, message: 'Please input unit' }]
    },
    {
      type: 'select',
      label: 'FREQUENCY',
      dbField: 'frequency',
      options: frequencyOptions,
      colSpan: valueForm.frequency === 'other' ? 6 : 12
    },
    {
      type: 'input',
      label: 'frequency text',
      dbField: 'frequency_supplement',
      colSpan: 6,
      hideField: valueForm.frequency !== 'other',
      rules: [{ required: true, message: 'Please input frequency' }]
    },

    {
      type: 'input',
      label: 'Recommended by',
      dbField: 'recommended_by',
      colSpan: 12
    },
    {
      type: 'date',
      label: 'Start date',
      dbField: 'start',
      format: formatDate,
      colSpan: 12,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.end &&
          moment(valueForm.end, formatDate).isBefore(currentDate)
      }
    },
    {
      type: 'date',
      label: 'end date',
      dbField: 'end',
      format: formatDate,
      colSpan: 12,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.start &&
          moment(valueForm.start, formatDate).isAfter(currentDate)
      }
    },
    {
      type: 'checkbox',
      text: 'Baseline Supplement',
      dbField: 'baseline',
      colSpan: 12
    }
  ]
}

export function getAllergiesForm(valueForm) {
  return [
    {
      type: 'select',
      label: 'type',
      dbField: 'allergy_type',
      options: [
        { label: 'Allergy', value: 'allergy' },
        { label: 'Intolerance', value: 'intolerance' },
        { label: 'Sensitive', value: 'sensitive' }
      ],
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: valueForm.isMultiple ? 'textarea' : 'input',
      label: 'name',
      dbField: 'name',
      rules: [{ required: true, message: 'This field is required' }]
    }
  ]
}

const activeTypeOptions = {
  active: [
    { label: 'Acute', value: 'acute' },
    { label: 'Chronic', value: 'chronic' }
  ],
  inactive: [
    { label: 'History', value: 'history' },
    { label: 'Resolved', value: 'resolved' }
  ]
}

export function getConditionForm(valueForm, type) {
  return [
    {
      type: valueForm.isMultiple ? 'textarea' : 'input',
      label: 'condition name',
      dbField: 'name',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'date',
      label: 'Start date',
      dbField: 'start',
      format: formatDate,
      colSpan: 12,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.end &&
          moment(valueForm.end, formatDate).isBefore(currentDate)
      }
    },
    {
      type: 'date',
      label: 'end date',
      dbField: 'end',
      format: formatDate,
      colSpan: 12,
      hideField: type === categories.active,
      extraAttr: {
        disabledDate: (currentDate) =>
          valueForm.start &&
          moment(valueForm.start, formatDate).isAfter(currentDate)
      }
    },
    {
      type: 'select',
      label: 'type',
      dbField: type === categories.active ? 'active_type' : 'inactive_type',
      colSpan: 12,
      options: activeTypeOptions[type],
      rules: [{ required: true, message: 'This field is required' }]
    }
  ]
}
