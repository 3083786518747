import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popover, Row, Col, message, Typography } from 'antd'
import '../adminTool/index.sass'
import ModalForm from '../../components/modalFrom'
import { createCategory, createSubCategory } from '../../models/goals.form'
import * as microGoals from '../../api/microGoals'

const { Paragraph } = Typography

class Categories extends Component {
  static propTypes = {
    categoriesOpr: PropTypes.func
  }
  state = {
    modalTitle: '',
    visible: false,
    formData: null,
    valueForm: null,
    loading: false,
    submitType: ''
  }

  submit = async (params) => {
    const { categoriesOpr, pillar, categories } = this.props
    const { submitType, selectCategoryName, selectCategoryDesc } = this.state
    let result, request
    try {
      switch (submitType) {
        case 'add category':
          request = {
            pillar,
            category: params.name,
            category_description: params.description,
            sub_category: 'General'
          }

          result = await microGoals.createCategories(request)
          categoriesOpr(result, 'add', pillar)
          break
        case 'edit category':
          const updateCategories = categories.filter(
            (item) => item.category === selectCategoryName
          )
          request = {
            category: params.name,
            category_description: params.description
          }
          for (let item of updateCategories) {
            result = await microGoals.editCategories(request, item.id)
            categoriesOpr(result, 'update', pillar)
          }
          break
        case 'add sub category':
          request = {
            pillar,
            category: selectCategoryName,
            category_description: selectCategoryDesc,
            sub_category: params.name,
            sub_category_description: params.description
          }
          result = await microGoals.createCategories(request)
          categoriesOpr(result, 'add', pillar)
          break
        case 'edit sub category':
          const updateCategory = categories.filter(
            (item) => item.sub_category === selectCategoryName
          )[0]
          request = {
            sub_category: params.name,
            sub_category_description: params.description
          }
          result = await microGoals.editCategories(request, updateCategory.id)
          categoriesOpr(result, 'update', pillar)
          break
        default:
          break
      }
      this.setState({ categories, visible: false })
    } catch (err) {
      console.error(err)
      message.error(err.message)
    }
  }

  deleteSubcategory = async (category) => {
    const { pillar, categoriesOpr } = this.props
    try {
      await microGoals.deleteSubcategories(category.id)
      categoriesOpr(category, 'delete', pillar)
    } catch (err) {
      console.error(err)
    }
  }

  groupCategories = () => {
    const { categories } = this.props
    const groupCategories = []
    for (let category of categories) {
      const index = groupCategories.findIndex(
        (item) => item.name === category.category
      )
      if (index !== -1) {
        groupCategories[index].subCategories.push(category)
      } else {
        groupCategories.push({
          name: category.category,
          subCategories: [category]
        })
      }
    }
    return groupCategories
  }

  renderSubcategoriesGroup(category) {
    const subCategories = category.subCategories
    const containerSize = 4
    const length =
      subCategories &&
      subCategories.length > 0 &&
      Math.ceil(subCategories.length / containerSize)
    let cols
    if (length) cols = new Array(length).fill(0)
    else cols = []
    return <>
      {cols.map((empty, index) => (
        <Col span={6} key={index}>
          {subCategories
            .slice(index * containerSize, (index + 1) * containerSize)
            .map(
              (item, index) =>
                item && (
                  <span
                    key={index}
                    onClick={() => {
                      this.setState({
                        visible: true,
                        submitType: 'edit sub category',
                        valueForm: {
                          name: item.sub_category,
                          description: item.sub_category_description
                        },
                        selectCategoryName: item.sub_category,
                        modalTitle: 'Edit sub category',
                        formData: createCategory
                      })
                    }}
                  >
                    <span className="biomarker-item">
                      <CloseOutlined
                        onClick={(e) => {
                          e.stopPropagation()
                          this.deleteSubcategory(item)
                        }} />
                      <span>
                        <Paragraph ellipsis>{item.sub_category} </Paragraph>
                      </span>
                    </span>
                    <br />
                  </span>
                )
            )}
        </Col>
      ))}
    </>
  }

  render() {
    const { modalTitle, visible, formData, valueForm, loading } = this.state
    const categories = this.groupCategories()
    return (
      <div>
        <Button
          type="primary"
          ghost
          onClick={() =>
            this.setState({
              modalTitle: 'Create a category',
              submitType: 'add category',
              valueForm: {},
              visible: true,
              formData: createCategory
            })
          }
        >
          Add Category{' '}
        </Button>
        {categories.map(
          (item, index) =>
            item && (
              <div className="category" key={index}>
                <h5>
                  <Popover
                    placement="bottomLeft"
                    content={
                      <p style={{ maxWidth: '200px' }}>{item.description}</p>
                    }
                  >
                    <span>{item.name}</span>
                  </Popover>
                  <Button
                    onClick={(_) =>
                      this.setState({
                        visible: true,
                        submitType: 'edit category',
                        valueForm: {
                          name: item.name,
                          description:
                            item.subCategories[0].category_description
                        },
                        selectCategoryName: item.name,
                        modalTitle: 'Edit category',
                        formData: createCategory
                      })
                    }
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      this.setState({
                        visible: true,
                        submitType: 'add sub category',
                        valueForm: {},
                        categoryId: item.id,
                        selectCategoryName: item.name,
                        selectCategoryDesc:
                          item.subCategories[0].category_description,
                        modalTitle: `CATEGORY:${item.name}`,
                        formData: createSubCategory
                      })
                    }
                  >
                    Add Sub Category
                  </Button>
                </h5>
                <Row type="flex" className="biomarkers" gutter={16}>
                  {this.renderSubcategoriesGroup(item)}
                </Row>
              </div>
            )
        )}
        <ModalForm
          title={modalTitle}
          visible={visible}
          formData={formData}
          valueForm={valueForm || {}}
          onSubmit={this.submit}
          onCancel={() => this.setState({ visible: false })}
          loading={loading}
        />
      </div>
    )
  }
}

export default Categories
