import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { CloseOutlined } from '@ant-design/icons'

const Container = styled.span`
  background-color: ${props => (props.isDragging ? 'lightgray' : 'white')};
`

class Metric extends Component {
  static propTypes = {
    metric: PropTypes.object,
    index: PropTypes.number,
    history: PropTypes.object
  }

  render() {
    const { metric, index, deleteBiomarker, history, path } = this.props
    return (
      <Draggable
        draggableId={`metric-${metric.id}`}
        index={index}
        type="metric"
      >
        {(provided, snapshot) => (
          <Container
            className="biomarker-item"
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            isDragging={snapshot.isDragging}
            onClick={() => {
              history.push(`/${path}/${metric.id}`)
            }}
          >
            <CloseOutlined
              onClick={e => {
                e.stopPropagation()
                deleteBiomarker()
              }} />
            <span>{metric.name}</span>
          </Container>
        )}
      </Draggable>
    )
  }
}

export default Metric
