import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { styles } from './index.sass'

const menuList = [
  {
    path: '/common-tools/facilities',
    key: 'facilities',
    label: 'Facilities & Experts'
  },
  {
    path: '/common-tools/recommended',
    key: 'recommended',
    label: 'Resources'
  },
  {
    path: '/common-tools/challenge',
    key: 'challenge',
    label: 'Challenge'
  },
  {
    path: '/common-tools/email-report',
    key: 'email-report',
    label: 'Summary Email Report'
  },
  {
    path: '/common-tools/email-template',
    key: 'email-template',
    label: 'Summary Email Template'
  },
  {
    path: '/common-tools/data-aggregation',
    key: 'data-aggregation',
    label: 'Data Aggregation'
  },
  {
    path: '/common-tools/timeline-report',
    key: 'timeline-report',
    label: 'Timeline Report'
  },
  {
    path: '/common-tools/hsd-weighting',
    key: 'hsd-weighting',
    label: 'HSD Weighting'
  },
  {
    path: '/common-tools/event-registration',
    key: 'event-registration',
    label: 'Event Registration'
  },
  {
    path: '/common-tools/partnership-registration',
    key: 'partnership-registration',
    label: 'Partnership Registration'
  },
  {
    path: '/common-tools/appointments',
    key: 'appointments',
    label: 'Appointments'
  },
  {
    key: 'download-csvs',
    path: '/common-tools/download-csvs',
    label: 'Download CSVs'
  }
]

class SlideMenu extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object
  }

  state = {
    selectedKeys: ['expert']
  }

  handleClick = (e) => {
    const { history } = this.props
    const menu = menuList.find((item) => item.key === e.key)
    this.setState({ selectedKeys: [menu.key] })
    history.push(menu.path)
  }

  render() {
    const {
      location: { pathname }
    } = this.props
    const key = pathname.split('/')[2]

    return (
      <div className={styles}>
        <Menu
          onClick={this.handleClick}
          selectedKeys={[key]}
          mode="inline"
          items={menuList}
        />
      </div>
    )
  }
}

export default SlideMenu
