import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import success from '../../../asserts/images/success.png'
import celebrate from '../../../asserts/images/celebrate.png'

const Container = styled.div`
  width: 400px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    color: #323f4a;
    font-size: 36px;
    margin-bottom: 36px;
  }
  img {
    margin-bottom: 36px;
  }
  .content {
    color: #52606c;
    font-size: 16px;
    // font-family: 'Heebo';
    letter-spacing: 0.3px;
    line-height: 150%;
  }
  button {
    width: 229px;
    height: 48px;
    margin-top: 20px;
  }
`

export default function CompletedMessage(props) {
  const { type, goNext } = props
  const isYes = type === 'yes'
  return (
    <Container>
      {isYes && <h1>Success</h1>}
      <img src={isYes ? success : celebrate} alt="completed" />
      {isYes ? (
        <div className="content">
          <p>
            Thank you for completing our pre-assessment medical review. Your
            health and safety is a top priority to us. Based on your responses,
            we would like to know more about your heart health so we can
            customize the safest physical assessment for you at Apeiron Life.
            Our medical director, Dr. Dev Mishra, will reach out for a brief
            discussion to see how we can best support you. Thank you for
            understanding.
          </p>
          <Button type="primary" size="large" onClick={() => goNext('/logout')}>
            LOG OUT
          </Button>
        </div>
      ) : (
        <div className="content">
          <p>
            Thank you for completing our pre-assessment medical review. Please
            click "NEXT" to add Logistical and Initial Intake details that will
            help us design your action plan.
          </p>
          <p>
            This should take less than 15 minutes. We look forward to learning
            more about you and supporting your health and well-being!
          </p>
          <Button size="large" type="primary" onClick={() => goNext()}>
            NEXT
          </Button>
          {/* <br />
          <Button
            style={{ width: 229, padding: 0, marginTop: 16 }}
            size="large"
            type="primary"
            ghost
            onClick={logOut}
          >
            I WILL COME BACK LATER
          </Button> */}
        </div>
      )}
    </Container>
  )
}
