import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setClientUnreadCount } from '../../redux/client/action'
import { Divider, message } from 'antd'
import PageLoading from '../UI/status/loading'
import MessageItem from './messageItem'
import { Card } from '../supportTeam/container'
import Editor from './editor'
import { getMessages, sendMessage } from '../../api/message'
import { getSupportTeam } from '../../api/team'
import { getRole } from '../../utils/common'
import { messageStyle } from './index.sass'

class Message extends Component {
  static propTypes = {
    clientId: PropTypes.number,
    markRead: PropTypes.func
  }

  state = { loading: true, text: '', submitting: false, rich_text: '' }

  submitRichText = async () => {
    const { rich_text } = this.state
    const { clientId } = this.props
    if (!rich_text) return
    this.setState({ submitting: true })
    try {
      const { messages } = await sendMessage({ rich_text }, clientId)
      this.setState({
        submitting: false,
        messages: messages.reverse(),
        rich_text: ''
      })
    } catch (err) {
      message.error(err.message)
      this.setState({ submitting: false })
    }
  }

  submit = async (text) => {
    text = text || this.state.text
    const { clientId } = this.props
    if (!text) return
    this.setState({ submitting: true })
    try {
      const { messages } = await sendMessage({ text }, clientId)
      this.setState({
        submitting: false,
        messages: messages.reverse(),
        text: ''
      })
    } catch (err) {
      message.error(err.message)
      this.setState({ submitting: false })
    }
  }

  initial = async () => {
    const { clientId, markRead, setClientUnreadCount } = this.props
    const { messages } = await getMessages(clientId)
    if (markRead) {
      markRead()
    }
    const { al_team_member } = await getSupportTeam(clientId)
    this.setState({
      messages: messages.reverse(),
      loading: false,
      al_team_member
    })
    setClientUnreadCount(0)
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { loading, messages, al_team_member } = this.state
    const loginRole = getRole(this.props.sender.role)
    return (
      <div className={messageStyle}>
        <Editor
          loading={loading}
          clientId={this.props.clientId}
          onSubmitCallback={(messages) => this.setState({ messages })}
        />
        <Divider />
        {loading || !messages ? (
          <PageLoading />
        ) : messages.length > 0 ? (
          <div className="message-list">
            {messages.map((item, index) => (
              <MessageItem
                clientId={this.props.clientId}
                {...item}
                key={item.id || index}
              />
            ))}
          </div>
        ) : (
          <div className="empty-container">
            <div className="team-member">
              <Card
                isActive
                role_name="Client Advocate"
                roles={[]}
                name={
                  al_team_member.client_advocate &&
                  al_team_member.client_advocate.full_name
                }
                avatar={
                  al_team_member.client_advocate &&
                  al_team_member.client_advocate.avatar
                }
              />
            </div>
            <div className="team-member">
              <Card
                isActive
                role_name="Concierge"
                roles={[]}
                name={
                  al_team_member.concierge && al_team_member.concierge.full_name
                }
                avatar={
                  al_team_member.concierge && al_team_member.concierge.avatar
                }
              />
            </div>
            <div className="team-member">
              <Card
                isActive
                role_name="Movement & Exercise Lead"
                roles={[]}
                name={
                  al_team_member.performance_team_lead &&
                  al_team_member.performance_team_lead.full_name
                }
                avatar={
                  al_team_member.performance_team_lead &&
                  al_team_member.performance_team_lead.avatar
                }
              />
            </div>
            <div className="empty-info">
              <p>
                Write a message to begin chatting with{' '}
                {loginRole === 'Expert' ? 'client' : 'your Apeiron Life team.'}
              </p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect(
  null,
  (dispatch) => ({
    setClientUnreadCount: (count) => dispatch(setClientUnreadCount(count))
  })
)(Message)
