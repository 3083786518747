import React, { useCallback, useState } from 'react'
import moment from 'moment'
import { Modal, Button, message } from 'antd'
import BasicForm from '../../formV4'
import style from '../../../components/program/style/programEditor.module.sass'
import { assign } from 'lodash'
import { saveOverviewSummary } from '../../../api'

const FormModels = [
  {
    type: 'number',
    label: 'weight (lbs)',
    dbField: 'weight',
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'originDate',
    label: 'time',
    dbField: 'date',
    extraAttr: {
      format: 'YYYY-MM-DD HH:mm:ss',
      disabledDate: (currentDate) => moment().isBefore(currentDate),
      showTime: {
        defaultValue: moment('00:00:00', 'HH:mm:ss')
      }
    },
    colSpan: 12,
    rules: [{ required: true, message: 'This field is required' }]
  }
]

const AddWeightModal = (props) => {
  const [saving, setSaving] = useState(false)
  const [refForm, setRefForm] = useState(null)
  const onOk = useCallback(async () => {
    try {
      const data = await refForm.handleSubmit()
      const params = assign(
        {},
        {
          type: 'weight',
          pillar: 'nutrition',
          weight: data.weight,
          record_date: data.date.format('YYYY-MM-DD'),
          weight_at: data.date.unix()
        }
      )
      setSaving(true)
      await saveOverviewSummary(props.personId, [params])
      message.success('New weight added')
      props.onDone()
    } catch (err) {
    } finally {
      setSaving(false)
    }
  })

  return (
    <Modal
      open={true}
      width={890}
      bodyStyle={{ padding: '36px 45px' }}
      onOk={onOk}
      onCancel={props.onCancel}
      footer={null}
    >
      <h1 className={style.title}>Add Weight</h1>
      <BasicForm
        formData={FormModels}
        initialValues={{
          time: moment()
        }}
        wrappedComponentRef={(inst) => setRefForm(inst)}
      />
      <footer className={style.footer}>
        <div></div>
        <div>
          <Button ghost onClick={props.onCancel}>
            CANCEL
          </Button>
          <Button type="primary" onClick={onOk} loading={saving}>
            SAVE
          </Button>
        </div>
      </footer>
    </Modal>
  )
}

export default AddWeightModal
