import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import LockStatus from '../../../components/UI/lockStatus'
import { loadUser } from '../../../utils/storage'
import ProgramList from '../../../components/program/programList'
import { userType } from '../../../utils/constant'

const Container = styled.div`
  padding-right: 50px;
`
class ProgramView extends Component {
  static propTypes = {}

  render() {
    const person = loadUser()
    const isNonCore = person.profile.account_type === userType.Non_Core
    if (isNonCore) {
      return (
        <>
          <h1 className="title">Programs</h1>
          <LockStatus type="NON-CORE" />
        </>
      )
    }
    return person ? (
      <Container>
        <h1>Programs</h1>
        <ProgramList person={person} position="clientView" {...this.props} />
      </Container>
    ) : (
      <div />
    )
  }
}

export default ProgramView
