import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-right: 24px;
  font-family: Gilroy;
  cursor: pointer;
  .avatar-container {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: rgba(38, 67, 130, 0.1);
    margin-right: 12px;
    .avatar {
      width: 32px;
      height: 32px;
      margin-left: 0px;
    }
  }

  .name {
    font-size: 16px;
    color: #323f4a;
  }
`

export const DisplayClient = ({ client, onClick }) => {
  const person = client.person
  return (
    <Container onClick={onClick}>
      <div className="avatar-container">
        {person && person.avatar && (
          <img className="avatar" src={person.avatar.oss_url} alt="" />
        )}
      </div>
      {person && person.profile && (
        <div className="name">
          {`${(person.profile && person.profile.nickname) ||
            person.first_name} ${person.last_name}`}
        </div>
      )}
    </Container>
  )
}
