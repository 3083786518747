import React, { useState } from 'react'
import { Modal, Button, Checkbox, message } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  font-family: Gilroy;
  .page-title {
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    letter-spacing: 0.3px;
    color: #3c4e5f;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ant-checkbox-group {
    display: flex;
    padding-left: 16px;
    flex-direction: column;
    & > label {
      height: 38px;
      font-weight: 500;
    }
  }
`

export default function SendMultipleQuestionnaireModal(props) {
  const { onCancel, options, confirm } = props

  const [checkedValues, setCheckedValues] = useState([])

  return (
    <Modal
      open={true}
      onCancel={onCancel}
      className="phs-modal"
      width={560}
      footer={
        <>
          <Button
            onClick={() => {
              setCheckedValues(options.map((o) => o.value))
            }}
            type="primary"
            ghost
            style={{ float: 'left' }}
          >
            SELECT ALL
          </Button>
          <Button
            onClick={onCancel}
            type="text"
            style={{
              width: '84'
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              if (checkedValues.length === 0) {
                message.error('Please select at least one!')
              } else {
                confirm({
                  checkedValues,
                  qNames: checkedValues.map((item) => {
                    return options.find((o) => o.value === item).label
                  })
                })
              }
            }}
            type="primary"
          >
            CONFIRM
          </Button>
        </>
      }
    >
      <Container>
        <h1 className="page-title">Select Questionnaire</h1>
        <Checkbox.Group
          options={options}
          value={checkedValues}
          onChange={(checkedValues) => {
            setCheckedValues(checkedValues)
          }}
        ></Checkbox.Group>
      </Container>
    </Modal>
  )
}
