import React, { Component } from 'react'
import { Select, Modal, Button, message } from 'antd'
import MediaTypeSwitch from './mediaType'
import ContentCard from './card'
import icoPlus from './assets/icon_plus.svg'
import icoSelectImg from './assets/icon_select_img.svg'
import './recommended.sass'
import BasicForm from '../../../components/formV4'
import {
  getRecommendedContent,
  getUrlTitleCover,
  delRecommendedContent,
  addRecommendedContent,
  editRecommendedContent,
  getCategories
} from '../../../api/recommend'
import { getBlogUrl } from '../../../utils/common'

const blankCnt = {
  category: 'nutrition',
  sub_category: 'Default',
  link: '',
  title: '',
  description: '',
  cover: '',
  cover_url: '',
  cover_type: 'url',
  link_domain: '',
  estimate_view_time: 0,
  media_type: 'article',
  external_link: ''
}

const sendValidKeys = {
  link: true,
  title: true,
  description: true,
  cover: true,
  cover_type: true,
  estimate_view_time: true,
  media_type: true,
  file: true,
  category: true,
  sub_category: true
}

class Recommended extends Component {
  // {
  //   categories: any[]
  //   curCategory: any
  //   showAddModal: boolean
  //   showEditModal: boolean
  //   modalAddVals: any & { [k: string]: any }
  //   modalEditVals: any & { [k: string]: any }
  //   modalAddProcessing: boolean
  //   modalEditProcessing: boolean
  //   subCategories: string[]
  //   contentList: any[]
  // }
  state = {
    categories: [],
    curCategory: 'All',
    showAddModal: false,
    showEditModal: false,
    modalAddVals: { ...blankCnt },
    modalEditVals: { ...blankCnt },
    modalAddProcessing: false,
    modalEditProcessing: false,
    subCategories: [],
    contentList: []
  }

   editFormRef
   addFormRef

   categoryStrId = {}
   chgCoverEle

  render() {
    const {
      categories,
      curCategory,
      contentList,
      showAddModal,
      showEditModal,
      modalAddVals,
      modalEditVals,
      modalAddProcessing,
      modalEditProcessing
    } = this.state
    let contents = contentList.filter(
      (item) => item && item.content_category.category === curCategory
    )

    if (curCategory === 'All') {
      contents = contentList
    }
    return (
      <div>
        <div className="page-title">Resources</div>
        <div className="recommened-content">
          <div className="catergory-filter">
            <span className="tag">FILTER BY :</span>
            <Select
              className="catergory-sel"
              value={curCategory}
              onChange={this.onChgCategory}
            >
              {['All', ...categories.map((category) => category.name)].map(
                (c, idx) => (
                  <Select.Option key={`c--${idx}`} value={c}>
                    {c[0].toUpperCase() + c.substr(1)}
                  </Select.Option>
                )
              )}
            </Select>
          </div>

          <div className="content-cards">
            {contents.map((c) => (
              <div
                key={`rc-cd-${c.id}`}
                onClick={() => this.openEditCardModal(c)}
              >
                <ContentCard
                  data={c}
                  // onRemove={this.removeCard}
                />
              </div>
            ))}
            <div className="add-new-card" onClick={this.addCard}>
              <img src={icoPlus} alt="" />
              <span>Add reading content</span>
            </div>
          </div>
        </div>

        {showAddModal && (
          <Modal
            visible={showAddModal}
            className="modal-recommend modal-add-recommend"
            width={890}
            onOk={this.editCard}
            onCancel={this.hideModal}
            cancelText="CANCEL"
            okText="CONFIRM"
            destroyOnClose
            confirmLoading={modalAddProcessing}
          >
            <h1 className="body-title">Add Reading Content</h1>
            <BasicForm
              formData={this.genModalAddFormData()}
              initialValues={modalAddVals}
              onChange={this.modalAddValChg}
              wrappedComponentRef={(inst) => (this.addFormRef = inst)}
            />
            <span className="min-tip">min</span>
          </Modal>
        )}
        {showEditModal && (
          <Modal
            visible={showEditModal}
            className="modal-recommend modal-edit-recommend"
            width={890}
            onCancel={this.hideModal}
            maskClosable={false}
            destroyOnClose
            confirmLoading={modalEditProcessing}
            footer={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>
                  {modalEditVals.id && (
                    <Button
                      style={{ color: '#EA5400', boxShadow: 'none' }}
                      onClick={() => this.removeCard(modalEditVals.id)}
                      disabled={modalEditProcessing}
                    >
                      DELETE
                    </Button>
                  )}
                </span>
                <span>
                  <Button
                    onClick={this.hideModal}
                    disabled={modalEditProcessing}
                  >
                    CANCEL
                  </Button>
                  <Button
                    type="primary"
                    onClick={this.sendEdit}
                    loading={modalEditProcessing}
                  >
                    CONFIRM{' '}
                  </Button>
                </span>
              </div>
            }
          >
            <h1 className="body-title">
              {' '}
              {modalEditVals.id ? 'Edit' : 'Add'} Reading Content
            </h1>
            <BasicForm
              formData={[
                ...this.genModalAddFormData(),
                ...this.genModalEditFormData()
              ]}
              initialValues={modalEditVals}
              onChange={this.modalEditValChg}
              wrappedComponentRef={(inst) => (this.editFormRef = inst)}
            />
            <span className="min-tip">min</span>
            <span
              className="character-tip copy-tip"
              onClick={() => {
                navigator.clipboard.writeText(modalEditVals.external_link)
                message.success('Link copied!')
              }}
            >
              copy
            </span>
            <span className="character-tip">
              {modalEditVals.title.length}/80 characters
            </span>
            <span className="character-tip description-tip">
              {modalEditVals.description.length}/80 characters
            </span>
            <div className="over-view-con">
              <h3 className="title">CARD OVERVIEW</h3>
              <div className="card-infos">
                <ContentCard data={this.getAddingCardData()} />
                <div className="right-info">
                  <MediaTypeSwitch
                    switch={this.switchAddingCntType}
                    type={modalEditVals.media_type}
                  />
                  <div
                    className="change-cover"
                    onClick={this.changeAddingCntCover}
                  >
                    <img src={icoSelectImg} alt="file" />
                    <span>Click to change image cover </span>
                  </div>
                  <div className="img-tip">
                    Image cover is recommended to be 237x114 px
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    )
  }

  UNSAFE_componentWillMount() {
    this.fetchData()
  }

  openEditCardModal(content) {
    const {
      content_category: { category, sub_category },
      link,
      title,
      description,
      cover,
      cover_url,
      cover_type,
      estimate_view_time,
      media_type,
      file,
      id
    } = content
    const modalEditVals = {
      id,
      link,
      external_link: link,
      title,
      description,
      cover,
      cover_url,
      cover_type,
      estimate_view_time,
      media_type,
      file,
      category,
      sub_category
    }

    if (link.includes('blog.apeiron.life')) {
      modalEditVals.external_link = getBlogUrl(link.split('/').pop())
    }

    this.setState({
      modalEditVals,
      modalAddVals: modalEditVals,
      showEditModal: true
      // curCategory: category
    })
  }

  genModalAddFormData() {
    const { categories, modalAddVals } = this.state

    const category = categories.find(
      (category) => category.name === modalAddVals.category
    )
    const subCategory = category ? [...category.subCategory, 'new'] : ['new']
    return [
      {
        label: 'category',
        dbField: 'category',
        type: 'select',
        defaultValue: categories.length ? categories[0].name : '',
        options: categories.map((c) => ({
          label: c.name[0].toUpperCase() + c.name.substr(1),
          value: c.name
        })),
        colSpan: 8
      },
      {
        label: 'Sub category',
        dbField: 'sub_category',
        type: 'select',
        defaultValue: subCategory.length ? subCategory[0] : '',
        options: subCategory.map((c) => ({
          label: c[0].toUpperCase() + c.substr(1),
          value: c
        })),
        colSpan: 8
      },
      {
        label: 'New Sub category',
        type: 'input',
        dbField: 'new_sub_category',
        hideField: true,
        unless: {
          key: 'sub_category',
          value: 'new'
        },
        colSpan: 8
      },
      {
        hideField: true,
        unless: {
          key: 'sub_category',
          value: 'new',
          not: true
        },
        colSpan: 8
      },
      {
        label: 'LINK',
        dbField: 'link',
        disabled: !!modalAddVals.id,
        type: 'input',
        placeholder: 'Paste a link to reading content here',
        colSpan: 15
      },
      {
        label: 'ESTIMATE VIEW TIME',
        dbField: 'estimate_view_time',
        type: 'input',
        inputType: 'number',
        placeholder: '0',
        colSpan: 9
      }
    ]
  }

  genModalEditFormData() {
    return [
      {
        label: 'EXTERNAL LINK',
        type: 'input',
        dbField: 'external_link',
        disabled: true
      },
      {
        label: 'TITLE',
        type: 'input',
        dbField: 'title',
        maxLength: 80,
        placeholder: 'edit the title of the link',
        rules: [{ max: 80 }]
      },
      {
        label: 'DESCRIPTION',
        type: 'input',
        dbField: 'description',
        maxLength: 80,
        placeholder: 'Fill in the description of the link here',
        rules: [{ max: 80 }]
      }
    ]
  }

  getAddingCardData() {
    const {
      modalEditVals: {
        link,
        cover,
        cover_url,
        cover_type,
        category,
        sub_category,
        title,
        description,
        estimate_view_time,
        media_type
      }
    } = this.state
    let card = {
      id: 'blank',
      link,
      cover,
      cover_url,
      cover_type,
      content_category: { category, sub_category },
      title,
      description,
      link_domain: this.getLinkDomain(link),
      estimate_view_time,
      media_type
    }
    return card
  }

  getLinkDomain(link) {
    let res = link.replace(/^(http[s]\\:\/\/)/, '')
    return res.split('/')[0].replace('www.', '')
  }

  modalAddValChg = (changeValue, allValues) => {
    const { modalAddVals, categories } = this.state
    // for (let k in chg) {
    // let v = chg[k].value
    if (changeValue.category) {
      const subCategories = categories.find(
        (category) => category.name === changeValue.category
      ).subCategory

      if (!subCategories.find((item) => item === modalAddVals.sub_category)) {
        modalAddVals.sub_category = subCategories[0]
        this.editFormRef &&
          this.editFormRef.setFieldValue(
            'sub_category',
            modalAddVals.sub_category
          )
        this.addFormRef &&
          this.addFormRef.setFieldValue(
            'sub_category',
            modalAddVals.sub_category
          )
      }
    }

    // modalAddVals[k] = typeof v === 'string' ? v.trim() : v
    // }
    this.setState({ modalAddVals: allValues, modalAddProcessing: false })
  }

  modalEditValChg = (changeValue, allValues) => {
    const { modalEditVals } = this.state

    this.setState({
      modalEditVals: Object.assign(modalEditVals, allValues),
      modalEditProcessing: false
    })
  }

  switchAddingCntType = (type) => {
    const { modalEditVals } = this.state
    modalEditVals.media_type = type
    this.setState({ modalEditVals })
  }

  async fetchData() {
    let { curCategory, categories } = this.state
    if (!categories.length) {
      const res = await getCategories()
      categories = res.categories.map((r) => r.category)
      categories = Array.from(new Set(categories))
      categories = categories.map((item) => {
        let subCategory = res.categories
          .filter((r) => r.category === item)
          .map((_item) => _item.sub_category)
        return { name: item, subCategory: Array.from(new Set(subCategory)) }
      })

      if (!curCategory) {
        curCategory = categories[0].name
      }
      let cid = this.categoryStrId[curCategory]

      const rcs = await getRecommendedContent(cid)
      this.setState({
        curCategory,
        categories,
        contentList: rcs.rows
      })
    }
  }

  onChgCategory = (val) => {
    this.setState({ curCategory: val })
  }

  addCard = () => {
    const { modalAddVals } = this.state
    Object.assign(modalAddVals, JSON.parse(JSON.stringify(blankCnt)))
    this.setState({ showAddModal: true, showEditModal: false })
  }

  hideModal = () => {
    this.setState({
      showAddModal: false,
      showEditModal: false,
      modalEditVals: JSON.parse(JSON.stringify(blankCnt)),
      modalAddVals: JSON.parse(JSON.stringify(blankCnt)),
      modalAddProcessing: false,
      modalEditProcessing: false
    })
  }

  editCard = async () => {
    const { modalAddVals, modalEditVals } = this.state
    if (!modalAddVals.link.startsWith('http')) {
      message.error('Please input the http:// or https:// in the URL.')
      return
    }
    this.setState({ modalAddProcessing: true, modalEditProcessing: false })
    let pageInfo = await getUrlTitleCover(modalAddVals.link)
    modalEditVals.title = pageInfo.page_title.substr(0, 80) // allow display max len = 50
    modalEditVals.cover = pageInfo.cover
    modalEditVals.file = undefined
    modalEditVals.description = ''
    modalEditVals.cover_type = 'url'

    // fill basic info
    modalEditVals.category = modalAddVals.category
    modalEditVals.sub_category = modalAddVals.sub_category
    modalEditVals.new_sub_category = modalAddVals.new_sub_category
    modalEditVals.category_id = this.categoryStrId[modalEditVals.category]
    modalEditVals.link = modalAddVals.link
    modalEditVals.estimate_view_time = modalAddVals.estimate_view_time
    modalEditVals.external_link = modalEditVals.link

    // fill external
    if (modalAddVals.link.includes('blog.apeiron.life')) {
      modalEditVals.external_link = getBlogUrl(
        modalAddVals.link.split('/').pop()
      )
    }

    this.setState({
      showAddModal: false,
      showEditModal: true,
      modalEditVals,
      modalAddVals: modalEditVals,
      modalAddProcessing: false,
      modalEditProcessing: false
    })
  }

  changeAddingCntCover = () => {
    if (!this.chgCoverEle) {
      this.chgCoverEle = document.createElement('input')
      this.chgCoverEle.type = 'file'
      this.chgCoverEle.accept = 'image/*'
    }
    this.chgCoverEle.dispatchEvent(new MouseEvent('click'))
    this.chgCoverEle.addEventListener('change', this._onFileChosen)
  }

  _onFileChosen = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    const { modalEditVals } = this.state
    modalEditVals.file = file
    modalEditVals.cover_type = 'upload'

    modalEditVals.cover_url = modalEditVals.cover = URL.createObjectURL(file)
    this.chgCoverEle.value = ''
    this.setState({ modalEditVals })
  }

  sendEdit = async () => {
    // send data by post
    const { modalEditVals, categories, contentList } = this.state
    if (!modalEditVals.link.startsWith('http')) {
      message.error('Please input the http:// or https:// in the URL.')
      return
    }
    if (!modalEditVals.description) {
      message.error('Please Fill in the description of the link.')
      return
    }
    if (!modalEditVals.cover) {
      message.error('Please upload image cover.')
      return
    }
    modalEditVals.category_id = this.categoryStrId[modalEditVals.category]

    this.setState({ modalEditProcessing: true })

    if (modalEditVals.sub_category === 'new') {
      const targetCategory = categories.find(
        (item) => item.name === modalEditVals.category
      )
      targetCategory.subCategory.push(modalEditVals.new_sub_category)
    }
    // modalEditVals.category = modalEditVals.category
    modalEditVals.sub_category =
      modalEditVals.sub_category === 'new'
        ? modalEditVals.new_sub_category
        : modalEditVals.sub_category
    const fmD = new FormData()
    for (const k in modalEditVals) {
      if (sendValidKeys[k] && modalEditVals[k] !== undefined) {
        fmD.set(k, modalEditVals[k])
      }
    }
    if (modalEditVals.id) {
      const r = await editRecommendedContent(fmD, modalEditVals.id)
      const idx = contentList.findIndex((c) => c.id === modalEditVals.id)
      contentList.splice(idx, 1, r.data || r)
    } else {
      const r = await addRecommendedContent(fmD)
      if (r) {
        const card = r.data || r
        contentList.unshift(card)
      }
    }
    this.setState({ modalEditProcessing: false, contentList })
    this.hideModal()
  }

  removeCard = async (id) => {
    const removed = await delRecommendedContent(id)
    const { contentList } = this.state
    if (removed) {
      const idx = contentList.findIndex((c) => c.id === id)
      contentList.splice(idx, 1)
      this.setState({ contentList })
      this.hideModal()
    }
  }
}

export default Recommended
