import React, { useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import BasicForm from '../../../components/formV4'

export default function AdditionalForm(props) {
  const {
    question: { additional_information, allow_multiple_answer },
    answer,
    update
  } = props

  const [answers, setAnswers] = useState(
    allow_multiple_answer ? [{ value: null }] : { value: null }
  )

  useEffect(() => {
    if (answer && !_.isEmpty(answer.answer)) {
      setAnswers(answer.answer)
    } else {
      setAnswers(allow_multiple_answer ? [{ value: {} }] : { value: {} })
    }
  }, [answer])

  const [state, dispatch] = useReducer(
    (state, action) => {
      state.refs = _.assign(state.refs, action.refs)
      if (allow_multiple_answer) {
        const [key] = _.keys(action.refs)
        answers[key].formRef = action.refs[0]
      } else {
        answers.formRef = action.refs[0]
      }
      setAnswers(answers)
      return state
    },
    { refs: {} }
  )

  return (
    <div className="q-form-group">
      {allow_multiple_answer
        ? answers.map((_answer, index) => {
            return (
              _answer.value && (
                <BasicForm
                  key={index}
                  formData={additional_information}
                  wrappedComponentRef={(inst) =>
                    dispatch({ refs: { [index]: inst } })
                  }
                  initialValues={_answer.value}
                  onChange={(changedFields, allValues) => {
                    updateAnswer(allValues, index)
                  }}
                />
              )
            )
          })
        : answers.value && (
            <BasicForm
              formData={additional_information}
              wrappedComponentRef={(inst) => dispatch({ refs: { 0: inst } })}
              initialValues={answers.value}
              onChange={(changedFields, allValues) => {
                updateAnswer(allValues)
              }}
            />
          )}

      {allow_multiple_answer && (
        <span
          className="q-multiple-answer"
          onClick={async () => {
            try {
              answers[answers.length - 1].formRef &&
                (await answers[answers.length - 1].formRef.validateFields())
            } catch (err) {
              if (err.errorFields.length > 0) {
                return
              }
            }
            answers.push({ value: {} })
            setAnswers(_.cloneDeep(answers))
          }}
        >
          {allow_multiple_answer}
        </span>
      )}
    </div>
  )

  async function updateAnswer(allValues, index) {
    if (allow_multiple_answer) {
      answers[index].value = allValues
    } else {
      answers.value = allValues
    }
    await setAnswers(_.cloneDeep(answers))

    const _update = () =>
      update(_.assignIn(answer, { answer: answers }), props.question)
    try {
      if (allow_multiple_answer) {
        if (state.refs[index]) {
          await state.refs[index].validateFields()
        }
      } else {
        if (state.refs[0]) {
          await state.refs[0].validateFields()
        }
      }
      _update()
    } catch (err) {
      if (err.errorFields.length === 0) {
        _update()
      }
    }
  }
}
