import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Element } from 'react-scroll'
import { Divider, Typography } from 'antd'
import ReactTextMoreLess from 'react-text-more-less'
import MetricCard from '../biomarker/client'

import { clientCategoriesStyle } from './index.sass'

const { Paragraph } = Typography

class ClientBloodTestCategories extends Component {
  static propTypes = {
    turnTo: PropTypes.func
  }

  state = {
    collapsed: true
  }

  renderBiomarkers(biomarkers) {
    const { turnTo, person, ageScopes } = this.props
    return (
      <>
        {biomarkers.map((item, index) => (
          <MetricCard
            ageScopes={ageScopes}
            key={index}
            metric={item}
            turnTo={turnTo}
            type="blood-test"
            person={person}
          />
        ))}
      </>
    )
  }

  renderBloodTestSubCategories(subCategories) {
    const { collapsed } = this.state

    return (
      <>
        {subCategories.map((item, index) => (
          <div className="sub-category-container" key={index}>
            <Divider orientation="left">{item.name}</Divider>
            <div className="description">
              <ReactTextMoreLess
                collapsed={collapsed}
                text={item.description}
                lessHeight={52}
                showMoreElement={
                  <span>
                    ... <span className="show-more-text">See more</span>
                  </span>
                }
                showLessElement={
                  <span className="show-more-text">See less</span>
                }
                onClick={() => {
                  this.setState({ collapsed: !collapsed })
                }}
              />
            </div>
            <div className="card-list">
              {item.bio_markers.length > 0 ? (
                this.renderBiomarkers(item.bio_markers)
              ) : (
                <div className="sec-no-data">no data</div>
              )}
            </div>
          </div>
        ))}
      </>
    )
  }

  render() {
    const { categories: propCategories } = this.props
    let categories = propCategories || []
    return (
      <div className={clientCategoriesStyle}>
        {categories.map((item, index) => (
          <Element name={`category_${item.name}`} key={index}>
            <div className="category-container">
              <div className="root-category">
                <div className="description">
                  <h2>{item.name}</h2>
                  <Paragraph>{item.description}</Paragraph>
                </div>
                {item &&
                  item.sub_categories &&
                  this.renderBloodTestSubCategories(item.sub_categories)}
              </div>
            </div>
          </Element>
        ))}
      </div>
    )
  }
}

export default ClientBloodTestCategories
