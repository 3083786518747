import React, { Component } from 'react'
import { Input, Typography } from 'antd'
import PropTypes from 'prop-types'
import { fileInputStyle } from './styles/fileInput.sass'
import IconInput from '../../../asserts/icon/icon-input.svg'

const { Paragraph } = Typography
export class FileInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    multiple: PropTypes.bool
  }

  state = {
    files: null,
    filename: ''
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      files: value
    })
  }

  triggerChange = (files) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(files)
    }
  }

  render() {
    const { id, multiple, extraAttr } = this.props
    const { filename } = this.state
    return (
      <div className={fileInputStyle}>
        <label htmlFor={id || 'input-file'}>
          <Paragraph ellipsis>
            {filename
              ? filename
              : (extraAttr && extraAttr.placeholder) || (
                  <img src={IconInput} alt="input" />
                )}
          </Paragraph>
          <Input
            type="file"
            id={id || 'input-file'}
            multiple={multiple}
            accept={extraAttr && extraAttr.accept}
            onChange={(event) => {
              if (event.target.value) {
                const files = event.target.files
                this.setState({ files, filename: event.target.value })
                this.triggerChange(files)
              }
            }}
          />
        </label>
      </div>
    )
  }
}

export default FileInput
