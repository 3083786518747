import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import _ from 'lodash'

export default function TextEntry(props) {
  const { question, answer = {}, update } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    const an = answer.answer.value
    if (typeof an === 'string' || typeof an === 'number') {
      setValue(an)
    }
  }, [answer])

  const isMultiLine = question.options_layout === 'multi-line'

  const childProps = {
    placeholder: 'Please type here',
    value,
    onChange: (e) => {
      const value = e.target.value
      setValue(value)
      update(_.assignIn(answer, { answer: { value } }), question)
    },
    className: `q-form-item ${answer.verify ? 'invalid' : ''}`
  }

  return (
    <>
      {isMultiLine ? (
        <Input.TextArea {...childProps} autoSize />
      ) : (
        <Input
          {...childProps}
          addonAfter={question.unit ? <span>{question.unit}</span> : null}
        />
      )}
      {answer.verify && (
        <p className="invalid">
          {answer.verify.message || 'Please type your answer'}
        </p>
      )}
    </>
  )
}
