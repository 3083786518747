import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

NormalSelect.propTypes = {
  options: PropTypes.array,
  initialValue: PropTypes.string,
  extraAttr: PropTypes.object,
  placeholder: PropTypes.string
}

export default function NormalSelect(props) {
  const {
    options,
    placeholder,
    disabled,
    value,
    extraAttr,
    onChange,
    onSelectChange
  } = props

  return (
    <Select
      onChange={triggerChange}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      {...extraAttr}
    >
      {options &&
        options.map(
          (item, index) =>
            item && (
              <Select.Option
                value={item.value || item}
                key={index}
                disabled={item.disabled}
                hidden={item.hidden}
              >
                {item.leftExtra && (
                  <img
                    src={item.leftExtra}
                    alt="extra"
                    style={{ marginRight: 10, width: 28 }}
                  />
                )}
                {item.label || item}
              </Select.Option>
            )
        )}
    </Select>
  )

  function triggerChange(value) {
    if (onChange) {
      onChange(value)
    }
    if (onSelectChange) {
      onSelectChange(value)
    }
  }
}
