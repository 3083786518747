import moment from 'moment'
import React, { useEffect } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as Routes from './models/routes.model'
import { animateScroll as scroll } from 'react-scroll'
// import Footer from './components/UI/footer/pageFooter'
import NoMatch from './view/noMatch'
// import PreviewReport from './view/creport/preview'
import { getToken, setCacheURL } from './utils/storage'
import { getRole, getClientType } from './utils/common'
import { publicPath, noCheckPath } from './utils/constant'
import './App.sass'

moment.locale('en', {
  week: {
    dow: 1
  }
})

const isDeeplink = (hash) => {
  const client = getClientType()
  if (client === 'app') {
    const deeplink = process.env.REACT_APP_IOS_DEEPLINk
    window.location.href = deeplink + hash
  }
}

const href = window.location.href
if (href.match('/password-reset/') && !href.match('/password-reset/verify')) {
  isDeeplink(href.split('/password-reset/')[1])
}

const isPublic = (pathname) => {
  return publicPath.find((path) => {
    const match = pathname.match(path)
    return match && match.index === 0
  })
}
const isNoCheck = (pathname) => {
  return noCheckPath.find((path) => {
    const match = pathname.match(path)
    return match && match.index === 0
  })
}

function App(props) {
  const {
    location: { pathname },
    history,
    account: { user }
  } = props

  useEffect(() => {
    const initial = () => {
      const location = history.location
      const token = getToken()
      const isPublicLink = isPublic(location.pathname)
      const isNoCheckLink = isNoCheck(location.pathname)

      if (!token || !user) {
        if (!isPublicLink) {
          if (location.pathname !== '/') {
            setCacheURL(`${location.pathname}${location.search}`)
          }
          history.push('/sign-in')
        }
      } else if (user && !(isPublicLink || isNoCheckLink)) {
        const {
          profile: {
            onboarding_steps: { onboarding_required },
            membership_selected
          }
        } = user
        const role = getRole(user.role)
        if (
          location.pathname === '/app/setting/view-signed-forms' &&
          onboarding_required
        ) {
          history.push('/redirect-sign-waiver-form')
        } else if (role === 'User' && location.pathname !== '/2fa') {
          if (!membership_selected) {
            history.push('/membership-selection')
          } else if (onboarding_required !== false) {
            history.push('/onboarding/ready')
          }
        }
      }
    }

    initial()
  }, [])

  useEffect(() => {
    scroll.scrollToTop({ ignoreCancelEvents: true })
  }, [pathname])

  return (
    <div className="App">
      <div>
        <Switch>
          {/* <Route path="/creport/preview/:hash" component={PreviewReport} /> */}
          {dynamicRoutes(user && user.role).map((route, index) => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              render={route.render}
              key={index}
            />
          ))}
          {user && <Route component={NoMatch} />}
        </Switch>
        {/* <Footer {...props} /> */}
      </div>
    </div>
  )

  function dynamicRoutes(roleNumber) {
    if (!roleNumber) return Routes.commonRoutes
    const role = getRole(roleNumber)
    let routes = Routes.commonRoutes
    switch (role) {
      case 'Expert':
      case 'Coach':
      case 'InternalAdmin':
      case 'Manager':
        routes = routes.concat(Routes.coachRoutes)
        break
      case 'Service_Provider':
        routes = routes.concat(Routes.providerRoutes)
        break
      case 'Assistant':
      case 'User':
        routes = routes.concat(Routes.clientRoutes)
        break
      default:
        break
    }
    return routes
  }
}

export default withRouter(connect(({ account }) => ({ account }), null)(App))
