import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import { ArrowDownIcon, WrapText, SvgText } from '../icons/weeklySummary'
import NormalBar from './comp/normalBar'
import HRV from './comp/hrv'
import BodyMass from './comp/bodyMass'
import HRZChart from './comp/heartRateZone'
import CheckDocs from './comp/checkDots'
import BedTimeBar from './comp/bedTime'
import Information from '../UI/extraInformation'
import { getAxisX, sumReduce } from './common'
import { timesFormat } from '../../utils/common'
import {
  summary_introduction,
  overview_rejuvenation,
  overview_exercise,
  overview_nutrition
} from './directions.json'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const green = '#7BAD2D',
  // red = '#FF6B00',
  gray = '#b7bfc6',
  normal = '#70808E',
  blue = '#3194EB'
// primary = '#383E48'

const textAttr = {
  x: '100%',
  textAnchor: 'end'
}

class SummaryEmailOverView extends Component {
  static propTypes = {}

  calculateElementWidth = (tag, text, fontSize, width) => {
    const element = document.createElement(tag.toUpperCase())
    const style = `font-family: 'Gilroy-Bold';line-height: 18px;letter-spacing: 1px;display: inline-block;font-size: ${fontSize}; width: ${width ||
      'auto'};font-weight: 700;box-sizing: border-box;color: transparent; `
    element.setAttribute('style', style)
    element.innerText = text
    document.body.append(element)
    const elementWidth = element.offsetWidth,
      elementHeight = element.offsetHeight
    document.body.removeChild(element)
    return { width: elementWidth, height: elementHeight }
  }

  formatSleepDuration(inbedTime, useForChange) {
    const { hours, minutes } = timesFormat(inbedTime)
    if (useForChange) {
      return hours && hours * 60 + minutes
    }
    if (hours)
      return `${hours > 0 ? hours : ''}h${
        minutes ? minutes + (hours > 0 ? '' : 'm') : ''
      }`
  }

  processSleepDurationChart(startDate, endDate, sleep) {
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    if (tickValuesX) {
      let weekAvg = sleep && sleep.average && sleep.average.avg_inbed_time
      let monthAvg =
        sleep &&
        sleep.previous_weeks_average &&
        sleep.previous_weeks_average.avg_inbed_time
      let change =
        weekAvg &&
        monthAvg &&
        this.calculateSleepDurationChange(weekAvg, monthAvg)
      return {
        weekAvg: weekAvg && this.formatSleepDuration(weekAvg),
        originWeekAvg: weekAvg,
        monthAvg: monthAvg && this.formatSleepDuration(monthAvg),
        change: change,
        entries: tickValuesX.map((date) => {
          let specificDay =
            sleep &&
            sleep.details &&
            sleep.details.find((detail) => {
              return date.format('YYYY-MM-DD') === detail.summary_date
            })
          if (specificDay) {
            return specificDay.inbed_time
          } else {
            return 0
          }
        })
      }
    }
  }

  calculateSleepDurationChange(weekAvg, monthAvg) {
    if (weekAvg && monthAvg) {
      return (
        this.formatSleepDuration(weekAvg, true) -
        this.formatSleepDuration(monthAvg, true)
      )
    }
  }

  processSleepHRVAverage(sleep) {
    const weekAvg = sleep && sleep.average && sleep.average.avg_hrv
    const monthAvg =
      sleep &&
      sleep.previous_weeks_average &&
      sleep.previous_weeks_average.avg_hrv
    let change
    if (weekAvg && monthAvg) {
      change = weekAvg - monthAvg
    }
    return { weekAvg, monthAvg, change }
  }

  processExe(exercise) {
    let aerobic = {},
      resistance = {},
      activity = {},
      movementQuality = {},
      totalDuration = {}

    if (exercise) {
      const reduceWorkout = (type) => {
        const typeWorkout = exercise.workouts.find(
          (workout) => workout.type === type
        )
        const total =
          typeWorkout && typeWorkout.total && Math.round(typeWorkout.total / 60)
        const monthAvg =
          typeWorkout &&
          typeWorkout.previous_weeks_average &&
          Math.round(typeWorkout.previous_weeks_average / 60)
        const change = this.processExerciseChange(total, monthAvg)
        return {
          total: total,
          monthAvg: monthAvg,
          change: change,
          entries: typeWorkout.details
        }
      }
      aerobic = reduceWorkout('Aerobic')
      resistance = reduceWorkout('Resistance')
      activity = reduceWorkout('Activity')
      movementQuality = reduceWorkout('Movement Quality')

      const totalEntries = aerobic.entries
        .map((entry) => entry.summary_date)
        .map((date) => {
          return {
            summary_date: date,
            heart_rate_zones_arr: [
              aerobic.entries.find((item) => item.summary_date === date)
                .heart_rate_zones,
              resistance.entries.find((item) => item.summary_date === date)
                .heart_rate_zones,
              activity.entries.find((item) => item.summary_date === date)
                .heart_rate_zones,
              movementQuality.entries.find((item) => item.summary_date === date)
                .heart_rate_zones
            ].filter((data) => data)
          }
        })
        .map((item) => {
          const getTotal = (index) => {
            let sum = 0
            for (let zone of item.heart_rate_zones_arr) {
              sum += zone[index]
            }
            return sum
          }
          const heart_rate_zones = [
            getTotal(0),
            getTotal(1),
            getTotal(2),
            getTotal(3),
            getTotal(4)
          ]
          item.heart_rate_zones = heart_rate_zones
          return item
        })

      const allTotal = [
        aerobic.total,
        resistance.total,
        activity.total,
        movementQuality.total
      ]
      const allMonthAvg = [
        aerobic.monthAvg,
        resistance.monthAvg,
        activity.monthAvg,
        movementQuality.monthAvg
      ]
      totalDuration = {
        total: this.processExerciseTotalDuration(allTotal),
        monthAvg: this.processExerciseTotalDuration(allMonthAvg),
        change: this.processExerciseChange(
          this.processExerciseTotalDuration(allTotal),
          this.processExerciseTotalDuration(allMonthAvg)
        ),
        entries: totalEntries
      }
    }
    return { aerobic, resistance, activity, movementQuality, totalDuration }
  }

  processExerciseChange(total, monthAvg) {
    if (total && monthAvg) {
      return total - monthAvg
    }
  }

  processExerciseTotalDuration(values) {
    return values && values.filter((item) => item).reduce(sumReduce, 0)
  }

  renderOverviewHeader(startDate, endDate, type = 'AVG') {
    const dates = getAxisX(startDate, endDate)
    return (
      <div className="table-header">
        <div className="days-7">
          <SvgText
            text={type === 'Latest' ? 'LATEST' : '7 DAY'}
            width={34}
            height={10}
            fontWeight="normal"
            fontSize={8}
            fill={normal}
          />
          <br />
          {type !== 'Latest' && (
            <SvgText
              text={type}
              width={26}
              height={10}
              fontWeight="normal"
              fontSize={8}
              fill={normal}
            />
          )}
        </div>
        <div className="week-4">
          {/* 4Week <br /> avg */}
          <SvgText
            text="3-WEEK"
            width={34}
            height={10}
            fontWeight="normal"
            fontSize={8}
            fill={normal}
          />
          <br />
          <SvgText
            text="AVG"
            width={22}
            height={10}
            fontWeight="normal"
            fontSize={8}
            fill={normal}
          />
        </div>
        <div className="change">
          <SvgText
            text="CHANGE"
            width={36}
            height={10}
            fontWeight="normal"
            fontSize={8}
            fill={normal}
          />
        </div>
        <div className="calendar">
          {dates &&
            dates.map((date, index) => (
              <span key={index}>
                <SvgText
                  text={date.format('ddd').toUpperCase()}
                  width={22}
                  height={10}
                  fontWeight="normal"
                  fontSize={8}
                  fill={normal}
                />
                <br /> <ArrowDownIcon />
              </span>
            ))}
        </div>
      </div>
    )
  }

  renderRejuvenation(isEditable) {
    const {
      startDate,
      endDate,
      data,
      setSubmitData,
      submitData: { overview },
      deleteOverviewPart,
      updateEditingCount,
      originInformation,
      templates,
      loginRole
    } = this.props
    const sleepDuration = this.processSleepDurationChart(
      startDate,
      endDate,
      data && data.sleep
    )
    const sleepHRVAverage = this.processSleepHRVAverage(data && data.sleep)
    const details = data && data.sleep && data.sleep.details
    //
    const dataRejuvenation = [
      {
        name: 'Bed Time',
        weekAvg:
          data && data.sleep.average && data.sleep.average.avg_bedtime_start,
        monthAvg:
          data &&
          data.sleep.previous_weeks_average &&
          data.sleep.previous_weeks_average.avg_bedtime_start,
        change: data && data.sleep.change && data.sleep.change.bedtime_start,
        changeUnit: 'm',
        chart: (
          <BedTimeBar
            {...{
              startDate,
              endDate,
              details
            }}
          />
        )
      },
      {
        name: 'Sleep Duration',
        weekAvg: sleepDuration && sleepDuration.weekAvg,
        monthAvg: sleepDuration && sleepDuration.monthAvg,
        change: sleepDuration && sleepDuration.change,
        changeUnit: 'm',
        chart: (
          <NormalBar
            {...{
              startDate,
              endDate,
              extraAxisText: 'Avg',
              target: (sleepDuration && sleepDuration.originWeekAvg) || 0,
              entries: sleepDuration && sleepDuration.entries,
              barColor: '#5C00D1',
              tickFormat: (t) => {
                return this.formatSleepDuration(t)
              }
            }}
          />
        )
      },
      {
        name: 'HRV',
        weekAvg: sleepHRVAverage.weekAvg && sleepHRVAverage.weekAvg + 'ms',
        monthAvg: sleepHRVAverage.monthAvg && sleepHRVAverage.monthAvg + 'ms',
        change: sleepHRVAverage.change,
        changeUnit: 'ms',
        chart: (
          <HRV {...{ startDate, endDate, sleep: data ? data.sleep : {} }} />
        )
      }
    ].filter(
      (item) =>
        !overview.deleted_rejuvenation.find((name) => name === item.name)
    )

    return (
      <>
        {dataRejuvenation.length > 0 && (
          <div ref="overview.rejuvenation_chart_url" className="ref-container">
            {this.renderOverviewHeader(startDate, endDate)}
            <div className="overview-section ">
              <div className="left-title Rejuvenation">
                <SvgText
                  height={110}
                  width={32}
                  fontFamily="Gilroy-Bold"
                  textAttr={{
                    x: -110,
                    y: 20,
                    fontSize: 14,
                    fill: '#5C00D1',
                    fontWeight: 'bold',
                    transform: 'rotate(-90)',
                    textAnchor: 'start',
                    dominantBaseline: 'top'
                  }}
                  text={'Rejuvenation'.toUpperCase()}
                />
              </div>
              <div className="right-content">
                {dataRejuvenation.map((item, index) => (
                  <div
                    className="row"
                    key={`dataRejuvenation--${item.name}`}
                    style={{
                      padding: dataRejuvenation.length === 1 ? '40px 0' : 0
                    }}
                  >
                    <div className="name">
                      <span>
                        <WrapText
                          text={item.name.toUpperCase()}
                          height={
                            this.calculateElementWidth(
                              'span',
                              item.name.toUpperCase(),
                              '12px',
                              '100px'
                            ).height
                          }
                        />
                      </span>
                    </div>
                    <div className={`${item.weekAvg ? '' : 'black'}`}>
                      {item.weekAvg ? (
                        <SvgText text={item.weekAvg} textAttr={textAttr} />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div className={`gray ${item.monthAvg ? '' : 'black'}`}>
                      {item.monthAvg ? (
                        <SvgText
                          text={item.monthAvg}
                          fill={gray}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div>
                      {item.change !== undefined && item.change !== null ? (
                        <SvgText
                          text={`${item.change > 0 ? '+' : ''}${item.change}${
                            item.changeUnit
                          }`}
                          fill={normal}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div className="chart">{item.chart}</div>
                    {isEditable && (
                      <div
                        className="delete"
                        onClick={() =>
                          deleteOverviewPart(
                            'overview.deleted_rejuvenation',
                            item.name,
                            item.name
                          )
                        }
                      >
                        <img className="delete-icon" src={DelIcon} alt="del" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {dataRejuvenation.length > 0 && (
          <Information
            updateEditingCount={updateEditingCount}
            status={originInformation && originInformation.status}
            initialValue={overview.rejuvenation_recommendation}
            doneStatus={overview.rejuvenation_recommendation_status}
            direction={overview_rejuvenation}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'rejuvenation_recommendation',
                value,
                status,
                position: 'Rejuvenation - Overview'
              })
            }
          />
        )}
      </>
    )
  }

  renderExercise(isEditable) {
    const {
      startDate,
      endDate,
      data,
      setSubmitData,
      submitData: { overview },
      deleteOverviewPart,
      updateEditingCount,
      originInformation,
      templates,
      loginRole
    } = this.props
    const {
      aerobic,
      resistance,
      activity,
      movementQuality,
      totalDuration
    } = this.processExe(data && data.exercise)
    const dataExercise = [
      {
        name: 'Aerobic',
        weekAvg: aerobic && aerobic.total,
        monthAvg: aerobic && aerobic.monthAvg,
        change: aerobic && aerobic.change,
        changeUnit: 'm',
        chart: aerobic && (
          <HRZChart
            {...{
              name: 'Aerobic',
              startDate,
              endDate,
              entries: aerobic.entries
            }}
          />
        )
      },
      {
        name: 'Resistance',
        weekAvg: resistance && resistance.total,
        monthAvg: resistance && resistance.monthAvg,
        change: resistance && resistance.change,
        changeUnit: 'm',
        chart: resistance && (
          <HRZChart
            {...{
              name: 'Resistance',
              startDate,
              endDate,
              entries: resistance.entries
            }}
          />
        )
      },
      {
        name: 'Activity',
        weekAvg: activity && activity.total,
        monthAvg: activity && activity.monthAvg,
        change: activity && activity.change,
        changeUnit: 'm',
        chart: activity && (
          <HRZChart
            {...{
              name: 'Activity',
              startDate,
              endDate,
              entries: activity.entries
            }}
          />
        )
      },
      {
        name: 'Movement Quality',
        weekAvg: movementQuality && movementQuality.total,
        monthAvg: movementQuality && movementQuality.monthAvg,
        change: movementQuality && movementQuality.change,
        changeUnit: 'm',
        chart: movementQuality && (
          <HRZChart
            {...{
              name: 'Movement Quality',
              startDate,
              endDate,
              entries: movementQuality.entries
            }}
          />
        )
      },
      {
        name: 'Exercise and Activity Duration',
        weekAvg: totalDuration && totalDuration.total,
        monthAvg: totalDuration && totalDuration.monthAvg,
        change: totalDuration && totalDuration.change,
        changeUnit: 'm',
        chart: totalDuration && (
          <HRZChart
            {...{
              name: 'Exercise and Activity Duration',
              startDate,
              endDate,
              entries: totalDuration.entries
            }}
          />
        )
      }
      // {
      //   name: 'ACSM Heart Health',
      //   weekAvg: acsmWeekAvg,
      //   monthAvg: acsmMonthAvg,
      //   change: acsmWeekAvg && acsmMonthAvg && acsmWeekAvg - acsmMonthAvg,
      //   changeUnit: '%',
      //   chart: (
      //     <AcsmHeart
      //       {...{
      //         startDate,
      //         endDate,
      //         heartRate: data ? data.exercise.heart_rate : []
      //       }}
      //     />
      //   )
      // }
    ].filter(
      (item) => !overview.deleted_exercise.find((name) => name === item.name)
    )

    return (
      <>
        {dataExercise.length > 0 && (
          <div ref="overview.exercise_chart_url" className="ref-container">
            {this.renderOverviewHeader(startDate, endDate, 'TOTAL')}
            <div className="overview-section ">
              <div className="left-title Exercise">
                <SvgText
                  height={70}
                  width={32}
                  fontFamily="Gilroy-Bold"
                  textAttr={{
                    x: -70,
                    y: 20,
                    fontSize: 14,
                    fill: '#FF6B00',
                    fontWeight: 'bold',
                    transform: 'rotate(-90)',
                    textAnchor: 'start',
                    dominantBaseline: 'top'
                  }}
                  text={'Exercise'.toUpperCase()}
                />
              </div>
              <div className="right-content">
                {dataExercise.map((item, index) => (
                  <div
                    className="row"
                    key={`dataExercise--${item.name}`}
                    style={{
                      padding: dataExercise.length === 1 ? '15px 0' : 0
                    }}
                  >
                    <div className="name">
                      <span>
                        <WrapText
                          text={item.name.toUpperCase()}
                          height={
                            this.calculateElementWidth(
                              'span',
                              item.name.toUpperCase(),
                              '12px',
                              '100px'
                            ).height
                          }
                        />
                      </span>
                    </div>
                    <div>
                      {item.weekAvg !== undefined && item.weekAvg !== null ? (
                        <SvgText
                          text={item.weekAvg + item.changeUnit}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div>
                      {item.monthAvg !== undefined && item.monthAvg !== null ? (
                        <SvgText
                          text={item.monthAvg + item.changeUnit}
                          fill={gray}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div>
                      {item.change !== undefined && item.change !== null ? (
                        <SvgText
                          text={`${item.change > 0 ? '+' : ''}${item.change}${
                            item.changeUnit
                          }`}
                          fill={normal}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div className="chart">{item.chart}</div>
                    {isEditable && (
                      <div
                        className="delete"
                        onClick={() =>
                          deleteOverviewPart(
                            'overview.deleted_exercise',
                            item.name,
                            item.name
                          )
                        }
                      >
                        <img className="delete-icon" src={DelIcon} alt="del" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <ul className="zone-list">
              <li className="z-5">Z5</li>
              <li className="z-4">Z4</li>
              <li className="z-3">Z3</li>
              <li className="z-2">Z2</li>
            </ul>
          </div>
        )}
        {dataExercise.length > 0 && (
          <Information
            updateEditingCount={updateEditingCount}
            status={originInformation && originInformation.status}
            initialValue={overview.exercise_recommendation}
            doneStatus={overview.exercise_recommendation_status}
            direction={overview_exercise}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'exercise_recommendation',
                value,
                status,
                position: 'Exercise - Overview'
              })
            }
          />
        )}
      </>
    )
  }

  renderWeight(isEditable) {
    const {
      startDate,
      endDate,
      setSubmitData,
      submitData: { overview },
      deleteOverviewPart,
      updateEditingCount,
      originInformation,
      templates,
      loginRole,
      goals
    } = this.props
    const dataWeight = goals
      .filter(
        (item) =>
          !overview.deleted_weight.find((id) => id === item.id) &&
          item.description.toLowerCase().includes('weight')
      )
      .map((item) => {
        item = _.cloneDeep(item)
        item.barProps = item.barProps || {}
        if (!item.isYesNoGoal) {
          item.barProps.target = item.average
        }
        item.barProps.min = null
        item.barProps.max = null
        item.barProps.extraAxisText = 'Avg'
        item.barProps.barColor = blue
        return item
      })

    return (
      <>
        {dataWeight.length > 0 && (
          <div ref="overview.weight_chart_url" className="ref-container">
            {this.renderOverviewHeader(startDate, endDate, 'Latest')}
            <div className="overview-section">
              <div className="left-title Weight">
                <SvgText
                  height={80}
                  width={32}
                  fontFamily="Gilroy-Bold"
                  textAttr={{
                    x: -70,
                    y: 20,
                    fontSize: 14,
                    fill: blue,
                    fontWeight: 'bold',
                    transform: 'rotate(-90)',
                    textAnchor: 'start',
                    dominantBaseline: 'top'
                  }}
                  text={'Weight'.toUpperCase()}
                />
              </div>
              <div className="right-content" style={{ height: 'auto' }}>
                {dataWeight.map((item, index) => {
                  const latest = item.details[0]

                  return (
                    <div
                      className="row"
                      key={index}
                      style={{
                        padding: dataWeight.length === 1 ? '15px 0' : 0
                      }}
                    >
                      <div className="name">
                        <span>
                          <WrapText
                            text={(item.description + ' (LBS)').toUpperCase()}
                            height={
                              this.calculateElementWidth(
                                'span',
                                (item.description + ' (LBS)').toUpperCase(),
                                '12px',
                                '100px'
                              ).height
                            }
                          />
                        </span>
                      </div>
                      <div>
                        {latest ? (
                          <SvgText text={latest.value} textAttr={textAttr} />
                        ) : (
                          <SvgText text={'--'} textAttr={textAttr} />
                        )}
                      </div>
                      <div>
                        {item.previous_weeks_average !== undefined &&
                        item.previous_weeks_average !== null ? (
                          <SvgText
                            text={item.previous_weeks_average}
                            fill={gray}
                            textAttr={textAttr}
                          />
                        ) : (
                          <SvgText text={'--'} textAttr={textAttr} />
                        )}
                      </div>
                      <div>
                        {item.change !== undefined && item.change !== null ? (
                          <SvgText
                            text={`${item.formatChange > 0 ? '+' : ''}${
                              item.formatChange
                            }`}
                            fill={item.changeColor}
                            textAttr={textAttr}
                          />
                        ) : (
                          <SvgText text={'--'} textAttr={textAttr} />
                        )}
                      </div>
                      <div className="chart">
                        <BodyMass
                          {...{ startDate, endDate, ...item.barProps }}
                          stroke={blue}
                        />
                      </div>
                      {isEditable && (
                        <div
                          className="delete"
                          onClick={() =>
                            deleteOverviewPart(
                              'overview.deleted_weight',
                              item.id,
                              item.description
                            )
                          }
                        >
                          <img
                            className="delete-icon"
                            src={DelIcon}
                            alt="del"
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}

        {dataWeight.length > 0 && (
          <Information
            updateEditingCount={updateEditingCount}
            initialValue={overview.weight_recommendation}
            doneStatus={overview.weight_recommendation_status}
            status={originInformation && originInformation.status}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'weight_recommendation',
                value,
                status,
                position: 'Weight - Overview'
              })
            }
          />
        )}
      </>
    )
  }

  renderNutrition(isEditable) {
    const {
      startDate,
      endDate,
      setSubmitData,
      submitData: { overview },
      deleteOverviewPart,
      updateEditingCount,
      originInformation,
      templates,
      loginRole,
      goals
    } = this.props
    overview.deleted_nutritions = overview.deleted_nutritions || []
    const dataNutrition = goals
      .filter(
        (item) =>
          !overview.deleted_nutritions.find((id) => id === item.id) &&
          !item.description.toLowerCase().includes('weight')
      )
      .map((item) => {
        item = _.cloneDeep(item)
        item.barProps = item.barProps || {}
        if (!item.isYesNoGoal) {
          item.barProps.target = item.average
        }
        item.barProps.min = null
        item.barProps.max = null
        item.barProps.extraAxisText = 'Avg'
        item.barProps.barColor = green
        return item
      })
    const noMicroGoals =
      overview.deleted_nutritions.length === 0 && dataNutrition.length === 0

    return (
      <>
        {dataNutrition.length > 0 || noMicroGoals ? (
          <div ref="overview.nutrition_chart_url" className="ref-container">
            {this.renderOverviewHeader(startDate, endDate)}
            <div className="overview-section">
              <div className="left-title Nutrition">
                <SvgText
                  height={80}
                  width={32}
                  fontFamily="Gilroy-Bold"
                  textAttr={{
                    x: -80,
                    y: 20,
                    fontSize: 14,
                    fill: '#7BAD2D',
                    fontWeight: 'bold',
                    transform: 'rotate(-90)',
                    textAnchor: 'start',
                    dominantBaseline: 'top'
                  }}
                  text={'Nutrition'.toUpperCase()}
                />
              </div>
              <div
                className="right-content"
                style={{ height: noMicroGoals ? 103 : 'auto' }}
              >
                {dataNutrition.map((item, index) => (
                  <div
                    className="row"
                    key={index}
                    style={{
                      padding: dataNutrition.length === 1 ? '15px 0' : 0
                    }}
                  >
                    <div className="name">
                      <span>
                        <WrapText
                          text={item.description.toUpperCase()}
                          height={
                            this.calculateElementWidth(
                              'span',
                              item.description.toUpperCase(),
                              '12px',
                              '100px'
                            ).height
                          }
                        />
                      </span>
                    </div>
                    <div>
                      {item.average !== undefined && item.average !== null ? (
                        <SvgText
                          text={
                            item.input_type === 'binary'
                              ? Math.round(item.average * 100) + '%'
                              : item.average
                          }
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div>
                      {item.previous_weeks_average !== undefined &&
                      item.previous_weeks_average !== null ? (
                        <SvgText
                          text={
                            item.input_type === 'binary'
                              ? Math.round(item.previous_weeks_average * 100) +
                                '%'
                              : item.previous_weeks_average
                          }
                          fill={gray}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div>
                      {item.change !== undefined && item.change !== null ? (
                        <SvgText
                          text={`${item.formatChange > 0 ? '+' : ''}${
                            item.formatChange
                          }${item.changeUnit}`}
                          fill={item.changeColor}
                          textAttr={textAttr}
                        />
                      ) : (
                        <SvgText text={'--'} textAttr={textAttr} />
                      )}
                    </div>
                    <div className="chart">
                      {item.input_type === 'binary' ? (
                        <CheckDocs
                          {...{ startDate, endDate, ...item.barProps }}
                        />
                      ) : item.description.toLowerCase().includes('weight') ? (
                        <BodyMass
                          {...{ startDate, endDate, ...item.barProps }}
                        />
                      ) : (
                        <NormalBar
                          {...{ startDate, endDate, ...item.barProps }}
                        />
                      )}
                    </div>
                    {isEditable && (
                      <div
                        className="delete"
                        onClick={() =>
                          deleteOverviewPart(
                            'overview.deleted_nutritions',
                            item.id,
                            item.description
                          )
                        }
                      >
                        <img className="delete-icon" src={DelIcon} alt="del" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: 20 }} />
        )}
        {dataNutrition.length > 0 || noMicroGoals ? (
          <Information
            updateEditingCount={updateEditingCount}
            initialValue={overview.nutrition_recommendation}
            doneStatus={overview.nutrition_recommendation_status}
            status={originInformation && originInformation.status}
            title="Overview"
            direction={overview_nutrition}
            templates={templates}
            showDone={loginRole !== 'User'}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'nutrition_recommendation',
                value,
                status,
                position: 'Nutrition - Overview '
              })
            }
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  render() {
    const {
      submitData: { overview },
      originInformation,
      setSubmitData,
      loginRole,
      templates,
      updateEditingCount
    } = this.props

    const isEditable =
      !originInformation ||
      (originInformation.status !== 'Sent' &&
        originInformation.status !== 'Opened')
    return (
      <>
        <div style={{ marginTop: 10 }}>
          <Information
            updateEditingCount={updateEditingCount}
            status={originInformation && originInformation.status}
            initialValue={overview.summary_introduction}
            doneStatus={overview.summary_introduction_status}
            title="Introduction"
            showDone={loginRole !== 'User'}
            templates={templates}
            direction={summary_introduction}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'summary_introduction',
                value,
                status,
                position: 'Introduction'
              })
            }
          />
        </div>
        {this.renderRejuvenation(isEditable)}

        {this.renderExercise(isEditable)}

        {this.renderWeight(isEditable)}

        {this.renderNutrition(isEditable)}
      </>
    )
  }
}

export default SummaryEmailOverView
