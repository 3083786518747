import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { Tabs, Row, Col, Divider } from 'antd'
import DetailItems from '../../goals/activity/detailItems'
import ActivityTrend from '../../goals/activity/trend'
import HeartRateZoneStage from '../../goals/activity/heartRateZoneStage'
import RPE from '../../goals/activity/rpe'
import RightHalfModalContainer from '../../UI/rightHalfModalContainer'

import { activityStyle } from '../../goals/activity/styles/activity.sass'
import { workoutDetailStyle } from './styles/workout.sass'

const { TabPane } = Tabs
const type = ['All', 'Aerobic', 'Resistance', 'Activity', 'Movement Quality']

class WorkoutSessions extends Component {
  static propTypes = {
    overview: PropTypes.object,
    closeModal: PropTypes.func,
    sessionType: PropTypes.string,
    saveManualRPE: PropTypes.func
  }

  state = {
    showDetail: false,
    showHRZIndex: 'All'
  }

  getDuration = (duration) => {
    if (!duration) return '--'
    const h = Math.floor(duration / (60 * 60))
    const m = Math.floor((duration - h * 60 * 60) / 60)
    const s = Math.round(duration - (h * 60 * 60 + m * 60))
    return `${h}:${m < 10 ? '0' + m : m}:${s < 10 ? '0' + s : s}`
  }

  getMetaData = (entries) => {
    function calculateShowTime(entry) {
      let showTime
      if (entry.details.start_date) {
        showTime = moment(entry.record_date).add(entry.details.start_date, 's')
      } else {
        showTime =
          (entry.details &&
            (entry.details.start_time_local || entry.details.start_time) &&
            (entry.details.start_time_local || entry.details.start_time) *
              1000) ||
          new Date(entry.record_date)
      }
      entry.showTime = showTime
      return entry
    }
    function sort(a, b) {
      return b.showTime - a.showTime
    }

    entries = _.cloneDeep(entries).map((item) => {
      const { details } = item
      item.type =
        (details &&
          (details.shift_activity_classification ||
            details.activity_classification)) ||
        item.type
      return item
    })

    const Aerobic = entries
      .filter((item) => item.type === 'Aerobic')
      .map((item) => calculateShowTime(item))
      .sort(sort)
    const Resistance = entries
      .filter((item) => item.type === 'Resistance')
      .map((item) => calculateShowTime(item))
      .sort(sort)
    const Activity = entries
      .filter((item) => item.type === 'Activity')
      .map((item) => calculateShowTime(item))
      .sort(sort)
    const MovementQuality = entries
      .filter((item) => item.type === 'Movement Quality')
      .map((item) => calculateShowTime(item))
      .sort(sort)
    const All = [].concat
      .call(Aerobic, Resistance, Activity, MovementQuality)
      .sort(sort)

    return {
      All,
      Aerobic,
      Resistance,
      Activity,
      'Movement Quality': MovementQuality
    }
  }

  showDetail = (workout) => {
    this.setState({ showDetail: true, workout })
  }

  getTrackingActivityMetrics = () => {
    let { trackingActivityMetrics } = this.props
    trackingActivityMetrics =
      trackingActivityMetrics ||
      'type,distance,duration,caloriesBurnt,avgHeartRate,maxHeartRate,heartRate,heartRateZone'
    return trackingActivityMetrics
  }

  setManualRpe = async (id, rpe) => {
    const { workout } = this.state
    const result = await this.props.setManualRpe(workout.id, rpe)
    workout.rpe_manual = result.rpe_manual
    workout.training_load = result.training_load
    this.setState({ workout })
    return result
  }

  componentDidMount() {
    const { sessionType } = this.props
    this.setState({
      showHRZIndex: sessionType || 'All'
    })
  }

  renderActivity() {
    const { workout } = this.state
    const trackingActivityMetrics = this.getTrackingActivityMetrics()
    const activityDetail = workout.details
    return (
      <div>
        <DetailItems activityDetail={activityDetail} showHeartRate={true} />
        <Divider />
        {trackingActivityMetrics && activityDetail && (
          <div className="activity-chart-container">
            {trackingActivityMetrics.includes('heartRate') && (
              <div className="activity-heart-rate-container">
                <div className="heart-rate-title">Heart Rate</div>
                <ActivityTrend
                  // id={activityDetailIndex}
                  detail={activityDetail}
                />
              </div>
            )}
            {trackingActivityMetrics.includes('heartRateZone') && (
              <HeartRateZoneStage
                editGoal={{}}
                activityDetail={activityDetail}
                totalWidth={200}
                calculatePercentage={true}
              />
            )}
          </div>
        )}
        <Divider />
        <RPE activityDetail={workout} setManualRpe={this.setManualRpe} />
      </div>
    )
  }

  render() {
    const { closeModal, overview, sessionType } = this.props
    const { showDetail, workout, showHRZIndex } = this.state
    let data
    if (overview) {
      data = this.getMetaData(overview.entries)
    }
    return (
      <RightHalfModalContainer extraClass="goal-detail-bg">
        <div
          className={`${activityStyle} ${workoutDetailStyle} container-body`}
        >
          <div className="header">
            <div className="title">
              {showDetail && (
                <LeftOutlined
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    this.setState({ activityEntry: null, showDetail: false })
                  }
                />
              )}
              {showDetail && workout
                ? moment(workout.showTime).format('ddd, MMM DD')
                : 'Workout Sessions'}
            </div>

            <div className="operate">
              {/* {showDetail && (
                <>
                  <img
                    src={EditIcon}
                    alt="edit"
                    onClick={() => {
                      openModal({
                        visible: 2,
                        targetDate: null
                      })
                      setGlobalState({
                        type:
                          goal.input_type === 'binary'
                            ? 'question'
                            : 'tracking',
                        pillar: goal.category.pillar,
                        editGoal: goal
                      })
                    }}
                  />
                  <img
                    src={TrashIcon}
                    alt="trash"
                    onClick={() => {
                      openModal({
                        visible: 4,
                        targetDate: null
                      })
                      setGlobalState({
                        targetGoal: goal
                      })
                    }}
                  />
                </>
              )} */}
              <CloseOutlined
                onClick={() => {
                  closeModal()
                }}
                style={{ fontSize: 20, color: '#383E48' }}
              />
            </div>
          </div>
          {showDetail ? (
            this.renderActivity()
          ) : (
            <div
              className="activity-toggle-container"
              style={{
                marginTop: 0
              }}
            >
              <Tabs
                defaultActiveKey={sessionType || 'All'}
                tabPosition="top"
                onChange={(activeKey) =>
                  this.setState({ showHRZIndex: activeKey })
                }
              >
                {type
                  .filter((item) => data[item].length > 0)
                  .map((item, index) => {
                    return (
                      <TabPane
                        tab={`${item} (${data[item] && data[item].length})`}
                        key={item}
                        className="activity-tab-description"
                      />
                    )
                  })}
              </Tabs>
              <div>
                <ul className="workout-list">
                  {data[showHRZIndex] &&
                    data[showHRZIndex].length > 0 &&
                    data[showHRZIndex].map((workout, index) => (
                      <li key={index}>
                        <Row type="flex" justify="space-between">
                          <Col span={7}>
                            <label>Date</label>
                            <div
                              className={`${
                                workout.manual ? '' : 'clickable'
                              } date`}
                              onClick={() =>
                                !workout.manual && this.showDetail(workout)
                              }
                            >
                              <span>
                                {workout.manual
                                  ? moment(workout.record_date).format(
                                      'ddd, MMM DD'
                                    )
                                  : moment(workout.showTime).format(
                                      'ddd, MMM DD - hh:mm A'
                                    )}
                              </span>
                            </div>
                          </Col>
                          <Col span={5}>
                            <label>Activity Name</label>
                            <div className="name">
                              {workout.manual
                                ? workout.details.activity_type_shown ||
                                  'Manual Workout'
                                : workout.details.activity_type_shown}
                            </div>
                          </Col>
                          <Col span={4}>
                            {' '}
                            <label>Type</label>
                            <div className="name">{workout.type}</div>
                          </Col>
                          <Col span={4}>
                            <label>Time</label>
                            <div className="name">
                              {this.getDuration(workout.details.duration)}
                            </div>
                          </Col>
                          <Col span={4}>
                            <label>Calories Burnt</label>
                            <div className="name">
                              {workout.details.energy_burned
                                ? Math.round(workout.details.energy_burned) +
                                  ' Cal'
                                : '--'}
                            </div>
                          </Col>
                        </Row>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </RightHalfModalContainer>
    )
  }
}

export default WorkoutSessions
