import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import Highlighter from 'react-highlight-words'
import { Modal, Button } from 'antd'
import { FileOutlined } from '@ant-design/icons'
import Editor from './editor'
import { loadUser } from '../../utils/storage'
import { linkify } from '../../utils/common'
import { workoutPillarColors } from '../../utils/constant'
import Confirm from '../UI/confirmModal'
import { getFileDetail } from '../../api/file'

const ListContainer = styled.div`
  .children-container {
    padding-left: 44px;
    & > div {
      margin-top: 16px;
    }
    .info-container {
      position: relative;
      .opr.is-owner {
        position: absolute;
        right: 0;
        margin-top: -20px;
      }
    }
  }
`
export default function NotesList(props) {
  const { list, person, submitNote, deleteNote, searchText, onSummary } = props
  const [showEditor, setShowEditor] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [target, setTarget] = useState(null)
  const [showAll, setShowAll] = useState(!(onSummary && list.length > 4))
  let showNotes = list
  if (!showAll) {
    showNotes = _.slice(list, 0, 4)
  }
  return (
    <>
      {showNotes.map((item) => (
        <ListContainer key={item.id}>
          <Item
            {...item}
            reply={() => reply(item)}
            edit={() => edit(item)}
            handleDelete={() => handleDelete(item)}
            searchText={searchText}
            onSummary={onSummary}
          />
          <div className="children-container">
            {item.children.map((child) => (
              <Item
                isChild
                key={child.id}
                {...child}
                searchText={searchText}
                reply={() => reply(item)}
                edit={() => edit(child)}
                handleDelete={() => handleDelete(child)}
                onSummary={onSummary}
              />
            ))}
          </div>
        </ListContainer>
      ))}
      {showEditor && (
        <EditorModal
          person={person}
          target={target}
          cancel={() => setShowEditor(false)}
          submitNote={submitNote}
        />
      )}
      {showDelete && (
        <ConfirmDelete
          onCancel={() => setShowDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {onSummary && list.length > 4 && (
        <span className="show-all" onClick={() => setShowAll(true)}>
          Show all notes
        </span>
      )}
    </>
  )

  async function handleDelete(item) {
    if (item) {
      setTarget(item)
      setShowDelete(true)
    } else {
      await deleteNote(target)
    }
  }

  function reply(item) {
    setTarget({ item, status: 'reply', title: 'Reply Note' })
    setShowEditor(true)
  }

  function edit(item) {
    setTarget({ item, status: 'edit', title: 'Edit Note' })
    setShowEditor(true)
  }
}

function ConfirmDelete(props) {
  const { handleDelete, onCancel } = props
  const [isDeleting, setIsDeleting] = useState(false)
  async function onConfirm() {
    setIsDeleting(true)
    await handleDelete()
    setIsDeleting(false)
    onCancel()
  }
  return (
    <Confirm
      {...{
        modalWidth: 564,
        title: 'Do you want to delete this Note?',
        onCancel: onCancel,
        footer: (
          <>
            <Button onClick={onCancel} style={{ height: 'auto' }}>
              Cancel
            </Button>
            <Button
              danger
              loading={isDeleting}
              disabled={isDeleting}
              style={{ height: 'auto', marginLeft: 20 }}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </>
        )
      }}
    />
  )
}

function EditorModal(props) {
  const {
    person,
    submitNote,
    cancel,
    target: { title, item, status }
  } = props
  return (
    <Modal
      width={890}
      title={title}
      visible={true}
      cancelText="Cancel"
      okText="Submit"
      onCancel={cancel}
      footer={null}
      maskClosable={false}
    >
      <Editor
        initialData={{ ...item, status }}
        submitNote={submitNote}
        person={person}
        cancel={cancel}
        showInModal
      />
    </Modal>
  )
}

const ItemContainer = styled.div`
  display: flex;
  margin-top: 16px;
  &:hover {
    &:not(.on-summary) .info-container .opr.is-owner {
      .btn {
        display: block;
      }
      .pillar-container {
        display: none;
      }
    }
    .summary-edit-opr {
      .btn {
        display: block;
      }
    }
    .reply {
      color: #97a0a7;
    }
  }
  mark {
    background-color: rgb(111, 187, 191);
    color: rgb(255, 255, 255);
    padding: 0px;
  }
  .reply {
    color: transparent;
    font-size: 12px;
    display: inline-block;
    cursor: pointer;
    &.has-children {
      color: #97a0a7;
    }
    &:hover {
      color: #264382;
    }
  }
  .avatar {
    width: 32px;
    height: 32px;
    width: 32px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 12px;
    background: #f1f3f6;
    img {
      width: 100%;
    }
    & + div {
      width: calc(100% - 44px);
    }
    & > div {
      margin-bottom: 5px;
    }
  }
  .opr {
    .btn {
      margin: 0 5px;
      display: none;
      font-size: 12px;
      color: #264382;
      cursor: pointer;
      &.delete {
        color: #ff3d00;
      }
    }
    &.summary-edit-opr {
      display: flex;
      position: absolute;
      right: 25px;
      margin-top: -22px;
      background: linear-gradient(
        270deg,
        #ffffff 75.52%,
        rgba(255, 255, 255, 0) 100%
      );
      padding-left: 60px;
    }
  }
  .info-container {
    color: #97a0a7;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    strong {
      font-size: 14px;
      color: #323f4a;
      font-family: 'Gilroy-Bold';
    }
    .opr {
      display: flex;
      .pillar-container {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline-block;
          margin: 0 5px;
          &:before {
            content: '·';
            color: rgba(18, 22, 45, 0.5);
            font-weight: bold;
            margin-right: 5px;
            display: inline-block;
          }
          &:first-child:before {
            content: none;
          }

          &.Exercise {
            color: ${workoutPillarColors.exercise.primary};
          }
          &.Nutrition {
            color: ${workoutPillarColors.nutrition.primary};
          }
          &.Sleep {
            color: ${workoutPillarColors.sleep.primary};
          }
          &.Medical {
            color: #223064;
          }
          &.Sales {
            color: orange;
          }
        }
      }
    }
  }
  .system.text-container {
    background: rgba(245, 186, 64, 0.1);
  }
  .text-container,
  .image-container img {
    border: 1px solid #dee2ec;
    border-radius: 4px;
    max-width: 100%;
  }
  .text-container {
    position: relative;
    padding: 12px 16px;
    margin-bottom: 10px;
    color: #60656d;
    white-space: pre-wrap;
    word-break: break-word;
    line-height: 0.5;
    & > div:first-child {
      line-height: 1.75;
    }
    .at-item {
      color: #264382;
      font-weight: 700;
    }
    .is-edited {
      color: #97a0a7;
    }
    img {
      max-width: 100%;
    }
  }
  .file-list {
    display: flex;
    flex-wrap: wrap;
    .file-item {
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 10px;
      align-items: center;
      padding: 8px 10px;
      display: flex;
      justify-content: space-between;
      background: rgba(38, 67, 130, 0.05);
      color: #264382;
      border-radius: 22px;
      .name {
        margin: 0 15px;
      }
    }
  }
`

function Item(props) {
  const loginUser = loadUser()
  const {
    created_person,
    created_at,
    updated_at,
    text,
    pillars,
    children,
    isChild,
    files,
    reply,
    edit,
    handleDelete,
    searchText,
    onSummary
  } = props

  const { avatar, full_name, id } = created_person || {
    full_name: 'System',
    avatar: {
      oss_url:
        'https://public-source.s3-us-west-2.amazonaws.com/person.avatar/system-avatar.png'
    }
  }

  const isOldImageNote = !text && files.length === 1 && !files[0].id

  return (
    <ItemContainer className={onSummary ? 'on-summary' : ''}>
      <div className="avatar">
        {avatar && <img src={avatar.oss_url} alt="avatar" />}
      </div>
      <div>
        <div className="info-container">
          <span>
            <>
              <strong>
                {/* {full_name} */}
                <HightLightContainer text={full_name} searchText={searchText} />
              </strong>{' '}
              {` ${moment(created_at).format('MMM DD, YYYY')} at ${moment(
                created_at
              ).format('h:mm A')}`}
            </>
            {onSummary && loginUser.id === id && (
              <div className="opr summary-edit-opr">
                <div className="btn delete" onClick={handleDelete}>
                  Delete
                </div>
                {!isOldImageNote && (
                  <div className="btn edit" onClick={edit}>
                    Edit
                  </div>
                )}
              </div>
            )}
          </span>
          <div className={`opr ${loginUser.id === id ? 'is-owner' : ''}`}>
            {loginUser.id === id && (
              <>
                <div className="btn delete" onClick={handleDelete}>
                  Delete
                </div>
                {!isOldImageNote && (
                  <div className="btn edit" onClick={edit}>
                    Edit
                  </div>
                )}
              </>
            )}
            <ul className="pillar-container">
              {pillars.map((pillar) => (
                <li key={pillar} className={pillar}>
                  {pillar}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isOldImageNote ? (
          renderImage()
        ) : (
          <>
            {text && renderText()}
            {files.length > 0 && (
              <div className="file-list">
                {files.map((file) => (
                  <div
                    className="file-item"
                    key={file.id}
                    onClick={() => handleFileClick(file)}
                  >
                    <FileOutlined />
                    <div className="name">
                      <HightLightContainer
                        text={file.name || 'unknow name'}
                        searchText={searchText}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {!isChild && created_person && (
          <div>
            <span
              className={`reply ${children.length > 0 ? 'has-children' : ''}`}
              onClick={reply}
            >
              Reply
            </span>
          </div>
        )}
      </div>
    </ItemContainer>
  )

  function renderImage() {
    const [{ url }] = files
    return (
      <div className="image-container ">
        <img src={url} alt="only-url" />
      </div>
    )
  }

  function renderText() {
    let finalText = text

    if (searchText) {
      finalText = text.replace(
        new RegExp(searchText, 'ig'),
        `<mark className="search-high-light">${searchText}</mark>`
      )
    }
    return (
      <div className={`text-container ${created_person ? '' : 'system'}`}>
        <div
          style={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word'
          }}
          dangerouslySetInnerHTML={{
            __html: linkify(finalText)
          }}
        />
        {updated_at !== created_at && (
          <span className="is-edited">
            <br />
            (Edited)
          </span>
        )}
      </div>
    )
  }
}

async function handleFileClick(file) {
  try {
    const _file = await getFileDetail(file.id)
    window.open(_file.attachment.oss_url)
  } catch (err) {}
}

function HightLightContainer(props) {
  const { text, searchText } = props
  return (
    <Highlighter
      highlightStyle={{
        backgroundColor: '#6FBBBF',
        color: '#ffffff',
        padding: 0
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text.toString()}
    />
  )
}
