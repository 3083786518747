import React from 'react'
import Notes from '../../../notes'
import RightDrawer from '../../../UI/rightDrawer'

export default function NotesContainer(props) {
  const {
    notesRecord: { client },
    title,
    closeModal
  } = props
  return (
    <RightDrawer
      {...{
        title,
        closeModal,
        visible: true
      }}
    >
      <Notes person={client} noFilter />
    </RightDrawer>
  )
}
