import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

import { style } from './index.sass'

class SignedCard extends Component {
  static propTypes = {
    user: PropTypes.object,
    viewSigned: PropTypes.func,
    loginRole: PropTypes.string
  }

  render() {
    const { user, viewSigned, loginRole } = this.props
    const completed_signature = (user && user.profile.completed_signature) || []

    return (
      <div className={style}>
        <div className="box-view signed-forms">
          <div className="box-title-container">
            <div className="box-title">Signed Forms</div>
            <div className="box-subtitle">
              {(loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
              completed_signature.length === 0
                ? 'There are no signed forms here'
                : 'View your signed forms'}
            </div>
          </div>
          {(completed_signature.length > 0 || loginRole !== 'Expert') && (
            <Button className="box-button" onClick={viewSigned}>
              {loginRole === 'Expert' || loginRole === 'InternalAdmin'
                ? 'View'
                : user && user.profile.waiver_required
                ? 'Sign'
                : 'View'}
            </Button>
          )}
        </div>
      </div>
    )
  }
}

export default SignedCard
