import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import BasicForm from '../../formV4'
import { editWorkout } from '../../../api/program'
import style from '../style/programEditor.module.sass'
import moment from 'moment'

const getFormData = () => [
  {
    type: 'input',
    label: 'Activity Name',
    dbField: 'activity_type_shown',
    disabled: true,
    colSpan: 12
  },
  {
    type: 'time',
    label: 'duration',
    dbField: 'formattedDuration',
    rules: [
      { required: true, message: 'This field is required' },
      {
        validator: (_, value) => {
          if (value === '00:00:00') {
            return Promise.reject(
              new Error('The selected time must be greater than 0!')
            )
          }
          return Promise.resolve()
        }
      }
    ],
    colSpan: 12,
    extraAttr: {
      showNow: false
    }
  }
]

class WorkoutEditor extends Component {
  static propTypes = {
    workout: PropTypes.object
  }

  state = { valueForm: null, isSaving: false }

  handleCancel = () => this.props.cancel()

  handleOk = async () => {
    const { workout, edited } = this.props

    try {
      const params = await this.basicFormRef.handleSubmit()
      this.setState({ isSaving: true })
      await editWorkout(workout.id, {
        duration: moment.duration(params.formattedDuration).as('seconds')
      })
      edited()
    } catch (err) {
      console.error(err)
      this.setState({ isSaving: false })
    }
  }

  updateFormsData = (changeValue, allValues) => {
    this.setState({ valueForm: allValues })
  }

  componentDidMount() {
    const { workout } = this.props

    if (workout.details) {
      const { activity_type_shown, formattedDuration } = workout.details
      this.setState({
        valueForm: {
          activity_type_shown,
          formattedDuration
        }
      })
    } else {
      this.setState({ valueForm: {} })
    }
  }

  render() {
    const { valueForm, isSaving } = this.state
    return (
      <Modal
        open={true}
        width={890}
        bodyStyle={{ padding: '36px 45px' }}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={null}
      >
        <h1 className={style.title}>Edit Workout</h1>
        {valueForm && (
          <BasicForm
            formData={getFormData()}
            initialValues={valueForm}
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            onChange={this.updateFormsData}
          />
        )}
        <footer className={style.footer}>
          <div></div>
          <div>
            <Button ghost onClick={this.handleCancel}>
              CANCEL
            </Button>
            <Button type="primary" onClick={this.handleOk} loading={isSaving}>
              CONFIRM
            </Button>
          </div>
        </footer>
      </Modal>
    )
  }
}

export default WorkoutEditor
