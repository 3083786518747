import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Goals from '../../../components/goals'
import LockStatus from '../../../components/UI/lockStatus'
import { userType } from '../../../utils/constant'
import NullImage from '../../../asserts/images/client-empty.png'

const NullContainer = styled.div`
  margin-top: 112px;
  text-align: center;
  margin-left: -78px;
  img {
    width: 287px;
    margin-bottom: 30px;
  }
  p {
    font-family: Heebo;
    font-size: 14px;
    line-height: 150%;
    color: #79879b;
    opacity: 0.8;
  }
`

class ClientMicroGoals extends Component {
  static propTypes = {}

  switchStatus = (type) => {
    const {
      history,
      match: { url }
    } = this.props
    const targetPath = url === '/' ? '/app' : url.split('/goals')[0]
    history.replace(`${targetPath}/goals/${type}`)
  }

  renderNullStatus(type) {
    return (
      <NullContainer>
        <img src={NullImage} alt="null status" />
        {type === 'archived' ? (
          <p>Your archived microgoals will appear here</p>
        ) : (
          <p>
            Your microgoals will appear here when your
            <br />
            Client Advocate sets them for you.
          </p>
        )}
      </NullContainer>
    )
  }
  render() {
    const {
      account: { user: person },
      match: {
        params: { type }
      }
    } = this.props
    const isNonCore = person.profile.account_type === userType.Non_Core
    if (isNonCore) {
      return (
        <>
          <h1 className="title">{person.first_name}’s Trends</h1>
          <LockStatus type="NON-CORE" />
        </>
      )
    }
    return (
      <Goals
        person={person}
        renderNullStatus={this.renderNullStatus}
        switchStatus={this.switchStatus}
        type={type || 'active'}
        loginRole="user"
      />
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  null
)(ClientMicroGoals)

// export default ClientMicroGoals
