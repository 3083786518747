import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import TrackingGoalIcon from '../../../asserts/icon/goals/tracking-goal.svg'
import QuestionIcon from '../../../asserts/icon/goals/question.svg'
import { goalsModal } from '../index.sass'

class SelectModal extends Component {
  static propTypes = {
    pillar: PropTypes.string,
    closeModal: PropTypes.func,
    openModal: PropTypes.func
  }

  state = {
    type: 'tracking'
  }

  confirm = () => {
    const { openModal, setGlobalState } = this.props
    const { type } = this.state
    const editGoal = {}
    if (type === 'question') {
      editGoal.target_goal = 'tracking'
    }
    openModal({ visible: 2 })
    setGlobalState({ editGoalType: type, editGoal })
  }
  render() {
    const { pillar, closeModal } = this.props
    const { type } = this.state
    return (
      <Modal
        visible={true}
        title={pillar.split('_').join(' ')}
        className={`${goalsModal} select-modal`}
        okText="CONFIRM"
        onCancel={closeModal}
        onOk={this.confirm}
      >
        <h1 className="select-type">Selecting Metric Type </h1>
        <div
          className={`select-type-icon ${
            type === 'tracking' ? 'selected' : ''
          }`}
          onClick={() => this.setState({ type: 'tracking' })}
        >
          <img src={TrackingGoalIcon} alt="icon" />
          <br />
          Tracking Metric
        </div>
        <div
          className={`select-type-icon ${
            type === 'question' ? 'selected' : ''
          }`}
          onClick={() => this.setState({ type: 'question' })}
        >
          <img src={QuestionIcon} alt="icon" />
          <br />
          Yes/No Question
        </div>
      </Modal>
    )
  }
}

export default SelectModal
