import styled from 'styled-components'

const Title = styled.h1`
  font-family: Gilroy;
  font-size: 24px;
  letter-spacing: 0.3px;
  color: #4c6072;
`

const Description = styled.div`
  font-family: Gilroy;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: rgba(0, 0, 0, 0.65);
  svg {
    margin-right: 8px;
  }
`

const Footer = styled.footer`
  text-align: right;
  margin-top: 60px;
  .ant-btn {
    height: 45px;
  }
  .ant-btn-primary {
    min-width: 140px;
  }
  .ant-btn-background-ghost {
    color: #4c6072;
    box-shadow: none;
    border: none;
    margin-right: 25px;
  }
`

const ModalStyled = { Title, Description, Footer }

export { ModalStyled }
