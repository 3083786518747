import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Popover } from 'antd'
import BodyMale from '../../../asserts/images/body-male.svg'
import BodyFemale from '../../../asserts/images/body-female.svg'
import { colors } from '../../../utils/constant'

export const levelColor = {}
for (const { key, value } of colors) {
  levelColor[key] = value
}

export const level = `
.level {
  font-size: 16px;
  display: inline;
  white-space: nowrap;
  color: #4C6072;
  font-family: 'Gilroy-Bold';
  border-bottom: 1px dashed;

  &.Optimal {
    color: ${levelColor.green};
  }
  &.Monitoring, &.Concern {
    color: ${levelColor.yellow};
  }
  &.risk, &.Risk {
    color: ${levelColor.red};
  }
}
`

export const BodyContainer = styled.div`
  padding: 0 80px;
  .total-score {
    display: flex;
    justify-content: space-between;
    width: 500px;
    margin: 0 auto;
    color: #4c6072;
    font-size: 32px;
    line-height: 150%;
    font-weight: 600;
    div {
      text-align: center;
    }
    span {
      color: #4c6072;
      font-size: 18px;
    }
  }

  .image-around {
    display: flex;
    justify-content: space-between;
    img {
      height: 500px;
    }
  }
  .label {
    margin-top: 50px;
    font-size: 14px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #4c6072;
    opacity: 0.6;
    white-space: nowrap;
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  ${level}
  .level {
    cursor: pointer;
  }
`

export const PopTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  text-transform: capitalize;
  align-items: center;
  ${level}
  .level {
    border: none !important;
    padding-left: 10px;
  }
`

export const PopContent = styled.div`
  ul {
    list-style: none;
    font-size: 14px;
    color: #4c6072;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: 25px;
    li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 13px;
      align-items: center;
      &:last-child {
        padding: 0;
      }
      .icon {
        display: inline-block;
        margin-right: 16px;
        // background: #cfc9c4;
        border-radius: 100%;
        width: 32px;
        height: 32px;
      }
    }
    .name {
      cursor: pointer;
    }
    ${level}
    .level {
      border: none !important;
    }
  }
`

export default function BodyStatus(props) {
  const { data, person } = props
  const ImageUrl = person.profile.gender === 'male' ? BodyMale : BodyFemale
  const totalScore = data['Total Systems Score']
  const bodyLeftItems = data['Core Lipids']
    ? [
        {
          name: 'Core Lipids'
        },
        {
          name: 'Advanced Cardiovascular'
        },
        {
          name: 'Liver'
        },
        {
          name: 'Kidney'
        },
        {
          name: 'Vitamin and Mineral'
        }
      ]
    : [
        {
          name: 'Liver'
        },
        {
          name: 'Kidney'
        },
        {
          name: 'Vitamin and Mineral',
          labelStyle: { marginRight: -20 }
        },
        { name: 'Environmental Toxins' }
      ]

  const bodyRightItems = data['Performance Hormones']
    ? [
        {
          name: 'Inflammation',
          labelStyle: { marginLeft: -40 },
          levelStyle: { marginLeft: -40 }
        },
        {
          name: 'Blood Sugar'
        },
        {
          name: 'Thyroid'
        },
        {
          name: 'Performance Hormones',
          labelStyle: { marginLeft: -40 },
          levelStyle: { marginLeft: -40 }
        }
      ]
    : [
        {
          name: 'Inflammation'
        },
        {
          name: 'Blood Sugar'
        },
        {
          name: 'Thyroid'
        },
        {
          name:
            person.profile.gender === 'male'
              ? 'Male Performance Hormones'
              : 'Female Performance Hormones'
        },
        {
          name: 'Environmental Toxins'
        }
      ]

  const linkToMetric = (metric) => {
    if (metric.group) {
      let url = window.location.pathname.split('ass-report')[0]
      if (metric.group === 'bio_marker') {
        url += 'blood-test'
      } else {
        url += metric.group.replace('_', '-')
      }
      url += `?metric=${metric.name}`
      window.location.href = window.location.origin + url
    }
  }

  function getOldContent(metrics) {
    return (
      <PopContent>
        <ul>
          {Object.keys(metrics).map((item, index) => (
            <li key={index}>
              <div>{item}</div>
              <div
                className="level"
                style={
                  metrics[item] && metrics[item].color
                    ? { color: levelColor[metrics[item].color] }
                    : {}
                }
              >
                {(metrics[item] && metrics[item].level) || '--'}
              </div>
            </li>
          ))}
        </ul>
      </PopContent>
    )
  }

  function getContent(metrics) {
    if (!_.isArray(metrics)) {
      return getOldContent(metrics)
    }
    return (
      <PopContent>
        <ul>
          {metrics.map((item, index) => {
            const { level, color, display_name, value } = item
            return (
              <li key={index}>
                <div
                  className="name level"
                  style={color ? { color: levelColor[color] } : {}}
                  onClick={() => linkToMetric(item)}
                >
                  {display_name} {value} {level && `(${level})`}
                </div>
                <div
                  className="level"
                  style={color ? { color: levelColor[color] } : {}}
                >
                  {/* {level || '--'} */}
                </div>
              </li>
            )
          })}
        </ul>
      </PopContent>
    )
  }

  function renderBodyItems(bodyItems) {
    return (
      <>
        {bodyItems.map((item, index) => (
          <div key={index}>
            <div
              className="label"
              onClick={() =>
                linkToMetric({ ...item, ...(data[item.name] || {}) })
              }
              style={item.labelStyle || {}}
            >
              {item.name}
            </div>
            {data[item.name] && (
              <Popover
                placement="topLeft"
                overlayClassName="popover-no-arrow"
                title={
                  <PopTitle>
                    <div>{item.name}</div>
                    <div
                      className="level"
                      style={{
                        color: levelColor[data[item.name].color]
                      }}
                    >
                      {data[item.name].level || '--'}
                    </div>
                  </PopTitle>
                }
                content={getContent(data[item.name].sub_metrics)}
                // content=""
              >
                <div
                  className={`level ${data[item.name].level}`}
                  style={{
                    ...(item.levelStyle || {}),
                    color: levelColor[data[item.name].color]
                  }}
                >
                  {data[item.name].level || '--'}
                </div>
              </Popover>
            )}
          </div>
        ))}
      </>
    )
  }
  return (
    <BodyContainer>
      {totalScore && (
        <div className="total-score">
          <div>
            {totalScore.value || '--'}
            <br />
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => linkToMetric(totalScore)}
            >
              Total Systems Score
            </span>
          </div>
          <div
            style={{
              color: levelColor[totalScore.color]
            }}
          >
            {totalScore.level || '--'}
            <br />
            <span>Classification</span>
          </div>
        </div>
      )}
      <div className="image-around">
        <div className="text-left">
          {/* <div className="label" style={{ marginRight: -20 }}>
            CardioMetabolic
          </div> */}

          {/* <div
            style={{ marginRight: -20, border: 'none', cursor: 'default' }}
            className={`level ${cardioLevel}`}
          >
            {cardioLevel || '--'}
          </div> */}

          {renderBodyItems(bodyLeftItems)}
        </div>
        <img src={ImageUrl} alt="" />

        <div className="text-right">{renderBodyItems(bodyRightItems)}</div>
      </div>
      {/* <div className="text-center">
        {renderBodyItems([{ name: 'Environmental Toxins' }])}
      </div> */}
    </BodyContainer>
  )
}
