import React, { Component } from 'react'
import ReactCodeInput from 'react-code-input'
import { verify2FA, enable2FA } from '../../api/people'
import { loadUser } from '../../utils/storage'
import './index.sass'
import enable2faVerify from '../../asserts/icon/2fa/icon-enable2fa-verify.svg'

const codeInputProps = {
  className: 'react-code-input',
  inputStyle: {
    margin: '9px',
    MozAppearance: 'textfield',
    width: '60px',
    borderRadius: '8px',
    fontSize: '24px',
    height: '60px',
    paddingLeft: '24px',
    backgroundColor: '#F5F6F8',
    color: '#545F6D',
    border: '0px'
  },
  inputStyleInvalid: {
    margin: '9px',
    MozAppearance: 'textfield',
    width: '60px',
    borderRadius: '8px',
    fontSize: '24px',
    height: '60px',
    paddingLeft: '24px',
    backgroundColor: '#F5F6F8',
    color: '#545F6D',
    border: '1px solid rgba(252, 46, 46, 0.5)'
  }
}

class VerifyCode extends Component {
  state = {
    codeIsValid: true,
    counter: 0
  }

  UNSAFE_componentWillMount() {
    clearInterval(this.resendCountDownID)
  }

  handleCodeChange = (e) => {
    if (e.length === 4) {
      this.checkCode(e)
    }
  }

  checkCode = async (code) => {
    this.setState({ loading: true })
    try {
      await verify2FA({ code: code }, loadUser().id)
      this.props.onSuccess()
    } catch (err) {
      this.setState({ codeIsValid: false })
    }
  }

  handleResend = async () => {
    const { phoneNumber } = this.props
    const { counter } = this.state
    if (counter !== 0) {
      return
    }
    this.setState({ counter: 30 })
    try {
      await enable2FA({ profile: { phone_number: phoneNumber } }, loadUser().id)
      this.resendCountDownID = setInterval(() => this.handleCountDown(), 1000)
    } catch (err) {
      this.setState({ loading: false })
    }
  }

  handleCountDown = () => {
    const { counter } = this.state
    this.setState((state, props) => ({
      counter: state.counter - 1 <= 0 ? 0 : state.counter - 1
    }))
    if (counter === 0) {
      clearInterval(this.resendCountDownID)
    }
  }

  render() {
    const { codeIsValid, counter } = this.state
    const { phoneNumber } = this.props
    return (
      <div className="columnContainer enterPhoneNumberContainer">
        <img src={enable2faVerify} alt="" style={{ paddingTop: 3 }} />
        <div
          className="enter-phone-number-top-title"
          style={{ paddingTop: 35 }}
        >
          2 FACTOR Authentication
        </div>
        <div className="main-title">Enter Your Phone Number</div>
        <div
          className="sub-title enter-phone-number-desc"
          style={{ width: 282 }}
        >
          A text message with a verification code was just sent to{' '}
          {phoneNumber.slice(0, phoneNumber.length - 2).replace(/./gi, '*')}
          {phoneNumber.slice(phoneNumber.length - 2)}
        </div>
        <div style={{ paddingTop: 23 }}>
          <ReactCodeInput
            type="number"
            fields={4}
            isValid={codeIsValid}
            onChange={this.handleCodeChange}
            {...codeInputProps}
          />
        </div>
        {!codeIsValid && (
          <div className="invalid-label">
            <span style={{ position: 'absolute' }}>
              The code is invalid. Try again
            </span>
          </div>
        )}
        <div className="resend-label">
          Haven’t received the text message?
          <span
            className={`resend-button ${
              counter === 0 ? '' : 'disable-resend-button'
            }`}
            onClick={this.handleResend}
          >
            Resend {counter === 0 ? '' : `${counter}s`}
          </span>
        </div>
      </div>
    )
  }
}

export default VerifyCode
