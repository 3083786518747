import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SucceedIcon, CloseIcon } from '../../icons/weeklySummary'
import { succeedMessageStyle } from './styles/succeedMessage.sass'

class SucceedMessage extends Component {
  static propTypes = {
    status: PropTypes.string,
    email: PropTypes.string,
    onDismiss: PropTypes.func
  }

  render() {
    let { status, email, onDismiss } = this.props
    status = status || 'sent'
    email = email || '*user*@apeiron.life'
    return (
      <div className={succeedMessageStyle}>
        <div className="icon">
          <SucceedIcon />
          <div className="content">
            <h3>Email {status} successfully</h3>
            {status === 'sent' ? (
              <p>Email message was successfully sent to {email}</p>
            ) : (
              <p>Email message was successfully saved as draft</p>
            )}
          </div>
        </div>

        <div className="close" onClick={onDismiss}>
          <CloseIcon />
        </div>
      </div>
    )
  }
}

export default SucceedMessage
