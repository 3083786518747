import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Information from '../UI/extraInformation'
import CheckDocs from './comp/checkDots'
import NormalBar from './comp/normalBar'
import SubTip from './comp/subTip'
import { calculateElementWidth } from './common'
import { SvgText } from '../icons/weeklySummary'
import { nutrition } from './directions.json'
import { nutritionStyle } from './styles/nutrition.sass'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const gray = '#b7bfc6',
  primary = '#383E48'

class Nutrition extends Component {
  static propTypes = {
    goals: PropTypes.array
  }

  renderGoal(item, index) {
    const { deleteNutrition, startDate, endDate, isLocked } = this.props

    const isEditable = !isLocked && this.props.isEditable

    if (item.description.toLowerCase().includes('weight')) {
      const latest = item.details[0]
      if (latest) {
        item.showLatestData = latest
      } else {
        item.showLatestData = { summary_date: null, value: '--' }
      }
    }

    const SevenDayText =
      item.average || item.average === 0
        ? item.input_type === 'binary'
          ? Math.round(item.average * 100) + '%'
          : item.showLatestData
          ? item.showLatestData.value
          : item.average
        : '--'
    return (
      <div
        className="goal-item"
        key={index}
        style={{ borderTopWidth: index > 0 ? 1 : 0 }}
      >
        {isEditable && (
          <div
            className="delete"
            onClick={() =>
              deleteNutrition('nutrition', item.id, item.description)
            }
          >
            <img className="delete-icon" src={DelIcon} alt="del" />
          </div>
        )}
        <div className="name">{item.description}</div>

        <div
          className="data-content ref-container"
          ref={`nutrition.chart_url.${item.id}`}
        >
          <div>
            <div className="days">
              <SvgText
                text={SevenDayText}
                height={33}
                fontSize={30}
                width={
                  calculateElementWidth('span', SevenDayText, '30px').width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
              <SubTip
                text={
                  item.showLatestData
                    ? item.showLatestData.summary_date
                      ? `${moment(item.showLatestData.summary_date).format(
                          'MMM DD, YYYY'
                        )}`
                      : '--'
                    : '7 DAY AVG'
                }
                unit={item.unit}
              />
            </div>
            <div className="weeks">
              <SvgText
                text={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? item.input_type === 'binary'
                      ? Math.round(item.previous_weeks_average * 100) + '%'
                      : item.previous_weeks_average
                    : '--'
                }
                fill={
                  item.previous_weeks_average ||
                  item.previous_weeks_average === 0
                    ? gray
                    : primary
                }
                fontSize={30}
                height={33}
                width={
                  calculateElementWidth(
                    'span',
                    item.previous_weeks_average ||
                      item.previous_weeks_average === 0
                      ? item.input_type === 'binary'
                        ? Math.round(item.previous_weeks_average * 100) + '%'
                        : item.previous_weeks_average
                      : '--',
                    '30px'
                  ).width + 5
                }
                textAttr={{
                  x: 0,
                  textAnchor: 'start',
                  dominantBaseline: 'mathematical'
                }}
              />
              <SubTip text="3-WEEK AVG" unit={item.unit} />
            </div>
          </div>
          <div className="chart">
            {item.input_type === 'binary' ? (
              <CheckDocs
                size="large"
                showLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            ) : (
              <NormalBar
                barWidth={16}
                showXLabel={true}
                showLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      setSubmitData,
      originInformation,
      submitData,
      goals: renderGoals,
      loginRole,
      templates,
      updateEditingCount,
      updateEmailAction,
      isLocked
    } = this.props
    const status = isLocked
      ? 'Locked'
      : (originInformation && originInformation.status) || 'Draft'
    return (
      <div className={nutritionStyle}>
        <div style={{ marginBottom: 20 }}>
          <Information
            direction={nutrition}
            updateEmailAction={updateEmailAction}
            updateEditingCount={updateEditingCount}
            status={status}
            title="Overview"
            templates={templates}
            showDone={loginRole !== 'User'}
            initialValue={submitData.nutrition_recommendation}
            doneStatus={submitData.nutrition_recommendation_status}
            updateCommend={(value, status) =>
              setSubmitData({
                part: null,
                field: 'nutrition_recommendation',
                value,
                status,
                section: 'Nutrition'
              })
            }
          />
        </div>
        {renderGoals.map((item, index) => this.renderGoal(item, index))}
      </div>
    )
  }
}

export default Nutrition
