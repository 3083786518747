import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DatasetContainer from '../../../components/business/dataset/container'
import { getBreadCrumbList } from '../../../models/breadcrumb.model'
import { getTitleByType } from '../../../components/business/dataset/common'
class Dataset extends Component {
  static propTypes = {}

  state = {
    person: null
  }

  initial = async (type) => {
    const { getPerson, setClientBreadcrumb } = this.props
    const person = await getPerson()
    this.setState({ person })
    setClientBreadcrumb(getBreadCrumbList(person, type))
  }

  componentDidMount() {
    const {
      match: {
        params: { type }
      },
      setClientPageTitle
    } = this.props
    this.initial(type)
    setClientPageTitle(getTitleByType(type))
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { type }
      },
      setClientPageTitle
    } = prevProps
    const newType = this.props.match.params.type
    if (newType !== type) {
      newType && setClientPageTitle(getTitleByType(newType))
      // setClientPageTitle(
      //   navagations.filter((item) => item.id === newType)[0].title
      // )
      this.initial(newType)
    }
  }

  render() {
    const {
      match: {
        params: { type }
      },
      slideMenu: { groups }
    } = this.props
    const { person } = this.state
    return (
      (person && groups && (
        <DatasetContainer {...this.props} {...{ type, person }} />
      )) || <div />
    )
  }
}

export default connect(
  ({ slideMenu }) => ({ slideMenu }),
  null
)(Dataset)
