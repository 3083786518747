import React, { useEffect, useState } from 'react'
import ExportDataContainer from '../../../components/exportData'
import { loadUser } from '../../../utils'

export default function ExportData({getPerson, setClientPageTitle}) {

  const [person, setPerson] = useState({})

  useEffect(() => {
    setClientPageTitle && setClientPageTitle(null)
    if(getPerson){
      getPerson().then(e => setPerson(e))
    }else{
      setPerson(loadUser())
    }
  }, [])

  return <ExportDataContainer person={person}/>
}
