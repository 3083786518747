import React, { Component } from 'react'
import PropTypes from 'prop-types'
import QuestionsContainer from '../../../components/questionnaire/container'
import { withRouter } from 'react-router-dom'
import { getPeopleById } from '../../../api/people'
import { saveUser } from '../../../utils/storage'

class Questionnaire extends Component {
  static propTypes = {
    getPerson: PropTypes.func
  }

  state = {
    person: null,
    answer: null,
    survey: null
  }

  initial = async () => {
    const { person } = await getPeopleById('me')
    saveUser(person)
    this.setState({ person })
  }

  toEditAnswer = () => {
    const { editSection } = this.state

    let url = `/onboarding/questionnaire?callback=${this.props.location.pathname}`
    if (editSection && editSection > 1) {
      url += `&section=${editSection - 1}`
    }

    this.props.history.push(url)
  }

  viewScore = () => {
    this.props.history.push('/app/questionnaire/score')
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    const env = process.env.REACT_APP_WORKFLOW
    const haveScore = env !== 'staging' && env !== 'production'
    return (
      <div className="question-con">
        <h1 className="page-title">Questionnaire</h1>
        <div className="btn-group">
          {haveScore && (
            <div className="btn-edit-answer" onClick={this.viewScore}>
              View Score
            </div>
          )}
          <div className="btn-edit-answer" onClick={this.toEditAnswer}>
            Edit Answers
          </div>
        </div>
        {person && (
          <QuestionsContainer
            person={person}
            setEditSection={(editSection) => this.setState({ editSection })}
          />
        )}
      </div>
    )
  }
}

export default withRouter(Questionnaire)
