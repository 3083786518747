import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import BasicForm from '../../../components/formV4'
import RangeForm from '../../../components/formV4/rangeForm'
import EditableForm from '../../../components/formV4/rangeFormsWrapped'
import { basic, selectBasic } from '../../../models/adminEditor.form'
import { Button, Divider, message, Select, Modal } from 'antd'

const RangesForm = EditableForm(RangeForm)
const Option = Select.Option

class BloodTestForm extends Component {
  static propTypes = {
    biomarker: PropTypes.object,
    addRange: PropTypes.func,
    removeRange: PropTypes.func,
    update: PropTypes.func
  }

  state = {
    convRange: null,
    funcRange: null,
    loading: false
  }
  rangeFormCon = null
  rangeFormFun = null

  getPushlishParamsObj = async () => {
    const { valueType } = this.props
    const biomarker = await this.basicFormRef.handleSubmit()
    biomarker.conventional_ranges =
      this.rangeFormCon && (await this.rangeFormCon.getPostRanges())
    biomarker.functional_ranges =
      this.rangeFormFun && (await this.rangeFormFun.getPostRanges())
    if (valueType !== 'number') {
      let options = biomarker.conventional_ranges.map((item) => item.name)
      const options1 =
        (biomarker.functional_ranges &&
          biomarker.functional_ranges.map((item) => item.name)) ||
        []
      options = options.concat(options1)
      options = Array.from(new Set(options))

      biomarker.options = options
    } else {
      biomarker.options = null
    }

    return biomarker
  }

  publish = async () => {
    const { update } = this.props
    try {
      const biomarker = await this.getPushlishParamsObj()
      this.setState({ loading: true })
      await update(biomarker)
      this.setState({ loading: false })
      message.success('Biomarker is published!')
    } catch (err) {
      this.setState({ loading: false })
      console.error(err)
      if (err.message === 'Form validate error') {
        const modal = Modal.error()
        modal.update({
          content: 'Cannot pass the form validation, please check your input.'
        })
      } else if (err.message === 'Range Name Conflict') {
        const modal = Modal.error()
        modal.update({
          content: 'Range name conflict.'
        })
      }
    }
  }

  updateRangeFormData = (changedFields, type, index, ranges) => {
    const field = changedFields.name
    if (!field) return
    ranges[index][field.name] = field.value
    if (type === 'conv') {
      this.setState({ convRange: ranges })
    } else {
      this.setState({ funcRange: ranges })
    }
  }

  optionsOpr = (type, index, value) => {
    let { options } = this.state
    const { biomarker } = this.props
    options = options || biomarker.options || []
    switch (type) {
      case 'add':
        options.push('')
        break
      case 'remove':
        options.splice(index, 1)
        break
      case 'update':
        options[index] = value
        break
      default:
        break
    }
    this.setState({ options })
  }

  renderRanges(type) {
    const { biomarker, valueType, ageScopes } = this.props
    const { convRange, funcRange, refreshing } = this.state
    let ranges,
      options = []
    if (type === 'conv') {
      ranges = convRange || (!refreshing && biomarker.conventional_ranges) || []
    } else {
      ranges = funcRange || (!refreshing && biomarker.functional_ranges) || []
    }
    if (valueType !== 'number') {
      options = ranges.map((item) => item.name)
    }

    return (
      <div className="ranges-container">
        {biomarker && ranges && (
          <RangesForm
            type="blood-test"
            defaultValues={ranges || []}
            options={options}
            // onRefChange={refs =>
            //   type === 'conv'
            //     ? (this.conRangeRefs = refs)
            //     : (this.funcRangeRefs = refs)
            // }
            ref={(r) =>
              type === 'conv'
                ? (this.rangeFormCon = r)
                : (this.rangeFormFun = r)
            }
            editKey={`range${biomarker.id}`}
            valueType={valueType}
            optionsOpr={this.optionsOpr}
            ageScopes={ageScopes || []}
          />
        )}
      </div>
    )
  }

  render() {
    const { biomarker, valueType, changeValueType } = this.props
    const { loading } = this.state
    return (
      <div className="edit-container">
        <div className="value-type">
          <span>Value Type:</span>
          <Select
            style={{ width: 130 }}
            value={valueType}
            onChange={changeValueType}
          >
            <Option value="number">Number</Option>
            <Option value="multi-select">Multi-select</Option>
          </Select>
        </div>
        <BasicForm
          wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          initialValues={biomarker}
          // formData={basic}
          formData={valueType === 'number' ? basic : selectBasic}
        />
        <Divider>Conventional Ranges Divider</Divider>
        {this.renderRanges('conv')}
        {/* comment on sprint 23 */}
        {/* <Divider>Functional Ranges Divider</Divider>
        {this.renderRanges('func')} */}
        <Divider orientation="left">
          {(biomarker.updated_at || biomarker.updated_by_person) &&
            `Last updated ${biomarker.updated_by_person &&
              'by ' +
                ((biomarker.updated_by_person.profile &&
                  biomarker.updated_by_person.profile.nickname) ||
                  biomarker.updated_by_person
                    .first_name)} ${biomarker.updated_at &&
              ' at ' + moment(biomarker.updated_at).format('YYYY-MM-DD')}`}
        </Divider>
        <Button
          className="btn-publish"
          loading={loading}
          onClick={this.publish}
          type="primary"
        >
          PUBLISH
        </Button>
      </div>
    )
  }
}

export default BloodTestForm
