import React, { useState } from 'react'
import { Popover } from 'antd'

export default function WomanFront(props) {
  const { opacities, getContent, quality, overlayStyle, colorMaps } = props
  const [hoverIndex, setHover] = useState(null)
  return (
    <svg
      width="350"
      height="630"
      viewBox="0 0 263 506"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.6941 378.305C97.0269 379.436 98.0931 380.829 99.5148 381.787C98.8928 384.573 99.4259 387.271 99.5148 389.97C99.6925 392.408 99.6925 394.845 99.6925 397.283C99.6036 406.162 101.114 414.868 102.802 423.66C103.957 429.841 106.623 435.413 108.222 441.419C110.088 448.036 112.043 454.652 112.931 461.442C113.465 465.621 113.198 469.799 113.109 473.978"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.307 259.786C92.5841 263.15 92.8507 266.003 92.5841 268.528C92.2287 272.793 92.4064 277.146 92.1399 281.499C91.6956 288.463 93.2061 295.253 95.1608 302.044C97.2933 309.182 100.403 315.885 102.802 322.85C105.201 329.814 106.712 336.866 106.356 344.178"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.964 211.158C88.4082 219.864 87.0755 228.482 85.6538 237.101C84.943 241.192 83.2548 244.935 82.3663 249.027C81.5666 252.857 80.8558 256.775 80.3227 260.692C79.9673 262.956 80.0561 265.306 79.7896 267.57"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.992 302.795C115.726 306.712 114.62 309.964 113.553 313.794C112.132 318.756 110.977 323.805 111.243 329.029C111.51 333.556 112.932 337.734 116.13 341.129C119.24 344.437 121.728 343.219 122.972 339.911C123.772 337.734 124.459 334.854 124.637 332.59"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.5537 193.225C57.2209 197.403 54.9107 200.972 52.334 204.455C47.4471 211.158 42.3825 217.774 37.4068 224.564C35.6297 227.002 33.5861 229.352 32.2533 232.051C30.4763 235.707 28.6104 239.451 29.6766 243.716"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4213 193.659C55.0885 197.141 53.0449 200.101 50.8236 203.061C46.6475 208.807 41.7606 214.03 37.7622 219.95C34.919 224.302 32.6088 229.091 28.877 233.008C26.3891 235.62 24.2567 238.579 20.9691 240.234C20.3472 240.582 19.6363 241.017 18.8367 240.843"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.237 244.239C127.237 245.98 126.171 247.46 125.46 249.027C123.594 253.032 122.616 257.21 121.817 261.563C121.106 265.568 120.573 269.572 119.773 273.49C119.507 274.708 119.684 276.014 119.596 277.233C119.329 281.673 117.961 285.956 119.027 290.483L119.329 292.525"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.548 90.8482C123.149 89.1071 120.217 89.1071 117.463 88.5848C113.553 87.8013 109.644 87.4531 105.734 86.5825C103.957 86.1472 101.914 86.1472 99.9589 86.0602C97.0268 85.8861 94.0947 85.0155 91.2514 84.9285C88.6746 84.8414 86.0091 83.9709 83.3435 84.5802"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.7996 116.997C83.7317 116.997 84.4987 117.574 86.72 120.011C88.9413 122.362 91.6069 124.451 94.9833 125.06C98.0932 125.583 101.203 126.018 104.402 125.583C109.111 124.886 113.642 123.755 118.263 122.623"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.2033 198.274C40.2502 205.674 36.4295 214.205 32.9643 222.911C32.0758 225.087 31.3649 227.438 29.5879 229.179"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.707 248.767C117.996 251.901 116.574 254.686 115.686 257.733C113.998 264.001 113.376 270.269 113.998 276.711"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.553 111.306C110.355 112.96 107.422 114.962 104.135 116.529C98.8038 119.228 93.7758 120.504 87.9115 118.589C87.3784 118.415 83.4171 116.129 82.884 116.216"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.613 143.082C130.613 146.216 130.347 149.35 130.08 152.484C129.813 155.966 130.613 159.535 130.435 163.105C130.347 165.02 130.347 167.022 129.813 168.937"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.5071 145.922C97.5071 146.183 97.7378 146.651 98.0044 146.912C101.736 149.698 102.714 153.963 103.424 157.968C104.579 163.975 105.823 169.894 109.733 174.857"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.946 66.9951C131.413 67.0822 131.235 67.3433 131.324 67.8657C131.59 70.9126 130.879 73.8724 130.791 76.8323C130.702 79.9663 130.524 83.2744 128.125 85.799"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.7183 81.8818C91.6068 82.3171 92.4953 82.3171 93.4727 82.3171C97.6488 82.3171 101.736 82.7524 105.823 83.5359C107.156 83.797 108.666 83.5359 110.088 83.797"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4937 137.684C97.2045 139.773 99.1592 140.643 101.025 141.166C102.18 141.514 102.713 141.862 103.158 142.907C104.935 146.737 105.468 150.829 106.534 154.833C107.067 156.662 107.511 158.577 108.4 160.231"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.6262 162.756C99.337 164.062 99.6036 165.716 101.203 166.499C101.647 166.673 101.381 167.196 101.381 167.544C101.558 172.767 103.602 177.294 107.156 181.124"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.813 171.896C129.635 177.99 130.435 184.171 129.635 190.265"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.103 64.123C129.458 70.2169 129.725 76.3107 127.148 82.0564"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.972 244.239C117.374 249.288 112.22 254.686 109.821 261.998C109.466 262.956 108.755 264.001 109.377 265.132"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129.813 192.354C129.991 196.62 130.08 200.886 130.435 205.064C130.524 205.935 131.324 206.457 131.057 207.328"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.553 41.1396C115.686 42.7937 117.729 44.6218 119.951 46.1888C121.905 47.5817 121.283 49.7581 120.928 51.5862C120.75 52.5438 120.928 53.5885 120.395 54.5461"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.31 32.3477C115.686 36.004 120.484 36.8745 124.482 34.6981C125.46 34.1758 126.082 33.4794 126.615 32.6088"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.686 412.167C116.041 414.082 116.396 416.084 116.752 418C117.196 420.437 118.351 422.352 120.128 424.094C120.661 424.616 121.283 424.964 122.083 424.877"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.886 479.897C114.531 483.118 114.442 486.34 112.754 489.299C112.309 490.083 111.961 490.336 111.516 491.12"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.771 51.1514C124.393 54.8947 125.015 58.6381 126.792 62.0332"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.019 480.681C118.174 483.989 118.884 487.297 117.463 490.692C117.374 490.953 117.285 490.692 117.019 490.692"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.574 36.5264C120.128 38.6157 123.949 40.1827 126.792 43.2296"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.865 480.332C111.243 484.075 110.799 487.906 106.712 489.908"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.104 87.8886C121.994 85.9734 118.618 84.6676 114.886 84.3193"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.792 62.8164C126.792 65.5151 126.881 68.1268 125.282 70.5643"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.217 389.446C117.907 390.926 117.374 393.277 117.019 395.714C116.93 396.062 116.238 397.468 116.238 397.949"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.381 159.622C101.824 161.798 102.179 163.975 101.381 166.064"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.613 55.3301C130.169 57.5064 128.392 58.9864 128.036 61.1628"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.6262 156.053C99.6925 158.49 100.759 159.274 103.069 159.187"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.335 149.784C102.535 149.697 101.736 149.61 101.025 149.61"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.235 378.305C165.902 379.436 164.836 380.829 163.414 381.787C164.036 384.573 163.503 387.271 163.414 389.97C163.237 392.408 163.237 394.845 163.237 397.283C163.326 406.162 161.815 414.868 160.127 423.66C158.972 429.841 156.306 435.413 154.707 441.419C152.841 448.036 150.886 454.652 149.998 461.442C149.465 465.621 149.731 469.799 149.82 473.978"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.622 259.786C170.345 263.15 170.078 266.003 170.345 268.528C170.7 272.793 170.522 277.146 170.789 281.499C171.233 288.463 169.723 295.253 167.768 302.044C165.635 309.182 162.526 315.885 160.127 322.85C157.728 329.814 156.217 336.866 156.572 344.178"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M174.965 211.158C174.52 219.864 175.853 228.482 177.275 237.101C177.986 241.192 179.674 244.935 180.562 249.027C181.362 252.857 182.073 256.775 182.606 260.692C182.961 262.956 182.873 265.306 183.139 267.57"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.937 302.795C147.203 306.712 148.309 309.964 149.375 313.794C150.797 318.756 151.952 323.805 151.686 329.029C151.419 333.556 149.997 337.734 146.799 341.129C143.689 344.437 141.201 343.219 139.957 339.911C139.157 337.734 138.469 334.854 138.292 332.59"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.375 193.226C205.708 197.404 208.018 200.973 210.595 204.456C215.482 211.159 220.546 217.775 225.522 224.565C227.299 227.003 229.343 229.353 230.675 232.052C232.452 235.708 234.318 239.452 233.252 243.717"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.507 193.659C207.84 197.141 209.884 200.101 212.105 203.061C216.281 208.807 221.168 214.03 225.166 219.95C228.01 224.302 230.32 229.091 234.052 233.008C236.54 235.62 238.672 238.579 241.96 240.234C242.582 240.582 243.292 241.017 244.092 240.843"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.692 244.239C135.692 245.98 136.759 247.46 137.469 249.027C139.335 253.032 140.313 257.21 141.112 261.563C141.823 265.568 142.356 269.572 143.156 273.49C143.423 274.708 143.245 276.014 143.334 277.233C143.6 281.673 144.968 285.956 143.902 290.483L143.6 292.525"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.38 90.8482C139.779 89.1071 142.711 89.1071 145.466 88.5848C149.375 87.8013 153.285 87.4531 157.194 86.5825C158.971 86.1472 161.015 86.1472 162.97 86.0602C165.902 85.8861 168.834 85.0155 171.677 84.9285C174.254 84.8414 176.92 83.9709 179.585 84.5802"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.129 116.997C179.197 116.997 178.43 117.574 176.209 120.011C173.987 122.362 171.322 124.451 167.945 125.06C164.836 125.583 161.726 126.018 158.527 125.583C153.818 124.886 149.286 123.755 144.666 122.623"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M216.726 198.274C222.679 205.674 226.499 214.205 229.965 222.911C230.853 225.087 231.564 227.438 233.341 229.179"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.222 248.767C144.933 251.901 146.354 254.686 147.243 257.733C148.931 264.001 149.553 270.269 148.931 276.711"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.375 111.306C152.574 112.96 155.506 114.962 158.794 116.529C164.125 119.228 169.153 120.504 175.017 118.589C175.55 118.415 179.512 116.129 180.045 116.216"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.316 143.082C132.316 146.216 132.582 149.35 132.849 152.484C133.115 155.966 132.316 159.535 132.493 163.105C132.582 165.02 132.582 167.022 133.115 168.937"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.422 145.922C165.422 146.183 165.191 146.651 164.925 146.912C161.193 149.698 160.215 153.963 159.505 157.968C158.349 163.975 157.106 169.894 153.196 174.857"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.983 66.9951C131.516 67.0822 131.694 67.3433 131.605 67.8657C131.338 70.9126 132.049 73.8724 132.138 76.8323C132.227 79.9663 132.405 83.2744 134.804 85.799"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.21 81.8818C171.322 82.3171 170.433 82.3171 169.456 82.3171C165.28 82.3171 161.192 82.7524 157.105 83.5359C155.772 83.797 154.262 83.5359 152.84 83.797"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.435 137.684C165.724 139.773 163.769 140.643 161.904 141.166C160.748 141.514 160.215 141.862 159.771 142.907C157.994 146.737 157.461 150.829 156.395 154.833C155.862 156.662 155.417 158.577 154.529 160.231"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.302 162.757C163.592 164.063 163.325 165.717 161.726 166.5C161.282 166.674 161.548 167.197 161.548 167.545C161.37 172.768 159.327 177.295 155.773 181.125"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.115 171.896C133.293 177.99 132.493 184.171 133.293 190.265"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.826 64.123C133.471 70.2169 133.204 76.3107 135.781 82.0564"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.957 244.239C145.555 249.288 150.708 254.686 153.107 261.998C153.463 262.956 154.173 264.001 153.552 265.132"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.115 192.354C132.938 196.62 132.849 200.886 132.493 205.064C132.404 205.935 131.605 206.457 131.871 207.328"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.375 41.1396C147.243 42.7937 145.199 44.6218 142.978 46.1888C141.023 47.5817 141.645 49.7581 142.001 51.5862C142.178 52.5438 142.001 53.5885 142.534 54.5461"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.619 32.3477C147.243 36.004 142.445 36.8745 138.447 34.6981C137.469 34.1758 136.847 33.4794 136.314 32.6088"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.243 412.167C146.888 414.082 146.532 416.084 146.177 418C145.732 420.437 144.577 422.352 142.8 424.094C142.267 424.616 141.645 424.964 140.846 424.877"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.042 479.897C148.398 483.118 148.487 486.34 150.175 489.299C150.619 490.083 150.968 490.336 151.412 491.12"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.157 51.1514C138.536 54.8947 137.914 58.6381 136.136 62.0332"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.91 480.681C144.755 483.989 144.044 487.297 145.466 490.692C145.555 490.953 145.644 490.692 145.91 490.692"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.355 36.5264C142.801 38.6157 138.98 40.1827 136.137 43.2296"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.064 480.332C151.686 484.075 152.13 487.906 156.217 489.908"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.825 87.8886C140.935 85.9734 144.311 84.6676 148.043 84.3193"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.136 62.8164C136.136 65.5151 136.048 68.1268 137.647 70.5643"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.711 389.446C145.022 390.926 145.555 393.277 145.91 395.714C145.999 396.062 146.691 397.468 146.691 397.949"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.548 159.622C161.104 161.798 160.749 163.975 161.548 166.064"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.316 55.3301C132.76 57.5064 134.537 58.9864 134.893 61.1628"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.302 156.053C163.236 158.49 162.17 159.274 159.86 159.187"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M159.593 149.784C160.393 149.697 161.193 149.61 161.904 149.61"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.8054 129.936C94.2723 131.503 95.2496 132.895 95.5162 134.288C97.8264 144.822 98.5372 155.356 98.4483 166.063C98.4483 167.543 97.5598 168.027 97.5598 170.242C98.4483 173.289 97.8264 176.336 98.0041 179.47C98.2706 182.691 97.1155 185.738 96.0493 188.698C94.45 193.05 92.6729 197.403 89.9185 201.233C89.1188 202.365 89.2965 203.932 88.5857 205.151C86.0978 209.243 84.4985 213.769 83.2545 218.296C82.0106 222.91 81.2998 227.524 80.8555 232.312C80.3224 237.622 80.9444 242.933 80.4113 248.156C79.967 252.857 80.0559 257.471 79.7005 262.172C79.4339 265.567 79.8782 268.875 80.2336 272.183C80.8555 278.277 81.7441 284.284 82.7214 290.378C83.5211 295.601 84.765 300.737 86.3644 305.873C87.4306 309.356 87.8749 313.012 88.8523 316.581C90.8959 323.546 92.5841 330.597 94.8054 337.474C95.0719 338.432 95.2496 339.39 95.2496 340.434C95.4273 348.53 96.4936 356.626 97.6486 364.636C98.0929 367.595 97.2044 370.381 96.7601 373.167C96.1382 376.823 95.5162 380.567 94.9831 384.223C94.45 387.879 93.3837 391.448 92.7618 395.105C91.6067 401.634 90.9847 408.163 91.3401 414.779C91.5178 417.565 92.0509 420.438 93.7391 422.962C94.8054 424.703 94.6277 426.967 95.1608 428.969C95.9605 432.103 97.9152 434.802 98.5372 437.936C99.6923 443.333 102.18 448.295 103.691 453.606C105.201 458.916 106.8 464.313 107.778 469.711C108.311 472.409 107.867 475.282 107.867 478.068C107.867 478.851 107.778 479.548 108.4 480.419C108.844 481.115 108.755 482.334 108.133 483.465C105.823 487.731 102.358 490.778 98.0041 493.041C96.7601 493.738 95.605 494.26 94.9831 495.74C94.45 496.872 94.7165 497.742 95.3385 498.439C96.0493 499.309 97.1155 499.309 98.0929 498.7"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.9185 210.374C89.9185 212.115 90.5404 213.682 90.807 215.423C91.5178 220.559 91.7844 225.783 92.6729 230.919C93.3837 234.924 94.0945 238.928 95.1608 242.758C95.605 244.5 95.4273 245.979 95.0719 247.633C94.4499 250.593 94.1834 253.64 93.7391 256.6C92.9394 262.259 92.0509 267.83 91.4289 273.489C90.9847 277.232 90.807 280.976 90.807 284.806C90.807 291.422 90.3627 298.038 91.4289 304.654C92.4063 310.574 93.5614 316.494 94.8942 322.414C95.605 325.461 96.3158 328.507 96.7601 331.641C97.2044 335.124 98.3594 338.432 99.7811 341.653C101.469 345.396 102.447 349.314 103.424 353.231C104.135 355.93 103.957 358.89 104.401 361.762C104.935 365.419 106.001 368.988 106.712 372.644C106.8 373.167 107.163 375.061 107.43 375.41"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.7393 214.553C96.4937 222.91 98.1819 231.528 100.581 240.06C103.335 249.81 106.889 259.299 110.621 268.701C113.553 276.013 117.107 283.152 118.973 290.813C120.217 295.775 121.195 300.128 122.794 305.09C122.883 305.351 124.208 309.074 124.208 309.074"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.957 381.872C107.245 386.573 109.2 391.796 110.266 397.368C110.799 400.328 111.154 403.375 111.154 406.334C111.065 415.04 111.332 423.745 111.687 432.451C111.954 437.239 112.309 442.114 112.843 446.902C113.553 453.431 114.797 459.873 115.952 466.315C116.219 467.708 116.397 469.101 117.196 470.32"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.13 152.656C115.952 157.88 116.13 163.103 116.485 168.326C116.663 171.025 116.397 173.724 116.397 176.509C116.397 178.686 116.485 180.862 116.752 182.951C117.107 185.824 116.485 188.697 116.308 191.483C115.952 196.01 115.775 200.536 116.397 205.063C116.663 207.066 116.752 209.068 116.752 211.157C117.019 217.425 118.707 223.258 120.75 229.09C121.461 231.093 122.172 233.095 123.238 235.01C123.594 235.707 124.038 236.403 124.482 237.099C125.015 237.883 125.637 238.405 126.437 238.318C127.325 238.231 127.681 237.274 127.947 236.49C128.569 234.575 129.191 232.747 129.813 230.832"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.772 345.135C124.394 348.617 123.772 352.012 123.327 355.407C122.172 363.677 120.751 371.948 119.951 380.305C119.507 384.745 120.751 389.097 121.195 393.45C121.995 401.198 123.15 408.859 123.061 416.607C123.061 420.785 122.261 424.877 121.55 428.882C120.839 433.06 120.751 437.239 120.395 441.417C119.862 446.989 119.24 452.648 119.151 458.219C119.151 462.137 119.773 466.054 121.195 469.797C121.55 470.842 121.863 472.386 122.307 473.517C120.886 475.346 119.24 476.152 117.108 477.371C118.618 479.025 120.751 479.025 122.439 479.809"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.4526 244.167C97.052 247.301 98.6702 253.024 99.2033 256.419C100.27 262.687 102.935 268.52 104.623 274.614C106.578 281.752 109.777 288.456 112.62 295.246C115.641 302.297 119.195 309.175 121.505 316.574C122.305 319.012 122.838 321.624 123.46 324.061C123.549 324.496 123.965 326.636 124.33 327.425"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.885 383.352C117.552 386.311 117.019 389.532 116.752 392.753C116.219 398.76 116.041 404.854 115.775 410.948C115.597 415.91 115.242 420.872 115.864 425.747C115.952 426.27 115.864 426.879 115.864 427.401C115.597 432.189 116.13 436.977 116.397 441.852C116.574 446.902 117.641 451.864 117.996 456.913C118.172 459.409 119.248 462.831 119.426 463.005"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.268 52.2402C116.534 57.3765 117.196 63.5992 117.818 68.7354C117.996 70.0413 117.64 70.7377 116.663 71.4341C113.82 73.8717 110.266 74.7422 106.889 76.048C100.847 78.3985 94.7165 80.4878 88.4968 82.2289C83.2545 83.7959 80.0558 87.2781 76.8571 91.3697C73.1253 96.2448 71.2594 101.816 70.3709 107.562C69.5712 112.437 68.9493 117.573 70.1932 122.535"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.88 255.729C128.925 257.732 128.747 260.343 128.569 262.868C128.214 269.223 127.77 275.578 127.059 281.846C126.526 286.721 125.548 291.596 125.193 296.471C125.015 298.735 124.838 301.085 124.571 303.348C123.86 309.355 125.015 315.362 124.571 321.282C124.127 326.853 124.838 332.425 124.66 337.996C124.571 339.999 124.482 342.088 123.949 344.003C123.683 345.135 122.883 346.092 122.261 347.137C121.017 349.488 119.329 351.577 117.996 353.84C115.775 357.845 113.82 361.849 113.376 366.55C113.109 369.771 112.843 372.992 113.376 376.213C113.553 376.997 113.909 377.519 114.353 378.216C116.93 377.606 116.93 375.343 117.641 373.689C119.151 370.381 119.418 366.812 119.862 363.242C120.306 360.108 120.484 356.974 121.017 353.84C121.195 352.796 121.284 351.577 120.839 350.532"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.2805 86.4512C94.5034 88.8887 94.2723 90.7608 93.7391 93.6336C92.5841 99.4663 90.0073 104.603 86.009 109.042C84.1431 111.132 81.8329 112.786 79.7893 114.614C76.5906 117.4 72.9477 119.837 70.1044 123.058C69.6601 123.581 69.3936 124.277 69.0382 124.886C64.6844 132.199 62.4631 140.208 60.8637 148.478C60.1529 151.873 59.6198 155.356 58.4647 158.664C57.7539 160.753 56.3323 162.581 54.9106 164.322C50.1126 170.416 45.5811 176.771 42.027 183.648C39.8057 188.088 38.5618 193.05 37.0513 197.838C35.4519 202.713 33.4083 207.327 30.8316 211.854C29.41 214.553 28.0772 217.339 27.3664 220.647C27.011 223.694 25.6782 226.044 24.2565 228.569C23.0126 230.745 21.3244 232.312 19.1031 233.357C15.3713 235.011 12.8834 237.709 11.3729 241.54C10.129 244.761 8.35194 247.808 6.13062 250.507C5.59751 251.116 5.06439 251.812 4.70898 252.683C5.86407 253.902 6.84144 253.902 8.26308 252.857C10.129 251.377 11.3729 249.462 13.15 247.982"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.3627 130.979C88.7634 134.723 87.8749 138.727 86.1867 142.471C84.0542 147.172 82.0995 151.873 79.2562 156.225C77.7457 158.576 76.946 161.188 75.5244 163.538C73.6585 166.585 71.8814 169.806 71.2595 173.375C70.3709 178.686 66.4614 182.603 64.7732 187.565C63.2627 192.005 61.1303 196.184 59.1755 200.449C56.9542 205.237 54.1109 209.764 50.9122 214.03C46.5585 219.863 42.4713 225.782 38.3841 231.876C36.7847 234.227 35.8073 236.838 36.7847 240.059C37.9398 243.89 36.5182 247.807 35.2742 251.463C33.2306 257.296 31.0981 263.129 29.41 269.049C29.0545 270.267 28.3437 271.399 27.4552 272.357C26.9221 273.053 26.3001 273.575 25.2339 272.879C26.5667 268.352 27.7218 263.651 29.5877 259.211"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.2369 123.406C68.8605 130.284 66.195 137.422 63.8848 144.735C62.1966 150.045 61.1304 155.442 61.1304 161.014C61.1304 162.494 61.4858 163.974 62.4632 165.193C63.0851 165.976 63.8848 166.15 64.7733 165.454C66.6392 164.061 68.2386 162.407 69.5714 160.579C70.371 159.447 71.3484 158.489 72.4146 157.706"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.104 91.2832C120.75 92.589 117.107 95.2877 113.376 97.7253C108.844 100.598 105.112 104.428 101.025 107.824C96.0494 112.002 90.4517 114.788 83.9655 115.92C82.455 116.181 81.1222 116.964 79.7005 117.4C78.9009 117.574 78.1012 117.835 77.3904 117.4"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.9185 206.369C91.8732 210.374 94.8942 213.769 97.6486 217.164C102.269 222.997 107.956 227.785 113.997 232.312C117.818 235.184 121.816 237.97 125.993 240.408C127.947 241.539 129.991 242.323 132.39 242.062"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.351 1C122.732 1.17411 114.531 4.1408 110.177 11.8887C107.956 15.8062 107.6 20.0718 108.489 24.4246C108.755 25.6434 109.022 26.7751 108.666 27.9938"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.718 130.979C91.962 132.024 91.5177 133.591 91.6954 134.723C92.2285 140.468 93.8278 145.953 94.6275 151.611C95.3383 156.748 96.0492 161.797 97.2042 166.846C97.2931 167.107 97.4208 170.051 97.5985 170.399"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.8329 117.398C83.9654 118.356 85.387 120.01 86.9864 121.577C87.6972 122.274 87.6083 122.796 87.4306 123.666C85.2093 133.591 82.0106 143.167 76.1464 151.698C75.2579 152.917 74.6359 154.223 73.7474 155.441C72.4146 157.444 72.77 159.794 72.0592 161.971C70.9929 165.54 69.4824 168.848 66.0172 170.937C62.6408 173.026 59.531 175.464 56.4211 177.902C55.0883 178.946 54.9995 180.861 54.4664 182.428C51.09 192.092 51.09 192.092 43.6264 201.232C39.7169 206.02 35.6297 210.721 32.1644 215.857C31.0982 217.512 30.2985 219.253 29.1434 220.907C28.788 221.342 27.8995 222.387 26.9221 223.344"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.309 44.4474C109.733 44.5344 108.489 42.7063 107.689 40.8781C106.445 37.8312 105.468 34.6972 104.668 31.4762C104.313 29.8222 104.313 27.907 106.267 26.8623C108.933 27.5587 109.644 29.474 109.733 31.9115C109.91 34.9584 110.621 38.0053 111.776 40.8781C112.665 42.9675 112.576 45.405 113.464 47.5814C114.531 49.9318 115.775 52.1082 117.729 53.8493C120.128 56.0257 123.238 58.0279 122.705 61.9454C125.104 70.1286 126.437 78.4858 128.303 86.7561C128.658 88.4972 129.28 90.2383 130.346 91.8052"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.3822 392.144C97.2045 395.103 96.2271 397.976 95.7828 400.936C95.1609 405.463 94.6278 409.99 94.6278 414.517C94.6278 417.215 94.1653 420.534 94.1653 423.658"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3278 247.546C14.0387 250.593 13.1501 253.64 13.0613 256.774C12.7947 263.564 12.0839 270.441 11.6396 277.232C11.6396 277.841 11.6396 278.712 12.7059 278.712C13.6833 278.712 14.4829 278.624 14.6606 277.493C15.3715 272.879 16.7931 268.439 17.8593 263.912C18.2147 262.345 18.5701 260.865 18.9255 259.298"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M117.196 75.7876C114.797 78.3993 111.598 80.1404 108.933 82.4038C110.443 84.4931 112.043 85.0155 114.442 84.319C116.041 83.8837 117.018 82.5779 117.196 80.5757C117.463 77.5287 117.831 74.347 117.831 70.2021"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.44 70.1289C119.862 73.9593 121.195 77.7897 122.705 81.6201C124.038 84.8412 124.838 88.2363 126.703 91.2832"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.036 134.549C123.505 134.027 119.062 135.158 114.619 135.855C111.065 136.464 107.867 135.768 104.579 134.723C99.4255 133.069 94.9829 130.022 90.9846 126.54C89.8295 125.495 89.1187 124.015 87.697 123.058"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.957 353.927C106.267 356.887 107.156 360.369 107.778 363.938C108.044 365.505 107.867 367.159 108.044 368.726C108.311 370.99 107.511 373.166 107.423 375.429"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.222 201.407C107.955 202.452 107.511 203.497 106.889 204.28C104.757 207.153 101.825 207.762 98.1816 205.76C95.6938 204.367 93.0282 203.148 90.718 201.407"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.771 488.254C119.418 490.778 114.797 491.91 109.733 491.214C107.778 490.953 106.178 490.256 104.846 488.863"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.8121 260.518C23.4567 263.564 22.3017 266.35 21.502 269.31C20.7023 272.183 19.7249 274.969 19.1918 277.841C18.9253 279.408 17.9479 280.366 16.6151 280.714C15.0158 281.15 15.0158 279.408 14.3049 278.712"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.1123 173.464C66.5808 178.252 59.9752 182.43 55.5326 187.392C54.5552 188.436 53.489 189.568 51.9785 189.916"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.1093 159.97C58.9978 161.188 58.1982 162.32 58.1093 163.539C57.7539 168.066 56.8654 172.506 55.9769 176.945C55.888 177.207 56.0657 177.468 56.1546 177.816"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M99.248 132.809C99.5146 134.463 100.759 135.681 101.647 136.987C104.757 141.601 108.311 145.867 113.109 148.914"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.593 485.728C121.372 485.902 120.572 483.987 119.595 482.768C117.018 479.46 112.931 478.503 109.288 481.985C108.468 482.768 109.022 481.985 108.844 481.985"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.527 473.802C123.771 475.369 123.238 477.197 123.06 478.764C122.883 480.505 122.972 482.159 123.416 483.813C124.482 488.079 124.215 492.432 124.215 496.784C124.215 499.222 122.883 500.092 120.661 499.483"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.573 468.579C119.062 468.666 117.996 469.798 117.463 470.755C115.952 473.454 113.553 475.108 111.421 477.198C110.532 478.068 109.733 479.113 108.844 480.07"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.442 28.7764C118.44 31.388 122.438 31.1268 126.348 28.7764"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.303 32.3467C128.303 34.7842 127.503 36.9606 126.792 39.224C126.348 40.7039 126.259 42.0968 128.036 42.7933"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.6 135.941C109.733 139.598 112.487 142.558 116.308 144.473"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0564 273.489C23.9901 275.317 23.457 277.407 21.9465 278.974C20.8803 280.105 19.9918 280.018 18.9255 279.322"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.185 58.4883C123.227 59.8098 124.838 61.1623 125.726 62.0328C127.148 63.2516 129.365 64.3151 131.32 64.3151"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.501 47.8417C129.013 47.1453 126.881 48.1899 124.837 49.4087C125.904 53.065 128.925 53.3262 131.946 53.6744"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.752 494.694C116.308 494.956 115.863 495.217 115.508 495.565C114.353 496.522 113.642 497.654 114.086 499.134C114.442 500.179 117.018 501.136 118.262 500.788C119.951 500.44 121.106 499.395 121.461 497.654C121.639 496.871 121.639 496.087 121.728 495.391"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7913 232.921C23.1014 233.269 25.4116 233.356 27.6329 233.966"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.023 492.867C104.49 493.738 103.957 494.608 103.335 495.392C102.269 496.611 101.914 498.003 102.536 499.396C102.98 500.354 105.29 500.267 107.067 499.396C106.178 498.003 107.422 496.872 107.867 495.74C108.133 494.956 108.933 494.347 108.844 493.389"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.647 492.432C100.403 493.215 99.6032 494.434 98.8924 495.566C98.1816 496.61 97.7373 498.09 98.537 499.048C99.4255 500.092 100.936 500.092 102.269 499.309"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.553 498.438C112.754 498.351 112.309 498.874 111.865 499.222C110.088 500.702 109.2 500.702 107.156 499.222"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.726 50.5405C127.681 50.2793 129.546 51.1499 131.501 50.9758"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.771 26.5142C122.527 24.599 120.484 25.1213 118.618 25.0342"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3889 225.782C27.8994 227.175 28.6102 229.264 30.6538 230.135"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8315 235.01C32.342 235.793 34.0302 236.229 35.7184 236.054"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.4482 168.152C99.0702 169.632 99.0702 171.199 99.5145 172.766"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9644 231.268C34.386 232.225 36.0742 232.138 37.6735 232.486"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.7181 126.801C90.1849 128.02 90.8069 129.325 90.5404 130.544"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.303 41.3135C129.191 42.2711 130.257 42.7934 131.501 43.2287"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.123 129.936C168.656 131.503 167.679 132.895 167.413 134.288C165.102 144.822 164.392 155.356 164.48 166.063C164.48 167.543 165.369 168.027 165.369 170.242C164.48 173.289 165.102 176.336 164.925 179.47C164.658 182.691 165.813 185.738 166.879 188.698C168.479 193.05 170.256 197.403 173.01 201.233C173.81 202.365 173.632 203.932 174.343 205.151C176.831 209.243 178.43 213.769 179.674 218.296C180.918 222.91 181.629 227.524 182.073 232.312C182.606 237.622 181.984 242.933 182.517 248.156C182.962 252.857 182.873 257.471 183.228 262.172C183.495 265.567 183.051 268.875 182.695 272.183C182.073 278.277 181.185 284.284 180.207 290.378C179.408 295.601 178.164 300.737 176.564 305.873C175.498 309.356 175.054 313.012 174.076 316.581C172.033 323.546 170.345 330.597 168.123 337.474C167.857 338.432 167.679 339.39 167.679 340.434C167.501 348.53 166.435 356.626 165.28 364.636C164.836 367.595 165.724 370.381 166.169 373.167C166.791 376.823 167.413 380.567 167.946 384.223C168.479 387.879 169.545 391.448 170.167 395.105C171.322 401.634 171.944 408.163 171.589 414.779C171.411 417.565 170.878 420.438 169.19 422.962C168.123 424.703 168.301 426.967 167.768 428.969C166.968 432.103 165.014 434.802 164.392 437.936C163.236 443.333 160.749 448.295 159.238 453.606C157.728 458.916 156.128 464.313 155.151 469.711C154.618 472.409 155.062 475.282 155.062 478.068C155.062 478.851 155.151 479.548 154.529 480.419C154.085 481.115 154.174 482.334 154.795 483.465C157.106 487.731 160.571 490.778 164.925 493.041C166.169 493.738 167.324 494.26 167.946 495.74C168.479 496.872 168.212 497.742 167.59 498.439C166.879 499.309 165.813 499.309 164.836 498.7"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.01 210.374C173.01 212.115 172.389 213.682 172.122 215.423C171.411 220.559 171.145 225.783 170.256 230.919C169.545 234.924 168.834 238.928 167.768 242.758C167.324 244.5 167.502 245.979 167.857 247.633C168.479 250.593 168.746 253.64 169.19 256.6C169.99 262.259 170.878 267.83 171.5 273.489C171.944 277.232 172.122 280.976 172.122 284.806C172.122 291.422 172.566 298.038 171.5 304.654C170.523 310.574 169.368 316.494 168.035 322.414C167.324 325.461 166.613 328.507 166.169 331.641C165.725 335.124 164.57 338.432 163.148 341.653C161.46 345.396 160.482 349.314 159.505 353.231C158.794 355.93 158.972 358.89 158.528 361.762C157.994 365.419 156.928 368.988 156.217 372.644C156.129 373.167 155.766 375.061 155.499 375.41"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.19 214.553C166.435 222.91 164.747 231.528 162.348 240.06C159.594 249.81 156.04 259.299 152.308 268.701C149.376 276.013 145.821 283.152 143.956 290.813C142.712 295.775 141.734 300.128 140.135 305.09C140.046 305.351 138.721 309.074 138.721 309.074"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.972 381.872C155.684 386.573 153.729 391.796 152.663 397.368C152.13 400.328 151.775 403.375 151.775 406.334C151.863 415.04 151.597 423.745 151.242 432.451C150.975 437.239 150.62 442.114 150.086 446.902C149.376 453.431 148.132 459.873 146.977 466.315C146.71 467.708 146.532 469.101 145.733 470.32"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.799 152.656C146.976 157.88 146.799 163.103 146.443 168.326C146.266 171.025 146.532 173.724 146.532 176.509C146.532 178.686 146.443 180.862 146.177 182.951C145.821 185.824 146.443 188.697 146.621 191.483C146.976 196.01 147.154 200.536 146.532 205.063C146.266 207.066 146.177 209.068 146.177 211.157C145.91 217.425 144.222 223.258 142.178 229.09C141.468 231.093 140.757 233.095 139.691 235.01C139.335 235.707 138.891 236.403 138.447 237.099C137.913 237.883 137.291 238.405 136.492 238.318C135.603 238.231 135.248 237.274 134.981 236.49C134.359 234.575 133.737 232.747 133.115 230.832"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.158 345.135C138.536 348.617 139.158 352.012 139.602 355.407C140.757 363.677 142.179 371.948 142.979 380.305C143.423 384.745 142.179 389.097 141.735 393.45C140.935 401.198 139.78 408.859 139.869 416.607C139.869 420.785 140.668 424.877 141.379 428.882C142.09 433.06 142.179 437.239 142.534 441.417C143.067 446.989 143.689 452.648 143.778 458.219C143.778 462.137 143.156 466.054 141.735 469.797C141.379 470.842 141.066 472.386 140.622 473.517C142.044 475.346 143.689 476.152 145.822 477.371C144.311 479.025 142.179 479.025 140.491 479.809"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.476 244.167C165.877 247.301 164.259 253.024 163.725 256.419C162.659 262.687 159.994 268.52 158.305 274.614C156.351 281.752 153.152 288.456 150.309 295.246C147.288 302.297 143.734 309.175 141.423 316.574C140.624 319.012 140.091 321.624 139.469 324.061C139.38 324.496 138.963 326.636 138.598 327.425"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.044 383.352C145.377 386.311 145.91 389.532 146.177 392.753C146.71 398.76 146.888 404.854 147.154 410.948C147.332 415.91 147.687 420.872 147.065 425.747C146.977 426.27 147.065 426.879 147.065 427.401C147.332 432.189 146.799 436.977 146.532 441.852C146.355 446.902 145.288 451.864 144.933 456.913C144.757 459.409 143.681 462.831 143.503 463.005"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.661 52.2393C146.394 57.3755 145.733 63.5982 145.111 68.7345C144.933 70.0403 145.288 70.7367 146.266 71.4332C149.109 73.8707 152.663 74.7412 156.039 76.0471C162.081 78.3975 168.212 80.4869 174.432 82.228C179.674 83.795 182.873 87.2771 186.072 91.3687C189.803 96.2438 191.669 101.815 192.558 107.561C193.357 112.436 193.979 117.572 192.735 122.534"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.32 255.7C133.275 257.702 134.182 260.343 134.36 262.868C134.715 269.223 135.159 275.578 135.87 281.846C136.403 286.721 137.381 291.596 137.736 296.471C137.914 298.734 138.091 301.085 138.358 303.348C139.069 309.355 137.914 315.362 138.358 321.282C138.802 326.853 138.091 332.425 138.269 337.996C138.358 339.998 138.447 342.088 138.98 344.003C139.246 345.135 140.046 346.092 140.668 347.137C141.912 349.487 143.6 351.577 144.933 353.84C147.154 357.845 149.109 361.849 149.553 366.55C149.82 369.771 150.086 372.992 149.553 376.213C149.376 376.997 149.02 377.519 148.576 378.215C145.999 377.606 145.999 375.343 145.288 373.689C143.778 370.381 143.511 366.811 143.067 363.242C142.623 360.108 142.445 356.974 141.912 353.84C141.734 352.795 141.645 351.577 142.09 350.532"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M166.648 86.4512C168.425 88.8887 168.656 90.7608 169.19 93.6336C170.345 99.4663 172.921 104.603 176.92 109.042C178.786 111.132 181.096 112.786 183.139 114.614C186.338 117.4 189.981 119.837 192.824 123.058C193.269 123.581 193.535 124.277 193.891 124.886C198.244 132.199 200.466 140.208 202.065 148.478C202.776 151.873 203.309 155.356 204.464 158.664C205.175 160.753 206.596 162.581 208.018 164.322C212.816 170.416 217.348 176.771 220.902 183.648C223.123 188.088 224.367 193.05 225.877 197.838C227.477 202.713 229.52 207.327 232.097 211.854C233.519 214.553 234.852 217.339 235.562 220.647C235.918 223.694 237.251 226.044 238.672 228.569C239.916 230.745 241.604 232.312 243.826 233.357C247.557 235.011 250.045 237.709 251.556 241.54C252.8 244.761 254.577 247.808 256.798 250.507C257.331 251.116 257.864 251.812 258.22 252.683C257.065 253.902 256.087 253.902 254.666 252.857C252.8 251.377 251.556 249.462 249.779 247.982"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.566 130.979C174.165 134.723 175.054 138.727 176.742 142.471C178.874 147.172 180.829 151.873 183.673 156.225C185.183 158.576 185.983 161.188 187.404 163.538C189.27 166.585 191.047 169.806 191.669 173.375C192.558 178.686 196.467 182.603 198.155 187.565C199.666 192.005 201.798 196.184 203.753 200.449C205.974 205.237 208.818 209.764 212.016 214.03C216.37 219.863 220.457 225.782 224.545 231.876C226.144 234.227 227.121 236.838 226.144 240.059C224.989 243.89 226.411 247.807 227.654 251.463C229.698 257.296 231.831 263.129 233.519 269.049C233.874 270.267 234.585 271.399 235.474 272.357C236.007 273.053 236.629 273.575 237.695 272.879C236.362 268.352 235.207 263.651 233.341 259.211"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M190.692 123.406C194.068 130.284 196.734 137.422 199.044 144.735C200.732 150.045 201.798 155.442 201.798 161.014C201.798 162.494 201.443 163.974 200.466 165.193C199.844 165.976 199.044 166.15 198.155 165.454C196.289 164.061 194.69 162.407 193.357 160.579C192.558 159.447 191.58 158.489 190.514 157.706"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.825 91.2822C142.179 92.588 145.822 95.2868 149.553 97.7243C154.085 100.597 157.817 104.428 161.904 107.823C166.88 112.001 172.477 114.787 178.963 115.919C180.474 116.18 181.807 116.963 183.228 117.399C184.028 117.573 184.828 117.834 185.539 117.399"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.01 206.369C171.056 210.374 168.035 213.769 165.28 217.164C160.66 222.997 154.973 227.785 148.931 232.312C145.111 235.184 141.112 237.97 136.936 240.408C134.982 241.539 132.938 242.323 130.539 242.062"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.749 1C140.368 1.17411 148.398 4.14055 152.752 11.8884C154.973 15.8059 155.329 20.0716 154.44 24.4243C154.174 25.6431 153.907 26.7748 154.263 27.9936"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.211 130.979C170.967 132.024 171.411 133.591 171.233 134.723C170.7 140.468 169.101 145.953 168.301 151.611C167.59 156.748 166.88 161.797 165.724 166.846C165.636 167.107 165.508 170.051 165.33 170.399"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.096 117.398C178.964 118.356 177.542 120.01 175.943 121.577C175.232 122.274 175.321 122.796 175.498 123.666C177.72 133.591 180.918 143.167 186.783 151.698C187.671 152.917 188.293 154.223 189.182 155.441C190.514 157.444 190.159 159.794 190.87 161.971C191.936 165.54 193.447 168.848 196.912 170.937C200.288 173.026 203.398 175.464 206.508 177.902C207.841 178.946 207.929 180.861 208.463 182.428C211.839 192.092 211.839 192.092 219.303 201.232C223.212 206.02 227.299 210.721 230.765 215.857C231.831 217.512 232.63 219.253 233.786 220.907C234.141 221.342 235.029 222.387 236.007 223.344"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.619 44.4474C153.196 44.5344 154.44 42.7063 155.24 40.8781C156.484 37.8312 157.461 34.6972 158.261 31.4762C158.616 29.8222 158.616 27.907 156.661 26.8623C153.996 27.5587 153.285 29.474 153.196 31.9115C153.018 34.9584 152.308 38.0053 151.152 40.8781C150.264 42.9675 150.353 45.405 149.464 47.5814C148.398 49.9318 147.154 52.1082 145.199 53.8493C142.8 56.0257 139.691 58.0279 140.224 61.9454C137.825 70.1286 136.492 78.4858 134.626 86.7561C134.271 88.4972 133.649 90.2383 132.582 91.8052"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.547 392.144C165.724 395.103 166.702 397.976 167.146 400.936C167.768 405.463 168.301 409.99 168.301 414.517C168.301 417.215 168.763 420.534 168.763 423.658"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M249.601 247.546C248.89 250.593 249.779 253.64 249.868 256.774C250.134 263.564 250.845 270.441 251.289 277.232C251.289 277.841 251.289 278.712 250.223 278.712C249.246 278.712 248.446 278.624 248.268 277.493C247.558 272.879 246.136 268.439 245.07 263.912C244.714 262.345 244.359 260.865 244.003 259.298"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.733 75.7876C148.132 78.3993 151.33 80.1404 153.996 82.4038C152.485 84.4931 150.886 85.0155 148.487 84.319C146.888 83.8837 145.91 82.5779 145.733 80.5757C145.466 77.5287 145.097 74.347 145.097 70.2021"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.489 70.1289C143.067 73.9593 141.734 77.7897 140.224 81.6201C138.891 84.8412 138.091 88.2363 136.225 91.2832"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.893 134.548C139.424 134.026 143.867 135.157 148.309 135.854C151.863 136.463 155.062 135.767 158.35 134.722C163.503 133.068 167.946 130.021 171.944 126.539C173.099 125.494 173.81 124.014 175.232 123.057"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.972 353.927C156.662 356.887 155.773 360.369 155.151 363.938C154.884 365.505 155.062 367.159 154.884 368.726C154.618 370.99 155.418 373.166 155.506 375.429"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.707 201.407C154.973 202.452 155.418 203.497 156.04 204.28C158.172 207.153 161.104 207.762 164.747 205.76C167.235 204.367 169.901 203.148 172.211 201.407"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.158 488.254C143.511 490.778 148.132 491.91 153.196 491.214C155.151 490.953 156.75 490.256 158.083 488.863"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.117 260.518C239.472 263.564 240.627 266.35 241.427 269.31C242.226 272.183 243.204 274.969 243.737 277.841C244.003 279.408 244.981 280.366 246.314 280.714C247.913 281.15 247.913 279.408 248.624 278.712"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.817 173.464C196.348 178.252 202.954 182.43 207.396 187.392C208.374 188.436 209.44 189.568 210.95 189.916"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.82 159.97C203.931 161.188 204.731 162.32 204.82 163.539C205.175 168.066 206.064 172.506 206.952 176.945C207.041 177.207 206.863 177.468 206.774 177.816"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.681 132.808C163.414 134.462 162.17 135.68 161.282 136.986C158.172 141.6 154.618 145.866 149.82 148.913"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.335 485.729C141.557 485.903 142.356 483.988 143.334 482.769C145.91 479.461 149.998 478.504 153.641 481.986C154.46 482.769 153.907 481.986 154.085 481.986"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.402 473.802C139.158 475.369 139.691 477.197 139.869 478.764C140.046 480.505 139.957 482.159 139.513 483.813C138.447 488.079 138.713 492.432 138.713 496.784C138.713 499.222 140.046 500.092 142.268 499.483"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.356 468.579C143.867 468.666 144.933 469.798 145.466 470.755C146.977 473.454 149.376 475.108 151.508 477.198C152.397 478.068 153.196 479.113 154.085 480.07"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.487 28.7764C144.489 31.388 140.49 31.1268 136.581 28.7764"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.626 32.3467C134.626 34.7842 135.426 36.9606 136.137 39.224C136.581 40.7039 136.67 42.0968 134.893 42.7933"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.329 135.941C153.196 139.598 150.442 142.558 146.621 144.473"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M237.873 273.489C238.939 275.317 239.472 277.407 240.982 278.974C242.049 280.105 242.937 280.018 244.003 279.322"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.744 58.4873C139.702 59.8088 138.091 61.1613 137.203 62.0318C135.781 63.2506 133.564 64.3142 131.609 64.3142"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.427 47.8417C133.915 47.1453 136.048 48.1899 138.091 49.4087C137.025 53.065 134.004 53.3262 130.983 53.6744"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.177 494.694C146.621 494.956 147.065 495.217 147.421 495.565C148.576 496.522 149.287 497.654 148.842 499.134C148.487 500.179 145.91 501.136 144.666 500.788C142.978 500.44 141.823 499.395 141.468 497.654C141.29 496.871 141.29 496.087 141.201 495.391"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M242.137 232.921C239.827 233.269 237.517 233.356 235.296 233.966"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.905 492.867C158.438 493.738 158.972 494.608 159.593 495.392C160.66 496.611 161.015 498.003 160.393 499.396C159.949 500.354 157.639 500.267 155.862 499.396C156.75 498.003 155.506 496.872 155.062 495.74C154.795 494.956 153.996 494.347 154.085 493.389"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.282 492.432C162.526 493.215 163.325 494.434 164.036 495.566C164.747 496.61 165.191 498.09 164.392 499.048C163.503 500.092 161.993 500.092 160.66 499.309"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.375 498.438C150.175 498.351 150.619 498.874 151.064 499.222C152.841 500.702 153.729 500.702 155.773 499.222"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.203 50.5405C135.248 50.2793 133.382 51.1499 131.427 50.9758"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.158 26.5142C140.402 24.599 142.445 25.1213 144.311 25.0342"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M236.54 225.782C235.029 227.175 234.318 229.264 232.275 230.135"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.097 235.01C230.587 235.793 228.899 236.229 227.211 236.054"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.48 168.152C163.859 169.632 163.859 171.199 163.414 172.766"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.965 231.268C228.543 232.225 226.855 232.138 225.255 232.486"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.211 126.801C172.744 128.02 172.122 129.325 172.389 130.544"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.626 41.3135C133.738 42.2711 132.671 42.7934 131.428 43.2287"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* part 1 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[0])}
      >
        <g
          filter="url(#filter0_f_33395_33003)"
          onMouseEnter={() => setHover(0)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 0 ? 1 : 0}
            d="M132.382 64.6873C125.538 64.6873 122.8 59.0677 116.23 52.3779L118.42 69.7714C119.671 75.973 125.264 92.5167 131.835 92.5167C138.953 92.5167 143.307 73.5463 144.976 69.7714L146.892 52.3779C140.048 58.8001 137.858 64.6873 132.382 64.6873Z"
            fill="url(#paint0_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 3 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[2])}
      >
        <g
          filter="url(#filter1_f_33395_33003)"
          onMouseEnter={() => setHover(2)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 2 ? 1 : 0}
            d="M131.835 92.5168C124.443 92.5168 120.062 73.2502 118.42 69.5039C117.325 71.4306 113.766 73.3394 112.123 74.053L90.4954 81.8131C95.3137 95.0857 95.6057 119.276 95.1494 129.712C106.319 139.345 124.26 137.115 131.835 134.796V92.5168Z"
            fill="url(#paint1_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 4 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[3])}
      >
        <g
          filter="url(#filter2_f_33395_33003)"
          onMouseEnter={() => setHover(3)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 3 ? 1 : 0}
            d="M131.835 92.5168C139.226 92.5168 143.607 73.2502 145.249 69.5039C146.344 71.4306 149.903 73.3394 151.546 74.053L173.174 81.8131C168.355 95.0857 168.063 119.276 168.52 129.712C157.35 139.345 139.409 137.115 131.835 134.796V92.5168Z"
            fill="url(#paint2_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 6 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[5])}
      >
        <g
          filter="url(#filter3_f_33395_33003)"
          onMouseEnter={() => setHover(5)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 5 ? 1 : 0}
            d="M131.835 209.187V134.796C111.849 139.613 102.268 134.796 95.1495 129.712C98.161 140.951 98.4348 162.804 97.6135 169.048C100.68 182.32 93.9632 195.986 90.2217 201.159C105.553 213.147 124.352 211.506 131.835 209.187Z"
            fill="url(#paint3_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 7 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[6])}
      >
        <g
          filter="url(#filter4_f_33395_33003)"
          onMouseEnter={() => setHover(6)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 6 ? 1 : 0}
            d="M131.835 209.187V134.796C151.82 139.613 161.402 134.796 168.52 129.712C165.508 140.951 165.234 162.804 166.056 169.048C162.99 182.32 169.706 195.986 173.448 201.159C158.116 213.147 139.318 211.506 131.835 209.187Z"
            fill="url(#paint4_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 8 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[7])}
      >
        <g
          filter="url(#filter5_f_33395_33003)"
          onMouseEnter={() => setHover(7)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 7 ? 1 : 0}
            d="M131.561 256.283L131.835 209.187C113.218 214.004 95.1496 206.511 90.2217 200.892C89.3457 201.962 88.9442 204.37 88.853 205.441C78.9973 220.64 78.541 252.983 79.5448 267.254C80.6399 271.625 85.732 282.347 97.3398 290.267C108.948 298.188 120.063 317.473 124.169 326.125C125.538 319.97 123.895 310.069 124.169 308.196C124.388 306.697 127.181 278.493 128.549 264.578C128.988 257.728 130.74 256.194 131.561 256.283Z"
            fill="url(#paint5_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 10 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[9])}
      >
        <g
          filter="url(#filter6_f_33395_33003)"
          onMouseEnter={() => setHover(9)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 9 ? 1 : 0}
            d="M99.4008 292.09C86.5464 284.328 80.5295 272.193 79.709 268C80.803 287.272 90.7401 323.675 95.5718 339.467C95.1342 344.392 96.1188 354.01 96.6658 358.204C98.4162 364.413 97.9422 368.999 97.4863 370.516L93.1104 393.535C88.9532 412.807 92.2899 422.087 94.4779 424.317C94.569 426.012 95.9547 432.615 100.768 445.463C105.582 458.311 107.697 469.374 108.153 473.3C107.715 477.583 108.335 480.438 108.7 481.33C115.482 471.052 119.913 469.196 121.281 469.553C119.311 462.7 120.46 441.715 121.281 432.079C124.781 416.662 122.375 397.461 120.734 389.788C118.983 385.934 121.463 368.018 122.922 359.542C124.453 352.904 124.289 347.497 124.016 345.623C125.547 338.129 124.654 330.009 124.016 326.887C119.913 316.715 108.153 299.317 99.4008 292.09Z"
            fill="url(#paint6_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 11 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[10])}
      >
        <g
          filter="url(#filter7_f_33395_33003)"
          onMouseEnter={() => setHover(10)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 10 ? 1 : 0}
            d="M164.017 291.822C176.872 284.06 182.888 272.193 183.709 268C182.615 287.272 172.703 323.139 167.871 338.932C168.309 343.857 167.324 353.475 166.777 357.668C165.027 363.878 165.501 368.464 165.957 369.981L170.333 393C174.49 412.272 171.153 421.551 168.965 423.782C168.874 425.477 167.488 432.079 162.675 444.927C157.861 457.775 155.746 468.839 155.29 472.765C155.728 477.047 155.108 479.902 154.743 480.795C147.961 470.516 143.53 468.66 142.162 469.017C144.132 462.165 142.983 441.18 142.162 431.544C138.662 416.126 141.068 396.926 142.709 389.253C144.46 385.398 141.98 367.483 140.521 359.007C138.99 352.368 139.154 346.962 139.427 345.088C137.896 337.593 138.789 330.277 139.427 327.154C143.53 316.983 155.265 299.049 164.017 291.822Z"
            fill="url(#paint7_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 12 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[11])}
      >
        <g
          filter="url(#filter8_f_33395_33003)"
          onMouseEnter={() => setHover(11)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 11 ? 1 : 0}
            d="M109.05 480.698C116.667 471.775 120.385 469.181 121.292 469L122.652 473.625C123.523 474.496 123.74 476.527 123.74 477.434C123.088 478.74 123.287 481.968 123.468 483.419C124.557 485.596 124.829 493.395 124.829 497.022C125.046 500.069 122.562 500.287 121.292 500.015C117.157 502.844 114.672 500.468 113.946 498.926C111.335 501.32 108.687 500.649 107.689 500.015C105.73 501.32 103.79 500.377 103.064 499.743C101.323 501.048 99.6182 499.924 98.9835 499.198C94.6306 499.416 95.5374 496.387 96.535 494.845C108.723 487.228 109.956 482.24 109.05 480.698Z"
            fill="url(#paint8_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 13 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[12])}
      >
        <g
          filter="url(#filter9_f_33395_33003)"
          onMouseEnter={() => setHover(12)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 12 ? 1 : 0}
            d="M154.496 480.698C146.878 471.775 143.16 469.181 142.253 469L140.893 473.625C140.023 474.496 139.805 476.527 139.805 477.434C140.458 478.74 140.259 481.968 140.077 483.419C138.989 485.596 138.717 493.395 138.717 497.022C138.499 500.069 140.984 500.287 142.254 500.015C146.389 502.844 148.874 500.468 149.599 498.926C152.211 501.32 154.859 500.649 155.857 500.015C157.815 501.32 159.756 500.377 160.482 499.743C162.223 501.048 163.927 499.924 164.562 499.198C168.915 499.416 168.008 496.387 167.011 494.845C154.823 487.228 153.589 482.24 154.496 480.698Z"
            fill="url(#paint9_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 9 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[8])}
      >
        <g
          filter="url(#filter10_f_33395_33003)"
          onMouseEnter={() => setHover(8)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 8 ? 1 : 0}
            d="M131.561 256.283L131.835 209.187C150.451 214.004 168.52 206.511 173.448 200.892C174.324 201.962 174.725 204.37 174.816 205.441C184.672 220.64 185.128 252.983 184.125 267.254C183.03 271.625 177.937 282.347 166.33 290.267C154.722 298.188 143.607 317.473 139.5 326.125C138.131 319.97 139.226 310.604 138.953 308.731C138.734 307.233 135.941 279.028 134.572 265.114C133.204 259.227 132.382 256.194 131.561 256.283Z"
            fill="url(#paint10_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 2 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[1])}
      >
        <g
          filter="url(#filter11_f_33395_33003)"
          onMouseEnter={() => setHover(1)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 1 ? 1 : 0}
            d="M70.2364 122.486C66.9512 94.0147 82.3735 83.5072 90.4954 81.8125C95.1495 93.319 95.697 119.008 95.1495 129.711C97.7777 141.057 98.9823 163.696 97.6134 169.315C97.1754 167.602 93.5981 145.589 91.8643 134.796C91.6452 131.799 90.8604 131.228 90.4954 131.317C87.4292 143.305 79.3621 157.898 75.7119 163.696C74.1787 165.194 72.7004 168.602 72.1528 170.118C71.7878 172.615 70.6745 178.092 69.1414 180.019C67.6083 181.945 65.7649 185.817 65.0348 187.511C64.3048 189.474 62.1329 194.897 59.2857 200.891C56.4385 206.885 44.4109 223.725 38.7529 231.396C36.5628 233.965 36.1978 237.283 36.289 238.621C37.8221 240.12 37.4753 244.954 37.1103 247.184L29.7185 269.662C27.7474 273.087 25.977 273.408 25.3382 273.141L22.8743 277.957C21.5602 280.526 19.5891 279.92 18.7678 279.295C15.7015 281.65 14.57 279.385 14.3874 277.957C12.4163 279.884 11.741 277.69 11.6497 276.352L13.2924 248.255L8.63816 252.804C6.17423 254.945 3.43654 253.071 5.35293 251.466C6.88604 250.181 9.8245 245.044 11.1021 242.635C12.8542 236.641 17.6726 233.716 19.8627 233.002C24.4621 230.219 26.5245 225.063 26.9807 222.833C27.802 217.214 30.5397 212.397 32.7299 208.116C34.482 204.691 35.4676 202.229 35.7414 201.426C39.6836 184.3 50.3424 169.137 55.179 163.696C58.2452 160.913 59.3768 156.292 59.5593 154.33C62.1875 138.06 67.7724 126.322 70.2364 122.486Z"
            fill="url(#paint11_radial_33395_33003)"
          />
        </g>
      </Popover>

      {/* part 5 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[4])}
      >
        <g
          filter="url(#filter12_f_33395_33003)"
          onMouseEnter={() => setHover(4)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 4 ? 1 : 0}
            d="M193.182 122.486C196.467 94.0147 181.296 83.5072 173.174 81.8125C168.52 93.319 167.972 119.008 168.52 129.711C165.782 141.486 164.961 163.16 166.056 169.047C166.494 167.335 169.82 145.589 171.554 134.796C171.773 131.799 172.558 131.228 172.923 131.317C175.989 143.305 184.056 157.898 187.706 163.696C189.239 165.194 190.718 168.602 191.265 170.118C191.63 172.615 192.743 178.092 194.277 180.019C195.81 181.945 197.653 185.817 198.383 187.511C199.113 189.474 201.285 194.897 204.132 200.891C206.98 206.885 219.007 223.725 224.665 231.396C226.855 233.965 227.22 237.283 227.129 238.621C225.596 240.12 225.943 244.954 226.308 247.184L233.699 269.662C235.671 273.087 237.441 273.408 238.08 273.141L240.544 277.957C241.858 280.526 243.829 279.92 244.65 279.295C247.716 281.65 248.848 279.385 249.031 277.957C251.002 279.884 251.677 277.69 251.768 276.352L250.126 248.255L254.78 252.804C257.244 254.945 259.981 253.071 258.065 251.466C256.532 250.181 253.593 245.044 252.316 242.635C250.564 236.641 245.745 233.716 243.555 233.002C238.956 230.219 236.894 225.063 236.437 222.833C235.616 217.214 232.878 212.397 230.688 208.116C228.936 204.691 227.95 202.229 227.677 201.426C223.734 184.3 213.076 169.137 208.239 163.696C205.173 160.913 204.041 156.292 203.859 154.33C201.23 138.06 195.646 126.322 193.182 122.486Z"
            fill="url(#paint12_radial_33395_33003)"
          />
        </g>
      </Popover>

      <defs>
        <filter
          id="filter0_f_33395_33003"
          x="112.23"
          y="48.3779"
          width="38.6621"
          height="48.1387"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter1_f_33395_33003"
          x="86.4954"
          y="65.5039"
          width="49.3391"
          height="75.2993"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter2_f_33395_33003"
          x="127.835"
          y="65.5039"
          width="49.3391"
          height="75.2993"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter3_f_33395_33003"
          x="86.2217"
          y="125.712"
          width="49.613"
          height="89.1147"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter4_f_33395_33003"
          x="127.834"
          y="125.712"
          width="49.613"
          height="89.1147"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter5_f_33395_33003"
          x="75.1787"
          y="196.892"
          width="60.656"
          height="133.233"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter6_f_33395_33003"
          x="75.709"
          y="264"
          width="53.1523"
          height="221.33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter7_f_33395_33003"
          x="134.582"
          y="264"
          width="53.1272"
          height="220.794"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter8_f_33395_33003"
          x="91.709"
          y="465"
          width="37.1331"
          height="40.333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter9_f_33395_33003"
          x="134.704"
          y="465"
          width="37.1331"
          height="40.333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter10_f_33395_33003"
          x="127.561"
          y="196.892"
          width="60.9297"
          height="133.233"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter11_f_33395_33003"
          x="0.708984"
          y="77.8125"
          width="101.474"
          height="206.503"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>
        <filter
          id="filter12_f_33395_33003"
          x="161.586"
          y="77.8125"
          width="101.123"
          height="206.503"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33003"
          />
        </filter>

        {/* part 1 */}
        <radialGradient
          id="paint0_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(131.207 67.7655) rotate(90) scale(25.3457 25.145)"
        >
          <stop stopColor={colorMaps[opacities[0]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[0]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 3 */}
        <radialGradient
          id="paint1_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(110.687 95.3036) rotate(90) scale(42.4961 33.9008)"
        >
          <stop stopColor={colorMaps[opacities[2]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[2]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 4 */}
        <radialGradient
          id="paint2_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(152.982 95.3036) rotate(90) scale(42.4961 33.9008)"
        >
          <stop stopColor={colorMaps[opacities[3]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[3]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 6 */}
        <radialGradient
          id="paint3_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(110.547 160.808) rotate(90) scale(51.2201 34.1254)"
        >
          <stop stopColor={colorMaps[opacities[5]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[5]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 7 */}
        <radialGradient
          id="paint4_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(153.122 160.808) rotate(90) scale(51.2201 34.1254)"
        >
          <stop stopColor={colorMaps[opacities[6]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[6]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 8 */}
        <radialGradient
          id="paint5_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(104.898 248.901) rotate(90) scale(79.0785 43.1813)"
        >
          <stop stopColor={colorMaps[opacities[7]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[7]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 10 */}
        <radialGradient
          id="paint6_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(101.764 349.782) rotate(90) scale(134.707 37.0278)"
        >
          <stop stopColor={colorMaps[opacities[9]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[9]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 11 */}
        <radialGradient
          id="paint7_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(161.68 349.247) rotate(90) scale(134.707 37.0278)"
        >
          <stop stopColor={colorMaps[opacities[10]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[10]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 12 */}
        <radialGradient
          id="paint8_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(115.267 483.147) rotate(136.474) scale(31.4287 19.455)"
        >
          <stop stopColor={colorMaps[opacities[11]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[11]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 13 */}
        <radialGradient
          id="paint9_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(148.278 483.147) rotate(43.5261) scale(31.4287 19.455)"
        >
          <stop stopColor={colorMaps[opacities[12]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[12]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 9 */}
        <radialGradient
          id="paint10_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(158.771 248.901) rotate(90) scale(79.0785 43.1813)"
        >
          <stop stopColor={colorMaps[opacities[8]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[8]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 2 */}
        <radialGradient
          id="paint11_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(50.138 157.911) rotate(90) scale(125.345 76.2716)"
        >
          <stop stopColor={colorMaps[opacities[1]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[1]]}
            stopOpacity={0.2}
          />
        </radialGradient>

        {/* part 5 */}
        <radialGradient
          id="paint12_radial_33395_33003"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(213.28 157.911) rotate(90) scale(125.345 76.2716)"
        >
          <stop stopColor={colorMaps[opacities[4]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[4]]}
            stopOpacity={0.2}
          />
        </radialGradient>
      </defs>
    </svg>
  )
}
