import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'

import { zoneTypeStyle } from './styles/zoneType.sass'

const { TabPane } = Tabs

class ZoneTabs extends Component {
  static propTypes = {
    zoneTypes: PropTypes.array,
    onTabChange: PropTypes.func
  }

  state = {
    showHRZIndex: 0
  }

  getHRZTypeText = (item) => {
    let text = ''
    switch (item) {
      case 'All':
        text =
          'Includes all physical activities: both workouts and daily movement.'
        break
      case 'Aerobic':
        text = 'Includes only your aerobic (cardio) workouts.'
        break
      case 'Resistance':
        text = 'Includes only your resistance (strength) training workouts.'
        break
      case 'Activity':
        text = 'Includes only your activity workouts.'
        break
      case 'Movement Quality':
        text = 'Includes only your movement quality workouts.'
        break
      case 'Active':
        text = 'Includes only your daily movement, not workouts.'
        break
      default:
        break
    }
    return text
  }

  render() {
    const { zoneTypes, onTabChange } = this.props
    return (
      <div className={zoneTypeStyle}>
        <Tabs
          defaultActiveKey={'0'}
          tabPosition="top"
          onChange={(activeKey) => {
            onTabChange && onTabChange(activeKey, zoneTypes[activeKey])
            this.setState({ showHRZIndex: activeKey })
          }}
        >
          {zoneTypes.map((item, index) => (
            <TabPane
              tab={item}
              key={index}
              className="activity-tab-description"
            >
              {this.getHRZTypeText(item)}
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  }
}

export default ZoneTabs
