import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'

import {
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  FileTextOutlined,
  ShareAltOutlined
} from '@ant-design/icons'

import { Collapse, Modal } from 'antd'
import ReportBanner from './banner'
import ReportDeviIn from './diveIn'
import ReportBloodTest from './bloodTest'
import PhysicalTests from './physicalTests'
import ReportBodyComp from './bodyComp'
import ReportLifestyle from './lifestyle'
import ReportRangeOfMotion from './rangeOfMotion'
import ReportMovementEfficiency from './movementEfficiency'

import ClientSlideMenu from '../../../components/UI/clientSlideMenu'
import { draftReportNav } from '../../../models/clientNavagation.model'
import { getClient } from '../../../api/team'
import { getReportById, updateReport } from '../../../api/report'
import { uploadAttachment } from '../../../api/file'
import { DownIcon } from '../../../components/icons'

const Panel = Collapse.Panel
const confirm = Modal.confirm

export class DraftReport extends Component {
  static propTypes = {}

  state = {
    person: this.props.client.person,
    report: null,
    activeKey: 'introduction'
  }

  getPerson = async () => {
    const { client, match, setClient } = this.props
    const { personId } = match.params
    if (client.person && client.person.id === personId) return client.person
    const result = await getClient(personId)
    const person = result.clients[0]
    setClient(person)
    this.setState({ person })
  }

  getReport = async () => {
    const { match, history } = this.props
    const { personId, reportId } = match.params
    const result = await getReportById(personId, reportId)
    const report = result.reports[0]
    // if (report.publish_status === 'Published') {
    //   return history.push(match.url.replace('draft', 'preview'))
    // }
    if (!report) {
      history.push('/404')
    }
    this.setState({
      report
    })
  }

  preview = () => {
    const { match, history } = this.props
    history.push(match.url.replace('draft', 'preview'))
  }

  close = () => {
    const { match, history } = this.props
    const { personId } = match.params
    history.push(`/client/${personId}/detail/creport`)
  }

  updateReport = async (params) => {
    const { match } = this.props
    const { reportId } = match.params
    try {
      const result = await updateReport(reportId, params)
      const report = result.report
      this.setState({ report })
    } catch (err) {
      console.error(err)
    }
  }

  publish = async () => {
    const { match, history } = this.props
    const { reportId, personId } = match.params
    const params = { publish_status: 'Published' }
    try {
      await updateReport(reportId, params)
      history.push(`/client/${personId}/detail/creport`)
    } catch (err) {
      console.error(err)
    }
  }

  deleteAttachmentSync = (model, id) => {
    const { report } = this.state
    if (model === 'intro_cover_photo') {
      report[model] = null
    } else {
      const files = report[model].filter((item) => item.id !== id)
      report[model] = files
    }
    this.setState({ report })
  }

  addAttachment = async (
    model,
    file,
    progressCallback = () => {},
    source = null
  ) => {
    const { match } = this.props
    const { personId, reportId } = match.params
    const { report } = this.state
    try {
      const result = await uploadAttachment({
        model,
        personId,
        reportId,
        file,
        progressCallback,
        source
      })
      const attachment = result.attachment || result.data.attachment
      let files = report[model.split('.')[1]]
      if (
        model === 'report.intro_cover_photo' ||
        model === 'report.cover_photo'
      ) {
        files = attachment
      } else {
        files = files || []
        files.push(attachment)
      }
      report[model.split('.')[1]] = files
      this.setState({ report })
    } catch (err) {
      console.error(err)
    }
  }

  componentDidMount() {
    this.getPerson()
    this.getReport()
  }

  renderExtra(enabledField) {
    const enabled = {}
    enabled[enabledField] = false
    return (
      <DeleteOutlined
        onClick={(e) => {
          e.stopPropagation()
          confirm({
            title: 'Delete Section',
            content: 'Are you sure you want to delete this section?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => this.updateReport(enabled)
          })
        }} />
    )
  }

  render() {
    const customPanelStyle = {
      borderRadius: 4,
      marginBottom: 18,
      border: '1px solid #EAEDF3',
      // padding: '15px 10px',
      color: ' #4C6072',
      letterSpacing: 0.3
    }
    const { person, report, activeKey } = this.state
    const childProps = {
      updateReport: this.updateReport,
      addAttachment: this.addAttachment,
      deleteAttachmentSync: this.deleteAttachmentSync,
      person,
      report
    }
    return (
      <div className="draft-report">
        <header>
          <span className="action-item" onClick={this.preview}>
            <EyeFilled />
            PREVIEW
          </span>
          <span
            className="action-item"
            onClick={() =>
              confirm({
                title: 'Publish Report',
                content: 'Are you sure you want to publish this report?',
                okText: 'Yes',
                okType: 'primary',
                cancelText: 'No',
                onOk: () => this.publish()
              })
            }
          >
            <ShareAltOutlined />
            PUBLISH
          </span>
          <CloseOutlined className="close" onClick={this.close} />
        </header>
        <div className="sub-header">
          <FileTextOutlined />
          YOU ARE IN DRAFT MODE
        </div>
        {report && (
          <ReportBanner {...childProps} image={report && report.cover_photo} />
        )}
        <div className="draft-report-container">
          <div className="left">
            <ClientSlideMenu
              propNavagations={draftReportNav}
              {...childProps}
              title={activeKey}
              navagitions={(item) => this.setState({ activeKey: item.id })}
            />
          </div>
          <div className="right">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <DownIcon rotate={isActive ? 180 : 0} />
              )}
              activeKey={activeKey}
              onChange={(ids) => this.setState({ activeKey: ids.pop() })}
            >
              {(!report || report.intro_enabled) && (
                <Panel
                  header="0. Introduction"
                  key="introduction"
                  style={customPanelStyle}
                  extra={this.renderExtra('intro_enabled')}
                  forceRender={true}
                >
                  <ReportDeviIn {...childProps} />
                </Panel>
              )}
              {(!report || report.blood_test_enabled) && (
                <Panel
                  header="1. Blood test result"
                  key="blood-test-result"
                  style={customPanelStyle}
                  extra={this.renderExtra('blood_test_enabled')}
                >
                  <ReportBloodTest {...childProps} />
                </Panel>
              )}
              {(!report || report.body_comp_enabled) && (
                <Panel
                  header="2. Body Composition & Vitals"
                  key="body-composition"
                  style={customPanelStyle}
                  extra={this.renderExtra('body_comp_enabled')}
                >
                  <ReportBodyComp {...childProps} />
                </Panel>
              )}
              {(!report || report.lifestyle_assessment_enabled) && (
                <Panel
                  header="3. Lifestyle Assessment"
                  key="lifestyle-assessment"
                  style={customPanelStyle}
                  extra={this.renderExtra('lifestyle_assessment_enabled')}
                >
                  <ReportLifestyle {...childProps} />
                </Panel>
              )}
              {(!report || report.physical_enabled) && (
                <Panel
                  header="4. Physical Tests"
                  key="physical-tests"
                  style={customPanelStyle}
                  extra={this.renderExtra('physical_enabled')}
                >
                  <PhysicalTests {...childProps} />
                </Panel>
              )}
              {(!report || report.range_of_motion_enabled) && (
                <Panel
                  header="5. RANGE OF MOTION"
                  key="range-of-motion"
                  style={customPanelStyle}
                  extra={this.renderExtra('range_of_motion_enabled')}
                >
                  <ReportRangeOfMotion {...childProps} />
                </Panel>
              )}
              {(!report || report.movement_efficiency_enabled) && (
                <Panel
                  header="6. MOVEMENT EFFICIENCY"
                  key="movement-efficiency"
                  style={customPanelStyle}
                  extra={this.renderExtra('movement_efficiency_enabled')}
                >
                  <ReportMovementEfficiency {...childProps} />
                </Panel>
              )}
              {/* {(!report || report.lifestyle_assessment_enabled) && (
                <Panel
                  header="7. Lifestyle Assessment"
                  key="recommendations"
                  style={customPanelStyle}
                  extra={this.renderExtra('lifestyle_assessment_enabled')}
                >
                  7. Lifestyle Assessment
                </Panel>
              )} */}
            </Collapse>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(DraftReport)
