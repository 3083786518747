import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import PersonalValue from './personalValue'
import HexagonText from '../../../UI/HexagonText'
import { rangeNonlinearPercent } from '../../../../utils/common'
import { detailColors } from '../../../../utils/constant'
import { rangeAreaStyle } from './index.sass'

export class MetricRangesArea extends Component {
  static propTypes = {
    value: PropTypes.number,
    ranges: PropTypes.array,
    person: PropTypes.object,
    matchRange: PropTypes.object,
    metric: PropTypes.object,
    updateValue: PropTypes.func,
    type: PropTypes.string
  }

  state = {
    key: ''
  }

  // componentDidMount() {
  //   const { person } = this.props
  //   const gender = person.profile.gender
  //   const key = `${gender}_`
  //   this.setState({
  //     key
  //   })
  // }

  renderRangeAreaBar(range) {
    const { value } = this.props
    let barStyle = {}
    const colorObj = detailColors.find((item) => item.key === range.color)
    if (value || value === 0) {
      barStyle = {
        background: colorObj && colorObj.value
      }
    }
    return (
      <div className="progress-container">
        <div className="progress-bar" style={barStyle} />
      </div>
    )
  }

  renderRangeArea(range) {
    // const { key } = this.state
    let area = '',
      areaStyle = {}
    if (range.min && range.max) {
      area = `${range.min} - ${range.max}`
    } else if (range.min && !range.max) {
      area = `> ${range.min}`
    } else if (!range.min && range.max) {
      area = `< ${range.max}`
    }
    const colorObj = detailColors.find((item) => item.key === range.color)
    areaStyle = { color: colorObj && colorObj.value }
    return (
      <div className="range-info">
        <span className="area" style={areaStyle}>
          {area}
        </span>
        {range.name}
      </div>
    )
  }

  renderPolygonValue(range) {
    const { value, person } = this.props
    const gender = person.profile.gender
    const style = {}
    if (value) {
      const { percent } = rangeNonlinearPercent(value, range, gender)
      if (percent === 0) {
        style.left = '0%'
      } else if (percent === 100) {
        style.right = '0%'
      } else {
        style.left = '50%'
        style.transform = 'translateX(-50%)'
      }
      // style.marginTop = (isPublicLink ? 25 : 10) + 'px'
    }
    const colorObj = detailColors.find((item) => item.key === range.color)

    return (
      <div className="polygon-value" style={style}>
        <HexagonText
          text={((value || value === 0) && value.toString()) || '0.00'}
          color={(value || value === 0) && colorObj && colorObj.value}
          size={60}
        />
      </div>
    )
  }

  render() {
    const { value, ranges, metric, type } = this.props
    let { matchRange } = this.props
    let colorObj
    if (!matchRange && metric.output_range) {
      matchRange = metric.ranges.find(
        (item) => item.name === metric.output_range.name
      )
    }
    if (matchRange) {
      colorObj = detailColors.find((item) => item.key === matchRange.color)
    }
    ranges.sort(
      (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
    )
    const rangeLengths = ranges.map((item) => item.name.length)
    const maxLength = Math.max(0, ...rangeLengths)
    return (
      (
        <div className={`ranges-area ${rangeAreaStyle}`}>
          <div className="range-title desktop-show">
            <span>{type}</span>
            <span>your value</span>
          </div>
          <div className="range-level">
            {value ? (
              <>
                {(matchRange && (
                  <span>Your levels are {matchRange.name}</span>
                )) || <span>Levels unknown</span>}
                <span
                  style={{
                    color: (colorObj && colorObj.value) || '#4C6072'
                  }}
                >
                  {value} {metric.unit}
                </span>
              </>
            ) : (
              <>
                <span>Levels unknown</span>
                <span
                  style={{
                    color: '#4C6072'
                  }}
                >
                  {value} {metric.unit}
                </span>
                {/* <PersonalValue
                  status={status}
                  initialValue={metric && metric.personal_data}
                  bioMarker={metric}
                  personId={person.id}
                  savePersonalValue={params => updateValue(params)}
                  // personBioMarkerSync={personalData => {
                  //   updateValue(personalData)
                  // }}
                /> */}
              </>
            )}
          </div>
          <div
            className="range-value mobile-show"
            style={{
              color: (colorObj && colorObj.value) || '#4C6072'
            }}
          >
            {value} {metric.unit}
          </div>
          <div className="ranges-container">
            {ranges.map((range, index) => (
              <div
                className="range-area"
                key={index}
                style={{ width: 100 / ranges.length + '%' }}
              >
                {((matchRange &&
                  (matchRange.id === range.id ||
                    matchRange.name === range.name)) ||
                  (!matchRange && !value && index === 0)) &&
                  this.renderPolygonValue(range)}
                {this.renderRangeAreaBar(range)}
              </div>
            ))}
          </div>
          <div className="range-info-container desktop-show">
            {ranges.map((range, index) => (
              <div
                className="range-area"
                style={{ width: 100 / ranges.length + '%' }}
                key={index}
              >
                {this.renderRangeArea(range)}
              </div>
            ))}
          </div>
          <div className="range-info-container mobile-show">
            {ranges.map((range, index) => (
              <div
                className={`${maxLength > 12 ? 'long' : 'short'} range-area`}
                style={
                  maxLength < 12 && ranges.length < 5
                    ? { width: 100 / ranges.length + '%' }
                    : {}
                }
                key={index}
              >
                {this.renderRangeArea(range)}
              </div>
            ))}
          </div>
        </div>
      ) || <div />
    )
  }
}

export default MetricRangesArea
