import { useState } from 'react'
import { saveScoreRecommendation } from '../../api/nutrition'

export function useRecommendation(person, score) {
  const [editingTextNumber, setEditingTextNumber] = useState(0)
  async function saveRecommendation(date, params, version) {
    await saveScoreRecommendation(person.id, date, params, version)
    // const targetScore = score.find((item) => item.date === date)
    // if (targetScore) {
    //   targetScore.recommendation = Object.assign(
    //     targetScore.recommendation || {},
    //     params
    //   )
    // }
    // this.setState({ targetScore, score })
  }

  function updateEditingCount(count) {
    setEditingTextNumber(editingTextNumber + count)
  }

  return [saveRecommendation, updateEditingCount]
}
