const OFFSET_SORCE_Y = [-20, -10, -5, 5, 5, -5, -10]
const OFFSET_NAME_Y = [-5, 5, 10, 20, 20, 10, 5]

const OFFSET_SORCE_COMPARE_Y = [-5, 5, 5, 10, 15, 15, 10]
const MOBILE_OFFSET_SORCE_COMPARE_Y = [0, 5, 5, 30, 30, 15, 10]
const OFFSET_NAME_COMPARE_Y = [-5, 5, 5, 10, 15, 15, 10]
const MOBILE_OFFSET_NAME_COMPARE_Y = [0, 5, 5, 30, 30, 15, 10]

const GRADIENT_DATA = [
  { x: 0, y: 100 },
  { x: 1, y: 100 },
  { x: 2, y: 100 },
  { x: 3, y: 100 },
  { x: 4, y: 100 },
  { x: 5, y: 100 },
  { x: 6, y: 100 }
]

const AXIS_DATAS = [
  [
    { x: 0, y: 20 },
    { x: 1, y: 20 },
    { x: 2, y: 20 },
    { x: 3, y: 20 },
    { x: 4, y: 20 },
    { x: 5, y: 20 },
    { x: 6, y: 20 }
  ],
  [
    { x: 0, y: 40 },
    { x: 1, y: 40 },
    { x: 2, y: 40 },
    { x: 3, y: 40 },
    { x: 4, y: 40 },
    { x: 5, y: 40 },
    { x: 6, y: 40 }
  ],
  [
    { x: 0, y: 60 },
    { x: 1, y: 60 },
    { x: 2, y: 60 },
    { x: 3, y: 60 },
    { x: 4, y: 60 },
    { x: 5, y: 60 },
    { x: 6, y: 60 }
  ],
  [
    { x: 0, y: 80 },
    { x: 1, y: 80 },
    { x: 2, y: 80 },
    { x: 3, y: 80 },
    { x: 4, y: 80 },
    { x: 5, y: 80 },
    { x: 6, y: 80 }
  ],
  [
    { x: 0, y: 100 },
    { x: 1, y: 100 },
    { x: 2, y: 100 },
    { x: 3, y: 100 },
    { x: 4, y: 100 },
    { x: 5, y: 100 },
    { x: 6, y: 100 }
  ]
]

const LABEL_TEXT_ANCHOR = [
  'middle',
  'end',
  'end',
  'end',
  'start',
  'start',
  'start'
]

export {
  OFFSET_SORCE_Y,
  OFFSET_NAME_Y,
  AXIS_DATAS,
  GRADIENT_DATA,
  LABEL_TEXT_ANCHOR,
  OFFSET_SORCE_COMPARE_Y,
  OFFSET_NAME_COMPARE_Y,
  MOBILE_OFFSET_SORCE_COMPARE_Y,
  MOBILE_OFFSET_NAME_COMPARE_Y
}
