import axios, { hashRequest } from './axios'

export const createBloodTest = (params, person_id) =>
  axios.postJson(`/datasets/bio_marker?person_id=${person_id}`, params)

export const deleteBloodTest = id => axios.delete(`/datasets/${id}`)

export const getClientLinkHash = (id, dataset_id) =>
  axios.postJson('/permanent_link/generate', {
    person_id: id,
    scope: `dataset:${dataset_id}`
  })

export const getReportLinkHash = (id, reportId) =>
  axios.postJson('/permanent_link/generate', {
    person_id: id,
    scope: `report:${reportId}`
  })

export const getAllReportLinkHash = personId =>
  // axios.get(`/permanent_link?scope=all&person_id=${personId}`)
  axios.postJson('/permanent_link/generate', {
    person_id: personId,
    scope: 'all'
  })

export const getBloodTests = person_id => {
  let url = '/datasets/bio_marker'
  if (person_id) {
    url += `?person_id=${person_id}`
  }
  return axios.get(url)
}

export const getBloodTestById = async (datasetID, person_id, hash) => {
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: `/datasets/bio_marker/${datasetID}?person_id=${person_id}`
    })
    result = result.data
  } else {
    result = axios.get(
      `/datasets/bio_marker/${datasetID}?person_id=${person_id}`
    )
  }
  return result
}

export const updateBioMarkerPerference = (params, id) =>
  axios.putJson(`/datasets/${id}/preference`, params)
