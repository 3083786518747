import React, { useEffect, useState } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { Button, Modal, Progress, message } from 'antd'
import { remoteImgToBase64, timeout } from '../../../utils/common'
import moment from 'moment'
import axios from 'axios'
import { formDataRequest } from '../../../api/axios'

export const RenderImageUrl = ({ url }) => {
  const [data, setData] = useState()
  useEffect(() => {
    if (!url) return
    remoteImgToBase64(url).then((e) => {
      setData(e)
    })
  }, [url])
  return (
    <>
      {' '}
      {data && (
        <img
          style={{ borderRadius: 8, width: 212, height: 220 }}
          src={data}
          alt=""
        />
      )}{' '}
    </>
  )
}

const GenericPdfDownloader = ({
  ids,
  filename,
  person,
  className,
  uploadRequest
}) => {
  const [disabled, setDisabled] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (uploadRequest) {
      setIsModalOpen(true)
      uploadPDF()
        .then(() => {
          setIsModalOpen(false)
          message.destroy()
          message.success('Upload successful!')
          setTimeout(() => {
            window.close()
          }, 500)
        })
        .catch(() => {
          setIsModalOpen(false)
          message.destroy()
          message.error('Fail to upload!')
        })
    }
  }, [uploadRequest])

  const uploadPDF = async () => {
    message.open({
      type: 'loading',
      content: 'PDF is creating...',
      duration: 0
    })
    await timeout(400)
    const pdf = await generatorPDF()
    message.destroy()
    message.open({
      type: 'loading',
      content: 'PDF is uploading...',
      duration: 0
    })
    const title =
      'Assessment Review' +
      moment().format('MM/DD/YYYY') +
      person.first_name.substr(0, 2).toUpperCase() +
      person.last_name.substr(0, 2).toUpperCase() +
      String(person.id).padStart(4, '0')
    const file = pdf.output('blob')
    const path = `/files/ar_deck?person_id=${
      person.id
    }&file_name=AssessmentReview_${person.id}_${new Date().getTime()}.pdf`
    const formData = new FormData()
    formData.append('file', file)
    formData.append('size', file.size)
    formData.set('title', title)
    formData.set('size', file.size)
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    await formDataRequest({
      method: 'post',
      path,
      data: formData,
      onBeforeUpload: () => {
        setPercent(0)
      },
      onUploadProgress: (process) => {
        const percent = (
          (process.loaded / process.total).toFixed(2) * 100
        ).toFixed(0)
        return setPercent(percent)
      },
      cancelSource: source
    })
  }

  const generatorPDF = async () => {
    const footer = document.getElementById('footer-preview')
    const avatar = document.getElementById('avatar')
    const saveASPDF = document.getElementById('saveASPDF')

    if (footer && footer.style) footer.style.display = 'none'
    if (saveASPDF && saveASPDF.style) saveASPDF.style.display = 'none'
    if (avatar && avatar.style) avatar.style.opacity = '0'
    const element = document.getElementById(ids)
    const canvas = await html2canvas(element, {
      useCORS: true,
      imageTimeout: 60 * 1000
    })
    const height = 10800
    const width = canvas.width / (canvas.height / height)
    var doc = new jsPDF('p', 'px', [width, height])
    doc.addImage(canvas.toDataURL('image/png'), 'JPEG', 0, 0, width, height)
    if (footer && footer.style) footer.style.display = 'flex'
    if (avatar && avatar.style) avatar.style.opacity = '1'
    if (saveASPDF && saveASPDF.style) saveASPDF.style.display = 'block'
    return doc
  }

  const downloadPdfDocument = async () => {
    setDisabled(true)
    message.open({
      type: 'loading',
      content: 'PDF in progress..',
      duration: 0
    })
    const doc = await generatorPDF()
    doc.save(filename)
    message.destroy()
    setDisabled(false)
  }
  return (
    <>
      <Button
        type="primary"
        disabled={disabled}
        className={className}
        onClick={downloadPdfDocument}
        id="saveASPDF"
      >
        SAVE AS PDF
      </Button>
      <Modal
        title="Please don't close this page until the PDF upload is complete!"
        open={isModalOpen}
        closable={false}
        footer={null}
        width={800}
      >
        <Progress percent={percent} status="active" />
      </Modal>
    </>
  )
}

export default GenericPdfDownloader
