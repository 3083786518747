import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Typography } from 'antd'
import MetricCard from '../biomarker/client'
const { Paragraph } = Typography

class ClientCategory extends Component {
  static propTypes = {
    category: PropTypes.object,
    metricsField: PropTypes.string,
    turnTo: PropTypes.func
  }

  renderSubCategory(category, index) {
    const { metricsField, turnTo, ageScopes, person } = this.props
    return (
      <div className="sub-category-container" key={index}>
        <Divider orientation="left">{category.name}</Divider>
        <div className="description">
          <Paragraph
            ellipsis={{ rows: 1.5, expandable: true }}
            index={category.id}
          >
            {category.description}
          </Paragraph>
        </div>
        <div className="card-list">
          {category[metricsField].length > 0 ? (
            category[metricsField].map((item, index) => (
              <MetricCard
                key={index}
                metric={item}
                {...{ ageScopes, person, turnTo }}
              />
            ))
          ) : (
            <div className="sec-no-data">no data</div>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { category, metricsField, turnTo, ageScopes, person } = this.props
    return (
      <div className="root-category">
        <div className="description">
          <h2>{category.name}</h2>
          <Paragraph
          // ellipsis={{ rows: 1.5, expandable: true }}
          >
            {category.description}
          </Paragraph>
        </div>
        {(category.child_categories && category.child_categories.length > 0 && (
          <div>
            {category.child_categories.map((item, index) =>
              this.renderSubCategory(item, index)
            )}
          </div>
        )) || (
          <div className="card-list">
            {category[metricsField].length > 0 ? (
              category[metricsField].map((item, index) => (
                <MetricCard
                  key={index}
                  metric={item}
                  {...{ ageScopes, person, turnTo }}
                />
              ))
            ) : (
              <div className="sec-no-data">no data</div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ClientCategory
