import _ from 'lodash'
import moment from 'moment'
// import {timesFormat} from '../../utils/common'

export function calculateDataRatio(data, baseValue) {
  if (baseValue) {
    data = data.map((item) => {
      if (item.x !== baseValue.date && _.isNumber(item.y)) {
        const value = Number(baseValue.value)
        item.ratio = _.round((((item.value || item.y) - value) / value) * 100)
      }
      return item
    })
  }
  return data
}

export function calculateDataValues(metric) {
  metric.values = metric.values || []

  let data = metric.values.map((item) => ({
    x: item.date,
    y: item.value && _.round(item.value, 1),
    source: item.source
  }))
  if (_.includes(['wake_time_end', 'bed_time_start'], metric.metric_key)) {
    // bed_time start or bed time end
    data = metric.values.map((item) => {
      const isSameDay = moment(item.date).isSame(item.value, 'day')
      const hr = moment(item.value).format('H')
      const minutes = moment(item.value).format('m')
      let diff = 0

      if (metric.metric_key === 'bed_time_start') {
        if (isSameDay && hr < 12) {
          diff = 24
        }
      }

      return {
        x: item.date,
        y: item.value && _.round(Number(hr) + Number(minutes) / 60 + diff, 2),
        value: item.value
      }
    })
  } else {
    let baseValue = metric.values.find((item) => item.value)
    if (baseValue) {
      data = calculateDataRatio(data, baseValue)
    }
  }

  return data
}

export function calculateAxisY(data, metric) {
  let axisY = []

  if (metric.metric_key.includes('bed_time_start')) {
    let minValue = Math.min(
      ...data.map((item) => Number(item.y)).filter((item) => item)
    )
    if (minValue < 18) {
      axisY = calculateAxisYDataForTrends(minValue, 30)
    } else {
      axisY = [18, 22, 26, 30]
    }
  } else if (metric.metric_key.includes('wake_time_end')) {
    let maxValue = Math.max(
      ...data.map((item) => Number(item.y)).filter((item) => item)
    )
    let minValue = Math.min(
      ...data.map((item) => Number(item.y)).filter((item) => item)
    )
    if (maxValue < 14) maxValue = 14
    if (minValue > 2) minValue = 2
    if (maxValue === 14 && minValue === 2) {
      axisY = [2, 6, 10, 14]
    } else {
      axisY = calculateAxisYDataForTrends(_.round(minValue), _.round(maxValue))
    }
  } else {
    let maxValue = Math.max(
      ...data.map((item) => Number(item.y)),
      getDefaultMaxValueByUnit(metric.unit)
    )
    let minValue = Math.min(
      ...data.filter((item) => _.isNumber(item.y)).map((item) => Number(item.y))
    )
    if (data.filter((item) => _.isNumber(item.y)).length <= 1) {
      minValue = Math.min(minValue, 0)
    }
    axisY = calculateAxisYDataForTrends(minValue, maxValue)
  }

  return axisY
}

export function tickFormatY(y, metric, position) {
  if (_.includes(['wake_time_end', 'bed_time_start'], metric.metric_key)) {
    // bed_time start or bed time end
    if (_.isNumber(y)) {
      let hr, min
      if (y >= 24) {
        y = y - 24
        hr = Math.floor(y)
        min = (y % 1) * 60
      } else {
        hr = Math.floor(y) - 12
      }
      min = (y % 1) * 60
      y = moment()
        .hour(hr)
        .minute(min)
        .format('hh:mm')
      //   return
    } else if (y) {
      y = moment(y).format('hh:mm a')
    }
  } else {
    y = y && _.round(y, 2)
  }
  // else if (metric.unit === "Hours" && position === 'data') {
  //   const { hours, minutes } = timesFormat(y * 60 * 60)
  //   y = ${}
  // }
  return y
}

function calculateAxisYDataForTrends(min, max) {
  let step = 1,
    base = 0
  if (max < 30) {
    step = _.ceil(max / 3, 0)
  } else if (max < 300) {
    base = _.floor(min, -1)
    step = _.ceil((_.ceil(max, -1) - base) / 3, -1)
  } else if (max <= 1000) {
    base = _.floor(min, -2)
    step = _.ceil((_.ceil(max, -2) - base) / 3, -2)
  } else {
    base = _.floor(min, -3)
    step = _.ceil((_.ceil(max, -3) - base) / 3, -3)
  }
  return [0, 1, 2, 3].map((number) => number * step + base)
}

function getDefaultMaxValueByUnit(unit) {
  let max = 10
  switch ((unit || '').toLowerCase()) {
    case 'hours':
      max = 12
      break
    case 'bpm':
      max = 180
      break
    case 'lbs':
      max = 200
      break

    default:
      break
  }

  return max
}

export function getBarWidth(charts, dateRange) {
  const metric = charts[0]
  const len = metric.data.length
  let width = 20
  if (len > 25) {
    width = 12
  } else if (len > 15) {
    width = 16
  }

  return charts.length > 1 && !charts.find((item) => item.ranges)
    ? width / 2
    : width
}

export function getRadius(width) {
  switch (width) {
    case 8:
    case 12:
    case 10:
      return 2
    case 6:
      return 1
    default:
      return 4
  }
}

export function dateFormatConfirm(dateRange, metric) {
  if (dateRange.name === 'Lifetime' || dateRange.name === 'Custom') {
    const { aggregate_mode, values } = metric
    const count = values.length
    let format = 'M/DD'
    if (aggregate_mode === 'day' || aggregate_mode === 'week') {
      if (count < 14) {
        format = 'MMM/D'
      } else {
        format = 'M/DD'
      }
    } else {
      if (count < 14) {
        format = 'MMM/YY'
      } else {
        format = 'M/YY'
      }
    }

    return format
  } else {
    return dateRange.formatX
  }
}

export function unifyAxisY(chart) {
  const [{ axisY: arrY1 }, { axisY: arrY2 }] = chart
  const arrY = _.concat(arrY1, arrY2)

  const axisY = calculateAxisYDataForTrends(
    Math.min(...arrY),
    Math.max(...arrY)
  )
  chart[0].axisY = axisY
  chart[1].axisY = axisY
  return chart
}

export function getAxisPadding(metric, dateRange, type) {
  let paddingLeft = 0,
    paddingRight = 0
  if (type === 'bar') {
    if (dateRange.name === 'Week' || dateRange.name === 'Bi-Weekly') {
      paddingLeft = 30
      paddingRight = 30
    }
  } else {
    switch (dateRange.name) {
      case 'Lifetime':
        paddingRight = 10
        break
      case 'Month':
        paddingRight = 20
        break
      default:
        break
    }
  }

  return { paddingLeft, paddingRight }
}
