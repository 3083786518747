import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RangesArea from './area'
// import { matchRangeByType } from '../../../utils/common'

export class RangesAreas extends Component {
  static propTypes = {
    type: PropTypes.string,
    person: PropTypes.object,
    biomarker: PropTypes.object,
    updateRangeEffects: PropTypes.func,
    showConv: PropTypes.bool,
    showFunc: PropTypes.bool,
    status: PropTypes.string
  }

  state = {}

  updateValue = dataset => {
    const { biomarkerId } = this.props
    const personalBiomarker = dataset.dataset.snapshot.find(
      item => item.bio_marker_id === Number(biomarkerId)
    )

    this.props.updateMatchRange(personalBiomarker, true)
    this.setState({ value: personalBiomarker.value })
  }

  filterRange = () => {
    const { biomarker } = this.props
    this.setState({
      functionalRanges: biomarker.functional_ranges,
      conventionalRanges: biomarker.conventional_ranges
    })
  }

  componentDidMount() {
    const { biomarker } = this.props
    const value = biomarker.personal_data && biomarker.personal_data.value
    this.filterRange()
    if (value || value === 0) this.setState({ value })
  }

  render() {
    const { functionalRanges, conventionalRanges, value } = this.state
    const {
      person,
      biomarker,
      isPublicLink,
      status,
      showConv,
      showFunc,
      functionalMatchRange,
      conventionalMatchRange,
      datasetId,
      biomarkerId
    } = this.props

    const childProps = {
      value: Number(value),
      person,
      biomarker,
      updateValue: this.updateValue,
      isPublicLink,
      status,
      datasetId,
      biomarkerId
    }
    return (
      <>
        {conventionalRanges && showConv && (
          <RangesArea
            type="CONVENTIONAL"
            matchRange={conventionalMatchRange}
            ranges={conventionalRanges}
            {...childProps}
          />
        )}
        {functionalRanges && showFunc && (
          <RangesArea
            type="Functional"
            {...childProps}
            ranges={functionalRanges}
            matchRange={functionalMatchRange}
          />
        )}
      </>
    )
  }
}

export default RangesAreas
