import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getDbFields } from './common'
import ModalForm from '../../modalFrom'
import { testDate } from '../../../models/client.form'
import {
  createDataset,
  delDataset,
  updateHistoryDataset
} from '../../../api/dataset'
import { getCategories } from '../../../api/categories'
import {
  getBloodTests,
  updateBioMarkerPerference
} from '../../../api/bloodTests'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'

export default (WrappedComponent) =>
  class BloodTestContainerWrapper extends Component {
    static propTypes = {
      person: PropTypes.object,
      category: PropTypes.string
    }

    state = {
      dataLoading: true,
      modalVisible: false,
      createDatasetLoading: false,
      ageScopes: null
    }

    getDatasets = async () => {
      const { person } = this.props
      const id = person.id
      let result, datasets
      result = await getBloodTests(id)
      datasets = result.datasets
      this.setState({
        datasets,
        showDataset: datasets[0] && datasets[0].test_date
      })
    }

    getBiomarkerCategories = async () => {
      const { setClientBiomarkerCategories, person } = this.props
      let bioMarkersCategories
      const result = await getCategories(person.id)
      bioMarkersCategories = result
      setClientBiomarkerCategories(bioMarkersCategories)
      return bioMarkersCategories
    }

    createDateSetSubmit = async (params) => {
      const { person } = this.props
      const { datasets } = this.state
      this.setState({ createDatasetLoading: true })
      try {
        const result = await createDataset('bio_marker', person.id, params)
        const dataset = result.dataset
        dataset.isNew = true
        datasets.unshift(dataset)
        this.setState({
          showDataset: dataset.test_date,
          modalVisible: false,
          createDatasetLoading: false
        })
        // this.getDatasets()
      } catch (err) {
        this.setState({ createDatasetLoading: false })
        console.error(err)
        throw err
      }
    }

    personalBioMarkerPreferenceChanged = async (params) => {
      const preference = Object.keys(params)[0]
      const { datasets, showDataset } = this.state
      const index = datasets.findIndex((item) => item.test_date === showDataset)
      const dataset = datasets.find((item) => item.test_date === showDataset)
      try {
        await updateBioMarkerPerference(params, dataset.id)
        dataset.snapshot.forEach(function(element, index, array) {
          element[preference] = params[preference]
        })
        datasets[index] = dataset
        this.setState({
          datasets
        })
      } catch (err) {
        console.error(err.message)
      }
    }

    personalBioMarkerPreferenceStatus = () => {
      const { datasets, showDataset } = this.state
      let dataset = datasets.find((item) => item.test_date === showDataset)
      let conventionalNo = 0
      let functionalNo = 0
      if (dataset && dataset.snapshot) {
        dataset.snapshot.forEach(function(element, index, array) {
          if (!element) return
          if (element.prefer_conventional) {
            conventionalNo += 1
          }
          if (element.prefer_functional) {
            functionalNo += 1
          }
        })
      }

      const conventionalStatus =
        conventionalNo > 0
          ? conventionalNo === dataset.snapshot.length
            ? 'allChecked'
            : 'indeterminate'
          : 'unchecked'
      const functionalStatus =
        functionalNo > 0
          ? functionalNo === dataset.snapshot.length
            ? 'allChecked'
            : 'indeterminate'
          : 'unchecked'

      return {
        conventionalStatus: conventionalStatus,
        functionalStatus: functionalStatus
      }
    }

    findBiomarker = (biomarkerId) => {
      const { categories } = this.state
      let biomarker
      for (const category of categories) {
        for (const subCategory of category.sub_categories) {
          biomarker = subCategory.bio_markers.find(
            (item) => item.id === biomarkerId
          )
          if (biomarker) break
        }
        if (biomarker) break
      }
      return biomarker
    }

    savePersonalValue = async (personalValue, datasetId) => {
      const { datasets, showDataset } = this.state
      let dataset = datasets.find((item) => item.test_date === showDataset)
      const index = datasets.findIndex((item) => item.test_date === showDataset)
      const { value, id } = personalValue
      try {
        let result
        result = await updateHistoryDataset(datasetId, personalValue)
        dataset = result.dataset
        const testDate = dataset.test_date.slice(0, 10)
        datasets.splice(index, 1, dataset)
        const biomarker = this.findBiomarker(id)
        if (biomarker) {
          biomarker.history = biomarker.history || []
          const targetHistory = biomarker.history.find(
            (item) => item.test_date === testDate
          )
          if (targetHistory) {
            if (value === null) {
              biomarker.history = biomarker.history.filter(
                (item) => item.test_date !== testDate
              )
            } else {
              targetHistory.value = Number(value)
            }
          } else {
            biomarker.history.push({ value, test_date: testDate })
            biomarker.history.sort((a, b) => {
              return moment(a.test_date).isAfter(moment(b.test_date)) ? 1 : -1
            })
          }
        }
        this.setState({ datasets })
        return true
      } catch (err) {
        console.error(err)
      }
    }

    deleteDataset = async (item, index) => {
      const { person } = this.props
      let { datasets, showDataset } = this.state
      try {
        if (showDataset === item.test_date) {
          showDataset = ''
        }
        await delDataset(item.id)
        datasets.splice(index, 1)
        if (datasets.length > 0) {
          showDataset = datasets[0].test_date
        }
        const categories = await getCategories(person.id)
        this.setState({ datasets, showDataset, categories })
      } catch (err) {
        console.error(err)
      }
    }

    initial = async () => {
      const dbField = getDbFields('blood-test')
      let { ageScopes } = this.state
      this.setState({ dbField })
      try {
        const categories = await this.getBiomarkerCategories()
        await this.getDatasets()
        if (!ageScopes) {
          ageScopes = (await getRangeAgeScopes()).age_scope
        }
        this.setState({
          categories,
          dataLoading: false,
          ageScopes
        })
        this.props.restoreScrollPosition()
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
      }
    }

    fileUploadAsync = () => this.initial()

    componentDidMount() {
      this.initial()
    }

    componentWillUnmount() {
      const { history } = this.props
      if (!history.location.pathname.includes('biomarker')) {
        this.props.clearScrollPosition()
      }
    }

    render() {
      const { history, match, person, category } = this.props
      const childProps = {
        ...this.state,
        person,
        selectCategory:
          category ||
          (this.state.categories &&
            this.state.categories[0] &&
            this.state.categories[0].name),
        setWrapperState: (state) => this.setState({ ...state }),
        createDataset: () => this.setState({ modalVisible: true }),
        personalBioMarkerPreferenceStatus: this
          .personalBioMarkerPreferenceStatus,
        personalBioMarkerPreferenceChanged: this
          .personalBioMarkerPreferenceChanged,
        deleteDataset: this.deleteDataset,
        fileUploadAsync: this.fileUploadAsync,
        dataHandle: {
          savePersonalValue: this.savePersonalValue,
          turnTo: (id, datasetId) => {
            // before turn to detail, save scroll position
            this.props.saveScrollPosition()
            const path = match.url.split('/blood-test')[0]
            history.push(`${path}/biomarker/${id}/${datasetId}`)
          }
        }
      }

      const { modalVisible, createDatasetLoading } = this.state

      return (
        <>
          <WrappedComponent {...this.props} {...childProps} />
          {modalVisible && (
            <ModalForm
              title="Create DataSet"
              visible={modalVisible}
              formData={testDate}
              onCancel={() => this.setState({ modalVisible: false })}
              onSubmit={this.createDateSetSubmit}
              loading={createDatasetLoading}
            />
          )}
        </>
      )
    }
  }
