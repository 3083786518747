import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { sumReduce } from '../common'
import {
  HeartTargetSummaryHitMinimumLineIcon,
  HeartTargetHitedIcon,
  SvgText
} from '../../icons/weeklySummary'
import { acsmTargetStyle } from './styles/acsmTarget.sass'

class ACSMHeartTarget extends Component {
  static propTypes = {
    exercise: PropTypes.object,
    previousWeek: PropTypes.bool,
    containerStyle: PropTypes.object,
    checkToday: PropTypes.bool
  }

  processData() {
    const { exercise, previousWeek } = this.props
    if (exercise) {
      let zonesPercentage, dataColors
      if (previousWeek) {
        zonesPercentage = exercise.previous_week_acsm_percentage || [0, 0, 0, 0]
        dataColors = [
          { color: '#F6CAA3' },
          { color: '#F8B98C' },
          { color: '#F9A998' },
          { color: '#FB9273' }
        ]
      } else {
        zonesPercentage = exercise.current_acsm_percentage || [0, 0, 0, 0]
        dataColors = [
          { color: '#F7AD69' },
          { color: '#FB9043' },
          { color: '#FC7558' },
          { color: '#FF501A' }
        ]
      }
      let data = dataColors
        .map((zone, index) => {
          return {
            color: zone.color,
            percentage:
              zonesPercentage[index] && Math.round(zonesPercentage[index])
          }
        })
        .filter((item) => item.percentage !== 0)
        .reverse()
      return { data, totalPercentage: zonesPercentage.reduce(sumReduce, 0) }
    } else {
      return { data: [], totalPercentage: 0 }
    }
  }

  processPosition(totalPercentage) {
    return {
      hitMinimum: totalPercentage >= 50,
      minimumPosition:
        totalPercentage <= 100
          ? '50%'
          : `${Math.round((50 / totalPercentage) * 100)}%`,
      hitIdeal: totalPercentage > 100,
      idealPosition:
        totalPercentage > 100 ? Math.round((100 / totalPercentage) * 100) : 100,
      percentageLabelPosition:
        totalPercentage >= 95 ? '94%' : `${totalPercentage + 2}%`
    }
  }

  processMinimumLabelVisibility = (position) => {
    if (position) {
      return parseInt(position) < 20 ? 'hidden' : 'visible'
    }
    return 'visible'
  }

  processIdealLabelVisibility = (position) => {
    if (position) {
      return parseInt(position) > 82 ? 'hidden' : 'visible'
    }
    return 'visible'
  }

  getDateText = () => {
    const { previousWeek, startDate, endDate, checkToday } = this.props
    if (previousWeek) return 'PREVIOUS WEEK'
    if (startDate && endDate) {
      if (!checkToday) {
        return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
      } else if (
        moment().format('YYYY-MM-DD') === startDate.format('YYYY-MM-DD')
      ) {
        return 'Today'
      } else if (
        moment().isSameOrAfter(startDate) &&
        moment().isSameOrBefore(endDate)
      ) {
        return `${startDate.format('MMM D')} - TODAY`
      } else {
        return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`
      }
    } else {
      return ''
    }
  }

  render() {
    const { previousWeek, startDate, endDate, containerStyle } = this.props
    const { data, totalPercentage } = this.processData()
    const position = this.processPosition(totalPercentage)
    return (
      <div className={acsmTargetStyle} style={containerStyle || {}}>
        <div
          style={{
            position: 'relative',
            height: 18
          }}
        >
          {position.hitMinimum && (
            <HeartTargetHitedIcon
              style={{
                left: position.minimumPosition,
                transform: 'translateX(-50%)',
                position: 'absolute'
              }}
            />
          )}
          {position.hitIdeal && (
            <HeartTargetHitedIcon
              style={{
                // right: `${100 - position.idealPosition}%`,
                left: `${position.idealPosition}%`,
                transform: 'translateX(-50%)',
                position: 'absolute'
              }}
            />
          )}
        </div>
        <div className="zone-background">
          {data.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: item.color,
                flexGrow: item.percentage
              }}
              className={`
              ${index === 0 ? 'left-border' : ''}
              ${index === data.length - 1 ? 'right-border' : ''}`}
            />
          ))}
          {totalPercentage < 100 && (
            <div
              style={{
                backgroundColor: 'clear',
                flexGrow: 100 - totalPercentage
              }}
            />
          )}
          <HeartTargetSummaryHitMinimumLineIcon
            style={{
              position: 'absolute',
              left: position.minimumPosition
            }}
          />
          {position.hitIdeal && (
            <div
              className="hit-ideal-line"
              style={{
                // right: `${100 - position.idealPosition}%`,
                left: `${position.idealPosition}%`
              }}
            />
          )}
          <div
            className="percentage"
            style={{
              left: position.percentageLabelPosition
            }}
          >{`${totalPercentage}%`}</div>
        </div>
        <div className="zone-target">
          <span
            className="zone-target-label"
            style={{
              left: '0%'
            }}
          >
            {(previousWeek || (startDate && endDate)) && (
              <SvgText
                height={16}
                width={100}
                fontFamily="Gilroy-Bold"
                textAttr={{
                  x: 0,
                  y: 10,
                  fontSize: 10,
                  fontWeight: 'bold',
                  fill: '#70808E',
                  letterSpacing: 0.5,
                  textAnchor: 'start',
                  dominantBaseline: 'middle'
                }}
                text={this.getDateText()}
              />
            )}
          </span>
          <span
            className="zone-target-label"
            style={{
              left: position.minimumPosition,
              transform: 'translateX(-50%)',
              visibility: `${this.processMinimumLabelVisibility(
                position.minimumPosition
              )}`
            }}
          >
            <SvgText
              height={16}
              width={46}
              fontFamily="Gilroy-Bold"
              textAttr={{
                x: 1,
                y: 10,
                fontSize: 8,
                fontWeight: 'bold',
                fill: '#70808E',
                letterSpacing: 0.5,
                textAnchor: 'start',
                dominantBaseline: 'middle'
              }}
              text="MINIMUM"
            />
          </span>
          <span
            className="zone-target-label"
            style={{
              right: `${100 - position.idealPosition}%`,
              transform: `${
                100 - position.idealPosition === 0 ? '' : 'translateX(50%)'
              }`,
              visibility: `${this.processIdealLabelVisibility(
                100 - position.idealPosition
              )}`
            }}
          >
            <SvgText
              height={16}
              width={28}
              fontFamily="Gilroy-Bold"
              textAttr={{
                x: 0,
                y: 10,
                fontSize: 8,
                fontWeight: 'bold',
                fill: '#70808E',
                letterSpacing: 0.5,
                textAnchor: 'start',
                dominantBaseline: 'middle'
              }}
              text="IDEAL"
            />
          </span>
        </div>
      </div>
    )
  }
}

export default ACSMHeartTarget
