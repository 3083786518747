/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Additional from './addition'

export default function Emergency(props) {
  const { question, update: updateAnswers, answer } = props
  const [values, setValues] = useState([])
  let [
    emergency_contact_name,
    emergency_contact_phone,
    emergency_contact_email,
    emergency_contact_fax_number,
    emergency_contact_website,
    emergency_contact_relation
  ] = values

  useEffect(() => {
    const valuesTemp = _.cloneDeep(answer && answer.answer.value)
    setValues(valuesTemp)
    checkRequired(valuesTemp)
  }, [answer])

  const checkRequired = (values) => {
    const isModify = values.find(e => e !== '')
    if(isModify){
      const newAnswer = _.assignIn(answer, { answer: { value: values } })
      updateAnswers(newAnswer, question)
    }
  }
 
  const additionalInformation = question.additional_information

  const update = () => {
    const values = [
      emergency_contact_name,
      emergency_contact_phone,
      emergency_contact_email,
      emergency_contact_fax_number,
      emergency_contact_website,
      emergency_contact_relation
    ]
    setValues(values)
    const newAnswer = _.assignIn(answer, { answer: { value: values } })
    updateAnswers(newAnswer, question)
  }

  const handleAdditionalAnswer = (newAnswer) => {
    emergency_contact_name = newAnswer[0]
    emergency_contact_phone = newAnswer[1]
    emergency_contact_email = newAnswer[2]
    emergency_contact_fax_number = newAnswer[3]
    emergency_contact_website = newAnswer[4]
    emergency_contact_relation = newAnswer[5]
    update()
  }

  return (
    <div className="q-form-group">
      {additionalInformation && (
        <div className={'q-form-item '}>
          <Additional
            items={additionalInformation}
            anArr={[
              emergency_contact_name,
              emergency_contact_phone,
              emergency_contact_email,
              emergency_contact_fax_number,
              emergency_contact_website,
              emergency_contact_relation
            ]}
            update={handleAdditionalAnswer}
            verify={answer.verify ? answer.verify.additional : []}
          />
        </div>
      )}
      {answer && answer.verify && <p className="invalid">{'Required'}</p>}
    </div>
  )
}
