import React, { useEffect, useState } from 'react'
import {
  getClientList,
  getClientDetail,
  getSupportTeam
} from '../../../models/breadcrumb.model'
import SupportTeamContainer from '../../../components/supportTeam/container'

export default function SupportTeamView(props) {
  let [person, setPerson] = useState(null)

  useEffect(() => {
    const initial = async () => {
      const { getPerson, setClientPageTitle, setClientBreadcrumb } = props

      if (!person) {
        person = await getPerson()
        setPerson(person)
      }

      setClientPageTitle &&
        setClientPageTitle(
          // `${person.profile.nickname || person.first_name}’s Program`
          'Support Team'
        )
      setClientBreadcrumb &&
        setClientBreadcrumb([
          getClientList(),
          getClientDetail(person),
          getSupportTeam(person, true)
        ])
    }
    initial()
  }, [])
  return <div>{person && <SupportTeamContainer person={person} />}</div>
}
