import axios from './axios'

const url = '/facility'

export const getFacilities = () => axios.get(url)

export const createFacility = params => axios.postJson(url, params)

export const updateFacility = (params, id) =>
  axios.putJson(`${url}/${id}`, params)

export const delFacility = id => axios.delete(`${url}/${id}`)

export const getFacilityDetail = id => axios.get(`${url}/${id}`)

export const getService = () => axios.get(`${url}/service`)

export const getExperts = () => axios.get(`${url}/expert`)

export const updateExperts = (facilityId, serviceId, params) =>
  axios.putJson(`${url}/expert/${facilityId}/${serviceId}`, params)
