import React, { Component } from 'react'
import Message from '../../../components/message'
import { ChartIcon } from '../../../components/icons'
import {
  getClientList,
  getClientDetail,
  getMessage
} from '../../../models/breadcrumb.model'
import { loadUser } from '../../../utils/storage'

class MessagePage extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    setClientPageTitle(null)
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getMessage(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    return (
      <>
        <h1
          className="page-title"
          style={{ padding: '20px 0', marginBottom: 16 }}
        >
          <span
            style={{
              color: '#E75F25',
              background: '#FDEEE6',
              borderRadius: '100%',
              display: 'inline-block',
              marginRight: 12,
              width: 32,
              height: 32,
              textAlign: 'center',
              lineHeight: '32px'
            }}
          >
            <ChartIcon />
          </span>
          {person &&
            `${person.profile.nickname || person.first_name} ${
              person.last_name
            }'s `}
          <span style={{ color: '#E75F25' }}>Chat</span>
        </h1>
        {person && (
          <div
            style={{
              background: '#ffffff',
              borderRadius: 12,
              padding: 30,
              boxShadow: '10px 4px 30px rgba(0, 0, 0, 0.05)',
              minHeight: 'calc(100vh - 300px + 60px)'
              // position: 'absolute',
              // width: 'calc(100% - 38px - 44px)'
            }}
          >
            <Message clientId={person.id} sender={loadUser()} />
          </div>
        )}
      </>
    )
  }
}

export default MessagePage
