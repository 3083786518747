import React from 'react'
import _ from 'lodash'
import { tickFormatY, getBarWidth, getRadius } from './chartHandle'
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryGroup,
  VictoryLabel,
  VictoryTooltip,
  VictoryScatter
} from 'victory'
import ContainerWidthSizer from '../UI/ContainerWidthSizer'
import CustomTooltip from './customTooltip'
import { Scatter } from './rangeAxisChart'
import { renderTravel } from './travel'

function BarChart(props) {
  const {
    charts,
    showIndex,
    showNumber,
    dateRange,
    active,
    strokeColor,
    fontFamily,
    strokeActiveColor,
    fontSize,
    isCompared,
    setActive,
    chartPadding,
    travelData
  } = props
  const [metric, compared] = charts
  const barWidth = getBarWidth(charts, dateRange)
  const rangeMetric = charts.find((item) => item.ranges)
  if (compared && !rangeMetric && !_.isEqual(metric.axisY, compared.axisY)) {
    handleAxisYForBar(metric, compared)
  }
  let minDomain = { y: !metric.ranges ? metric.axisY[0] : compared.axisY[0] }

  if (!rangeMetric) {
    minDomain = {
      y: Math.min(
        ...[metric.axisY[0], compared && compared.axisY[0]].filter((y) =>
          _.isNumber(y)
        )
      )
    }
  }

  function renderChartContainer({
    containerStyle,
    isTooltipContainer,
    travelData
  }) {
    return (
      <ContainerWidthSizer style={containerStyle || {}}>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={200}
            minDomain={minDomain}
            padding={chartPadding}
          >
            <VictoryAxis
              name="axis_x"
              tickValues={metric.axisX || compared.axisX}
              style={{
                grid: {
                  stroke: isTooltipContainer
                    ? 'transparent'
                    : (tick) =>
                        active
                          ? active.x === tick
                            ? strokeActiveColor
                            : strokeColor
                          : strokeColor,
                  strokeDasharray: '6, 3'
                },
                axis: {
                  stroke: isTooltipContainer ? 'transparent' : strokeColor
                },
                tickLabels: {
                  fill: 'transparent'
                }
              }}
            />
            {metric && !metric.ranges && (
              <VictoryAxis
                dependentAxis
                // orientation={isCompared ? 'right' : 'left'}
                tickValues={metric.axisY}
                tickFormat={(t) =>
                  tickFormatY(
                    metric.tickFormatY ? metric.tickFormatY(t) : t,
                    metric
                  )
                }
                style={{
                  axis: {
                    stroke: isTooltipContainer ? 'transparent' : strokeColor
                  },
                  grid: {
                    stroke: isTooltipContainer ? 'transparent' : strokeColor,
                    strokeDasharray: '6, 3'
                  },
                  tickLabels: {
                    fontFamily,
                    fontSize,
                    fill: isTooltipContainer
                      ? 'transparent'
                      : metric.theme.primary,
                    padding: 15
                  }
                }}
              />
            )}
            {compared && !compared.ranges && (
              <VictoryAxis
                key={Math.random()}
                dependentAxis
                orientation="right"
                tickValues={compared.axisY}
                tickFormat={(t) =>
                  tickFormatY(
                    compared.tickFormatY ? compared.tickFormatY(t) : t,
                    compared
                  )
                }
                style={{
                  axis: { stroke: strokeColor },
                  grid: {
                    stroke: isTooltipContainer ? 'transparent' : strokeColor,
                    strokeDasharray: '6, 3'
                  },
                  tickLabels: {
                    fontFamily,
                    fontSize,
                    fill: isTooltipContainer
                      ? 'transparent'
                      : compared.theme.primary,
                    padding: 15
                  }
                }}
              />
            )}
            <VictoryGroup offset={barWidth * 2}>
              {metric && !metric.ranges && (
                <VictoryBar
                  name="data"
                  data={metric.data}
                  labels={(datum) =>
                    isTooltipContainer
                      ? datum.value || datum.y
                      : showNumber
                      ? tickFormatY(
                          datum.value || datum.originY || datum.y,
                          metric,
                          'data'
                        )
                      : null
                  }
                  labelComponent={
                    isTooltipContainer ? (
                      <VictoryTooltip
                        flyoutComponent={
                          <CustomTooltip
                            containerWidth={width}
                            setActive={setActive}
                            metrics={compared ? [metric, compared] : [metric]}
                            dateRange={dateRange}
                          />
                        }
                      />
                    ) : (
                      <VictoryLabel />
                    )
                  }
                  cornerRadius={{ top: getRadius(barWidth) }}
                  style={{
                    data: {
                      fill: `url(#barGradient_${showIndex}_${metric.metric_key
                        .split(' ')
                        .join('_')})`,
                      width: barWidth,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    },
                    labels: {
                      fill: isTooltipContainer
                        ? 'transparent'
                        : metric.theme.primary,
                      fontSize,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    }
                  }}
                />
              )}

              {compared && !compared.ranges && (
                <VictoryBar
                  mane="data"
                  data={compared.data}
                  labels={(datum) =>
                    isTooltipContainer
                      ? datum.value || datum.y
                      : showNumber
                      ? tickFormatY(
                          datum.value || datum.originY || datum.y,
                          compared,
                          'data'
                        )
                      : null
                  }
                  labelComponent={
                    isTooltipContainer ? (
                      <VictoryTooltip
                        flyoutComponent={
                          <CustomTooltip
                            containerWidth={width}
                            setActive={setActive}
                            metrics={compared ? [metric, compared] : [metric]}
                            dateRange={dateRange}
                          />
                        }
                      />
                    ) : (
                      <VictoryLabel />
                    )
                  }
                  cornerRadius={{ top: getRadius(barWidth) }}
                  style={{
                    data: {
                      fill: `url(#barGradient_${showIndex}_${compared.metric_key
                        .split(' ')
                        .join('_')})`,
                      width: barWidth,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    },
                    labels: {
                      fill: isTooltipContainer
                        ? 'transparent'
                        : compared.theme.primary,
                      fontSize,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    }
                  }}
                />
              )}
            </VictoryGroup>

            {rangeMetric && (
              <VictoryGroup>
                <VictoryScatter
                  name="data"
                  data={rangeMetric.dataSet}
                  dataComponent={<Scatter active={active} />}
                  style={{
                    labels: {
                      fill: isTooltipContainer
                        ? 'transparent'
                        : (datum) => datum.color,
                      fontSize,
                      fillOpacity: isTooltipContainer
                        ? 0
                        : (datum) =>
                            active ? (active.x === datum.x ? 1 : 0.5) : 1
                    }
                  }}
                  labels={(datum) => (showNumber ? datum.value : null)}
                />
                {isTooltipContainer && (
                  <VictoryScatter
                    data={rangeMetric.dataSet}
                    size={10}
                    labels={(datum) => (showNumber ? datum.y : null)}
                    labelComponent={
                      <VictoryTooltip
                        flyoutComponent={
                          <CustomTooltip
                            containerWidth={width}
                            setActive={setActive}
                            metrics={compared ? [metric, compared] : [metric]}
                            dateRange={dateRange}
                          />
                        }
                      />
                    }
                    style={{
                      data: {
                        fill: 'transparent'
                      },
                      labels: {
                        fill: 'transparent',
                        fontSize
                      }
                    }}
                  />
                )}
              </VictoryGroup>
            )}
            {travelData && renderTravel(travelData, metric.axisY[0])}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }

  return (
    <>
      {charts
        .filter((item) => !item.ranges)
        .map(({ metric_key, theme }, index) => (
          <svg style={{ height: 200, position: 'absolute' }} key={index}>
            <defs>
              <linearGradient
                id={`barGradient_${showIndex}_${metric_key
                  .split(' ')
                  .join('_')}`}
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop
                  offset="0"
                  stopColor={theme.barBg ? theme.barBg[0] : theme.primary}
                  stopOpacity={theme.barBg ? 1 : 0.9}
                />
                <stop
                  offset="1"
                  stopColor={theme.barBg ? theme.barBg[1] : theme.primary}
                  stopOpacity="1"
                />
              </linearGradient>
            </defs>
          </svg>
        ))}

      {/* transparent chart to show tooltip */}
      {renderChartContainer({
        containerStyle: { position: 'absolute', width: '100%', zIndex: 99 },
        isTooltipContainer: true
      })}
      {renderChartContainer({
        containerStyle: isCompared
          ? { position: 'absolute', width: '100%' }
          : null,
        travelData
        // isTooltipContainer: isCompared
      })}
    </>
  )
}
export default BarChart

function handleAxisYForBar(metric, compared) {
  _.forEach([metric, compared], (targetMetric) => {
    if (targetMetric.axisY[0] !== 0) {
      const min = targetMetric.axisY[0]
      targetMetric.originAxisY = _.cloneDeep(targetMetric.axisY)
      targetMetric.axisY = targetMetric.axisY.map((item) => item - min)
      targetMetric.tickFormatY = (t) => {
        const index = targetMetric.axisY.findIndex((tick) => tick === t)
        return targetMetric.originAxisY[index]
      }
      targetMetric.data = targetMetric.data.map((item) => {
        item.originY = item.y
        item.y = item.y && item.y - min
        return item
      })
    }
  })

  const maxDomain = Math.max(...metric.axisY, ...compared.axisY)

  const targetMetric = [metric, compared].find(
    ({ axisY }) => axisY[axisY.length - 1] !== maxDomain
  )

  const standardMetric = [metric, compared].find(
    ({ axisY }) => axisY[axisY.length - 1] === maxDomain
  )

  if (targetMetric) {
    const ratio = maxDomain / targetMetric.axisY[targetMetric.axisY.length - 1]

    targetMetric.originAxisY = targetMetric.originAxisY || targetMetric.axisY

    targetMetric.axisY = standardMetric.axisY

    targetMetric.data = targetMetric.data.map((item) => {
      item.originY = item.originY || item.y
      item.y = item.y && _.round(item.y * ratio, 2)
      return item
    })
  }
}
