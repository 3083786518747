import * as actionType from './action-type'
import initialState from './initial-state'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.SET_EDITING_TEXT_NUMBER:
      return {
        ...state,
        ...payload
      }
    default:
      return state
  }
}
