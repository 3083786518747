import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ClockCircleFilled } from '@ant-design/icons'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Empty, Button, Modal, Radio } from 'antd'
import moment from 'moment'
import DataTable from '../../../components/UI/table'
import {
  getClientList,
  getClientDetail,
  getComprehensiveReport
} from '../../../models/breadcrumb.model'
import navagations from '../../../models/clientNavagation.model'
import { getReports, createReport, deleteReport } from '../../../api/report'
import { getBloodTests, getReportLinkHash } from '../../../api/bloodTests'
import { getAllDataset } from '../../../api/dataset'
import IconArchive from '../../../asserts/icon/archive.svg'
// import IconReport from '../../../asserts/icon/generate-report.svg'
import IconDel from '../../../asserts/icon/icon-del.svg'
import {
  ShareIcon,
  PenIcon,
  GenerateReportIcon
} from '../../../components/icons'

const RadioGroup = Radio.Group

export class CreportList extends Component {
  static propTypes = {
    getPerson: PropTypes.func
  }

  state = {
    reports: [],
    person: null,
    bloodTests: [],
    modalVisible: false,
    loading: false,
    selectedBloodTest: null,
    opacity: 1,
    hasDatasets: false,
    params: {}
  }

  columns = [
    {
      title: 'REPORT NUMBER',
      key: 'index',
      render: (text, record, index) => this.state.reports.length - index
    },
    {
      title: 'GENERATED TIME',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => <div>{moment(text).format('LT - ll')}</div>
    },
    {
      title: 'PUBLISH STATUS',
      dataIndex: 'status',
      key: 'status',
      width: '35%',
      render: (text, record, index) => (
        <div className="report-status">
          {record.publish_status === 'Draft' ? (
            <span className="editing">
              <ClockCircleFilled /> EDITING
            </span>
          ) : (
            <span className="published">
              <LegacyIcon type="check" theme="filled" /> PUBLISHED
            </span>
          )}
        </div>
      )
    },
    {
      key: 'action',
      render: (text, record, index) => (
        <div className="action-list">
          <span
            className="action-item"
            onClick={(e) => {
              e.stopPropagation()
              this.editReport(record.id)
            }}
          >
            <PenIcon />
            EDIT
          </span>
          {record.publish_status === 'Draft' ? (
            <span
              className="action-item"
              style={{ width: '70px', display: 'inline-block' }}
            />
          ) : (
            <span
              className="action-item"
              onClick={(e) => {
                e.stopPropagation()
                this.getLink(record.id)
              }}
            >
              <ShareIcon />
              SHARE
            </span>
          )}
          <span
            className="action-item"
            onClick={(e) => {
              e.stopPropagation()
              this.deleteReport(record.id, index)
            }}
          >
            <img className="phs-pointer" src={IconDel} alt="del" />
          </span>
        </div>
      )
    }
  ]

  editReport = (id) => {
    const { history } = this.props
    const { person } = this.state
    history.push(`/creport/${person.id}/draft/${id}`)
  }

  previewReport = (id) => {
    const { history } = this.props
    const { person } = this.state
    history.push(`/creport/${person.id}/preview/${id}`)
  }

  createReport = async () => {
    const { params, person, reports } = this.state
    this.setState({ loading: true })
    // if (!selectedBloodTest) return
    try {
      const result = await createReport(person.id, params)
      reports.push(result.report)
      this.setState({ reports, loading: false, modalVisible: false })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  deleteReport = async (id, index) => {
    const { reports, opacity } = this.state
    if (opacity < 1) return
    this.setState({ opacity: 0.5 })
    try {
      await deleteReport(id)
      reports.splice(index, 1)
      this.setState({ reports, opacity: 1 })
    } catch (err) {
      console.error(err)
      this.setState({ opacity: 1 })
    }
  }

  addDataset = (link) => {
    const { history, match } = this.props
    history.push(match.path.replace('creport', link.addlink))
  }

  getLink = async (id) => {
    const { person } = this.state
    const result = await getReportLinkHash(person.id, id)
    window.open(`/creport/preview/${result.permanent_link.hash}`)
  }

  getDatasets = async () => {
    const { person } = this.state
    const results = await Promise.all([
      getBloodTests(person.id),
      getAllDataset('body_comp', person.id),
      getAllDataset('lifestyle_assessment', person.id),
      getAllDataset('range_of_motion', person.id),
      getAllDataset('movement_efficiency', person.id)
    ])

    const [
      bloodTests,
      bodyComps,
      lifestyles,
      rangeOfMotions,
      movementEfficiencies
    ] = results

    const datasets = [
      {
        list: bloodTests.datasets,
        title: 'Blood Tests',
        addLink: 'blood-test',
        field: 'blood_test_id'
      },
      {
        list: bodyComps.datasets,
        title: 'Body Composition & Vitals',
        addlink: 'body-comp',
        field: 'body_comp_id'
      },
      {
        list: lifestyles.datasets,
        title: 'Lifestyle Assessment',
        addlink: 'life-style',
        field: 'lifestyle_assessment_id'
      },
      {
        list: rangeOfMotions.datasets,
        title: 'Range of Motions',
        addlink: 'range-of-motions',
        field: 'range_of_motion_id'
      },
      {
        list: movementEfficiencies.datasets,
        title: 'Movement Efficiency',
        addlink: 'movement-efficiency',
        field: 'movement_efficiency_id'
      }
    ]

    this.setState({
      hasDatasets: true,
      datasets
    })
  }

  initial = async () => {
    const { setClientBreadcrumb, setClientPageTitle, getPerson } = this.props
    const person = await getPerson()
    setClientBreadcrumb([
      getClientList(),
      getClientDetail(person),
      getComprehensiveReport(person.id, true)
    ])
    setClientPageTitle(
      navagations.filter((item) => item.id === 'comprehensive-report')[0].title
    )
    const result = await getReports(person.id)
    const reports = result.reports
    this.setState({ person, reports })
    this.getDatasets()
  }

  componentDidMount() {
    this.initial()
  }

  renderAddButton() {
    return (
      <Button
        type="primary"
        ghost
        onClick={() => this.setState({ modalVisible: true })}
      >
        <GenerateReportIcon />
        {/* <img src={IconReport} alt="icon" /> */}
        Generate REPORT
      </Button>
    )
  }
  renderModal() {
    const {
      hasDatasets,
      datasets,
      modalVisible,
      loading,
      // selectedBloodTest,
      params
    } = this.state

    return (
      hasDatasets && (
        <Modal
          title="Select datasets"
          visible={modalVisible}
          cancelText="Cancel"
          okText="OK"
          onOk={this.createReport}
          onCancel={() => this.setState({ modalVisible: false })}
          okButtonProps={{ loading }}
        >
          {datasets.map((item, index) => (
            <div key={index}>
              <h2>{item.title}</h2>
              <RadioGroup
                onChange={(e) => {
                  params[item.field] = e.target.value
                  this.setState({ params })
                }}
                value={params[item.field]}
              >
                {item.list.map((dataset, index) => (
                  <Radio
                    key={index}
                    value={dataset.id}
                    className="blood-test-item"
                  >
                    {moment(dataset.test_date.substr(0, 10)).format(
                      'MMM DD,YYYY'
                    )}
                  </Radio>
                ))}
              </RadioGroup>
              <Button
                type="primary"
                ghost
                onClick={() => this.addDataset(item)}
              >
                Add New Dataset
              </Button>
            </div>
          ))}
        </Modal>
      )
    )
  }

  render() {
    const { reports, person, opacity } = this.state
    return (
      <>
        {reports.length > 0 ? (
          <div className="phs-section com-report" style={{ opacity }}>
            <div className="phs-section-header">
              <h2>
                {person &&
                  `${(person.profile && person.profile.nickname) ||
                    person.first_name}'s`}{' '}
                Generated Reports
              </h2>
              {this.renderAddButton()}
            </div>
            <div className="">
              <DataTable
                className="com-report"
                columns={this.columns}
                dataSource={reports}
                onClickRow={(record) => this.previewReport(record.id)}
                hidePagination={true}
              />
            </div>
          </div>
        ) : (
          <div>
            <Empty
              className="phs-empty"
              image={IconArchive}
              description={
                <span className="empty-description">No Report Available</span>
              }
            >
              {this.renderAddButton()}
            </Empty>
          </div>
        )}
        {this.renderModal()}
      </>
    )
  }
}

export default CreportList
