import React, { Component } from 'react'
import HealthScoreContainer from '../../components/healthScore'
import LockStatus from '../../components/UI/lockStatus'
import { userType } from '../../utils/constant'
import { mobileHealthScore } from './styles/healthScore.sass'

class HealthScore extends Component {
  static propTypes = {}

  render() {
    const { person } = this.props
    if (!person) return null
    const isPartner = person.profile.account_type === userType.Partners
    return isPartner ? (
      <LockStatus />
    ) : (
      <div className={mobileHealthScore}>
        <HealthScoreContainer mobile={true} {...this.props} loginRole="User" />
      </div>
    )
  }
}

export default HealthScore
