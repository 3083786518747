import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { message } from 'antd'
import { reOrderMetric, reOrderCategory } from '../../../api/metric'
import { getDbFields } from '../../../components/business/dataset/common'

export default (WrappedComponent) =>
  class CategoriesWrapper extends React.Component {
    static propTypes = {
      type: PropTypes.string,
      path: PropTypes.string,
      biomarkersFiled: PropTypes.string,
      fixNumber: PropTypes.number,
      getCategories: PropTypes.func,
      createCategory: PropTypes.func,
      createMetric: PropTypes.func,
      delMetric: PropTypes.func,
      delCategory: PropTypes.func,
      editMetric: PropTypes.func,
      updateSubMenu: PropTypes.func,
      slideMenu: PropTypes.object,
      group: PropTypes.string
    }

    state = {
      modalTitle: '',
      visible: false,
      formData: null,
      valueForm: null,
      loading: false,
      categories: [],
      submitType: '',
      pageLoading: false
    }

    submit = async (params) => {
      const {
        submitType,
        categoryId,
        categories,
        parentCategoryId
      } = this.state
      const {
        type,
        path,
        group,
        createCategory,
        editCategory,
        createMetric,
        biomarkersFiled,
        slideMenu,
        updateSubMenu
      } = this.props
      const dbField = getDbFields(path)
      let result, index, biomarkers, category, sequence
      try {
        switch (submitType) {
          case 'add category':
            sequence = categories.length
              ? Math.max(...categories.map((item) => item.sequence)) + 1
              : 0
            result = await createCategory({ ...params, sequence }, type, group)
            categories.push(result)
            if (_.get(slideMenu, `groups.${group}`)) {
              slideMenu.groups[group].categories = categories.map(
                ({ name, id, group }) => ({
                  name,
                  id,
                  group,
                  subcategory_ids: []
                })
              )
              updateSubMenu(slideMenu.groups)
            }
            break
          case 'add sub-category':
            params.parent_category_id = parentCategoryId
            category = categories.find(
              (item) => item.id === params.parent_category_id
            )
            sequence =
              (category.child_categories &&
                Math.max(
                  ...category.child_categories.map((item) => item.sequence)
                ) + 1) ||
              0
            result = await createCategory({ ...params, sequence }, type, group)
            const targetIndex = categories.findIndex(
              (item) => item.id === parentCategoryId
            )
            categories[targetIndex].child_categories =
              categories[targetIndex].child_categories || []
            categories[targetIndex].child_categories.push(result)
            break
          case 'edit category':
            result = await editCategory(params, categoryId, type)
            index = categories.findIndex((item) => item.id === categoryId)
            categories[index].name = result.name
            categories[index].description = result.description
            break
          case 'edit sub-category':
            result = await editCategory(params, categoryId, type)
            category = categories.find(
              (item) => item.id === result.parent_category_id
            )
            category = category.child_categories.find(
              (item) => item.id === categoryId
            )
            category.name = result.name
            category.description = result.description
            break
          case 'add metric':
            index = categories.findIndex((item) => item.id === categoryId)
            category = categories.filter((item) => item.id === categoryId)[0]
            biomarkers = category[biomarkersFiled]
            sequence = biomarkers.length
              ? Math.max(...biomarkers.map((item) => item.sequence)) + 1
              : 0
            result = await createMetric(
              { ...params, sequence },
              categoryId,
              type,
              dbField.type
            )
            biomarkers ? biomarkers.push(result) : (biomarkers = [result])
            category[biomarkersFiled] = biomarkers
            categories.splice(index, 1, category)
            break
          case 'add metric for sub-category':
            const rootIndex = categories.findIndex((item) =>
              item.child_categories.find(
                (child_categories) => child_categories.id === categoryId
              )
            )
            category = categories[rootIndex]
            index = category.child_categories.findIndex(
              (item) => item.id === categoryId
            )
            const targetCategory = category.child_categories[index]
            biomarkers = targetCategory[biomarkersFiled]
            sequence = biomarkers.length
              ? Math.max(...biomarkers.map((item) => item.sequence)) + 1
              : 0
            result = await createMetric(
              { ...params, sequence },
              categoryId,
              type,
              dbField.type
            )
            biomarkers ? biomarkers.push(result) : (biomarkers = [result])
            category.child_categories[index][biomarkersFiled] = biomarkers
            categories.splice(rootIndex, 1, category)
            break
          default:
            break
        }
        this.setState({ categories, visible: false })
      } catch (err) {
        console.error(err)
        message.error(err.message)
      }
    }

    deleteCategory = async (category) => {
      const { delCategory, type } = this.props
      let { categories } = this.state
      try {
        await delCategory(category.id, type)
        const length = categories.length
        categories = categories.filter((item) => item.id !== category.id)
        if (length === categories.length) {
          categories.map((item) => {
            item.child_categories = item.child_categories.filter(
              (item) => item.id !== category.id
            )
            return item
          })
        }
        // const index = categories.findIndex(item => item.id === category.id)

        // categories.splice(index, 1)
        this.setState({ categories })
      } catch (err) {
        console.error(err)
      }
    }

    deleteBiomarker = async (biomarker, targetCategory) => {
      const { delMetric, biomarkersFiled, type } = this.props
      const { categories } = this.state
      try {
        await delMetric(biomarker.id, type)
        let index
        index = categories.findIndex((item) => item.id === targetCategory.id)
        if (index === -1) {
          const rootIndex = categories.findIndex((item) =>
            item.child_categories.find((item) => item.id === targetCategory.id)
          )
          const rootCategory = categories[rootIndex]

          index = rootCategory.child_categories.findIndex(
            (item) => item.id === targetCategory.id
          )
          let metrics = rootCategory.child_categories[index][biomarkersFiled]
          metrics = metrics.filter((item) => item.id !== biomarker.id)
          rootCategory.child_categories[index][biomarkersFiled] = metrics
          // rootCategory.child_categories.splice(index, 1, category[index])
          categories.splice(rootIndex, 1, rootCategory)
        } else {
          targetCategory[biomarkersFiled] = targetCategory[
            biomarkersFiled
          ].filter((item) => item.id !== biomarker.id)
          categories.splice(index, 1, targetCategory)
        }

        this.setState({ categories })
      } catch (err) {
        console.error(err)
      }
    }

    findCategory = (categoryId, rootCategoryId) => {
      const { categories } = this.state
      let result
      if (rootCategoryId) {
        const rooCategory = categories.find(
          (item) => item.id === rootCategoryId
        )
        result = rooCategory.child_categories.find(
          (item) => item.id === categoryId
        )
      } else {
        result = categories.find((item) => item.id === categoryId)
      }
      return result
    }

    getReOrderParams = (
      sourceOrder,
      destinationOrder,
      isSameOrigin,
      dragObj
    ) => {
      const { dragOrigin, source, destination } = dragObj
      let params = []
      if (isSameOrigin) {
        params = sourceOrder.map(({ id, sequence }, index) => {
          if (dragOrigin.id === id) {
            return { id, sequence: destination.order }
          } else if (
            source.order < destination.order &&
            sequence > source.order &&
            sequence <= destination.order
          ) {
            return { id, sequence: --sequence }
          } else if (
            source.order > destination.order &&
            sequence < source.order &&
            sequence >= destination.order
          ) {
            return { id, sequence: ++sequence }
          }
          return { id, sequence }
        })
      } else {
        params = sourceOrder.map(({ id, sequence }) => {
          if (sequence > source.order) {
            return { id, sequence: --sequence }
          }
          return { id, sequence }
        })
        params = params.concat(
          destinationOrder.map(({ id, sequence }) => {
            if (sequence > destination.order) {
              return { id, sequence: ++sequence }
            }
            return { id, sequence }
          })
        )
      }

      return params
    }

    onDragMetricHandle = async (result) => {
      const {
        biomarkersFiled,
        getCategories,
        editMetric,
        type,
        categoryGroups
      } = this.props
      const { draggableId, destination, source } = result
      const sourceCategory = this.findCategory(
        source.categoryId,
        source.rootCategoryId
      )
      const destinationCategory = this.findCategory(
        destination.categoryId,
        destination.rootCategoryId
      )
      const dragMetric = sourceCategory[biomarkersFiled].find(
        (item) => item.id === Number(draggableId.split('-')[1])
      )
      let sourceOrder = sourceCategory[biomarkersFiled],
        destinationOrder = destinationCategory[biomarkersFiled]
      if (sourceOrder[0] && !sourceOrder[0].sequence) {
        sourceOrder = sourceOrder.map((item, index) => {
          return { ...item, sequence: index }
        })
      }
      if (destinationOrder[0] && !destinationOrder[0].sequence) {
        destinationOrder = destinationOrder.map((item, index) => {
          return { ...item, sequence: index }
        })
      }

      const params = this.getReOrderParams(
        sourceOrder,
        destinationOrder,
        sourceOrder.id === destinationOrder.id,
        { dragOrigin: dragMetric, ...result }
      )
      try {
        if (sourceCategory.id !== destinationCategory.id) {
          const dbField = getDbFields(type)
          await editMetric(
            {
              category_id: destinationCategory.id,
              sequence: destination.order
            },
            dragMetric.id,
            type,
            dbField.type
          )
        }
        await reOrderMetric(params, type)
        const ids =
          categoryGroups && categoryGroups.categories.map((item) => item.id)
        const categories = await getCategories(type, ids)
        this.setState({ categories })
      } catch (err) {
        console.error(err)
      }
    }

    onDragCategoryHandle = async (result) => {
      const { draggableId, destination, source } = result
      const sourceCategory = this.findCategory(source.categoryId)
      const destinationCategory = this.findCategory(destination.categoryId)
      const dragSubCategory = sourceCategory.child_categories.find(
        (item) => item.id === Number(draggableId.split('-')[1])
      )
      let sourceOrder = sourceCategory.child_categories,
        destinationOrder = destinationCategory.child_categories
      if (!sourceOrder[0].sequence) {
        sourceOrder = sourceOrder.map(({ id }, index) => {
          return { id, sequence: index }
        })
      }

      if (!destinationOrder[0].sequence) {
        destinationOrder = destinationOrder.map(({ id }, index) => {
          return { id, sequence: index }
        })
      }
      const params = this.getReOrderParams(
        sourceOrder,
        destinationOrder,
        sourceOrder.id === destinationOrder.id,
        { dragOrigin: dragSubCategory, ...result }
      )

      try {
        if (sourceCategory.id !== destinationCategory.id) {
          await this.props.editCategory(
            {
              parent_category_id: destinationCategory.id,
              sequence: destination.order
            },
            dragSubCategory.id,
            this.props.type
          )
        }
        await this.reOrderRootCategory(params)
      } catch (err) {
        console.error(err)
      }
    }

    reOrderRootCategory = async (params) => {
      const { getCategories, type, categoryGroups } = this.props
      await reOrderCategory(params, type)
      const ids =
        categoryGroups && categoryGroups.categories.map((item) => item.id)
      const categories = await getCategories(type, ids)
      this.setState({ categories })
    }

    onDragEndHandle = async (result) => {
      const { destination, source, type } = result
      if (
        destination.droppableId === source.droppableId &&
        destination.order === source.order
      )
        return
      if (type === 'metric') {
        return await this.onDragMetricHandle(result)
      } else {
        // type === "category"
        return await this.onDragCategoryHandle(result)
      }
    }

    initial = async () => {
      const { getCategories, type, categoryGroups } = this.props
      try {
        this.setState({ pageLoading: true })
        const ids =
          categoryGroups && categoryGroups.categories.map((item) => item.id)
        const categories = await getCategories(type, ids)
        this.setState({ categories, pageLoading: false })
      } catch (err) {
        console.error(err)
        message.error(err.message)
      }
    }

    componentDidUpdate(prevProps) {
      const { type } = this.props
      if (type !== prevProps.type) {
        this.initial()
      }
    }

    componentDidMount() {
      this.initial()
    }

    render() {
      const childProps = {
        ...this.state,
        setPropsState: (state) => this.setState(state),
        submit: this.submit,
        deleteCategory: this.deleteCategory,
        deleteBiomarker: this.deleteBiomarker,
        reOrderRootCategory: this.reOrderRootCategory,
        onDragEndHandle: this.onDragEndHandle
      }

      return <WrappedComponent {...childProps} {...this.props} />
    }
  }
