export const getTemplateForm = (categories, subCategories) => [
  {
    type: 'select',
    label: 'Category',
    dbField: 'category',
    options: [
      ...categories,
      {
        label: 'Add new category',
        value: 'Add new category'
      }
    ],
    placeholder: 'Select category',
    rules: [{ required: true, message: 'Please select category' }]
  },
  {
    type: 'input',
    label: 'new category',
    dbField: 'new_category',
    hideField: true,
    unless: {
      key: 'category',
      value: 'Add new category'
    },
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'select',
    label: 'sub category',
    dbField: 'subcategory',
    options: [
      ...subCategories,
      {
        label: 'Add new sub category',
        value: 'Add new sub category'
      }
    ],
    placeholder: '',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'new sub category',
    dbField: 'new_sub_category',
    hideField: true,
    placeholder: 'Select sub category',
    unless: {
      key: 'subcategory',
      value: 'Add new sub category'
    },
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'title',
    dbField: 'title',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'textarea',
    label: 'text',
    dbField: 'text',
    extraAttr: {
      autoSize: { minRows: 5 }
    },
    rules: [{ required: true, message: 'This field is required' }]
  }
]
