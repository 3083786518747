import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CaretDownOutlined } from '@ant-design/icons'
import { getMultiSelectOptions } from '../../../../utils/common'
import { select } from './multi-select.sass'

class MultiSelect extends Component {
  static propTypes = {
    biomarker: PropTypes.object,
    savePersonalValue: PropTypes.func
  }

  state = {
    loading: false,
    extendStatus: 'close'
  }

  saveValue = async (value) => {
    const { biomarker, savePersonalValue, datasetId, type } = this.props
    const { person_value: personValue } = biomarker
    const activeIndex =
      personValue && personValue.value && Number(personValue.value)
    if (activeIndex === value) return
    const params = {
      id: biomarker.id
    }
    if (type === 'blood-test') {
      params.prefer_conventional = true
      params.prefer_functional = false
    } else {
      params.on = true
    }
    params.value = value
    try {
      this.setState({ loading: true, savingIndex: value })
      savePersonalValue(params, datasetId)
      this.setState({ loading: false, savingIndex: -1, extendStatus: 'close' })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false, savingIndex: -1 })
    }
  }

  // componentDidMount() {
  //   const { biomarker } = this.props
  //   if (biomarker.compute) {
  //     console.log('auto calculate', biomarker)
  //   }
  // }

  render() {
    const { extendStatus } = this.state
    const { biomarker, ageScopes, person, type } = this.props
    const {
      person_value,
      personal_data,
      ranges,
      conventional_ranges
      // functional_ranges // hide functional on sprint23
    } = biomarker || {}
    const optionsOrigin =
      type === 'blood-test'
        ? [].concat(conventional_ranges || []) //.concat(functional_ranges || []) // hide functional on sprint23
        : ranges
    const options = getMultiSelectOptions(
      optionsOrigin,
      person && person.profile,
      ageScopes
    )
    const activeItem =
      (person_value && person_value.value) ||
      (personal_data && personal_data.value)
    return (
      <div
        className={`${select} ${
          biomarker.compute && !biomarker.allow_manual_update ? 'disabled' : ''
        }`}
      >
        <div
          className="list-container"
          onMouseLeave={() => {
            this.setState({ extendStatus: 'close' })
          }}
        >
          <div
            className="current-value"
            onClick={() => {
              if (biomarker.compute && !biomarker.allow_manual_update) return
              this.setState({
                extendStatus: extendStatus === 'open' ? 'close' : 'open'
              })
            }}
          >
            {activeItem}
            <CaretDownOutlined />
          </div>
          <ul className={`options-list ${extendStatus}`}>
            {options &&
              options.map((item, index) => (
                <li
                  key={index}
                  className={`option-item ${activeItem === item && 'active'}`}
                  onClick={() => this.saveValue(item)}
                >
                  {item}
                </li>
              ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default MultiSelect
