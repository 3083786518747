import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import TodayTag from '../../../components/UI/todayTag'
import DataTable from '../../../components/UI/table'
import PageLoading from '../../../components/UI/status/loading'
import {
  getCircuitTrainingSessions,
  exportSessions
} from '../../../api/eventRegistration'
import moment from 'moment'
import _ from 'lodash'

export const Container = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;
    .title {
      font-weight: 500;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #323f4a;
    }
    .action {
      display: flex;
      align-items: center;
      button {
        height: 39px;
        width: 131px;
      }
    }
  }
  .page-content {
    padding: 24px 0;
    .description {
      letter-spacing: 0.3px;
      font-size: 18px;
      font-weight: 700;
      color: #383e48;
      p:first-child {
        margin-bottom: 4px;
      }
      .time {
        font-size: 14px;
        font-weight: 500;
        color: #5e687d;
      }
    }
    .ant-table-thead > tr > th {
      font-size: 12px;
      color: #5c626a;
      background: white;
      letter-spacing: 0.3px;
    }
    .ant-table-tbody > tr > td {
      font-size: 14px;
      color: #5e687d;
      vertical-align: top;
      letter-spacing: 0.3px;
      font-weight: 400;
    }
  }
`

const booleanRender = (value) => {
  return (
    <div
      style={{
        textAlign: 'center'
      }}
    >
      {value ? 'Yes' : 'No'}
    </div>
  )
}

const columns = [
  {
    title: 'TIME',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 160,
    render: (value) => {
      return moment(value).format('M/D/YY - h:mm A')
    },
    sorter: (left, right) => {
      return moment(left.created_at).unix() - moment(right.created_at).unix()
    }
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    width: 155,
    sorter: (a, b) => {
      return (
        a.name.toLowerCase().charCodeAt() - b.name.toLowerCase().charCodeAt()
      )
    }
  },
  {
    title: 'EMAIL ADDRESS',
    dataIndex: 'email',
    key: 'email',
    width: 180,
    sorter: (a, b) => {
      return (
        a.email.toLowerCase().charCodeAt() - b.email.toLowerCase().charCodeAt()
      )
    }
  },
  {
    title: 'ATTEND?',
    dataIndex: 'attend',
    key: 'attend',
    render: booleanRender
  },
  {
    title: 'BRING GUESTS?*',
    dataIndex: 'bring_guest',
    key: 'bring_guest',
    width: 133,
    render: booleanRender
  },
  {
    title: 'GUEST NAME',
    dataIndex: 'guest',
    key: 'guest',
    width: 150,
    render: (value, record) => {
      const guests = [1, 2, 3, 4]
        .map((e) => _.get(record, `guest_${e}`))
        .filter((e) => e)
      if (guests.length > 0) {
        return guests.map((e) => (
          <>
            {e}
            <br />
          </>
        ))
      } else {
        return '--'
      }
    }
  },
  {
    title: 'NOTE',
    dataIndex: 'supplement',
    key: 'note',
    render: (value) => {
      const result = _.trim(value)
      return <div style={{ whiteSpace: 'pre-line' }}>{result || '--'}</div>
    }
  }
]

export default function EventRegistration() {
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [exporting, setExporting] = useState(false)

  const exportData = async () => {
    setExporting(true)
    const report = await exportSessions()
    window.open(report.url)
    setExporting(false)
  }

  useEffect(() => {
    initial()
  }, [])

  return loading ? (
    <PageLoading />
  ) : (
    <Container>
      <div className="page-header">
        <div className="title">Event Registration</div>
        <div className="action">
          {/* <DateRange /> */}
          <TodayTag />
          <Button
            type="primary"
            loading={exporting}
            disabled={exporting}
            onClick={exportData}
          >
            EXPORT
          </Button>
        </div>
      </div>
      <div className="page-content">
        <div className="description">
          <p>Apeiron Life Community Hike</p>
          <p className="time">
            Thursday, 9/19, 5pm. <br />
            Windy Hill Preserve - meet at{' '}
            <a
              href="https://maps.apple.com/?address=525%20Portola%20Rd,%20Portola%20Valley,%20CA%20%2094028,%20United%20States&auid=202694686647524578&ll=37.375418,-122.223265&lsp=9902&q=Lower%20Windy%20Hill%20Parking%20Area&t=m"
              target="_blank"
              rel="noopener noreferrer"
            >
              parking lot off of Portola Road
            </a>
            . Planning for around a one hour hike with about 400 feet of
            elevation gain.
          </p>
        </div>
        <DataTable
          dataSource={dataSource}
          columns={columns}
          hidePagination={true}
        />
      </div>
    </Container>
  )

  async function initial() {
    const { list } = await getCircuitTrainingSessions()
    setDataSource(list)
    setLoading(false)
  }
}
