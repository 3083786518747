import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Empty } from 'antd'
import ClientContainerWrapper from './clientContainerWrapper'
import ClientCategories from './categories/client'
import FileManager from '../files/management'
import PageLoading from '../../UI/status/loading'
import { getTitleByType } from './common'
import { clientContainerStyle } from './container.sass'
import { loadUser } from '../../../utils/storage'

// client view dataset container: exclude blood test
class ClientContainer extends Component {
  static propTypes = {}

  componentDidMount() {
    window.addEventListener('scroll', this.orderScroll)
  }

  componentDidUpdate() {
    const {
      categories,
      setCurrentCategory,
      slideMenu: { currentCategory }
    } = this.props
    if (categories && categories[0] && !currentCategory) {
      setCurrentCategory(categories[0].name)
    }
  }

  orderScroll = (e) => {
    const {
      slideMenu: { currentCategory },
      setCurrentCategory
    } = this.props
    const elementList = e.target.querySelectorAll('div[name]')
    const viewHeight = window.innerHeight
    const scrollTop = document.scrollingElement.scrollTop
    let _currentCategory = ''
    for (let element of elementList) {
      if (viewHeight - element.offsetTop + scrollTop > 0) {
        const name = element.getAttribute('name')
        if (name.includes('category_')) {
          _currentCategory = name.replace('category_', '')
        }
      }
    }
    if (_currentCategory !== currentCategory) {
      setCurrentCategory(_currentCategory)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.orderScroll)
  }

  render() {
    const person = loadUser()
    const {
      dataset,
      type,
      dataLoading,
      category,
      categories,
      dbField
    } = this.props
    const title = getTitleByType(type)
    const selectCategory = category || (categories && categories[0].name)

    return (
      <div className={clientContainerStyle}>
        <h1 className="page-title">{title}</h1>
        {dataLoading ? (
          <PageLoading />
        ) : (
          <>
            {dbField && (
              <FileManager
                title="Assessment files"
                type={dbField.fileType || dbField.type}
                person={person}
                editable={false}
              />
            )}
            {dataset && dataset.snapshot.length > 0 ? (
              <ClientCategories
                {...this.props}
                selectCategory={selectCategory}
              />
            ) : (
              <Empty style={{ marginTop: 50 }} />
            )}
          </>
        )}
      </div>
    )
  }
}

export default ClientContainerWrapper(ClientContainer)
