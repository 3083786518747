import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setSubMenu } from '../../../redux/slideMenu/action'
import { LoadingOutlined } from '@ant-design/icons'
import AdminToolEditor from '../editor'
import { getMetricById, getCategories, editMetric } from '../../../api/metric' //'../../../api/bodyComps'
import { getSlideMenu } from '../../../api/dataset'
import { getDbFields } from '../../../components/business/dataset/common'

function MetricEditor(props) {
  const {
    history,
    match: {
      params: { path, id }
    },
    slideMenu: { groups },
    updateSubMenu
  } = props
  const [dbFields, setDbFields] = useState(null)

  async function initial() {
    const result = await getSlideMenu()
    updateSubMenu(result.groups)
  }
  useEffect(() => {
    initial()
  }, [])

  useEffect(() => {
    setDbFields(null)
    setDbFields(getDbFields(path))
  }, [path])

  function getIds() {
    let ids = []
    if (dbFields && groups) {
      ids = groups[dbFields.groupType || dbFields.type].categories.map(
        (item) => item.id
      )
    }
    return ids
  }
  const childProps = {
    id,
    type: path,
    categoryIdField: 'category_id',
    dbFields,
    metricsField: dbFields && dbFields.metricsField,
    turnTo: (id) => history.replace(`/${path}/${id}`),
    goBack: () => history.push(`/admin/${path}`),
    getMetricById: async (id) =>
      await getMetricById(id, null, dbFields.category_type),
    getCategories: () => getCategories(dbFields.category_type, getIds()),
    editMetric
  }
  return <>
    {groups && dbFields ? (
      <AdminToolEditor {...childProps} />
    ) : (
      <div className="page-loading page-loading-modal">
        <LoadingOutlined />
      </div>
    )}
  </>
}

export default connect(
  ({ slideMenu }) => ({ slideMenu }),
  (dispatch) => ({
    updateSubMenu: (subMenu) => dispatch(setSubMenu('groups', subMenu))
  })
)(MetricEditor)
