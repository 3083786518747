import React from 'react'
import moment from 'moment'

import { VictoryChart, VictoryAxis, VictoryLine, VictoryScatter } from 'victory'
import { axisStyles } from '../sections/aerobic'

export default function Polynomial(props) {
  const { series, seriesVersions, testDate, latestVO2 } = props
  const list = seriesVersions || [{ series, testDate }]
  let dataArr = []
  for (let i = 0; i < list.length; i++) {
    const data = handleData(list[i].series, list[i].testDate)
    dataArr.push(data)
  }

  dataArr = dataArr.reverse()

  if (dataArr[0]) {
    const l = dataArr.length
    dataArr[l - 1].color = '#2B4B8E'
    dataArr[l - 1].size = 5
    if (dataArr[l - 2]) {
      dataArr[l - 2].color = '#758CBB'
    }
  }

  const scatterData = dataArr.find((item) => item.size === 5)

  const axisX = [10, 20, 30, 40, 50, 60],
    axisY = [0, 2, 4, 6, 8, 10, 12, 14, 16]

  return (
    <div>
      <div className="tip-label right-align">
        {latestVO2 && <div>Vo2pk = {latestVO2} mg/kl/min</div>}
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-circle" style={{ background: item.color }} />
            {moment(item.testDate).format('M/D/YYYY')}
          </div>
        ))}
      </div>
      <div className="tip-label right-align" style={{ marginBottom: 0 }}>
        {dataArr.map((item, index) => (
          <div key={index}>
            <span className="tip-line" style={{ background: item.color }} />
            Timelime for {moment(item.testDate).format('M/D/YYYY')}
            <br />
            <span className="space" />
            {item.R2 && <>r2 = {Math.round(item.R2 * 1000) / 1000}</>}
          </div>
        ))}
      </div>
      <VictoryChart
        width={600}
        height={500}
        minDomain={{ x: 10, y: 0 }}
        maxDomain={{ x: 60, y: 16 }}
      >
        <VictoryAxis
          tickValues={axisX}
          label="OXYGEN UPTAKE (ML/KG/MIN)"
          style={axisStyles}
        />
        <VictoryAxis
          tickValues={axisY}
          dependentAxis
          style={axisStyles}
          label="LACTATE (MM)"
        />
        {dataArr
          .filter((item) => item.data.length > 0)
          .map((item, index) => {
            const { arrX, aa, m, color } = item
            return (
              <VictoryLine
                key={index}
                y={(d) => {
                  return getY(d.x, arrX, aa, m) || null
                }}
                style={{
                  data: {
                    stroke: color,
                    strokeWidth: 1.5
                  }
                }}
              />
            )
          })}

        {scatterData && (
          <VictoryScatter
            data={scatterData.data}
            style={{ data: { fill: scatterData.color } }}
            size={scatterData.size}
          />
        )}
      </VictoryChart>
    </div>
  )
}

function handleData(series, testDate) {
  const { lactate, vo2 } = series || {}

  let data = []
  for (let i = 0; i < lactate.length; i++) {
    if (lactate[i] && vo2[i]) {
      data.push({ x: vo2[i], y: lactate[i] })
    }
  }

  let arrX = [],
    arrY = []

  for (let i = 0; i < data.length; i++) {
    arrX.push(data[i].x)
    arrY.push(data[i].y)
  }

  const len = arrY.length
  const m = 4
  const a = new Array(arrX.length)
  const aa = PolyFit(arrX, arrY, len, a, m)

  const R2 = calculateR2(arrX, arrY, len, aa, m)

  return { data, testDate, R2, color: '#BCC2CE', arrX, aa, m }
}

export function PolyFit(x, y, n, a, m) {
  var i, j, k
  var z,
    p,
    c,
    g,
    q = 0,
    d1,
    d2
  var s = new Array(20)
  var t = new Array(20)
  var b = new Array(20)
  var dt = new Array(3)
  for (i = 0; i <= m - 1; i++) {
    a[i] = 0.0
  }
  if (m > n) {
    m = n
  }
  if (m > 20) {
    m = 20
  }
  z = 0.0
  for (i = 0; i <= n - 1; i++) {
    z = z + x[i] / (1.0 * n)
  }
  b[0] = 1.0
  d1 = 1.0 * n
  p = 0.0
  c = 0.0
  for (i = 0; i <= n - 1; i++) {
    p = p + (x[i] - z)
    c = c + y[i]
  }
  c = c / d1
  p = p / d1
  a[0] = c * b[0]
  if (m > 1) {
    t[1] = 1.0
    t[0] = -p
    d2 = 0.0
    c = 0.0
    g = 0.0
    for (i = 0; i <= n - 1; i++) {
      q = x[i] - z - p
      d2 = d2 + q * q
      c = c + y[i] * q
      g = g + (x[i] - z) * q * q
    }
    c = c / d2
    p = g / d2
    q = d2 / d1
    d1 = d2
    a[1] = c * t[1]
    a[0] = c * t[0] + a[0]
  }
  for (j = 2; j <= m - 1; j++) {
    s[j] = t[j - 1]
    s[j - 1] = -p * t[j - 1] + t[j - 2]
    if (j >= 3)
      for (k = j - 2; k >= 1; k--) {
        s[k] = -p * t[k] + t[k - 1] - q * b[k]
      }
    s[0] = -p * t[0] - q * b[0]
    d2 = 0.0
    c = 0.0
    g = 0.0
    for (i = 0; i <= n - 1; i++) {
      q = s[j]
      for (k = j - 1; k >= 0; k--) {
        q = q * (x[i] - z) + s[k]
      }
      d2 = d2 + q * q
      c = c + y[i] * q
      g = g + (x[i] - z) * q * q
    }
    c = c / d2
    p = g / d2
    q = d2 / d1
    d1 = d2
    a[j] = c * s[j]
    t[j] = s[j]
    for (k = j - 1; k >= 0; k--) {
      a[k] = c * s[k] + a[k]
      b[k] = t[k]
      t[k] = s[k]
    }
  }
  dt[0] = 0.0
  dt[1] = 0.0
  dt[2] = 0.0
  for (i = 0; i <= n - 1; i++) {
    q = a[m - 1]
    for (k = m - 2; k >= 0; k--) {
      q = a[k] + q * (x[i] - z)
    }
    p = q - y[i]
    if (Math.abs(p) > dt[2]) {
      dt[2] = Math.abs(p)
    }
    dt[0] = dt[0] + p * p
    dt[1] = dt[1] + Math.abs(p)
  }
  return a
}

export function getY(x, xx, a, m) {
  var y = 0
  var ave = average(xx)

  var l = 0
  for (var i = 0; i < m; i++) {
    l = a[0]
    if (i > 0) {
      y += a[i] * Math.pow(x - ave, i)
    }
  }
  return y + l
}

function average(x) {
  var ave = 0
  var sum = 0
  if (x !== null) {
    for (var i = 0; i < x.length; i++) {
      sum += x[i]
    }
    ave = sum / x.length
  }
  return ave
}

function calculateR2(arrX, arrY, len, aa, m) {
  var arrRes = []
  for (let i = 0; i < len; i++) {
    arrRes.push({ x: arrX[i], y: getY(arrX[i], arrX, aa, m) })
  }
  let sum_yavgy = 0,
    sum_yrely = 0
  for (let i = 0; i < arrY.length; i++) {
    sum_yrely += Math.pow(arrY[i] - arrRes[i].y, 2)
    sum_yavgy += Math.pow(arrY[i] - average(arrY), 2)
  }

  const R2 = sum_yavgy !== 0 && 1 - sum_yrely / sum_yavgy
  return R2 && Math.round(R2 * 1000) / 1000
}
