import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { message } from 'antd'
import { attachFile, attachLink } from '../../../api/microGoals'

import Attachments from '../../UI/attachment'

class GoalsAttach extends Component {
  static propTypes = {
    saveGoal: PropTypes.func,
    person: PropTypes.object,
    editGoal: PropTypes.object
  }

  state = {
    visible: false
  }

  saveLink = async (value) => {
    const { editGoal, person, addAttachSync } = this.props
    const result = await attachLink(editGoal.id, person.id, value)
    addAttachSync(result.attachment, editGoal)
  }

  uploadFile = async (file, callBack) => {
    const { editGoal, person, addAttachSync } = this.props
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ source, file })
      const result = await attachFile({
        goalId: editGoal.id,
        personId: person.id,
        files: [file],
        source,
        progressCallback: (percent) => {
          callBack(percent)
          if (percent === 100) {
            this.setState({ source: null })
          }
        }
      })
      addAttachSync(result.attachment, editGoal)
      return result
    } catch (err) {
      console.error(err)
      if (!axios.isCancel(err)) {
        message.error(err.message)
      }
    }
  }

  cancelUpload = () => {
    const { source } = this.state
    source && source.cancel()
    this.setState({ source: null })
  }

  prefixUpload = async () => {
    const { saveGoal, editGoal } = this.props
    try {
      if (!editGoal || !editGoal.id) {
        return await saveGoal(true)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  removeAttach = async (attach) => {
    let { removeAttach, editGoal } = this.props
    await removeAttach(attach, editGoal)
  }

  render() {
    return (
      <Attachments
        prefixUpload={this.prefixUpload}
        attachments={this.props.editGoal.attachments}
        removeAttach={this.removeAttach}
        cancelUpload={this.cancelUpload}
        uploadFile={this.uploadFile}
        saveLink={this.saveLink}
        targetId={this.props.editGoal.id}
        editAttachTitle={this.props.editAttachTitle}
        confirmObj={{
          title: 'Save Metric before attaching file?',
          description: 'Metrics must be saved before attaching a file or URL.'
        }}
      />
    )
  }
}

export default GoalsAttach
