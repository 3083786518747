import React, { useState, useEffect } from 'react'
import _ from 'lodash'
// import { CloseOutlined } from '@ant-design/icons'
import { Radio, Input } from 'antd'

export default function SingleChoices(props) {
  const {
    question: { choices },
    answer,
    update
  } = props

  const [answerObj, setAnswerObj] = useState({ value: null })

  useEffect(() => {
    if (answer && (!answerObj.value || answer.verify)) {
      setAnswerObj(answer.answer)
    }
  }, [answer])

  return (
    <div className="q-form-group">
      {choices.map((choice, index) => {
        const isChecked = answerObj.value === choice.title
        const verify = _.get(answer, 'verify.choices', [])[index]
        return (
          <div
            key={index}
            className={`q-form-item ${isChecked ? 'valid' : ''} ${
              verify ? 'invalid' : ''
            }`}
          >
            <Radio
              onClick={(e) => preUpdate({ value: choice.title })}
              checked={isChecked}
            >
              {choice.title}
            </Radio>

            {choice.allow_text_entry && (
              <>
                <Input
                  placeholder="Please type here"
                  className={verify && verify.textRequired ? 'invalid' : ''}
                  value={isChecked ? answerObj.text : ''}
                  onChange={(e) =>
                    preUpdate({ value: choice.title, text: e.target.value })
                  }
                />

                {verify && verify.textRequired && (
                  <p className="invalid">Please type this input</p>
                )}
              </>
            )}
          </div>
        )
      })}
      {answer.verify && answer.verify.required && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </div>
  )

  function preUpdate(answerObj) {
    const newAnswer = _.assignIn(answer, { answer: answerObj })
    setAnswerObj(answerObj)
    update(newAnswer, props.question)
  }
}
