import React, { Component } from 'react'

// type HexagonTextProps = {
//   x?: number
//   y?: number
//   color: string | ((y: any) => string)
//   text?: string
//   datum?: { x: any; y: any, amount?: any }
//   size?: number,
//   borderSize?: number
// }

class HexagonText extends Component {
  // <HexagonTextProps>
  render() {
    const { x, y, datum, color, text, size, borderSize } = this.props
    const height = (size || 60) + (borderSize || 0) * 2
    const width = (height * Math.sqrt(3)) / 2
    const amount =
      datum && (datum.amount !== undefined ? datum.amount : datum.y)
    return (
      <svg
        x={x ? x - width / 2 : 0}
        y={y ? y - height / 2 : 0}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
      >
        <g transform={`scale(${height / 60})`}>
          <path
            d="M26 2.62268e-06L51.9808 15L51.9808 45L26 60L0.0192336 45L0.0192362 15L26 2.62268e-06Z"
            fill="#FFFFFF"
          />
        </g>
        <g
          transform={`scale(${(height - (borderSize || 0) * 2) /
            60}) translate(${borderSize}, ${borderSize})`}
        >
          <path
            d="M26 2.62268e-06L51.9808 15L51.9808 45L26 60L0.0192336 45L0.0192362 15L26 2.62268e-06Z"
            fill={
              typeof color === 'function' ? color(amount) : color || '#D2D2D2'
            }
          />
          <text
            textAnchor="middle"
            alignmentBaseline="middle"
            dy={30 + 2}
            dx={26}
            fontSize={18}
            fontFamily="Lato"
            fontWeight="bold"
            fill="white"
          >
            {text || amount}
          </text>
        </g>
      </svg>
    )
  }
}

export default HexagonText
