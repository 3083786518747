import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PopUpModal from './popUp'
import MobilePopUpModal from './mobilePopUp'
import { getScoreDetail } from '../../../../api/nutrition'
import PageLoading from '../../../../components/UI/status/loading'
import whatIcon from '../../../../asserts/icon/nutrition-score/what.png'
import whyIcon from '../../../../asserts/icon/nutrition-score/why.png'
import howIcon from '../../../../asserts/icon/nutrition-score/how.png'
import GeneralChart from '../generalChart'

class ScoreDetail extends Component {
  static propTypes = {
    questionId: PropTypes.any,
    questionOriginalId: PropTypes.string,
    person: PropTypes.object,
    device: PropTypes.string,
    hash: PropTypes.string,
    sectionVersion: PropTypes.number
  }

  state = {
    loading: true,
    detail: null
  }

  getCurrentStatus = (details) => {
    const currentRecord = details[0] || {}
    let status = 'optimal'
    switch (currentRecord.score) {
      case 1:
        status = 'at risk'
        break
      case 2:
        status = 'concern'
        break
      default:
        break
    }
    return <div className={status}>{status.toUpperCase()}</div>
  }

  initial = async () => {
    const {
      questionId,
      person,
      hash,
      sectionVersion,
      questionOriginalId
    } = this.props

    const detail = await getScoreDetail({
      personId: person.id,
      questionId,
      questionOriginalId,
      hash,
      sectionVersion
    })

    this.setState({ detail: detail, loading: false })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { loading, detail } = this.state
    const isMobile = this.props.device === 'mobile'
    const PopUpContainer = isMobile ? MobilePopUpModal : PopUpModal

    return (
      <PopUpContainer onCancel={this.props.onCancel}>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <GeneralChart
              {...this.state.detail}
              showSelectTag={true}
              className="generalChart"
            />
            <div className="info-container">
              <ul
                className={`info-list ${
                  detail.icons && detail.icons.length ? 'has-icon' : ''
                }`}
              >
                {detail.what && (
                  <li>
                    <div>
                      <img src={whatIcon} alt="what" />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: detail.what
                      }}
                    />
                  </li>
                )}
                {detail.why && (
                  <li>
                    <div>
                      <img src={whyIcon} alt="why" />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: detail.why
                      }}
                    />
                  </li>
                )}
                {detail.how && (
                  <li>
                    <div>
                      <img src={howIcon} alt="how" />
                    </div>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: detail.how
                      }}
                    />
                  </li>
                )}
              </ul>
              {detail.icons && (
                <div className="icons">
                  <div>
                    {detail.icons.map((icon, index) => {
                      return (
                        <div key={index}>
                          {icon.path && (
                            <img
                              src={require(`../../../../asserts/icon/nutrition-score/${icon.path}`)}
                              alt="icon"
                            />
                          )}
                          <div>{icon.description}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </PopUpContainer>
    )
  }
}

export default ScoreDetail
