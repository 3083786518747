import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

export default function PageLoading(props) {
  return (
    <div className="page-loading" style={props.style || {}}>
      <LoadingOutlined />
    </div>
  )
}
