import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import PHSHeader from '../../components/UI/header'
import NoMatch from '../noMatch'
import Metrics from './metrics'
import BloodTest from './bloodTest'
import { admin } from './index.sass'

const headerTabs = [
  {
    id: 'CLIENTS',
    title: 'MY CLIENTS',
    href: '/client'
  }
]
const tab = [
  {
    title: 'Vitals',
    href: '/vitals',
    id: 'vitals'
  },
  {
    title: 'Body Composition',
    href: '/body-comp',
    id: 'body_comp'
  },
  {
    title: 'Bone Density',
    href: '/bone-density',
    id: 'bone_density'
  },
  {
    title: 'Movement Quality & Balance',
    href: '/movement-quality',
    id: 'movement_quality'
  },
  {
    title: 'Muscle Fitness',
    href: '/muscle-fitness',
    id: 'muscle_fitness'
  },
  {
    title: 'Aerobic Fitness',
    href: '/aerobic-fitness',
    id: 'aerobic_fitness'
  },
  {
    title: 'Cognitive Health',
    href: '/cognitive-health',
    id: 'cognitive_health'
  },
  {
    title: 'Blood Panel',
    href: '/blood-test',
    id: 'blood_test'
  }
]
class AdminTool extends Component {
  static propTypes = {}

  state = {
    selectedTab: 'body_com',
    navAlignState: 'left'
  }

  componentDidMount() {
    const { location } = this.props
    switch (location.pathname) {
      case '/admin/vitals':
        this.setState({ selectedTab: 'vitals' })
        break
      case '/admin/body-comp':
        this.setState({ selectedTab: 'body_comp' })
        break
      case '/admin/bone-density':
        this.setState({ selectedTab: 'bone_density' })
        break
      case '/admin/movement-quality':
        this.setState({ selectedTab: 'movement_quality' })
        break
      case '/admin/muscle-fitness':
        this.setState({
          selectedTab: 'muscle_fitness'
        })
        break
      case '/admin/aerobic-fitness':
        this.setState({ selectedTab: 'aerobic_fitness' })
        break
      case '/admin/cognitive-health':
        this.setState({
          selectedTab: 'cognitive_health',
          navAlignState: 'right'
        })
        break
      case '/admin/blood-test':
        this.setState({ selectedTab: 'blood_test', navAlignState: 'right' })
        break
      default:
        break
    }
    if (location.pathname.split('/').length > 3)
      this.setState({ selectedTab: null })
  }

  changeTabs = (tab) => {
    const { history, match } = this.props
    this.setState({ selectedTab: tab.id })
    history.push(`${match.url}${tab.href}`)
  }

  render() {
    const { selectedTab, navAlignState } = this.state
    const { match, history } = this.props
    return (
      <div className={admin}>
        <PHSHeader tabs={headerTabs} history={history} />
        <div className="admin-container">
          {selectedTab && (
            <div className="admin-tabs">
              {/* {navAlignState === 'right' && (
                <div
                  className="icon-container-left"
                  onClick={() => this.setState({ navAlignState: 'left' })}
                >
                  <Icon type="caret-left" />
                </div>
              )} */}
              <div
                className="admin-tabs-container"
                style={navAlignState === 'left' ? { left: 0 } : { right: 0 }}
              >
                {tab.map((item, index) => (
                  <span
                    key={index}
                    className={selectedTab === item.id ? 'active' : ''}
                    onClick={() => this.changeTabs(item)}
                  >
                    {item.title}
                  </span>
                ))}
              </div>
            </div>
          )}
          <Switch>
            <Route exact path={match.url} component={Metrics} />
            <Route path={`${match.url}/blood-test`} component={BloodTest} />
            <Route path={`${match.url}/:path`} component={Metrics} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default AdminTool
