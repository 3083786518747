import React from 'react'
import { Modal, Button } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 0.3px;
  h1 {
    font-size: 36px;
    margin-top: 12px;
  }
  div {
    padding: 24px;
    font-size: 20px;
    font-weight: 300;
  }
  button {
    width: 98%;
    height: 48px;
    margin: 10px;
  }
`

export default function SendSuccessModal(props) {
  return (
    <Modal
      open={true}
      width={380}
      onCancel={props.ok}
      closable={false}
      footer={null}
      centered={true}
    >
      <Container>
        <h1>Success</h1>
        <CheckCircleOutlined style={{ fontSize: '82px', color: '#86AC44' }} />
        <div>Email Sent</div>
        <Button type="primary" onClick={props.ok}>
          OK
        </Button>
      </Container>
    </Modal>
  )
}
