import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Upload, Progress } from 'antd'
import IconUp from '../../../asserts/icon/move-up.svg'

export class ReportBanner extends Component {
  static propTypes = {
    person: PropTypes.object,
    addAttachment: PropTypes.func,
    image: PropTypes.object
  }

  state = {
    uploading: false,
    percent: 0
  }
  upload = (request) => {
    const { file } = request
    const { addAttachment } = this.props
    this.setState({ uploading: true })
    addAttachment('report.cover_photo', file, (percent) => {
      this.setState({ percent })
      if (percent === 100) {
        this.setState({ uploading: false })
      }
    })
  }

  render() {
    const { person, image, report } = this.props
    const { uploading, percent } = this.state
    const style = {}
    if (image) {
      style.background = `url(${image.oss_url})`
    }
    return (
      <div className={`report-banner ${image ? 'has-bg' : ''}`} style={style}>
        {uploading && (
          <Progress percent={percent} status={'active'} showInfo={false} />
        )}
        <h1>Comprehensive Report</h1>
        {person && (
          <p>
            Prepared for{' '}
            {(person.profile && person.profile.nickname) || person.first_name}
          </p>
        )}
        {report && (
          <Upload showUploadList={false} customRequest={this.upload}>
            <Button className="white" type="primary">
              <img src={IconUp} alt="upload" className="anticon" />
              UPLOAD IMAGE
            </Button>
          </Upload>
        )}
      </div>
    )
  }
}

export default ReportBanner
