import React from 'react'
import ReactDOM from 'react-dom'

export default class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.el.className = 'ipad-menu'
    this.el.addEventListener('touchmove', e => {
      if (e.target.nodeName !== 'LI') {
        e.preventDefault()
      }
    })
    this.el.addEventListener('click', e => {
      if (e.target.nodeName === 'A') {
        setTimeout(() => {
          this.props.setPropsState({
            show: false
          })
        })
      }
    })
  }

  componentDidMount() {
    document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}
