import styled from 'styled-components'

const SignPanelContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #e6e9f0;
  width: 1440px;
  height: 100vh;
  max-height: 900px;
  display: flex;
  align-items: center;

  .left,
  .right {
    display: inline-block;
    width: 50%;

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  .left {
    padding: 0 12%;
    vertical-align: middle;

    .title {
      color: #545f6d;
      font-size: 32px;
      letter-spacing: 0.3px;
    }

    .text {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #5c626a;
      opacity: 0.8;
      padding-right: 30%;
    }

    .ant-input-affix-wrapper {
      padding-top: 0;
      padding-bottom: 0;

      .ant-input-prefix {
        margin-right: 10px;
      }
    }

    .ant-legacy-form .ant-col-24 {
      padding: 0;
      margin: 0 -2px;
    }

    .submit-button {
      width: 100%;
      height: 50px;
      letter-spacing: 0.3px;
      font-size: 16px;
      text-transform: capitalize;
      margin-top: 24px;
      margin-bottom: 32px;

      & + p {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.3px;
        color: #5c626a;

        span {
          color: #2b4b8f;
        }
      }
    }

    .check-tos,
    .error-Message {
      display: inline-block;
      padding-top: 10px;
      cursor: pointer;
      font-size: 16px;

      &.error .ant-checkbox {
        border-color: #fc1d1d;
      }

      .ant-checkbox {
        border: 2px solid #a5abb6;
        border-radius: 2px;
      }

      .anticon {
        margin-right: 10px;
      }

      .ant-checkbox-wrapper {
        margin-right: 5px;
      }

      a {
        color: #2b4b8f;
        font-weight: bold;
      }
    }

    .error-Message {
      color: #fc1d1d;
    }

    .forget {
      font-size: 16px;
      color: #787d85;
      padding-bottom: 16px;

      span {
        color: #2b4b8f;
        cursor: pointer;
      }
    }

    .email-info {
      color: #787d85;
      font-size: 16px;

      a {
        color: #2b4b8f;
      }
    }

    .tos-pp {
      padding-top: 20px;
      font-family: Gilroy;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.3px;
      color: #787d85;

      a {
        color: #2b4b8f;
        font-weight: bold;
      }
    }

    .ant-form {
      margin: 0 auto;
      text-align: left;

      .ant-input-affix-wrapper {
        .ant-input {
          height: 43px;
        }
      }
    }
  }
`

const ResetPwsContainer = styled.div`
  max-width: 1440px;
  height: calc(100vh - 60px);
  position: relative;
  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .title {
      color: #545f6d;
      letter-spacing: 0.3px;
      font-size: 36px;
    }
    .description {
      color: #5c626a;
      letter-spacing: 0.3px;
      font-size: 14px;
      opacity: 0.8;
    }
    .short {
      width: 390px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    .resend {
      letter-spacing: 0.3px;
      font-size: 16px;
      color: #2b4b8f;
      cursor: pointer;
    }
    img {
      margin: 40px 0;
    }
    button {
      width: 300px;
      height: 50px;
      font-size: 16px;
    }
    .ant-form {
      width: 300px;
      margin: 0 auto;
      text-align: left;
      .ant-input-affix-wrapper {
        border: 1px solid #d9d9d9;
      }
      .ant-input {
        background: transparent !important;
        border-color: transparent;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 20px;
    color: #5b616b;
    text-align: center;
    font-size: 14px;
    opacity: 0.4;
    width: 100%;
  }
`

export { SignPanelContainer, ResetPwsContainer }
