import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
// import Highlighter from 'react-highlight-words'
import _ from 'lodash'
import PageLoading from '../../../components/UI/status/loading'
import { Input, Button, message } from 'antd'
import DataTable from '../../../components/UI/table'
import {
  getWeightingActivities,
  putWeightingActivity,
  exportActivities,
  resortWeightingActivities
} from '../../../api/hsdWeighting'
import { ArrowUpOutlined } from '@ant-design/icons'
import { NumericInput } from './numericInput'

const Container = styled.div`
  .page-title {
    margin-top: 20px;
    margin-bottom: 30px;
    .ant-btn {
      float: right;
      width: 151px;
      height: 49px;
    }
  }
  .ant-tabs-tab {
    text-transform: uppercase;
  }
  .table-container {
  }
  .ant-table-tbody > tr:hover {
    & > td .anticon {
      display: block;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 14px 16px;
    .up-icon {
      width: 20px;
    }
    .anticon {
      display: none;
      cursor: pointer;
    }
  }
  .ant-table-cell {
    color: #4c6072;
    font-size: 10px;
    text-align: center;
    background: white;
    strong {
      text-transform: uppercase;
      font-size: 14px;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
    .ant-input {
      text-align: center;
      font-weight: 600;
    }
    .ant-input-borderless {
      border: 1px solid #d9d9d9;
      border-color: transparent;
    }
    .ant-input-borderless:focus {
      border-color: #d9d9d9;
    }
    .ant-input-borderless::placeholder {
      color: #323f4a;
    }
    .ant-input-borderless:focus::placeholder {
      color: transparent;
    }
    .ant-table-column-title {
      font-size: 12px;
    }
  }
  .ant-table-cell:nth-child(2) {
    text-align: start;
  }
  .ant-table-cell:nth-child(1) {
    font-size: 19px;
  }
`

const colors = {
  Aerobic: {
    background: 'rgba(255, 107, 0, 0.2)',
    color: '#FF6B00'
  },
  Activity: {
    background: 'rgba(188, 202, 112, 0.2)',
    color: '#BCCA70'
  },
  Resistance: {
    background: 'rgba(88, 100, 107, 0.2)',
    color: '#58646B'
  },
  'Movement Quality': {
    background: 'rgba(255, 107, 0, 0.2)',
    color: '#BCCA70'
  }
}

const CategoryContainer = styled.span`
  background: ${(props) => _.get(colors, `${props.type}.background`)};
  color: ${(props) => _.get(colors, `${props.type}.color`)};
  border-radius: 6px;
  font-family: 'Gilroy';
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.8px;
  padding: 11px 10px;
  text-transform: uppercase;
`

export default function HSDWeighting(props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const searchInputRef = useRef(null)
  const [exporting, setExporting] = useState(false)

  useEffect(() => {
    const getActivities = async () => {
      const result = await getWeightingActivities()
      setData(result)
      setLoading(false)
    }
    getActivities()
  }, [])

  const exportData = async () => {
    setExporting(true)
    const report = await exportActivities()
    window.open(report.url)
    setExporting(false)
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
  }

  const handleReset = (clearFilters) => {
    clearFilters()
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputRef}
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      let filterValue = record[dataIndex]
      return filterValue
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase())
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select())
      }
    }
  })

  const inputSorter = (key) => {
    return {
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (
          _.get(a, ['hsd_weighting', key], 0) -
          _.get(b, ['hsd_weighting', key], 0)
        )
      }
    }
  }

  const columns = [
    {
      title: '',
      width: 50,
      fixed: 'left',
      render: (text, record, index) => {
        return index ? (
          <div className="up-icon">
            <ArrowUpOutlined onClick={() => moveToTop(record, index)} />
          </div>
        ) : null
      }
    },
    {
      title: 'ACTIVITY TYPE',
      key: 'activity_name',
      fixed: 'left',
      ...getColumnSearchProps('activity_name'),
      dataIndex: 'activity_name',
      width: 250,
      render: (text) => <strong>{text}</strong>,
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return (
          a.activity_name.toLowerCase().charCodeAt() -
          b.activity_name.toLowerCase().charCodeAt()
        )
      }
    },
    {
      title: 'CATEGORY',
      key: 'classification',
      width: 201,
      render: (type) => (
        <CategoryContainer type={type}>{type}</CategoryContainer>
      ),
      dataIndex: 'classification',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => {
        return a.classification.length - b.classification.length
      }
    },
    {
      title: 'CARDIOMETABOLIC',
      key: 'cardiometabolic',
      width: 155,
      dataIndex: ['hsd_weighting', 'cardiometabolic'],
      ...inputSorter('cardiometabolic')
    },
    {
      title: 'AEROBIC',
      key: 'aerobic',
      width: 95,
      dataIndex: ['hsd_weighting', 'aerobic'],
      ...inputSorter('aerobic')
    },
    {
      title: 'BONE',
      key: 'bone',
      width: 130,
      dataIndex: ['hsd_weighting', 'bone'],
      ...inputSorter('bone')
    },
    {
      title: 'BODY COMPOSITION',
      key: 'body_composition',
      width: 163,
      dataIndex: ['hsd_weighting', 'body_composition'],
      ...inputSorter('body_composition')
    },
    {
      title: 'MUSCLE FITNESS',
      key: 'muscle_fitness',
      width: 163,
      dataIndex: ['hsd_weighting', 'muscle_fitness'],
      ...inputSorter('muscle_fitness')
    },
    {
      title: 'MOVEMENT QUALITY',
      key: 'movement_quality',
      width: 163,
      dataIndex: ['hsd_weighting', 'movement_quality'],
      ...inputSorter('movement_quality')
    },
    {
      title: 'BALANCE',
      key: 'balance',
      width: 130,
      dataIndex: ['hsd_weighting', 'balance'],
      ...inputSorter('balance')
    },
    {
      title: 'COGNITIVE HEALTH',
      key: 'cognitive_health',
      width: 163,
      dataIndex: ['hsd_weighting', 'cognitive_health'],
      ...inputSorter('cognitive_health')
    }
  ].map((item) => {
    if (!item.render) {
      item.render = (text, record, index) => (
        <NumericInput
          bordered={false}
          placeholder={'N/A'}
          defaultValue={text}
          onPressEnter={(e) => {
            e.target.blur()
          }}
          onBlur={(e) => {
            inputDone(e, record, item.dataIndex)
          }}
        />
      )
    }
    return item
  })

  const getValueBy = (type, input, record, dataIndex) => {
    if (dataIndex.includes(type)) {
      return input
    } else {
      return _.get(record, ['hsd_weighting', type], null)
    }
  }

  const inputDone = async (e, record, dataIndex) => {
    let { value: inputValue } = e.target
    if (
      inputValue === `${_.get(record, dataIndex, null)}` ||
      (inputValue.length === 0 && _.isNil(_.get(record, dataIndex)))
    ) {
      return
    }
    if (inputValue === '') {
      inputValue = null
    }

    const hsd_weighting = {}
    const types = [
      'bone',
      'aerobic',
      'balance',
      'muscle_fitness',
      'cardiometabolic',
      'body_composition',
      'cognitive_health',
      'movement_quality'
    ]
    types.forEach((key) => {
      hsd_weighting[key] = getValueBy(key, inputValue, record, dataIndex)
    })
    const params = {
      activity_name: record.activity_name,
      hsd_weighting: hsd_weighting
    }
    try {
      await putWeightingActivity(params)
      let newData = data.concat([])
      const shouldReplaceIndex = _.findIndex(newData, [
        'activity_name',
        record.activity_name
      ])
      newData[shouldReplaceIndex].hsd_weighting = params.hsd_weighting
      setData(newData)
    } catch (error) {
      let newData = data.concat([])
      setData(newData)
    }
  }

  function moveToTop(record, index) {
    let newData = data.concat([])
    let cached = data.concat([])
    newData.splice(index, 1)
    newData.unshift(record)
    setData(newData)
    return setTimeout(() => {
      resort(newData, cached)
    })
  }

  async function resort(newData, cached) {
    try {
      await resortWeightingActivities({
        activity_names: newData.map((e) => e.activity_name)
      })
    } catch (error) {
      // Revert if got error
      setData(cached)
      message.error(error.messgae)
      console.error(error)
    }
  }

  return (
    <Container>
      <div className="page-title">
        Health span domain weighting
        <Button
          type="primary"
          loading={exporting}
          disabled={exporting}
          onClick={exportData}
        >
          EXPORT
        </Button>
      </div>
      {loading ? (
        <PageLoading />
      ) : (
        <div className="table-container">
          <DataTable
            scroll={{
              x: 1400,
              y: window.screen.height - 500
            }}
            columns={columns}
            hidePagination={true}
            dataSource={data}
            rowKey={(record) => `${record.activity_name}`}
          />
        </div>
      )}
    </Container>
  )
}
