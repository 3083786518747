import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider, List, Card, Select, Typography } from 'antd'
import PageLoading from '../../UI/status/loading'
import AperionModalForm from '../../modalFrom/apeironModalForm'
import { getTemplateForm } from '../../../models/emailTemplate.form'
import { capitalize } from '../../../utils/common'
import { emailTemplateStyle } from './index.sass'
import {
  getEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
  deleteEmailTemplate
} from '../../../api/summaryEmail'

function AddIcon() {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.864 11.336H28.68V17.864H17.864V28.68H11.336V17.864H0.52V11.336H11.336V0.519997H17.864V11.336Z"
        fill="#52606C"
      />
    </svg>
  )
}

// const addNewCategory = {
//   label: 'Add new category',
//   value: 'Add new category'
// }

class EmailTemplateContainer extends Component {
  static propTypes = {}

  state = {
    showForm: false,
    loading: true,
    templates: [],
    filterBy: 'All',
    selectedTemplate: null
  }

  cardHandle = (card) => {
    this.setState({
      showForm: true,
      selectedTemplate: card.content,
      currentValueForm: card.content
    })
  }

  fetchTemplate = async () => {
    this.setState({ loading: true })
    let templates = (await getEmailTemplate()) || []
    templates = templates.map((item, index) => {
      return {
        key: index + 1,
        content: item
      }
    })
    templates.unshift({ key: 0, content: null })
    const { filterByValues, categories } = this.processCategories(templates)
    this.setState({ templates, loading: false, filterByValues, categories })
  }

  submitTemplate = async (params) => {
    const { selectedTemplate } = this.state
    if (params.category === 'Add new category') {
      params.category = params.new_category
      delete params.new_category
    }
    if (params.subcategory === 'Add new sub category') {
      params.subcategory = params.new_sub_category
      delete params.new_sub_category
    }
    params.category = params.category.toLowerCase()
    params.subcategory = params.subcategory.toLowerCase()
    try {
      if (selectedTemplate) {
        await updateEmailTemplate(selectedTemplate.id, params)
      } else {
        await createEmailTemplate(params)
      }
      await this.fetchTemplate()
      this.setState({ showForm: false, filterBy: 'All' })
    } catch (err) {
      this.setState({ showForm: false })
    }
  }

  deleteTemplate = async () => {
    const { selectedTemplate, templates } = this.state
    if (selectedTemplate) {
      const result = await deleteEmailTemplate(selectedTemplate.id)
      if (result && result.done) {
        const index = templates.findIndex((item) => {
          if (item.content) {
            return item.content.id === selectedTemplate.id
          }
          return false
        })
        if (index > -1) {
          templates.splice(index, 1)
        }
      }
    }
    await this.fetchTemplate()
    this.setState({ showForm: false, templates })
  }

  processCategories = (templates) => {
    let categories = []
    if (templates) {
      categories = new Set()
      templates
        .filter((item) => item.content)
        .forEach((item) => {
          categories.add(item.content.category.toLowerCase())
        })
      return {
        filterByValues: ['All'].concat(Array.from(categories)),
        categories: Array.from(new Set(categories)).map((category) => {
          return {
            label: capitalize(category),
            value: category
          }
        })
      }
    }
    return { filterByValues: ['All'], categories }
  }

  filterBy = (value) => {
    this.setState({ filterBy: value })
  }

  componentDidMount() {
    this.fetchTemplate()
  }

  render() {
    const {
      showForm,
      loading,
      templates,
      filterByValues,
      filterBy,
      categories,
      currentValueForm,
      selectedTemplate
    } = this.state
    let filteredTemplates = templates
    if (filterBy && filterBy !== 'All') {
      filteredTemplates = templates.filter((item) => {
        if (item.content) {
          return item.content.category === filterBy
        }
        return true
      })
    }

    let subCategories = []
    if (currentValueForm) {
      subCategories = templates
        .filter(
          (item) =>
            item.content && item.content.category === currentValueForm.category
        )
        .map((item) => item.content.subcategory)

      subCategories = Array.from(new Set(subCategories)).map((item) => ({
        label: capitalize(item),
        value: item
      }))
    }

    const template = getTemplateForm(categories || [], subCategories || [])

    return (
      <div className={emailTemplateStyle}>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <Divider />
            <div className="catergory-filter">
              <span className="tag">FILTER BY :</span>
              <Select
                className="catergory-sel"
                value={filterBy}
                onChange={this.filterBy}
                defaultValue={'All'}
              >
                {filterByValues.map((c, idx) => (
                  <Select.Option key={`c--${idx}`} value={c}>
                    {c[0].toUpperCase() + c.substr(1)}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <List
              grid={{ gutter: 30, column: 4 }}
              dataSource={filteredTemplates}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    className={`template-card ${item.content ? '' : 'add'}`}
                    onClick={() => this.cardHandle(item)}
                  >
                    {item.content ? (
                      <>
                        <div className="category">
                          <Typography.Paragraph ellipsis>
                            {item.content.category} * {item.content.subcategory}
                          </Typography.Paragraph>
                        </div>
                        <div className="sub-category">{item.content.title}</div>
                        <div className="template">{item.content.text}</div>
                      </>
                    ) : (
                      <>
                        <AddIcon />
                        <br />
                        <div>Add template</div>
                      </>
                    )}
                  </Card>
                </List.Item>
              )}
            />
            {showForm ? (
              <AperionModalForm
                visible={showForm}
                width={600}
                formData={template}
                valueForm={
                  (selectedTemplate && Object.create(selectedTemplate)) || {}
                }
                syncUpdate={(valueForm) =>
                  this.setState({ currentValueForm: valueForm })
                }
                title={selectedTemplate ? 'Edit template' : 'Add template'}
                onCancel={() => this.setState({ showForm: false })}
                onSubmit={this.submitTemplate}
                footer={{
                  type: 'flex',
                  leftPart: selectedTemplate
                    ? [
                        {
                          btnType: 'danger',
                          ghost: true,
                          text: 'DELETE',
                          type: 'opr',
                          clickCallback: this.deleteTemplate
                        }
                      ]
                    : [],
                  rightPart: [
                    {
                      btnType: 'primary',
                      ghost: true,
                      text: 'CANCEL',
                      type: 'cancel'
                    },
                    {
                      btnType: 'primary',
                      text: 'SAVE',
                      type: 'submit',
                      style: { minWidth: 155, height: 48 }
                    }
                  ]
                }}
              />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    )
  }
}

export default EmailTemplateContainer
