import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Input, Button, Checkbox, Radio, Space, Row, Col } from 'antd'

const FilterContainer = styled.div`
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 35px;
    h1 {
      margin-bottom: 0;
      font-size: 36px;
    }
    .ant-input-group {
      display: flex;
      background: rgba(18, 22, 45, 0.05);
      border-radius: 10px;
      overflow: hidden;
      padding: 2px;
      .ant-input-affix-wrapper {
        order: 2;
        background: transparent;
        border: none;
        overflow: hidden;
        border-radius: 10px !important;
        padding-left: 44px;
        &.ant-input-affix-wrapper-focused {
          background: #ffffff;
        }
      }
      .ant-input {
        background: transparent;
      }
      .ant-input-group-addon {
        z-index: 1;
        left: -2px;
        button {
          background: transparent;
          border: none;
        }
      }
    }
    .opr-group {
      display: flex;
      align-items: center;
      & > button {
        border: 1px solid #dee2ec;
        border-radius: 6px;
        height: 34px;
        width: 75px;
        margin-left: 16px;
        color: #60656d;
        display: flex;
        align-items: center;
        & > svg {
          margin-right: 6px;
          transform: scale(2);
        }
      }
    }
  }
  .conditions {
    padding-bottom: 35px;
    color: #60656d;
    .type {
      color: #6d7d8c;
    }
    .ant-checkbox-group {
      .ant-checkbox-group-item {
        display: inline-flex;
        margin-bottom: 15px;
        color: #60656d;
        // &:last-child {
        //   margin: 0;
        // }
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after,
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #264382;
        border-color: #264382;
      }
    }
    .ant-radio-group,
    .ant-space {
      .ant-radio-button-wrapper {
        box-shadow: none;
      }
      .ant-radio-button-wrapper {
        width: 100%;
        border-color: transparent !important;
        color: #60656d;
        span:not(.ant-radio-button) {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .ant-radio-button-wrapper-checked {
        padding: 4px 10px;
        color: #264382;
        background: #f3f3f4;
        border-radius: 12px;
        line-height: 14px;
      }
    }
  }
`

const typeOptions = [
  {
    label: 'Exercise',
    value: 'Exercise'
  },
  {
    label: 'Nutrition',
    value: 'Nutrition'
  },
  {
    label: 'Sleep',
    value: 'Sleep'
  },
  {
    label: 'Medical',
    value: 'Medical'
  },
  {
    label: 'Files',
    value: 'Files'
  }
]

export default function TopFilter(props) {
  const { experts, onConditionChange } = props
  const [selectTypes, setSelectTypes] = useState([])
  const [selectPeople, setSelectPeople] = useState([])
  const [selectDate, setSelectDate] = useState('')
  const [search, setSearch] = useState('')
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    onConditionChange({ selectTypes, selectPeople, selectDate, search })
  }, [selectTypes, selectPeople, selectDate, search])

  return (
    <FilterContainer>
      <div className="title">
        <h1>Notes</h1>
        <div className="opr-group">
          <Input.Search onSearch={onSearch} size="large" allowClear />
          <Button onClick={() => setCollapse(!collapse)}>
            <FilterIcon /> Filter
          </Button>
        </div>
      </div>
      <div
        className="conditions"
        style={{ display: collapse ? 'none' : 'block' }}
      >
        <Row>
          <Col span={3}>
            <span className="type">Type:</span>
          </Col>
          <Col span={21}>
            <Checkbox.Group
              options={typeOptions}
              defaultValue={[]}
              onChange={(checkedValues) => setSelectTypes(checkedValues)}
            />
          </Col>
          <Col span={3}>
            <span className="type">People:</span>
          </Col>
          <Col span={21}>
            <Checkbox.Group
              options={experts
                .sort((a, b) => {
                  return (
                    a.full_name.toLowerCase().charCodeAt() -
                    b.full_name.toLowerCase().charCodeAt()
                  )
                })
                .map((item) => ({
                  label: item.full_name,
                  value: item.id
                }))}
              defaultValue={[]}
              onChange={(checkedValues) => setSelectPeople(checkedValues)}
            />
          </Col>
          <Col span={3}>
            <span className="type">Date:</span>
          </Col>
          <Col span={21}>
            <Radio.Group
              onChange={(e) => setSelectDate(e.target.value)}
              value={selectDate}
              size="small"
            >
              <Space size={15}>
                <Radio.Button value="">
                  <span>All</span>
                </Radio.Button>
                <Radio.Button
                  value={moment()
                    .subtract(1, 'week')
                    .format('YYYY-MM-DD')}
                >
                  <span>1 week ago</span>
                </Radio.Button>
                <Radio.Button
                  value={moment()
                    .subtract(1, 'month')
                    .format('YYYY-MM-DD')}
                >
                  <span>1 month ago</span>
                </Radio.Button>
                <Radio.Button
                  value={moment()
                    .subtract(3, 'month')
                    .format('YYYY-MM-DD')}
                >
                  <span>3 months ago</span>
                </Radio.Button>
                <Radio.Button
                  value={moment()
                    .subtract(1, 'year')
                    .format('YYYY-MM-DD')}
                >
                  <span>1 year ago</span>
                </Radio.Button>
              </Space>
            </Radio.Group>
          </Col>
        </Row>
      </div>
    </FilterContainer>
  )

  function onSearch(value) {
    setSearch(value)
  }
}

function FilterIcon(props) {
  return (
    <svg
      {...props}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0625 0.5H0.9375"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="square"
      />
      <path
        d="M7.8125 3H2.1875"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="square"
      />
      <path
        d="M6.5625 5.5H3.4375"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="square"
      />
    </svg>
  )
}
