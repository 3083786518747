import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import ScoreDetail from '../../components/nutritionScore/comp/scoreDetail'

class NutritionScoreDetail extends Component {
  static propTypes = {}

  render() {
    const {
      match: {
        params: { personId, hash, questionId }
      }
    } = this.props
    return (
      <>
        <ScoreDetail
          person={{ id: personId }}
          hash={hash}
          questionId={questionId}
          device="mobile"
        />
      </>
    )
  }
}

export default NutritionScoreDetail
