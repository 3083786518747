import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import BasicForm from '../../components/formV4'
import RangeForm from '../../components/formV4/rangeForm'
import EditableForm from '../../components/formV4/rangeFormsWrapped'
import { metricBasic, selectBasic } from '../../models/adminEditor.form'
import { Button, Divider, message, Select, Modal } from 'antd'

import './index.sass'

const RangesForm = EditableForm(RangeForm)
const Option = Select.Option

class MetricForm extends Component {
  static propTypes = {
    id: PropTypes.number,
    metric: PropTypes.object,
    ageScopes: PropTypes.array,
    update: PropTypes.func,
    valueType: PropTypes.string,
    changeValueType: PropTypes.func
  }

  // rangeRefs = {}
  rangeForm = null
  rangeValidKeys = [
    'name',
    'health_effect',
    'life_suggestion',
    'is_optimal',
    'order',
    'male_age_scope',
    'female_age_scope',
    'is_option'
  ]

  state = {
    metric: null,
    ranges: null,
    formData: {
      name: '',
      introduction: '',
      support_links: ''
    },
    getBasicForm: null,
    loading: false
  }

  getPublishParamsObj = async () => {
    const { valueType } = this.props
    const metric = await this.basicFormRef.handleSubmit()
    if (this.rangeForm) {
      metric.ranges = await this.rangeForm.getPostRanges()
    }
    if (valueType === 'number') {
      metric.options = null
    } else {
      const options = metric.ranges.map((item) => item.name)
      metric.options = options
    }
    return metric
  }

  publish = async () => {
    const { update, valueType } = this.props
    try {
      this.setState({ loading: true })
      const metric = await this.getPublishParamsObj()
      await update(metric)
      this.setState({ loading: false })
      message.success('Metric is published!')
    } catch (err) {
      this.setState({ loading: false })
      console.error(err)
      if (err.message === 'Form validate error') {
        const modal = Modal.error()
        modal.update({
          content: 'Cannot pass the form validation, please check your input.'
        })
      } else if (err.message === 'Range Name Conflict') {
        const modal = Modal.error()
        let message = 'Range name conflict.'
        if (valueType === 'multi-select') {
          message = 'Output conflict.'
        }
        modal.update({
          content: message
        })
      }
    }
  }

  optionsOpr = (type, index, value) => {
    let { options } = this.state
    const { metric } = this.props
    options = options || metric.options || []
    switch (type) {
      case 'add':
        options.push('')
        break
      case 'remove':
        options.splice(index, 1)
        break
      case 'update':
        options[index] = value
        break
      default:
        break
    }
    this.setState({ options })
  }

  render() {
    const {
      metric,
      valueType,
      changeValueType,
      ageScopes,
      id,
      changeOptimal
    } = this.props
    let { loading, ranges, options } = this.state
    ranges = ranges || (metric && metric.ranges) || []
    options = options || (metric && metric.options) || []
    return (
      <div className="edit-container">
        <div className="value-type">
          <span>Value Type:</span>
          <Select
            style={{ width: 130 }}
            value={valueType}
            onChange={changeValueType}
          >
            <Option value="number">Number</Option>
            <Option value="multi-select">Multi-select</Option>
          </Select>
        </div>
        <BasicForm
          wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          initialValues={metric}
          formData={valueType === 'number' ? metricBasic : selectBasic}
        />
        <Divider>
          {valueType === 'number' ? 'Ranges Divider' : 'Choices Divider'}
        </Divider>
        {metric && ranges && (
          <RangesForm
            defaultValues={ranges}
            options={options}
            // onRefChange={refs => (this.rangeRefs = refs)}
            ref={(r) => (this.rangeForm = r)}
            editKey={`range${id}`}
            valueType={valueType}
            changeOptimal={changeOptimal}
            optionsOpr={this.optionsOpr}
            ageScopes={ageScopes}
          />
        )}
        <Divider orientation="left">
          {(metric.updated_at || metric.updated_by_person) &&
            `Last updated ${metric.updated_by_person &&
              'by ' +
                ((metric.updated_by_person.profile &&
                  metric.updated_by_person.profile.nickname) ||
                  metric.updated_by_person.first_name)} ${metric.updated_at &&
              ' at ' + moment(metric.updated_at).format('YYYY-MM-DD')}`}
        </Divider>
        <Button
          className="btn-publish"
          loading={loading}
          onClick={this.publish}
          type="primary"
        >
          PUBLISH
        </Button>
        {/* <Button
          // onClick={this.preview}
          >
            PREVIEW
          </Button> */}
      </div>
    )
  }
}

export default MetricForm
