import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider, Modal, Alert } from 'antd'
import {
  getClientList,
  getClientDetail,
  getClientSetting
} from '../../../../models/breadcrumb.model'
import UserInfo from '../../../../components/business/setting/userInfo'
import SignedCard from '../../../../components/business/setting/signed'
import ProfileSetting from '../../../../components/business/setting'
import SignedForms from '../../../../components/business/setting/signedForms'
import PageLoading from '../../../../components/UI/status/loading'
import {
  getCustomer,
  getIntroductory,
  getNotificationStatus
} from '../../../../api/people'

class SettingOverview extends Component {
  static propTypes = {}

  state = {
    card: {},
    visible: false,
    loading: false
  }

  getCard = async (id) => {
    const data = await getCustomer(id)
    const card =
      data.customer &&
      data.customer.sources &&
      data.customer.sources.data[0].card
    if (card) {
      this.setState({ card })
    }
  }

  getClientIntroductory = async (id) => {
    const data = await getIntroductory(id)
    if (data) {
      this.setState({ introductory: data })
    }
  }

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    this.setState({ loading: true })
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    setClientPageTitle('Profile & Settings')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getClientSetting(person, true)
      ])

    this.getCard(person.id)
    this.getClientIntroductory(person.id)
    const result = await getNotificationStatus(person.id)
    person.profile = Object.assign(person.profile, result)

    this.setState({ loading: false, person })
  }

  getAlert() {
    const { person } = this.state
    const { allow_app_notification, allow_emails } =
      (person && person.profile) || {}
    const messageTypes = [],
      desTypes = []
    if (!allow_emails) {
      messageTypes.push('emails')
      desTypes.push('summary email')
    }
    if (!allow_app_notification) {
      messageTypes.push('push notifications')
      desTypes.push('push notifications')
    }
    const message = `${messageTypes.join(' & ')} have been paused for ${person
      .profile.nickname || person.first_name} ${person.last_name}`
    const description = `Client won’t be able to receive ${desTypes
      .reverse()
      .join(' and ')} while paused `

    return { message, description }
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { match, history } = this.props
    const { person, card, visible, loading, introductory } = this.state
    const { allow_app_notification, allow_emails } =
      (person && person.profile) || {}

    return loading ? (
      <PageLoading />
    ) : (
      <div>
        <Divider />
        {person && (!allow_app_notification || !allow_emails) && (
          <Alert {...this.getAlert()} type="warning" showIcon />
        )}
        <UserInfo
          user={person}
          introductory={introductory}
          loginRole="Expert"
        />
        <SignedCard
          user={person}
          loginRole="Expert"
          viewSigned={() => this.setState({ visible: true })}
        />

        {/* <FileManager
          person={person}
          loginRole={role}
          insuranceFiles={
            _.get(introductory, 'insurance_type') === 'photo'
              ? _.get(introductory, 'insurance_photos', [])
              : []
          }
        /> */}

        <ProfileSetting
          user={person}
          card={card}
          loginRole="Expert"
          changePassword={() => history.push(`${match.url}/change-password`)}
          changePayment={() => history.push(`${match.url}/payment`)}
          updateSync={(person) => this.setState({ person })}
        />
        <Modal
          visible={visible}
          footer={null}
          width={600}
          onCancel={() => this.setState({ visible: false })}
        >
          <SignedForms person={person} loginRole="Expert" />
        </Modal>
      </div>
    )
  }
}

export default SettingOverview
