import React, { useState } from 'react'
import moment from 'moment'
import _ from 'lodash'
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip
} from 'victory'
import { detailColors } from '../../../../utils/constant'
import { getAgeTag } from '../../../../utils/common'

const calculateColor = (v, ranges) => {
  const range = ranges.filter((range) => {
    return (!range.min || range.min <= v) && (!range.max || range.max >= v)
  })
  return range.length ? range[0].color : '#D2D2D2'
}

function CicleClicked(props) {
  const { x, y, ranges, datum, selectedDatum } = props
  const value = datum.amount
  const color = calculateColor(value, ranges)
  // let [clicked, setClicked] = React.useState(false)
  const clicked = selectedDatum && selectedDatum.x === datum.x
  if (ranges.length === 0) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.x - 8}
        y={props.y - 8}
        onClick={() => {
          props.showInfoHandle(props.datum)
        }}
      >
        <circle
          cx="8"
          cy="8"
          r="7"
          fill="#264382"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    )
  }
  return (
    <svg
      x={x - 11}
      y={y - 11}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => {
        // clicked = !clicked
        props.showInfoHandle(props.datum)
        // setClicked(clicked)
      }}
    >
      <circle
        cx="11"
        cy="11"
        r={clicked ? '10' : '8'}
        fill="#ECF9F2"
        stroke={color}
        strokeWidth={clicked ? '2' : '0'}
        strokeDasharray="2 2"
      />
      <circle cx="11" cy="11" r="5" fill={color} />
    </svg>
  )
}

const grid = (min) => ({
  stroke: (tick) => {
    return tick - min === 0 ? 'transparent' : 'rgba(128, 151, 177, 0.375)'
  },
  strokeDasharray: '8, 6',
  strokeWidth: 0.825
})
const axis = {
  stroke: 'transparent',
  strokeWidth: 1.65
}

export default function HistoryChart(props) {
  const { biomarker, person, ageScopes, xLabelOffset } = props
  const [selectedDatum, setSelectedDatum] = useState(null)
  if (biomarker.options) {
    // multi-select type no chat
    return null
  }
  const { gender } = person.profile
  const _ranges = biomarker.conventional_ranges || biomarker.ranges
  const ageTag = getAgeTag(person.profile, ageScopes)
  const histories = biomarker.history || []
  const ranges = _ranges
    .map(
      (range) =>
        range[`${gender}_age_scope`] && range[`${gender}_age_scope`][ageTag]
    )
    .filter((range) => range)
    .map((range) => {
      const [min, max, color] = range
      const colorObj = detailColors.find((item) => item.key === color)
      return {
        min: min && Number(min),
        max: max && Number(max),
        color: colorObj && colorObj.value
      }
    })
    .sort((a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE))

  let dataSet = []
  const test_dates = [null]
  const test_date_ticks = [0, 1, 2, 3, 4, 5, 6, 7, 8]
  let range_ticks = ranges.map((r, idx) => idx)
  range_ticks.push(range_ticks.length)

  let min_y = ranges.length + 1
  let maxHistory
  if (histories.length > 0) {
    maxHistory = Math.max(...histories.map((item) => item.value))
    histories.map((item) => {
      const amount = Number(item.value)
      let idx = 1
      for (const range of ranges) {
        if (
          (!range.min || range.min <= amount) &&
          (!range.max || range.max >= amount)
        ) {
          break
        }
        idx++
      }
      const nextRange = ranges[idx]
      const currRange = ranges[idx - 1]
      if (currRange && currRange.max && !currRange.min) {
        currRange.min = currRange.max - currRange.max
      }
      let y = idx - 0.9
      let diff = 0
      if (_.isNumber(amount) && currRange) {
        if (!nextRange && !currRange.max && _.isNumber(currRange.min)) {
          const denominator = maxHistory - currRange.min || 1
          diff = ((amount - currRange.min) / denominator) * 0.9
          if (diff === 0 && amount === maxHistory) {
            diff = 0.9
          }
        } else if (currRange && currRange.max && _.isNumber(currRange.min)) {
          diff =
            ((amount - currRange.min) / (currRange.max - currRange.min)) * 0.9
        } else {
          y = idx
        }
        y += diff
        if (y < 0) {
          y = 0
        } else if (y > range_ticks[range_ticks.length - 1]) {
          y = range_ticks[range_ticks.length - 1]
        }
      } else {
        y = null
      }

      dataSet.push({
        x: test_dates.length,
        amount,
        y
      })
      if (y < min_y) {
        min_y = y
      }
      test_dates.push(item.test_date)
      return item
    })
  }

  if (maxHistory && ranges.length === 0) {
    maxHistory += Math.abs(maxHistory / 3)
    range_ticks = [0, 1, 2, 3, 4]
    dataSet = dataSet.map((item) => {
      item.y = (3 * item.amount) / maxHistory
      return item
    })
  }

  if (biomarker.formula_variable_name === 'trunk_isometric_extension_back') {
    console.log('🚀 ~ file: chat.js:120 ~ HistoryChart ~ ranges:', ranges)
    console.log(
      '🚀 ~ file: chat.js:125 ~ HistoryChart ~ range_ticks:',
      range_ticks
    )
  }

  return (
    <div className="chat">
      <VictoryChart
        height={200}
        padding={{
          right: 60,
          left: 45,
          top: 20,
          bottom: 25
        }}
      >
        <VictoryAxis
          offsetY={xLabelOffset || 30}
          tickValues={test_date_ticks}
          tickFormat={(x) => {
            return test_dates[x] && moment(test_dates[x]).format('M/D/YY')
          }}
          style={{
            grid: {
              stroke: 'transparent'
            },
            axis,
            ticks: {
              strokeWidth: 0
            },
            tickLabels: {
              fontFamily: 'Gilroy',
              fontSize: 9,
              fill: '#70808E'
            }
          }}
        />
        <VictoryAxis
          dependentAxis
          orientation="right"
          offsetX={60}
          tickValues={range_ticks}
          tickFormat={(idx) => {
            const range = ranges[idx - 1]
            if (!range) return ''
            if (range.max && range.min) {
              return `${range.min}-${range.max}`
            } else if (range.max) {
              return `< ${range.max}`
            } else {
              return `> ${range.min}`
            }
          }}
          style={{
            grid: grid(range_ticks[0]),
            axis,
            ticks: { strokeWidth: 0 },
            tickLabels: {
              fill: (idx) => ranges[idx - 1] && ranges[idx - 1].color,
              fontFamily: 'Gilroy',
              fontSize: 9
              // padding: 5
            }
          }}
        />
        {dataSet.length > 1 && (
          <VictoryLine
            bubbleProperty="amount"
            style={{
              data: { stroke: '#264382', opacity: 0.3, strokeWidth: 2 }
            }}
            data={dataSet}
          />
        )}

        <VictoryScatter
          // data={dataSet.map((d) => {
          //   const idx = ranges.findIndex((r) => d.amount <= r.max)
          //   const r = ranges[idx]
          //   if (r) {
          //     if (r.max) {
          //       d.y = (d.amount - r.min) / (r.max - r.min) + idx
          //     } else {
          //       d.y = d.amount / r.min
          //     }
          //   }
          //   if (d.y < 0) d.y = 0
          //   return d
          // })}
          data={dataSet}
          dataComponent={
            <CicleClicked
              ranges={ranges}
              selectedDatum={selectedDatum}
              showInfoHandle={(datum) => {
                setSelectedDatum(datum)
              }}
            />
          }
          labelComponent={
            <VictoryTooltip
              active={true}
              dx={(datum) =>
                selectedDatum && selectedDatum.x === datum.x ? 0 : 10000
              }
              cornerRadius={2}
              flyoutStyle={{ fill: '#70808E', stroke: 'transparent' }}
              style={{ fill: '#ffffff', fontSize: 10 }}
            />
          }
          labels={(datum) => datum.amount}
        />
      </VictoryChart>
    </div>
  )
}
