import React, { Component } from 'react'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryAxis,
  VictoryTheme
} from 'victory'

const color = {
  max: '#5C00D1',
  avg: '#9D66E4',
  min: '#BE99ED'
}

const AxisStyle = {
  strokeDasharray: '5, 5',
  stroke: '#C0C7D1'
}

class Interpolation extends Component {
  state = {
    max: [],
    avg: [],
    min: [],
    axisX: ['M', 'T', 'W', 'T ', 'F', 'S', 'S '],
    axisY: [],
    text: {
      max: 0,
      avg: 0,
      min: 0
    }
  }

  componentDidMount() {
    let { overview, type, avg } = this.props

    const { axisX } = this.state

    let max = [],
      min = [],
      avgs = []
    if (overview && overview.entries) {
      const temp =
        overview.entries.filter(
          (item) => item.pillar === 'rejuvenation' && !item.manual
        ) || []

      if (type === 'Resting') {
        temp.forEach((e) => {
          max.push(e.details.max_hr)
          min.push(e.details.min_hr)
          avgs.push(e.details.resting_heartrate)
        })
      } else if (type === 'HRV') {
        temp.forEach((e) => {
          max.push(e.details.max_hrv)
          min.push(e.details.min_hrv)
          avgs.push(e.details.heart_rate_variation)
        })
      }
    }
    this.getAxisY(max)
    this.getTexts(max, min, avg)
    this.setState({
      max: max.map((e, index) => ({ x: axisX[index], y: e })),
      min: min.map((e, index) => ({ x: axisX[index], y: e })),
      avg: avgs.map((e, index) => ({ x: axisX[index], y: e }))
    })
  }

  getAxisY(maxs) {
    if (maxs.length === 0) {
      return
    }
    let max = Math.max(...maxs)
    max = Math.ceil(max / 10) * 10
    this.setState({
      axisY: [0, max / 2, max]
    })
  }

  getTexts(maxs, mins, avg) {
    if (maxs.length === 0) {
      return
    }
    const max = Math.max(...maxs)
    const min = Math.min(...mins)
    this.setState({
      text: {
        max,
        avg,
        min
      }
    })
  }

  render() {
    const { max, min, avg, axisX, axisY, text } = this.state
    const { type } = this.props
    const label = type === 'Resting' ? 'resting HR' : 'HRV'
    const unit = type === 'Resting' ? 'bpm' : 'ms'
    return (
      <InterpolationContainer>
        <div>
          <VictoryChart>
            <VictoryLine
              interpolation="linear"
              data={max}
              style={{ data: { stroke: color.max, strokeWidth: 5 } }}
            />
            <VictoryScatter
              data={max}
              size={5}
              style={{ data: { fill: color.max } }}
              dataComponent={<Dot stroke={color.max} />}
            />
            <VictoryLine
              interpolation="linear"
              data={avg}
              style={{ data: { stroke: color.avg, strokeWidth: 5 } }}
            />
            <VictoryScatter
              data={avg}
              size={5}
              style={{ data: { fill: color.avg } }}
              dataComponent={<Dot stroke={color.avg} />}
            />
            <VictoryLine
              interpolation="linear"
              data={min}
              style={{ data: { stroke: color.min, strokeWidth: 5 } }}
            />
            <VictoryScatter
              data={min}
              size={5}
              style={{ data: { fill: color.min } }}
              dataComponent={<Dot stroke={color.min} />}
            />
            <VictoryAxis
              crossAxis
              style={{
                axis: AxisStyle,
                tickLabels: { fill: '#C0C7D1', fontSize: '18px' }
              }}
              theme={VictoryTheme.material}
              standalone={false}
              tickValues={axisX}
            />
            {axisY.length > 0 && (
              <VictoryAxis
                dependentAxis
                tickValues={axisY}
                offsetX={40}
                style={{
                  grid: AxisStyle,
                  axis: AxisStyle,
                  tickLabels: { fill: '#C0C7D1', fontSize: '18px' }
                }}
              />
            )}
          </VictoryChart>
        </div>
        <div className="item">
          <div className="i-left">
            <div className="circle"></div> Max {label}
          </div>{' '}
          <div className="bpm">
            {text.max} {unit}
          </div>
        </div>
        <div className="item">
          <div className="i-left">
            <div className="circle avg"></div>Avg {label}
          </div>{' '}
          <div className="bpm">
            {text.avg} {unit}
          </div>
        </div>
        <div className="item">
          <div className="i-left">
            <div className="circle min"></div>Min {label}
          </div>{' '}
          <div className="bpm">
            {text.min} {unit}
          </div>
        </div>
      </InterpolationContainer>
    )
  }
}

function Dot(props) {
  return (
    <svg
      width="100"
      height="35"
      viewBox="0 0 100 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 10}
      y={(props.y && props.y - 15) || 0}
    >
      <circle
        cx="10"
        cy="5"
        r="8"
        fill="white"
        stroke={props.stroke}
        strokeWidth="5"
      />
    </svg>
  )
}

const InterpolationContainer = styled.div`
  .item {
    display: flex;
    color: #8596a4;
    font-family: Gilroy;
    font-size: 12px;
    font-weight: 500;
    line-height: 130%;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 20px;
    .i-left {
      display: flex;
      align-items: center;
      width: 110px;
    }
    .circle {
      background: #5c00d1;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      &.avg {
        background-color: #9d66e4;
      }
      &.min {
        background-color: #be99ed;
      }
      margin-right: 4px;
    }
    .bpm {
      color: #383e48;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }
  }
`

export default Interpolation
