import React from 'react'
import { Pluralize } from '../../../utils/common'

// interface IContentCardProp {
//   data: {
//     id: string
//     content_category: any
//     // category_id: number // ..
//     link: string
//     title: string // link's title
//     description: string // link's title
//     cover_url: string // img src
//     cover_type: 'upload' | 'url'
//     link_domain: string // source form which website
//     file?: any // the file if user uploaded to replace the cover
//     estimate_view_time: number // the estimated reading minutes
//     media_type?: string // Article or Video
//   }
//   onRemove?: (id: string) => void
// }
class ContentCard extends React.Component {
  render() {
    const {
      data: {
        cover_url,
        // id,
        content_category,
        title,
        description,
        estimate_view_time,
        media_type
      }
    } = this.props
    return (
      <div className="content-card">
        <div className="card-info">
          <img src={cover_url} alt="cover" />
          <div className={`category ${content_category.category}`}>
            {content_category &&
              content_category.category &&
              content_category.category[0].toUpperCase() +
                content_category.category.substr(1)}
          </div>
          <div className="sub-con">
            <div className={`sub-category ${content_category.category}`}>
              {content_category.sub_category}
            </div>
            <div className="title" style={{ padding: 0 }}>
              {title}
            </div>
            <div className="description">
              {description &&
                description.length > 0 &&
                description[0].toUpperCase() + description.substr(1)}
            </div>
            {/* <div className="sourcesite">{link_domain}</div> */}
            <div className="minutes">
              {media_type && (
                <span>
                  {media_type[0].toUpperCase() + media_type.substr(1)} -{' '}
                </span>
              )}
              <span>{estimate_view_time}</span>
              <span>
                {' '}
                {Pluralize('min', estimate_view_time || 1)}{' '}
                {media_type === 'article' ? 'read' : 'watch'}
              </span>
            </div>
          </div>
        </div>
        {/* {this.props.onRemove && (
          <div className="to-remove" onClick={this.toRemove}>
            REMOVE
          </div>
        )} */}
      </div>
    )
  }

  toRemove = (e) => {
    this.props.data.id && this.props.onRemove(this.props.data.id)
  }
}

export default ContentCard
