import React, { Component } from 'react'
import PHSHeader from '../../components/UI/header'
import PageLoading from '../../components/UI/status/loading'
import { admin } from '../adminTool/index.sass'
import * as microGoals from '../../api/microGoals'
import { Tabs } from 'antd'
import Categories from './categories'

const headerTabs = [
  {
    id: 'CLIENTS',
    title: 'MY CLIENTS',
    href: '/client'
  }
]

const TabPane = Tabs.TabPane

class GoalsTool extends Component {
  static propTypes = {}

  state = {
    selectedTab: 'nutrition',
    loadingCategories: true
  }

  getCategories = async () => {
    const result = await microGoals.getCategories()
    let categories = {
      nutrition: [],
      mindset: [],
      exercise: [],
      rejuvenation: [],
      functional_health: []
    }
    for (const pillar in categories) {
      categories[pillar] = result.categories.filter(
        (category) => category.pillar === pillar
      )
    }
    this.setState({
      loadingCategories: false,
      categories: categories
    })
  }

  categoriesOpr = (category, type, pillar) => {
    const { categories } = this.state
    let index
    switch (type) {
      case 'add':
        categories[pillar].push(category)
        break
      case 'update':
        index = categories[pillar].findIndex((item) => item.id === category.id)
        index !== -1 && (categories[pillar][index] = category)
        break
      case 'delete':
        index = categories[pillar].findIndex((item) => item.id === category.id)
        index !== -1 && categories[pillar].splice(index, 1)
        break
      default:
        break
    }
    this.setState({ categories })
  }

  componentDidMount() {
    this.getCategories()
  }

  render() {
    const { loadingCategories, categories } = this.state
    const { history } = this.props
    return (
      <div className={admin}>
        <PHSHeader tabs={headerTabs} history={history} />
        {loadingCategories ? (
          <PageLoading />
        ) : (
          <div className="admin-container">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Nutrition" key="1">
                <Categories
                  path="nutrition"
                  pillar="nutrition"
                  history={this.props.history}
                  categories={categories.nutrition}
                  categoriesOpr={this.categoriesOpr}
                />
              </TabPane>
              <TabPane tab="Mindset" key="2">
                <Categories
                  path="mindset"
                  pillar="mindset"
                  history={this.props.history}
                  categories={categories.mindset}
                  categoriesOpr={this.categoriesOpr}
                />
              </TabPane>
              <TabPane tab="Exercise" key="3">
                <Categories
                  path="exercise"
                  pillar="exercise"
                  history={this.props.history}
                  categories={categories.exercise}
                  categoriesOpr={this.categoriesOpr}
                />
              </TabPane>
              <TabPane tab="Rejuvenation" key="4">
                <Categories
                  path="rejuvenation"
                  pillar="rejuvenation"
                  history={this.props.history}
                  categories={categories.rejuvenation}
                  categoriesOpr={this.categoriesOpr}
                />
              </TabPane>
              <TabPane tab="Functional Health" key="5">
                <Categories
                  path="functional-health"
                  pillar="functional_health"
                  history={this.props.history}
                  categories={categories['functional_health']}
                  categoriesOpr={this.categoriesOpr}
                />
              </TabPane>
            </Tabs>
          </div>
        )}
        {/* <PHSFooter /> */}
      </div>
    )
  }
}

export default GoalsTool
