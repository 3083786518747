import styled from 'styled-components'

export const QuestionContainer = styled.div`
  position: ${({ type, hasExtraInfo }) =>
    type === 'section_title' && hasExtraInfo ? 'sticky' : 'static'};
  top: 74px;
  z-index: 99;
  background: #f1f3f6;
  .question {
    margin-top: 30px;
    &.hide-title {
      margin-top: 0;
    }
    &.q-group + div {
      margin-top: 0;
    }
  }

  .q-title {
    font-size: 18px;
    color: #38435e;
    display: flex;
    margin-bottom: 18px;
    .required {
      color: #ff0000;
      font-size: 26px;
      margin-right: 5px;
      height: 24px;
    }
    .note_title {
      color: #98a9bc;
      font-size: 14px;
    }
    span {
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      background: #dee4ea;
      color: #98a9bc;
      font-size: 12px;
      border-radius: 100%;
      margin-right: 10px;
    }

    svg {
      align-self: center;
    }

    p {
      max-width: 90%;
      margin: 0;
      margin-right: 12px;
      flex-grow: 1;
    }

    .p-without-flex-grow {
      flex-grow: unset;
    }

    .what-img {
      width: 16px;
      margin-top: -24px;
    }
  }

  .q-level-bar {
    margin: 0;
    padding: 0;
    list-style: none;
    border-radius: 8px;
    background: #ffffff;
    overflow: hidden;
    li {
      display: inline-block;
      text-align: center;
      line-height: 54px;
      font-family: 'Gilroy-bold';
      border-left: 1px solid #f1f3f6;
      cursor: pointer;
      &:first-child {
        border-left: none;
      }

      &.selected {
        background: #05b800;
        color: #ffffff;
      }
    }
  }

  .q-label-level-bar {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      color: #5e687d;
      cursor: pointer;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-left: 2px solid #f1f3f6;
        font-size: 14px;
        font-family: Gilroy;
        background-color: #ffffff;
      }

      .label {
        margin-top: 8px;
        font-size: 12px;
        font-family: Heebo;
        text-align: center;
      }

      &.selected .title {
        background: #05b800;
        color: #ffffff;
      }

      &:first-child .title {
        border-radius: 8px 0 0 8px;
        border-left: none;
      }

      &:last-child .title {
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .q-sub-title {
    border-top: 1px solid rgba(152, 169, 188, 0.2);
    padding-top: 40px;
    .container {
      display: flex;
      padding: 21px;
      background: rgba(38, 67, 130, 0.05);
      border-radius: 8px;
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-right: 18px;
    }

    .content {
      font-size: 14px;
      color: #38435e;
    }
  }

  input.invalid {
    border: 1px solid rgba(252, 46, 46, 0.5);
  }

  p.invalid {
    color: #ff0000;
    white-space: nowrap;
  }

  .q-form-sub-group {
    padding-left: 50px;
  }

  .q-space {
    height: 16px;
  }
  .q-form-group {
    .q-text-group {
      padding: 16px;
      display: flex;
      .ant-checkbox-wrapper {
        width: auto;
        margin-right: 16px;
        padding-top: 2px;
      }
      .q-text-group-title strong {
        font-family: 'Gilroy-Bold';
      }
    }
    .q-text-group-description {
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
    }
    .q-multiple-answer {
      color: #264382;
      font-size: 14px;
      margin: 16px 0;
      display: inline-block;
      cursor: pointer;
    }
    .ant-form {
      padding-top: 50px;
      border-top: 1px solid rgb(217, 217, 217);
      margin-top: 20px;
      &:first-child {
        padding-top: 30px;
        margin-top: 0;
        border-top: none;
      }
      .ant-form-item .ant-form-item-label {
        background: transparent;
        height: 18px;
        top: 1px;
        border-bottom: 1px solid #fff;
        & > label {
          background: transparent;
        }
      }
    }
  }

  .q-body.inline .q-form-group {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    p.invalid {
      width: 100%;
    }
  }

  .q-body.ant-col-24 {
    padding-left: 34px;
  }

  .multi-column {
    column-count: 3;
  }

  .q-form-item {
    background: #fbfbfb;
    min-height: 54px;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 0 16px;
    background: #ffffff;
    break-inside: avoid-column;

    .ant-input-group input {
      border-right: none;
      border-radius: 8px 0 0 8px;
    }
    .ant-input-group-addon {
      border-radius: 0 8px 8px 0;
    }

    &.hide {
      display: none;
    }

    &.ant-select {
      padding: 0;
      width: 100%;
      .ant-select-selector {
        min-height: inherit;
        border-radius: 8px;
        background: #fbfbfb;
        align-items: center;
      }
      .ant-select-selection__rendered {
        padding: 12px 16px;
        margin: 0;
      }
      .ant-select-selection__placeholder,
      .ant-select-search__field__placeholder {
        left: 16px;
      }
    }

    &.ant-input-group-wrapper {
      padding: 0;
    }

    .additional-item {
      margin-bottom: 16px;
      &:first-child {
        padding-top: 16px;
      }
      &:last-child {
        padding-bottom: 16px;
      }
    }

    & > .ant-input {
      margin-bottom: 16px;
    }

    .ant-input,
    &.ant-input {
      border: 1px solid rgba(147, 147, 147, 0.2);
      height: 54px;
      border-radius: 8px;
      background: #fbfbfb;
      letter-spacing: 0.3px;
      &.invalid {
        border: 1px solid rgba(252, 46, 46, 0.5);
      }
    }

    &.ant-time-picker {
      display: flex;
      align-items: center;
    }

    &.ant-calendar-picker .ant-input,
    .ant-time-picker-input {
      border: none;
      background: transparent;
      box-shadow: none;
    }

    &.valid {
      outline: 1px solid rgba(5, 184, 0, 0.5);
    }

    &.invalid,
    &.invalid input {
      border: 1px solid rgba(252, 46, 46, 0.5);
      outline: none;
    }

    &.invalid .ant-picker-input,
    &.invalid .ant-input-number-input-wrap {
      input {
        border: none;
      }
    }

    .ant-upload {
      width: 100%;
    }

    .ant-radio-wrapper,
    .ant-checkbox-wrapper,
    .item-text {
      padding: 20px 0;
      width: 100%;
      white-space: normal;
    }

    &.ant-input-number {
      display: flex;
      align-items: center;
    }

    .attach-list {
      margin-top: 0;
    }
    .elem-group {
      display: flex;
      align-items: center;
      min-height: 54px;
    }
  }
`
