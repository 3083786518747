import React from 'react'
import { Divider } from 'antd'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import DxaSummary from '../comp/dxaSummary'

export default function BoneHealth(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    handleSave,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status, detail },
    dynamicData: { ui_version, dxaSummary }
  } = props

  const boneData = dataList.find((item) => item.type === 'bone_health') || {}

  const boneCompare =
    compareData.find((item) => item.type === 'bone_health') || {}

  const bodyComp = notes.find((item) => item.pillar === 'bone health')

  return (
    <HideWrap
      name="Bone Health"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
    >
      <section>
        <h1 className="section-title">Bone Health</h1>
        <HideWrap
          name="Bone Health - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <div className="sub-title">Summary</div>
          <HealthCard
            {...boneData}
            compare={boneCompare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={bodyComp ? bodyComp.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'body comp', status)
            }
            updateEditingCount={updateEditingCount}
          />
        </HideWrap>
        {dxaSummary && (
          <>
            <Divider />
            <HideWrap
              name="Body Composition - DXA"
              title="DXA Results Summary"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <div className="sub-title">DXA Results Summary</div>
              <DxaSummary
                {...{
                  view,
                  person,
                  detail,
                  handleSave,
                  dxaSummary,
                  ui_version,
                  ageScopes,
                  notes,
                  handleCommendSave,
                  updateEditingCount
                }}
              />
            </HideWrap>
          </>
        )}
      </section>
    </HideWrap>
  )
}
