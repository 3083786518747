import axios from './axios'
import { questionnaireConfig } from '../utils/constant'

// stepName:
//     | 'waiver_forms_skipped'
//     | 'billing_info_skipped'
//     | 'questionnaire_skipped'
//     | 'onboarding_required',
//   user: any
export function skipOnboardingStep(stepName, user) {
  let val
  switch (stepName) {
    case 'questionnaire_skipped':
      val = questionnaireConfig.sections
      break
    case 'waiver_forms_skipped':
      val = [1, 2, 3] // 1 = Medical Release Form; 2 = Liability Release Form
      break
    case 'onboarding_required':
      val = false
      break
    default:
      val = true
  }
  const oldSteps = user.profile.onboarding_steps || {}
  axios.putJson(`/clients/${user.id}`, {
    profile: { onboarding_steps: Object.assign(oldSteps, { [stepName]: val }) }
  })
  // modify user's profile.onboarding_steps
  user.profile.onboarding_steps = user.profile.onboarding_steps || {}
  user.profile.onboarding_steps[stepName] = val
}

export function skipWaiverSection(stepId, user) {
  //stepId: 1 | 2 | 3, user: any
  // modify user's profile.onboarding_steps
  user.profile.onboarding_steps = user.profile.onboarding_steps || {}
  let arr = user.profile.onboarding_steps.waiver_forms_skipped || []
  if (arr.includes(stepId)) {
    return {}
  }
  arr.push(stepId)
  arr.sort()
  user.profile.onboarding_steps.waiver_forms_skipped = arr
  return axios.putJson(`/clients/${user.id}`, {
    profile: { onboarding_steps: user.profile.onboarding_steps }
  })
}

export function skipQuestionnaireSection(stepId, user) {
  // modify user's profile.onboarding_steps
  user.profile.onboarding_steps = user.profile.onboarding_steps || {}
  let arr = (user.profile.onboarding_steps.questionnaire_skipped =
    user.profile.onboarding_steps.questionnaire_skipped || [])
  if (arr.includes(stepId)) {
    return
  }
  arr.push(stepId)
  arr.sort()
  axios.putJson(`/clients/${user.id}`, {
    profile: { onboarding_steps: user.profile.onboarding_steps }
  })
}

export function skipNotification(params, person_id) {
  return axios.postJson(
    `/clients/onboarding_email?person_id=${person_id}`,
    params
  )
}
