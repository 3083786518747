import React, { useState, useEffect } from 'react'
import QuestionnaireGroup from './group'
import { Button, Progress, Tabs } from 'antd'
import { connect } from 'react-redux'
import mapDispatchToProps from '../../dispatch'
import {
  getClientList,
  getClientDetail,
  getQuestionnaire
} from '../../../../models/breadcrumb.model'
import {
  getAllExperts,
  getSectionModules,
  getSurveyProgress
} from '../../../../api'
import { QuestionniaresContainer } from './style'
import {
  QuestionnaireDoneSmallIcon,
  SendQuestionnaireBtn
} from '../../../../components/icons/questionnaires'
import AnswerProgress from '../../../../components/questionnaire/progress'
import OldQuestionnaires from '../../../../components/questionnaire/container'
import SendQuestionnaire from './sendQuestionnaireModal'
import SendSuccessModal from './sendSuccessModal'
import { getRole } from '../../../../utils'
import PageLoading from '../../../../components/UI/status/loading'
import moment from 'moment'
import WelcomeEmail from '../../../../components/modalFrom/welcomeEmail'
import SendMultipleQuestionnaireModal from './sendMultipleQuestionnaireModal'

function QuestionnaireV2(props) {
  const { history, getPerson, setClientBreadcrumb, setClientPageTitle } = props
  const [pageloading, setPageloading] = useState(false)
  const [person, setPerson] = useState(null)
  const [modules, setModules] = useState(null)
  const [sendEmail, setSendEmail] = useState(null)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false)
  const [sendMultiQtnr, setSendMultiQtnr] = useState(false)
  const [answerProgress, setAnswerProgress] = useState(0)
  const [admins, setAdmins] = useState([])

  useEffect(() => {
    const initial = async () => {
      setClientPageTitle('Questionnaire')
      const person = await getPerson()
      setPerson(person)
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getQuestionnaire(person.id, true)
      ])
      const [modules, progress, result] = await Promise.all([
        getSectionModules(person.id),
        getSurveyProgress(person.id),
        getAllExperts()
      ])
      setModules(modules)
      setAnswerProgress(progress.completion_rate)
      setAdmins(result.clients)
    }

    initial()
  }, [])

  const refresh = async () => {
    setPageloading(true)
    try {
      const modules = await getSectionModules(person.id)
      setModules(modules)
      const progress = await getSurveyProgress(person.id)
      setAnswerProgress(progress.completion_rate)
    } finally {
      setPageloading(false)
    }
  }

  const isUser = getRole() === 'User'

  return (
    <QuestionniaresContainer>
      {isUser && <div className="page-title">Questionnaires</div>}
      {pageloading ? (
        <PageLoading />
      ) : (
        <>
          {!isUser && (
            <>
              <div className="questionnaire-action-container">
                <Button
                  type="primary"
                  style={{
                    marginRight: 12
                  }}
                  onClick={() => setSendWelcomeEmail(true)}
                >
                  SEND WELCOME EMAIL
                </Button>
                <Button
                  type="primary"
                  ghost
                  onClick={() => setSendMultiQtnr(true)}
                >
                  SEND MULTIPLE QUESTIONNAIRE
                </Button>
              </div>
            </>
          )}

          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: 'QUESTIONNAIRE',
                key: '1',
                children: (
                  <>
                    <AnswerProgress percent={`${answerProgress}`} />
                    {modules &&
                      modules.map((module) => (
                        <div className="module-container" key={module.module}>
                          <div className="group-header">
                            {module.module}
                            {/* {module.sections.every(
                            (s) => s.multi_answer_version
                          ) &&
                            getRole() !== 'User' && (
                              <div>
                                <Button
                                  onClick={() =>
                                    setSendEmail({
                                      title: module.module,
                                      originalIds: module.sections.map(
                                        (i) => i.original_id
                                      )
                                    })
                                  }
                                >
                                  SEND ALL QUESTIONNAIRES
                                </Button>
                              </div>
                            )} */}
                          </div>
                          {module.sections.map((section, index) => (
                            <div
                              className={`group ${
                                sectionClickable(section) ? '' : 'no-sessions'
                              }`}
                              key={index}
                              onClick={() => {
                                if (sectionClickable(section))
                                  turnToDetail(section)
                              }}
                              capture={true}
                            >
                              <div>
                                {section.completion_rate >= 100 ? (
                                  <QuestionnaireDoneSmallIcon />
                                ) : (
                                  <Progress
                                    type="circle"
                                    percent={section.completion_rate}
                                    strokeColor={'#7BAD2D'}
                                    trailColor={'#E6E9F2'}
                                    width={20}
                                    strokeWidth={23}
                                    showInfo={false}
                                  />
                                )}
                                <span className="group-name">
                                  {section.name}
                                </span>
                              </div>
                              <div className="group-base-info">
                                {section.multi_answer_version && (
                                  <>
                                    <div className="group-base-info-date">
                                      <div className="group-base-info-title">
                                        LAST SENT
                                      </div>
                                      <div className="group-base-info-content">
                                        {section.record_date
                                          ? moment(
                                              section.record_date,
                                              'YYYY-MM-DD'
                                            ).format('MM/DD/YYYY')
                                          : '--'}
                                      </div>
                                    </div>
                                    <div className="group-base-info-date">
                                      <div className="group-base-info-title">
                                        LAST COMPLETED
                                      </div>
                                      <div className="group-base-info-content">
                                        {section.completion_date
                                          ? moment(
                                              section.completion_date,
                                              'YYYY-MM-DD'
                                            ).format('MM/DD/YYYY')
                                          : '--'}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {section.multi_answer_version &&
                                  getRole() !== 'User' && (
                                    <SendQuestionnaireBtn
                                      onClick={(event) => {
                                        event.stopPropagation()
                                        setSendEmail({
                                          title: section.name,
                                          originalIds: [section.original_id],
                                          qNames: [section.name]
                                        })
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </>
                )
              },
              {
                label: 'HISTORY',
                key: '2',
                children: (
                  <OldQuestionnaires
                    person={person}
                    setEditSection={(activeKey) => {
                      console.log(activeKey)
                    }}
                  />
                )
              }
            ]}
          />
        </>
      )}
      {sendEmail && (
        <SendQuestionnaire
          person={person}
          done={(method) => {
            setSendEmail(null)
            if (method === 'email') {
              setSendEmailSuccess(true)
            } else {
              refresh()
            }
          }}
          onCancel={() => setSendEmail(null)}
          sendEmail={sendEmail}
        />
      )}
      {sendEmailSuccess && (
        <SendSuccessModal
          ok={() => {
            setSendEmailSuccess(false)
            refresh()
          }}
        />
      )}
      {sendWelcomeEmail && (
        <WelcomeEmail
          onCancel={() => setSendWelcomeEmail(false)}
          person={person}
          experts={admins}
        />
      )}
      {sendMultiQtnr && (
        <SendMultipleQuestionnaireModal
          onCancel={() => setSendMultiQtnr(false)}
          options={modules
            .flatMap((m) => m.sections)
            .filter((s) => s.multi_answer_version)
            .map((s) => {
              return {
                label: s.name,
                value: s.original_id
              }
            })}
          confirm={({ checkedValues, qNames }) => {
            setSendMultiQtnr(false)
            setSendEmail({
              title: 'Send Questionnaire',
              originalIds: checkedValues,
              qNames
            })
          }}
        />
      )}
    </QuestionniaresContainer>
  )

  function turnToDetail(section) {
    const loginRole = getRole()
    if (loginRole === 'User') {
      history.push(
        `/app/questionnaire/group?original_ids=${section.original_id}`
      )
    } else {
      history.push(
        `/client/${person &&
          person.id}/detail/questionnaire/group?original_ids=${
          section.original_id
        }`
      )
    }
  }

  function sectionClickable(section) {
    if (getRole() === 'User') {
      return section.has_sessions
    } else {
      return (
        section.has_sessions ||
        section.original_id === 'pre_medical' ||
        section.original_id === 'lifestyle_goals'
      )
    }
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(QuestionnaireV2)

export { QuestionnaireGroup }
