import React, { Component } from 'react'
import { Modal, Divider } from 'antd'
import { markdownModalStyle } from './styles/markdownModal.sass'

class BasicMarkdownFormat extends Component {
  render() {
    return (
      <Modal
        className={`${markdownModalStyle} markdown`}
        width={667}
        title={'Basic Markdown Format'}
        footer={null}
        visible={true}
        onCancel={this.props.cancel}
        // mask={false}
        maskClosable={true}
      >
        <div className="tip-title">
          Header
          <p>#This is an example header</p>
        </div>
        <Divider />
        <div className="tip-title">
          Paragraphs
          <p>This is an example paragraph</p>
        </div>
        <Divider />
        <div className="tip-title">
          Bold
          <p>Some of these words **are bold**</p>
        </div>
        <Divider />
        <div className="tip-title">
          Links
          <p>This is an [example link](http://example.com/)</p>
        </div>
        <Divider />
      </Modal>
    )
  }
}

export default BasicMarkdownFormat
