import styled from 'styled-components'

export const Container = styled.div`
  .title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.3px;
    color: #323f4a;
    text-align: left;
  }
  .activity-item:not(:first-child) {
    margin-top: 24px;
  }
`

export const DayActivityContainer = styled.div`
  color: #38435e;
  .title-section {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    .serial-number {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: 'Lato';
      font-weight: 700;
      color: #98a9bc;
      background: #dee4ea;
    }
    .title {
      margin-left: 12px;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .flex-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .q-form-item {
    overflow: hidden;

    &.ant-input-number {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
      input {
        padding: 0 16px;
      }
    }
  }
`
