import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LeftOutlined } from '@ant-design/icons'
import { Layout, Menu, Button } from 'antd'
import './layout.sass'

const { Header, Sider, Content } = Layout

class AdminToolDetailLayout extends Component {
  static propTypes = {
    categories: PropTypes.array,
    metric: PropTypes.object,
    categoryIdField: PropTypes.string,
    id: PropTypes.string,
    metricsField: PropTypes.string,
    turnTo: PropTypes.func,
    goBack: PropTypes.func
  }

  state = {}

  getDefaultOpenKeys = (category_id) => {
    const { categories } = this.props
    let result = []
    if (categories) {
      let parentCategory = categories.find(
        (item) =>
          item.id === category_id ||
          item.child_categories.find((subItem) => subItem.id === category_id)
      )
      parentCategory && result.push(`category_${parentCategory.id}`)
      category_id && result.push(`sub_category_${category_id}`)
    } else {
      result = (category_id && [`category_${category_id}`]) || []
    }

    return result
  }

  getItems = () => {
    const { categories, metricsField, metric } = this.props
    if (categories && categories.length > 0 && metric) {
      return categories.map((_category) => {
        const { id, name, child_categories } = _category
        const category = { label: name, key: `category_${id}` }
        if (child_categories.length > 0) {
          category.children = child_categories.map((_subCategory) => {
            const { id, name } = _subCategory
            const subCategory = { label: name, key: `sub_category_${id}` }
            subCategory.children = _subCategory[metricsField].map(
              ({ id, name }) => ({ label: name, key: id })
            )
            return subCategory
          })
        } else if (_category[metricsField]) {
          category.children = _category[metricsField].map(({ id, name }) => ({
            label: name,
            key: id
          }))
        }

        return category
      })
    }
    return []
  }

  render() {
    const { categories, metric, id, goBack, turnTo } = this.props
    const items = this.getItems()
    return (
      <Layout style={{ height: '100vh' }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo">P H S</div>
          {categories && categories.length > 0 && metric && (
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[id]}
              items={items}
              defaultOpenKeys={this.getDefaultOpenKeys(metric.category_id)}
              onClick={(item) => turnTo(item.key)}
            ></Menu>
          )}
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <Button className="btn-back" onClick={() => goBack()}>
              <LeftOutlined />
              Back
            </Button>
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}

export default AdminToolDetailLayout
