import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { message } from 'antd'
import {
  getCategories,
  getDataset,
  createDataset,
  delDataset,
  updateHistoryDataset
} from '../../../api/dataset'
import { uploadAttachment, deleteAttachment } from '../../../api/file'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'
import { getDbFields } from './common'
import { testDate } from '../../../models/client.form'
import ModalForm from '../../modalFrom'

export default (WrappedComponent) =>
  (class ContainerWrapper extends React.Component {
    static propTypes = {
      type: PropTypes.string
    }

    state = {
      dataLoading: true,
      modalVisible: false,
      createDatasetLoading: false,
      ageScopes: null,
      isInitialDone: false
    }

    propsVersion = 1

    upload = async (type, file, progressCallback, source = null) => {
      const { datasets, showDataset } = this.state
      const { person } = this.props
      const dataset = datasets.find((item) => item.test_date === showDataset)
      const index = datasets.findIndex((item) => item.test_date === showDataset)
      try {
        const result = await uploadAttachment({
          model: `dataset.${type}`,
          personId: person.id,
          reportId: dataset.id,
          file,
          progressCallback,
          source
        })
        const attachment = result.attachment || result.data.attachment
        dataset[type] = attachment
        datasets.splice(index, 1, dataset)
        source.cancel()
        this.setState({ datasets })
      } catch (err) {
        message.error(err.messgae)
        throw err
      }
    }

    deletePhoto = async (type, file) => {
      const { datasets, showDataset } = this.state
      const { person } = this.props
      try {
        await deleteAttachment(file.id, person.id)
        const dataset = datasets.find((item) => item.test_date === showDataset)
        const index = datasets.findIndex(
          (item) => item.test_date === showDataset
        )
        dataset[type] = null
        datasets.splice(index, 1, dataset)
        this.setState({ datasets })
      } catch (err) {
        console.error(err)
      }
    }

    findBiomarker = (biomarkerId) => {
      const {
        categories,
        dbField: { metricsField }
      } = this.state
      let biomarker
      for (const category of categories) {
        if (category.child_categories.length > 0) {
          for (const childCategory of category.child_categories) {
            biomarker = childCategory[metricsField].find(
              (item) => item.id === biomarkerId
            )
            if (biomarker) break
          }
        } else {
          biomarker = category[metricsField].find(
            (item) => item.id === biomarkerId
          )
        }
        if (biomarker) break
      }
      return biomarker
    }

    savePersonalValue = async (personalValue, datasetId) => {
      const { datasets, showDataset } = this.state
      let dataset = datasets.find((item) => item.test_date === showDataset)
      const index = datasets.findIndex((item) => item.test_date === showDataset)
      const { value, id } = personalValue

      try {
        let result
        result = await updateHistoryDataset(datasetId, personalValue)
        dataset = result.dataset
        const testDate = dataset.test_date.slice(0, 10)
        datasets.splice(index, 1, dataset)
        const biomarker = this.findBiomarker(id)

        if (biomarker) {
          biomarker.history = biomarker.history || []
          const targetHistory = biomarker.history.find(
            (item) => item.test_date === testDate
          )

          if (targetHistory) {
            if (value === null) {
              biomarker.history = biomarker.history.filter(
                (item) => item.test_date !== testDate
              )
            } else {
              targetHistory.value = Number(value)
            }
          } else {
            biomarker.history.push({ value, test_date: testDate })
            biomarker.history.sort((a, b) => {
              return moment(a.test_date).isAfter(moment(b.test_date)) ? 1 : -1
            })
          }
        }
        this.setState({ datasets })
        return true
      } catch (err) {
        console.error(err)
      }
    }

    getDatasets = async (type, personId) => {
      const dbField = getDbFields(type)
      const datasets = await getDataset({
        type,
        personId: personId,
        group: dbField.groupType
      })
      this.setState({
        datasets,
        showDataset: datasets[0] && datasets[0].test_date
      })
    }

    initial = async (type, propsVersion) => {
      const {
        person,
        slideMenu: { groups }
      } = this.props
      const dbField = getDbFields(type)
      this.setState({ dbField })
      let { ageScopes } = this.state
      try {
        const targetMenu = groups[type.replace('-', '_')] || { categories: [] }

        const categories = await getCategories({
          type,
          personId: person.id,
          ids: targetMenu.categories.map((item) => item.id)
        })

        await this.getDatasets(type, person.id)

        if (!ageScopes) {
          ageScopes = (await getRangeAgeScopes()).age_scope
        }

        if (propsVersion === this.propsVersion) {
          this.setState({
            categories,
            ageScopes,
            dataLoading: false
          })
        }
        this.props.restoreScrollPosition()
      } catch (err) {
        console.error(err)
        this.setState({ dataLoading: false })
      }
    }

    fileUploadAsync = async () => {
      const { person, type } = this.props
      await this.getDatasets(type, person.id)
    }

    createDateSetSubmit = async (params) => {
      const { person } = this.props
      const {
        dbField: { type, groupType }
      } = this.state
      this.setState({ createDatasetLoading: true })
      try {
        const result = await createDataset(
          type,
          person.id,
          params,
          groupType || type
        )
        const dataset = result.dataset
        this.setState({
          showDataset: dataset.test_date,
          modalVisible: false,
          createDatasetLoading: false
        })
        this.getDatasets(this.props.type, person.id)
      } catch (err) {
        this.setState({ createDatasetLoading: false })
        // throw new Error() //err
        // console.error(err)
      }
    }

    deleteDataset = async (item, index) => {
      const {
        person,
        type,
        slideMenu: { groups }
      } = this.props

      let { datasets, showDataset, dbField } = this.state
      try {
        if (showDataset === item.test_date) {
          showDataset = ''
        }
        await delDataset(item.id, dbField.groupType || dbField.type)
        datasets.splice(index, 1)
        if (datasets.length > 0) {
          showDataset = datasets[0].test_date
        }
        const targetMenu = groups[type.replace('-', '_')] || { categories: [] }
        const categories = await getCategories({
          type,
          personId: person.id,
          ids: targetMenu.categories.map((item) => item.id)
        })
        this.setState({ datasets, showDataset, categories })
      } catch (err) {
        console.error(err)
      }
    }

    componentDidMount() {
      const { type } = this.props

      this.initial(type, this.propsVersion)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { type } = this.props
      if (nextProps.type !== type) {
        this.props.clearScrollPosition()
        this.setState({ dataLoading: true })
        this.propsVersion++
        this.initial(nextProps.type, this.propsVersion)
      }
    }

    componentWillUnmount() {
      const { history } = this.props
      if (!history.location.pathname.includes(this.props.type)) {
        this.props.clearScrollPosition()
      }
    }

    render() {
      const { history, match, person, type } = this.props
      const childProps = {
        ...this.state,
        person,
        selectCategory:
          match.params.category ||
          (this.state.categories &&
            this.state.categories[0] &&
            this.state.categories[0].name),
        setWrapperState: (state) => this.setState({ ...state }),
        createDataset: () => this.setState({ modalVisible: true }),
        deleteDataset: this.deleteDataset,
        fileUploadAsync: this.fileUploadAsync,
        dataHandle: {
          savePersonalValue: this.savePersonalValue,
          upload: this.upload,
          deletePhoto: this.deletePhoto,
          turnTo: (id, datasetId) => {
            // before turn to detail, save scroll position
            this.props.saveScrollPosition()
            const path = match.url.split('/' + type)[0]
            history.push(`${path}/${type}/${id}/${datasetId}`)
          }
        }
      }

      const { modalVisible, createDatasetLoading } = this.state

      return (
        <>
          <WrappedComponent {...this.props} {...childProps} />
          {modalVisible && (
            <ModalForm
              title="Create DataSet"
              visible={modalVisible}
              formData={testDate}
              onCancel={() => this.setState({ modalVisible: false })}
              onSubmit={this.createDateSetSubmit}
              loading={createDatasetLoading}
            />
          )}
        </>
      )
    }
  })
