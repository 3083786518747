import React, { Component } from 'react'
import { CheckOutlined } from '@ant-design/icons'
// import PropTypes from 'prop-types'
import { Button } from 'antd'
import Header from './header'
import Location from './location'
import { saveCheckInRecord } from '../../../api/checkin'
import {
  getCheckInSercive,
  getCheckInFacility,
  getCheckIn,
  setCheckIn
  // removeCheckInFacility
} from '../../../utils/storage'

class Expert extends Component {
  static propTypes = {}

  state = {
    selected: null,
    service: null,
    view: 'expert'
  }

  select = (selected) => {
    this.setState({ selected })
  }

  submit = () => {
    const { service, checkIn, facility, selected } = this.state
    if (!checkIn.visited) {
      this.props.history.push(`/check-in/photo/${selected}`)
    } else {
      saveCheckInRecord(
        {
          facility_id: facility.id,
          service_id: service.id,
          expert_id: selected
        },
        checkIn.id
      )
        .then((result) => {
          setCheckIn(result.checkin)
          this.props.history.push('/check-in/finish')
        })
        .catch((err) => {
          throw err
        })
    }
  }

  componentDidMount() {
    const checkIn = getCheckIn()
    const facility = getCheckInFacility()
    const serviceId = getCheckInSercive()
    const service = facility.services.find(
      (item) => item.id === Number(serviceId)
    )
    this.setState({ service, checkIn, facility })
  }

  render() {
    const { service, facility, selected } = this.state
    return (
      <div className="expert">
        <Header history={this.props.history} />
        {facility && <Location location={facility.name} />}
        <p className="check-title">
          {service && service.experts.length === 0
            ? 'Please contact Apeiron Life to add experts.'
            : 'Who are you here to see?'}
        </p>
        <ul className="expert-list">
          {service &&
            service.experts.map((item, index) => (
              <li key={index} onClick={() => this.select(item.id)}>
                <div className="avatar-content">
                  {selected === item.id && (
                    <div className="selected">
                      <CheckOutlined />
                    </div>
                  )}
                  {item.avatar && (
                    <img
                      className="check-in-avatar"
                      src={item.avatar.oss_url}
                      alt="avatar"
                    />
                  )}
                </div>
                <div className="expert-name">{`${(item.profile &&
                  item.profile.nickname) ||
                  item.first_name} ${item.last_name}`}</div>
              </li>
            ))}
        </ul>
        {selected && <Button onClick={this.submit}>Next</Button>}
        {/* {facility && <Location location={facility.name} />} */}
      </div>
    )
  }
}

export default Expert
