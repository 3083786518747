import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Profile from './profile'
import ClientSignedForms from './viewSignedForms'
// import Enable2FA from './settings/enable-2fa'
import SettingChangePassword from './settings/change-password'

class ClientProfile extends Component {
  static propTypes = {}

  render() {
    const { match } = this.props
    return (
      <div>
        <Switch>
          <Route path={`${match.url}`} exact component={Profile} />
          {/* full path is :  /profile/change-password */}
          <Route
            path={`${match.url}/view-signed-forms`}
            exact
            component={ClientSignedForms}
          />
          {/* <Route
            path={`${match.url}/enable-2fa`}
            exact
            component={Enable2FA}
          /> */}
          <Route
            path={`${match.url}/change-password`}
            exact
            component={SettingChangePassword}
          />
        </Switch>
      </div>
    )
  }
}

export default ClientProfile
