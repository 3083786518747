import { Row, Col, Divider } from 'antd'
import React from 'react'

import styled from 'styled-components'
import ActivityIcon from '../../../asserts/icon/overview/overview-hsd-summary-activity-icon.png'
import DurationIcon from '../../../asserts/icon/overview/overview-hsd-summary-duration-icon.png'
import HSDRing from './hsdRing'
import { Pluralize } from '../../../utils'
import _ from 'lodash'

const Container = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  font-family: Gilroy;
  padding: 12px 20px;
  margin-bottom: 9px;
  .ant-row {
    align-items: center;
  }
  .main-box {
    display: flex;
    align-items: center;
    .ring-box {
      width: 80px;
    }
    img {
      width: 36px;
      height: 36px;
    }
    .title-label {
      color: #8596a4;
      font-size: 12px;
      line-height: 15.6px;
    }

    .value-label {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.3px;
      color: #383e48;
      font-weight: 600;
    }
  }
`

const LeftMarginBox = styled.div`
  margin-left: 14px;
`

export default function HSDOverView({
  duration,
  healthy_signals_more_progress_avg,
  healthy_signals_progress,
  session
}) {
  return (
    <Container>
      <Row gutter={[100, 0]}>
        <Col span={8}>
          <div className="main-box">
            <div className="ring-box">
              <HSDRing percent={healthy_signals_progress} />
            </div>
            <LeftMarginBox>
              <div className="title-label">Current</div>
              <div className="value-label">{healthy_signals_progress}%</div>
            </LeftMarginBox>
            <Divider type="vertical" />
            <LeftMarginBox>
              <div className="title-label">3-week avg</div>
              <div className="value-label">
                {healthy_signals_more_progress_avg}%
              </div>
            </LeftMarginBox>
          </div>
        </Col>
        <Col span={6}>
          <div className="main-box">
            <img src={ActivityIcon} alt="activity" />
            <LeftMarginBox>
              <div className="title-label">Physical Activity</div>
              <div className="value-label">
                {session} {Pluralize('session', session)}
              </div>
            </LeftMarginBox>
          </div>
        </Col>
        <Col span={7}>
          <div className="main-box">
            <img src={DurationIcon} alt="duration" />
            <LeftMarginBox>
              <div className="title-label">Workout Duration</div>
              {_.isNil(duration) ? (
                <div className="value-label">--</div>
              ) : (
                <div className="value-label">
                  {Math.round(duration / 60)}{' '}
                  {Pluralize('mins', Math.round(duration / 60))}
                </div>
              )}
            </LeftMarginBox>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
