import React from 'react'
import _, { capitalize } from 'lodash'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryArea,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer
} from 'victory'
import { calcTextWidth } from '../../../utils/String+Extension'
import { BiomeWeightHoverContainer } from '../../../pages/microbiomeAnalysis/index.style'
import { ConvertSourceToComp } from '../../../pages/microbiomeAnalysis/comp/BiomeWeightChart'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'

export function WeightChart({ weight }) {
  const { yTickValues, step, data } = calculatePoints(_.get(weight, 'week', []))

  if (data.length === 0) {
    return (
      <VictoryChart
        width={500}
        height={130}
        padding={{ top: 5, bottom: 20, left: 10, right: 20 }}
      >
        <VictoryAxis
          tickValues={[' ']}
          style={{
            axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '5, 5' },
            tickLabels: { fontSize: 10, padding: 5, fill: '#70808E' }
          }}
        />
        <VictoryAxis
          dependentAxis
          offsetX={505}
          tickValues={[0, step, 2 * step, 3 * step]}
          tickFormat={(t) => {
            return t + yTickValues[0]
          }}
          style={{
            axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '5, 5' },
            grid: {
              stroke: 'rgba(0, 0, 0, 0.1)',
              strokeDasharray: '5, 5'
            },
            tickLabels: { fontSize: 10, padding: 5, fill: '#70808E' }
          }}
        />
      </VictoryChart>
    )
  } else {
    return (
      <>
        <VictoryChart
          width={500}
          height={130}
          padding={{ top: 5, bottom: 20, left: 10, right: 20 }}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            tickValues={_.concat(
              data.map((e) => e.x),
              ['']
            )}
            tickFormat={(t) => (t ? moment(t).format('M/D') : ' ')}
            style={{
              axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '3, 3' },
              grid: {
                stroke: (tick) => {
                  return tick.length > 0 ? 'rgba(0, 0, 0, 0.5)' : 'transparent'
                },
                strokeDasharray: '3, 3'
              },
              tickLabels: { fontSize: 10, padding: 5, fill: '#70808E' }
            }}
          />

          <VictoryAxis
            dependentAxis
            offsetX={505}
            tickValues={[0, step, 2 * step, 3 * step]}
            tickFormat={(t) => {
              return t + yTickValues[0]
            }}
            style={{
              axis: { stroke: 'rgba(0, 0, 0, 0.1)', strokeDasharray: '3, 3' },
              grid: {
                stroke: 'rgba(0, 0, 0, 0.1)',
                strokeDasharray: '3, 3'
              },
              tickLabels: { fontSize: 10, padding: 5, fill: '#70808E' }
            }}
          />
          <VictoryArea style={{ data: { fill: 'url(#linear)' } }} data={data} />
          <VictoryLine
            style={{
              data: { stroke: '#FF6B00', strokeWidth: '2px' }
            }}
            data={data}
          />
          <VictoryScatter
            style={{ data: { fill: 'rgba(255, 107, 0, 0.2)' } }}
            size={8}
            data={[_.last(data)]}
          />
          <VictoryScatter
            labels={(props) => {
              return 'what number'
            }}
            labelComponent={
              <VictoryTooltip
                // active={true}
                labelComponent={<HoverComp />}
                flyoutHeight={70}
                flyoutWidth={30}
                flyoutStyle={{
                  stroke: 'transparent',
                  strokeWidth: 2,
                  fill: 'white'
                }}
              />
            }
            style={{ data: { fill: '#FF6B00' } }}
            size={4}
            data={data}
          />
        </VictoryChart>
        <svg style={{ height: 0 }}>
          <defs>
            <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="rgba(255, 107, 0, 0.5)" />
              <stop offset="100%" stopColor="rgba(255, 107, 0, 0)" />
            </linearGradient>
          </defs>
        </svg>
      </>
    )
  }

  function calculatePoints(originDatas) {
    const filteredData = _.filter(
      originDatas.reverse(),
      (d) => d.weight !== null
    )
    const allWeight = filteredData.map((d) => d.weight)
    let yTickValues
    let step = 1
    if (allWeight.length === 0) {
      yTickValues = [152, 151, 150, 149].reverse()
    } else if (allWeight.length === 1) {
      let max = Math.ceil(allWeight[0]) + 1
      yTickValues = [max, max - 1, max - 2, max - 3].reverse()
    } else {
      let max = Math.ceil(Math.max(...allWeight))
      let min = Math.floor(Math.min(...allWeight))

      while ((max - min) % 3 !== 0) {
        max += 1
        min -= 1
      }

      step = (max - min) / 3
      yTickValues = [
        max,
        max - 1 * step,
        max - 2 * step,
        max - 3 * step
      ].reverse()
    }
    return {
      yTickValues,
      step,
      data: filteredData.map((d) => {
        return {
          x: d.created_at,
          y: d.weight - yTickValues[0],
          source: d.source,
          weight: d.weight
        }
      })
    }
  }

  function HoverComp(props) {
    const {
      x,
      y,
      datum: { weight, source, percent }
    } = props
    const sourceStr = ConvertWeightSource(source)
    const textWidth = calcTextWidth(sourceStr)
    return (
      <g>
        <foreignObject
          x={x - 80}
          y={y - 11.6 - 35}
          height={84}
          width={(textWidth < 150 ? 150 : textWidth) + 60}
        >
          <BiomeWeightHoverContainer dotColor={'#FF6B00'}>
            <div className="weight-source-data">
              <span>Weight</span>
              {ConvertSourceToComp(source)}
            </div>
            <div className="weight-data-container">
              <div>
                <span className="dot" />
                <span className="date">
                  {moment(props.datum.x, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                </span>
              </div>
              <div>
                <span>{weight}</span>
                {percent && (
                  <span className="changes">
                    {percent > 0 ? (
                      <ArrowUpOutlined
                        style={{
                          fontSize: 12,
                          color: '#9aa5b1'
                        }}
                      />
                    ) : (
                      <ArrowDownOutlined
                        style={{
                          fontSize: 12,
                          color: '#9aa5b1'
                        }}
                      />
                    )}
                    {Math.abs(percent)}%
                  </span>
                )}
              </div>
            </div>
          </BiomeWeightHoverContainer>
        </foreignObject>
      </g>
    )
  }
}

export function ConvertWeightSource(source) {
  if (source) {
    switch (source) {
      case 'dataset':
        return 'Apeiron Scale'
      default:
        return isFirstCharUpperCase(source) ? source : capitalize(source)
    }
  }
  return 'Manual Entry'
}

function isFirstCharUpperCase(str) {
  const firstChar = _.first(str)
  return firstChar >= 'A' && firstChar <= 'Z'
}
