import React, { useState, useEffect } from 'react'
import { Button, Upload, message } from 'antd'
import _ from 'lodash'
import { SendEmailIcon } from '../../icons'
import BasicForm from '../../formV4' //BasicForm
import { basicInfo } from '../../../models/client.form'
import {
  introductoryEmailInfo,
  introductoryAddressInfo,
  introductoryEmergencyInfo,
  introductoryInsuranceInfo
} from '../../../models/clientIntroductory.form'
import {
  saveAvatar,
  saveIntroductory,
  sendLogisticsEmail,
  sendMedicalReleaseEmail
} from '../../../api/people'
import { editClient } from '../../../api/team'

import { userInfoStyle, style } from './index.sass'

import editIcon from '../../../asserts/icon/icon-profile-edit.svg'

const UserInfo = ({ user, updateSync, loginRole, introductory }) => {
  const [state, setState] = useState({
    editing: false,
    logisticsSent: false,
    logisticsLoading: false,
    medicalReleaseSent: false,
    medicalReleaseSending: false,
    editedUserInfo: null,
    updating: false,
    needupdateHeader: false
  })

  const [basicFormRef, setBasicFormRef] = useState(null)
  const [emailFormRef, setEmailFormRef] = useState(null)
  const [addressFormRef, setAddressFormRef] = useState(null)
  const [emergencyFormRef, setEmergencyFormRef] = useState(null)
  const [insuranceFormRef, setInsuranceFormRef] = useState(null)

  const sendEmail = async () => {
    setState((prevState) => ({ ...prevState, logisticsLoading: true }))
    try {
      await sendLogisticsEmail(user.id)
      message.success('Email sent successfully!')
      setState((prevState) => ({
        ...prevState,
        logisticsLoading: false,
        logisticsSent: true
      }))
    } catch (err) {
      setState((prevState) => ({ ...prevState, logisticsLoading: false }))
    }
  }

  const sendMedicalRelease = async () => {
    // Implement the sendMedicalRelease function
    setState((prevState) => ({ ...prevState, medicalReleaseSending: true }))
    try {
      await sendMedicalReleaseEmail(user.id)
      message.success('Email sent successfully!')
      setState((prevState) => ({
        ...prevState,
        medicalReleaseSending: false,
        medicalReleaseSent: true
      }))
    } catch (err) {
      setState((prevState) => ({ ...prevState, medicalReleaseSending: false }))
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const customRequest = (request) => {
    const { file } = request
    getBase64(file, (imageUrl) => {
      changeAvatar(file)
    })
  }

  const changeAvatar = async (file) => {
    if (file) {
      let result = await saveAvatar(file, user.id)
      let person = result.data.person
      setState((prevState) => ({ ...prevState, user: person }))
      if (updateSync) {
        updateSync(person)
      }
    }
  }

  const convertEmailParams = (params) => {
    let schedulingEmails = _.get(introductory, 'has_scheduling_email', false)
      ? _.get(introductory, 'scheduling_emails', [])
      : []

    let billingEmails = _.get(introductory, 'has_billing_email', false)
      ? _.get(introductory, 'billing_emails', [])
      : []

    _.forIn(params, function(value, key) {
      if (key.startsWith('schedulingEmail_') && value) {
        if (schedulingEmails[key.slice(-1)]) {
          schedulingEmails[key.slice(-1)].email = value
        } else {
          schedulingEmails[key.slice(-1)] = {
            email: value
          }
        }
      } else if (key.startsWith('billingEmail_') && value) {
        if (billingEmails[key.slice(-1)]) {
          billingEmails[key.slice(-1)].email = value
        } else {
          billingEmails[key.slice(-1)] = {
            email: value
          }
        }
      }
    })

    return {
      personParam: {
        email: params.email
      },
      emailParam: {
        has_scheduling_email: schedulingEmails.length > 0,
        scheduling_emails: schedulingEmails,
        has_billing_email: billingEmails.length > 0,
        billing_emails: billingEmails
      }
    }
  }

  const cancelEdit = () => {
    basicFormRef.resetFields()
    emailFormRef.resetFields()
    addressFormRef.resetFields()
    insuranceFormRef && insuranceFormRef.resetFields()
    setState((prevState) => ({ ...prevState, editing: false }))
  }

  const submit = async () => {
    setState((prevState) => ({ ...prevState, updating: true }))
    try {
      const params = _.cloneDeep(await basicFormRef.handleSubmit())
      const emailParams = await emailFormRef.handleSubmit()
      const { personParam, emailParam } = convertEmailParams(emailParams)
      _.assign(params, personParam)
      let addressParams = await addressFormRef.handleSubmit()
      addressParams = _.pickBy(addressParams, (obj) => obj.address)
      const emergencyParams = await emergencyFormRef.handleSubmit()
      let insuranceParams = {}
      if (insuranceFormRef) {
        insuranceParams = await insuranceFormRef.handleSubmit()
      }
      const editProfileResult = await editClient(params, user.id)

      if (introductory) {
        await saveIntroductory(
          introductory.id,
          _.assign(addressParams, emergencyParams, emailParam, insuranceParams)
        )
      }

      setState((prevState) => ({
        ...prevState,
        updating: false,
        editing: false,
        user: editProfileResult.team.person
      }))
      if (updateSync) {
        updateSync(editProfileResult.team.person)
      }
    } catch (err) {
      console.error(err)
      setState((prevState) => ({
        ...prevState,
        updating: false,
        needupdateHeader: true
      }))
    }
  }

  useEffect(() => {
    if (user) {
      user.profile.date_of_birth = (user.profile.date_of_birth || '').substr(
        0,
        10
      )
      setState((prevState) => ({ ...prevState, user, editedUserInfo: user }))
    }
  }, [user])

  const {
    editing,
    logisticsSent,
    logisticsLoading,
    editedUserInfo,
    medicalReleaseSending,
    medicalReleaseSent
  } = state

  const formData = basicInfo({ editing, loginRole })

  const { emailFormData, emailValue } = introductoryEmailInfo({
    disabled: !editing,
    email: _.get(user, 'email', null),
    introductory: _.cloneDeep(introductory)
  })

  const { addressFormData } = introductoryAddressInfo({
    disabled: !editing,
    introductory
  })

  const { emergencyFormData } = introductoryEmergencyInfo({
    disabled: !editing
  })

  const insuranceInfo = introductoryInsuranceInfo(editing)

  if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
    formData.push({
      type: 'select',
      label: 'MEMBERSHIP',
      dbField: ['profile', 'membership'],
      colSpan: 12,
      disabled: !editing,
      options: [
        { value: 'insights', label: 'Healthspan Insights' },
        { value: 'body recomposition', label: 'Body Recomposition' },
        { value: 'evaluation2', label: 'Healthspan Assessment' },
        { value: 'gold', label: 'Gold Membership' },
        { value: 'silver', label: 'Silver Membership' },
        { value: 'bronze', label: 'Bronze Membership' },
        { value: 'remote', label: 'Remote Membership' },
        { value: 'remote lite', label: 'Remote Lite Membership' },
        { value: 'core', label: 'Core A La Carte' },
        { value: 'core+', label: 'Core' },
        { value: 'lite', label: 'Apeiron Lite' },
        { value: 'module', label: 'Module Membership' }
      ]
    })
    formData.push({
      type: 'select',
      options: [
        { value: 'annually', label: 'Annually' },
        {
          value: 'quarterly',
          label: 'Quarterly',
          disabled:
            editedUserInfo &&
            (editedUserInfo.profile.membership === 'evaluation' ||
              editedUserInfo.profile.membership === 'evaluation2')
        }
      ],
      dbField: ['profile', 'billing_preference'],
      label: 'BILLING PREFERENCE',
      disabled: (loginRole || 'User') === 'User' || !editing,
      colSpan: 12
    })
  }

  return (
    <div className={style}>
      <div className="title">
        <span>Profile</span>
        {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
          <div>
            <Button
              type="primary"
              ghost
              onClick={sendMedicalRelease}
              disabled={medicalReleaseSent}
              loading={medicalReleaseSending}
            >
              <SendEmailIcon />
              Send Medical Release
            </Button>
            <Button
              style={{
                marginLeft: 15
              }}
              type="primary"
              onClick={sendEmail}
              disabled={logisticsSent}
              loading={logisticsLoading}
            >
              <SendEmailIcon />
              Send Onboarding Flow
            </Button>
          </div>
        )}
      </div>
      <div className={userInfoStyle}>
        <div className="box-view user-avatar-container">
          {user && user.avatar && user.avatar.oss_url ? (
            <img src={user.avatar.oss_url} alt="" className="user-avatar" />
          ) : (
            <div className="user-avatar" style={{ background: '#E5E5E5' }} />
          )}
          {loginRole !== 'Expert' && (
            <Upload
              name="avatar"
              customRequest={customRequest}
              showUploadList={false}
            >
              <Button className="box-button">Change my photo</Button>
            </Upload>
          )}
        </div>
        {user && (
          <div className="box-view user-info-box">
            <div className="user-basic-info">
              <div className="top">
                <span className="title">Basic Information</span>
                {editing ? (
                  <div className="edit">
                    <span
                      onClick={cancelEdit}
                      style={{
                        color: 'rgba(38, 67, 130, 0.5)',
                        marginRight: 22
                      }}
                    >
                      Cancel
                    </span>
                    <span onClick={submit}>Done</span>
                  </div>
                ) : (
                  <div
                    className="edit"
                    onClick={() =>
                      setState((prevState) => ({ ...prevState, editing: true }))
                    }
                  >
                    <img
                      src={editIcon}
                      alt=""
                      style={{
                        marginRight: 14,
                        paddingBottom: 3
                      }}
                    />
                    Edit
                  </div>
                )}
              </div>
              <BasicForm
                formData={formData}
                normalLayout
                initialValues={user}
                wrappedComponentRef={(ref) => setBasicFormRef(ref)}
                onChange={(changeValue, AllValues) => {
                  setState((prevState) => ({
                    ...prevState,
                    editedUserInfo: AllValues
                  }))
                  if (changeValue.profile && changeValue.profile.membership) {
                    if (changeValue.profile.membership.includes('evaluation')) {
                      basicFormRef.setFieldValue(
                        ['profile', 'billing_preference'],
                        'annually'
                      )
                    }
                  }
                }}
              />
            </div>
            <div className="user-basic-info">
              <div className="top">
                <span className="title">email information </span>
              </div>
              {introductory && (
                <BasicForm
                  formData={emailFormData}
                  normalLayout
                  initialValues={emailValue}
                  wrappedComponentRef={(ref) => setEmailFormRef(ref)}
                />
              )}
            </div>

            <div className="user-basic-info">
              <div className="top">
                <span className="title">address information </span>
              </div>
              {introductory && (
                <BasicForm
                  formData={addressFormData}
                  normalLayout
                  initialValues={introductory}
                  wrappedComponentRef={(ref) => setAddressFormRef(ref)}
                />
              )}
            </div>

            <div className="user-basic-info">
              <div className="top">
                <span className="title">emergency contact </span>
              </div>
              {introductory && (
                <BasicForm
                  formData={emergencyFormData}
                  normalLayout
                  initialValues={introductory}
                  wrappedComponentRef={(ref) => setEmergencyFormRef(ref)}
                />
              )}
            </div>

            {_.get(introductory, 'insurance_type') === 'manual' && (
              <div className="user-basic-info">
                <div className="top">
                  <span className="title">insurance information </span>
                </div>
                {introductory && (
                  <BasicForm
                    normalLayout
                    formData={insuranceInfo}
                    initialValues={introductory}
                    wrappedComponentRef={(ref) => setInsuranceFormRef(ref)}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default UserInfo
