import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Button } from 'antd'
import Header from './header'
import Location from './location'
import {
  getCheckInFacility,
  setCheckInSercive,
  getCheckIn
} from '../../../utils/storage'

class Welcome extends Component {
  static propTypes = {}

  state = {
    selected: null,
    facility: null
  }

  select = (item) => {
    this.setState({ selected: item.id })
  }

  selectSercive = () => {
    const { selected } = this.state
    setCheckInSercive(selected)
    this.props.history.push('/check-in/expert')
  }

  componentDidMount() {
    const facility = getCheckInFacility()
    const checkIn = getCheckIn()
    this.setState({ facility, checkIn })
  }

  render() {
    const { selected, facility, checkIn } = this.state
    return (
      <div className="welcome">
        <Header history={this.props.history} />
        {facility && <Location location={facility.name} />}
        {checkIn && checkIn.visited && checkIn.avatar && (
          <img
            className="check-in-avatar"
            src={checkIn.avatar.oss_url}
            alt="avatar"
          />
        )}
        <p className="check-title">
          {checkIn && checkIn.visited ? 'Welcome back' : 'Thanks'}{' '}
          {checkIn && (
            <span className="first-name">
              {(checkIn.profile && checkIn.profile.nickname) ||
                checkIn.first_name ||
                checkIn.full_name.split(' ')[0]}
            </span>
          )}
          !
          <br />
          {facility && facility.services.length === 0
            ? 'Please contact Apeiron Life to add services.'
            : 'What type of appointment are you here for?'}
        </p>
        <ul className="check-list">
          {facility &&
            facility.services.map((item, index) => (
              <li
                key={index}
                className={selected === item.id ? 'selected' : ''}
                onClick={() => this.select(item)}
              >
                {item.name}
              </li>
            ))}
        </ul>
        <br />
        {selected && <Button onClick={this.selectSercive}>Next</Button>}
        {/* {facility && <Location location={facility.name} />} */}
      </div>
    )
  }
}

export default Welcome
