import CSV from '../asserts/icon/file/csv.png'
import DOC from '../asserts/icon/file/doc.png'
import MP4 from '../asserts/icon/file/mp4.png'
import PDF from '../asserts/icon/file/pdf.png'
import PPT from '../asserts/icon/file/ppt.png'
import TEXT from '../asserts/icon/file/text.png'
import COMMON from '../asserts/icon/file/file.png'

export default function getFileIcon(name) {
  const type = name.split('.').pop()
  switch (true) {
    case /csv/.test(type):
      return CSV
    case /doc|docx/.test(type):
      return DOC
    case /mp4/.test(type):
      return MP4
    case /pdf/.test(type):
      return PDF
    case /ppt/.test(type):
      return PPT
    case /txt/.test(type):
      return TEXT
    default:
      return COMMON
  }
}
