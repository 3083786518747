import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
import _ from 'lodash'
import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { datasetTypes as types } from '../../UI/clientSlideMenu/menu'
import MenuIcon from '../../../asserts/icon/permanent-menu.svg'
import PolygonAvatar from '../../../components/UI/polygonAvatar'
import Drawer from 'antd-mobile/lib/drawer'
import List from 'antd-mobile/lib/list'
import Accordion from 'antd-mobile/lib/accordion'
import 'antd-mobile/lib/drawer/style/css'
import 'antd-mobile/lib/list/style/css'
import 'antd-mobile/lib/accordion/style/css'

import { headerStyle } from './mobile-header.sass'

// const types = [
//   {
//     name: 'Body Composition & Vitals',
//     type: 'body-comp',
//     menuKey: 'body_comp'
//   },
//   {
//     name: 'Lifestyle',
//     menuKey: 'lifestyle_assessment',
//     type: 'life-style'
//   },
//   {
//     name: 'Blood Tests',
//     type: 'blood-test',
//     menuKey: 'bio_marker'
//   },
//   {
//     name: 'Initial 360 Physical Evaluation',
//     menuKey: 'physical_assessment',
//     type: 'physical'
//   },
//   {
//     name: 'Cardiovascular Fitness',
//     menuKey: 'cardiorespiratory',
//     type: 'cardiorespiratory'
//   },
//   {
//     name: 'Cognitive Healths',
//     menuKey: 'cognitive_health',
//     type: 'cognitive_health'
//   }
// ]
export class MobileHeader extends Component {
  static propTypes = {
    person: PropTypes.object,
    bioMarkersCategories: PropTypes.array,
    selectCategory: PropTypes.string,
    syncSelectCategory: PropTypes.func,
    showDetail: PropTypes.bool,
    detailMetric: PropTypes.object,
    title: PropTypes.any,
    hideHeader: PropTypes.bool,
    linkTo: PropTypes.func
  }

  state = {
    open: false
  }

  onOpenChange = (...args) => {
    this.setState({ open: !this.state.open })
  }

  getSideBar = () => {
    const { groups, linkTo, url } = this.props
    const targetType = _.find(types, (item) => _.includes(url, item.path)) || {}
    const selectCategory =
      (this.props.selectCategory !== 'undefined' &&
        this.props.selectCategory) ||
      _.get(groups, `${targetType.menuKey}.categories[0].name`)

    return (
      groups && (
        <List>
          {types.map((item) => (
            <List.Item multipleLine key={item.key}>
              <Accordion
                defaultActiveKey={
                  targetType.menuKey === item.menuKey ? '0' : null
                }
              >
                <Accordion.Panel header={item.menuTitle}>
                  {(groups[item.menuKey] &&
                    groups[item.menuKey].categories.map((category, index) => (
                      <div
                        key={index}
                        className={`menu-item ${
                          selectCategory && category.name === selectCategory
                            ? 'active'
                            : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            open: false
                          })
                          if (window.webkit) {
                            window.webkit.messageHandlers.jsBridgeDatasetTitle.postMessage(
                              {
                                title: item.name
                              }
                            )
                          }

                          linkTo && linkTo(item.path, category.name)
                        }}
                      >
                        {category.name}
                      </div>
                    ))) || <div>empty category</div>}
                </Accordion.Panel>
              </Accordion>
            </List.Item>
          ))}
        </List>
      )
    )
  }

  render() {
    const { open } = this.state
    const { person, title, hideHeader, back, showDetail } = this.props
    return (
      <div className={headerStyle}>
        {person && (
          <>
            {!hideHeader && (
              <div className="header">
                <h1>Your test result</h1>
                <PolygonAvatar
                  width={60}
                  image={person.avatar && person.avatar.oss_url}
                  innerId={`mobile-header-inner-${person.id}`}
                  hexId={`mobile-header-hex-${person.id}`}
                />
              </div>
            )}
            <div className="menu">
              {showDetail ? (
                <LeftOutlined
                  style={{ fontSize: 25 }}
                  onClick={() => {
                    this.setState({ open: false })
                    back && back()
                  }} />
              ) : open ? (
                <CloseOutlined
                  style={{ fontSize: 25 }}
                  onClick={() => {
                    this.setState({ open: false })
                  }} />
              ) : (
                <img
                  className="menu-icon"
                  src={MenuIcon}
                  alt="menu"
                  onClick={() => {
                    this.setState({ open: true })
                  }}
                />
              )}

              <div className="page-title">{title}</div>
            </div>
          </>
        )}
        <Drawer
          sidebar={this.getSideBar() || <div />}
          enableDragHandle
          style={{
            top: hideHeader ? 50 : 150
          }}
          contentStyle={{
            color: '#A6A6A6',
            textAlign: 'center'
          }}
          open={this.state.open}
          onOpenChange={this.onOpenChange}
        >
          <div className="is-momentumScrollable">{this.props.children}</div>
        </Drawer>
      </div>
    )
  }
}

export default MobileHeader
// connect(
//   ({ slideMenu }) => ({ slideMenu }),
//   null
// )(MobileHeader)
