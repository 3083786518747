import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import ContainerWrapper from '../../components/business/shareLink/wapper'
// import Container from '../../components/business/shareLink/container'
import MobileContainer from '../../components/business/shareLink/mobileContainer'

// import DesktopUserInfo from '../../components/business/shareLink/desktopUserInfo'
import MobileHeader from '../../components/business/shareLink/mobileHeader'

// const ShareLinkContainer = ContainerWrapper(Container)
const ShareLinkMobileContainer = ContainerWrapper(MobileContainer)

class Dataset extends Component {
  static propTypes = {}

  state = {}

  linkTo = (type, category) => {
    const { history, hideHeader, hash } = this.props
    let targetPatch = `/share-link/${type}/${hash}`
    if (category) {
      targetPatch += `/${category}`
    }
    if (hideHeader) {
      targetPatch = '/ios' + targetPatch
    }
    this.setState({ switchData: true })
    setTimeout(() => this.setState({ switchData: false }), 100)
    history.push(targetPatch)
  }

  render() {
    const {
      match: {
        params: { category },
        url
      },
      type,
      hash,
      person,
      setClientBiomarkerCategories,
      history,
      hideHeader,
      setSubMenu,
      slideMenu: { groups }
    } = this.props
    const { title, switchData } = this.state //selectCategory
    const selectCategory = decodeURI(category)
    return (
      <div>
        {person && groups && (
          <>
            {/* <div className="desktop-show">
              <DesktopUserInfo person={person} />
              <ShareLinkContainer
                {...{
                  hash,
                  type,
                  person,
                  setClientBiomarkerCategories,
                  category,
                  history,
                  url,
                  setSubMenu
                }}
              />
            </div> */}
            {/* <div className="mobile-show"> */}
            <MobileHeader
              url={url}
              person={person}
              title={title}
              hideHeader={hideHeader}
              linkTo={this.linkTo}
              selectCategory={selectCategory}
              groups={groups}
            >
              {/* ShareLinkMobileContainer */}
              <ShareLinkMobileContainer
                {...{
                  hash,
                  type,
                  person,
                  setClientBiomarkerCategories,
                  category,
                  history,
                  url,
                  setSubMenu,
                  switchData,
                  groups
                }}
                setMobileHeaderState={(state) => this.setState({ ...state })}
              />
            </MobileHeader>
            {/* </div> */}
          </>
        )}
      </div>
    )
  }
}

export default Dataset
