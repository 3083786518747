import React, { useState, useEffect } from 'react'
import { Input, Select } from 'antd'

export default function Additional(props) {
  const { items, anArr, update, verify } = props

  const [values, setValues] = useState([])

  useEffect(() => {
    setValues(anArr)
  }, [anArr])

  const onChange = (value, index) => {
    values[index] = value
    setValues(values)
    update(values)
  }

  return (
    <>
      {(items || []).map((item, index) => {
        return (
          <div key={`additional_${index} `} className="additional-item">
            {item.type === 'text_entry' && (
              <Input
                placeholder={item.title || 'Please type here'}
                className={`q-form-item ${
                  verify && verify[index] ? 'invalid' : ''
                }`}
                value={values[index]}
                // defaultValue={(_.isString(anArr[index]) && anArr[index]) || ''}
                onChange={(e) => onChange(e.target.value, index)}
              />
            )}
            {item.type === 'select' && (
              <Select
                placeholder={item.title || 'Please select'}
                className={`q-form-item ${
                  verify && verify[index] ? 'invalid' : ''
                }`}
                onChange={(value) => onChange(value, index)}
                value={values[index] || undefined}
                options={item.options.map((option, _index) => ({
                  value: option,
                  label: option
                }))}
              />
            )}
            {verify && verify[index] && (
              <p className="invalid">{verify[index].message || 'Required'}</p>
            )}
          </div>
        )
      })}
    </>
  )
}
