import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TreeSelect } from 'antd'

const { SHOW_PARENT } = TreeSelect

class TreeSelectItem extends Component {
  static propTypes = {
    treeData: PropTypes.array,
    extraAttr: PropTypes.object
  }

  triggerChange = (value) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  componentDidMount() {
    const { value, initialValue } = this.props
    if (!value && initialValue) {
      this.triggerChange(initialValue)
    }
  }

  render() {
    const { treeData, extraAttr, value } = this.props
    const tProps = {
      treeData,
      showCheckedStrategy: SHOW_PARENT,
      onChange: this.triggerChange,
      value,
      ...extraAttr
    }
    return <TreeSelect {...tProps} />
  }
}

export default TreeSelectItem
