import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  font-size: 12px;
  color: #264382;
  background: rgba(38, 67, 130, 0.15);
  padding: 12px 14px;
  border-radius: 12px;
  margin: 16px 0;
  display: flex;
  div {
    margin-left: 10px;
  }
`

export default function TipBar(props) {
  return (
    <Container>
      <Icon />
      <div>{props.text}</div>
    </Container>
  )
}

function Icon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99999 0.801025C3.56222 0.801025 0.777771 3.58547 0.777771 7.02325C0.777771 10.461 3.56222 13.2455 6.99999 13.2455C10.4378 13.2455 13.2222 10.461 13.2222 7.02325C13.2222 3.58547 10.4378 0.801025 6.99999 0.801025ZM7.77777 10.111H6.22222V6.49997H7.77777V10.111ZM7.77777 5.49997H6.22222V3.8888H7.77777V5.49997Z"
        fill="#264382"
      />
    </svg>
  )
}
