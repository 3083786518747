import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import CompactView from './comp/healthyCompactView'
import {
  getHealthySignals,
  getHealthySignalsSettings,
  getHealthySignalsPercentageTarget,
  setHealthySignalsPercentageTarget
} from '../../api/people'

const HSDContainer = styled.div`
  h5 {
    color: #264382;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 600;
  }
`

export default function HealthySignals(props) {
  const { person } = props
  const [pageLoading, setPageLoading] = useState(false)
  // const [dateInfo, setDateInfo] = useState(null)
  const [data, setData] = useState(null)
  const [settings, setSettings] = useState([])
  const [targets, setTargets] = useState({})
  useEffect(() => {
    initial()
  }, [props.startDate])

  const cardList = settings.map(
    (item) =>
      item && {
        ...item,
        data: (data || {})[item.domain],
        target: targets[item.domain]
      }
  )

  const hightestValue = getHighestValue(cardList, false)

  return (
    <HSDContainer>
      <CompactView
        cardList={cardList}
        pageLoading={pageLoading}
        hightestValue={hightestValue}
        saveTargets={saveTargets}
        currentWeek={props.startDate}
      />
    </HSDContainer>
  )

  async function saveTargets(target, value) {
    const healthy_signals_percentage_target = _.cloneDeep(targets)
    healthy_signals_percentage_target[target] = value

    await setHealthySignalsPercentageTarget({
      today: moment().format('YYYY-MM-DD'),
      person_id: person.id,
      domain: target,
      target: value
    })

    setTargets(healthy_signals_percentage_target)
  }

  function getHighestValue(list, needMore) {
    if (!list) return
    let array = list.map((item) => {
      const { target, data } = item
      let result = [target]
      if (data) {
        result = [target, data.seg_pct]
        if (needMore) {
          result.push(data.more_seg_pct_avg)
        }
      }
      return result
    })
    array = [].concat.call([0], ...array)
    return Math.round(Math.max(...array))
    // return Math.round(Math.max(...array) * 1.25)
  }

  async function initial() {
    const { startDate: from, endDate: to } = props
    if (settings.length === 0) {
      const { settings } = await getHealthySignalsSettings()
      setSettings(settings)
    }
    const result = await getHealthySignalsPercentageTarget(
      person.id,
      moment(from).format('YYYY-MM-DD')
    )
    const targets = {}
    _(result).forEach((item) => {
      targets[item.domain] = item.target
    })
    setTargets(targets)
    await fetchData(from, to)
  }

  async function fetchData(from, to) {
    const { person } = props
    setPageLoading(true)
    const result = await getHealthySignals({
      personId: person.id,
      from: moment(from).format('YYYY-MM-DD'),
      to: moment(to).format('YYYY-MM-DD')
    })
    // setDateInfo({ from, to })
    setData(result.week_data)
    setPageLoading(false)
  }
}
