import React, { Fragment, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { HSDSkipHistoryDateIcon } from '../../../asserts/svgWrappers/home'
import moment from 'moment'
import _ from 'lodash'

export const Container = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0px 10px 0px 0px;
  .skip-date-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    .special-date-container {
      display: flex;
      align-items: center;
      text-align: center;
      & > div:first-child {
        width: 38px;
        font-size: 11px;
        color: #8596a4;
        letter-spacing: 0.21px;
        font-weight: 600;
      }
    }
    .separate-line {
      width: 30px;
      height: 1px;
      /* display: inline-block; */
      border: 0.5px solid #e3e6ea;
      margin: 6px 5px 20px;
    }
    .separate-year {
      background-color: rgba(133, 150, 164, 0.3);
      width: 16px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      & > span {
        display: inline-block;
        transform: rotate(-90deg);
        color: rgba(133, 150, 164, 1);
        font-size: 10px;
      }
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
  }
  .arrow {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 174px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    span {
      display: none;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background: #ffffff;
      border: 1px solid #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      text-align: center;
      line-height: 40px;
    }
    &:hover {
      span {
        display: inline-block;
      }
    }
  }
  .arrow-left {
    left: 0;
    &:hover {
      background: linear-gradient(
        90deg,
        #ffffff 11.13%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .arrow-right {
    right: 0;
    justify-content: flex-end;
    &:hover {
      background: linear-gradient(
        270deg,
        #ffffff 11.13%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
`

export default function HSDSkipHistory({ notFocusActivityFeedRecord }) {
  const [position, setPosition] = useState({ left: 0 })
  const ref = React.createRef()

  const slide = (direction) => {
    const element = ref.current
    const { clientWidth, scrollWidth } = element
    const { left } = position

    let newPosition = {}
    if (direction === 'right') {
      if (scrollWidth + (left - clientWidth) <= clientWidth + 52) {
        // 52 is parent padding
        newPosition = {
          right: 0,
          left: clientWidth - scrollWidth
        }
      } else {
        newPosition = { left: left - clientWidth }
      }
    } else {
      // scroll left
      newPosition = {
        left: left + clientWidth > 0 ? 0 : left + clientWidth
      }
    }
    setPosition(newPosition)
  }

  const groupedByYear = _.groupBy(
    notFocusActivityFeedRecord.map((item) => item.end),
    (date) => date.split('-')[0]
  )

  const transformed = _.map(groupedByYear, (datesArray, year) => ({
    [year]: datesArray
  }))

  return (
    <Container>
      {position.left !== 0 && (
        <div className="arrow arrow-left">
          <span onClick={() => slide('left')}>
            <LeftOutlined />
          </span>
        </div>
      )}
      <div className="skip-date-container" style={position} ref={ref}>
        {transformed.map((obj) => {
          const key = _.keys(obj)[0]
          const value = _.values(obj)[0]
          return value.map((d, index) => {
            if (index === 0) {
              return (
                <Fragment key={index + key}>
                  <div className="separate-year">
                    <span>{key}</span>
                  </div>
                  <div className="special-date-container">
                    <div>
                      <HSDSkipHistoryDateIcon />
                      <div>{moment(d, 'YYYY-MM-DD').format('MMM D')}</div>
                    </div>
                    {index < value.length - 1 && (
                      <div className="separate-line" />
                    )}
                  </div>
                </Fragment>
              )
            } else {
              return (
                <div className="special-date-container" key={index + key}>
                  <div>
                    <HSDSkipHistoryDateIcon />
                    <div>{moment(d, 'YYYY-MM-DD').format('MMM D')}</div>
                  </div>
                  {index < value.length - 1 && (
                    <div className="separate-line" />
                  )}
                </div>
              )
            }
          })
        })}
      </div>
      {position.right !== 0 && notFocusActivityFeedRecord.length > 9 && (
        <div className="arrow arrow-right">
          <span onClick={() => slide('right')}>
            <RightOutlined />
          </span>
        </div>
      )}
    </Container>
  )
}
