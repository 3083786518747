import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Input } from 'antd'

const { TextArea } = Input

class TextInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    disabled: PropTypes.bool
  }

  state = {
    value: null
  }

  onKeyDown = async (event) => {
    const target = event.target
    if (event.keyCode === 13) {
      await this.props.saveEntry(event.target.value)
      target && target.blur()
      return false
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ value })
  }

  render() {
    const { value } = this.state
    return (
      <div className="text-input">
        <TextArea
          placeholder="n/a"
          autoSize={{ minRows: 2 }}
          disabled={this.props.disabled}
          value={value}
          onChange={(event) => this.setState({ value: event.target.value })}
          onBlur={() => this.setState({ value: this.props.value })}
          onKeyDown={this.onKeyDown}
        />
      </div>
    )
  }
}

export default TextInput
