import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import { Button, message, Checkbox } from 'antd'
import BasicForm from '../../components/formV4'
import { signInForm } from '../../models/signIn.form'
import { login } from '../../api/people'
import { getSupportRoles } from '../../api/team'
import { SignPanelContainer } from './style'
import { getRole, getBlogUrl } from '../../utils/common'
import { getCacheURL, removeCacheURL, setCacheURL } from '../../utils/storage'
import loginBg from '../../asserts/images/login-bg.png'
import phsLogo from '../../asserts/icon/latest-logo.svg'

class SigninPage extends Component {
  state = {
    loading: false,
    disabled: false,
    formValue: {},
    keep: false,
    checkedValue: true,
    checkError: false
  }

  componentDidMount() {
    // const keep = getKeepLogged()
    // if (keep) {
    //   this.setState({ keep: true })
    // }
    const env = process.env.REACT_APP_WORKFLOW
    let marketingOrigin = 'https://staging.apeiron.life'
    if (env === 'production' || env === 'matterme') {
      marketingOrigin = 'https://www.apeiron.life'
    }
    this.setState({ marketingOrigin })
  }

  signin = async () => {
    const { checkedValue, basicFormRef } = this.state
    if (!checkedValue) {
      return this.setState({ checkError: true })
    }
    this.setState({
      loading: true,
      disabled: true
    })
    // if (keep) {
    //   setKeepLogged(true)
    // } else {
    //   removeKeepLogged()
    // }

    try {
      const params = await basicFormRef.handleSubmit()
      params.app = 'dashboard'
      const {
        setLoginAccount,
        history,
        location: { search }
      } = this.props
      const person = await login(params, setLoginAccount)
      const role = getRole(person.role)
      const path = getCacheURL()

      if (search.match('blogpath')) {
        // set cookie for api
        await getSupportRoles()
        const blogpath = search.split('=')[1]
        window.open(getBlogUrl(blogpath))
      }

      if (path) {
        removeCacheURL()
      }
      switch (role) {
        case 'Manager':
          history.push(path || '/')
          break
        case 'Expert':
        case 'Service_Provider':
        case 'Coach':
        case 'InternalAdmin':
          if (person.profile.password_reset_required) {
            if (path) setCacheURL(path)
            history.push('/change-password')
          } else {
            history.push(path || '/client')
          }

          break
        case 'Assistant':
        case 'User':
          let targetUrl = '/'
          const {
            profile: {
              password_reset_required,
              enabled_2fa,
              onboarding_steps: { onboarding_required },
              membership_selected
            }
          } = person

          if (password_reset_required) {
            targetUrl = '/change-password'
            if (path) setCacheURL(path)
          } else if (path) {
            if (
              onboarding_required &&
              path === '/app/setting/view-signed-forms'
            ) {
              targetUrl = '/redirect-sign-waiver-form'
            } else {
              targetUrl = path
            }
          } else if (enabled_2fa) {
            targetUrl = '/2fa'
          } else if (!membership_selected) {
            targetUrl = '/membership-selection'
          } else if (onboarding_required) {
            targetUrl = '/onboarding/ready'
          }
          history.push(targetUrl)
          break
        default:
          throw new Error('You do not have enough role to access')
      }
    } catch (error) {
      console.error(error)
      if (error.statusCode === 404) {
        message.error(error.message)
      }
      this.setState({
        loading: false,
        disabled: false
      })
    }
  }

  render() {
    const { marketingOrigin, checkedValue, checkError } = this.state
    return (
      <SignPanelContainer>
        <div className="left">
          <img
            src={phsLogo}
            alt="logo"
            style={{
              position: 'absolute',
              left: '36px',
              top: '20px',
              width: '150px'
            }}
          />
          <h1 className="title">Welcome to Apeiron Life</h1>
          <p className="text">
            Maximizing Healthspan through Human Performance Optimization
          </p>
          <BasicForm
            wrappedComponentRef={(inst) =>
              this.setState({ basicFormRef: inst })
            }
            formData={signInForm}
            onEnter={this.signin}
          />
          <div className="opr">
            <span
              className={`check-tos ${checkError ? 'error' : ''}`}
              // onClick={() => this.setState({ keep: !keep })}
            >
              <Checkbox
                checked={checkedValue}
                onChange={(e) => {
                  let checkError = this.state.checkError
                  if (e.target.checked && checkError) {
                    checkError = false
                  }
                  this.setState({ checkedValue: e.target.checked, checkError })
                }}
              />
              I have read and agreed to the{' '}
              <a target="_bank" href={`${marketingOrigin}/terms-of-use`}>
                Terms of Use
              </a>
            </span>
            {checkError && (
              <span className="error-Message">
                {' '}
                Please accept the Terms of Use to continue
              </span>
            )}
            <Button
              type="primary"
              className="submit-button"
              onClick={this.signin}
              disabled={this.state.disabled}
              loading={this.state.loading}
            >
              Connect
            </Button>
          </div>

          <div className="forget">
            Forgot password?{' '}
            <span
              onClick={() => this.props.history.push('/password-reset/verify')}
            >
              Recover here
            </span>
          </div>
          <div className="email-info">
            Never signed into your account before?
            <br />
            Email{' '}
            <a href="mailto:concierge@apeiron.life">
              concierge@apeiron.life
            </a>{' '}
            and we’ll set you up.
          </div>
          {/* <p className="tos-pp">
            By continuing, you agree to ApeironLife’s{' '}
            <a target="_bank" href={`${marketingOrigin}/terms-of-use`}>
              Terms of Use
            </a>{' '}
            and{' '}
            <a href={`${marketingOrigin}/privacy-policy`} target="_bank">
              Privacy Policy
            </a>
          </p> */}
        </div>
        <div className="right">
          <img src={loginBg} alt="bg" />
        </div>
      </SignPanelContainer>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(SigninPage)
