import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import moment from 'moment'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryScatter,
  VictoryLabel
} from 'victory'
// import { RangeBar as RangeBar1 } from './bodyComp'
// import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { ValueIcon } from './bodyComp'
import { ChartContainer } from '../sections/aerobic'
import { RangeColors } from '../../../utils/constant'
import { Divider } from 'antd'
import HideWrap from './hideWrap'

import Grip from '../../../asserts/images/placeholder/grip-strength.svg'
import Pull from '../../../asserts/images/placeholder/pull-ups.svg'
import Push from '../../../asserts/images/placeholder/push-ups.svg'
import Chart from '../../../asserts/images/placeholder/chart-placeholder.png'
import FrontPlank from '../../../asserts/images/placeholder/front-plank.svg'
import BackExtension from '../../../asserts/images/placeholder/back-extension.svg'
import LeftBridge from '../../../asserts/images/placeholder/left-bridge.svg'
import RightBridge from '../../../asserts/images/placeholder/right-bridge.svg'
import HipAbduction from '../../../asserts/images/placeholder/hip-abduction.svg'
import HipAdduction from '../../../asserts/images/placeholder/hip-adduction.svg'
import KneeFlexion from '../../../asserts/images/placeholder/knee-flexion.svg'
import KneeExtension from '../../../asserts/images/placeholder/knee-extension.svg'
import FlexionExtensionRatioLeft from '../../../asserts/images/placeholder/flexion-extension-ratio-left.svg'
import FlexionExtensionRatioRight from '../../../asserts/images/placeholder/flexion-extension-ratio-right.svg'

import SitToStand from '../../../asserts/images/placeholder/sit-to-stand.svg'

const StrengthContainer = styled.div`
  .range-list {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(50, 58, 70, 0.5);
    li {
      padding-left: 24px;
    }
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
  .bg-container {
    height: 247px;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .bg-placeholder {
      width: 400px;
      height: 150px;
      background: #223064;
      opacity: 0.1;
      border-radius: 17px;
    }
  }
  .chart-container {
    width: 500px;
    // height: 275px;
    border-bottom: 1px solid #e3e6ea;
    padding-top: 30px;
    &:last-child {
      border: none;
    }
    img {
      vertical-align: middle;
    }
    .metric-info {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      font-size: 15px;
      color: #4c6072;
      & > div {
        margin: 30px 0;
        margin-bottom: 10px;
      }
      .ant-divider-vertical {
        height: 60px;
      }
      .currently,
      .previously {
        line-height: 21px;
        max-width: 180px;
      }
      .currently {
        width: 150px;
      }
      .value {
        font-size: 24px;
        margin-top: 10px;
        .anticon {
          font-size: 14px;
        }
      }
      .previously > div {
        border-left: 1px solid #e2f2ff;
        padding-left: 35px;
      }
      .history {
        margin-right: -45px;
        margin-top: 10px;
      }
    }
  }
`

const { red, orange, yellow, green, emerald } = RangeColors
const Tips = {
  body: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'Very good',
      color: green
    },
    {
      name: 'Excellent',
      color: emerald
    }
  ],
  core: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'Very good',
      color: green
    },
    {
      name: 'Excellent',
      color: emerald
    }
  ],
  balance: [
    {
      name: 'Optimal',
      color: emerald
    },
    {
      name: 'Concern',
      color: yellow
    },
    {
      name: 'At risk',
      color: red
    }
  ],
  dsi: [
    {
      name: 'low',
      color: red
    },
    {
      name: 'moderate',
      color: yellow
    },
    {
      name: 'High',
      color: green
    }
  ]
}

export function UpperBody(props) {
  const {
    metrics: {
      combined_grip_strength,
      pull_up,
      hang_time,
      hang_90,
      push_up,
      trunk_isometric_flexion_plank,
      trunk_isometric_extension_back,
      trunk_side_bridge_left,
      trunk_side_bridge_right
    },
    person,
    hideSectionHandle,
    hideSection,
    view,
    ageScopes
  } = props
  const upperBodyStrength = [
    {
      bg: Grip,
      name: 'Grip Strength',
      metric: combined_grip_strength
    },
    {
      bg: Pull,
      metric: _.isNumber(pull_up.value)
        ? pull_up
        : _.isNumber(hang_90.value)
        ? hang_90
        : hang_time,
      name: _.isNumber(pull_up.value)
        ? 'Pull-ups'
        : _.isNumber(hang_90.value)
        ? 'Hang 90/90'
        : 'Hang-time'
    },
    {
      bg: Push,
      metric: push_up,
      name: 'Push-ups'
    }
  ]

  const coreStrength = [
    {
      bg: FrontPlank,
      name: 'Front Plank',
      metric: trunk_isometric_flexion_plank
    },
    {
      bg: BackExtension,
      name: 'Back Extension',
      metric: trunk_isometric_extension_back
    },
    {
      bg: LeftBridge,
      metric: trunk_side_bridge_left,
      name: 'Left Bridge'
    },
    {
      bg: RightBridge,
      metric: trunk_side_bridge_right,
      name: 'Right Bridge'
    }
  ]

  return (
    <StrengthContainer>
      <HideWrap
        name="Muscle Fitness - Upper body strength"
        title="Upper Body Strength"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <div className="sub-title">Upper Body Strength</div>
        <ChartContainer>
          <div>
            {upperBodyStrength.map((item, index) => (
              <div className="bg-container" key={index}>
                <div>
                  <img src={item.bg} alt="bg" />
                </div>
              </div>
            ))}
          </div>
          <div>
            <ul className="range-list">
              {Tips.body.map((item, index) => (
                <li key={index}>
                  <span style={{ background: item.color }} /> {item.name}
                </li>
              ))}
            </ul>

            {upperBodyStrength.map((item, index) => (
              <div className="chart-container" key={index}>
                {item.metric ? (
                  <MetricContainer
                    metric={{ ...item.metric, name: item.name }}
                    person={person}
                    ageScopes={ageScopes}
                  />
                ) : (
                  <img src={Chart} alt="bg" />
                )}
              </div>
            ))}
          </div>
        </ChartContainer>
      </HideWrap>

      <Divider />
      <HideWrap
        name="Muscle Fitness - Trunk Endurance"
        title="Trunk Endurance"
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <div className="sub-title">Trunk Endurance</div>
        <ChartContainer>
          <div>
            {coreStrength.map((item, index) => (
              <div className="bg-container" key={index}>
                {item.bg ? (
                  <div>
                    <img src={item.bg} alt="bg" />
                  </div>
                ) : (
                  <div className="bg-placeholder" />
                )}
              </div>
            ))}
          </div>
          <div>
            <ul className="range-list">
              {Tips.core.map((item, index) => (
                <li key={index}>
                  <span style={{ background: item.color }} /> {item.name}
                </li>
              ))}
            </ul>

            {coreStrength.map((item, index) => (
              <div className="chart-container" key={index}>
                {item.metric ? (
                  <MetricContainer
                    metric={{ ...item.metric, name: item.name }}
                    person={person}
                    ageScopes={ageScopes}
                  />
                ) : (
                  <img src={Chart} alt="bg" />
                )}
              </div>
            ))}
          </div>
        </ChartContainer>
      </HideWrap>
    </StrengthContainer>
  )
}

export function LowerBody(props) {
  const {
    metrics: {
      Sit_to_Stand,
      hip_abduction_total,
      hip_adduction_total,
      knee_extension_total,
      knee_flexion_extension_ratio_left,
      knee_flexion_extension_ratio_right,
      knee_flexion_total,
      hip_abduction_diff,
      hip_adduction_diff,
      knee_flexion_diff,
      knee_extension_diff
    },
    person,
    ageScopes
  } = props
  const lowerCodyStrength = [
    {
      bg: HipAbduction,
      name: 'Hip Abduction Total',
      metric: hip_abduction_total,
      difference: {
        name: 'Hip Abduction Difference',
        metric: { ...hip_abduction_diff, showUnit: true }
      }
    },
    {
      bg: HipAdduction,
      name: 'Hip Adduction Total',
      metric: hip_adduction_total,
      difference: {
        name: 'Hip Adduction Difference',
        metric: { ...hip_adduction_diff, showUnit: true }
      }
    },
    {
      bg: KneeFlexion,
      name: 'Knee Flexion Total',
      metric: knee_flexion_total,
      difference: {
        name: 'Knee Flexion Difference',
        metric: { ...knee_flexion_diff, showUnit: true }
      }
    },
    {
      bg: KneeExtension,
      name: 'Knee Extension Total',
      metric: knee_extension_total,
      difference: {
        name: 'Knee Extension Difference',
        metric: { ...knee_extension_diff, showUnit: true }
      }
    },
    {
      bg: FlexionExtensionRatioLeft,
      name: 'Flexion/Extension Ratio Left',
      metric: knee_flexion_extension_ratio_left,
      showRangeName: true
    },
    {
      bg: FlexionExtensionRatioRight,
      name: 'Flexion/Extension Ratio Right',
      metric: knee_flexion_extension_ratio_right,
      showRangeName: true
    },
    {
      bg: SitToStand,
      name: 'Sit to Stand',
      metric: Sit_to_Stand,
      showRangeName: true
    }
  ]

  return (
    <StrengthContainer>
      <ChartContainer>
        <div>
          {lowerCodyStrength.map((item, index) => (
            <div className="bg-container" key={index}>
              {item.bg ? (
                <div>
                  <img src={item.bg} alt="bg" />{' '}
                </div>
              ) : (
                <div className="bg-placeholder" />
              )}
            </div>
          ))}
        </div>
        <div>
          <ul className="range-list">
            {Tips.body.map((item, index) => (
              <li key={index}>
                <span style={{ background: item.color }} /> {item.name}
              </li>
            ))}
          </ul>

          {lowerCodyStrength.map((item, index) => (
            <div
              className="chart-container"
              key={index}
              // style={{ height: item.difference ? 313 : 275 }}
            >
              {item.metric ? (
                <MetricContainer
                  metric={{
                    ...item.metric,
                    name: item.name,
                    showRangeName: item.showRangeName
                  }}
                  difference={item.difference}
                  person={person}
                  ageScopes={ageScopes}
                />
              ) : (
                <img src={Chart} alt="bg" />
              )}
            </div>
          ))}
        </div>
      </ChartContainer>
    </StrengthContainer>
  )
}

export function DSIContainer(props) {
  const { dsi, person, ageScopes } = props

  return (
    <StrengthContainer>
      <ChartContainer>
        <div>
          <div className="bg-container">
            <div className="bg-placeholder" />
          </div>
        </div>
        <div>
          <ul className="range-list">
            {Tips.dsi.map((item, index) => (
              <li key={index}>
                <span style={{ background: item.color }} /> {item.name}
              </li>
            ))}
          </ul>
          <div className="chart-container">
            <MetricContainer
              metric={{
                ...dsi,
                name: 'DSI'
                // showRangeName: item.showRangeName
              }}
              // difference={item.difference}
              person={person}
              ageScopes={ageScopes}
            />
          </div>
        </div>
      </ChartContainer>
    </StrengthContainer>
  )
}

function MetricContainer(props) {
  const { metric, person, ageScopes, difference } = props
  const { series, name, unit, value, color } = metric
  let PreviousValue = series[series.length - 2]

  if (PreviousValue && PreviousValue.test_date) {
    PreviousValue = PreviousValue.value
  }

  const rangeMetric = difference ? difference.metric : metric
  return (
    <>
      {difference && difference.metric.ranges.length > 0 && (
        <div style={{ fontSize: 15, color: '#4c6072', paddingBottom: 15 }}>
          {difference.name}
        </div>
      )}
      <RangeBar
        {...rangeMetric}
        person={person}
        ageScopes={ageScopes}
        showRangeArea
        hideLevel
      />
      <div className="metric-info">
        <div className="currently">
          <div>{name}</div>
          {_.isNumber(value) ? (
            <div className="value" style={{ color: RangeColors[color] }}>
              {value} {unit}{' '}
              {_.isNumber(PreviousValue) && (
                <LegacyIcon
                  type={value >= PreviousValue ? 'caret-up' : 'caret-down'}
                />
              )}
            </div>
          ) : (
            <div className="value"> -- </div>
          )}
        </div>
        <Divider type="vertical" />
        {/* <div className="previously">
          <div>
            <div>Previous – {PreviousValue}</div>
            <div
              className="value"
              style={{ color: RangeColors[rangeMetric.color] }}
            >
              {rangeMetric.level}
            </div>
          </div>
        </div> */}

        <div className="history">
          <HistoryChart series={series} unit={unit} />
        </div>
      </div>
    </>
  )
}

const RangeBarContainer = styled.div`
  .ranges {
    display: flex;
    margin-top: 50px;
    margin-bottom: 15px;
    .range-item {
      height: 6px;
      border: 1px solid #ffffff;
      position: relative;
      .has-value {
        font-weight: 700;
        line-height: 1.2;
        margin-top: -57px;
        white-space: nowrap;
        .level,
        .value-number {
          display: inline-block;
          margin-left: 50%;
          transform: translateX(-50%);
        }
        .level {
          font-size: 16px;
        }
        .value-number {
          font-size: 20px;
          color: #4c6072;
          padding-bottom: 15px;
        }
      }
      .value {
        margin-top: -7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        span {
          font-size: 18px;
          color: #4c6072;
          font-family: Gilroy-Bold;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: -25px;
          white-space: nowrap;
        }
      }
    }
    .range-area {
      font-size: 12px;
      color: #4c6072;
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }
  }
`
export function RangeBar(props) {
  const {
    ranges,
    value,
    person,
    ageScopes,
    level,
    unit,
    showRangeName,
    showUnit,
    hideValue,
    color,
    showRangeArea,
    hideLevel
  } = props
  const gender = person.profile.gender
  const age = moment().diff(person.profile.date_of_birth, 'years')

  const targetScope = ageScopes.find(({ scope: [min, max] }) => {
    if (age >= min && age <= max) return true
    return false
  })

  const ageTag = targetScope && targetScope.tag

  let _ranges = _.cloneDeep(ranges)

  try {
    _ranges = ranges
      .map((range) => ({
        scope:
          range[`${gender}_age_scope`] && range[`${gender}_age_scope`][ageTag],
        name: range.name
      }))
      .filter((range) => range)
      .map(({ scope, name }) => {
        const [min, max, color] = scope
        return {
          min: min,
          max: max,
          color: RangeColors[color],
          name
        }
      })
      .sort((a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE))
  } catch (err) {
    _ranges = (ranges || [])
      .sort((a, b) => a.order - b.order)
      .map((range) => {
        range.color =
          range[`${gender}_age_scope`][ageTag] &&
          RangeColors[range[`${gender}_age_scope`][ageTag][2]]
        return range
      })
  }

  function renderValueIcon(item) {
    let iconStyle = {}
    if (showRangeArea) {
      iconStyle = getPointExtraStyle(value, item)
    }

    return (
      <div className="has-value">
        <div
          className="level"
          style={{
            color: hideValue || hideLevel ? 'transparent' : RangeColors[color],
            marginLeft: iconStyle ? iconStyle.left : null
          }}
        >
          {level}
        </div>
        <br />
        <div
          className="value-number"
          style={{
            opacity: hideValue ? 0 : 1,
            marginLeft: iconStyle ? iconStyle.left : null
          }}
        >
          {value}
          {unit}
        </div>
        <br />
        <ValueIcon
          color={item.color || '#8A969F'}
          style={iconStyle}
          hideValueNumber
          value={
            showRangeName
              ? item.name
              : value +
                (unit && (unit.toLocaleLowerCase() === 'kg' || showUnit)
                  ? unit
                  : '')
          }
        />
      </div>
    )
  }

  return (
    <RangeBarContainer>
      <div className="ranges">
        {_ranges.map((item, index) => (
          <div
            key={index}
            className={`${item.name} range-item`}
            style={{
              width: 100 / _ranges.length + '%',
              background: item.color || '#8A969F'
            }}
          >
            {level === item.name && renderValueIcon(item)}
            {showRangeArea && (
              <div className="range-area" style={{ color: '#4c6072' }}>
                {formatRangeArea(item)}
              </div>
            )}
          </div>
        ))}
      </div>
    </RangeBarContainer>
  )

  function getPointExtraStyle(value, range) {
    let left = 50
    let correctionOffset = -50
    if (range.max && range.min) {
      left = ((value - range.min) / (range.max - range.min)) * 100
    }
    if (Math.round(left) === 0) {
      correctionOffset = 0
    } else if (Math.round(left) === 100) {
      correctionOffset = -100
    }
    return {
      left: left + '%',
      transform: `translateX(${correctionOffset}%)`
    }
  }

  function formatRangeArea(range) {
    if (range.max && range.min) {
      return `${range.min}-${range.max}`
    } else if (range.max) {
      return `< ${range.max}`
    } else {
      return `> ${range.min}`
    }
  }
}

const axisStyle = {
  axis: { stroke: 'transparent' },
  grid: {
    stroke: 'transparent'
  },
  tickLabels: {
    fill: 'transparent'
  }
}
export function HistoryChart(props) {
  const { series, unit } = props
  const data = series
    .map((item, index) => {
      if (item && item.test_date) {
        return { x: index, y: item.value, date: item.test_date }
      } else {
        return { x: index, y: item }
      }
    })
    .filter((item) => _.isNumber(item.y))
  const values = data.map((item) => item.y) //.filter((item) => item)
  const minY = Math.min(...values),
    maxY = Math.max(...values)
  const tickValuesY =
    _.isFinite(minY) && _.isFinite(maxY) ? [minY - 10, maxY + 10] : []
  return (
    <VictoryChart
      width={230}
      height={100}
      padding={{
        top: 5,
        bottom: 25,
        left: 50,
        right: 50
      }}
    >
      <VictoryAxis
        // invertAxis={true}
        dependentAxis
        style={axisStyle}
        tickValues={tickValuesY}
      />
      <VictoryAxis style={axisStyle} />
      <VictoryLine
        data={data}
        style={{ data: { stroke: '#40C47C', strokeWidth: 3 } }}
      />
      <VictoryScatter
        data={data}
        dataComponent={<Dot />}
        labels={(datum) =>
          _.isNumber(datum.y) && [
            datum.y + unit,
            datum.date && moment(datum.date).format('M/D/YY')
          ]
        }
        labelComponent={
          <VictoryLabel
            dy={40}
            backgroundPadding={5}
            style={[
              {
                fill: '#4C6072',
                fontSize: 10,
                fontWeight: 600,
                fontFamily: 'Gilroy'
              },
              { fill: '#4C6072', fontSize: 10, fontFamily: 'Gilroy' }
            ]}
          />
        }
      />
    </VictoryChart>
  )
}

function Dot(props) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="5"
        cy="5"
        r="3.5"
        fill="white"
        stroke="#40C47C"
        strokeWidth="3"
      />
    </svg>
  )
}
