import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import PageLoading from '../../../UI/status/loading'
import RangesAreas from '../ranges'
import MetricRangesArea from '../ranges/metricArea'
import TrendGraph from '../../../trendGraph'
import MobileTrendGraph from '../../../trendGraph/mobile'
import { About, Lifestyle } from '../../../icons'
import { colors } from '../../../../utils/constant'
import { detailStyle } from './index.sass'
import Calendar from '../../../../asserts/icon/icon-calendar.svg'

class DatasetDetailContainer extends Component {
  static propTypes = {}

  renderRangeContainter() {
    const {
      biomarker,
      dataset,
      person,
      type,
      range,
      // ageScopes,
      functionalMatchRange,
      conventionalMatchRange
    } = this.props
    let childProps
    const personalData = biomarker && biomarker.personal_data
    if (type && type !== 'blood-test') {
      if (biomarker) {
        const value = biomarker.personal_data.value
        biomarker.ranges = biomarker.ranges || []
        childProps = {
          value,
          person,
          ranges: (biomarker && biomarker.ranges) || [],
          matchRange: range,
          type,
          metric: biomarker
        }
      }
    } else {
      childProps = {
        biomarker,
        person,
        functionalMatchRange,
        conventionalMatchRange,
        type,
        showConv: true, //personalData && personalData.prefer_conventional, // comment on sprint 23
        showFunc: false //personalData && personalData.prefer_functional // comment on sprint 23
      }
    }

    return (
      <div className="ranges-info">
        <div className="header desktop-show">
          <h1 className="result">
            Result
            <br />
            <span className="date">
              <img src={Calendar} alt="calendar" />
              {dataset
                ? moment(dataset.test_date.substr(0, 10)).format('MM/DD/YYYY')
                : '-/-/-'}
            </span>
          </h1>
        </div>
        <div className="ranges-area-container">
          {biomarker && biomarker.options ? (
            <div>
              {personalData && personalData.prefer_conventional && (
                <div className="options-container">
                  <div>
                    <span className="label">CONVENTIONAL</span>
                    <br />
                    {(conventionalMatchRange && conventionalMatchRange.name) ||
                      'N/A'}
                  </div>
                  <div
                    className="value"
                    style={{
                      color:
                        (conventionalMatchRange &&
                          colors.find(
                            (item) => item.key === conventionalMatchRange.color
                          ).value) ||
                        'rgb(76, 96, 114)'
                    }}
                  >
                    <span className="label">your value</span>
                    <br />
                    {personalData.value || 'N/A'}
                  </div>
                </div>
              )}
              {personalData && personalData.on && (
                <div className="options-container">
                  <div>
                    <span className="label">name</span>
                    <br />
                    {personalData.spec && personalData.spec.category}
                  </div>
                  <div
                    className="value"
                    style={{
                      color:
                        (range &&
                          colors.find((item) => item.key === range.color)
                            .value) ||
                        'rgb(76, 96, 114)'
                    }}
                  >
                    <span className="label">your value</span>
                    <br />
                    {personalData.value}
                  </div>
                </div>
              )}
            </div>
          ) : biomarker && type && type !== 'blood-test' ? (
            <MetricRangesArea {...childProps} />
          ) : (
            <RangesAreas {...childProps} />
          )}
        </div>
      </div>
    )
  }

  renderBiomarkerInfo() {
    const {
      biomarker,
      // functionalMatchRange,
      conventionalMatchRange,
      html,
      show,
      setPropsState
    } = this.props
    let range = null
    range =
      (conventionalMatchRange &&
        biomarker &&
        biomarker.conventional_ranges.find(
          (item) => item.name === conventionalMatchRange.name
        )) ||
      this.props.range

    /** comment on sprint 23 hide functional **/
    // if (biomarker) {
    //   const {
    //     personal_data: { prefer_functional }
    //   } = biomarker
    //   if (prefer_functional) {
    //     range = functionalMatchRange
    //   }
    // }
    return (
      <div className="biomarker-info">
        <div className="tabs">
          <ul>
            <li
              className={show === 'about' ? 'active' : ''}
              onClick={() =>
                setPropsState({ html: biomarker.introduction, show: 'about' })
              }
            >
              <About color={show === 'about' ? '#264382' : '#B2B2B2'} />
              <br />
              About
            </li>
            {/* <li
              className={show === 'health' ? 'active' : ''}
              onClick={() =>
                setPropsState({
                  html: range && range.health_effect,
                  show: 'health'
                })
              }
            >
              <Heartbeat color={show === 'health' ? '#264382' : '#B2B2B2'} />
              <br />
              Health Effects
            </li> */}
            <li
              className={show === 'life' ? 'active' : ''}
              onClick={() =>
                setPropsState({
                  html: range && range.life_suggestion,
                  show: 'life'
                })
              }
            >
              <Lifestyle color={show === 'life' ? '#264382' : '#B2B2B2'} />
              <br />
              Lifestyle Suggestions
            </li>
          </ul>
        </div>
        <div className="information-container">
          <div
            className="information"
            dangerouslySetInnerHTML={{
              __html: html && html.replace(/style="(.*?)"/g, '')
            }}
          />
        </div>
      </div>
    )
  }

  renderTrend() {
    const { person, biomarker, histories, type } = this.props
    // const personalData = biomarker && biomarker.personal_data
    let showConv, showFunc
    if (!type || type === 'blood-test') {
      showConv = true //personalData && personalData.prefer_conventional // comment on sprint 23
      showFunc = false //personalData && personalData.prefer_functional // comment on sprint 23
      return (
        (showConv || showFunc) && (
          <div className="trend-graph desktop-show">
            <h1>Trend Graph</h1>
            <TrendGraph
              person={person}
              biomarker={biomarker}
              histories={histories}
              {...{ showConv, showFunc }}
            />
          </div>
        )
      )
    } else {
      return (
        <div className="trend-graph desktop-show">
          <h1>Trend Graph</h1>
          <TrendGraph
            person={person}
            biomarker={biomarker}
            histories={histories}
          />
        </div>
      )
    }
  }

  renderMobileTrend() {
    const { person, biomarker, histories, type } = this.props
    // const personalData = biomarker && biomarker.personal_data
    let showConv, showFunc
    if (!histories) {
      return <div />
    }
    if (!type || type === 'blood-test') {
      showConv = true // personalData && personalData.prefer_conventional // comment on sprint23
      showFunc = false // personalData && personalData.prefer_functional // comment on sprint23
      return (
        (showConv || showFunc) && (
          <MobileTrendGraph
            person={person}
            biomarker={biomarker}
            histories={histories}
            {...{ showConv, showFunc }}
          />
        )
      )
    } else {
      return (
        <MobileTrendGraph
          person={person}
          biomarker={biomarker}
          histories={histories}
        />
      )
    }
  }

  render() {
    const { dataLoading, biomarker } = this.props
    if (biomarker && !biomarker.ranges) {
      biomarker.ranges = biomarker.conventional_ranges
    }
    return dataLoading ? (
      <PageLoading />
    ) : (
      <div className={detailStyle}>
        {biomarker && (
          <>
            <div className="client-biomarker-container">
              {this.renderRangeContainter()}
              <div className="trend-graph mobile-show">
                {!biomarker.options &&
                  biomarker.ranges &&
                  this.renderMobileTrend()}
              </div>
              {this.renderBiomarkerInfo()}
            </div>
            {!biomarker.options && biomarker.ranges && this.renderTrend()}
          </>
        )}
      </div>
    )
  }
}

export default DatasetDetailContainer
