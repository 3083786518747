import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Category from './bloodTestCategory'
import { injectRootCategories } from '../common'

class BloodTestCategories extends Component {
  static propTypes = {}

  componentDidMount() {
    const {
      location: { search }
    } = this.props
    const metricName = decodeURI(search.replace('?metric=', ''))
    const targetMetric = document.getElementsByName(metricName)
    if (targetMetric[0]) {
      window.scrollTo(0, targetMetric[0].offsetTop)
    }
  }

  render() {
    const {
      person,
      categories: propCategories,
      showDataset,
      datasets,
      dataHandle,
      type,
      ageScopes,
      saveScrollPosition
    } = this.props
    const dataset = datasets.find((item) => item.test_date === showDataset)
    let categories = propCategories || []

    categories = injectRootCategories(
      categories,
      (dataset && dataset.snapshot) || []
    )

    const categoryProps = {
      ...dataHandle,
      person,
      type,
      ageScopes,
      datasetId: dataset.id,
      testDate: dataset.test_date,
      saveScrollPosition
    }

    return (
      (dataset && (
        <>
          {categories.map((item, index) => (
            <Category
              key={index}
              category={item}
              type={type}
              {...categoryProps}
            />
          ))}
        </>
      )) || <div />
    )
  }
}

export default BloodTestCategories
