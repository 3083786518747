import React, { Component } from 'react'
import MicroGoals from './overview'
import GoalModalForm from './overview/modal'
import SelectModal from './overview/selectModal'
import DeleteConfirm from './overview/deleteConfirm'
// import ArchivedView from './detail/archivedView'
import SelectPeriodBar from './progress/selectBar'
import DateRange from '../UI/mgComponents/dateRange' //'./progress/dateRange'
import PageLoading from '../UI/status/loading'
import TodayTag from '../UI/todayTag'
import GoalDetail from './detail'

import GoalsWrapper from './goalsWrapper'
import ArchivedGoals from './overview/archivedGoals'

import { goalsStyle } from './index.sass'
import { goalsDataStyle } from './overview/goals.sass'
import { progressStyle } from './progress/progress.sass'

class Goals extends Component {
  static propTypes = {}

  state = {}

  isEmptyData = () => {
    const { statistics, type, archivedGoals } = this.props
    const data = type === 'active' ? statistics : archivedGoals.goals
    if (type === 'active') {
      delete data.aggregation
      return !(data && Object.keys(data).length > 0)
    } else {
      const length = Object.keys(data).filter((item) => data[item].length > 0)
        .length
      return length === 0
    }
  }

  renderArchivedGoals() {
    const {
      loading,
      loginRole,
      renderNullStatus,
      type,
      pillars,
      archivedGoals,
      archivedGoal,
      deleteGoal,
      person
    } = this.props
    return (
      <div className={goalsStyle} style={{ paddingTop: 40 }}>
        {person && (
          <h1 className={`title ${loginRole || 'User'}`}>
            {loginRole !== 'Expert' && (
              <>
                {(person.profile && person.profile.nickname) ||
                  person.first_name}
                ’s Micro Goals
              </>
            )}
          </h1>
        )}
        {loading ? (
          <PageLoading />
        ) : this.isEmptyData() && renderNullStatus ? (
          renderNullStatus(type)
        ) : (
          <ArchivedGoals
            {...{
              loginRole,
              goals: archivedGoals.goals,
              archivedGoal,
              deleteGoal,
              pillars
            }}
          />
        )}
      </div>
    )
  }

  render() {
    const {
      loading,
      person,
      showDate,
      targetGoal,
      type,
      loginRole,
      renderNullStatus,
      switchStatus
    } = this.props

    const { visible, targetDate } = this.state
    const childProps = {
      ...this.props,
      targetDate,
      position: 'trends',
      openModal: (state) => this.setState({ ...state })
    }
    const modalProps = {
      closeModal: () => this.setState({ visible: false }),
      openModal: (state) => this.setState({ ...state }),
      ...this.props
    }

    return (
      <div className={`${goalsStyle} ${loginRole}`}>
        <div className={`${goalsDataStyle} ${progressStyle} goals-container`}>
          {person && (
            <h1 className={`title ${loginRole} ${type}`}>
              {loginRole !== 'Expert' && (
                <span>
                  {(person.profile && person.profile.nickname) ||
                    person.first_name}
                  ’s {type === 'active' ? 'Trends' : 'Archived Metrics'}
                </span>
              )}
              <div>
                <div>
                  <DateRange
                    prevDate={this.props.prevDate}
                    nextDate={this.props.nextDate}
                    showDate={showDate}
                  />
                </div>
                <TodayTag style={{ marginRight: 58 }} />
              </div>
            </h1>
          )}
          <SelectPeriodBar {...{ showDate, type, switchStatus }} />
          {loading ? (
            <PageLoading />
          ) : this.isEmptyData() && renderNullStatus ? (
            renderNullStatus(type)
          ) : (
            <MicroGoals {...childProps} />
          )}
        </div>
        {visible === 2 && <GoalModalForm {...modalProps} />}
        {visible === 1 && <SelectModal {...modalProps} />}
        {visible === 3 && <GoalDetail {...childProps} />}
        {visible === 4 && (
          <DeleteConfirm
            targetGoal={targetGoal}
            deleteGoal={async (goal, isArchived) => {
              await this.props.deleteGoal(goal, isArchived)
              this.setState({ visible: 0 })
            }}
            archivedGoal={async (goal, archived) => {
              await this.props.archivedGoal(goal, archived)
              this.setState({ visible: 0 })
            }}
            closeModal={() => this.setState({ visible: false })}
          />
        )}
      </div>
    )
  }
}

export default GoalsWrapper(Goals)
