import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import HealthCard from './card'
import { getSum } from '../../utils/common'

class TotalCard extends Component {
  static propTypes = {
    list: PropTypes.array,
    compareDataList: PropTypes.array
  }

  getLatestData = (dataList) => {
    dataList.sort((a, b) => {
      if (moment(a).isAfter(moment(b))) return 1
      else return -1
    })
    return dataList[0]
  }

  getData = (list) => {
    const scoreArr = list
      .map(
        (item) =>
          (item.score_manual && item.score_manual.value) || item.score_auto
      )
      .filter((item) => item)

    const latestDateArr = list
      .map(
        (item) =>
          (item.score_manual && item.score_manual.updated_at) ||
          item.latest_test_date
      )
      .filter((item) => item)
    const latestData = this.getLatestData(latestDateArr)

    const score = Math.round(getSum(scoreArr) / scoreArr.length)

    return { score, latestData }
  }

  render() {
    const { list, compareDataList } = this.props
    let compareLatestData, compareScore
    if (compareDataList) {
      const result = this.getData(compareDataList)
      compareLatestData = result.latestData
      compareScore = result.score
    }
    const { score, latestData } = this.getData(list)

    return score || compareScore ? (
      <HealthCard
        {...{
          disEdit: true,
          name: 'Total Apeiron Life score',
          description:
            'We create a composite score with all of your health and fitness data, giving you one number to monitor over time. Like all of your scores, this is adjusted for your age and gender.',
          score_auto: score,
          latest_test_date: latestData,
          missing_metrics: [],
          compare: compareDataList
            ? {
                score_auto: compareScore || null,
                latest_test_date: compareLatestData || null
              }
            : null
        }}
      />
    ) : (
      <></>
    )
  }
}

export default TotalCard
