import React from 'react'

function getDevice() {
  const userAgentInfo = navigator.userAgent
  // app = navigator.appVersion
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  if (flag) {
    return 'pc'
  } else {
    if (!!userAgentInfo.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      return 'ios'
    } else if (
      userAgentInfo.indexOf('Android') > -1 ||
      userAgentInfo.indexOf('Adr') > -1
    ) {
      return 'android'
    }
  }
}

function redirectWebView(type) {
  const { origin } = window.location
  switch (type) {
    case 'nutrition':
      window.location.href = `${origin}/app/nutrition-score`
      break
    default:
      return <div>Redirecting...</div>
  }

  return <div>Redirecting...</div>
}

function redirectAppView(type) {
  const issafariBrowser =
    /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)

  if (!issafariBrowser) {
    return <>Please open this page with Safari</>
  }

  window.location.href = 'https://itunes.apple.com/cn/app/id1471466406?mt=8'
  return <div>Redirecting...</div>
}

export default function Redirection(props) {
  const {
    match: {
      params: { type }
    }
  } = props

  const device = getDevice()

  switch (device) {
    case 'pc':
      return redirectWebView(type)
    case 'ios':
      return redirectAppView(type)
    case 'android':
      return <>Android devices are not supported</>
    default:
      return <div>Redirecting...</div>
  }
  // return <div>Redirecting...</div>
}
