import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import BasicForm from '../../BasicForm'

import { pattern } from '../../../utils/constant'

import { conFirmSendStyle } from './styles/confirmSendEmail.sass'

class ConfirmSendEmail extends Component {
  static propTypes = {
    handleCancel: PropTypes.func,
    person: PropTypes.object
  }

  state = { visible: true, inputError: false }

  formData = [
    {
      type: 'input',
      label: 'TO',
      dbField: 'to',
      disabled: true,
      itemProps: {
        closable: false
      },
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'multiTag',
      label: 'CC',
      dbField: 'cc_list',
      itemProps: {
        closable: true,
        textRules: [{ pattern: pattern.email }],
        options: (() => {
          const {
            person: {
              profile: { executive_assistant_email, trainer_email }
            },
            experts
          } = this.props
          let cc_list = { tags: [] }
          if (executive_assistant_email)
            cc_list.tags.push(executive_assistant_email)
          if (trainer_email) cc_list.tags.push(trainer_email)
          return experts
            .map((expert) => ({
              label: expert.email,
              value: expert.email
            }))
            .concat(
              cc_list.tags.map((item) => ({
                label: item,
                value: item
              }))
            )
            .sort((a, b) => a.value.charCodeAt() - b.value.charCodeAt())
        })()
      },
      rules: [
        {
          validator: (rule, valueObj, callback) => {
            if (valueObj.editValue === undefined) {
              for (const tag of valueObj.tags) {
                if (!pattern.email.test(tag)) {
                  callback('Please input a valid email')
                  break
                }
              }
            } else if (valueObj && valueObj.editValue) {
              if (!pattern.email.test(valueObj.editValue)) {
                callback('Please input a valid email')
              } else if (
                valueObj.tags &&
                valueObj.tags.find((tag) => tag === valueObj.editValue)
              ) {
                callback('Please don\'t type duplicate email')
              }
            }

            callback()
          }
        }
      ]
    }
  ]

  sendEmail = async () => {
    const params = await this.refForm.handleSubmit()
    this.setState({ sending: true })

    if (params.cc_list && params.cc_list.editValue) {
      params.cc_list.tags.push(params.cc_list.editValue)
    }

    const ccList =
      params.cc_list && params.cc_list.tags
        ? params.cc_list.tags.join(';')
        : null

    this.props.handleOk(ccList)
  }

  cancel = () => {
    this.setState({ visible: false })
    this.props.handleCancel()
  }

  render() {
    const {
      person: {
        email,
        profile: { executive_assistant_email, trainer_email }
      }
    } = this.props
    let cc_list = { tags: [] }
    if (executive_assistant_email) cc_list.tags.push(executive_assistant_email)
    if (trainer_email) cc_list.tags.push(trainer_email)
    return (
      <Modal
        visible={this.state.visible}
        onCancel={this.cancel}
        className={conFirmSendStyle}
        footer={
          <>
            <Button onClick={this.cancel}>CANCEL</Button>
            <Button
              onClick={this.sendEmail}
              type="primary"
              loading={this.state.sending}
            >
              SEND NOW
            </Button>
          </>
        }
      >
        <h1 className="title" style={{ textAlign: 'left', fontSize: 24 }}>
          Send summary email
        </h1>
        <BasicForm
          wrappedComponentRef={(inst) => (this.refForm = inst)}
          valueForm={{
            to: email,
            cc_list
          }}
          formData={this.formData}
          // onChange={this.updateValueForm}
        />
      </Modal>
    )
  }
}

export default ConfirmSendEmail
