import React, { useState, useEffect } from 'react'
// import Question from './question'
import styled from 'styled-components'
import _ from 'lodash'
import { Row, Col } from 'antd'
import SingleChoices from './singleChoices'
import { QuestionContainer } from './style'

const Container = styled.div`
  .question .q-body {
    padding: 0;
  }
`

export default function QuestionGroup(props) {
  const { question, answer, update } = props
  const { sub_questions } = question
  const [answerObj, setAnswerObj] = useState({})

  useEffect(() => {
    if (answer.answer && _.isEmpty(answerObj)) {
      setAnswerObj({ ..._.assignIn(answerObj, answer.answer) })
    }
  }, [answer])

  return (
    <Container>
      {answer && answer.verify && (
        <p className="invalid">Please answer this question</p>
      )}
      {sub_questions &&
        sub_questions.map((q, index) => {
          const { grid_wrapper, options_layout, type, original_id } = q
          const childProps = {
            question: q,
            answer: answerObj[original_id] || {
              question_type: type,
              question_original_id: original_id,
              answer: { value: null }
            },
            update: preUpdate
          }
          return (
            <QuestionContainer key={index}>
              <div className="question">
                <Row>
                  {q.type !== 'sub_title' && q.type !== 'banner' && (
                    <Col
                      span={grid_wrapper ? grid_wrapper[0] : 24}
                      className="q-title"
                    >
                      {q.required && <i className="required">*</i>}
                      <p dangerouslySetInnerHTML={{ __html: q.question }} />
                    </Col>
                  )}
                  <Col
                    span={grid_wrapper ? grid_wrapper[1] : 24}
                    className={`q-body ${options_layout}`}
                  >
                    {renderQuestionBody(childProps, type)}
                  </Col>
                </Row>
              </div>
            </QuestionContainer>
          )
        })}
    </Container>
  )

  function renderQuestionBody(childProps, type) {
    switch (type) {
      case 'single_choice':
        return <SingleChoices {...childProps} />
      default:
        return null
    }
  }

  function preUpdate(_answer, { original_id }) {
    answerObj[original_id] = _answer
    const newAnswer = _.assignIn(answer, {
      answer: answerObj,
      question_original_id: question.original_id
    })
    update(newAnswer, question)
  }
}
