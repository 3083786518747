import axios, { formDataRequest } from './axios'
import { roleLevel } from '../utils/constant'

export const getTeam = (id) => axios.get(`/team/${id}`)

export const getClients = () => axios.get('/clients/home')

export const getAllExperts = async () => {
  const { clients: experts } = await axios.get(
    `/clients?role=${roleLevel.Expert}`
  )
  const { clients: sp } = await axios.get(
    `/clients?role=${roleLevel.Service_Provider}`
  )
  return { clients: [...experts, ...sp] }
}

export const getExperts = async () => {
  const { clients: experts } = await axios.get(
    `/clients?role=${roleLevel.Expert}`
  )
  return experts
}

export const getClient = (id) => axios.get(`/clients?person_id=${id}`)

export const createClient = (client) => axios.postJson('/clients', client)

export const editClient = (client, id, query) => {
  let url = `/clients/${id}`
  if (query) {
    url += query
  }

  return axios.putJson(url, client)
}

export const resetPassword = (id) => axios.post(`/clients/${id}/reset`)

export const getClientProfileInfo = (personId) =>
  axios.get(`/clients/profile/${personId}`)

export const getConnectDeviceURL = (service, id, cancelSource, role) =>
  axios.getDataWithCancelHandle(
    `/providers/auth/${id}/${role}/${service}`,
    cancelSource
  )

export const introductoryNotification = (personId) =>
  axios.post(`/clients/completed_introductory_email?person_id=${personId}`)

export const sendWelcomeEmail = (params, person_id) =>
  axios.postJson(`/clients/send_welcome_email?person_id=${person_id}`, params)

export const sendMembershipEmail = (params, person_id) =>
  axios.postJson(
    `/clients/send_membership_email?person_id=${person_id}`,
    params
  )

export const disconnectService = (service, person_id) =>
  axios.post(`/providers/disconnect/${person_id}/${service}`)

export const getExportReport = (
  source,
  person_id,
  exportObject,
  progressCallback
) =>
  formDataRequest({
    method: 'get',
    path: person_id
      ? `/datasets/export?person_id=${person_id}&in_data_aggregation=${exportObject ===
          'in_data_aggregation'}`
      : `/datasets/export?in_data_aggregation=${exportObject ===
          'in_data_aggregation'}`,
    cancelSource: source
    // propConfig: {
    //   onDownloadProgress: (process) => {
    //     const percent = (process.loaded / process.total).toFixed(2) * 100
    //     return progressCallback && progressCallback(percent)
    //   }
    // }
  })

export const getSupportTeam = (personId) =>
  axios.get(`/support_team?person_id=${personId}`)

export const getSupportRoles = () => axios.get('/support_team/roles')

export const addSupportTeam = (params) =>
  axios.postJson('/support_team', params)

export const editSupportTeam = (params, id) =>
  axios.putJson(`/support_team/${id}`, params)

export const deleteSupportTeam = (id) => axios.delete(`/support_team/${id}`)

export const getMembershipFees = (id) =>
  axios.get(`/clients/membership-fees?person_id=${id}`)

export const getMutedExperts = (id) =>
  axios.get(`/message/muted_experts?client_id=${id}`)

export const updateMuteState = (params) =>
  axios.postJson('/message/mute-email', params)
