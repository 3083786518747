import {
  setClientBreadcrumb,
  setClient,
  setClientPageTitle,
  setClientBiomarker,
  setClientSelecCategory,
  setClientBiomarkerCategories,
  setClientUnreadCount,
  setClientLeftNavigateBarHidden
} from '../../redux/client/action'
import { setSubMenu } from '../../redux/slideMenu/action'
import { saveClient } from '../../utils/storage'

// import LifeStyle from './detail/lifeStyle'

export default (dispatch) => ({
  setClientBreadcrumb: (breadcumb) => dispatch(setClientBreadcrumb(breadcumb)),
  setClient: (person) => {
    saveClient(person)
    dispatch(setClient(person))
  },
  setClientPageTitle: (title) => dispatch(setClientPageTitle(title)),
  setClientBiomarker: (biomarker) => {
    // setClientBiomarkerItem(biomarker)
    dispatch(setClientBiomarker(biomarker))
  },
  setClientSelecCategory: (category) =>
    dispatch(setClientSelecCategory(category)),
  setClientBiomarkerCategories: (categories) =>
    dispatch(setClientBiomarkerCategories(categories)),
  setSubMenu: (type, subMenu) => dispatch(setSubMenu(type, subMenu)),
  setClientUnreadCount: (count) => dispatch(setClientUnreadCount(count)),
  setClientLeftNavigateBarHidden: (hidden) =>
    dispatch(setClientLeftNavigateBarHidden(hidden))
})
