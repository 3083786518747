import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import BasicForm from '../BasicForm'
import { apeironFormStyle } from './apeiron-form.sass'

export class AperionModalForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    valueForm: PropTypes.object,
    formData: PropTypes.array,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    width: PropTypes.number,
    footer: PropTypes.object
  }

  state = {
    valueForm: {},
    loadingBinder: {}
  }

  submit = async () => {
    try {
      const params = await this.refForm.handleSubmit()
      this.props.onSubmit(params)
    } catch (err) {}
  }

  componentWillUnmount() {
    this.refForm.reset()
  }

  cancel = () => {
    const { onCancel } = this.props
    onCancel()
  }

  updateValueForm = (changedFields) => {
    const { valueForm } = this.state
    const fieldName = Object.keys(changedFields)[0]
    const field = changedFields[fieldName]
    if (!field) return
    valueForm[field.name] = field.value
    this.setState({ valueForm })
    if (this.props.syncUpdate) {
      this.props.syncUpdate(valueForm)
    }
  }

  componentDidMount() {
    this.setState({ valueForm: this.props.valueForm || {} })
  }

  renderButton(item, index) {
    const { loadingBinder } = this.state
    let loading
    if (loadingBinder[item.text]) {
      loading = loadingBinder[item.text]
    } else {
      loadingBinder[item.text] = false
      loading = false
    }
    return (
      <Button
        key={index}
        {...{
          ghost: item.ghost,
          type: item.btnType,
          loading,
          onClick: (e) => {
            if (item.type === 'cancel') {
              this.cancel()
            } else if (item.type === 'submit') {
              this.submit(e)
              loadingBinder[e.target.innerText] = true
              this.setState({ loadingBinder })
            } else {
              item.clickCallback && item.clickCallback(e)
              loadingBinder[e.target.innerText] = true
              this.setState({ loadingBinder })
            }
          },
          style: item.style
        }}
      >
        {item.text}
      </Button>
    )
  }

  renderFooter() {
    const { footer } = this.props
    const { leftPart, rightPart } = footer
    if (!footer) return null
    return (
      <footer className={footer.type}>
        {leftPart && (
          <div className="left-group">
            {leftPart.map((item, index) => this.renderButton(item, index))}
          </div>
        )}
        {rightPart && (
          <div className="right-group">
            {rightPart.map((item, index) => this.renderButton(item, index))}
          </div>
        )}
      </footer>
    )
  }

  render() {
    const { title, width, visible, formData, loading } = this.props
    return (
      <Modal
        width={width}
        className={apeironFormStyle}
        visible={visible}
        okText="Submit"
        onOk={this.submit}
        onCancel={this.cancel}
        okButtonProps={{ loading }}
        footer={this.renderFooter()}
      >
        <h1 className="title">{title}</h1>
        <BasicForm
          wrappedComponentRef={(inst) => (this.refForm = inst)}
          valueForm={this.state.valueForm}
          formData={formData}
          onChange={this.updateValueForm}
        />
      </Modal>
    )
  }
}

export default AperionModalForm
