import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import _ from 'lodash'
import { Divider, Typography, message, Progress, Table, Tag } from 'antd'
import { CopyOutlined, DeleteFilled } from '@ant-design/icons'
import RightDrawer from '../../../components/UI/rightDrawer'
import { getChallengeDetail } from '../../../api/challenge'
import PageLoading from '../../../components/UI/status/loading'
import { targetGoals } from '../../../models/challenge.form'
import { Pluralize } from '../../../utils/common'
import filesize from '../../../utils/filesize'
import ConfirmDelete from './confirmDelete'
import PolygonAvatar from '../../../components/UI/polygonAvatar'

const DetailContainer = styled.div`
  color: #383e48;
  font-size: 14px;
  margin: -24px;
  padding: 24px;
  a {
    color: #0077e4;
    text-decoration: underline;
  }
  .sub-title {
    font-weight: 700;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
    .btn {
      margin-left: 24px;
      .anticon {
        margin-right: 6px;
      }
    }
  }
  .detail-text {
    &.flex {
      display: flex;
      margin-top: 30px;
      & > span:first-child {
        display: block;
        width: 115px;
      }
      & > span:last-child a {
        word-break: break-word;
      }
    }
    .lighter {
      max-width: calc(100% - 120px);
      vertical-align: top;
      white-space: pre-wrap;
    }
  }
  .lighter {
    color: #8691a7;
    display: inline-block;
    padding-left: 24px;
  }
  .btn {
    color: #264382;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
  }
  .info-list {
    display: flex;
    flex-wrap: wrap;
    .info-item {
      width: 50%;
      display: flex;
      margin-bottom: 16px;
      span:first-child {
        width: 116px;
      }
    }
    .link-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .ant-typography {
        margin: 0;
        width: calc(100% - 116px - 50px);
        padding-left: 23px;
      }
    }
  }
  .badge-container {
    display: inline-block;
    cursor: pointer;
    & > div {
      min-width: 188px;
      background: rgba(38, 67, 130, 0.05);
      padding: 8px;
      border-radius: 8px;
      display: flex;
      margin-top: 15px;
      font-size: 12px;
      align-items: center;
      .lighter {
        padding-left: 0;
      }
      .polygon-avatar {
        margin-right: 15px;
        position: relative;
        svg {
          position: absolute;
        }
      }
    }
  }
  .full-line {
    margin: 24px -24px;
  }
`

export default function ChallengeDetail(props) {
  const {
    challenge: { status, name, start, id, badge_image },
    onCancel,
    handleEdit,
    handleDelete
  } = props

  const [detail, setDetail] = useState(null)
  const [confirmDel, setConfirmDel] = useState(false)

  useEffect(() => {
    initial()
  }, [])

  const date = moment(
    `${moment(start).format('YYYY')}-${moment(start).format('MM')}`,
    'YYYY-MM'
  )
  let leftDays
  if (status !== 'past') {
    leftDays =
      date.daysInMonth() -
      moment()
        .toDate()
        .getDate() +
      1
  }
  const title = (
    <div style={{ fontSize: 18 }}>
      {name}
      <br />
      <span style={{ color: '#8691A7', fontSize: 14 }}>
        {status === 'past'
          ? 'Past Challenge'
          : `${leftDays} ${Pluralize('day', leftDays || 1)} left`}
      </span>
    </div>
  )
  return (
    <RightDrawer width={800} title={title} closeModal={onCancel} visible={true}>
      {!detail ? (
        <PageLoading />
      ) : (
        <DetailContainer>
          <div className="sub-title">
            <span>Challenge Information</span>
            <div>
              {status !== 'active' && (
                <span className="btn" onClick={() => setConfirmDel(true)}>
                  <DeleteFilled />
                  Delete
                </span>
              )}
              <span className="btn" onClick={handleEdit}>
                {status === 'active' ? 'Edit' : 'Renew'}
              </span>
            </div>
          </div>
          <div className="detail-text">
            Challenge Detail:{' '}
            <span className="lighter">{detail && detail.description}</span>
          </div>
          {detail.instructions &&
            detail.instructions.map(({ title, link }, index) => (
              <div className="detail-text flex" key={index}>
                <span>{title + ':'}</span>
                <span className="lighter">
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {link}
                  </a>
                </span>
              </div>
            ))}

          <Divider />
          <Information {...detail} />
          {badge_image && (
            <>
              <Divider />
              <div>
                Badge Image:
                <br />
                <div
                  className="badge-container"
                  onClick={() => {
                    window.open(badge_image.url)
                  }}
                >
                  <div>
                    <PolygonAvatar
                      image={badge_image.url}
                      width={45}
                      innerId={`challenge-${id}`}
                      height={50}
                      hexId={`hex-challenge-${id}`}
                    />
                    <div>
                      {badge_image.name}
                      <br />
                      <span className="lighter">
                        {badge_image.type.replace('image/', '')} ·{' '}
                        {filesize(badge_image.size, { round: 1 })}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <Divider className="full-line" />
          {status === 'past' && (
            <Statistic {...detail.statistic} target={detail.target} />
          )}
          <Participants
            list={detail.participants}
            group={detail.metric_group}
            target={detail.target}
            unit={detail.unit}
            metric={detail.metric}
            ranking_type={detail.ranking_type}
          />
        </DetailContainer>
      )}
      {confirmDel && (
        <ConfirmDelete
          handleDelete={handleDelete}
          onCancel={() => setConfirmDel(false)}
          challenge={props.challenge}
        />
      )}
    </RightDrawer>
  )

  async function initial() {
    const challenge = await getChallengeDetail(id)
    setDetail(challenge)
  }
}

const StatisticContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 110px;
  margin-top: 30px;
  & > div {
    width: 33%;
    text-align: center;
    text-transform: uppercase;
  }
  .number {
    font-weight: 700;
    color: #383e48;
    font-size: 30px;
    margin-top: 12px;
  }
  .lighter {
    font-size: 12px;
    padding: 0;
  }
`

function Statistic(props) {
  const {
    completed_participants,
    completed_percentage,
    total_participants,
    total_percentage,
    target
  } = props || {
    completed_participants: 0,
    completed_percentage: 0,
    total_participants: 0,
    total_percentage: 0
  }
  return (
    <>
      <div className="sub-title">Challenge Statistic</div>
      <StatisticContainer>
        <div>
          <Progress
            type="circle"
            percent={total_percentage || 0}
            strokeColor="#264382"
            strokeWidth={8}
            format={(percent) => (
              <span style={{ color: '#264382' }}>{`${percent}%`}</span>
            )}
          />
          <div className="number">{_.toString(total_participants)}</div>
          <div className="lighter">
            Participants of <br /> eligible clients
          </div>
        </div>
        {target && (
          <>
            <div>
              <Progress
                type="circle"
                percent={completed_percentage || 0}
                strokeColor="#264382"
                strokeWidth={8}
                format={(percent) => (
                  <span style={{ color: '#264382' }}>{`${percent}%`}</span>
                )}
              />
              <div className="number">{_.toString(completed_participants)}</div>
              <div className="lighter">fully completed</div>
            </div>
            <div>
              <Progress
                type="circle"
                percent={100 - (completed_percentage || 0)}
                strokeColor="#264382"
                strokeWidth={8}
                format={(percent) => (
                  <span style={{ color: '#264382' }}>{`${percent}%`}</span>
                )}
              />
              <div className="number">
                {_.toString(total_participants - completed_participants)}
              </div>
              <div className="lighter">incompleted</div>
            </div>
          </>
        )}
      </StatisticContainer>
      <Divider className="full-line" />
    </>
  )
}

const ParticipantsContainer = styled.div`
  margin-top: 25px;
  .avatar-container {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: #f5f5f5;
    border-radius: 100%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .ant-table-thead > tr > th {
    background: rgba(38, 67, 130, 0.05);
    color: #4c6072;
    opacity: 0.6;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`

function Participants(props) {
  const { list, target, unit, group, metric, ranking_type } = props
  const columns = [
    {
      title: '',
      key: 'avatar',
      dataIndex: 'avatar_oss_url',
      render: (text) => (
        <div className="avatar-container">
          {text && <img src={text} alt="avatar" />}
        </div>
      )
    },
    { title: 'RANK', key: 'ranking', dataIndex: 'ranking' },
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => (
        <>
          <a href={`/client/${record.client_id}/detail`}>{text}</a>
          {record.is_anonymous && (
            <>
              <br /> <Tag style={{ marginLeft: -5 }}>Anonymous</Tag>
            </>
          )}
        </>
      )
    },
    {
      title: 'PROGRESS (DAILY)',
      key: 'input',
      dataIndex: 'input',
      render: (text) => {
        if (target === 'yes') {
          return text === 'yes' ? 'Checked-in' : '--'
        } else if (unit === 'min') {
          const minute = Math.round(Number((text || 0) / 60))
          return (
            <>
              {minute} {unit ? Pluralize(unit, minute || 1) : ''}
            </>
          )
        } else {
          return (
            <>
              {text ? formatCurrency(text) : '--'}/
              <span style={{ opacity: 0.8 }}>
                {formatCurrency(target)}
                {unit && Pluralize(unit, text || 1)}
              </span>
            </>
          )
        }
      }
    },
    {
      title: 'ADHERENCE',
      key: 'adherence',
      dataIndex: 'adherence',
      render: (text, record) => {
        return text.replace(
          ')',
          (unit || 'day') !== '%'
            ? ' ' + Pluralize(unit || 'day', record.done_cnt || 1) + ')'
            : ')'
        )
      }
    },
    {
      title: 'JOINED DATE',
      key: 'join_at',
      dataIndex: 'join_at',
      render: (text) => moment((text || '').substr(0, 10)).format('MMM D, YYYY')
    }
  ]
  if (group === 'HRZ' || metric === 'Mindfulness Minute') {
    const totalColumn = {
      title: 'PROGRESS (TOTAL)',
      key: 'total_val',
      dataIndex: 'total_val',
      render: (text) => {
        const minute = Math.round(Number((text || 0) / 60))
        return (
          <>
            {minute} {unit ? Pluralize(unit, minute || 1) : ''}
          </>
        )
      }
    }
    columns.splice(3, 0, totalColumn)
    columns.splice(5, 1)
  }
  if (ranking_type === 'streak_cnt') {
    columns.splice(4, 1, {
      title: 'ADHERENCE',
      key: 'max_streak_cnt',
      dataIndex: 'max_streak_cnt',
      render: (text) => `${text} days`
    })
  }
  if (metric === 'Hang time') {
    columns.splice(3, 0, {
      title: 'LONGEST TIME',
      key: 'max_val',
      dataIndex: 'max_val',
      render: (text) => <>{text ? text + ' ' + unit : '--'}</>
    })
    columns.splice(4, 1, {
      title: 'PROGRESS (DAILY)',
      key: 'input',
      dataIndex: 'input',
      render: (text) => <>{text ? text + ' ' + unit : '--'}</>
    })
    columns.splice(5, 1, {
      title: 'IMPROVED %',
      key: 'improvement',
      dataIndex: 'improvement',
      render: (text, data) => {
        if (!data.baseline) {
          return <>--</>
        }
        return (
          <>
            {text}% <br /> ({data.max_val - data.baseline}/{data.baseline || 0})
          </>
        )
      }
    })
  }
  if (metric === 'Row time') {
    columns.splice(3, 0, {
      title: 'SHORTEST TIME',
      key: 'min_val',
      dataIndex: 'min_val',
      render: (text) => <>{text ? text + ' ' + unit : '--'}</>
    })
    columns.splice(4, 1, {
      title: 'PROGRESS (DAILY)',
      key: 'input',
      dataIndex: 'input',
      render: (text) => <>{text ? text + ' ' + unit : '--'}</>
    })
    columns.splice(5, 1, {
      title: 'IMPROVED %',
      key: 'improvement',
      dataIndex: 'improvement',
      render: (text, data) => {
        if (!data.baseline) {
          return <>--</>
        }
        return (
          <>
            {text}% <br /> ({Number((data.baseline - data.min_val).toFixed(1))}/
            {data.baseline || 0})
          </>
        )
      }
    })
  }
  if (metric === 'Healthy Signals') {
    columns.splice(3, 1, {
      title: 'PROGRESS',
      key: 'total_val',
      dataIndex: 'total_val',
      render: (text) => <>{text ? text + ' ' + unit : '--'}</>
    })
    columns.splice(4, 1)
  }
  return (
    <>
      <div className="sub-title">Participants ({list.length})</div>
      <ParticipantsContainer>
        <Table
          columns={columns}
          size="middle"
          pagination={false}
          dataSource={list || []}
          rowKey={(record) => record.client_id}
        />
      </ParticipantsContainer>
    </>
  )
}

function Information(props) {
  const {
    source,
    pillar,
    metric,
    frequency,
    target_comparison,
    target,
    target_upper,
    start,
    id
  } = props
  const list = [
    { label: 'Tracking Source', value: source },
    { label: 'Pillar', value: pillar },
    { label: 'Metric', value: metric },
    { label: 'Frequency', value: frequency },
    {
      label: 'Target',
      value: targetGoals.find((item) => item.value === target_comparison).label
    },
    {
      label: 'Target',
      value: `${formatCurrency(target)}${
        target_upper ? ' - ' + formatCurrency(target_upper) : ''
      }`
    },
    { label: 'Month', value: moment(start).format('MMMM') },
    { label: 'Year', value: moment(start).format('YYYY') }
  ]

  let link = `/app/challenge/${id}`
  if (process.env.REACT_APP_WORKFLOW !== 'production') {
    link = 'https://app-staging.apeiron.life' + link
  } else {
    link = 'https://app.apeiron.life' + link
  }
  return (
    <div className="info-list">
      {list.map((item, index) => (
        <div className="info-item" key={index}>
          <span>{item.label}</span>
          <span className="lighter">{item.value}</span>
        </div>
      ))}
      <div className="link-item">
        <span>Link to Challenge:</span>
        <Typography.Paragraph ellipsis>
          {/* <a href={link}>{link}</a> */}
          {link}
        </Typography.Paragraph>
        <span
          className="btn"
          onClick={() => {
            navigator.clipboard.writeText(link)
            message.success('Link copied!')
          }}
        >
          copy <CopyOutlined />
        </span>
      </div>
    </div>
  )
}

function formatCurrency(text) {
  return `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
