import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col } from 'antd'
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryGroup
} from 'victory'
import { SvgText, WrapText } from '../../icons/weeklySummary'
import {
  getAxisX,
  xAxisFormat,
  tickLabels,
  calculateElementWidth
} from '../common'
import { getSum, timesFormat } from '../../../utils/common'
import { fontFamily } from '../../../utils/constant'

const normalFont = `Gilroy, ${fontFamily}`
const boldFont = `Gilroy-Bold, ${fontFamily}`

const boldTextAttr = {
  x: 0,
  y: 0,
  fontSize: 12,
  // fontWeight: 'bold',
  fontFamily: boldFont,
  fill: '#383E48',
  letterSpacing: 0.5,
  textAnchor: 'start',
  dominantBaseline: 'middle'
}

const grayTextAttr = _.create(boldTextAttr, { fill: '#70808E' })

const capitalize = (str) => {
  var newStr = str.split(' ')
  for (var i = 0; i < newStr.length; i++) {
    newStr[i] =
      newStr[i].charAt(0).toUpperCase() + newStr[i].slice(1).toLowerCase()
  }
  return newStr.join(' ')
}

const formatTime = (duration) => {
  const hours = Math.floor(duration / (60 * 60))
  const minutes = Math.round((duration - hours * 60 * 60) / 60)
  return `${hours > 0 ? hours + 'h ' : ''}${minutes}m`
}

class WorkoutChart extends Component {
  static propTypes = {}

  barDataHandle = (tickValuesX, barData) => {
    // const barData = [].concat.apply(...data)
    return tickValuesX.map((item) => {
      const entry = barData
        .filter((entry) => entry.summary_date === item.format('YYYY-MM-DD'))
        .map((entry) =>
          getSum(entry.activities.map((activity) => activity.duration))
        )
      const y = getSum(entry)
      return {
        x: item,
        y
      }
    })
  }

  getEmptyDate = (tickValuesX, barDatas, maxDuration) => {
    return tickValuesX.map((x, index) => {
      const hasData = barDatas.find((item) => item.data[index].y > 0)
      return {
        x,
        y: hasData ? 0 : maxDuration * 0.02
      }
    })
  }

  labelsHandle = ({ Aerobic, Resistance, Activity, MovementQuality }) => {
    const groupHandle = (data) => {
      const activities =
        data.length > 0
          ? [].concat.call(...data.map((item) => item.activities))
          : []
      const names = Array.from(
        new Set(activities.map((activity) => activity.name))
      )
      return names.map((name) => {
        const durations = getSum(
          activities
            .filter((activity) => activity.name === name)
            .map((activity) => Math.round(activity.duration / 60) * 60)
        )
        const activityCount = activities.filter(
          (activity) => activity.name === name
        ).length
        if (activityCount > 1) {
          name = name + ` (${activityCount})`
        }

        const hours = Math.floor(durations / (60 * 60))
        const minutes = Math.round((durations - hours * 60 * 60) / 60)

        return {
          name,
          duration: hours * 60 * 60 + minutes * 60
        }
      })
    }
    const aerobic = groupHandle(Aerobic)
    const resistance = groupHandle(Resistance)
    const activity = groupHandle(Activity)
    const movementQuality = groupHandle(MovementQuality)
    return { aerobic, resistance, activity, movementQuality }
  }

  getBarLabels = (barDatas, tickValuesX) => {
    const empty = barDatas.find((item) => item.type === 'Empty')
    const dataArr = barDatas.filter((item) => item.type !== 'Empty')

    return tickValuesX.map((x, index) => {
      let y
      if (empty.data[index]) {
        y = getSum(
          dataArr.map(
            (item) =>
              Math.round((item.data[index].y - item.data[index].y0) / 60) * 60
          )
        )
      } else {
        y = 0
      }
      return { x, y }
    })
  }

  programDataHandle(tickValuesX = [], programData) {
    const workouts =
      (programData.exercise.summary.workouts &&
        programData.exercise.summary.workouts.filter(
          (item) => item.program_item_id
        )) ||
      []
    const filterWorkouts = (type) =>
      workouts.filter(
        (workout) =>
          workout && (workout.shift_category || workout.category) === type
      )

    const Aerobic = filterWorkouts('Aerobic')

    const Resistance = filterWorkouts('Resistance')

    const Activity = filterWorkouts('Activity')

    const NonProgram =
      programData.exercise.summary.workouts &&
      programData.exercise.summary.workouts.filter(
        (item) => !item.program_item_id
      )

    const barDataHandle = (tickValuesX, barData) => {
      return tickValuesX.map((item) => {
        const entry = barData
          .filter((entry) => entry.record_date === item.format('YYYY-MM-DD'))
          .map((entry) => entry.duration)
        const y = getSum(entry)
        return {
          x: item,
          y
        }
      })
    }
    const barDatas = [
      {
        type: 'Activity',
        barColor: '#BCCA70',
        data: barDataHandle(tickValuesX, Activity)
      },
      {
        type: 'Resistance',
        barColor: '#58646B',
        data: barDataHandle(tickValuesX, Resistance)
      },
      {
        type: 'Aerobic',
        barColor: '#FF6B00',
        data: barDataHandle(tickValuesX, Aerobic)
      },
      {
        type: 'NonProgram',
        barColor: '#C4C4C4',
        data: barDataHandle(tickValuesX, NonProgram)
      }
    ]

    // ===============
    for (const index in barDatas) {
      if (index > 0) {
        barDatas[index].data = barDatas[index].data.map((item, _index) => {
          const y0 = barDatas[index - 1].data[_index].y
          item.y += y0
          return {
            ...item,
            y0
          }
        })
      } else {
        barDatas[index].data = barDatas[index].data.map((item) => ({
          ...item,
          y0: 0
        }))
      }
    }

    const maxDuration = Math.max(
      ...[].concat.call(...barDatas.map((item) => item.data.map((d) => d.y)))
    )

    let tickValues = [0, 1200, 2400, 3600]

    if (maxDuration) {
      const step = Math.ceil(maxDuration / 60 / 30) * 10
      tickValues = [0, step * 60, step * 2 * 60, step * 3 * 60]
    }

    barDatas.push({
      type: 'Empty',
      barColor: '#C4C4C4',
      data: this.getEmptyDate(tickValuesX, barDatas, maxDuration || 3600)
    })
    const groupHandle = (data, category, index) => {
      // console.log('groupHandle', data)
      const names = Array.from(new Set(data.map((item) => item.type)))

      const detail = names.map((name) => {
        const workouts = data.filter((item) => item.type === name)
        const durations = getSum(
          workouts.map((item) => Math.round(item.duration / 60) * 60)
        )
        const workoutCount = workouts.length
        if (workoutCount > 1) {
          name = name + ` (${workoutCount})`
        }
        const hours = Math.floor(durations / (60 * 60))
        const minutes = Math.round((durations - hours * 60 * 60) / 60)

        return {
          name,
          duration: hours * 60 + minutes,
          count: workoutCount
        }
      })

      const completed = {
        session: getSum(detail.map((item) => item.count)),
        duration: getSum(detail.map((item) => item.duration))
      }

      // const plannedWorkout = programData.exercise.program.items.filter(
      //   (item) => item.category === category
      // )
      const planned = {
        session: programData.exercise.summary.pillar_item_count.target[index], // plannedWorkout.length,
        duration: Math.round(
          programData.exercise.summary.achievement.duration.pillar.target[
            index
          ] / 60
        ) // getSum(plannedWorkout.map((item) => item.duration / 60))
      }

      return { detail, completed, planned }
    }

    const labels = {
      aerobic: groupHandle(Aerobic, 'Aerobic', 0),
      resistance: groupHandle(Resistance, 'Resistance', 1),
      activity: groupHandle(Activity, 'Activity', 2),
      non_program: groupHandle(NonProgram, '', 3)
      // total: {completed}
    }

    labels.total = {
      planned: {
        session: getSum([
          labels.aerobic.planned.session,
          labels.resistance.planned.session,
          labels.activity.planned.session
        ]),
        duration: getSum([
          labels.aerobic.planned.duration,
          labels.resistance.planned.duration,
          labels.activity.planned.duration
        ])
      },
      completed: {
        session: getSum([
          labels.aerobic.completed.session,
          labels.resistance.completed.session,
          labels.activity.completed.session,
          labels.non_program.completed.session
        ]),
        duration: getSum([
          labels.aerobic.completed.duration,
          labels.resistance.completed.duration,
          labels.activity.completed.duration,
          labels.non_program.completed.duration
        ])
      }
    }

    return { barDatas, tickValues, labels }
  }

  dataHandle(tickValuesX = [], exercise, programData) {
    if (programData && programData.exercise.program) {
      return this.programDataHandle(tickValuesX, programData)
    }
    const workouts =
      exercise &&
      _.cloneDeep(exercise.workouts).map((item) => {
        const { details } = item
        item.type =
          (details &&
            (details.shift_activity_classification ||
              details.activity_classification)) ||
          item.type
        return item
      })

    const filterDatas = (datas) => {
      const result = datas.map((data) => data.details)
      for (const index in result) {
        result[index] = result[index].filter(
          (item) => item.activities.length > 0
        )
      }
      return result
    }

    const filterWorkouts = (type) =>
      workouts.filter((workout) => workout && workout.type === type)

    const Aerobic = [].concat.apply(...filterDatas(filterWorkouts('Aerobic')))

    const Resistance = [].concat.apply(
      ...filterDatas(filterWorkouts('Resistance'))
    )

    const Activity = [].concat.apply(...filterDatas(filterWorkouts('Activity')))

    const MovementQuality = [].concat.apply(
      ...filterDatas(filterWorkouts('Movement Quality'))
    )

    const barDatas = [
      {
        type: 'Movement Quality',
        barColor: '#C29E7C',
        data: this.barDataHandle(tickValuesX, MovementQuality)
      },
      {
        type: 'Activity',
        barColor: '#BCCA70',
        data: this.barDataHandle(tickValuesX, Activity)
      },
      {
        type: 'Resistance',
        barColor: '#58646B',
        data: this.barDataHandle(tickValuesX, Resistance)
      },
      {
        type: 'Aerobic',
        barColor: '#FF6B00',
        data: this.barDataHandle(tickValuesX, Aerobic)
      }
    ]

    for (const index in barDatas) {
      if (index > 0) {
        barDatas[index].data = barDatas[index].data.map((item, _index) => {
          const y0 = barDatas[index - 1].data[_index].y
          item.y += y0
          return {
            ...item,
            y0
          }
        })
      } else {
        barDatas[index].data = barDatas[index].data.map((item) => ({
          ...item,
          y0: 0
        }))
      }
    }

    const maxDuration = Math.max(
      ...[].concat.call(...barDatas.map((item) => item.data.map((d) => d.y)))
    )

    let tickValues = [0, 1200, 2400, 3600]

    if (maxDuration) {
      const step = Math.ceil(maxDuration / 60 / 30) * 10
      tickValues = [0, step * 60, step * 2 * 60, step * 3 * 60]
    }

    barDatas.push({
      type: 'Empty',
      barColor: '#C4C4C4',
      data: this.getEmptyDate(tickValuesX, barDatas, maxDuration || 3600)
    })

    const labels = this.labelsHandle({
      Aerobic,
      Resistance,
      Activity,
      MovementQuality
    })
    return { barDatas, tickValues, labels }
  }

  renderChart(barDatas, tickValues, tickValuesX) {
    const barLabels = this.getBarLabels(barDatas, tickValuesX)
    return (
      <VictoryChart width={500} height={250} domainPadding={20}>
        <VictoryAxis
          style={{
            axis: {
              stroke: 'transparent'
            },
            tickLabels
          }}
          tickValues={tickValuesX}
          tickLabelComponent={
            <VictoryLabel text={(datum) => xAxisFormat(datum)} />
          }
        />

        <VictoryAxis
          dependentAxis
          tickValues={tickValues}
          tickLabelComponent={
            <VictoryLabel text={(datum) => datum && datum / 60} />
          }
          style={{
            axis: { stroke: 'transparent' },
            grid: {
              stroke: '#E4EAEF',
              strokeDasharray: '3,3'
            },
            tickLabels
          }}
        />
        <VictoryGroup>
          {barDatas &&
            barDatas.map((item, index) => (
              <VictoryBar
                key={index}
                cornerRadius={{ bottom: 6, top: 6 }}
                style={{
                  data: {
                    fill: item.barColor,
                    stroke: '#fff',
                    strokeWidth: 1
                  }
                }}
                barWidth={36}
                data={item.data}
              />
            ))}
          <VictoryBar
            data={barLabels}
            style={{
              data: {
                fillOpacity: 0
              },
              labels: {
                fontSize: 10,
                fill: '#70808E'
              }
            }}
            labels={(datum) => {
              if (datum.y) {
                const { hours, minutes } = timesFormat(datum.y)
                return `${hours ? hours + 'h' : ''}${minutes}m`
              }
              return null
            }}
          />
        </VictoryGroup>
      </VictoryChart>
    )
  }

  renderLabel(typeData, maxNameWidth) {
    return (
      <>
        <div className="activity-type">
          <div className="name">
            <span className={typeData.name} style={{ marginTop: -3 }} />
            <WrapText
              height={
                calculateElementWidth(
                  'span',
                  typeData.name.toUpperCase(),
                  '14px',
                  maxNameWidth + 'px'
                ).height
              }
              width={maxNameWidth}
              fontFamily={boldFont}
              textAttr={boldTextAttr}
              text={typeData.name.toUpperCase()}
            />
          </div>
          <SvgText
            height={18}
            width={
              calculateElementWidth(
                'span',
                formatTime(typeData.totalDuration),
                '14px'
              ).width + 5
            }
            fontFamily={boldFont}
            textAttr={_.create(boldTextAttr, { y: 10 })}
            text={formatTime(typeData.totalDuration)}
          />
        </div>
        {typeData.data.map((item, index) => (
          <div key={index} className="activity-name">
            <div className="name">
              <WrapText
                height={
                  calculateElementWidth(
                    'span',
                    capitalize(item.name),
                    '14px',
                    maxNameWidth + 'px'
                  ).height
                }
                width={maxNameWidth}
                fontFamily={normalFont}
                textAttr={grayTextAttr}
                text={capitalize(item.name)}
              />
            </div>
            <SvgText
              height={18}
              width={
                calculateElementWidth('span', formatTime(item.duration), '14px')
                  .width + 5
              }
              fontFamily={normalFont}
              textAttr={grayTextAttr}
              text={formatTime(item.duration)}
            />
          </div>
        ))}
      </>
    )
  }

  renderProgramLabels(labels) {
    const { aerobic, resistance, activity, non_program, total } = labels
    let labelData = [
      {
        name: 'Aerobic',
        data: aerobic
      },
      {
        name: 'Activity',
        data: activity
      },
      {
        name: 'Resistance',
        data: resistance
      },
      {
        name: 'non-program',
        data: non_program
      },
      {
        name: 'Total',
        data: total
      }
    ]
    const maxNameWidth = 415
    return (
      <div className="workout-labels" align="top">
        <div className="activity-label">
          <div />
          <div className="workout-summary">
            <WrapText
              height={
                calculateElementWidth(
                  'span',
                  'PLANNED SESSION/MINS',
                  '14px',
                  90 + 'px'
                ).height
              }
              width={92}
              fontFamily={boldFont}
              textAttr={_.create(grayTextAttr, { textAnchor: 'middle' })}
              text="PLANNED SESSION/MINS"
              textStyle={{ display: 'inline-block', textAlign: 'right' }}
            />
            <WrapText
              height={
                calculateElementWidth(
                  'span',
                  'COMPLETED SESSION/MINS',
                  '14px',
                  90 + 'px'
                ).height
              }
              width={92}
              fontFamily={boldFont}
              textAttr={_.create(grayTextAttr, { textAnchor: 'middle' })}
              // textAttr={grayTextAttr}
              text="COMPLETED SESSION/MINS"
              textStyle={{ display: 'inline-block', textAlign: 'right' }}
            />
          </div>
        </div>
        {labelData.map((typeData, index) => {
          const { planned, completed } = typeData.data
          const plannedLabel = `${
            _.isFinite(planned.session) ? planned.session : '--'
          } / ${_.isFinite(planned.duration) ? planned.duration : '--'}`
          const completedLabel = `${completed.session} / ${completed.duration}`
          if (
            (plannedLabel === '0 / 0' || plannedLabel === '-- / --') &&
            completedLabel === '0 / 0'
          )
            return null
          const elemObj = calculateElementWidth(
            'span',
            typeData.name.toUpperCase(),
            '14px'
          )
          const showThumb =
            completed.session >= planned.session &&
            completed.duration >= planned.duration
          return (
            <div key={index}>
              <div className="activity-type">
                <div className="name">
                  <span className={typeData.name} />
                  <WrapText
                    height={16} //elemObj.height - 2
                    width={elemObj.width}
                    fontFamily={boldFont}
                    textAttr={boldTextAttr}
                    text={typeData.name.toUpperCase()}
                  />
                  {typeData.name !== 'Total' && showThumb && <Thumb />}
                </div>
                <div className="workout-summary">
                  <WrapText
                    height={
                      16
                      // calculateElementWidth(
                      //   'span',
                      //   plannedLabel,
                      //   '12px',
                      //   90 + 'px'
                      // ).height
                    }
                    width={90}
                    fontFamily={boldFont}
                    textAttr={boldTextAttr}
                    text={plannedLabel}
                    textStyle={{ display: 'block', textAlign: 'right' }}
                  />
                  <WrapText
                    height={
                      calculateElementWidth(
                        'span',
                        completedLabel,
                        '12px',
                        90 + 'px'
                      ).height
                    }
                    width={90}
                    fontFamily={boldFont}
                    textAttr={boldTextAttr}
                    text={completedLabel}
                    textStyle={{ display: 'block', textAlign: 'right' }}
                  />
                </div>
              </div>

              {typeData.data.detail &&
                typeData.data.detail.map((item, index) => (
                  <div key={index} className="activity-name">
                    <div className="name">
                      <WrapText
                        height={
                          calculateElementWidth(
                            'span',
                            capitalize(item.name),
                            '14px',
                            maxNameWidth + 'px'
                          ).height
                        }
                        width={maxNameWidth}
                        fontFamily={normalFont}
                        textAttr={grayTextAttr}
                        text={capitalize(item.name)}
                      />
                    </div>
                    <SvgText
                      height={18}
                      width={
                        calculateElementWidth('span', item.duration, '14px')
                          .width + 5
                      }
                      fontFamily={normalFont}
                      textAttr={grayTextAttr}
                      text={item.duration}
                    />
                  </div>
                ))}
            </div>
          )
        })}
      </div>
    )
  }

  renderLabels(labels) {
    const { programData } = this.props
    if (programData && programData.exercise.program) {
      return this.renderProgramLabels(labels)
    }
    const { aerobic, resistance, activity, movementQuality } = labels
    let labelData = [
      {
        name: 'Aerobic',
        data: aerobic,
        totalDuration: getSum(aerobic.map((item) => item.duration))
      },
      {
        name: 'Resistance',
        data: resistance,
        totalDuration: getSum(resistance.map((item) => item.duration))
      },
      {
        name: 'Activity',
        data: activity,
        totalDuration: getSum(activity.map((item) => item.duration))
      }
    ]
    if (movementQuality) {
      labelData.push({
        name: 'Movement Quality',
        data: movementQuality,
        totalDuration: getSum(movementQuality.map((item) => item.duration))
      })
    }

    labelData = labelData.filter((item) => item.data.length > 0)
    return (
      <div className="workout-labels" align="top">
        <Row gutter={32} type="flex">
          {labelData.map((item, index) => {
            const fullWidth = labelData.length === 1
            return (
              <Col span={fullWidth ? 24 : 12} key={index}>
                {this.renderLabel(item, 150)}
              </Col>
            )
          })}
        </Row>
      </div>
    )
  }

  render() {
    const { startDate, endDate, exercise, programData } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    const { barDatas, tickValues, labels } = this.dataHandle(
      tickValuesX,
      exercise,
      programData
    )
    return (
      <>
        <div className="chart-container">
          <div className="label left-axis">
            <SvgText
              height={140}
              width={30}
              fontFamily={normalFont}
              textAttr={{
                x: -100,
                y: 20,
                fontSize: 12,
                fill: '#70808E',
                fontWeight: 'normal',
                transform: 'rotate(-90) ',
                textAnchor: 'start',
                dominantBaseline: 'top'
              }}
              text="MINUTES"
            />
          </div>
          {this.renderChart(barDatas, tickValues, tickValuesX)}
        </div>
        {this.renderLabels(labels)}
      </>
    )
  }
}

export default WorkoutChart

function Thumb(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.956 4.51555H6.731V1.51555C6.7627 1.31132 6.74578 1.10249 6.68161 0.906024C6.61744 0.709561 6.50783 0.531005 6.36169 0.384862C6.21554 0.238719 6.03699 0.12911 5.84053 0.0649398C5.64406 0.000769727 5.43523 -0.0161519 5.231 0.0155496C5.12609 0.0227908 5.02612 0.0629278 4.94534 0.130252C4.86455 0.197577 4.80704 0.288661 4.781 0.39055L3 6.01555V12.0155H9.431C9.9079 12.0063 10.366 11.8277 10.7232 11.5117C11.0805 11.1956 11.3137 10.7628 11.381 10.2905L11.981 6.84055C12.0282 6.56108 12.0123 6.27459 11.9344 6.00207C11.8566 5.72954 11.7187 5.47789 11.531 5.26555C11.3484 5.02283 11.1098 4.82787 10.8355 4.69728C10.5613 4.56669 10.2595 4.50434 9.956 4.51555Z"
        fill="#7BAD2D"
      />
      <path d="M2 6.0155H0V12.0155H2V6.0155Z" fill="#7BAD2D" />
    </svg>
  )
}
