import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ZoneTabs from './zoneTabs'
import { getSum } from '../../../utils/common'
import Styles from './styles/HRZStage.module.sass'

const _formatSummaryDuration = (duration, unit, hoursInclude) => {
  const momentDuration = moment.duration(duration, unit)
  return momentDuration.format(
    `${hoursInclude && momentDuration.hours() > 0 ? 'h[h] ' : ''}${
      momentDuration.minutes() > 0 ? 'm[m] ' : ''
    }s[s]`,
    {
      trim: false
    }
  )
}

function getLargestNumInArrayIndex(array) {
  return array.indexOf(Math.max.apply(Math, array))
}

function roundPercentageTotals(numArr) {
  // Total percent of the the numbers combined (doesnt always equal 100%).
  const totalPercentage = getSum(numArr)

  // numArr = numArr.map(item => Math.round(item))

  // If not 100%, then we need to work around it by subtracting from the largest number (not as accurate but works out).
  if (totalPercentage !== 100 && totalPercentage !== 0) {
    // Get the index of the largest number in the array.
    var index = getLargestNumInArrayIndex(numArr)
    // Take the difference away from the largest number.
    numArr[index] = Number(numArr[index] - (totalPercentage - 100))
  }

  return numArr
}

class HeartRateZoneStage extends Component {
  static propTypes = {
    editGoal: PropTypes.object,
    activityDetail: PropTypes.object,
    totalWidth: PropTypes.number,
    calculatePercentage: PropTypes.bool,
    showACMSPercentage: PropTypes.bool
  }

  state = {
    showHRZIndex: 0
  }

  render() {
    const {
      editGoal,
      totalWidth,
      calculatePercentage,
      showACMSPercentage,
      syncTabChange
    } = this.props
    let activityDetail = this.props.activityDetail || {}
    const { showHRZIndex } = this.state
    let zoneTypes = [] //['All', 'Aerobic', 'Resistance', 'Active']
    if (editGoal.goal_type === 'heartRateMetrics') {
      zoneTypes = Object.keys(activityDetail.heart_rate_zones).sort(
        (a, b) =>
          activityDetail.heart_rate_zones[a].sort -
          activityDetail.heart_rate_zones[b].sort
      )
      activityDetail =
        activityDetail.heart_rate_zones[zoneTypes[showHRZIndex]] || {}
      if (activityDetail.heartRateZone) {
        activityDetail.heartRateZone1 = activityDetail.heartRateZone[0]
        activityDetail.heartRateZone2 = activityDetail.heartRateZone[1]
        activityDetail.heartRateZone3 = activityDetail.heartRateZone[2]
        activityDetail.heartRateZone4 = activityDetail.heartRateZone[3]
        activityDetail.heartRateZone5 = activityDetail.heartRateZone[4]
      }
    }
    let heartRateZoneSource =
      activityDetail.heartRateZoneSource || new Array(5).fill(0)
    if (calculatePercentage) {
      // heartRateZoneSource = heartRateZoneSource.map(
      //   item => Math.round(item / 60) * 60
      // )
      const [z1, z2, z3, z4, z5] = heartRateZoneSource
      const z1_h = parseInt(z1 / 3600)
      const z1_m = Math.round((z1 - z1_h * 3600) / 60)
      activityDetail.heartRateZone1 =
        z1_h > 0
          ? `${z1_h}h ${z1_m}m`
          : moment.duration(z1, 's').format('m') + 'm'
      activityDetail.heartRateZone2 = moment.duration(z2, 's').format('m') + 'm'
      activityDetail.heartRateZone3 = moment.duration(z3, 's').format('m') + 'm'
      activityDetail.heartRateZone4 = moment.duration(z4, 's').format('m') + 'm'
      activityDetail.heartRateZone5 = moment.duration(z5, 's').format('m') + 'm'
      activityDetail.heartRateZone1Percentage = null
    }
    if (
      !activityDetail.heartRateZone1Percentage &&
      typeof activityDetail.heartRateZone1Percentage !== 'number'
    ) {
      let heartRateZonePercentage = activityDetail.heartRateZonePercentage
      if (!activityDetail.heartRateZonePercentage || calculatePercentage) {
        heartRateZoneSource = heartRateZoneSource.map((item) =>
          Math.round(item / 60)
        )
        const sum = getSum(heartRateZoneSource)
        heartRateZonePercentage = roundPercentageTotals(
          heartRateZoneSource.map((item) => Math.round((item * 100) / sum))
        )
      }

      const [
        heartRateZone1Percentage,
        heartRateZone2Percentage,
        heartRateZone3Percentage,
        heartRateZone4Percentage,
        heartRateZone5Percentage
      ] = heartRateZonePercentage
      activityDetail = Object.assign(activityDetail, {
        heartRateZone1Percentage,
        heartRateZone2Percentage,
        heartRateZone3Percentage,
        heartRateZone4Percentage,
        heartRateZone5Percentage
      })
    }
    if (
      activityDetail.heartRateZone1 &&
      activityDetail.heartRateZone1.includes(':')
    ) {
      activityDetail.heartRateZone1 = _formatSummaryDuration(
        heartRateZoneSource[0],
        'seconds',
        true
      )
      activityDetail.heartRateZone2 = _formatSummaryDuration(
        heartRateZoneSource[1],
        'seconds'
      )
      activityDetail.heartRateZone3 = _formatSummaryDuration(
        heartRateZoneSource[2],
        'seconds'
      )
      activityDetail.heartRateZone4 = _formatSummaryDuration(
        heartRateZoneSource[3],
        'seconds'
      )
      activityDetail.heartRateZone5 = _formatSummaryDuration(
        heartRateZoneSource[4],
        'seconds'
      )
    }
    let max = Math.max(...heartRateZoneSource)

    const values = [
      {
        color: 'rgba(255,184,0,0.6)',
        desc: 'Warm Up - Z1',
        time: activityDetail.heartRateZone1,
        percentage: activityDetail.heartRateZone1Percentage
      },
      {
        color: 'rgba(255,149,51,.7)',
        desc: 'Easy - Z2',
        time: activityDetail.heartRateZone2,
        percentage: showACMSPercentage
          ? activityDetail.acsmPercentage[0]
          : activityDetail.heartRateZone2Percentage
      },
      {
        color: 'rgba(255,107,0,.8)',
        desc: 'Aerobic - Z3',
        time: activityDetail.heartRateZone3,
        percentage: showACMSPercentage
          ? activityDetail.acsmPercentage[1]
          : activityDetail.heartRateZone3Percentage
      },
      {
        color: 'rgba(255,88,51,.9)',
        desc: 'Threshold - Z4',
        time: activityDetail.heartRateZone4,
        percentage: showACMSPercentage
          ? activityDetail.acsmPercentage[2]
          : activityDetail.heartRateZone4Percentage
      },
      {
        color: '#FF3D00',
        desc: 'Maximum - Z5',
        time: activityDetail.heartRateZone5,
        percentage: showACMSPercentage
          ? activityDetail.acsmPercentage[3]
          : activityDetail.heartRateZone5Percentage
      }
    ]
    if (showACMSPercentage) {
      values.shift()
      const newSource = Array.from(new Set(heartRateZoneSource))
      newSource.shift()
      max = Math.max(...newSource)
    }

    if (max === 0) {
      max = 1
    }

    const widths = heartRateZoneSource.map((value) => {
      return (value / max) * (totalWidth || 205)
    })
    const heartRateZoneDefinition = activityDetail.heartRateZoneDefinition

    return (
      <div className={Styles.activityHeartRateZoneContainer}>
        <div className={Styles.heartRateTitle}>
          {showACMSPercentage ? 'Target Breakdown' : 'Heart Rate Zones'}
        </div>

        {editGoal.goal_type === 'heartRateMetrics' && (
          <>
            <div
              // className="activity-toggle-container"
              style={{
                marginTop: 0
              }}
            >
              <ZoneTabs
                zoneTypes={zoneTypes}
                onTabChange={(activeKey, zoneType) => {
                  syncTabChange && syncTabChange(zoneType)
                  this.setState({ showHRZIndex: activeKey })
                }}
              />
            </div>
          </>
        )}

        {values.reverse().map((value, index) => {
          return (
            <div key={index} className={Styles.activityHeartRateZone}>
              <div
                style={{
                  background: value.color,
                  opacity: value.opacity,
                  width: widths[4 - index],
                  borderRadius: 4,
                  borderLeft:
                    widths[4 - index] > 0 ? 'none' : `1px solid ${value.color}`
                }}
              />
              <div className={Styles.activityHeartRateInfoContainer}>
                <span className={Styles.time}>
                  {value.time} ({(value.percentage || 0) + '%'})
                </span>
                <span className={Styles.subContent}>
                  {value.desc}{' '}
                  {(heartRateZoneDefinition &&
                    heartRateZoneDefinition[4 - index]) ||
                    ''}
                </span>
                {/* <span className="subContent"></span> */}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

export default HeartRateZoneStage
