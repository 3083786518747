import React, { Component } from 'react'
import {
  getClientList,
  getClientDetail
} from '../../../models/breadcrumb.model'
import ClientProfile from '../../../components/client/profile'

class ProfileOverview extends Component {
  static propTypes = {}

  state = {
    person: null
  }

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }

    setClientPageTitle(null)
    setClientBreadcrumb &&
      setClientBreadcrumb([getClientList(), getClientDetail(person, true)])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    return (
      (person && (
        <>
          <ClientProfile {...this.props} person={person} />
        </>
      )) || <></>
    )
  }
}

export default ProfileOverview
