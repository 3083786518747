import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import DatasetDetailContainer from '../../../components/business/dataset/detail'
import DatasetDetailWrapper from '../../../components/business/dataset/detail/wrapper'

const DetailView = DatasetDetailWrapper(DatasetDetailContainer)

class DatasetDetail extends Component {
  static propTypes = {}

  render() {
    const {
      match: { params },
      history,
      groups
    } = this.props
    return <DetailView {...params} history={history} groups={groups} />
  }
}

export default DatasetDetail
