import React, { Component } from 'react'
import moment from 'moment'
// import { Input, Button } from 'antd'
import { SimpleCheckIcon } from '../../components/icons'
import Loading from '../../components/UI/status/loading'
import PolygonAvatar from '../UI/polygonAvatar'
import { loadUser } from '../../utils/storage'
import { getRole, linkify } from '../../utils/common'
import Editor from './editor'

// const { TextArea } = Input

class MessageItem extends Component {
  static propTypes = {}

  state = {
    showEdit: false,
    value: this.props.text,
    submitting: false,
    rich_text: this.props.rich_text
  }

  changeHandle = (e) => this.setState({ value: e.target.value })

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({ value: this.props.text })
    }
  }

  renderMedia = () => {
    const { text } = this.props
    const {
      // type,  // type default is image
      loading,
      url,
      error
    } = text

    return (
      <div
        className={`message-media-info ${loading ? 'loading' : ''} ${
          error ? 'error' : ''
        }`}
      >
        {/* <Card cover={<img src={url} alt="message" />} loading={loading} /> */}
        <img src={url} alt="message" />
        {loading && <Loading />}
        {error && <div className="error">{error}</div>}
      </div>
    )
  }

  renderMessage = (linkifyMessage, edited) => {
    if (linkifyMessage.match(/<a/gim)) {
      return (
        <div className="text-container">
          <div
            style={{
              whiteSpace: 'pre-wrap',
              wordBreak: 'break-word'
            }}
            dangerouslySetInnerHTML={{
              __html: linkifyMessage
            }}
          />
          {edited && <span className="is-edited">(Edited)</span>}
        </div>
      )
    }
    return (
      <pre>
        {linkifyMessage}
        {edited && (
          <span className="is-edited">
            <br />
            (Edited)
          </span>
        )}
      </pre>
    )
  }

  render() {
    const {
      sender_person,
      id,
      created_at,
      text,
      status,
      is_draft,
      clientId
    } = this.props
    if (is_draft) return null
    const { value, rich_text } = this.state
    const loginUser = loadUser()
    const role = getRole(loginUser.role)
    const showEdit =
      this.state.showEdit && (typeof text === 'string' || rich_text)
    const isEditable = id && (typeof text === 'string' || rich_text)
    return (
      <div className="message-item">
        <div className="avatar">
          <PolygonAvatar
            width={40}
            image={sender_person.avatar && sender_person.avatar.oss_url}
            innerId={`message-${id}-inner-${sender_person.id}`}
            hexId={`message-${id}-hex-${sender_person.id}`}
          />
        </div>
        <div className="message-content">
          <div className="message-tag">
            <span>
              <strong>
                {(sender_person.profile && sender_person.profile.nickname) ||
                  sender_person.first_name}
              </strong>
              {moment(created_at).format('MMM DD, YYYY')} at{' '}
              {moment(created_at).format('h:mm A ')}
              {getRole(sender_person.role) === role && (
                <SimpleCheckIcon
                  style={{
                    marginLeft: 8,
                    color:
                      Number(status) === 1 ? '#2B4B8F' : 'rgb(151, 160, 167)'
                  }}
                  // type="check"
                />
              )}
            </span>

            {loginUser.id === sender_person.id &&
              role === 'Expert' &&
              isEditable &&
              !showEdit && (
                <div
                  className="opr"
                  onClick={() => this.setState({ showEdit: true })}
                >
                  Edit
                </div>
              )}
          </div>
          {showEdit ? (
            <Editor
              value={rich_text}
              close={() => this.setState({ showEdit: false })}
              text={value}
              id={id}
              clientId={clientId}
              onSubmitCallback={(rich_text) => this.setState({ rich_text })}
            />
          ) : (
            this.renderContent()
          )}
        </div>
      </div>
    )
  }

  renderContent() {
    const { edited } = this.props
    const { value: text, rich_text } = this.state
    if (rich_text) {
      return (
        <div className="message-info">
          <div dangerouslySetInnerHTML={{ __html: rich_text }} />
        </div>
      )
    } else if (text) {
      return typeof text === 'string' ? (
        <div className="message-info">
          {this.renderMessage(linkify(text), edited)}
        </div>
      ) : (
        this.renderMedia()
      )
    } else {
      return null
    }
  }
}

export default MessageItem
