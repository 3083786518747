import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Tabs, Card, Row, Col, Typography, Select } from 'antd'
import Empty from '../../../components/UI/status/empty'
import { countViewNumber } from '../../../api/recommend'

import { Pluralize, getBlogUrl } from '../../../utils/common'
import { resourceStyle } from './index.sass'

const { TabPane } = Tabs
const { Meta } = Card
const { Paragraph } = Typography
const { Option } = Select

class ResourceCategory extends Component {
  static propTypes = {}

  state = { categories: [], sort: 'Latest' }

  UNSAFE_componentWillMount() {
    const {
      match: {
        params: { category }
      }
    } = this.props
    const list = this.props.list.filter(
      (item) => item.content_category.category === category.toLowerCase()
    )

    const subCategories = list.map((item) => item.content_category.sub_category)
    let categories = Array.from(new Set(subCategories))
    categories.sort()
    categories.unshift('All')
    categories = categories.map((item) => ({
      name: item.toUpperCase(),
      list:
        item === 'All'
          ? list
          : list.filter((_item) => _item.content_category.sub_category === item)
    }))
    this.setState({ categories })
  }

  sortList = (list) => {
    const { sort } = this.state
    switch (sort) {
      case 'Oldest':
        list = list.sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        break
      case 'Latest':
        list = list.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
        break
      case 'Popular':
        list = list.sort((a, b) => b.number_of_view - a.number_of_view)
        break
      default:
        break
    }
    return list
  }

  render() {
    const {
      match: {
        params: { category }
      }
    } = this.props

    const { categories, sort } = this.state
    return (
      <div className={resourceStyle}>
        <h1 className="title">
          {category}
          <div className="sort">
            Sort by:{' '}
            <Select
              defaultValue="Latest"
              value={sort}
              style={{ width: 100, height: 40 }}
              onChange={(value) => this.setState({ sort: value })}
            >
              <Option value="Latest">Latest</Option>
              <Option value="Oldest">Oldest</Option>
              <Option value="Popular">Popular</Option>
            </Select>
          </div>
        </h1>
        <Tabs defaultActiveKey="ALL">
          {categories.map((category, _index) => (
            <TabPane
              tab={`${category.name}(${category.list.length})`}
              key={category.name}
            >
              <div className="select-container">
                <Row className="content-list" gutter={24} type="flex">
                  {this.sortList(category.list).map((item, index) => (
                    <Col
                      span={6}
                      key={index}
                      onClick={() => {
                        countViewNumber(item.id)
                        if (item.link_domain === 'blog.apeiron.life') {
                          const url = getBlogUrl(item.link.split('/').pop())
                          window.open(url)
                        } else {
                          window.open(item.link)
                        }
                      }}
                    >
                      <Card
                        hoverable
                        key={index}
                        className="content-item category-item"
                        cover={<img alt="example" src={item.cover_url} />}
                      >
                        <div
                          className={`item-type ${
                            item.content_category.category
                          }`}
                        >
                          {item.content_category.sub_category}
                        </div>
                        <Meta
                          className="item-meta"
                          title={item.title}
                          description={
                            <Paragraph ellipsis>{item.description}</Paragraph>
                          }
                        />

                        <div className="item-tip">
                          Article • {item.estimate_view_time}{' '}
                          {Pluralize('mins', item.estimate_view_time)} read
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>

                {category.list.length === 0 && (
                  <Empty message="Nothing to read yet!" />
                )}
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  }
}

export default ResourceCategory
