import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import TouchInput from '../../../components/business/creport/touchInput'
import KeyTakeaway from '../../../components/business/creport/keyTakeaway'
import ReportFiles from '../../../components/business/creport/files'
import { deleteAttachment } from '../../../api/file'
import imgaeICon from '../../../asserts/icon/drag-file-icon.svg'

class ReportBodyComp extends Component {
  static propTypes = {
    report: PropTypes.object,
    addAttachment: PropTypes.func,
    updateReport: PropTypes.func,
    deleteAttachmentSync: PropTypes.func
  }

  state = {
    isDeleting: false
  }

  uploadFile = (file, progressCallback, source = null) => {
    const { addAttachment } = this.props
    addAttachment(
      'report.body_comp_media_files',
      file,
      progressCallback,
      source
    )
  }

  deleteFile = async (id) => {
    const { deleteAttachmentSync, person } = this.props
    const { isDeleting } = this.state
    if (isDeleting) return
    this.setState({ isDeleting: true })
    try {
      await deleteAttachment(id, person.id)
      this.setState({ isDeleting: false })
      deleteAttachmentSync('body_comp_media_files', id)
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { report, updateReport } = this.props
    return (
      report && (
        <div>
          <TouchInput
            initialValue={report.body_comp_intro}
            placeholder="click to Add introduction"
            save={(value) => updateReport({ body_comp_intro: value })}
          />
          <Divider className="full" />
          <h1 className="section-title">Body Composition & Vitals Summary</h1>
          <TouchInput
            initialValue={report.body_comp_summary}
            placeholder="+ Click to add summary"
            save={(value) => updateReport({ body_comp_summary: value })}
          />
          {/* <h1>body comp range output</h1> */}
          <Divider className="full" />
          <h1 className="section-title">key takeaway</h1>
          <KeyTakeaway
            initialValue={report.body_comp_takeaway}
            update={(value) => updateReport({ body_comp_takeaway: value })}
          />
          <ReportFiles
            uploadIcon={imgaeICon}
            save={(value) => updateReport({ body_comp_media_intro: value })}
            files={report.body_comp_media_files || []}
            introduction={report.body_comp_media_intro}
            upload={this.uploadFile}
            deleteFile={this.deleteFile}
          />
        </div>
      )
    )
  }
}

export default ReportBodyComp
