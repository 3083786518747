import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CategoriesWrapper from './categoriesWrapper'
import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  UpOutlined
} from '@ant-design/icons'
import { Button, Popover } from 'antd'
import ModalForm from '../../../components/modalFrom'
import PageLoading from '../../../components/UI/status/loading'
import {
  createCategoryForm,
  createMetric
} from '../../../models/bioMarkers.form'
import { DragDropContext } from 'react-beautiful-dnd'
import Category from './category'

class Categories extends Component {
  static propTypes = {
    type: PropTypes.string,
    path: PropTypes.string,
    biomarkersFiled: PropTypes.string,
    fixNumber: PropTypes.number,
    getCategories: PropTypes.func,
    createCategory: PropTypes.func,
    createMetric: PropTypes.func,
    delMetric: PropTypes.func,
    delCategory: PropTypes.func
  }

  state = {
    reOrdering: false
  }
  onDragEnd = (result) => {
    const { destination, source } = result
    /*
    draggableId: metric.id
    destination: {
      droppableId: target category.id-index-column length
      index: index -> index*column length
    }
    source: {
      droppableId: source category.id-index-column length
      index: index -> index*column length
    }
    */
    if (destination && source) {
      const [
        sourceType,
        sourceCategoryId,
        sourceColumnIndexOrRootCatrgoryId,
        sourceColumnLength
      ] = source.droppableId.split('-')
      const [
        destinationType,
        destinationCategoryId,
        destinationColumnIndexOrRootCatrgoryId,
        destinationColumnLength
      ] = destination.droppableId.split('-')
      // source.type = sourceType
      source.categoryId = Number(sourceCategoryId)
      destination.categoryId = Number(destinationCategoryId)
      if (sourceType === 'metric') {
        source.order = sourceColumnLength
          ? Number(sourceColumnIndexOrRootCatrgoryId) *
              Number(sourceColumnLength) +
            source.index
          : 0
      } else if (sourceType === 'subCategory') {
        source.order = source.index
        source.rootCategoryId = Number(sourceColumnIndexOrRootCatrgoryId)
      } else {
        source.order = source.index
      }

      if (destinationType === 'metric') {
        destination.order = destinationColumnLength
          ? Number(destinationColumnIndexOrRootCatrgoryId) *
              Number(destinationColumnLength) +
            destination.index
          : 0
      } else if (destinationType === 'subCategory') {
        destination.order = destination.index
        destination.rootCategoryId = Number(
          destinationColumnIndexOrRootCatrgoryId
        )
      } else {
        destination.order = destination.index
      }

      this.props.onDragEndHandle(result)
    }
  }

  reOrderCategory = async (categoryId, upOrdown) => {
    const { categories } = this.props
    let sourceIndex = categories.findIndex((item) => item.id === categoryId)
    let params = categories.map(({ id }, index) => {
      return { id, sequence: index }
    })
    if (upOrdown === 'up') {
      params[sourceIndex - 1].sequence++
      params[sourceIndex].sequence--
    } else {
      params[sourceIndex].sequence++
      params[sourceIndex + 1].sequence--
    }
    this.setState({ reOrdering: true })
    try {
      await this.props.reOrderRootCategory(params)
      this.setState({ reOrdering: false })
    } catch (err) {
      this.setState({ reOrdering: false })
    }
  }

  render() {
    const {
      modalTitle,
      visible,
      formData,
      valueForm,
      loading,
      categories,
      pageLoading,
      setPropsState,
      fixNumber,
      biomarkersFiled
    } = this.props

    return pageLoading ? (
      <PageLoading />
    ) : (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Button
          type="primary"
          ghost
          onClick={() =>
            setPropsState({
              modalTitle: 'Create a category',
              submitType: 'add category',
              valueForm: {},
              visible: true,
              formData: createCategoryForm
            })
          }
        >
          Add Category
        </Button>
        {categories.map((item, index) => (
          <div className="category" key={index}>
            <h5>
              <Popover
                placement="bottomLeft"
                content={
                  <p style={{ maxWidth: '200px' }}>{item.description}</p>
                }
              >
                <span>{item.name}</span>
              </Popover>
              {(!fixNumber || index > fixNumber - 1) &&
                ((item[biomarkersFiled] &&
                  item[biomarkersFiled].length === 0) ||
                  !item[biomarkersFiled]) &&
                ((item.child_categories &&
                  item.child_categories.length === 0) ||
                  !item.child_categories) && (
                  <Button
                    icon={<CloseOutlined />}
                    danger
                    onClick={() => this.props.deleteCategory(item)}
                  />
                )}
              <Button
                onClick={(_) =>
                  setPropsState({
                    visible: true,
                    submitType: 'edit category',
                    valueForm: item,
                    categoryId: item.id,
                    modalTitle: 'Edit category',
                    formData: createCategoryForm
                  })
                }
              >
                <EditOutlined />
              </Button>
              {(!item[biomarkersFiled] ||
                item[biomarkersFiled].length === 0) && (
                <Button
                  type="primary"
                  ghost
                  className="white-btn"
                  onClick={() =>
                    setPropsState({
                      visible: true,
                      submitType: 'add sub-category',
                      valueForm: {},
                      parentCategoryId: item.id,
                      modalTitle: `Add sub category for ${item.name}`,
                      formData: createCategoryForm
                    })
                  }
                >
                  Add Sub Category
                </Button>
              )}

              {(!item.child_categories ||
                item.child_categories.length === 0) && (
                <Button
                  type="primary"
                  ghost
                  className="white-btn"
                  onClick={() =>
                    setPropsState({
                      visible: true,
                      submitType: 'add metric',
                      valueForm: {},
                      categoryId: item.id,
                      modalTitle: 'Add Metric',
                      formData: createMetric
                    })
                  }
                >
                  Add Metric
                </Button>
              )}
              {index !== 0 && (
                <Button
                  type="primary"
                  ghost
                  className="white-btn"
                  onClick={() => this.reOrderCategory(item.id, 'up')}
                  disabled={this.state.reOrdering}
                >
                  <UpOutlined />
                </Button>
              )}
              {index !== categories.length - 1 && (
                <Button
                  type="primary"
                  ghost
                  className="white-btn"
                  onClick={() => this.reOrderCategory(item.id, 'down')}
                  disabled={this.state.reOrdering}
                >
                  <DownOutlined />
                </Button>
              )}
            </h5>
            <Category {...this.props} category={item} />
          </div>
        ))}
        <ModalForm
          title={modalTitle}
          visible={visible}
          formData={formData}
          valueForm={valueForm || {}}
          onSubmit={this.props.submit}
          onCancel={() => setPropsState({ visible: false })}
          loading={loading}
        />
      </DragDropContext>
    )
  }
}

export default CategoriesWrapper(Categories)
