import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider } from 'antd'
import { Element } from 'react-scroll'
import ClientCategory from './clientCategory'
// import BodyCompLogo from '../../../../asserts/images/body-com-dataset.svg'
// import LifeStyleLogo from '../../../../asserts/images/life-style-dataset.svg'
import { clientCategoriesStyle } from './index.sass'

class ClientCategories extends Component {
  static propTypes = {}

  renderImage(imageUrl) {
    return (
      <div className="image-content">
        <img src={imageUrl} alt="attachment" />
      </div>
    )
  }

  renderBodyCompContainer(categories) {
    const {
      dbField: { metricsField },
      dataset,
      turnTo,
      ageScopes,
      person
    } = this.props
    // const [category] = categories || []
    return (
      <>
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="category-container">
              <div className="root-category">
                {category && (
                  <ClientCategory
                    category={category}
                    {...{
                      ageScopes,
                      person,
                      metricsField,
                      turnTo,
                      testDate: dataset.test_date
                    }}
                  />
                )}

                {category && category.name === 'Total Body Composition' && (
                  <div className="root-category">
                    <Divider orientation="left">
                      Composition Trend: Total Graph
                    </Divider>
                    {(dataset.trend_graph &&
                      this.renderImage(dataset.trend_graph.oss_url)) || (
                      <div className="sec-no-data card-list">no data</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Element>
        ))}
      </>
    )
  }

  renderLifeStyleContainer(categories) {
    const {
      dataset,
      dbField: { metricsField },
      turnTo,
      ageScopes,
      person
    } = this.props
    // const [category] = categories || []
    return (
      <>
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="category-container">
              {/* {index === 0 && (
                <h1>
                  <img src={LifeStyleLogo} alt="logo" />
                </h1>
              )} */}
              <div className="root-category">
                {category && category.name === 'Stress' && (
                  <div className="attachment-container">
                    <Divider orientation="left">Stress Summary</Divider>
                    {dataset.stress_summary ? (
                      this.renderImage(dataset.stress_summary.oss_url)
                    ) : (
                      <div className="sec-no-data card-list">no data</div>
                    )}
                  </div>
                )}
                {category && category.name === 'Recovery' && (
                  <div className="attachment-container">
                    <Divider orientation="left">Recovery Summary</Divider>
                    {dataset.recovery_summary ? (
                      this.renderImage(dataset.recovery_summary.oss_url)
                    ) : (
                      <div className="sec-no-data card-list">no data</div>
                    )}
                  </div>
                )}
                {category && category.name === 'Exercise' && (
                  <div className="attachment-container">
                    <Divider orientation="left">Exercise Summary</Divider>
                    {dataset.exercise_summary ? (
                      this.renderImage(dataset.exercise_summary.oss_url)
                    ) : (
                      <div className="sec-no-data card-list">no data</div>
                    )}
                  </div>
                )}
                {category && (
                  <ClientCategory
                    category={category}
                    {...{
                      metricsField,
                      turnTo,
                      testDate: dataset.test_date,
                      ageScopes,
                      person
                    }}
                  />
                )}
              </div>
            </div>
          </Element>
        ))}{' '}
      </>
    )
  }

  renderCommonCategories(categories) {
    const {
      turnTo,
      dataset,
      dbField: { metricsField },
      ageScopes,
      person
    } = this.props
    return (
      <div className="category-container">
        {categories.map((category, index) => (
          <Element name={`category_${category.name}`} key={index}>
            <div className="root-category extra">
              <ClientCategory
                category={category}
                {...{
                  metricsField,
                  turnTo,
                  testDate: dataset.test_date,
                  ageScopes,
                  person
                }}
              />
            </div>
          </Element>
        ))}
      </div>
    )
  }

  render() {
    const { type } = this.props
    let { categories } = this.props
    // categories = categories.filter(item => item.name === selectCategory)
    let children
    switch (type) {
      case 'body-comp':
        children = this.renderBodyCompContainer(categories)
        break
      case 'life-style':
        children = this.renderLifeStyleContainer(categories)
        break
      default:
        children = this.renderCommonCategories(categories)
    }
    return <div className={clientCategoriesStyle}>{children}</div>
  }
}

export default ClientCategories
