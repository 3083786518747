import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'antd'
import BasicForm from '../../../components/formV4'
import { challengeModel } from '../../../models/challenge.form'
import _ from 'lodash'
import moment from 'moment'
import ConfirmDelete from './confirmDelete'

function ChallengeForm(props) {
  const { onCancel, selectedChallenge, handleDelete, metrics } = props
  const [refForm, setRefForm] = useState(null)
  const [groups, setGroups] = useState([])
  const [valueForm, setValueForm] = useState(null)
  const [dateDuplicate, setDateDuplicate] = useState(false)
  const [confirmDel, setConfirmDel] = useState(false)
  const [loading, setLoading] = useState(false)
  const [metricOptions, setMetricOptions] = useState([])

  useEffect(() => {
    const values = initialValueForm()
    setValueForm(values)

    const options = metrics.filter(
      ({ pillar, source }) =>
        pillar === values.pillar && source === values.source
    )
    setMetricOptions(options)
    if (values.is_group_metrics) {
      setGroups(
        metrics.find((metric) => metric.group === values.metric).children
      )
    }
  }, [])

  const formData = challengeModel({
    // target_goal: (valueForm && valueForm.target_goal) || 'greater',
    month: valueForm && valueForm.month,
    year: valueForm && valueForm.year,
    dateDuplicate,
    isActive: selectedChallenge && selectedChallenge.status === 'active',
    metrics,
    metricOptions: metricOptions.map(({ metric }) => ({
      label: metric,
      value: metric
    })),
    groups,
    valueForm: valueForm || {}
  })

  return (
    <>
      <Modal
        maskClosable={false}
        visible={true}
        onCancel={onCancel}
        className="client-creation"
        width={'-'}
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {selectedChallenge && (
                <Button
                  danger
                  type="text"
                  style={{ height: 48 }}
                  onClick={() => setConfirmDel(true)}
                >
                  DELETE CHALLENGE
                </Button>
              )}
            </div>
            <div>
              <Button type="text" style={{ height: 48 }} onClick={onCancel}>
                CANCEL
              </Button>
              <Button
                onClick={submit}
                type="primary"
                style={{ width: 155, height: 48 }}
                loading={loading}
              >
                {selectedChallenge && selectedChallenge.status === 'active'
                  ? 'SAVE'
                  : 'SCHEDULE'}
              </Button>
            </div>
          </div>
        }
      >
        <h1 className="title">
          {selectedChallenge ? 'Edit' : 'Create'} Challenge
        </h1>
        {valueForm && formData && (
          <BasicForm
            wrappedComponentRef={(inst) => {
              setRefForm(inst)
              if (valueForm.is_group_metrics) {
                inst.setFieldValue('is_group_metrics', 'yes')
              }
            }}
            formData={formData}
            onChange={updateValueForm}
            initialValues={valueForm}
          />
        )}
      </Modal>
      {confirmDel && (
        <ConfirmDelete
          handleDelete={handleDelete}
          onCancel={() => setConfirmDel(false)}
          challenge={selectedChallenge}
        />
      )}
    </>
  )

  function initialValueForm() {
    if (selectedChallenge) {
      const initialValues = _.cloneDeep(selectedChallenge)
      if (initialValues.status === 'past') {
        delete initialValues.month
        delete initialValues.year
      } else {
        initialValues.month = selectedChallenge.start
        initialValues.year = selectedChallenge.start
      }
      if (initialValues.metric.includes(',')) {
        initialValues.is_group_metrics = 'yes'
        initialValues.metrics = initialValues.metric.split(',')
        initialValues.metric = metrics.find((item) =>
          item.children
            ? item.children.find((child) => child === initialValues.metrics[0])
            : item.metric === initialValues.metrics[0]
        ).group
      } else {
        const group = metrics.find((item) =>
          item.children
            ? item.children.find((child) => child === initialValues.metric)
            : item.metric === initialValues.metric
        ).group
        if (group) {
          initialValues.is_group_metrics = 'yes'
          initialValues.metrics = [initialValues.metric]
          initialValues.metric = group
        }
      }

      if (initialValues.instructions && initialValues.instructions.length > 0) {
        for (const index in initialValues.instructions) {
          const { title, link } = initialValues.instructions[index]
          initialValues[`instruction_title_${index}`] = title
          initialValues[`instruction_${index}`] = link
        }
      }

      return initialValues
    } else {
      return {
        // source: 'auto',
        // pillar: 'exercise',
        // metric: 'Steps',
        // frequency: 'daily',
        // // target_comparison: 'gte',
        // // target: 10000,
        // ranking: 'Most total days in the month'
      }
    }
  }
  function updateValueForm(changeValues, allValues) {
    const metricChangeHandle = (newMetric) => {
      const targetMetric =
        newMetric && metrics.find((item) => item.metric === newMetric.metric)
      if (targetMetric && targetMetric.group) {
        setGroups(targetMetric.children)
        refForm.setFieldValue('is_group_metrics', 'yes')
      } else {
        refForm.setFieldValue('is_group_metrics', null)
      }
    }
    if (dateDuplicate && (changeValues.month || changeValues.year)) {
      setDateDuplicate(false)
    }
    if (changeValues.month && !allValues.year) {
      refForm.setFieldValue('year', changeValues.month)
    }
    if (changeValues.pillar || changeValues.source) {
      const newMetricOptions = metrics.filter(
        ({ pillar, source }) =>
          pillar === allValues.pillar && source === allValues.source
      )
      setMetricOptions(newMetricOptions)
      refForm.setFieldValue(
        'metric',
        newMetricOptions[0] && newMetricOptions[0].metric
      )
      refForm.syncFormValues({
        metric: newMetricOptions[0] && newMetricOptions[0].metric
      })
      if (
        !newMetricOptions[0] ||
        newMetricOptions[0].metric !== allValues.metric
      ) {
        metricChangeHandle(newMetricOptions[0])
      }
    }

    if (changeValues.metric) {
      metricChangeHandle(changeValues)
    }
    setValueForm(allValues)
  }

  async function submit() {
    setLoading(true)
    try {
      const params = await refForm.handleSubmit()
      const { month, year, target, target_upper } = params
      params.month = moment(month).format('MM')
      params.year = moment(year).format('YYYY')
      params.start = moment(`${params.year}-${params.month}-01`).format(
        'YYYY-MM-DD'
      )
      params.end = moment(
        `${params.year}-${params.month}-${moment(params.start).daysInMonth()}`
      ).format('YYYY-MM-DD')
      delete params.month
      delete params.year
      if (target) {
        params.target = _.toString(target)
      } else {
        params.target = ''
      }
      if (target_upper) {
        params.target_upper = _.toString(target_upper)
      }
      if (params.metric === 'Yes/No') {
        // for no alcohol
        params.target = 'yes'
        params.target_comparison = 'eq'
      }
      if (params.metrics && params.metrics.length) {
        params.metric = params.metrics.join(',')
        delete params.metrics
      }

      let instructions = Object.keys(params).filter((field) =>
        field.includes('instruction_title_')
      )

      if (instructions.length > 0) {
        // handle instructions
        instructions = instructions
          .map((key) => {
            const item = {
              title: params[key],
              link: params[key.replace('title_', '')]
            }
            delete params[key]
            delete params[key.replace('title_', '')]
            return item
          })
          .filter((item) => item.title)

        params.instructions = instructions
      }

      await props.submit(
        _.pick(params, [
          ...formData.map((item) => item.dbField),
          'start',
          'end',
          'instructions'
        ])
      )

      props.onCancel()
    } catch (err) {
      console.error(err)
      if (err.message && err.message.includes('same month, same year')) {
        setDateDuplicate(true)
      }
      setLoading(false)
    }
  }
}

export default ChallengeForm
