export const passwordForm = [
  {
    type: 'password',
    label: 'NEW PASSWORD',
    dbField: 'password',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'password',
    label: 'VERIFY NEW PASSWORD',
    dbField: 'password2',
    dependencies: ['password'],
    rules: [
      { required: true, message: 'This field is required' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error('The two passwords that you entered do not match!')
          )
        }
      })
    ]
  }
]
