const navagations = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    href: '{url}',
    title: 'Basic Info & Body Composition & Vitals'
  },
  {
    id: 'questionnaire',
    name: 'Questionnaire',
    href: '{url}/questionnaire',
    title: 'Questionnaire'
  },
  // {
  //   id: 'comprehensive-report',
  //   name: 'Comprehensive Report',
  //   title: 'Comprehensive Report',
  //   href: '{url}/creport'
  // },
  {
    id: 'blood-test',
    name: 'Blood Tests',
    href: '{url}/blood-test',
    title: 'Blood Tests'
  },
  {
    id: 'body-comp',
    name: 'Body Composition & Vitals',
    title: 'Body Composition & Vitals',
    href: '{url}/body-comp'
  },
  {
    id: 'life-style',
    title: 'Lifestyle Assessment',
    name: 'Lifestyle',
    href: '{url}/life-style'
  },
  {
    id: 'physical',
    title: 'Initial 360 Physical Evaluation',
    name: 'Physical',
    href: '{url}/physical'
  },
  {
    id: 'range-of-motions',
    title: 'Range of Motions',
    name: 'Range of Motions',
    href: '{url}/range-of-motions'
  },
  {
    id: 'force-plate',
    title: 'Force plate',
    name: 'Force plate',
    href: '{url}/force-plate'
  },
  {
    id: 'strength',
    title: 'Strength',
    name: 'Strength',
    href: '{url}/strength'
  },
  {
    id: 'cardiorespiratory',
    title: 'Cardiovascular Fitness',
    name: 'Cardiovascular Fitness',
    href: '{url}/cardiorespiratory'
  },
  {
    id: 'cognitive-health',
    title: 'Cognitive Health',
    name: 'Cognitive Health',
    href: '{url}/cognitive-health'
  },
  {
    id: 'movement-efficiency',
    title: 'Movement Efficiency',
    name: 'Movement Efficiency',
    href: '{url}/movement-efficiency'
  },
  {
    id: 'vital',
    title: 'Vitals',
    name: 'Vitals',
    href: '{url}/vital'
  },
  {
    id: 'nutrition',
    title: 'Nutrition',
    name: 'Nutrition',
    href: '{url}/nutrition'
  }
]
export default navagations

export const shareNavagations = [
  {
    id: 'blood-test',
    name: 'Blood Tests',
    href: '{url}/blood-test'
  },
  {
    id: 'body-comp',
    name: 'Body Composition & Vitals',
    title: 'Body Composition & Vitals'
  },
  {
    id: 'life-style',
    title: 'Lifestyle Assessment',
    name: 'Lifestyle'
  },
  {
    id: 'range-of-motions',
    title: 'Range of Motions',
    name: 'Range of Motions'
  },
  {
    id: 'force-plate',
    title: 'Force plate',
    name: 'Force plate'
  },
  {
    id: 'strength',
    title: 'Strength',
    name: 'Strength'
  },
  {
    id: 'cardiorespiratory',
    title: 'Cardiovascular Fitness',
    name: 'Cardiovascular Fitness'
  },
  {
    id: 'cognitive-health',
    title: 'Cognitive Health',
    name: 'Cognitive Health'
  },
  {
    id: 'movement-efficiency',
    title: 'Movement Efficiency',
    name: 'Movement Efficiency'
  },
  {
    id: 'vital',
    title: 'Vitals',
    name: 'Vitals'
  },
  {
    id: 'nutrition',
    title: 'Nutrition',
    name: 'Nutrition'
  }
]

export const draftReportNav = [
  {
    id: 'introduction',
    name: '0. Introduction',
    title: 'Introduction'
  },
  {
    id: 'blood-test-result',
    name: '1. Blood Tests Result',
    title: 'Blood Tests Result'
  },
  {
    id: 'body-composition',
    name: '2. Body Composition & Vitals',
    title: 'Body Composition & Vitals'
  },
  {
    id: 'lifestyle-assessment',
    name: '3. Lifestyle Assessment',
    title: 'Lifestyle Assessment'
  },
  {
    id: 'physical-tests',
    name: '4. Physical Tests',
    title: 'Physical Tests'
  },
  {
    id: 'range-of-motion',
    name: '5. Range of Motion',
    title: 'Range of Motion'
  },
  {
    id: 'movement-efficiency',
    name: '6. Movement Efficiency',
    title: 'Movement Efficiency'
  },
  {
    id: 'recommendations',
    name: '7. Recommendations',
    title: 'Recommendations'
  }
]
