import React, { useState, useEffect } from 'react'
import {
  getClientList,
  getClientDetail,
  getTrends
} from '../../../models/breadcrumb.model'
import TrendsContainer from '../../../components/trends'
import { loadUser } from '../../../utils/storage'

export default function Trends(props) {
  const [person, setPerson] = useState(null)
  const initial = async () => {
    const { setClientPageTitle, setClientBreadcrumb, getPerson } = props
    const person = getPerson ? await getPerson() : loadUser()
    setPerson(person)
    if (setClientPageTitle && setClientBreadcrumb) {
      setClientPageTitle('Trends')
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getTrends(person, true)
      ])
    }
  }
  useEffect(() => {
    initial()
  }, [])

  return <>{person && <TrendsContainer person={person} />}</>
}
