import axios, { formDataRequest } from './axios'
import { setTokenItem, saveUser } from '../utils/storage'
import { questionnaireConfig } from '../utils/constant'
// import { init } from '../server/send-bird'

const initAccount = async (path, params, setLoginAccount) => {
  try {
    const res = await axios.post(path, params)
    saveUser(res.person)
    setTokenItem(res.token)
    setLoginAccount && setLoginAccount(res.person)
    // init(res.person)
    return res.person
  } catch (error) {
    throw error
  }
}

export const refreshProfile = async (id) => {
  const result = await getPeopleById(id)
  saveUser(result.person)
}

export const login = (params, setLoginAccount) =>
  initAccount('/people/login', params, setLoginAccount)

export const register = (params, setLoginAccount) =>
  initAccount('/people', params, setLoginAccount)

export const logOut = () => axios.post('/people/logout')

export const getPeople = (page, size = 10) => {
  const offset = (page > 0 && (page - 1) * size) || 0
  return axios.get(`/people?limit=${size}&offset=${offset}`)
}

export const getPeopleById = (id) =>
  axios.get(`/people/${id}`).then((result) => {
    return {
      person: result.ghostPerson || result.person
    }
  })

export const getPeopleWaitWaiverState = (id) =>
  axios.get('/people/me?poll_waiver_required=false').then((result) => {
    return {
      person: result.ghostPerson || result.person
    }
  })

export const getRecords = (person_id, page, search, size = 10) => {
  const offset = (page > 0 && (page - 1) * size) || 0
  let searchParams = ''
  if (search) {
    for (let key in search) {
      searchParams += `&${key}=${search[key]}`
    }
  }
  return axios.get(
    `/records?person_id=${person_id}&offset=${offset}&limit=${size}${searchParams}`
  )
}

export const getResumePoint = (id) => axios.get(`/records/last?person_id=${id}`)

export const savePersonalBiomarkerValue = (params) =>
  axios.postJson('/people/bio_marker', params)

export const deletePeople = (id) => axios.delete(`/people/${id}`)

export const saveAvatar = (file, id) => {
  const path = `/people/${id}/avatar`
  const formData = new FormData()
  formData.append('file', file)
  return formDataRequest({
    method: 'put',
    path,
    data: formData
  })
}

export const forgotPassword = (params) =>
  axios.post('/people/forgot_password', params)

export const passwordReset = (params) =>
  axios.post('/people/reset_password', params)

export const passwordInitChange = (params) =>
  axios.post('/people/change_password', params)

export const getWaiverForm = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=medical_release_form`
  )
}

export const getLiabilityForm = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=liability_release_form`
  )
}

export const getMembershipAgreement = (suffix) => {
  const now = new Date()
  const hour = now.getHours()
  const minute = now.getMinutes()
  return axios.get(
    `/people/waiver_form?suffix=${suffix}&hour=${hour}&minute=${minute}&type=membership_agreement`
  )
}

export const getWaiverSignedForm = (urlIncluded, person_id) => {
  let url = `/people/waiver_signed?urlIncluded=${urlIncluded}`
  if (person_id) {
    url += `&person_id=${person_id}`
  }
  return axios.get(url)
}

export const getWaiverSinedFormUrl = (id, person_id) => {
  let url = `/people/waiver_signed/${id}`
  if (person_id) {
    url += `?person_id=${person_id}`
  }
  return axios.get(url)
}

export const getQuestions = (personId) => {
  let url = `/survey/${questionnaireConfig.surveyName}`
  if (personId) {
    url += `?person_id=${personId}`
  }
  return axios.get(url)
}

export const getQuestionnaireResult = async (personId) =>
  axios.get(
    `/survey/v4/score/${questionnaireConfig.surveyName}?person_id=${personId}`
  )

export const getEditQuestions = () => {
  let url = `/survey/detail/${questionnaireConfig.surveyName}`
  return axios.get(url)
}

export const postEditQuestions = (m) =>
  axios.put(`/survey/detail/${questionnaireConfig.surveyName}`, m)

export const postAnswer = (
  { answer, id, section_id, nutrition_date },
  person_id
) => {
  let url = `/survey/${id}/answers/${section_id}?person_id=${person_id}`
  if (nutrition_date) {
    url += `&nutrition_date=${nutrition_date}`
  }
  return axios.postJson(url, answer)
}

export const getIntroductory = (personId) =>
  axios.get(`/introductory?person_id=${personId}`)

export const saveIntroductory = (id, params) =>
  axios.putJson(`/introductory/${id}`, params)

export const sendLogisticsEmail = (id) =>
  axios.post(`/introductory/send-logistics-questionnaire-email?person_id=${id}`)

export const sendMedicalReleaseEmail = (id) =>
  axios.post(`/clients/send-medical-release-form-email?person_id=${id}`)

export const completeSurvey = (personId) =>
  axios.post(`/survey/complete?person_id=${personId}`)

export const sendOnboardingCompletedEmail = (personId) =>
  axios.post(`/clients/completed_onboarding_handle?person_id=${personId}`)
// axios.post(`/clients/completed_onboarding_email?person_id=${personId}`)

export const saveStripeSource = (source, person_id) => {
  let url = '/stripe/cards'
  if (person_id) {
    url += `?person_id=${person_id}`
  }
  return axios.postJson(url, { source })
}

export const getCustomer = (id) => {
  let url = '/stripe/cards'
  if (id) {
    url += `?person_id=${id}`
  }
  return axios.get(url)
}

export const updateProfileStatus = async (id, profile, callback) => {
  const {
    team: { person }
  } = await axios.putJson(`/clients/${id}`, { profile })
  await saveUser(person)
  callback && callback(person)
}

export const enable2FA = (params, id) =>
  axios.post(`/people/${id}/enable_2fa`, params)

export const verify2FA = (params, id) =>
  axios.post(`/people/${id}/verify_2fa`, params)

export const setNotification = (params, id) =>
  axios.putJson(`/people/notification_settings?person_id=${id}`, params)

export const getNotificationStatus = (id) =>
  axios.get(`/people/notification_settings?person_id=${id}`)

export const getHealthySignals = ({ personId, from, to }) =>
  axios.get(
    `/healthy_signals?person_id=${personId}&range_type=week&from=${from}&to=${to}`
  )

export const getHealthySignalsSettings = () =>
  axios.get('/healthy_signals/settings')

export const sendPreScreeningEmail = (personId) =>
  axios.post(`/clients/questionnaire_pre_screening_email?person_id=${personId}`)

// abandon this API: https://apeironlife.monday.com/boards/450906325/pulses/4056610453
export const sendMedicalHistoryEmail = async (personId, surveyId) =>
  await axios.post(
    `/clients/questionnaire_medical_history_email?person_id=${personId}&survey_id=${surveyId}`
  )

export const getHealthySignalsPercentageTarget = (personId, day) =>
  axios.get(
    `/healthy_signals/percentage-targets?person_id=${personId}&day=${day}`
  )

export const setHealthySignalsPercentageTarget = (params) =>
  axios.put('/healthy_signals/percentage-target', params)
