import React from 'react'

function Arrow(props) {
  return (
    <svg
      width="3"
      height="7"
      viewBox="0 0 3 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x}
      y={(props.y && props.y - 4) || 0}
    >
      <path
        d="M3 3.4587L6.67564e-08 6.00409L4.79289e-09 0.913305L3 3.4587Z"
        fill={props.style.fill}
      />
    </svg>
  )
}

export default Arrow
