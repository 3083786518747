import React, { Component } from 'react'
import { RightOutlined } from '@ant-design/icons'
// import PropTypes from 'prop-types'
import { Modal } from 'antd'
import Calendar from '../../goals/progress/calendar'
import TextList from '../../goals/progress/textList'
import ProgressBar from '../../goals/progress/bar'
import ProgressLine from '../../goals/progress/line'
import ProgressMultiBar from '../../goals/progress/multi-bar'
import ProgressMultiLine from '../../goals/progress/multi-line'
// import { EditPanIcon } from '../../icons'
import CardStyle from '../style/card.module.sass'

const confirm = Modal.confirm

class ProgramCard extends Component {
  static propTypes = {}

  state = {}

  deleteConfirm = (goal) => {
    const { deleteGoal } = this.props
    confirm({
      title: 'Do you want to delete this goal?',
      onOk() {
        deleteGoal(goal, 'archived')
      }
    })
  }

  getEntries() {
    const { goal } = this.props
    const { achieving } = goal || { achieving: [] }

    let entries = []
    for (const achiev of achieving) {
      entries = entries.concat(achiev.entries)
    }
    return entries
  }

  showActivity = (date) => {
    const { goal, setGlobalState } = this.props
    setGlobalState({
      showActivityDetail: true,
      targetDate: typeof date === 'string' ? date : null,
      editGoal: goal.micro_goal
    })
  }

  renderNumberBody(achievingData) {
    const { goal, startDate, endDate } = this.props
    const {
      micro_goal: { chart_type, auto_range, id, unit, children, fixed_target }
    } = goal || { micro_goal: {} }
    const childProps = {
      loginRole: 'Expert',
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      period: 'weekly', //: cron_day_month !== '*' ? 'monthly' : 'weekly',
      achievingData,
      auto_range,
      unit,
      children,
      fixed_target
    }
    switch (chart_type) {
      case 'bar':
        return <ProgressBar {...childProps} id={id} />
      case 'line':
        return <ProgressLine {...childProps} />
      case 'multi-bar':
        return <ProgressMultiBar {...childProps} goal={goal} />
      case 'multi-line':
        return <ProgressMultiLine {...childProps} goal={goal} />
      default:
        return 'No Graph'
    }
  }

  renderBody(achievingData) {
    const { goal, startDate, endDate } = this.props
    const {
      micro_goal: { input_type, goal_type, auto_activity }
    } = goal || { micro_goal: {} }
    const childProps = {
      startDate,
      endDate,
      period: 'weekly',
      showActivity: this.showActivity
      // notState: true
    }

    switch (input_type) {
      case 'binary':
        return (
          <Calendar
            entries={achievingData && achievingData.entries}
            {...childProps}
            auto_activity={auto_activity}
          />
        )
      case 'text':
        return <TextList entries={achievingData && achievingData.entries} />
      case 'number':
        if (goal_type === 'heartRateMetrics') {
          return (
            <Calendar
              entries={achievingData && achievingData.entries}
              {...childProps}
              // {...{
              //   // startDate: start,
              //   // endDate: end,
              //   // period: 'monthly',

              //   // showActivity
              // }}
            />
          )
        } else {
          return this.renderNumberBody(achievingData)
        }

      default:
        return input_type
    }
  }

  render() {
    const { goal, isProgramActive } = this.props
    let { micro_goal: microGoal } = goal || {}
    const entries = this.getEntries()

    const achievingData = { entries: entries || [] }
    const inActive =
      (isProgramActive &&
        !(microGoal.active || (microGoal.published_at ? true : false))) ||
      microGoal.draft_data

    return (
      <div
        className={`${CardStyle.cardContainer} ${
          inActive ? CardStyle.inActive : ''
        }`}
      >
        <div className={CardStyle.title}>
          {microGoal.micro_goal}
          {/* <EditPanIcon onClick={this.showActivity} /> */}
          <RightOutlined onClick={this.showActivity} />
        </div>
        <div className={CardStyle.body}>
          {achievingData ? this.renderBody(achievingData) : 'No Data'}
        </div>
      </div>
    )
  }
}

export default ProgramCard
