import axios, { formDataRequest, hashRequest } from './axios'
// import axios from 'axios'

export const getFiles = async (hash, type, personId, page, size, types) => {
  let url = `/files/${type}?person_id=${personId}`
  if ((page || page === 0) && size) {
    const offset = (page > 0 && page * size) || 0
    url += `&limit=${size}&offset=${offset}`
  }
  if (types) {
    url += `&types=${types}`
  }
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    result = result.data
  } else {
    result = axios.get(url)
  }
  return result
}

export const uploadFile = ({title, description, data}) =>
  axios.post('/files/ar_deck', {title, description, data})

export const deleteFile = (id, person_id) =>
  axios.delete(`/files/${id}?person_id=${person_id}`)

export const uploadToGetStaticUrl = ({
  file,
  personId,
  onBeforeUpload,
  progressCallback,
  source
}) => {
  let path = `/files?person_id=${personId}`
  const formData = new FormData()
  formData.append('file', file)
  return formDataRequest({
    method: 'post',
    path,
    data: formData,
    onBeforeUpload,
    onUploadProgress: (process) =>
      progressCallback && progressCallback(process),
    cancelSource: source
  })
}

export const upload = ({
  type,
  personId,
  test_date,
  extraInfo,
  files,
  isImport,
  source,
  onBeforeUpload,
  progressCallback
}) => {
  let path = `/files/${type}?person_id=${personId}` ///&test_date=${test_date}
  const formData = new FormData()
  if (files.length > 1) {
    for (const file of files) {
      formData.append('files', file)
      formData.append('size', file.size)
    }
  } else {
    formData.append('file', files[0])
    formData.append('size', files[0].size)
  }

  if (test_date) {
    formData.set('test_date', test_date)
  }
  if (isImport) {
    path += '&import_data=true'
  }
  if (extraInfo) {
    for (const attr in extraInfo) {
      formData.set(attr, extraInfo[attr])
    }
  }
  // const CancelToken = axios.CancelToken
  // const source = CancelToken.source()
  return formDataRequest({
    method: 'post',
    path,
    data: formData,
    onBeforeUpload,
    onUploadProgress: (process) =>
      progressCallback && progressCallback(process),
    // {
    //   const percent = (process.loaded / process.total).toFixed(2) * 100
    //   return progressCallback && progressCallback(percent)
    // },
    cancelSource: source
  })
}

export const deleteAttachment = (id, personId) =>
  axios.delete(`/attachments/${id}?person_id=${personId}`)

export const uploadAttachment = ({
  model,
  personId,
  reportId,
  file,
  source,
  progressCallback
}) => {
  const path = `/attachments/${model}/${reportId}?person_id=${personId}`
  const formData = new FormData()
  formData.append('file', file)
  const obj = {
    method: 'post',
    path,
    data: formData,
    onUploadProgress: (process) => {
      const percent = (process.loaded / process.total).toFixed(2) * 100
      return progressCallback && progressCallback(percent)
    }
  }
  if (source) {
    obj.cancelSource = source
  }

  return formDataRequest(obj)
}

export const getFileDetail = (id) => axios.get(`/files/detail/${id}`)

export const uploadPublicFile = ({
  path,
  file,
  onBeforeUpload,
  progressCallback,
  source
}) => {
  const url = `/files/public/${path}`
  const formData = new FormData()
  formData.append('file', file)
  return formDataRequest({
    method: 'post',
    path: url,
    data: formData,
    onBeforeUpload,
    onUploadProgress: (process) =>
      progressCallback && progressCallback(process),
    // {
    //   const percent = (process.loaded / process.total).toFixed(2) * 100
    //   return progressCallback && progressCallback(percent)
    // },
    cancelSource: source
  })
}
