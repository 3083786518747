import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Slider, Radio } from 'antd'
import styled from 'styled-components'

const Container = styled.div`
  .slider {
    height: 45px;
    margin-bottom: 8px;
    padding: 0 5px;
  }
  .slider-title {
    display: flex;
    justify-content: space-between;
    color: rgba(56, 67, 94, 0.7);
  }
  .ant-slider {
    .ant-slider-rail {
      height: 16px;
      background: #c5cad2;
      border-radius: 24px;
      margin-left: -6px;
      margin-right: -6px;
      width: calc(100% + 12px);
    }
    .ant-slider-step {
      margin-top: 6px;
    }
    .ant-slider-handle {
      top: 50%;
      width: 22px;
      height: 22px;
      border: 1px solid #05b800;
      padding: 5px;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 10px;
        display: block;
        background-color: #05b800;
      }
    }
    .ant-slider-track {
      height: 16px;
      margin-left: -6px;
      border-radius: 24px 0 0 24px;
      background-color: #05b800;
    }
    .ant-slider-mark {
      margin-top: 10px;
      font-size: 13px;
      .ant-slider-mark-text {
        color: #c5c9d2;
      }
    }
  }
`

export default function SliderContainer(props) {
  const { question, answer, update } = props
  const [value, setValue] = useState(null)
  const {
    slider: { max, min, max_plus, stepping: step, unit = '', single_choice }
  } = question
  const middle = Math.round(max / step / 2) * step
  const marks = {
    [min]: min + unit,
    [middle]: middle + unit,
    [max]: max + unit + (max_plus ? '+' : '')
  }
  const maxTitle = _.get(question, 'slider.max_title')
  const minTitle = _.get(question, 'slider.min_title')
  const hasTitle = maxTitle || minTitle

  if (_.isNumber(value)) {
    marks[value] = {
      label: value + unit,
      style: {
        color: '#5E687D'
      }
    }
  }

  useEffect(() => {
    if (answer && _.isNumber(answer.answer.value)) {
      setValue(answer.answer.value)
    }
  }, [answer])

  return (
    <Container>
      <div className="slider">
        {hasTitle && (
          <div className="slider-title">
            <span className="slider-left">{minTitle || ' '}</span>
            <span className="slider-right">{maxTitle || ''}</span>
          </div>
        )}
        <Slider
          {...{ max, min, step, marks }}
          value={_.isNumber(value) ? value : null}
          dots
          onChange={onChange}
          tooltip={null}
        />
      </div>
      {single_choice &&
        single_choice.map((choice, index) => (
          <div className="q-form-item" key={index}>
            <Radio
              onClick={(e) => {
                onChange(choice.title, choice.score)
              }}
              checked={value === choice.title}
            >
              {choice.title}
            </Radio>
          </div>
        ))}
      {answer && answer.verify && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </Container>
  )

  function onChange(value, score) {
    setValue(value)
    const newAnswer = _.assignIn(answer, { answer: { value, score } })
    update(newAnswer, question)
  }
}
