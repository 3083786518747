import React from 'react'
import styled from 'styled-components'
import { getClientType } from '../../utils/common'
import Lock from '../../asserts/images/lock.png'

const Container = styled.div`
  width: 100%;
  text-align: center;
  font-family: Gilroy;
  font-size: 16px;
  line-height: 150%;
  color: #8596a4;
  .description {
    font-size: 14px;
    text-align: left;
  }
  img {
    width: 64px;
    margin-bottom: 24px;
  }
`

export default function LockStatus(props) {
  const clientType = getClientType()

  let Status = styled.div``
  if (clientType === 'app') {
    Status = styled.div`
      width: calc(100%);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `
  } else {
    Status = styled.div`
      background: #ffffff;
      border: 1px solid #dee2ec;
      box-sizing: border-box;
      box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      margin-right: 50px;
      padding-top: 16px;
      position: absolute;
      width: calc(100% - 100px);
      height: calc(100% - 138px - 35px - 40px);
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    `
  }

  let mainContent = // default for partner
    clientType === 'app' ? (
      <>
        These features are available for our <br /> core members. If you are
        interested in <br /> joining our 1 year membership,
        <br /> please let us know.
      </>
    ) : (
      <>
        These features are available for our core members.
        <br /> If you are interested in joining our 1 year <br />
        membership, please let us know.
      </>
    )

  if (props.type === 'NON-CORE') {
    mainContent = (
      <>
        These features are available for our core members.
        <br /> If you are interested in joining our 1 year
        <br /> membership, please let us know.
      </>
    )
  }

  return (
    <Container>
      {props.description}
      <Status>
        <div>
          <img src={Lock} alt="lock" />
          <p>{mainContent}</p>
        </div>
      </Status>
    </Container>
  )
}
