import React, { Component } from 'react'
import {
  VictoryChart,
  VictoryScatter,
  VictoryAxis,
  // VictoryLabel,
  VictoryLine
} from 'victory'
import moment from 'moment'
import HexagonText from '../UI/HexagonText'
import { detailColors } from '../../utils/constant'
// import { saveClient } from '../../utils/storage'

const grid = (min) => ({
  stroke: (tick) => {
    return tick - min === 0 ? 'transparent' : 'rgba(128, 151, 177, 0.375)'
  },
  strokeDasharray: '8, 6',
  strokeWidth: 0.825
})
const axis = {
  stroke: 'rgba(176, 186, 201, 0.3)',
  strokeWidth: 1.65
}

const hexagon_size = {
  width: 52,
  height: 60
}

// type Range = {
//   id: number
//   bio_marker_id: number
//   min: number
//   max: number
//   color: string // has moved to (fe)male_age_scope
//   name: string
//   male_age_scope: { [k: string]: [] }
//   female_age_scope: { [k: string]: [] }
//   order: number
// }

// type SimplifiedRange = {
//   min: number
//   max: number
//   color: string
// }

// type Profile = {
//   gender: 'male' | 'female'
//   city: string
//   state: string
//   date_of_birth: Date
//   height_inch: number
// }
// type Person = {
//   profile: Profile
// }
// type History = {
//   test_date: Date
//   value: number
// }

// type Props = {
//   person: Person
//   biomarker: {
//     unit: string
//     conventional_ranges: Range[]
//     functional_ranges: Range[]
//     ranges: Range[]
//     personal_data: {
//       created_at: Date
//       value: number
//       prefer_functional: boolean
//       prefer_conventional: boolean
//       optimal_range: number[]
//     }
//   }
//   histories: History[]
// }

class MobileTrendGraph extends Component {
  // (v: number, ranges: SimplifiedRange[])
  calculateColor(v, ranges) {
    const range =
      ranges &&
      ranges.filter((range) => {
        return (!range.min || range.min <= v) && (!range.max || range.max >= v)
      })
    return range && range.length ? range[0].color : '#D2D2D2'
  }

  calculateDomain(ranges) {
    // SimplifiedRange[]
    let min = Number.MAX_VALUE
    let min_ext = false
    let max = Number.MIN_VALUE
    let max_ext = false
    ranges.forEach((range) => {
      if (range.min < min) {
        min = range.min
        min_ext = false
      } else if (range.max > max) {
        max = range.max
        max_ext = false
      }
      if (range.max < min) {
        min = range.max
        min_ext = true
      } else if (range.min > max) {
        max = range.min
        max_ext = true
      }
    })

    const span = max - min
    const each = span / ranges.length
    return [min - (min_ext ? each : 0), max + (max_ext ? each : 0)]
  }

  render() {
    const { biomarker, person, histories } = this.props

    if (!biomarker) return <div />
    let {
      unit,
      personal_data: { prefer_conventional, optimal_range }, // prefer_functional
      conventional_ranges: _conventional_ranges,
      functional_ranges: _functional_ranges
    } = biomarker
    const gender = person.profile.gender

    if (optimal_range) {
      _conventional_ranges = biomarker.ranges
    }

    const conventional_ranges =
      _conventional_ranges &&
      _conventional_ranges.map((range) => {
        const colorObj = detailColors.find((item) => item.key === range.color)
        return {
          min: range.min,
          max: range.max,
          color: colorObj && colorObj.value,
          order: range.order
        }
      })
    conventional_ranges.sort(
      (a, b) => (a.max || Number.MAX_VALUE) - (b.max || Number.MAX_VALUE)
    )
    const functional_ranges =
      _functional_ranges &&
      _functional_ranges.map((range) => {
        const colorObj = detailColors.find((item) => item.key === range.color)
        return {
          min: range[`${gender}_min`],
          max: range[`${gender}_max`],
          color: colorObj && colorObj.value
        }
      })
    const conventional_domain =
      conventional_ranges && this.calculateDomain(conventional_ranges)
    const functional_domain =
      functional_ranges && this.calculateDomain(functional_ranges)

    const dataSet = []
    const dateValues = [null]
    const tickValues = [0, 1, 2, 3, 4]

    const height = 400
    const padding = {
      left: 45,
      right: 45,
      top: 50,
      bottom: 35
    }
    const ext_padding = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }

    let merged_domain = []
    {
      let min_value = Number.MAX_VALUE
      if (histories.length > 0) {
        if (histories.length >= 4) {
          ext_padding.right = hexagon_size.width / 2
        }
        histories.map((item, index) => {
          if (index < 4) {
            dataSet.push({
              x: dateValues.length,
              y: item.value
            })
          }
          if (item.value < min_value) {
            min_value = item.value
          }
          dateValues.push(item.test_date)
          return item
        })
      }

      const values = dataSet.map((item) => item.y)
      if (optimal_range) {
        let minArr = conventional_ranges.map((item) => item.min),
          maxArr = conventional_ranges.map((item) => item.max)
        merged_domain = []
        merged_domain.push(minArr.length ? Math.min(...minArr) : 0)
        maxArr = maxArr.concat(values)
        merged_domain.push(Math.max(...maxArr))
        // if (merged_domain.length === 1) {
        //   const values = dataSet.map(item => item.y)
        //   merged_domain.push(Math.max(...values))
        // }
      } else {
        merged_domain = [
          Math.min(
            conventional_domain[0] < 0 ? 0 : conventional_domain[0],
            functional_domain[0] < 0 ? 0 : functional_domain[0]
          ),
          Math.max(conventional_domain[1], functional_domain[1], ...values)
        ]
      }

      if (
        min_value <
        (((merged_domain[1] - merged_domain[0]) / height) *
          hexagon_size.height) /
          2
      ) {
        ext_padding.bottom = hexagon_size.height / 2
      }
    }

    // const styles = this.getStyles()

    return (
      <div
        style={{
          padding: 20
          // transform: `scale(${document.documentElement.clientWidth / 450})`
        }}
      >
        <div className="trend-title">Trend graph</div>
        <div className="chat-axis-title">
          <div className="left">
            {prefer_conventional && (
              <>
                CONVENTIONAL <br /> ({unit.toUpperCase()})
              </>
            )}
          </div>
          {/* comment on sprint23 */}
          {/* <div className="right">
            {prefer_functional && (
              <>
                FUNCTIONAL <br /> ({unit.toUpperCase()})
              </>
            )}
          </div> */}
        </div>

        <VictoryChart
          domain={{ y: merged_domain }}
          domainPadding={{
            x: [ext_padding.left, ext_padding.right],
            y: [ext_padding.bottom, ext_padding.top]
          }}
          singleQuadrantDomainPadding={false}
          height={height + padding.bottom}
          padding={padding}
          // containerComponent={<VictoryZoomContainer zoomDomain={{y: merged_domain}}/>}
        >
          {/* {prefer_conventional && (
            <VictoryLabel
              x={0}
              y={0}
              lineHeight={29 / 12}
              style={styles.titleConventional}
              text={`CONVENTIONAL (${unit.toUpperCase()})`}
            />
          )} */}
          {/* {prefer_functional && (
            <VictoryLabel
              x={534 - 38 * 2 - 10}
              y={0}
              lineHeight={29 / 12}
              style={styles.titleFunctional}
              text={`FUNCTIONAL (${unit.toUpperCase()})`}
            />
          )} */}
          <VictoryAxis
            offsetY={padding.bottom}
            tickValues={tickValues}
            style={{
              grid: grid(tickValues[0]),
              axis,
              ticks: {
                strokeWidth: 0
              },
              tickLabels: {
                fontFamily: 'Heebo',
                fontWeight: 'bold',
                fontSize: '12px',
                fill: '#4C6072',
                lineHeight: '19px',
                opacity: 0.8
              }
            }}
            tickFormat={(x) => {
              return (
                dateValues[x] &&
                moment(dateValues[x])
                  .format('MMM D')
                  .toUpperCase()
              )
            }}
          />
          <VictoryAxis
            dependentAxis
            offsetX={padding.left}
            orientation="left"
            // tickCount={8}
            style={{
              grid: grid(merged_domain[0]),
              axis,
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: (v) => this.calculateColor(v, conventional_ranges),
                fontFamily: 'Heebo',
                fontWeight: 'bold',
                lineHeight: '21px',
                fontSize: 14,
                padding: 5
              }
            }}
          />
          {/* comment on sprint23 */}
          {/* <VictoryAxis
            dependentAxis
            offsetX={padding.right}
            // tickCount={8}
            orientation="right"
            style={{
              axis: { strokeWidth: 0 },
              ticks: { strokeWidth: 0 },
              tickLabels: {
                fill: (v: number) => this.calculateColor(v, functional_ranges),
                fontFamily: 'Heebo',
                fontWeight: 'bold',
                lineHeight: '21px',
                fontSize: 14,
                padding: 5
              }
            }}
          /> */}
          {dataSet.length > 1 && (
            <VictoryLine
              style={{
                data: { stroke: 'rgba(83,180,59, 0.2)' },
                parent: { border: '1px solid #ccc' }
              }}
              data={dataSet}
            />
          )}
          <VictoryScatter
            data={dataSet}
            dataComponent={
              <HexagonText
                color={(value) =>
                  this.calculateColor(value, conventional_ranges)
                }
                size={60}
              />
            }
          />
        </VictoryChart>
      </div>
    )
  }

  getStyles() {
    // : { [key: string]: React.CSSProperties }
    return {
      titleConventional: {
        textAnchor: 'start',
        alignmentBaseline: 'hanging',
        // verticalAnchor: 'start',
        fill: '#4C6072',
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 'bold'
      },
      titleFunctional: {
        textAnchor: 'end',
        alignmentBaseline: 'hanging',
        fill: '#4C6072',
        fontFamily: 'Lato',
        fontSize: '12px',
        fontWeight: 'bold'
      }
    }
  }
}

export default MobileTrendGraph
