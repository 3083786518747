import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import {
  Divider,
  Row,
  Col,
  Modal,
  Button,
  Typography,
  Empty,
  Popover
} from 'antd'
import { EditPanIcon, CollapsePlusIcon } from '../icons'
import BasicForm from '../formV4' //'../BasicForm'
import { pattern } from '../../utils/constant'
import Confirm from '../UI/confirmModal'
import {
  getAllExperts,
  getSupportTeam,
  getSupportRoles,
  addSupportTeam,
  editSupportTeam,
  deleteSupportTeam,
  editClient,
  getMutedExperts,
  updateMuteState
} from '../../api/team'
import PageLoading from '../UI/status/loading'
import { getRole } from '../../utils/common'
import unmutedIcon from '../../asserts/icon/unmuted.svg'
import unmutedHoverIcon from '../../asserts/icon/unmuted-hover.svg'
import mutedIcon from '../../asserts/icon/muted.svg'
import mutedHoverIcon from '../../asserts/icon/muted-hover.svg'

const Container = styled.div`
  section {
    margin-top: 32px;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dee2ec;
    box-sizing: border-box;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    &:first-child {
      margin-top: 40px;
    }
    .title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0.3px;
      color: #383e48;
      display: flex;
      justify-content: space-between;
      .opr {
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
`

export default function SupportTeamContainer(props) {
  const [teamEditor, setTeamEditor] = useState(false)
  const [supportEditor, setSupportEditor] = useState(false)
  const [teamMember, setTeamMember] = useState(null)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [mutedExpertIds, setMutedExpertsIds] = useState([])
  const loginRole = getRole()

  useEffect(() => {
    const initial = async () => {
      const { clients } = await getAllExperts()
      const roles = await getSupportRoles()
      const mutedResults = await getMutedExperts(props.person.id)
      const { support_team_members, al_team_member } = await getSupportTeam(
        props.person.id
      )
      const activeTeamMember = support_team_members.filter(
        (item) => item.status === 1
      )
      const inActiveTeamMember = support_team_members.filter(
        (item) => item.status === 0
      )
      setData({
        experts: clients,
        roles,
        activeTeamMember,
        inActiveTeamMember,
        al_team_member
      })
      setMutedExpertsIds(mutedResults.muted_experts || [])
      setLoading(false)
    }
    initial()
  }, [])

  const editTeamSync = async () => {
    setLoading(true)
    const { support_team_members, al_team_member } = await getSupportTeam(
      props.person.id
    )
    const activeTeamMember = support_team_members.filter(
      (item) => item.status === 1
    )
    const inActiveTeamMember = support_team_members.filter(
      (item) => item.status === 0
    )
    data.al_team_member = al_team_member
    data.activeTeamMember = activeTeamMember
    data.inActiveTeamMember = inActiveTeamMember
    setData(data)
    setLoading(false)
  }

  const toggleMuteState = async (expertId) => {
    setLoading(true)
    const currentMuted = mutedExpertIds.includes(expertId)
    const shouldMute = currentMuted ? false : true
    await updateMuteState({
      expert_id: expertId,
      client_id: props.person.id,
      muted: shouldMute
    })
    let newValues = mutedExpertIds
    if (shouldMute) {
      newValues.push(expertId)
    } else {
      newValues = mutedExpertIds.filter((item) => item !== expertId)
    }
    setMutedExpertsIds(newValues)
    setLoading(false)
  }

  if (loading) {
    return <PageLoading />
  }

  return (
    <Container>
      <Divider />
      <section>
        <div className="title">
          <span>Apeiron Life Team</span>
          <span className="opr">
            {loginRole === 'Expert' && (
              <EditPanIcon onClick={() => setTeamEditor(true)} />
            )}
            {loginRole === 'User' && (
              <Button type="primary" ghost onClick={props.chart}>
                Chat with your team
              </Button>
            )}
          </span>
        </div>
        <Row type="flex">
          <Col span={6}>
            <Card
              isActive
              role_name="Client Advocate"
              roles={[]}
              name={
                data.al_team_member.client_advocate &&
                data.al_team_member.client_advocate.full_name
              }
              avatar={
                data.al_team_member.client_advocate &&
                data.al_team_member.client_advocate.avatar
              }
              showMuteState={
                data.al_team_member.client_advocate &&
                data.al_team_member.client_advocate.expert_id !== -1
              }
              isMuted={
                data.al_team_member.client_advocate
                  ? mutedExpertIds.includes(
                      data.al_team_member.client_advocate.expert_id
                    )
                  : false
              }
              toggleMuteState={() => {
                // console.log('---------: ', data.al_team_member.client_advocate)
                if (data.al_team_member.client_advocate) {
                  toggleMuteState(data.al_team_member.client_advocate.expert_id)
                }
              }}
            />
          </Col>
          <Col span={6}>
            <Card
              isActive
              roles={[]}
              role_name="Concierge"
              name={
                data.al_team_member.concierge &&
                data.al_team_member.concierge.full_name
              }
              avatar={
                data.al_team_member.concierge &&
                data.al_team_member.concierge.avatar
              }
              showMuteState={
                data.al_team_member.concierge &&
                data.al_team_member.concierge.expert_id !== -1
              }
              isMuted={
                data.al_team_member.concierge
                  ? mutedExpertIds.includes(
                      data.al_team_member.concierge.expert_id
                    )
                  : false
              }
              toggleMuteState={() => {
                if (data.al_team_member.concierge) {
                  toggleMuteState(data.al_team_member.concierge.expert_id)
                }
              }}
            />
          </Col>
          <Col span={6}>
            <Card
              isActive
              roles={[]}
              role_name="Movement & Exercise Lead"
              name={
                data.al_team_member.performance_team_lead &&
                data.al_team_member.performance_team_lead.full_name
              }
              avatar={
                data.al_team_member.performance_team_lead &&
                data.al_team_member.performance_team_lead.avatar
              }
              showMuteState={
                data.al_team_member.performance_team_lead &&
                data.al_team_member.performance_team_lead.expert_id !== -1
              }
              isMuted={
                data.al_team_member.performance_team_lead
                  ? mutedExpertIds.includes(
                      data.al_team_member.performance_team_lead.expert_id
                    )
                  : false
              }
              toggleMuteState={() => {
                if (data.al_team_member.performance_team_lead) {
                  toggleMuteState(
                    data.al_team_member.performance_team_lead.expert_id
                  )
                }
              }}
            />
          </Col>
          <Col span={6}>
            {/* <Card
              isActive
              roles={[]}
              role_name="Trainer"
              name={
                data.al_team_member.trainer &&
                data.al_team_member.trainer.full_name
              }
              avatar={
                data.al_team_member.trainer &&
                data.al_team_member.trainer.avatar
              }
            /> */}
          </Col>
        </Row>
      </section>
      <section>
        <div className="title">
          <span>Support Team</span>
          <span className="opr">
            {loginRole === 'Expert' && (
              <CollapsePlusIcon
                onClick={() => {
                  setTeamMember(null)
                  setSupportEditor(true)
                }}
              />
            )}
          </span>
        </div>
        {data.activeTeamMember.length === 0 ? (
          <Empty style={{ marginTop: 30 }} />
        ) : (
          <Row type="flex">
            {data.activeTeamMember.map((item, index) => (
              <Col span={6} key={index}>
                <Card
                  {...item}
                  isActive
                  roles={data.roles}
                  handleClick={() => {
                    if (loginRole === 'Expert') {
                      setTeamMember(item)
                      setSupportEditor(true)
                    }
                  }}
                />
              </Col>
            ))}
          </Row>
        )}
      </section>
      <section>
        <div className="title">Inactive Support Team</div>
        {data.inActiveTeamMember.length > 0 ? (
          <Row type="flex">
            {data.inActiveTeamMember.map((item, index) => (
              <Col span={6} key={index}>
                <Card
                  {...item}
                  roles={data.roles}
                  handleClick={() => {
                    if (loginRole === 'Expert') {
                      setTeamMember(item)
                      setSupportEditor(true)
                    }
                  }}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Empty style={{ marginTop: 30 }} />
        )}
      </section>
      {teamEditor && (
        <EditALTeam
          handleCancel={() => setTeamEditor(false)}
          experts={data.experts}
          team={data.al_team_member}
          editSync={editTeamSync}
          personId={props.person.id}
        />
      )}
      {supportEditor && (
        <EditSupportTeam
          roles={data.roles}
          personId={props.person.id}
          editSync={editTeamSync}
          handleCancel={() => setSupportEditor(false)}
          teamMember={teamMember}
        />
      )}
    </Container>
  )
}

const CardContainer = styled.div`
  display: flex;
  .avatar {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 100%;
    background: #e5e5e5;
    margin-right: 16px;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    color: #909399;
    text-transform: uppercase;
    &.active {
      background: #264382;
      color: #ffffff;
    }
    img {
      max-width: 100%;
    }
  }
  .muted-icon {
    position: absolute;
    top: 32px;
    right: 16px;
    &:hover {
      content: url(${mutedHoverIcon});
    }
  }
  .unmuted-icon {
    position: absolute;
    top: 32px;
    right: 16px;
    &:hover {
      content: url(${unmutedHoverIcon});
    }
  }
  .member-title {
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    color: #383e48;
  }
  .description {
    color: #8c9096;
    font-size: 13px;
    margin-bottom: 13px;
  }
`

const PopoverContainer = styled.div`
  border-radius: 4px;
  border: 1px solid;
  border-color: #d4dbe2;
  max-width: 186px;
  height: auto;
  background: #e8ecf0;
  padding-bottom: 4px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  word-wrap: break-word;
  color: #52606c;
  font-size: 12px;
  line-height: 14.52px;
`
const muteTip = (isMuted) => {
  if (isMuted) {
    return <PopoverContainer>Unmute notification</PopoverContainer>
  } else {
    return (
      <PopoverContainer>
        Mute notification: When you mute, you won't get push email
        notifications.
      </PopoverContainer>
    )
  }
}

export function Card(props) {
  const {
    isActive,
    role,
    name,
    email,
    fax_number,
    website,
    phone_number,
    avatar,
    roles,
    role_name,
    showMuteState,
    isMuted,
    handleClick,
    toggleMuteState
  } = props
  const names =
    (name || role_name)
      .split(' ')
      .filter((item) => item !== '&')
      .map((item) => item.substring(0, 1)) || []

  // const isClientView = getRole(loadUser().role) === 'User'
  return (
    <CardContainer onClick={handleClick || null}>
      <div style={{ position: 'relative' }}>
        <div className={`avatar ${isActive ? 'active' : ''}`}>
          {avatar ? (
            <img src={avatar.oss_url} alt={'avatar'} />
          ) : (
            names.join('')
          )}
        </div>
        {showMuteState && (
          <Popover content={muteTip(isMuted)} trigger="hover">
            <img
              src={isMuted ? mutedIcon : unmutedIcon}
              className={isMuted ? 'muted-icon' : 'unmuted-icon'}
              onClick={toggleMuteState}
              alt="mute-icon"
            />
          </Popover>
        )}
      </div>
      <div style={{ width: '72%' }}>
        <div className="member-title">
          {role_name || (roles && roles[role])}
        </div>
        <div className="description">
          {name || '--'} <br />
          {
            <>
              {email && (
                <>
                  E: {email} <br />
                </>
              )}
              {phone_number && <>T: {phone_number}</>}
              {fax_number && (
                <>
                  <br />
                  F: {fax_number}
                </>
              )}
              {website && (
                <>
                  <Typography.Paragraph
                    ellipsis
                    style={{
                      color: '#8c9096',
                      fontSize: 13
                    }}
                  >
                    W: {website}
                  </Typography.Paragraph>
                </>
              )}
            </>
          }
        </div>
      </div>
    </CardContainer>
  )
}

const ModalContainer = styled.div`
  h1 {
    font-size: 24px;
    margin-bottom: 50px;
    color: #4c6072;
    font-family: Gilroy;
  }
`

function EditALTeam(props) {
  let [loading, setLoading] = useState(false)
  const [refForm, setRefForm] = useState(null)
  const { experts, team, editSync, handleCancel, personId } = props
  const options = experts.map(({ full_name, id }) => ({
    label: full_name,
    value: id
  }))

  options.push({
    label: 'None',
    value: -1
  })

  const handleOk = async () => {
    setLoading(true)
    try {
      const al_team = await refForm.handleSubmit()
      delete al_team.trainer
      await editClient({ profile: { al_team } }, personId)
      editSync()
      handleCancel()
    } catch (err) {
      setLoading(false)
    }
  }
  let formValue = {}

  Object.keys(team).map((key) => {
    formValue[key] = team[key] && team[key].expert_id
    return key
  })

  return (
    <Modal
      visible
      onCancel={handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div />
          <div>
            <Button type="none" onClick={handleCancel} disabled={loading}>
              CANCEL
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              loading={loading}
              disabled={loading}
            >
              UPDATE
            </Button>
          </div>
        </div>
      }
    >
      <ModalContainer>
        <h1>Apeiron Life Team</h1>
        <BasicForm
          wrappedComponentRef={(inst) => setRefForm(inst)}
          initialValues={formValue}
          formData={[
            {
              type: 'select',
              dbField: 'client_advocate',
              label: 'client advocate',
              options,
              rules: [{ required: true, message: 'This field is required' }]
            },
            {
              type: 'select',
              dbField: 'concierge',
              label: 'concierge',
              options,
              rules: [{ required: true, message: 'This field is required' }]
            },
            {
              type: 'select',
              dbField: 'performance_team_lead',
              label: 'Movement & Exercise Lead',
              options,
              rules: [{ required: true, message: 'This field is required' }]
            }
            // {
            //   type: 'select',
            //   dbField: 'trainer',
            //   label: 'trainer',
            //   options,
            //   rules: [{ required: true, message: 'This field is required' }]
            // }
          ]}
        />
      </ModalContainer>
    </Modal>
  )
}

function EditSupportTeam(props) {
  let [showConfirm, setShowConfirm] = useState(false)
  let [loading, setLoading] = useState(false)
  const [refForm, setRefForm] = useState(null)
  const { roles, teamMember } = props
  if (teamMember) {
    teamMember.status = teamMember.status + ''
    teamMember.role = teamMember.role + ''
  }
  let [formValue, setFormValue] = useState(
    (teamMember && _.cloneDeep(teamMember)) || {}
  )
  const options = Object.keys(roles).map((key) => ({
    label: roles[key],
    value: key + ''
  }))

  const handleOk = async () => {
    try {
      setLoading(true)
      const params = await refForm.handleSubmit()
      params.person_id = props.personId
      params.status = params.status || 1
      if (teamMember) {
        await editSupportTeam(params, teamMember.id)
      } else {
        await addSupportTeam(params)
      }
      props.editSync()
      props.handleCancel()
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  const handleConfirm = async () => {
    try {
      setLoading(true)
      await deleteSupportTeam(teamMember.id)
      props.editSync()
      setLoading(false)
      props.handleCancel()
    } catch (err) {
      setLoading(false)
      console.error(err)
    }
  }

  const updateFormsData = (changedValues, allValues) => {
    setFormValue(allValues)
  }

  const formData = [
    {
      type: 'select',
      dbField: 'role',
      label: 'Role',
      options: options,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      dbField: 'role_name',
      label: 'Role Name',
      hideField: true,
      unless: { key: 'role', value: '9999' },
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      dbField: 'name',
      label: 'name',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      dbField: 'email',
      label: 'email',
      rules: [
        {
          pattern: pattern.email,
          message: 'Please input a valid email'
        }
      ]
    },
    {
      type: 'input',
      dbField: 'phone_number',
      label: 'cellphone',
      // colSpan: 12,
      rules: [
        {
          pattern: pattern.phoneOrFaxNumber,
          message: 'Please input a valid phone number'
        }
      ]
    },
    {
      type: 'input',
      dbField: 'fax_number',
      label: 'Fax',
      // colSpan: 12,
      // hideField: true,
      // unless: { key: 'role', value: '2' },
      rules: [
        {
          pattern: pattern.phoneOrFaxNumber,
          message: 'Please input a valid fax number'
        }
      ]
    },
    {
      type: 'input',
      dbField: 'website',
      label: 'website'
    },
    {
      type: 'radios',
      label: 'status',
      dbField: 'status',
      options: [
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' }
      ],
      hideField: true,
      unless: { key: 'id' },
      rules: [{ required: true, message: 'This field is required' }]
    }
  ]

  return (
    <Modal
      visible
      onCancel={props.handleCancel}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {teamMember && (
              <Button type="none" onClick={() => setShowConfirm(true)}>
                <Typography.Text type="danger" disabled={loading}>
                  DELETE
                </Typography.Text>
              </Button>
            )}
          </div>
          <div>
            <Button type="none" onClick={props.handleCancel} disabled={loading}>
              CANCEL
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              disabled={loading}
              loading={loading}
            >
              {teamMember ? 'SAVE' : 'ADD'}
            </Button>
          </div>
        </div>
      }
    >
      <ModalContainer>
        <h1>{teamMember ? 'Edit' : 'Add'} Support Team</h1>
        <BasicForm
          wrappedComponentRef={(inst) => setRefForm(inst)}
          initialValues={formValue}
          formData={formData}
          onChange={updateFormsData}
        />

        {showConfirm && (
          <Confirm
            {...{
              modalWidth: 600,
              onCancel: () => setShowConfirm(false),
              description: 'Do you want to delete this team member?',
              // title: 'Publish Comprehensive Report confirmation',
              confirmText: 'Confirm',
              onConfirm: handleConfirm
            }}
          />
        )}
      </ModalContainer>
    </Modal>
  )
}
