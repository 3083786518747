import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { loadUser } from '../../utils/storage'
import PreviewReport from '../../components/assessmentReview/preview'
import List from '../../components/assessmentReview'
import NoMatch from '../noMatch'

export default function AssessmentReview(props) {
  const { match } = props
  return (
    <Switch>
      <Route
        exact
        path={`${match.url}`}
        render={(props) => <List {...props} getPerson={loadUser} />}
      />
      <Route
        path={`${match.url}/preview/:id`}
        render={(props) => <PreviewReport {...props} getPerson={loadUser} />}
      />
      <Route component={NoMatch} />
    </Switch>
  )
}
