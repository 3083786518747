import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CaretRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import MarkdownIt from 'markdown-it'
import BasicForm from '../../BasicForm'
import { PlusIcon } from '../../icons/weeklySummary'
import BasicMarkdownFormat from './basicMarkdownFormat'
import ConfirmPopUp from './confirm'
import TemplatesCascader from './templatesCascader'
import {
  AvatarIcon,
  EditCircleIcon,
  GreenCheck
} from '../../icons/weeklySummary'

import { Style } from './styles/extraInfo.sass'
import DelIcon from '../../../asserts/icon/weekly-summary/delete.svg'

class Information extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
    doneStatus: PropTypes.string,
    updateCommend: PropTypes.func,
    updateEditingCount: PropTypes.func,
    direction: PropTypes.string,
    disMarkDone: PropTypes.bool,
    updateEmailAction: PropTypes.func
  }

  state = {
    isEdit: false,
    initialValue: '',
    showBasicMarkdownFormat: false,
    showDirection: false
  }

  cancel = (status) => {
    const { updateEmailAction, updateEditingCount } = this.props
    this.refForm && this.refForm.reset()
    if (updateEditingCount) {
      updateEditingCount(-1)
    }

    if (updateEmailAction) {
      updateEmailAction(1)
    }

    this.setState({
      isEdit: false,
      showConfirm: false,
      initialValue: this.props.initialValue
    })
    if (status === 'done') {
      this.props.markAsDone()
    }
  }

  submit = async () => {
    const params = await this.refForm.handleSubmit()
    this.setContentHtml(params.content, 'save')
    this.cancel()
  }

  markDone = async () => {
    const params = await this.refForm.handleSubmit()
    this.setContentHtml(params.content, 'done')
    this.cancel()
  }

  handleShowBasicMarkdownFormatModal = (e) => {
    this.setState({
      showBasicMarkdownFormat: true
    })
  }

  handleBasicMarkdownFormatModalCancel = (e) => {
    this.setState({
      showBasicMarkdownFormat: false
    })
  }

  setContentHtml = (value, status) => {
    if (!value && status !== 'receive') return
    const { updateCommend } = this.props
    const md = new MarkdownIt('zero').enable([
      'heading',
      'list',
      'link',
      'autolink',
      'emphasis',
      'blockquote'
    ])
    const titleStyle =
      'font-weight: bold;color: #383E48;margin-top:0;margin-bottom:0.5em;'

    let result = md.render(value || '')

    result = result
      .replace('<h1>', `<h1 style="${titleStyle}">`)
      .replace('<h2>', `<h2 style="${titleStyle}">`)
      .replace('<h3>', `<h3 style="${titleStyle}">`)
      .replace('<h4>', `<h4 style="${titleStyle}">`)
      .replace('<h5>', `<h5 style="${titleStyle}">`)

    this.setState({ contentHtml: result, initialValue: value, isEdit: false })
    if (status === 'receive') {
      this.refForm && this.refForm.setFieldsValue({ content: value })
    } else if (status !== 'init') {
      updateCommend(value, status)
    }
  }

  clear = () => {
    this.setState({ contentHtml: '', initialValue: '' })
    this.props.updateCommend('', 'delete')
  }

  selectTemplate = (text) => {
    const { initialValue } = this.state
    let newContent
    if (initialValue) {
      newContent = `${initialValue} ${text}`
    } else {
      newContent = text
    }
    this.setState({ initialValue: newContent })
    this.refForm && this.refForm.setFieldsValue({ content: newContent })
  }

  componentDidMount() {
    const { initialValue } = this.props
    if (initialValue) {
      this.setContentHtml(initialValue, 'init')
    }
  }

  componentDidUpdate(prevProps) {
    const { initialValue, status } = this.props
    if (initialValue !== prevProps.initialValue) {
      this.setContentHtml(initialValue, 'receive')
    }
    if (status === 'Locked' && this.state.isEdit) {
      this.setState({ isEdit: false })
    }
  }

  UNSAFE_componentWillReceiveProps(nextPorps) {
    const { initialValue } = this.props
    if (initialValue !== nextPorps.initialValue) {
      this.setContentHtml(nextPorps.initialValue, 'receive')
    }
  }

  renderEditor() {
    const { title, templates, direction, disMarkDone } = this.props
    const {
      initialValue,
      showBasicMarkdownFormat,
      showConfirm,
      showDirection
    } = this.state
    return (
      <div className={Style}>
        <div className="information-editor">
          <BasicForm
            wrappedComponentRef={(inst) => (this.refForm = inst)}
            valueForm={{ content: initialValue }}
            onChange={(changedFields) => {
              if (
                changedFields.content &&
                changedFields.content.value !== undefined
              ) {
                this.setState({ initialValue: changedFields.content.value })
              }
            }}
            formData={[
              {
                type: 'textarea',
                label: title,
                dbField: 'content',
                rules: [{ required: true, message: 'This field is required' }],
                extraAttr: {
                  style: { height: 190 }
                }
              }
            ]}
          />
          <div className="mark-down-support">
            <div className="header">
              {direction && (
                <div
                  className="direction-label"
                  onClick={() =>
                    this.setState({ showDirection: !showDirection })
                  }
                >
                  Direction{' '}
                  <CaretRightOutlined rotate={showDirection ? 90 : 0} />
                </div>
              )}
              <div>
                Basic{' '}
                <span onClick={this.handleShowBasicMarkdownFormatModal}>
                  Markdown
                </span>{' '}
                format is supported.
              </div>
            </div>
            {direction && showDirection && (
              <p
                className="direction-content"
                dangerouslySetInnerHTML={{ __html: direction }}
              />
            )}
          </div>
          <div className={`editor-footer ${templates ? 'templates' : ''}`}>
            {templates && (
              <TemplatesCascader
                templates={templates}
                selectTemplate={this.selectTemplate}
              />
            )}
            <div className="buttons">
              <Button
                type="primary"
                ghost
                onClick={() => this.setState({ showConfirm: true })}
              >
                CANCEL
              </Button>
              {!disMarkDone && (
                <Button type="primary" ghost onClick={this.markDone}>
                  MARK AS DONE
                </Button>
              )}
              <Button
                type="primary"
                ghost
                onClick={this.submit}
                // disabled={loading || overviewLoading}
              >
                SAVE
              </Button>
            </div>
          </div>
          {showBasicMarkdownFormat && (
            <BasicMarkdownFormat
              cancel={this.handleBasicMarkdownFormatModalCancel}
            />
          )}
          <ConfirmPopUp
            title="Cancel editing confirmation"
            content="Changes you made to this section will not be saved. Are you sure you want to continue?"
            okText="DISCARD"
            visible={showConfirm}
            handleCancel={() => this.setState({ showConfirm: false })}
            handleOk={this.cancel}
          />
        </div>
      </div>
    )
  }

  render() {
    const { title, status, doneStatus, showDone, extraStyles } = this.props
    const { isEdit, contentHtml, initialValue } = this.state
    if (isEdit) return this.renderEditor()
    return (
      <div className={Style}>
        {initialValue || contentHtml ? (
          <>
            <div
              className={`information-container ${status}`}
              style={extraStyles || {}}
            >
              <div className="opr">
                <EditCircleIcon
                  className="opr-button"
                  onClick={() => {
                    this.props.updateEditingCount &&
                      this.props.updateEditingCount(1)
                    this.props.updateEmailAction &&
                      this.props.updateEmailAction(0)
                    this.setState({ isEdit: true })
                  }}
                />
                <img
                  className="opr-button"
                  onClick={() => this.clear()}
                  src={DelIcon}
                  alt=""
                />
              </div>
              <div className="content">
                <div className="avatar">
                  {doneStatus && showDone ? <GreenCheck /> : <AvatarIcon />}
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: contentHtml
                  }}
                />
              </div>
            </div>
            {showDone && <div className="done-status">{doneStatus}</div>}
          </>
        ) : status === 'Sent' || status === 'Opened' || status === 'Locked' ? (
          <></>
        ) : (
          <div className="information-container">
            <div
              className="null-state"
              onClick={() => {
                this.props.updateEditingCount &&
                  this.props.updateEditingCount(1)

                this.props.updateEmailAction && this.props.updateEmailAction(0)

                this.setState({ isEdit: true }, () => {
                  console.log('isEdit', this.state.isEdit)
                })
              }}
            >
              <PlusIcon />
              {/* <Icon type="plus" /> */}
              ADD {title}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Information
