import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryLabel,
  VictoryLine,
  VictoryArea,
  VictoryScatter
} from 'victory'

import {
  OFFSET_SORCE_COMPARE_Y,
  AXIS_DATAS,
  OFFSET_NAME_COMPARE_Y,
  LABEL_TEXT_ANCHOR,
  MOBILE_OFFSET_SORCE_COMPARE_Y,
  MOBILE_OFFSET_NAME_COMPARE_Y
} from '../../healthScore/constant'

const positions = {
  Balance: {
    top: '15.7%',
    left: '47%'
  },
  'Cognitive Health': {
    top: '32%',
    left: '17.785%'
  },
  'Aerobic fitness': {
    top: '62.78%',
    left: '15.28%'
  },
  'Bone Density': {
    top: '89.379%',
    left: '29.87%'
  },
  'Body Composition': {
    top: '91.5%',
    left: '60.5%'
  },
  'Muscle Fitness': {
    top: '66%',
    left: '74%'
  },
  'Movement Quality': {
    top: '34%',
    left: '69%'
  }
}

const GraphContainer = styled.div`
  position: relative;
  .data-detail {
    position: absolute;
    z-index: 9;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width: 269px;
    padding: 24px;
    .title {
      font-size: 15px;
      letter-spacing: 0.3px;
      text-transform: capitalize;
      color: #4c6072;
      padding-bottom: 15px;
    }
    .body > div {
      display: flex;
      justify-content: space-between;
      color: #4c6072;
      font-size: 14px;
      &:first-child {
        padding-top: 24px;
        padding-bottom: 10px;
        border-top: 1px solid #e3e6ea;
      }
      .circle {
        display: inline-block;
        margin-right: 10px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        border: 2px solid;
        &.latest {
          background: rgba(127, 184, 0, 0.2);
          border-color: #7fb800;
        }
        &.previous {
          background: rgba(13, 35, 80, 0.2);
          border-color: #0d2350;
        }
        &.carried {
          background: #fff;
          border-color: #0d2350;
        }
      }
    }
    .latest {
      color: #7fb800;
    }
    .previous {
      color: #0d2350;
    }
    .carried {
      color: #0d2350;
    }
  }
`

AXIS_DATAS.pop()

export default function Graph(props) {
  const [showPopUp, setShowPopUp] = useState(false)
  const [popIndex, setPopIndex] = useState(0)
  let { compareData, dataList, mobile } = props
  const numberFontSize = mobile ? 21 : 14
  let first = dataList[0]
  dataList = [first].concat(dataList.slice(1, 7).reverse())
  const score = dataList.map((item, index) => {
    const { score_manual, score_auto, isPrefill, latest_test_date } = item || {}
    const _score = (score_manual && score_manual.value) || score_auto
    // let isEstimate = false
    // if (index === 1 && !item.y) {
    //   isEstimate = true
    // }
    return {
      x: index,
      y: _.isNumber(_score) ? _score : 75, //isEstimate ? 75 : Number(_score) || 0,
      axisLabel: _score || '--',
      isPrefill: !score_manual && isPrefill, // api return spell error Prefill
      date: latest_test_date,
      isEstimate: _.has(item, 'score_auto') && !_.isNumber(_score)
    }
  })
  const name = dataList.map((item) => item.name)
  let compareScatterEstimate
  const hasCompareScore = compareData.find((item) => {
    const { score_manual, score_auto } = item || {}
    const _score = (score_manual && score_manual.value) || score_auto
    return _score
  })
  if (hasCompareScore) {
    first = compareData[0]
    compareData = [first]
      .concat(compareData.slice(1, 7).reverse())
      .map((item, index) => {
        const { score_manual, score_auto, latest_test_date } = item || {}
        const _score = (score_manual && score_manual.value) || score_auto
        return {
          x: index,
          y: Number(_score) || 0, // _.isNumber(_score) ? _score : 75,
          axisLabel: _score || '--',
          date: latest_test_date
          // isEstimate: _.has(item, 'score_auto') && !_.isNumber(_score)
        }
      })

    compareScatterEstimate = _.cloneDeep(compareData).map(
      ({ x, y, isEstimate }) => ({
        x,
        y: isEstimate ? y : null
      })
    )
  }

  const scatterPreFillData = _.cloneDeep(score).map(({ x, y, isPrefill }) => ({
    x,
    y: isPrefill ? y : null
  }))

  const scatterEstimateData = _.cloneDeep(score).map(
    ({ x, y, isEstimate }) => ({
      x,
      y: isEstimate ? y : null
    })
  )

  const axisEvent = [
    {
      target: 'tickLabels',
      eventHandlers: {
        onMouseEnter: (e, { index }) => {
          setShowPopUp(true)
          setPopIndex(index)
        },
        onMouseLeave: (e, { index }) => {}
      }
    }
  ]
  return (
    <GraphContainer
      style={{ width: props.fromAR ? 'calc(100% - 400px)' : '100%' }}
    >
      <VictoryChart
        polar
        domain={{ y: [0, 100] }}
        startAngle={90}
        endAngle={450}
      >
        <VictoryPolarAxis
          name="graph-score"
          labelPlacement="vertical"
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
          tickFormat={(t) =>
            hasCompareScore
              ? [score[t].axisLabel, ' / ', compareData[t].axisLabel]
              : [score[t].axisLabel, '', '']
          }
          tickLabelComponent={
            <VictoryLabel
              dy={(y) =>
                (mobile
                  ? MOBILE_OFFSET_SORCE_COMPARE_Y
                  : OFFSET_SORCE_COMPARE_Y)[y || 0]
              }
              textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
              inline
              style={[
                {
                  fill: (t = 0) => {
                    return score[t] && score[t].isPrefill
                      ? '#4C6072'
                      : '#7FB800'
                  },
                  fontSize: numberFontSize
                },
                {
                  fill: 'rgba(76,96,114,0.1)',
                  fontSize: numberFontSize
                },
                { fill: '#0D2350', fontSize: numberFontSize }
              ]}
            />
          }
          events={mobile ? null : axisEvent}
          style={{
            axis: {
              fill: 'rgba(13, 35, 80, 0.2)' // background
            },
            grid: {
              stroke: '#B1B8BD'
            }
          }}
        />
        <VictoryPolarAxis
          name="graph-label"
          tickValues={[0, 1, 2, 3, 4, 5, 6]}
          tickFormat={(t) => name[t].toUpperCase()}
          events={mobile ? null : axisEvent}
          tickLabelComponent={
            <VictoryLabel
              dy={(y) =>
                (mobile ? MOBILE_OFFSET_NAME_COMPARE_Y : OFFSET_NAME_COMPARE_Y)[
                  y || 0
                ]
              }
              textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
            />
          }
          labelPlacement="vertical"
          style={{
            axis: { stroke: 'transparent' },
            grid: { stroke: '#516960' },
            tickLabels: {
              fontSize: mobile ? 10 : 6,
              fill: (t) =>
                score[t].axisLabel === '--' && !compareData
                  ? '#E75F25'
                  : 'rgba(76,96,114,.6)',
              transform: 'translate(0, 8)'
            }
          }}
        />
        <VictoryPolarAxis
          dependentAxis
          tickFormat={(t) => null}
          style={{
            grid: {
              stroke: (t) => (t === 100 ? '#0D2350' : '#B1B8BD')
            },
            axis: {
              stroke: 'transparent'
            }
          }}
        />

        {compareData && (
          <VictoryArea
            style={{ data: { fill: 'rgba(13, 35, 80, 0.2)' } }}
            data={compareData}
          />
        )}
        {compareData && (
          <VictoryLine
            data={compareData}
            style={{
              data: {
                stroke: '#0D2350',
                strokeWidth: 1.5
              }
            }}
          />
        )}
        {compareScatterEstimate &&
          compareScatterEstimate.find((item) => item.y) !== -1 && (
            <VictoryScatter
              data={compareScatterEstimate}
              dataComponent={
                <Pointer
                  dasharray
                  stroke="#0D2350"
                  fill="rgba(13, 35, 80, 0.2)"
                />
              }
            />
          )}

        <VictoryArea
          style={{ data: { fill: 'rgba(127, 184, 0, 0.2)' } }}
          data={score}
        />
        <VictoryLine
          data={score}
          style={{
            data: {
              stroke: '#7FB800',
              strokeWidth: 1.5
            }
          }}
        />
        {scatterPreFillData.find((item) => item.y) !== -1 && (
          <VictoryScatter
            data={scatterPreFillData}
            dataComponent={<Pointer />}
          />
        )}
        {scatterEstimateData.find((item) => item.y) !== -1 && (
          <VictoryScatter
            data={scatterEstimateData}
            dataComponent={
              <Pointer
                dasharray
                stroke="#7FB800"
                fill="rgba(127, 184, 0, 0.2)"
              />
            }
          />
        )}
      </VictoryChart>
      {showPopUp && (
        <div
          className="data-detail"
          style={positions[name[popIndex]]}
          onMouseLeave={() => setShowPopUp(false)}
        >
          <div className="title">{name[popIndex]}</div>
          <div className="body">
            {score[popIndex] &&
            score[popIndex].axisLabel &&
            Number(score[popIndex].axisLabel) ? (
              <div>
                <div>
                  <span
                    className={`circle latest ${
                      score[popIndex].isPrefill ? 'carried' : ''
                    }`}
                  />
                  {score[popIndex].isPrefill
                    ? 'Carried Forward'
                    : moment(score[popIndex].date).format('MMM YYYY')}
                </div>
                <div
                  className={`latest ${
                    score[popIndex].isPrefill ? 'carried' : ''
                  }`}
                >
                  {score[popIndex].axisLabel}
                </div>
              </div>
            ) : null}
            {Number(_.get(compareData[popIndex], 'axisLabel', null)) ? (
              <div>
                <div>
                  <span className="circle previous" />
                  {moment(compareData[popIndex].date).format('MMM YYYY')}
                </div>
                <div className="previous">
                  {compareData[popIndex].axisLabel}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </GraphContainer>
  )
}

function Pointer(props) {
  const { x, y, fill, stroke, dasharray } = props
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 6, y: y - 6 }}
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={fill || 'white'}
        stroke={stroke || '#0D2350'}
        strokeWidth="2"
        strokeDasharray={dasharray ? '2,2' : null}
      />
    </svg>
  )
}
