import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VictoryPie } from 'victory'

class RatePie extends Component {
  static propTypes = {
    icon: PropTypes.any,
    averageRate: PropTypes.object
  }

  render() {
    const {
      averageRate: { achieved, missed, no_data },
      icon
    } = this.props
    const data = [
      { x: 1, y: no_data },
      { x: 2, y: missed },
      { x: 3, y: achieved }
    ]
    return (
      <div style={{ width: 80, position: 'relative' }}>
        <VictoryPie
          style={{ labels: { padding: 0, color: 'transparent', fontSize: 0 } }}
          colorScale={['rgba(166,176,185,.2)', '#EA4D00', '#80B32E']}
          label={d => ''}
          data={data}
        />
        <img
          src={icon}
          alt="icon"
          style={{
            width: 51,
            height: 51,
            position: 'absolute',
            border: '3px solid #F1F3F6',
            borderRadius: '100%',
            background: '#F1F3F6',
            top: 13,
            left: 13
          }}
        />
      </div>
    )
  }
}

export default RatePie
