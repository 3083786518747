/**
 * only used for onboarding logistics
 * */
import React, { useState, useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import _ from 'lodash'

export default function InlineEntries(props) {
  const { question, answer, update } = props
  const [values, setValues] = useState([])
  useEffect(() => {
    const an = answer.answer.value
    if (an) {
      setValues(an)
    }
  }, [answer])

  const hasError =
    answer.verify &&
    answer.verify.items &&
    answer.verify.items.find((an) => an && (an.required || an.message))
  return (
    <>
      <Row gutter={18}>
        {question.items.map((item, index) => (
          <Col key={index} span={item.span}>
            <Input
              placeholder="Please type here"
              value={values[index]}
              onChange={(e) => {
                const an = e.target.value
                values[index] = an
                setValues(values)
                update(
                  _.assignIn(answer, { answer: { value: values } }),
                  question
                )
              }}
              className={`q-form-item ${
                answer.verify &&
                answer.verify.items &&
                answer.verify.items[index]
                  ? 'invalid'
                  : ''
              }`}
              addonAfter={item.unit ? <span>{item.unit}</span> : null}
            />
          </Col>
        ))}
      </Row>
      {hasError && (
        <p className="invalid">
          {hasError.message || 'Please type these input'}
        </p>
      )}
    </>
  )
}
