import React from 'react'
import { pattern } from '../utils/constant'
import iconEmail from '../asserts/icon/signIn/email.svg'
import iconPws from '../asserts/icon/signIn/pws.svg'

export const signInForm = [
  {
    type: 'input',
    prefix: <img src={iconEmail} alt="prefix" />,
    dbField: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: pattern.email, message: 'Please input a valid email' }
    ]
  },
  {
    type: 'password',
    prefix: <img src={iconPws} alt="prefix" />,
    dbField: 'password',
    iconRender: (visible) => null,
    rules: [{ required: true, message: 'This field is required' }]
  }
]

export const verifyEmail = [
  {
    type: 'input',
    prefix: <img src={iconEmail} alt="prefix" />,
    dbField: 'email',
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: pattern.email, message: 'Please input a valid email' }
    ]
  }
]

export const registerForm = [
  {
    type: 'input',
    label: 'First Name',
    dbField: 'first_name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Last Name',
    dbField: 'last_name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Email',
    dbField: 'email',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Password',
    dbField: 'password',
    inputType: 'password',
    rules: [{ required: true, message: 'This field is required' }]
  }
]
