import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import Message from '../../components/message'
import { loadUser } from '../../utils/storage'

class MessagePage extends Component {
  static propTypes = {}

  render() {
    const person = loadUser()
    return (
      <>
        <h1 className="title" style={{ paddingTop: 40 }}>
          Chat
        </h1>
        <div
          style={{
            background: '#ffffff',
            borderRadius: 12,
            padding: 30,
            boxShadow: '10px 4px 30px rgba(0, 0, 0, 0.05)',
            minHeight: 'calc(100vh - 300px + 90px)',
            marginRight: 50
            // position: 'absolute',
            // width: 'calc(100% - 110px)'
          }}
        >
          <Message clientId={person.id} sender={person} />
        </div>
      </>
    )
  }
}

export default MessagePage
