import { updateAnswer } from '../../../api'
import _ from 'lodash'

export default class AnswerUpdater {
  /**{
   *  [qid]: {
   *  timestamp, answer
   * }
   * }**/
  _postQueue = {}

  constructor(params) {
    const { answers, saveCallback } = params
    this._answers = answers
    this._saveCallback = saveCallback
  }

  _postDoing = false
  _updating = false

  isLoading() {
    return this._updating || this._postDoing
  }

  async save(answer, section_original_id) {
    const skip = this.checkAnswer(answer)
    if (!skip) {
      this._updating = true
      const { session_id, question_original_id } = answer

      await updateAnswer({
        session_id,
        question_original_id,
        answer: answer.answer
      })

      this._updating = false
      this._saveCallback()
    }
  }

  autoSave(answer, sectionOriginalId) {
    this._updating = true
    const { question_original_id } = answer
    this._postQueue[question_original_id] = {
      answer,
      timeStamp: new Date().getTime()
    }
    this.autoPost(sectionOriginalId)
  }

  updateAnswer(answer, sectionOriginalId) {
    const answerInfo = this._answers.find(
      (item) => item.section_original_id === sectionOriginalId
    )
    if (answerInfo) {
      const targetIndex = answerInfo.answers.findIndex(
        (_answer) =>
          _answer.question_original_id === answer.question_original_id
      )
      if (targetIndex > -1) {
        answerInfo.answers[targetIndex] = answer
      } else {
        answerInfo.answers.push(answer)
      }
    }
  }

  autoPost(sectionOriginalId) {
    if (this._postDoing) return
    this._postDoing = true
    const getQUeue = () =>
      Object.keys(this._postQueue)
        .filter((qid) => this._postQueue[qid])
        .map((qid) => {
          const { timeStamp, answer } = this._postQueue[qid]

          const result = {
            qid,
            answer,
            timeStamp
          }

          return result
        })

    const doingPost = async () => {
      const queue = getQUeue()

      if (!queue.length) {
        this._postDoing = false
        this._updating = false
        this._saveCallback()
        return
      }
      queue.sort((a, b) => a.timeStamp - b.timeStamp)
      const timeStamp = new Date().getTime()
      if (timeStamp - queue[0].timeStamp >= 2 * 1000) {
        const skip = this.checkAnswer(queue[0])

        if (!skip) {
          try {
            this._updating = true
            const { session_id, question_original_id, answer } = queue[0].answer
            const params = { session_id, question_original_id, answer }
            await updateAnswer(params)
            this.updateAnswer(queue[0].answer, sectionOriginalId)
            delete this._postQueue[queue[0].qid]
            this._updating = Object.keys(this._postQueue).length ? true : false
            this._saveCallback()
          } catch (err) {
            console.error(err)
            this._postDoing = false
            this._updating = false
            return
          }
        }
      }

      setTimeout(doingPost, 1000)
    }

    doingPost()
  }

  checkAnswer(answer) {
    let skip = false

    if (answer.verify) {
      skip = true
    }

    return skip
  }
}

async function verify(answer, question) {
  const { value, text } = answer ? answer.answer : {}
  const { choices, type, required } = question
  let _verify
  if (type === 'single_choice') {
    _verify = { choices: _.fill(new Array(choices), null) }
    if (!value && value !== 0 && required) {
      _verify = { required: true }
    } else {
      const index = choices.findIndex((item) => item.title === value)
      if (index !== -1 && choices[index].allow_text_entry && !text) {
        _verify.choices[index] = { textRequired: true }
      }
    }
  } else if (type === 'multi_choice') {
    if (required && _.isEmpty(answer.answer)) {
      _verify = { required: true }
    } else {
      _verify = { choices: _.fill(new Array(choices), null) }
      _.forIn(choices, (choice, index) => {
        const isChecked = answer.answer.find(
          (item) => item.value === choice.title
        )
        if (isChecked) {
          if (choice.allow_text_entry && !isChecked.text) {
            _verify.choices[index] = { textRequired: true }
          } else if (choice.subChoices && !isChecked.sub_value) {
            _verify.choices[index] = {
              subChoiceRequired: true,
              message: choice.subType === 'number' && 'Please type number here'
            }
          }
        }
      })
    }
  } else if (type === 'additional_form') {
    if (question.allow_multiple_answer) {
      _.forIn(answer.answer, async (item) => {
        if (item.formRef) {
          try {
            await item.formRef.validateFields()
          } catch (err) {
            console.error(err)
            if (err.errorFields && err.errorFields.length > 0) {
              _verify = { message: 'Please fill out this form' }
            }
          }
        }
      })
    } else {
      if (answer.answer.formRef) {
        try {
          await answer.answer.formRef.validateFields()
        } catch (err) {
          if (err.errorFields.length > 0) {
            _verify = { message: 'Please fill out this form' }
          }
        }
      }
    }

    if (required && _.isEmpty(answer.answer)) {
      _verify = { required: true }
    }
  }
  if (_verify && _verify.choices && !_verify.choices.find((item) => item)) {
    _verify = undefined
  }
  return _verify
}

export async function verifyAnswer(answer, question) {
  const { type, required } = question
  const { value } = answer ? answer.answer : {}

  let _verify
  switch (type) {
    case 'yes_no':
      _verify = _.isBoolean(value) ? null : { required: true }
      break
    case 'level_bar':
    case 'label_level_bar':
    case 'text_entry':
    case 'time':
    case 'slider':
    case 'number':
      _verify = value || _.isNumber(value) ? null : { required: true }
      break
    case 'single_choice':
    case 'multi_choice':
    case 'additional_form':
      _verify = await verify(answer, question)
      break
    case 'question_group':
      _verify =
        required && _.isEmpty(answer && answer.answer)
          ? { required: true }
          : null
      if (!_verify) {
        await _.forIn(answer.answer, async (subAnswer) => {
          const subQuestion = question.sub_questions.find(
            (q) => q.original_id === subAnswer.question_original_id
          )
          const __verify = await verify(subAnswer, subQuestion)
          if (__verify) {
            subAnswer.verify = __verify
          } else {
            delete subAnswer.verify
          }
        })
      }

      break
    default:
      break
  }

  if (_verify) {
    answer = answer || {}
    answer.verify = _verify
  } else if (answer && answer.verify) {
    delete answer.verify
  }

  return answer
}

export async function checkCompleted(answers, sections) {
  for (let index = 0; index < sections.length; index++) {
    const section = sections[index]
    const answerInfo = answers.find(
      (answer) => answer.section_original_id === section.original_id
    )
    if (!answerInfo) {
      continue
    }
    const _verifyAnswers = []

    for (let index = 0; index < section.questions.length; index++) {
      const question = section.questions[index]
      if (['description', 'sub_title', 'banner'].includes(question.type)) {
        continue
      }
      let answer = answerInfo.answers.find(
        (item) => item.question_original_id === question.original_id
      )
      if (!answer && question.required) {
        answer = {
          session_id: answerInfo.id,
          question_original_id: question.original_id,
          question_type: question.type,
          answer:
            question.type === 'multi_choice'
              ? []
              : {
                  value: null
                }
        }
        if (question.type === 'question_group') {
          answer.answer = {}
        }
      }
      if (answer) {
        const _answer = await verifyAnswer(answer, question)
        _verifyAnswers.push(_answer)
      }
    }

    answerInfo.answers = _verifyAnswers
  }

  let verifyAnswers = _.concat(
    [],
    ...answers.map((answerInfo) => answerInfo.answers)
  )
  const isCompleted = !JSON.stringify(verifyAnswers).includes('verify')
  return { isCompleted, answers }
}
