import React, { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import BiomarkerLayout from './layout'
import BloodTestForm from './form'
import { getCategories } from '../../../api/categories'
import { getBiomarkerById, editBiomarker } from '../../../api/biomarkers'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'

class BloodTestEditor extends Component {
  static propTypes = {}

  state = {
    categories: null,
    biomarker: null,
    loading: false,
    valueType: '',
    ageScopes: null
  }

  initial = async (id) => {
    const { match } = this.props
    id = id || match.params.id
    let { categories, valueType, ageScopes } = this.state
    this.setState({ loading: true })
    try {
      categories = categories || (await getCategories())
      const biomarker = await getBiomarkerById(id)
      biomarker.conventional_ranges.sort((a, b) => a.order - b.order)
      biomarker.functional_ranges.sort((a, b) => a.order - b.order)
      if (biomarker.options) {
        valueType = 'multi-select'
      } else {
        valueType = 'number'
      }
      if (!ageScopes) {
        const age_scope_def = (await getRangeAgeScopes()).age_scope // pls check BE'S router/range_age_scope.ts
        ageScopes = Object.entries(age_scope_def).map(([k, v]) => ({
          tag: k,
          label: v.label
        }))
      }
      this.setState({
        categories,
        biomarker,
        valueType,
        loading: false,
        ageScopes
      })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  addRange = (type) => {
    const { biomarker } = this.state
    if (type === 'func') {
      biomarker.functional_ranges.push({ color: 'yellow' })
    } else {
      biomarker.conventional_ranges.push({ color: 'yellow' })
    }

    this.setState({ biomarker })
  }

  removeRange = async (index, type) => {
    const { biomarker } = this.state

    if (type === 'func') {
      biomarker.functional_ranges.splice(index, 1)
    } else {
      biomarker.conventional_ranges.splice(index, 1)
    }
    this.setState({
      biomarker
    })
  }

  update = async (params) => {
    const { match } = this.props
    const { id } = match.params
    try {
      await editBiomarker(id, params)
      this.setState({ biomarker: null })
      this.initial(id)
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  componentDidMount() {
    this.initial()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id } = nextProps.match.params
    const { biomarker } = this.state
    if (!biomarker || id !== this.props.id) {
      this.setState({ biomarker: null })
      this.initial(id)
    }
  }

  render() {
    const { match, history } = this.props
    const { categories, biomarker, loading, valueType, ageScopes } = this.state
    const layoutProps = {
      categories,
      biomarker,
      id: match.params.id,
      turnTo: (id) => history.replace(`/blood-test/${id}`),
      goBack: () => history.push('/admin/blood-test')
    }
    const detailProps = {
      biomarker,
      valueType,
      ageScopes,
      addRange: this.addRange,
      removeRange: this.removeRange,
      update: this.update,
      changeValueType: (valueType) => this.setState({ valueType })
    }
    return (
      <BiomarkerLayout {...layoutProps}>
        {loading && (
          <div className="page-loading page-loading-modal">
            <LoadingOutlined />
          </div>
        )}
        {categories && biomarker && <BloodTestForm {...detailProps} />}
      </BiomarkerLayout>
    )
  }
}

export default BloodTestEditor
