import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tag } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { SignedIcon } from '../../icons/onboarding'
import { getWaiverSignedForm, getWaiverSinedFormUrl } from '../../../api/people'
import leftArrow from '../../../asserts/icon/btn-left-arrow.svg'
import pdfIcon from '../../../asserts/images/pdf.svg'
import viewSignedIcon from '../../../asserts/icon/icon-signed-form-view.svg'
import toSignIcon from '../../../asserts/icon/icon-to-sign.svg'

import { whiteBoxView } from './index.sass'

class SignedForms extends Component {
  static propTypes = {
    toWaiver: PropTypes.func,
    back: PropTypes.func,
    moreForms: PropTypes.func,
    person: PropTypes.object,
    loginRole: PropTypes.string
  }

  state = {
    medicalForms: [],
    liabilityForms: [],
    membershipAgreement: []
  }

  loadSignedForms = async () => {
    const { person, loginRole } = this.props
    const result = await getWaiverSignedForm(false, person && person.id)
    const formData = _.cloneDeep(result)
    if (result) {
      if (!result.medical_release_form.length && loginRole !== 'Expert') {
        result.medical_release_form = [
          {
            status: 'no',
            type: 'medical_release_form'
          }
        ]
      }
      if (!result.liability_release_form.length && loginRole !== 'Expert') {
        result.liability_release_form = [
          {
            status: 'no',
            type: 'liability_release_form'
          }
        ]
      }
      if (!result.membership_agreement.length && loginRole !== 'Expert') {
        result.membership_agreement = [
          {
            status: 'no',
            type: 'membership_agreement'
          }
        ]
      }
      this.setState({
        medicalForms: result.medical_release_form,
        liabilityForms: result.liability_release_form,
        membershipAgreement: result.membership_agreement[0]
          ? [result.membership_agreement[0]]
          : result.membership_agreement,
        formData
      })
    }
  }

  openUrl = async (id) => {
    const { person } = this.props
    const { url } = await getWaiverSinedFormUrl(id, person && person.id)
    window.open(url)
  }

  componentDidMount() {
    this.loadSignedForms()
  }

  getSignedForms(forms, txt, addIdx) {
    const { moreForms } = this.props
    return (
      <>
        {forms.map((info, index) => {
          const { doctor, expires_on } = info
          const signed = info.status === 'signed'
          return (
            <div className="form-view" key={index}>
              <div className="form-view-container">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img src={pdfIcon} alt="" />
                  <div className="form-info">
                    <span className="form-title">
                      {addIdx
                        ? `${txt} - ${forms.doctor || index + 1}`
                        : `${txt} - ${doctor || ''}`}
                    </span>
                    <div>
                      {signed && (
                        <span style={{ color: '#22A95F' }}>
                          <SignedIcon style={{ marginRight: 10 }} />
                          Signed
                        </span>
                      )}
                      {expires_on && moment().isAfter(expires_on) && (
                        <Tag style={{ marginLeft: 10 }} color="error">
                          Expired
                        </Tag>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                  onClick={(e) =>
                    signed
                      ? this.openUrl(info.signature_request_id)
                      : moreForms(txt)
                  }
                >
                  <img
                    className="opr-icon"
                    src={signed ? viewSignedIcon : toSignIcon}
                    alt=""
                  />
                  <span className="form-view-icon-title">
                    {signed ? 'VIEW' : 'SIGN'}
                  </span>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  render() {
    const { back, loginRole, moreForms } = this.props //toWaiver
    const {
      medicalForms,
      liabilityForms,
      membershipAgreement,
      formData
    } = this.state
    return (
      <div className={whiteBoxView} style={{ marginTop: back ? 120 : 0 }}>
        <div className="white-box-container">
          {back && (
            <div className="back" onClick={back}>
              <img
                src={leftArrow}
                alt=""
                style={{ marginRight: 10, marginTop: -1 }}
              />
              <span className="back-title">BACK</span>
            </div>
          )}
          <div className="white-box-container-title">Your Forms</div>
          <div className="white-box-container-notice">
            <span>Note:</span> Any new forms you sign may take a few minutes to
            be displayed here
          </div>
          <div className="form-view-box">
            {(loginRole !== 'Expert' || medicalForms.length > 0) &&
              this.getSignedForms(medicalForms, 'Medical Release Form')}

            {formData && formData.medical_release_form.length > 0 && moreForms && (
              <p
                className="add-more-tip"
                onClick={() => moreForms('Medical Release Form')}
              >
                + ADD MORE MEDICAL RELEASE FORM
              </p>
            )}

            {(loginRole !== 'Expert' || liabilityForms.length > 0) &&
              this.getSignedForms(
                liabilityForms,
                'Liability Release Form',
                // false
                formData && formData.liability_release_form.length > 0
              )}

            {(loginRole !== 'Expert' || membershipAgreement.length > 0) &&
              this.getSignedForms(
                membershipAgreement,
                'Membership Agreement',
                // false
                formData && formData.membership_agreement.length > 0
              )}
          </div>
        </div>
      </div>
    )
  }
}

export default SignedForms
