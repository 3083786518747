import React from 'react'
import { Divider } from 'antd'
import styled from 'styled-components'
import HealthCard from '../../healthScore/card'
import Information from '../../UI/extraInformation'
import HideWrap from '../comp/hideWrap'
import {
  RangeBar,
  ValueIcon
} from '../../../components/assessmentReview/comp/bodyComp'

import { ScoreColorGroup } from '../comp/Widgets'
import GripImage from '../../../asserts/images/placeholder/grip-strength.svg'
import PullImage from '../../../asserts/images/placeholder/pull-ups.svg'
import PushImage from '../../../asserts/images/placeholder/push-ups.svg'
import SitToStandImage from '../../../asserts/images/placeholder/sit-to-stand.svg'
import IsometricPullImage from '../../../asserts/images/physical-assessment/ic-isometric-pull.svg'
import IsometricBeltSquatImage from '../../../asserts/images/physical-assessment/ic-isometric-belt-squat.svg'
import IsometricPressImage from '../../../asserts/images/physical-assessment/ic-isometric-press.png'

import FrontPlankImage from '../../../asserts/images/placeholder/front-plank.svg'
import BackExtensionImage from '../../../asserts/images/placeholder/back-extension.svg'
import LeftBridgeImage from '../../../asserts/images/placeholder/left-bridge.svg'
import RightBridgeImage from '../../../asserts/images/placeholder/right-bridge.svg'

import HipAbductionImage from '../../../asserts/images/placeholder/hip-abduction.svg'
import HipAdductionImage from '../../../asserts/images/placeholder/hip-adduction.svg'
import KneeFlexionImage from '../../../asserts/images/placeholder/knee-flexion.svg'
import KneeExtensionImage from '../../../asserts/images/placeholder/knee-extension.svg'
import FlexionExtensionRatioLeftImage from '../../../asserts/images/placeholder/flexion-extension-ratio-left.svg'
import FlexionExtensionRatioRightImage from '../../../asserts/images/placeholder/flexion-extension-ratio-right.svg'
import CountermovementImage from '../../../asserts/images/physical-assessment/ic-countermovement-jump.svg'

import AsymmetryChart from '../comp/AsymmetryChart'
import classNames from 'classnames'
import { RangeColors } from '../../../utils'
import { handleMetricRanges } from '../../../components/assessmentReview/wrapper'
import { HistoryChart } from '../comp/strength'
import _, { upperFirst } from 'lodash'
import moment from 'moment'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

const Container = styled.section`
  .ant-divider-horizontal {
    margin: 0;
  }

  .metrics-section-container {
    margin-top: 60px;
    position: relative;
    .metrics-section-name {
      font-size: 24px;
      font-weight: 600;
      color: #323f4a;
    }
  }
`

export default function MuscleSection(props) {
  const {
    person,
    hideSectionHandle,
    hideSection,
    compareData,
    dataList,
    view,
    loginRole,
    save,
    onScoreChangeHandle,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    ageScopes,
    arDetail: { notes, status },
    dynamicData: { upperBodyAndCoreStrength, lowerBodyStrength }
    // data: {
    //   gripStrength,
    //   isometricPull,
    //   isometricPress,
    //   sitToStand,
    //   isometricBeltSquat
    // }
  } = props

  const muscleData =
    dataList.find((item) => item.name === 'Muscle Fitness') || {}
  const muscleCompare =
    compareData.find((item) => item.name === 'Muscle Fitness') || {}

  const muscle = notes.find((item) => item.pillar === 'muscle')

  const metrics = [
    {
      section_name: 'Upper Body Strength',
      section_metrics: [
        {
          name: 'Grip Strength',
          image: GripImage,
          avg: {
            ...upperBodyAndCoreStrength.combined_grip_strength,
            showRangeArea: true,
            hideName: true,
            hideValueNumber: true
          },
          left: {
            ...upperBodyAndCoreStrength.grip_strength_left
          },
          right: {
            ...upperBodyAndCoreStrength.grip_strength_right
          },
          asymmetry: {
            ...upperBodyAndCoreStrength.grip_strength_diff,
            name: 'Grip Strength'
          },
          asymmetryText: 'Asymmetry',
          isSymmetricChart: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.grip_strength_left,
              name: 'Grip Strength (L)'
            },
            {
              ...upperBodyAndCoreStrength.grip_strength_right,
              name: 'Grip Strength (R)'
            },
            {
              ...upperBodyAndCoreStrength.grip_strength_age_ranked,
              name: 'Grip Strength (Age Ranked)'
            },
            {
              ...upperBodyAndCoreStrength.combined_grip_strength,
              name: 'Classification'
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.combined_grip_strength }
        },
        {
          name: 'Pull-Ups',
          image: PullImage,
          hidden: true,
          showGenderAge: true,
          avg: {
            ...upperBodyAndCoreStrength.pull_up.level === null ? upperBodyAndCoreStrength.hang_90 : upperBodyAndCoreStrength.pull_up,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.pull_up.level === null ? upperBodyAndCoreStrength.hang_90 : upperBodyAndCoreStrength.pull_up,
              name: 'Pull-ups',
              comparable: true
            }
          ],
          historyChart: {
            ...upperBodyAndCoreStrength.pull_up.level === null ? upperBodyAndCoreStrength.hang_90 : upperBodyAndCoreStrength.pull_up
        }
        },
        {
          name: 'Push-Ups',
          image: PushImage,
          avg: {
            ...upperBodyAndCoreStrength.push_up,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.push_up,
              name: 'Push-Ups',
              comparable: true
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.push_up }
        },
        {
          name: 'Isometric Pull',
          image: IsometricPullImage,
          avg: {
            ...upperBodyAndCoreStrength.isometric_pull_avg,
            showRangeArea: true,
            hideName: true,
            hideValueNumber: true
          },
          left: {
            ...upperBodyAndCoreStrength.isometric_pull_left
          },
          right: {
            ...upperBodyAndCoreStrength.isometric_pull_right
          },
          asymmetry: {
            ...upperBodyAndCoreStrength.isometric_pull_difference
          },
          asymmetryText: 'Asymmetry',
          isSymmetricChart: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.isometric_pull_left,
              name: 'Isometric Pull (L)'
            },
            {
              ...upperBodyAndCoreStrength.isometric_pull_right,
              name: 'Isometric Pull (R)'
            },
            {
              ...upperBodyAndCoreStrength.isometric_pull_total,
              name: 'Isometric Pull/ BW (Total)'
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.isometric_pull_total }
        },
        {
          name: 'Isometric Press',
          image: IsometricPressImage,
          imageStyle: {
            width: 142
          },
          avg: {
            ...upperBodyAndCoreStrength.isometric_press_avg,
            showRangeArea: true,
            hideName: true,
            hideValueNumber: true
          },
          left: {
            ...upperBodyAndCoreStrength.isometric_press_left
          },
          right: {
            ...upperBodyAndCoreStrength.isometric_press_right
          },
          asymmetry: {
            ...upperBodyAndCoreStrength.isometric_press_difference
          },
          asymmetryText: 'Asymmetry',
          isSymmetricChart: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.isometric_press_left,
              name: 'Isometric Press (L)'
            },
            {
              ...upperBodyAndCoreStrength.isometric_press_right,
              name: 'Isometric Press (R)'
            },
            {
              ...upperBodyAndCoreStrength.isometric_press_total,
              name: 'Isometric Press (Total)'
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.isometric_press_total }
        }
      ]
    },
    {
      section_name: 'Trunk Endurance',
      section_metrics: [
        {
          name: 'Plank',
          image: FrontPlankImage,
          imageStyle: {
            width: 200
          },
          avg: {
            ...upperBodyAndCoreStrength.trunk_isometric_flexion_plank,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.trunk_isometric_flexion_plank,
              name: 'Plank',
              comparable: true
            }
          ],
          historyChart: {
            ...upperBodyAndCoreStrength.trunk_isometric_flexion_plank
          }
        },
        {
          name: 'Back Extension',
          image: BackExtensionImage,
          imageStyle: {
            width: 200
          },
          avg: {
            ...upperBodyAndCoreStrength.trunk_isometric_extension_back,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.trunk_isometric_extension_back,
              name: 'Back Extension',
              comparable: true
            }
          ],
          historyChart: {
            ...upperBodyAndCoreStrength.trunk_isometric_extension_back
          }
        },
        {
          name: 'Left Bridge',
          image: LeftBridgeImage,
          imageStyle: {
            width: 200
          },
          avg: {
            ...upperBodyAndCoreStrength.trunk_side_bridge_left,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.trunk_side_bridge_left,
              name: 'Left Bridge',
              comparable: true
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.trunk_side_bridge_left }
        },
        {
          name: 'Right Bridge',
          image: RightBridgeImage,
          imageStyle: {
            width: 200
          },
          avg: {
            ...upperBodyAndCoreStrength.trunk_side_bridge_right,
            showRangeArea: true,
            hideName: true,
            showUnit: false
          },
          noCompare: true,
          subMetrics: [
            {
              ...upperBodyAndCoreStrength.trunk_side_bridge_right,
              name: 'Right Bridge',
              comparable: true
            }
          ],
          historyChart: { ...upperBodyAndCoreStrength.trunk_side_bridge_right }
        }
      ]
    },
    {
      section_name: 'Lower Body Strength',
      section_metrics: [
        {
          name: 'Sit to Stand',
          image: SitToStandImage,
          avg: {
            ...lowerBodyStrength.Sit_to_Stand,
            showRangeArea: true,
            hideName: true
          },
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.Sit_to_Stand,
              name: 'Sit to stand'
            },
            {
              ...lowerBodyStrength.sit_to_stand_age_ranked,
              name: 'Sit to Stand (Age-ranked)'
            }
          ],
          historyChart: { ...lowerBodyStrength.Sit_to_Stand }
        },
        {
          name: 'Hip Abduction Total',
          image: HipAbductionImage,
          avg: {
            ...lowerBodyStrength.hip_abduction_total,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.hip_abduction_diff
          },
          asymmetryText: 'Asymmetry',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.hip_abduction_age_ranked,
              name: 'Hip Abduction (Age Ranked)'
            },
            {
              ...lowerBodyStrength.hip_abduction_total,
              name: 'Classification'
            }
          ],
          historyChart: { ...lowerBodyStrength.hip_abduction_total }
        },
        {
          name: 'Hip Adduction Total',
          imageStyle: {
            height: 140
          },
          image: HipAdductionImage,
          avg: {
            ...lowerBodyStrength.hip_adduction_total,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.hip_adduction_diff
          },
          asymmetryText: 'Asymmetry',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.hip_adduction_age_ranked,
              name: 'Hip Adduction (Age Ranked)'
            },
            {
              ...lowerBodyStrength.hip_adduction_total,
              name: 'Classification'
            }
          ],
          historyChart: { ...lowerBodyStrength.hip_adduction_total }
        },
        {
          name: 'Knee Flexion',
          image: KneeFlexionImage,
          avg: {
            ...lowerBodyStrength.knee_flexion_total,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.knee_flexion_diff
          },
          asymmetryText: 'Asymmetry',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.knee_flexion_age_ranked,
              name: 'Knee Flexion (Age Ranked)'
            },
            {
              ...lowerBodyStrength.knee_flexion_total,
              name: 'Classification'
            }
          ],
          historyChart: { ...lowerBodyStrength.knee_flexion_total }
        },
        {
          name: 'Knee Extension',
          image: KneeExtensionImage,
          avg: {
            ...lowerBodyStrength.knee_extension_total,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.knee_extension_diff
          },
          asymmetryText: 'Asymmetry',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.knee_extension_age_ranked,
              name: 'Knee Extension (Age Ranked)'
            },
            {
              ...lowerBodyStrength.knee_extension_total,
              name: 'Classification'
            }
          ],
          historyChart: { ...lowerBodyStrength.knee_extension_total }
        },
        {
          name: 'Flexion/Extension Ratio Left',
          image: FlexionExtensionRatioLeftImage,
          avg: {
            ...lowerBodyStrength.knee_flexion_extension_ratio_left,
            showRangeArea: true,
            hideName: true
          },
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.knee_flexion_extension_ratio_left,
              name: 'Flexion/Extension Ratio Left',
              comparable: true
            }
          ],
          historyChart: {
            ...lowerBodyStrength.knee_flexion_extension_ratio_left
          }
        },
        {
          name: 'Flexion/Extension Ratio Right',
          image: FlexionExtensionRatioRightImage,
          avg: {
            ...lowerBodyStrength.knee_flexion_extension_ratio_right,
            showRangeArea: true,
            hideName: true
          },
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.knee_flexion_extension_ratio_right,
              name: 'Flexion/Extension Ratio Right',
              comparable: true
            }
          ],
          historyChart: {
            ...lowerBodyStrength.knee_flexion_extension_ratio_right
          }
        },
        {
          name: 'Isometric Belt Squat ',
          image: IsometricBeltSquatImage,
          avg: {
            ...lowerBodyStrength.belt_squat_isometric_pull,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.belt_squat_isometric_pull_asymmetry
          },
          asymmetryText: 'Isometric Belt Squat Asymmetry',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.belt_squat_isometric_pull,
              name: 'Belt Squat Isometric Peak Force'
            }
          ],
          historyChart: { ...lowerBodyStrength.belt_squat_isometric_pull }
        },
        {
          name: 'Countermovement Jump',
          image: CountermovementImage,
          imageStyle: {
            height: 230
          },
          avg: {
            ...lowerBodyStrength.jump_height,
            showRangeArea: true,
            hideName: true
          },
          asymmetry: {
            ...lowerBodyStrength.peak_drop_landing_force_asymmetry,
            name: 'Countermovement Jump'
          },
          asymmetryText: 'Peak Knee Flexion (After Landing)',
          noCompare: true,
          subMetrics: [
            {
              ...lowerBodyStrength.jump_height,
              name: 'Jump Height'
            }
          ],
          historyChart: { ...lowerBodyStrength.jump_height }
        }
      ]
    }
  ]

  return (
    <HideWrap
      name="Muscle Fitness"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
    >
      <Container>
        <h1 className="section-title">Muscle Fitness</h1>
        <HideWrap
          name="Muscle Fitness - summary"
          title="summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <h3 className="sub-title">Summary</h3>
          <HealthCard
            {...muscleData}
            compare={muscleCompare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={muscle ? muscle.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'muscle', status)
            }
            updateEditingCount={updateEditingCount}
          />
        </HideWrap>
        {metrics.map((section, index) => (
          <div
            key={`${section.section_name}-${index}`}
            className="metrics-section-container"
          >
            <div className="metrics-section-name">{section.section_name}</div>
            <ScoreColorGroup
              domain={'muscle'}
              style={{
                position: 'absolute',
                right: 0,
                top: 0
              }}
            />
            {section.section_metrics.map((metric, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Divider />}
                  <HideWrap
                    name={metric.name}
                    title=""
                    syncStatus={hideSectionHandle}
                    status={hideSection}
                    view={view}
                    viewIconStyle={
                    hideSection.includes(metric.name)
                      ? {
                        right: '-6px',           
                        top: 'calc(50% - 16px)',
                        zIndex: 9999
                      }
                      : {
                        top: '60px',
                        zIndex: 9999
                      }
                    }
                >
                  <MuscleMetric
                    {...metric}
                    {...{
                      person,
                      ageScopes
                    }}
                  />
                </HideWrap>

              </React.Fragment>
            ))}
            {index !== section.section_metrics.length - 1 && <Divider />}
          </div>
        ))}
      </Container>
    </HideWrap>
  )
}

const MetricContainer = styled.div`
  height: 450px;
  position: relative;

  .metric-name {
    font-size: 18px;
    letter-spacing: 0.3px;
    line-height: 27px;
    position: absolute;
    top: 68px;
    color: #4c6072;
  }
  .metric-content {
    display: flex;
    align-items: center;
    height: 100%;
    & > div {
      display: inline-block;
    }

    .metric-middle-container {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      .bar-with-title {
        display: flex;
        & > span {
          width: 40px;
          min-width: 40px;
          font-size: 12px;
          text-align: center;
        }
        & > div {
          flex-grow: 1;
        }
        .ranges {
          margin-top: 6px;
        }
      }
      .sub-metrics-container {
        display: flex;
        justify-content: space-between;
        .metric-text-container {
          max-width: 50%;
          display: flex;
          flex-wrap: wrap;
          row-gap: 24px;
          margin-left: 40px;
          margin-top: 36px;
          .single-text-metric {
            margin-right: 16px;
            .single-text-metric-title {
              font-size: 15px;
              color: #4c6072;
            }
            .single-text-metric-content {
              font-size: 24px;
              color: #40c47c;
              font-weight: 700;

              svg {
                margin-left: 5px;
              }
            }
          }

          &.no-compare {
            margin-left: 0;
          }
        }
      }
    }

    .gender-age-container {
      width: 24%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .gender-label {
        padding: 11px 16px;
        background: #2b4b8e;
        color: white;
        border-radius: 12px;
        font-size: 16px;
        font-weight: 600;
      }
      .age-container {
        margin-top: 16px;
        color: #4c6072;
        font-weight: 600;
        font-size: 16px;
        .age {
          margin-left: 8px;
          color: black;
        }
      }
    }
  }

  .metric-polar-chart-container {
    position: absolute;
    width: 340px;
    top: 50%;
    right: -75px;
    transform: translateY(-50%);
    .polar-chart-name {
      font-size: 15px;
      line-height: 21px;
      color: #4c6072;
      display: flex;
      text-align: center;
      justify-content: center;
      & > div {
        max-width: 220px;
      }
    }
  }
`

function MuscleMetric(props) {
  const {
    noCompare,
    person,
    ageScopes,
    isSymmetricChart,
    showGenderAge
  } = props
  
  return (
    <MetricContainer>
      <div className="metric-name">{props.name}</div>
      <div className="metric-content">
        <div
          className="metric-image-conatiner"
          style={{
            width: '22.57%'
          }}
        >
          <img src={props.image} alt="bg" style={props.imageStyle || {}} />
        </div>
        <div
          className="metric-middle-container"
          style={{
            width: '53.57%'
          }}
        >
          {noCompare ? (
            <RangeBar {...props.avg} {...{ ageScopes, person }} />
          ) : (
            <>
              <div className="bar-with-title">
                <span>(L)</span>
                <CompareLine {...props.left} {...{ ageScopes, person }} />
              </div>
              <div className="bar-with-title">
                <span>(R)</span>
                <CompareLine {...props.right} {...{ ageScopes, person }} />
              </div>
              <div className="bar-with-title">
                <span>(AVG)</span>
                <RangeBar {...props.avg} {...{ ageScopes, person }} />
              </div>
            </>
          )}
          <div className="sub-metrics-container">
            <div
              className={classNames('metric-text-container', {
                'no-compare': noCompare
              })}
            >
              {props.subMetrics.map((metric, index) => {
                if(metric.name === 'Classification') {
                    return null
                }
                return (
                  <div className="single-text-metric" key={index}>
                    <div className="single-text-metric-title">
                      {metric.name}
                    </div>
                    <div
                      className="single-text-metric-content"
                      style={{
                        color:
                          RangeColors[metric.color] || 'rgba(67, 67, 67, 1)'
                      }}
                    >
                      {metric.value
                        ? `${metric.value +
                            (metric.unit ? ` ${metric.unit}` : '')}`
                        : '--'}
                      {renderArrow(metric)}
                    </div>
                  </div>
                )
              })}
            </div>
            {props.historyChart && props.historyChart.series && (
              <div
                style={{
                  maxWidth: 240
                }}
              >
                <HistoryChart {...props.historyChart} />
              </div>
            )}
          </div>
        </div>
        {/* {showGenderAge && (
          <div className="gender-age-container">
            <div className="gender-label">
              {upperFirst(person.profile.gender)}
            </div>
            <div className="age-container">
              <span>Age: </span>
              <span className="age">{renderAgeScore(person, ageScopes)}</span>
            </div>
          </div>
        )} */}
      </div>

      {props.asymmetry && (
        <div className="metric-polar-chart-container">
          <div className="polar-chart-name">
            <div>{props.asymmetryText}</div>
          </div>
          <AsymmetryChart
            metric={props.asymmetry}
            {...{ ageScopes, person, isSymmetricChart }}
          />
        </div>
      )}
    </MetricContainer>
  )

  function renderAgeScore(person, ageScopes) {
    const age = moment().diff(person.profile.date_of_birth, 'years')
    const targetScope = ageScopes.find(({ scope: [min, max] }) => {
      if (age >= min && age <= max) return true
      return false
    })
    return targetScope.label
  }

  function renderArrow(metric) {
    if (metric.comparable && metric.series.length > 1) {
      const lastObj = _.last(metric.series).value
      const compareObj = _.nth(metric.series, -2).value
      if (lastObj > compareObj) {
        return (
          <CaretUpOutlined
            style={{
              fontSize: 14,
              color: 'rgb(64, 196, 124)'
            }}
          />
        )
      } else if (lastObj < compareObj) {
        return (
          <CaretDownOutlined
            style={{
              fontSize: 14,
              color: '#FC5108'
            }}
          />
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }
}

const CompareLineContainer = styled.div`
  /* position: relative; */
  display: flex;
  height: 22px;
  padding-bottom: 5px;

  .range-item {
    position: relative;
    .value {
      position: absolute;
      bottom: -7px;
    }
    & > svg {
      margin-bottom: 5px;
    }
  }
`

function CompareLine(props) {
  let { value, ranges, ageScopes, person, level } = props

  let _ranges = handleMetricRanges(ranges, person, ageScopes)

  return (
    <CompareLineContainer>
      {_ranges.map((item, index) => (
        <div
          key={index}
          className={`${item.name} range-item`}
          style={{
            width: 100 / _ranges.length + '%'
          }}
        >
          <svg width="100%" height="2" xmlns="http://www.w3.org/2000/svg">
            <line
              x1="0"
              y1="0"
              x2="1000"
              stroke="#E1DEDE"
              strokeWidth="2"
              strokeDasharray="2,2"
            />
          </svg>
          {level === item.name && (
            <>
              <ValueIcon
                color={item.color || '#8A969F'}
                style={getPointExtraStyle(value, item)}
                hideValueNumber={true}
              />
            </>
          )}
        </div>
      ))}
    </CompareLineContainer>
  )

  function getPointExtraStyle(value, range) {
    let left = 50
    let correctionOffset = -50
    if (range.max && range.min) {
      left = ((value - range.min) / (range.max - range.min)) * 100
    }
    if (Math.round(left) === 0) {
      correctionOffset = 0
    } else if (Math.round(left) === 100) {
      correctionOffset = -100
    }
    return {
      left: left + '%',
      transform: `translateX(${correctionOffset}%)`
    }
  }
}
