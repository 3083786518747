import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TouchInput from './touchInput'

class KeyTakeaway extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    update: PropTypes.func
  }

  state = {
    values: []
  }

  componentDidMount() {
    const { initialValue } = this.props
    const values = (initialValue && JSON.parse(initialValue).list) || []
    this.setState({ values })
  }

  save = async (value, index) => {
    const { update } = this.props
    const { values } = this.state
    try {
      if (index || index === 0) {
        value ? values.splice(index, 1, value) : values.splice(index, 1)
      } else {
        value && values.push(value)
      }
      await update(JSON.stringify({ list: values }))
      this.setState({ values })
    } catch (err) {
      console.error(err)
    }
  }

  render() {
    const { update } = this.props
    const { values } = this.state
    return (
      <ul className="key-takeaway">
        {values.map((item, index) => (
          <li key={index} className="key-takeaway-item">
            {update ? (
              <TouchInput
                initialValue={item}
                placeholder="Click To Add Takeaway"
                save={(value) => this.save(value, index)}
              />
            ) : (
              <p
                className="text-section"
                dangerouslySetInnerHTML={{
                  __html: item.replace(/style="(.*?)"/g, '')
                }}
              />
            )}
          </li>
        ))}
        {update && (
          <li className="key-takeaway-item">
            <TouchInput
              placeholder="Click To Add Takeaway"
              save={(value) => this.save(value)}
            />
          </li>
        )}
      </ul>
    )
  }
}

export default KeyTakeaway
