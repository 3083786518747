import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { message, Typography, Button, Modal, Row, Col } from 'antd'
import FileUploadModal from './modal'
import IconDel from '../../../asserts/icon/icon-del.svg'
import IconDownload from '../../../asserts/icon/icon-download.svg'
import IconNoFile from '../../../asserts/icon/no-file.svg'
import { getFiles, deleteFile } from '../../../api/file'
import getFileIcon from '../../../models/fileIcon.model'
import { loadUser } from '../../../utils/storage'
import {
  SimpleUploadIcon,
  DelIcon,
  DownloadIcon,
  FileShareIcon
} from '../../../components/icons'
import { ViewCircleIcon } from '../../../components/icons/weeklySummary'
import { PreviewAbleType } from '../../../utils/common'
import { filesStyle, mobileFilesStyle } from './index.sass'

const confirm = Modal.confirm
const { Paragraph } = Typography

export class FileManager extends Component {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    person: PropTypes.object,
    editable: PropTypes.bool,
    mobile: PropTypes.bool,
    hash: PropTypes.string,
    onlyFile: PropTypes.bool,
    datasetVersions: PropTypes.array,
    fileUploadAsync: PropTypes.func
  }

  state = {
    files: [],
    modalVisible: false,
    useTrayIcon: false,
    showAll: false
  }

  initial = async (type, page = 0) => {
    const { person, hash } = this.props
    if (person) {
      const personId = person.id
      const result = await getFiles(hash, type, personId, page)
      this.setState({ ...result, loading: false })
    }
  }

  addFileSync = () => {
    const { type } = this.props
    const { fileUploadAsync } = this.props
    if ((type === 'bio_marker' || type === 'body_comp') && fileUploadAsync) {
      fileUploadAsync()
    }
    this.initial(type)
  }

  delFiles = async (id, index) => {
    const { person } = this.props
    const { files } = this.state
    try {
      await deleteFile(id, person.id)
      files.splice(index, 1)
      this.setState({ files })
    } catch (err) {
      console.log(err)
      message(err.message)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { type } = this.props
    if (nextProps.type !== type) {
      this.initial(nextProps.type)
    }
  }

  componentDidMount() {
    const { type } = this.props
    this.initial(type)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getDownLoadICon = (attachment) => {
    const fileType = attachment.oss_name.split('.').pop()
    if (PreviewAbleType.includes(fileType)) {
      return (
        <ViewCircleIcon
          style={{ fontSize: 40, color: '#75879D', verticalAlign: 'middle' }}
        />
      )
    } else {
      return <img src={IconDownload} alt="download" />
    }
  }

  renderFileList(files) {
    const { editable, onlyFile, loginRole, person } = this.props
    const { showAll } = this.state
    const showFiles = files.length > 4 && !showAll ? files.slice(0, 4) : files
    return (
      <>
        {showFiles.map((item, index) => (
          <div className="files-list-item" key={index}>
            <div className="file-icon">
              <img
                src={getFileIcon(
                  item.attachment
                    ? item.attachment.name || item.attachment.oss_name
                    : ''
                )}
                alt="file"
              />
              {/* <span>{files.length - index}</span> */}
            </div>
            <div
              className={`name files-item-col ${
                onlyFile ? 'no-test-date' : ''
              } `}
            >
              <span className="item-label"> FILE NAME </span>
              <br />
              {item.attachment ? (
                <Paragraph ellipsis style={{ marginBottom: '0px' }}>
                  <a
                    href={item.attachment.oss_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontSize: '14px' }}
                  >
                    {item.attachment.name || item.attachment.oss_name}
                  </a>
                </Paragraph>
              ) : (
                <span className="text-primary name-label">FILE NAME</span>
              )}
            </div>
            <div className="date  files-item-col">
              {item.test_date && (
                <div>
                  <span className="item-label">Test date</span>
                  <br />
                  <span className="item-label-value">
                    {item.test_date &&
                      moment(item.test_date.substr(0, 10)).format(
                        'MMM DD,YYYY'
                      )}
                  </span>
                </div>
              )}
              <div>
                <span className="item-label">upload date</span>
                <br />
                <span className="item-label-value">
                  {moment(item.created_at).format('MMM DD,YYYY')}
                </span>
              </div>
            </div>
            <div className="operator">
              <span className="item-label">Upload by</span>
              <br />
              <span className="item-label-value">
                {item.uploaded_by === (loadUser() || this.props.person).id
                  ? 'You'
                  : item.created_by}
              </span>
            </div>
            <div className="opr">
              <a
                href={item.attachment.oss_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.getDownLoadICon(item.attachment)}
              </a>
              {editable &&
                (loginRole !== 'User' || item.uploaded_by === person.id) && (
                  <img
                    className="phs-pointer"
                    onClick={() => {
                      const deleteFile = this.delFiles
                      confirm({
                        title: 'Do you want to delete this file?',
                        okText: 'Yes',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk() {
                          deleteFile(item.id, index)
                        }
                      })
                    }}
                    src={IconDel}
                    alt="del"
                  />
                )}
            </div>
          </div>
        ))}
        {files.length > 4 && (
          <div
            className="show-all"
            onClick={() => this.setState({ showAll: !showAll })}
          >
            {showAll ? 'Show less' : 'Show all'}
          </div>
        )}
      </>
    )
  }

  renderFileTray(files) {
    return (
      <>
        {files.map((item, index) => (
          <Col span={6} key={index}>
            <div className="tray-file-item">
              <div className="file-icon ">
                {/* <img src={IconFile} alt="file" /> */}
                <img
                  src={getFileIcon(
                    item.attachment
                      ? item.attachment.name || item.attachment.oss_name
                      : ''
                  )}
                  alt="file"
                />
              </div>

              <div className="file-name">
                {item.attachment ? (
                  <Paragraph ellipsis>
                    <a href={item.attachment.oss_url}>
                      {item.attachment.name || item.attachment.oss_name}
                    </a>
                  </Paragraph>
                ) : (
                  <span className="text-primary name-label">FILE NAME</span>
                )}
              </div>

              <a href={item.attachment.oss_url}>
                <DownloadIcon />
              </a>

              <DelIcon
                onClick={() => {
                  const deleteFile = this.delFiles
                  confirm({
                    title: 'Do you want to delete this file?',
                    okText: 'Yes',
                    okType: 'danger',
                    cancelText: 'No',
                    onOk() {
                      deleteFile(item.id, index)
                    }
                  })
                }}
              />
            </div>
          </Col>
        ))}
      </>
    )
  }

  renderMobileLayout(files) {
    const { showAll } = this.state
    const showFiles = files.length > 4 && !showAll ? files.slice(0, 4) : files
    return (
      <>
        {showFiles.map((item, index) => (
          <div
            className="file-list-container"
            key={index}
            onClick={() => this.clickFile(item)}
          >
            {index === 0 && <div className="file-separator" />}
            <div className="file-container">
              {/* <img src={IconMobileFile} alt="file" className="file-image" /> */}
              <img
                src={getFileIcon(
                  item.attachment
                    ? item.attachment.name || item.attachment.oss_name
                    : ''
                )}
                alt="file"
                className="file-image"
              />
              <div className="file-info-container">
                <span className="file-name">
                  {item.attachment.name || item.attachment.oss_name}
                </span>
                <span className="file-test-date">
                  Test date{' '}
                  {moment(item.test_date.substr(0, 10)).format('MMM DD,YYYY')}
                </span>
              </div>
              <div
                className="file-share-image"
                onClick={() => this.shareFile(item)}
              >
                <FileShareIcon />
              </div>
            </div>
            {index !== showFiles.length - 1 && (
              <div className="file-separator" />
            )}
          </div>
        ))}

        {files.length > 4 && (
          <div
            className="show-all"
            onClick={() => this.setState({ showAll: !showAll })}
          >
            {showAll ? 'Show less' : 'Show all'}
          </div>
        )}
        <div className="section-separator" />
      </>
    )
  }

  clickFile(item) {
    if (window.webkit) {
      window.webkit.messageHandlers.jsBridgeClickFile.postMessage(item)
    }
  }

  shareFile(item) {
    if (window.webkit) {
      window.webkit.messageHandlers.jsBridgeShareFile.postMessage(item)
    }
  }

  render() {
    const {
      title,
      type,
      person,
      editable,
      mobile,
      onlyFile,
      datasetVersions
    } = this.props
    const { files, modalVisible, useTrayIcon } = this.state
    return (
      <div>
        {mobile ? (
          <div>
            {files.length > 0 && (
              <div className={mobileFilesStyle}>
                <span className="section-title">
                  Assessment Files {files.length > 0 && `(${files.length})`}
                </span>
                {this.renderMobileLayout(files)}
              </div>
            )}
          </div>
        ) : (
          <div className={`phs-section ${filesStyle}`}>
            <div className="phs-section-body">
              <h2>
                <span className="title">
                  {title} {files.length > 0 && `(${files.length})`}
                </span>
                {files.length > 0 && editable && (
                  <div className="right-opr">
                    {/* <span className="oprate">
                      {!onlyFile &&
                        (useTrayIcon ? (
                          <span
                            onClick={() =>
                              this.setState({ useTrayIcon: false })
                            }
                          >
                            <BarsIcon /> more detail
                          </span>
                        ) : (
                          <span
                            onClick={() => this.setState({ useTrayIcon: true })}
                          >
                            <TrayIcon /> use tray icon
                          </span>
                        ))}
                    </span> */}
                    <Button
                      type="primary upload"
                      onClick={() => this.setState({ modalVisible: true })}
                    >
                      <SimpleUploadIcon style={{ fontSize: 16 }} />
                      UPLOAD
                    </Button>
                  </div>
                )}
              </h2>
              {files.length > 0 ? (
                useTrayIcon ? (
                  <Row
                    className="tray-file-container"
                    type="flex"
                    justify="start"
                  >
                    {this.renderFileTray(files)}
                  </Row>
                ) : (
                  this.renderFileList(files)
                )
              ) : (
                <div className="files-null-state">
                  <img src={IconNoFile} alt="no file" />
                  <p>No file has been uploaded yet</p>
                  {editable && (
                    <Button
                      type="primary"
                      className="upload-btn"
                      onClick={() => this.setState({ modalVisible: true })}
                    >
                      <SimpleUploadIcon style={{ fontSize: 16 }} />
                      UPLOAD NOW
                    </Button>
                  )}
                </div>
              )}
              {modalVisible && (
                <FileUploadModal
                  type={type}
                  personId={person.id}
                  firstName={person.first_name}
                  lastName={person.last_name}
                  close={() => this.setState({ modalVisible: false })}
                  addFileSync={this.addFileSync}
                  onlyFile={onlyFile}
                  datasetVersions={datasetVersions}
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default FileManager
