import React, { useState } from 'react'
import _ from 'lodash'

// import React from 'react'
import { Divider, Input } from 'antd'

import styled from 'styled-components'
// import { Divider } from 'antd'
import Information from '../../../components/UI/extraInformation'
import BodyStatus from './body'
import { RangeColors } from '../../../utils/constant'
import HealthCard from '../../../components/healthScore/card'
import RangeBar from '../../../components/assessmentReview/comp/rangeBar'
import HideWrap from '../../../components/assessmentReview/comp/hideWrap'

export const Container = styled.section`
  .ant-divider-horizontal {
    margin: 32px 0;
  }
  .tags {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #5a657c;
    display: flex;
    justify-content: flex-end;
    margin-top: -47px;
    padding-bottom: 22px;
    span {
      padding: 4px 20px;
      border: 1px solid rgba(33, 57, 89, 0.2);
      border-radius: 19px;
      margin-left: 16px;
    }
    span:before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 6px;
    }
    span:first-child:before {
      background: #7fb800;
    }
    span:last-child:before {
      background: #0d2350;
    }
  }
  .range-list {
    display: flex;
    list-style: none;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: rgba(50, 58, 70, 0.5);
    margin: 0;
    li {
      padding-left: 24px;
    }
    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
  .health-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 40px;
    .label {
      white-space: pre-line;
      font-size: 15px;
    }
    .value {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #4c6072;
      .valid {
        font-size: 12px;
        color: red;
      }
      input {
        background: #edf0f4;
        border-radius: 4px;
        text-align: right;
      }
      span {
        width: 60px;
        display: inline-block;
        margin-right: 20px;
        text-align: right;
      }
    }
  }
  .health-data:nth-child(2n) {
    background: rgba(241, 243, 245, 0.3);
  }
  .body-systems {
    margin-top: 40px;
  }
`

const ranges = [
  {
    name: 'at risk',
    color: RangeColors.red //'#EA4D00'
  },
  {
    name: 'concern',
    color: RangeColors.orange //'#FF9548'
  },
  {
    name: 'needs monitoring',
    color: RangeColors.yellow //'#FFB800'
  },
  {
    name: 'optimized',
    color: RangeColors.green //'#40C47C'
  }
]

export default function Cardiometabolic(props) {
  const {
    hideSectionHandle,
    hideSection,
    previous,
    latest,
    dynamicData,
    view,
    handleSave,
    loginRole,
    handleCommendSave,
    save,
    onScoreChangeHandle,
    cancelEdit,
    person,
    updateEditingCount,
    id,
    arDetail: { notes, status, detail }
  } = props

  const data = {
    name: 'Cardiometabolic',
    type: 'cardiometabolic',
    description:
      'Your score emcompasses the health of your heart, blood vessels, and metabolism. It considers your risk for developing heart disease and Type 2 diabetes, two prevalent chronic diseases.',
    ...latest.snapshot.cardiometabolic
  }

  const compare = {
    name: 'Cardiometabolic',
    type: 'cardiometabolic',
    description:
      'Your score emcompasses the health of your heart, blood vessels, and metabolism. It considers your risk for developing heart disease and Type 2 diabetes, two prevalent chronic diseases.',
    ...previous.snapshot.cardiometabolic
  }

  const [validScore, setValidScore] = useState(true)
  const [scoreEditing, setScoreEditing] = useState(
    status !== 1 && !(detail && detail.ASCVD) && view !== 'Preview'
  )
  const [metsScoreEditing, setMetsScoreEditing] = useState(
    status !== 1 &&
      !(detail && _.isNumber(detail.mets_score)) &&
      view !== 'Preview'
  )

  // const metsScore =
  //   dynamicData.mets_z_wc || dynamicData.mets_z_bmi || dynamicData.metsScore
  function getCalculateScore() {
    const percent =
      detail &&
      detail.mets_score &&
      100 *
        (1 /
          (1 +
            Math.exp(
              -0.07056 * Math.pow(detail.mets_score, 3) -
                1.5976 * detail.mets_score
            )))
    // dynamicData.mets_z_wc
    //   ? dynamicData.metsPercent
    //   : dynamicData.metBMIPercent
    if (
      _.isFinite(percent) &&
      dynamicData.lipidScore &&
      detail &&
      detail.ASCVD
    ) {
      return Math.round(
        0.2 * detail.ASCVD +
          0.4 * (100 - percent) +
          0.4 * dynamicData.lipidScore
      )
    } else {
      return '--'
    }
  }

  const healthData = [
    {
      label: `Atherosclerotic & Cardiovascular
        Disease Score`,
      value: detail && detail.ASCVD,
      ranges: {
        at_risk: [-Number.MAX_VALUE, 80],
        concern: [80, 90],
        needs_monitoring: [90, 95],
        optimized: [95, Number.MAX_VALUE]
      },
      inputValue: scoreEditing ? (
        <>
          <Input
            placeholder={detail && detail.ASCVD}
            defaultValue={detail ? detail.ASCVD : null}
            autoFocus={true}
            onKeyUp={(e) => {
              const ASCVD = Number(e.target.value)
              setValidScore(true)
              if (!ASCVD || ASCVD > 100) {
                setValidScore(false)
              } else if (e.keyCode === 13) {
                handleSave({ detail: { ASCVD } })
                setScoreEditing(false)
                detail.ASCVD = ASCVD
              }
            }}
          />
          {!validScore && <span className="valid">Invalid</span>}
        </>
      ) : (
        <span
          onClick={() =>
            status !== 1 && view !== 'Preview' && setScoreEditing(true)
          }
        >
          {(detail && detail.ASCVD) || '--'}
        </span>
      )
    },
    {
      label: 'Metabolic Syndrome Severity Score',
      value: detail && detail.mets_score, //_.isFinite(metsScore) ? Math.round(metsScore * 100) / 100 : '--',
      ranges: {
        at_risk: {
          range: [1, Number.MAX_VALUE],
          label: '+1 or higher'
        },
        concern: {
          range: [0, 1],
          label: '0 to +1'
        },
        needs_monitoring: {
          range: [-1, 0],
          label: '0 to -1'
        },
        optimized: {
          range: [-Number.MAX_VALUE, -1],
          label: '-1 or lower'
        }
      },
      inputValue: metsScoreEditing ? (
        <>
          <Input
            placeholder={detail && detail.mets_score}
            defaultValue={detail ? detail.mets_score : null}
            autoFocus={true}
            onKeyUp={(e) => {
              const mets_score = Number(e.target.value)
              // setValidScore(true)
              // if (!ASCVD || ASCVD > 100) {
              //   setValidScore(false)
              // } else
              if (e.keyCode === 13) {
                handleSave({ detail: { mets_score } })
                setMetsScoreEditing(false)
                detail.mets_score = mets_score
              }
            }}
          />
          {/* {!validScore && <span className="valid">Invalid</span>} */}
        </>
      ) : (
        <span
          onClick={() =>
            status !== 1 && view !== 'Preview' && setMetsScoreEditing(true)
          }
        >
          {detail && _.isNumber(detail.mets_score) ? detail.mets_score : '--'}
        </span>
      )
    },
    {
      label: 'Apeiron Advanced Lipid Score',
      value: dynamicData.lipidScore || '--',
      ranges: {
        at_risk: [-Number.MAX_VALUE, 80],
        concern: [80, 90],
        needs_monitoring: [90, 95],
        optimized: [95, Number.MAX_VALUE]
      }
    },
    {
      label: 'Apeiron Total Cardiometabolic Score',
      value: getCalculateScore(),
      ranges: {
        at_risk: [-Number.MAX_VALUE, 80],
        concern: [80, 90],
        needs_monitoring: [90, 95],
        optimized: [95, Number.MAX_VALUE]
      }
    }
  ]

  const cardio = notes.find((item) => item.pillar === 'cardio')
  const body = notes.find((item) => item.pillar === 'body')
  const cardioLevel = getScore(
    data.score_manual ? data.score_manual.value : data.score_auto
  )
  return (
    <HideWrap
      id={id}
      name="Cardiometabolic Health"
      isSection={true}
      syncStatus={hideSectionHandle}
      status={hideSection}
      view={view}
    >
      <Container>
        <h1 className="section-title">Cardiometabolic Health</h1>
        <HideWrap
          name="Cardiometabolic Health - Summary"
          title="Summary"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <div className="sub-title">Summary</div>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={cardio ? cardio.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'cardio', status)
            }
            updateEditingCount={updateEditingCount}
          />
        </HideWrap>
        <Divider />
        {!dynamicData.ui_version && (
          <>
            <HideWrap
              name="Cardiometabolic Health - Cardiovascular And Metabolic Health"
              title="Cardiovascular And Metabolic Health"
              syncStatus={hideSectionHandle}
              status={hideSection}
              view={view}
            >
              <div className="sub-title">
                <span>Cardiovascular and Metabolic Health</span>
              </div>
              <div className="sub-title">
                <span></span>
                <ul className="range-list">
                  {ranges.map((item, index) => (
                    <li key={index}>
                      <span style={{ background: item.color }} /> {item.name}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="health-data-container">
                {healthData.map((item, index) => (
                  <div key={index} className="health-data">
                    <div className="label">{item.label}</div>
                    <div className="value">
                      <span>{item.inputValue || item.value}</span>
                      <RangeBar ranges={ranges} data={item} />
                    </div>
                  </div>
                ))}
              </div>
            </HideWrap>
            <Divider />
          </>
        )}

        <HideWrap
          name="Cardiometabolic Health - Health Risk Body Systems"
          title="Health Risk Body Systems"
          syncStatus={hideSectionHandle}
          status={hideSection}
          view={view}
        >
          <div className="sub-title"> Health Risk Body Systems</div>
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            initialValue={body ? body.memo : ''}
            updateCommend={(value) => handleCommendSave(value, 'body')}
            updateEditingCount={updateEditingCount}
          />
          <div className="body-systems">
            <BodyStatus
              data={dynamicData.overviewBodySystems}
              cardioLevel={cardioLevel}
              person={person}
            />
          </div>
        </HideWrap>
      </Container>
    </HideWrap>
  )
}

function getScore(score) {
  let status
  if (!score && score !== 0) {
    status = null
  } else if (score < 80) {
    status = 'At risk'
  } else if (score >= 80 && score < 90) {
    status = 'Concern'
  } else if (score >= 90 && score < 95) {
    status = 'Needs Monitoring'
  } else if (score >= 95) {
    status = 'Optimal'
  }
  return status
}
