import axios, { hashRequest } from './axios'

export const editBiomarker = (id, params) =>
  axios.put(`/bio_markers/${id}`, params)

export const getBiomarkerById = async (id, hash) => {
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: `/bio_markers/${id}`
    })
    result = result.data
  } else {
    result = await axios.get(`/bio_markers/${id}`)
  }
  return result.bio_marker
}

export const createBiomarker = (biomarker) =>
  axios.post('/bio_markers', biomarker)

export const deleteBiomarker = (id) => axios.delete(`/bio_markers/${id}`)

export const getBloodTestsHistory = async (biomarker_id, person_id, hash) => {
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: `/bio_markers/${biomarker_id}/history?person_id=${person_id}&limit=8`
    })
    return result.data
  } else {
    return axios.get(
      `/bio_markers/${biomarker_id}/history?person_id=${person_id}&limit=8`
    )
  }
}

export const reOrderBiomarker = async (params) =>
  axios.putJson('/bio_markers/order', params)

export const reOrderCategory = async (params) =>
  axios.put('/bio_markers/categories/order', params)

export const reOrderRootCategory = async (params) =>
  axios.put('/bio_markers/root_categories/order', params)
