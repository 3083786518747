import moment from 'moment'
import axios from './axios'

export const getPartnershipSessions = () =>
  axios.get('/landing/sessions?partnership=bay_club')

export const exportPartnershipSessions = () =>
  axios.get(
    `landing/export?partnership=bay_club&zero_unix=${moment()
      .startOf('day')
      .unix()}`
  )
