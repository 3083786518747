import * as actionType from './action-type'

export const setSubMenu = (type, categories) => {
  return {
    type: actionType.SET_SLIDE_SUB_MENU,
    payload: { [type]: categories }
  }
}

export const setCurrentCategory = (category) => {
  return {
    type: actionType.SET_SLIDE_SUB_MENU,
    payload: { currentCategory: category }
  }
}
