import React from 'react'
import { connect } from 'react-redux'
import EmailList from '../../../components/summaryEmail/emailList'
import { userType } from '../../../utils/constant'
import LockStatus from '../../../components/UI/lockStatus'
import { summaryEmailStyle } from './index.sass'

function SummaryEmailList(props) {
  const isPartner =
    props.account.user.profile.account_type === userType.Partners
  return (
    <div className={summaryEmailStyle}>
      <h1 className="title">Weekly Summary</h1>
      {isPartner ? (
        <LockStatus />
      ) : (
        <EmailList
          person={props.account.user}
          loginRole="User"
          linkToWeeklySummary={(email) => {
            if (email.new_format) {
              props.history.push(`/app/summary-email/${email.id}`)
            } else {
              props.history.push(`/app/summary-email/backup/${email.id}`)
            }
          }}
        />
      )}
    </div>
  )
}

export default connect(
  ({ account }) => ({ account }),
  null
)(SummaryEmailList)
