import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Modal, Button, Radio } from 'antd'
import IconExport from '../../../asserts/icon/icon-clients-data-export.svg'
import BasicForm from '../../BasicForm'
import { exportClientsDataModelStyle } from './index.sass'
import { getExportReport } from '../../../api/team'

const RadioGroup = Radio.Group

export class ExportClientsDataModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    assessment: PropTypes.array
  }

  state = {
    exportLoading: false,
    exportObject: 'all',
    title: 'Export Ad Hoc Reports',
    status: 'select'
  }

  export = async () => {
    const { exportObject } = this.state
    let person_id
    if (exportObject === 'specific') {
      const params = await this.basicFormRef.handleSubmit()
      person_id = params['person_id']
    }
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    this.setState({ exportLoading: true, source })
    try {
      const result = await getExportReport(source, person_id, exportObject)
      window.open(result.url || result.data.url)
      this.props.close()
      // this.setState({ status: 'succeed' })
    } catch (err) {
      console.error(err)
      this.setState({ exportLoading: false })
    }
  }

  cancel = () => {
    const { close } = this.props
    const { source } = this.state
    if (source) {
      source.cancel()
    }
    close()
  }

  selectExportMethod = (e) => {
    this.setState({ exportObject: e.target.value })
  }

  render() {
    const { assessment } = this.props
    const { exportLoading, exportObject, status } = this.state
    return (
      <Modal
        width={600}
        height={380}
        className={exportClientsDataModelStyle}
        maskClosable={false}
        visible={true}
        onCancel={this.cancel}
        footer={
          status === 'select' ? (
            <div className="modal-footer">
              <Button type="link" onClick={this.cancel} className="cancel">
                CANCEL
              </Button>
              <Button
                type="primary"
                onClick={() => this.export()}
                loading={exportLoading}
              >
                {!exportLoading && <img src={IconExport} alt="export" />}
                DOWNLOAD
              </Button>
            </div>
          ) : (
            <div className="modal-footer">
              <Button type="primary" onClick={this.cancel}>
                OK
              </Button>
            </div>
          )
        }
      >
        {status === 'select' ? (
          <>
            <h1 className="title-center">Export Ad Hoc Reports</h1>
            <RadioGroup
              onChange={this.selectExportMethod}
              value={exportObject}
              className="radio-group-container"
            >
              <Radio value="all">Download data for all users</Radio>
              <Radio value="in_data_aggregation">
                Download for all users included in data aggregation
              </Radio>
              <Radio value="specific">Download data for specific user</Radio>
            </RadioGroup>
            {exportObject === 'specific' && (
              <BasicForm
                formData={[
                  {
                    type: 'select',
                    label: 'SELECT USER',
                    dbField: 'person_id',
                    options: assessment.map((item) => {
                      return {
                        label: `${(item.profile && item.profile.nickname) ||
                          item.first_name} ${item.last_name}`,
                        value: item.id
                      }
                    }),
                    rules: [{ required: true, message: 'Must select a user' }],
                    extraAttr: {
                      showSearch: true,
                      optionFilterProp: 'children',
                      filterOption: (input, option) => {
                        return (
                          option.props.children
                            .join()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        )
                      }
                    }
                  }
                ]}
                onChange={(changedFields, form) => {
                  // this.updateFormsData(changedFields)
                }}
                wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
              />
            )}
          </>
        ) : (
          <>
            <h1 className="title-center">
              Preparing your client export ad hoc report
            </h1>
            <p>
              We need a moment to prepare your report. Once ready, we will email
              you a download link.
            </p>
          </>
        )}
      </Modal>
    )
  }
}

export default ExportClientsDataModal
