import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Upload, Button, Checkbox, Collapse } from 'antd'
import BasicForm from '../formV4'
import PolygonAvatar from '../../components/UI/polygonAvatar'
import { parseInch, formatInch, getRole } from '../../utils/common'
import { getClient, ethnicities } from '../../models/client.form' //client
import { createClient, editClient, resetPassword } from '../../api/team'
import { saveAvatar } from '../../api/people'
import IconCamera from '../../asserts/icon/icon-camera.svg'
import TipCamera from '../../asserts/images/camera-tip.svg'
import { userType } from '../../utils/constant'
import _ from 'lodash'
import moment from 'moment'
import { loadUser, saveUser } from '../../utils/storage'

const { Panel } = Collapse

export class ClientForm extends Component {
  static propTypes = {
    initValue: PropTypes.object,
    onCancel: PropTypes.func,
    submitSync: PropTypes.func,
    loginRole: PropTypes.string
  }

  state = {
    loading: false,
    sendLoading: false,
    file: null,
    person: null,
    valueForm: {}
  }

  submit = async () => {
    const { submitSync, initValue } = this.props
    const { file, valueForm } = this.state
    this.setState({ loading: true })
    try {
      const clientParams = await this.refForm.handleSubmit()
      const additionalParams = await this.refAdditionalForm.handleSubmit()

      const params = _.defaultsDeep(clientParams, additionalParams)
      if (!params.profile.gender) {
        params.profile.gender = 'male'
      }
      if (!params.profile.notes) {
        params.profile.notes = null
      }
      if (!params.profile.nickname) {
        params.profile.nickname = null
      }
      if (!params.profile.executive_assistant_email) {
        params.profile.executive_assistant_email = null
      }
      if (!params.profile.trainer_email) {
        params.profile.trainer_email = null
      }
      if (!params.profile.max_heart_rate) {
        params.profile.max_heart_rate = null
      }
      let result, syncType, person, successMsg
      const { height_ft, height_in } = params.profile
      if (height_ft || height_in) {
        params.profile.height_inch = parseInch(height_ft, height_in)
      }
      delete params.profile.height_ft
      delete params.profile.height_in

      if (params.profile.ethnicity === 'Other') {
        params.profile.ethnicity = params.other_ethnicity
        delete params.other_ethnicity
      }

      if (params.profile.client_since === '') {
        params.profile.client_since = null
      }

      // account type
      params.profile.account_type = parseInt(params.profile.account_type)
      params.profile.payment_status = parseInt(params.profile.payment_status)

      // open to ilabs
      params.profile.open_to_ilabs = valueForm.profile.open_to_ilabs || false

      if (initValue) {
        result = await editClient(params, initValue.id)
        syncType = 'update'
        successMsg = 'Profile updated'
      } else {
        params.current_date = moment().format('YYYY-MM-DD')
        result = await createClient(params)
        syncType = 'create'
        successMsg = 'Created a new client'
      }
      person = result.team.person

      if (file) {
        result = await saveAvatar(file, result.team.person.id)
        person = result.person || result.data.person
      }

      const account = loadUser()
      if (account.id === person.id) {
        saveUser(person)
      }

      this.setState({ person, loading: false })
      submitSync(person, syncType)
      message.success(successMsg)
    } catch (err) {
      console.error(err)
      // if (err.statusCode) {
      //   message.error(err.message)
      // }
      this.setState({ loading: false })
    }
  }

  cancel = () => {
    const { onCancel } = this.props
    this.refForm && this.refForm.resetFields()
    this.refAdditionalForm && this.refAdditionalForm.resetFields()
    onCancel()
  }

  sendEmail = async () => {
    const { initValue } = this.props
    const { person } = this.state

    const id = (initValue && initValue.id) || person.id
    try {
      this.setState({ sendLoading: true })
      await resetPassword(id)
      this.setState({ sendLoading: false })
      message.success('success!')
    } catch (err) {
      console.error(err)
      message.error(err.message)
      this.setState({ sendLoading: false })
    }
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  customRequest = (request) => {
    const { file } = request
    this.getBase64(file, (imageUrl) =>
      this.setState({
        imageUrl,
        file: file
      })
    )
  }

  updateValueForm = async (changedValues) => {
    if (!this.refForm || !this.refAdditionalForm) return
    const { valueForm } = this.state
    if (changedValues.profile) {
      valueForm.profile = valueForm.profile || {}
      const profile = changedValues.profile
      if (profile.account_type && !valueForm.id) {
        if (profile.account_type === '2') {
          valueForm.profile.payment_status = '1'
          valueForm.profile.open_to_ilabs = true
          this.refAdditionalForm.setFieldValue(
            ['profile', 'payment_status'],
            '1'
          )
        } else {
          valueForm.profile.payment_status = '0'
          valueForm.profile.open_to_ilabs = false
          this.refAdditionalForm.setFieldValue(
            ['profile', 'payment_status'],
            '0'
          )
        }
        if (profile.account_type === '2' || profile.account_type === '3') {
          valueForm.profile.in_data_aggregation = false
          this.refAdditionalForm.setFieldValue(
            ['profile', 'in_data_aggregation'],
            false
          )
        } else {
          valueForm.profile.in_data_aggregation = true
          this.refAdditionalForm.setFieldValue(
            ['profile', 'in_data_aggregation'],
            true
          )
        }
      }
      _.assign(valueForm.profile, changedValues.profile)
    } else {
      _.assign(valueForm, changedValues)
    }
    this.setState({
      valueForm: _.cloneDeep(valueForm)
    })
  }

  otherEthnicityValidator = (rule, value, callback) => {
    const { valueForm } = this.state
    if (!value && valueForm.profile.ethnicity === 'Other') {
      callback('Please input your ethnicity')
    }
    callback()
  }

  componentDidMount() {
    const { initValue } = this.props
    let valueForm
    if (initValue) {
      valueForm = Object.assign({}, initValue)
      valueForm.profile = Object.assign({}, initValue.profile)
      valueForm.profile.account_type = valueForm.profile.account_type.toString()
      valueForm.profile.payment_status = valueForm.profile.payment_status.toString()
    } else {
      valueForm = {
        profile: {
          account_type: '-1',
          payment_status: '0',
          discount: 'no',
          in_data_aggregation: true
        }
      }
    }
    if (
      valueForm.profile.height_inch &&
      (!valueForm.profile.height_ft || !valueForm.profile.height_in)
    ) {
      const { height_ft, height_in } = formatInch(valueForm.profile.height_inch)
      valueForm.profile.height_ft = height_ft
      valueForm.profile.height_in = height_in
    }

    const ethnicity = valueForm.profile.ethnicity
    const notOther = ethnicities.find((item) => item.value === ethnicity)
    if (ethnicity && !notOther) {
      valueForm.profile.ethnicity = 'Other'
      valueForm.other_ethnicity = ethnicity
    }

    if (valueForm.profile.client_since) {
      valueForm.profile.client_since = valueForm.profile.client_since.substr(
        0,
        10
      )
    }
    if (valueForm.profile.date_of_birth) {
      valueForm.profile.date_of_birth = valueForm.profile.date_of_birth.substr(
        0,
        10
      )
    }

    this.setState({ valueForm })
  }

  render() {
    const {
      initValue,
      editEmail,
      sendMemeberShip,
      loginRole,
      onDelete
    } = this.props
    const { loading, imageUrl, sendLoading, person, valueForm } = this.state
    const accountRole = getRole((initValue || {}).role)
    const avatar =
      imageUrl || (initValue && initValue.avatar && initValue.avatar.oss_url)
    const { clientInfo, additionalInfo } = getClient(
      this.otherEthnicityValidator,
      valueForm
    )
    const openToiLabs = _.get(valueForm, 'profile.open_to_ilabs', false)
    const clientAccountType = _.get(
      initValue,
      'profile.account_type',
      userType.Test_User
    )
    return (
      <div>
        <Modal
          open={true}
          onCancel={this.cancel}
          className="phs-modal client-creation"
          width={880}
          footer={
            <>
              {/* <Button onClick={this.cancel}>CANCEL</Button> */}
              {(initValue || person) &&
                (loginRole === 'Expert' ||
                  clientAccountType === userType.Test_User) && (
                  <>
                    {accountRole !== 'Expert' && (
                      <Button danger onClick={() => onDelete()}>
                        DELETE
                      </Button>
                    )}
                    <Button
                      onClick={this.sendEmail}
                      type="primary"
                      ghost
                      loading={sendLoading}
                      style={{ width: 170 }}
                    >
                      RESET PASSWORD
                    </Button>
                    {accountRole !== 'Expert' && (
                      <>
                        <Button
                          onClick={editEmail}
                          type="primary"
                          ghost
                          loading={sendLoading}
                          style={{ width: 200 }}
                        >
                          SEND WELCOME EMAIL
                        </Button>
                        <Button
                          onClick={sendMemeberShip}
                          type="primary"
                          ghost
                          loading={sendLoading}
                          style={{ width: 200 }}
                        >
                          SEND MEMBERSHIP PAGE
                        </Button>
                      </>
                    )}
                  </>
                )}
              {(loginRole !== 'InternalAdmin' ||
                clientAccountType === userType.Test_User) && (
                <Button onClick={this.submit} type="primary" loading={loading}>
                  {initValue ? 'UPDATE' : 'CREATE'}
                </Button>
              )}
            </>
          }
        >
          <h1 className="title">
            {' '}
            {initValue ? 'Edit Client' : 'Add New Client'}{' '}
            {initValue && initValue.id && (
              <span style={{ fontSize: 16, opacity: 0.5 }}>
                (id: {initValue.id})
              </span>
            )}
          </h1>
          <div className="upload-avatar">
            {avatar ? (
              <div>
                <PolygonAvatar
                  innerId="edit_avatar_inner"
                  width={100}
                  image={avatar}
                  hexId="edit_avatar_hex"
                />
                <Upload
                  name="avatar"
                  customRequest={this.customRequest}
                  showUploadList={false}
                  className="edit-avatar"
                >
                  <img src={TipCamera} alt="tip" />
                </Upload>
              </div>
            ) : (
              <Upload
                name="avatar"
                customRequest={this.customRequest}
                showUploadList={false}
              >
                <PolygonAvatar
                  icon={IconCamera}
                  width={100}
                  iconX={37}
                  iconY={43}
                  innerId="add_avatar"
                />
              </Upload>
            )}
            {loginRole === 'Expert' && (
              <Checkbox
                checked={!openToiLabs}
                onChange={(e) =>
                  this.updateValueForm({
                    profile: {
                      open_to_ilabs: !e.target.checked
                    }
                  })
                }
                style={{ marginLeft: '80px' }}
              >
                Hide to non-AL
              </Checkbox>
            )}
          </div>
          {!_.isEmpty(valueForm) && (
            <>
              <div style={{ marginBottom: '30px' }}>
                <BasicForm
                  wrappedComponentRef={(inst) => (this.refForm = inst)}
                  initialValues={valueForm}
                  formData={clientInfo}
                  onChange={this.updateValueForm}
                />
              </div>

              <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header="Additional Information" key="1">
                  <BasicForm
                    wrappedComponentRef={(inst) =>
                      (this.refAdditionalForm = inst)
                    }
                    initialValues={valueForm}
                    formData={additionalInfo}
                    onChange={this.updateValueForm}
                  />
                </Panel>
              </Collapse>
            </>
          )}
        </Modal>
      </div>
    )
  }
}

export default ClientForm
