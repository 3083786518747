import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, message } from 'antd'
import RichText from '../../richText'

export class TouchInput extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    save: PropTypes.func,
    editType: PropTypes.string
  }

  state = {
    editing: false,
    value: '',
    style: null
  }
  removeHTMLTag = (str) => {
    str = str.replace(/<\/?[^>]*>/g, '')
    str = str.replace(/[ | ]*\n/g, '\n')
    str = str.replace(/\n[\s| | ]*\r/g, '\n')
    str = str.replace(/&nbsp;/gi, '')
    return str
  }

  save = async () => {
    const { value } = this.state
    const realValue = this.removeHTMLTag(value)
    try {
      if (!realValue.trim()) {
        await this.props.save('')
      } else {
        await this.props.save(value)
      }
      this.setState({ editing: false, value: '' })
    } catch (err) {
      console.error(err)
      message(err.message)
    }
  }

  render() {
    const { editing, value } = this.state
    const { initialValue, placeholder } = this.props
    return (
      <div style={{ marginBottom: '10px' }}>
        {!editing ? (
          <div
            onClick={() =>
              this.setState({ value: initialValue, editing: true })
            }
          >
            {value || initialValue ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: (value || initialValue).replace(
                    /font-family(.*?);/g,
                    ''
                  )
                }}
              />
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </div>
        ) : (
          <>
            <RichText
              value={value}
              placeholder={placeholder}
              onChange={(value) =>
                this.setState({
                  value
                })
              }
              style={{ marginBottom: '15px' }}
            />
            <div style={{ height: '15px' }} />
            <Button type="primary" onClick={this.save}>
              Save
            </Button>
            <Button
              onClick={() =>
                this.setState({ editing: false, value: initialValue })
              }
              style={{ marginLeft: '15px' }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    )
  }
}

export default TouchInput
