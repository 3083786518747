// const {loadUser}

export default {
  users: [],
  clientUsers: [],
  isConnect: false,
  profile: null,
  profileSB: null,
  clinetSB: null
}
