import React from 'react'
import { sumReduce } from '../common'
import { SvgText } from '../../icons/weeklySummary'

import { acsmBreakDownStyle } from './styles/acsmBreakDown.sass'

function processComponentWidth(data) {
  const maxPosition = 68
  const maxDuration = Math.max.apply(
    null,
    data.map((item) => Math.max(item.duration, item.ideal))
  )
  return data.map((item) => {
    let maxItemDuration = Math.max(item.duration, item.ideal)
    return {
      backgroundPosition: (maxItemDuration / maxDuration) * maxPosition + '%',
      durationPosition: (item.duration / maxItemDuration) * 100 + '%',
      minimumPosition: (item.minimum / maxItemDuration) * 100 + '%'
    }
  })
}

function processData(exercise) {
  if (exercise) {
    let totalDuration =
      (exercise.heart_rate && exercise.heart_rate.map((item) => item.zones)) ||
      []
    if (totalDuration.length === 0) {
      totalDuration = [0, 0, 0, 0]
    }

    const zonesDuration = exercise.totalZones
      ? exercise.totalZones.map((duration) => Math.round(duration / 60))
      : [0, 1, 2, 3].map((e) => {
          return totalDuration
            .map((duration) => Math.round(duration[e] / 60))
            .reduce(sumReduce, 0)
        })
    let data = [
      { color: '#F7AD69', zoneDesc: 'Easy - Z2 ' },
      { color: '#FB9043', zoneDesc: 'Aerobic - Z3 ' },
      { color: '#FC7558', zoneDesc: 'Threshold - Z4 ' },
      { color: '#FF501A', zoneDesc: 'Maximum - Z5 ' }
    ]
      .map((zone, index) => {
        return {
          color: zone.color,
          percentage: Math.round(
            (exercise.current_acsm_percentage &&
              exercise.current_acsm_percentage[index]) ||
              0
          ),
          duration: zonesDuration[index],
          target: exercise.acsm_target[index],
          zoneDesc: zone.zoneDesc + exercise.heart_rate_zone_definition[index],
          minimum: exercise.totalZones
            ? exercise.acsm_target[index]
            : exercise.acsm_target[index] * 0.125,
          ideal: exercise.totalZones
            ? exercise.acsm_target[index]
            : exercise.acsm_target[index] * 0.25
        }
      })
      .reverse()
    return data
  } else {
    return []
  }
}

export default function ACSMHeartTargetBreakDown(props) {
  const data = processData(props.exercise)
  const position = processComponentWidth(data)
  return (
    <div className={acsmBreakDownStyle}>
      {data.map((item, index) => (
        <div key={index} className="zone-breakdown-container">
          <div
            className="zone-background"
            style={{
              width: position[index].backgroundPosition
            }}
          >
            <div
              className="zone"
              style={{
                backgroundColor: item.color,
                width: position[index].durationPosition
              }}
            />
          </div>
          <div className="zone-data-container">
            <div className="zone-data">
              <SvgText
                height={18}
                width={180}
                text={`${item.duration || 0}${'m ('}${item.percentage ||
                  0}${'%)'}`}
                textAttr={{
                  fontWeight: 'bold',
                  letterSpacing: '0.5px',
                  fontSize: 12,
                  textAnchor: 'satrt',
                  x: 0,
                  y: 10
                }}
              />
            </div>
            <div className="zone-desc">
              <SvgText
                height={18}
                width={180}
                text={item.zoneDesc}
                fontFamily="Gilroy"
                textAttr={{
                  fontWeight: 'normal',
                  letterSpacing: '0.5px',
                  fontSize: 12,
                  textAnchor: 'satrt',
                  fill: '#a6b0b9',
                  x: 0,
                  y: 10
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
