import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider, Card, Row, Col } from 'antd'
import { ArrowRightIcon } from '../../../components/icons/facility'

import { Pluralize } from '../../../utils/common'

import { resourceStyle } from './index.sass'
import Learning1 from '../../../asserts/images/placeholder/learning1.png'
import Learning2 from '../../../asserts/images/placeholder/learning2.png'
import Learning3 from '../../../asserts/images/placeholder/learning3.png'
import Exercise from '../../../asserts/images/placeholder/resource-exercise.png'
import Rejuvenation from '../../../asserts/images/placeholder/resource-rejuvenation.png'
import Nutrition from '../../../asserts/images/placeholder/resource-nutrition.png'

// const { Option } = Select
const { Meta } = Card

const dataList = [
  {
    type: 'Exercise',
    title: 'Foundational Plan: Exercise',
    readTime: '10 mins read',
    thumbUrl: Learning2,
    link:
      'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Exercise.pdf'
  },
  {
    type: 'Rejuvenation',
    title: 'Foundational Plan: Sleep',
    readTime: '8 mins read',
    thumbUrl: Learning3,
    link:
      'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Sleep.pdf'
  },
  {
    type: 'Nutrition',
    title: 'Foundational Plan: Nutrition',
    readTime: '5 mins read',
    thumbUrl: Learning1,
    link:
      'https://public-source.s3-us-west-2.amazonaws.com/learning_center/Apeiron+Life+Foundation+Plan+-+Nutrition.pdf'
  }
]

const categories = [
  { name: 'Exercise', bg: Exercise },
  { name: 'Rejuvenation', bg: Rejuvenation },
  { name: 'Nutrition', bg: Nutrition }
]

class Resource extends Component {
  static propTypes = {}

  state = {
    activType: 'All'
  }

  getReadNumber = (name) => {
    const { list } = this.props
    const number = list.filter(
      (item) => item.content_category.category === name.toLowerCase()
    ).length

    return number + ' ' + Pluralize('articles', number || 1)
  }

  render() {
    const { activType } = this.state
    let list = dataList
    if (activType !== 'All') {
      list = dataList.filter((item) => item.type === activType)
    }
    return (
      <div className={resourceStyle}>
        <h1 className="title">Resources </h1>
        <div>
          Find answers to your wellness and performance questions, along with
          tips from our experts.
        </div>
        <Divider />
        <div className="select-container">
          <Row className="resource-list" gutter={24}>
            {categories.map((item, index) => (
              <Col
                span={8}
                key={index}
                onClick={() =>
                  this.props.history.push(`/app/resource/${item.name}`)
                }
              >
                <Card
                  hoverable
                  key={index}
                  className="resource-item"
                  cover={<img alt="example" src={item.bg} />}
                >
                  <Meta
                    className="item-meta"
                    title={item.name}
                    description={`${this.getReadNumber(item.name)} `}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <Divider />
        <div className="select-container">
          <div className="sub-title">Your Foundational Plans</div>
          <Row className="content-list" gutter={24}>
            {list.map((item, index) => (
              <Col span={8} onClick={() => window.open(item.link)} key={index}>
                <Card
                  hoverable
                  key={index}
                  className="content-item"
                  cover={<img alt="example" src={item.thumbUrl} />}
                >
                  <div className={`item-type ${item.type}`}>{item.type}</div>
                  <Meta
                    className="item-meta"
                    title={item.title}
                    description={item.readTime}
                  />
                  <div className="item-more">
                    Learn more <ArrowRightIcon />
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    )
  }
}

export default Resource
