import React from 'react'
import _, { get, isNil } from 'lodash'
import styled from 'styled-components'
import { VictoryChart, VictoryPolarAxis, VictoryScatter } from 'victory'
import { RangeColors } from '../../../utils'
import { handleMetricRanges } from '../wrapper'

const ChartContainer = styled.div`
  position: relative;
  padding-bottom: 160px;
  & > div {
    position: absolute;
  }
  .label {
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4c6072;
    line-height: 1.2;
    text-align: center;
    .value {
      font-size: 24px;
    }
  }
`

export default function AsymmetryChart(props) {
  const { ageScopes, metric, person, isSymmetricChart = true } = props
  let { level, ranges, color, unit } = metric
  color = RangeColors[color] || color
  const value = metric.value

  let _ranges = handleMetricRanges(ranges, person, ageScopes)

  const rangeArea = []
    .concat(..._ranges.map(({ min, max }) => [Number(min), Number(max)]))
    .sort((a, b) => a - b)
  const angles = calcAngles(_ranges)
  const _max = Math.max(...rangeArea)
  let valueMax = _max

  const _min = Math.min(...rangeArea)
  let valueMin = _min

  if (
    _ranges.length === 5 &&
    isNil(get(_ranges, '[0].min')) &&
    isNil(get(_ranges, '[4].max')) &&
    _ranges.slice(1, 4).every((r) => !isNil(r.min) && !isNil(r.max))
  ) {
    // get second value interval
    const interval = _ranges[3].max - _ranges[3].min
    valueMax = _max + interval
    valueMin = _min - interval
  }

  if (get(props, 'metric.name') === 'Sit to Stand') {
    valueMax = _max + 5
  }

  return (
    <ChartContainer>
      {isSymmetricChart
        ? angles.map(({ startAngle, endAngle, color }, index) => {
            return (
              <div key={index}>
                <VictoryChart polar startAngle={startAngle} endAngle={endAngle}>
                  <VictoryPolarAxis
                    style={{
                      axis: {
                        strokeWidth: 8,
                        stroke: color
                      },
                      tickLabels: { fill: 'transparent' }
                    }}
                  />
                </VictoryChart>
              </div>
            )
          })
        : _ranges.map(({ min, max, color }, index) => {
            min = min || 0
            max = max || 100
            return (
              <div key={index}>
                <VictoryChart
                  polar
                  startAngle={(_max - max) * 1.8}
                  endAngle={(_max - min) * 1.8}
                  maxDomain={_max}
                  minDomain={_min}
                >
                  <VictoryPolarAxis
                    style={{
                      axis: {
                        strokeWidth: 8,
                        stroke: color
                      },
                      tickLabels: { fill: 'transparent' }
                    }}
                  />
                </VictoryChart>
              </div>
            )
          })}

      <div>
        <VictoryChart
          polar
          startAngle={0}
          endAngle={180}
          tickValues={[0, 45, 90, 135, 180]}
          maxDomain={valueMax}
          minDomain={valueMin}
        >
          <VictoryPolarAxis
            style={{
              axis: {
                strokeWidth: 0
              },
              tickLabels: { fill: 'transparent' }
            }}
          />
          {_.isNumber(value) && (
            <VictoryScatter
              data={[
                {
                  x:
                    (get(props, 'metric.name') === 'Sit to Stand' ? 1 : -1) *
                    optimizeValueXAxis(value),
                  y: valueMax
                }
              ]}
              dataComponent={<Scatter color={color} />}
            />
          )}
        </VictoryChart>
      </div>
      <div className="label">
        <div className="value">{_.isNumber(value) ? value + unit : '--'}</div>
        <div style={{ color: color }}>{level}</div>
      </div>
    </ChartContainer>
  )

  function calcAngles(ranges) {
    if (get(props, 'metric.name') === 'Sit to Stand') {
      return ranges.map((range, index) => {
        const startAngle = index * 90
        const endAngle = (index + 1) * 90
        return {
          startAngle,
          endAngle,
          color: range.color
        }
      })
    }
    if (get(props, 'metric.name') === 'Countermovement Jump') {
      const angleInterval = 1.8
      const angles = [25, 15, 20, 15, 25].map((i) => i * angleInterval)
      let results = []
      let startAngle = 0
      for (let index = 0; index < angles.length; index++) {
        results.push({
          startAngle: startAngle,
          endAngle: startAngle + angles[index],
          color: ranges[index].color
        })
        startAngle += angles[index]
      }
      return results
    }
    if (get(props, 'metric.name') === 'Grip Strength') {
      const angleInterval = 3
      const angles = [15, 5, 20, 5, 15].map((i) => i * angleInterval)
      let results = []
      let startAngle = 0
      for (let index = 0; index < angles.length; index++) {
        results.push({
          startAngle: startAngle,
          endAngle: startAngle + angles[index],
          color: ranges[index].color
        })
        startAngle += angles[index]
      }
      return results
    }
    const middleIndex = Math.floor(ranges.length / 2)
    const angleInterval = 180 / (ranges.length + 1)
    return ranges.map((range, index) => {
      let startAngle, endAngle
      if (index === middleIndex) {
        startAngle = index * angleInterval
        endAngle = (index + 2) * angleInterval
      } else {
        startAngle = (index + (index > middleIndex ? 1 : 0)) * angleInterval
        endAngle = (index + 1 + (index > middleIndex ? 1 : 0)) * angleInterval
      }
      return {
        startAngle,
        endAngle,
        color: range.color
      }
    })
  }

  function optimizeValueXAxis(value) {
    if (value <= valueMin) {
      return valueMin
    } else if (value >= valueMax) {
      return valueMax
    } else {
      return value
    }
  }
}

function Scatter(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 8}
      y={props.y - 8}
    >
      <circle
        cx="8"
        cy="7.99994"
        r="6"
        fill="white"
        stroke={props.color}
        strokeWidth="4"
      />
    </svg>
  )
}
