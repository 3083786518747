import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import HelloSign from 'hellosign-embedded'
import { RightOutlined } from '@ant-design/icons'
import { Button, Checkbox, message, Radio } from 'antd'
import {
  ViewIcon,
  VectorIcon,
  SignedIcon
} from '../../../components/icons/onboarding'

import {
  getMembershipAgreement,
  getWaiverSignedForm
} from '../../../api/people'
import { saveUser } from '../../../utils/storage'
import { LogoutIcon } from '../../../components/icons/onboarding'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import pdfIcon from '../../../asserts/images/pdf.svg'

const RadioGroup = Radio.Group

class MembershipAgreement extends Component {
  static propTypes = {}

  state = {
    formInfo: {
      type: 'MEMBERSHIP AGREEMENT',
      name: 'Membership Agreement Form',
      signed: function() {
        return this.signs[this.signs.length - 1]
      },
      des:
        'BY SIGNING THIS FORM, YOU ACKNOWLEDGE AND ACCEPT THE TERM OF SERVICE OF APEIRON PROGRAM.',
      getUrl: getMembershipAgreement,
      isChecked: true,
      allowMulti: false,
      signs: [false]
    }
  }

  sign = async (getUrl, suffix) => {
    const { client } = this.state
    this.setState({ loading: true })
    const {
      embedded: { sign_url }
    } = await getUrl(suffix)
    client.open(sign_url)
    this.setState({ loading: false })
  }

  hasOtherMedicalProviders = () => {
    const { formInfo, hasOtherProviders } = this.state
    if (hasOtherProviders) {
      formInfo.signs.push(false)
      this.setState({ formInfo, hasOtherProviders: false })
    } else {
      if (formInfo.isChecked && formInfo.signed()) {
        this.props.history.push('/')
      }
    }
  }

  continue = () => {
    const {
      history,
      account: {
        user: { profile }
      }
    } = this.props
    const {
      onboarding_steps: { onboarding_required }
    } = profile
    if (onboarding_required) {
      history.push('/onboarding/ready')
    } else {
      history.push('/')
    }
  }

  checked = () => {
    const { formInfo } = this.state
    formInfo.isChecked = !formInfo.isChecked
    this.setState({ formInfo })
    if (formInfo.signed() && formInfo.isChecked) {
      this.props.history.push('/')
    }
  }

  loadSignedForms = async () => {
    const { formInfo } = this.state
    const res = await getWaiverSignedForm(false)
    if (res.membership_agreement.length) {
      formInfo.signs = res.membership_agreement
    }
    this.setState({ formInfo })
  }

  componentDidMount() {
    const {
      account
      // location: { search }
    } = this.props
    this.loadSignedForms()
    const client = new HelloSign({
      clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
      skipDomainVerification: process.env.REACT_APP_WORKFLOW === 'develop'
    })

    client.on('sign', () => {
      // in runtime, the step and stepForm was freshed now, so regain here
      let { formInfo } = this.state

      formInfo.signs[formInfo.signs.length - 1] = true
      this.setState({ formInfo })

      const { user } = account

      const singedName = 'membership_agreement'
      user.profile.completed_signature = user.profile.completed_signature || []
      if (!user.profile.completed_signature.includes(singedName)) {
        user.profile.completed_signature.push(singedName)
        user.profile.waiver_required = false
        this.props.setLoginAccount(user)
        saveUser(user)
      }
    })

    client.on('close', () => {})

    client.on('error', (data) => {
      const errStr = `Something went wrong! Please try again. Error code: ${data.code}`
      console.error(errStr)
      message.error(errStr)

      client.close()
    })

    this.setState({ client })
  }

  render() {
    const { logOut, history } = this.props
    const { loading, hasOtherProviders, formInfo } = this.state
    return (
      <div className="main-container">
        <header>
          <img src={phsLogo} alt="logo" />
          <div onClick={() => logOut(history)}>
            <LogoutIcon />
            LOG OUT
          </div>
        </header>
        <div className="step-title">MEMBERSHIP AGREEMENT</div>
        <div className="main-content">
          <div className="introduction bottom-line">
            {/* <div className="sub-title">Introduction</div> */}
            <p>{formInfo.des}</p>
          </div>
          <div className="sign ">
            <div className="sub-title">Sign with eSignature</div>
            {/* {formInfo.signs.map((hasSigned, index) => {
              return ( */}
            {/* key={index} */}
            <div className="sign-content">
              <div>
                <img className="icon" src={pdfIcon} alt="pdf" />
                <div className="sign-title">
                  <h5>
                    {formInfo.name}
                    {/* - {index + 1} */}
                  </h5>
                  {!formInfo.signs[0] && (
                    <p>Please preview and sign document</p>
                  )}
                </div>
              </div>
              {formInfo.signs[0] ? (
                <span className="signed">
                  <SignedIcon />
                  Signed
                </span>
              ) : (
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() =>
                    this.sign(formInfo.getUrl, formInfo.signs.length - 1)
                  }
                  disabled={!formInfo.isChecked}
                >
                  <ViewIcon />
                  Preview & Sign
                </Button>
              )}
            </div>
            {/* //   )
            // })} */}
          </div>

          {!formInfo.signed() && (
            <div className="check">
              <div>
                <Checkbox checked={formInfo.isChecked} onClick={this.checked} />
                I understand this is a legal representation of my signature
              </div>
              <div className="vector">
                <VectorIcon />
                <p>
                  Apeiron Life is compliant with all major eSignature laws
                  including ESIGN, UETA, European eIDAS, and eSignature service
                  provided by HelloSign
                </p>
              </div>
            </div>
          )}
          {formInfo.allowMulti && formInfo.signed() && (
            <div className="other-provider-select">
              <p className="sub-title">
                DO YOU HAVE any OTHER PROVIDER(S) that need a medical release ?
              </p>
              <div>
                <RadioGroup
                  onChange={(e) =>
                    this.setState({ hasOtherProviders: e.target.value })
                  }
                  defaultValue={hasOtherProviders}
                >
                  <Radio value={false} className="radio">
                    No
                  </Radio>
                  <Radio value={true} className="radio">
                    Yes
                  </Radio>
                </RadioGroup>
              </div>
              <Button
                type="primary"
                style={{
                  width: '186px',
                  height: '48px',
                  marginTop: '24px'
                }}
                onClick={() => this.hasOtherMedicalProviders()}
              >
                Proceed
              </Button>
            </div>
          )}
        </div>
        {formInfo.signed() && (
          <footer>
            <span className="back">{/* <Icon type="left" /> back */}</span>
            {
              <span className="back" onClick={this.continue}>
                CONTINUE <RightOutlined />
              </span>
            }
          </footer>
        )}
      </div>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(MembershipAgreement)
