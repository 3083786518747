import React from 'react'
import { Prompt } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { message } from 'antd'
import { getAllExperts } from '../../api/team'
import {
  getProgramDetail,
  publishProgram,
  getLiveProgram,
  // changeExpertReleation,
  editProgram,
  getProgramPercent,
  getProgramItemDurationMapping,
  getProgramItemIntensityRange,
  getWorkoutTypes,
  notifyClient
} from '../../api/program'
import {
  getCategories,
  createGoal,
  updateGoal,
  deleteGoal,
  getMetaData,
  saveEntry,
  deleteEntry,
  creatGoalBulk,
  editGoalBulk,
  deleteGoalBulk
} from '../../api/microGoals'
import {
  getThisWeekRange,
  getShowWeeklyDate,
  prevWeekDate,
  nextWeekDate
} from '../../utils/dateHandles'
import { staticTrackingOuraMetric } from '../../utils/constant'
import { loadUser } from '../../utils/storage'
import { getRole } from '../../utils/common'

const CRUD_MG_TYPE = 1

export default (WrappedComponent) =>
  class ProgramWrapper extends React.Component {
    static propTypes = {}

    state = {
      isDeleted: false,
      weekRange: {}
      // statistics: {}
      // program: { items: [] },
      // summary: { workouts: [] }
    }

    prevDate = () => {
      const {
        weekRange: { startDate, endDate }
      } = this.state
      const weekRange = prevWeekDate(startDate, endDate)
      this.setState({ pageLoading: true, weekRange })

      this.initail(weekRange)
    }

    nextDate = () => {
      const {
        weekRange: { startDate, endDate }
      } = this.state
      const weekRange = nextWeekDate(startDate, endDate)
      this.setState({ pageLoading: true, weekRange })

      this.initail(weekRange)
    }

    deleteCallback = (history) => {
      this.setState({ isDeleted: true })
      setTimeout(() => history.go(-1))
    }

    notifyClient = async (message) => {
      const { program } = this.state
      await notifyClient(program.id, message)
    }

    checkPublishable = () => {
      const { program, nutrition, rejuvenation } = this.state
      return !!(
        (program &&
          (program.status === 0 ||
            program.draft_data ||
            (program.items.length > 0 &&
              (program.items.find((item) => item.status === 0) ||
                program.items.find((item) => item.draft_data))))) ||
        (nutrition &&
          nutrition.find((item) => {
            const microGoal = item.micro_goal
            return (
              !(microGoal.active || (microGoal.published_at ? true : false)) ||
              microGoal.draft_data
            )
          })) ||
        (rejuvenation &&
          rejuvenation.find((item) => {
            const microGoal = item.micro_goal
            return (
              !(microGoal.active || (microGoal.published_at ? true : false)) ||
              microGoal.draft_data
            )
          }))
      )
    }

    beforeunload = (env) => {
      const { isDeleted } = this.state
      const publishable = this.checkPublishable()
      if (publishable && !isDeleted) {
        const confirmMessage =
          'This program has not published. Are you sure to leave?'
        if (env) {
          env.preventDefault()
          env.returnValue = confirmMessage
        }
        return confirmMessage
      }
    }

    publish = async () => {
      const { program } = this.state
      await publishProgram(program.id)
      this.fetchProgram(this.state.weekRange)
      message.success('This program has been published!')
      this.setState({ showPublishConfirm: false })
    }

    groupArray = (arr, field) => {
      let result = []
      for (let item of arr) {
        if (item[field] && !result.find((node) => node === item[field])) {
          result.push(item[field])
        }
      }
      result = result.map((item) => {
        let children = arr.filter((child) => child[field] === item)
        children = children.map((child) => ({
          title: child.name,
          value: child.name,
          key: child.name //`${item}-${child.name}`
        }))
        return {
          title: item,
          value: `origin-${item}`,
          key: `origin-${item}`,
          children
        }
      })
      return result
    }

    getMetaData = async () => {
      const result = await getMetaData()
      let { healthkit, garmin, activity, ouraring, polar, withings } = result
      let healthkitMetric = [],
        garminMetric = [],
        ouraMetric = [],
        polarMetric = [],
        withingsMetric = [],
        healthkitActivities = [],
        garminActivities = [],
        polarActivities = [],
        trackingMetric = [],
        ouraActivities = [],
        trackingOuraMetric = []

      for (const item in healthkit) {
        healthkitMetric.push({
          label: item,
          value: item,
          pillar: healthkit[item].pillar,
          unit: healthkit[item].unit
        })
      }
      for (const item in garmin) {
        garminMetric.push({
          label: item,
          value: item,
          pillar: garmin[item].pillar,
          unit: garmin[item].unit
        })
      }

      for (const item in ouraring) {
        const itemObj = {
          label: item,
          value: item,
          unit: ouraring[item].unit
        }
        switch (ouraring[item].show_property) {
          case 0:
            ouraMetric.push(itemObj)
            trackingOuraMetric.push({ ...ouraring[item], name: item })
            break
          case 1:
            ouraMetric.push(itemObj)
            break
          case 2:
            trackingOuraMetric.push({ ...ouraring[item], name: item })
            break
          case 3:
            ouraActivities.push(itemObj)
            break
          default:
            ouraMetric.push(itemObj)
            break
        }
      }

      for (const item in polar) {
        polarMetric.push({
          label: item,
          value: item,
          pillar: polar[item].pillar,
          unit: polar[item].unit
        })
      }

      for (const item in withings) {
        withingsMetric.push({
          label: item,
          value: item,
          pillar: withings[item].pillar,
          unit: withings[item].unit
        })
      }

      for (const item of activity.healthkit) {
        healthkitActivities.push({
          label: item,
          value: item
        })
      }

      for (const item of activity.garmin) {
        garminActivities.push({
          label: item,
          value: item
        })
      }

      for (const item of activity.polar) {
        polarActivities.push({
          label: item,
          value: item
        })
      }

      for (const item in activity.trackingMetric) {
        trackingMetric.push({
          label: activity.trackingMetric[item],
          value: item
        })
      }

      const ouraSleepMetric = trackingOuraMetric
      trackingOuraMetric = this.groupArray(trackingOuraMetric, 'group_name')

      this.setState({
        healthkit,
        garmin,
        ouraring,
        polar,
        withings,
        healthkitMetric,
        healthkitActivities,
        garminMetric,
        garminActivities,
        ouraMetric,
        polarMetric,
        polarActivities,
        trackingMetric,
        ouraActivities,
        ouraSleepMetric,
        trackingOuraMetric,
        withingsMetric
      })
    }

    addAttachSync = (attachmemt, action, targetPillar) => {
      if (action.micro_goal) {
        // nutrition or rejuvenation action item
        const pillar = action.category ? action.category.pillar : targetPillar
        const targetGoal = this.state[pillar].find(
          (item) => item.micro_goal.id === action.id
        )
        targetGoal.micro_goal.attachments
          ? targetGoal.micro_goal.attachments.push(attachmemt)
          : (targetGoal.micro_goal.attachments = [attachmemt])
        this.setState({
          pillar: this.state[pillar],
          editGoal: targetGoal.micro_goal
        })
      } else {
        // exercise item
        const { summary } = this.state
        const { program_items } = summary
        const programItem = program_items.find((item) => item.id === action.id)
        if (programItem) {
          if (programItem.attachments) {
            programItem.attachments.push(attachmemt)
          } else {
            programItem.attachments = [attachmemt]
          }
          summary.program_items = program_items
          this.setState({ summary })
        }
      }
    }

    removeAttachSync = (attachment, action, targetPillar) => {
      if (action.micro_goal) {
        const pillar = action.category ? action.category.pillar : targetPillar
        const targetGoal = this.state[pillar].find(
          (item) => item.micro_goal.id === action.id
        )
        if (targetGoal) {
          targetGoal.micro_goal.attachments = targetGoal.micro_goal.attachments.filter(
            (item) => item.id !== attachment.id
          )
        }
        this.setState({
          pillar: this.state[pillar],
          editGoal: targetGoal.micro_goal
        })
      } else {
        const { summary } = this.state
        const { program_items } = summary
        const programItem = program_items.find((item) => item.id === action.id)
        if (programItem) {
          programItem.attachments = programItem.attachments.filter(
            (item) => item.id !== attachment.id
          )
          summary.program_items = program_items
          this.setState({ summary })
        }
      }
    }

    editAttachTitleSync = (action, targetPillar) => {
      if (action.micro_goal) {
        const pillar = action.category ? action.category.pillar : targetPillar
        const targetGoal = this.state[pillar].find(
          (item) => item.micro_goal.id === action.id
        )
        targetGoal.micro_goal = action
        this.setState({
          pillar: this.state[pillar],
          editGoal: targetGoal.micro_goal
        })
      } else {
        const { summary } = this.state
        const index = summary.program_items.findIndex(
          (item) => item.id === action.id
        )
        summary.program_items.splice(index, 1, action)
        this.setState({ summary })
      }
    }

    getProgramData = async (id, from, to) => {
      const { originView, person } = this.props
      let result
      if (originView === 'Overview') {
        result = await getLiveProgram(id, from, to, person && person.id)
      } else {
        result = await getProgramDetail(id, from, to, person && person.id)
      }
      return result
    }

    fetchProgram = async (weekRange, isInitial) => {
      const {
        match: {
          params: { id }
        },
        setBreadcrums,
        position
      } = this.props
      let { selectExercise } = this.state
      const { startDate, endDate } = weekRange
      const showDate = getShowWeeklyDate(startDate, endDate)
      let personId = id
      if (!personId) {
        personId = loadUser().id
      }
      try {
        const { exercise: result, ...otherData } = await this.getProgramData(
          personId,
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD')
        )
        if (isInitial) {
          setBreadcrums && setBreadcrums(result.program.name)
        }
        if (result.summary) {
          result.summary.workouts.map((workout) => {
            if (workout.program_item_id) {
              const item = result.program.items.find(
                (item) => item.id === workout.program_item_id
              )

              workout.name = item && item.name
              if (position !== 'clientView' && item && item.draft_data) {
                workout.name = item.draft_data.name
              }
            }
            return workout
          })
        }

        if (selectExercise && !selectExercise.record_date) {
          selectExercise = result.summary.program_items.find(
            (item) => item.id === selectExercise.id
          )
        }

        /* handle micro goal start */

        let nutrition = otherData.nutrition
          ? otherData.nutrition.filter(
              (item) => !item.micro_goal.parent_micro_goal_id
            )
          : []

        let rejuvenation = otherData.rejuvenation
          ? otherData.rejuvenation.filter(
              (item) => !item.micro_goal.parent_micro_goal_id
            )
          : []

        const loginRole = getRole()
        if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
          nutrition =
            nutrition &&
            nutrition.map((item) => {
              item.micro_goal = _.assign(
                item.micro_goal,
                item.micro_goal.draft_data
              )
              return item
            })
          rejuvenation =
            rejuvenation &&
            rejuvenation.map((item) => {
              item.micro_goal = _.assign(
                item.micro_goal,
                item.micro_goal.draft_data
              )
              return item
            })
        }

        /* handle micro goal end */

        if (position !== 'clientView' && result.program) {
          result.program = Object.assign(
            result.program,
            result.program.draft_data || {}
          )
        }

        let hideArrow = ''
        if (endDate.isSameOrAfter(moment(), 'day')) {
          hideArrow += ' right'
        }
        if (
          result.program &&
          startDate.isSameOrBefore(result.program.from, 'day')
        ) {
          hideArrow += 'left '
        }
        // if (endDate.isSame(result.program.to, 'day')) {
        //   hideArrow += ' right'
        // }
        const expiredDate = moment().isAfter(endDate, 'day')
        // const releations = {}
        // for (let item of result.program.releations || []) {
        //   releations[item.pillar] = item
        // }
        this.setState({
          ...result,
          nutrition,
          rejuvenation,
          // releations,
          extra: otherData.extra,
          itemCount: otherData.action_item_count,
          hsdSummary: otherData.hsd_summary,
          // pageLoading: false,
          errorMessage: '',
          showDate,
          selectExercise,
          hideArrow,
          expiredDate,
          loginRole
        })
      } catch (err) {
        console.error(err)
        let errorMessage = ''
        if (err.statusCode === 404) {
          errorMessage = 'Sorry, the program does not exist.'
        } else {
          errorMessage = err.message
        }

        this.setState({
          // pageLoading: false,
          errorMessage
        })
      }
    }

    getMicroGoalMetaData = async (isInitial) => {
      const { position } = this.props
      if (isInitial) {
        const result = await getCategories()
        this.setState({ categories: result.categories })
        const loginRole = getRole()
        if (
          (position && position !== 'clientView') ||
          loginRole === 'Expert' ||
          loginRole === 'InternalAdmin'
        ) {
          this.getMetaData()
        } else {
          this.setState({
            trackingOuraMetric: staticTrackingOuraMetric
          })
        }
      }
    }

    getProgramMetaData = async (isInitial) => {
      if (isInitial) {
        const itemDurationMapping = await getProgramItemDurationMapping()
        const intensityRange = await getProgramItemIntensityRange()
        const workoutTypes = await getWorkoutTypes()
        this.setState({ itemDurationMapping, intensityRange, workoutTypes })
      }
    }

    deleteEntry = async (id, date) => {
      const { person } = this.props
      const { nutrition, rejuvenation } = this.state
      const { achieving } = await deleteEntry(id, date, person.id)
      let isDone = false
      for (const pillar of [nutrition, rejuvenation]) {
        for (const item of pillar) {
          if (item.micro_goal.id === id) {
            this.updateAchieving(achieving, item.micro_goal)
            isDone = true
          }
        }
        if (isDone) break
      }
    }

    saveEntry = async (goal, value, date, period) => {
      const { person } = this.props
      period = period || this.state.period.replace('ly', '')
      // midpoint input handle
      if (goal.unit === 'Time') {
        if (value > 24) {
          value = value % 24
        }
        const hour = parseInt(value)
        const minute = Math.round((value % 1) * 60)

        const timeStemp = new Date(
          moment().format(`YYYY-MM-DD ${hour}:${minute}`)
        ).getTime()
        value = (timeStemp / 1000).toString()
      }
      const params = {
        input: value,
        created_at: date,
        unit: period
      }
      const { achieving, micro_goal } = await saveEntry(
        goal.id,
        params,
        person.id
      )
      this.updateAchieving(achieving, micro_goal)
      await this.updateProgramPercent()
    }

    updateAchieving = (achieving, micro_goal) => {
      const pillar = this.state[micro_goal.category.pillar]
      const entry = achieving[0]
      const index = pillar.findIndex(
        (item) => item.micro_goal.id === micro_goal.id
      )
      const target = pillar[index]
      target.achieving = target.achieving.map((item) => {
        if (item.from === entry.from && item.to === entry.to) {
          return achieving[0]
        } else {
          return item
        }
      })
      pillar[index] = target
      this.setState({
        [micro_goal.category.pillar]: _.cloneDeep(pillar)
      })
    }

    updateProgramPercent = async () => {
      const {
        match: {
          params: { id }
        }
      } = this.props
      const {
        weekRange: { startDate, endDate },
        extra
      } = this.state
      const {
        nutrition_program_achieved,
        sleep_program_achieved
      } = await getProgramPercent(
        id,
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD')
      )
      extra.nutrition_program_achieved = nutrition_program_achieved
      extra.sleep_program_achieved = sleep_program_achieved
      this.setState({ extra })
    }

    requestGoalsBulk = async (params) => {
      const { person } = this.props
      const {
        trackingOuraMetric,
        ouraSleepMetric,
        editGoal,
        weekRange
      } = this.state
      const { tracking_activity_metric, category_id } = params
      const requestArr = []
      const metrics = tracking_activity_metric.split(',')
      const origins = metrics.filter((item) => item.match('origin-'))
      let requestMetrics = metrics.filter((item) => !item.match('origin-'))
      for (const origin of origins) {
        const originObj = trackingOuraMetric.find(
          (item) => item.value === origin
        )
        requestMetrics = requestMetrics.concat(
          originObj.children.map((item) => item.value)
        )
      }
      const tempGoal = {
        is_parent: false,
        category_id,
        chart_type: 'line',
        cron_day_month: params.cron_day_month,
        cron_day_week: params.cron_day_week,
        input_type: 'number',
        person_id: person.id,
        source: params.source, //'ouraring',
        repeat_num: params.repeat_num,
        unit: params.unit
      }
      for (const metric of requestMetrics) {
        const task = Object.assign({}, tempGoal)
        task.auto_metric = task.micro_goal = metric
        task.group_name = ouraSleepMetric.find(
          (item) => item.name === metric
        ).group_name
        requestArr.push(task)
      }
      params.tracking_activity_metric = requestMetrics.join(',')
      params.person_id = person.id
      params.is_parent = true
      params.auto_metric = params.auto_activity
      params.goal_type = 'sleep'
      delete params.auto_activity
      requestArr.unshift(params)
      try {
        if (editGoal && editGoal.id) {
          await editGoalBulk(requestArr, editGoal.id, CRUD_MG_TYPE)
        } else {
          const { micro_goals } = await creatGoalBulk(requestArr, CRUD_MG_TYPE)
          const microGoal = micro_goals.find(
            (item) => !item.parent_micro_goal_id
          )
          this.setState({ editGoal: microGoal })
        }
        await this.initail(weekRange)
      } catch (err) {
        console.error(err)
      }
    }

    requestHartRateGoal = async (params) => {
      const { person } = this.props
      const { garminMetric, healthkitMetric, editGoal, weekRange } = this.state
      params.person_id = person.id
      let metrics = []
      if (params.source === 'healthkit') {
        metrics = healthkitMetric
          .filter((item) => item.value.includes('HeartRate'))
          .map((item) => item.value)
      } else if (params.source === 'garmin') {
        metrics = garminMetric
          .filter((item) => item.value.includes('HeartRate'))
          .map((item) => item.value)
      } else if (params.source === 'any') {
        metrics = []
          .concat(
            healthkitMetric.filter((item) => item.value.includes('HeartRate'))
          )
          .concat(
            garminMetric.filter((item) => item.value.includes('HeartRate'))
          )
          .map((item) => item.value)
        metrics = Array.from(new Set(metrics))
      }

      metrics = metrics.filter(
        (metric) =>
          ![
            'HeartRateZone1',
            'HeartRateZone2',
            'HeartRateZone3',
            'HeartRateZone4',
            'HeartRateZone5'
          ].find((item) => metric === item)
      )
      const requestArr = metrics.map((metric) => {
        const result = Object.assign({}, params)
        result.auto_metric = metric
        result.micro_goal = metric
        result.is_parent = false
        return result
      })
      params.is_parent = true
      params.goal_type = 'heartRateMetrics'
      params.auto_metric = 'Heart Rate Zones'

      requestArr.unshift(params)

      try {
        if (editGoal && editGoal.id) {
          await editGoalBulk(requestArr, editGoal.id, CRUD_MG_TYPE)
        } else {
          const { micro_goals } = await creatGoalBulk(requestArr, CRUD_MG_TYPE)
          const microGoal = micro_goals.find(
            (item) => !item.parent_micro_goal_id
          )
          this.setState({ editGoal: microGoal })
        }
        await this.initail(weekRange)
      } catch (err) {
        console.error(err)
      }
    }

    requestGoal = async (params, pillar) => {
      const { person } = this.props
      const { categories, editGoal, weekRange, program } = this.state
      const { startDate, endDate } = weekRange
      let targetPillar = this.state[pillar]
      const category = categories.find(
        (item) =>
          item.category === params.category &&
          item.sub_category === params.sub_category
      )
      if (category) {
        params.category_id = category.id
      } else {
        params.category_id = categories.find(
          (item) => item.category === 'Default' && item.pillar === pillar
        ).id
      }
      delete params.category
      delete params.sub_category
      // program item defaulte daily
      params.cron_day_week = '*'
      params.cron_day_month = '*'
      params.program_id = program.id

      if (params.input_type === 'binary' && params.auto_activity === 'Sleep') {
        return this.requestGoalsBulk(params)
      }

      if (
        !params.auto_activity &&
        ((params.source !== 'manual' &&
          params.auto_metric === 'Heart Rate Zones') ||
          (editGoal && editGoal.goal_type === 'heartRateMetrics'))
      ) {
        return this.requestHartRateGoal(params)
      }

      try {
        let result, microGoal
        if (editGoal && editGoal.id) {
          await updateGoal(params, editGoal.id, CRUD_MG_TYPE)
        } else {
          params.person_id = person.id
          result = await createGoal(params, CRUD_MG_TYPE)
          microGoal = result.micro_goal
          this.setState({ editGoal: microGoal })
          if (!targetPillar) {
            targetPillar = []
          }
          targetPillar.push({
            micro_goal: microGoal,
            achieving: [
              {
                entries: [],
                from: startDate.format('YYYY-MM-DD'),
                to: endDate.format('YYYY-MM-DD'),
                achieved: 0,
                missed: 0,
                no_data: 0,
                expected: 1
              }
            ]
          })
        }
        this.initail(weekRange)
        /*    this.setState({
          editGoal: (result && result.micro_goal) || {}
          // [pillar]: targetPillar
        }) */
      } catch (err) {
        console.error(err)
      }
    }

    deleteGoal = async (goal) => {
      try {
        if (goal.goal_type) {
          await deleteGoalBulk(goal.id, CRUD_MG_TYPE)
        } else {
          await deleteGoal(goal.id, CRUD_MG_TYPE)
        }

        await this.initail(this.state.weekRange)
      } catch (err) {
        console.error(err)
      }
    }

    changeExpert = async (expertId) => {
      const { person } = this.props
      const { program, experts } = this.state
      const { id, name, from, to, description } = program
      await editProgram(
        { expert_id: expertId, name, from, to, description },
        person.id,
        id
      )
      program.draft_data = program.draft_data || {}
      program.draft_data.expert = experts.find((item) => item.id === expertId)
      this.setState({ program })
    }

    initail = async (weekRange, isInitial) => {
      try {
        await this.fetchProgram(weekRange, isInitial)
        await this.getMicroGoalMetaData(isInitial)
        await this.getProgramMetaData(isInitial)
        const { clients: experts } = await getAllExperts()
        this.setState({ pageLoading: false, experts })
      } catch (err) {
        console.error(err)
      }
    }

    componentDidMount() {
      let thisWeek = getThisWeekRange()
      // let params = new URL(document.location).searchParams
      // const from = params.get('from')
      // const to = params.get('to')
      // if (from && to) {
      //   if (moment().isBefore(from, 'day')) {
      //     thisWeek = getThisWeekRange(from)
      //   } else if (moment().isAfter(to, 'day')) {
      //     thisWeek = getThisWeekRange(to)
      //   }
      // }
      this.setState({ pageLoading: true })
      this.setState({ weekRange: thisWeek })
      // this.fetchProgram(thisWeek, true)
      this.initail(thisWeek, true)
    }

    render() {
      const { isDeleted, program } = this.state
      const childProps = {
        ...this.state,
        publish: this.publish,
        prevDate: this.prevDate,
        nextDate: this.nextDate,
        beforeunload: this.beforeunload,
        fetchProgram: this.fetchProgram,
        addAttachSync: this.addAttachSync,
        removeAttachSync: this.removeAttachSync,
        editAttachTitleSync: this.editAttachTitleSync,
        deleteCallback: this.deleteCallback,
        checkPublishable: this.checkPublishable,
        saveEntry: this.saveEntry,
        deleteEntry: this.deleteEntry,
        requestGoal: this.requestGoal,
        deleteGoal: this.deleteGoal,
        setEditGoal: (editGoal) => this.setState({ editGoal }),
        changeExpert: this.changeExpert,
        notifyClient: this.notifyClient
      }
      const loginRole = getRole()

      const publishable = this.checkPublishable()
      const needPrompt = !!(
        this.props.position !== 'clientView' &&
        this.props.originView !== 'Overview' &&
        loginRole !== 'Service_Provider' &&
        program &&
        program.status &&
        publishable &&
        !isDeleted
      )
      return (
        <>
          <Prompt
            when={needPrompt}
            message="This program has not published. Are you sure to leave?"
          />
          <WrappedComponent {...childProps} {...this.props} />
        </>
      )
    }
  }
