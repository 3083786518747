import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Empty } from 'antd'
import GoalsCategory from './goalCategory'

class MicroGoals extends Component {
  static propTypes = {
    pillars: PropTypes.array
  }

  shouldComponentUpdate(nextProps) {
    const { pillars, statistics, type } = this.props
    return !(
      pillars === nextProps.pillars &&
      statistics === nextProps.statistics &&
      type === nextProps.type
    )
  }

  render() {
    const { pillars, statistics, type } = this.props // archivedGoals, type

    const childProps = {
      closeModal: () => {
        this.setState({ visible: 0 })
      },
      ...this.props
    }
    let loopPillars = pillars
    if (type === 'archived') {
      loopPillars = loopPillars.filter((item) => statistics[item.name])
    }

    return (
      <div>
        {loopPillars.length > 0 ? (
          loopPillars.map((item, index) => (
            <GoalsCategory
              tabPillar={item}
              key={index}
              {...childProps}
              goals={statistics}
            />
          ))
        ) : (
          <Empty style={{ paddingTop: 50 }} />
        )}
      </div>
    )
  }
}

export default MicroGoals
