import React, { Component } from 'react'
import { Upload, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  min-height: 40px;
  line-height: 42px;
  cursor: pointer;
  .ant-upload-list-picture .ant-upload-list-item {
    border: none;
  }
  .ant-upload.ant-upload-select {
    display: block;
  }
`

class FileUpload extends Component {
  static propTypes = {
    id: PropTypes.string,
    multiple: PropTypes.bool
  }

  state = {
    files: [],
    filename: ''
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      value
    })
  }

  triggerChange = (file) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(file)
    }
    if (this.state.value) {
      this.setState({ value: null })
    }
    return false
  }

  render() {
    const { extraAttr } = this.props
    const { value } = this.state
    const defaultFileList = value && typeof value !== 'string' ? [value] : []
    return (
      <Container>
        <Upload
          {...extraAttr}
          defaultFileList={defaultFileList}
          beforeUpload={this.triggerChange}
        >
          {extraAttr && extraAttr.placeholder}
        </Upload>
        {value && value.url && (
          <>
            <div className="ant-upload-list ant-upload-list-picture">
              <div
                className="ant-upload-list-item-info"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 8,
                  marginTop: 8
                }}
              >
                <span className="ant-upload-span">
                  <a
                    href={value.url}
                    className="ant-upload-list-item-thumbnail"
                  >
                    <img
                      src={value.url}
                      alt=""
                      className="ant-upload-list-item-image"
                    />
                  </a>
                </span>
                <span className="ant-upload-list-item-name">{value.name}</span>
                <span
                  className="ant-upload-list-item-card-actions picture"
                  style={{ textAlign: 'right' }}
                >
                  <Button
                    className="ant-upload-list-item-card-actions-btn"
                    onClick={() => {
                      this.setState({ value: null })
                      this.triggerChange(null)
                    }}
                    type="text"
                    size="small"
                    icon={<DeleteOutlined />}
                  />
                </span>
              </div>
            </div>
          </>
        )}
      </Container>
    )
  }
}

export default FileUpload
