import React, { Component } from 'react'
import { Button } from 'antd'
import BasicForm from '../../components/formV4'
import { verifyEmail } from '../../models/signIn.form'
import { passwordForm } from '../../models/setting.form'
import { forgotPassword, passwordReset } from '../../api/people'
import sendEmail from '../../asserts/images/sendEmail.png'
import success from '../../asserts/images/success.png'
import { ResetPwsContainer } from './style'

class ResetView extends Component {
  static propTypes = {}

  state = {
    formValue: {},
    status: false,
    loading: false
  }

  verify = async () => {
    try {
      const params = await this.basicFormRef.handleSubmit()
      this.setState({ loading: true })
      await forgotPassword(params)
      this.setState({
        loading: false,
        status: true
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  reset = async () => {
    const { location } = this.props
    const hash = location.pathname.split('reset/')[1]
    try {
      const params = await this.basicFormRef.handleSubmit()
      this.setState({ loading: true })
      await passwordReset({
        password: params.password,
        hash
      })
      this.setState({
        loading: false,
        status: true
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  updateValueForm = (changedFields) => {
    const { formValue } = this.state
    const fieldName = Object.keys(changedFields)[0]
    const field = changedFields[fieldName]
    if (!field) return
    formValue[field.name] = field.value
    this.setState({ formValue })
  }

  renderContainer() {
    const {
      match: {
        params: { type }
      }
    } = this.props
    const { status, loading } = this.state
    if (type === 'verify') {
      return status ? (
        <>
          <h1 className="title">We’ve sent a link to your email!</h1>
          <img src={sendEmail} alt="send" />
          <p className="description short">
            You have received a link in your email, please check your email and
            access to the link to reset your password
          </p>
          <div
            className="resend"
            onClick={() => this.setState({ status: false })}
          >
            Not received? Resend it
          </div>
        </>
      ) : (
        <>
          <h1 className="title">Enter your email</h1>
          <p className="description">Check your email to verify the account</p>
          <BasicForm
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            formData={verifyEmail}
            onEnter={this.verify}
          />
          <Button type="primary" onClick={this.verify} loading={loading}>
            Verify your email
          </Button>
        </>
      )
    } else if (type) {
      return status ? (
        <>
          <h1 className="title">Password reset successful</h1>
          <img src={success} alt="success" />
          <p className="description short">
            You have successfully reset your password, please use your new
            password when logging in
          </p>
          <Button
            type="primary"
            onClick={() => this.props.history.push('/sign-in')}
          >
            Go back to login
          </Button>
        </>
      ) : (
        <>
          <h1 className="title">Enter a new password</h1>
          <BasicForm
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            normalLayout
            formData={passwordForm}
            onEnter={this.reset}
            onChange={this.updateValueForm}
          />
          <Button type="primary" onClick={this.reset} loading={loading}>
            Reset password
          </Button>
        </>
      )
    }

    return <div />
  }

  render() {
    return (
      <ResetPwsContainer>
        <div className="container">{this.renderContainer()}</div>
      </ResetPwsContainer>
    )
  }
}

export default ResetView
