import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox } from 'antd'
import Header from './header'
import { pattern } from '../../../utils/constant'
import BasicForm from '../../../components/formV4'
import {
  createNewCheckin,
  getCheckinInfo,
  getFacilityDetail
} from '../../../api/checkin'
import { setCheckIn, setCheckInFacility } from '../../../utils/storage'
import iconEmail from '../../../asserts/icon/signIn/email.svg'
import iconUser from '../../../asserts/icon/icon-user.svg'

const formData = (notFound) => [
  {
    type: 'input',
    dbField: 'full_name',
    placeholder: 'Your full name',
    disabled: notFound,
    prefix: <img src={iconUser} alt="prefix" />,
    // suffix: notFound ? (
    //   <span style={{ color: '#BAC3CE' }}>Not found</span>
    // ) : null,
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: /[ ]\w+/, message: 'Please enter your full name' }
    ]
  },
  {
    type: 'input',
    dbField: 'email',
    placeholder: 'Your email address',
    disabled: notFound,
    prefix: <img src={iconEmail} alt="prefix" />,
    // suffix: notFound ? (
    //   <span style={{ color: '#BAC3CE' }}>Not found</span>
    // ) : null,
    rules: [
      { required: true, message: 'This field is required' },
      { pattern: pattern.email, message: 'Please input a valid email' }
    ]
  }
]
class Confirm extends Component {
  static propTypes = {}

  state = {
    procced: false,
    agree: true,
    loading: false,
    notFound: false
  }

  submit = async (procced) => {
    const { history } = this.props
    const { agree } = this.state
    let visited = true
    if (procced) {
      visited = false
    }
    if (!agree) {
      return
    }
    this.setState({ loading: true, notFound: false })
    try {
      const params = await this.refForm.handleSubmit()
      let result
      if (visited) {
        result = await getCheckinInfo(params.email)
      } else {
        result = await createNewCheckin(params)
      }
      const { checkin } = result
      setCheckIn(checkin)
      this.setState({ loading: false })
      history.push('/check-in/welcome')
    } catch (err) {
      console.error(err)
      if (err.statusCode === 404) {
        this.setState({
          notFound: true
        })
      }
      this.setState({
        loading: false
      })
    }
  }

  initial = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { facility } = await getFacilityDetail(id)
    this.setState({ facility })
    setCheckInFacility(facility)
  }

  componentDidMount() {
    this.initial()
  }

  renderSelected() {
    const { agree, loading, notFound, facility } = this.state
    return (
      <>
        <p className="welcome-text">
          Welcome to Apeiron Life{(facility && ` ${facility.name}.`) || '.'}
        </p>
        {notFound ? (
          <p className="check-title">
            Looks like you’re visiting us for the first time.
            <br />
            Please double-check your info and tap proceed.
          </p>
        ) : (
          <p className="check-title">
            Please check in with your name and email address.
          </p>
        )}

        <div className="confirm-form">
          <BasicForm
            wrappedComponentRef={(inst) => (this.refForm = inst)}
            formData={formData(notFound)}
          />
          <div className="confirm-check">
            <Checkbox
              checked={agree}
              onChange={(e) => {
                this.setState({ agree: e.target.checked })
              }}
            />
            I have read and agree to Apeiron Life’s{' '}
            <Link to="/terms-of-use/back">Terms of Use</Link> and{' '}
            {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
            <Link to="/facility-waiver">Facility Waiver</Link>
            <br />
            {notFound ? (
              <div className="button-group">
                <Button
                  ghost
                  onClick={() => this.setState({ notFound: false })}
                  style={{
                    marginRight: 60
                  }}
                >
                  Edit info
                </Button>
                <Button ghost onClick={() => this.submit(true)}>
                  Proceed
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => this.submit()}
                disabled={!agree || loading}
                loading={loading}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </>
    )
  }

  render() {
    return (
      <div className="confirm">
        <Header history={this.props.history} />
        {this.renderSelected()}
      </div>
    )
  }
}

export default Confirm
