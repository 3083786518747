import axios from './axios'
import moment from 'moment'

export const getMedicalInformation = (personId) =>
  axios.get(
    `/medical_information?person_id=${personId}&today=${moment().format(
      'YYYY-MM-DD'
    )}`
  )

export const getMedicalInfoById = (id) =>
  axios.get(`/medical_information/${id}`)

export const createMedical = (params, personId) =>
  axios.postJson(`/medical_information?person_id=${personId}`, params)

export const updateMedical = (params, id) =>
  axios.putJson(`/medical_information/${id}`, params)

export const deleteMedical = (id) => axios.delete(`/medical_information/${id}`)
