import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Checkbox, Input, InputNumber } from 'antd'

export default function MultiChoice(props) {
  const {
    question: { choices, choiceLimit },
    answer,
    update
  } = props

  const [answerArray, setAnswerArray] = useState([])

  useEffect(() => {
    if (answer && answer.answer.length) {
      setAnswerArray(answer.answer)
    }
  }, [answer])

  const hasSubChoice = choices.find(
    (c) => c.subChoices && c.subChoices.length > 0
  )

  return (
    <div className="q-form-group">
      <div
        className={
          choices.length > 6 ? (hasSubChoice ? '' : 'multi-column') : ''
        }
      >
        {choices.map((choice, index) => {
          const isChecked =
            answerArray &&
            answerArray.find((item) => item.value === choice.title)

          const verify = _.get(answer, 'verify.choices', [])[index]
          return (
            <div key={index}>
              <div
                className={`q-form-item ${isChecked ? 'valid' : ''} ${
                  verify ? 'invalid' : ''
                }`}
                style={{breakInside:'auto', outline:'none'}}
              >
                <Checkbox
                  onClick={(e) => preUpdate({ value: choice.title })}
                  checked={isChecked}
                  disabled={
                    !isChecked && Object.keys(answer).length >= choiceLimit
                  }
                >
                  {choice.title}
                </Checkbox>

                {choice.allow_text_entry && (
                  <>
                    <Input
                      placeholder="Please type here"
                      className={verify && verify.textRequired ? 'invalid' : ''}
                      value={isChecked ? isChecked.text : ''}
                      onChange={(e) =>
                        preUpdate({ value: choice.title, text: e.target.value })
                      }
                    />

                    {verify && verify.textRequired && (
                      <p className="invalid">Please type this input</p>
                    )}
                  </>
                )}
              </div>

              {choice.subChoices && isChecked && (
                <SubChoice
                  {...{
                    choice,
                    answer: isChecked,
                    verify: _.get(answer, `verify.choices[${index}]`),
                    update: (value) =>
                      preUpdate({ value: choice.title, sub_value: value })
                  }}
                />
              )}
            </div>
          )
        })}
      </div>
      {answer.verify && answer.verify.required && (
        <p className="invalid">Please select at least 1 option</p>
      )}
    </div>
  )

  function preUpdate(answerObj) {
    let isChecked =
      answerArray && answerArray.find((item) => item.value === answerObj.value)
    if (isChecked) {
      if (answerObj.sub_value || answerObj.text) {
        isChecked = _.assignIn(isChecked, answerObj)
      } else if (answerArray.length === 1) {
        return
      } else {
        _.remove(answerArray, (an) => an.value === answerObj.value)
      }
    } else {
      answerArray.push(answerObj)
    }
    const newAnswer = _.assignIn(answer, { answer: answerArray })
    setAnswerArray(_.cloneDeep(answerArray))
    update(_.cloneDeep(newAnswer), props.question)
  }
}

function SubChoice(props) {
  const {
    choice: { subChoices, subType },
    answer,
    verify,
    update
  } = props
  const [values, setValues] = useState(new Array(subChoices.length))

  useEffect(() => {
    if (answer.sub_value) {
      setValues(answer.sub_value)
    }
  }, [answer])

  return (
    <div className="q-form-sub-group">
      {subChoices.map((item, index) => {
        return (
          <div className="q-form-item" key={index}>
            {subType === 'number' && injectElemNode(item, index)}
          </div>
        )
      })}
      {verify && verify.subChoiceRequired && (
        <p className="invalid">
          {verify.message || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )

  function injectElemNode(item, index) {
    const elem = item.title.split(' ').map((elem) => {
      if (elem === '%value') {
        const inputProps = { min: 0 }
        if (item.range) {
          const [min, max] = item.range
          inputProps.min = min
          inputProps.max = max
        }
        return (
          <InputNumber
            {...inputProps}
            value={values[index]}
            onChange={(value) => {
              values[index] = value
              setValues(values)
              update(values)
            }}
            formatter={(value) => (Number(value) && Math.round(value)) || value}
          />
        )
      } else return elem + ' '
    })
    return (
      <div className="elem-group">
        {elem.map((elem, index) => (
          <span key={index} style={{ marginRight: 5 }}>
            {elem}
          </span>
        ))}
      </div>
    )
  }
}
