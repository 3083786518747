import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import BasicForm from '../../../components/formV4'
import { passwordInitChange } from '../../../api/people'
import { passwordForm } from '../../../models/setting.form'
import { setTokenItem, saveUser } from '../../../utils/storage'
// import { whiteBoxView } from '../index.sass'
import { settingContainer } from './index.sass'
import leftArrow from '../../../asserts/icon/btn-left-arrow.svg'
import success from '../../../asserts/images/success.png'

class SettingChangePassword extends Component {
  static propTypes = {
    loginRole: PropTypes.string,
    back: PropTypes.func
  }

  state = {
    status: false,
    loading: false,
    need2FA: false,
    step: 'CHANGE_PASSWORD'
  }

  handleBackAction = () => {
    const { history, back } = this.props
    if (back) {
      back()
    } else if (history) {
      history.go(-1)
    }
  }

  submit = async () => {
    try {
      const { password } = await this.basicFormRef.handleSubmit()
      this.setState({ loading: true })
      const result = await passwordInitChange({ password })
      const { token, person } = result
      saveUser(person)
      setTokenItem(token)
      this.setState({
        loading: false,
        step: 'DONE'
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  renderPasswordForm() {
    const { loading } = this.state
    return (
      <div className="changePasswordContainer columnContainer">
        <div className="main-title">Enter a new password</div>
        <div className="sub-title">Please enter your new password</div>
        <div className="form-container">
          <BasicForm
            normalLayout
            wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
            formData={passwordForm}
          />
        </div>
        <div style={{ paddingTop: 40 }}>
          <Button
            type="primary"
            className="main-button"
            loading={loading}
            disabled={loading}
            onClick={this.submit}
          >
            Done
          </Button>
        </div>
      </div>
    )
  }

  renderPasswordUpdated() {
    return (
      <div className="changePasswordContainer columnContainer">
        <div className="main-title">Password updated!</div>
        <img src={success} alt="success" style={{ paddingTop: 72 }} />
        <div className="sub-title" style={{ width: 310, paddingTop: 40 }}>
          You have successfully updated your password, please use your new
          password when logging in
        </div>
        <div style={{ paddingTop: 60 }}>
          <Button
            type="primary"
            className="main-button"
            onClick={this.handleBackAction}
          >
            Back to Setting
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { step } = this.state
    let component
    switch (step) {
      case 'CHANGE_PASSWORD':
        component = this.renderPasswordForm()
        break
      case 'DONE':
        component = this.renderPasswordUpdated()
        break
      default:
        break
    }
    return (
      // <div className={whiteBoxView}>
      <div className="white-box-container" style={{ height: 590 }}>
        <div
          className="back"
          onClick={this.handleBackAction}
          style={{ marginLeft: 20 }}
        >
          <img
            src={leftArrow}
            alt=""
            style={{ marginRight: 10, marginTop: -1 }}
          />
          <span className="back-title">BACK</span>
        </div>
        <div className={settingContainer}>{component && component}</div>
      </div>
      // </div>
    )
  }
}

export default SettingChangePassword
