import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Tabs, Popover, Button, Checkbox } from 'antd'
import { FilterFilled } from '@ant-design/icons'
import { clientTypes } from '../../../models/client.form'
import { amountFormat } from '../../../utils/common' //'../../../utils'
import _ from 'lodash'

const Container = styled.div`
  margin: 30px 0;
  position: relative;
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px;
  }
  .ant-tabs-nav-wrap,
  .ant-tabs-dropdown-menu-item > span {
    text-transform: uppercase;
  }

  .filter-btn {
    border-radius: 6px;
    height: 40px;
    color: #616e7c !important;
    border-color: transparent !important;
    position: absolute;
    right: 0;
  }

  .ant-tabs-nav {
    width: calc(100% - 150px);
  }
  .statistics {
    text-align: center;
    font-size: 16px;
    display: flex;

    & > div {
      border: 1px solid #e6e9f2;
      background: #ffffff;
      width: 25%;
      margin: 32px 0;
      padding: 36px 0;
      &:first-child {
        border-radius: 8px 0 0 8px;
        .value {
          color: #ff6b00;
        }
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
      &:not(&:last-child) {
        border-right: none;
      }
    }
    .value {
      font-size: 48px;
      line-height: 42px;
      font-weight: 600;
      span {
        font-size: 24px;
        &:first-child {
          margin-right: -8px;
        }
        &:last-child {
          margin-left: -8px;
        }
      }
    }
  }
`
export default function Navigate(props) {
  const {
    activeKey,
    onActiveKeyChange,
    statistics,
    onFilter,
    filterTypes
  } = props
  const [filterOpen, setFilterOpen] = useState(false)

  return (
    <Container className="list-section">
      <Popover
        trigger="click"
        onOpenChange={(visible) => {
          if (filterOpen !== visible) {
            setFilterOpen(visible)
          }
        }}
        content={
          <PopContent
            filterTypes={filterTypes}
            filter={(values) => {
              onFilter(values)
              setFilterOpen(false)
            }}
          />
        }
        placement="bottomRight"
        open={filterOpen}
      >
        <Button className="filter-btn" type="default">
          Filter by Client{' '}
          <FilterFilled
            style={{ color: filterTypes.length > 0 ? '#1890ff' : '#bec7d9' }}
          />
        </Button>
      </Popover>
      <Tabs
        defaultActiveKey={activeKey}
        onChange={onActiveKeyChange}
        items={getTabItems()}
      />
    </Container>
  )

  function getTabItems() {
    let tabs = Object.keys(statistics || {}).map((key) => {
      const data = statistics[key]
      return {
        label: `${key} (${statistics[key].session_count})`,
        key,
        children: (
          <div className="statistics">
            {[
              {
                name: 'Credits Used',
                value: _.get(data, 'sessions_used', 0)
              },
              {
                name: 'Sessions Booked',
                value: data.booked_session_count
              },
              {
                name: 'Sessions Completed',
                value: data.completed_session_count
              },
              {
                name: 'Sessions Canceled',
                value: data.canceled_session_count
              },
              {
                name: 'Total Revenue',
                value: amountFormat.format(data.client_pay).substring(1),
                prefix: '$'
              }
            ].map((item, index) => (
              <div key={index}>
                <div className="value">
                  <span>{item.prefix}</span> {item.value}{' '}
                  <span>{item.suffix}</span>
                </div>
                <div className="name">{item.name}</div>
              </div>
            ))}
          </div>
        )
      }
    })

    return tabs
  }
}

const PopContainer = styled.div`
  padding: 4px 12px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  .ant-checkbox-group-item {
    display: flex;
    margin: 5px 0;
  }
  footer {
    margin: 0 -12px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    padding: 4px 12px;
    justify-content: space-between;
  }
`
function PopContent(props) {
  const [values, setValues] = useState([])
  useEffect(() => {
    setValues(props.filterTypes)
  }, [props.filterTypes])
  return (
    <PopContainer>
      <Checkbox.Group
        value={values}
        options={clientTypes}
        onChange={onChange}
      />
      <footer>
        <Button
          size="small"
          type="link"
          onClick={() => {
            setValues([])
            // props.filter([])
          }}
          disabled={values.length === 0}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => props.filter(values)}
        >
          OK
        </Button>
      </footer>
    </PopContainer>
  )

  function onChange(values) {
    setValues(values)
  }
}
