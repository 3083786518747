import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, Radio, InputNumber, DatePicker } from 'antd'
import { phsForm, normalForm } from './index.sass'
import RichTextItem from './items/richText'
import DatePickerItem from './items/datePicker'
import FileUpload from './items/fileUpload'
import TimePickerItem from './items/timePicker'
import CheckBoxItem from './items/checkBoxItem'
import SelectColor from './items/selectColor'
import CustomizedSelect from './items/customizedSelect'
import NormalSelect from './items/normalSelect'
import TreeSelectItem from './items/tree'
import CheckBoxGroup from './items/checkBoxGroup'

const { Item } = Form
export default function BasicForm(props) {
  const {
    formData,
    initialValues,
    justify,
    gutter,
    wrappedComponentRef,
    onChange,
    onEnter,
    normalLayout
  } = props
  const [formValues, setFormValues] = useState(_.cloneDeep(initialValues))
  const [form] = Form.useForm()

  useEffect(() => {
    if (wrappedComponentRef && form) {
      form.handleSubmit = async (_formData) => {
        await form.validateFields()
        const params = form.getFieldsValue(true)
        const formStruct = (_formData || formData)
          .filter(
            (item) =>
              !item.hideField ||
              (item.unless && isHide(item.unless, form, initialValues))
          )
          .map((item) => item.dbField)
          .filter((item) => item)
        return _.pick(params, formStruct)
      }

      form.syncFormValues = (updateValues) => {
        setFormValues(_.assign(formValues, updateValues))
      }
      wrappedComponentRef(form)
    }
  }, [form])

  return (
    <>
      <Form
        onKeyUp={enter}
        size="large"
        className={normalLayout ? normalForm : phsForm}
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Row
          type="flex"
          justify={justify || 'space-between'}
          gutter={gutter || 16}
        >
          {formData.map((item, index) => {
            if (
              !item.hideField ||
              (item.unless && isHide(item.unless, form, initialValues))
            ) {
              return (
                <Col
                  span={item.colSpan || 24}
                  key={index}
                  style={item.style || {}}
                >
                  <Item
                    key={index}
                    name={item.dbField}
                    label={item.label}
                    rules={item.rules || []}
                    dependencies={item.dependencies}
                    {...(item.extraValidate || {})}
                  >
                    {renderFormItem(item)}
                  </Item>
                </Col>
              )
            } else {
              return null
            }
          })}
        </Row>
      </Form>
    </>
  )

  function enter(event) {
    if (
      event.keyCode === 13 &&
      event.target.nodeName.toLowerCase() === 'input'
    ) {
      onEnter && onEnter()
    }
  }

  function onValuesChange(changedValues, allValues) {
    setFormValues(_.assign(formValues, allValues))
    if (onChange) {
      onChange(changedValues, allValues)
    }
  }

  function renderFormItem(item) {
    const { type, placeholder, disabled } = item
    switch (type) {
      case 'select':
        return (
          <NormalSelect
            options={item.options}
            {...item.extraAttr}
            disabled={disabled}
            placeholder={placeholder}
            extraAttr={item.extraAttr || {}}
          />
        )

      case 'input':
        return (
          <Input
            suffix={item.suffix}
            prefix={item.prefix}
            disabled={disabled}
            placeholder={placeholder}
            addonBefore={item.addonBefore}
          />
        )
      case 'number':
        return (
          <InputNumber
            prefix={item.prefix}
            disabled={disabled}
            placeholder={placeholder}
            {...item.extraAttr}
          />
        )

      case 'textarea':
        return (
          <Input.TextArea
            autoSize={{ minRows: 2 }}
            {...item.extraAttr}
            disabled={disabled}
          />
        )

      case 'password':
        return (
          <Input.Password
            prefix={item.prefix}
            iconRender={item.iconRender}
            disabled={disabled}
          />
        )

      case 'radios':
        return (
          <Radio.Group>
            {item.options.map(({ label, value }, index) => (
              <Radio key={index} value={value}>
                {label}
              </Radio>
            ))}
          </Radio.Group>
        )
      case 'richText':
        return <RichTextItem {...item} />
      case 'date':
        return <DatePickerItem {...item} />
      case 'originDate':
        return <DatePicker style={{ width: '100%' }} {...item.extraAttr} />

      case 'text':
        return (
          <div style={{ textAlign: item.align }}>
            {item.text || placeholder}
          </div>
        )
      case 'upload':
        return <FileUpload extraAttr={item.extraAttr || {}} />
      case 'time':
        return <TimePickerItem extraAttr={item.extraAttr} />
      case 'checkbox':
        return <CheckBoxItem {...item} />
      case 'checkboxGroup':
        return <CheckBoxGroup {...item} />
      case 'selectColor':
        return <SelectColor />
      case 'customizedSelect':
        return (
          <CustomizedSelect
            options={item.options}
            initialValue={item.defaultValue}
            disabled={!!item.disabled}
            placeholder={item.placeholder}
            extraAttr={item.extraAttr || {}}
          />
        )
      case 'treeSelect':
        return (
          <TreeSelectItem
            treeData={item.treeData}
            // initialValue={data.defaultValue}
            // disabled={data.disabled}
            extraAttr={item.extraAttr || {}}
          />
        )
      default:
        return <div>{type}</div>
    }
  }
}

function isHide(unless, form, initialValues) {
  let value
  let result = false
  const findIndex = (data, value) => {
    return data.value.findIndex((item) => item === value)
  }
  if (unless.and) {
    for (let item of unless.and) {
      value = getValue(item.key)
      if (typeof item.value === 'string') {
        result = !!(value === item.value)
      } else {
        result = !!(findIndex(item, value) !== -1)
      }
      if (item.not) {
        result = !result
      }
      if (!result) {
        return false
      }
    }
    return true
  } else if (unless.or) {
    for (let item of unless.or) {
      value = getValue(item.key)
      if (typeof item.value === 'string') {
        result = !!(value === item.value)
      } else {
        result = !!(findIndex(item, value) !== -1)
      }
      if (item.not) {
        result = !result
      }
      if (result) {
        break
      }
    }
    return result
  } else {
    value = getValue(unless.key)
    if (typeof unless.value === 'string') {
      result = !!(value === unless.value)
    } else if (!unless.value) {
      return !!value
    } else {
      result = !!(unless.value.findIndex((item) => item === value) !== -1)
    }
    if (unless.not) {
      result = !result
    }
    return result
  }

  function getValue(key) {
    return (
      form.getFieldValue(key) || (initialValues && _.get(initialValues, key))
    )
  }
}
