import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import {
  getClientList,
  getClientDetail,
  getSummaryEmail
} from '../../../models/breadcrumb.model'
import EmailList from '../../../components/summaryEmail/emailList'
import { getRole } from '../../../utils/common'

class SummaryEmail extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()

      this.setState({ person })
    }

    setClientPageTitle('Summary Email')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getSummaryEmail(person, true)
      ])
  }

  linkToWeeklySummary = (email, action) => {
    const { person } = this.state
    if (email.new_format || email.id === 'new') {
      if (action === 'preview') {
        this.props.history.push(
          `/client/${person.id}/detail/summary-email/preview/${email.id}`
        )
      } else {
        this.props.history.push(
          `/client/${person.id}/detail/summary-email/${email.id}`
        )
      }
    } else {
      this.props.history.push(
        `/client/${person.id}/detail/summary-email/backup/${email.id}`
      )
    }
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    const loginRole = getRole()
    return (
      (person && (
        <EmailList
          person={person}
          loginRole={loginRole}
          linkToWeeklySummary={this.linkToWeeklySummary}
        />
      )) || <></>
    )
  }
}

export default SummaryEmail
