import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import styled from 'styled-components'
import { CalendarIcon } from '../../../components/icons/program'
const colors = {
  rejuvenation: {
    primary: '#5C00D1',
    light: '#E2CCFF'
  },
  nutrition: {
    primary: '#7BAD2D',
    light: '#E9F4D7'
  },
  exercise: {
    primary: '#FF6B00',
    light: '#FEE2CD'
  }
}
const CardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #dee2ec;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  padding: 20px 16px;
  .title {
    color: #383e48;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tag {
      font-size: 12px;
      background: ${(props) => _.get(colors, `${props.type}.light`)};
      color: ${(props) => _.get(colors, `${props.type}.primary`)};
      padding: 4px 8px;
      border-radius: 4px;
      font-weight: 600;
      letter-spacing: 0.3px;
      text-transform: capitalize;
    }
  }
  .date-range {
    color: #4c6072;
    font-size: 14px;
    .anticon {
      margin-right: 12px;
    }
  }
  .information {
    color: #8691a7;
    font-size: 14px;
    line-height: 21px;
    margin-top: 24px;
  }
`

export default function Card(props) {
  const {
    item: { pillar, start, name, total_participants, completed_participants },
    status,
    handleClick
  } = props
  return (
    <CardContainer
      type={pillar.toLowerCase()}
      status={status}
      onClick={() => handleClick(props.item, status)}
    >
      <div className="title">
        <span>{name}</span>
        <span className="tag">{pillar}</span>
      </div>
      <div className="date-range">
        <CalendarIcon />
        <span>{moment(start).format('MMMM, YYYY')}</span>
      </div>
      {status !== 'inActive' && (
        <div className="information">
          <span>{total_participants} Total participants</span>
          <br />
          {status === 'past' && (
            <span>{completed_participants} Challenge completed</span>
          )}
        </div>
      )}
    </CardContainer>
  )
}
