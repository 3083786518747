import React, { Component } from 'react'
import { Button } from 'antd'
import Header from './header'
import Location from './location'
import { BackIcon } from '../../../components/icons'
import { CameraIcon } from '../../../components/icons/facility'
import { saveCheckInRecord } from '../../../api/checkin'
import { base64toBlob } from '../../../utils/common'
import {
  getCheckInSercive,
  getCheckInFacility,
  getCheckIn,
  setCheckIn
} from '../../../utils/storage'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

class TakePhoto extends Component {
  static propTypes = {}

  state = {
    avatar: null,
    errorStatus: null
  }

  upload = (file) => {
    const {
      match: {
        params: { expertId }
      }
    } = this.props
    const { service, checkIn, facility } = this.state
    file = file || this.state.file
    try {
      saveCheckInRecord(
        {
          facility_id: facility.id,
          service_id: service.id,
          file,
          expert_id: expertId
        },
        checkIn.id
      )
        .then((result) => {
          setCheckIn(result.data.checkin)
          this.props.history.push('/check-in/finish')
        })
        .catch((err) => {
          throw err
        })
    } catch (err) {
      console.error(err)
    }
  }

  onTakePhoto = (dataUri) => {
    let [contentType, realData] = dataUri.split(';')
    contentType = contentType.replace('data:', '')
    realData = realData.split(',')[1]
    const file = base64toBlob(realData, contentType)
    this.setState({ file, avatar: dataUri, cameraWorking: false })
  }

  onCameraError = (optional) => {
    console.error(optional)
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.setState({ cameraWorking: false, errorStatus: 'forbid' })
    } else {
      this.setState({ cameraWorking: false, errorStatus: 'nonsupport' })
    }
  }

  componentDidMount() {
    const checkIn = getCheckIn()
    const facility = getCheckInFacility()
    const serviceId = getCheckInSercive()
    const service = facility.services.find(
      (item) => item.id === Number(serviceId)
    )
    this.setState({ service, checkIn, facility })
  }

  render() {
    const { avatar, cameraWorking, facility, errorStatus } = this.state
    return (
      <div className="avatar">
        <Header history={this.props.history} />
        {facility && <Location location={facility.name} />}
        {errorStatus ? (
          errorStatus === 'forbid' ? (
            <p className="check-title">
              Seems the browser not allow staging.apeiron.life to access camera.
            </p>
          ) : (
            <p className="check-title">
              Get an unknown error, maybe the browser is incompatible, please
              use Safari instead.
            </p>
          )
        ) : avatar ? (
          <p className="check-title">Would you like to use this photo?</p>
        ) : (
          <p className="check-title">
            Great! First, let’s take a photo for your profile.
          </p>
        )}
        <div className="uplaod">
          {avatar && (
            <div className="avatar-box">
              <img src={avatar} alt="avatar" />
            </div>
          )}

          {cameraWorking && (
            <>
              <BackIcon
                onClick={() => this.setState({ cameraWorking: false })}
                className="cancel-take-photo"
              />
              <Camera
                onTakePhoto={this.onTakePhoto}
                onCameraError={this.onCameraError}
              />
            </>
          )}
          <br />
          {!cameraWorking &&
            (avatar ? (
              <div className="btn-group">
                <Button
                  ghost
                  onClick={() =>
                    this.setState({ cameraWorking: true, avatar: null })
                  }
                >
                  Retake
                </Button>
                <Button ghost onClick={() => this.upload()}>
                  Yes
                </Button>
              </div>
            ) : errorStatus ? (
              ''
            ) : (
              <Button onClick={() => this.setState({ cameraWorking: true })}>
                <CameraIcon />
                Take Photo
              </Button>
            ))}
        </div>
      </div>
    )
  }
}

export default TakePhoto
