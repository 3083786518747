import React from 'react'
import { Modal } from 'antd'
import _ from 'lodash'
import styled from 'styled-components'
import moment from 'moment'
import { Pluralize, firstUpperCase, getRole } from '../../../utils/common'
import AppStore from '../../../asserts/images/app-store.svg'
import AppStoreQrCode from '../../../asserts/images/app-store-qr-code.png'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import Graph from '../../../components/assessmentReview/comp/graph'

const { info } = Modal

const HealthContainer = styled.section`
  .tags {
    display: flex;
    justify-content: flex-end;
    margin-top: -47px;
    span {
      margin-left: 16px;
      display: block;
      padding: 2px 8px;
      background: rgba(217, 220, 227, 0.2);
      border: 1px solid rgba(33, 57, 89, 0.2);
      border-radius: 19px;
      color: #5a657c;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 12px;
      svg {
        margin-right: 6px;
        vertical-align: middle;
        margin-top: -2px;
      }
    }
    &.User {
      margin-top: -67px;
    }
  }
  .container {
    display: flex;
    margin-top: 47px;
    margin-right: -150px;
  }
`

export default function HealthProfile(props) {
  const {
    compareData,
    dataList,
    person,
    latest_bmi,
    latest_height,
    latest_weight,
    ui_version,
    id
  } = props
  const loginRole = getRole()
  return (
    <HealthContainer id={id}>
      <h1 className="section-title">Health Profile</h1>
      <div className={`${loginRole} tags`}>
        <span>
          <Circle fillOpacity={0.2} fill="#7FB800" stroke="#7FB800" />
          Latest
        </span>
        <span>
          <Circle fillOpacity={0.2} dasharray fill="#7FB800" stroke="#7FB800" />
          Estimate
        </span>
        <span>
          <Circle fillOpacity={0.2} fill="#0D2350" stroke="#0D2350" />
          Previous
        </span>
        <span>
          <Circle fillOpacity={1} fill="white" stroke="#0D2350" />
          carried forward
        </span>
      </div>
      <div className="container">
        <Detail
          person={person}
          {...{ latest_height, latest_weight, latest_bmi, ui_version }}
        />
        <Graph {...{ compareData, dataList, fromAR: true }} />
      </div>
    </HealthContainer>
  )
}

const DetailContainer = styled.div`
  width: 400px;
  .user {
    height: fit-content;
    margin-top: 67px;
    padding: 32px 24px;
    border: 1px solid #e3e6ea;
    border-radius: 4px;
    .profile-item {
      width: 50%;
      display: inline-block;
      letter-spacing: 0.3px;
      font-size: 15px;
      margin-bottom: 30px;
      .label {
        color: #94a0aa;
        margin-bottom: 20px;
      }
      .value {
        color: #4c6072;
      }
    }
  }
  img {
    padding-top: 20px;
    cursor: pointer;
  }
`

const QrCodeContainer = styled.div`
  text-align: center;
  p {
    padding-top: 32px;
    margin: 0px;
    font-family: 'Gilroy';
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.3px;
    color: #4c6072;
  }
`

function Detail(props) {
  const {
    person,
    latest_bmi: bmi,
    latest_height,
    latest_weight,
    ui_version
  } = props
  const age = moment().diff(person.profile.date_of_birth, 'years')
  const months = person.profile.client_since
    ? moment().diff(
        moment(person.profile.client_since).format('YYYY-MM-DD'),
        'months'
      )
    : null
  const height = latest_height || person.profile.height_inch
  const weight = latest_weight || person.profile.weight_lbs

  const profileItems = [
    {
      label: 'Age',
      value: age ? `${age} ${Pluralize('years', age)} old` : '--'
    },
    {
      label: 'Gender',
      value: firstUpperCase(person.profile.gender)
    },
    {
      label: 'Height',
      value: height ? `${height} inches` : '--'
    },
    {
      label: 'Weight',
      value: weight ? `${weight} lbs` : '--'
    },
    {
      label: 'BMI',
      value: _.isFinite(bmi) ? bmi + ' kg/m²' : '--',
      unit: 'kg/m²'
    },
    {
      label: 'Apeiron',
      value: months ? `${months} ${Pluralize('months', months)}` : '--'
    }
  ]

  return (
    <DetailContainer>
      <div className="user">
        {profileItems.map((item, index) => (
          <div className="profile-item" key={index}>
            <div className="label">{item.label}</div>
            <div className="value">{item.value}</div>
          </div>
        ))}
      </div>
      {ui_version && (
        <img
          src={AppStore}
          alt=""
          onClick={() => {
            info({
              bodyStyle: {
                padding: '24px 24px 0px 24px'
              },
              content: (
                <QrCodeContainer>
                  <img src={phsLogo} alt="logo" />
                  <p>
                    Use your phone to scan the QR code to download the Apeiron
                    app.
                  </p>
                  <img
                    src={AppStoreQrCode}
                    alt="qrCode"
                    style={{ width: 300 }}
                  />
                </QrCodeContainer>
              ),
              width: '410px',
              cancelButtonProps: { style: { display: 'none' } },
              okButtonProps: { style: { display: 'none' } },
              icon: <></>,
              closable: true
            })
          }}
        />
      )}
    </DetailContainer>
  )
}

function Circle(props) {
  const { fillOpacity, fill, stroke, dasharray } = props
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill={fill}
        fillOpacity={fillOpacity}
        stroke={stroke}
        strokeWidth="2"
        strokeDasharray={dasharray ? '2,2' : null}
      />
    </svg>
  )
}
