import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { getAxisX } from '../common'
import { SvgText } from '../../icons/weeklySummary'

function LDot(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="10" fill={props.fill || '#7BAD2D'} />
      <path
        d="M6.66666 10L9.16666 12.5L13.3333 7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function Dot(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="14" height="14" rx="7" fill={props.fill || '#7BAD2D'} />
      <path
        d="M4.66666 7L6.41666 8.75L9.33332 5.25"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

class CheckDocs extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    entries: PropTypes.array,
    showLabel: PropTypes.bool
  }

  render() {
    const { startDate, endDate, entries, showLabel, size } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    return (
      <div className="yes-no-chart">
        {tickValuesX.map((item, index) => (
          <div className="check-dot" key={index}>
            {size ? (
              <LDot fill={entries[index] === 'yes' ? '#7BAD2D' : '#C9CDD2'} />
            ) : (
              <Dot fill={entries[index] === 'yes' ? '#7BAD2D' : '#C9CDD2'} />
            )}
            <br />
            {showLabel && (
              <SvgText
                text={moment(item)
                  .format('ddd')
                  .toUpperCase()}
                width={22}
                height={10}
                fontWeight="normal"
                fontFamily="Gilroy"
                fontSize={10}
                fill="#70808E"
              />
            )}
          </div>
        ))}
      </div>
    )
  }
}

export default CheckDocs
