import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import mapDispatchToProps from '../dispatch'
import DraftReport from '../../../components/assessmentReview/draft'
import PreviewReport from '../../../components/assessmentReview/preview'
import PreviewReportV1 from '../../../pages/assessmentReviewV1/preview'
import List from '../../../components/assessmentReview'
import NoMatch from '../../noMatch'
import {
  getClientList,
  getClientDetail,
  getAssessmentReview
  // getAssessmentReviewDraft,
  // getAssessmentReviewPreview
} from '../../../models/breadcrumb.model'

class AssessmentReview extends Component {
  static propTypes = {}

  state = {}

  setBreadcrumb = (person, isDetail) => {
    const { setClientBreadcrumb, location } = this.props
    if (!isDetail && location.pathname.includes('/ass-report/')) return
    person = person || this.state.person
    const breadcrumb = [
      getClientList(),
      getClientDetail(person),
      getAssessmentReview(person.id)
    ]
    if (isDetail) {
      breadcrumb.push({
        ...isDetail
      })
    }
    setClientBreadcrumb(breadcrumb)
  }

  initial = async () => {
    const { getPerson } = this.props

    const person = await getPerson()
    this.setState({ person })
    this.setBreadcrumb(person)
  }

  componentDidMount() {
    this.props.setClientPageTitle(null)
    this.initial()
  }

  render() {
    const { match, getPerson } = this.props
    return (
      <>
        <Switch>
          <Route
            exact
            path={`${match.url}`}
            render={(props) => (
              <List
                {...props}
                getPerson={getPerson}
                setBreadcrumb={this.setBreadcrumb}
              />
            )}
          />
          <Route
            path={`${match.url}/draft/:id`}
            render={(props) => (
              <DraftReport
                {...props}
                getPerson={getPerson}
                setBreadcrumb={this.setBreadcrumb}
              />
            )}
          />
          <Route
            path={`${match.url}/preview/:id`}
            render={(props) => (
              <PreviewReport
                {...props}
                getPerson={getPerson}
                setBreadcrumb={this.setBreadcrumb}
              />
            )}
          />
          <Route
            path={`${match.url}/preview-v1/:id`}
            render={(props) => (
              <PreviewReportV1
                {...props}
                getPerson={getPerson}
                setBreadcrumb={this.setBreadcrumb}
              />
            )}
          />
          <Route component={NoMatch} />
        </Switch>
      </>
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(AssessmentReview)
