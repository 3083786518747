import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Row, Col, Popover, Button } from 'antd'
import Metric from './metric'
import {
  createCategoryForm,
  createMetric
} from '../../../models/bioMarkers.form'

const SubCategory = styled.div`
  min-width: 300px;
  margin: 0 10px;
  border: ${(props) => (props.isDragging ? '1px solid white' : 'none')};
  border-radius: 4px;
  background: #e4f1fd;
`

const TaskList = styled.div`
  min-height: 100px;
  background-color: ${(props) =>
    props.isDraggingOver ? 'rgba(41,127,202,0.5)' : 'transparent'};
`

class Category extends Component {
  static propTypes = {
    category: PropTypes.object
  }

  renderBiomarkerGroup() {
    const {
      path,
      history,
      biomarkersFiled,
      deleteBiomarker,
      category
    } = this.props
    const biomarkers = category[biomarkersFiled]
    const containerSize = 4
    const length =
      biomarkers &&
      biomarkers.length > 0 &&
      Math.ceil(biomarkers.length / containerSize)
    let cols
    if (length) cols = new Array(containerSize).fill(0)
    else cols = []
    return (
      <>
        {cols.map((empty, index) => (
          <Col span={6} key={index}>
            <Droppable
              droppableId={`metric-${category.id}-${index}-${length}`}
              type="metric"
            >
              {(provided, snapshot) => (
                <TaskList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {biomarkers
                    .slice(index * length, (index + 1) * length)
                    .map((item, listIndex) => (
                      <Metric
                        key={item.id}
                        {...{ path, history, metric: item, index: listIndex }}
                        deleteBiomarker={() => deleteBiomarker(item, category)}
                      />
                    ))}
                  {provided.placeholder}
                </TaskList>
              )}
            </Droppable>
          </Col>
        ))}
      </>
    )
  }

  renderSubcategoryHeader(item) {
    const { biomarkersFiled, deleteCategory, setPropsState } = this.props
    return (
      <h3>
        <Popover
          placement="bottomLeft"
          content={<p style={{ maxWidth: '200px' }}>{item.description}</p>}
        >
          <span>{item.name}</span>
        </Popover>
        {(!item[biomarkersFiled] || !item[biomarkersFiled].length) && (
          <CloseOutlined
            onClick={(_) => {
              deleteCategory(item)
            }}
            style={{
              color: 'red',
              cursor: 'pointer',
              margin: '0 5px'
            }} />
        )}
        <EditOutlined
          style={{
            cursor: 'pointer',
            margin: '0 5px'
          }}
          onClick={() =>
            setPropsState({
              visible: true,
              submitType: 'edit sub-category',
              valueForm: item,
              categoryId: item.id,
              modalTitle: 'Edit category',
              formData: createCategoryForm
            })
          } />
      </h3>
    )
  }

  renderSubCategoryBody(item) {
    const {
      biomarkersFiled,
      path,
      history,
      deleteBiomarker,
      category
    } = this.props
    return (
      <Droppable
        droppableId={`subCategory-${item.id}-${category.id}`}
        type="metric"
      >
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {item[biomarkersFiled] &&
              item[biomarkersFiled].map((biomarker, listIndex) => (
                <Metric
                  key={biomarker.id}
                  {...{
                    path,
                    history,
                    metric: biomarker,
                    index: listIndex
                  }}
                  deleteBiomarker={() => deleteBiomarker(biomarker, item)}
                />
              ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    )
  }

  renderSubCategory() {
    const { category, setPropsState } = this.props
    const subCategory = category.child_categories || []
    return (
      <>
        {subCategory.map((item, index) => (
          <Draggable
            draggableId={`subCategory-${item.id}-${index}`}
            index={index}
            key={index}
          >
            {(provided, snapshot) => (
              <SubCategory
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                className="item-inner-list biomarkers"
              >
                {this.renderSubcategoryHeader(item)}
                {this.renderSubCategoryBody(item)}
                <Button
                  type="primary"
                  ghost
                  onClick={() =>
                    setPropsState({
                      visible: true,
                      submitType: 'add metric for sub-category',
                      valueForm: {},
                      categoryId: item.id,
                      modalTitle: 'Add Metric',
                      formData: createMetric
                    })
                  }
                >
                  {' '}
                  Add Metric
                </Button>
              </SubCategory>
            )}
          </Draggable>
        ))}
      </>
    )
  }

  render() {
    const { category } = this.props

    return category.child_categories && category.child_categories.length > 0 ? (
      <Droppable
        droppableId={`category-${category.id}`}
        type="category"
        direction="horizontal"
        style={{ display: 'flex' }}
      >
        {(provided, snapshot) => (
          <div
            className="sub-category"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              padding: 5,
              background: snapshot.isDraggingOver
                ? 'rgba(41,127,202,0.5)'
                : 'transparent'
            }}
          >
            {this.renderSubCategory()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    ) : (
      <Row type="flex" className="biomarkers" gutter={16}>
        {this.renderBiomarkerGroup()}
      </Row>
    )
  }
}

export default Category
