import React from 'react'
import { Modal, Button } from 'antd'

export default function(props) {
  return (
    <Modal
      open={props.visible}
      footer={null}
      centered={true}
      closable={false}
      keyboard={false}
      maskClosable={false}
      bodyStyle={{
        textAlign: 'center'
      }}
      style={{
        width: 359
      }}
    >
      <h2>Connecting...</h2>
      <Button
        ghost
        type="primary"
        onClick={props.cancel}
        style={{ width: 140 }}
      >
        Cancel
      </Button>
    </Modal>
  )
}
