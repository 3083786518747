import React from 'react'
import moment from 'moment'
import { getOverviewSummary, saveOverviewSummary } from '../../api/microGoals'
import { saveRPE } from '../../api/summaryEmail'
import { getLiveProgram } from '../../api/program'
import { getSum } from '../../utils/common'
import { getShowWeeklyDate } from '../../utils/dateHandles'
import { getHomeNutritionScore } from '../../api'

export default (WrappedComponent) =>
  class PillarWrapper extends React.Component {
    state = {
      overview: {}
      // startDate:
    }

    initial = async (props, forceRefresh = false) => {
      this.setState({ overviewLoading: true })
      if (this.state.overviewLoading && !forceRefresh) {
        return
      }
      let {
        person,
        weekRange: { startDate, endDate }
      } = props || this.props
      if (
        !startDate ||
        !endDate ||
        (this.state.overviewLoading && !forceRefresh)
      )
        return
      this.setState({
        overview: {},
        programData: null
      })

      try {
        const overview = await getOverviewSummary(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          person.id
        )
        const nutritionScore = await getHomeNutritionScore(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          person.id
        )

        const { heart_rate } = overview
        const entries = (heart_rate && heart_rate.achieving) || []
        const heartRateTypes = [
          { name: 'Z1', color: '#C9CDD2' },
          { name: 'Z2', color: '#F7AD69' },
          { name: 'Z3', color: '#FB9043' },
          { name: 'Z4', color: '#FC7558' },
          { name: 'Z5', color: '#FF501A' }
        ]
        const totalHeartRate = heartRateTypes.map((item, index) => ({
          ...item,
          value:
            index > 0 &&
            getSum(
              entries.map((item) =>
                Math.round(item.heart_rate_zone[index] / 60)
              )
            ) + 'm'
        }))

        totalHeartRate.shift()

        const heartRatePercentage = heartRateTypes.map((item, index) => ({
          ...item,
          value:
            ((heart_rate &&
              heart_rate.ascm_percentage &&
              heart_rate.ascm_percentage[index - 1]) ||
              0) + '%'
        }))

        heartRatePercentage.shift()

        // --->
        // const { exercise: programData } = await this.getProgramData(
        //   person.id,
        //   moment(startDate).format('YYYY-MM-DD'),
        //   moment(endDate).format('YYYY-MM-DD')
        // )

        // const expiredDate = moment().isAfter(endDate, 'day')

        const state = {
          overview,
          nutritionScore,
          expiredDate: moment().isAfter(endDate, 'day'),
          // startDate: moment(startDate).clone(),
          // endDate: moment(endDate).clone(),
          overviewLoading: false,
          totalHeartRate: totalHeartRate.reverse(),
          heartRatePercentage: heartRatePercentage.reverse(),
          showRangeDate: getShowWeeklyDate(startDate, endDate)
        }
        this.setState(state)
      } catch (err) {
        console.error(err)
      }
    }

    getProgramData = async (personId, from, to) => {
      let result = await getLiveProgram(personId, from, to)
      if (result.program && result.program.items.length === 0) {
        result = {
          program: null,
          summary: null
        }
      }
      return result
    }

    EditProgramItemCallback = async (workoutEdited) => {
      const { person } = this.props
      const { startDate, endDate } = this.state
      const programData = await this.getProgramData(
        person.id,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD')
      )
      this.setState({ programData })
      if (workoutEdited) {
        const overview = await getOverviewSummary(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          person.id
        )
        this.setState({ overview })
      }
    }

    editWeightCallback = async (workoutEdited) => {
      const { person } = this.props
      const { startDate, endDate } = this.state
      const overview = await getOverviewSummary(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        person.id
      )
      this.setState({ overview })
    }

    setManualRpe = async (id, rpe) => {
      const { overview } = this.state
      try {
        document.body.style.cursor = 'wait !important'
        const result = await saveRPE(id, rpe)
        const target = overview.entries.find((entry) => entry.id === id)
        target.rpe_manual = result.rpe_manual
        target.training_load = result.training_load
        document.body.style.cursor = 'auto'
        return result
      } catch (err) {
        document.body.style.cursor = 'auto'
      }
    }

    componentDidUpdate() {
      const nextProps = this.props
      const { startDate, endDate } = nextProps.weekRange
      if (
        (!this.state.startDate && startDate) ||
        moment(this.state.startDate).format('YYYY-MM-DD') !==
          moment(startDate).format('YYYY-MM-DD')
      ) {
        this.setState({
          startDate: startDate.clone(),
          endDate: endDate.clone()
        })
        this.initial(nextProps)
      }
    }

    submit = async (dataArr) => {
      const { person } = this.props
      this.setState({ overviewLoading: true })
      try {
        await saveOverviewSummary(person.id, dataArr)
        this.setState({ overviewLoading: false })
        await this.initial(this.props, true)
      } catch (err) {
        this.setState({ overviewLoading: false })
      }
    }

    render() {
      const {
        overview,
        overviewLoading,
        totalHeartRate,
        heartRatePercentage,
        showRangeDate,
        programData,
        expiredDate,
        nutritionScore
      } = this.state
      const childProps = {
        ...this.props,
        overview,
        overviewLoading,
        nutritionScore,
        totalHeartRate,
        heartRatePercentage,
        showRangeDate,
        programData,
        expiredDate,
        EditProgramItemCallback: this.EditProgramItemCallback,
        editWeightCallback: this.editWeightCallback,
        submitMetrics: this.submit,
        setManualRpe: this.setManualRpe
      }
      return <WrappedComponent {...childProps} {...this.props} />
    }
  }
