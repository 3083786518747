import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { SvgText } from '../../icons/weeklySummary'
import { getAxisX, calculateElementWidth } from '../../summaryEmail/common'
import { getSum } from '../../../utils/common'
import { fontFamily } from '../../../utils/constant'
import { modalityListStyle } from './styles/modality.sass'

let typeList = [
  {
    name: 'Aerobic',
    color: '#FF6B00'
  },
  {
    name: 'Resistance',
    color: '#58646B'
  },
  {
    name: 'Activity',
    color: '#BCCA70'
  },
  {
    name: 'Movement Quality',
    color: '#C29E7C'
  }
]
// let typeList = [
//   {
//     name: 'Walking',
//     color: 'rgba(243,181,85,.3)',
//     duration: 64
//   },
//   {
//     name: 'Running',
//     color: '#F3B555',
//     duration: 28
//   },
//   {
//     name: 'Swimming',
//     color: '#D76829',
//     duration: 35
//   },
//   {
//     name: 'Cycling',
//     color: '#A04917',
//     duration: '32'
//   },
//   {
//     name: 'Cardio',
//     color: '#FF501A',
//     duration: 26
//   },
//   {
//     name: 'Cross Training',
//     color: '#C29E7C',
//     duration: 27
//   },
//   {
//     name: 'Strength',
//     color: '#58646B',
//     duration: 95
//   },
//   {
//     name: 'Yoga',
//     color: '#C9D48A',
//     duration: 34
//   },
//   {
//     name: 'Other',
//     color: '#B9C8D1',
//     duration: 27
//   }
// ]

class Modality extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    overview: PropTypes.object,
    totalHeartRate: PropTypes.array
  }

  state = {}

  xAxisFormat = (x) => {
    const Weeks = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    return Weeks[moment(x).format('d')]
  }

  getMaxInputByBarData = (dataBars) => {
    const arr = dataBars.map((bar) => bar.data.map((item) => item.y)) || []
    const nunmberArr = [0].concat(...arr)
    return Math.max(...nunmberArr)
  }

  dataHandle(axisX) {
    const {
      overview: { entries }
    } = this.props
    const workouts = _.cloneDeep(entries)
      .filter((item) => item.pillar === 'exercise')
      .map((item) => {
        const { details } = item
        item.type =
          (details &&
            (details.shift_activity_classification ||
              details.activity_classification)) ||
          item.type
        return item
      })
    let bars = new Array(...typeList).reverse().map((item) => {
      const data = axisX.map((x) => {
        const target = workouts.filter((entry) => {
          const isCurrentDay =
            entry.record_date === moment(x).format('YYYY-MM-DD')

          return isCurrentDay && entry.type === item.name
        })
        let y = null
        if (target.length > 0) {
          y = getSum(
            target.map((t) => Math.round(t.details.duration / 60) * 60 || 0)
          )
        }

        return { x, y, y0: 0 }
      })
      const duration = getSum(
        data.filter((d) => d.y).map((d) => Math.round(d.y / 60))
      )
      return { ...item, data, duration }
    })
    bars = bars.filter((item) => item.duration)

    function barDataHandle(i) {
      const barData = bars[i].data
      if (i > 0) {
        const referData = bars[i - 1].data
        barData.map((item) => {
          const target = referData.find((t) => t.x.isSame(item.x))
          item.y0 = target.y || target.y0
          if (typeof item.y === 'number') {
            item.y += item.y0
          }
          return item
        })
      }
    }

    for (const i in bars) {
      barDataHandle(i)
    }
    return bars
  }

  processData() {
    const { startDate, endDate } = this.props
    const axisX = getAxisX(startDate, endDate)
    const dataBars = this.dataHandle(axisX)
    const maxInput = this.getMaxInputByBarData(dataBars)
    this.setState({ axisX, dataBars, maxInput })
  }

  componentDidMount() {
    this.processData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.processData()
    }
  }

  renderPlaceholderBar() {
    const { dataBars, axisX, maxInput } = this.state

    const dataArr = axisX.map((item) => {
      let arr = dataBars.map((bar) => {
        const result = bar.data.find(
          (data) => item.format('YYYY_MM_DD') === data.x.format('YYYY_MM_DD')
        )
        return result && result.y
      })
      const max = Math.max(...arr)
      const noData = max > 0 ? false : true
      const cornerRadius =
        (max / maxInput) * 100 < 6 ? (max / maxInput) * 100 : 6
      return {
        x: item,
        y: noData ? (item.y = maxInput / 100 || 1) : max,
        noData: noData,
        cornerRadius
      }
    })

    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: (datum) => (!datum.noData ? 'transparent' : '#DAE1EA'),
            stroke: '#fff',
            strokeWidth: (datum) => (!datum.noData ? 3 : 0)
          }
        }}
        barWidth={
          this.props.barWidth
            ? this.props.barWidth
            : (datum) => (!datum.noData ? 15 : 12)
        }
        data={dataArr}
        cornerRadius={{
          top: (datum) => (!datum.noData ? datum.cornerRadius : 0),
          bottom: (datum) => (!datum.noData ? datum.cornerRadius : 0)
        }}
      />
    )
  }

  renderBar(barData, index) {
    const dataStyle = {
      fill: barData.color || '#264382',
      stroke: '#fff',
      strokeWidth: 1
    }

    return (
      <VictoryBar
        key={index}
        offsetX={10}
        style={{
          data: dataStyle
        }}
        // cornerRadius={{
        //   top: (datum) => datum.topRadius || 0, //( ? 4 : 0),
        //   bottom: (datum) => datum.bottomRadius || 0 //( ? 4 : 0)
        // }}
        barWidth={this.props.barWidth || 12}
        data={barData.data}
      />
    )
  }

  renderChart(dataBars, axisX) {
    const { maxInput } = this.state
    return (
      <div className="chart">
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart
              width={width}
              height={100}
              padding={{
                top: 10,
                left: this.props.paddingLeft || 10,
                bottom: 20,
                right: 10
              }}
            >
              <VictoryAxis
                padding={{ left: 10, right: 10 }}
                style={
                  this.props.xAxisStyle || {
                    axis: {
                      stroke: 'transparent'
                    },
                    tickLabels: {
                      fontFamily,
                      fontSize: '10px',
                      fill: '#B0BAC9'
                    }
                  }
                }
                tickValues={axisX}
                tickLabelComponent={
                  <VictoryLabel
                    text={(datum) => {
                      let result = this.props.xAxisFormat
                        ? this.props.xAxisFormat(datum)
                        : this.xAxisFormat(datum)
                      return result
                    }}
                  />
                }
              />
              {!maxInput && (
                <VictoryAxis
                  dependentAxis
                  tickValues={[0, 100]}
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fill: 'transparent'
                    }
                  }}
                />
              )}
              {dataBars.map((barData, index) => this.renderBar(barData, index))}
              {this.renderPlaceholderBar()}
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      </div>
    )
  }

  render() {
    const { axisX, dataBars } = this.state
    return (
      <div className={`${this.props.extraClass} ${modalityListStyle}`}>
        {axisX && dataBars && this.renderChart(dataBars, axisX)}
        <ul className="modality-type-list">
          {dataBars &&
            new Array(...dataBars).reverse().map((item, index) => (
              <li key={index}>
                <div>
                  <span style={{ background: item.color }} />
                  {/* {item.name} */}
                  <SvgText
                    text={item.name}
                    height={16}
                    fontSize={12}
                    fill="#8596A4"
                    fontFamily="Gilroy"
                    width={
                      calculateElementWidth('span', item.name, '12px').width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                </div>

                <div>
                  <SvgText
                    text={`${item.duration}m`}
                    height={16}
                    fontSize={12}
                    fill="#383E48"
                    width={
                      calculateElementWidth('span', `${item.duration}m`, '12px')
                        .width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                </div>
              </li>
            ))}
        </ul>
      </div>
    )
  }
}

export default Modality
