import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Switch } from 'antd'
import ChangeEmail from './emailForm'
import { setNotification } from '../../../api/people'
import ConfirmPopUp from './confirm'

import { style, settingStyle } from './index.sass'

import jcbIcon from '../../../asserts/icon/cards/icon-jcb-card.svg'
import amexIcon from '../../../asserts/icon/cards/icon-amex-card.svg'
import visaIcon from '../../../asserts/icon/cards/icon-visa-card.svg'
import mastercardIcon from '../../../asserts/icon/cards/icon-mastercard-card.svg'

class ProfileSetting extends Component {
  static propTypes = {
    user: PropTypes.object,
    card: PropTypes.object,
    changePassword: PropTypes.func,
    changePayment: PropTypes.func,
    linkTo2FA: PropTypes.func,
    loginRole: PropTypes.string
  }

  state = {
    user: null,
    showEmailForm: false,
    person: null,
    loading: null,
    notificationType: 'email',
    showConfirm: false
    // allow_email: false,
    // allow_app_notification
  }

  changeNotifications = async (checked, field) => {
    const user = this.state.person || this.props.user
    if (user) {
      this.setState({ loading: field })
      try {
        await setNotification({ [field]: checked }, user.id)
        user.profile[field] = checked
        this.setState({ loading: null, person: user })
        this.props.updateSync(user)
      } catch (err) {
        this.setState({ loading: null })
      }
    }
  }

  // initial = async () => {
  //   const user = this.state.person || this.props.user
  //   const result = await getNotificationStatus(user.id)
  //   console.log('result===', result)
  // }

  // componentDidMount() {
  //   this.initial()
  // }

  render() {
    let {
      user,
      card,
      changePassword,
      changePayment,
      linkTo2FA,
      loginRole
    } = this.props
    const {
      showEmailForm,
      person,
      loading,
      notificationType,
      showConfirm,
      notifyMessage
    } = this.state
    user = person || user || { profile: {} }
    let cardIcon
    switch (card.brand) {
      case 'American Express':
        cardIcon = amexIcon
        break
      case 'JCB':
        cardIcon = jcbIcon
        break
      case 'MasterCard':
        cardIcon = mastercardIcon
        break
      case 'Visa':
        cardIcon = visaIcon
        break
      default:
        break
    }
    return (
      <div className={style}>
        <div className="title">
          Settings
          <div className="line" />
        </div>

        <div className={settingStyle}>
          <div className="box-view signed-forms">
            <div className="box-title-container">
              <div className="box-title">Email Address</div>
              <div className="box-subtitle">
                Your email address is &nbsp;
                <span className="box-point-text">{user && user.email}</span>
              </div>
            </div>
            <Button
              className="box-button"
              onClick={() => this.setState({ showEmailForm: true })}
            >
              Change
            </Button>
          </div>
          {loginRole !== 'Expert' && (
            <div className="box-view signed-forms">
              <div className="box-title-container">
                <div className="box-title">Password</div>
              </div>
              <Button className="box-button" onClick={changePassword}>
                Change
              </Button>
            </div>
          )}
          <div className="box-view signed-forms">
            <div className="box-title-container">
              <div className="box-title">Payment Method</div>
              <div className="box-subtitle">
                {card && card.last4 && (
                  <img src={cardIcon} alt="" style={{ marginRight: 12 }} />
                )}
                {card && card.last4
                  ? `Ending in ${card.last4}`
                  : 'No payment method is available'}
              </div>
            </div>
            <Button className="box-button" onClick={changePayment}>
              {card && card.last4 ? 'Change' : 'Add'}
            </Button>
          </div>
          {loginRole !== 'Expert' && (
            <div className="box-view signed-forms">
              <div className="box-title-container">
                <div className="box-title">2-Factor Authentication</div>
                <div className="box-subtitle">
                  {user.profile && user.profile.enabled_2fa ? (
                    <>
                      Enabled with Trusted phone number:
                      <span className="box-point-text">
                        &nbsp;&nbsp;{user.profile.phone_number}
                      </span>
                    </>
                  ) : (
                    <> Turn on 2FA now to keep your data safe </>
                  )}
                </div>
              </div>
              {!user.profile.enabled_2fa && (
                <Button className="box-button" onClick={linkTo2FA}>
                  Enable Now
                </Button>
              )}
            </div>
          )}

          {/* notification Allow Emails  */}
          <div className="box-view has-items">
            <div className="box-title-container">
              <div className="box-title">Emails Notifications</div>
              {/* <div className="box-subtitle">Allow all emails notifications</div> */}
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow workout email notifications
              </div>
              <Switch
                checked={user.profile.allow_workout_email}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_workout_email',
                      notifyMessage: 'workout email'
                    })
                  } else {
                    this.changeNotifications(checked, 'allow_workout_email')
                  }
                }}
                loading={loading === 'allow_workout_email'}
              />
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow FFQ email notifications
              </div>
              <Switch
                checked={user.profile.allow_ffq_email}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_ffq_email',
                      notifyMessage: 'ffq email'
                    })
                  } else {
                    this.changeNotifications(checked, 'allow_ffq_email')
                  }
                }}
                loading={loading === 'allow_ffq_email'}
              />
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow chat email notifications
              </div>
              <Switch
                disabled
                checked={user.profile.allow_chat_email}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_chat_email',
                      notifyMessage: 'chat email'
                    })
                  } else {
                    this.changeNotifications(checked, 'allow_chat_email')
                  }
                }}
                loading={loading === 'allow_chat_email'}
              />
            </div>
          </div>

          {/* notification Allow App Notifications */}
          <div className="box-view has-items">
            <div className="box-title-container">
              <div className="box-title">App Push Notifications</div>
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow workout push notifications in app
              </div>
              <Switch
                checked={user.profile.allow_workout_notification}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_workout_notification',
                      notifyMessage: 'workout app'
                    })
                  } else {
                    this.changeNotifications(
                      checked,
                      'allow_workout_notification'
                    )
                  }
                }}
                loading={loading === 'allow_workout_notification'}
              />
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow smart push notifications in app
              </div>
              <Switch
                checked={user.profile.allow_smart_notification}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_smart_notification',
                      notifyMessage: 'smart app'
                    })
                  } else {
                    this.changeNotifications(
                      checked,
                      'allow_smart_notification'
                    )
                  }
                }}
                loading={loading === 'allow_smart_notification'}
              />
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow chat push notifications in app
              </div>
              <Switch
                checked={user.profile.allow_chat_notification}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_chat_notification',
                      notifyMessage: 'chat app'
                    })
                  } else {
                    this.changeNotifications(checked, 'allow_chat_notification')
                  }
                }}
                loading={loading === 'allow_chat_notification'}
              />
            </div>
            <div className="box-item">
              <div className="box-item-title">
                Allow program push notifications in app
              </div>
              <Switch
                checked={user.profile.allow_program_notification}
                onChange={(checked) => {
                  if (
                    (loginRole === 'Expert' || loginRole === 'InternalAdmin') &&
                    !checked
                  ) {
                    this.setState({
                      showConfirm: true,
                      notificationType: 'allow_program_notification',
                      notifyMessage: 'program app'
                    })
                  } else {
                    this.changeNotifications(
                      checked,
                      'allow_program_notification'
                    )
                  }
                }}
                loading={loading === 'allow_program_notification'}
              />
            </div>
          </div>
        </div>
        {showEmailForm && (
          <ChangeEmail
            person={user}
            closeModal={() => this.setState({ showEmailForm: false })}
            loginRole={loginRole}
            updateEmailSync={(person) => this.setState({ person })}
          />
        )}

        {showConfirm && (
          <ConfirmPopUp
            {...{
              gender: user.profile.gender,
              name: user.profile.nickname || user.first_name,
              type: notifyMessage,
              onOk: () => {
                this.changeNotifications(false, notificationType)
                this.setState({ showConfirm: false })
              },
              onCancel: () => {
                this.setState({ showConfirm: false })
              }
            }}
          />
        )}
      </div>
    )
  }
}

export default ProfileSetting
