import { getRole } from '../utils/common'

export const getClientList = (isActive) => ({
  id: 'ClIENT_LIST',
  title: 'client list',
  href: isActive ? '' : '/client'
})

export const getClientDetail = (person, isActive) => ({
  id: 'CLIENT_DETAIL',
  title: `${(person.profile && person.profile.nickname) || person.first_name} ${
    person.last_name
  }`,
  href: isActive ? '' : `/client/${person.id}/detail`
})

export const getMessage = (person, isActive) => ({
  id: 'MESSAGE',
  title: 'chat',
  href: isActive ? '' : `/client/${person.id}/detail/chat`
})

export const getTimeline = (person, isActive) => ({
  id: 'TIMELINE',
  title: 'Timeline',
  href: isActive ? '' : `/client/${person.id}/detail/timeline`
})

export const getNotes = (person, isActive) => ({
  id: 'NOTES',
  title: 'notes',
  href: isActive ? '' : `/client/${person.id}/detail/notes`
})

export const getHealthStatus = (person, isActive) => ({
  id: 'HEALTHSTATUS',
  title: 'Health Compass',
  href: isActive ? '' : `/client/${person.id}/detail/health-status`
})

export const getProgram = (person, isActive) => ({
  id: 'PROGRAM',
  title: 'Program (Archive)',
  href: isActive ? '' : `/client/${person.id}/detail/program`
})

export const getSupportTeam = (person, isActive) => ({
  id: 'SUPPORT_TEAM',
  title: 'Support Team',
  href: isActive ? '' : `/client/${person.id}/detail/support-team`
})

export const getProgramDetail = (person, id, isActive, title) => ({
  id: 'PROGRAM_DETAIL',
  title: title || '',
  href: isActive ? '' : `/client/${person.id}/detail/program/${id}`
})

export const getTrackingMetrics = (person, isActive) => ({
  id: 'TRACKING_METRICS',
  title: 'Trends',
  href: isActive ? '' : `/client/${person.id}/detail/goals/active`
})

export const getNutritionScore = (person, isActive) => ({
  id: 'NUTRITION_SCORE',
  title: 'Nutrition Signals Report',
  href: isActive ? '' : `/client/${person.id}/detail/nutrition-score`
})

export const getNutritionTrends = (person, isActive) => ({
  id: 'NUTRITION_TRENDS',
  title: 'Nutrition Signals Trends',
  href: isActive ? '' : `/client/${person.id}/detail/nutrition-trends`
})

export const getFilePage = (person, isActive) => ({
  id: 'FILE_PAGE',
  title: 'file',
  href: isActive ? '' : `/client/${person.id}/detail/file`
})

export const getTrends = (person, isActive) => ({
  id: 'TRENDS',
  title: 'trends',
  href: isActive ? '' : `/client/${person.id}/detail/trends`
})

export const getSummaryEmail = (person, isActive) => ({
  id: 'SUMMARY_EMAIL',
  title: 'Summary Email',
  href: isActive ? '' : `/client/${person.id}/detail/summary-email`
})
export const getWeeklySummary = (person, isActive) => ({
  id: 'WEEKLY_SUMMARY',
  title:
    getRole() === 'Service_Provider'
      ? 'Email Detail'
      : 'Edit & Send Summary Email',
  href: isActive ? '' : `/client/${person.id}/detail/weekly-summary`
})

export const getClientSetting = (person, isActive) => ({
  id: 'CLIENT_SETTING',
  title: getRole() === 'Service_Provider' ? 'Files' : 'Profile & Settings',
  href: isActive ? '' : `/client/${person.id}/detail/setting`
})

export const getSettingPassword = (person, isActive) => ({
  id: 'SETTING_PASSWORD',
  title: 'Change Password',
  href: isActive ? '' : `/client/${person.id}/detail/setting/change-password`
})

export const getSettingPayment = (person, isActive) => ({
  id: 'SETTING_PAYMENT',
  title: 'Payment Method',
  href: isActive ? '' : `/client/${person.id}/detail/setting/payment`
})

export const getQuestionnaire = (personId, isActive) => ({
  id: 'QUESTIONNAIRE',
  title: 'Questionnaire',
  href: isActive ? `/client/${personId}/detail/questionnaire` : ''
})

export const getQuestionnaireSection = (personId, title, originalIds) => ({
  id: 'QUESTIONNAIRE_SECTION',
  title: title,
  href: `/client/${personId}/detail/questionnaire/group?original_ids=${originalIds}`
})

export const getQuestionnaireScore = (personId, isActive) => ({
  id: 'QUESTIONNAIRE SCORE',
  title: 'Questionnaire Score',
  href: isActive ? '' : `/client/${personId}/detail/questionnaire/score`
})

export const getAssessmentReview = (personId, isActive) => ({
  id: 'Assessment Review & Milestone Planning',
  title: 'Assessment Review & Milestone Planning',
  href: isActive ? '' : `/client/${personId}/detail/ass-report`
})
export const getAssessmentReviewDraft = (personId, isActive) => ({
  id: 'Assessment Review & Milestone Planning',
  title: 'Assessment Review & Milestone Planning',
  href: isActive ? '' : `/client/${personId}/detail/ass-report/preview`
})
export const getAssessmentReviewPreview = (personId, isActive) => ({
  id: 'Assessment Review & Milestone Planning',
  title: 'Assessment Review & Milestone Planning',
  href: isActive ? '' : `/client/${personId}/detail/ass-report/preview`
})

export const getBloodTest = (personId, isActive) => ({
  id: 'BLOOD_TEST',
  title: 'blood tests',
  href: isActive ? '' : `/client/${personId}/detail/blood-test`
})

export const getBodyComposition = (personId, isActive = true) => ({
  id: 'BODY_COM',
  title: 'Body Composition & Vitals',
  href: isActive ? '' : `/client/${personId}/detail/body-comp`
})

export const getLifeStyle = (personId, isActive = true) => ({
  id: 'LIFE_STYLE',
  title: 'Lifestyle Assessment',
  href: isActive ? '' : `/client/${personId}/detail/life-style`
})

export const getForcePlates = (personId, isActive = true) => ({
  id: 'FORCE_PLATES',
  title: 'force plate',
  href: isActive ? '' : `/client/${personId}/detail/force-plate`
})

export const getRangeOfMotions = (personId, isActive = true) => ({
  id: 'RANGE_OF_MOTIONS',
  title: 'range of motions',
  href: isActive ? '' : `/client/${personId}/detail/range-of-motions`
})

export const getMovementEfficiencies = (personId, isActive = true) => ({
  id: 'MOVEMENTEFF',
  title: 'movementEfficiencies',
  href: isActive ? '' : `/client/${personId}/detail/movement-efficiency`
})

export const getStrength = (personId, isActive = true) => ({
  id: 'STRENGTH',
  title: 'strength',
  href: isActive ? '' : `/client/${personId}/detail/strength`
})

export const getPhysical = (personId, isActive = true) => ({
  id: 'Physical',
  title: 'Initial 360 Physical Evaluation',
  href: isActive ? '' : `/client/${personId}/detail/physical`
})

export const getCardr = (personId, isActive = true) => ({
  id: 'CARDR',
  title: 'Cardiovascular Fitness',
  href: isActive ? '' : `/client/${personId}/detail/cardiorespiratories`
})

export const getCognitive = (personId, isActive = true) => ({
  id: 'COGNITIVE',
  title: 'Cognitive Health',
  href: isActive ? '' : `/client/${personId}/detail/cognitive-health`
})

export const getClientBiomarker = (personId, biomarker, isActive = true) => ({
  id: 'CLIENT_BIOMARKER',
  title: biomarker.name,
  href: isActive ? '' : `/client/${personId}/detail/biomarker/${biomarker.id}`
})

export const getComprehensiveReport = (personId, isActive = true) => ({
  id: 'COMPREHENSIVE_REPORT',
  title: 'COMPREHENSIVE REPORT',
  href: isActive ? '' : `/client/${personId}/detail/creport`
})

const getNutrition = (personId, isActive = true) => ({
  id: 'NUTRITION',
  title: 'Nutrition',
  href: isActive ? '' : `/client/${personId}/detail/nutrition`
})

const getVital = (personId, isActive = true) => ({
  id: 'VITAL',
  title: 'vital',
  href: isActive ? '' : `/client/${personId}/detail/vital`
})

export const getBreadCrumbList = (person, type) => {
  const list = [getClientList(), getClientDetail(person)]
  switch (type) {
    case 'nutrition':
      list.push(getNutrition(person.id))
      break
    case 'vital':
      list.push(getVital(person.id))
      break
    case 'movement-efficiency':
      list.push(getMovementEfficiencies(person.id))
      break

    case 'cardiorespiratory':
      list.push(getCardr(person.id))
      break
    case 'cognitive-health':
      list.push(getCognitive(person.id))
      break
    case 'strength':
      list.push(getStrength(person.id))
      break
    case 'force-plate':
      list.push(getForcePlates(person.id))
      break
    case 'range-of-motions':
      list.push(getRangeOfMotions(person.id))
      break
    case 'life-style':
      list.push(getLifeStyle(person.id))
      break
    case 'body-comp':
      list.push(getBodyComposition(person.id))
      break
    case 'blood-test':
      list.push(getBloodTest(person.id))
      break
    case 'physical':
      list.push(getPhysical(person.id))
      break
    default:
      break
  }
  return list
}
