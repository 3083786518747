import React from 'react'
import { ServicesContainer } from './style'

const services = [
  {
    name: 'Personal Training*',
    location: ['Apeiron Life/\nProvider location/Virtual', 'Other'],
    duration: ['60 min', '60 min'],
    price: ['$175', '$250']
  },
  {
    name: 'Nutrition Consultation',
    location: ['Apeiron Life/\nProvider location/Virtual', 'Other'],
    duration: ['60 min', '60 min'],
    price: ['$200', '$300']
  },
  {
    name: 'Chiropractor',
    location: ['Apeiron Life', 'Other'],
    duration: ['30 min', '30 min'],
    // price: ['$120', '$180'],
    needContact: true
  },
  {
    name: 'Yoga or Pilates*',
    location: ['Apeiron Life/\nProvider location/Virtual', 'Other'],
    duration: ['60 min', '60 min'],
    price: ['$175', '$250']
  },
  {
    name: 'Acupuncture',
    location: ['Apeiron Life/\nProvider location/Virtual', 'Other'],
    duration: ['60 min', '60 min'],
    price: ['$180', '$270']
  },
  {
    name: 'Massage',
    location: ['Apeiron Life/\nProvider location/Virtual', 'Other'],
    duration: ['60 min', '60 min'],
    price: ['$175', '$250']
  },
  {
    name: 'Mindfulness Coaching',
    location: ['Virtual'],
    duration: ['45 min'],
    price: ['$250']
  },
  {
    name: 'premium Services',
    description:
      'One time use included in Gold membership, available for\nadditional fee to other memberships',
    location: [],
    duration: [],
    price: []
  },
  {
    name: 'Sleep Consultation and Brain Map',
    description: 'Includes WatchPAT in-home sleep apnea test',
    location: ['Virtual'],
    duration: ['60 min'],
    price: ['$500']
  },
  {
    name: 'Microbiome Analysis Program',
    // description: 'Prices vary depending on exact tests run',
    location: ['Virtual'],
    duration: ['-'],
    price: ['$1,750']
  },
  {
    name: 'Micronutrient Assessment',
    location: ['Virtual'],
    duration: ['-'],
    price: ['$1,000']
  },
  {
    name: 'Continuous Glucose Monitoring',
    location: ['-'],
    duration: ['1 month license'],
    price: ['$325']
  },

  {
    name: 'Joint care and performance \nrecovery module',
    description:
      'Up to 15 appointments of acute/chronic injury recovery, pre/post operative care, and/or event-based care. Some \nconditions will require additional medical attention to be \nreferred outside of Apeiron Life',
    location: ['Apeiron Life'],
    duration: ['Varies'],
    price: ['$4,500']
  }
  // {
  //   name: 'Golf Module',
  //   location: ['-'],
  //   duration: ['3 months'],
  //   price: ['$4,000']
  // }
]

export default function Services(props) {
  return (
    <ServicesContainer>
      <div className="bold-text">Services</div>
      <ul>
        <li>
          Customize your Apeiron Life program with additional wellness services,
          based on your preferences and goals.
        </li>
        <li>
          All services are provided by licensed, qualified professionals at our
          facility in Menlo Park, CA, virtually, or in your home.
        </li>
      </ul>
      <div className="row">
        <div className="bold-text">
          STANDARD SERVICES
          <div className="normal">
            designated number of sessions included in gold, silver, bronze, and
            remote memberships
          </div>
        </div>
        <div className="bold-text">LOCATION</div>
        <div className="bold-text" style={{ textAlign: 'left' }}>
          DURATION/
          <br />
          LENGTH
        </div>
        <div className="bold-text">PRICE</div>
      </div>
      {services.map(
        (
          {
            name,
            description,
            descriptionGold,
            priceExplain,
            location,
            duration,
            price,
            needContact
          },
          index
        ) => (
          <div
            className={`row ${name} ${location.length === 1 ? 'single' : ''}`}
            key={index}
          >
            <div>
              <div className="service-name">{name}</div>
              {description && (
                <div
                  style={{
                    paddingRight: '70px'
                  }}
                >
                  {description}
                </div>
              )}
            </div>

            <div>
              <div className="info">
                {location.map((item, _index) => (
                  <div key={_index}>{item}</div>
                ))}
              </div>
            </div>
            <div>
              <div className="info">
                {duration.map((item, _index) => (
                  <div key={_index}>{item}</div>
                ))}
              </div>
            </div>
            <div>
              <div className="info">
                {needContact ? (
                  <div className="contact">
                    <a
                      href="mailto:concierge@apeiron.life"
                      target="_bank"
                      style={{
                        color: '#264382',
                        textDecoration: 'underline'
                      }}
                    >
                      Contact your concierge
                    </a>
                    <br />
                    for more information
                  </div>
                ) : (
                  price.map((item, _index) => (
                    <div key={_index}>
                      {item}
                      {priceExplain && <div>{priceExplain[_index]}</div>}
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )
      )}

      <div className="extra-text">
        * Interested in utilizing our personal training, yoga, or pilates
        services as a couple or in a small group with other Apeiron Life
        members? <br />
        <a
          href="mailto:concierge@apeiron.life"
          target="_bank"
          style={{
            color: '#264382',
            textDecoration: 'underline'
          }}
        >
          Contact your concierge
        </a>{' '}
        for information about discounted rates!
      </div>
      <div className="payment-policy">
        <div className="bold-text">Payment Policy</div>
        <ul>
          <li>
            <div className="bold-text">Payment</div>
            <div className="description">
              Any services outside of the specific membership plan will be
              billed on a monthly basis, and can be paid via automatic charge to
              the credit card on file, or check or wire transfer.
            </div>
          </li>
          <li>
            <div className="bold-text">Sessions</div>
            <div className="description">
              In-home services will be billed as 1.2 sessions to account for
              travel costs and additional planning/setup. Any sessions beyond
              the allotted number for the membership package will revert to a la
              carte pricing.
            </div>
          </li>
          <li>
            <div className="bold-text">Credit Card</div>
            <div className="description">
              Clients are required to keep a credit card on file with Apeiron
              Life. You expressly authorize Apeiron Life to charge the card you
              place on our file for any payment incurred in accordance with this
              services policy, your membership agreement and/or Apeiron Life’s
              terms of service.
            </div>
          </li>
          <li>
            <div className="bold-text">Cancellation</div>
            <div className="description">
              Clients will be charged full price for services canceled within 24
              hours of a scheduled appointment. For any services included in the
              membership plan, sessions canceled within 24 hours will be
              deducted from the remaining balance.
            </div>
          </li>
          {/* <li>
            <div className="bold-text">House Account Term</div>
            <div className="description">
              <ul>
                <li>Payments must be made in $5,000 increments.</li>
                <li>Fees will be drawn from account as services are booked.</li>
                <li>Monthly account balances will be provided upon request.</li>
                <li>
                  Balances are non-transferable to members or non-members.
                </li>
                <li>
                  Clients will be charged for cancellations or no-shows that are
                  made within 24 hours of the scheduled service, and your house
                  account will be debited for the service(s).
                </li>
              </ul>
              <p>
                Clients have the option to choose auto-debit billing, where
                Apeiron Life will automatically bill you when your house account
                falls below $200.
              </p>
              <p>
                House account balances are non-refundable, except under the
                following circumstances:
              </p>
              <ul>
                <li>
                  If your membership with Apeiron Life expires and you do not
                  renew your membership.
                </li>
                <li>
                  If you become disabled and cannot physically participate in
                  any of the services, Apeiron Life will refund you the
                  remaining balance on your house account. Your condition must
                  be verified in writing by a doctor.
                </li>
                <li>
                  In the event of death, Apeiron Life will refund your
                  representative the remaining balance on your house account.
                </li>
                <li>
                  If you move your residence more than 50 miles from a Apeiron
                  Life location, you may cancel your house account and Apeiron
                  Life will refund you the remaining balance.
                </li>
                <li>
                  If Apeiron Life stops offering services, you may cancel your
                  purchase and Apeiron Life will refund you the remaining
                  balance of your house account.
                </li>
              </ul>
            </div>
          </li> */}
        </ul>
      </div>
    </ServicesContainer>
  )
}
