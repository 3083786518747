import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Button } from 'antd'
import WelcomeEmail from '../../components/modalFrom/welcomeEmail'
import { getPeopleById } from '../../api/people'
import success from '../../asserts/images/success.png'

import { SettingStyle } from './index.sass'

class SendMembershipSelection extends Component {
  static propTypes = {}

  state = {
    view: 'choose',
    values: [],
    selectLoading: false,
    editWelcomeEmail: false
  }

  onChange = (checkedValue) => {
    this.setState({
      values: checkedValue
    })
  }

  getPerson = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const { person } = await getPeopleById(id)
    this.setState({ person })
  }

  componentDidMount() {
    this.getPerson()
  }

  renderSuccessSection() {
    const { history } = this.props
    return (
      <div className="succeed-container">
        <h1 className="title">Success</h1>
        <div className="body-box">
          <img src={success} alt="success" />
          <p>Client has been created!</p>
        </div>
        <Button
          type="primary"
          onClick={() => this.setState({ editWelcomeEmail: true })}
          style={{ marginBottom: 20 }}
        >
          Send Membership Email
        </Button>
        <Button type="primary" ghost onClick={(e) => history.push('/')}>
          Skip
        </Button>
      </div>
    )
  }

  render() {
    const { editWelcomeEmail, person } = this.state
    const { history } = this.props
    return (
      <div className={SettingStyle}>
        {this.renderSuccessSection()}
        {editWelcomeEmail && (
          <WelcomeEmail
            onCancel={() => this.setState({ editWelcomeEmail: false })}
            onSend={() => history.push('/')}
            person={person}
            isMembership={true}
          />
        )}
      </div>
    )
  }
}

export default SendMembershipSelection
