import React, { useState, useEffect, useRef } from 'react'
import { Select, Input } from 'antd'

export default function CustomizedSelect(props) {
  const { options, placeholder, disabled, extraAttr, onChange, value } = props
  const { extraoptions, onTypeCallback } = extraAttr
  const [selectValue, setSelectValue] = useState([])

  useEffect(() => {
    if (value) {
      setSelectValue(value)
    }
  }, [value])

  function onTypeChange(valueString, option) {
    if (!valueString) return
    const values = valueString.split(',').filter((v) => v)
    const newValue = Array.from(
      new Set(
        (selectValue || []).concat(values.map((v) => `${option.value}:${v}`))
      )
    )
    setSelectValue(newValue)
    triggerChange(newValue)
    if (onTypeCallback) {
      onTypeCallback(newValue)
    }
  }

  function triggerChange(value, option) {
    setSelectValue(value)
    if (onChange) {
      onChange(value)
    }
  }
  return (
    <Select
      placeholder={placeholder}
      onChange={triggerChange}
      disabled={disabled}
      value={selectValue}
      {...extraAttr}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {extraoptions.map((item, index) => (
            <div key={index} className="ant-select-item ant-select-item-option">
              <div className="ant-select-item-option-content">
                {item.label}
                <ExtraOptionsInput
                  onTypeChange={onTypeChange}
                  option={item}
                  placeholder={item.placeholder}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    >
      {options.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  )
}

function ExtraOptionsInput(props) {
  const { option, onTypeChange, inputId, placeholder } = props
  const inputRef = useRef(null)
  const [value, setValue] = useState(null)
  function onPressEnter(e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.keyCode === 13) {
      onTypeChange(value, option)
      setValue(null)
    }
  }
  return (
    <div>
      <Input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={onPressEnter}
        id={inputId}
        placeholder={placeholder}
        ref={inputRef}
        onBlur={() => {
          if (value) {
            onTypeChange(value, option)
            setValue(null)
          }
        }}
      />
    </div>
  )
}
