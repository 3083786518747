import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider, Button, message } from 'antd'

import { Trash } from '../icons'
import WeeklySummaryWrapper from './weeklySummaryWrapper'

import RecentActivity from './comp/recentActivity'
import DateRange from '../UI/mgComponents/dateRange'
import PageLoading from '../UI/status/loading'

import { getAxisX } from './common'
import ConfirmPopUp from '../UI/extraInformation/confirm'
import SubjectEditor from './comp/subjectEditor'
import SummaryEmailOverView from './overview'
import Nutrition from './nutrition'
import Exercise from './exercise'
import Rejuvenation from './rejuvenation'
import ConfirmSendEmail from './comp/confirmSendEmail'
import TextEditor from '../UI/extraInformation/textEditor'
import { summaryStyle } from './styles/summary.sass'

import EmailBG from '../../asserts/images/email-bg.png'

const green = '#7BAD2D',
  red = '#FF6B00',
  // gray = '#b7bfc6',
  normal = '#70808E'
// primary = '#383E48'

/*

if allow resend

then

get new screenshot before resend

*/

class WeeklySummaryBackupLayout extends Component {
  static propTypes = {
    person: PropTypes.object,
    data: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      submiting: false,
      sendConfirmVisible: false,
      clearConfirmVisible: false
      // sendConfirmInfo: {
      //   title: 'Send summary confirmation',
      //   content: 'Are you sure to send this summary email to client@domain.com?',
      //   handleOk: 'SEND NOW'
      // }
    }

    this.exerciseRef = React.createRef()
    this.nutritionRef = React.createRef()
    this.rejuvenationRef = React.createRef()
    this.overviewRef = React.createRef()
  }

  getObjString(obj) {
    let result = obj
    if (typeof obj !== 'string') {
      result = JSON.stringify(obj)
    }
    return result
  }

  screenshot = async () => {
    function getUrls(elements) {
      const toCanvasTasks = elements.map((item) => {
        const { elem, key } = item
        return new Promise((resolve, rejects) => {
          window
            .html2canvas(elem, { backgroundColor: '#fff' })
            .then((canvas) => {
              /* start extra test code*/
              // const link = document.createElement('a')
              // link.download = 'text.png'
              // canvas.toBlob(function(blob) {
              //   link.href = URL.createObjectURL(blob)
              //   link.click()
              // })
              /* end extra test code */
              return resolve({ base64_data: canvas.toDataURL(), id: key })
            })
            .catch((err) => rejects(err))
        })
      })

      return Promise.all(toCanvasTasks)
    }

    try {
      console.log('try to get screenshots')

      const refsArr = [
        this.overviewRef.current ? this.overviewRef.current.refs : [],
        this.exerciseRef.current ? this.exerciseRef.current.refs : [],
        this.nutritionRef.current ? this.nutritionRef.current.refs : [],
        this.rejuvenationRef.current ? this.rejuvenationRef.current.refs : []
      ].map((item) => {
        return Object.keys(item).map((key) => ({ key, elem: item[key] }))
      })
      const urls = await getUrls([].concat(...refsArr))
      return urls
    } catch (err) {
      console.log('we met something wrong, pleasse try again.')
      console.error(err)
    }
  }

  submit = async (type, ccList) => {
    if (this.state.submiting) {
      return
    }
    // const { originInformation } = this.props

    const hide = message.loading(
      `${type === 'draft' ? 'saving' : 'sending'}`,
      0
    )

    if (this.state.submiting) {
      return setTimeout(hide)
    }

    this.setState({ submiting: true })

    setTimeout(async () => {
      let urls
      if (
        type !== 'draft'
        // &&
        // originInformation.status !== 'Sent' &&
        // originInformation.status !== 'Opened'
      ) {
        urls = await this.screenshot()
      }
      this.submitHandle(type, ccList, urls, hide)
    }, 1000)
  }

  submitHandle = async (type, ccList, urls, hide) => {
    let { submitData } = this.props
    const { EmailAction } = this.props
    let action
    switch (type) {
      case 'sent':
        action = EmailAction.sent
        break
      case 'test':
        action = EmailAction.send_test_email
        break
      default:
        action = EmailAction.save_as_draft
        break
    }
    try {
      await this.props.submit(action, null, submitData, urls, ccList) // call api
    } catch (err) {
      this.setState({ submiting: false })
    }

    setTimeout(hide)
    if (type === 'test') {
      this.setState({ submiting: false })
    }
  }

  processNormalGoal(startDate, endDate, goal, isOverviewPart) {
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    if (tickValuesX) {
      let isYesNoGoal = false
      const result = {
        entries: tickValuesX.map((date) => {
          let specificDay = goal.details.find((detail) => {
            return date.format('YYYY-MM-DD') === detail.summary_date
          })
          if (specificDay) {
            // if (specificDay.value === 'yes') {
            //   isYesNoGoal = true
            //   return 100
            // } else if (specificDay.value === 'no') {
            //   isYesNoGoal = true
            //   return 0
            // } else {
            //   return specificDay.value
            // }
            return specificDay.value
          } else {
            return null
          }
        })
      }
      result.isYesNoGoal = isYesNoGoal
      const ranges = goal.auto_range ? goal.auto_range.split(',') : []
      const [min, max] = ranges
      let colorFunc
      if (ranges.length === 1) {
        result.target = Number(goal.auto_range)
        colorFunc = (t) => {
          if (t.y === Number(goal.auto_range)) {
            return green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Goal'
      } else if (Number(min) && Number(max)) {
        result.min = Number(min)
        result.max = Number(max)
        colorFunc = (t) => {
          if (t.y >= min && t.y <= max) {
            return green
          } else {
            return red
          }
        }
      } else if (Number(min)) {
        result.target = Number(min)
        colorFunc = (t) => {
          if (t.y >= min) {
            return green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Goal'
      } else if (Number(max)) {
        result.target = Number(max)
        colorFunc = (t) => {
          if (t.y <= max) {
            return green
          } else {
            return red
          }
        }
        result.extraAxisText = 'Limit'
      } else {
        colorFunc = green
      }
      result.barColor = colorFunc

      result.changeColor = colorFunc

      // if (isOverviewPart) {
      //   if (!isYesNoGoal) {
      //     result.target = goal.average
      //   }
      //   result.min = null
      //   result.max = null
      //   result.extraAxisText = 'Avg'
      //   result.barColor = green
      // }
      if (goal.description.toLowerCase().includes('weight')) {
        result.target = goal.average
        result.extraAxisText = 'Avg'
      }
      result.tickFormat = (y) => (y > 1000 ? Math.round(y / 100) / 10 + 'k' : y)
      return result
    }
  }

  updateSubject = (subject, isSave) => {
    const { setSubmitData } = this.props

    if (isSave) {
      setSubmitData({
        field: 'email_subject',
        value: subject,
        status: 'save',
        position: 'subject'
      })
    }

    this.props.changeSubject(subject)
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.props.beforeunload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.props.beforeunload)
  }

  renderFooter() {
    const { submiting } = this.state
    const {
      submitData,
      editingTextNumber,
      changedSubject,
      originInformation
    } = this.props
    const disSend =
      submiting ||
      editingTextNumber > 0 ||
      (changedSubject && changedSubject !== submitData.email_subject)
    return (
      <footer>
        <div
        // style={{ cursor: 'pointer' }}
        // onClick={() => this.setState({ clearConfirmVisible: true })}
        >
          {/* <span>CLEAR ALL CHANGES</span> */}
        </div>
        <div>
          {/* <Button
        type="primary"
        disabled={this.state.submiting}
        ghost
        onClick={() => this.submit('draft')}
      >
        SAVE AS DRAFT
      </Button> */}
          <Button
            type="primary"
            ghost
            disabled={disSend}
            onClick={() => this.submit('test')}
          >
            SEND TEST EMAIL
          </Button>

          <Button
            type="primary"
            disabled={disSend}
            onClick={() => {
              if (!submitData.email_subject) {
                return message.error('Please type in email subject...')
              }
              this.setState({ sendConfirmVisible: true })
            }}
          >
            {!originInformation ||
              (originInformation.status !== 'Sent' &&
                originInformation.status !== 'Opened')
              ? 'Send'
              : 'Resend'}
          </Button>
        </div>
      </footer>
    )
  }

  render() {
    const {
      showDate,
      startDate,
      endDate,
      hideArrow,
      prevDate,
      nextDate,
      loading,
      clearAllChanges,
      originInformation,
      data,
      person,
      changeLog,
      statisticsLog,
      ouraRingConnected,
      deleteNutrition,
      oprRejuvenation,
      oprExercise,
      setSubmitData,
      submitData,
      deleteSections,
      loginRole,
      templates,
      updateEditingCount
    } = this.props

    const { clearConfirmVisible, sendConfirmVisible } = this.state

    const { nutrition, deleted_section } = submitData || statisticsLog

    const goals =
      (data &&
        data.nutrition.map((item) => {
          item.barProps = this.processNormalGoal(
            startDate,
            endDate,
            item,
            false
          )
          const target = nutrition.find((goal) => goal.id === item.id)
          if (target) {
            target.description = item.description
            return Object.assign(item, target)
          }
          item.changeColor = item.auto_range
            ? item.barProps.changeColor({ y: item.average })
            : normal
          return item
        })) ||
      []

    const isEditable =
      !originInformation ||
      (originInformation.status !== 'Sent' &&
        originInformation.status !== 'Opened')

    return loading ? (
      <PageLoading />
    ) : (
      <div className={summaryStyle}>
        {loginRole !== 'User' && (
          <DateRange {...{ hideArrow, showDate, prevDate, nextDate }} />
        )}
        {loginRole === 'User' && (
          <h1 className="title">{`${startDate &&
            startDate.format('MMM DD')} - ${endDate &&
            endDate.format('MMM DD, YYYY')}`}</h1>
        )}
        <Divider style={{ marginTop: 0 }} />

        <div className="email-content">
          <div>
            {loginRole !== 'User' && ( //isEditable &&
              <SubjectEditor
                value={submitData.email_subject}
                updateValue={this.updateSubject}
                updateEditingCount={updateEditingCount}
              />
            )}
            <div className="section-container">
              {loginRole === 'User' && (
                <img className="bg-image" src={EmailBG} alt="background" />
              )}
              <div className="summary-date">
                {!isEditable &&
                  `${startDate && startDate.format('MMM DD')} - ${endDate &&
                  endDate.format('MMM DD')}`}
              </div>
              {!isEditable ? (
                <h3 className="Overview">
                  {submitData.overview.title ||
                    'Let’s take a look at your progress'}
                </h3>
              ) : (
                <TextEditor
                  {...{
                    limit: 50,
                    updateEditingCount,
                    loginRole,
                    updateCommend: (value, status) =>
                      setSubmitData({
                        part: 'overview',
                        field: 'title',
                        value,
                        status,
                        position: 'Title'
                      }),
                    renderResult: (value) => (
                      <h3 className="Overview">{value}</h3>
                    ),
                    disDeletable: true,
                    initialValue:
                      submitData.overview.title ||
                      'Let’s take a look at your progress'
                  }}
                />
              )}

              <SummaryEmailOverView
                goals={goals}
                {...this.props}
                ref={this.overviewRef}
              />
              {/* {this.renderOverview(goals)} */}
            </div>
            {(ouraRingConnected || (data && data.sleep.details)) &&
              !deleted_section.rejuvenation && (
                <div className="section-container">
                  <h3 className="Rejuvenation">
                    Rejuvenation
                    {isEditable && (
                      <Trash onClick={() => deleteSections('rejuvenation')} />
                    )}
                  </h3>
                  <Rejuvenation
                    ref={this.rejuvenationRef}
                    {...{
                      startDate,
                      endDate,
                      data,
                      setSubmitData,
                      originInformation,
                      submitData,
                      deleteSections,
                      oprRejuvenation,
                      loginRole,
                      templates,
                      updateEditingCount
                    }}
                  />
                </div>
              )}
            {!deleted_section.exercise && data && (
              <div className="section-container">
                <h3 className="Exercise">
                  Exercise
                  {isEditable && (
                    <Trash onClick={() => deleteSections('exercise')} />
                  )}
                </h3>
                <Exercise
                  ref={this.exerciseRef}
                  {...{
                    startDate,
                    endDate,
                    data,
                    setSubmitData,
                    originInformation,
                    deleteSections,
                    submitData,
                    oprExercise,
                    loginRole,
                    templates,
                    updateEditingCount
                  }}
                />
              </div>
            )}
            {!deleted_section.nutrition && goals.length > 0 ? (
              <div className="section-container">
                <h3 className="Nutrition">
                  Nutrition
                  {isEditable && (
                    <Trash onClick={() => deleteSections('nutrition')} />
                  )}
                </h3>
                <Nutrition
                  ref={this.nutritionRef}
                  goals={goals}
                  {...{
                    originInformation,
                    startDate,
                    endDate,
                    deleteNutrition,
                    setSubmitData,
                    submitData,
                    loginRole,
                    templates,
                    updateEditingCount
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          {loginRole !== 'User' && (
            <RecentActivity
              {...{ startDate, endDate, changeLog, person, statisticsLog }}
            />
          )}
        </div>

        {/* {(!originInformation ||
          (originInformation.status !== 'Sent' &&
            originInformation.status !== 'Opened')) &&
          this.renderFooter()} */}
        {/* loginRole !== 'User' */}
        {isEditable && this.renderFooter()}

        {sendConfirmVisible && (
          <ConfirmSendEmail
            {...{
              person: person,
              // clientEmail: person.email,
              handleCancel: () => this.setState({ sendConfirmVisible: false }),
              handleOk: (ccList) => {
                this.setState({ sendConfirmVisible: false })
                this.submit('sent', ccList)
              }
            }}
          />
        )}
        <ConfirmPopUp
          {...{
            title: 'Clear all changes confirmation',
            content:
              'All changes you made so far will not be saved. Are you sure you want to continue?',
            okText: 'CLEAR',

            handleCancel: () => this.setState({ clearConfirmVisible: false }),
            handleOk: () => {
              this.setState({ clearConfirmVisible: false })
              clearAllChanges()
            }
          }}
          visible={clearConfirmVisible}
        />
      </div>
    )
  }
}

export default WeeklySummaryWrapper(WeeklySummaryBackupLayout)
