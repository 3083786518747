import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { daysOfWeekStyle } from './styles/daysOfWeek.sass'

const week = [
  { label: 'M', value: 1 },
  { label: 'T', value: 2 },
  { label: 'W', value: 3 },
  { label: 'T', value: 4 },
  { label: 'F', value: 5 },
  { label: 'S', value: 6 },
  { label: 'S', value: 7 }
]
class SelectDaysOfWeek extends Component {
  static propTypes = {}

  state = {
    values: []
  }

  triggerChange = (values) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(values)
    }
  }

  valueChange = (value) => {
    let { values } = this.state
    values = values || []
    if (!value) return
    if (values.find((item) => item === value)) {
      values = values.filter((item) => item !== value)
    } else {
      values.push(value)
    }
    this.setState({ values })
    this.triggerChange(values)
  }

  componentDidMount() {
    const { value } = this.props
    if (value) {
      this.setState({
        values: value.map((item) => Number(item))
      })
    }
  }

  render() {
    let { values } = this.state
    return (
      <div className={daysOfWeekStyle}>
        {week.map((item, index) => (
          <span
            key={index}
            className={`days-item ${
              values && values.find((day) => day === item.value)
                ? 'selected'
                : ''
            }`}
            onClick={() => this.valueChange(item.value)}
          >
            {item.label}
          </span>
        ))}
      </div>
    )
  }
}

export default SelectDaysOfWeek
