import React, { Component } from 'react'
import { AOFIcon, LTAIcon } from '../icons/weeklySummary'
import Information from '../UI/extraInformation'
import EmailRichTextEditor from './comp/richTextEditor'
import { summary_introduction } from './directions.json'

class SummaryEmailOverView extends Component {
  static propTypes = {}

  render() {
    const {
      submitData: { overview },
      originInformation,
      setSubmitData,
      removeTextPart,
      loginRole,
      templates,
      updateEditingCount,
      updateEmailAction,
      isLocked
    } = this.props
    const status = isLocked
      ? 'Locked'
      : (originInformation && originInformation.status) || 'Draft'

    return (
      <>
        <div style={{ marginTop: 10 }}>
          <Information
            updateEditingCount={updateEditingCount}
            status={status}
            extraStyles={{
              background: '#ffffff',
              border: '1px solid #E4EAEF'
            }}
            initialValue={overview.summary_introduction}
            updateEmailAction={updateEmailAction}
            doneStatus={overview.summary_introduction_status}
            title="Introduction"
            showDone={loginRole !== 'User'}
            templates={templates}
            direction={summary_introduction}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'overview',
                field: 'summary_introduction',
                value,
                status,
                section: 'Introduction'
              })
            }
          />
        </div>

        <div className="profile-container">
          {/* rechTextEditor */}
          {!overview.areas_of_focus_is_hide &&
            (overview.areas_of_focus || status === 'Draft') && (
              <div className="profile-info">
                <EmailRichTextEditor
                  updateEditingCount={updateEditingCount}
                  status={status}
                  initialValue={
                    overview.areas_of_focus_is_hide
                      ? null
                      : overview.areas_of_focus
                  }
                  updateEmailAction={updateEmailAction}
                  updateCommend={(value, status) => {
                    if (status === 'delete') {
                      removeTextPart({
                        part: 'overview',
                        field: 'areas_of_focus',
                        value,
                        status,
                        section: 'Long Term Goals'
                      })
                    } else {
                      setSubmitData({
                        part: 'overview',
                        field: 'areas_of_focus',
                        value,
                        status,
                        section: 'Long Term Goals'
                      })
                    }
                  }}
                  icon={<AOFIcon style={{ color: '#FF6B00' }} />}
                  title="Goals"
                />
              </div>
            )}

          {!overview.long_term_aspirations_is_hide &&
            (overview.long_term_aspirations || status === 'Draft') && (
              <div className="profile-info">
                <EmailRichTextEditor
                  icon={<LTAIcon style={{ color: '#FF6B00' }} />}
                  title="Aspirations"
                  updateEditingCount={updateEditingCount}
                  updateEmailAction={updateEmailAction}
                  status={status}
                  initialValue={
                    overview.long_term_aspirations_is_hide
                      ? null
                      : overview.long_term_aspirations
                  }
                  updateCommend={(value, status) => {
                    if (status === 'delete') {
                      removeTextPart({
                        part: 'overview',
                        field: 'long_term_aspirations',
                        value,
                        status,
                        section: 'Aspirations'
                      })
                    } else {
                      setSubmitData({
                        part: 'overview',
                        field: 'long_term_aspirations',
                        value,
                        status,
                        section: 'Aspirations'
                      })
                    }
                  }}
                />
              </div>
            )}
        </div>
      </>
    )
  }
}

export default SummaryEmailOverView
