import React from 'react'
import ACSMHeartTargetBreakDown from './acsmHeartTargetBreakDown'
import { SvgText } from '../../icons/weeklySummary'
import { breakDownContainerStyle } from './styles/acsmBreakDownContainer.sass'

export default function AcsmBreakDownContainer(props) {
  return (
    <div className={breakDownContainerStyle}>
      <h4 className="sub-title">
        <SvgText
          text="TARGET BREAKDOWN"
          textAttr={{
            x: 0,
            fontSize: 14,
            textAnchor: 'start'
          }}
          width={300}
          height={21}
        />
      </h4>
      <div className="content">
        <ACSMHeartTargetBreakDown {...props} />
      </div>
    </div>
  )
}
