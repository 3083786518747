import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Divider } from 'antd'
import HexagonText from '../../UI/HexagonText'

class BloodTestResult extends Component {
  static propTypes = {
    result: PropTypes.array
  }

  state = {
    categories: null
  }

  componentDidMount() {
    const { result } = this.props
    let categories = {}
    for (let i = 0, l = result.length; i < l; i++) {
      if (result[i].output === 'Optimal') continue
      categories[result[i].root_category] =
        categories[result[i].root_category] || []
      categories[result[i].root_category].push(result[i])
    }
    const m = new Map()
    for (let key in categories) {
      m.set(key, { name: key, biomarkers: categories[key] })
    }
    categories = [...m.values()]
    this.setState({ categories })
  }

  renderBiomarker(biomarker) {
    const { bio_marker, output, value, unit, optimal_range } = biomarker
    const areas = [
      {
        names: ['Low', 'At Risk Low'],
        color: '#FF6B00'
      },
      {
        names: ['Moderately Low', 'Sub-Optimal Low', 'Sub-Optimal Medium Low'],
        color: '#FFB800'
      },
      {
        names: ['Optimal'],
        color: '#40C47C'
      },
      {
        names: [
          'Moderately High',
          'Sub-Optimal High',
          'Sub-Optimal Medium High'
        ],
        color: '#FFB800'
      },
      {
        names: ['High', 'At Risk High'],
        color: '#FF6B00'
      }
    ]
    return (
      <>
        <div className="name">{bio_marker}</div>
        <div className="ranage">
          {Number(optimal_range[1]) === Number.POSITIVE_INFINITY
            ? `>${Number(optimal_range[0])}`
            : `${Number(optimal_range[0])}-${Number(optimal_range[1])}`}
        </div>
        <div className="unit">{unit}</div>
        <div className="range-bar">
          {areas.map((item, index) => (
            <div className="range-item" key={index}>
              <div className="item-bar" style={{ background: item.color }} />
              {item.names.find(name => name === output) && (
                <HexagonText
                  color={item.color}
                  text={Number(value)}
                  size={60}
                />
              )}
            </div>
          ))}
        </div>
      </>
    )
  }

  renderCategory(category) {
    const { name, biomarkers } = category
    return (
      <>
        <div className="header">
          <div className="left">
            <span>Blood Tests</span>
            <br />
            {name}
          </div>
          <div className="right">
            <span className="optimized">optimized</span>
            <span className="concern">concern</span>
            <span className="at risk"> at risk</span>
          </div>
        </div>
        <div className="biomarkers-cotainer">
          {biomarkers.map((item, index) => (
            <div className="biomarker-item" key={index}>
              {this.renderBiomarker(item)}
            </div>
          ))}
        </div>
      </>
    )
  }

  render() {
    const { categories } = this.state
    return (
      <div className="report-blood-test-result">
        {categories &&
          categories.map((item, index) => (
            <div className="category" key={index}>
              {index > 0 && <Divider />}
              {this.renderCategory(item)}
            </div>
          ))}
      </div>
    )
  }
}

export default BloodTestResult
