import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Row, Col } from 'antd'
import { Yes, No } from '../../../components/icons/statusIcon'
import NumberInput from './numberInput'
import TextInput from './textInput'
import { GoalsMethods, dateFormat } from '../../../utils/common'
import PageLoading from '../../UI/status/loading'

class EditView extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    saveEntry: PropTypes.func,
    deleteEntry: PropTypes.func,
    goal: PropTypes.object,
    setPropState: PropTypes.func,
    achieving: PropTypes.array
  }

  getDateArray = () => {
    const { startDate, endDate, goal } = this.props
    const { period, weeks } = this.getGoalPeriod(goal)
    let days = [],
      count = 0,
      start = startDate,
      end = endDate
    if (period === 'monthly') {
      const year = moment(startDate).year()
      const month = moment(startDate).month()
      start = moment([year, month, 1])
      end = moment([year, month, startDate.daysInMonth()])
    }

    while (
      moment(start)
        .add(count, 'days')
        .isBefore(moment(end))
    ) {
      days.unshift(moment(start).add(count, 'days'))
      count++
    }
    days.unshift(moment(end))

    if (period === 'On specific days per week') {
      const weeklyDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      days = days.filter((item) => {
        const index = weeklyDays.findIndex(
          (day) => moment(item).format('ddd') === day
        )
        return weeks.find((week) => Number(week) === index + 1)
      })
    }
    days = days.filter((item) =>
      moment(item.format('YYYY-MM-DD')).isSameOrBefore(
        moment().format('YYYY-MM-DD')
      )
    )
    return days
  }

  getValue = (date) => {
    const { achieving } = this.props
    let value
    for (const item of achieving) {
      for (const entry of item.entries) {
        if (date.format('YYYY-MM-DD') === entry.created_at) {
          value = entry
          break
        }
      }
      if (value) break
    }
    return value
  }

  saveEntry = async (value, date, oldValue) => {
    const { saveEntry, goal, deleteEntry } = this.props
    if (value === oldValue) {
      await deleteEntry(goal.id, date.format('YYYY-MM-DD'))
    } else {
      const period = goal.cron_day_month === '*' ? 'week' : 'month'
      await saveEntry(goal, value, date.format('YYYY-MM-DD'), period)
    }
  }

  saveNumberOrTextEntry = async (value, date) => {
    const { saveEntry, goal, deleteEntry } = this.props
    if (value) {
      const period = goal.cron_day_month === '*' ? 'week' : 'month'
      await saveEntry(goal, value, date.format('YYYY-MM-DD'), period)
    } else {
      await deleteEntry(goal.id, date.format('YYYY-MM-DD'))
    }
  }

  getGoalPeriod = (goal) => {
    const { cron_day_month, cron_day_week, repeat_num } = goal
    let period = 'daily',
      weeks
    if (cron_day_month !== '*') {
      period = 'monthly'
    } else {
      if (cron_day_week === '1' && repeat_num > 0) {
        period = 'weekly'
      } else if (cron_day_week !== '*' && repeat_num === 0) {
        period = 'On specific days per week'
        weeks = cron_day_week.split(',')
      }
    }
    return { period, weeks }
  }

  formatTime = (val, unit) => {
    switch (unit) {
      case 'Time':
        return {
          __html: `<span class='fmt-t'>${GoalsMethods.formatVal(
            val,
            'Time'
          )}</span>`
        }
      case 'Hours':
        let [h, f] = (val + '').split('.')
        let m = 0
        if (!h || h.toLowerCase() === 'null') {
          h = 0
        }
        if (f) {
          m = Math.round(parseFloat('0.' + f) * 60)
        }

        return {
          __html: `<span class='fmt-h'>${h}<sub>h</sub></span> <span class='fmt-m'>${m}<sub>m</sub></span>`
        }
      case 'Minutes':
        const m2 = Math.round(val)
        const h2 = (Math.floor(m2 / 60) % 24) + ''
        const mm = (m2 % 60) + ''
        return {
          __html: `<span class='fmt-h'>${h2}<sub>h</sub></span> <span class='fmt-m'>${mm}<sub>m</sub></span>`
        }
      default:
        return { __html: '' }
    }
  }

  renderBinary(date, showAuto) {
    const { position, role } = this.props
    const entry = this.getValue(date)
    const input = entry && entry.input
    const inputAuto = entry && entry.input_auto
    const isActivity =
      entry && entry.activity_details && entry.activity_details.length > 0
    const { setPropState } = this.props
    const disabled =
      (position === 'trends' && role !== 'Expert') ||
      role === 'Service_Provider'
    return (
      <>
        <span
          className="date-text"
          onClick={() => {
            if (isActivity) {
              setPropState({
                showActivity: true,
                activityEntry: entry,
                targetDate: entry.created_at
              })
            }
          }}
        >
          {isActivity ? (
            <span className="activity">{dateFormat(date)}</span>
          ) : (
            dateFormat(date)
          )}
          {/* {entry && (
            <span className="is-manual">{entry.manual ? 'M' : 'A'}</span>
          )} */}
        </span>
        <div
          className={`input-circle ${disabled ? 'not-allowed' : ''} ${
            isActivity && (showAuto ? input : input || inputAuto) === 'yes'
              ? 'activity'
              : ''
          }`}
          onClick={() => !disabled && this.saveEntry('yes', date, input)}
        >
          <Yes selected={(showAuto ? input : input || inputAuto) === 'yes'} />
        </div>
        <div
          className={`input-circle ${disabled ? 'not-allowed' : ''}`}
          onClick={() => !disabled && this.saveEntry('no', date, input)}
        >
          <No selected={(showAuto ? input : input || inputAuto) === 'no'} />
        </div>
        {showAuto && (
          <>
            <div
              className={`input-circle not-allowed ${
                isActivity && inputAuto === 'yes' ? 'activity' : ''
              }`}
            >
              <Yes selected={inputAuto === 'yes'} />
            </div>
            <div className="input-circle not-allowed">
              <No selected={inputAuto === 'no'} />
            </div>
          </>
        )}
      </>
    )
  }

  renderNumberInput(date, showAuto) {
    const { goal, position, role } = this.props
    const value = this.getValue(date)
    const inputVal = value ? value.input || value.inputAuto : undefined
    const needFormatUnit = ['Time', 'Hours', 'Minutes']
    return (
      <>
        <NumberInput
          value={
            value && (showAuto ? value.input : value.input || value.input_auto)
          }
          decimalDigits={
            goal.auto_metric &&
            (goal.auto_metric.toUpperCase() === 'BODYMASS' ||
              goal.auto_metric.toUpperCase() === 'WEIGHT')
              ? 1
              : 2
          }
          entry={value}
          saveEntry={(value) => this.saveNumberOrTextEntry(value, date)}
          auto_range={goal.auto_range}
          unit={goal.unit}
          disabled={
            (position === 'trends' && role !== 'Expert') ||
            role === 'Service_Provider'
          }
          formatter={
            needFormatUnit.includes(goal.unit) &&
            value &&
            inputVal &&
            inputVal.toLowerCase() !== 'null'
              ? (val) => this.formatTime(val, goal.unit)
              : undefined
          }
        />
        {showAuto && (
          <NumberInput
            value={value && value.input_auto}
            entry={value}
            // saveEntry={value => this.saveNumberOrTextEntry(value, date)}
            disabled={true}
            auto_range={goal.auto_range}
            formatter={
              needFormatUnit.includes(goal.unit) &&
              value &&
              value.input_auto &&
              value.input_auto.toLowerCase() !== 'null'
                ? (val) => this.formatTime(val, goal.unit)
                : undefined
            }
          />
        )}
      </>
    )
  }

  renderTextInput(date) {
    const { position, role } = this.props
    const value = this.getValue(date)
    return (
      <TextInput
        value={value && value.input}
        disabled={
          (position === 'trends' && role !== 'Expert') ||
          role === 'Service_Provider'
        }
        saveEntry={(value) => this.saveNumberOrTextEntry(value, date)}
      />
    )
  }

  renderDate(date) {
    // const entry = this.getValue(date)
    return (
      <span className="date-text">
        {/* {date.format('ddd, MMM D')} */}
        {dateFormat(date)}

        {/* {!isAuto && entry && (
          <span className="is-manual">{entry.manual ? 'M' : 'A'}</span>
        )} */}
      </span>
    )
  }

  renderTextListInput(days) {
    return (
      <Row type="flex">
        {days.map((item, index) => (
          <Col span={12} key={item.format('YYYY-MM-DD')}>
            <div
              className={`date-item text-item ${
                item.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                  ? 'today'
                  : ''
              } ${item.format('ddd') === 'Sun' ? 'sunday' : ''}`}
            >
              {this.renderDate(item)}
              {this.renderTextInput(item)}
            </div>
          </Col>
        ))}
      </Row>
    )
  }

  render() {
    const { goal, role, loading } = this.props
    if (loading) return <PageLoading />
    const days = this.getDateArray()
    if (!days.length) return null
    const isExpertView =
      goal.source !== 'manual' &&
      (role === 'Expert' || role === 'Service_Provider')
    return (
      <div>
        <div className="section-name">edit data</div>
        {goal.input_type === 'text' ? (
          this.renderTextListInput(days)
        ) : (
          <Row type="flex">
            {isExpertView && (
              <>
                <Col span={days.length > 1 ? 12 : 24} className="input-type">
                  <span>Manual </span>
                  <span>Auto</span>
                </Col>
                {days.length > 1 && (
                  <Col span={12} className="input-type">
                    <span>Manual </span>
                    <span>Auto</span>
                  </Col>
                )}
              </>
            )}
            {days.map((item, index) => (
              <Col
                span={goal.source === 'manual' || role === 'user' ? 8 : 12}
                key={item.format('YYYY-MM-DD')}
              >
                <div
                  className={`date-item ${
                    item.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
                      ? 'today'
                      : ''
                  } ${item.format('ddd') === 'Sun' ? 'sunday' : ''}`}
                >
                  {goal.input_type === 'number' && this.renderDate(item)}

                  {goal.input_type === 'number'
                    ? this.renderNumberInput(item, isExpertView)
                    : this.renderBinary(item, isExpertView)}
                </div>
              </Col>
            ))}
          </Row>
        )}
      </div>
    )
  }
}

export default EditView
