import React, { useState } from 'react'
import { Popover } from 'antd'

export default function ManBack(props) {
  const { opacities, getContent, quality, overlayStyle, colorMaps } = props
  const [hoverIndex, setHover] = useState(null)
  return (
    <svg
      width="350"
      height="630"
      viewBox="0 0 262 505"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M118.593 272.01C118.925 278.513 118.427 285.016 118.344 291.519C118.261 296.225 117.764 300.931 118.427 305.722C119.091 310.6 119.506 315.391 119.92 320.268C120.086 323.007 119.837 325.659 119.589 328.312C118.759 335.927 118.178 343.457 119.174 351.157C119.837 356.12 120.335 361.254 119.589 366.302C119.257 368.869 118.593 371.351 118.013 373.918"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.3629 283.047C95.1187 284.416 95.1187 286.127 95.0358 287.753C94.621 293.058 94.3722 298.449 94.3722 303.754C94.4551 310.513 95.3676 317.273 95.5335 324.118C95.6164 326.428 95.4505 328.739 95.3676 331.134C95.1187 337.723 95.6994 344.311 95.9482 350.814"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.091 88.5574C105.985 87.445 110.962 86.3327 115.441 83.8513C119.174 81.7977 121.911 82.5678 124.731 86.3327C125.312 87.1028 125.893 88.044 126.639 88.4718C128.879 89.9264 128.381 91.98 127.717 93.8624C127.137 95.4026 126.888 96.686 127.552 98.2262C127.718 98.7396 128.049 99.5097 127.635 100.28C126.224 103.103 127.22 105.927 128.049 108.665C128.547 110.462 128.547 112.259 128.381 113.97C128.215 115.254 128.298 116.537 128.713 117.649C129.211 119.019 129.211 120.388 129.211 121.757C129.211 123.382 129.128 124.923 129.542 126.463C129.874 127.832 129.874 129.115 129.294 130.57C128.713 131.853 128.547 133.308 128.962 134.848C129.708 137.501 128.547 140.153 128.962 142.891C129.128 144.004 128.962 145.201 129.211 146.399C129.625 148.795 128.049 150.763 127.635 152.988"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.7552 183.706C41.783 194.487 39.4604 206.466 36.972 218.445C35.8936 223.494 35.23 228.628 32.2439 232.992"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.5429 284.673C94.3723 282.021 94.6212 279.197 95.2018 276.459C96.0313 272.608 97.5244 269.186 98.9345 265.592C99.681 263.71 100.262 261.742 100.842 259.774C101.506 257.549 101.008 255.239 100.262 253.014C99.681 251.303 99.2663 249.506 98.8515 247.709C98.2709 244.971 96.6949 242.575 95.4507 240.179C94.6212 238.553 92.8792 238.126 91.1373 238.297"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.98 151.191C123.073 152.646 121.331 154.101 121.828 156.924C119.506 160.09 119.589 164.454 116.769 167.534C115.11 169.417 114.28 172.155 113.368 174.636C113.036 175.406 113.119 176.519 113.616 177.545C113.865 178.059 114.197 179.257 113.119 179.77C111.626 180.455 111.543 181.995 111.377 183.278C111.128 185.075 110.381 186.359 109.137 187.642C107.312 189.525 105.488 191.407 105.57 194.402"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M128.547 241.549C128.215 245.399 127.385 249.164 126.473 252.843C125.063 258.491 121.579 262.598 117.017 266.106C112.206 269.785 106.897 272.438 101.837 275.689"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.234 37.0479C124.068 38.0746 124.897 38.4169 125.478 38.8447C126.971 39.9571 126.888 41.3261 126.722 42.9518C126.39 46.1177 125.644 49.2837 125.81 52.6207C125.976 56.5567 125.478 60.4927 123.736 64.172C123.321 65.0276 122.824 66.14 123.487 67.2523C123.653 67.5946 123.653 68.108 123.487 68.4502C122.326 71.0172 122.243 73.7553 122.077 76.4933C121.911 78.8892 121.082 81.0283 118.925 82.4829"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.0682 182.851C68.824 188.926 67.4969 195.086 64.8425 200.734C62.1882 206.552 59.6997 212.456 56.1329 217.761"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.0038 246.512C83.4231 248.993 84.1696 251.218 84.9162 253.528C86.3263 257.55 86.0775 261.742 84.9162 265.849C83.5061 270.983 82.1789 276.117 81.9301 281.508"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.28 110.977C114.197 108.752 114.778 106.613 115.11 104.388C115.441 102.42 115.441 100.366 115.275 98.3985C114.861 94.2914 111.875 92.5801 108.557 91.211C106.151 90.1842 103.58 89.842 101.174 88.9863"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.989 241.549C120.916 246.597 118.51 251.389 114.695 255.325C110.63 259.517 105.985 263.197 102.335 267.817"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.842 283.047C120.584 285.785 120.999 288.951 122.243 291.86C124.234 296.481 123.902 301.272 123.57 306.064C123.404 308.716 122.906 311.283 122.575 313.85"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.054 464.188C109.22 471.375 108.639 478.562 108.639 485.75C108.639 486.862 108.639 487.975 107.893 488.83"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.98 226.403C119.589 229.826 116.188 235.217 112.455 240.179C110.132 243.26 107.81 246.34 105.487 249.42"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118.261 456.83C118.676 462.82 118.261 468.895 118.261 474.884C118.261 476.852 118.344 478.906 119.672 480.617"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.81 264.994C125.395 273.037 124.483 280.909 121.413 288.439"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.4371 99.8525C76.3726 104.473 73.8842 109.949 72.474 115.853C72.0593 117.65 71.0639 118.934 69.6538 119.96"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.4924 104.388C66.5846 106.356 65.921 109.094 65.4233 111.575C64.8426 114.399 64.5109 117.394 64.5109 120.388C64.5109 121.672 65.1745 122.784 64.9256 124.068"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4237 237.87C27.6818 241.122 25.0275 243.689 23.2855 246.94C21.5436 250.106 19.5529 253.186 18.7234 256.78"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.308 198.423C108.225 201.332 106.732 203.728 105.488 206.209C103.663 209.717 102.501 213.397 101.921 217.333"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.972 241.206C35.8937 242.49 36.8061 243.859 37.055 245.056C37.7186 248.992 37.2209 252.928 36.6402 256.779C36.5573 257.292 36.6402 257.891 36.6402 258.405"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.1794 213.654C44.4374 221.355 41.7001 228.799 38.2163 235.816"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3543 218.787C41.6999 223.835 38.7967 228.798 36.806 234.189C36.6401 234.617 36.3912 235.044 36.2253 235.387"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.9945 137.672C88.8148 142.977 93.6258 145.972 98.3538 149.138C99.0174 149.565 99.681 149.993 100.345 150.335"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9906 240.35C30.9998 245.227 30.668 250.361 30.5851 255.58"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.676 88.8141C99.7638 88.7285 98.9344 88.6429 98.1049 88.4718C94.1234 87.7017 90.1419 86.6749 86.4092 85.1348"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M104.326 99.8525C99.9299 102.163 96.3631 105.5 93.211 109.265C92.3816 110.291 91.635 111.318 90.8055 112.345"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.589 131.512C84.5014 133.822 86.4922 135.02 88.4 136.218C91.0543 137.929 93.8746 139.555 97.1096 139.726"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.4279 113.458C85.4969 117.736 89.9762 119.619 94.5383 121.244C94.7872 121.33 95.036 121.159 95.3678 121.073"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.0129 147.513C80.6857 152.39 77.7825 156.24 74.4646 159.834"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.5386 142.208C59.0363 143.919 59.0363 145.631 59.2851 147.342C59.6998 149.738 60.7782 151.791 62.1054 153.674"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.5613 108.922C89.8931 112.173 91.552 114.911 93.211 117.564C96.446 122.698 101.008 126.377 106.317 129.115"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6679 239.152C29.0089 242.746 25.8568 245.227 23.8661 248.564"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.413 475.654C121.247 478.906 120.335 481.729 117.017 483.013"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.828 482.672C121.082 485.068 120.169 487.464 120.003 490.03"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.602 54.332C117.681 56.1289 117.764 58.1825 118.593 60.0649"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.584 41.583C120.999 43.1232 121.662 44.4922 122.409 45.8613"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.7048 250.191C22.456 250.705 22.2072 251.304 21.8754 251.817"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.1511 207.663C91.7271 207.577 92.0589 205.952 92.3078 205.01C93.3861 200.304 96.4552 197.994 100.769 197.053C104.667 196.197 108.649 195.513 112.464 197.053C117.026 198.85 120.344 201.844 121.091 207.235C121.837 212.54 123.248 217.76 123.828 223.065C123.911 223.92 124.16 224.776 124.741 225.546C125.819 226.829 125.819 228.455 125.736 230.081C125.653 230.937 125.736 231.878 125.736 232.733C125.57 236.584 126.483 240.006 129.552 242.317C131.128 243.515 131.128 244.969 130.879 246.424C130.132 251.044 129.552 255.75 128.224 260.2C126.98 264.478 126.814 268.671 126.98 272.949C127.063 277.655 126.814 282.447 126.483 287.153C126.151 291.431 126.068 295.624 125.736 299.902C125.404 304.608 125.238 309.4 124.906 314.106C124.243 321.978 123.662 329.85 123.828 337.722C123.911 342.428 124.575 347.219 124.824 351.926C125.155 358.086 125.653 364.332 122.169 369.98C120.842 372.204 120.344 374.857 119.017 377.167C118.437 378.194 118.851 379.477 119.017 380.59C119.93 386.579 120.344 392.74 121.754 398.644C122.501 401.724 123.165 404.719 123.579 407.885C123.994 410.88 123.828 413.875 122.75 416.698C122.086 418.581 121.589 420.463 120.759 422.26C119.349 425.34 117.524 425.768 115.367 423.287C111.884 419.351 109.81 414.559 108.234 409.511C107.321 406.687 107.238 403.778 107.073 400.869C106.907 397.189 106.99 393.51 107.073 389.745C107.073 382.814 107.736 375.884 108.234 369.039C108.483 365.787 108.649 362.621 109.229 359.37C109.644 357.145 110.639 355.263 112.049 353.637C112.464 353.123 112.962 352.696 113.46 352.182"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.4324 126.376C83.5983 129.028 83.6812 131.681 83.8471 134.419C83.8471 134.59 83.9301 134.932 84.013 135.018C86.8333 137.927 86.7503 141.863 87.0821 145.543C87.8286 152.644 88.824 159.661 90.8977 166.506C91.4784 168.56 92.4737 170.442 93.1373 172.41C94.2986 175.576 93.9668 178.913 93.9668 182.079C94.0498 188.068 92.3908 193.801 90.6489 199.534C89.4046 203.556 89.4876 207.663 89.4046 211.684C89.3217 216.647 87.9116 221.182 86.6674 225.888C85.589 229.91 84.9254 234.017 84.5936 238.21C84.2619 242.145 84.2619 246.167 83.1835 250.189C82.5199 252.756 82.354 255.665 82.2711 258.403C82.1052 263.793 82.2711 269.184 82.0222 274.66C81.7734 280.308 82.2711 285.869 82.7688 291.517C83.1835 296.565 84.096 301.528 85.0084 306.576C86.2526 313.849 88.4922 320.694 90.483 327.711C91.5613 331.561 91.976 335.583 93.1373 339.433C94.1327 342.684 95.294 345.936 95.9576 349.273C96.4553 351.84 96.4553 354.321 96.6212 356.888C96.953 361.081 96.4553 365.274 95.8746 369.381C95.7087 370.236 95.8746 371.419 95.8746 372.894"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.317 277.484C109.147 279.11 108.649 280.821 108.483 282.447C108.068 287.752 108.732 292.972 109.064 298.277C109.229 301.186 109.395 304.01 109.478 306.919C109.644 309.999 109.478 313.079 109.478 316.16C109.478 319.582 109.561 322.748 111.303 326.085C113.875 330.963 114.704 336.524 115.534 342C116.031 345.594 116.197 349.188 117.027 352.696C117.275 353.637 116.944 354.578 116.944 355.605C116.944 359.285 117.358 362.878 117.524 366.472C117.607 369.809 117.441 373.317 118.603 376.569"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M121.364 420.553C121.364 422.25 120.178 426.71 120.178 428.079C120.178 432.87 120.427 437.662 120.012 442.453C119.764 445.277 119.515 448.015 119.432 450.839C119.349 454.176 119.681 457.427 119.764 460.679C119.846 463.93 119.764 467.096 120.178 470.348C120.427 471.888 120.676 473.428 121.505 474.626C122.916 476.68 122.75 478.819 122.501 481.043C122.418 482.07 122.003 483.097 122.501 484.124C123.164 485.835 122.667 487.717 121.837 489.001C120.51 490.969 120.51 493.108 120.344 495.247C120.344 495.846 120.344 496.445 120.344 497.13C120.261 499.525 119.432 500.124 117.192 500.638C112.132 501.75 107.736 499.611 103.257 497.729C98.8605 495.932 94.4643 494.306 90.3168 491.996C88.5749 491.054 87.5795 489.856 88.1602 488.659C88.5749 487.717 90.7316 487.375 91.8929 488.316C93.4689 489.6 95.0449 490.113 96.8698 489.514C97.9481 489.172 98.6946 490.199 99.69 490.37C101.847 490.798 104.086 490.712 106.243 490.712"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.1377 174.635C57.8842 176.004 56.7229 177.116 56.4741 178.314C55.8105 181.138 55.2299 183.876 55.3958 186.785C55.9764 197.823 52.8244 207.92 48.594 217.76C46.0226 223.664 43.0365 229.396 39.8844 235.044C39.6356 235.557 39.0549 235.814 39.0549 236.413"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.899 414.504C92.3997 417.455 93.469 421.063 93.9667 423.373C94.7133 427.224 95.7916 431.074 96.5381 434.924C97.2847 438.86 98.28 442.711 99.1095 446.561C99.7731 449.984 101.515 453.15 102.096 456.658C103.008 462.134 104.833 467.525 105.248 473.086C105.579 476.937 104.086 480.188 101.266 482.755C100.851 483.183 100.354 483.525 100.105 483.953C98.363 486.606 95.8745 487.547 92.8884 487.461C92.3078 487.461 91.8101 487.547 91.4783 488.06"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.579 37.4736C123.911 39.3561 123.496 41.2385 123.247 43.0354C122.75 46.9714 121.837 50.9073 120.925 54.7578C120.095 58.1804 118.685 61.4318 117.192 64.5977C115.284 68.7049 111.635 70.5873 108.068 72.6409C104.75 74.5233 101.183 75.9779 97.6993 77.5181C95.1279 78.716 92.6395 80.1706 89.9851 81.1118C88.575 81.6252 87.1649 81.1118 85.7548 81.5396C82.6857 82.4808 79.4507 82.8231 76.3816 84.021C73.8102 85.0478 71.8194 86.588 70.4923 88.8127C66.8425 95.1445 63.9393 101.819 62.4463 109.092C61.7827 112.343 62.1145 115.509 62.944 118.504C63.5246 120.472 64.1882 122.44 64.6859 124.493"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.5477 434.422C98.8703 440.583 103.423 447.33 105.58 453.491C107.653 459.309 109.561 465.128 110.805 471.289C111.552 475.053 111.635 478.733 111.054 482.412C110.639 484.893 110.639 487.717 108.566 489.6"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.6397 179.341C72.566 179.854 71.073 181.309 69.6628 182.849C68.0868 184.56 66.7597 186.528 64.6859 187.812C63.5247 188.582 62.944 189.951 62.3634 191.405C60.7044 195.684 58.7966 199.791 56.9717 203.898C54.3174 210.23 51.0824 216.305 48.511 222.637C47.8474 224.348 46.8521 225.974 46.8521 227.942"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.9528 101.904C93.5519 105.926 89.4045 108.835 85.0083 111.573C82.1051 113.37 79.3678 115.68 76.2157 116.793C73.0637 117.819 72.2342 120.215 71.3218 122.697C69.8287 126.718 68.9163 130.825 68.7504 135.189C68.6674 137.585 68.8333 140.409 66.2619 141.949C66.179 141.949 66.096 141.949 66.096 141.949C62.4463 142.804 62.7781 145.799 62.4463 148.708C61.9486 153.243 62.2804 157.778 62.1145 162.228C62.0316 163.939 62.944 165.65 62.3634 167.362"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.0129 135.189C83.6811 135.532 83.5981 135.96 83.5981 136.387C83.6811 141.692 82.6857 146.741 81.1926 151.789C80.1143 155.554 79.9484 159.49 78.7871 163.255C78.1235 165.394 77.5429 167.533 76.7963 169.587C75.8839 171.897 75.5521 174.207 76.3816 176.603C76.4646 176.86 76.4646 177.116 76.2157 177.459C73.2296 181.651 72.4001 186.785 70.6582 191.406C67.3402 200.304 63.3587 208.775 57.2205 216.048C54.0685 219.813 51.1653 223.92 47.7644 227.514C45.4419 229.996 43.7829 232.819 41.6262 235.386C39.6355 237.696 39.8014 240.349 40.2991 243.001C41.0456 247.023 40.6309 250.873 39.4696 254.724C37.7277 260.542 36.1517 266.361 34.4927 272.179C33.7462 274.575 32.7508 275.174 30.76 274.404"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.266 203.557C116.28 205.268 113.211 206.808 111.054 209.717C109.727 211.514 107.902 212.969 106.077 214.167C103.506 215.964 101.017 217.846 98.7777 220.071C94.8791 223.921 91.5612 228.371 90.3999 233.932C89.8193 236.67 88.9898 239.237 86.9161 241.034C85.9207 241.975 85.9207 243.601 84.5935 244.2"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.478 323.348C108.814 323.861 109.146 324.546 109.063 325.23C108.814 330.278 108.151 335.241 107.57 340.204C107.072 344.226 106.492 348.162 105.496 352.098C104.418 356.376 103.008 360.568 100.934 364.419C99.5241 366.986 98.5287 369.809 96.455 372.12C95.0449 373.745 95.1279 376.227 94.879 378.28C94.0495 384.954 92.3906 391.457 90.8146 397.96C89.4044 403.779 89.3215 409.768 92.3076 415.159C93.7177 417.726 95.4597 420.378 97.9481 422.175C99.5241 423.288 100.934 423.031 102.096 421.405C104.75 417.811 106.741 413.875 107.072 409.255C107.072 409.084 107.321 408.998 107.487 408.827"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.1192 170.956C57.0547 174.379 52.9073 177.63 49.1746 181.481C46.852 183.876 44.7783 186.7 42.4558 189.096C40.9627 190.636 40.8798 192.861 40.2991 194.829C38.806 199.535 37.5618 204.412 36.3176 209.204C35.9029 210.915 34.9075 212.284 33.995 213.653C32.502 215.963 31.0919 218.359 30.4283 220.926C29.267 225.547 26.7011 229.055 24.2127 232.82C23.8809 233.333 23.1701 233.941 22.3477 234.9"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2162 242.231C38.1425 241.974 36.6494 240.434 34.6586 240.006C31.5895 239.321 29.184 237.182 26.5297 235.728C24.5389 234.701 22.7141 234.444 20.5574 235.642C16.2441 238.038 13.2579 241.289 11.1013 245.91C9.69117 248.905 7.86631 251.899 5.29491 254.21C4.54837 254.894 3.47004 255.579 4.29953 256.862C5.04606 258.06 6.37324 258.146 7.45156 258.06C8.94463 257.889 10.1059 256.948 11.1013 255.75C12.1796 254.381 13.0091 252.755 14.4192 251.557"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.219 451.389C118.721 455.068 117.69 461.79 116.944 465.469C116.28 468.806 116.529 472.229 116.612 475.566C116.778 479.673 117.192 483.865 118.105 487.887C118.437 489.256 118.934 490.283 120.096 490.967"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M130.984 1.03613C120.256 1.47877 111.635 8.3817 109.063 17.5372C108.068 20.9598 108.566 24.468 109.561 27.805C109.727 28.5751 109.976 29.174 109.727 29.8585"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.6995 94.1182C99.0266 95.7439 100.934 96.4284 102.51 97.7975C105.745 100.536 107.239 104.044 108.317 108.151C109.561 113.199 111.054 118.162 113.045 122.954C114.787 127.403 116.778 131.681 118.354 136.216C119.598 139.81 121.34 143.318 123.248 146.741C125.653 150.933 128.059 155.126 130.796 159.062"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.917 250.189C14.1705 251.045 14.6681 251.986 14.7511 252.842C15.1658 258.489 14.834 264.051 14.917 269.698C14.9999 272.95 15.0829 276.116 15.0829 279.367C15.0829 281.421 15.4976 281.763 17.6543 281.421"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.874 125.777C110.473 128.259 106.658 129.799 102.676 130.398C98.3629 131.082 93.9666 130.569 89.7363 129.029C88.658 128.601 87.5796 128.43 86.5013 128.088C83.0175 126.804 82.105 123.98 81.9391 120.643C81.8562 118.419 81.8562 116.108 82.105 113.884"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.248 136.216C70.0775 139.296 70.907 142.377 71.6535 145.457C72.4001 148.879 73.1466 152.216 73.7273 155.725C74.5567 161.372 76.1328 166.934 73.7273 172.581C73.5614 173.009 73.8102 173.437 74.142 173.779"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.248 39.6982C121.257 40.0405 120.427 41.5807 119.432 43.2064C117.939 45.6022 117.524 48.2547 117.275 50.9928C116.778 55.6133 116.197 60.2339 116.695 64.9399"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.036 67.4214C114.87 67.1647 114.621 66.908 114.621 66.5658C114.953 59.8061 113.792 53.3032 112.133 46.8003C111.386 43.9766 110.888 40.9818 110.971 37.9871C111.054 36.1046 110.971 34.1366 110.64 32.2542C110.308 30.2862 108.898 29.4306 107.736 28.4038C107.073 27.8048 105.497 29.1739 105.165 30.4573C104.833 31.9975 104.75 33.7088 105.248 35.1634C106.16 37.9015 106.824 40.8107 107.653 43.6344C108.317 46.2013 109.561 47.057 111.967 47.1425"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126.814 264.563C125.072 265.334 124.243 267.13 122.833 268.328C117.524 272.949 111.635 276.115 104.916 278.254C100.437 279.708 95.9575 281.762 92.8884 285.869C91.2294 288.094 90.7317 290.832 89.7363 293.228"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.3036 157.008C56.2252 159.917 55.6446 162.997 54.6492 165.907C53.9027 167.875 52.4925 168.987 51.3313 170.356C46.7691 175.319 44.2807 181.308 42.3728 187.64C42.207 188.068 41.895 189.629 41.7291 189.971"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.326 195.769C105.579 192.09 103.423 189.01 101.515 185.929C99.5243 182.763 97.1188 179.769 95.3769 176.432C95.128 176.004 94.9621 175.319 94.2156 175.405"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.483 119.018C67.8379 120.814 64.6029 124.237 62.5292 128.858C61.285 131.596 60.4555 134.505 59.709 137.5C59.0454 139.895 60.5385 140.837 61.9486 142.035C62.3633 142.377 62.944 142.291 63.1928 142.805"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.7186 263.793C21.3869 267.387 20.1426 270.724 19.479 274.232C18.9813 276.457 18.7325 278.767 18.1519 280.992C17.8201 282.275 18.5666 282.788 19.2302 283.216C20.0597 283.815 21.221 283.73 21.9675 283.045C22.8799 282.275 23.4606 281.163 23.7924 279.965C25.1195 275.088 26.4467 270.21 27.7739 265.419"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.6443 139.251C58.7319 141.048 58.7136 144.002 58.6306 145.884C58.3818 149.392 57.3686 153.338 57.5345 156.846"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0276 229.055C31.258 232.135 35.1565 235.729 40.3823 237.098"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2485 264.136C32.7508 267.131 31.5895 269.869 30.843 272.778C30.3453 274.746 27.1103 280.65 25.8661 281.848C24.9536 282.618 24.1242 282.96 23.2947 281.848"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.487 343.454C109.146 345.935 110.473 348.674 112.63 350.898C113.957 352.182 115.036 353.722 116.612 354.578"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.506 481.043C104.252 483.268 105.662 484.979 106.741 486.947C108.483 490.027 109.976 493.279 111.303 496.616C111.386 496.872 111.469 497.044 111.718 497.044"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.93 483.096C102.925 484.721 103.672 486.518 105.082 487.802C106.077 488.743 106.077 490.112 106.658 491.31C105.58 492.593 103.589 491.909 102.428 493.449C104.418 494.134 105.911 495.674 108.068 495.845"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.1329 157.008C58.7136 158.206 58.7136 159.575 58.8795 160.944C59.2942 163.254 60.2066 165.308 61.5338 167.019C62.4462 168.302 63.3587 169.5 63.8564 170.955"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.2945 163.341C57.7184 164.282 56.6401 166.079 54.7323 166.592"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.906 491.311C107.819 492.252 108.234 493.45 108.234 494.562C108.317 495.845 108.731 496.701 109.644 497.471"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.9899 81.625C87.8287 82.7373 86.4185 82.994 85.0084 83.5074"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.497 353.295C106.907 354.407 108.151 355.605 109.063 357.231"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.6351 172.581C74.7227 173.351 73.7273 173.865 73.2296 175.062"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.407 272.011C143.075 278.514 143.573 285.017 143.656 291.52C143.739 296.226 144.237 300.932 143.573 305.723C142.909 310.601 142.495 315.392 142.08 320.269C141.914 323.007 142.163 325.66 142.412 328.313C143.241 335.928 143.822 343.458 142.826 351.158C142.163 356.121 141.665 361.255 142.412 366.303C142.743 368.87 143.407 371.352 143.988 373.919"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.637 283.048C166.881 284.417 166.881 286.128 166.964 287.754C167.379 293.059 167.628 298.45 167.628 303.755C167.545 310.514 166.633 317.274 166.467 324.119C166.384 326.429 166.55 328.74 166.633 331.135C166.881 337.724 166.301 344.312 166.052 350.815"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.909 88.5574C156.015 87.445 151.038 86.3327 146.559 83.8513C142.826 81.7977 140.089 82.5678 137.269 86.3327C136.688 87.1028 136.107 88.044 135.361 88.4718C133.121 89.9264 133.619 91.98 134.282 93.8624C134.863 95.4026 135.112 96.686 134.448 98.2262C134.282 98.7396 133.951 99.5097 134.365 100.28C135.775 103.103 134.78 105.927 133.951 108.665C133.453 110.462 133.453 112.259 133.619 113.97C133.785 115.254 133.702 116.537 133.287 117.649C132.789 119.019 132.789 120.388 132.789 121.757C132.789 123.382 132.872 124.923 132.458 126.463C132.126 127.832 132.126 129.115 132.706 130.57C133.287 131.853 133.453 133.308 133.038 134.848C132.292 137.501 133.453 140.153 133.038 142.891C132.872 144.004 133.038 145.201 132.789 146.399C132.375 148.795 133.951 150.763 134.365 152.988"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M214.245 183.706C220.217 194.487 222.54 206.466 225.028 218.445C226.106 223.494 226.77 228.628 229.756 232.992"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.457 284.674C167.628 282.022 167.379 279.198 166.798 276.46C165.969 272.609 164.476 269.187 163.066 265.593C162.319 263.711 161.738 261.743 161.158 259.775C160.494 257.55 160.992 255.24 161.738 253.015C162.319 251.304 162.734 249.507 163.149 247.71C163.729 244.972 165.305 242.576 166.549 240.18C167.379 238.554 169.121 238.127 170.863 238.298"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.02 151.192C138.928 152.647 140.67 154.102 140.172 156.925C142.494 160.091 142.411 164.455 145.232 167.535C146.891 169.418 147.72 172.156 148.633 174.637C148.964 175.407 148.881 176.52 148.384 177.546C148.135 178.06 147.803 179.258 148.881 179.771C150.375 180.456 150.457 181.996 150.623 183.279C150.872 185.076 151.619 186.36 152.863 187.643C154.688 189.526 156.513 191.408 156.43 194.403"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M133.453 241.548C133.785 245.398 134.614 249.163 135.527 252.842C136.937 258.49 140.421 262.597 144.983 266.105C149.794 269.784 155.103 272.437 160.162 275.688"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.766 37.0479C137.932 38.0746 137.103 38.4169 136.522 38.8447C135.029 39.9571 135.112 41.3261 135.278 42.9518C135.61 46.1177 136.356 49.2837 136.19 52.6207C136.024 56.5567 136.522 60.4927 138.264 64.172C138.679 65.0276 139.176 66.14 138.513 67.2523C138.347 67.5946 138.347 68.108 138.513 68.4502C139.674 71.0172 139.757 73.7553 139.923 76.4933C140.089 78.8892 140.918 81.0283 143.075 82.4829"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M191.932 182.851C193.176 188.926 194.503 195.086 197.157 200.734C199.812 206.552 202.3 212.456 205.867 217.761"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.996 246.512C178.577 248.993 177.83 251.218 177.084 253.528C175.674 257.55 175.922 261.742 177.084 265.849C178.494 270.983 179.821 276.117 180.07 281.508"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.72 110.976C147.803 108.751 147.223 106.612 146.891 104.387C146.559 102.419 146.559 100.366 146.725 98.3975C147.14 94.2904 150.126 92.5791 153.444 91.21C155.849 90.1833 158.421 89.841 160.826 88.9854"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.011 241.548C141.084 246.596 143.49 251.388 147.305 255.324C151.37 259.517 156.015 263.196 159.665 267.816"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.158 283.048C141.416 285.786 141.002 288.952 139.757 291.861C137.767 296.482 138.098 301.273 138.43 306.065C138.596 308.717 139.094 311.284 139.426 313.851"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.946 464.189C152.78 471.377 153.361 478.564 153.361 485.752C153.361 486.864 153.361 487.977 154.107 488.832"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.02 226.403C142.411 229.826 145.812 235.217 149.545 240.179C151.868 243.26 154.19 246.34 156.513 249.42"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.739 456.83C143.324 462.82 143.739 468.895 143.739 474.884C143.739 476.852 143.656 478.906 142.328 480.617"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.19 264.993C136.605 273.036 137.517 280.908 140.587 288.438"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.563 99.8525C185.627 104.473 188.116 109.949 189.526 115.853C189.941 117.65 190.936 118.934 192.346 119.96"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.508 104.388C195.416 106.356 196.079 109.094 196.577 111.575C197.157 114.399 197.489 117.394 197.489 120.388C197.489 121.672 196.826 122.784 197.075 124.068"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.576 237.869C234.318 241.121 236.973 243.688 238.715 246.939C240.456 250.105 242.447 253.185 243.277 256.779"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.692 198.423C153.775 201.332 155.268 203.728 156.513 206.209C158.338 209.717 159.499 213.397 160.079 217.333"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.028 241.207C226.106 242.491 225.194 243.86 224.945 245.057C224.281 248.993 224.779 252.929 225.36 256.78C225.443 257.293 225.36 257.892 225.36 258.406"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.821 213.653C217.563 221.354 220.3 228.798 223.784 235.815"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.646 218.788C220.3 223.836 223.203 228.799 225.194 234.19C225.36 234.618 225.609 235.045 225.775 235.388"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M176.005 137.672C173.185 142.977 168.374 145.972 163.646 149.138C162.982 149.565 162.319 149.993 161.655 150.335"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M229.01 240.351C231 245.228 231.332 250.362 231.415 255.581"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M161.324 88.815C162.236 88.7295 163.066 88.6439 163.895 88.4728C167.877 87.7027 171.858 86.6759 175.591 85.1357"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.674 99.8525C162.07 102.163 165.637 105.5 168.789 109.265C169.619 110.291 170.365 111.318 171.195 112.345"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.411 131.512C177.499 133.822 175.508 135.02 173.6 136.218C170.946 137.929 168.126 139.555 164.891 139.726"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M179.572 113.458C176.503 117.736 172.024 119.619 167.462 121.244C167.213 121.33 166.964 121.159 166.632 121.073"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M179.987 147.512C181.315 152.389 184.218 156.239 187.536 159.833"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.462 142.208C202.964 143.919 202.964 145.631 202.715 147.342C202.3 149.738 201.222 151.791 199.895 153.674"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.439 108.922C172.107 112.173 170.448 114.911 168.789 117.564C165.554 122.698 160.992 126.377 155.683 129.115"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M231.332 239.152C232.991 242.746 236.143 245.227 238.134 248.564"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.587 475.654C140.753 478.906 141.665 481.729 144.983 483.013"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.172 482.67C140.919 485.066 141.831 487.462 141.997 490.029"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.398 54.3311C144.319 56.1279 144.237 58.1815 143.407 60.0639"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.416 41.583C141.001 43.1232 140.338 44.4922 139.591 45.8613"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.295 250.19C239.544 250.704 239.793 251.303 240.125 251.816"
        stroke="#7689B1"
        strokeWidth="0.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.849 207.664C170.273 207.578 169.941 205.953 169.692 205.011C168.614 200.305 165.545 197.995 161.232 197.054C157.333 196.198 153.352 195.514 149.536 197.054C144.974 198.851 141.656 201.845 140.909 207.236C140.163 212.541 138.753 217.761 138.172 223.066C138.089 223.921 137.84 224.777 137.26 225.547C136.181 226.83 136.181 228.456 136.264 230.082C136.347 230.938 136.264 231.879 136.264 232.734C136.43 236.585 135.518 240.007 132.449 242.318C130.873 243.516 130.873 244.97 131.121 246.425C131.868 251.045 132.449 255.751 133.776 260.201C135.02 264.479 135.186 268.672 135.02 272.95C134.937 277.656 135.186 282.448 135.518 287.154C135.849 291.432 135.932 295.625 136.264 299.903C136.596 304.609 136.762 309.401 137.094 314.107C137.757 321.979 138.338 329.851 138.172 337.723C138.089 342.429 137.425 347.22 137.177 351.926C136.845 358.087 136.347 364.333 139.831 369.981C141.158 372.205 141.656 374.858 142.983 377.168C143.564 378.195 143.149 379.478 142.983 380.591C142.071 386.58 141.656 392.741 140.246 398.645C139.499 401.725 138.836 404.72 138.421 407.886C138.006 410.881 138.172 413.876 139.25 416.699C139.914 418.582 140.412 420.464 141.241 422.261C142.651 425.341 144.476 425.769 146.633 423.288C150.117 419.352 152.19 414.56 153.766 409.512C154.679 406.688 154.762 403.779 154.928 400.87C155.093 397.19 155.01 393.511 154.928 389.746C154.928 382.815 154.264 375.885 153.766 369.039C153.517 365.788 153.352 362.622 152.771 359.371C152.356 357.146 151.361 355.264 149.951 353.638C149.536 353.124 149.038 352.697 148.541 352.183"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.568 126.377C178.402 129.029 178.319 131.682 178.153 134.42C178.153 134.591 178.07 134.933 177.987 135.019C175.167 137.928 175.25 141.864 174.918 145.544C174.171 152.645 173.176 159.662 171.102 166.507C170.522 168.561 169.526 170.443 168.863 172.411C167.701 175.577 168.033 178.914 168.033 182.08C167.95 188.069 169.609 193.802 171.351 199.535C172.595 203.557 172.512 207.664 172.595 211.685C172.678 216.648 174.088 221.183 175.333 225.889C176.411 229.911 177.075 234.018 177.406 238.21C177.738 242.146 177.738 246.168 178.816 250.19C179.48 252.757 179.646 255.666 179.729 258.404C179.895 263.794 179.729 269.185 179.978 274.661C180.227 280.308 179.729 285.87 179.231 291.517C178.816 296.566 177.904 301.529 176.992 306.577C175.747 313.85 173.508 320.695 171.517 327.712C170.439 331.562 170.024 335.583 168.863 339.434C167.867 342.685 166.706 345.937 166.042 349.274C165.545 351.841 165.545 354.322 165.379 356.889C165.047 361.082 165.545 365.275 166.125 369.382C166.291 370.237 166.125 371.42 166.125 372.895"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.683 277.485C152.854 279.111 153.351 280.822 153.517 282.448C153.932 287.753 153.268 292.973 152.937 298.278C152.771 301.187 152.605 304.011 152.522 306.92C152.356 310 152.522 313.08 152.522 316.161C152.522 319.583 152.439 322.749 150.697 326.086C148.126 330.963 147.296 336.525 146.467 342.001C145.969 345.595 145.803 349.189 144.974 352.697C144.725 353.638 145.056 354.579 145.056 355.606C145.056 359.286 144.642 362.879 144.476 366.473C144.393 369.81 144.559 373.318 143.398 376.57"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.636 420.553C140.636 422.25 141.822 426.71 141.822 428.079C141.822 432.87 141.573 437.662 141.988 442.453C142.236 445.277 142.485 448.015 142.568 450.839C142.651 454.176 142.319 457.427 142.236 460.679C142.154 463.93 142.236 467.096 141.822 470.348C141.573 471.888 141.324 473.428 140.495 474.626C139.084 476.68 139.25 478.819 139.499 481.043C139.582 482.07 139.997 483.097 139.499 484.124C138.836 485.835 139.333 487.717 140.163 489.001C141.49 490.969 141.49 493.108 141.656 495.247C141.656 495.846 141.656 496.445 141.656 497.13C141.739 499.525 142.568 500.124 144.808 500.638C149.868 501.75 154.264 499.611 158.743 497.729C163.139 495.932 167.536 494.306 171.683 491.996C173.425 491.054 174.42 489.856 173.84 488.659C173.425 487.717 171.268 487.375 170.107 488.316C168.531 489.6 166.955 490.113 165.13 489.514C164.052 489.172 163.305 490.199 162.31 490.37C160.153 490.798 157.914 490.712 155.757 490.712"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.862 174.635C204.116 176.004 205.277 177.116 205.526 178.314C206.189 181.138 206.77 183.876 206.604 186.785C206.024 197.823 209.176 207.92 213.406 217.76C215.977 223.664 218.964 229.396 222.116 235.044C222.364 235.557 222.945 235.814 222.945 236.413"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.101 414.504C169.6 417.455 168.531 421.063 168.033 423.373C167.287 427.224 166.208 431.074 165.462 434.924C164.715 438.86 163.72 442.711 162.89 446.561C162.227 449.984 160.485 453.15 159.904 456.658C158.992 462.134 157.167 467.525 156.752 473.086C156.421 476.937 157.914 480.188 160.734 482.755C161.149 483.183 161.646 483.525 161.895 483.953C163.637 486.606 166.125 487.547 169.112 487.461C169.692 487.461 170.19 487.547 170.522 488.06"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.421 37.4746C138.089 39.357 138.504 41.2395 138.752 43.0363C139.25 46.9723 140.163 50.9083 141.075 54.7587C141.904 58.1813 143.315 61.4328 144.808 64.5987C146.716 68.7058 150.365 70.5883 153.932 72.6418C157.25 74.5243 160.817 75.9789 164.301 77.519C166.872 78.717 169.36 80.1716 172.015 81.1128C173.425 81.6262 174.835 81.1128 176.245 81.5406C179.314 82.4818 182.549 82.8241 185.618 84.022C188.19 85.0488 190.18 86.5889 191.508 88.8136C195.157 95.1454 198.061 101.82 199.554 109.093C200.217 112.344 199.885 115.51 199.056 118.505C198.475 120.473 197.812 122.441 197.314 124.494"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.452 434.424C163.13 440.585 158.577 447.332 156.42 453.493C154.347 459.311 152.439 465.13 151.195 471.291C150.448 475.055 150.365 478.735 150.946 482.414C151.36 484.895 151.36 487.719 153.434 489.601"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.36 179.342C189.434 179.855 190.927 181.31 192.337 182.85C193.913 184.561 195.241 186.529 197.314 187.813C198.476 188.583 199.056 189.952 199.637 191.406C201.296 195.685 203.204 199.792 205.028 203.899C207.683 210.231 210.918 216.306 213.489 222.638C214.153 224.349 215.148 225.975 215.148 227.943"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.047 101.905C168.448 105.927 172.595 108.836 176.992 111.574C179.895 113.371 182.632 115.681 185.784 116.794C188.936 117.82 189.766 120.216 190.678 122.698C192.171 126.719 193.084 130.826 193.25 135.19C193.333 137.586 193.167 140.41 195.738 141.95C195.821 141.95 195.904 141.95 195.904 141.95C199.554 142.805 199.222 145.8 199.554 148.709C200.051 153.244 199.72 157.779 199.885 162.229C199.968 163.94 199.056 165.651 199.637 167.363"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.987 135.19C178.319 135.533 178.402 135.961 178.402 136.388C178.319 141.693 179.314 146.742 180.807 151.79C181.886 155.555 182.052 159.491 183.213 163.256C183.876 165.395 184.457 167.534 185.204 169.588C186.116 171.898 186.448 174.208 185.618 176.604C185.535 176.861 185.535 177.117 185.784 177.46C188.77 181.652 189.6 186.786 191.342 191.407C194.66 200.305 198.641 208.776 204.779 216.049C207.932 219.814 210.835 223.921 214.236 227.515C216.558 229.996 218.217 232.82 220.374 235.387C222.365 237.697 222.199 240.35 221.701 243.002C220.954 247.024 221.369 250.874 222.53 254.725C224.272 260.543 225.848 266.362 227.507 272.18C228.254 274.576 229.249 275.175 231.24 274.405"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.734 203.557C145.72 205.268 148.789 206.808 150.946 209.717C152.273 211.514 154.098 212.969 155.923 214.167C158.494 215.964 160.983 217.846 163.222 220.071C167.121 223.921 170.439 228.371 171.6 233.932C172.181 236.67 173.01 239.237 175.084 241.034C176.079 241.975 176.079 243.601 177.406 244.2"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.522 323.349C153.185 323.862 152.854 324.547 152.937 325.231C153.185 330.279 153.849 335.242 154.43 340.205C154.927 344.226 155.508 348.162 156.503 352.098C157.582 356.377 158.992 360.569 161.066 364.42C162.476 366.987 163.471 369.81 165.545 372.121C166.955 373.746 166.872 376.228 167.121 378.281C167.95 384.955 169.609 391.458 171.185 397.961C172.595 403.78 172.678 409.769 169.692 415.16C168.282 417.727 166.54 420.379 164.052 422.176C162.476 423.289 161.066 423.032 159.904 421.406C157.25 417.812 155.259 413.876 154.927 409.256C154.927 409.085 154.678 408.999 154.513 408.828"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.881 170.956C204.945 174.379 209.093 177.63 212.825 181.481C215.148 183.876 217.222 186.7 219.544 189.096C221.037 190.636 221.12 192.861 221.701 194.829C223.194 199.535 224.438 204.412 225.682 209.204C226.097 210.915 227.093 212.284 228.005 213.653C229.498 215.963 230.908 218.359 231.572 220.926C232.733 225.547 235.299 229.055 237.787 232.82C238.119 233.333 238.83 233.941 239.652 234.9"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.784 242.232C223.858 241.975 225.351 240.435 227.341 240.007C230.41 239.322 232.816 237.183 235.47 235.729C237.461 234.702 239.286 234.445 241.443 235.643C245.756 238.039 248.742 241.29 250.899 245.911C252.309 248.906 254.134 251.9 256.705 254.211C257.452 254.895 258.53 255.58 257.7 256.863C256.954 258.061 255.627 258.147 254.548 258.061C253.055 257.89 251.894 256.949 250.899 255.751C249.82 254.382 248.991 252.756 247.581 251.558"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.781 451.391C143.279 455.07 144.31 461.792 145.057 465.471C145.72 468.808 145.471 472.231 145.388 475.568C145.222 479.675 144.808 483.867 143.895 487.889C143.563 489.258 143.066 490.285 141.905 490.969"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.234 1C145.609 2.21722 151.223 8.56179 152.937 17.5383C153.661 21.3307 153.434 24.4691 152.439 27.8061C152.273 28.5762 152.024 29.1752 152.273 29.8597"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.301 94.1191C162.973 95.7449 161.066 96.4294 159.49 97.7984C156.255 100.537 154.761 104.045 153.683 108.152C152.439 113.2 150.946 118.163 148.955 122.955C147.213 127.404 145.222 131.682 143.646 136.217C142.402 139.811 140.66 143.319 138.752 146.742C136.347 150.934 133.941 155.127 131.204 159.063"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M247.083 250.19C247.829 251.046 247.332 251.987 247.249 252.843C246.834 258.49 247.166 264.052 247.083 269.699C247 272.951 246.917 276.117 246.917 279.368C246.917 281.422 246.502 281.764 244.346 281.422"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.126 125.778C151.527 128.26 155.342 129.8 159.324 130.399C163.637 131.083 168.033 130.57 172.264 129.03C173.342 128.602 174.42 128.431 175.499 128.089C178.983 126.805 179.895 123.981 180.061 120.644C180.144 118.42 180.144 116.109 179.895 113.885"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M192.752 136.217C191.922 139.297 191.093 142.377 190.346 145.458C189.6 148.88 188.853 152.217 188.273 155.726C187.443 161.373 185.867 166.935 188.273 172.582C188.438 173.01 188.19 173.438 187.858 173.78"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.752 39.7002C140.743 40.0425 141.573 41.5826 142.568 43.2084C144.061 45.6042 144.476 48.2567 144.725 50.9948C145.222 55.6153 145.803 60.2358 145.305 64.9419"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.964 67.4224C147.13 67.1657 147.379 66.909 147.379 66.5668C147.047 59.8071 148.209 53.3042 149.868 46.8012C150.614 43.9776 151.112 40.9828 151.029 37.988C150.946 36.1056 151.029 34.1376 151.361 32.2552C151.692 30.2872 153.103 29.4315 154.264 28.4048C154.927 27.8058 156.503 29.1748 156.835 30.4583C157.167 31.9985 157.25 33.7098 156.752 35.1644C155.84 37.9025 155.176 40.8117 154.347 43.6353C153.683 46.2023 152.439 47.0579 150.034 47.1435"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.186 264.563C136.928 265.334 137.757 267.13 139.167 268.328C144.476 272.949 150.365 276.115 157.084 278.254C161.563 279.708 166.042 281.762 169.112 285.869C170.77 288.094 171.268 290.832 172.264 293.228"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.696 157.009C205.775 159.918 206.355 162.998 207.351 165.908C208.097 167.876 209.507 168.988 210.669 170.357C215.231 175.32 217.719 181.309 219.627 187.641C219.793 188.069 220.105 189.63 220.271 189.972"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.674 195.769C156.421 192.09 158.577 189.01 160.485 185.929C162.476 182.763 164.881 179.769 166.623 176.432C166.872 176.004 167.038 175.319 167.785 175.405"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.517 119.018C194.162 120.814 197.397 124.237 199.471 128.858C200.715 131.596 201.544 134.505 202.291 137.5C202.954 139.895 201.461 140.837 200.051 142.035C199.637 142.377 199.056 142.291 198.807 142.805"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M240.281 263.795C240.613 267.389 241.857 270.726 242.521 274.234C243.019 276.459 243.267 278.769 243.848 280.994C244.18 282.277 243.433 282.79 242.77 283.218C241.94 283.817 240.779 283.732 240.032 283.047C239.12 282.277 238.539 281.165 238.208 279.967C236.88 275.09 235.553 270.212 234.226 265.421"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.355 139.254C203.268 141.051 203.286 144.005 203.369 145.887C203.618 149.395 204.631 153.341 204.465 156.849"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M234.972 229.055C230.742 232.135 226.843 235.729 221.618 237.098"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.751 264.136C229.249 267.131 230.41 269.869 231.157 272.778C231.655 274.746 234.89 280.65 236.134 281.848C237.046 282.618 237.876 282.96 238.705 281.848"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M154.513 343.455C152.854 345.936 151.527 348.675 149.37 350.899C148.043 352.183 146.964 353.723 145.388 354.579"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M158.494 481.045C157.748 483.27 156.338 484.981 155.259 486.949C153.517 490.029 152.024 493.281 150.697 496.618C150.614 496.874 150.531 497.046 150.282 497.046"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.07 483.098C159.075 484.723 158.328 486.52 156.918 487.804C155.923 488.745 155.923 490.114 155.342 491.312C156.42 492.595 158.411 491.911 159.572 493.451C157.582 494.136 156.089 495.676 153.932 495.847"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.867 157.009C203.286 158.207 203.286 159.576 203.12 160.945C202.706 163.255 201.793 165.309 200.466 167.02C199.554 168.303 198.641 169.501 198.144 170.956"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.706 163.342C204.282 164.283 205.36 166.08 207.268 166.593"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M155.094 491.312C154.181 492.254 153.766 493.452 153.766 494.564C153.683 495.847 153.269 496.703 152.356 497.473"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.01 81.627C174.172 82.7393 175.582 82.996 176.992 83.5094"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.503 353.296C155.093 354.408 153.849 355.606 152.937 357.232"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M186.365 172.582C187.277 173.352 188.272 173.866 188.77 175.063"
        stroke="#2B4B8E"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* part 1 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[0])}
      >
        <g
          filter="url(#filter0_f_33395_33404)"
          onMouseEnter={() => setHover(0)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 0 ? 1 : 0}
            d="M138.009 37.4375C133.079 41.7243 126.488 39.2237 123.809 37.4375C126.167 56.5136 121.844 76.7329 118.986 82.4485C120.594 85.3064 124.398 87.2712 131.043 87.2712C137.688 87.2712 141.403 85.3064 143.368 82.4485C138.009 74.4109 137.205 46.5469 138.009 37.4375Z"
            fill="url(#paint0_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 4 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[3])}
      >
        <g
          filter="url(#filter1_f_33395_33404)"
          onMouseEnter={() => setHover(3)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 3 ? 1 : 0}
            d="M118.986 82.7165C125.631 65.7837 124.97 45.4752 123.809 37.4375C123.166 47.9401 121.397 54.4953 120.594 56.46C117.503 65.6766 111.268 71.0172 108.537 72.5354L89.2455 81.1089C93.7467 93.5406 110.948 87.3605 118.986 82.7165Z"
            fill="url(#paint1_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 3 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[2])}
      >
        <g
          filter="url(#filter2_f_33395_33404)"
          onMouseEnter={() => setHover(2)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 2 ? 1 : 0}
            d="M143.368 82.4485C137.741 74.4109 137.473 47.3506 138.009 37.4375C138.652 47.9401 141.164 54.4953 141.968 56.46C145.058 65.6766 151.294 71.0172 154.025 72.5354L173.316 81.1089C168.815 93.5406 151.406 87.0925 143.368 82.4485Z"
            fill="url(#paint2_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 7 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[6])}
      >
        <g
          filter="url(#filter3_f_33395_33404)"
          onMouseEnter={() => setHover(6)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 6 ? 1 : 0}
            d="M131.043 87.2722L131.043 194.441C90.8531 191.494 89.5135 208.105 89.7814 203.819C90.0493 199.532 89.7814 202.211 92.4607 192.566C94.6042 184.85 94.4255 178.277 94.0683 175.955C93.5326 125.584 88.0358 77.2743 89.5134 81.3779C91.925 88.0748 99.427 92.6294 118.986 82.7175C120.915 86.1469 127.828 87.1829 131.043 87.2722Z"
            fill="url(#paint3_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 6 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[5])}
      >
        <g
          filter="url(#filter4_f_33395_33404)"
          onMouseEnter={() => setHover(5)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 5 ? 1 : 0}
            d="M131.043 87.2722L131.043 194.441C171.233 191.494 172.573 208.105 172.305 203.819C172.037 199.532 172.305 202.211 169.625 192.566C167.482 184.85 167.661 178.277 168.018 175.955C168.553 125.584 174.05 77.2743 172.573 81.3779C170.161 88.0748 162.659 92.6294 143.1 82.7175C141.171 86.1469 134.258 87.1829 131.043 87.2722Z"
            fill="url(#paint4_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 9 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[8])}
      >
        <g
          filter="url(#filter5_f_33395_33404)"
          onMouseEnter={() => setHover(8)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 8 ? 1 : 0}
            d="M131.043 248.6V194.44C102.661 192.299 92.7544 199.89 89.8092 204.439C90.0234 213.217 88.1134 221.3 87.1317 224.244C80.9198 251.009 81.3304 277.684 82.3121 287.676C108.659 281.038 123.1 269.387 127.027 264.391C128.955 260.537 130.507 252.258 131.043 248.6Z"
            fill="url(#paint5_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 11 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[10])}
      >
        <g
          filter="url(#filter6_f_33395_33404)"
          onMouseEnter={() => setHover(10)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 10 ? 1 : 0}
            d="M82.2854 287.71C103.186 281.547 111.76 278.064 127.034 264.398C127.677 275.76 125.872 304.681 124.89 317.721C122.961 329.082 124.086 345.32 124.89 352.019C127.677 359.522 121.942 372.652 118.727 378.279C118.727 380.851 122.121 400.072 123.818 409.361C123.818 417.668 121.675 418.471 121.407 420.347C121.192 421.848 120.424 426.331 120.067 428.386C120.603 433.745 119.799 447.142 119.531 451.162C119.317 454.377 119.977 465.899 120.335 471.258C113.475 467.828 106.758 475.01 104.258 479.029C106.401 473.67 103.364 460.54 101.578 454.645C98.7913 448.429 94.1647 425.438 92.1997 414.72C89.1986 409.575 89.8774 402.037 90.5919 398.911C93.8074 387.978 94.7899 380.244 94.8792 377.743C94.8792 374.527 95.5937 373.545 95.951 373.455C95.7366 372.169 96.0403 368.989 96.219 367.56C97.7195 358.343 96.3083 349.608 95.4151 346.392C87.055 325.17 83.1786 298.428 82.2854 287.71Z"
            fill="url(#paint6_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 10 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[9])}
      >
        <g
          filter="url(#filter7_f_33395_33404)"
          onMouseEnter={() => setHover(9)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 9 ? 1 : 0}
            d="M179.688 287.71C158.787 281.547 150.213 278.064 134.939 264.398C134.296 275.76 136.101 304.681 137.083 317.721C139.012 329.082 137.887 345.32 137.083 352.019C134.296 359.522 140.031 372.652 143.246 378.279C143.246 380.851 139.852 400.072 138.155 409.361C138.155 417.668 140.299 418.471 140.566 420.347C140.781 421.848 141.549 426.331 141.906 428.386C141.37 433.745 142.174 447.142 142.442 451.162C142.656 454.377 141.996 465.899 141.638 471.258C148.498 467.828 155.215 475.01 157.715 479.029C155.572 473.67 158.609 460.54 160.395 454.645C163.182 448.429 167.808 425.438 169.773 414.72C172.774 409.575 172.096 402.037 171.381 398.911C168.166 387.978 167.183 380.244 167.094 377.743C167.094 374.527 166.379 373.545 166.022 373.455C166.236 372.169 165.933 368.989 165.754 367.56C164.254 358.343 165.665 349.608 166.558 346.392C174.918 325.17 178.794 298.428 179.688 287.71Z"
            fill="url(#paint7_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 13 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[12])}
      >
        <g
          filter="url(#filter8_f_33395_33404)"
          onMouseEnter={() => setHover(12)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 12 ? 1 : 0}
            d="M105.363 474.006C112.7 470.554 118.67 470.769 120.738 471.309L121.547 474.006C123.921 475.301 123.436 480.66 122.896 483.178C123.975 485.983 122.986 488.123 122.357 488.842C121.062 491 120.738 495.856 120.738 498.013C119.444 505.135 99.5184 496.845 89.7176 491.809C86.4999 489.5 88.189 487.673 90.7966 487.224C100.939 487.224 104.733 478.412 105.363 474.006Z"
            fill="url(#paint8_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 12 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[11])}
      >
        <g
          filter="url(#filter9_f_33395_33404)"
          onMouseEnter={() => setHover(11)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 11 ? 1 : 0}
            d="M156.443 474.006C149.106 470.554 143.135 470.769 141.067 471.309L140.258 474.006C137.885 475.301 138.37 480.66 138.91 483.178C137.831 485.983 138.82 488.123 139.449 488.842C140.744 491 141.067 495.856 141.067 498.013C142.362 505.135 162.287 496.845 172.088 491.809C177.483 489.22 173.617 487.673 171.009 487.224C160.867 487.224 157.072 478.412 156.443 474.006Z"
            fill="url(#paint9_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 8 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[7])}
      >
        <g
          filter="url(#filter10_f_33395_33404)"
          onMouseEnter={() => setHover(7)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 7 ? 1 : 0}
            d="M131.043 248.6V194.44C159.425 192.299 169.331 199.89 172.277 204.439C172.063 213.218 173.973 221.301 174.954 224.245C181.166 251.009 180.756 277.684 179.774 287.676C153.427 281.038 138.986 269.387 135.059 264.391C133.131 260.537 131.578 252.258 131.043 248.6Z"
            fill="url(#paint10_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 5 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[4])}
      >
        <g
          filter="url(#filter11_f_33395_33404)"
          onMouseEnter={() => setHover(4)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 4 ? 1 : 0}
            d="M71.0262 88.0756C76.3848 82.5028 85.4052 81.2882 89.2456 81.3775C92.7287 132.283 94.0683 153.717 94.0683 175.954C91.8356 171.132 87.37 159.289 87.37 150.502C87.37 141.714 85.2266 136.659 84.1548 135.23C84.1548 140.588 80.2252 157.468 78.2603 165.237C76.3312 170.381 76.2062 174.525 76.3848 175.954C76.5992 176.597 76.6527 177.294 76.6527 177.562C75.581 178.419 74.2413 181.313 73.7055 182.652C68.0789 202.211 57.6295 217.214 50.6633 224.448C45.0903 230.235 42.4467 234.54 41.8215 235.969C41.0177 236.773 39.5709 239.184 40.2139 242.399C40.857 245.614 40.4819 250.883 40.2139 253.116L35.1232 271.067C34.0515 275.139 31.6401 274.907 30.5684 274.282L27.0853 280.444C25.1561 283.445 23.6021 282.588 23.0663 281.784C19.8511 284.999 18.3328 282.588 17.9755 280.98C16.0464 282.266 15.2069 280.444 15.0283 279.372L14.4924 251.776L10.7414 256.331C5.16839 259.975 4.31101 256.956 4.57894 254.992C5.86502 253.92 7.61551 251.866 8.33 250.973C13.0456 240.47 19.2259 236.058 21.7266 235.165C24.9418 233.665 28.6035 225.967 30.0325 222.305C31.1042 218.232 34.2301 212.928 35.6591 210.784C40.8034 183.778 50.3061 169.881 54.4144 166.309L57.6296 156.664C57.6296 140.588 62.2737 128.353 64.5958 124.245C58.9692 114.868 64.3279 95.0416 71.0262 88.0756Z"
            fill="url(#paint11_radial_33395_33404)"
          />
        </g>
      </Popover>

      {/* part 2 */}
      <Popover
        overlayStyle={overlayStyle}
        placement="topLeft"
        overlayClassName="popover-no-arrow"
        content={getContent(quality[1])}
      >
        <g
          filter="url(#filter12_f_33395_33404)"
          onMouseEnter={() => setHover(1)}
          onMouseLeave={() => setHover(null)}
        >
          <path
            stroke="blue"
            strokeOpacity={hoverIndex === 1 ? 1 : 0}
            d="M191.06 88.0756C185.701 82.5028 176.681 81.2882 172.84 81.3775C169.357 132.283 168.018 153.717 168.018 175.954C170.25 171.132 174.716 159.289 174.716 150.502C174.716 141.714 176.859 136.659 177.931 135.23C177.931 140.588 181.861 157.468 183.826 165.237C185.755 170.381 185.88 174.525 185.701 175.954C185.487 176.597 185.433 177.294 185.433 177.562C186.505 178.419 187.845 181.313 188.38 182.652C194.007 202.211 204.456 217.214 211.423 224.448C216.996 230.235 219.639 234.54 220.264 235.969C221.068 236.773 222.515 239.184 221.872 242.399C221.229 245.614 221.604 250.883 221.872 253.116L226.963 271.067C228.034 275.139 230.446 274.907 231.518 274.282L235.001 280.444C236.93 283.445 238.484 282.588 239.02 281.784C242.235 284.999 243.753 282.588 244.11 280.98C246.04 282.266 246.879 280.444 247.058 279.372L247.594 251.776L251.345 256.331C256.918 259.975 257.775 256.956 257.507 254.992C256.221 253.92 254.47 251.866 253.756 250.973C249.04 240.47 242.86 236.058 240.359 235.165C237.144 233.665 233.482 225.967 232.053 222.305C230.982 218.232 227.856 212.928 226.427 210.784C221.283 183.778 211.78 169.881 207.672 166.309L204.456 156.664C204.456 140.588 199.812 128.353 197.49 124.245C203.117 114.868 197.758 95.0416 191.06 88.0756Z"
            fill="url(#paint12_radial_33395_33404)"
          />
        </g>
      </Popover>

      <defs>
        <filter
          id="filter0_f_33395_33404"
          x="114.986"
          y="33.4375"
          width="32.3818"
          height="57.8335"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter1_f_33395_33404"
          x="85.2455"
          y="33.4375"
          width="43.3069"
          height="58.9878"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter2_f_33395_33404"
          x="133.82"
          y="33.4375"
          width="43.496"
          height="58.8804"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter3_f_33395_33404"
          x="85.2621"
          y="77.1338"
          width="49.7809"
          height="131.396"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter4_f_33395_33404"
          x="127.043"
          y="77.1338"
          width="49.7809"
          height="131.396"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter5_f_33395_33404"
          x="77.7434"
          y="190.067"
          width="57.2996"
          height="101.608"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter6_f_33395_33404"
          x="78.2854"
          y="260.398"
          width="52.8835"
          height="222.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter7_f_33395_33404"
          x="130.804"
          y="260.398"
          width="52.8835"
          height="222.63"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter8_f_33395_33404"
          x="83.9553"
          y="467"
          width="43.4208"
          height="37.7803"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter9_f_33395_33404"
          x="134.429"
          y="467"
          width="44.3762"
          height="37.7803"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter10_f_33395_33404"
          x="127.043"
          y="190.067"
          width="57.2996"
          height="101.608"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter11_f_33395_33404"
          x="0.535889"
          y="77.373"
          width="97.5325"
          height="209.996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>
        <filter
          id="filter12_f_33395_33404"
          x="164.018"
          y="77.373"
          width="97.5325"
          height="209.996"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="2"
            result="effect1_foregroundBlur_33395_33404"
          />
        </filter>

        {/* part 1 */}
        <radialGradient
          id="paint0_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(131.461 60.5568) rotate(90) scale(31.5062 19.7646)"
        >
          <stop stopColor={colorMaps[opacities[0]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[0]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[0] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[0] / 100)} 
          />*/}
        </radialGradient>

        {/* part 4 */}
        <radialGradient
          id="paint1_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.4 61.2202) rotate(90) scale(32.4102 25.5342)"
        >
          <stop stopColor={colorMaps[opacities[3]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[3]]}
            stopOpacity={0.2}
          />

          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[3] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[3] / 100)}
          /> */}
        </radialGradient>

        {/* part 3 */}
        <radialGradient
          id="paint2_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(156.161 61.2202) rotate(90) scale(32.4102 25.5342)"
        >
          <stop stopColor={colorMaps[opacities[2]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[2]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[2] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[2] / 100)}
          /> */}
        </radialGradient>

        {/* part 7 */}
        <radialGradient
          id="paint3_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(110.317 137.382) rotate(90) scale(79.1917 34.4027)"
        >
          <stop stopColor={colorMaps[opacities[6]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[6]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[6] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[6] / 100)}
          /> */}
        </radialGradient>

        {/* part 6 */}
        <radialGradient
          id="paint4_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(151.769 137.382) rotate(90) scale(79.1917 34.4027)"
        >
          <stop stopColor={colorMaps[opacities[5]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[5]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[5] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[5] / 100)}
          /> */}
        </radialGradient>

        {/* part 9 */}
        <radialGradient
          id="paint5_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(106.967 237.38) rotate(90) scale(59.3195 39.9636)"
        >
          <stop stopColor={colorMaps[opacities[8]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[8]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[8] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[8] / 100)}
          /> */}
        </radialGradient>

        {/* part 11 */}
        <radialGradient
          id="paint6_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(105.25 363.709) rotate(90) scale(136.011 36.3838)"
        >
          <stop stopColor={colorMaps[opacities[10]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[10]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[10] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[10] / 100)}
          /> */}
        </radialGradient>

        {/* part 10 */}
        <radialGradient
          id="paint7_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(156.723 363.709) rotate(90) scale(136.011 36.3838)"
        >
          <stop stopColor={colorMaps[opacities[9]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[9]]}
            stopOpacity={0.2}
          />

          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[9] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[9] / 100)}
          /> */}
        </radialGradient>

        {/* part 13 */}
        <radialGradient
          id="paint8_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(111.421 484.031) rotate(144.983) scale(34.7422 20.2404)"
        >
          <stop stopColor={colorMaps[opacities[12]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[12]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[12] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[12] / 100)}
          /> */}
        </radialGradient>

        {/* part 12 */}
        <radialGradient
          id="paint9_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(150.385 484.031) rotate(35.0173) scale(34.7422 20.2404)"
        >
          <stop stopColor={colorMaps[opacities[11]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[11]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[11] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[11] / 100)}
          /> */}
        </radialGradient>

        {/* part 8 */}
        <radialGradient
          id="paint10_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(155.119 237.38) rotate(90) scale(59.3195 39.9636)"
        >
          <stop stopColor={colorMaps[opacities[7]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[7]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[7] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[7] / 100)}
          /> */}
        </radialGradient>

        {/* part 5 */}
        <radialGradient
          id="paint11_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(49.6552 173.449) rotate(90) scale(129.634 73.7216)"
        >
          <stop stopColor={colorMaps[opacities[4]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[4]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[4] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[4] / 100)}
          /> */}
        </radialGradient>

        {/* part 2 */}
        <radialGradient
          id="paint12_radial_33395_33404"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(212.431 173.449) rotate(90) scale(129.634 73.7216)"
        >
          <stop stopColor={colorMaps[opacities[1]]} stopOpacity={0.9} />
          <stop
            offset="1"
            stopColor={colorMaps[opacities[1]]}
            stopOpacity={0.2}
          />
          {/* <stop stopColor="#D5001C" stopOpacity={0.9 * (opacities[1] / 100)} />
          <stop
            offset="1"
            stopColor="#D5001C"
            stopOpacity={0.2* (opacities[1] / 100)}
          /> */}
        </radialGradient>
      </defs>
    </svg>
  )
}
