import React, { useEffect, useState } from 'react'
import NutritionScoreExpertView from '../../../components/nutritionScore/expertView'

import {
  getClientList,
  getClientDetail,
  getNutritionTrends
} from '../../../models/breadcrumb.model'
import PageLoading from '../../../components/UI/status/loading'

function NutritionScore(props) {
  const { getPerson, setClientPageTitle, setClientBreadcrumb } = props
  const [person, setPerson] = useState(null)
  useEffect(() => {
    initial()
  }, [])

  return person ? <NutritionScoreExpertView person={person} /> : <PageLoading />

  async function initial() {
    const person = await getPerson()
    setPerson(person)
    setClientPageTitle && setClientPageTitle('Nutrition Signals Trends')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getNutritionTrends(person, true)
      ])
  }
}

export default NutritionScore
