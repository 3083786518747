import React from 'react'
// import { Modal } from 'antd-mobile'
// import 'antd-mobile/lib/modal/style/css'
import { detailStyle } from '../../styles/detailPopup.sass'

export default function MobilePopUpModal(props) {
  return (
    <div className={`${detailStyle} mobile mobile-container`}>
      {props.children}
    </div>
  )
  // return (
  //   <Modal
  //     popup
  //     visible={true}
  //     onClose={props.onCancel}
  //     animationType="slide-up"
  //     className={`${detailStyle} mobile`}
  //   >
  //     {props.children}
  //   </Modal>
  // )
}
