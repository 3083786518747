import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import moment from 'moment'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}
const blue = '#264382'
const green = '#7BAD2D'
const gray = '#BAC3CE'

class SleepBars extends Component {
  static propTypes = {
    goal: PropTypes.object,
    list: PropTypes.array,
    period: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    parentEntries: PropTypes.array
  }
  state = {
    dataArr: [],
    dataY: []
  }

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'week') {
      return moment(x).format('ddd')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).date() % 5 === 0) {
        result = moment(x).format('M/D')
      }

      return result
    }
  }

  initialSleepTime = () => {
    const { list, period, startDate, endDate, parentEntries } = this.props
    const children = list.map((item) => item.goal).filter((item) => item)
    let inbedData = {},
      asleepData = {}
    const dataArr = []
    for (const child of children) {
      const {
        micro_goal: { micro_goal },
        achieving
      } = child
      let entries = []
      for (const achiev of achieving) {
        entries = entries.concat(achiev.entries)
      }
      if (period === 'month') {
        entries = entries.filter(
          (entry) =>
            moment(startDate).isSameOrBefore(entry.created_at, 'month') &&
            moment(endDate).isSameOrAfter(entry.created_at, 'month')
        )
      }
      entries = entries.map((item) => ({
        x: moment(item.created_at),
        y: Number(item.input_auto) || null
      }))
      const checkEntries = (dataItem, goalName) => {
        for (let sleepEntries of parentEntries) {
          const target = dataItem.entries.find(
            (enrty) => enrty.x.format('YYYY-MM-DD') === sleepEntries.created_at
          )

          if (
            !target &&
            sleepEntries.activity_details &&
            sleepEntries.activity_details[0] &&
            sleepEntries.activity_details[0].sleep
          ) {
            dataItem.entries.push({
              x: moment(sleepEntries.created_at),
              y:
                Number(sleepEntries.activity_details[0].sleep[goalName]) || null
            })
          }
        }

        return dataItem
      }
      let dataItem = {}
      dataItem.entries = entries
      if (micro_goal.includes('Asleep')) {
        dataItem.color = green
        dataItem = checkEntries(dataItem, 'Sleep Time - Asleep')
        asleepData = dataItem
      } else if (micro_goal.includes('In Bed')) {
        dataItem.color = gray
        dataItem = checkEntries(dataItem, 'Sleep Time - In Bed')
        inbedData = dataItem
      }
      dataArr.push(dataItem)
    }
    let entries = [].concat(...dataArr.map((item) => item.entries))
    const maxValue = Math.max(...entries.map((item) => item.y), 10)
    let dataY = []
    if (maxValue) {
      dataY = [0, 5, 10]
    }
    if (maxValue > 10) {
      dataY.push(parseInt(maxValue))
    }
    this.setState({
      asleepData,
      inbedData,
      dataY,
      type: 'sleepTime'
    })
  }

  initialBadTime = () => {
    const { list, period, startDate, endDate } = this.props
    const children = list.map((item) => item.goal).filter((item) => item)
    const dataArr = []

    for (const child of children) {
      const { achieving } = child || {}
      let entries = []
      for (const achiev of achieving) {
        entries = entries.concat(achiev.entries)
      }
      if (period === 'month') {
        entries = entries.filter(
          (entry) =>
            moment(startDate).isSameOrBefore(entry.created_at, 'month') &&
            moment(endDate).isSameOrAfter(entry.created_at, 'month')
        )
      }

      entries = [].concat(
        ...entries.map((item) => {
          let inputs = []
          try {
            inputs = JSON.parse(item.input_auto)
          } catch (err) {
            console.error(err)
            console.info(`Some data error happend, the micro goal is: ${
              child.micro_goal.micro_goal
            } - id ${child.micro_goal.id}
              the entry is:
            `)
          }
          return inputs.map((input) => {
            const startTime = moment(input.bedtime_start),
              endTime = moment(input.bedtime_end),
              y0 = startTime.hour() * 60 + startTime.minute()
            let y = endTime.hour() * 60 + endTime.minute()
            if (endTime.date() > startTime.date()) {
              y = y + 24 * 60
            }
            return {
              x: moment(item.created_at),
              y: y || null,
              y0: y0 || null
            }
          })
        })
      )
      dataArr.push({
        color: blue,
        entries
      })
    }

    const allEntries = [].concat(...dataArr.map((data) => data.entries))
    let min = Math.min(...allEntries.map((entry) => entry.y0))
    let max = Math.max(...allEntries.map((entry) => entry.y))
    min = parseInt(min / 60) || 0
    max = max > 0 ? Math.ceil(max / 60) : 9

    const dataY = [min * 60, parseInt((min + max) / 2) * 60, max * 60]
    this.setState({
      dataY,
      dataArr
    })
  }

  initialMetaData = () => {
    const { startDate, endDate } = this.props
    const dataX = []
    let count = 1
    dataX.push(moment(startDate))
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      dataX.push(moment(startDate).add(count, 'days'))
      count++
    }
    dataX.push(moment(endDate))
    this.setState({ dataX })
  }

  timeValueHandle = (v) => {
    if (v > 24 * 60) {
      v = v - 24 * 60
    }
    const hour = parseInt(v / 60),
      minute = v % 60
    return moment(`${hour}:${minute}`, 'HH:mm').format('hhA')
  }

  componentDidMount() {
    const { type } = this.props

    if (type === 'Total Sleep Time') {
      this.initialSleepTime()
    } else {
      this.initialBadTime()
    }

    this.setState({
      type
    })
    this.initialMetaData()
  }

  render() {
    const { period } = this.props
    const { asleepData, inbedData, type, dataX, dataY } = this.state
    const dataArr =
      type === 'Total Sleep Time'
        ? [inbedData, asleepData].filter((item) => item.entries)
        : this.state.dataArr
    const gridStroken = (t) => {
      if (period === 'monthly' && moment(t).date() % 5 === 0) {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={width / 3}
            padding={{ top: 20, left: 50, bottom: 50, right: 50 }}
            domainPadding={20}
          >
            <VictoryAxis
              style={{
                axis,
                grid: {
                  strokeDasharray,
                  stroke: gridStroken
                },
                tickLabels: {
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fill: (x) => {
                    if (
                      moment(x).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD')
                    ) {
                      return '#3561C0'
                    }
                    return '#B0BAC9'
                  },
                  padding: (x) => {
                    if (
                      period === 'monthly' &&
                      moment(x).format('ddd') !== 'Sun' &&
                      moment(x).format('YYYY-MM-DD') ===
                        moment().format('YYYY-MM-DD') &&
                      moment(x).date() % 5 !== 0
                    ) {
                      return 0
                    } else {
                      return 10
                    }
                  }
                }
              }}
              tickValues={dataX}
              tickLabelComponent={
                <VictoryLabel
                  text={(datum) => {
                    let result = this.xAxisFormat(datum)
                    return result
                  }}
                />
              }
            />
            {type === 'Total Sleep Time' ? (
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {
                    strokeDasharray,
                    stroke: 'rgba(0,0,0,.5)'
                  },
                  grid: {
                    strokeDasharray,
                    stroke: 'rgba(0,0,0,.1)'
                  },
                  tickLabels: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    fill: (v) => {
                      if (v < 2e-9) {
                        return 'transparent'
                      }
                      return '#B0BAC9'
                    }
                  }
                }}
                tickValues={dataY}
              />
            ) : (
              <VictoryAxis
                dependentAxis
                style={{
                  axis: {
                    strokeDasharray,
                    stroke: 'rgba(0,0,0,.5)'
                  },
                  grid: {
                    strokeDasharray,
                    stroke: 'rgba(0,0,0,.1)'
                  },
                  tickLabels: {
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    fill: (v) => {
                      if (v < 2e-9) {
                        return 'transparent'
                      }
                      return '#B0BAC9'
                    }
                  }
                }}
                tickValues={dataY}
                tickFormat={this.timeValueHandle}
              />
            )}

            {dataArr.map((data, index) => (
              <VictoryBar
                key={index}
                offsetX={10}
                data={data.entries}
                // alignment="center"
                // barRatio={4}
                // cornerRadius={{ top: 4, bottom: 4 }}
                cornerRadius={{ top: 2, bottom: 2 }}
                barWidth={period === 'month' ? 14 : 40}
                style={{
                  data: {
                    fill: data.color
                  },
                  axis
                }}
              />
            ))}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default SleepBars
