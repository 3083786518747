import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import DatasetDetailWrapper from '../../components/business/dataset/detail/wrapper'
import DatasetDetailContainer from '../../components/business/dataset/detail'
import MobileHeader from '../../components/business/shareLink/mobileHeader'

const DetailView = DatasetDetailWrapper(DatasetDetailContainer)

class ShareLinkDetail extends Component {
  static propTypes = {}

  state = {}

  back = () => {
    const { history, hideHeader, hash, type } = this.props
    let targetPatch = `/share-link/${type}/${hash}`
    if (hideHeader) {
      targetPatch = '/ios' + targetPatch
    }
    history.push(targetPatch)
  }

  render() {
    const {
      hash,
      history,
      type,
      person,
      hideHeader,
      match: { params },
      slideMenu: { groups }
    } = this.props
    const { title } = this.state
    return (
      (person && groups && (
        <>
          <div className="desktop-show">
            <DetailView
              {...{ hash, history, type, person, groups }}
              {...params}
            />
          </div>
          <div className="mobile-show">
            <MobileHeader
              person={person}
              title={title}
              back={this.back}
              showDetail={true}
              hideHeader={hideHeader}
              groups={groups}
            >
              <DetailView
                {...{ hash, history, type, person, groups }}
                {...params}
                setMobileHeaderState={(state) => this.setState({ ...state })}
              />
            </MobileHeader>
          </div>
        </>
      )) || <div />
    )
  }
}

export default ShareLinkDetail
