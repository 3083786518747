import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Biomarker from '../biomarker'

class Category extends Component {
  static propTypes = {
    category: PropTypes.object,
    biomarkerType: PropTypes.string
  }

  renderSubCategory() {
    const { category } = this.props
    return (
      <div>
        <div className="root-category">
          {category && (
            <>
              <h1>{category.name}</h1>
              <div className="description">
                <p>{category.description}</p>
              </div>
            </>
          )}
        </div>
        {category &&
          category.child_categories.map((item, index) =>
            this.renderWithoutSubCategory(item, index)
          )}
      </div>
    )
  }

  renderWithoutSubCategory(category, index) {
    const { biomarkerType, datasetId } = this.props
    return (
      <div className="sub-category-container" key={index}>
        <div className="top">
          <div className="name">
            <div>{category && category.name}</div>
          </div>
          <div className="description">{category && category.description}</div>
        </div>
        <div className="bottom">
          {category && (
            <>
              {/* {category[biomarkerType].child_categories &&
                category[biomarkerType].child_categories.length > 0 &&
                category[biomarkerType].child_categories.map((item, index) =>
                  this.renderSubCategory(item, index)
                )} */}
              {category[biomarkerType] &&
                category[biomarkerType].map((item, index) => (
                  <Biomarker
                    key={index + '-' + datasetId}
                    biomarker={item}
                    {...this.props}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    )
  }

  render() {
    const { category } = this.props
    if (category.child_categories && category.child_categories.length > 0) {
      return this.renderSubCategory()
    } else {
      return this.renderWithoutSubCategory(category, 0)
    }
  }
}

export default Category
