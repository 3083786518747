import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Empty } from 'antd'
import { setCurrentCategory } from '../../../redux/slideMenu/action'
import FileManager from '../files/management'
import ClientBloodTestCategories from './categories/clientBloodTest'
import PageLoading from '../../UI/status/loading'

import { clientContainerStyle } from './container.sass'
import { getTitleByType } from './common'

class BloosTestContainer extends Component {
  static propTypes = {}

  componentDidMount() {
    const { categories, setCurrentCategory } = this.props
    window.addEventListener('scroll', this.orderScroll)
    if (categories && categories[0]) {
      setCurrentCategory(categories[0].name)
    }
  }
  componentDidUpdate() {
    const {
      categories,
      setCurrentCategory,
      slideMenu: { currentCategory }
    } = this.props
    if (categories && categories[0] && !currentCategory) {
      setCurrentCategory(categories[0].name)
    }
  }

  orderScroll = (e) => {
    const {
      slideMenu: { currentCategory },
      setCurrentCategory
    } = this.props
    const elementList = e.target.querySelectorAll('div[name]')
    const viewHeight = window.innerHeight
    const scrollTop = document.scrollingElement.scrollTop
    let _currentCategory = ''
    for (let element of elementList) {
      if (viewHeight - element.offsetTop + scrollTop > 0) {
        const name = element.getAttribute('name')
        if (name.includes('category_')) {
          _currentCategory = name.replace('category_', '')
        }
      }
    }
    if (_currentCategory !== currentCategory) {
      setCurrentCategory(_currentCategory)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.orderScroll)
  }

  render() {
    const { dataset, dataLoading, person, hash } = this.props
    const title = getTitleByType('blood-test')
    return (
      <div className={clientContainerStyle}>
        <h1 className="page-title">{title}</h1>
        {person && (
          <FileManager
            title="Assessment files"
            type="bio_marker"
            person={person}
            editable={false}
            hash={hash}
          />
        )}
        {dataLoading ? (
          <PageLoading />
        ) : (
          <>
            {dataset && dataset.snapshot.length > 0 ? (
              <ClientBloodTestCategories {...this.props} />
            ) : (
              <Empty style={{ marginTop: 50 }} />
            )}
          </>
        )}
      </div>
    )
  }
}

export default connect(
  ({ slideMenu, client }) => ({ slideMenu, client }),
  (dispatch) => ({
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category))
  })
)(BloosTestContainer)
