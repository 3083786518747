import { combineReducers } from 'redux'
import sendBird from './sendBird/reducer'
import client from './client/reducer'
import account from './account/reducer'
import sharelink from './shareLink/reducer'
import slideMenu from './slideMenu/reducer'
import summaryEmail from './summaryEmail/reducer'
import initialState from './initial-state'
// const USER_LOGOUT = 'USER_LOGOUT'

const appReducer = combineReducers({
  sendBird,
  client,
  account,
  sharelink,
  slideMenu,
  summaryEmail
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = initialState
  }

  return appReducer(state, action)
}

export default rootReducer
