import React, { useState, useEffect } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Collapse, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import MedicalEditor from './medicalEditor'
import { categories } from '../../models/medical.form'
import { getMedicalInformation } from '../../api/medical'
import { EditCircleIcon } from '../icons/weeklySummary'
const { Panel } = Collapse

const Container = styled.div`
  height: 100vh;
  position: sticky;
  top: 0;
  overflow: auto;
  .title {
    margin-top: 40px;
    font-size: 36px;
    color: #323f4a;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
  }
  .ant-collapse-content-box {
    max-height: 350px;
    overflow: auto;
  }
  .ant-collapse-item:last-child .ant-collapse-content-box {
    max-height: 100%;
  }
  .ant-collapse-borderless {
    background: transparent;
  }

  .no-data {
    border-top: 1px solid #e8ecf0;
    padding-top: 10px;
    margin-bottom: 8px;
    color: #8895a2;
    font-size: 14px;
    text-align: center;
  }
`

const ListContainer = styled.ul`
  padding: 0 8px;
  color: #60656d;
  font-size: 14px;
  list-style: none;
  li {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    &.wrap {
      flex-wrap: wrap;
    }
    &:not(&:first-child) {
      border-top: 1px solid #e8ecf0;
    }
    &:last-child {
      padding-bottom: 0;
      .flex {
        border-bottom: none;
      }
    }
    &.only-end {
      background: #f5f5f6;
      &:last-child {
        padding-bottom: 16px;
        border-radius: 0 0 8px 8px;
      }
      &:hover .edit {
        background: #f5f5f6;
      }
    }
    &:not(.only-end) + .only-end {
      border-radius: 8px 8px 0 0;
      border-top: none !important;
      &:last-child {
        border-radius: 8px;
      }
    }
    .type {
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      color: #323f4a;
      padding-bottom: 16px;
      text-transform: capitalize;
      & + div {
        width: 100%;
        max-width: 100%;
        border-bottom: none;
      }
    }
    .name {
      max-width: calc(100% - 150px);
      &.large-width {
        max-width: calc(100% - 80px);
      }
      .pre {
        white-space: pre-wrap;
      }
    }
    &.full-width .name {
      max-width: 100%;
      width: 100%;
    }
    .text-right {
      text-align: right;
      max-width: 150px;
      position: relative;
      min-width: 50px;
      min-height: 45px;
      .edit {
        display: none;
        position: absolute;
        background: #ffffff;
        right: 0;
        top: 0;
        width: 100%;
        font-size: 32px;
        .anticon {
          cursor: pointer;
        }
      }
      .baseline {
        display: inline-block;
        color: #264382;
        font-size: 12px;
        border-radius: 4px;
        background: #d4d9e6;
        padding: 2px 8px;
      }
      .color {
        padding: 4px 8px;
        border-radius: 2px;
        font-size: 12px;
        text-transform: capitalize;
        &.red {
          background: rgba(233, 0, 0, 0.1);
          color: #e90000;
        }
        &.yellow {
          background: rgba(244, 187, 64, 0.1);
          color: #f4bb40;
        }
      }
    }
    &:hover .text-right .edit {
      display: block;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      // border-bottom: 1px solid #e8ecf0;
      width: 100%;
      // max-width: calc(100% - 42px);
      align-items: center;
      .text-right {
        // align-self: end;
        height: 100%;
        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
          white-space: nowrap;
        }
      }
    }

    &.has-prefix {
      display: flex;
      align-items: center;
      border-bottom: none !important;
      padding-bottom: 0;
      .prefix {
        padding-right: 10px;
        span {
          display: block;
          width: 32px;
          height: 32px;
          background: rgba(217, 217, 217, 0.5);
          color: #60656d;
          text-align: center;
          border-radius: 100%;
          line-height: 32px;
          font-size: 10px;
          text-transform: uppercase;
        }
        &.acute {
          span {
            background: rgba(253, 239, 232, 0.5);
            border: 2px solid #f3a172;
            color: #ed742e;
            line-height: 30px;
          }
        }
        &.chronic {
          span {
            background: rgba(254, 246, 231, 0.5);
            border: 2px solid #f9dd9f;
            color: #f4bb40;
            line-height: 30px;
          }
        }
        &.history {
          span {
            background: rgba(242, 242, 242, 0.5);
            border: 2px solid #cccccc;
            color: #aeaeae;
            line-height: 30px;
          }
        }
        &.resolved {
          span {
            background: rgba(232, 241, 218, 0.5);
            border: 2px solid #b5cf86;
            color: #86ac44;
            line-height: 30px;
          }
        }
      }
      & > div {
        padding-bottom: 12px;
      }
      &:last-child {
        & > div {
          padding-bottom: 0;
        }
      }
    }
  }
  .light {
    font-size: 12px;
    color: #60656d;
  }
  .lighter {
    color: #8895a2;
    font-size: 12px;
    svg {
      margin-right: 5px;
      vertical-align: middle;
    }
  }
  .lightest {
    color: #bec7d9;
  }
`

const panelStyle = {
  marginBottom: 12,
  border: '1px solid #DEE2EC',
  background: '#ffffff',
  borderRadius: 12
}

export default function MedicalInfo(props) {
  const { person, refreshNotes } = props
  const [addItemType, setAddItemType] = useState(null)
  const [editTarget, setEditTarget] = useState(null)
  const [medications, setMedications] = useState({})

  useEffect(() => {
    fetchData()
  }, [])

  const renderActiveOrInactive = (list, type) => {
    return (
      <ListContainer>
        {list.map((item) => (
          <li key={item.id} className="has-prefix">
            <div className={`prefix ${item[`${type}_type`]}`}>
              <span>{item[`${type}_type`].slice(0, 1)}</span>
            </div>
            <div className="flex">
              <div className="name large-width">
                <span className="pre">{item.name}</span>

                <br />
                <span className="light pre">{showDate(item)}</span>
              </div>
              <div className="text-right">
                {item.color && (
                  <span className={`color ${item.color}`}>
                    {item.color} flag
                  </span>
                )}
                {(item.source === 'manual' ||
                  item.source === 'questionnaire') && (
                  <div className="edit">
                    <EditCircleIcon
                      onClick={() => editItem(item.category, item)}
                    />
                  </div>
                )}
              </div>
            </div>
          </li>
        ))}
      </ListContainer>
    )
  }

  return (
    <Container>
      <h1 className="title">Medical Information</h1>
      <Collapse
        bordered={false}
        defaultActiveKey={['1', '2', '3', '4', '5', '6']}
      >
        <Panel
          header={
            <PanelHeader
              title="Medications"
              count={
                medications[categories.medication]
                  ? medications[categories.medication].length
                  : null
              }
              type={categories.medication}
              addItem={addItem}
            />
          }
          key="1"
          style={panelStyle}
        >
          {medications[categories.medication] &&
          medications[categories.medication].length ? (
            <ListContainer>
              {medications[categories.medication].map((item) => (
                <li key={item.id} className={item.outdated ? 'only-end' : ''}>
                  <div className="flex">
                    <div className="name">
                      <span className="pre">{item.name}</span>
                      <br />
                      <span className="light pre">
                        {item.dose} {item.unit.replace('other:', '')}{' '}
                        {(item.dose || item.unit) && item.frequency && ' - '}{' '}
                        {item.frequency && item.frequency.replace('other:', '')}
                        {item.duration && ` - ${item.duration}`}
                      </span>
                      <br />
                      <span className="lighter">
                        {item.prescribing_by && (
                          <>
                            <DoctorAvatar /> {item.prescribing_by}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="text-right">
                      {item.baseline && (
                        <span className="baseline">Baseline</span>
                      )}
                      <br />
                      <span
                        className={` bottom ${
                          item.outdated ? 'lightest' : 'light'
                        }`}
                      >
                        {showLogicalDate(item)}
                      </span>
                      {(item.source === 'manual' ||
                        item.source === 'questionnaire') && (
                        <div className="edit">
                          <EditCircleIcon
                            onClick={() => editItem(item.category, item)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ListContainer>
          ) : (
            <p className="no-data">No medications recorded</p>
          )}
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Supplements"
              count={
                medications[categories.supplement]
                  ? medications[categories.supplement].length
                  : null
              }
              type={categories.supplement}
              addItem={addItem}
            />
          }
          key="2"
          style={panelStyle}
        >
          {medications[categories.supplement] &&
          medications[categories.supplement].length ? (
            <ListContainer>
              {medications[categories.supplement].map((item) => (
                <li key={item.id} className={item.outdated ? 'only-end' : ''}>
                  <div className="flex">
                    <div className="name">
                      <span className="pre">
                        {item.name}
                        {item.brand && ` - ${item.brand}`}
                      </span>
                      <br />
                      <span className="light">
                        {item.dose} {item.unit.replace('other:', '')}
                        {(item.dose || item.unit) &&
                          item.frequency &&
                          ' - '}{' '}
                        {item.frequency && item.frequency.replace('other:', '')}
                      </span>
                      <br />
                      <span className="lighter">
                        {item.recommended_by && (
                          <>
                            <DoctorAvatar /> {item.recommended_by}
                          </>
                        )}
                      </span>
                    </div>
                    <div className="text-right">
                      {item.baseline && (
                        <span className="baseline">Baseline</span>
                      )}
                      <br />
                      <span
                        className={` bottom ${
                          item.outdated ? 'lightest' : 'light'
                        }`}
                      >
                        {showLogicalDate(item)}
                      </span>
                      {(item.source === 'manual' ||
                        item.source === 'questionnaire') && (
                        <div className="edit">
                          <EditCircleIcon
                            onClick={() => editItem(item.category, item)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ListContainer>
          ) : (
            <p className="no-data">No supplements recorded</p>
          )}
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Allergies"
              count={
                medications[categories.allergy]
                  ? medications[categories.allergy].length
                  : null
              }
              type={categories.allergy}
              addItem={addItem}
            />
          }
          key="3"
          style={panelStyle}
        >
          {medications[categories.allergy] &&
          medications[categories.allergy].length ? (
            <ListContainer>
              {medications[categories.allergy].map((item) => (
                <li key={item.id} className="wrap">
                  <div className="type">{item.allergy_type}</div>
                  <div className="flex">
                    <div className="name">
                      <span className="pre">{item.name}</span>
                      <br />
                      <span className="light">{showDate(item)}</span>
                    </div>
                    <div className="text-right">
                      {(item.source === 'manual' ||
                        item.source === 'questionnaire') && (
                        <div className="edit">
                          <EditCircleIcon
                            onClick={() => editItem(item.category, item)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ListContainer>
          ) : (
            <p className="no-data">No allergies recorded</p>
          )}
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Active"
              count={
                medications[categories.active]
                  ? medications[categories.active].length
                  : null
              }
              type={categories.active}
              addItem={addItem}
            />
          }
          key="5"
          style={panelStyle}
        >
          {medications[categories.active] &&
          medications[categories.active].length ? (
            renderActiveOrInactive(
              medications[categories.active],
              categories.active
            )
          ) : (
            <p className="no-data">No active recorded</p>
          )}
        </Panel>
        <Panel
          header={
            <PanelHeader
              title="Inactive"
              count={
                medications[categories.inactive]
                  ? medications[categories.inactive].length
                  : null
              }
              type={categories.inactive}
              addItem={addItem}
            />
          }
          key="6"
          style={panelStyle}
        >
          {medications[categories.inactive] &&
          medications[categories.inactive].length ? (
            renderActiveOrInactive(
              medications[categories.inactive],
              categories.inactive
            )
          ) : (
            <p className="no-data">No inactive recorded</p>
          )}
        </Panel>
      </Collapse>
      {addItemType && (
        <MedicalEditor
          person={person}
          type={addItemType}
          target={editTarget}
          closeModal={() => {
            setAddItemType(null)
            setEditTarget(null)
          }}
          submitSync={() => {
            fetchData()
            refreshNotes()
          }}
        />
      )}
    </Container>
  )

  function showLogicalDate(item) {
    const { shown_date, start, end } = item
    const format = 'M/D/YYYY'
    if (start || end) {
      return (
        <span>
          {start && moment(start).format(format)}
          <svg
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ margin: '0 8px' }}
          >
            <path
              d="M3.89216 0.0881946C3.8445 0.0444682 3.78514 0.0155537 3.72133 0.00497599C3.65752 -0.00560175 3.59201 0.00261422 3.53278 0.0286223C3.47356 0.0546304 3.42318 0.0973071 3.38779 0.15145C3.3524 0.205593 3.33353 0.268862 3.33347 0.333546V2.66705H0.333347C0.244938 2.66705 0.16015 2.70218 0.0976351 2.76469C0.0351204 2.82721 0 2.912 0 3.00041L0 5.00056C0 5.08898 0.0351204 5.17377 0.0976351 5.23628C0.16015 5.2988 0.244938 5.33392 0.333347 5.33392H3.33347V7.66743C3.33353 7.73211 3.3524 7.79538 3.38779 7.84953C3.42318 7.90367 3.47356 7.94635 3.53278 7.97235C3.59201 7.99836 3.65752 8.00658 3.72133 7.996C3.78514 7.98542 3.8445 7.95651 3.89216 7.91278L7.89232 4.24584C7.92627 4.21462 7.95336 4.17669 7.9719 4.13446C7.99043 4.09223 8 4.04661 8 4.00049C8 3.95437 7.99043 3.90875 7.9719 3.86652C7.95336 3.82429 7.92627 3.78636 7.89232 3.75514L3.89216 0.0881946Z"
              fill="currentColor"
            />
          </svg>
          {end && moment(end).format(format)}
        </span>
      )
    } else {
      return (
        <>
          {!shown_date &&
            (item.source === 'questionnaire' ? '"Questionnaire"' : '"Manual"')}
          {shown_date && moment(shown_date).format(format)}
        </>
      )
    }
  }

  function showDate(item) {
    const { shown_date, start, end } = item
    const format = 'M/D/YYYY'
    let date = shown_date ? moment(shown_date).format(format) : ''
    if (start && end) {
      date = moment(start).format(format) + ' - ' + moment(end).format(format)
    }
    return date
  }

  function addItem(type) {
    setAddItemType(type)
  }

  function editItem(type, target) {
    setEditTarget(target)
    setAddItemType(type)
  }

  async function fetchData() {
    const data = await getMedicalInformation(person.id)
    const medications = {}
    for (const category in categories) {
      medications[category] = data.filter((item) => item.category === category)
    }
    setMedications(medications)
  }
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 18px;
    font-family: 'Gilroy';
    font-weight: 500;
    margin: 0;
    span {
      color: #323f4a;
      &.count {
        width: 23px;
        height: 23px;
        display: inline-block;
        font-size: 12px;
        margin-left: 3px;
        color: #ffffff;
        background: #264382;
        border-radius: 100%;
        text-align: center;
        line-height: 23px;
      }
    }
  }
`

function PanelHeader(props) {
  const { type, title, count, addItem } = props
  return (
    <HeaderContainer>
      <div className="title">
        <span>{title} </span>
        {count > 0 && <span className="count">{count}</span>}
      </div>
      {addItem && (
        <Button type="primary" ghost icon={<PlusOutlined />} onClick={onAdd}>
          ADD
        </Button>
      )}
    </HeaderContainer>
  )

  function onAdd(e) {
    e.stopPropagation()
    addItem(type)
  }
}

function DoctorAvatar() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 6.75C3.6 6.75 2.25 5.4 2.25 3.75V3C2.25 1.35 3.6 0 5.25 0C6.9 0 8.25 1.35 8.25 3V3.75C8.25 5.4 6.9 6.75 5.25 6.75Z"
        fill="#D6DBDF"
      />
      <path d="M3.75 8.25C1.65 8.25 0 9.9 0 12H6V8.25H3.75Z" fill="#D6DBDF" />
      <path
        d="M10.5 7.5H9V9H7.5V10.5H9V12H10.5V10.5H12V9H10.5V7.5Z"
        fill="#D6DBDF"
      />
    </svg>
  )
}
