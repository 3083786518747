import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, Button, Divider, message } from 'antd'
import BasicForm from '../../../../components/formV4'
import { getFacilityForm } from '../../../../models/facility.form'
import { createFacility, updateFacility } from '../../../../api/facility'
import './index.sass'

class FacilityModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    services: PropTypes.array,
    initValue: PropTypes.object,
    submitSync: PropTypes.func,
    editPart: PropTypes.string
  }

  state = {
    selected: [],
    valueForm: {}
  }

  cancel = () => {
    const { closeModal } = this.props
    closeModal()
  }

  submit = async () => {
    const { initValue, submitSync, closeModal, editPart } = this.props
    this.setState({ loading: true })

    try {
      let params = {}
      if (editPart !== 'service') {
        params = await this.refForm.handleSubmit()
      } else {
        const { name, address, open_time, close_time } = initValue
        params = { name, address, open_time, close_time }
      }
      params.service_ids = this.state.selected
      params.open_time = moment(params.open_time, 'h:mm A').format('HH:mm:ss')
      params.close_time = moment(params.close_time, 'h:mm A').format('HH:mm:ss')
      let result, successMsg, syncType
      if (initValue) {
        result = await updateFacility(params, initValue.id)
        syncType = 'update'
        successMsg = 'Facility updated!'
      } else {
        result = await createFacility(params)
        syncType = 'create'
        successMsg = 'Facility created!'
      }

      this.setState({ loading: false })
      submitSync(result.facility, syncType)
      message.success(successMsg)
      closeModal()
    } catch (err) {
      console.error(err)
      this.setState({ loading: false })
    }
  }

  selected = (item) => {
    let { selected } = this.state
    const serviceId = selected.find((i) => i === item.id)
    if (serviceId) {
      selected = selected.filter((i) => i !== item.id)
    } else {
      selected.push(item.id)
    }
    this.setState({ selected })
  }

  updateValueForm = (changedValues, allValues) => {
    this.setState({ valueForm: allValues })
  }

  getDisabledHour = (type) => {
    const { valueForm } = this.state
    let compareTime, hour, minute
    let result = Array.from(Array(24), (v, k) => k)
    if (type === 'open') {
      const openTime = moment(valueForm.open_time)
      compareTime = moment(valueForm.close_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      result = result.filter((item) => {
        if (minute > 0 && openTime.minute() < minute) {
          return item >= hour
        } else {
          return item > hour
        }
      })
    } else {
      const closeTime = moment(valueForm.close_time)
      compareTime = moment(valueForm.open_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      result = result.filter((item) => {
        if (minute > 0 && closeTime.minute() > minute) {
          return item <= hour
        } else {
          return item < hour
        }
      })
    }
    return result
  }

  getDisabledMinute = (type, selectHour) => {
    const { valueForm } = this.state
    let compareTime, hour, minute
    let result = Array.from(Array(60), (v, k) => k)
    if (type === 'open') {
      compareTime = moment(valueForm.close_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      result = result.filter((item) => {
        if (selectHour === hour) {
          return item > minute
        }
        return false
      })
    } else {
      compareTime = moment(valueForm.open_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      result = result.filter((item) => {
        if (selectHour === hour) {
          return item < minute
        }
        return false
      })
    }

    return result
  }

  disabled = (time, type, hour) => {
    if (time === 'hours') {
      return this.getDisabledHour(type)
    } else {
      return this.getDisabledMinute(type, hour)
    }
  }

  validateTime = (type, value, callback) => {
    const { valueForm } = this.state
    const time = moment(value, 'h:mm A')
    let compareTime, hour, minute
    if (type === 'open') {
      compareTime = moment(valueForm.close_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      if (
        time.hour() > hour ||
        (time.hour() === hour && time.minute() >= minute)
      ) {
        return callback('Open time should be ahead close time')
      }
    } else {
      compareTime = moment(valueForm.open_time, 'h:mm A')
      hour = compareTime.hour()
      minute = compareTime.minute()
      if (
        time.hour() < hour ||
        (time.hour() === hour && time.minute() <= minute)
      ) {
        return callback('Close time should be behind open time')
      }
    }
    return callback()
  }

  componentDidMount() {
    const { initValue } = this.props
    if (initValue && initValue.services) {
      this.setState({
        selected: initValue.services.map((item) => item.id),
        valueForm: initValue || {}
      })
    }
  }

  render() {
    const { visible, initValue, services, editPart } = this.props
    const { loading, selected } = this.state
    const form = getFacilityForm(this.disabled, this.validateTime)
    return (
      <Modal
        visible={visible}
        className="phs-modal facilities-expert-modal"
        onCancel={this.cancel}
        footer={
          <>
            <Button onClick={this.cancel}>CANCEL</Button>
            <Button onClick={this.submit} type="primary" loading={loading}>
              CONFIRM
            </Button>
          </>
        }
      >
        <h1 className="title">
          {initValue ? 'Edit ' : 'Add '}
          {editPart !== 'service' ? 'Facility' : 'Services'}
        </h1>
        {editPart !== 'service' ? (
          <BasicForm
            wrappedComponentRef={(inst) => (this.refForm = inst)}
            initialValues={initValue || {}}
            onChange={this.updateValueForm}
            formData={form}
          />
        ) : (
          <div className="facility-name">
            <span>Facility name: </span>
            {initValue.name}
          </div>
        )}
        {editPart !== 'facility' && (
          <>
            <Divider orientation="left">
              Services AVAILABLE {!initValue && <span>(CLICK TO SELECT)</span>}
            </Divider>

            <ul className="available-list">
              {services.map((item, index) => (
                <li
                  key={index}
                  className={
                    selected.find((i) => i === item.id) ? 'selected' : ''
                  }
                  onClick={() => this.selected(item)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </>
        )}
      </Modal>
    )
  }
}

export default FacilityModal
