import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Empty } from 'antd'
import QuestionnaireWrapper from './wrapper'
import AnswerProgress from './progress'
import Section from './section'
import PageLoading from '../UI/status/loading'
import styled from 'styled-components'

const Container = styled.div`
  .ant-tabs {
    padding-top: 40px;
    .ant-tabs-nav-container {
      &:after {
        display: none;
      }
    }
    &.ant-tabs-top {
      margin: 0;
      border: none;
      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
  .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs.ant-tabs-top .ant-tabs-nav-list .ant-tabs-tab {
    min-width: 176px;
    margin: 0;
    height: 49px;
    line-height: 29px;
    padding: 10px 0;
    text-align: center;
    background: #e8ecf0;
    color: #bac3ce;
    font-family: Gilroy;
    font-size: 14px;
    letter-spacing: 0.3px;
    display: flex;
    justify-content: space-around;
  }
  .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs.ant-tabs-top .ant-tabs-nav-list .ant-tabs-tab-active {
    background: #ffffff;
  }
  .ant-tabs.ant-tabs-top .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs.ant-tabs-top
    .ant-tabs-nav-list
    .ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #75879d;
  }
`

const { TabPane } = Tabs

class QuestionsContainer extends Component {
  static propTypes = {
    survey: PropTypes.object,
    answer: PropTypes.object,
    setEditSection: PropTypes.func,
    person: PropTypes.object
  }

  render() {
    const {
      survey,
      answer,
      dataLoading,
      getAnswer,
      person,
      editAnswer
    } = this.props

    return dataLoading ? (
      <PageLoading />
    ) : (
      <Container>
        {survey ? (
          <AnswerProgress survey={survey} answers={answer && answer.answer} />
        ) : (
          <Empty />
        )}
        {survey && (
          <Tabs
            type="card"
            defaultActiveKey="0"
            onChange={(activeKey) => this.props.setEditSection(activeKey)}
          >
            {survey.sections.map((item, index) => (
              <TabPane tab={item.name} key={index}>
                <Section
                  earlierSections={survey.earlier_sections}
                  section={item}
                  answers={answer && answer.answer}
                  getAnswer={getAnswer}
                  editAnswer={editAnswer}
                  person={person}
                />
              </TabPane>
            ))}
          </Tabs>
        )}
      </Container>
    )
  }
}

export default QuestionnaireWrapper(QuestionsContainer)
