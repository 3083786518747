import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Modal, Button, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  getMedicalForm,
  getSupplementsForm,
  getConditionForm,
  getAllergiesForm,
  categories,
  fixedUnits
} from '../../models/medical.form'
import BasicForm from '../formV4'
import Confirm from '../UI/confirmModal'
import { createMedical, updateMedical, deleteMedical } from '../../api/medical'

export default function MedicalEditor(props) {
  const { type, target, closeModal, person, submitSync } = props
  const [refForm, setRefForm] = useState(null)
  const [valueForm, setValueForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmDel, setConfirmDel] = useState(false)
  const [formData, setFormData] = useState([])

  useEffect(() => {
    if (target) {
      const initial = _.cloneDeep(target)

      if (initial.unit) {
        if (
          !fixedUnits.includes(initial.unit) &&
          !initial.unit.includes('other')
        ) {
          // handle old data
          initial.unit = 'other:' + initial.unit
        }
        if (initial.unit.includes('other')) {
          const [unit, unit_supplement] = initial.unit.split(':')
          initial.unit = unit
          initial.unit_supplement = unit_supplement
        }
      }
      if (initial.frequency && initial.frequency.includes('other')) {
        const [frequency, frequency_supplement] = initial.frequency.split(':')
        initial.frequency = frequency
        initial.frequency_supplement = frequency_supplement
      }

      initial.isMultiple = initial.name.includes('\n')
      setValueForm(initial)
    } else {
      setValueForm({})
    }
  }, [target])
  useEffect(() => {
    let formData = []
    if (valueForm) {
      switch (type) {
        case categories.medication:
          formData = getMedicalForm(valueForm)
          break
        case categories.supplement:
          formData = getSupplementsForm(valueForm)
          break
        case categories.allergy:
          formData = getAllergiesForm(valueForm)
          break
        case categories.active:
        case categories.inactive:
          formData = getConditionForm(valueForm, type)
          break
        default:
          break
      }
    }
    setFormData(formData)
  }, [valueForm])

  return (
    <Modal
      visible={true}
      width={693}
      title={`${target && target.id ? 'Edit' : 'Add'} ${type.replace(
        type[0],
        type[0].toUpperCase()
      )} ${
        type === categories.active || type === categories.inactive
          ? 'Condition'
          : ''
      }`}
      okText="SAVE"
      cancelText="CANCEL"
      onCancel={closeModal}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {target && target.id && (
              <Button
                danger
                type="text"
                style={{ height: 48 }}
                onClick={() => setConfirmDel(true)}
              >
                DELETE
              </Button>
            )}
          </div>
          <div>
            <Button type="text" style={{ height: 48 }} onClick={closeModal}>
              CANCEL
            </Button>
            {target && target.id && (
              <>
                {type === categories.active && (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: 155, height: 48 }}
                    loading={loading}
                    onClick={() => toggle('resolve')}
                  >
                    RESOLVE
                  </Button>
                )}
                {type === categories.inactive && (
                  <Button
                    type="primary"
                    ghost
                    style={{ width: 155, height: 48 }}
                    loading={loading}
                    onClick={() => toggle('reActive')}
                  >
                    RE-ACTIVE
                  </Button>
                )}
              </>
            )}

            <Button
              onClick={submit}
              type="primary"
              style={{ width: 155, height: 48 }}
              loading={loading}
            >
              {target && target.id ? 'UPDATE' : 'SAVE'}
            </Button>
          </div>
        </div>
      }
    >
      {valueForm && (
        <BasicForm
          initialValues={valueForm}
          formData={formData}
          onChange={updateValueForm}
          wrappedComponentRef={(inst) => setRefForm(inst)}
        />
      )}
      {confirmDel && (
        <ConfirmDelete
          handleDelete={handleDelete}
          onCancel={() => setConfirmDel(false)}
          target={target}
        />
      )}
    </Modal>
  )

  function updateValueForm(changedValue, allValues) {
    setValueForm(_.cloneDeep(_.assign(valueForm, allValues)))
  }

  async function handleDelete() {
    await deleteMedical(target.id)
    submitSync && submitSync()
    closeModal()
  }

  async function submit() {
    setLoading(true)
    try {
      const params = await refForm.handleSubmit(formData)
      params.category = type
      if (params.unit && params.unit === 'other') {
        params.unit = params.unit + ':' + params.unit_supplement
        delete params.unit_supplement
      }
      if (params.frequency && params.frequency === 'other') {
        params.frequency = params.frequency + ':' + params.frequency_supplement
        delete params.frequency_supplement
      }

      if (_.has(params, 'start') && !params.start) {
        params.start = null
      }
      if (_.has(params, 'end') && !params.end) {
        params.end = null
      }

      if (target && target.id) {
        await updateMedical(params, target.id)
      } else {
        await createMedical(params, person.id)
      }
      message.success('Success!')
      submitSync && submitSync()
      closeModal()
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }

  async function toggle(opr) {
    setLoading(true)
    try {
      const params = await refForm.handleSubmit(formData)
      if (opr === 'resolve') {
        params.inactive_type = 'resolved'
        params.category = categories.inactive
      } else {
        params.active_type = target.active_type || 'chronic'
        params.category = categories.active
      }

      await updateMedical(params, target.id)
      message.success('Success!')
      submitSync && submitSync()
      closeModal()
    } catch (err) {
      console.error(err)
      setLoading(false)
    }
  }
}

function ConfirmDelete(props) {
  const { handleDelete, onCancel, target } = props
  const [isDeleting, setIsDeleting] = useState(false)
  async function onConfirm() {
    setIsDeleting(true)
    await handleDelete()
    setIsDeleting(false)
    onCancel()
  }
  return (
    <Confirm
      {...{
        modalWidth: 564,
        title: (
          <span
            style={{
              fontSize: 16,
              color: '#262626',
              lineHeight: 1.7,
              display: 'inline-block'
            }}
          >
            <ExclamationCircleOutlined
              style={{ color: '#F9B300', marginRight: 8 }}
            />
            Do you want to delete: {target.name}?
          </span>
        ),
        onCancel,
        footer: (
          <>
            <Button onClick={onCancel} style={{ height: 'auto' }}>
              Cancel
            </Button>
            <Button
              danger
              loading={isDeleting}
              disabled={isDeleting}
              style={{ height: 'auto', marginLeft: 20 }}
              onClick={onConfirm}
            >
              Delete
            </Button>
          </>
        )
      }}
    />
  )
}
