import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Alert, Divider } from 'antd'
import { connect } from 'react-redux'
import { setEditingTextNumber } from '../../../redux/summaryEmail/action'
import {
  getClientList,
  getClientDetail,
  getWeeklySummary,
  getSummaryEmail
} from '../../../models/breadcrumb.model'
import WeeklySummaryContainer from '../../../components/summaryEmail/v3/draftEmailContainer' //'../../../components/summaryEmail/weeklySummary'
import { getNotificationStatus } from '../../../api/people'

class WeeklySummary extends Component {
  static propTypes = {}

  state = { allow_emails: true }

  initial = async () => {
    const {
      getPerson,
      setClientPageTitle,
      setClientBreadcrumb,
      match
    } = this.props
    let { person } = this.state
    const {
      params: { id }
    } = match
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    // get notification status
    const { allow_emails } = await getNotificationStatus(person.id)
    this.setState({ allow_emails })
    // console.log(result)
    setClientPageTitle(
      id === 'new' ? 'Create New Email' : 'Summary Email Detail'
    )
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getSummaryEmail(person),
        getWeeklySummary(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const {
      match: { params, path },
      history,
      setEditingTextNumber
    } = this.props
    const { person, allow_emails } = this.state

    return person ? (
      <>
        <Divider style={{ marginTop: 0 }} />
        {!allow_emails && (
          <>
            <Alert
              message={`Email notifications have been paused for ${(person.profile &&
                person.profile.nickname) ||
                person.first_name} ${person.last_name}`}
              description="This client has requested email notifications to be turned OFF"
              type="warning"
              showIcon
            />
            <div style={{ marginBottom: 24 }} />
          </>
        )}
        <WeeklySummaryContainer
          person={person}
          allowEmailNotification={allow_emails}
          setEditingTextNumber={setEditingTextNumber}
          id={params.id}
          createSync={(id) => history.replace(path.replace(':id', id))}
          saveSync={() => history.replace(path.replace(':id', ''))}
          loginRole="Expert"
        />
      </>
    ) : (
      <></>
    )
  }
}

export default connect(
  ({ summaryEmail }) => ({ summaryEmail }),
  (dispatch) => ({
    setEditingTextNumber: (number) => dispatch(setEditingTextNumber(number))
  })
)(WeeklySummary)
