import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Divider } from 'antd'
import MultiSelect from '../dataInput/multiSelect'
import PersonalValue from '../dataInput/personalValue'
import RectangularText from '../../../UI/RectangularText'
import HistoryChart from './chat'

import { biomarkerStyle } from './index.sass'

class Biomarker extends Component {
  static propTypes = {
    biomarker: PropTypes.object,
    turnTo: PropTypes.func
  }

  state = {
    loading: false
  }

  switchHandle = async (checked) => {
    const {
      biomarker,
      savePersonalValue,
      datasetId,
      biomarkerIdField
    } = this.props
    const personalValue = Object.assign({}, biomarker.person_value)
    if (!biomarker.person_value) return
    try {
      this.setState({ loading: true })
      personalValue.on = checked
      await savePersonalValue(
        {
          on: personalValue.on,
          value: personalValue.value,
          id: personalValue[biomarkerIdField]
        },
        datasetId
      )
      this.setState({ loading: false })
    } catch (err) {
      console.error(err)
    }
  }

  getRangeString = (range) => {
    const [min, max] = range
    let str = ''
    if (min && max) {
      str = `${Number(min)} -- ${Number(max)}`
    } else if (!min && max) {
      str = `< ${Number(max)}`
    } else if (min && !max) {
      str = `>${Number(min)}`
    }
    return str
  }

  renderCurrentRange() {
    const { biomarker } = this.props
    const { person_value: personValue } = biomarker
    const currentRange = personValue && personValue.output_range
    return (
      <RectangularText
        text={(currentRange && currentRange.name) || 'N/A'}
        color={(currentRange && currentRange.color) || '#C9D0E0'}
      />
    )
  }

  renderOptimalRange() {
    const { biomarker } = this.props
    const { person_value: personValue } = biomarker
    let optimalRange = personValue && personValue.optimal_range
    // let thresholdRange = personValue && personValue.threshold_range
    let optimalRangeStr
    // let thresholdRangeStr
    if (biomarker.options && personValue) {
      optimalRange = biomarker.ranges.find((item) => item.is_optimal)
    } else if (optimalRange) {
      optimalRangeStr = this.getRangeString(optimalRange)
      // thresholdRangeStr = this.getRangeString(thresholdRange)
    }
    return (
      <>
        <div className="range-box">
          Optimal {biomarker.options ? 'Output' : 'Range'}
          <br />
          <span className={`ranges ${optimalRangeStr && optimalRange.name}`}>
            {(optimalRangeStr && optimalRangeStr.trim()) ||
              (optimalRange && optimalRange.name) || (
                <span className="unknown">N/A</span>
              )}
          </span>
        </div>
        {/* {thresholdRangeStr && (
          <div className="range-box">
            Threshold Range
            <br />
            <span
              className={`ranges ${thresholdRangeStr && thresholdRange.name}`}
            >
              {(thresholdRangeStr && thresholdRangeStr.trim()) ||
                (thresholdRange && thresholdRange.name) || (
                  <span className="unknown">N/A</span>
                )}
            </span>
          </div>
        )} */}
      </>
    )
  }

  render() {
    const { biomarker, turnTo, datasetId, person, ageScopes } = this.props
    return (
      <div className={biomarkerStyle} name={biomarker.name}>
        <div className="personal-value-container">
          <div
            className="name"
            onClick={() => {
              turnTo(biomarker.id, datasetId)
            }}
          >
            {biomarker.name}
          </div>

          <div className="value">
            <span>your value</span>
            {biomarker.options ? (
              <MultiSelect {...this.props} />
            ) : (
              <PersonalValue {...this.props} />
            )}
          </div>
        </div>
        <Divider type="vertical" />
        <div className="range">
          <div className="conventional">
            <Switch
              loading={this.state.loading}
              onClick={this.switchHandle}
              checked={
                biomarker && biomarker.person_value && biomarker.person_value.on
              }
            />

            <span>{this.renderOptimalRange()}</span>
            {this.renderCurrentRange()}
          </div>
        </div>
        <HistoryChart
          biomarker={biomarker}
          person={person}
          ageScopes={ageScopes}
        />
      </div>
    )
  }
}

export default Biomarker
