import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Button } from 'antd'
import { getCheckIn, getCheckInFacility } from '../../../utils/storage'
import SquareLogo from '../../../asserts/images/square-logo.svg'
import TextLogo from '../../../asserts/icon/text-logo.svg'

class Finish extends Component {
  static propTypes = {}

  state = {
    align: false,
    logoStyle: {}
  }

  animateAssist = () => {
    const avatarElem = document.getElementById('avatar-image'),
      avatarDom = ReactDOM.findDOMNode(avatarElem),
      box = avatarDom.getBoundingClientRect(),
      body = document.body,
      docEl = document.documentElement,
      scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
      clientTop = docEl.clientTop || body.clientTop || 0,
      top = box.top + scrollTop - clientTop
    this.setState({ logoStyle: { top: top - 4 } })
  }

  componentDidMount() {
    const facility = getCheckInFacility()
    this.setState({ facility })
    setTimeout(() => {
      this.setState({ align: true })
      this.animateAssist()
    }, 1000)
  }

  render() {
    const { align, facility, logoStyle } = this.state
    const checkIn = getCheckIn()
    return (
      <div className={`finish ${align ? 'align' : ''}`}>
        {/* align */}
        <div className="header">
          <img src={SquareLogo} alt="logo" className="square-logo" />
          <img src={TextLogo} alt="text" />
        </div>
        <img
          src={SquareLogo}
          style={logoStyle}
          alt="logo"
          className="square-logo"
        />
        <div className="center">
          <div className="avatar-content">
            {checkIn && checkIn.avatar && (
              <img
                src={checkIn.avatar.oss_url}
                alt="avatar"
                id="avatar-image"
              />
            )}
          </div>
          <p className="check-title large">Thank you! You’re all set.</p>
          <p className="check-title">
            We’ve let{' '}
            <span className="first-name">
              {checkIn &&
                checkIn.records &&
                checkIn.records.pop().expert.first_name}{' '}
            </span>
            know you’re here.
          </p>
          <Button
            ghost
            onClick={() => this.props.history.push(`/check-in/${facility.id}`)}
          >
            Back to Homepage
          </Button>
        </div>
      </div>
    )
  }
}

export default Finish
