import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import TipBar from '../../UI/tipBarCom'
import Styles from './styles/detailItem.module.sass'

class DetailItems extends Component {
  static propTypes = {
    activityDetail: PropTypes.object,
    showHeartRate: PropTypes.bool,
    manualDetail: PropTypes.object
  }

  getTrackingActivityMetrics = () => {
    let { trackingActivityMetrics } = this.props
    trackingActivityMetrics =
      trackingActivityMetrics ||
      'type,distance,duration,caloriesBurnt,avgHeartRate,maxHeartRate,heartRate,heartRateZone'
    return trackingActivityMetrics
  }

  transformActivityDetail(detail) {
    const { manualDetail } = this.props
    const trackingActivityMetrics = this.getTrackingActivityMetrics()
    var keyValues = []

    if (trackingActivityMetrics.includes('distance') && detail.distance) {
      keyValues.push({
        key: 'Distance',
        value: `${Math.round((detail.distance || 0) / 16.09344) / 100} mi`
      })
    }
    if (trackingActivityMetrics.includes('duration')) {
      if (manualDetail) {
        keyValues.push({
          key: 'Duration',
          value: detail.formattedDuration,
          suffix: <span className={`${Styles.durationSuffix}`}>(Edited)</span>
        })
      } else {
        keyValues.push({ key: 'Duration', value: detail.formattedDuration })
      }
    }
    if (
      trackingActivityMetrics.includes('caloriesBurnt') &&
      detail.source !== 'manual'
    ) {
      keyValues.push({
        key: 'Active Calories',
        value: `${Math.round(detail.energy_burned || 0)} Cal`
      })
    }
    if (
      trackingActivityMetrics.includes('avgHeartRate') &&
      detail.source !== 'manual'
    ) {
      keyValues.push({
        key: 'Avg Heart Rate',
        value: `${(detail.average_heart_rate &&
          Math.round(detail.average_heart_rate)) ||
          0} bpm`
      })
    }
    if (
      trackingActivityMetrics.includes('maxHeartRate') &&
      detail.source !== 'manual'
    ) {
      keyValues.push({
        key: 'Max Heart Rate',
        value: `${detail.max_heart_rate || 0} bpm`
      })
    }
    return keyValues
  }

  render() {
    const { activityDetail, showHeartRate } = this.props
    return activityDetail ? (
      <>
        <Row
          className={`${Styles.activityTypeTextContainer} ${
            showHeartRate ? 'bottom-line' : ''
          }`}
        >
          <Col style={{ paddingTop: 16 }} span={24}>
            <div
              className={`${Styles.activityTextContent} ${Styles.daySummary}`}
            >
              Activity Summary
            </div>
          </Col>
          {activityDetail.activity_type_shown && (
            <Col style={{ paddingTop: 16 }} span={24}>
              <div className={Styles.activityTextTitle}>Activity Name</div>
              <div
                className={`${Styles.activityTextContent} ${Styles.daySummary}`}
              >
                {activityDetail.activity_type_shown}
              </div>
            </Col>
          )}
          <Col style={{ paddingTop: 16 }} span={12}>
            <div className={Styles.activityTextTitle}>Source</div>
            <div className={`${Styles.activityTextContent} ${Styles.source}`}>
              {activityDetail.source === 'ouraring'
                ? 'Oura ring'
                : activityDetail.source}
            </div>
          </Col>
          {activityDetail.activity_classification && (
            <Col style={{ paddingTop: 16 }} span={12}>
              <div className={Styles.activityTextTitle}>Activity Type</div>
              <div
                className={`${Styles.activityTextContent} ${Styles.daySummary}`}
              >
                <span
                  className={`circle ${activityDetail.shift_activity_classification ||
                    activityDetail.activity_classification}`}
                />
                {activityDetail.shift_activity_classification ||
                  activityDetail.activity_classification}
              </div>
            </Col>
          )}

          {this.transformActivityDetail(activityDetail).map(
            (keyValue, index) => {
              return (
                <Col key={index} style={{ paddingTop: 16 }} span={12}>
                  <div className={Styles.activityTextTitle}>{keyValue.key}</div>
                  <div className={Styles.activityTextContent}>
                    {keyValue.value}
                    {keyValue.suffix && keyValue.suffix}
                  </div>
                </Col>
              )
            }
          )}
        </Row>

        {activityDetail.shift_activity_classification && (
          <TipBar
            text={`The exercise type has been changed from ${activityDetail.activity_classification} to ${activityDetail.shift_activity_classification} based on the HRZ of this exercise.`}
          />
        )}
      </>
    ) : (
      <></>
    )
  }
}

export default DetailItems
