import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Modal, Button, Segmented, message } from 'antd'
import { getExperts } from '../../../../api/team'
import PageLoading from '../../../../components/UI/status/loading'
import BasicForm from '../../../../components/formV4'
import { SendQuestionnaireModalBody } from './style'
import Logo from '../../../../asserts/icon/icon-logo.svg'
import moment from 'moment'
import { createSectionsSession, sendNewSessionEmail } from '../../../../api'
import { get } from 'lodash'

function getFormData(experts) {
  return [
    {
      type: 'select',
      options: experts.map((expert) => {
        return {
          label: expert.first_name + ` (${expert.email})`,
          value: expert.email
        }
      }),
      dbField: 'from',
      label: 'from',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'input',
      label: 'subject',
      dbField: 'subject',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      type: 'richText',
      extraAttr: {
        toolbar: {
          options: ['inline', 'fontSize', 'list', 'link'],
          link: {
            showOpenOptionOnHover: false,
            defaultTargetOption: '_self'
          }
        },
        toolbarHidden: true
      },
      dbField: 'content',
      rules: [{ required: true, message: 'Please input email content' }]
    }
  ]
}

export default function SendQuestionnaire(props) {
  const {
    onCancel,
    person,
    done,
    allowCreate = () => true,
    sendEmail: { title, originalIds, qNames }
  } = props
  let refForm = useRef(null)
  const [sendMethod, setSendMethod] = useState('email')
  const [loading, setLoading] = useState(false)
  const [pageloading, setPageloading] = useState(true)
  const [experts, setExperts] = useState([])
  const [valueForm, setValueForm] = useState({})

  const emailSubject = useMemo(() => {
    return `${get(person, 'profile.nickname') ||
      get(person, 'first_name')} - Please update your Apeiron Life ${
      qNames.length > 1 ? 'Questionnaires' : qNames[0]
    }`
  }, [person, qNames])

  const targetLink = useMemo(() => {
    const queryString = btoa(
      JSON.stringify({
        person_id: person.id,
        record_date: moment().format('YYYY-MM-DD'),
        section_ids: originalIds.join(',')
      })
    )
    return process.env.REACT_APP_WORKFLOW === 'production'
      ? `https://app.apeiron.life/onboarding/questionnaire-record/${queryString}`
      : `https://app-staging.apeiron.life/onboarding/questionnaire-record/${queryString}`
  }, [originalIds, person])

  function generateParams() {
    return {
      person_id: person.id,
      record_date: moment().format('YYYY-MM-DD'),
      original_ids: originalIds.join(',')
    }
  }

  function getEmailTemplate(from) {
    return `<p>Hi ${person.first_name},</p>
    <p>To allow your Apeiron Life team to better customize and personalize your program, please update the following: </p>
    ${
      qNames.length > 1
        ? `<div>${qNames
            .map((name, index) => {
              return `<a href="${targetLink}" _blank="target" style="text-decoration: none">- ${name}</a>${
                index + 1 < qNames.length ? '<br/>' : ''
              }`
            })
            .join('')}</div>`
        : `<p><a href="${targetLink}" _blank="target" style="text-decoration: none">${qNames[0]}</a></p>`
    }
    <p>Please reach out with any questions.</p>
    <p>Thank you, <br>${from}</p>`
  }

  useEffect(() => {
    const env = process.env.REACT_APP_WORKFLOW
    const from =
      env === 'production' ? 'amy@apeiron.life' : 'ava+13@interactivelabs.co'

    const initData = async () => {
      const result = await getExperts()
      setExperts(result)
      setPageloading(false)
      const template = getEmailTemplate(
        result.find((e) => e.email === from).first_name
      )
      console.log(
        '🚀 ~ file: sendQuestionnaireModal.js:117 ~ initData ~ template:',
        template
      )
      setValueForm({
        from: from,
        subject: emailSubject,
        content: template
      })
    }
    initData()
  }, [])

  const sendEmail = async () => {
    if (!allowCreate()) {
      return
    }
    setLoading(true)
    const formParmas = await refForm.current.handleSubmit()
    try {
      await createSectionsSession(generateParams())
      await sendNewSessionEmail(formParmas, person.id)
      done('email')
    } catch (error) {
      setLoading(false)
    }
  }

  const copyLink = async () => {
    if (!allowCreate()) {
      return
    }
    setLoading(true)
    try {
      const textToCopy = targetLink
      await navigator.clipboard.writeText(textToCopy)
      await createSectionsSession(generateParams())
      message.success('Copied!')
      done('link')
    } catch (error) {
      setLoading(false)
      await navigator.clipboard.writeText('')
    }
  }

  const updateValueForm = async (changedValues) => {
    if (changedValues.from) {
      const template = getEmailTemplate(
        experts.find((e) => e.email === changedValues.from).first_name
      )
      console.log(
        '🚀 ~ file: sendQuestionnaireModal.js:163 ~ updateValueForm ~ template:',
        template
      )
      valueForm.content = template
      refForm.current.setFieldValue('content', template)
      setValueForm({
        from: changedValues.from,
        subject: emailSubject,
        content: template
      })
    }
  }

  return (
    <Modal
      open={true}
      onCancel={onCancel}
      className="phs-modal client-creation"
      width={'-'}
      footer={
        <>
          <Button onClick={onCancel}>CANCEL</Button>
          {sendMethod === 'email' ? (
            <Button
              onClick={sendEmail}
              type="primary"
              loading={loading}
              style={{
                width: '84'
              }}
            >
              SEND
            </Button>
          ) : (
            <Button onClick={copyLink} type="primary" loading={loading}>
              COPY LINK
            </Button>
          )}
        </>
      }
    >
      <SendQuestionnaireModalBody>
        <h1 className="title">Send Questionnaire</h1>
        <Segmented
          options={[
            {
              label: <div style={{ padding: 4 }}>Send an email</div>,
              value: 'email'
            },
            {
              label: <div style={{ padding: 4 }}>Copy Link</div>,
              value: 'link'
            }
          ]}
          value={sendMethod}
          onChange={setSendMethod}
        />
        {pageloading ? (
          <PageLoading />
        ) : sendMethod === 'email' ? (
          valueForm.content && (
            <BasicForm
              wrappedComponentRef={(ref) => {
                refForm.current = ref
              }}
              initialValues={valueForm}
              formData={getFormData(experts)}
              onChange={updateValueForm}
            />
          )
        ) : (
          <div className="link-container">
            <img src={Logo} alt="logo" />
            <div className="desc-container">
              <div className="link-title">{title}</div>
              <div className="link">apeiron.life</div>
            </div>
          </div>
        )}
      </SendQuestionnaireModalBody>
    </Modal>
  )
}
