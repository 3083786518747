import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
import { Input, Button, Row, Col } from 'antd'

export class InputArray extends Component {
  static propTypes = {
    initVales: PropTypes.array,
    eachColSpan: PropTypes.number
  }

  state = {
    values: []
  }

  componentDidMount() {
    const { value: values } = this.props
    if (values && values.length) {
      this.setState({ values })
    }
  }

  triggerChange = changedValue => {
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  valueChange = (value, index) => {
    const { values } = this.state
    values[index] = value
    this.setState({ values })
    this.triggerChange(values)
  }

  addItem = () => {
    const { values } = this.state
    values.push('')
    this.setState({ values })
  }

  removeItem = index => {
    const { values } = this.state
    values.splice(index, 1)
    this.setState({ values })
  }

  render() {
    const { eachColSpan } = this.props
    return (
      <div>
        <Row type="flex" justify="start">
          {this.state.values.map((item, index) => (
            <Col
              span={eachColSpan || 24}
              key={index}
              style={{ marginBottom: 10 }}
            >
              <Input
                defaultValue={item}
                addonAfter={
                  <DeleteOutlined onClick={e => this.removeItem(index)} />
                }
                onChange={e => this.valueChange(e.target.value, index)}
              />
            </Col>
          ))}
        </Row>
        <Button style={{ marginTop: '20px' }} onClick={this.addItem}>
          Add Item
        </Button>
      </div>
    )
  }
}

export default InputArray
