import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button, Divider } from 'antd'
import BasicForm from '../../formV4'
import { DuplicateIcon, EmptyIcon } from '../../icons/program'
import style from '../style/programEditor.module.sass'

class CreateProgram extends Component {
  static propTypes = {
    programs: PropTypes.array
  }

  state = {
    type: 'copy'
  }

  handleOk = async () => {
    const { handleOk, programs } = this.props
    const { type } = this.state
    if (type === 'copy') {
      try {
        const { programId } = await this.basicFormRef.handleSubmit()
        const program = programs.find((item) => item.id === programId)
        handleOk(program)
      } catch (err) {
        console.error(err)
      }
    } else {
      handleOk()
    }
  }

  render() {
    const { programs } = this.props
    const { type } = this.state
    const options = programs.map((program) => ({
      label: program.name + `(${program.status === 1 ? 'active' : 'inactive'})`,
      value: program.id
    }))
    return (
      <Modal
        visible={true}
        width={630}
        bodyStyle={{ padding: '36px 45px' }}
        onOk={this.handleOk}
        onCancel={this.props.handleCancel}
        footer={null}
      >
        <h1 className={style.title}>Create a program</h1>
        <div
          className={type === 'copy' ? style.itemActive : style.item}
          onClick={() => this.setState({ type: 'copy' })}
        >
          <DuplicateIcon />
          Duplicate program
        </div>
        <BasicForm
          wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
          formData={[
            {
              type: 'select',
              options,
              dbField: 'programId',
              label: 'PROGRAM',
              rules: [{ required: true, message: 'This field is required' }]
            }
          ]}
        />
        <div className={style.divider}>
          <Divider dashed={true}> or</Divider>
        </div>
        <div
          className={type === 'empty' ? style.itemActive : style.item}
          onClick={() => this.setState({ type: 'empty' })}
        >
          <EmptyIcon />
          Create an empty program
        </div>
        <footer className={style.footer}>
          <div />
          <div>
            <Button ghost onClick={this.props.handleCancel}>
              CANCEL
            </Button>
            <Button type="primary" onClick={this.handleOk}>
              CONFIRM
            </Button>
          </div>
        </footer>
      </Modal>
    )
  }
}

export default CreateProgram
