import React from 'react'
import styled from 'styled-components'
import { VictoryPie } from 'victory'

const Container = styled.div`
  border-radius: 8px;
  background: #fafafa;
  margin-bottom: 24px;
  color: #323f4a;
  &.active {
    border: 1px solid #264382;
  }
  .title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    margin: 0 24px;
    padding: 16px 0;
    border-bottom: 1px solid #e3e6ea;
    text-transform: capitalize;
    .value {
      font-family: 'Gilroy-Bold';
    }
  }
  .pipe-container {
    padding-left: 10px;
    padding-top: 10px;
    .pipe {
      width: 110px;
      display: inline-block;
      vertical-align: top;
    }
    .pipe-list {
      width: calc(100% - 110px);
      display: inline-block;
      list-style: none;
      font-size: 12px;
      padding-left: 10px;
      padding-top: 15px;
      padding-right: 24px;
      li {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: #4c6072;
        margin-bottom: 10px;
        letter-spacing: 0.3px;
        font-family: 'Gilroy-Bold';
        .label-name {
          color: #a5afb8;
          letter-spacing: 0.7px;
          span {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 2px;
          }
        }
      }
    }
  }
`

export default function GraphCard(props) {
  const { entries, type, percentage_score, isActive } = props
  const getPipeData = (entries, type) => {
    if (type === 'timing') {
      entries = entries.filter((item) => item.score)
    }
    const pipeData = [
      { x: 'AT RISK', y: 0, color: '#FF6B00', score: 1 },
      { x: 'CONCERN', y: 0, color: '#F5BA40', score: 2 },
      { x: 'OPTIMAL', y: 0, color: '#40C47C', score: 3 },
      { x: 'UNKNOW', y: 0, color: 'rgb(201, 208, 224)', score: null }
    ]
    return pipeData
      .map((data) => {
        if (data.score) {
          data.y = entries.filter((entry) => entry.score === data.score).length
        } else {
          data.y = entries.filter((entry) => !entry.score).length
        }
        return data
      })
      .filter((data) => data.y)
  }

  const pipeData = getPipeData(entries, type)

  return (
    <Container className={isActive ? 'active' : ''}>
      <div className="title">
        <div>{type}</div>
        <div className="value">
          {percentage_score || percentage_score === 0
            ? percentage_score + '%'
            : '--'}
        </div>
      </div>
      <div className="pipe-container">
        <div className="pipe">
          <VictoryPie
            innerRadius={100}
            data={pipeData}
            style={{
              labels: { fill: 'transparent' },
              data: {
                fill: (datum) => datum.color
              }
            }}
          />
        </div>
        <ul className="pipe-list">
          {pipeData.map((item, index) => (
            <li key={index}>
              <div className="label-name">
                <span style={{ background: item.color }} />
                {item.x}
              </div>
              <div>{item.y}</div>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}
