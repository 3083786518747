import styled from 'styled-components'

export const MicrobiomeMainContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;

  .qqt-container {
    padding: 36px 40px;
    background-color: white;
  }

  .timeline-container {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
  }

  .no-data {
    text-align: center;
    margin-top: 50px;
    color: #a5abb2;
    font-size: 24px;
  }

  .metrics-container {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-top: 36px;
  }

  .weight-container {
    display: flex;
    flex-direction: column;
    padding: 24px 16px 0px;
    .metric-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
    }
    .basic-weight-info {
      color: #383e48;
      margin-left: 8px;
      .label {
        color: #8596a4;
        font-size: 12px;
        font-weight: 600;
      }
      .l-number {
        font-size: 24px;
        font-weight: 700;
      }
      .l-number-no-data {
        color: #a5abb2;
        font-size: 24px;
      }
      .number-unit {
        font-size: 16px;
      }
      .more-week-avg {
        margin-top: 6px;
        font-size: 12px;
        color: #a5abb2;
      }
      .more-week-avg-value {
        font-size: 16px;
        letter-spacing: 0.3px;
      }
    }
    .weight-chart-container {
      padding-top: 40px;
      .title-view {
        display: flex;
        margin-left: 8px;
        justify-content: space-between;
        font-size: 12px;
        color: #97a0a7;
        .title-view-date {
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
`

export const MicrobiomeChartContainer = styled.div`
  padding: 16px 24px;
  &.Selected {
    background: rgba(38, 67, 130, 0.1);
  }
  &.Previously {
    background: #f5f5f4;
  }
  .header {
    display: flex;
    justify-content: space-between;
    line-height: 29px; /* 207.143% */
    letter-spacing: 0.3px;
    font-size: 12px;
    .name {
      color: #4c6072;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      text-transform: capitalize;
      .tag {
        border-radius: 4px;
        padding: 4px 8px;
        font-size: 10px;
        &.Selected {
          color: #ffffff;
          background: #264382;
        }
        &.Previously {
          color: #264382;
          background: #e2f2ff;
        }
      }
    }
  }
  .optional-message {
    padding: 8px;
    background-color: rgba(244, 245, 249, 1);
    color: rgba(151, 160, 167, 1);
    font-size: 12px;
    border-radius: 4px;
    margin-top: 4px;
  }
  .optimal {
    color: #40c47c;
    border-color: #40c47c;
  }
  .concern,
  .fair {
    color: #f5ba40;
    border-color: #f5ba40;
  }
  .at-risk,
  .poor,
  .risk {
    color: #ff6b00;
    border-color: #ff6b00;
  }
  .status-group {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding-top: 12px;
    margin-bottom: 0;
    font-size: 14px;
    transform: scale(0.7);
    width: 117%;
    li:before {
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 2px solid;
      border-radius: 9px;
      margin-left: 17px;
      margin-right: 5px;
      background: currentColor;
    }
  }
  .chart {
    margin: 0 -20px;
    position: relative;
    padding-left: 20px;
    .chart-label {
      position: absolute;
      font-family: Gilroy-Bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #70808e;
      height: 13px;
      left: 25px;
      top: 50%;
      margin-top: -5px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      .label-text {
        transform: translateX(-50%) rotate(-90deg) scale(0.72);
      }
    }
  }
`

export const BiomeWeightHoverContainer = styled.div`
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background-color: white;
  font-size: 13px;
  color: #a5abb2;
  border-radius: 7px;
  .weight-source-data {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    color: #323f4a;
    .manual {
      border-radius: 4px;
      font-size: 12px;
      color: #8596a4;
      background-color: #f1f3f6;
      padding: 2px 7px;
    }
  }
  .weight-data-container {
    display: flex;
    justify-content: space-between;
    .dot {
      width: 12px;
      display: inline-block;
      height: 12px;
      border-radius: 12px;
      background-color: ${(props) => props.dotColor || '#7bad2d'};
    }
    .date {
      margin-left: 6px;
    }
    .weight-value {
      color: #7bad2d;
    }
    .changes {
      border-radius: 7px;
      margin-left: 6px;
      font-size: 11px;
      padding: 8px 6px;
      background-color: rgba(123, 173, 45, 0.1);

      svg {
        margin-right: 4px;
      }
    }
  }
`
