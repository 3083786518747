import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import {
  createCategoryForm,
  createBiomarker
} from '../../../models/bioMarkers.form'
import Metric from '../categories/metric'

const SubCategory = styled.div`
  min-width: 300px;
  margin: 0 10px;
  border: ${(props) => (props.isDragging ? '1px solid white' : 'none')};
  border-radius: 4px;
  background: #e4f1fd;
`

const TaskList = styled.div`
  min-height: 100px;
  background-color: ${(props) =>
    props.isDraggingOver ? 'rgba(41,127,202,0.5)' : 'transparent'};
`

class Category extends Component {
  static propTypes = {
    category: PropTypes.object
  }

  renderSubcategoryHeader(item) {
    const { setPropsState, deleteCategory } = this.props
    return (
      <h3>
        <Popover
          placement="bottomLeft"
          content={<p style={{ maxWidth: '200px' }}>{item.description}</p>}
        >
          <span>{item.name}</span>
        </Popover>
        {(!item.bio_markers || !item.bio_markers.length) && (
          <CloseOutlined
            onClick={(_) => {
              deleteCategory(item)
            }}
            style={{
              color: 'red',
              cursor: 'pointer',
              margin: '0 5px'
            }} />
        )}
        <EditOutlined
          style={{
            cursor: 'pointer',
            margin: '0 5px'
          }}
          onClick={(_) =>
            setPropsState({
              visible: true,
              addType: 'edit category',
              valueForm: item,
              categoryId: item.id,
              modalTitle: 'Edit category\'s name',
              formData: createCategoryForm
            })
          } />
      </h3>
    )
  }

  renderSubCategoryBody(item) {
    const { category, deleteBiomarker, path, history } = this.props
    return (
      <Droppable
        droppableId={`subCategory-${item.id}-${category.id}`}
        type="metric"
      >
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {item.bio_markers.map((biomarker, listIndex) => (
              <Metric
                key={biomarker.id}
                {...{
                  path,
                  history,
                  metric: biomarker,
                  index: listIndex
                }}
                deleteBiomarker={() => deleteBiomarker(biomarker.id, item.id)}
              />
            ))}
            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    )
  }

  renderSubCategory() {
    const { category, setPropsState } = this.props
    const subCategory = category.sub_categories || []
    return (
      <>
        {subCategory.map((item, index) => (
          <Draggable
            draggableId={`subCategory-${item.id}-${index}`}
            index={index}
            key={index}
          >
            {(provided, snapshot) => (
              <SubCategory
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                isDragging={snapshot.isDragging}
                className="item-inner-list biomarkers"
              >
                {this.renderSubcategoryHeader(item)}
                {this.renderSubCategoryBody(item)}
                <Button
                  type="primary"
                  ghost
                  onClick={() =>
                    setPropsState({
                      visible: true,
                      addType: 'bio_marker',
                      categoryId: item.id,
                      modalTitle: 'Add bio marker',
                      valueForm: {},
                      formData: createBiomarker
                    })
                  }
                >
                  Add Biomarker
                </Button>
              </SubCategory>
            )}
          </Draggable>
        ))}
      </>
    )
  }

  render() {
    const { category } = this.props
    return (
      <Droppable
        droppableId={`category-${category.id}`}
        type="category"
        direction="horizontal"
        style={{ display: 'flex' }}
      >
        {(provided, snapshot) => (
          <div
            className="sub-category"
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              padding: 5,
              background: snapshot.isDraggingOver
                ? 'rgba(41,127,202,0.5)'
                : 'transparent'
            }}
          >
            {this.renderSubCategory()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}

export default Category
