import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import NutritionScoreContainer from '../../../components/nutritionScore'

import {
  getClientList,
  getClientDetail,
  getNutritionScore
} from '../../../models/breadcrumb.model'
import PageLoading from '../../../components/UI/status/loading'

class NutritionScore extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    setClientPageTitle && setClientPageTitle('Nutrition Signals Report')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getNutritionScore(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    return this.state.person ? (
      <NutritionScoreContainer
        person={this.state.person}
        {...this.props}
        loginRole="Expert"
      />
    ) : (
      <PageLoading />
    )
  }
}

export default NutritionScore
