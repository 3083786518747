import React, { useState } from 'react'
import styled from 'styled-components'
import { Modal, Button, Checkbox } from 'antd'
import { ModalStyled } from '../../UI/commonStyled'
import BasicForm from '../../BasicForm'

const { Title, Description, Footer } = ModalStyled

const FormContainer = styled.div`
  .check {
    margin: 20px 0;
    .ant-checkbox-wrapper {
      margin-right: 15px;
    }
  }
  .message-container {
    position: relative;
    textarea.ant-input {
      height: 107px;
    }
    .left-character {
      position: absolute;
      right: 14px;
      top: 78px;
      z-index: 2;
      font-size: 12px;
      color: #8d99a5;
      letter-spacing: 0.3px;
    }
  }
`

export default function NotifyConfirm(props) {
  let basicFormRef
  const [message, setMessage] = useState(null)
  const [showMessageInput, setShowMessageInput] = useState(false)
  const [loading, setloading] = useState(false)
  const handleConfirm = async () => {
    setloading(true)
    if (showMessageInput) {
      try {
        let params = await basicFormRef.handleSubmit()
        await props.onConfirm(params.message)
      } catch (err) {
        setloading(false)
      }
    } else {
      await props.onConfirm()
    }
    setloading(false)
  }
  return (
    <Modal
      visible={true}
      footer={null}
      bodyStyle={{ padding: '36px 45px' }}
      onCancel={props.onCancel}
      width={props.modalWidth}
    >
      <Title>Notify client</Title>
      <Description>Do you want to notify the client?</Description>
      <FormContainer>
        <div className="check">
          <Checkbox
            value={showMessageInput}
            onChange={(e) => setShowMessageInput(e.target.checked)}
          />
          Custom message
        </div>
        {showMessageInput && (
          <div className="message-container">
            <div className="left-character">
              {message ? message.length : 0}/138 character left
            </div>
            <BasicForm
              formData={[
                {
                  type: 'textarea',
                  label: 'message',
                  dbField: 'message',
                  rules: [{ required: true, message: 'Please select date!' }],
                  extraAttr: {
                    maxLength: 138
                  }
                }
              ]}
              onChange={(changedFields, form) => {
                if (changedFields.message) {
                  setMessage(changedFields.message.value)
                }
              }}
              wrappedComponentRef={(inst) => (basicFormRef = inst)}
            />
          </div>
        )}
      </FormContainer>
      <Footer>
        <Button ghost onClick={props.onCancel}>
          {props.cancelText || 'CANCEL'}
        </Button>
        <Button
          type="primary"
          style={props.confirmStyle || {}}
          onClick={handleConfirm}
          disabled={loading} //props.waitingConfirm}
          loading={loading}
        >
          {props.confirmText || 'CONFIRM'}
        </Button>
      </Footer>
    </Modal>
  )
}
