import * as actionType from './action-type'
import initialState from './initial-state'

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.SET_CLIENT_BREADCRUMB:
      return {
        ...state,
        breadcrumb: JSON.stringify({ list: payload.breadcrumb })
      }
    case actionType.SET_CLIENT_PEOPLE:
      return {
        ...state,
        person: payload.person
      }
    case actionType.SET_CLIENT_PAGE_TITLE:
      return {
        ...state,
        pageTitle: payload.pageTitle
      }
    case actionType.SET_CLIENT_BIOMARKER:
      return {
        ...state,
        clientBiomarker: payload.biomarker
      }
    case actionType.SET_CLIENT_BIOMARKER_CATEGORIES:
      return {
        ...state,
        categories: payload.categories
      }
    case actionType.SET_CLIENT_SELECT_CATEGORY:
      return {
        ...state,
        selectCategory: payload.category
      }
    case actionType.SET_CLIENT_REPORT_ID:
      return {
        ...state,
        bloodTestId: payload.bloodTestId
      }
    case actionType.SET_CLIENT_BODY_BOMP_ID:
      return {
        ...state,
        bodyCompId: payload.bodyCompId
      }
    case actionType.SET_CLIENT_LIFE_STYLE_ID:
      return {
        ...state,
        lifeStyleId: payload.lifeStyleId
      }
    case actionType.SET_CLIENT_UNREAD_MESSAGE_COUNT:
      return {
        ...state,
        unreadCount: payload.unreadCount
      }
    case actionType.SET_CLIENT_LET_NAVIGATE_BAR_HIDDEN:
      return {
        ...state,
        leftNavigateBarHidden: payload.hidden
      }
    default:
      return state
  }
}
