import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import {
  getClientList,
  getClientDetail,
  getClientSetting,
  getSettingPassword
} from '../../../../models/breadcrumb.model'
import SettingChangePassword from '../../../profile/settings/change-password'

class ChangePassword extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }

    setClientPageTitle('Change Password')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getClientSetting(person),
        getSettingPassword(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { match, history } = this.props
    return (
      <SettingChangePassword
        loginRole="Expert"
        back={() => history.push(match.url)}
      />
    )
  }
}

export default ChangePassword
