import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import moment from 'moment'
import NutritionScoreWrapper from './wrapper'
import { Button } from 'antd'
import ChartContainer from './comp/chartContainer'
import ScoreSection from './comp/scoreSection'
import Empty from '../UI/status/empty'
// import ConfirmPopUp from '../UI/extraInformation/confirm'
import ScoreDetail from './comp/scoreDetail'
import { containerStyle } from './styles/container.sass'

class NutritionScoreContainer extends Component {
  static propTypes = {}

  state = {
    targetScore: null,
    confirmVisible: false,
    showDetail: false
  }

  linkToQuestionnaire = () => {
    const { latestDate, history, person, loginRole } = this.props
    if (latestDate) {
      if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
        history.push(`/client/${person.id}/detail/questionnaire`)
      } else {
        history.push('/app/questionnaire')
      }
    }
  }

  showDetail = (entry, version) => {
    if (this.props.device === 'mobile') {
      //todo call function fill argument questionid: entry.id
      if (window.webkit) {
        window.webkit.messageHandlers.jsBridgeShowNutritionEntry.postMessage(
          entry
        )
      }
    } else {
      this.setState({
        showDetail: true,
        questionId: entry.id || entry.subject,
        sectionVersion: version,
        questionOriginalId: entry.original_id
      })
    }
  }

  render() {
    const {
      score,
      // newSession,
      person,
      hideDescription,
      device,
      hash
    } = this.props
    const {
      // confirmVisible,
      targetScore,
      showDetail,
      questionId,
      questionOriginalId,
      sectionVersion
    } = this.state
    const isEmpty =
      score.length === 0 ||
      (score.length === 1 &&
        !score[0].answers.find((answer) => answer.percentage_score))

    return (
      <div className={containerStyle}>
        {!hideDescription && (
          <div className="description">
            Your nutrition is unique and personal to you and we’ve quantified
            it! Based on your unique goals, bloodwork, wearable data, nutrition
            signals and other assessment results, we’ve tailored your nutrition
            recommendations below.
          </div>
        )}

        {isEmpty ? (
          <>
            <Empty
              message={
                <span sytle={{ fontFamily: 'Heebo' }}>
                  Your nutrition signals will appear here when you <br />{' '}
                  complete the{' '}
                  <b style={{ color: '#383E48', fontWeight: 700 }}>
                    Questionnaire
                  </b>
                  .
                  <br />
                  {device !== 'mobile' && (
                    <Button
                      className="send-btn"
                      type="primary"
                      style={{ float: 'none', marginTop: 35 }}
                      onClick={this.linkToQuestionnaire}
                    >
                      complete Questionnaire
                    </Button>
                  )}
                </span>
              }
            />
          </>
        ) : (
          <>
            <div className="nutrition-score-container">
              <ChartContainer
                {...this.props}
                defaultTargetIndex={8}
                targetScore={targetScore}
                onChange={(targetScore) => this.setState({ targetScore })}
              />
            </div>
            <div className="section-container">
              <ScoreSection
                {...this.props}
                targetScore={targetScore}
                showDetail={this.showDetail}
              />
            </div>
          </>
        )}

        {/* <ConfirmPopUp
          {...{
            visible: confirmVisible,
            handleCancel: () => this.setState({ confirmVisible: false }),
            handleOk: async () => {
              await newSession()
              this.setState({ confirmVisible: false })
            },
            title: 'Send questionnaire confirmation',
            content: (
              <>
                Are you sure to send nutrition questionnaire to{' '}
                <strong>{person.email}</strong>?
                {latestDate ? (
                  <span>
                    The last questionnaire was taken on{' '}
                    <strong>{moment(latestDate).format('MMM DD, YYYY')}</strong>
                    .
                  </span>
                ) : (
                  ''
                )}
              </>
            ),
            okText: 'SEND NOW'
          }}
        /> */}

        {showDetail && (
          <ScoreDetail
            onCancel={() => this.setState({ showDetail: false })}
            {...{
              device,
              hash,
              person,
              questionId,
              questionOriginalId,
              sectionVersion
            }}
          />
        )}
      </div>
    )
  }
}

export default NutritionScoreWrapper(NutritionScoreContainer)
