/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Input, Select } from 'antd'
import { usePlacesWidget } from 'react-google-autocomplete'
import { states } from '../introductory/survey.json'

const APP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

export default function SearchMap(props) {
  const { item, update, an } = props
  const [isCompleted, setCompleted] = useState(false)
  const [answer, setAnswer] = useState({})
  const mapInformation = _.cloneDeep(item.map_information)
  const triggerInfo = _.find(mapInformation, (info) => info.trigger_key)
  _.pull(mapInformation, triggerInfo)

  useEffect(() => {
    an && setAnswer(an)
    setCompleted(an && an.required ? !an.required : !!an)
  }, [an])

  const verify = (answerObj) => {
    _.forIn([triggerInfo, ...mapInformation], (info) => {
      if (info.required && !answerObj[info.key]) {
        answerObj[info.key] = { required: true }
      }
    })
    setAnswer(_.cloneDeep(answerObj))
    update(answerObj, props.question)
  }

  const { ref } = usePlacesWidget({
    apiKey: APP_KEY,
    onPlaceSelected: (place) => {
      console.log(place)
      setCompleted(true)
      const {
        address_components,
        place_id,
        formatted_address,
        geometry,
        html_attributions
      } = place
      _.forIn(mapInformation, (info) => {
        if (info.type) {
          answer[info.key] = _.get(
            _.find(address_components, (com) =>
              _.includes(com.types, info.type)
            ),
            'short_name'
          )
        }
      })
      if (triggerInfo) {
        answer[triggerInfo.key] = ref.current.value
      }
      _.assign(answer, {
        address_components,
        place_id,
        formatted_address,
        geometry,
        html_attributions
      })
      verify(answer)
    },
    options: {
      types: ['address'],
      // Case insensitive, country code list: https://www.iban.com/country-codes
      componentRestrictions: { country: ['us', 'ca'] }
    }
  })

  const onChange = (value, key) => {
    _.unset(answer, 'required')
    answer[key] = value
    verify(answer)
  }
  return (
    <>
      <div className="additional-item" key="trigger-item">
        <input
          ref={ref}
          className={` ant-input q-form-item ${
            (answer && answer.required) ||
            (triggerInfo &&
              answer[triggerInfo.key] &&
              answer[triggerInfo.key].required)
              ? 'invalid'
              : ''
          }`}
          type="text"
          placeholder="Please type here"
          defaultValue={
            triggerInfo && _.isString(answer[triggerInfo.key])
              ? answer[triggerInfo.key]
              : ''
          }
          onChange={(e) => {
            onChange(e.target.value, triggerInfo.key)
          }}
          onBlur={() => setCompleted(true)}
        />
        {answer && answer.required && (
          <p className="invalid">{answer.message || 'Required'}</p>
        )}
        {triggerInfo &&
          answer[triggerInfo.key] &&
          answer[triggerInfo.key].required && (
            <p className="invalid">
              {answer[triggerInfo.key].message || 'Required'}
            </p>
          )}
      </div>
      {isCompleted &&
        mapInformation.map((info, index) => {
          return (
            <div key={`map_additional_${index} `} className="additional-item">
              {info.has_list ? (
                <Select
                  placeholder={'Please select'}
                  className={`q-form-item ${
                    answer[info.key] && answer[info.key].required
                      ? 'invalid'
                      : ''
                  }`}
                  onChange={(value) => onChange(value, info.key)}
                  value={
                    _.isString(answer[info.key]) ? answer[info.key] : undefined
                  }
                >
                  {states.map(({ postal }, _index) => (
                    <Select.Option value={postal} key={_index}>
                      {postal}
                    </Select.Option>
                  ))}
                </Select>
              ) : (
                <Input
                  placeholder={info.title || 'Please type here'}
                  className={`q-form-item ${
                    answer[info.key] && answer[info.key].required
                      ? 'invalid'
                      : ''
                  }`}
                  defaultValue={info.defaultValue || ''}
                  onChange={(e) => onChange(e.target.value, info.key)}
                  value={
                    _.isString(answer[info.key])
                      ? answer[info.key]
                      : info.forceValue || ''
                  }
                  disabled={info.disabled}
                />
              )}
              {answer[info.key] && answer[info.key].required && (
                <p className="invalid">
                  {answer[info.key].message || 'Required'}
                </p>
              )}
            </div>
          )
        })}
    </>
  )
}
