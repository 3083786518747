import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { Modal, Button, Progress, message, Upload, Typography } from 'antd'
import IconUpload from '../../../asserts/icon/icon-file-upload.svg'
import BasicForm from '../../formV4'
import filesize from '../../../utils/filesize'
import { uploadModalStyle } from './uploadModal.sass'
import { upload } from '../../../api/file'
import IconCheck from '../../../asserts/icon/c-check.svg'
import FileMask from '../../../asserts/icon/client/Mask.svg'
import { getSum } from '../../../utils/common'

const { Paragraph } = Typography

export class FileUploadModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    close: PropTypes.func,
    type: PropTypes.string,
    personId: PropTypes.number,
    addFileSync: PropTypes.func,
    onlyFile: PropTypes.bool,
    datasetVersions: PropTypes.array
  }

  state = {
    status: 'normal',
    percent: 0,
    source: null,
    fileRequired: false,
    fileObj: null,
    showConfirmModal: false,
    showSelectConfirmModal: false
  }

  timeStamp = null

  customRequest = (request) => {
    const { type } = this.props
    if (type === 'body_comp') {
      let { tempRequest } = this.state
      if (_.isArray(tempRequest)) {
        tempRequest.push(request)
      } else {
        tempRequest = [request]
      }
      this.setState({ showSelectConfirmModal: true, tempRequest })
    } else {
      this.processRequest(request)
    }
  }

  processRequest = (request) => {
    let fileObj
    if (_.isArray(request)) {
      fileObj = []
      for (const item of request) {
        const { file } = item
        const { name, size } = file
        const fileType = name.split('.').pop()
        fileObj.push({
          file,
          name,
          size,
          type: fileType
        })
      }
    } else {
      const { file } = request
      const { name, size } = file
      const fileType = name.split('.').pop()
      fileObj = { file, name, size, type: fileType }
    }

    this.setState({
      fileRequired: false,
      fileObj,
      tempRequest: null,
      showSelectConfirmModal: false
    })
  }

  confirm = async () => {
    const { fileObj } = this.state
    const { type } = this.props

    if (!fileObj) {
      return this.setState({ fileRequired: true })
    } else if (fileObj.length > 2) {
      return
    }
    try {
      await this.basicFormRef.handleSubmit()
      if (type === 'bio_marker' || type === 'body_comp') {
        this.setState({ showConfirmModal: true })
      } else {
        this.upload()
      }
    } catch (err) {
      console.error(err)
    }
  }

  upload = async (isImport) => {
    const { fileObj } = this.state
    this.setState({ showConfirmModal: false })
    try {
      let { test_date } = await this.basicFormRef.handleSubmit()
      test_date = moment(test_date).format('YYYY-MM-DD')
      const { type, personId, addFileSync } = this.props
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ status: 'active', source })
      let files = []
      if (_.isArray(fileObj)) {
        for (const obj of fileObj) {
          files.push(obj.file)
        }
      } else {
        files.push(fileObj.file)
      }
      const result = await upload({
        type,
        personId,
        test_date,
        files,
        isImport: isImport === true,
        source,
        onBeforeUploadCallback: () => {
          this.timeStamp = Date.now()
          this.prevProgress = 0
        },
        progressCallback: (process) => {
          const percent = process.loaded / process.total
          const time = Date.now() - this.timeStamp
          const size = _.isArray(fileObj)
            ? getSum(fileObj.map((item) => item.file.size))
            : fileObj.file.size
          const [chunk, unit] = filesize(percent * size, {
            round: 1
          }).split(' ')
          const speed = (chunk / (time / 1000)).toFixed(2)
          const speedUnit = `${unit}/sec`
          this.timeStamp = Date.now()
          this.prevProgress = process
          this.setState({
            percent: (percent * 100).toFixed(2),
            speed,
            speedUnit
          })
        }
      })
      this.setState({ status: 'success' })
      addFileSync(result.files || result.file)
    } catch (err) {
      if (err.files || err.test_date) {
        return
      } else if (!axios.isCancel(err)) {
        console.error(err)
        message.error(err.message)
        this.setState({ status: 'exception', errMessage: err.message })
      }
    }
  }
  cancel = () => {
    const { source, status } = this.state
    const { close } = this.props
    if (status === 'active') source.cancel()
    close()
  }

  exitDataset = () => {
    const { datasetVersions } = this.props
    const { test_date } = this.state
    return (
      datasetVersions &&
      datasetVersions.find(
        (item) =>
          moment(item).format('YYYY-MM-DD') ===
          moment(test_date).format('YYYY-MM-DD')
      )
    )
  }

  render() {
    const { close, type, firstName, lastName } = this.props
    const {
      status,
      percent,
      speed,
      speedUnit,
      errMessage,
      fileObj,
      fileRequired,
      showConfirmModal,
      tempRequest,
      showSelectConfirmModal
    } = this.state

    return (
      <div className="uplaod-modal">
        <Modal
          className={uploadModalStyle}
          width={600}
          maskClosable={false}
          visible={true}
          footer={
            <div className="upload-footer">
              {(status === 'normal' ||
                status === 'active' ||
                status === 'exception') && (
                <Button type="link" onClick={this.cancel}>
                  CANCEL
                </Button>
              )}
              {status === 'normal' && (
                <Button type="primary" onClick={() => this.confirm()}>
                  <img src={IconUpload} alt="ipload" />
                  UPLOAD FILE
                </Button>
              )}
              {status === 'success' && (
                <Button type="primary" ghost onClick={() => close()}>
                  DONE
                </Button>
              )}
            </div>
          }
          onCancel={this.cancel}
        >
          {/* <div className="modal-container"> */}
          <h1 className="title-center">File Uploading</h1>
          {status === 'normal' && (
            <>
              <Upload
                showUploadList={false}
                customRequest={this.customRequest}
                disabled={status === 'active' || status === 'success'}
                multiple={type === 'body_comp'}
              >
                {_.isArray(fileObj) ? (
                  <div
                    className={`upload-container ${fileObj ? '' : 'empty'} ${
                      fileRequired || fileObj.length > 2 ? 'has-error' : ''
                    }`}
                  >
                    <div style={{ width: '80%' }}>
                      {fileObj.map((item, index) => (
                        <div
                          className="name"
                          key={index}
                          style={{ width: '100%' }}
                        >
                          <img src={FileMask} alt="mask" />
                          <div>
                            <Paragraph ellipsis>{item.name}</Paragraph>
                            <span>
                              {item.type} • {filesize(item.size, { round: 1 })}
                            </span>
                          </div>
                        </div>
                      ))}

                      <span className="speed">
                        {percent < 100 && speed
                          ? `${speed} ${speedUnit} - `
                          : ''}
                        {percent > 0 && `${percent}%`}
                      </span>
                    </div>
                    {!(status === 'active' || status === 'success') && (
                      <div className="opr">Change file</div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`upload-container ${fileObj ? '' : 'empty'} ${
                      fileRequired ? 'has-error' : ''
                    }`}
                  >
                    {fileObj ? (
                      <>
                        <div className="name">
                          <img src={FileMask} alt="mask" />
                          <div>
                            <Paragraph ellipsis>{fileObj.name}</Paragraph>
                            <span>
                              {fileObj.type} •{' '}
                              {filesize(fileObj.size, { round: 1 })}
                            </span>
                            <span className="speed">
                              {percent < 100 && speed
                                ? `${speed} ${speedUnit} - `
                                : ''}
                              {percent > 0 && `${percent}%`}
                            </span>
                          </div>
                        </div>
                        {!(status === 'active' || status === 'success') && (
                          <div className="opr">Change file</div>
                        )}
                      </>
                    ) : (
                      <div className="opr select">Select file to upload</div>
                    )}
                  </div>
                )}

                {percent > 0 && (
                  <Progress
                    percent={percent}
                    status={status}
                    showInfo={false}
                  />
                )}
                {fileRequired && (
                  <div className="file-required">Please select a file</div>
                )}
                {fileObj && fileObj.length > 2 && (
                  <div className="file-required">
                    You are only allowed to upload a maximum of 2 files
                  </div>
                )}
              </Upload>
              <BasicForm
                formData={[
                  {
                    type: 'date',
                    label: 'test date',
                    dbField: 'test_date',
                    format: 'MMM DD, YYYY',
                    disabledDate: (currentDate) =>
                      currentDate.isAfter(moment()),
                    rules: [{ required: true, message: 'Please select date!' }]
                  }
                ]}
                onChange={(changedFields, allValues) => {
                  this.setState({ test_date: allValues.test_date })
                }}
                wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
              />
            </>
          )}
          {(status === 'active' ||
            status === 'success' ||
            status === 'exception') && (
            <div className="upload-progress">
              <div>
                {status === 'active' && (
                  <span className="active">
                    uploading... {parseInt(percent)}%
                  </span>
                )}
                {status === 'success' && (
                  <span className="success">
                    <img
                      src={IconCheck}
                      alt="check"
                      style={{ marginRight: '10px' }}
                    />
                    COMPLETED
                  </span>
                )}
                {status === 'exception' && (
                  <span className="active">{errMessage}</span>
                )}
              </div>
              <Progress percent={percent} status={status} showInfo={false} />
            </div>
          )}
          {/* </div> */}
        </Modal>
        <Modal
          className={uploadModalStyle}
          visible={showConfirmModal}
          onCancel={() => this.setState({ showConfirmModal: false })}
          footer={
            <div className="upload-footer ">
              {fileObj && isDataCanBeImport(fileObj) ? (
                <>
                  <Button type="link" onClick={this.upload}>
                    UPLOAD ONLY
                  </Button>
                  <Button type="primary" onClick={() => this.upload(true)}>
                    {this.exitDataset() ? 'IMPORT AND REPLACE' : 'IMPORT DATA'}
                  </Button>
                </>
              ) : (
                <>
                  <Button type="link" onClick={this.cancel}>
                    NO
                  </Button>
                  <Button type="primary" onClick={this.upload}>
                    UPLOAD
                  </Button>
                </>
              )}
            </div>
          }
        >
          {/* <div className="modal-container"> */}
          <h1 className="title-normal">
            {fileObj && isDataCanBeImport(fileObj)
              ? `Import ${
                  type === 'body_comp' ? 'body composition' : 'blood test'
                } data confirmation`
              : 'Upload file confirmation'}
          </h1>

          {fileObj && isDataCanBeImport(fileObj) ? (
            <div className="noti-container">
              {this.exitDataset()
                ? 'This dataset already exit. Do you want to import and replace ?'
                : 'Do you want to import this data into the platform?'}
            </div>
          ) : (
            <div>
              Do you want to add file to the platform? <br />
              Its data cannot be imported.
            </div>
          )}
          {/* </div> */}
        </Modal>

        <Modal
          className={uploadModalStyle}
          visible={showSelectConfirmModal}
          onCancel={() => this.setState({ showSelectConfirmModal: false })}
          footer={
            <div className="upload-footer ">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showSelectConfirmModal: false,
                    tempRequest: null
                  })
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.processRequest(tempRequest)
                }}
              >
                Confirm
              </Button>
            </div>
          }
        >
          <h1 className="title-normal">Client confirmation</h1>
          <div>
            {`You are about to upload body composition information for client ${firstName} ${lastName}`}
            <br />
            Are you sure?
          </div>
        </Modal>
      </div>
    )
  }
}

export default FileUploadModal

function isDataCanBeImport(fileObj) {
  return _.isArray(fileObj)
    ? 'csv,csv'.includes(fileObj.map((obj) => obj.type).join(','))
    : fileObj.type === 'csv'
}
