import React, { useState, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Switch } from 'antd'
import { Link, Element, Events, scrollSpy } from 'react-scroll'
import DateGroupPicker from '../UI/dateGroupPicker'
import ChartContainer from './comp/chartContainer'
import { getQQTDetails, getScore } from '../../api/nutrition'
import GraphCard from './comp/qqtGraphCard'
import PageLoading from '../UI/status/loading'
import GeneralChart from './comp/generalChart'
import TimingChart from './comp/timingChart'
import HSDSkipHistory from '../client/comp/hsdSkipHistory'
import { Pluralize } from '../../utils'

const Container = styled.div`
  .description {
    width: 780px;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.3px;
    color: #52606c;
    margin-top: -20px;
  }
  .timeline-container {
    background: darkblue;
    margin-top: 35px;
    border-radius: 12px 12px 0 0;
    overflow: hidden;
  }
  .qqt-container {
    background: #ffffff;
    padding: 36px 40px;
    border-radius: 2px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .switch-all-data {
        font-size: 12px;
        letter-spacing: 0.3px;
        text-transform: capitalize;
        font-weight: 600;
        .ant-switch {
          margin-left: 14px;
        }
      }
    }
    .no-data {
      text-align: center;
      margin-top: 50px;
      color: #a5abb2;
      font-size: 24px;
    }
    .qqt {
      margin-top: 36px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      display: flex;
      .graph {
        border-right: 1px solid #e5e5e5;
        width: 35%;
        padding: 24px;
        position: sticky;
        top: 0;
        height: 90vh;
      }
    }
  }
`

const dateRanges = [
  {
    name: 'Month',
    barWidth: 8,
    picker: 'month',
    formatX: 'MMM/D'
  },
  {
    name: 'Quarterly',
    barWidth: 12,
    picker: 'quarter',
    type: 'quarterly',
    formatX: 'MM/DD'
  },
  {
    name: 'Annual',
    barWidth: 20,
    type: 'annual',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Lifetime',
    barWidth: 8,
    picker: 'date',
    type: 'lifetime'
    // formatX: 'M/D/YY'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
    // formatX: ''
  }
]

export default function NutritionScoreExpertView(props) {
  const { person } = props
  const [loading, setLoading] = useState(true)
  const [pageLoading, setPageLoading] = useState(true)
  const [showAllData, setShowAllData] = useState(false)
  const [activeGraphCard, setActiveGraphCard] = useState('quantity')
  const [state, dispatch] = useReducer(reducer, {
    lastProcessAnswer: null,
    answerDetails: [],
    notFocusActivityFeedRecord: []
  })

  useEffect(() => {
    const initial = async () => {
      const year = new Date().getFullYear()
      const from = `${year}-01-01`,
        to = `${year}-12-31`
      const {
        lastProcessAnswer,
        answerDetails,
        not_focus_activity_feed_record
      } = await getQQTDetails(person.id)
      let score = await getScore(person.id)
      score = score.reverse()
      // const latestDate = score[0].completion_date || score[0].date
      // const latestVersionDate = score[0].date
      score = score.filter((item) => {
        return item.is_complete
      })

      const lastTimingAnswer =
        lastProcessAnswer &&
        score.find((item) => item.date === lastProcessAnswer.date)

      dispatch({
        type: 'initial',
        payload: {
          lastProcessAnswer,
          answerDetails,
          from,
          to,
          lastTimingAnswer,
          score: score.length > 8 ? score.slice(0, 8) : score,
          notFocusActivityFeedRecord: not_focus_activity_feed_record
          //
          // latestDate,
          // latestVersionDate
        }
      })
    }

    initial()
  }, [])

  useEffect(() => {
    Events.scrollEvent.register('begin', (to, element) => {
      setActiveGraphCard(to)
    })

    scrollSpy.update()
    window.addEventListener('scroll', onScroll)

    return () => {
      Events.scrollEvent.remove('begin')
      Events.scrollEvent.remove('end')
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const { from, to } = state
  return !pageLoading ? (
    <Container>
      <div className="description">
        Your nutrition is unique to you and we’ve quantified it below. Your
        Score measures the quantity, quality, and timing of your food habits.
      </div>
      <div className="timeline-container">
        <ChartContainer
          defaultTargetIndex={8}
          {...state}
          hiddenSummary={true}
          person={person}
        />
      </div>

      <div className="qqt-container">
        <div className="header">
          <DateGroupPicker
            dateRanges={dateRanges}
            fetchData={fetchData}
            defaultSelectedDate={3}
            from={from}
            to={to}
          />
          <div className="switch-all-data">
            See All Data Points{' '}
            <Switch
              size="small"
              checked={showAllData}
              onChange={onSwitchChange}
            />
          </div>
        </div>
        {loading ? (
          <PageLoading />
        ) : !state.lastProcessAnswer ? (
          <div className="no-data">No Data</div>
        ) : (
          <div className="qqt">
            <div className="graph">
              {state.lastProcessAnswer.answers.map((answer, index) => (
                <Link
                  key={index}
                  activeClass="active"
                  to={answer.type}
                  // smooth={true}
                  duration={500}
                  onSetActive={() => {
                    setActiveGraphCard(answer.type)
                  }}
                >
                  <GraphCard
                    {...answer}
                    isActive={activeGraphCard === answer.type}
                  />
                </Link>
              ))}
            </div>
            <ChartView
              {...state}
              onScroll={onScroll}
              showAllData={showAllData}
            />
          </div>
        )}
      </div>
    </Container>
  ) : (
    <PageLoading />
  )

  function reducer(state, action) {
    if (loading) {
      setLoading(false)
    }
    if (action.type === 'initial') {
      setPageLoading(false)
      return { ...action.payload }
    } else if (action.payload) {
      return _.assign({}, state, action.payload)
    }

    return state
  }

  async function onSwitchChange(checked) {
    // setLoading(true)
    setShowAllData(checked)
    // let result
    // if (checked) {
    //   result = await getQQTDetails(person.id)
    // } else {
    //   result = await getQQTDetails(person.id, from, to)
    // }
    // const { lastProcessAnswer, answerDetails } = result
    // dispatch({
    //   type: 'update',
    //   payload: { lastProcessAnswer, answerDetails, from, to }
    // })
  }

  async function fetchData(from, to) {
    setLoading(true)
    setShowAllData(false)
    const {
      lastProcessAnswer,
      answerDetails,
      not_focus_activity_feed_record
    } = await getQQTDetails(person.id, from, to)
    dispatch({
      type: 'update',
      payload: {
        lastProcessAnswer,
        answerDetails,
        from,
        to,
        notFocusActivityFeedRecord: not_focus_activity_feed_record
      }
    })
    setLoading(false)
  }

  function onScroll(e) {
    const quality = e.target.querySelector('.quality'),
      timing = e.target.querySelector('.timing')
    const screenHeight = window.screen.height
    if (timing && timing.offsetTop - window.scrollY < screenHeight) {
      setActiveGraphCard('timing')
    } else if (quality && quality.offsetTop - window.scrollY < screenHeight) {
      setActiveGraphCard('quality')
    } else {
      setActiveGraphCard('quantity')
    }
  }
}

const GeneralChartContainer = styled.div`
  width: 70%;
  .title {
    padding: 12px 24px;
    background: #fafafa;
    color: #a5abb2;
    font-size: 12px;
    text-transform: uppercase;
  }
  .skiped-container-header {
    background-color: #f1f3f6;
    font-size: 14px;
    font-weight: 600;
    color: #8596a4;
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
  }
`

function ChartView(props) {
  let {
    answerDetails: { quality, quantity, timing },
    lastTimingAnswer,
    onScroll,
    showAllData,
    notFocusActivityFeedRecord
  } = props
  const _timing = lastTimingAnswer.answers.find(
    (item) => item.type === 'timing'
  )
  const entries = _timing.entries
  if (!showAllData) {
    quality = quality.filter((item) => item.selectTag)
    quantity = quantity.filter((item) => item.selectTag)
    timing = timing.filter((item) => item.selectTag)
  }

  return (
    <GeneralChartContainer onScroll={onScroll}>
      {notFocusActivityFeedRecord.length > 0 && (
        <div>
          <div className="skiped-container-header">
            <span>Client selected not to focus on Nutrition</span>
            <span>
              Total: {notFocusActivityFeedRecord.length}{' '}
              {Pluralize('time', notFocusActivityFeedRecord.length)}
            </span>
          </div>
          <HSDSkipHistory
            notFocusActivityFeedRecord={notFocusActivityFeedRecord}
          />
        </div>
      )}
      <Element name="quantity">
        <div className="title quantity">quantity</div>
        {quantity.map((item, index) => (
          <GeneralChart key={index} {...item} />
        ))}
      </Element>
      <Element name="quality">
        <div className="title quality">quality</div>
        {quality.map((item, index) => (
          <GeneralChart key={index} {...item} />
        ))}
      </Element>
      <Element name="timing">
        <div className="title timing">timing</div>
        <TimingChart
          entries={entries}
          version={lastTimingAnswer.section_version || 3}
        />
        {timing
          .filter((item) => !item.subject || item.subject !== 'Eating Window')
          .map((item, index) => (
            <GeneralChart key={index} {...item} />
          ))}
      </Element>
    </GeneralChartContainer>
  )
}
