import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Resource from './resource'
import ResourceCategory from './category'
import PageLoading from '../../../components/UI/status/loading'
import LockStatus from '../../../components/UI/lockStatus'
import { getRecommendedContent } from '../../../api/recommend'
import { loadUser } from '../../../utils/storage'
import { userType } from '../../../utils/constant'

class ResourcePage extends Component {
  static propTypes = {}

  state = { loading: true, list: [] }

  componentDidMount() {
    const user = loadUser()
    const isNonCore = user.profile.account_type === userType.Non_Core
    if (isNonCore) {
      this.setState({ isNonCore })
    } else {
      this.fatchData()
    }
  }

  fatchData = async () => {
    const { rows } = await getRecommendedContent()
    this.setState({
      loading: false,
      list: rows
    })
  }

  render() {
    const { match } = this.props
    const { isNonCore } = this.state

    if (isNonCore) {
      return (
        <>
          <h1 className="title">Resources</h1>
          <LockStatus
            type="NON-CORE"
            description={
              <div className="description">
                Find answers to your wellness and performance questions, along
                with tips from our experts.
              </div>
            }
          />
        </>
      )
    }
    return (
      <div>
        {this.state.loading ? (
          <PageLoading />
        ) : (
          <Switch>
            <Route
              path={`${match.url}/:category`}
              render={(props) => (
                <ResourceCategory {...props} {...this.state} />
              )}
            />
            <Route
              path={`${match.url}`}
              exact
              render={(props) => <Resource {...props} {...this.state} />}
            />
          </Switch>
        )}
      </div>
    )
  }
}

export default ResourcePage
