import React from 'react'
import styled from 'styled-components'
import successImage from '../../../asserts/images/success.png'
import { ReactComponent as FileIcon } from '../../../asserts/icon/icon-onboarding-record-file.svg'
import { RightOutlined } from '@ant-design/icons'

const Container = styled.div`
  width: 431px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #52606c;
  max-height: calc(100% - 148px);
  overflow: auto;
  .title {
    color: #323f4a;
    font-size: 36px;
    line-height: 1.5;
  }
  img {
    margin: 46px;
  }
  .desc {
    white-space: pre-wrap;
    line-height: 140%;
    letter-spacing: 0.3px;
  }
  .destination-view {
    background-color: white;
    border-radius: 12px;
    padding: 17px 24px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    cursor: pointer;
  }
`

/*
  destination should be: 
  {
    path: "/xxx/xxxxx"
    name: "xxxxxx",
    inAppFunc: () => {}
  }
*/
export default function RecordCompletedPage(props) {
  const { history, destinations } = props //history

  return (
    <Container>
      <div className="title">Success</div>

      <img src={successImage} alt="success" />

      <p className="desc">
        Thank you for completing the questionnaire. You can now review your
        personalized result below.
      </p>
      {destinations.map((d) => {
        return (
          <div
            className="destination-view"
            onClick={() => {
              if (navigator.userAgent.includes('life.apeiron.ios')) {
                d.inAppFunc()
              } else {
                history.push(d.path)
              }
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <FileIcon />
              <span style={{ marginLeft: 12 }}>{d.name}</span>
            </div>
            <RightOutlined />
          </div>
        )
      })}
    </Container>
  )
}
