import React, { useState, useEffect } from 'react'
import QuestionResultContainer from '../../../components/questionnaire/resultContainer'
import {
  getClientList,
  getClientDetail,
  getQuestionnaire,
  getQuestionnaireScore
} from '../../../models/breadcrumb.model'

export default function QuestionScore(props) {
  const [person, setPerson] = useState(null)

  useEffect(() => {
    const { setClientPageTitle, setClientBreadcrumb, getPerson } = props
    setClientPageTitle('Questionnaire Score')
    const initial = async () => {
      const person = await getPerson()
      setPerson(person)
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getQuestionnaire(person.id),
        getQuestionnaireScore(person.id, true)
      ])
    }
    initial()
  }, [])
  return person ? (
    <QuestionResultContainer personId={person.id} history={props.history} />
  ) : null
}
