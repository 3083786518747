import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Tribute from 'tributejs'
import { Upload, Button, Tag } from 'antd'
import { FileOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons'
import { ImageIcon, AttachIcon } from '../icons/weeklySummary'
import { getAllExperts } from '../../api/team'
import { uploadPublicFile } from '../../api/file'
import { upload } from '../../api/file' //deleteFile
import filesize from '../../utils/filesize'
import { workoutPillarColors } from '../../utils/constant'
import './editor.sass'

const { CheckableTag } = Tag

const EditorContainer = styled.div`
  position: relative;
  padding-bottom: 10px;
  img {
    max-width: 100%;
  }
  .ant-input {
    padding: 12px 16px;
  }
  .note-operate {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8ecf0;
    &.no-line {
      border-bottom: none;
    }
    & > div:first-child {
      display: flex;
    }
    .ant-btn {
      margin-left: 15px;
      position: relative;
      z-index: 1;
    }
    .anticon {
      color: #264382;
      margin-right: 15px;
      vertical-align: sub;
    }
    .upload-icon {
      svg {
        height: 18px;
        width: 18px;
      }
    }
    .ant-tag {
      border-radius: 4px;
      color: #264382;
      &.ant-tag-checkable-checked {
        &.Exercise {
          background-color: ${workoutPillarColors.exercise.light};
          color: ${workoutPillarColors.exercise.primary};
        }
        &.Nutrition {
          background-color: ${workoutPillarColors.nutrition.light};
          color: ${workoutPillarColors.nutrition.primary};
        }
        &.Sleep {
          background-color: ${workoutPillarColors.sleep.light};
          color: ${workoutPillarColors.sleep.primary};
        }
        &.Medical {
          background-color: #d9def2;
          color: #223064;
        }
        &.Sales {
          background-color: rgba(255, 165, 0, 0.15);
          color: orange;
        }
      }
    }
    &:hover {
      color: #40a9ff;
      cursor: pointer;
    }
    &.disabled {
      color: rgba(0, 0, 0, 0.25);
      cursor: not-allowed;
    }
  }
  .editor-input {
    border: 1px solid #dee2ec;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 14px;
    color: #60656d;
    white-space: pre-wrap;
    &[placeholder]:empty:before {
      content: attr(placeholder);
      color: #323f4a;
      opacity: 0.5;
    }
    .at-item {
      color: #264382;
      font-weight: 500;
    }
  }
  .file-list {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    .file-item {
      margin-right: 10px;
      margin-bottom: 10px;
      align-items: center;
      min-width: 212px;
      padding: 8px 10px;
      display: flex;
      justify-content: space-between;
      background: rgba(38, 67, 130, 0.05);
      color: #264382;
      border-radius: 4px;
      span {
        color: #60656d;
      }
      .name {
        margin: 0 15px;
      }
    }
  }
`

const tagsData = ['Exercise', 'Nutrition', 'Sleep', 'Medical', 'Sales']

export default function Editor(props) {
  const { submitNote, person, experts, showInModal, initialData } = props

  const { status, files, pillars, text, created_person, pid } = _.get(
    props,
    'initialData',
    {
      pillars: [],
      files: [],
      text: '',
      pid: 0
    }
  )
  const initialText =
    status === 'reply'
      ? `<span person-id="${created_person.id}" class="at-item">@${created_person.full_name}</span> <span>&nbsp;</span>`
      : text

  const [isEditing, setIsEditing] = useState(false)
  const [selectedTags, setSelectedTags] = useState(
    status !== 'reply' ? pillars : []
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [preUpload, setPreUpload] = useState(
    status !== 'reply' ? _.cloneDeep(files) : []
  )
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [hasImageUploading, setHasImageUploading] = useState(false)
  const editorRef = useRef(null)
  useEffect(() => {
    initial()
  }, [])

  async function initial() {
    if (experts) {
      renderEditor(experts)
    } else {
      const { clients } = await getAllExperts()
      renderEditor(clients)
    }
    setIsEditing(true)
  }

  const handleTagsChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag)
    setSelectedTags(nextSelectedTags)
  }

  return (
    <EditorContainer>
      <div
        onPaste={onTextPaste}
        ref={editorRef}
        contentEditable={isEditing}
        onKeyUp={() => {
          const disabledSubmit = !(
            editorRef.current && editorRef.current.innerHTML
          )
          setDisabledSubmit(disabledSubmit)
        }}
        className="editor-input"
        placeholder="Leave a note"
        dangerouslySetInnerHTML={{
          __html: initialText
        }}
      ></div>
      {preUpload.length > 0 && (
        <div className="file-list">
          {preUpload.map((file, index) => (
            <div className="file-item" key={index}>
              <FileOutlined />
              <div className="name">
                {file.name}
                <span>({filesize(Number(file.size), { round: 1 })})</span>
              </div>
              <span>
                {file.loading ? (
                  <LoadingOutlined />
                ) : (
                  <CloseOutlined onClick={() => removeFile(file, index)} />
                )}
              </span>
            </div>
          ))}
        </div>
      )}
      <div className={`note-operate ${showInModal ? 'no-line' : ''}`}>
        <div>
          {status !== 'reply' && pid === 0 && (
            <>
              <Upload
                className="upload-icon"
                action={(file) => beforeUpload(file)}
                showUploadList={false}
              >
                <AttachIcon />
              </Upload>

              <Upload
                showUploadList={false}
                action={(file) => importImage(file)}
                accept="image/*"
              >
                <ImageIcon />
              </Upload>

              <div>
                {tagsData.map((tag) => (
                  <CheckableTag
                    key={tag}
                    className={tag}
                    checked={selectedTags.indexOf(tag) > -1}
                    onChange={(checked) => handleTagsChange(tag, checked)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </div>
            </>
          )}
        </div>

        <div>
          {!showInModal && (
            <Button
              type="primary"
              disabled={
                (disabledSubmit && preUpload.length === 0) ||
                isSubmitting ||
                hasImageUploading
              }
              loading={isSubmitting}
              onClick={submit}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
      {showInModal && (
        <div className={`note-operate ${showInModal ? 'no-line' : ''}`}>
          <div></div>
          <div>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              onClick={props.cancel}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              disabled={
                (disabledSubmit && preUpload.length === 0) ||
                isSubmitting ||
                hasImageUploading
              }
              loading={isSubmitting}
              onClick={submit}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </EditorContainer>
  )

  function onTextPaste(e) {
    e.preventDefault()
    let text = ''
    if (window.clipboardData && window.clipboardData.setData) {
      text = window.clipboardData.getData('text')
    } else {
      text = e.clipboardData.getData('text/plain')
    }

    const selection = window.getSelection()
    if (!selection.rangeCount) return
    selection.deleteFromDocument()

    if (!text) {
      const files = e.clipboardData.files
      for (let i = 0; i < files.length; i++) {
        if (files[i].type.includes('image')) {
          const reader = new FileReader()
          reader.onload = async (e) => {
            const img = new Image()
            img.src = e.target.result
            selection.getRangeAt(0).insertNode(img)
            selection.collapseToEnd()
            //upload file
            setHasImageUploading(true)

            if (!files[i].type.includes('svg+xml')) {
              const url = await uploadImage(files[i])
              img.src = url
            }
            setHasImageUploading(false)
          }
          reader.readAsDataURL(files[i])
        }
      }
    } else {
      selection.getRangeAt(0).insertNode(document.createTextNode(text))
      selection.collapseToEnd()
    }
  }

  async function importImage(image) {
    editorRef.current.focus()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const img = new Image()
      img.src = e.target.result
      const selection = window.getSelection()
      const range = document.createRange()
      range.selectNodeContents(editorRef.current)
      range.collapse(false)
      range.insertNode(img)
      selection.removeAllRanges()
      selection.addRange(range)
      selection.collapseToEnd()

      // upload file
      setHasImageUploading(true)
      const url = await uploadImage(image)
      img.src = url
      setHasImageUploading(false)
      const disabledSubmit = !(editorRef.current && editorRef.current.innerHTML)
      setDisabledSubmit(disabledSubmit)
    }

    reader.readAsDataURL(image)
    return Promise.reject('fails')
  }

  async function removeFile(file) {
    _.pull(preUpload, file)
    setPreUpload(_.cloneDeep(preUpload))
  }

  async function beforeUpload(file) {
    setPreUpload([...preUpload, file])
    return Promise.reject('fails')
  }

  async function handleFiles() {
    let files = []
    for (const item of preUpload) {
      if (item.id) {
        files.push(item)
      } else {
        const { name, size, type } = item
        item.loading = true
        setPreUpload(_.cloneDeep(preUpload))
        try {
          const { file } = await upload({
            personId: person.id,
            type: 'note',
            files: [item]
          })
          files.push({
            name,
            size,
            fileType: type,
            type: file.type,
            id: file.id
          })
        } catch (err) {
          console.error(err)
        }
        item.loading = false
        setPreUpload(_.cloneDeep(preUpload))
      }
    }
    return files
  }

  async function submit() {
    setIsSubmitting(true)
    const noteContent = editorRef.current.innerHTML
    const files = await handleFiles()

    const params = {
      client_id: person.id,
      from: 'note',
      pid,
      pillars: selectedTags,
      text: noteContent,
      files
    }

    if (status === 'reply') {
      params.pid = initialData.id
    } else if (status === 'edit') {
      params.id = initialData.id
    }

    await submitNote(params)
    setIsSubmitting(false)
    setPreUpload([])
    editorRef.current.innerHTML = ''
    if (props.cancel) {
      props.cancel()
    }
  }

  function renderEditor(atList) {
    let tribute = new Tribute({
      allowSpaces: true,
      noMatchTemplate: '<span style="visibility: hidden;"></span>',
      selectTemplate: function(item) {
        if (item) {
          const {
            original: { id, full_name }
          } = item
          return `<span person-id="${id}" class="at-item">@${full_name}</span>`
        } else {
          return null
        }
      },
      values: (text, cb) => {
        const nextList = atList.filter((item) =>
          _.includes(item.first_name.toLowerCase(), text)
        )
        cb(nextList)
      },
      menuItemTemplate: (item) => {
        const {
          original: { full_name, email, avatar }
        } = item
        const avatarElement = avatar
          ? `<img src=${avatar.oss_url} alt="avatar" />`
          : '<span class="avatar-placeholder"/>'
        return `<div class="menu-template">
          <div class="avatar">
            ${avatarElement}
          </div>
          <div class="info">
            <div class="name">${full_name}</div>
            <div class="email">${email}</div>
          </div>
        </div>`
      },
      searchOpts: {
        pre: '<div class="name">',
        post: '</div>',
        skip: true
      },
      lookup: 'full_name',
      fillAttr: 'full_name'
    })

    if (editorRef.current) {
      tribute.attach(editorRef.current)
      // editorRef.current.focus()
      // if (editorRef.current.innerText) {
      //   moveRangeToEnd()
      // }
      const disabledSubmit = !(editorRef.current && editorRef.current.innerHTML)
      setDisabledSubmit(disabledSubmit)
    }
  }

  async function uploadImage(file) {
    const { url } = await uploadPublicFile({
      path: 'notes',
      file
    })
    return url
  }
}
