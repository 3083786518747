import React, { useEffect } from 'react'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button } from 'antd'
// import { SimpleUploadIcon } from '../icons' // BellIcon

import Wrapper from './wrapper'
import HealthProfile from './comp/health'
import Cardiometabolic from './comp/cardiometabolic'
import Aerobic from './comp/aerobic'
// import PageLoading from '../UI/status/loading'
// import { Footer, DraftContainer } from './draft'
import MovementQuality from './comp/movementQuality'
import CognitiveHealth from './comp/cognitiveHealth'
import Banner from '../../components/assessmentReview/comp/banner'
import PageLoading from '../../components/UI/status/loading'
import { DraftContainer, Footer } from '../../components/assessmentReview/draft'
import { SimpleUploadIcon } from '../../components/icons'

export default Wrapper(PreviewReport)

function PreviewReport(props) {
  const {
    previous,
    latest,
    list,
    person,
    loginRole,
    arDetail,
    latest_bmi,
    latest_height,
    latest_weight,
    dynamicData,
    routeTypes,
    jumpToDataset,
    handleSave,
    handlePublish,
    showActivity,
    ageScopes,
    infinityLoadCount,
    maxLoadCount,
    next,
    hideSectionHandle,
    hideSection
  } = props

  const getDataList = (snapshot, graph_type) => {
    return list.map((item) => {
      const detail =
        (snapshot[item.type] && _.cloneDeep(snapshot[item.type])) || {}
      detail.isDefaultValue = true
      if (detail.score_manual && detail.score_manual.value) {
        detail.isDefaultValue = false
      }
      return {
        ...item,
        ...detail
      }
    })
  }

  const compareData = getDataList(previous.snapshot, 'previous')
  const dataList = getDataList(latest.snapshot, 'latest')

  useEffect(() => {
    // Scroll to the top after first loaded
    window.scrollTo(0, 0)
  }, [])

  const commonProps = {
    hideSectionHandle,
    hideSection,
    arDetail,
    person,
    dynamicData,
    loginRole,
    view: 'Preview'
  }

  return arDetail ? (
    <InfiniteScroll
      dataLength={infinityLoadCount}
      next={next}
      hasMore={infinityLoadCount < maxLoadCount}
      loader={
        <h4
          style={{
            paddingBottom: 75,
            fontSize: 24,
            textAlign: 'center',
            color: '#4C6072'
          }}
        >
          Loading...
        </h4>
      }
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <b>Yay! You have seen it all</b>
        </p>
      }
    >
      <DraftContainer
        id="PDFAssessment"
        style={
          loginRole === 'User' ? { marginLeft: -25, marginRight: -25 } : {}
        }
      >
        <Banner person={person} period={arDetail.time_period} showPDF={true} />
        <HealthProfile
          {...{
            compareData,
            dataList,
            person,
            latest_bmi,
            latest_height,
            latest_weight,
            ui_version: dynamicData.ui_version
          }}
        />
        <Cardiometabolic
          {...commonProps}
          {...{
            previous,
            latest,
            routeTypes,
            jumpToDataset
          }}
        />
        {(arDetail.status === 1 || infinityLoadCount > 1) && (
          <Aerobic
            {...commonProps}
            {...{
              ageScopes,
              compareData,
              dataList,
              routeTypes,
              jumpToDataset,
              infinityLoadCount
            }}
          />
        )}

        {dynamicData.movementQuality && (
          <MovementQuality
            {...commonProps}
            {...{
              compareData,
              dataList
            }}
          />
        )}
        {dynamicData.cognitiveHealth && (
          <CognitiveHealth
            {...commonProps}
            {...{
              compareData,
              dataList,
              ageScopes
            }}
          />
        )}

        {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
          <Footer id="footer-preview">
            <div>
              {arDetail.status === 0 && (
                <Button
                  ghost
                  type="primary"
                  onClick={() => handleSave(null, 'edit')}
                >
                  BACK TO EDIT
                </Button>
              )}
              <Button ghost type="primary" onClick={() => showActivity()}>
                ACTIVITY
              </Button>
            </div>
            <div>
              {/* <Button ghost type="primary" onClick={handlePreview}>
            <ViewIcon />
            PREVIEW
          </Button> */}
              {arDetail.status === 0 && (
                <Button type="primary" onClick={handlePublish}>
                  <SimpleUploadIcon />
                  PUBLISH
                </Button>
              )}
              {/* <Button
              type="primary"
              onClick={handleNotify}
              disabled={!arDetail.published_at}
            >
              <BellIcon />
              NOTIFY CLIENT
            </Button> */}
            </div>
          </Footer>
        )}
      </DraftContainer>
    </InfiniteScroll>
  ) : (
    <PageLoading />
  )
}
