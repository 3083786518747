import React from 'react'
import { connect } from 'react-redux'
import WeeklySummaryContainer from '../../../components/summaryEmail/v3/draftEmailContainer' //'../../../components/summaryEmail/weeklySummary'
import { summaryEmailStyle } from './index.sass'

function SummaryEmail(props) {
  const {
    match: { params },
    account
  } = props
  return (
    <div className={summaryEmailStyle}>
      <WeeklySummaryContainer
        person={account.user}
        id={params.id}
        loginRole="User"
      />
    </div>
  )
}

export default connect(
  ({ account }) => ({ account }),
  null
)(SummaryEmail)
