import { Button, Card } from 'antd'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import CSV from '../../asserts/icon/icon-csv.svg'
import { downloadCSV } from '../../api/public'
import { ArrowDownOutlined } from '@ant-design/icons'

const Container = styled.div`
  .header-tool {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cardList {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .ant-card {
    height: 110px;
    border-radius: 12px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }
  .ant-card-body {
    height: 100%;
  }

  .card-contents {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      postion: absolute;
      top: 0px;
    }

    .text-contents {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
    }

    button {
      position: absolute;
      right: 24px;
    }

    .title {
      font-size: 18px;
      color: #29303e;
      font-family: 'Gilroy';
      font-weight: 500;
    }
    .subtitle {
      font-size: 12px;
      color: #a5abb2;
      font-family: 'Gilroy';
      font-weight: 500;
    }
  }
`

const items = [
  { title: 'No service appointments in 2+ months', type: 1 },
  { title: 'Clients use less than 2 service sessions', type: 2 },
  { title: 'Clients with no connected wearables', type: 3 },
  { title: 'Total clients on the App', type: 4 },
  { title: 'Clients chatting on the App in last 2 months', type: 5 }
]

export default function DownloadCSVs() {
  const [loadingItems, setLoadingItems] = useState([])

  const onClickDownload = async (item) => {
    const loading = loadingItems
    loading.push(item.type)
    setLoadingItems(loading)
    const { url } = await downloadCSV(item.type)
    const filtered = loadingItems.filter((e) => e !== item.type)
    setLoadingItems(filtered)
    window.open(url)
  }

  useEffect(() => {}, [])
  return (
    <Container>
      <div className="header-tool">
        <h1 className="page-title">Download CSVs</h1>
        {/* <Button loading={loading} ghost type="primary" onClick={onClickDownloadAll}>Download All CSVs</Button> */}
      </div>
      <div className="cardList">
        {items.map((e) => {
          return (
            <Card>
              <div className="card-contents">
                <img src={CSV} alt="download_icon"></img>
                <div className="text-contents">
                  <div className="title">{e.title}</div>
                  <div className="subtitle">CSV</div>
                </div>
                <Button
                  loading={loadingItems.includes(e.type)}
                  type="primary"
                  onClick={() => onClickDownload(e)}
                >
                  <ArrowDownOutlined />
                  Download
                </Button>
              </div>
            </Card>
          )
        })}
      </div>
    </Container>
  )
}
