import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from 'antd'
import { AddressIcon } from '../../../../components/icons/facility'

import ArrowRight from '../../../../asserts/icon/facility/arrow-right.svg'

class Facility extends Component {
  static propTypes = {
    facility: PropTypes.object,
    turnToDetail: PropTypes.func
  }

  render() {
    const { turnToDetail, facility } = this.props
    return (
      <div className="facility">
        <div className="title">
          <span>{facility.name}</span>
          <img
            className="arrow"
            src={ArrowRight}
            alt="right"
            onClick={() => turnToDetail()}
          />
        </div>
        <div className="time-area">
          Open: {moment(facility.open_time, 'HH:mm:ss').format('hh:mm A')} –
          Close: {moment(facility.close_time, 'HH:mm:ss').format('hh:mm A')}
        </div>
        <div className="address">
          <AddressIcon />
          {facility.address}
        </div>
        {facility.services.length > 0 && (
          <>
            <Divider orientation="left">Available services</Divider>
            <div className="services">
              <ul className="list">
                {facility.services[0] && <li>{facility.services[0].name}</li>}
                {facility.services[1] && <li>{facility.services[1].name}</li>}
                {facility.services[2] && <li>{facility.services[2].name}</li>}
              </ul>
              {facility.services.length > 3 && (
                <div className="others">
                  + {facility.services.length - 3} other services
                </div>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default Facility
