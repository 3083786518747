import React from 'react'
import styled from 'styled-components'
import { RangeColors } from '../../../utils'

const ScoreColorGroupStyle = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(50, 58, 70, 0.5);
  li {
    padding-left: 24px;
  }
  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`

const { red, orange, yellow, green, emerald } = RangeColors

const Tips = {
  muscle: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'Very good',
      color: green
    },
    {
      name: 'Excellent',
      color: emerald
    }
  ],
  movement: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'optimal',
      color: green
    }
  ],
  balance: [
    {
      name: 'poor',
      color: red
    },
    {
      name: 'fair',
      color: orange
    },
    {
      name: 'good',
      color: yellow
    },
    {
      name: 'optimal',
      color: green
    }
  ]
}

export const ScoreColorGroup = (props) => {
  return (
    <ScoreColorGroupStyle style={props.style || {}}>
      {Tips[props.domain].map((item, index) => (
        <li key={index}>
          <span style={{ background: item.color }} /> {item.name}
        </li>
      ))}
    </ScoreColorGroupStyle>
  )
}

export const AerobicTestTypeCycleIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 18, y: y - 18 }}
    >
      <g clipPath="url(#clip0_2645_36628)">
        <path
          d="M36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
          fill="#2B4B8E"
        />
        <g clipPath="url(#clip1_2645_36628)">
          <path
            d="M12.3 25.8012C14.1225 25.8012 15.6 24.3237 15.6 22.5012C15.6 20.6786 14.1225 19.2012 12.3 19.2012C10.4775 19.2012 9 20.6786 9 22.5012C9 24.3237 10.4775 25.8012 12.3 25.8012Z"
            stroke="white"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M23.7023 25.8012C25.5249 25.8012 27.0023 24.3237 27.0023 22.5012C27.0023 20.6786 25.5249 19.2012 23.7023 19.2012C21.8798 19.2012 20.4023 20.6786 20.4023 22.5012C20.4023 24.3237 21.8798 25.8012 23.7023 25.8012Z"
            stroke="white"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M21.2969 12C22.1253 12 22.7969 11.3284 22.7969 10.5C22.7969 9.67157 22.1253 9 21.2969 9C20.4684 9 19.7969 9.67157 19.7969 10.5C19.7969 11.3284 20.4684 12 21.2969 12Z"
            stroke="white"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
          <path
            d="M17.9987 24.0007V19.8007L15.5219 17.8207C15.4561 17.7679 15.402 17.7019 15.3634 17.6269C15.3248 17.5518 15.3024 17.4695 15.2978 17.3853C15.2931 17.301 15.3063 17.2167 15.3365 17.1379C15.3666 17.0591 15.413 16.9875 15.4727 16.9279L18.7247 13.6759C18.7844 13.6162 18.856 13.5697 18.9348 13.5396C19.0136 13.5094 19.0979 13.4963 19.1821 13.5009C19.2664 13.5056 19.3487 13.5279 19.4237 13.5665C19.4988 13.6052 19.5648 13.6592 19.6175 13.7251L21.5987 16.2007H23.9987"
            stroke="white"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2645_36628">
          <rect width="36" height="36" fill="white" />
        </clipPath>
        <clipPath id="clip1_2645_36628">
          <rect
            width="19.2"
            height="19.2"
            fill="white"
            transform="translate(8.40234 7.79883)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AerobicTestTypeTreadmillIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 18, y: y - 18 }}
    >
      <g clipPath="url(#clip0_2645_36979)">
        <path
          d="M36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
          fill="#2B4B8E"
        />
        <path
          d="M18.6016 13.0002L22.9984 11.5344C23.2633 11.4461 23.504 11.2974 23.7016 11.1L25.8016 9"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M23.3906 11.3477L25.2002 21.0005"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M21.1016 12.166L22.7942 21.193"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.1998 24.6C25.6772 24.6 26.135 24.4104 26.4726 24.0728C26.8102 23.7352 26.9998 23.2774 26.9998 22.8C26.9998 22.3226 26.8102 21.8648 26.4726 21.5272C26.135 21.1896 25.6772 21 25.1998 21L10.0606 22.2138C9.75671 22.2487 9.4777 22.3985 9.28084 22.6326C9.08398 22.8668 8.98422 23.1674 9.00203 23.4727C9.01985 23.7781 9.15389 24.065 9.37663 24.2747C9.59937 24.4843 9.89392 24.6007 10.1998 24.6H25.1998Z"
          fill="white"
        />
        <path
          d="M11.3984 25.7996V24.5996"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M24 25.7996V24.5996"
          stroke="white"
          strokeWidth="1.2"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
      </g>
      <defs>
        <clipPath id="clip0_2645_36979">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const AerobicTestTypeOtherIcon = (props) => {
  const { x, y } = props
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ x: x - 18, y: y - 18 }}
    >
      <g clipPath="url(#clip0_2781_2949)">
        <path
          d="M36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C27.9411 36 36 27.9411 36 18Z"
          fill="#2B4B8E"
        />
        <g clipPath="url(#clip1_2781_2949)">
          <path
            d="M20.8125 14.25L11.4375 14.2678C10.92 14.2678 10.5 13.8478 10.5 13.3303C10.5 12.8128 10.92 12.3928 11.4375 12.3928L20.8125 12.375V14.25Z"
            fill="white"
          />
          <path
            d="M24.5625 18.9375H15.1875V17.0625H24.5625C25.08 17.0625 25.5 17.4825 25.5 18C25.5 18.5175 25.08 18.9375 24.5625 18.9375Z"
            fill="white"
          />
          <path
            d="M24.5625 14.2678L22.6875 14.25V12.375L24.5625 12.3928C25.08 12.3928 25.5 12.8128 25.5 13.3303C25.5 13.8488 25.08 14.2678 24.5625 14.2678Z"
            fill="white"
          />
          <path
            d="M20.8125 23.625H11.4375C10.92 23.625 10.5 23.205 10.5 22.6875C10.5 22.17 10.92 21.75 11.4375 21.75H20.8125V23.625Z"
            fill="white"
          />
          <path
            d="M24.5625 23.625H22.6875V21.75H24.5625C25.08 21.75 25.5 22.17 25.5 22.6875C25.5 23.205 25.08 23.625 24.5625 23.625Z"
            fill="white"
          />
          <path
            d="M13.3125 18.9375H11.4375C10.92 18.9375 10.5 18.5175 10.5 18C10.5 17.4825 10.92 17.0625 11.4375 17.0625H13.3125V18.9375Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2781_2949">
          <rect width="36" height="36" fill="white" />
        </clipPath>
        <clipPath id="clip1_2781_2949">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(10.5 10.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
