import React from 'react'
// import moment from 'moment'
import {
  Hourglass,
  ProgramPercentIcon,
  Start,
  // CalendarIcon,
  ActionItemCountIcon
} from '../../icons/program'
import { Button, Divider, Progress } from 'antd'

import styled from 'styled-components'
import { CollapsePlusIcon, EditPanIcon } from '../../icons'

const Container = styled.div`
  display: flex;
  padding-bottom: 34px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .basic-text-info {
    padding-right: 56px;
    font-size: 14px;
    color: #4c6072;
    & > div {
      margin: 10px 0;
    }
    .program-name {
      font-size: 18px;
      font-weight: 600;
      color: #323f4a;
    }
    .anticon {
      margin-right: 12px;
    }
  }
  .pipe-chart {
    display: flex;
    padding: 30px;
    align-items: center;
    .description {
      margin-left: 16px;
      .progress {
        color: #323f4a;
        font-size: 24px;
        font-weight: 600;
      }
      .text {
        color: #8596a4;
        font-size: 12px;
      }
    }
  }
  .ant-divider-vertical {
    height: auto;
  }
`

const NoProgramContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 27px;
  .program-name {
    font-size: 18px;
    font-weight: 600;
    color: #323f4a;
    margin-right: 19px;
  }
`

export default function ProgramOverviewInfo({
  program,
  summary,
  editProgram,
  createProgram,
  loginRole
}) {
  if (program) {
    const { name } = program
    const {
      achievement: { duration, session },
      pillar_item_count: { actual, target }
    } = summary || { achievement: { duration: {}, session: {} } }

    return (
      <Container>
        <div className="basic-text-info">
          <div className="program-name">{name}</div>
          <div>
            <ActionItemCountIcon />
            {program.items.length} program action items per week
          </div>
          <div>
            <ProgramPercentIcon
              style={{ fontSize: 18, verticalAlign: 'middle' }}
            />
            Program: {target.join('-')} • Actual: {actual.join('-')}
          </div>
          {loginRole !== 'User' && loginRole !== 'Service_Provider' && (
            <Button type="primary" ghost onClick={editProgram}>
              <EditPanIcon />
              EDIT PROGRAM
            </Button>
          )}
        </div>
        <Divider type="vertical" />
        <div className="pipe-chart">
          <Progress
            type="circle"
            percent={(session.completed / session.target) * 100}
            width={100}
            format={() => <Start fill="#0D2350" />}
          />
          <div className="description">
            <div className="progress">
              {session.completed} of {session.target}
            </div>
            <div className="text">Total program sessions completed</div>
          </div>
        </div>
        <Divider type="vertical" />
        <div className="pipe-chart">
          <Progress
            type="circle"
            percent={(duration.completed / duration.target) * 100}
            strokeColor="#0D2350"
            width={100}
            format={() => <Hourglass fill="#0D2350" />}
          />
          <div className="description">
            <div className="progress">
              {Math.round(duration.completed / 60)} of{' '}
              {Math.round(duration.target / 60)}
            </div>
            <div className="text">Minutes completed</div>
          </div>
        </div>
      </Container>
    )
  } else {
    return (
      <NoProgramContainer>
        <div className="program-name">No Program</div>
        <Button type="primary" ghost onClick={createProgram}>
          <CollapsePlusIcon />
          ADD PROGRAM
        </Button>
      </NoProgramContainer>
    )
  }
}
