import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, message, Checkbox } from 'antd'
import BasicForm from '../../formV4'
import { microGoalsModel } from '../../../models/goals.form'
import GoalsAttach from './goalsAttach'
import { goalsModal } from '../index.sass'

class GoalModalForm extends Component {
  static propTypes = {
    editGoalType: PropTypes.string,
    pillar: PropTypes.string,
    closeModal: PropTypes.func,
    categories: PropTypes.array,
    requestGoal: PropTypes.func,
    editGoal: PropTypes.object,
    metricUnit: PropTypes.object
  }

  state = {
    valueForm: {},
    loading: false,
    isExpertOnly: false
  }

  validate = async (notClose) => {
    const { requestGoal, closeModal, pillar } = this.props
    this.setState({ loading: true })

    try {
      let params
      try {
        params = await this.formRef.handleSubmit()
      } catch (err) {
        console.error(err)
        throw new Error('Form validate error')
      }
      switch (params.target_goal) {
        case 'fixed':
          params.auto_range = params.range1
          break
        case 'range':
          params.auto_range = `${params.range1},${params.range2}`
          break
        case 'greater':
          params.auto_range = `${params.range1},`
          break
        case 'lesser':
          params.auto_range = `,${params.range1}`
          break
        default:
          params.auto_range = null
          break
      }
      delete params.target_goal
      delete params.range1
      delete params.range2
      if (params.tracking_activity_metric) {
        params.tracking_activity_metric = params.tracking_activity_metric.join(
          ','
        )
      }
      if (params.input_type === 'binary') {
        params.auto_metric = null
        params.chart_type = 'none'
      }

      params.is_expert_only = this.state.isExpertOnly

      /* program feature influnce */
      params.show_micro_goal = true
      params.active = false
      /* program feature influnce */
      await requestGoal(params, pillar)
      this.setState({ loading: false })
      if (!notClose) {
        closeModal()
      }
    } catch (err) {
      this.setState({ loading: false })
      console.error(err)
      if (err.message !== 'Form validate error') {
        message.error(err.message)
      }
      if (notClose) {
        throw err
      }
    }
  }

  judgeActivityNeedChange = (source) => {
    const activitys = this.formData.find((item) => {
      if (item.dbField === 'auto_activity') {
        const condition =
          item.unless &&
          item.unless.and &&
          item.unless.and.find((i) => i.key === 'source')
        if (condition.value === source) {
          return true
        }
      }
      return false
    })
    const noChange =
      activitys &&
      activitys.options &&
      activitys.options.find(
        (option) => option.value === this.state.valueForm.auto_activity
      )

    return !noChange
  }

  changeSourceHandle = async (valueForm, field) => {
    const { trackingMetric, trackingOuraMetric, pillar } = this.props
    if (field.value === 'manual') {
      delete valueForm.auto_metric
      delete valueForm.auto_activity
    } else {
      // let setFormData
      if (valueForm.input_type === 'text') {
        valueForm.input_type = 'number'
        await this.formRef.setFieldsValue({
          input_type: 'number'
        })
      } else if (valueForm.input_type === 'binary') {
        if (
          field.value === 'ouraring' ||
          (field.value === 'any' && valueForm.auto_activity === 'Sleep')
        ) {
          valueForm.tracking_activity_metric = trackingOuraMetric.map(
            (item) => item.value
          )
          valueForm.auto_activity = 'Sleep'
          if (this.formRef) {
            await this.formRef.setFieldsValue({ auto_activity: 'Sleep' })
          }
        } else {
          valueForm.tracking_activity_metric = trackingMetric.map(
            (item) => item.value
          )
          if (field.value !== 'any') {
            if (this.judgeActivityNeedChange(field.value)) {
              valueForm.auto_activity = 'Any' //valueForm.auto_activity || 'Running'
              if (this.formRef) {
                await this.formRef.setFieldsValue({ auto_activity: 'Any' })
              }
            }
          }
        }
      } else {
        if (field.value === 'any') {
          valueForm.auto_metric = 'Heart Rate Zones'
          valueForm.unit = 'bpm'
        } else if (field.value === 'withings' && !valueForm.auto_metric) {
          valueForm.auto_metric = 'Weight'
          valueForm.unit = 'Lbs'
        } else if (valueForm.source !== field.value) {
          let dataArr
          if (field.value === 'ouraring') {
            dataArr = this.props.ouraMetric.filter((item) =>
              item.value.includes('Sleep')
            )
          } else {
            dataArr = this.props[field.value + 'Metric']
          }
          const data =
            field.value === 'ouraring'
              ? dataArr[0]
              : dataArr.find((item) => item.pillar === pillar)
          if (data) {
            valueForm.auto_metric = data.value
            valueForm.unit = data.unit
          }
        }

        const value = this.formRef && this.formRef.getFieldValue('auto_metric')
        if (value !== valueForm.auto_metric) {
          await this.formRef.setFieldsValue({
            auto_metric: valueForm.auto_metric,
            unit: valueForm.unit
          })
        }
      }
    }
  }

  changeInputTypeHandle = async (valueForm, field) => {
    const { trackingMetric, trackingOuraMetric } = this.props

    if (field.value === 'number' && valueForm.chart_type === 'none') {
      valueForm.chart_type = 'line'
      await this.formRef.setFieldsValue({
        chart_type: 'line'
      })
    } else if (field.value === 'binary' && valueForm.source !== 'manual') {
      if (valueForm.source !== 'ouraring') {
        valueForm.tracking_activity_metric = trackingMetric.map(
          (item) => item.value
        )

        if (this.judgeActivityNeedChange(valueForm.source)) {
          valueForm.auto_activity = 'Any'
        }
      } else {
        valueForm.tracking_activity_metric = trackingOuraMetric.map(
          (item) => item.value
        )
        valueForm.auto_activity = 'Sleep'
      }
    } else if (field.value === 'binary' || field.value === 'text') {
      valueForm.target_goal = 'tracking'
    }
  }

  changeAutoMetricHandle = (valueForm, field) => {
    const { healthkit, garmin, ouraring, polar, withings } = this.props
    let unitObj
    if (valueForm.source === 'healthkit') {
      unitObj = healthkit && healthkit[field.value]
    } else if (valueForm.source === 'garmin') {
      unitObj = garmin && garmin[field.value]
    } else if (valueForm.source === 'ouraring') {
      unitObj = ouraring && ouraring[field.value]
    } else if (valueForm.source === 'polar') {
      unitObj = polar && polar[field.value]
    } else if (valueForm.source === 'withings') {
      unitObj = withings && withings[field.value]
    }
    valueForm.unit = unitObj && unitObj.unit
  }

  updateValueForm = async (changedFields) => {
    const { trackingOuraMetric } = this.props
    const { valueForm } = this.state
    const fieldName = Object.keys(changedFields)[0]
    const field = changedFields[fieldName]
    if (!field) return
    if (field.name === 'input_type') {
      this.changeInputTypeHandle(valueForm, field)
    } else if (field.name === 'source') {
      this.changeSourceHandle(valueForm, field)
    } else if (field.name === 'auto_metric') {
      this.changeAutoMetricHandle(valueForm, field)
    }

    if (
      (field.name === 'source' || field.name === 'auto_activity') &&
      valueForm.source !== 'ouraring' &&
      valueForm.auto_activity !== 'Sleep'
    ) {
      if (
        field.name === 'auto_activity' &&
        !['Yoga', 'Strength'].find((item) => item === field.value)
      ) {
        if (
          valueForm.tracking_activity_metric &&
          !valueForm.tracking_activity_metric.find(
            (item) => item === 'distance'
          )
        ) {
          valueForm.tracking_activity_metric.splice(1, 0, 'distance')
        }
      } else if (
        valueForm.auto_activity === 'Strength' ||
        valueForm.auto_activity === 'Yoga'
      ) {
        valueForm.tracking_activity_metric =
          valueForm.tracking_activity_metric &&
          valueForm.tracking_activity_metric.filter(
            (item) => item !== 'distance'
          )
      }
    }
    if (field.name === 'auto_activity' && field.value === 'Sleep') {
      valueForm.tracking_activity_metric = trackingOuraMetric.map(
        (item) => item.value
      )
    }
    valueForm[field.name] = field.value
    this.setState({ valueForm })
  }

  componentDidMount() {
    const { editGoal, editGoalType } = this.props
    const valueForm = Object.assign({ frequency: 'overall' }, editGoal)
    if (editGoal) {
      switch (editGoal.period) {
        case 'daily':
          valueForm.goal_period = '0'
          break
        case 'weekly':
          valueForm.goal_period = '1'
          break
        case 'On specific days per week':
          valueForm.goal_period = '2'
          break
        case 'monthly':
          valueForm.goal_period = '3'
          break
        default:
          break
      }
      if (editGoal.goal_type === 'heartRateMetrics') {
        valueForm.auto_metric = 'Heart Rate Zones'
      }
    }
    if (editGoalType === 'question') {
      valueForm.input_type = 'binary'
    }
    if (valueForm.input_type === 'binary') {
      valueForm.target_goal = 'tracking'
    }
    if (valueForm.auto_metric === 'Sleep') {
      valueForm.auto_activity = 'Sleep'
    }
    if (valueForm.auto_metric) {
      const metricUnit = this.props[valueForm.source]
      valueForm.unit = metricUnit && metricUnit[valueForm.auto_metric].unit
    }
    if (
      valueForm.tracking_activity_metric &&
      typeof valueForm.tracking_activity_metric === 'string'
    ) {
      valueForm.tracking_activity_metric = valueForm.tracking_activity_metric.split(
        ','
      )
    }
    const isExpertOnly = editGoal && editGoal.is_expert_only

    this.setState({ valueForm, isExpertOnly })
  }

  render() {
    const {
      categories,
      pillar,
      person,
      editGoal,
      addAttachSync,
      removeAttach,
      editAttachTitle,
      editGoalType,
      healthkitMetric,
      garminMetric,
      ouraMetric,
      polarMetric,
      withingsMetric,
      polarActivities,
      healthkitActivities,
      garminActivities,
      trackingMetric,
      ouraActivities,
      trackingOuraMetric
    } = this.props
    const { valueForm, isExpertOnly } = this.state
    let anyActivities = []
      .concat(polarActivities)
      .concat(garminActivities)
      .concat(healthkitActivities)
      .concat(ouraActivities)
      .map((item) => item.value)
    anyActivities = Array.from(new Set(anyActivities)).map((item) => ({
      label: item,
      value: item
    }))

    this.formData = microGoalsModel({
      categories: categories.filter(
        (item) => item.pillar === pillar && item.category !== 'Default'
      ),
      isBinary: valueForm.input_type === 'binary',
      isEdit: editGoal && editGoal.id,
      trackingOnly:
        valueForm.input_type === 'binary' || valueForm.input_type === 'text',
      showEmailTitle:
        pillar === 'nutrition' ||
        (pillar === 'rejuvenation' && valueForm.auto_activity !== 'Sleep'),
      disabledSource:
        editGoal &&
        editGoal.id &&
        valueForm.input_type === 'binary' &&
        valueForm.source === 'ouraring',
      anyActivities,
      healthkitMetric,
      garminMetric,
      ouraMetric,
      polarMetric,
      withingsMetric,
      polarActivities,
      healthkitActivities,
      garminActivities,
      trackingMetric:
        valueForm.auto_activity === 'Strength' ||
        valueForm.auto_activity === 'Yoga'
          ? trackingMetric.filter((item) => item.value !== 'distance')
          : trackingMetric,
      ouraActivities,
      trackingOuraMetric,
      pillar,
      selectSource: valueForm.source
    })

    let bodyTitle = ''
    if (
      editGoalType === 'tracking' ||
      (editGoal.id && editGoal.input_type !== 'binary')
    ) {
      bodyTitle = 'Add Tracking Metric'
    } else {
      bodyTitle = 'Add Yes/No Question'
    }

    return (
      <Modal
        className={goalsModal}
        title={pillar.split('_').join(' ')}
        visible={true}
        okText="CONFIRM"
        onOk={() => this.validate()}
        onCancel={() => {
          this.props.closeModal()
        }}
        confirmLoading={this.state.loading}
      >
        <h1 className="body-title"> {bodyTitle}</h1>
        <BasicForm
          formData={this.formData}
          valueForm={valueForm}
          onChange={this.updateValueForm}
          wrappedComponentRef={(inst) => (this.formRef = inst)}
        />
        <div className="only-expert">
          <Checkbox
            checked={isExpertOnly}
            onChange={() => this.setState({ isExpertOnly: !isExpertOnly })}
          >
            Show only to expert
          </Checkbox>
        </div>
        <GoalsAttach
          saveGoal={this.validate}
          {...{
            addAttachSync,
            removeAttach,
            editAttachTitle,
            person,
            editGoal
          }}
        />
      </Modal>
    )
  }
}

export default GoalModalForm
