import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Divider } from 'antd'
import mapDispatchToProps from '../dispatch'
import { hashRequest } from '../../../api/axios'
import { getClient } from '../../../api/team'
import { getReportById } from '../../../api/report'
// import KeyTakeaway from '../../../components/business/creport/keyTakeaway'
// import ReportFiles from '../../../components/business/creport/files'
// import ReportBloodTestCharts from '../../../components/business/creport/charts'
// import BloodTestResult from '../../../components/business/creport/bloodTest'
import BloodTestPreview from './bloodTest'
import PhysicalTestPreview from './physicalTest'
import BodyCompPreview from './bodyComp'
import LifestylePreview from './lifestyle'
import RangeOfMotionPreview from './rangeOfMotion'

export class PreviewReport extends Component {
  static propTypes = {}

  state = {
    person: this.props.client.person,
    report: null
  }

  getPerson = async () => {
    const { client, match, setClient } = this.props
    const { personId, hash } = match.params
    let result, person
    if (hash) {
      result = await hashRequest({
        hash,
        method: 'get',
        path: '/people/me'
      })
      result = result.data
      person = result.person
    } else {
      if (client.person && client.person.id === personId) return client.person
      result = await getClient(personId)
      person = result.clients[0]
    }
    setClient(person)
    this.setState({ person })
  }

  getReport = async () => {
    const { match, history } = this.props
    const { personId, reportId, hash } = match.params
    let result
    if (hash) {
      result = await hashRequest({
        hash,
        method: 'get',
        path: '/reports'
      })
      result = result.data
    } else {
      result = await getReportById(personId, reportId)
    }
    if (result.reports.length) {
      this.setState({
        report: result.reports[0]
      })
    } else {
      history.push('/404')
    }
  }

  initail = async () => {
    await this.getPerson()
    await this.getReport()
  }

  dealHtml = (html) => {
    return (html && html.replace(/font-family(.*?);/g, '')) || ''
  }

  componentDidMount() {
    this.initail()
  }

  render() {
    const { report, person } = this.state
    return (
      report && (
        <div className="preview-report">
          <div className="preview-banner">
            {report.cover_photo && (
              <img src={report.cover_photo.oss_url} alt="banner" />
            )}
            <h1>Comprehensive Report</h1>
            {person && (
              <p>
                Prepared for{' '}
                {(person.profile && person.profile.nickname) ||
                  person.first_name}
              </p>
            )}
          </div>
          {report.intro_enabled && (
            <div className="report-dive-in report-section">
              <h1 className="section-title">0. Introduction</h1>
              <Divider />
              <p
                className="text-section"
                dangerouslySetInnerHTML={{
                  __html: this.dealHtml(report.intro_intro)
                }}
              />
              {report.intro_cover_photo && (
                <img src={report.intro_cover_photo.oss_url} alt="intro cover" />
              )}
            </div>
          )}
          {report.blood_test_enabled && (
            <BloodTestPreview report={report} dealHtml={this.dealHtml} />
          )}
          <Divider className="full" />
          {report.body_comp_enabled && (
            <BodyCompPreview report={report} dealHtml={this.dealHtml} />
          )}
          <Divider className="full" />
          {report.lifestyle_assessment_enabled && (
            <LifestylePreview report={report} dealHtml={this.dealHtml} />
          )}
          <Divider className="full" />
          {report.physical_enabled && (
            <PhysicalTestPreview report={report} dealHtml={this.dealHtml} />
          )}
          <Divider className="full" />
          {report.range_of_motion_enabled && (
            <RangeOfMotionPreview report={report} dealHtml={this.dealHtml} />
          )}
        </div>
      )
    )
  }
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(PreviewReport)
