import React from 'react'
import QuestionSections from '../onboarding/questionnaire/sections'
import { getClient } from '../../api'
import { OnBoardingContainer } from '../onboarding/style'

export default function ClientQuestionnaire(props) {
  const getPerson = async () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const result = await getClient(id)
    const person = result.clients[0]
    return person
  }

  return (
    <OnBoardingContainer>
      <QuestionSections getPerson={getPerson} {...props} loginRole="Expert" />
    </OnBoardingContainer>
  )
}
