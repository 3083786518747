import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

class NormalSelect extends Component {
  static propTypes = {
    options: PropTypes.array,
    initialValue: PropTypes.string,
    extraAttr: PropTypes.object,
    placeholder: PropTypes.string
  }

  state = {
    value: undefined
  }

  triggerChange = (value) => {
    const { onChange, onSelectChange } = this.props
    if (onChange) {
      onChange(value)
    }
    if (onSelectChange) {
      onSelectChange(value)
    }
  }

  componentDidMount() {
    const { value, initialValue } = this.props
    if (!value && initialValue) {
      this.triggerChange(initialValue)
    }
  }

  render() {
    const {
      options,
      placeholder,
      disabled,
      initialValue,
      value,
      extraAttr
    } = this.props
    return (
      <Select
        onChange={this.triggerChange}
        value={value || initialValue}
        placeholder={placeholder}
        disabled={disabled}
        {...extraAttr}
      >
        {options &&
          options.map(
            (item, index) =>
              item && (
                <Select.Option
                  value={item.value || item}
                  key={index}
                  disabled={item.disabled}
                >
                  {item.leftExtra && (
                    <img
                      src={item.leftExtra}
                      alt="extra"
                      style={{ marginRight: 10, width: 28 }}
                    />
                  )}
                  {item.label || item}
                </Select.Option>
              )
          )}
      </Select>
    )
  }
}

export default NormalSelect
