import React, { Component } from 'react'
// import PropTypes from 'prop-types'
// import { Popover, Typography } from 'antd'
import { Element } from 'react-scroll'
import Biomarker from '../biomarker/bloodTest'

// import icInfo from '../../../../asserts/icon/ic-info.svg'

import { categoriesStyle } from './index.sass'
// const { Paragraph } = Typography

class Category extends Component {
  static propTypes = {}

  renderSubCategory(category, index) {
    const { datasetId } = this.props
    return (
      <div className="sub-category-container" key={index}>
        <div className="top">
          <div className="name">
            <span>{index > 8 ? index + 1 : '0' + (index + 1)}.</span>
            <div>{category.name}</div>
          </div>
          <div className="description">{category.description}</div>
        </div>
        <div className="bottom">
          {category.bio_markers.length > 0 &&
            category.bio_markers.map((item, index) => (
              <Biomarker
                key={index + '-' + datasetId}
                index={index}
                biomarker={item}
                {...this.props}
              />
            ))}
        </div>
      </div>
    )
  }

  render() {
    const { category } = this.props
    return (
      <div className={`${categoriesStyle} category-container`}>
        <Element name={`category_${category.name}`}>
          <div className="root-category root-title">
            {/* <div className="mobile-show">
              {category && (
                <div className="left">
                  {category.name}
                  <Popover
                    placement="topLeft"
                    content={
                      <div style={{ maxWidth: '487px' }}>
                        <span className="title">Definition</span>
                        <p>{category.description}</p>
                      </div>
                    }
                  >
                    <span style={{ marginLeft: '-20px', paddingLeft: '20px' }}>
                      <img src={icInfo} alt="info" />
                    </span>
                  </Popover>
                </div>
              )}
              <div className="right" />
            </div> */}
            {category && (
              <>
                <h1>{category.name}</h1>
                <div className="description">
                  <p>{category.description}</p>
                </div>
              </>
            )}
          </div>
          {category &&
            category.sub_categories.map((item, index) =>
              this.renderSubCategory(item, index)
            )}
        </Element>
      </div>
    )
  }
}

export default Category
