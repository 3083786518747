import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'moment-duration-format'
import {
  VictoryChart,
  VictoryBar,
  VictoryScatter,
  VictoryLabel,
  VictoryAxis
} from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { getAxisX, tickLabels } from '../common'
import { timesFormat } from '../../../utils/common'
import { fontFamily } from '../../../utils/constant'
import moment from 'moment'

class BedTimeBar extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    details: PropTypes.array
  }

  processBedTime(tickValuesX = [], details) {
    if (details) {
      const datas = tickValuesX.map((date) => {
        const specificDetail = details.find((detail) => {
          return detail.summary_date === date.format('YYYY-MM-DD')
        })
        if (specificDetail) {
          const bedTimeStart =
            moment(specificDetail.bedtime_start).unix() -
            moment(specificDetail.summary_date).unix()
          const bedtime_end =
            moment(specificDetail.bedtime_end).unix() -
            moment(specificDetail.summary_date).unix()
          return {
            x: date,
            y: Number(bedtime_end) || null,
            y0: Number(bedTimeStart) || 0,
            noData: false
          }
        } else {
          return {
            x: date,
            y: 0,
            y0: 0,
            noData: true
          }
        }
      })
      return datas
    } else {
      return tickValuesX.map((date) => {
        return {
          x: date,
          y: 0,
          y0: 0,
          noData: true
        }
      })
    }
  }

  processNoDataScatter(tickValuesX = [], datas, min, max) {
    const allNoData = (min === 0) & (max === 0)
    if (allNoData) {
      return tickValuesX.map((date) => {
        return { x: date, y: 0.5 }
      })
    } else {
      const middle = (max - min) / 2
      return datas
        .filter((data) => data.noData)
        .map((data) => {
          return data.noData && { x: data.x, y: middle }
        })
    }
  }

  tickFormat = (t, min) => {
    const format = new Intl.NumberFormat('en-IN', { minimumIntegerDigits: 2 })
    let a = 'AM',
      times
    t += min
    if (t >= 0) {
      times = timesFormat(t)
    } else {
      const addOneDay = t + 86400
      times = timesFormat(addOneDay)
    }
    let { hours, minutes } = times
    if (hours >= 12) {
      hours = format.format(hours > 12 ? hours - 12 : 12)
      a = 'PM'
      /* eslint-disable-next-line eqeqeq */
    } else if (hours == 0) {
      hours = 12
      a = 'AM'
    }
    hours = format.format(hours)
    minutes = format.format(minutes)
    return `${hours}:${minutes} ${a}`
  }

  render() {
    const { startDate, endDate, details } = this.props
    const tickValuesX = startDate && endDate && getAxisX(startDate, endDate)
    let datas = []
    if (tickValuesX) {
      datas = this.processBedTime(tickValuesX, details)
    }
    let min, max
    if (datas.length > 0) {
      const minArr = datas.filter((data) => !data.noData).map((data) => data.y0)
      min = minArr.length > 0 ? Math.min.apply(null, minArr) : 0
      max = Math.max.apply(null, datas.map((data) => data.y))
      datas = datas.map((data) => {
        if (!data.noData) {
          data.y -= min
          data.y0 -= min
        }
        return data
      })
    } else {
      min = 0
      max = 1
    }
    const nodataScatter = this.processNoDataScatter(
      tickValuesX,
      datas,
      min,
      max
    )
    const allNoData = (min === 0) & (max === 0)
    if (allNoData) {
      max = 1
    }

    const axis = allNoData ? { fill: 'transparent' } : tickLabels
    const gridStroke = allNoData ? 'transparent' : '#B7BFC6'
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart
            width={width}
            height={60}
            // padding={{ top: 50, bottom: 50, left: 80, right: 50 }}
          >
            {tickValuesX && (
              <VictoryAxis
                style={{
                  axis: {
                    stroke: 'transparent'
                  },
                  tickLabels: {
                    fill: 'transparent'
                  }
                }}
                tickValues={tickValuesX}
                tickLabelComponent={<VictoryLabel text={''} />}
              />
            )}
            <VictoryAxis
              dependentAxis
              orientation="right"
              tickValues={[0, max - min]}
              tickFormat={(t) => this.tickFormat(t, min)}
              style={{
                axis: { stroke: 'transparent' },
                grid: {
                  stroke: gridStroke,
                  strokeDasharray: '3,3'
                },
                tickLabels: { ...axis, padding: 5, fontSize: 8 }
              }}
            />
            {datas && (
              <VictoryBar
                offsetX={10}
                data={datas}
                barWidth={16}
                cornerRadius={{ top: 2, bottom: 2 }}
                style={{
                  data: {
                    fill: '#5C00D1'
                  }
                }}
              />
            )}
            {nodataScatter && (
              <VictoryScatter
                style={{ data: { fill: '#C4C4C4' } }}
                size={1}
                data={nodataScatter}
              />
            )}
            <VictoryAxis
              offsetY={20}
              tickValues={tickValuesX}
              tickFormat={(x) => x.format && x.format('ddd').toUpperCase()}
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: {
                  fontFamily: `"Gilroy",${fontFamily}`,
                  fontSize: '10px',
                  fill: '#70808E'
                }
              }}
            />
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default BedTimeBar
