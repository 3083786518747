import axios, { hashRequest } from './axios'

export const getHealthStatus = async (personId, quarters, hash) => {
  let url = `/health_status?person_id=${personId}`
  if (quarters) {
    // 2019-3 or '2019-3,2019-4'
    url += `&quarters=${quarters}`
  }
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  return axios.get(url)
}

export const getExportTxtForBodyComps = (person_id, type) => {
  return axios.get(`/body_comps/export-txt?person_id=${person_id}&type=${type}`)
}

/*
params: {
  person_id: number,
  quarter: string,
  type: string,
  score: number
}
*/
export const saveScore = (params) => axios.putJson('/health_status', params)

export const saveScoreBulk = (params) =>
  axios.putJson('/health_status/bulk', params)
