import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import successImage from '../../../asserts/images/success.png'

const Container = styled.div`
  width: 431px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #52606c;
  max-height: calc(100% - 148px);
  overflow: auto;
  .title {
    color: #323f4a;
    font-size: 36px;
    line-height: 1.5;
  }
  img {
    margin: 46px;
  }
  .desc {
    white-space: pre-wrap;
    line-height: 140%;
    letter-spacing: 0.3px;
  }
  button {
    margin-top: 46px;
    padding: 0 32px;
  }
`

export default function Successful(props) {
  const { description, history, btnText, path, renderBtn } = props //history

  return (
    <Container>
      <div className="title">Success</div>

      <img src={successImage} alt="success" />

      <p className="desc">{description}</p>

      {btnText && (
        <Button
          size="large"
          type="primary"
          onClick={() => path && history.push(path)}
        >
          {btnText}
        </Button>
      )}
      {renderBtn && renderBtn()}
    </Container>
  )
}
