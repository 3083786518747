import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryArea,
  VictoryScatter
} from 'victory'
// import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { fontFamily } from '../../../utils/constant'
import ContainerWidthSizer from '../../../components/UI/ContainerWidthSizer'

const fontSize = 10
const chartPadding = {
  top: 50,
  left: 50,
  bottom: 50,
  right: 30
}

export default function BodyChart(props) {
  const { series, name, dateFormat } = props
  const lineGradient_id = `lineGradient_body_comp_${name.split(' ').join('_')}}`

  const data = (series || []).map(({ test_date, value }) => ({
    x: test_date,
    y: value
  }))

  const tickValuesX = data.map((item) => item.x)

  while (tickValuesX.length < 4) {
    tickValuesX.push(
      moment()
        .add(tickValuesX.length, 'day')
        .format('YYYY-MM-DD')
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <svg style={{ height: 200, position: 'absolute' }}>
        <defs>
          <linearGradient
            id={lineGradient_id}
            x1="0%"
            y1="0%"
            x2="0%"
            y2="100%"
          >
            <stop offset="0" stopColor="#7BAD2D" stopOpacity="0.2" />
            <stop offset="0.875" stopColor="#7BAD2D" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>

      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart width={width} height={200} padding={chartPadding}>
            <VictoryAxis
              name="axis_x"
              tickValues={tickValuesX}
              tickFormat={(t) =>
                _.find(data, { x: t })
                  ? moment(t).format(dateFormat || 'M/D/YYYY')
                  : null
              }
              style={{
                grid: {
                  stroke: '#E4E7EB',
                  strokeDasharray: '6, 3'
                },
                axis: { stroke: '#CBD2D9' },
                tickLabels: {
                  fill: (t) => {
                    const target = series.find((item) => item.test_date === t)
                    if (target) {
                      return target.axisColor || '#94A0AA'
                    }
                    return '#94A0AA'
                  }
                }
              }}
            />
            <VictoryAxis
              dependentAxis
              tickValues={[0, 100, 200]}
              offsetX={35}
              style={{
                axis: {
                  stroke: 'transparent'
                },
                grid: {
                  stroke: '#E4E7EB',
                  strokeDasharray: '6, 3'
                },
                tickLabels: {
                  fontFamily,
                  fontSize,
                  fill: '#7BAD2D'
                }
              }}
            />
            <VictoryArea
              name="data"
              style={{
                data: {
                  fill: `url(#${lineGradient_id})`
                },
                labels: {
                  fill: 'transparent',
                  fontSize
                }
              }}
              data={data}
            />

            <VictoryLine
              name="data"
              data={data}
              labels={(datum) => datum.y}
              style={{
                data: {
                  stroke: '#7BAD2D'
                },
                labels: {
                  fill: '#7BAD2D',
                  fontSize
                }
              }}
            />
            <VictoryScatter
              name="data"
              data={data}
              dataComponent={<Scatter />}
              style={{
                labels: {
                  fill: 'transparent',
                  fontSize
                }
              }}
            />
            {/* </VictoryGroup> */}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    </div>
  )
}

function Scatter(props) {
  return (
    <svg
      width="12"
      height="12"
      x={props.x - 6}
      y={props.y - 6}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="4.5"
        fill="white"
        stroke="#7BAD2D"
        strokeWidth="3"
      />
    </svg>
  )
}
