import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from 'react-stripe-elements'
import { Button, Checkbox, Row, Col } from 'antd' // Input, Form, Icon
import AttachInformation from './info'
import { saveStripeSource } from '../../../api/people'
import promptIcon from '../../../asserts/icon/icon-payment-tips.svg'
import promptIconError from '../../../asserts/icon/icon-payment-tip-error.svg'
import bankInfoCardBG from '../../../asserts/icon/payment-bank-info-card.svg'
import cardLogo from '../../../asserts/icon/payment-card-logo.svg'
import supportBanksIcon from '../../../asserts/icon/payment-support-banks.svg'
import cvvIcon from '../../../asserts/icon/payment-bank-info-cvv.svg'
import editIcon from '../../../asserts/icon/payment-bank-edit.svg'

class EditCreditCard extends Component {
  static propTypes = {
    confirm: PropTypes.func,
    setPropsState: PropTypes.func,
    agreePrivacy: PropTypes.bool,
    loading: PropTypes.bool,
    customer: PropTypes.any,
    changeCard: PropTypes.bool,
    loginRole: PropTypes.string,
    client: PropTypes.object
  }

  state = {
    name: '',
    validInfo: {}
  }
  handleSubmit = async (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault()

    const {
      setPropsState,
      customer,
      changeCard,
      client,
      successCallback
    } = this.props
    // const { name } = this.state
    if (customer && !changeCard) {
      setPropsState({ finished: true })
      successCallback && successCallback()
      return
    }
    setPropsState({ loading: true })
    this.setState({ error: false })
    try {
      const { source, error } = await this.props.stripe.createSource({
        type: 'card'
        // owner: {
        //   name
        // }
      })
      if (error) {
        throw error
      }
      await saveStripeSource(source.id, client && client.id)
      setPropsState({ finished: true, loading: false })
      successCallback && successCallback()
    } catch (err) {
      console.error(err)
      setPropsState({ loading: false })
      this.setState({ error: true })
    }
  }

  validate = (changeObject) => {
    const { elementType } = changeObject
    const { validInfo } = this.state
    validInfo[elementType] = changeObject.error
    this.setState({
      validInfo
    })
  }

  componentDidMount() {
    const env = process.env.REACT_APP_WORKFLOW
    let marketingOrigin = 'https://staging.apeiron.life'
    if (env === 'production' || env === 'matterme') {
      marketingOrigin = 'https://www.apeiron.life'
    }
    this.setState({ marketingOrigin })
  }

  render() {
    const {
      agreePrivacy,
      loading,
      setPropsState,
      customer,
      changeCard
      // skipPayment,
      // loginRole
    } = this.props
    const { validInfo, error, marketingOrigin } = this.state //name
    const injectStyle = {
      style: {
        base: {
          color: '#4C6072',
          '::placeholder': {
            color: 'rgba(76,96,114, .6)'
          }
        }
      }
    }
    let card
    if (customer && customer.sources) {
      const {
        sources: {
          data: [item]
        }
      } = customer

      card = item.card || item
    }
    return (
      <form onSubmit={this.handleSubmit} className="main-content">
        <div
          className={`tips-content ${card ? 'hascard' : ''} ${error ? 'error' : ''
            }`}
        >
          {error ? (
            <>
              <img src={promptIconError} alt="error:" />
              <p>
                THE CREDIT CARD YOU PROVIDED WAS DECLINED. PLEASE TRY PAYING
                AGAIN WITH A DIFFERENT CARD
              </p>
            </>
          ) : (
            <>
              <img src={promptIcon} alt="tip" />
              <p>Your card will be charged on your program start date.</p>
            </>
          )}
        </div>
        <div className="bank-info">
          <div className="bank-info-detail">
            <div className="bank-info-input">
              <div className="sub-title">
                {card ? 'PROVIDED PAYMENT METHOD' : 'PAYMENT METHOD'}
              </div>
              {customer && customer.sources && !changeCard ? (
                <>
                  {customer.name && (
                    <div className="stripe-element-input prefill">
                      {customer.name}
                    </div>
                  )}
                  <div className="stripe-element-input prefill">
                    XXXXXXXXXXXXX-{card.last4}
                  </div>
                  <div
                    className="edit-button"
                    onClick={() => setPropsState({ changeCard: true })}
                  >
                    <img
                      src={editIcon}
                      alt=""
                      style={{ width: 14, height: 14, marginRight: 8 }}
                    />
                    Change Credit Card
                  </div>
                </>
              ) : (
                <>
                  {/* <Form.Item>
                    <Input
                      className="credit-card-name"
                      value={name}
                      placeholder="First Name / Last Name"
                      onChange={e => this.setState({ name: e.target.value })}
                    />
                  </Form.Item> */}
                  <CardNumberElement
                    onChange={this.validate}
                    className={`stripe-element-input ${validInfo.cardNumber ? 'invalid' : ''
                      }`}
                    {...injectStyle}
                  />
                  {validInfo.cardNumber && (
                    <div className="invalid-message">
                      {validInfo.cardNumber.message}
                    </div>
                  )}
                  <Row gutter={16}>
                    <Col span={8}>
                      <label className="stripe-element-label">
                        Expiry Date{' '}
                      </label>
                      <CardExpiryElement
                        onChange={this.validate}
                        className={`stripe-element-input ${validInfo.cardExpiry ? 'invalid' : ''
                          }`}
                        {...injectStyle}
                      />
                      {validInfo.cardExpiry && (
                        <div className="invalid-message">
                          {validInfo.cardExpiry.message}
                        </div>
                      )}
                    </Col>
                    <Col span={8}>
                      <label className="stripe-element-label">
                        Security Code{' '}
                      </label>
                      <CardCvcElement
                        onChange={this.validate}
                        className={`stripe-element-input ${validInfo.cardCvc ? 'invalid' : ''
                          }`}
                        {...injectStyle}
                      />
                      {validInfo.cardCvc && (
                        <div className="invalid-message">
                          {validInfo.cardCvc.message}
                        </div>
                      )}
                    </Col>
                    <Col span={8}>
                      <img src={cvvIcon} alt="cvv-icon" className="cvv-icon" />
                    </Col>
                  </Row>
                </>
              )}
            </div>
            <div
              className="bank-info-card"
              style={{ background: `url(${bankInfoCardBG})` }}
            >
              <div className="bank-info-card-logo">
                <img src={cardLogo} alt="" />
              </div>
              <div className="bank-info-card-support-banks">
                <span style={{ marginBottom: 20 }}>SUPPORTED CREDIT CARDS</span>
                <img src={supportBanksIcon} alt="" />
              </div>
            </div>
          </div>
          <AttachInformation />
        </div>
        <div className="privacy bottom-line">
          <div>
            <Checkbox
              checked={agreePrivacy}
              onChange={(e) =>
                setPropsState({ agreePrivacy: e.target.checked })
              }
            />
            I agree to the Apeiron Life{' '}
            <a target="_bank" href={`${marketingOrigin}/terms-of-use`}>
              Terms of Service
            </a>{' '}
            and{' '}
            <a href={`${marketingOrigin}/privacy-policy`} target="_bank">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="confirm">
          <Button
            htmlType="submit"
            type="primary"
            className="confirm-button"
            // onClick={e => {
            //   e.stopPropagation()
            //   this.props.confirm()
            // }}
            loading={loading}
            disabled={!agreePrivacy}
          >
            SUBMIT
          </Button>
          {/* {skipPayment && loginRole !== 'Expert' && (
            <span className="skip-button" onClick={skipPayment}>
              OR SKIP <Icon type="right" />
            </span>
          )} */}
        </div>
      </form>
    )
  }
}

export default injectStripe(EditCreditCard)
