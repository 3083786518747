import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { animateScroll as scroll } from 'react-scroll'
import { containerStyle } from './index.sass'

class RightHalfModalContainer extends Component {
  static propTypes = {
    extraClass: PropTypes.string
  }

  componentDidMount() {
    scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
  }

  render() {
    return (
      <div className={`${this.props.extraClass} ${containerStyle}`}>
        <div className="modal-detail-container">{this.props.children}</div>
      </div>
    )
  }
}

export default RightHalfModalContainer
