import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Empty } from 'antd'
import PageLoading from '../../UI/status/loading'
import MobileCategories from './mobileCategories'
import FileManager from '../files/management'
import LockStatus from '../../UI/lockStatus'
import { userType } from '../../../utils/constant'

class MobileContainer extends Component {
  static propTypes = {}

  render() {
    const {
      dataset,
      dataLoading,
      switchData,
      dbField,
      person,
      hash
    } = this.props
    let type = dbField && (dbField.fileType || dbField.type)
    if (type === 'blood_test') {
      type = 'bio_marker'
    }
    const isPartner = person.profile.account_type === userType.Partners
    if (isPartner) {
      return <LockStatus />
    }
    return (
      <div>
        {dataLoading ? (
          <PageLoading />
        ) : (
          <>
            {dbField && (
              <FileManager
                title="Assessment files"
                type={dbField.fileType || dbField.type}
                person={person}
                editable={false}
                mobile={true}
                hash={hash}
              />
            )}
            {!switchData && dataset && dataset.snapshot.length > 0 ? (
              <MobileCategories {...this.props} />
            ) : (
              <Empty style={{ marginTop: 50 }} />
            )}
          </>
        )}
      </div>
    )
  }
}

export default MobileContainer
