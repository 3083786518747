import React, { useState } from 'react'
import Icon from '@ant-design/icons'
import { Modal, Tree, Button } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'
import { TreeSwitcherArrow } from '../../icons/trendsIcons'

export const CheckedColor = {
  Sleep: '#5c00d1',
  Exercise: '#FD8F3E',
  Nutrition: '#7BAD2D',
  Healthspan: '#1D5FF5',
  'Blood Metrics': '#1D5FF5'
}

const Container = styled.div`
  font-family: Gilroy;
  .ant-col-5 {
    max-width: 20%;
    border-right: 1px solid #e3e7eb;
    padding-right: 4px !important;
    :last-child {
      border-right: none;
    }
    .ant-tree-title {
      font-size: 12px;
      color: #616e7c;
      font-family: Gilroy;
      font-weight: 500;
      word-break: break-word;
    }
  }
`
const Header = styled.div`
  font-family: Gilroy;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: rgba(50, 63, 74, 1);
  padding: 16px 0;
  border-bottom: 1px solid #e3e7eb;
`
const Footer = styled.div`
  font-family: Gilroy;
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    :last-child {
      width: 86px;
      height: 34px;
      font-weight: 600;
    }
  }
`
const Body = styled.div`
  font-family: Gilroy;
  padding: 8px 15px 8px 19px;
  border-bottom: 1px solid #e3e7eb;
`

const TreeContainer = styled.div`
  max-height: 365px;
  min-height: 310px;
  margin-top: 10px;
  font-family: Gilroy;
  .ant-tree {
    max-height: 310px;
    overflow: auto;
    margin-top: 12px;
    overflow-y: overlay;
    ::-webkit-scrollbar {
      width: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: #afafaf;
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #5c626a;
    }
    ::-webkit-scrollbar-corner {
      background: #179a16;
    }
    .ant-tree-indent {
      margin-left: -24px;
    }
    .ant-tree-list-holder-inner .ant-tree-treenode:first-child {
      position: sticky;
      top: 0;
      background: #ffffff;
      z-index: 9;
      .ant-tree-indent {
        margin-left: 0;
      }
      .ant-tree-switcher {
        display: none;
      }
    }
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: ${(props) => props.inputColor || '#5c00d1'};
    border-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: ${(props) => props.inputColor || '#5c00d1'};
  }
  .title,
  .top-title {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #616e7c;
  }
  .top-title {
    background: #ffffff;
    white-space: nowrap;
  }
  .ant-tree-switcher {
    order: 5;
  }
  .ant-tree-switcher-noop {
    width: 0px;
  }
  .ant-tree .ant-tree-treenode {
    width: 100%;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    flex-grow: 1;
  }
  .ant-tree-switcher_close .ant-tree-switcher-icon svg {
    transform: rotate(-180deg);
  }
`

export default function ARTrendsMetricsEditor(props) {
  const { allMetrics, apply, onCancel } = props

  const [selectedMetrics, setSelectedMetrics] = useState(
    props.selectedMetrics ||
      _.flattenDeep(recursiveFn(allMetrics[0].children, (e) => e))
  )
  const [expandedKeys, setExpandedKeys] = useState([])

  return (
    <>
      <Modal
        open={true}
        footer={null}
        onCancel={props.onCancel}
        width={300}
        height={541}
        zIndex={9999}
        bodyStyle={{
          padding: '0px 9px'
        }}
      >
        <Container>
          <Header>Select Metrics</Header>
          <Body>{allMetrics.map((e, index) => renderMetrics(e, index))}</Body>
          <Footer>
            <div>
              <Button
                type="primary"
                ghost
                style={{
                  border: 'none',
                  boxShadow: 'none'
                }}
                onClick={() => setSelectedMetrics([])}
              >
                Clear all
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  apply(selectedMetrics)
                  onCancel()
                }}
              >
                Apply
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  )

  function renderTreeNode(children, category, length) {
    const result = children.map((child) => {
      if (child.children) {
        return {
          title: child.category,
          key: `${child.category}_ignore`,
          children: renderTreeNode(child.children)
        }
      } else {
        return {
          title: child.name,
          key: child.metric_key
        }
      }
    })

    return category
      ? [
          {
            title: `${category}${length > 0 ? ` (${length})` : ''}`,
            key: category,
            titleClassName: 'top-title',
            children: result
          }
        ]
      : result
  }

  function renderMetrics(metric, index) {
    return (
      <TreeContainer inputColor={CheckedColor[metric.category]} key={index}>
        <Tree
          checkable
          switcherIcon={<Icon component={TreeSwitcherArrow} />}
          treeData={renderTreeNode(
            metric.children,
            metric.category,
            selectedMetrics.length
          )}
          selectable={false}
          onCheck={(_, e) => {
            onCheck(e.node, metric.category, e.checked)
          }}
          checkedKeys={selectedMetrics.map((e) => e.metric_key)}
          expandedKeys={[...expandedKeys, metric.category]}
          onExpand={(keys) => setExpandedKeys(keys)}
          autoExpandParent={false}
          titleRender={({ titleClassName, title }) => (
            <div className={titleClassName}>{title}</div>
          )}
        />
      </TreeContainer>
    )
  }

  function onCheck(node, category, checked) {
    const allKeys = node.children
      ? _.flattenDeep(recursiveFn(node.children, (e) => e.key))
      : [node.key]

    if (checked) {
      const shouldSearchCateMetrics = _.flattenDeep(
        recursiveFn(
          allMetrics.find((e) => e.category === category).children,
          (e) => e
        )
      )
      const shouldDisplayMetrics = shouldSearchCateMetrics
        .filter((metric) => allKeys.includes(metric.metric_key))
        .map(({ ranges, unit, name, metric_key, up_color }) => {
          return {
            add_metrics: [],
            categories: [category],
            metric_key,
            name,
            unit,
            ranges,
            up_color
          }
        })

      setSelectedMetrics([...selectedMetrics, ...shouldDisplayMetrics])
    } else {
      setSelectedMetrics(
        selectedMetrics.filter((metric) => !allKeys.includes(metric.metric_key))
      )
    }
  }

  function recursiveFn(children, returnBlock) {
    return children.map((child) => {
      if (child.children) {
        return recursiveFn(child.children, returnBlock)
      } else {
        return returnBlock(child)
      }
    })
  }
}
