import axios, { formDataRequest } from './axios'

export const createNewCheckin = params => axios.postJson('/checkin', params)

export const getCheckinInfo = email =>
  axios.postJson('/checkin/info', { email })

export const commitRecord = params => axios.postJson('/checkin/record', params)

export const getFacilityList = () => axios.get('/checkin/facility-list')

export const getFacilityDetail = id =>
  axios.get(`/checkin/facility-detail/${id}`)

// const saveRecord = () => axios.post
export const saveCheckInRecord = (params, id) => {
  const { facility_id, service_id, file, expert_id } = params
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
  }
  formData.set('facility_id', facility_id)
  formData.set('service_id', service_id)
  formData.set('expert_id', expert_id)
  // axios.postJson(`/checkin/${id}/record`, params)
  return formDataRequest({
    method: 'post',
    path: `/checkin/${id}/record`,
    data: formData
  })
}
