import React from 'react'
import styled from 'styled-components'
import phsLogo from '../../../asserts/icon/latest-logo.svg'
import { clearStorage } from '../../../utils/storage'
import { logOut } from '../../../api/people'
import { LogoutIcon } from '../../../components/icons/onboarding'
const SimpleHeaderContainer = styled.header`
  background: #ffffff;
  height: 74px;
  position: fixed;
  width: 1440px;
  padding: 0 32px;
  max-width: 100%;
  top: 0;
  border: 1px solid #eaedf3;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  img {
    width: 150px;
    margin: 21px auto auto 23px;
  }
  div {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #264382;
    cursor: pointer;
    .anticon {
      margin-right: 8px;
      vertical-align: bottom;
    }
  }
`

export default function SimpleHeader(props) {
  const { history } = props
  return (
    <SimpleHeaderContainer>
      <img src={phsLogo} alt="logo" />
      <div
        className="log-out"
        onClick={() => {
          clearStorage()
          logOut()
          history.push('/sign-in')
        }}
      >
        <LogoutIcon />
        LOG OUT
      </div>
    </SimpleHeaderContainer>
  )
}
