// export const SET_CLIENT_HEADER = 'SET_CLIENT_HEADER'
export const SET_CLIENT_PEOPLE = 'SET_CLIENT_PEOPLE'
export const SET_CLIENT_BREADCRUMB = 'SET_CLIENT_BREADCRUMB'
export const SET_CLIENT_PAGE_TITLE = 'SET_CLIENT_PAGE_TITLE'
export const SET_CLIENT_BIOMARKER = 'SET_CLIENT_BIOMARKER'
export const SET_CLIENT_BIOMARKER_CATEGORIES = 'SET_CLIENT_BIOMARKER_CATEGORIES'
export const SET_CLIENT_SELECT_CATEGORY = 'SET_CLIENT_SELECT_CATEGORY'
export const SET_CLIENT_REPORT_ID = 'SET_CLIENT_REPORT_ID'
export const SET_CLIENT_BODY_BOMP_ID = 'SET_CLIENT_BODY_BOMP_ID'
export const SET_CLIENT_LIFE_STYLE_ID = 'SET_CLIENT_LIFE_STYLE_ID'
export const SET_CLIENT_UNREAD_MESSAGE_COUNT = 'SET_CLIENT_UNREAD_MESSAGE_COUNT'
export const SET_CLIENT_LET_NAVIGATE_BAR_HIDDEN =
  'SET_CLIENT_LET_NAVIGATE_BAR_HIDDEN'
