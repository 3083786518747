export function getInterpolateColor(colors, r) {
  const canvas = document.createElement('canvas')
  canvas.width = 100
  canvas.height = 1
  const ctx = canvas.getContext('2d')
  const grd = ctx.createLinearGradient(0, 1, 100, 1)
  for (const { color, offset } of colors) {
    grd.addColorStop(offset, color)
  }
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, 100, 1)
  var x = parseInt(r * 100)
  var colorData = ctx.getImageData(x, 0, 1, 1).data
  canvas.remove()
  return {
    r: colorData[0],
    g: colorData[1],
    b: colorData[2],
    a: colorData[3],
    color: `rgba(${colorData[0]}, ${colorData[1]}, ${colorData[2]}, ${colorData[3]})`
  }
}
