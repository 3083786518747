import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { Modal, Empty } from 'antd'
import Categories from '../categories'
import BloodTestCategories from '../categories/bloodTest'
import PageLoading from '../../../UI/status/loading'
import { setCurrentCategory } from '../../../../redux/slideMenu/action'
import { Trash } from '../../../icons'
import { Style } from './index.sass'
import { getDbFields } from '../common'

const confirm = Modal.confirm

// client view dataset container
class Container extends Component {
  static propTypes = {}

  componentDidMount() {
    // const { categories, setCurrentCategory, slideMenu } = this.props
    window.addEventListener('scroll', this.orderScroll)
    // if (categories && categories[0]) {
    //   setCurrentCategory(categories[0].name)
    // }
  }

  componentDidUpdate() {
    const {
      categories,
      setCurrentCategory,
      slideMenu: { groups, currentCategory },
      type
    } = this.props
    if (categories && categories[0]) {
      const dbFields = getDbFields(type)
      const categoryNames = _.get(
        groups[dbFields.groupType || dbFields.type],
        'categories',
        []
      ).map((item) => item.name)

      if (
        !currentCategory ||
        (dbFields.type !== 'blood_test' &&
          !_.includes(categoryNames, currentCategory) &&
          currentCategory !== categories[0].name)
      ) {
        setCurrentCategory(categories[0].name)
      }
    }
  }

  orderScroll = (e) => {
    const {
      slideMenu: { currentCategory },
      setCurrentCategory
    } = this.props
    const elementList = e.target.querySelectorAll('div[name]')
    const viewHeight = window.innerHeight
    const scrollTop = document.scrollingElement.scrollTop
    let _currentCategory = ''
    for (let element of elementList) {
      if (viewHeight - element.offsetTop + scrollTop > 0) {
        const name = element.getAttribute('name')
        if (name.includes('category_')) {
          _currentCategory = name.replace('category_', '')
        }
      }
    }
    if (_currentCategory !== currentCategory) {
      setCurrentCategory(_currentCategory)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.orderScroll)
  }

  renderCategories() {
    const { type } = this.props
    if (type === 'blood-test') {
      return <BloodTestCategories {...this.props} />
    } else {
      return <Categories {...this.props} />
    }
  }

  render() {
    const {
      datasets,
      dataLoading,
      setWrapperState,
      showDataset,
      deleteDataset
      // type
    } = this.props
    // let conventionalStatus, functionalStatus
    // if (type === 'blood-test' && !dataLoading && datasets) {
    //   const status = this.props.personalBioMarkerPreferenceStatus()
    //   conventionalStatus = status.conventionalStatus
    //   functionalStatus = status.functionalStatus
    // }
    return (
      <div className={Style}>
        <div className="data-set-action-container">
          {!dataLoading && datasets && (
            <>
              <div>
                {datasets.length > 0 && (
                  <>
                    VERSIONS <br />
                  </>
                )}
                {datasets.map((item, index) => (
                  <span
                    key={index}
                    className={`date-set-tab ${
                      showDataset === item.test_date ? 'active' : ''
                    }`}
                  >
                    <span
                      onClick={() => {
                        setWrapperState({
                          showDataset: item.test_date,
                          personalBioMarkers: item,
                          linkId: item.id
                        })
                      }}
                    >
                      {moment(item.test_date.substr(0, 10)).format(
                        'MMM DD,YYYY'
                      )}
                    </span>
                    <span
                      onClick={() => {
                        confirm({
                          title: (
                            <span>
                              Do you want to delete dataset: <br />
                              {moment(item.test_date.substr(0, 10)).format(
                                'MMM DD,YYYY'
                              )}
                              ?{' '}
                            </span>
                          ),
                          okText: 'Yes',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk() {
                            deleteDataset(item, index)
                          }
                        })
                      }}
                    >
                      <Trash />
                    </span>
                  </span>
                ))}
              </div>
              {/* comment on sprint 23 */}
              {/* {!dataLoading && type === 'blood-test' && datasets.length > 0 && (
                <PerferCheckbox
                  onPreferChange={this.props.personalBioMarkerPreferenceChanged}
                  conventionalStatus={conventionalStatus}
                  functionalStatus={functionalStatus}
                />
              )} */}
            </>
          )}
        </div>
        <div>
          {dataLoading ? (
            <PageLoading />
          ) : datasets && datasets.length > 0 ? (
            this.renderCategories()
          ) : (
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                paddingTop: 50,
                clear: 'right'
              }}
            >
              <Empty />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  ({ slideMenu, client }) => ({ slideMenu, client }),
  (dispatch) => ({
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category))
  })
)(Container)
