import React from 'react'
import styled from 'styled-components'
import { autoSource } from '../../../models/goals.form'
import checkIcon from '../../../asserts/icon/client/check.png'
import warningIcon from '../../../asserts/icon/client/warning.png'
import _ from 'lodash'
import moment from 'moment'
import { Button } from 'antd'
import { EditPanIcon } from '../../icons'

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  font-family: Gilroy;
  padding: 14px 0px;
  .basic-info-container {
    color: #323f4a;
    .name-container {
      display: flex;
      align-items: center;
      button {
        margin-left: 8px;
      }
    }
    .info-summary {
      font-size: 14px;
      color: #52606c;
    }
  }
  .devices-container {
    display: flex;
    align-items: center;
    .item {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-images {
        position: relative;
        cursor: pointer;
        .status {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 12px;
        }
      }
      .sync-info {
        margin-top: 8px;
        color: #60656d;
        &.error {
          color: #e75f25;
        }
      }
    }
  }
`

export const ClientInfo = ({
  person,
  deviceInfo,
  loginRole,
  editProfile,
  disconnect,
  connect
}) => {
  const gender = _.get(person, 'profile.gender[0]', '-').toUpperCase()
  const age =
    person.profile && person.profile.date_of_birth
      ? `${moment().diff(person.profile.date_of_birth, 'years')}`
      : '-'
  const dateOfBirth = _.get(person, 'profile.date_of_birth', '-')
  const infoSummary = _.compact([
    age + ' ' + gender,
    moment(dateOfBirth).format('MM/DD/YYYY'),
    _.get(person, 'profile.city'),
    _.get(person, 'profile.state')
  ]).join(', ')

  return (
    <Container>
      <>
        <h1 className="title" style={{ display: 'flex', alignItems: 'center' }}>
          <div className="avatar-container">
            {person.avatar && (
              <img
                className="avatar-small"
                src={person.avatar.oss_url}
                alt=""
              />
            )}
          </div>
          <div className="basic-info-container">
            <div className="name-container">
              {`${(person.profile && person.profile.nickname) ||
                person.first_name} ${person.last_name}`}
              {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
                <Button type="primary" onClick={editProfile}>
                  <EditPanIcon />
                  EDIT
                </Button>
              )}
            </div>
            <div className="info-summary">{infoSummary}</div>
          </div>
        </h1>
      </>
      <div className="devices-container">
        {autoSource.map((item, index) => {
          const device = _.get(deviceInfo, item.value, {})
          return (
            <div key={index} className="item">
              <div
                className="item-images"
                onClick={() => {
                  if (item.value === 'healthkit') {
                    return
                  }
                  if (device.connection_status === 'Connected') {
                    disconnect(item.service)
                  } else {
                    connect(item.service)
                  }
                }}
              >
                <img src={item.leftExtra} alt="" style={{ width: 48 }} />
                {device.connection_status === 'Connected' && (
                  <img className="status" src={checkIcon} alt="check" />
                )}
                {device.connection_status === 'Disconnect' && (
                  <img className="status" src={warningIcon} alt="warning" />
                )}
              </div>
              <span
                className={`sync-info ${
                  device.connection_status === 'Disconnect' ? 'error' : ''
                }`}
              >
                {device.last_sync
                  ? moment(
                      device.last_sync.toString().substr(0, 15),
                      'ddd MMM D YYYY'
                    ).format('MM/DD/YY')
                  : '--'}
              </span>
            </div>
          )
        })}
      </div>
    </Container>
  )
}
