import React from 'react'
import { Row, Col } from 'antd'
import _, { includes } from 'lodash'
import { QuestionContainer } from './style'
import SelectQuestion from './select'
import InlineEntries from './inlineEntries'
import TextEntry from './textEntry'
import NumberEntry from './number'
import Emergency from './emergency'
import ResidenceAddress from './residenceAddress'
import SingleChoices from './singleChoices'
import IntroSingleChoice from './introSingleChoices'
import YesOrNo from './yesOrNO'
import LevelBar from './levelBar'
import MultiChoice from './multiChoice'
import Slider from './slider'
import TimeOrDatePicker from './timeOrDatePicker'
import AdditionalForm from './additionalForm'
import LabelLevelBar from './labelLevelBar'
import SectionTitle from './sectionTitle'
import WeeklyActivities from './WeeklyActiviyGroup'
import QuestionGroup from './group'
import classNames from 'classnames'

export default function Question(props) {
  const {
    question,
    answers,
    updateAnswer,
    sessionId,
    setConfirmBtnDisabled
  } = props

  const { grid_wrapper, options_layout, type } = question

  if (
    ['description', 'sub_title', 'banner', 'section_title'].includes(type) ||
    typeof question === 'string'
  ) {
    return renderQuestionWithoutAnswer(type)
  }

  return (
    <QuestionContainer>
      <div
        className={`question ${question.hide_title ? 'hide-title' : ''} ${
          question.type === 'question_group' ? 'q-group' : ''
        }`}
      >
        <Row>
          {question.type !== 'sub_title' &&
            question.type !== 'banner' &&
            !question.hide_title && (
              <Col
                span={grid_wrapper ? grid_wrapper[0] : 24}
                className="q-title"
              >
                {question.id && <span>{question.id}</span>}
                {question.required && <i className="required">*</i>}
                <p
                  dangerouslySetInnerHTML={{
                    __html: question.question_html || question.question
                  }}
                />
              </Col>
            )}
          <Col
            span={grid_wrapper ? grid_wrapper[1] : 24}
            className={classNames('q-body', options_layout, {
              'pl-0': type === 'weekly_activiy_group'
            })}
          >
            {renderQuestion()}
          </Col>
        </Row>
      </div>
    </QuestionContainer>
  )

  function renderQuestion() {
    const answer = answers.find((item) => {
      if (item.question_original_id) {
        return item.question_original_id === question.original_id
      }
      return item.question_id === question.id
    })
    return renderQuestionByType(answer)
  }

  function renderQuestionWithoutAnswer(type) {
    let element
    switch (type) {
      case 'description':
        element = (
          <div
            dangerouslySetInnerHTML={{
              __html: question.question_html || question.question
            }}
          />
        )
        break
      case 'banner':
        element = (
          <div className="introduction banner ">
            <p dangerouslySetInnerHTML={{ __html: question.question }} />
          </div>
        )
        break
      case 'sub_title':
        element = subTitle()
        break
      case 'section_title':
        element = <SectionTitle question={question} />
        break
      default:
        break
    }

    if (typeof question === 'string') {
      element = (
        <div className="introduction bottom-line">
          {question.includes('\n') ? <pre>{question}</pre> : <p>{question}</p>}
        </div>
      )
    }

    return (
      <QuestionContainer type={type} hasExtraInfo={!!question.extra_info}>
        <div className="question">{element}</div>
      </QuestionContainer>
    )
  }

  function renderQuestionByType(answer) {
    const { type, allow_multiple_answer } = question
    if (!answer) {
      const isArrayAnswer = type === 'multi_choice' || allow_multiple_answer
      answer = {
        session_id: sessionId,
        question_original_id: question.original_id,
        answer: isArrayAnswer
          ? []
          : {
              value: null
            }
      }
      if (includes(['question_group', 'weekly_activiy_group'], question.type)) {
        answer.answer = {}
      }
    }
    const childProps = { question, answer, update: updateAnswer }

    if (props.extraInfo) {
      _.assignIn(childProps, props.extraInfo)
    }
    switch (type) {
      case 'text_entry':
        return <TextEntry {...childProps} />
      case 'number':
        return <NumberEntry {...childProps} />
      case 'select':
        return <SelectQuestion {...childProps} />
      case 'inline-entries':
        return <InlineEntries {...childProps} />
      case 'introductory_emergency':
        return <Emergency {...childProps} />
      case 'residence_address':
        return <ResidenceAddress {...childProps} />
      case 'intro_single_choice':
        return <IntroSingleChoice {...childProps} />
      case 'yes_no':
        return <YesOrNo {...childProps} />
      case 'single_choice':
        return <SingleChoices {...childProps} />
      case 'level_bar':
        return <LevelBar {...childProps} />
      case 'label_level_bar':
        return <LabelLevelBar {...childProps} />
      case 'multi_choice':
        return <MultiChoice {...childProps} />
      case 'slider':
        return <Slider {...childProps} />
      case 'date':
      case 'time':
        return <TimeOrDatePicker {...childProps} />
      case 'additional_form':
        return <AdditionalForm {...childProps} />
      case 'question_group':
        return <QuestionGroup {...childProps} />
      case 'weekly_activiy_group':
        return (
          <WeeklyActivities
            {...childProps}
            setConfirmBtnDisabled={setConfirmBtnDisabled}
          />
        )
      default:
        return <div>{type}</div>
    }
  }

  function subTitle() {
    return (
      <div className="q-sub-title">
        <div className="container">
          <img src={question.icon} alt="icon" className="icon" />
          <div className="content">{question.question}</div>
        </div>
      </div>
    )
  }
}
