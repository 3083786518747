import axios from './axios'

export const getAll = (personId, version) => {
  let url = `/timeline?person_id=${personId}&versionable=true`
  if (version) {
    url += `&v=${version}`
  }
  return axios.get(url)
}

/*
{
  timeline_id: Joi.number().required(),
  name: Joi.string().required(),
  client_id: Joi.number().required(),
  planned_date: Joi.date()
    .allow(null)
    .optional(),
  completed_date: Joi.date()
    .allow(null)
    .optional(),
}
*/
export const createItem = (params) => axios.postJson('/timeline/item', params)

export const updateItem = (params) => axios.putJson('/timeline/item', params)

export const deleteItem = (id) => axios.delete(`/timeline/item/${id}`)

/*
{
   client_id: Joi.number().required(),
   timeline_id: Joi.number().required(),
}
*/
export const markAsDone = (params) =>
  axios.putJson('/timeline/mark-as-done', params)

export const markAsUnDone = (params) =>
  axios.putJson('/timeline/mark-as-undone', params)

/*
  {
  note: Joi.alternatives(Joi.string(), Joi.object().unknown()).required(), // JSON data
  timeline_id: Joi.number().required(),
  client_id: Joi.number().required(),
}
 */
export const saveNote = (params) =>
  axios.postJson('/timeline/leave-note', params)

export const getActivities = (id, personId, version) => {
  let url = `/timeline/${id}?person_id=${personId}`
  if (version) {
    url += `&v=${version}`
  }
  return axios.get(url)
}

export const getReport = (year) => axios.get(`/timeline/report?year=${year}`)

export const exportReport = (year) => axios.get(`/timeline/export?year=${year}`)
