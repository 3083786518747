import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import BloosTestContainer from '../../../components/business/dataset/bloodTestContainer'
import { getTitleByType } from '../../../components/business/dataset/common'
import { getBreadCrumbList } from '../../../models/breadcrumb.model'

class BloodTest extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientBreadcrumb, setClientPageTitle } = this.props
    setClientPageTitle(getTitleByType('blood-test'))
    const person = await getPerson()
    this.setState({ person })
    setClientBreadcrumb(getBreadCrumbList(person, 'blood-test'))
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    const {
      match: {
        params: { category }
      }
    } = this.props
    return (
      (person && (
        <BloosTestContainer {...this.props} {...{ person, category }} />
      )) || <div />
    )
  }
}

export default BloodTest
