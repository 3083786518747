import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryArea
} from 'victory'
// import ChatArea from './area'
import { GoalsMethods } from '../../../utils/common'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}
const green = '#7BAD2D'
const red = '#E75F25'
class ProgressBar extends Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    period: PropTypes.string,
    achievingData: PropTypes.object,
    autoRange: PropTypes.string
  }

  xAxisFormat = (x) => {
    const { period } = this.props
    if (period === 'weekly') {
      return moment(x).format('MMM D')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).format('ddd') === 'Sun') {
        result = moment(x).format('M/D')
      }
      return result
    }
  }

  render() {
    const {
      period,
      achievingData: { entries },
      startDate,
      endDate,
      auto_range,
      unit,
      fixed_target
    } = this.props
    const { data, areaData } = GoalsMethods.getChatData({
      entries,
      startDate,
      endDate,
      auto_range
    })
    let { values, max, min, target } = GoalsMethods.getAxisyTickValues(
      auto_range
    )
    if (fixed_target) {
      if (values) {
        values.push(fixed_target)
      } else {
        values = [0, fixed_target]
      }
    }
    const gridStroken = (t) => {
      if (moment(t).format('ddd') === 'Sun') {
        return 'rgba(0,0,0,.5)'
      } else {
        return 'rgba(0,0,0,.1)'
      }
    }
    return (
      <>
        <div
          style={{
            position: 'absolute',
            paddingRight: period === 'monthly' ? 10 : 25
          }}
        >
          <Area
            {...{
              values,
              min,
              max,
              target,
              areaData,
              data,
              fixed_target,
              unit
            }}
          />
        </div>
        <VictoryChart domainPadding={{ x: [10, 0], y: [0, 10] }}>
          <VictoryAxis
            style={{
              axis,
              ticks: {
                strokeWidth: 0
              },
              grid: {
                stroke: gridStroken,
                strokeDasharray
              },
              tickLabels: {
                fontFamily: 'Lato',
                fontSize: '18px',
                fill: (x) => {
                  if (
                    moment(x).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD')
                  ) {
                    if (moment(x).format('ddd') === 'Sun') {
                      return '#3561C0'
                    }
                    return 'rgba(53,97,192,.6)'
                  }
                  return '#B0BAC9'
                },
                padding: (x) => {
                  if (
                    period === 'monthly' &&
                    moment(x).format('ddd') !== 'Sun' &&
                    moment(x).format('YYYY-MM-DD') ===
                      moment().format('YYYY-MM-DD') &&
                    moment(x).date() % 5 !== 0
                  ) {
                    return 0
                  } else {
                    return 10
                  }
                }
              }
            }}
            tickValues={data.map((item) => {
              return item.x
            })}
            tickLabelComponent={
              <VictoryLabel
                text={(datum) => {
                  let result = this.xAxisFormat(datum)
                  if (period === 'weekly') {
                    result = result.split(' ').join('\n')
                  }
                  return result
                }}
              />
            }
          />
          <VictoryAxis
            dependentAxis
            tickValues={values}
            style={{
              axis: {
                stroke: 'transparent'
              },
              grid: {
                stroke: 'transparent'
              },
              ticks: {
                strokeWidth: 0
              },
              tickLabels: {
                fill: 'transparent'
              }
            }}
            tickFormat={(y) => GoalsMethods.formatVal(y, unit)}
          />
          <VictoryAxis
            dependentAxis
            style={{
              axis: {
                strokeDasharray,
                stroke: '#808080'
              },
              ticks: {
                strokeWidth: 0
              },
              tickLabels: {
                fontFamily: 'Lato',
                fontSize: unit === 'Minutes' ? '16px' : '18px',
                fill: (v) => {
                  if (v < 2e-9) {
                    return 'transparent'
                  }
                  return '#B0BAC9'
                }
              }
            }}
            tickFormat={(y) => GoalsMethods.formatVal(y, unit)}
          />

          <VictoryBar
            offsetX={10}
            style={{
              data: {
                fill: (t) => {
                  if (target || min || max) {
                    return t.isSuccess ? green : red
                  }
                  return '#264382'
                }
              },
              axis
            }}
            data={data}
          />
        </VictoryChart>
      </>
    )
  }
}

export default ProgressBar

function Area(props) {
  const { values, min, max, target, areaData, data, unit, fixed_target } = props
  const _tickLabels = {
    fontFamily: 'Lato',
    fontSize: '18px',
    fill: (t) => (t === fixed_target ? '#C0C7D1' : 'transparent')
  }
  return (
    <VictoryChart domainPadding={{ x: [0, 0], y: [0, 10] }}>
      <VictoryAxis
        dependentAxis
        tickValues={values}
        orientation="right"
        tickLabelComponent={<VictoryLabel dy={-10} />}
        style={{
          axis: {
            stroke: 'transparent'
          },
          grid: {
            stroke: (t) =>
              [max, min, target, fixed_target].find((item) => item === t)
                ? '#FFB800'
                : 'transparent',
            strokeDasharray: (t) =>
              t === fixed_target ? null : strokeDasharray
          },
          ticks: {
            strokeWidth: 0
          },
          tickLabels: _tickLabels
        }}
      />
      {fixed_target && (
        <VictoryAxis
          dependentAxis
          tickValues={values}
          orientation="right"
          tickLabelComponent={<VictoryLabel dy={10} />}
          style={{
            axis: {
              stroke: 'transparent'
            },
            grid: {
              stroke: (t) =>
                [max, min, target, fixed_target].find((item) => item === t)
                  ? '#FFB800'
                  : 'transparent',
              strokeDasharray: (t) =>
                t === fixed_target ? null : strokeDasharray
            },
            ticks: {
              strokeWidth: 0
            },
            tickLabels: _tickLabels
          }}
          tickFormat={(y) => unit}
        />
      )}
      <VictoryAxis
        dependentAxis
        style={{
          axis: {
            strokeDasharray,
            stroke: 'transparent'
          },
          ticks: {
            strokeWidth: 0
          },
          tickLabels: {
            fill: 'transparent'
          }
        }}
      />
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: 'transparent'
          },
          axis
        }}
        data={data}
      />
      {areaData && (
        <VictoryArea
          style={{ data: { fill: 'rgba(123, 173, 45, 0.1)' } }}
          padding={{ left: 0, right: 0 }}
          data={areaData}
        />
      )}
    </VictoryChart>
  )
}
