import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Modal, Input, Typography, Button } from 'antd'
import TouchTextInput from './touchInput'
import AttachThumb from './thumb'
import { attachStyle, linkModalStyle } from './index.sass'
import { ModalStyled } from '../commonStyled'

const { Title } = ModalStyled

const { Paragraph } = Typography

class LinkModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    editAttachTitle: PropTypes.func,
    removeAttach: PropTypes.func
  }

  state = {
    value: '',
    adding: false
  }

  saveLink = async () => {
    const { value, adding } = this.state
    if (adding) return
    try {
      this.setState({ adding: true })
      await this.props.saveLink(value)
      this.setState({ value: '', adding: false })
    } catch (err) {
      console.error(err)
      this.setState({ adding: false })
    }
  }

  render() {
    const { visible, closeModal, removeAttach, editAttachTitle } = this.props
    const { value } = this.state
    const attachments = this.props.attachments
      ? this.props.attachments.filter((item) => item.type === 'url')
      : []

    return (
      <Modal
        visible={visible}
        onCancel={closeModal}
        className={`${attachStyle} ${linkModalStyle}`}
        footer={null}
        width={667}
        bodyStyle={{ padding: '36px 40px' }}
      >
        <Title> Attach Link</Title>
        <div className="ant-form-item ant-form-item-link-container">
          <div className="ant-form-item-control-wrapper control">
            <div className="ant-form-item-label">
              <label> Input Link </label>
            </div>
            <div className="ant-form-item-control link-input">
              <Input
                value={value}
                onChange={(e) => this.setState({ value: e.target.value })}
              />
            </div>
          </div>
          <Button
            className={`add ${value.length > 0 ? '' : 'unclickable'}`}
            onClick={this.saveLink}
            disabled={value.length <= 0}
            style={{ marginTop: 10 }}
          >
            + ADD
          </Button>
        </div>
        {attachments.length > 0 && (
          <div
            className="separator"
            style={{
              marginBottom: '15px'
            }}
          />
        )}
        <div className="attach-list">
          {attachments.map((item, index) => (
            <div className="link-item" key={index}>
              <AttachThumb attach={item} />
              <div className="name">
                <div className="title">
                  <TouchTextInput
                    placeholder="Click to add a title"
                    initialValue={item.title}
                    save={(title) => editAttachTitle(item, title)}
                    showIcon={true}
                  />
                </div>
                <Paragraph ellipsis>{item.url}</Paragraph>
              </div>
              <CloseOutlined onClick={() => removeAttach(item)} />
            </div>
          ))}
        </div>
      </Modal>
    )
  }
}

export default LinkModal
