import React from 'react'
import Icon from '@ant-design/icons'

const address = props => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.66694 15.3335C5.57854 15.3335 5.49377 15.2984 5.43127 15.2359C5.20927 15.0142 0.000268909 9.76185 0.000268909 6.00019C-0.00703584 5.254 0.134549 4.51385 0.416739 3.82304C0.698929 3.13222 1.11606 2.50463 1.64372 1.97697C2.17138 1.44931 2.79897 1.03218 3.48978 0.749991C4.18059 0.467801 4.92075 0.326216 5.66694 0.333521C6.41312 0.326216 7.15328 0.467801 7.84409 0.749991C8.5349 1.03218 9.16249 1.44931 9.69016 1.97697C10.2178 2.50463 10.6349 3.13222 10.9171 3.82304C11.1993 4.51385 11.3409 5.254 11.3336 6.00019C11.3336 9.76185 6.1246 15.0142 5.9026 15.2359C5.84011 15.2984 5.75533 15.3335 5.66694 15.3335Z" />
    <path
      d="M5.66699 8C6.77156 8 7.66699 7.10457 7.66699 6C7.66699 4.89543 6.77156 4 5.66699 4C4.56242 4 3.66699 4.89543 3.66699 6C3.66699 7.10457 4.56242 8 5.66699 8Z"
      fill="white"
    />
  </svg>
)

export const AddressIcon = props => <Icon component={address} {...props} />

const whiteAddress = props => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 22.4993C8.36741 22.4993 8.24026 22.4466 8.14652 22.3528C7.81353 22.0204 0.000403345 14.1422 0.000403345 8.5C-0.0105533 7.38077 0.201814 6.27059 0.625078 5.23443C1.04834 4.19826 1.67401 3.25691 2.46546 2.46546C3.25691 1.67401 4.19826 1.04834 5.23443 0.625078C6.27059 0.201813 7.38077 -0.0105533 8.5 0.000403345C9.61923 -0.0105533 10.7294 0.201813 11.7656 0.625078C12.8017 1.04834 13.7431 1.67401 14.5345 2.46546C15.326 3.25691 15.9517 4.19826 16.3749 5.23443C16.7982 6.27059 17.0106 7.38077 16.9996 8.5C16.9996 14.1422 9.18647 22.0204 8.85348 22.3528C8.75974 22.4466 8.63259 22.4993 8.5 22.4993Z"
      fill="white"
    />
    <path
      d="M8.49996 11.5C10.1567 11.5 11.4998 10.1569 11.4998 8.5001C11.4998 6.84333 10.1567 5.50024 8.49996 5.50024C6.84319 5.50024 5.50011 6.84333 5.50011 8.5001C5.50011 10.1569 6.84319 11.5 8.49996 11.5Z"
      fill="#223064"
    />
  </svg>
)

export const WhiteAddressIcon = props => (
  <Icon component={whiteAddress} {...props} />
)

const addUser = props => (
  <svg
    width="11"
    height="16"
    viewBox="0 0 11 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6.58933 12.3328C6.01533 11.8768 5.042 11.3335 3.66667 11.3335C2.29133 11.3335 1.318 11.8768 0.744667 12.3322C0.271333 12.7075 0 13.2755 0 13.8908V15.3335H7.33333V13.8908C7.33333 13.2755 7.062 12.7075 6.58933 12.3328Z" />
    <path d="M3.66671 10.6667C4.95537 10.6667 6.00004 9.622 6.00004 8.33333C6.00004 7.04467 4.95537 6 3.66671 6C2.37804 6 1.33337 7.04467 1.33337 8.33333C1.33337 9.622 2.37804 10.6667 3.66671 10.6667Z" />
    <path d="M8.66671 6.00008V4.00008H10.6667V2.66675H8.66671V0.666748H7.33337V2.66675H5.33337V4.00008H7.33337V6.00008H8.66671Z" />
  </svg>
)

export const AddUserIcon = props => <Icon component={addUser} {...props} />

const trash = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.875 4.875V10.125C1.875 10.9538 2.54625 11.625 3.375 11.625H9.375C10.2038 11.625 10.875 10.9538 10.875 10.125V4.875"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.125 2.625H11.625"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.875 2.625V0.375H7.875V2.625"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.375 5.625V9.375"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.625 5.625V9.375"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.125 5.625V9.375"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const TrashIcon = props => <Icon component={trash} {...props} />

const camera = props => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.74984 19.7083H19.2498C19.7361 19.7083 20.2024 19.5151 20.5462 19.1713C20.89 18.8275 21.0832 18.3611 21.0832 17.8749V6.87492C21.0832 6.38869 20.89 5.92237 20.5462 5.57856C20.2024 5.23474 19.7361 5.04159 19.2498 5.04159H16.0415L14.2082 1.83325H8.70817L6.87484 5.04159H2.74984C2.26361 5.04159 1.79729 5.23474 1.45347 5.57856C1.10966 5.92237 0.916504 6.38869 0.916504 6.87492V17.8749C0.916504 18.3611 1.10966 18.8275 1.45347 19.1713C1.79729 19.5151 2.26361 19.7083 2.74984 19.7083Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M11.4583 16.9582C13.9896 16.9582 16.0417 14.9061 16.0417 12.3748C16.0417 9.84353 13.9896 7.7915 11.4583 7.7915C8.92703 7.7915 6.875 9.84353 6.875 12.3748C6.875 14.9061 8.92703 16.9582 11.4583 16.9582Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M4.12484 8.70817C4.37797 8.70817 4.58317 8.50297 4.58317 8.24984C4.58317 7.99671 4.37797 7.7915 4.12484 7.7915C3.87171 7.7915 3.6665 7.99671 3.6665 8.24984C3.6665 8.50297 3.87171 8.70817 4.12484 8.70817Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
)

export const CameraIcon = props => <Icon component={camera} {...props} />

const arrowRight = props => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23.7071 8.7071C24.0976 8.31658 24.0976 7.68342 23.7071 7.29289L17.3431 0.928931C16.9526 0.538406 16.3195 0.538406 15.9289 0.928931C15.5384 1.31946 15.5384 1.95262 15.9289 2.34314L21.5858 8L15.9289 13.6569C15.5384 14.0474 15.5384 14.6805 15.9289 15.0711C16.3195 15.4616 16.9526 15.4616 17.3431 15.0711L23.7071 8.7071ZM8.74228e-08 9L23 9L23 7L-8.74228e-08 7L8.74228e-08 9Z" />
  </svg>
)

export const ArrowRightIcon = props => (
  <Icon component={arrowRight} {...props} />
)
