import React from 'react'
import Icon from '@ant-design/icons'

const Email = (props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="18" cy="17.9999" rx="18" ry="17.9999" fill="#F2F3F6" />
    <g clipPath="url(#clip0_48392_62361)">
      <path
        d="M10.5 15.0005L18 19.0005L25.5 15.0005"
        stroke="#264382"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 24.5005H12C11.6022 24.5005 11.2206 24.3425 10.9393 24.0611C10.658 23.7798 10.5 23.3983 10.5 23.0005L10.5 13.0005C10.5 12.6027 10.658 12.2211 10.9393 11.9398C11.2206 11.6585 11.6022 11.5005 12 11.5005L24 11.5005C24.3978 11.5005 24.7794 11.6585 25.0607 11.9398C25.342 12.2211 25.5 12.6027 25.5 13.0005V23.0005C25.5 23.3983 25.342 23.7798 25.0607 24.0611C24.7794 24.3425 24.3978 24.5005 24 24.5005Z"
        stroke="#264382"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_48392_62361">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(10 10.0005)"
        />
      </clipPath>
    </defs>
  </svg>
)

export const SendQuestionnaireBtn = (props) => (
  <Icon component={Email} {...props} />
)

const Done = (props) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10.5C20 16.0228 15.5228 20.5 10 20.5C4.47715 20.5 0 16.0228 0 10.5C0 4.97715 4.47715 0.5 10 0.5C15.5228 0.5 20 4.97715 20 10.5ZM3.7664 10.5C3.7664 13.9427 6.55728 16.7336 10 16.7336C13.4427 16.7336 16.2336 13.9427 16.2336 10.5C16.2336 7.05728 13.4427 4.2664 10 4.2664C6.55728 4.2664 3.7664 7.05728 3.7664 10.5Z"
      fill="#D9D9D9"
    />
    <circle cx="10" cy="10.5" r="10" fill="#7BAD2D" />
    <path d="M5 10L8.5 13.5L14.5 7.5" stroke="white" strokeWidth="1.5" />
  </svg>
)

export const QuestionnaireDoneSmallIcon = (props) => (
  <Icon component={Done} {...props} />
)
