import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { Progress } from 'antd'
import { CheckedCircleIcon } from '../../../components/icons/onboarding'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 56px;
  padding-right: 0;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  line-height: 14px;
  .section-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #c1c5cd;
    letter-spacing: 0.3px;
    &.active {
      .section-info {
        color: #38435e;
        span,
        .anticon {
          opacity: 1;
        }
      }
      .section-progress {
        opacity: 1;
      }
    }
  }
  .section-info {
    text-transform: uppercase;
    font-weight: bold;
    span {
      color: #fff;
      width: 16px;
      height: 16px;
      display: inline-block;
      font-size: 12px;
      text-align: center;
      line-height: 16px;
      background: #98a9bc;
      border-radius: 100%;
      margin: 0 10px;
      opacity: 0.3;
    }
    .anticon {
      margin: 0 10px;
      opacity: 0.3;
      color: #05b800;
      font-size: 16px;
      vertical-align: middle;
    }
  }
  .section-progress {
    opacity: 0.3;
    width: 32px;
    margin-left: 16px;
    overflow: hidden;
    .ant-progress-inner {
      background-color: rgba(152, 169, 188, 0.2);
      border-radius: 31px;
    }
    .ant-progress-bg {
      height: 3px !important;
      background-color: #98a9bc;
    }
  }
`

class QuestionnaireProgress extends Component {
  static propTypes = {
    sections: PropTypes.array,
    step: PropTypes.number,
    answers: PropTypes.object
  }

  sectionProgress(section, isLast) {
    const answers = this.props.answers[section.id] || {}
    const requiredQuestions = section.questions.filter(
      (item) => item.id && typeof item.id === 'number' && item.required
    )
    const answeredQuestions = requiredQuestions.filter((item) => {
      const an = answers[item.id]
      const textRequired =
        an &&
        (an.textRequired ||
          Object.keys(an)
            .map((a) => an[a])
            .find(
              (a) =>
                a &&
                (a.textRequired ||
                  Object.keys(a)
                    .map((aa) => a[aa])
                    .find((aa) => aa && aa.textRequired))
            ))
      if (textRequired) return false
      if (_.isArray(an)) {
        return an.length > 0
      }
      return an !== undefined && an !== null && !an.required
    })

    const percent =
      requiredQuestions.length > 0
        ? parseInt((answeredQuestions.length / requiredQuestions.length) * 100)
        : 0

    return (
      <>
        <div className="section-info">
          {percent === 100 ? <CheckedCircleIcon /> : <span>{section.id}</span>}
          {section.name}
        </div>
        {!isLast && (
          <div className="section-progress">
            <Progress percent={percent} showInfo={false} />
          </div>
        )}
      </>
    )
  }

  render() {
    const { sections, step } = this.props
    return (
      <Container className="progress questionnaire">
        {sections.map((item, index) => (
          <div
            key={index}
            className={`${step >= index ? 'active' : ''} section-item`}
          >
            {this.sectionProgress(item, index === sections.length - 1)}
          </div>
        ))}
      </Container>
    )
  }
}

export default QuestionnaireProgress
