import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { GoalsMethods } from '../../../../utils/common'

class AvgEtc extends Component {
  static propTypes = {
    unit: PropTypes.string,
    achieving: PropTypes.array,
    aggregation: PropTypes.object
  }

  getValues = () => {
    const { achieving } = this.props
    const values = []
    for (const item of achieving) {
      for (const entry of item.entries) {
        values.push(Number(entry.input))
      }
    }
    values.sort((a, b) => a - b)
    let sum = 0
    for (const item of values) {
      sum += item
    }
    const length = values.length
    return {
      avg: length > 0 && parseInt(sum / length),
      min: values[0],
      max: values[length - 1],
      length
    }
  }

  render() {
    let { unit, aggregation } = this.props
    let { avg, min, max, lowest, highest } = aggregation
    // if value is time, display hh:mm
    // if (unit === 'Time' || 'Minutes') {
    //   ;[avg, min, max] = [avg, min, max].map(
    //     t => GoalsMethods.formatVal(parseInt(t), unit)
    //     // t ? GoalsMethods.formatVal(parseInt(t), unit) : 'n/a'
    //   )

    //   if (unit === 'Minutes') {
    //     unit = '' // hide minutes unit
    //   }
    // }
    return (
      <div className="number-statistical">
        <div>
          {avg || 0} <span>{unit}</span>
          <br />
          AVG
        </div>
        {min !== undefined && (
          <div>
            {min || 0} <span>{unit}</span>
            <br />
            Min
          </div>
        )}
        {max !== undefined && (
          <div>
            {max || 0} <span>{unit}</span>
            <br />
            Max
          </div>
        )}
        {lowest !== undefined && (
          <div>
            {lowest || 0} <span>{unit}</span>
            <br />
            Lowest
          </div>
        )}
        {highest !== undefined && (
          <div>
            {highest || 0} <span>{unit}</span>
            <br />
            Highest
          </div>
        )}
      </div>
    )
  }
}

export default AvgEtc
