import React from 'react'
import PropTypes from 'prop-types'

PolygonAvatar.propTypes = {
  image: PropTypes.string,
  hasBorder: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number
}

function PolygonAvatar(props) {
  const {
    icon,
    image: showImage,
    hasBorder,
    width,
    height,
    innerId,
    hexId,
    iconX,
    iconY
  } = props
  const hrefLink = null

  return (
    <div
      className="polygon-avatar"
      style={{ width: width + 'px', height: height || width + 'px' }}
    >
      <svg
        className="svg-graphic"
        width="106"
        height="112"
        viewBox="0 0 104 116"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
      >
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
        >
          <clipPath id={hexId}>
            <path
              opacity="0.1"
              d="M44 2.6188C48.9504 -0.239323 55.0496 -0.239323 60 2.6188L95.9615 23.3812C100.912 26.2393 103.962 31.5214 103.962 37.2376V78.7624C103.962 84.4786 100.912 89.7607 95.9615 92.6188L60 113.381C55.0496 116.239 48.9504 116.239 44 113.381L8.03847 92.6188C3.08806 89.7607 0.038475 84.4786 0.038475 78.7624V37.2376C0.038475 31.5214 3.08806 26.2393 8.03848 23.3812L44 2.6188Z"
              fill="#264382"
            />
          </clipPath>

          <clipPath id={innerId}>
            <path
              transform="translate(-1,4)"
              d="M45 4.6188C49.9504 1.76068 56.0496 1.76068 61 4.6188L90.8993 21.8812C95.8498 24.7393 98.8993 30.0214 98.8993 35.7376V70.2624C98.8993 75.9786 95.8498 81.2607 90.8993 84.1188L61 101.381C56.0496 104.239 49.9504 104.239 45 101.381L15.1007 84.1188C10.1502 81.2607 7.10065 75.9786 7.10065 70.2624V35.7376C7.10065 30.0214 10.1502 24.7393 15.1007 21.8812L45 4.6188Z"
              fill="#C4C4C4"
            />
          </clipPath>
        </g>
        <a transform={`scale(${width ? width / 106 : 1})`} href={hrefLink}>
          {hasBorder ? (
            <>
              <path
                opacity="0.1"
                d="M44 2.6188C48.9504 -0.239323 55.0496 -0.239323 60 2.6188L95.9615 23.3812C100.912 26.2393 103.962 31.5214 103.962 37.2376V78.7624C103.962 84.4786 100.912 89.7607 95.9615 92.6188L60 113.381C55.0496 116.239 48.9504 116.239 44 113.381L8.03847 92.6188C3.08806 89.7607 0.038475 84.4786 0.038475 78.7624V37.2376C0.038475 31.5214 3.08806 26.2393 8.03848 23.3812L44 2.6188Z"
                fill="#264382"
              />
              {showImage && (
                <image
                  y="5"
                  clipPath={`url(#${innerId})`}
                  height="100%"
                  width="100%"
                  xlinkHref={showImage}
                  preserveAspectRatio="xMidYMin slice"
                />
              )}
            </>
          ) : showImage ? (
            <image
              clipPath={`url(#${hexId})`}
              height="100%"
              width="100%"
              xlinkHref={showImage}
              preserveAspectRatio="xMidYMin slice"
            />
          ) : (
            <>
              <path
                opacity="0.1"
                d="M44 2.6188C48.9504 -0.239323 55.0496 -0.239323 60 2.6188L95.9615 23.3812C100.912 26.2393 103.962 31.5214 103.962 37.2376V78.7624C103.962 84.4786 100.912 89.7607 95.9615 92.6188L60 113.381C55.0496 116.239 48.9504 116.239 44 113.381L8.03847 92.6188C3.08806 89.7607 0.038475 84.4786 0.038475 78.7624V37.2376C0.038475 31.5214 3.08806 26.2393 8.03848 23.3812L44 2.6188Z"
                fill="#264382"
              />
              {icon && <image y={iconY} x={iconX} xlinkHref={icon} />}
            </>
          )}
        </a>
      </svg>
    </div>
  )
}

export default PolygonAvatar
