import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { message, Input, Typography } from 'antd'
import { EditPanIcon } from '../../icons'

const { Paragraph } = Typography

export class TouchTextInput extends Component {
  static propTypes = {
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    save: PropTypes.func,
    editType: PropTypes.string,
    showIcon: PropTypes.bool
  }

  state = {
    editing: false,
    value: '',
    style: null
  }

  save = async () => {
    const { value } = this.state
    try {
      await this.props.save(value)
      this.setState({ editing: false, value: value })
    } catch (err) {
      console.error(err)
      message(err.message)
    }
  }

  onKeyDown = async (event) => {
    const target = event.target
    if (event.keyCode === 13) {
      target && target.blur()
      this.save()
    }
  }

  render() {
    const { editing, value } = this.state
    const { initialValue, placeholder, showIcon } = this.props
    return (
      <div style={{ marginBottom: '10px' }}>
        {!editing ? (
          <div
            onClick={() =>
              this.setState({ value: initialValue, editing: true })
            }
          >
            {showIcon && <EditPanIcon style={{ float: 'right' }} />}
            {value || initialValue ? (
              <Paragraph ellipsis>{value || initialValue}</Paragraph>
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </div>
        ) : (
          <Input
            value={value}
            autoFocus={true}
            placeholder={placeholder}
            onChange={(event) => this.setState({ value: event.target.value })}
            onBlur={() =>
              this.setState({ editing: false, value: initialValue })
            }
            onKeyDown={this.onKeyDown}
            style={{ marginBottom: '15px' }}
          />
        )}
      </div>
    )
  }
}

export default TouchTextInput
