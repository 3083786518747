import React, { useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import { Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { animateScroll as scroll } from 'react-scroll'
import { ErrorIcon } from '../../../components/icons/onboarding'
import PageLoading from '../../../components/UI/status/loading'
import Questions from './questions'
// import Successful from './successful'
import NavBar from './navBar'
import { OnboardingHeader } from '../style'
import AnswerUpdater, {
  verifyAnswer,
  checkCompleted
} from '../questionComponentsV2/answerUpdater'
import { loadUser } from '../../../utils'
import {
  getSectionDetails,
  getRecordSessions,
  completeNewSessionEmail
} from '../../../api'
import RecordCompletedPage from './RecordCompletedPage'

const initialState = {
  sections: [],
  answers: [],
  answerUpdater: null,
  loading: false,
  saveCount: 0,
  // pageTitle: '',
  showNavBar: false
}

export default function QuestionnaireRecord(props) {
  const {
    history,
    match: { params }
  } = props
  const person = loadUser()
  const [pageLoading, setPageLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(false)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater } = state
      setSaveLoading(answerUpdater.isLoading())
      return _.assignIn(state, { answers: answerUpdater._answers })
    } else if (action.type === 'check answers') {
      return _.assign({}, state, { answers: action.answers })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  useEffect(() => {
    const initial = async () => {
      try {
        const { section_ids, person_id, record_date } = JSON.parse(
          window.atob(params.hash)
        )
        if (Number(person_id) !== person.id) throw new Error()
        setPageLoading(true)
        setShowSuccess(false)
        const sections = await getSectionDetails(person_id, section_ids)
        const answers = await getRecordSessions(section_ids, record_date)
        let showNavBar = false,
          activeStep = 0
        if (sections.length > 1) {
          showNavBar = true
          activeStep = Math.max(
            answers.findIndex((answerInfo) => answerInfo.completion_rate < 100),
            0
          )
        }
        dispatch({
          type: 'initial',
          payload: {
            sections,
            answers,
            activeStep,
            showNavBar,
            answerUpdater: new AnswerUpdater({
              answers,
              sections,
              saveCallback: () => dispatch({ type: 'update answers' })
            })
          }
        })
        setPageLoading(false)
      } catch (err) {
        history.push('/404')
      }
    }

    initial()
  }, [params.hash])

  const { sections, answers, answerUpdater, showNavBar, activeStep } = state

  const { original_id, questions, name } = sections[activeStep] || {}
  const answerInfo = answers.find(
    (item) => item.section_original_id === original_id
  )

  useEffect(() => {
    setConfirmBtnDisabled(saveLoading || showError)
  }, [saveLoading, showError])

  return (
    <div className="main-container">
      <OnboardingHeader />
      {showSuccess && showSuccess.success ? (
        <RecordCompletedPage
          destinations={showSuccess.destinations}
          history={history}
        />
      ) : (
        <>
          {showNavBar && <NavBar activeStep={activeStep} sections={sections} />}
          <div className={`main-content questionnaire ${params.section}`}>
            <h1 className="title">{!showNavBar && name}</h1>
            {pageLoading && <PageLoading />}
            {showError && (
              <div className="error-message">
                <ErrorIcon />
                Error: Please answer all required questions.
              </div>
            )}

            {original_id && (
              <div className="section-container">
                <Questions
                  {...{
                    original_id,
                    questions,
                    answerInfo,
                    updateAnswer,
                    setConfirmBtnDisabled
                  }}
                />
              </div>
            )}
          </div>
          <footer>
            {activeStep > 0 ? (
              <span
                className="back"
                onClick={() =>
                  dispatch({
                    type: 'update',
                    payload: { activeStep: activeStep - 1 }
                  })
                }
              >
                <LeftOutlined /> back
              </span>
            ) : (
              <span></span>
            )}

            <Button
              type="primary"
              disabled={confirmBtnDisabled}
              loading={saveLoading}
              onClick={onConfirm}
            >
              {activeStep === sections.length - 1 ? 'CONFIRM' : 'NEXT'}
            </Button>
          </footer>
        </>
      )}
    </div>
  )

  async function updateAnswer(answer, original_id, question) {
    if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)

    if (!JSON.stringify(_answer).includes('verify')) {
      setSaveLoading(true)
      answerUpdater.autoSave(_answer, original_id)
    } else {
      const answerInfo = answers.find((item) => item.id === _answer.session_id)
      const targetIndex = answerInfo.answers.findIndex(
        (an) => an.question_original_id === _answer.question_original_id
      )
      if (targetIndex > -1) {
        answerInfo.answers[targetIndex] = _answer
      } else {
        answerInfo.answers.push(_answer)
      }
      dispatch({ type: 'check answers', answers: _.cloneDeep(answers) })
    }
  }

  async function onConfirm() {
    const { isCompleted, answers: _answers } = await checkCompleted(
      [answerInfo],
      [sections[activeStep]]
    )

    if (!isCompleted) {
      setShowError(true)
      scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
      dispatch({
        type: 'check answers',
        answers: _answers
      })
    } else {
      if (sections.length - 1 > activeStep) {
        scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
        dispatch({ type: 'update', payload: { activeStep: activeStep + 1 } })
      } else {
        setSaveLoading(true)
        try {
          await completeNewSessionEmail({
            session_ids: state.answers.map((e) => e.id)
          })
          setShowSuccess({
            success: true,
            destinations: completedDest()
          })
        } catch {
          setSaveLoading(false)
        }
      }
    }
  }

  function completedDest() {
    const nutritionDest = {
      path: '/app/nutrition-score',
      name: 'Nutrition Result',
      inAppFunc: () => {
        window.webkit.messageHandlers.nutritionScore.postMessage({})
      }
    }
    const microbiomeDest = {
      path: '/app/microbiome-analysis',
      name: 'Microbiome Analysis',
      inAppFunc: () => {
        window.webkit.messageHandlers.microbiomeResult.postMessage({})
      }
    }
    const heathCompassDest = {
      path: '/app/health-score',
      name: 'Health Compass',
      inAppFunc: () => {
        window.webkit.messageHandlers.healthScore.postMessage({})
      }
    }
    let destinations = []
    const hasNutrition = sections.find(
      (s) => s.original_id === 'nutrition_eating_habits'
    )
    const hasMicrobiome = sections.find((s) => s.original_id === 'gut_health')
    const onlyNutrition =
      sections.length === 1 &&
      sections[0].original_id === 'nutrition_eating_habits'
    const onlyMicrobiome =
      sections.length === 1 && sections[0].original_id === 'gut_health'
    if (onlyNutrition) {
      destinations.push(nutritionDest)
    } else if (onlyMicrobiome) {
      destinations.push(microbiomeDest)
    } else {
      hasNutrition && destinations.push(nutritionDest)
      hasMicrobiome && destinations.push(microbiomeDest)
      if (sections.length > 2) {
        destinations.unshift(heathCompassDest)
      }
    }

    return destinations
  }
}
