import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { animateScroll as scroll } from 'react-scroll'
import HalfPageModal from '../../UI/halfPageModal'
import Activities from './timelineActivities'
import { Button, Divider, Upload, Input } from 'antd'
import { ImageIcon, AttachIcon } from '../../icons/weeklySummary'
import { toBase64, imageRenderInfo } from '../../../utils/common'

const { TextArea } = Input

const Container = styled.div`
  letter-spacing: 0.3px;
  .ant-input {
    min-height: 44px;
    border: 1px solid #dee2ec;
    border-radius: 4px;
    font-size: 14px;
    color: #323a46;
    margin-bottom: 16px;
    padding: 10px;
  }
  .submit-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    button {
      width: 94px;
    }
    .upload-icon {
      margin-right: 25px;
    }
    .anticon {
      color: #264382;
      font-size: 18px;
      cursor: pointer;
      &.disabled {
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
`

export default function Notes(props) {
  const { notes, close, saveNote } = props
  const [text, setText] = useState('')
  const [submiting, setSubmiting] = useState(false)

  useEffect(() =>
    scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
  )

  const submit = async () => {
    setSubmiting(true)
    await saveNote(text)
    setText('')
    setSubmiting(false)
  }

  const uploadFile = (request, type) => {
    const { file } = request
    console.log(file)
    save()
    async function save() {
      setSubmiting(true)
      let note
      if (type === 'image') {
        const url = await toBase64(file)
        const { width, height } = await imageRenderInfo(url)
        note = {
          type,
          url,
          width,
          height,
          loading: true
        }
      } else {
        note = {
          type,
          name: file.name,
          loading: true
        }
      }
      await saveNote(note, file)
      setSubmiting(false)
    }
  }

  return (
    <HalfPageModal
      position="right"
      close={close}
      title="Note"
      containerStyle={{
        marginTop: -30,
        marginRight: -44,
        marginLeft: -314,
        minHeight: 'calc(100% + 110px)'
      }}
    >
      <Container>
        <TextArea
          placeholder="Leave a message"
          autoSize={{ minRows: 2 }}
          onChange={(e) => setText(e.target.value)}
          value={text}
        />
        <div className="submit-button">
          <div>
            <Upload
              className="upload-icon"
              customRequest={(request) => uploadFile(request, 'file')}
              showUploadList={false}
              // disabled={submiting || loading || !!text}
              accept=".pdf,video/*,.doc,.docx"
            >
              <AttachIcon />
            </Upload>
            <Upload
              className="upload-icon"
              customRequest={(request) => uploadFile(request, 'image')}
              showUploadList={false}
              // disabled={submiting || loading || !!text}
              accept="image/*" //,.pdf,video/*,.doc,.docx
            >
              <ImageIcon
              // className={submiting || loading || text ? 'disabled' : ''}
              />
            </Upload>
          </div>

          <Button
            type="primary"
            size="large"
            onClick={submit}
            disabled={submiting || !text}
          >
            Submit
          </Button>
        </div>
      </Container>
      <Divider />
      {notes && <Activities list={notes} />}
    </HalfPageModal>
  )
}
