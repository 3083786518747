import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import NoMatch from '../../noMatch'
import ExpertView from './expert'
import Services from './expert/services'

class FacilitiesManagement extends Component {
  static propTypes = {}

  render() {
    const { match } = this.props
    return (
      <Switch>
        <Route exact path={`${match.url}`} component={ExpertView} />
        <Route path={`${match.url}/:id`} component={Services} />
        <Route component={NoMatch} />
      </Switch>
    )
  }
}

export default FacilitiesManagement
