import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import axios from 'axios'
import _ from 'lodash'
import { Modal, message } from 'antd' // message
import BasicForm from '../../formV4' //BasicForm
import { programActionModel } from '../../../models/goals.form'
import {
  attachFile,
  attachLink,
  removeAttachment,
  editAttachment
} from '../../../api/microGoals'
import Attachments from '../../UI/attachment'
import { modalContainer } from '../style/actionEditor.sass'

class ActionItemEditor extends Component {
  static propTypes = {}

  state = {
    valueForm: {}
  }

  uploadFile = async (file, callBack) => {
    const { editGoal, person, addAttachSync, pillar } = this.props
    try {
      const CancelToken = axios.CancelToken
      const source = CancelToken.source()
      this.setState({ source, file })
      const result = await attachFile({
        goalId: editGoal.id,
        personId: person.id,
        files: [file],
        source,
        progressCallback: (percent) => {
          callBack(percent)
          if (percent === 100) {
            this.setState({ source: null })
          }
        }
      })
      addAttachSync(result.attachment, editGoal, pillar)
    } catch (err) {
      console.error(err)
      if (!axios.isCancel(err)) {
        message.error(err.message)
      }
    }
  }

  saveLink = async (value) => {
    const { editGoal, person, addAttachSync, pillar } = this.props
    const result = await attachLink(editGoal.id, person.id, value)
    addAttachSync(result.attachment, editGoal, pillar)
  }

  cancelUpload = () => {
    const { source } = this.state
    source && source.cancel()
    this.setState({ source: null })
  }

  prefixUpload = async () => {
    const { editGoal } = this.props
    try {
      if (!editGoal || !editGoal.id) {
        return await this.validate(true)
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  removeAttach = async (attach) => {
    let { removeAttachSync, editGoal, person, pillar } = this.props
    await removeAttachment(attach.id, person.id)
    removeAttachSync(attach, editGoal, pillar)
  }

  editAttachTitle = async (attach, title) => {
    const { person, editGoal, editAttachTitleSync, pillar } = this.props
    const result = await editAttachment(attach.id, person.id, { title })
    for (let index in editGoal.attachments) {
      const item = editGoal.attachments[index]
      if (item.id === attach.id) {
        editGoal.attachments[index] = result.attachment
      }
    }
    editAttachTitleSync(editGoal, pillar)
  }

  judgeActivityNeedChange = (source) => {
    const activitys = this.formData.find((item) => {
      if (item.dbField === 'auto_activity') {
        const condition =
          item.unless &&
          item.unless.and &&
          item.unless.and.find((i) => i.key === 'source')
        if (condition.value === source) {
          return true
        }
      }
      return false
    })
    const noChange =
      activitys &&
      activitys.options &&
      activitys.options.find(
        (option) => option.value === this.state.valueForm.auto_activity
      )
    return !noChange
  }

  changeSourceHandle = async (allValues, source) => {
    const { trackingOuraMetric, pillar } = this.props // trackingMetric
    const { valueForm } = this.state
    if (source === 'manual') {
      delete valueForm.auto_metric
    } else {
      if (allValues.input_type === 'text') {
        await this.formRef.setFieldsValue({
          input_type: 'number'
        })
        valueForm.input_type = 'number'
      } else if (allValues.input_type === 'binary') {
        if (
          source === 'ouraring' ||
          (source === 'any' && valueForm.auto_activity === 'Sleep')
        ) {
          const activityMetrics = trackingOuraMetric.map((item) => item.value)
          await this.formRef.setFieldsValue({
            auto_activity: 'Sleep',
            tracking_activity_metric: activityMetrics
          })
          valueForm.auto_activity = 'Sleep'
          valueForm.tracking_activity_metric = activityMetrics
        } else {
          await this.formRef.setFieldsValue({ auto_activity: 'Any' })
          valueForm.auto_activity = 'Any'
          delete valueForm.unit
        }
      }
      if (valueForm.input_type === 'number') {
        if (source === 'any') {
          await this.formRef.setFieldsValue({
            auto_metric: 'Heart Rate Zones',
            unit: 'bpm'
          })
          valueForm.unit = 'bmp'
        } else if (valueForm.source !== source || !allValues.auto_metric) {
          let dataArr
          if (source === 'ouraring') {
            dataArr = this.props.ouraMetric.filter((item) =>
              item.value.includes('Sleep')
            )
          } else {
            dataArr = this.props[source + 'Metric']
          }
          const data =
            source === 'ouraring'
              ? dataArr[0]
              : dataArr.find((item) => item.pillar === pillar)
          if (data) {
            await this.formRef.setFieldsValue({
              auto_metric: data.value,
              unit: data.unit
            })
            valueForm.unit = data.unit
          }
        }
      }
    }
  }

  changeInputTypeHandle = async (allValues, value) => {
    const { valueForm } = this.state
    const { trackingMetric, trackingOuraMetric } = this.props
    if (value === 'number' && allValues.chart_type === 'none') {
      valueForm.chart_type = 'line'
      await this.formRef.setFieldsValue({
        chart_type: 'line'
      })
    } else if (value === 'binary' && allValues.source !== 'manual') {
      if (valueForm.source !== 'ouraring') {
        valueForm.tracking_activity_metric = trackingMetric.map(
          (item) => item.value
        )
        if (this.judgeActivityNeedChange(allValues.source)) {
          valueForm.auto_activity = 'Any'
          await this.formRef.setFieldsValue({
            auto_activity: 'Any'
          })
          delete valueForm.unit
        }
      } else {
        valueForm.tracking_activity_metric = trackingOuraMetric.map(
          (item) => item.value
        )
        valueForm.auto_activity = 'Sleep'
        await this.formRef.setFieldsValue({
          auto_activity: 'Sleep'
        })
      }
    } else if (value === 'binary' || value === 'text') {
      valueForm.target_goal = 'tracking'
    }
  }

  changeAutoMetricHandle = (valueForm, value) => {
    const { healthkit, garmin, ouraring, polar, withings } = this.props
    let unitObj
    if (valueForm.source === 'healthkit') {
      unitObj = healthkit && healthkit[value]
    } else if (valueForm.source === 'garmin') {
      unitObj = garmin && garmin[value]
    } else if (valueForm.source === 'ouraring') {
      unitObj = ouraring && ouraring[value]
    } else if (valueForm.source === 'polar') {
      unitObj = polar && polar[value]
    } else if (valueForm.source === 'withings') {
      unitObj = withings && withings[value]
    }
    valueForm.unit = unitObj && unitObj.unit
  }

  updateValueForm = async (changedValue, allValues) => {
    const { trackingOuraMetric } = this.props
    const { valueForm } = this.state
    if (changedValue.input_type) {
      this.changeInputTypeHandle(allValues, changedValue.input_type)
    } else if (changedValue.source) {
      this.changeSourceHandle(allValues, changedValue.source)
    } else if (changedValue.auto_metric) {
      this.changeAutoMetricHandle(allValues, changedValue.auto_metric)
    }

    if (changedValue.auto_activity && changedValue.auto_activity === 'Sleep') {
      const activityMetrics = trackingOuraMetric.map((item) => item.value)
      await this.formRef.setFieldsValue({
        tracking_activity_metric: activityMetrics
      })
      valueForm.tracking_activity_metric = activityMetrics
    }

    _.assign(valueForm, changedValue)
    this.setState({ valueForm })
  }

  validate = async (notClose) => {
    const { requestGoal, closeModal, pillar } = this.props
    this.setState({ loading: true })

    try {
      let params
      try {
        params = await this.formRef.handleSubmit()
      } catch (err) {
        console.error(err)
        throw new Error('Form validate error')
      }
      switch (params.target_goal) {
        case 'fixed':
          params.auto_range = params.range1
          break
        case 'range':
          params.auto_range = `${params.range1},${params.range2}`
          break
        case 'greater':
          params.auto_range = `${params.range1},`
          break
        case 'lesser':
          params.auto_range = `,${params.range1}`
          break
        default:
          params.auto_range = null
          break
      }
      delete params.target_goal
      delete params.range1
      delete params.range2
      if (params.tracking_activity_metric) {
        params.tracking_activity_metric = params.tracking_activity_metric.join(
          ','
        )
      }
      if (params.input_type === 'binary') {
        params.auto_metric = null
        params.chart_type = 'none'
      }

      params.is_expert_only = this.state.isExpertOnly

      /* program feature influnce */
      params.show_program = true
      params.active = false

      if (!params.unit) {
        params.unit = this.state.valueForm.unit
      }
      this.setState({ loading: false })

      /* program feature influnce */
      await requestGoal(params, pillar)
      this.setState({ loading: false })
      if (!notClose) {
        closeModal()
      }
    } catch (err) {
      this.setState({ loading: false })
      console.error(err)
      if (err.message !== 'Form validate error') {
        message.error(err.message)
      }
      if (notClose) {
        throw err
      }
    }
  }

  componentDidMount() {
    const { editGoal, editGoalType } = this.props
    const valueForm = Object.assign({ frequency: 'overall' }, editGoal)
    if (!_.isEmpty(editGoal)) {
      switch (editGoal.period) {
        case 'daily':
          valueForm.goal_period = '0'
          break
        case 'weekly':
          valueForm.goal_period = '1'
          break
        case 'On specific days per week':
          valueForm.goal_period = '2'
          break
        case 'monthly':
          valueForm.goal_period = '3'
          break
        default:
          break
      }
      if (editGoal.goal_type === 'heartRateMetrics') {
        valueForm.auto_metric = 'Heart Rate Zones'
      }
    } else {
      // default form values is manual goal
      valueForm.source = 'manual'
      valueForm.input_type = 'number'
      valueForm.target_goal = 'tracking'
      valueForm.chart_type = 'line'
    }
    if (editGoalType === 'question') {
      valueForm.input_type = 'binary'
    }
    if (valueForm.input_type === 'binary') {
      valueForm.target_goal = 'tracking'
    }
    if (valueForm.auto_metric === 'Sleep') {
      valueForm.auto_activity = 'Sleep'
    }
    if (valueForm.auto_metric) {
      const metricUnit = this.props[valueForm.source]
      valueForm.unit = metricUnit && metricUnit[valueForm.auto_metric].unit
    }
    if (
      valueForm.tracking_activity_metric &&
      typeof valueForm.tracking_activity_metric === 'string'
    ) {
      valueForm.tracking_activity_metric = valueForm.tracking_activity_metric.split(
        ','
      )
    }

    this.setState({ valueForm })
  }

  render() {
    const { valueForm } = this.state
    const {
      categories,
      editGoal,
      healthkitMetric,
      garminMetric,
      ouraMetric,
      polarMetric,
      withingsMetric,
      polarActivities,
      healthkitActivities,
      garminActivities,
      trackingMetric,
      ouraActivities,
      trackingOuraMetric
    } = this.props
    let { pillar } = this.props
    let anyActivities = []
      .concat(polarActivities)
      .concat(garminActivities)
      .concat(healthkitActivities)
      .concat(ouraActivities)
      .map((item) => item && item.value)

    anyActivities = Array.from(new Set(anyActivities)).map((item) => ({
      label: item,
      value: item
    }))

    this.formData = programActionModel({
      categories: categories.filter(
        (item) => item.pillar === pillar && item.category !== 'Default'
      ),
      isBinary: valueForm.input_type === 'binary',
      isEdit: editGoal && editGoal.id,
      trackingOnly:
        valueForm.input_type === 'binary' || valueForm.input_type === 'text',
      disabledSource:
        editGoal &&
        editGoal.id &&
        valueForm.input_type === 'binary' &&
        valueForm.source === 'ouraring',
      anyActivities,
      healthkitMetric,
      garminMetric,
      ouraMetric,
      polarMetric,
      withingsMetric,
      polarActivities,
      healthkitActivities,
      garminActivities,
      trackingMetric:
        valueForm.auto_activity === 'Strength' ||
        valueForm.auto_activity === 'Yoga'
          ? trackingMetric.filter((item) => item.value !== 'distance')
          : trackingMetric,
      ouraActivities,
      trackingOuraMetric,
      pillar,
      selectSource: valueForm.source
    })
    return (
      <Modal
        className={modalContainer}
        width={890}
        title={pillar && pillar.toUpperCase()}
        visible={true}
        okText="CONFIRM"
        onOk={() => this.validate()}
        onCancel={() => {
          this.props.closeModal()
        }}
        confirmLoading={this.state.loading}
      >
        <h1 className="body-title">
          {editGoal.id ? 'Edit' : 'Add'} Action Item
        </h1>
        {!_.isEmpty(valueForm) && (
          <BasicForm
            formData={this.formData}
            initialValues={valueForm}
            gutter={30}
            onChange={this.updateValueForm}
            wrappedComponentRef={(inst) => (this.formRef = inst)}
          />
        )}
        <Attachments
          attachments={editGoal.attachments || []}
          prefixUpload={this.prefixUpload}
          removeAttach={this.removeAttach}
          cancelUpload={this.cancelUpload}
          uploadFile={this.uploadFile}
          saveLink={this.saveLink}
          description="Attach Files"
          targetId={editGoal && editGoal.id}
          editAttachTitle={this.editAttachTitle}
          confirmObj={{
            title: 'Save Action Item before attaching file?',
            description:
              'Action Items must be saved before attaching a file or URL.'
          }}
        />
      </Modal>
    )
  }
}

export default ActionItemEditor
