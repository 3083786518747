import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import PageLoading from '../../../components/UI/status/loading'
import RangesArea from '../../../components/business/dataset/ranges/metricArea'
import TrendGraph from '../../../components/trendGraph'
// import MobileTrendGraph from '../../../components/trendGraph/mobile'
import {
  getClientList,
  getClientDetail,
  getBodyComposition,
  getLifeStyle,
  getRangeOfMotions,
  getForcePlates,
  getStrength,
  getCardr,
  getCognitive,
  getMovementEfficiencies,
  getPhysical
} from '../../../models/breadcrumb.model'
import { colors } from '../../../utils/constant'
import { getAgeTag } from '../../../utils/common'
import { getMetricData, getMetricHistories } from '../../../api/metric'
import { updateHistoryDataset } from '../../../api/dataset'
import { getRangeAgeScopes } from '../../../api/rangeAgeScope'

import { About, Lifestyle } from '../../../components/icons' // Heartbeat
import IconReturn from '../../../asserts/icon/icon-return.svg'
import Calendar from '../../../asserts/icon/icon-calendar.svg'

class MetricDetail extends Component {
  static propTypes = {
    getPerson: PropTypes.func,
    setClientBreadcrumb: PropTypes.func,
    setClientPageTitle: PropTypes.func,
    history: PropTypes.object,
    isPublicLink: PropTypes.bool,
    metric: PropTypes.object,
    dataset: PropTypes.object,
    person: PropTypes.object,
    histories: PropTypes.array
  }

  state = {
    loading: false,
    show: 'about'
  }
  initial = async () => {
    const {
      getPerson,
      setClientBreadcrumb,
      setClientPageTitle,
      history,
      isPublicLink,
      type,
      setState
    } = this.props
    let { metric, dataset, person } = this.props
    let metricIdField, histories, pageTitle

    if (!isPublicLink) {
      const { match } = this.props
      const { id, datasetId } = match.params
      person = await getPerson()
      let { breadcrumbArr, filed } = this.getMetricIdField(
        match.params.type,
        person,
        isPublicLink
      )
      metricIdField = filed
      const result = await getMetricData(
        id,
        match.params.type,
        datasetId,
        person.id
      )
      metric = result.metric
      dataset = result.dataset && result.dataset.dataset
      breadcrumbArr.push({ title: metric && metric.name })
      setClientBreadcrumb(breadcrumbArr)
      pageTitle = (
        <div>
          {' '}
          <img
            src={IconReturn}
            alt="return "
            onClick={() => history.go(-1)}
          />{' '}
          {metric.name}
        </div>
      )
      setClientPageTitle(pageTitle)

      histories = await getMetricHistories(id, match.params.type, person.id)
    } else {
      const { filed } = this.getMetricIdField(type, person)
      metricIdField = filed
      pageTitle = (
        <div>
          {' '}
          <img
            src={IconReturn}
            alt="return "
            onClick={() => setState({ showDetail: false })}
          />{' '}
          {metric.name}
        </div>
      )
    }

    const snapshot = dataset.snapshot
    const ageScopes = (await getRangeAgeScopes()).age_scope
    const ageTag = getAgeTag(person.profile, ageScopes)

    metric.ranges.sort((a, b) => a.order - b.order)
    metric.personal_data =
      snapshot.filter((item) => item[metricIdField] === metric.id)[0] || {}
    const value = metric.personal_data && metric.personal_data.value
    metric.ranges = metric.ranges.map((range) => {
      const scope = range[`${person.profile.gender}_age_scope`][ageTag]
      const [min, max, color] = scope || []
      range.min = min
      range.max = max
      range.color = color
      return range
    })
    if (histories) {
      histories = histories.history.sort((a, b) => {
        return moment(a.test_date) - moment(b.test_date)
      })
    }

    this.setState({
      metric,
      dataset,
      ageScopes,
      loading: false,
      value,
      person,
      pageTitle,
      html: metric.introduction,
      histories: histories,
      metricIdField
    })
  }

  getMetricIdField = (type, person, isPublicLink) => {
    let filed = '',
      breadcrumbArr = []
    if (isPublicLink) {
      breadcrumbArr = [getClientList(), getClientDetail(person)]
    }
    switch (type) {
      case 'body-comp': // in use
      case 'vitals': // in use
      case 'bone-density': // in use
        !isPublicLink &&
          breadcrumbArr.push(getBodyComposition(person.id, false))
        filed = 'body_comp_id'
        break
      case 'life-style':
      case 'lifestyle_assessments':
        !isPublicLink && breadcrumbArr.push(getLifeStyle(person.id, false))
        filed = 'lifestyle_assessment_id'
        break
      case 'physical':
      case 'movement-quality': // in use
      case 'muscle-fitness': // in use
        !isPublicLink && breadcrumbArr.push(getPhysical(person.id, false))
        filed = 'physical_assessment_id'
        break
      case 'range-of-motions':
      case 'range_of_motions':
        !isPublicLink && breadcrumbArr.push(getRangeOfMotions(person.id, false))
        filed = 'range_of_motion_id'
        break
      case 'force-plate':
      case 'force_plates':
        !isPublicLink && breadcrumbArr.push(getForcePlates(person.id, false))
        filed = 'force_plate_id'
        break
      case 'strength':
      case 'strengths':
        !isPublicLink && breadcrumbArr.push(getStrength(person.id, false))
        filed = 'strength_id'
        break
      case 'cardiorespiratory':
      case 'cardiorespiratories':
      case 'aerobic-fitness': // in use
        !isPublicLink && breadcrumbArr.push(getCardr(person.id, false))
        filed = 'cardiorespiratory_id'
        break
      case 'cognitive-healths':
      case 'cognitive-health': // in use
        !isPublicLink && breadcrumbArr.push(getCognitive(person.id, false))
        filed = 'cognitive_health_id'
        break
      case 'movement-efficiency':
      case 'movement_efficiencies':
        !isPublicLink &&
          breadcrumbArr.push(getMovementEfficiencies(person.id, false))
        filed = 'movement_efficiency_id'
        break
      case 'vital':
        !isPublicLink &&
          breadcrumbArr.push(getMovementEfficiencies(person.id, false))
        filed = 'vital_id'
        break
      case 'nutrition':
        !isPublicLink &&
          breadcrumbArr.push(getMovementEfficiencies(person.id, false))
        filed = 'nutrition_id'
        break
      default:
        break
    }
    return { breadcrumbArr, filed }
  }

  savePersonalValue = async ({ bio_marker_id, value }) => {
    const {
      match: {
        params: { datasetId, id }
      }
    } = this.props
    const { metric, dataset, metricIdField } = this.state

    const result = await updateHistoryDataset(datasetId, {
      value,
      id,
      on: true
    })
    const personalData = result.dataset.snapshot.find(
      (item) => item[metricIdField] === Number(id)
    ) //result.personal_data
    metric.personal_data = personalData
    const histories = this.state.histories || []
    histories.push({
      test_date: dataset.test_date,
      value: personalData.value
    })
    this.setState({ metric, value: personalData.value })
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.initial()
  }

  renderMetricInfo(range) {
    const { show, html, metric } = this.state
    const matchRange =
      metric && metric.ranges.find((item) => item.name === range.name)
    return (
      <div className="biomarker-info">
        <div className="tabs">
          <ul>
            <li
              className={show === 'about' ? 'active' : ''}
              onClick={() =>
                this.setState({ html: metric.introduction, show: 'about' })
              }
            >
              <About color={show === 'about' ? '#264382' : '#B2B2B2'} />
              <br />
              About
            </li>
            {/* <li
              className={show === 'health' ? 'active' : ''}
              onClick={() =>
                this.setState({
                  html: matchRange && matchRange.health_effect,
                  show: 'health'
                })
              }
            >
              <Heartbeat color={show === 'health' ? '#264382' : '#B2B2B2'} />
              <br />
              Health Effects
            </li> */}
            <li
              className={show === 'life' ? 'active' : ''}
              onClick={() =>
                this.setState({
                  html: matchRange && matchRange.life_suggestion,
                  show: 'life'
                })
              }
            >
              <Lifestyle color={show === 'life' ? '#264382' : '#B2B2B2'} />
              <br />
              Lifestyle Suggestions
            </li>
          </ul>
        </div>
        <div className="information-container">
          <div
            className="information"
            dangerouslySetInnerHTML={{
              __html: html && html.replace(/style="(.*?)"/g, '')
            }}
          />
        </div>
      </div>
    )
  }

  renderRangeContainer(range) {
    const { metric, dataset, value, person } = this.state // ageScopes
    const { match, isPublicLink } = this.props
    // const { type, datasetId } = (match && match.parmas) || {}
    return (
      <div className="ranges-info">
        <div className="header desktop-show">
          <h1 className="result">
            Result
            <br />
            <span className="date">
              <img src={Calendar} alt="calendar" />
              {dataset
                ? moment(dataset.test_date.substr(0, 10)).format('MM/DD/YYYY')
                : '-/-/-'}
            </span>
          </h1>
        </div>
        <div className="ranges-area-container">
          {metric &&
            (metric.options ? (
              <div className="options-container">
                <div>
                  <span className="label">name</span>
                  <br />
                  {metric.personal_data &&
                    metric.personal_data.spec &&
                    metric.personal_data.spec.category}
                </div>
                <div
                  className="value"
                  style={{
                    color:
                      (range &&
                        (colors.find((item) => item.key === range.color) || {})
                          .value) ||
                      'rgb(76, 96, 114)'
                  }}
                >
                  <span className="label">your value</span>
                  <br />
                  {value || 'unknow'}
                  {/* {(!isPublicLink && !value && value !== 0 && (
                    <MultiSelect
                      biomarker={metric}
                      savePersonalValue={this.savePersonalValue}
                      {...{ person, type, datasetId, ageScopes }}
                    />
                  )) ||
                    value ||
                    'unknow'} */}
                </div>
              </div>
            ) : (
              <RangesArea
                value={value}
                ranges={metric.ranges}
                person={person}
                metric={metric}
                matchRange={range}
                status={isPublicLink ? 'closed' : dataset.status}
                type={match && match.params.type}
                updateValue={(params) => this.savePersonalValue(params)}
              />
            ))}
        </div>
      </div>
    )
  }

  render() {
    const { histories, setClientPageTitle } = this.props
    const { loading, person, metric, pageTitle } = this.state
    let range
    const personalData = metric && metric.personal_data
    if (personalData) {
      range = personalData.output_range || {}
    }
    return loading ? (
      <PageLoading />
    ) : (
      <>
        {!setClientPageTitle && (
          <h1 className="page-title desktop-show">{pageTitle}</h1>
        )}
        <div className="client-biomarker-container">
          {this.renderRangeContainer(range)}
          {this.renderMetricInfo(range)}
        </div>
        {metric &&
          !metric.options &&
          metric.ranges &&
          (histories || this.state.histories) && (
            <div className="trend-graph desktop-show">
              <h1>Trend Graph</h1>
              <TrendGraph
                person={person}
                biomarker={metric}
                histories={histories || this.state.histories}
              />
            </div>
          )}
      </>
    )
  }
}

export default MetricDetail
