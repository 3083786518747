import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
// import PropTypes from 'prop-types'
import Confirm from './pages/confirm'
import Welcome from './pages/welcome'
import Expert from './pages/expert'
import Finish from './pages/finish'
import Facility from './pages/facility'
import TakePhoto from './pages/photo'
import { CheckStyle } from './index.sass'

class CheckIn extends Component {
  static propTypes = {}

  render() {
    const { match } = this.props
    return (
      <div className={CheckStyle}>
        <Switch>
          <Route path={`${match.url}/photo/:expertId`} component={TakePhoto} />
          <Route path={`${match.url}/welcome`} component={Welcome} />
          <Route path={`${match.url}/expert`} component={Expert} />
          <Route path={`${match.url}/finish`} component={Finish} />
          <Route path={`${match.url}/:id`} component={Confirm} />
          <Route exact path={`${match.url}`} component={Facility} />
        </Switch>
      </div>
    )
  }
}

export default CheckIn
