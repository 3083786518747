/**
 * only used for onboarding logistics
 * */

import React, { useState, useEffect } from 'react'
import { Radio, Input, Upload, Row, Col, Typography, Button, Modal } from 'antd'
import _ from 'lodash'
import { CloseOutlined } from '@ant-design/icons'
import Additional from './addition'
import { VectorPlusIcon } from '../../../components/icons'
import TouchTextInput from '../../../components/UI/attachment/touchInput'
import { getToken } from '../../../utils/storage'
import AttachThumb from '../../../components/UI/attachment/thumb'
import { attachStyle } from '../../../components/UI/attachment/index.sass'
import { ModalStyled } from '../../../components/UI/commonStyled'

export default function IntroSingleChoice(props) {
  const {
    answer,
    question: { choices, answer_format },
    update,
    formatSource
  } = props
  const [values, setValues] = useState({})

  useEffect(() => {
    if (answer) {
      setValues(answer.answer.value)
    }
  }, [answer])

  return (
    <div className="q-form-group">
      {choices.map((choice, index) => {
        const answerObj = values[index]
        const isChecked = !!answerObj
        const verify = _.get('answer', 'verify.choices', [])[index]
        return (
          <div
            key={index}
            className={`q-form-item ${answerObj ? 'valid' : ''} ${
              verify ? 'invalid' : ''
            }`}
          >
            <Radio
              onClick={(e) => {
                const obj = {
                  [index]: []
                }
                radioClickHandle(obj)
              }}
              value={choice.id || index}
              checked={isChecked}
            >
              {choice.title}
            </Radio>

            {choice.type === 'attachment' && isChecked && (
              <Attachment
                answers={answerObj}
                {...choice.handleAttachment}
                update={(attachments) => {
                  updateAnswer({ [choice.id || index]: attachments })
                }}
              />
            )}

            {choice.additional_information && isChecked && (
              <Additional
                items={choice.additional_information}
                anArr={(answerObj && answerObj.additional_answer) || []}
                update={(anArr) => {
                  if (answerObj.additional_answer) {
                    updateAnswer({
                      [choice.id || index]: { additional_answer: anArr }
                    })
                  } else {
                    updateAnswer({ [choice.id || index]: anArr })
                  }
                }}
                verify={answer.verify && answer.verify.additional}
              />
            )}
          </div>
        )
      })}

      {answer.verify && answer.verify.required && (
        <p className="invalid">
          {answer.verify.message || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )

  function radioClickHandle(answerObj) {
    if (formatSource && answer_format) {
      let _obj = {}
      _.forIn(answerObj, (obj, key) => {
        _obj[key] = applyAnswer(answer_format.format[key], formatSource)
      })
      answerObj = _obj
    }
    updateAnswer(answerObj)
  }

  function updateAnswer(answerObj) {
    const newAnswer = _.assignIn(answer, { answer: { value: answerObj } })
    setValues(answerObj)
    update(newAnswer, props.question)
  }
}

const { Paragraph } = Typography

const { Title, Footer } = ModalStyled

function Attachment(props) {
  const { update, action, removeAttach, editAttach } = props
  let answers = _.cloneDeep(props.answers)
  const [percent, setPercent] = useState(0)
  const [status, setStatus] = useState(null)
  const [modalShown, setModalShown] = useState(false)
  const onChange = ({ event, file }) => {
    setStatus(file.status)
    setPercent(parseInt(file.percent))
    if (file.status === 'done') {
      if (file.response) {
        if (!_.isArray(answers)) {
          answers = []
        }
        const attach = file.response.attachment
        answers.push(attach)
        editAttach(attach, {
          size: `${file.size}`,
          title: 'Insurance information'
        })
        update(answers)
      }
    }
  }

  const editTitle = async (attach, title) => {
    const target = _.find(answers, { id: attach.id })
    target.title = title
    await editAttach(attach, { title })
    update(answers)
  }

  const remove = async (attach) => {
    await removeAttach(attach)
    _.remove(answers, function(an) {
      return an.id === attach.id
    })
    update(answers)
  }
  return (
    <>
      {answers.length ? (
        <div className={attachStyle}>
          <Row gutter={10} className="attach-list">
            {answers.map((item, index) => (
              <Col key={index} span={8}>
                <div
                  className="attach-item"
                  onClick={() => setModalShown(true)}
                >
                  <AttachThumb attach={item} />
                  <div className="name">
                    <div className="title">
                      <Paragraph ellipsis>{item.title}</Paragraph>
                    </div>
                    <Paragraph ellipsis>{item.name}</Paragraph>
                  </div>
                  <div
                    className="close"
                    onClick={(e) => remove(item) && e.stopPropagation()}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <>
          <Upload
            style={{ width: '100%' }}
            name="file"
            action={action}
            showUploadList={false}
            onChange={onChange}
            headers={{
              Authorization: `Bearer ${getToken()}`
            }}
          >
            <Input
              placeholder="+ Attach File"
              className={`q-form-item ${
                answers && answers.required ? 'invalid' : ''
              }`}
            />
          </Upload>
        </>
      )}

      {answers && answers.required && (
        <p className="invalid">{answers.message || 'Required'}</p>
      )}

      <UploadModal
        visible={modalShown || status === 'uploading'}
        closeModal={() => setModalShown(false)}
        attachments={_.isArray(answers) ? answers : []}
        removeAttach={remove}
        onChange={onChange}
        status={status}
        percent={percent}
        editAttachTitle={editTitle}
        action={action}
        headers={{
          Authorization: `Bearer ${getToken()}`
        }}
      />
    </>
  )
}

function UploadModal(props) {
  const {
    visible,
    attachments,
    editAttachTitle,
    removeAttach,
    action,
    closeModal,
    onChange,
    status,
    percent
  } = props

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      className={attachStyle}
      footer={null}
      width={667}
      bodyStyle={{ padding: '36px 40px' }}
    >
      <Title> Attach Files</Title>
      <div className="attach-list">
        {attachments.map((item, index) => (
          <div className="file-item" key={index}>
            <AttachThumb attach={item} />
            <div className="name">
              <div className="title">
                <TouchTextInput
                  placeholder="Click to add a title"
                  initialValue={item.title}
                  save={async (title) => await editAttachTitle(item, title)}
                />
              </div>
              <Paragraph ellipsis>{item.oss_name}</Paragraph>
            </div>
            <span className="rate finish">100%</span>
            <CloseOutlined onClick={() => removeAttach(item)} />
          </div>
        ))}
        {status === 'uploading' && (
          <div className="file-item">
            <span className="thumb" />
            <div className="name">
              {/* <div className="title">{item.title}</div> */}
              {/* <Paragraph ellipsis>{item.oss_name}</Paragraph> */}
            </div>
            <span className="rate">{percent}%</span>
            {/* <Icon type="close" onClick={this.cancelUpload} /> */}
          </div>
        )}
      </div>
      <Upload
        className="upload-text"
        name="file"
        action={action}
        showUploadList={false}
        onChange={onChange}
        headers={{
          Authorization: `Bearer ${getToken()}`
        }}
      >
        <VectorPlusIcon />
        <div>Attach Files</div>
      </Upload>
      <Footer>
        {status !== 'uploading' && (
          <Button ghost onClick={closeModal}>
            CANCEL
          </Button>
        )}
        <Button
          type="primary"
          onClick={closeModal}
          loading={status === 'uploading'}
        >
          CONFIRM
        </Button>
      </Footer>
    </Modal>
  )
}

function applyAnswer(format, answer) {
  if (!format) return null
  let answerObj
  answerObj = answerObj || {}
  if (format.text_key) {
    answerObj.text = answer[format.text_key]
  } else if (format.additional_key) {
    answerObj.additional_answer = formatting(format.additional_key)
  } else if (format.attachment_key) {
    answerObj = formatting(format.attachment_key)
  } else {
    answerObj = formatting(format)
  }

  function formatting(format) {
    if (_.isString(format)) {
      return answer[format]
    } else if (_.isArray(format)) {
      return format.map((item) => formatting(item))
    } else {
      if (format.key) {
        return formatting(format.key)
      } else {
        let obj = {}
        _.forIn(format, (value, key) => {
          obj[key] = formatting(value.key || value)
        })
        return obj
      }
    }
  }

  return answerObj
}
