import React from 'react'
import { Modal } from 'antd'
import { disconnectService } from '../../api/team'

class DisconnectService extends React.Component {

  state = {
    confirmLoading: false,
    visible: true
  }

  handleConfirm = async () => {
    this.setState({ confirmLoading: true })
    const { service, person_id } = this.props
    try {
      await disconnectService(service, person_id)
    } catch (error) {

    }
    this.props.completed()
    this.setState({
      visible: false,
      confirmLoading: false
    })
  }

  render() {
    const { service, canceled } = this.props
    const { confirmLoading, visible } = this.state
    return (
      <Modal
        visible={visible}
        onCancel={canceled}
        confirmLoading={confirmLoading}
        onOk={this.handleConfirm}
        okText={'Confirm'}
        destroyOnClose={true}
      >
        <h3>
          Are you sure to disconnect from 
          <span style={{
            textTransform: 'capitalize'
          }}>
            {` ${service}`}?
          </span>
        </h3>
      </Modal>
    )
  }
}

export default DisconnectService
