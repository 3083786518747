import React, { Component } from 'react'
import moment from 'moment'
import 'moment-duration-format'
import { RightOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd' // Progress
import { CollapsePlusIcon, EditPanIcon } from '../icons'
import TodayTag from '../UI/todayTag'
import PageLoading from '../UI/status/loading'
import DateRange from '../UI/mgComponents/dateRange'
import ProgramWrapper from '../program/wrapper'
import PillarWrapper from './pillarWrapper'
import ActionItemEditor from '../program/comp/actionItemEditor'
import Confirm from '../../components/UI/confirmModal'
import PillarEditModal from './comp/pillarEdit'
import GoalDetail from '../goals/detail'
import BarChart from './comp/bar'
import HeartRateBarsChart from './comp/heartRateBars'
// import TrainingLoad from './comp/trainingLoad'
import WorkoutSessions from './comp/workoutSessions'
import ProgramDataContainer from '../program/comp/programData'
// import ProgramOverview from '../program/comp/programOverview'
import Modality from './comp/modality'
// import HeartRatePercentage from './comp/heartRatePercentage'

import { getSum, Pluralize, timesFormat } from '../../utils/common'

import { overviewMetricsStyle } from './metrics.sass'

import AddIcon from '../../asserts/icon/goals/union-plus.svg'
import { SessionsIcon, MinutesIcon } from '../icons/program'
import { programCompletedInfoBoxStyle } from '../program/style/index.sass'
import HealthySignals from './healthySignals'
import _ from 'lodash'
import BedtimeChart from '../nutritionScore/comp/bedtimeChart'
import Interpolation from './comp/Interpolation'
import { WeightChart } from './comp/weightChart'
import GraphCard from '../nutritionScore/comp/qqtGraphCard'
import GeneralChart from '../nutritionScore/comp/generalChart'
import { NutritionCardContainer } from './style'
import ProgramOverviewInfo from './comp/programInfo'
import ProgramEditor from '../program/comp/programEditor'
import ProgramItemEditor from '../program/comp/itemEditor'
import AddWeightModal from './comp/AddWeightModal'
import HSDOverView from './comp/hsdSummary'
import { NotFocusNutritionIcon } from '../../asserts/svgWrappers/home'
import HSDSkipHistory from './comp/hsdSkipHistory'
import openStagingAppQRCode from '../../asserts/images/open-staging-app-qr-code.png'
import openAppQRCode from '../../asserts/images/open-app-qr-code.png'

function NoData(props) {
  return (
    <div className="no-data">
      No data
      {props.editData && !props.disabled && (
        <span onClick={props.editData}>
          <img src={AddIcon} alt="icon" />
        </span>
      )}
    </div>
  )
}

class OverviewMetrics extends Component {
  static propTypes = {}

  state = {
    isPillarEditing: false,
    showDateRange: true,
    showTodayTag: true,
    showSessions: false,
    sessionType: 'All'
  }

  deleteAction = async () => {
    const { editGoal, deleteGoal } = this.props
    this.setState({ isDeleting: true })
    await deleteGoal(editGoal)
    this.setState({ visible: 0, isDeleting: false })
  }

  renderOtherInput(goal, isLargeNumber) {
    const {
      micro_goal: { input_type, unit, source, auto_metric },
      achieving
    } = goal
    let value, sum
    let [{ entries }] = achieving
    entries = entries.filter((entry) => entry.input || entry.input_auto)
    if (input_type === 'number') {
      sum = getSum(entries.map((item) => item.input || item.input_auto || 0))

      const days = entries.length
      value = days > 0 ? Math.round((sum / days) * 10) / 10 : null
      if (
        source !== 'manual' &&
        (auto_metric === 'BodyMass' || auto_metric === 'Weight') &&
        days > 0
      ) {
        const entry = entries
          .filter((item) => item.input > 0 || item.input_auto > 0)
          .pop()
        value = entry && (entry.input || entry.input_auto || 0)
      }
    }

    return value && entries.length > 0 ? (
      <div className="value">
        {isLargeNumber ? (
          <>
            <span className="l-number">{value}</span>
            {unit}
          </>
        ) : (
          <>
            <div>
              {value} {unit}
              <br />
              <span>Avg</span>
            </div>
            <div>
              {sum} {unit}
              <br />
              <span>Total</span>
            </div>
          </>
        )}
      </div>
    ) : (
      <span className={isLargeNumber ? 'l-number' : ''}>
        <NoData disabled={this.props.loginRole === 'Service_Provider'} />
      </span>
    )
  }

  getMetricsValue(key, type, unit, editData) {
    const {
      loginRole,
      overview: { summary, entries }
    } = this.props
    const { Aerobic, Resistance, Activity } = summary || {}
    let value
    if (key === 'total') {
      value =
        (summary &&
          Aerobic.times +
            Resistance.times +
            Activity.times +
            summary['Movement Quality'].times) ||
        0
    } else if (key === 'totalMinutes') {
      value =
        entries &&
        getSum(
          entries
            .filter((item) => item.pillar === 'exercise')
            .map((item) => Math.round(item.details.duration / 60))
        )
    } else {
      value = (summary && summary[key][type]) || 0
    }
    if (type === 'duration') {
      value = Math.round(value / 60)
      if (!value && summary && summary[key].times) {
        value = '0'
      }
    }
    if (value && unit) {
      unit = Pluralize(unit, value === '0' ? 1 : value)
    }

    return value ? (
      value + ' ' + unit
    ) : (
      <NoData
        disabled={loginRole === 'Service_Provider'}
        editData={
          editData ||
          ((e) => {
            e.stopPropagation()
            this.setState({ isPillarEditing: true })
          })
        }
      />
    )
  }

  getAvgTrainingLoad(trainingLoad, editData) {
    if (!trainingLoad || trainingLoad.entries.length === 0) {
      return {
        avgTrainingLoad: (
          <NoData
            disabled={this.props.loginRole === 'Service_Provider'}
            editData={editData}
          />
        ),
        totalTrainingLoad: 0
      }
    } else {
      const total = getSum(
        trainingLoad.entries.map((item) => item.training_load)
      )
      const avgTrainingLoad = Math.round(total / trainingLoad.entries.length)
      return {
        avgTrainingLoad: avgTrainingLoad,
        totalTrainingLoad: total
      }
    }
  }

  getSleepItemValue(field, format) {
    const {
      overview: { summary }
    } = this.props
    const { rejuvenation } = summary || {}
    let value = rejuvenation && rejuvenation[field]
    if (format) {
      value = format(value)
    }
    return value
  }

  get3weekAvg(value, transform) {
    return value ? (
      <div className="more-week-avg-value">{transform(value)}</div>
    ) : (
      <div className="more-week-avg-value more-week-avg-nodata">No Data</div>
    )
  }

  showWorkout = (type) => {
    this.setState({
      showSessions: true,
      sessionType: type
    })
  }

  openHRZDetail = () => {
    const {
      weekRange: { startDate },
      overview,
      setEditGoal,
      loginRole
    } = this.props
    if (
      !overview.heart_rate ||
      (overview.heart_rate.is_expert_only && loginRole !== 'Expert')
    )
      return
    this.setState({
      visible: 3,
      showDateRange: false,
      showTodayTag: false,
      origin: 'Overview',
      originPeriod: 'week',
      targetDate: startDate.format('YYYY-MM-DD')
    })
    setEditGoal({
      id: overview.heart_rate.micro_goal_id,
      goal_type: 'heartRateMetrics',
      input_type: 'binary',
      source: 'auto',
      category: {
        pillar: overview.heart_rate.pillar //'exercise'
      }
    })
  }

  closeWorkoutEditModal = async (needUpdate) => {
    if (needUpdate) {
      await this.props.EditProgramItemCallback()
    }
    this.setState({ isPillarEditing: false })
  }

  renderProgram() {
    const {
      person,
      expiredDate,
      weekRange,
      EditProgramItemCallback,
      addAttachSync,
      removeAttachSync,
      loginRole,
      program,
      programData,
      workoutTypes,
      // summary,
      client: { leftNavigateBarHidden = true },
      intensityRange,
      itemDurationMapping
    } = this.props
    const summary = programData
      ? programData.exercise.summary
      : this.props.summary
    // const program = programs.find((item) => item.active)
    return (
      <>
        <ProgramDataContainer
          {...{ summary, program, weekRange, workoutTypes }}
          origin="overview"
          position={
            loginRole === 'User' || loginRole === 'Service_Provider'
              ? 'clientView'
              : ''
          }
          person={person}
          expiredDate={expiredDate}
          editItemCallback={EditProgramItemCallback}
          addAttachSync={addAttachSync}
          removeAttachSync={removeAttachSync}
          itemDurationMapping={itemDurationMapping}
          intensityRange={intensityRange}
          modalContainerStyle={
            loginRole === 'User'
              ? {
                  right: leftNavigateBarHidden ? -191 : -44,
                  left: leftNavigateBarHidden ? -198 : -354,
                  top: -65
                }
              : {
                  left: leftNavigateBarHidden ? -198 : -333,
                  right: leftNavigateBarHidden ? -199 : -63,
                  top: -36
                }
          }
        />
        {/* {summary.workouts.length && <Divider />} */}
      </>
    )
  }

  renderWeekPosition() {
    const {
      program,
      weekRange: { startDate }
    } = this.props
    const from = moment(program.from),
      to = moment(program.to)

    const totalWeek = to.diff(from, 'week') + 1
    const currentWeek = startDate.diff(from, 'week') + 1

    return `Week ${currentWeek} of ${totalWeek}`
  }

  renderCompletedInfo() {
    const { summary } = this.props
    const {
      achievement: { duration, session }
    } = summary
    const iconStyle = {
      fontSize: '28px',
      verticalAlign: 'middle',
      marginRight: '12px',
      color: 'white'
    }
    const totalMinutes = this.getMetricsValue('totalMinutes', null, '')
    return (
      <div className={programCompletedInfoBoxStyle}>
        <span>
          <SessionsIcon style={iconStyle} />
          <strong>
            {session.completed} of {session.target}
          </strong>
          Sessions completed
        </span>
        <Divider type="vertical" />
        <span>
          <MinutesIcon style={iconStyle} />
          <strong>
            {Number(totalMinutes) || 0} of {Math.round(duration.target / 60)}
          </strong>
          Minutes completed
        </span>
      </div>
    )
  }

  renderExercise() {
    const {
      pageLoading,
      overviewLoading,
      weekRange: { startDate, endDate },
      overview,
      totalHeartRate,
      program,
      summary,
      // extra,
      loginRole,
      // heartRatePercentage,
      person,
      hsdSummary
    } = this.props

    const isLoading = pageLoading || overviewLoading

    // let totalMinutes,
    // trainingLoad,
    // totalExercise,
    // totalHeartRatePercentage,
    // avgTrainingLoadObj = {}
    let totalMinutes,
      totalExercise = {}
    if (!isLoading) {
      totalMinutes = this.getMetricsValue('totalMinutes', null, '')

      // trainingLoad = overview && overview.training_load
      // avgTrainingLoadObj = this.getAvgTrainingLoad(trainingLoad, () =>
      //   this.setState({ isPillarEditing: true })
      // )
      totalExercise = this.getMetricsValue('total', null, '')
      // totalHeartRatePercentage =
      //   heartRatePercentage &&
      //   getSum(
      //     heartRatePercentage.map((item) => Number(item.value.replace('%', '')))
      //   )
    }

    const rollingAvg3 = overview && overview.rollingAvg3

    // const { avgTrainingLoad, totalTrainingLoad } = avgTrainingLoadObj

    return (
      <div className="metrics-card exercise">
        <HSDOverView {...hsdSummary} />
        {startDate && (
          <HealthySignals
            person={person}
            startDate={startDate && startDate.format('YYYY-MM-DD')}
            endDate={endDate && endDate.format('YYYY-MM-DD')}
          />
        )}
        <ProgramOverviewInfo
          program={program}
          summary={summary}
          editProgram={() => {
            this.setState({ showProgramEditor: true })
          }}
          createProgram={() => {
            this.setState({ showProgramCreator: true })
          }}
          loginRole={loginRole}
        />
        <h3>
          Exercise
          {/* {program &&
            summary.achievement &&
            loginRole !== 'User' &&
            this.renderCompletedInfo()} */}
          {loginRole !== 'User' && loginRole !== 'Service_Provider' && (
            <Button
              type="primary"
              disabled={_.isNil(program)}
              ghost
              onClick={() => this.setState({ showActionItemCreator: true })}
            >
              <EditPanIcon />
              ADD ITEM
            </Button>
          )}
        </h3>
        <div className="body">
          {isLoading ? (
            <PageLoading />
          ) : (
            <>
              {summary && this.renderProgram()}
              <div className="metrics-body">
                <div className="metric">
                  <div>
                    <div className="label">Minutes of total activity</div>
                    <div
                      // onClick={this.openHRZDetail}
                      className="clickable"
                      onClick={() => this.showWorkout('All')}
                    >
                      <span>
                        <span className="l-number">{totalMinutes}</span>
                        {Number(totalMinutes) ? (
                          <>
                            {Pluralize('min', totalMinutes)}
                            <RightOutlined />
                          </>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.minutes_of_total_activity,
                      (value) => {
                        return `${_.round(value)} ${Pluralize('min', value)}`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">Heart rate zones</div>
                    <div onClick={this.openHRZDetail} className="chart">
                      {!pageLoading && overview.entries && (
                        <HeartRateBarsChart
                          {...{
                            startDate,
                            endDate,
                            overview,
                            totalHeartRate
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="metric">
                  <div>
                    <div className="label">Avg training load (RPEs)</div>
                    <div>
                      <span>
                        <span className="l-number">{avgTrainingLoad}</span>
                      </span>
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.training_load_avg,
                      (value) => {
                        return `${_.round(value)}`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">Training load (RPEs)</div>
                    <div className="chart">
                      {extra && trainingLoad && (
                        <TrainingLoad
                          {...{
                            startDate,
                            endDate,
                            trainingLoad,
                            showLabel: true,
                            totalTrainingLoad,
                            avgTrainingLoad:
                              typeof avgTrainingLoad === 'number'
                                ? avgTrainingLoad
                                : null
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div> */}
                <div className="metric">
                  <div>
                    <div className="label">Total workout sessions</div>
                    <div
                      className="clickable"
                      onClick={() => this.showWorkout('All')}
                    >
                      <span>
                        <span className="l-number">{totalExercise}</span>

                        {Number(totalExercise) ? (
                          <>
                            {Pluralize('session', totalExercise)}{' '}
                            <RightOutlined />
                          </>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.total_workout_sessions,
                      (value) => {
                        return `${_.round(value)} ${Pluralize(
                          'session',
                          value
                        )}`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">{/* Modality */}</div>
                    <div
                      className="chart"
                      onClick={() => {
                        if (overview.entries.length > 0) {
                          this.showWorkout('All')
                        }
                      }}
                    >
                      {overview.entries && (
                        <Modality
                          {...{
                            startDate,
                            endDate,
                            overview,
                            totalHeartRate
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Divider /> */}
              <div className="metrics-body">
                {/* <div className="metric">
                  <div>
                    <div className="label">Apeiron training target</div>
                    <div
                      className={
                        loginRole !== 'Service_Provider' ? 'clickable' : ''
                      }
                      onClick={() => {
                        if (loginRole === 'Service_Provider') return
                        this.setState({ isPillarEditing: true })
                      }}
                    >
                      <span>
                        <span className="l-number">
                          {totalHeartRatePercentage ? (
                            totalHeartRatePercentage
                          ) : (
                            <NoData
                              disabled={loginRole === 'Service_Provider'}
                              editData={(e) => {
                                e.stopPropagation()
                                this.setState({ isPillarEditing: true })
                              }}
                            />
                          )}
                        </span>
                        {totalHeartRatePercentage > 0 &&
                          loginRole !== 'Service_Provider' && (
                            <>
                              {' '}
                              %
                              <RightOutlined />
                            </>
                          )}
                      </span>
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.training_target,
                      (value) => {
                        return `${_.round(value)}%`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">Target Breakdown</div>
                    <div className="chart">
                      {overview.heart_rate && (
                        <HeartRatePercentage
                          heartRatePercentage={heartRatePercentage || []}
                        />
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  renderRejuvenation() {
    const {
      pageLoading,
      weekRange: { startDate, endDate },
      overview,
      overviewLoading,
      program,
      loginRole
    } = this.props

    let { rejuvenation } = this.props
    if (
      rejuvenation &&
      !program &&
      (loginRole === 'User' || loginRole === 'Service_Provider')
    ) {
      rejuvenation = rejuvenation.filter(
        (item) => !item.micro_goal.is_expert_only
      )
    }

    const rollingAvg3 = overview && overview.rollingAvg3

    return (
      <div className="metrics-card rejuvenation ">
        <h3>
          Rejuvenation
          {/* {program && extra && (
            <div className={programCompletedInfoBoxStyle}>
              <span>
                <Progress
                  type="circle"
                  strokeWidth={18}
                  strokeColor="#5C00D1"
                  percent={(extra && extra.sleep_program_achieved) || 0}
                  width={24}
                  format={() => null}
                />
                <strong>
                  {extra && (extra.sleep_program_achieved || 0) + '%'}
                </strong>
                Completed rate
              </span>
            </div>
          )} */}
        </h3>
        <div className="body">
          {pageLoading || overviewLoading ? (
            <div style={{ width: '100%' }}>
              <PageLoading />
            </div>
          ) : (
            <>
              <div className="rjn-item">
                <div className="metric">
                  <div>
                    <div className="label">Average sleep time</div>
                    <div>
                      {this.getSleepItemValue('avg_asleep_time', (value) => {
                        const { hours, minutes } = timesFormat(value || 0)
                        return value ? (
                          <>
                            <span className="l-number">{hours}</span>h{' '}
                            <span className="l-number">{minutes}</span>m
                          </>
                        ) : (
                          <span className="l-number">
                            <NoData
                              disabled={loginRole === 'Service_Provider'}
                              editData={() =>
                                this.setState({ isPillarEditing: true })
                              }
                            />
                          </span>
                        )
                      })}
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.avg_sleep_time,
                      (value) => {
                        const { hours, minutes } = timesFormat(value || 0)
                        return `${hours}h ${minutes}m`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">Sleep time History</div>
                    <div className="chart">
                      {!pageLoading && overview.entries && (
                        <>
                          <BarChart
                            {...{ startDate, endDate, overview }}
                            pillar="Rejuvenation"
                          />
                          <div className="sleep-tip">
                            <div>
                              <span style={{ background: '#BAC3CE' }} />
                              Inbed
                            </div>
                            <div>
                              <span style={{ background: '#5C00D1' }} />
                              Asleep
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="metric" style={{ marginBottom: 26 }}>
                  <div>
                    <div className="label">Average hours in bed</div>
                    <div>
                      {this.getSleepItemValue('avg_inbed_time', (value) => {
                        const { hours, minutes } = timesFormat(value || 0)
                        return value ? (
                          <>
                            <span className="l-number">{hours}</span>h{' '}
                            <span className="l-number">{minutes}</span>m
                          </>
                        ) : (
                          <NoData
                            disabled={loginRole === 'Service_Provider'}
                            editData={() =>
                              this.setState({ isPillarEditing: true })
                            }
                          />
                        )
                      })}
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.avg_inbed_time,
                      (value) => {
                        const { hours, minutes } = timesFormat(value || 0)
                        return `${hours}h ${minutes}m`
                      }
                    )}
                  </div>
                  <div>
                    <div className="label">Hours in bed</div>
                    <div className="chart">
                      {!pageLoading && overview.entries && (
                        <>
                          <BarChart
                            {...{ startDate, endDate, overview }}
                            pillar="Rejuvenation-inbed"
                          />
                          <div className="sleep-tip">
                            <div>
                              <span style={{ background: '#5C00D1' }} />
                              Inbed
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="rjn-item">
                <div className="metric resting">
                  <div className="r-left">
                    <div className="label">Resting heart rate</div>
                    <div>
                      {this.getSleepItemValue(
                        'avg_resting_heartrate',
                        (value) =>
                          value ? (
                            <>
                              <span className="l-number">{value}</span>{' '}
                              {Pluralize('bpm', value)}
                            </>
                          ) : (
                            <NoData
                              disabled={loginRole === 'Service_Provider'}
                              editData={() =>
                                this.setState({ isPillarEditing: true })
                              }
                            />
                          )
                      )}
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.avg_resting_heartrate,
                      (value) => {
                        return `${value} bpm`
                      }
                    )}
                  </div>
                  <div className="r-right">
                    <div className="label">Resting heart rate History</div>
                    <div className="chart">
                      <Interpolation
                        {...{
                          startDate,
                          endDate,
                          overview,
                          type: 'Resting',
                          avg: this.getSleepItemValue('avg_resting_heartrate')
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="metric resting">
                  <div className="r-left">
                    <div className="label">Average HRV</div>
                    <div>
                      {this.getSleepItemValue('avg_hrv', (value) =>
                        value ? (
                          value + ' ms'
                        ) : (
                          <NoData
                            disabled={loginRole === 'Service_Provider'}
                            editData={() =>
                              this.setState({ isPillarEditing: true })
                            }
                          />
                        )
                      )}
                    </div>
                    <div className="more-week-avg">3-week avg</div>
                    {this.get3weekAvg(
                      rollingAvg3 && rollingAvg3.avg_hrv,
                      (value) => {
                        return `${value} ms`
                      }
                    )}
                  </div>
                  <div className="r-right">
                    <div className="label">HRV History</div>
                    <div className="chart">
                      <Interpolation
                        {...{
                          startDate,
                          endDate,
                          overview,
                          type: 'HRV',
                          avg: this.getSleepItemValue('avg_hrv')
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rjn-item sleep">
                <div className="s-left">
                  <div className="metric">
                    <div style={{ width: 470 }}>
                      <div className="label">Nights to bed before 11pm</div>
                      <div>
                        {this.getSleepItemValue('nights_before_11pm', (value) =>
                          this.getSleepItemValue('asleep_time') ? (
                            <>
                              {value} {Pluralize('night', value)}
                            </>
                          ) : (
                            <NoData
                              disabled={loginRole === 'Service_Provider'}
                              editData={() =>
                                this.setState({ isPillarEditing: true })
                              }
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="label">Sleep efficiency</div>
                      <div>
                        {this.getSleepItemValue(
                          'avg_sleep_efficiency',
                          (value) =>
                            value ? (
                              value + ' %'
                            ) : (
                              <NoData
                                disabled={loginRole === 'Service_Provider'}
                                editData={() =>
                                  this.setState({ isPillarEditing: true })
                                }
                              />
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-right">
                  <BedtimeChart
                    {...{
                      startTime: moment(
                        '2023-01-01 0:00:00',
                        'YYYY-MM-DD H:mm:ss'
                      )._d.getTime(),
                      endTime: moment(
                        '2023-01-02 20:00:00',
                        'YYYY-MM-DD H:mm:ss'
                      )._d.getTime(),
                      idealMealTime: moment(
                        '2023-01-01 22:00:00',
                        'YYYY-MM-DD H:mm:ss'
                      )._d.getTime(),
                      largestMealTime: this.getSleepItemValue(
                        'avg_bedtime_start_seconds',
                        (value) => {
                          return moment(
                            '2023-01-01 00:00:00',
                            'YYYY-MM-DD H:mm:ss'
                          )
                            .add(value, 's')
                            ._d.getTime()
                        }
                      )
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  renderNutrition() {
    const {
      // weekRange: { startDate, endDate },
      pageLoading,
      overviewLoading,
      nutritionScore,
      loginRole
    } = this.props

    const selectedActivityFeed = _.get(
      nutritionScore,
      'selectedActivityFeed',
      []
    )
    const notFocusNutrition = _.get(
      nutritionScore,
      'not_focus_activity_feed',
      false
    )
    const notFocusActivityFeedRecord = _.get(
      nutritionScore,
      'not_focus_activity_feed_record',
      []
    )

    return (
      <div
        className="nutrition metrics-card"
        style={{
          paddingBottom: 0,
          paddingRight: 0
        }}
      >
        <h3 style={{ marginBottom: 0 }}>Nutrition</h3>
        {pageLoading || overviewLoading ? (
          <PageLoading />
        ) : (
          <NutritionCardContainer>
            <div className="qqt-card-container">
              {_.get(nutritionScore, 'lastProcessAnswer.answers', [
                'Quantity',
                'Quality',
                'Timing'
              ]).map((answer, index) => {
                if (_.isString(answer)) {
                  return (
                    <div className="qqt-card-null-state" key={index}>
                      <span>{answer}</span>
                      <span className="n-a">N/A</span>
                    </div>
                  )
                } else {
                  return <GraphCard key={index} {...answer} />
                }
              })}
            </div>
            <div className="nutrition-items-container">
              {notFocusActivityFeedRecord.length > 0 && (
                <>
                  <div className="skiped-container-header">
                    <span>Client selected not to focus on Nutrition</span>
                    <span>
                      Total: {notFocusActivityFeedRecord.length}{' '}
                      {Pluralize('time', notFocusActivityFeedRecord.length)}
                    </span>
                  </div>
                  <HSDSkipHistory
                    notFocusActivityFeedRecord={notFocusActivityFeedRecord}
                  />
                </>
              )}
              {selectedActivityFeed.length > 0 ? (
                selectedActivityFeed.map((item, index) => {
                  return <GeneralChart key={index} {...item} />
                })
              ) : (
                <div className="null-state">
                  {notFocusNutrition || loginRole !== 'User' ? (
                    <>
                      <NotFocusNutritionIcon />
                      <span>
                        {notFocusNutrition
                          ? 'Client selected not to focus on Nutrition this week'
                          : 'No Nutrition Program currently selected.'}
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={
                          process.env.REACT_APP_WORKFLOW === 'production'
                            ? openAppQRCode
                            : openStagingAppQRCode
                        }
                        alt=""
                      />
                      <div className="view-in-app-label">
                        View in the Apeiron App
                      </div>
                      <div className="view-in-app-desc-label">
                        Use your phone to scan the QR Code
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </NutritionCardContainer>
        )}
      </div>
    )
  }

  renderWeight() {
    const {
      pageLoading,
      overviewLoading,
      overview: { weight }
    } = this.props

    const renderNumber = (value) => {
      return value ? (
        <div>
          <span className="l-number">{value}</span> lbs
        </div>
      ) : (
        <span className={'l-number'}>
          <NoData disabled={this.props.loginRole === 'Service_Provider'} />
        </span>
      )
    }

    let dateValue = ' '
    const week = _.get(weight, 'week', [])
    if (week.length === 1) {
      dateValue = moment(week[0].created_at).format('MMM D')
    } else if (week.length > 1) {
      dateValue =
        moment(_.last(week).created_at).format('MMM D') +
        ' - ' +
        moment(week[0].created_at).format('MMM D')
    }

    return (
      <div className="weight metrics-card">
        <h3>Weight </h3>
        {pageLoading || overviewLoading ? (
          <PageLoading />
        ) : (
          <>
            <div className="body metrics-body">
              <div className="first-level metric">
                <div>
                  <div className="label">Weight</div>
                  {renderNumber(_.get(weight, 'today_or_latest.weight'))}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <div>
                      <div className="more-week-avg">3-week avg</div>
                      {this.get3weekAvg(_.get(weight, 'avg'), (value) => {
                        return `${_.round(value, 1)} lbs`
                      })}
                    </div>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        this.setState({ showWeightModal: true })
                      }}
                      style={{
                        marginLeft: 36
                      }}
                    >
                      <CollapsePlusIcon />
                      ADD WEIGHT
                    </Button>
                  </div>
                </div>
              </div>
              <div className="weight-chart-container">
                <div className="title-view">
                  <span className="title-view-date">{dateValue}</span>
                  <span className="title-view-unit">lbs</span>
                </div>
                <WeightChart weight={_.cloneDeep(weight)} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  render() {
    const {
      showRangeDate,
      weekRange: { startDate, endDate },
      loginRole,
      showDate,
      overview,
      loading,
      pageLoading,
      overviewLoading,
      submitMetrics,
      person,
      setManualRpe,
      activityNames,
      nutrition,
      rejuvenation,
      editGoal,
      setEditGoal,
      program,
      // summary,
      // itemCount,
      weekRange,
      workoutTypes,
      itemDurationMapping
    } = this.props
    const {
      showDateRange,
      showTodayTag,
      sessionType,
      showSessions,
      visible,
      isDeleting,
      showProgramEditor,
      showProgramCreator,
      showActionItemCreator,
      showWeightModal
    } = this.state

    const isLoading = pageLoading || overviewLoading

    const mgChilProps = {
      ...this.state,
      ...this.props,
      startDate,
      endDate,
      loading: isLoading,
      statistics: { nutrition, rejuvenation },
      setGlobalState: (state) => {
        if (state.editGoal) {
          setEditGoal(state.editGoal)
        }
        this.setState(state)
      },
      openModal: (state) =>
        this.setState({ showDateRange: true, showTodayTag: true, ...state })
    }

    const modalProps = {
      closeModal: () => this.setState({ visible: 0 }),
      openModal: (state) => this.setState({ ...state }),
      ...this.state,
      ...this.props,
      pillar: editGoal && editGoal.category && editGoal.category.pillar
    }

    return (
      <div className={`${loginRole} ${overviewMetricsStyle}`}>
        <div className="core-metrics-top">
          <DateRange
            prevDate={() =>
              !pageLoading && !overviewLoading && this.props.prevDate()
            }
            nextDate={() =>
              !pageLoading && !overviewLoading && this.props.nextDate()
            }
            showDate={showDate}
          />
          <TodayTag />
          {loginRole !== 'Service_Provider' && (
            <Button
              type="primary"
              disabled={pageLoading}
              onClick={() => this.setState({ isPillarEditing: true })}
            >
              <EditPanIcon />
              EDIT DATA
            </Button>
          )}
        </div>
        <div className="core-metrics">
          {/* {program && (
            <ProgramOverview
              program={program}
              weekRange={weekRange}
              itemCount={itemCount}
            />
          )} */}
          {this.renderExercise()}
          {this.renderRejuvenation()}
          {this.renderNutrition()}
          {this.renderWeight()}
          <div />
        </div>

        {visible === 2 && <ActionItemEditor {...modalProps} />}
        {visible === 3 && (
          <GoalDetail
            {...mgChilProps}
            {...{ showDateRange, showTodayTag, showSessions, sessionType }}
          />
        )}
        {visible === 4 && (
          <Confirm
            {...{
              onCancel: () => this.setState({ visible: 0 }),
              description: `This is "${editGoal.micro_goal}" belongs to ${editGoal.category.pillar}`,
              title: 'Are you sure you want to delete ?',
              onConfirm: this.deleteAction,
              // disabled:isDeleting,
              loading: isDeleting
            }}
          />
        )}

        {this.state.isPillarEditing && (
          <PillarEditModal
            {...{
              startDate,
              endDate,
              loading,
              pageLoading: pageLoading || overviewLoading,
              showRangeDate,
              overview,
              submitMetrics,
              person,
              activityNames
            }}
            prevDate={this.props.prevDate}
            nextDate={this.props.nextDate}
            closeModal={this.closeWorkoutEditModal}
          />
        )}

        {showSessions && overview && (
          <WorkoutSessions
            {...{
              sessionType,
              overview,
              origin: 'Overview',
              originPeriod: 'week',
              targetDate: startDate.format('YYYY-MM-DD'),
              setManualRpe
            }}
            editGoal={{
              id: overview.heart_rate && overview.heart_rate.micro_goal_id,
              goal_type: 'heartRateMetrics',
              input_type: 'binary',
              source: 'auto',
              category: {
                pillar: overview.heart_rate && overview.heart_rate.pillar //'exercise'
              }
            }}
            closeModal={() => {
              this.setState({ showSessions: false, visible: 0 })
            }}
          />
        )}

        {showProgramEditor && (
          <ProgramEditor
            cancel={() => this.setState({ showProgramEditor: false })}
            program={program}
            personId={this.props.person.id}
            confirmCallback={() => {
              this.setState({ showProgramEditor: false })
              this.props.fetchProgram(weekRange, false)
            }}
            deleteCallback={() => this.props.deleteCallback(this.props.history)}
          />
        )}
        {showProgramCreator && (
          <ProgramEditor
            cancel={() => this.setState({ showProgramCreator: false })}
            personId={this.props.person.id}
            position={'overview'}
            confirmCallback={() => {
              this.props.fetchProgram(weekRange, false)
              this.setState({ showProgramCreator: false })
            }}
          />
        )}
        {showActionItemCreator && (
          <ProgramItemEditor
            workoutTypes={workoutTypes}
            cancel={() => this.setState({ showActionItemCreator: false })}
            itemDurationMapping={itemDurationMapping}
            position={'overview'}
            programsId={program.id}
            personId={person.id}
            confirmCallback={() => {
              this.props.fetchProgram(weekRange, false)
              this.setState({ showActionItemCreator: false })
            }}
            prefixUploadCallback={() => {
              this.props.fetchProgram(weekRange, false)
            }}
            addAttachSync={this.props.addAttachSync}
            removeAttachSync={this.props.removeAttachSync}
            editAttachTitleSync={this.props.editAttachTitleSync}
          />
        )}
        {showWeightModal && (
          <AddWeightModal
            personId={person.id}
            onDone={() => {
              this.props.editWeightCallback()
              this.setState({ showWeightModal: false })
            }}
            onCancel={() => {
              this.setState({ showWeightModal: false })
            }}
          />
        )}
      </div>
    )
  }
}

export default ProgramWrapper(PillarWrapper(OverviewMetrics))
