import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from '../../dispatch'
import moment from 'moment'
import {
  getClientList,
  getClientDetail,
  getQuestionnaire,
  getQuestionnaireSection
} from '../../../../models/breadcrumb.model'
import {
  getSectionDetails,
  getSectionsSessions,
  updateAnswer,
  createSectionsSession
} from '../../../../api'
import PageLoading from '../../../../components/UI/status/loading'
import AnswerProgress from '../../../../components/questionnaire/progress'
import { QuestionnaireGroupContainer } from './style'
import Section from './section'
import { Modal, Button, Row, Col } from 'antd'
import Question from '../../../onboarding/questionComponentsV2/question'
import _ from 'lodash'
import SendQuestionnaire from './sendQuestionnaireModal'
import SendSuccessModal from './sendSuccessModal'
import { getRole } from '../../../../utils'

function QuestionnaireGroup(props) {
  const {
    // history,
    location: { search },
    getPerson,
    setClientBreadcrumb,
    setClientPageTitle,
    history
  } = props
  const [person, setPerson] = useState(null)
  const [sections, setSections] = useState(null)
  const [answers, setAnswers] = useState(null)
  const [loading, setLoading] = useState(true)
  const [editQuestion, setEditQuestion] = useState({
    question: null,
    sectionId: null,
    versionDate: null
  })
  const [answersUpdating, setAnswersUpdating] = useState(false)
  const [updatedAnswer, setUpdatedAnswer] = useState(null)
  const [answerVersionIndex, setCurrentAnswerIndex] = useState('0')
  const [sendEmail, setSendEmail] = useState(null)
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false)

  useEffect(() => {
    const initial = async () => {
      setClientPageTitle(null)
      const ids = search.replace('?original_ids=', '')
      const person = await getPerson()
      setPerson(person)
      if (ids) {
        const sections = await getSectionDetails(person.id, ids)
        setSections(sections)
        let answers = await getSectionsSessions(person.id, ids)
        if (answers.length === 0 && getRole() !== 'User') {
          answers = await createSectionsSession({
            person_id: person.id,
            record_date: moment().format('YYYY-MM-DD'),
            original_ids: ids
          })
        }
        setAnswers(answers)
        setClientBreadcrumb([
          getClientList(),
          getClientDetail(person),
          getQuestionnaire(person.id, true),
          getQuestionnaireSection(person.id, sections[0].name, ids)
        ])
      }
      setLoading(false)
    }
    initial()
  }, [])

  const newVersion = async () => {
    setLoading(true)
    const ids = search.replace('?original_ids=', '')
    try {
      const answers = await getSectionsSessions(person.id, ids)
      setAnswers(answers)
    } finally {
      setLoading(false)
    }
  }

  const goToEditAnswer = () => {
    let url
    if (getRole() === 'User') {
      url = `/onboarding/questionnaire?original_ids=${
        sections[0].original_id
      }&record_date=${_.get(answers, [answerVersionIndex, 'record_date'])}`
    } else {
      url = `/client-questionnaire/${person.id}?original_ids=${
        sections[0].original_id
      }&record_date=${_.get(answers, [answerVersionIndex, 'record_date'])}`
    }
    history.push(url)
  }

  const viewReport = () => {
    const loginRole = getRole()
    if (loginRole === 'User') {
      history.push('/app/nutrition-score')
    } else {
      history.push(`/client/${person && person.id}/detail/nutrition-score`)
    }
  }

  const saveAnswer = async () => {
    setAnswersUpdating(true)
    if (!updatedAnswer.session_id) {
      updatedAnswer.session_id = (
        answers.find((a) => a.record_date === editQuestion.versionDate) ||
        answers[0]
      ).id
    }
    const result = await updateAnswer(
      _.pick(updatedAnswer, ['answer', 'question_original_id', 'session_id'])
    )
    const targetIndex = answers.findIndex((e) => e.id === result.id)
    if (targetIndex > -1) {
      setAnswers(_.set(answers, targetIndex, result))
    }
    setAnswersUpdating(false)
    cancelEditQuestion()
  }

  const cancelEditQuestion = () => {
    setEditQuestion({
      question: null,
      sectionId: null,
      versionDate: null
    })
    setUpdatedAnswer(null)
    setAnswersUpdating(false)
  }

  return (
    <div>
      {loading ? (
        <PageLoading />
      ) : (
        sections && (
          <QuestionnaireGroupContainer>
            <div className="group-header">
              <h1>{sections[0].name}</h1>
              <div>
                {sections[0].original_id === 'nutrition_eating_habits' && (
                  <Button type="primary" ghost onClick={viewReport}>
                    VIEW REPORT
                  </Button>
                )}
                {sections[0].multi_answer_version && getRole() !== 'User' && (
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      setSendEmail({
                        title: sections[0].name,
                        originalIds: [sections[0].original_id]
                      })
                    }}
                  >
                    SEND QUESTIONNAIRE
                  </Button>
                )}
                <Button type="primary" onClick={goToEditAnswer}>
                  EDIT ANSWERS
                </Button>
              </div>
            </div>
            <div className="section">
              <AnswerProgress
                percent={`${_.get(
                  answers,
                  [answerVersionIndex, 'completion_rate'],
                  0
                )}`}
              />
              <Section
                section={sections[0]}
                answers={answers.filter((a) => a.section_id === sections[0].id)}
                person={person}
                editAnswer={(question, sectionId, date) => {
                  setEditQuestion({
                    question: question,
                    sectionId: sectionId,
                    versionDate: date
                  })
                }}
                answerVersionIndex={(index) => {
                  setCurrentAnswerIndex(index)
                }}
              />
            </div>
          </QuestionnaireGroupContainer>
        )
      )}
      {answers && editQuestion.question && (
        <Modal
          open={true}
          width={770}
          bodyStyle={{
            background: '#F1F3F6',
            padding: '50px 40px',
            borderRadius: '4px 4px 0 0'
          }}
          onCancel={cancelEditQuestion}
          footer={[
            <Button key="back" onClick={cancelEditQuestion}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={answersUpdating}
              disabled={
                !updatedAnswer ||
                (editQuestion.question.required &&
                  _.isEmpty(updatedAnswer.answer) &&
                  !_.isBoolean(updatedAnswer.answer.value))
              }
              onClick={saveAnswer}
            >
              Save
            </Button>
          ]}
        >
          {editQuestion.question.extra_info && (
            <ExtraInfo question={editQuestion.question} />
          )}
          <Question
            question={editQuestion.question}
            answers={
              (updatedAnswer && [updatedAnswer]) ||
              _.cloneDeep(
                answers.find(
                  (a) => a.record_date === editQuestion.versionDate
                ) || _.get(answers, ['0'], {})
              ).answers ||
              []
            }
            updateAnswer={(answer) => {
              setUpdatedAnswer(answer)
            }}
          />
        </Modal>
      )}
      {sendEmail && (
        <SendQuestionnaire
          person={person}
          done={(method) => {
            setSendEmail(null)
            if (method === 'email') {
              setSendEmailSuccess(true)
            }
            newVersion()
          }}
          onCancel={() => setSendEmail(null)}
          sendEmail={sendEmail}
        />
      )}
      {sendEmailSuccess && (
        <SendSuccessModal ok={() => setSendEmailSuccess(false)} />
      )}
    </div>
  )
}

export default connect(
  ({ client }) => ({ client }),
  mapDispatchToProps
)(QuestionnaireGroup)

function ExtraInfo(props) {
  const {
    question: {
      grid_wrapper: [span1, span2],
      extra_info: { type, content }
    }
  } = props
  return (
    <Row>
      <Col span={span1}></Col>
      <Col span={span2}>
        {type === 'list' && (
          <ul
            className="list"
            style={{
              margin: 0,
              padding: 0,
              paddingTop: 15,
              fontSize: 12,
              color: '#52606c',
              listStyle: 'none',
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </Col>
    </Row>
  )
}
