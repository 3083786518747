import React, { useState, useEffect } from 'react'
import { LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { DateRageStyle } from './index.sass'

/*
static propTypes = {
    prevDate: PropTypes.func,
    nextDate: PropTypes.func,
    showDate: PropTypes.string,
    hideArrow: PropTypes.string,
    loading: PropTypes.bool
  }

 */
export default function DateRange(props) {
  const { prevDate, nextDate, showDate, dataLoading } = props
  const hideArrow = props.hideArrow || ''
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [showDate])

  const switchData = (callback) => {
    if (dataLoading) return
    setLoading(true)
    callback()
  }
  return (
    <div className={DateRageStyle}>
      <div className="switch-date-range">
        <span className={`arrow ${hideArrow.includes('left') ? 'hide' : ''}`}>
          <LeftOutlined onClick={() => switchData(prevDate)} />
        </span>
        <span>
          {loading || dataLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'rgb(56, 62, 72)' }}
                  spin
                />
              }
            />
          ) : (
            showDate
          )}
        </span>
        <span className={`arrow ${hideArrow.includes('right') ? 'hide' : ''}`}>
          <RightOutlined onClick={() => switchData(nextDate)} />
        </span>
      </div>
    </div>
  )
}
