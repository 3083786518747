import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Row, Col } from 'antd'
import { getSum } from '../../../utils/common'

function getLargestNumInArrayIndex(array) {
  return array.indexOf(Math.max.apply(Math, array))
}

function roundPercentageTotals(numArr) {
  // Total percent of the the numbers combined (doesnt always equal 100%).
  const totalPercentage = getSum(numArr)

  // numArr = numArr.map(item => Math.round(item))

  // If not 100%, then we need to work around it by subtracting from the largest number (not as accurate but works out).
  if (totalPercentage !== 100 && totalPercentage !== 0) {
    // Get the index of the largest number in the array.
    var index = getLargestNumInArrayIndex(numArr)
    // Take the difference away from the largest number.
    numArr[index] = Number(numArr[index] - (totalPercentage - 100))
  }

  return numArr
}

class OuraSleep extends Component {
  static propTypes = {
    onlyStage: PropTypes.bool,
    sleepDetail: PropTypes.object
  }

  hourFormat = (hour) =>
    hour && `${parseInt(hour)}h ${Math.round((hour % 1) * 60)}m`

  getTime = (bedTime, point) => {
    if (!bedTime) return
    const obj = JSON.parse(bedTime) && JSON.parse(bedTime)[0]
    if (!obj[point]) {
      return
    }

    const time = obj[point].split('T')[1]
    return moment(time, 'HH:mm:ss').format('hh:mmA')
  }

  transformSleepDetail = (sleepDetail) => {
    let source
    switch (sleepDetail.source || this.props.microGoalSource) {
      case 'manual':
        source = 'Manual Input'
        break
      case 'healthkit':
        source = 'Healthkit'
        break
      case 'garmin':
        source = 'Garmin'
        break
      default:
        source = 'Oura Ring'
        break
    }

    let keyValues = [
      { key: 'Type', value: 'Sleep' },
      {
        key: 'Source',
        value: source
      }
    ]
    if (sleepDetail['SleepEfficiency']) {
      keyValues.push({
        key: 'Sleep efficiency',
        value: `${sleepDetail['SleepEfficiency']}%`
      })
    }
    if (
      sleepDetail['Sleep Time - In Bed'] ||
      sleepDetail['Sleep Time - Asleep']
    ) {
      const inBed = this.hourFormat(sleepDetail['Sleep Time - In Bed'])
      const asleep = this.hourFormat(sleepDetail['Sleep Time - Asleep'])
      if (inBed && asleep) {
        keyValues.push({
          key: 'In Bed / Asleep',
          value: `${inBed} / ${asleep}`
        })
      } else if (inBed) {
        keyValues.push({
          key: sleepDetail.source === 'manual' ? 'Total sleep time' : 'In Bed',
          value: inBed
        })
      } else if (asleep) {
        keyValues.push({
          key: 'Asleep',
          value: asleep
        })
      }
    }
    if (sleepDetail['Bed Time'] || sleepDetail['Wake-up Time']) {
      const bedTime = this.getTime(sleepDetail['Bed Time'], 'bedtime_start')
      const wakeupTime = this.getTime(
        sleepDetail['Wake-up Time'],
        'bedtime_end'
      )
      if (bedTime && wakeupTime) {
        keyValues.push({
          key: 'Bed time / Wake-up time',
          value: `${bedTime} / ${wakeupTime}`
        })
      } else if (bedTime) {
        keyValues.push({
          key: 'Bed time',
          value: bedTime
        })
      } else if (wakeupTime) {
        keyValues.push({
          key: 'Wake-up Time',
          value: wakeupTime
        })
      }
    }
    if (sleepDetail['SleepAvgHeartRate']) {
      keyValues.push({
        key: 'Average HR',
        value: `${sleepDetail['SleepAvgHeartRate']} bpm`
      })
    }
    if (sleepDetail['SleepMinHeartRate']) {
      keyValues.push({
        key: 'Min HR',
        value: `${sleepDetail['SleepMinHeartRate']} bpm`
      })
    }
    if (sleepDetail['SleepMaxHeartRate']) {
      keyValues.push({
        key: 'Max HR',
        value: `${sleepDetail['SleepMaxHeartRate']} bpm`
      })
    }
    if (sleepDetail['SleepHeartRateVariation']) {
      keyValues.push({
        key: 'Average HRV',
        value: `${sleepDetail['SleepHeartRateVariation']} ms`
      })
    }
    if (sleepDetail['SleepMinHeartRateVariation']) {
      keyValues.push({
        key: 'Min HRV',
        value: `${sleepDetail['SleepMinHeartRateVariation']} ms`
      })
    }
    if (sleepDetail['SleepMaxHeartRateVariation']) {
      keyValues.push({
        key: 'Max HRV',
        value: `${sleepDetail['SleepMaxHeartRateVariation']} ms`
      })
    }
    return keyValues
  }

  renderSleepStages = (sleepDetail) => {
    const { SleepAwake, SleepRem, SleepLight, SleepDeep } = sleepDetail
    const max = Math.max(
      ...[SleepAwake, SleepRem, SleepLight, SleepDeep].map((item) =>
        Number(item)
      )
    )
    if (!sleepDetail.SleepAwakePercentage) {
      const percentage = [SleepAwake, SleepRem, SleepLight, SleepDeep]
      const sum = getSum(percentage)

      const sleepPercentage = roundPercentageTotals(
        percentage.map((item) => Math.round((item * 100) / sum))
      )
      const [
        SleepAwakePercentage,
        SleepRemPercentage,
        SleepLightPercentage,
        SleepDeepPercentage
      ] = sleepPercentage
      sleepDetail = Object.assign(sleepDetail, {
        SleepAwakePercentage,
        SleepRemPercentage,
        SleepLightPercentage,
        SleepDeepPercentage
      })
    }
    const color = '#264382'
    const values = [
      {
        desc: 'Awake',
        time: this.hourFormat(SleepAwake),
        opacity: 0.2,
        width: (Number(SleepAwake) / max) * 520,
        percentage: sleepDetail.SleepAwakePercentage
      },
      {
        desc: 'REM',
        time: this.hourFormat(SleepRem),
        opacity: 0.5,
        width: (Number(SleepRem) / max) * 520,
        percentage: sleepDetail.SleepRemPercentage
      },
      {
        desc: 'Light',
        time: this.hourFormat(SleepLight),
        opacity: 0.8,
        width: (Number(SleepLight) / max) * 520,
        percentage: sleepDetail.SleepLightPercentage
      },
      {
        desc: 'Deep',
        time: this.hourFormat(SleepDeep),
        opacity: 1,
        width: (Number(SleepDeep) / max) * 520,
        percentage: sleepDetail.SleepDeepPercentage
      }
    ]
    return (
      <div className="activity-heart-rate-zone-container">
        <div style={{ paddingBottom: 24 }} className="heart-rate-title">
          Sleep Stages
        </div>
        {values.map((value, index) => {
          return (
            <div key={index} className="activity-heart-rate-zone">
              <div
                style={{
                  background: color,
                  opacity: value.opacity,
                  width: value.width,
                  borderRadius: 4,
                  borderLeft: `1px solid ${color}`
                }}
              />
              <div className="activity-heart-rate-info-container">
                <span className="time">
                  {value.time} ({(value.percentage || 0) + '%'})
                </span>
                <span className="sub-content">{value.desc}</span>
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { sleepDetail, onlyStage } = this.props
    if (onlyStage) return this.renderSleepStages(sleepDetail)
    return (
      <>
        <Row
          className={`activity-type-text-container ${
            sleepDetail.source === 'ouraring' || !sleepDetail.source
              ? 'bottom-line'
              : ''
          }`}
        >
          {this.transformSleepDetail(sleepDetail).map((keyValue, index) => {
            return (
              <Col key={index} style={{ paddingTop: 16 }} span={12}>
                <div className="activity-text-title">{keyValue.key}</div>
                <div className="activity-text-content">{keyValue.value}</div>
              </Col>
            )
          })}
        </Row>
        {(sleepDetail.source === 'ouraring' ||
          sleepDetail.source === 'garmin' ||
          !sleepDetail.source) && (
          <div style={{ paddingTop: 25 }}>
            {this.renderSleepStages(sleepDetail)}
          </div>
        )}
      </>
    )
  }
}

export default OuraSleep
