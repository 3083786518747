import { publicPath } from './constant'
import { refreshProfile } from '../api/people'
import { getRole } from './common'

class Storage {
  constructor(props) {
    this.props = props || {}
    this.source = this.props.source || window.localStorage
    this.initRun()
  }

  initRun() {
    const reg = new RegExp('__expires__')
    let data = this.source
    let list = Object.keys(data)
    if (list.length > 0) {
      list.map((key) => {
        if (!reg.test(key)) {
          let now = Date.now()
          let expires = data[`${key}__expires__`] || Date.now + 1
          if (now >= expires) {
            this.remove(key)
          }
        }
        return key
      })
    }
  }

  // deault expired 1d = 60*24 min
  set(key, value, expired = 60 * 24) {
    let source = this.source
    // source[key] = JSON.stringify(value)
    source.setItem(key, value)
    if (expired) {
      // source[`${key}__expires__`] = Date.now() + 1000 * 60 * expired
      source.setItem(`${key}__expires__`, Date.now() + 1000 * 60 * expired)
    }
    return value
  }

  get(key) {
    const source = this.source,
      expired = source.getItem(`${key}__expires__`) || Date.now + 1
    const now = Date.now()

    if (now >= expired) {
      this.remove(key)
      return
    }
    const value = source[key] // ? JSON.parse(source[key]) : source[key];
    return value
  }

  remove(key) {
    const source = this.source
    source.removeItem(key)
    source.removeItem(`${key}__expires__`)
  }
}

const storage = new Storage()
const session = new Storage({ source: window.sessionStorage })

const tokenItem = 'phs-token'

export function setTokenItem(token) {
  const role = getRole()
  const expired = role === 'Expert' ? null : 60 * 24 * 15 // 2 weeks
  return storage.set(tokenItem, token, expired)
  // const keep = getKeepLogged()
  // if (keep) {
  //   return storage.set(tokenItem, token, null)
  // } else {
  //   return storage.set(tokenItem, token)
  // }
}

export function getToken() {
  return storage.get(tokenItem)
}

export function removeTokenItem() {
  return storage.remove(tokenItem)
}

const userItem = 'phs-user'
const userItemSaveTime = 'phs-user-save-time'

export function saveUser(user) {
  storage.set(userItemSaveTime, new Date().getTime())
  return storage.set(userItem, JSON.stringify(user), null)
}

export const loadUser = () => {
  const result = storage.get(userItem)
  const isExpire = storage.get(userItemSaveTime)
  const user = result && result !== 'undefined' && JSON.parse(result)
  if (!isExpire && user) {
    refreshProfile(user.id)
  }
  const { origin, pathname } = window.location
  if (
    !result &&
    pathname !== '/' &&
    !publicPath.find((path) => pathname.includes(path))
  ) {
    window.location.href = `${origin}`
  }
  return user
}

export function removeUserItem() {
  return storage.remove(userItem)
}

const clientPerson = 'phs-client-person'

export function saveClient(person) {
  return session.set(clientPerson, JSON.stringify(person), 30)
}

export function getClient() {
  const result = session.get(clientPerson)
  return result && result !== 'undefined' && JSON.parse(result)
}

export function removeClient() {
  return session.remove(clientPerson)
}

// const trendsDisplayMetrics = 'phs-trends-display-metrics'

// export function setTrendsDisplayMetrics(metrics) {
//   return session.set(trendsDisplayMetrics, JSON.stringify(metrics))
// }

// export function geTrendsDisplayMetrics() {
//   let result = session.get(trendsDisplayMetrics)
//   return result && JSON.parse(result)
// }

const trendsCatchForQA = 'phs-trends-for-qa'

export function setTrendsCatch(obj) {
  return session.set(trendsCatchForQA, JSON.stringify(obj))
}

export function geTrendsCatch() {
  let result = session.get(trendsCatchForQA)
  return result && JSON.parse(result)
}

// const clientBiomarker = 'phs-client-biomarker'

// export function setClientBiomarkerItem(biomarker) {
//   return session.set(clientBiomarker, JSON.stringify(biomarker), 30)
// }

// export function getClientBiomarker() {
//   let result = session.get(clientBiomarker)
//   return result && JSON.parse(result)
// }

// export function removeClientBiomarker() {
//   return session.remove(clientBiomarker)
// }

const BiomarkerCategories = 'phs-biomarker-categories'

export function setBiomarkerCategoriesItem(categories) {
  try {
    return storage.set(
      BiomarkerCategories,
      JSON.stringify({ list: categories })
    )
  } catch (err) {
    console.info(err)
  }
}

export function getBiomarkerCategories() {
  const result = storage.get(BiomarkerCategories)
  const categories = result && result !== 'undefined' && JSON.parse(result)
  return categories && categories.list
}

export function removeBiomarkerCategories() {
  return storage.remove(BiomarkerCategories)
}

const BloodTestKey = 'phs-blood-test-id'
export function setBloodTestID(id) {
  return session.set(BloodTestKey, id)
}

export function getBloodTestID() {
  return session.get(BloodTestKey)
}

export function removeBloodTestID() {
  return session.remove(BloodTestKey)
}

const cacheUrl = 'phs-cache-url'
export function setCacheURL(url) {
  return storage.set(cacheUrl, url)
}

export function getCacheURL() {
  return storage.get(cacheUrl)
}

export function removeCacheURL() {
  return storage.remove(cacheUrl)
}

const keepLogged = 'phs-keep-logged'

export function setKeepLogged(keep) {
  return storage.set(keepLogged, keep, null)
}

export function getKeepLogged() {
  return storage.get(keepLogged)
}

export function removeKeepLogged() {
  return storage.remove(keepLogged)
}

export function clearStorage() {
  removeTokenItem()
  removeUserItem()
  removeClient()
  // removeClientBiomarker()
  // removeBiomarkerCategories()
  removeBloodTestID()
}

const checkIn = 'phs-check-in'
export function setCheckIn(checkInfo) {
  return session.set(checkIn, JSON.stringify(checkInfo))
}

export function getCheckIn() {
  const result = session.get(checkIn)
  return result && JSON.parse(result)
}

const checkInFacility = 'phs-check-in-facility'
export function setCheckInFacility(facility) {
  return session.set(checkInFacility, JSON.stringify(facility))
}

export function getCheckInFacility() {
  const result = session.get(checkInFacility)
  return result && JSON.parse(result)
}

const checkInService = 'phs-check-in-service'
export function setCheckInSercive(serviceId) {
  return session.set(checkInService, serviceId)
}

export function getCheckInSercive() {
  const result = session.get(checkInService)
  return result
}

// export function removeCheckInFacility() {
//   session.remove(checkInFacility)
//   session.remove(checkInService)
// }
