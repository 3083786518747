import React, { Component } from 'react'
import { Button, Card } from 'antd'
import BasicForm from '../../components/BasicForm'
import axios from '../../api/axios'
import { fitbit } from '../../models/authrization.form'

export class Providers extends Component {
  static propTypes = {}

  state = {
    fitbitParams: {}
  }
  ouraring = async () => {
    const result = await axios.get('/providers/ouraring')
    window.open(result.authorizationUri)
  }

  getSleep = async () => {
    const sleep = await axios.get('providers/ouraring/sleep')
    console.log(sleep)
  }

  fitbit = async () => {
    const result = await axios.get('/providers/fitbit')
    window.open(result.authorizationUri)
  }

  getFitbitToken = async () => {
    const params = await this.fitbitRef.handleSubmit()
    const result = await axios.getJson('/providers/fitbit/callback', params)
    console.log(result)
  }

  getFitbitData = async () => {
    const result = await axios.get('/providers/fitbit/activity')
    console.log(result)
  }

  render() {
    const { fitbitParams } = this.state
    return (
      <div style={{ display: 'flex' }}>
        <Card title="ouraring" style={{ width: 300, margin: '30px' }}>
          <Button onClick={this.ouraring}>open AuthorizationURL</Button>
          <br />
          <br />
          <Button onClick={this.getSleep}>get sleep</Button>
        </Card>
        <Card title="fitbit" style={{ width: 300, margin: '30px' }}>
          <Button onClick={this.fitbit}>open AuthorizationURL</Button>
          <BasicForm
            wrappedComponentRef={inst => (this.fitbitRef = inst)}
            valueForm={fitbitParams}
            formData={fitbit}
          />
          <Button type="primary" onClick={this.getFitbitToken}>
            authorization callback
          </Button>
          <br />
          <br />
          <Button onClick={this.getFitbitData}>get activity</Button>
        </Card>
      </div>
    )
  }
}

export default Providers
