import React, { Component } from 'react'
import SignedForms from '../../components/business/setting/signedForms'
import { withRouter } from 'react-router'

class ClientSignedForms extends Component {
  static propTypes = {}

  toWaiver = (step) => {
    let url = '/onboarding/waiver?callback=/app/setting'
    if (step) {
      url += `&step=${step}`
    }
    this.props.history.push(url)
  }

  moreForms = (type) => {
    const { history } = this.props
    let path
    switch (type) {
      case 'Medical Release Form':
        path = 'medical'
        break
      case 'Liability Release Form':
        path = 'liabilities'
        break
      case 'Membership Agreement':
        // step = 2
        // break
        return
      default:
        break
    }
    history.push(
      `/onboarding/waiver/${path}?callback=/app/setting/view-signed-forms`
    )
  }

  back = () => this.props.history.push('/app/setting')

  render() {
    return (
      <SignedForms
        toWaiver={this.toWaiver}
        back={this.back}
        moreForms={this.moreForms}
      />
    )
  }
}

export default withRouter(ClientSignedForms)
