import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Popover, Row, Col } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import ManFront from '../../../components/assessmentReview/comp/painful/manFront'
import ManBack from '../../../components/assessmentReview/comp/painful/manBack'
import WomanFront from '../../../components/assessmentReview/comp/painful/womanFront'
import WomanBack from '../../../components/assessmentReview/comp/painful/womanBack'
import { PopTitle, level, levelColor, PopContent } from './body'
import BodyChart from './bodyChart'
import Information from '../../../components/UI/extraInformation'
import UploadImage from '../../../components/assessmentReview/comp/uploadImage'
import HideWrap from '../../../components/assessmentReview/comp/hideWrap'
import { Growth } from '../../../components/trends/customTooltip'
import HealthCard from '../../../components/healthScore/card'

const PainfulContainer = styled.div`
  .score {
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    color: #4c6072;
    font-size: 18px;
    width: 70%;
    white-space: nowrap;
    .large-number {
      font-size: 32px;
      font-weight: 600;
    }
    .anticon {
      font-size: 14px;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
  .detail {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    padding-right: 60px;
  }
  .media-container {
    display: flex;
    padding-left: 20px;
    padding-bottom: 50px;
    & > div > div,  //preview
    input + div {
      width: 322px;
      height: 430px;
      margin-right: 40px;
      .add-btn {
        display: inline-block;
        border: 1px solid #264382;
        padding: 10px 35px;
        border-radius: 38px;
      }
    }
  }
  .images {
    svg {
      height: auto;
      vertical-align: middle;
    }
  }
  .right-info {
    // padding-top: 70px;
    position: relative;
    .sfma-title {
      margin-top: 80px;
      font-size: 15px;
      color: #4c6072;
    }
    .sfma-chart {
      position: absolute;
      width: 270px;
      margin-top: -25px;
    }
  }
  .label {
    margin-top: 40px;
    font-size: 14px;
    letter-spacing: 0.7px;
    text-transform: uppercase;
    color: #4c6072;
    opacity: 0.6;
    white-space: nowrap;
  }
  ${level}
  .level {
    cursor: pointer;
  }
`

const painfulList = [
  {
    name: 'Neck',
    key: 'neck'
  },
  {
    name: 'Shoulder rotation',
    key: 'shoulderRotation'
  },
  {
    name: 'Multi segmental',
    key: 'multiSegmental'
  },
  {
    name: 'Legs',
    key: 'legs'
  }
]

export default function MovementQuality(props) {
  const {
    hideSectionHandle,
    hideSection,
    dataList,
    compareData,
    save,
    handleSave,
    loginRole,
    onScoreChangeHandle,
    arDetail: { notes, status, detail },
    view,
    cancelEdit,
    handleCommendSave,
    updateEditingCount,
    person,
    dynamicData: {
      movementQuality: {
        quality: { summary, front, back },
        opacity,
        sfma_total_score
      }
    }
  } = props

  const data = dataList.find((item) => item.name === 'Movement Quality') || {}
  const compare =
    compareData.find((item) => item.name === 'Movement Quality') || {}

  const movementQuality = notes.find(
    (item) => item.pillar === 'movement quality'
  )
  let sfmaRate
  if (sfma_total_score) {
    sfma_total_score.series =
      sfma_total_score.series &&
      sfma_total_score.series.map((item, index) => {
        if (index === sfma_total_score.series.length - 1) {
          item.axisColor = '#94A0AA'
        } else {
          item.axisColor = '#C6CDD2'
        }
        return item
      })

    if (
      _.isNumber(sfma_total_score.value) &&
      _.isNumber(sfma_total_score.previous)
    ) {
      sfmaRate = Math.round(
        ((sfma_total_score.value - sfma_total_score.previous) /
          sfma_total_score.previous) *
          100
      )
    }
  }

  function renderImageGroup() {
    if (
      view !== 'Draft' &&
      (!detail ||
        [
          detail.painful_image_1,
          detail.painful_image_2,
          detail.painful_image_3
        ].filter((item) => item).length === 0)
    ) {
      return null
    }

    return (
      <div className="media-container">
        {(view === 'Draft' || detail.painful_image_1) && (
          <div>
            <UploadImage
              onlyView={view !== 'Draft'}
              personId={person.id}
              url={detail.painful_image_1}
              uploadSync={(url) =>
                handleSave({
                  detail: {
                    painful_image_1: url
                  }
                })
              }
            />
          </div>
        )}
        {(view === 'Draft' || detail.painful_image_2) && (
          <div>
            <UploadImage
              // placeholder={Spine}
              onlyView={view !== 'Draft'}
              personId={person.id}
              url={detail.painful_image_2}
              uploadSync={(url) =>
                handleSave({
                  detail: { painful_image_2: url }
                })
              }
            />
          </div>
        )}
        {(view === 'Draft' || detail.painful_image_3) && (
          <div>
            <UploadImage
              // placeholder={Spine}
              onlyView={view !== 'Draft'}
              personId={person.id}
              url={detail.painful_image_3}
              uploadSync={(url) =>
                handleSave({
                  detail: { painful_image_3: url }
                })
              }
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <HideWrap
        name="Movement Quality"
        isSection={true}
        syncStatus={hideSectionHandle}
        status={hideSection}
        view={view}
      >
        <section>
          <h1 className="section-title">Movement Quality</h1>
          <div className="sub-title">Summary</div>
          <HealthCard
            {...data}
            compare={compare}
            save={save}
            loginRole={loginRole}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            onChangeHandle={onScoreChangeHandle}
            disEdit={status === 1 || view === 'Preview'}
            cancel={cancelEdit}
          />
          <Information
            title="COMMENT/NOTE"
            disMarkDone={true}
            initialValue={movementQuality ? movementQuality.memo : ''}
            status={view === 'Draft' ? 'Draft' : 'Opened'}
            updateCommend={(value, status) =>
              handleCommendSave(value, 'movement quality', status)
            }
            updateEditingCount={updateEditingCount}
          />
          <PainfulContainer>
            {sfma_total_score && (
              <Row className="score">
                <Col span={8}>
                  <span
                    style={{ color: levelColor[sfma_total_score.color] }}
                    className="large-number"
                  >
                    {sfma_total_score.value || '--'}
                    {sfmaRate > 0 && (
                      <CaretUpOutlined style={{ color: '#40c47c' }} />
                    )}
                    {sfmaRate < 0 && (
                      <CaretDownOutlined style={{ color: '#ff6b00' }} />
                    )}
                  </span>
                  <br />
                  <span>SFMA Total Score</span>
                </Col>
                <Col span={8}>
                  <span
                    style={{ color: levelColor[sfma_total_score.color] }}
                    className="large-number"
                  >
                    {sfma_total_score.level || '--'}
                  </span>
                  <br />
                  <span>Classification</span>
                </Col>
                <Col span={8}>
                  <span className="large-number">
                    {sfma_total_score.previous || '--'}
                  </span>
                  <br />
                  <span>Previous Score</span>
                </Col>
              </Row>
            )}

            <div className="detail">
              <div className="images">
                <PainfulImages
                  {...{ ...opacity, person, quality: { front, back } }}
                />
              </div>
              <div className="right-info">
                {painfulList.map((item, index) => (
                  <div key={index}>
                    <div className="label">{item.name}</div>
                    <Popover
                      placement="topLeft"
                      overlayClassName="popover-no-arrow"
                      title={
                        <PopTitle>
                          <div>{item.name}</div>
                          <div
                            className="level"
                            style={
                              summary[item.key].color
                                ? {
                                    color: levelColor[summary[item.key].color]
                                  }
                                : {}
                            }
                          >
                            {summary[item.key].level || '--'}
                          </div>
                        </PopTitle>
                      }
                      content={getContent(summary[item.key].metrics)}
                    >
                      <div
                        className="level"
                        style={
                          summary[item.key].color
                            ? {
                                color: levelColor[summary[item.key].color]
                              }
                            : {}
                        }
                      >
                        {summary[item.key].level || '--'}
                      </div>
                    </Popover>
                  </div>
                ))}
                {sfma_total_score && sfma_total_score.series && (
                  <>
                    <div className="sfma-title">
                      SFMA Total Score{' '}
                      {sfmaRate && (
                        <span style={{ marginLeft: 10 }}>
                          <Growth
                            ratio={sfmaRate}
                            up_color="green"
                            // extraStyle={item.extraStyle || {}}
                          />
                        </span>
                      )}
                    </div>
                    <div className="sfma-chart">
                      <BodyChart
                        series={sfma_total_score.series}
                        name="SFMA Total Score"
                        dateFormat="M/D/YY"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {renderImageGroup()}
          </PainfulContainer>
        </section>
      </HideWrap>
    </>
  )
}

function getContent(metrics) {
  return (
    <PopContent>
      <ul>
        {Object.keys(metrics).map((item, index) => (
          <li key={index}>
            <div>
              {metrics[item].icon ? (
                <img src={metrics[item].icon} alt="icon" className="icon" />
              ) : (
                <span className="icon" />
              )}
              {item}
            </div>
            <div
              className="level"
              style={
                metrics[item] && metrics[item].color
                  ? { color: levelColor[metrics[item].color] }
                  : {}
              }
            >
              {(metrics[item] && metrics[item].level) || '--'}
            </div>
          </li>
        ))}
      </ul>
    </PopContent>
  )
}

function PainfulImages(props) {
  const { front, back, person, quality } = props

  const overlayStyle = { minWidth: 230 }
  const colorMaps = {
    '0': 'transparent',
    '34': '#7FB800',
    // 'radial-gradient(71.76% 71.76% at 49.11% 50.38%, #7FB800 0%, rgba(127, 184, 0, 0.2) 100%)',
    '67': '#FFB800',
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, #FFB800 0%, rgba(255, 184, 0, 0.2) 100%)',
    '100': '#D5001C'
    // 'radial-gradient(64.71% 64.48% at 69.12% 50.19%, rgba(213, 0, 28, 0.9) 0%, rgba(213, 0, 28, 0.2) 100%)'
  }

  return person.profile.gender === 'male' ? (
    <>
      <ManFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <ManBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  ) : (
    <>
      <WomanFront
        opacities={front}
        getContent={getContent}
        quality={quality.front}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
      <WomanBack
        opacities={back}
        getContent={getContent}
        quality={quality.back}
        overlayStyle={overlayStyle}
        colorMaps={colorMaps}
      />
    </>
  )
}
