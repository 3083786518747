import axios from './axios'

export const getCategories = async (person_id, filterHistory) => {
  let url = '/bio_markers/root_categories'
  if (!filterHistory) {
    url += '?history=true'
  }
  if (person_id) {
    url += `&person_id=${person_id}`
  }
  const result = await axios.get(url)
  return result.root_categories
}

export const editCategory = (categoryId, editType, params) => {
  let path = 'bio_markers/categories'
  if (editType === 'edit root category') {
    path = 'bio_markers/root_categories'
  }
  return axios.putJson(`/${path}/${categoryId}`, params)
}

export const createCategory = (category) => {
  if (category.parent_id) {
    return axios.post('/bio_markers/categories', category)
  }
  return axios.post('/bio_markers/root_categories', category)
}

export const deleteCategory = (category) => {
  if (category.parent_id) {
    return axios.delete(`/bio_markers/categories/${category.id}`)
  }
  return axios.delete(`/bio_markers/root_categories/${category.id}`)
}
