import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { goalsModal } from '../index.sass'

class DeleteConfirm extends Component {
  static propTypes = {
    targetGoal: PropTypes.object,
    deleteGoal: PropTypes.func,
    archivedGoal: PropTypes.func,
    closeModal: PropTypes.func
  }

  render() {
    const { targetGoal, deleteGoal, archivedGoal, closeModal } = this.props
    return (
      <Modal
        className={`${goalsModal} delete-confirm`}
        visible={true}
        footer={null}
        onCancel={closeModal}
      >
        <div className="confirm"> Are you sure you want to delete ? </div>
        <div className="name">"{targetGoal.micro_goal}"?</div>
        <div className="footer-opr">
          <span onClick={() => deleteGoal(targetGoal)}>
            YES, DELETE THE GOAL
          </span>
          <span onClick={() => archivedGoal(targetGoal, true)}>
            NO, Archive IT
          </span>
        </div>
      </Modal>
    )
  }
}

export default DeleteConfirm
