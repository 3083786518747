import axios from './axios'

export const getMicrobiomeScore = (personId, from, to) => {
  let url = `microbiome_score/details?person_id=${personId}`
  if (from) {
    url += `&start_date=${from}`
  }
  if (to) {
    url += `&end_date=${to}`
  }

  return axios.get(url)
}
