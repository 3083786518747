import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { SearchOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { MemberTag, SearchFilterDropDown } from './table'
import Highlighter from 'react-highlight-words'
import { Pluralize } from '../../../utils/common'
import { clientTypes } from '../../../models/client.form'

const Container = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  & > div {
    width: 49%;
  }
  .list {
    height: calc(100vh - 250px);
    overflow: auto;
    font-family: Gilroy;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.3px;
    text-align: left;
    .ant-table-content {
      .ant-table-thead {
        position: relative;
        .client-name {
          .ant-table-filter-column {
            justify-content: flex-start;
          }
          .ant-table-column-title {
            flex: none;
          }
        }
        .client-search {
          .ant-dropdown-trigger.ant-table-filter-trigger {
            position: absolute;
            margin-left: calc(100% - 80px);
          }
          .ant-table-filter-trigger:hover {
            background: none;
          }
        }
      }
      .ant-table-cell {
        padding: 12px 24px;
        &:nth-child(2),
        &:nth-child(3) {
          padding: 12px 0;
        }
        .service-used {
          line-height: 48px;
          text-align: center;
          color: #323f4a;
          font-size: 24px;
          border-radius: 4px;
          display: inline-block;
        }
        .service-used-items {
          color: #3e4c59;
          font-size: 14px;
          display: flex;
          flex-wrap: wrap;
          & > div {
            background: #f1f3f6;
            padding: 8px;
            border-radius: 6px;
            margin: 8px;
            margin-left: 0;
          }
        }
      }
    }
  }
  .percents {
    display: flex;
    flex-wrap: wrap;
  }
  .title {
    padding: 16px;
    font-family: Gilroy;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: left;
    border-bottom: 1px solid #e6e9f2;
    color: #a5abb2;
    text-transform: uppercase;
    background: #ffffff;
  }
  .service {
    position: relative;
    width: 25%;
    padding-top: 25%;
    background: #ffffff;
    font-family: Gilroy;
    font-weight: 500;
    text-align: center;
    border: 1px solid #e6e9f2;
    & > div {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
    }
    .label {
      font-size: 16px;
      line-height: 21px;
      color: #52606c;
    }
    .value {
      color: #323f4a;
      line-height: 30px;
      font-size: 24px;
      span {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        margin-right: 2px;
      }
    }
    .clients-count {
      font-size: 14px;
      color: #616e7c;
    }
  }
`

export default function ServicesUsed(props) {
  const { clientStatistics, onFiltersChange } = props
  const [searchName, setSearchName] = useState('')
  const {
    appointmentsServiceUsedPercent,
    appointments_client
  } = clientStatistics || {
    appointmentsServiceUsedPercent: {},
    appointments_client: []
  }
  return (
    <Container>
      <div className="list">
        <Table
          rowKey={(record) => {
            return record.client_name + (record.client_id || '')
          }}
          columns={[
            {
              title: 'client',
              key: 'client',
              dataIndex: 'client_name',
              className: 'client-name',
              width: 193,
              ellipsis: true,
              filters: clientTypes,
              onFilter: (value, record) =>
                record.membership && value === record.membership,
              render: (text, record) => (
                <div style={{ position: 'relative', width: 145 }}>
                  <div
                    style={{
                      position: 'absolute',
                      color: '#474747',
                      fontSize: 16
                    }}
                  >
                    {searchName ? (
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: '#ffc069',
                          padding: 0
                        }}
                        searchWords={getSearchWords(searchName)}
                        autoEscape
                        textToHighlight={text.toString()}
                      />
                    ) : (
                      text
                    )}
                    {record.membership && (
                      <>
                        <br />
                        <MemberTag membership={record.membership} />
                      </>
                    )}
                  </div>
                  <div
                    style={{
                      opacity: 0,
                      color: '#474747',
                      fontSize: 16
                    }}
                  >
                    {searchName ? (
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: '#ffc069',
                          padding: 0
                        }}
                        searchWords={getSearchWords(searchName)}
                        autoEscape
                        textToHighlight={text.toString()}
                      />
                    ) : (
                      text
                    )}
                    {record.membership && (
                      <>
                        <br />
                        <MemberTag membership={record.membership} />
                      </>
                    )}
                  </div>
                </div>
              )
            },
            {
              title: '',
              key: 'client1',
              width: 1,
              dataIndex: 'client_name',
              className: 'client-search',
              filterDropdown: (props) => (
                <SearchFilterDropDown
                  {...props}
                  searchCallback={(searchText) => setSearchName(searchText)}
                />
              ),
              filterIcon: (filtered) => (
                <SearchOutlined
                  style={{
                    color: filtered ? '#1890ff' : undefined,
                    marginLeft: -18,
                    marginTop: -2
                  }}
                />
              ),
              onFilter: (value, record) => {
                const values = value.split(',')
                const filterValue = record.client_name ? record.client_name : ''
                return values.find((_value) =>
                  filterValue
                    .toString()
                    .toLowerCase()
                    .includes(_value.toLowerCase().trim())
                )
              },
              render: () => ''
            },
            {
              title: 'SERVICE USED',
              key: 'service_used',
              dataIndex: 'client_service_used',
              render: (client_service_used, record) => {
                return (
                  <div className="service-used-items">
                    {client_service_used.map(
                      ({ service, service_used }, index) => (
                        <div key={index}>
                          {service} ({service_used})
                        </div>
                      )
                    )}
                  </div>
                )
              }
            },
            {
              title: 'Total',
              key: 'service_used_total',
              dataIndex: 'client_service_used',
              align: 'center',
              // filteredValue:   [],
              filters: _.sortBy(
                _.uniq(
                  appointments_client.map(
                    (item) => item && item.client_service_used.length
                  )
                )
                  .sort()
                  .map((value) => ({ value, text: value.toString() }))
              ),
              onFilter: (value, record) =>
                record.client_service_used.length === value,
              render: (data) => (
                <div className="service-used">{data.length}</div>
              )
            }
          ]}
          dataSource={appointments_client}
          bordered
          size="middle"
          pagination={false}
          onChange={(agination, filters, sorter, extra) => {
            if (extra.action === 'filter') {
              onFiltersChange(filters)
            }
          }}
        />
      </div>
      <div>
        <div className="title">Clients have used</div>
        <div className="percents">
          {Object.keys(appointmentsServiceUsedPercent).map((key) => (
            <div className="service" key={key}>
              <div>
                <div className="label">
                  {key} {Pluralize('Service', key || 1)}
                </div>
                <div className="value">
                  <span>{appointmentsServiceUsedPercent[key].percentage}</span>%
                </div>
                <div className="clients-count">
                  <span>
                    {appointmentsServiceUsedPercent[key].count}{' '}
                    {Pluralize(
                      'client',
                      appointmentsServiceUsedPercent[key].count || 1
                    )}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  )

  function getSearchWords(text) {
    return text ? text.split(',').map((item) => item.trim()) : []
  }
}
