import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CaretDownOutlined } from '@ant-design/icons'
import { Tabs, Select } from 'antd'
import DataTable from '../../../UI/table'
import { listStyle } from '../styles/list.sass'
import ActionPlusIcon from '../../../../asserts/icon/client/icon-client-plus.svg'
import ActionDetailIcon from '../../../../asserts/icon/client/icon-client-detail.svg'
import ActionEditIcon from '../../../../asserts/icon/client/icon-client-edit.svg'
import ActionNoteIcon from '../../../../asserts/icon/client/icon-client-note.svg'

const { TabPane } = Tabs
const { Option } = Select

function SelectStatus(props) {
  const [value, setValue] = useState(props.value || 'Draft')
  const onChange = (value) => {
    setValue(value)
    props.onChange(value)
  }
  return (
    <Select
      dropdownClassName="status-drop-down"
      value={value}
      suffixIcon={<CaretDownOutlined />}
      onChange={onChange}
    >
      <Option value="Draft">
        <span className="draft status-circle" />
        Draft
      </Option>
      <Option value="Done">
        <span className="done status-circle" />
        Done
      </Option>
    </Select>
  )
}

class ListTabs extends Component {
  static propTypes = {
    entries: PropTypes.array,
    openNotesModal: PropTypes.func
  }

  state = {}

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  basicColumn = [
    {
      title: 'Name',
      key: 'client.name',
      dataIndex: 'client.name',
      width: 220, // 200
      sorter: (a, b) => {
        return a.client.name
          .toLowerCase()
          .localeCompare(b.client.name.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, { client: { id, name } }) => (
        <div
          className="name-container"
          onClick={() => {
            window.open(`/client/${id}/detail`)
          }}
        >
          {name}
        </div>
      )
    },
    {
      title: 'Date CREATED',
      key: 'email.log.created_at',
      width: 135,
      sorter: (a, b) => {
        const createdA = a.email && a.email.log.created_at
        const createdB = b.email && b.email.log.created_at
        if (createdA && createdB) {
          return new Date(createdA).getTime() - new Date(createdB).getTime()
        } else if (!createdA && !createdB) {
          return 0 //createdA ? 1 : -1
        } else {
          return createdA ? -1 : 1
        }
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <>
          <span>
            {record.email && record.email.log.created_at
              ? moment(record.email.log.created_at).format('MMM DD, YYYY')
              : '--'}
          </span>
        </>
      )
    }
  ]

  infoColumn = [
    {
      title: 'Date SEnt',
      key: 'email.log.sent_at',
      width: 166,
      sorter: (a, b) => {
        const createdA = a.email && a.email.log.sent_at
        const createdB = b.email && b.email.log.sent_at
        if (createdA && createdB) {
          return new Date(createdA).getTime() - new Date(createdB).getTime()
        } else if (!createdA && !createdB) {
          return 0 //createdA ? 1 : -1
        } else {
          return createdA ? -1 : 1
        }
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <>
          <span>
            {record.email && record.email.log.sent_at
              ? moment(record.email.log.sent_at).format('MMM DD, YYYY')
              : '--'}
          </span>
        </>
      )
    },
    {
      title: 'Sent by',
      key: 'email.log.sent_by',
      width: 110,
      // sorter: (a, b) => {
      //   const sentA = (a.log && a.log.sent_by.toLowerCase()) || ''
      //   const sentB = (b.log && b.log.sent_by.toLowerCase()) || ''
      //   return sentA.localeCompare(sentB)
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <>{(record.email && record.email.log.sent_by) || '--'}</>
      )
    },
    {
      title: 'Email Status',
      key: 'email.status',
      width: 300,
      // sorter: (a, b) => {
      //   const statusA = (a.status && a.status.toLowerCase()) || ''
      //   const statusB = (b.status && b.status.toLowerCase()) || ''
      //   return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      // },
      // sortDirections: ['descend', 'ascend'],
      render: (text, record, index) =>
        record.email ? (
          <>
            <span className={`status-circle ${record.email.status}`} />
            {record.email.status === 'Opened'
              ? `${record.email.status} on ${moment(
                  record.email.log.opened_at
                ).format('MMM DD, YYYY')}`
              : 'Unopened'}
          </>
        ) : (
          '--'
        )
    },
    {
      title: 'Actions',
      key: 'action',
      width: 125,
      render: (text, record, index) => (
        <div className="actions">
          <span
            className="as-link"
            style={{ marginRight: 5 }}
            onClick={() => this.props.openNotesModal(record)}
          >
            <img src={ActionNoteIcon} alt="comment" />
          </span>
          <span
            className="as-link"
            onClick={() => {
              let url = `/client/${record.client.id}/detail/summary-email/backup/${record.email.id}`

              if (record.email.new_format) {
                url = `/client/${record.client.id}/detail/summary-email/${record.email.id}`
              }
              return window.open(url)
            }}
          >
            <img src={ActionDetailIcon} alt="detail" />
          </span>
        </div>
      )
    }
  ]

  getSortStatusValue = (a, b, type) => {
    let statusA = '',
      statusB = ''

    if (a.email && a.email.section_status) {
      statusA = a.email.section_status[type]
    } else if (a.email) {
      statusA = 'Draft'
    }
    if (b.email && b.email.section_status) {
      statusB = b.email.section_status[type]
    } else if (b.email) {
      statusB = 'Draft'
    }
    return { statusA, statusB }
  }

  statusColumn = [
    {
      title: 'Intro',
      key: 'intro',
      width: 115,
      sorter: (a, b) => {
        const { statusA, statusB } = this.getSortStatusValue(a, b, 'intro')
        return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="status-container">
          {record.email ? (
            <SelectStatus
              onChange={(value) =>
                this.props.markAsDone(record.email.id, { intro: value })
              }
              value={
                record.email.section_status && record.email.section_status.intro
              }
            />
          ) : (
            '--'
          )}
        </div>
      )
    },
    {
      title: 'Sleep',
      key: 'sleep',
      width: 115,
      sorter: (a, b) => {
        const { statusA, statusB } = this.getSortStatusValue(a, b, 'sleep')
        return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="status-container">
          {record.email ? (
            <SelectStatus
              onChange={(value) =>
                this.props.markAsDone(record.email.id, { sleep: value })
              }
              value={
                record.email.section_status && record.email.section_status.sleep
              }
            />
          ) : (
            '--'
          )}
        </div>
      )
    },
    {
      title: 'Exercise',
      key: 'exercise',
      width: 115,
      sorter: (a, b) => {
        const { statusA, statusB } = this.getSortStatusValue(a, b, 'exercise')
        return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="status-container">
          {record.email ? (
            <SelectStatus
              onChange={(value) =>
                this.props.markAsDone(record.email.id, { exercise: value })
              }
              value={
                record.email.section_status &&
                record.email.section_status.exercise
              }
            />
          ) : (
            '--'
          )}
        </div>
      )
    },
    {
      title: 'Nutrition',
      key: 'nutrition',
      width: 115,
      sorter: (a, b) => {
        const { statusA, statusB } = this.getSortStatusValue(a, b, 'nutrition')
        return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => (
        <div className="status-container">
          {record.email ? (
            <SelectStatus
              onChange={(value) =>
                this.props.markAsDone(record.email.id, { nutrition: value })
              }
              value={
                record.email.section_status &&
                record.email.section_status.nutrition
              }
            />
          ) : (
            '--'
          )}
        </div>
      )
    },
    {
      title: 'Email Status',
      key: 'status',
      width: 115,
      sorter: (a, b) => {
        const statusA = a.email ? 'Draft' : 'Not created'
        const statusB = b.email ? 'Draft' : 'Not created'
        return statusA.toLowerCase().localeCompare(statusB.toLowerCase())
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record, index) => {
        return (
          <div className="status-container">
            <span className="status-circle Draft" />
            {record.email ? 'Draft' : 'Not created'}
          </div>
        )
      }
    },
    {
      title: 'Actions',
      key: 'status.action',
      width: 125,
      render: (text, record, index) => (
        <div className="actions">
          <span
            className="as-link"
            style={{ marginRight: 5 }}
            onClick={() => this.props.openNotesModal(record)}
          >
            <img src={ActionNoteIcon} alt="comment" />
          </span>
          {record.email ? (
            <span
              className="as-link"
              onClick={() => {
                let url = `/client/${record.client.id}/detail/summary-email/backup/${record.email.id}`

                if (record.email.new_format) {
                  url = `/client/${record.client.id}/detail/summary-email/${record.email.id}`
                }
                return window.open(url)
              }}
            >
              <img src={ActionEditIcon} alt="edit" />
            </span>
          ) : (
            <span
              className="as-link"
              onClick={() =>
                // this.props.turnTo(
                //   `/client/${record.client.id}/detail/summary-email`
                // )
                window.open(`/client/${record.client.id}/detail/summary-email`)
              }
            >
              <img src={ActionPlusIcon} alt="add" />
            </span>
          )}
        </div>
      )
    }
  ]

  render() {
    const { entries } = this.props
    const sentEmails = entries.filter(
      (item) => item.email && item.email.log.sent_at
    )
    const openedEmails = entries.filter(
      (item) => item.email && item.email.status === 'Opened'
    )
    const unopenedEmails = entries.filter(
      (item) => item.email && item.email.status === 'Sent'
    )
    const othersEmails = entries.filter(
      (item) => !item.email || item.email.status === 'Draft'
    )
    const columns = this.basicColumn.concat(this.infoColumn)
    const tableBodyScollHeight = window.document.body.offsetHeight - 500
    return (
      <div className={listStyle}>
        <Tabs defaultActiveKey="others">
          <TabPane tab={`NOT SENT (${othersEmails.length})`} key="others">
            <DataTable
              columns={this.basicColumn.concat(this.statusColumn)}
              dataSource={othersEmails}
              hidePagination={true}
              rowKey={(record) => record.client.id}
              scroll={{ y: tableBodyScollHeight }}
            />
          </TabPane>

          <TabPane tab={`EMAIL OPENED (${openedEmails.length})`} key="opented">
            <DataTable
              columns={columns}
              dataSource={openedEmails}
              hidePagination={true}
              rowKey={(record) => record.client.id}
              scroll={{ y: tableBodyScollHeight }}
            />
          </TabPane>
          <TabPane
            tab={`EMAIL UNOPENED (${unopenedEmails.length})`}
            key="unopened"
          >
            <DataTable
              columns={columns}
              dataSource={unopenedEmails}
              hidePagination={true}
              rowKey={(record) => record.client.id}
              scroll={{ y: tableBodyScollHeight }}
            />
          </TabPane>
          <TabPane tab={`EMAIL SENT (${sentEmails.length})`} key="sent">
            <DataTable
              columns={columns}
              dataSource={sentEmails}
              hidePagination={true}
              rowKey={(record) => record.client.id}
              scroll={{ y: tableBodyScollHeight }}
            />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default ListTabs
