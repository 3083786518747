import React, { Component } from 'react'
// import Iframe from './iframe'
import CoverHeader from './cover-header'
import { style } from './iframe.sass'

class TOSView extends Component {
  static propTypes = {}

  render() {
    const {
      match: {
        params: { header }
      },
      history
    } = this.props
    return (
      <div className={style}>
        {/* <Iframe
          title="tos"
          source="https://www-staging.apeiron.life/terms-of-use/"
        /> */}
        <CoverHeader header={header || 'icon'} history={history} />
        <iframe
          title="tos"
          src="https://apeiron.life/terms-of-use/"
          frameBorder="no"
          scrolling="yes"
        />
      </div>
    )
  }
}

export default TOSView
