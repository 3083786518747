import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { Modal } from 'antd'
import BasicForm from '../formV4' //'../BasicForm'

function ModalForm(props) {
  const { title, visible, valueForm, formData, loading } = props
  const [refForm, setRefForm] = useState(null)
  const [initialValue, setInitialValue] = useState(valueForm)

  useEffect(() => {
    if (visible) {
      setInitialValue(valueForm || {})
    } else {
      setInitialValue(null)
    }
  }, [valueForm, visible])

  return (
    <Modal
      title={title}
      visible={visible}
      cancelText="Cancel"
      okText="Submit"
      onOk={submit}
      onCancel={cancel}
      okButtonProps={{ loading }}
    >
      {initialValue && (
        <BasicForm
          wrappedComponentRef={(inst) => setRefForm(inst)}
          initialValues={initialValue}
          formData={formData}
        />
      )}
    </Modal>
  )

  async function submit() {
    try {
      const params = await refForm.handleSubmit()
      await props.onSubmit(params)
      refForm.resetFields()
    } catch (err) {}
  }

  function cancel() {
    const { onCancel } = props
    refForm.resetFields()
    onCancel()
  }
}

ModalForm.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  valueForm: PropTypes.object,
  formData: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ModalForm
