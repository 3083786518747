import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AdminToolDetailLayout from './layout'
import MetricForm from './form'
import { LoadingOutlined } from '@ant-design/icons'
import { getRangeAgeScopes } from '../../api/rangeAgeScope'
import { getDbFields } from '../../components/business/dataset/common'

class AdminToolEditor extends Component {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    categoryIdField: PropTypes.string,
    metricsField: PropTypes.string,
    turnTo: PropTypes.func,
    goBack: PropTypes.func,
    getMetricById: PropTypes.func,
    getCategories: PropTypes.func,
    editMetric: PropTypes.func
  }

  state = {
    categories: null,
    ageScopes: null, // {tag: 'A'|'B'|'C'|'D'|'E'|'F'|'G', label: '20-30'/'xx-xx'}[]
    metric: null,
    is404: false,
    loading: false,
    valueType: ''
  }

  initial = async (id) => {
    const { getMetricById, getCategories, dbFields } = this.props
    id = id || this.props.id
    let { categories, ageScopes, valueType } = this.state
    this.setState({ loading: true })
    try {
      categories = categories || (await getCategories())
      let metric = await getMetricById(id)
      if (dbFields) {
        metric = metric[dbFields.type]
      }

      if (metric && metric.options) {
        valueType = 'multi-select'
        metric.ranges
          .sort((a, b) => a.order - b.order)
          .map((item, index) => {
            item.option = metric.options[index]
            return item
          })
      } else {
        valueType = 'number'
        metric.ranges.sort((a, b) => a.order - b.order)
      }

      if (!ageScopes) {
        const age_scope_def = (await getRangeAgeScopes()).age_scope
        ageScopes = Object.entries(age_scope_def).map(([k, v]) => ({
          tag: k,
          label: v.label
        }))
      }

      this.setState({
        categories,
        ageScopes,
        metric,
        loading: false,
        valueType
      })
    } catch (err) {
      console.error(err)
      this.setState({ loading: false, is404: false })
      if (err.statusCode === 404) {
        this.setState({ is404: true })
      }
    }
  }

  update = async (params) => {
    const { id, editMetric, type } = this.props
    const dbField = getDbFields(type)
    try {
      await editMetric(params, id, dbField.groupType, dbField.type)
      this.setState({ metric: null })
      this.initial(id)
    } catch (err) {
      throw err
    }
  }

  componentDidMount() {
    this.initial()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id } = nextProps
    const { metric } = this.state
    if (!metric || id !== this.props.id) {
      this.setState({ metric: null })
      this.initial(id)
    }
  }

  render() {
    const { id } = this.props
    const {
      categories,
      ageScopes,
      metric,
      valueType,
      loading,
      is404
    } = this.state
    const layoutProps = {
      ...this.props,
      categories,
      ageScopes,
      metric,
      loading
    }
    const detailProps = {
      ageScopes,
      metric,
      update: this.update,
      id: Number(id),
      changeValueType: (valueType) => this.setState({ valueType }),
      valueType
    }

    return (
      <AdminToolDetailLayout {...layoutProps}>
        {loading && (
          <div className="page-loading page-loading-modal">
            <LoadingOutlined />
          </div>
        )}
        {is404 && (
          <div className="page404-container">
            <span
              style={{
                fontSize: '60px'
              }}
            >
              4 0 4
            </span>
            <h2> Page Not Found </h2>
            <h4>It looks like you're lost...</h4>
          </div>
        )}
        {categories && metric && <MetricForm {...detailProps} />}
      </AdminToolDetailLayout>
    )
  }
}

export default AdminToolEditor
