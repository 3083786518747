import React, { Component } from 'react'
import { Form } from '@ant-design/compatible'
import '@ant-design/compatible/assets/index.css'
import { Col } from 'antd'
import PropTypes from 'prop-types'
import NormalSelect from './select'
import { comboSelectStyle } from './styles/comboSelect.sass'

class ComboSelect extends Component {
  static propTypes = {
    include: PropTypes.array,
    source: PropTypes.array,
    targetField: PropTypes.string,
    level: PropTypes.array,
    getFieldDecorator: PropTypes.func
  }

  state = {
    optionsArr: null,
    value: null,
    values: []
  }

  onSelectChange = (value, field) => {
    const { levels } = this.props
    let { values } = this.state
    for (const index in levels) {
      if (field === levels[index]) {
        values[index] = value
        if (index < values.length - 1) {
          values = values.slice(0, index + 1)
        }
      }
    }
    this.setState({ values })
  }

  componentDidMount() {
    const { source, targetField, levels, value } = this.props
    // deadult level length equal 2
    const topOptions = []
    const values = []
    const subOptions = {}
    const topLevel = levels[0]
    for (const options of source) {
      if (!topOptions.find((item) => item === options[topLevel])) {
        topOptions.push(options[topLevel])
      }
    }
    for (const top of topOptions) {
      subOptions[top] = source.filter((item) => item[topLevel] === top)
    }
    if (value) {
      const target = source.find((item) => item[targetField] === value)
      for (const i in levels) {
        values.push(target[levels[i]])
      }
    }
    // const values = [topOptions[0]]

    this.setState({
      optionsArr: [topOptions, subOptions],
      values
      // values
    })
  }

  renderSelect(model, index) {
    const { optionsArr, values } = this.state
    const { levels } = this.props
    let options = optionsArr[index],
      value = values[index],
      field = levels[index],
      selectProps = {
        options,
        initialValue: value,
        placeholder: model.placeholder,
        onSelectChange: (value) => this.onSelectChange(value, field)
      }
    if (index === 0) {
      selectProps.optionType = 'string'
    } else {
      selectProps.options = options[values[index - 1]]
      selectProps.options =
        selectProps.options &&
        selectProps.options.map((item) => item.sub_category)
    }
    return <NormalSelect {...selectProps} />
  }

  render() {
    const { include, getFieldDecorator } = this.props
    const { optionsArr, values } = this.state
    return (
      <div className={comboSelectStyle}>
        {optionsArr &&
          include.map((item, index) => (
            <Col key={`combo-${index}`} span={item.colSpan}>
              <Form.Item label={item.label || ''}>
                {getFieldDecorator(item.dbField, {
                  rules: item.rules || [],
                  initialValue: values[index]
                })(this.renderSelect(item, index))}
              </Form.Item>
            </Col>
          ))}
      </div>
    )
  }
}

export default ComboSelect
