import { getClient, getBloodTestID } from '../../utils/storage'
import { getClientList } from '../../models/breadcrumb.model'

const breadcrumb = { list: [getClientList(true)] }
export default {
  breadcrumb: JSON.stringify(breadcrumb),
  person: getClient(),
  pageTitle: '',
  clientBiomarker: null,
  categories: [],
  selectCategory: null,
  bloodTestId: getBloodTestID(),
  bodyCompId: null,
  lifeStyleId: null,
  unreadCount: 0,
  leftNavigateBarHidden: true
}
