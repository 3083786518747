import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Select, Button } from 'antd'
import { getFacilityList } from '../../../api/checkin'

import SquareLogo from '../../../asserts/images/square-logo.svg'

const { Option } = Select

class Facility extends Component {
  static propTypes = {}

  state = {
    facilities: [],
    selected: null
  }

  onChange = value => {
    this.setState({
      selected: value
    })
  }

  initial = async () => {
    const { facility: facilities } = await getFacilityList()
    this.setState({ facilities })
  }

  componentDidMount() {
    this.initial()
  }
  render() {
    const { selected, facilities } = this.state
    return (
      <>
        <div className="normal-section">
          <div className="top-section">
            <img src={SquareLogo} alt="logo" />
            <p>Apeiron Life</p>
          </div>
          <p>Please select your facility.</p>
          <div className="check-facilities">
            <Select
              defaultValue={selected}
              onChange={this.onChange}
              dropdownClassName="select-facility"
            >
              {facilities.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <Button
            onClick={() => this.props.history.push(`/check-in/${selected}`)}
            disabled={!selected}
          >
            Go to check-in page
          </Button>
        </div>
      </>
    )
  }
}

export default Facility
