export const fitbit = [
  {
    type: 'input',
    label: 'code',
    dbField: 'code'
  },
  {
    type: 'input',
    label: 'state',
    dbField: 'state'
  }
]
