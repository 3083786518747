import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'

export default function CheckBoxGroup(props) {
  const { options, disabled, onChange } = props
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    props.value && setValue(props.value)
  }, [props.value])

  return (
    <Checkbox.Group
      options={options}
      disabled={disabled}
      value={value}
      onChange={(checkedValues) => {
        onChange(checkedValues)
      }}
    />
  )
}
