import React from 'react'
import _ from 'lodash'
import { Prompt } from 'react-router-dom'
import moment from 'moment'
import { message } from 'antd'
import {
  getSummaryEmail,
  updateScreenshort,
  saveSummaryEmail,
  getEmailDetail,
  getLastComments,
  getSummaryEmailChangeLog,
  getEmailTemplate,
  getStatisticsLog
} from '../../api/summaryEmail'
import {
  getOverviewSummary
  // saveOverviewSummary
} from '../../api/microGoals'
import { getClientProfileInfo } from '../../api/team'
import SucceedMessage from './comp/succeedMessage'
import Undo from './comp/undo'
import { loadUser } from '../../utils/storage'
import { capitalize } from '../../utils/common'
import {
  getThisWeekRange,
  getShowWeeklyDate,
  prevWeekDate,
  nextWeekDate
} from '../../utils/dateHandles'

const defaultTitle = 'Let’s take a look at your progress'
const EmailAction = {
  create: 'create',
  // update: 'update',
  sent: 'sent',
  undo: 'undo',
  mark_as_done: 'mark_as_done',
  save_as_draft: 'save_as_draft',
  delete: 'delete',
  send_test_email: 'send_test_email'
}

const initData = {
  email_subject: '',
  overview: {
    title: defaultTitle,
    title_status: '',
    summary_introduction: '',
    summary_introduction_status: '',
    rejuvenation_chart_url: '',
    rejuvenation_recommendation: '',
    rejuvenation_recommendation_status: '',
    exercise_chart_url: '',
    exercise_recommendation: '',
    exercise_recommendation_status: '',
    nutrition_chart_url: '',
    nutrition_recommendation: '',
    nutrition_recommendation_status: '',
    deleted_nutritions: [],
    deleted_rejuvenation: [],
    deleted_exercise: [],
    // new field
    deleted_weight: [],
    weight_chart_url: '',
    weight_recommendation: '',
    weight_recommendation_status: ''
  },
  rejuvenation: {
    sleep_chart_url: '',
    recommendation: '',
    recommendation_status: '',
    custom_text: '',
    custom_text_status: '',
    sleep_goals: [
      // {
      //   id: 1,
      //   description:'',
      //   chart_url: '',
      //   custom_text: '',
      //   custom_text_status: ''
      // }
    ],
    deleted_sleep_goal: []
  },
  exercise: {
    workout_chart_url: '',
    heart_chart_url: '',
    workout_recommendation: '',
    workout_recommendation_status: '',
    workout_custom_text: '',
    workout_custom_text_status: '',
    heart_recommendation: '',
    heart_recommendation_status: '',
    heart_custom_text: '',
    heart_custom_text_status: '',
    // new field
    activity_goals: [
      // {
      //   id: 1,
      //   description:'',
      //   chart_url: '',
      //   custom_text: '',
      //   custom_text_status: ''
      // }
    ],
    deleted_activity_goal: []
  },
  nutrition: [
    // {
    //   id: 1,
    //   description:'',
    //   unit: '',
    //   chart_url: '',
    //   recommendation: '',
    // recommendation_status: '',
    //   custom_text: '',
    // custom_text_status: ''
    // }
  ],
  nutrition_recommendation: '',
  nutrition_recommendation_status: '',
  deleted_nutritions: [],
  date_range: '',
  deleted_section: {
    rejuvenation: false,
    rejuvenation_quality: false,
    exercise: false,
    exercise_workout: false,
    exercise_target: false,
    exercise_training_target: false,
    exercise_target_breakdown: false,
    nutrition: false
  }
}

export default (WrappedComponent) =>
  class WeeklySummaryWrapper extends React.Component {
    state = {
      initailSubmit: true,
      startDate: null,
      endDate: null,
      showDate: '',
      hideArrow: '',
      data: null,
      submitData: initData,
      originInformation: null,
      loading: false,
      msg: null,
      editingTextNumber: 0,
      confirmMessage: 'Are you sure you want to leave this page?',
      changedSubject: null
    }

    handleScreenshorts = async (data, submitData) => {
      const { person } = this.props
      const { data: originData } = this.state

      const formatListToObj = (array) => {
        const result = {}
        for (const { id, url } of array) {
          result[id] = url
        }
        return result
      }

      const result = await updateScreenshort(data, person.id)
      const ChartUrlObj = {}
      const overview = result
        .filter((item) => item.id.includes('overview.'))
        .map((item) => {
          item.id = item.id.replace('overview.', '')
          return item
        })
      ChartUrlObj.overview = formatListToObj(overview)
      submitData.overview = Object.assign(
        submitData.overview,
        ChartUrlObj.overview
      )
      /* handle rejuvenation screenshots --start */
      const rejuvenation = result
        .filter((item) => item.id.includes('rejuvenation.'))
        .map((item) => {
          item.id = item.id.replace('rejuvenation.', '')
          return item
        })
      ChartUrlObj.rejuvenation = formatListToObj(rejuvenation)

      const sleepChart = ChartUrlObj.rejuvenation.sleep_chart_url
      submitData.rejuvenation.sleep_chart_url = sleepChart ? sleepChart : ''

      if (!submitData.rejuvenation.sleep_goals) {
        submitData.rejuvenation.sleep_goals = []
      }
      let urlObj = submitData.rejuvenation.sleep_goals.find(
        (item) => item.id === 1
      )

      if (urlObj) {
        urlObj.chart_url = ChartUrlObj.rejuvenation['chart_url.1']
      } else {
        submitData.rejuvenation.sleep_goals.push({
          id: 1,
          description: 'total hours of sleep',
          chart_url: ChartUrlObj.rejuvenation['chart_url.1']
        })
      }
      urlObj = submitData.rejuvenation.sleep_goals.find((item) => item.id === 2)

      if (urlObj) {
        urlObj.chart_url = ChartUrlObj.rejuvenation['chart_url.2']
      } else {
        submitData.rejuvenation.sleep_goals.push({
          id: 2,
          description: 'Night to bed before 11 PM',
          chart_url: ChartUrlObj.rejuvenation['chart_url.2']
        })
      }

      submitData.rejuvenation.sleep_goals = submitData.rejuvenation.sleep_goals
        .sort((a, b) => a.id - b.id)
        .filter(
          (item) =>
            !submitData.rejuvenation.deleted_sleep_goal ||
            !submitData.rejuvenation.deleted_sleep_goal.find(
              (id) => id === item.id
            )
        )
      /* handle rejuvenation screenshots --end */

      /* handle exercise screenshots --start */
      const exercise = result
        .filter((item) => item.id.includes('exercise.'))
        .map((item) => {
          item.id = item.id.replace('exercise.', '')
          return item
        })

      ChartUrlObj.exercise = formatListToObj(exercise)
      const { heart_chart_url, workout_chart_url } = ChartUrlObj.exercise // chart_url.1
      submitData.exercise = Object.assign(submitData.exercise, {
        heart_chart_url,
        workout_chart_url
      })

      if (!submitData.exercise.activity_goals) {
        submitData.exercise.activity_goals = []
      }
      urlObj = submitData.exercise.activity_goals.find((item) => item.id === 1)

      if (urlObj) {
        urlObj.chart_url = ChartUrlObj.exercise['chart_url.1']
      } else {
        submitData.exercise.activity_goals.push({
          id: 1,
          description: 'sessions overview',
          chart_url: ChartUrlObj.exercise['chart_url.1']
        })
      }
      urlObj = submitData.exercise.activity_goals.find((item) => item.id === 2)

      if (urlObj) {
        urlObj.chart_url = ChartUrlObj.exercise['chart_url.2']
      } else {
        submitData.exercise.activity_goals.push({
          id: 2,
          description: 'Activity overview',
          chart_url: ChartUrlObj.exercise['chart_url.2']
        })
      }

      urlObj = submitData.exercise.activity_goals.find((item) => item.id === 3)

      if (urlObj) {
        urlObj.chart_url = ChartUrlObj.exercise['chart_url.3']
      } else {
        submitData.exercise.activity_goals.push({
          id: 3,
          description: 'Training Load (RPES)',
          chart_url: ChartUrlObj.exercise['chart_url.3']
        })
      }

      submitData.exercise.activity_goals = submitData.exercise.activity_goals
        .sort((a, b) => a.id - b.id)
        .filter(
          (item) =>
            item.chart_url &&
            (!submitData.exercise.deleted_activity_goal ||
              !submitData.exercise.deleted_activity_goal.find(
                (id) => id === item.id
              ))
        )

      /* handle exercise screenshots --end */
      /* handle nutrition screenshort -- start */
      ChartUrlObj.nutrition = result.filter((item) =>
        item.id.includes('nutrition.')
      )

      for (const nutrition of ChartUrlObj.nutrition) {
        let urlObj = submitData.nutrition.find((item) =>
          nutrition.id.includes(`${item.id}`)
        )
        if (urlObj) {
          urlObj = urlObj.chart_url = nutrition.url
        } else {
          submitData.nutrition.push({
            id: Number(nutrition.id.split('.')[2]),
            chart_url: nutrition.url
          })
        }
      }
      for (const nutrition of originData.nutrition) {
        const target = submitData.nutrition.find(
          (item) => item.id === nutrition.id
        )
        if (target) {
          target.description = nutrition.description
          target.unit = nutrition.unit
        } else {
          submitData.nutrition.push({
            description: nutrition.description,
            unit: nutrition.unit,
            id: nutrition.id
          })
        }
      }
      submitData.nutrition = submitData.nutrition
        .sort((a, b) => a.id - b.id)
        .filter(
          (item) => !submitData.deleted_nutritions.find((id) => id === item.id)
        )

      return submitData
    }

    submit = async (action, position, submitData, data, ccList) => {
      const loginUser = loadUser()

      const { person, id, saveSync, createSync } = this.props

      const {
        startDate,
        endDate,
        ouraRingConnected,
        data: { nutrition, sleep },
        initailSubmit
      } = this.state

      if (initailSubmit) {
        submitData.nutrition = submitData.nutrition.filter((item) =>
          nutrition.find((n) => n.id === item.id)
        )

        this.setState({ initailSubmit: false })
      }

      let email
      if (action === EmailAction.sent) {
        email = person.email
      } else if (action === EmailAction.send_test_email) {
        email = loginUser.email
      }
      try {
        if (data) {
          // when send email/test email, need to save screenshots first
          submitData = await this.handleScreenshorts(data, submitData)
          if (!ouraRingConnected && !(sleep && sleep.details)) {
            submitData.deleted_section.rejuvenation = true
          }

          submitData.nutrition = submitData.nutrition.filter(
            (item) => item.description && item.chart_url
          )
          if (!submitData.overview.title) {
            submitData.overview.title = defaultTitle
          }
        }

        submitData.date_range = `${startDate.format(
          'MMM DD'
        )} - ${endDate.format('MMM DD')}`
        if (ccList) {
          submitData.cc_list = ccList
        }

        const params = {
          personId: person.id,
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
          email_id: id === 'new' ? null : Number(id),
          action,
          section: position
        }

        const result = await saveSummaryEmail(params, submitData)

        if (result.id && id === 'new') {
          createSync(result.id)
        }

        if (
          action === EmailAction.sent ||
          action === EmailAction.send_test_email
        ) {
          const msg = message.open({
            content: (
              <SucceedMessage
                status={action === 'draft' ? 'saved' : 'sent'}
                email={email}
                onDismiss={() => this.state.msg && setTimeout(msg)}
              />
            ),
            duration: 5
          })

          this.setState({ msg })

          action === EmailAction.sent && saveSync()
        }
      } catch (err) {
        console.error(err)
      }
    }

    handleUndo = async () => {
      const { originInformation, undo } = this.state
      await this.submit(EmailAction.undo, undo, originInformation.body)
      this.setState({ submitData: originInformation.body, undo: null })
    }

    getDoneStatus = (status) => {
      const loginUser = loadUser()
      return status === 'done'
        ? `Marked as done by ${(loginUser.profile &&
          loginUser.profile.nickname) ||
        loginUser.first_name} at ${moment().format(
          'MMM D, YYYY hh:mm:ss A'
        )}`
        : ''
    }

    setSubmitData = async ({ part, field, value, status, id, position }) => {
      const { submitData, originInformation } = this.state
      const doneStatus = this.getDoneStatus(status)
      if (status === 'delete') {
        originInformation.body = _.cloneDeep(submitData)
      }
      if (part === 'nutrition') {
        let index = submitData.nutrition.findIndex((item) => item.id === id)
        if (index !== -1) {
          submitData.nutrition[index][field] = value
          submitData.nutrition[index][`${field}_status`] = doneStatus
        } else {
          submitData.nutrition.push({
            id,
            [field]: value,
            [`${field}_status`]: doneStatus
          })
        }
      } else if (part) {
        submitData[part][field] = value
        submitData[part][`${field}_status`] = doneStatus
      } else {
        submitData[field] = value
        if (field !== 'email_subject') {
          submitData[`${field}_status`] = doneStatus
        }
      }

      this.setState({
        submitData,
        originInformation,
        undo: status === 'delete' ? position : null
      })
      // auto save
      this.submit(
        status === 'delete'
          ? EmailAction.delete
          : status === 'done'
            ? EmailAction.mark_as_done
            : EmailAction.save_as_draft,
        position,
        submitData
      )
    }

    deleteSections = async (section) => {
      const { submitData, originInformation } = this.state
      originInformation.body = _.cloneDeep(submitData)
      submitData.deleted_section[section] = true
      if (section === 'exercise_workout' || section === 'exercise_target') {
        if (
          submitData.deleted_section.exercise_workout &&
          submitData.deleted_section.exercise_target &&
          submitData.exercise.deleted_activity_goal &&
          submitData.exercise.deleted_activity_goal.length === 2
        ) {
          submitData.deleted_section.exercise = true
        }
      }
      if (
        section === 'exercise_training_target' ||
        section === 'exercise_target_breakdown'
      ) {
        if (
          submitData.deleted_section.exercise_training_target &&
          submitData.deleted_section.exercise_target_breakdown
        ) {
          submitData.deleted_section.exercise_target = true
        }
      }
      if (section === 'rejuvenation_quality') {
        if (
          submitData.deleted_section.rejuvenation_quality &&
          submitData.rejuvenation.deleted_sleep_goal &&
          submitData.rejuvenation.deleted_sleep_goal.length === 2
        ) {
          submitData.deleted_section.rejuvenation = true
        }
      }
      section = capitalize(section.split('_').join(' '))
      await this.submit(EmailAction.delete, section, submitData)

      this.setState({ submitData, undo: section, originInformation })
    }

    deleteOverviewPart = (position, id, description) => {
      const { submitData, data, originInformation } = this.state
      originInformation.body = _.cloneDeep(submitData)
      if (position === 'overview.deleted_rejuvenation') {
        submitData.overview.deleted_rejuvenation.push(id)
        if (submitData.overview.deleted_rejuvenation.length === 3) {
          submitData.overview.rejuvenation_recommendation = ''
        }
      } else if (position === 'overview.deleted_exercise') {
        submitData.overview.deleted_exercise.push(id)
        if (submitData.overview.deleted_exercise.length === 3) {
          submitData.overview.exercise_recommendation = ''
        }
      } else if (position === 'overview.deleted_nutritions') {
        submitData.overview.deleted_nutritions.push(id)
        if (
          submitData.overview.deleted_nutritions.length ===
          data.nutrition.filter(
            (item) => !item.description.toLowerCase().includes('weight')
          ).length
        ) {
          submitData.overview.nutrition_recommendation = ''
        }
      } else if (position === 'overview.deleted_weight') {
        submitData.overview.deleted_weight.push(id)
        if (
          submitData.overview.deleted_weight.length ===
          data.nutrition.filter((item) =>
            item.description.toLowerCase().includes('weight')
          ).length
        ) {
          submitData.overview.weight_recommendation = ''
        }
      }
      position = `${position
        .split('.deleted_')
        .map((item) => capitalize(item))
        .join(' - ')} : ${description}`
      this.submit(EmailAction.delete, position, submitData)
      this.setState({ submitData, originInformation, undo: position })
    }

    oprExercise = (type, obj) => {
      const { submitData, originInformation } = this.state
      if (type === 'delete') {
        originInformation.body = _.cloneDeep(submitData)
        if (!submitData.exercise.deleted_activity_goal) {
          submitData.exercise.deleted_activity_goal = []
        }
        submitData.exercise.deleted_activity_goal.push(obj.id)
        if (
          submitData.deleted_section.exercise_workout &&
          submitData.deleted_section.exercise_target &&
          submitData.exercise.deleted_activity_goal.length === 2
        ) {
          submitData.deleted_section.exercise = true
        }
        this.submit(EmailAction.delete, obj.position, submitData)
      } else {
        const { id, field, value, status, position } = obj
        const doneStatus = this.getDoneStatus(status)
        if (!submitData.exercise.activity_goals) {
          submitData.exercise.activity_goals = []
        }
        let index = submitData.exercise.activity_goals.findIndex(
          (item) => item.id === id
        )
        if (index !== -1) {
          submitData.exercise.activity_goals[index][field] = value
          submitData.exercise.activity_goals[index][
            `${field}_status`
          ] = doneStatus
        } else {
          submitData.exercise.activity_goals.push({
            id,
            [field]: value,
            [`${field}_status`]: doneStatus
          })
        }

        // auto save
        this.submit(
          status === 'done'
            ? EmailAction.mark_as_done
            : EmailAction.save_as_draft,
          position,
          submitData
        )
      }
      this.setState({
        submitData,
        originInformation,
        undo: type === 'delete' ? obj.position : null
      })
    }

    oprRejuvenation = (type, obj) => {
      const { submitData, originInformation } = this.state
      if (type === 'delete') {
        originInformation.body = _.cloneDeep(submitData)
        if (!submitData.rejuvenation.deleted_sleep_goal) {
          submitData.rejuvenation.deleted_sleep_goal = []
        }
        submitData.rejuvenation.deleted_sleep_goal.push(obj.id)
        if (
          submitData.deleted_section.rejuvenation_quality &&
          submitData.rejuvenation.deleted_sleep_goal &&
          submitData.rejuvenation.deleted_sleep_goal.length === 2
        ) {
          submitData.deleted_section.rejuvenation = true
        }
        this.submit(EmailAction.delete, obj.position, submitData)
      } else {
        const { id, field, value, status, position } = obj
        const doneStatus = this.getDoneStatus(status)
        if (!submitData.rejuvenation.sleep_goals) {
          submitData.rejuvenation.sleep_goals = []
        }
        let index = submitData.rejuvenation.sleep_goals.findIndex(
          (item) => item.id === id
        )
        if (index !== -1) {
          submitData.rejuvenation.sleep_goals[index][field] = value
          submitData.rejuvenation.sleep_goals[index][
            `${field}_status`
          ] = doneStatus
        } else {
          submitData.rejuvenation.sleep_goals.push({
            id,
            [field]: value,
            [`${field}_status`]: doneStatus
          })
        }
        // auto save

        this.submit(
          status === 'done'
            ? EmailAction.mark_as_done
            : EmailAction.save_as_draft,
          position,
          submitData
        )
      }
      this.setState({
        submitData,
        originInformation,
        undo: type === 'delete' ? obj.position : null
      })
    }

    deleteNutrition = (position, id, description) => {
      const { submitData, originInformation } = this.state
      originInformation.body = _.cloneDeep(submitData)
      submitData.deleted_nutritions.push(id)
      position = `${capitalize(position)} : ${description}`
      this.submit(EmailAction.delete, position, submitData)
      this.setState({ submitData, originInformation, undo: position })
    }

    clearAllChanges = () => {
      const { originInformation } = this.state
      this.setState({
        submitData: _.cloneDeep(originInformation.body)
      })
    }

    prevDate = () => {
      const { submitData } = this.state
      let { startDate, endDate } = prevWeekDate(
        this.state.startDate,
        this.state.endDate
      )
      this.getShowDate(startDate, endDate)
      this.setState({
        startDate,
        endDate
      })
      this.fetchSummaryEmailData(startDate, endDate)
      if (!Number(this.props.id)) this.getCommendData(startDate, endDate)
      // auto save
      submitData.email_subject = `Weekly summary email (${startDate &&
        startDate.format('MMM D')} - ${endDate && endDate.format('MMM D')})`
      this.submit(EmailAction.save_as_draft, null, submitData)
    }

    nextDate = () => {
      const { submitData } = this.state
      let { startDate, endDate } = nextWeekDate(
        this.state.startDate,
        this.state.endDate
      )
      this.getShowDate(startDate, endDate)
      this.setState({
        startDate,
        endDate
      })
      this.fetchSummaryEmailData(startDate, endDate)
      if (!Number(this.props.id)) this.getCommendData(startDate, endDate)
      // auto save
      submitData.email_subject = `Weekly summary email (${startDate &&
        startDate.format('MMM D')} - ${endDate && endDate.format('MMM D')})`
      this.submit(EmailAction.save_as_draft, null, submitData)
    }

    getShowDate(startDate, endDate) {
      const { originInformation } = this.state
      let showDate = getShowWeeklyDate(startDate, endDate)
      let hideArrow = ''
      if (showDate === 'This week') {
        hideArrow = 'right'
      }
      if (
        originInformation &&
        (originInformation.status === 'Sent' ||
          originInformation.status === 'Opened')
      ) {
        hideArrow = 'left, right'
      }
      this.setState({
        showDate,
        hideArrow
      })
    }

    getCommendData = async (startDate, endDate) => {
      const { id, person, createSync } = this.props
      let originInformation

      if (Number(id)) {
        const detail = await getEmailDetail(person.id, id)
        detail.body.overview.deleted_rejuvenation =
          detail.body.overview.deleted_rejuvenation || []
        detail.body.overview.deleted_exercise =
          detail.body.overview.deleted_exercise || []
        detail.body.overview.deleted_weight =
          detail.body.overview.deleted_weight || []
        originInformation = detail
        detail.body.deleted_section = detail.body.deleted_section || {}
      } else {
        const detail = await getLastComments(
          person.id,
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD')
        )
        const params = {
          personId: person.id,
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD'),
          email_id: id === 'new' ? null : Number(id),
          action: EmailAction.create,
          section: null
        }
        initData.email_subject = `Weekly summary email (${startDate &&
          startDate.format('MMM D')} - ${endDate && endDate.format('MMM D')})`
        originInformation = { body: initData }
        if (detail.comments) {
          originInformation = { body: this.state.submitData }
          const copyComments = {}
          for (let item of [
            'rejuvenation',
            'exercise',
            'nutrition',
            'overview'
          ]) {
            const targetObj = detail.comments[item]
            if (Array.isArray(targetObj)) {
              copyComments[item] = targetObj.map(
                ({ custom_text, id, unit }) => ({
                  custom_text,
                  id,
                  unit
                })
              )
            } else {
              const copyObj = {}
              for (let i of Object.keys(targetObj)) {
                if (
                  i.match(/recommendation$|summary_introduction$|custom_text$/g)
                )
                  copyObj[i] = targetObj[i]
              }
              copyComments[item] = copyObj
            }
          }
          Object.keys(originInformation.body).forEach((item) => {
            if (copyComments[item])
              Object.assign(originInformation.body[item], copyComments[item])
          })

          if (detail.comments.nutrition_recommendation) {
            originInformation.body.nutrition_recommendation =
              detail.comments.nutrition_recommendation
          }
          if (detail.comments.rejuvenation.sleep_goals) {
            originInformation.body.rejuvenation.sleep_goals = detail.comments.rejuvenation.sleep_goals.map(
              ({ custom_text, id }) => ({
                custom_text,
                id
              })
            )
          }
          if (detail.comments.exercise.activity_goals) {
            originInformation.body.exercise.activity_goals = detail.comments.exercise.activity_goals.map(
              ({ custom_text, id }) => ({
                custom_text,
                id
              })
            )
          }
        }

        // create email first
        const result = await saveSummaryEmail(params, originInformation.body)
        if (result.id && id === 'new') {
          createSync(result.id)
        }
      }

      const submitData = _.cloneDeep(originInformation.body)
      const state = { submitData }
      if (!this.state.originInformation) {
        state.originInformation = originInformation
      }
      this.setState(state)
      return originInformation
    }

    fetchSummaryEmailData = async (startDate, endDate) => {
      const { person } = this.props
      this.setState({ loading: true })
      try {
        const data = await getSummaryEmail(
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD'),
          person.id
        )

        const overview = await getOverviewSummary(
          startDate.format('YYYY-MM-DD'),
          endDate.format('YYYY-MM-DD'),
          person.id
        )
        data.overview = overview

        data.nutrition.map((item) => {
          item.formatChange =
            item.change_type === 'percentage'
              ? Math.round(item.change * 100)
              : item.change
          item.changeUnit = item.change_type === 'percentage' ? '%' : ''

          return item
        })

        this.setState({
          data: data,
          loading: false
        })
      } catch (err) {
        this.setState({ loading: false })
      }
    }

    fetchChangeLog = async () => {
      const { id } = this.props
      try {
        let data = []
        if (Number(id)) {
          data = await getSummaryEmailChangeLog(id)
        }
        this.setState({
          changeLog: data.reverse()
        })
      } catch (err) {
        console.error(err)
      }
    }

    fetchStatisticsLog = async () => {
      const { person } = this.props
      try {
        const data = await getStatisticsLog(person.id)
        this.setState({
          statisticsLog: data
        })
      } catch (err) {
        console.error(err)
      }
    }

    initial = async () => {
      const { person, loginRole } = this.props
      let { startDate, endDate } = getThisWeekRange()
      // disable this week, default last week
      // startDate = startDate.day(-6)
      // endDate = endDate.day(-7)

      const originInformation = await this.getCommendData(startDate, endDate)

      if (originInformation.from) {
        startDate = moment(originInformation.from)
        endDate = moment(originInformation.to)
      }

      this.getShowDate(startDate, endDate)
      this.fetchSummaryEmailData(startDate, endDate)
      let templates = []
      if (loginRole !== 'User') {
        this.fetchChangeLog()
        this.fetchStatisticsLog()
        templates = await getEmailTemplate()
      }
      const { device_info } = await getClientProfileInfo(person.id)

      this.setState({
        startDate,
        endDate,
        templates,
        ouraRingConnected:
          device_info && device_info.ouraring.connection_status === 'Connected'
      })
    }

    beforeunload = (env) => {
      const {
        editingTextNumber,
        changedSubject,
        submitData,
        confirmMessage
      } = this.state
      if (
        editingTextNumber > 0 ||
        (changedSubject && changedSubject !== submitData.email_subject)
      ) {
        env.preventDefault()
        env.returnValue = confirmMessage
      }
    }

    componentDidMount() {
      this.setState({ loading: true })
      this.initial()
    }

    render() {
      const { changedSubject, submitData, undo, confirmMessage } = this.state
      const childProps = {
        ...this.state,
        prevDate: this.prevDate,
        nextDate: this.nextDate,
        clearAllChanges: this.clearAllChanges,
        setSubmitData: this.setSubmitData,
        deleteNutrition: this.deleteNutrition,
        deleteOverviewPart: this.deleteOverviewPart,
        deleteSections: this.deleteSections,
        oprRejuvenation: this.oprRejuvenation,
        oprExercise: this.oprExercise,
        submit: this.submit,
        EmailAction,
        updateEditingCount: (count) => {
          const editingTextNumber = this.state.editingTextNumber + count
          this.setState({ editingTextNumber })
        },
        beforeunload: this.beforeunload,
        changeSubject: (subject) => this.setState({ changedSubject: subject })
      }

      return (
        <>
          <Prompt
            when={
              this.state.editingTextNumber > 0 ||
              (changedSubject && changedSubject !== submitData.email_subject)
            }
            message={(location) => {
              if (
                window.location.pathname.includes('news') &&
                location.pathname.includes('/detail/summary-email/')
              ) {
                return false
              } else {
                return confirmMessage
              }
            }}
          />
          <WrappedComponent {...childProps} {...this.props} />
          {undo && (
            <Undo
              duration={5}
              position={undo}
              close={() => this.setState({ undo: null })}
              undo={this.handleUndo}
            />
          )}
        </>
      )
    }
  }
