import React, { Component } from 'react'
import secureIcon from '../../../asserts/icon/icon-payment-secure-lock.svg'
import tipcheckedIcon from '../../../asserts/icon/icon-payment-tip-checked.svg'
class AttachInformation extends Component {
  static propTypes = {}

  render() {
    return (
      <div className="bank-info-description">
        <img src={secureIcon} alt="secure" className="secure-icon" />
        <div className="bank-info-description-content">
          <p className="bank-info-description-content-title">
            KEEP YOUR CREDIT CARD ON FILE
          </p>
          {/* <p className="bank-info-description-content-sub-title">
            Your one-year program totals $30,000 and includes:
          </p> */}
          <p className="bank-info-description-content-detail">
            <img src={tipcheckedIcon} alt="" />
            <span>
              This form is safe and secure. Your credit card details will be
              saved to your file for your convenience only.
            </span>
          </p>
          <p className="bank-info-description-content-detail">
            <img src={tipcheckedIcon} alt="" />
            <span>
              Your credit card will never be charged without your permission.
            </span>
          </p>
          <p className="bank-info-description-content-detail">
            <img src={tipcheckedIcon} alt="" />
            <span>
              For your Membership fees and to set up your Apeiron Life House
              Account, we recommend you to use wire transfer. Wire instructions
              are provided on your invoices. If you prefer to pay using your
              credit card for any transactions, please note that a standard 3%
              service fee will be included to your invoice.
            </span>
          </p>
        </div>
      </div>
    )
  }
}

export default AttachInformation
