import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { animateScroll as scroll } from 'react-scroll'
import { completedCallback } from '../../api/nutrition'
import { skipNotification } from '../../api/onboardingSkip'
import { logOut } from '../../api/people'
import { postAnswer, completeSurvey } from '../../api/people'

import { questionCompletion } from '../../components/questionnaire/progress'
import { questionnaireConfig } from '../../utils/constant'
import { saveUser, clearStorage } from '../../utils/storage'

export default (WrappedComponent) =>
  class QuestionnaireWrapper extends React.Component {
    static propTypes = {
      loginRole: PropTypes.string
    }

    state = {
      step: 6,
      answers: {},
      survey: null,
      postQueue: {},
      postDoing: false,
      pageLoading: true,
      finished: false,
      emailSent: false
    }

    scrollToTop = async () => {
      this.setState({ scrolling: true })
      await scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
      this.setState({ scrolling: false })
    }

    back = async () => {
      const { history } = this.props
      const { step } = this.state
      if (step === 0) {
        // history.push('/onboarding')
        history.goBack()
      } else {
        if (step === 6) {
          await this.checkCompleted()
        }
        this.setState({
          step: step - 1,
          showError: false
        })
      }
    }

    goAhead = () => {
      const {
        account,
        history,
        setLoginAccount,
        loginRole,
        location: { search }
      } = this.props
      const { step, survey, person, finished } = this.state
      const user = person || account.user
      const env = process.env.REACT_APP_WORKFLOW
      const haveScore =
        env !== 'staging' && env !== 'production' && env !== 'develop'

      if ((haveScore && finished) || step === survey.sections.length - 1) {
        if (
          !(
            user.profile.onboarding_steps.questionnaire_skipped &&
            user.profile.onboarding_steps.questionnaire_skipped.length
          )
        ) {
          // if not skipped any section, mark question_required=false
          user.profile.questionnaire_required = false
        }
        user.profile.onboarding_steps.questionnaire_skipped =
          questionnaireConfig.sections
        //  notification
        skipNotification(
          {
            form_name: 'Questionnaire',
            operation_type: 2
          },
          user.id
        )
        if (loginRole !== 'Expert') {
          setLoginAccount(user)
          saveUser(user)
        }
        if (haveScore) {
          this.setState({ finished: true })
        } else {
          if (search.match('callback')) {
            const callback = search.split(/=|&/g)[1]
            this.props.history.push(callback)
          } else {
            history.push('/onboarding/ready')
          }
        }
        // if route from user/settion/questionnare, go back to it, otherwise, goto onboarding
      } else {
        this.setState({
          step: step + 1,
          showError: false
        })
      }
    }

    checkCompletedForSection = (section, answers, checkAnswers, isForced) => {
      let is_completed = true
      if (!answers[section.id]) return false
      for (let question of section.questions) {
        if (typeof question !== 'string') {
          const an = answers[section.id][question.id]

          if (question.required) {
            const notSubQuestion = typeof question.id === 'number'
            const noAnswer = typeof answers[section.id] !== 'object'
            const answerRequired =
              typeof an !== 'boolean' &&
              typeof an !== 'number' &&
              (!an || !Object.keys(an).length || an.required)
            if (notSubQuestion && (noAnswer || answerRequired)) {
              if (isForced) {
                checkAnswers[question.id] = { required: true }
              }
              is_completed = false
            }
            if (question.type === 'question_group') {
              const subQuestions = section.questions.filter(
                (item) =>
                  item.id && item.id.toString().includes(`${question.id}_`)
              )
              const requiredSubQuestions = subQuestions.filter(
                (item) => item.required
              )
              if (requiredSubQuestions.length > 0) {
                for (const subQ of requiredSubQuestions) {
                  const subId = Number(subQ.id.split('_')[1])
                  if (
                    subQ.required &&
                    !(an && (an[subId] || an[subId] === 0))
                  ) {
                    checkAnswers[question.id] = checkAnswers[question.id] || {}
                    is_completed = false
                    checkAnswers[question.id][subId] = { required: true }
                  }
                }
              }
            }
          }
          if (is_completed && an) {
            const textOrSubChoicesRequired =
              an.textRequired ||
              an.subChoiceRequired ||
              Object.keys(an)
                .map((a) => an[a])
                .filter((a) => a)
                .find(
                  (a) =>
                    a.textRequired ||
                    a.subChoiceRequired ||
                    Object.keys(a)
                      .map((aa) => a[aa])
                      .find(
                        (aa) => aa && (aa.textRequired || aa.subChoiceRequired)
                      )
                )
            is_completed = !textOrSubChoicesRequired
          }
        }
      }
      return is_completed
    }

    next = async (isPreScreening) => {
      const { postQueue } = this.state
      if (Object.keys(postQueue).length) return
      const is_completed = await this.checkCompleted('force check')
      if (!is_completed) {
        this.setState({ showError: true })
      } else {
        if (isPreScreening) {
          return true
        } else {
          this.goAhead()
        }
      }
      this.scrollToTop()
    }

    checkCompleted = async (isForced) => {
      const {
        survey,
        step,
        answers,
        person,
        nutritionDate,
        emailSent
      } = this.state
      const section = survey.sections[step]
      const checkAnswers = answers[survey.sections[step].id]
      const is_completed = this.checkCompletedForSection(
        section,
        answers,
        checkAnswers,
        isForced
      )

      if (isForced) {
        this.setState({ answers })
      }
      if (
        section.original_id === 'nutrition_eating_habits' &&
        is_completed &&
        !emailSent
      ) {
        //send email
        this.setState({ emailSent: true })
        await completedCallback(
          (person || this.props.account.user).id,
          nutritionDate
        )
      }
      return is_completed
    }

    logOut = (history) => {
      clearStorage()
      logOut()
      history.push('/sign-in')
    }

    checkAnswer = (queue) => {
      const { postQueue, step, survey, answers } = this.state
      const section_id = survey.sections[step].id
      const question = survey.sections[step].questions.find(
        (item) => item.id === Number(queue.qid)
      )
      let answer = answers[section_id][question.id]

      let skip = false
      if (_.includes(['text_entry', 'date', 'time', 'number'], question.type)) {
        if (
          !queue.answer[queue.qid] ||
          (question.type === 'number' && typeof answer !== 'number')
        ) {
          delete postQueue[queue.qid]
          skip = true
        }
      } else if (question.type === 'slider') {
        if (!answer) {
          delete postQueue[queue.qid]
          skip = true
        }
      } else if (question.type === 'question_group') {
        const subQuestions = survey.sections[step].questions.filter(
          (item) => item.id && item.id.toString().includes(`${queue.qid}_`) //=== `${queue.qid}_${subId}`
        )
        for (let subQ of subQuestions) {
          const subId = subQ.id.split('_')[1]
          let subAnswer = answer[subId]
          let valid = true
          if (!subAnswer && subQ.required) {
            answer[subId] = { required: true }
            valid = false
          } else if (subAnswer) {
            if (subQ.type === 'single_choice') {
              const index = subQ.choices.findIndex(
                (item) => item.allow_text_entry && item.required
              )
              const checkChoice = index !== -1 && subAnswer[index]
              if (checkChoice && !checkChoice.text) {
                checkChoice.textRequired = true
                valid = false
              }
            }
          }
          if (!valid) {
            delete postQueue[queue.qid]
            skip = true
          }
        }
      } else if (question.type !== 'yes_no') {
        //single choices and miti choices
        const optionals = question.choices || question.items
        const hasAdditional = optionals && optionals[0].additional_information
        if (Object.keys(queue.answer).length === 0) {
          delete postQueue[queue.qid]
          skip = true
        } else if (hasAdditional) {
          let valid = true
          for (let choiceIndex of Object.keys(answer)) {
            const option = optionals[choiceIndex]
            for (let addInfo in option.additional_information) {
              if (
                option.additional_information[addInfo].required &&
                (!answer[choiceIndex] || !answer[choiceIndex][addInfo])
              ) {
                answer.textRequired = answer.textRequired || {}
                answer.textRequired[choiceIndex] =
                  answer.textRequired[choiceIndex] || []
                answer.textRequired[choiceIndex].push(addInfo)
                skip = true
                valid = false
              }
            }
          }

          if (!valid) {
            delete postQueue[queue.qid]
            skip = true
          }
        } else {
          // find choice whitch need input text or whitch is required
          const index = optionals.findIndex(
            (item) => item.allow_text_entry && item.required
          )

          let checkChoice = index !== -1 && answer[index]
          if (checkChoice && !checkChoice.text) {
            checkChoice.textRequired = true
            delete postQueue[queue.qid]
            skip = true
          }

          //check choice whitch is need
          for (const i in optionals) {
            if (answer[i] && answer[i].subChoiceRequired) {
              delete postQueue[queue.qid]
              skip = true
            }
            if (
              answer[i] &&
              optionals[i].subChoices &&
              Object.keys(answer[i]).length <= 0
            ) {
              answer[i].subChoiceRequired = true
              delete postQueue[queue.qid]
              skip = true
            }
          }

          if (question.type === 'rank_order') {
            for (const key in answer) {
              const item = answer[key] || {}
              if (isNaN(item.rank) && typeof item !== 'string') {
                item.invalidRank = true
                postQueue[queue.qid].timeStamp = new Date().getTime()
                skip = true
              }
            }
          }
        }
      }
      this.setState({ answers, postQueue })
      return skip
    }

    autoPost = async () => {
      const {
        postQueue,
        step,
        survey,
        answers,
        postDoing,
        person,
        nutritionDate
      } = this.state
      const section_id = survey.sections[step].id
      if (postDoing) return
      this.setState({ postDoing: true })
      const getQUeue = () =>
        Object.keys(postQueue)
          .filter((qid) => postQueue[qid])
          .map((qid) => {
            const { timeStamp, answer, type } = postQueue[qid]
            if (answer && answer.required) {
              delete answer.required
            }
            const result = {
              id: survey.id,
              qid,
              section_id,
              answer: {
                [qid]: answer
              },
              timeStamp,
              type
            }
            if (section_id === questionnaireConfig.nutritionId) {
              result.nutrition_date = nutritionDate
            }
            return result
          })

      let queue

      const checkQueue = async () => {
        queue = getQUeue()
        if (!queue.length) {
          this.setState({ postDoing: false })
          return
        }
        queue.sort((a, b) => a.timeStamp - b.timeStamp)
        const timeStamp = new Date().getTime()
        if (timeStamp - queue[0].timeStamp >= 3 * 1000) {
          const skip = this.checkAnswer(queue[0])
          if (!skip) {
            try {
              this.setState({ updating: true })
              await postAnswer(queue[0], person && person.id)
              delete postQueue[queue[0].qid]
              // todo:  update answers of state
              const updating = Object.keys(postQueue).length ? true : false
              await this.setState({ postQueue, updating })
              if (Object.keys(postQueue).length === 0) {
                // call api to know if questionnaire is completed
                const percent = questionCompletion(survey, answers)
                if (Number(percent) === 100) {
                  completeSurvey(person.id)
                }
              }
            } catch (err) {
              console.error(err)
              this.setState({ updating: false, postDoing: false })
              return
            }
          }
        }

        setTimeout(checkQueue, 1000)
      }

      checkQueue()
    }

    updateAnswers = (qid, body) => {
      const { answers, step, survey, postQueue } = this.state
      const section_id = survey && survey.sections[step].id
      if (typeof qid === 'string') {
        // handle answer for question group
        const [pQ, cQ] = qid.split('_')
        let answer = answers[section_id][pQ] || {}
        answer = Object.assign(answer, { [cQ]: body })
        answers[section_id][pQ] = answer
        postQueue[pQ] = {
          answer,
          timeStamp: new Date().getTime()
        }
      } else {
        answers[section_id][qid] = body
        postQueue[qid] = {
          answer: body,
          timeStamp: new Date().getTime()
        }
      }
      this.setState({ answers, postQueue, showError: false })
      this.autoPost()
    }

    render() {
      const childProps = {
        ...this.state,
        title: 'PreScreening',
        logOut: this.logOut,
        next: this.next,
        back: this.back,
        initialAnswers: (answers) => this.setState(answers),
        updateAnswers: this.updateAnswers,
        checkCompletedForSection: this.checkCompletedForSection
      }
      return <WrappedComponent {...childProps} {...this.props} />
    }
  }

export function checkAnswer(question, answers, options) {
  const { questions } = options
  const answer = answers[question.id]
  if (
    answer &&
    !_.includes(['text_entry', 'date', 'time', 'number'], question.type)
  ) {
    if (question.type === 'question_group') {
      const subQuestions = questions.filter(
        (item) => item.id && item.id.toString().includes(`${question.id}_`) //=== `${queue.qid}_${subId}`
      )
      for (let subQ of subQuestions) {
        const subId = subQ.id.split('_')[1]
        let subAnswer = answer[subId]
        if (!subAnswer && subQ.required) {
          answer[subId] = { required: true }
        } else if (subAnswer) {
          if (subQ.type === 'single_choice') {
            const index = subQ.choices.findIndex(
              (item) => item.allow_text_entry && item.required
            )
            const checkChoice = index !== -1 && subAnswer[index]
            if (checkChoice && !checkChoice.text) {
              checkChoice.textRequired = true
            }
          }
        }
      }
    } else if (question.type !== 'yes_no' && question.type !== 'slider') {
      const optionals = question.choices || question.items
      const hasAdditional = optionals && optionals[0].additional_information
      if (hasAdditional) {
        for (let choiceIndex of Object.keys(answer)) {
          const option = optionals[choiceIndex]
          for (let addInfo in _.get(option, 'additional_information', [])) {
            if (
              option.additional_information[addInfo].required &&
              (!answer[choiceIndex] || !answer[choiceIndex][addInfo])
            ) {
              answer.textRequired = answer.textRequired || {}
              answer.textRequired[choiceIndex] =
                answer.textRequired[choiceIndex] || []
              answer.textRequired[choiceIndex].push(addInfo)
            }
          }
        }
      } else {
        const index = optionals.findIndex(
          (item) => item.allow_text_entry && item.required
        )
        let checkChoice = index !== -1 && answer[index]
        if (checkChoice && !checkChoice.text) {
          checkChoice.textRequired = true
        }

        //check choice whitch is need
        for (const i in optionals) {
          if (
            answer[i] &&
            optionals[i].subChoices &&
            Object.keys(answer[i]).length <= 0
          ) {
            answer[i].subChoiceRequired = true
          }
        }

        if (question.type === 'rank_order') {
          for (const key in answer) {
            const item = answer[key] || {}
            if (isNaN(item.rank) && typeof item !== 'string') {
              item.invalidRank = true
            }
          }
        }
      }
    }
    answers[question.id] = answer
  }

  return { answers }
}
