import { io } from 'socket.io-client'
import { getToken } from '../../utils/storage'
import axios from '../../api/axios' //'./axios'

// let socket = io('wss://api-staging.apeiron.life', {
//   query: { token: getToken() }
// })

// export default socket

export const clearEmailEditStatus = (emailId) =>
  axios.post(`/jobs/manual-unlock-email?emailId=${emailId}`)

export default class ScketIo {
  constructor(props) {
    const {
      onConnecting,
      disConnect,
      onData,
      locked,
      unlocked,
      accessed,
      sync,
      emailId
    } = props

    this.socket = io(
      process.env.REACT_APP_API_ENDPOINT.replace('https', 'wss'),
      {
        query: { token: 'Bearer ' + getToken() },
        transports: ['websocket', 'polling']
      }
    )
    console.log('IO socket', this.socket)
    this.socket.on('connect', () => {
      onConnecting(this.socket)
    })
    this.socket.on('disconnect', (reson) => {
      console.log('disconnect', reson)
      disConnect(reson)
    })
    this.socket.on('data', (data) => {
      console.log(data)
      onData(data, this.socket)
    })
    this.socket.on('connect_error', (err) => {
      // revert to classic upgrade
      console.log('connect_error:', err)
      this.socket.io.opts.transports = ['websocket', 'polling']
    })

    this.socket.on(`room_email_${emailId}`, (data) => {
      console.log(`follow email room: ${emailId}`, data) // { id: 1 } id is summary email id
    })

    this.socket.on('email_sync', (data) => {
      console.log('email_sync:', data) // { id: 1, person: { id, nickname, email, full_name, avatar, socket_id }}
      sync(data)
    })

    // event: who is editing this summary email
    // IMPORTANT: this event will be triggered even the client obtained access permission, so check the locked person id, if it's yourself, just ignore this event
    this.socket.on('email_locked', (data) => {
      console.log('email_locked:', data) // { id: 1, person: { id, nickname, email, full_name, avatar, socket_id }}
      locked(data)
    })

    // event: expert had been saved or leaved the summary email page, so all experts opened this email page should be notified
    this.socket.on('email_unlocked', (data) => {
      console.log('email_unlocked:', data) // { id: 1 } id is summary email id
      unlocked(data, this.socket)
    })

    // event: obtained edit permission
    this.socket.on('email_accessed', (data) => {
      console.log('email_accessed:', data) // { id: 1 } id is summary email id
      accessed(data)
    })
  }
}
