import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { getAxisX } from '../common'
import { fontFamily } from '../../../utils/constant'

function Arrow(props) {
  return (
    <svg
      width="3"
      height="7"
      viewBox="0 0 3 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x}
      y={(props.y && props.y - 4) || 0}
    >
      <path
        d="M3 3.4587L6.67564e-08 6.00409L4.79289e-09 0.913305L3 3.4587Z"
        fill={props.style.fill}
      />
    </svg>
  )
}

class NormalBar extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    target: PropTypes.number,
    entries: PropTypes.array,
    barColor: PropTypes.any, // string or function
    extraAxisText: PropTypes.string,
    tickFormat: PropTypes.func,
    isYesNoGoal: PropTypes.bool,
    showXLabel: PropTypes.bool,
    barWidth: PropTypes.number,
    showLabel: PropTypes.bool
  }

  getTickValuesY(entries) {
    const { min, max, target } = this.props
    let _min, _max
    let refferData = [min, max, target].filter((item) => item || item === 0)
    if (entries) {
      refferData = refferData.concat(entries.map((item) => item.y))
    }
    _min = Math.min(...refferData)
    _max = Math.max(...refferData)
    if (_max === 0) _max = 100
    if (_min === 0 && target && target !== 0) _max = target * 2
    if (_min >= 1) _min -= 1
    _max += 1
    const result = [_min, min, max, target, _max].filter(
      (item) => item || item === 0
    )
    return result
  }

  getGridStroke = (t) => {
    const { min, max, target, isYesNoGoal } = this.props
    if (isYesNoGoal) {
      return 'transparent'
    }
    const refferData = [min, max, target]
    if (refferData.find((item) => item === t)) {
      return '#E4EAEF'
    }
    return 'transparent'
  }
  getLabelFill = (t) => {
    const { min, max, target, isYesNoGoal } = this.props
    if (isYesNoGoal) {
      return 'transparent'
    }
    const refferData = [min, max, target]
    if (refferData.find((item) => item === t)) {
      return '#70808E'
    }
    return 'transparent'
  }

  renderPlaceholderBar(tickValuesX = [], tickValuesY, entries) {
    const { emptyOptions, barWidth } = this.props
    const max = tickValuesY[tickValuesY.length - 1]
    const middle = max / 2
    let data =
      (entries && entries.filter((item) => !item.y)) ||
      tickValuesX.map((x) => ({ x, y: 0 }))
    data = data.map((item) => {
      const m = max / 100
      let y = m * 3,
        y0 = 0
      if (emptyOptions === 'middle') {
        y0 = middle
        y += y0
      }
      return { x: item.x, y, y0 }
    })
    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: '#C4C4C4'
          }
        }}
        barWidth={barWidth || (emptyOptions === 'middle' ? 4 : 12)}
        cornerRadius={{ top: 2, bottom: 2 }}
        data={data}
      />
    )
  }

  render() {
    const {
      startDate,
      endDate,
      barColor,
      extraAxisText,
      tickFormat,
      showXLabel,
      showLabel,
      barWidth
    } = this.props // entries
    let { entries } = this.props
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    if (entries && tickValuesX) {
      entries = tickValuesX.map((x, index) => ({ x, y: entries[index] }))
    }
    const tickValuesY = this.getTickValuesY(entries)
    return (
      <ContainerWidthSizer>
        {({ width }) => (
          <VictoryChart width={width} height={60}>
            <VictoryAxis
              invertAxis={true}
              dependentAxis
              orientation="right"
              tickValues={tickValuesY}
              tickFormat={tickFormat}
              style={{
                axis: { stroke: 'transparent' },
                grid: {
                  stroke: this.getGridStroke,
                  strokeDasharray: '3,3'
                },
                tickLabels: {
                  fontFamily: `"Gilroy",${fontFamily}`,
                  fontSize: '9px',
                  fill: this.getLabelFill
                }
              }}
            />
            {extraAxisText && (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                tickValues={tickValuesY}
                tickLabelComponent={<Arrow text={extraAxisText} />}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fontFamily: `"Gilroy",${fontFamily}`,
                    fontSize: '9px',
                    fill: this.getLabelFill
                  }
                }}
              />
            )}
            {extraAxisText && (
              <VictoryAxis
                invertAxis={true}
                dependentAxis
                tickValues={tickValuesY}
                tickLabelComponent={
                  <VictoryLabel
                    text={extraAxisText}
                    angle={-90}
                    dy={-8}
                    textAnchor="middle"
                  />
                }
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fontFamily: `"Gilroy",${fontFamily}`,
                    fontSize: '9px',
                    fill: this.getLabelFill
                  }
                }}
              />
            )}

            {entries && (
              <VictoryBar
                offsetX={10}
                data={entries}
                barWidth={barWidth || 12}
                cornerRadius={{ top: 2, bottom: 2 }}
                style={{
                  data: {
                    fill: barColor
                  },
                  labels: {
                    fontSize: 10,
                    fill: '#70808E'
                  }
                }}
                {...(showLabel
                  ? {
                      labels: (datum) => datum.y,
                      labelComponent: <VictoryLabel dy={8} />
                    }
                  : {})}
              />
            )}

            {tickValuesX &&
              tickValuesY &&
              this.renderPlaceholderBar(tickValuesX, tickValuesY, entries)}
            {showXLabel && (
              <VictoryAxis
                offsetY={20}
                tickValues={tickValuesX}
                tickFormat={(x) => x.format('ddd').toUpperCase()}
                style={{
                  axis: { stroke: 'transparent' },
                  tickLabels: {
                    fontFamily: `"Gilroy",${fontFamily}`,
                    fontSize: '10px',
                    fill: '#70808E'
                  }
                }}
              />
            )}
          </VictoryChart>
        )}
      </ContainerWidthSizer>
    )
  }
}

export default NormalBar
