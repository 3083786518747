import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import TodayTag from '../../../components/UI/todayTag'
import DataTable from '../../../components/UI/table'
import PageLoading from '../../../components/UI/status/loading'
import {
  getPartnershipSessions,
  exportPartnershipSessions
} from '../../../api/partnershipRegistration'
import _ from 'lodash'
import moment from 'moment'

export const Container = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 24px;
    .title {
      font-weight: 500;
      font-size: 36px;
      letter-spacing: 0.3px;
      color: #323f4a;
    }
    .action {
      display: flex;
      align-items: center;
      button {
        height: 39px;
        width: 131px;
      }
    }
  }
  .page-content {
    padding: 24px 0;
    .description {
      letter-spacing: 0.3px;
      font-size: 18px;
      font-weight: 600;
      color: #383e48;
      margin-bottom: 24px;
    }
    .ant-table-thead > tr > th {
      font-size: 12px;
      color: #5c626a;
      background: white;
      letter-spacing: 0.3px;
      .ant-table-column-sorters {
        justify-content: flex-start;
        .ant-table-column-title {
          flex: none;
        }
      }
    }
    .ant-table-tbody > tr > td {
      font-size: 14px;
      color: #5e687d;
      vertical-align: top;
      letter-spacing: 0.3px;
      font-weight: 400;
    }
  }
`

const columns = [
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 180,
    render: (value) => {
      return moment(value).format('M/D/YY - h:mm A')
    },
    sorter: (left, right) => {
      return moment(left.created_at).unix() - moment(right.created_at).unix()
    }
  },
  {
    title: 'NAME',
    dataIndex: 'name',
    key: 'name',
    width: 180,
    sorter: (a, b) => {
      return (
        a.name.toLowerCase().charCodeAt() - b.name.toLowerCase().charCodeAt()
      )
    }
  },
  {
    title: 'EMAIL',
    dataIndex: 'email',
    key: 'email',
    width: 180,
    sorter: (a, b) => {
      return (
        a.email.toLowerCase().charCodeAt() - b.email.toLowerCase().charCodeAt()
      )
    }
  },
  {
    title: 'PHONE',
    dataIndex: 'phone',
    key: 'phone',
    width: 180,
    sorter: (a, b) => a.phone.localeCompare(b.phone)
  },
  {
    title: 'INTEREST LEVEL',
    dataIndex: 'interest_level',
    key: 'interest_level',
    render: (value) => {
      const result = _.trim(value)
      return <div style={{ whiteSpace: 'pre-line' }}>{result || '--'}</div>
    },
    sorter: (a, b) => {
      return (
        a.interest_level.toLowerCase().charCodeAt() -
        b.interest_level.toLowerCase().charCodeAt()
      )
    }
  }
]

export default function PartnershipRegistration() {
  const [loading, setLoading] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [exporting, setExporting] = useState(false)

  const exportData = async () => {
    setExporting(true)
    const report = await exportPartnershipSessions()
    window.open(report.url)
    setExporting(false)
  }

  useEffect(() => {
    initial()
  }, [])

  return loading ? (
    <PageLoading />
  ) : (
    <Container>
      <div className="page-header">
        <div className="title">Partnership Registration</div>
        <div className="action">
          {/* <DateRange /> */}
          <TodayTag />
          <Button
            type="primary"
            loading={exporting}
            disabled={exporting}
            onClick={exportData}
          >
            EXPORT CSV
          </Button>
        </div>
      </div>
      <div className="page-content">
        <div className="description">
          <p>Bay Club Member Interest</p>
        </div>
        <DataTable
          dataSource={dataSource}
          columns={columns}
          hidePagination={true}
        />
      </div>
    </Container>
  )

  async function initial() {
    const result = await getPartnershipSessions()
    setDataSource(result)
    setLoading(false)
  }
}
