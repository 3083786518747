import axios from './axios'

export const getWeightingActivities = () =>
  axios.get('/activity_details/hsd_weightings')

export const putWeightingActivity = (params) =>
  axios.putJson('/activity_details/hsd_weighting', params)

export const resortWeightingActivities = (params) =>
  axios.putJson('/activity_details/hsd_weightings/resort', params)

export const exportActivities = () =>
  axios.get('activity_details/hsd_weightings/export')
