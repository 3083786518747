import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { Icon } from 'antd'

class SelectPeriodBar extends Component {
  static propTypes = {
    type: PropTypes.string,
    switchStatus: PropTypes.func
  }

  render() {
    const { type, switchStatus } = this.props
    return (
      <div className="select-bar">
        <div className="period">
          {switchStatus && (
            <>
              <div
                className={type === 'active' ? 'selected' : ''}
                onClick={() => switchStatus('active')}
              >
                Active Metrics
              </div>
              <div
                className={type === 'archived' ? 'selected' : ''}
                onClick={() => switchStatus('archived')}
              >
                Archived Metrics
              </div>
            </>
          )}
        </div>
        {/* <div className="switch-date-range">
          <span className="arrow">
            <Icon type="left" onClick={prevDate} />
          </span>
          <span>{showDate}</span>
          <span className="arrow">
            <Icon type="right" onClick={nextDate} />
          </span>
        </div> */}
      </div>
    )
  }
}

export default SelectPeriodBar
