import React from 'react'
import moment from 'moment'
import {
  getScore,
  saveScoreRecommendation,
  sendNewSession
} from '../../api/nutrition'
import PageLoading from '../UI/status/loading'

export default (WrappedComponent) =>
  class NutritionScoreWrapper extends React.Component {
    state = {
      loading: true,
      score: [],
      editingTextNumber: 0
    }

    saveRecommendation = async (date, params, version) => {
      const { person } = this.props
      const { score } = this.state
      await saveScoreRecommendation(person.id, date, params, version)
      const targetScore = score.find((item) => item.date === date)
      if (targetScore) {
        targetScore.recommendation = Object.assign(
          targetScore.recommendation || {},
          params
        )
      }
      this.setState({ targetScore, score })
    }

    newSession = async () => {
      const { person } = this.props
      await sendNewSession(person.id, moment().format('YYYY-MM-DD'))
    }

    selectScore = (targetScore) => this.setState({ targetScore })

    initial = async () => {
      const { person, hash } = this.props
      let score = await getScore(person.id, hash)
      score = score.reverse()
      const latestDate = score[0].completion_date || score[0].date
      const latestVersionDate = score[0].date
      score = score.filter((item) => {
        return item.is_complete
      })

      this.setState({
        score: score.length > 8 ? score.slice(0, 8) : score,
        latestDate,
        latestVersionDate,
        loading: false
      })
    }

    componentDidMount() {
      this.initial()
    }

    render() {
      const childProps = {
        ...this.state,
        updateEditingCount: (count) => {
          const editingTextNumber = this.state.editingTextNumber + count
          this.setState({ editingTextNumber })
        },
        saveRecommendation: this.saveRecommendation,
        newSession: this.newSession,
        selectScore: this.selectScore,
        refresh: () => {
          this.initial()
          this.setState({ loading: true })
        }
      }
      return this.state.loading ? (
        <PageLoading />
      ) : (
        <WrappedComponent {...childProps} {...this.props} />
      )
    }
  }
