import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import SleepChart from './comp/sleep'
import Information from '../UI/extraInformation'
import NormalBar from './comp/normalBar'
import CheckDocs from './comp/checkDots'
import BedTimeBar from './comp/bedTime'
import HRV from './comp/hrv'
import SubTip from './comp/subTip'
import { calculateElementWidth } from './common'
import { SvgText } from '../icons/weeklySummary'
import { sumReduce, getFloat, getAxisX } from './common'
import { timesFormat } from '../../utils/common'
import directions from './directions.json'

import { nutritionStyle } from './styles/nutrition.sass'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const gray = '#b7bfc6',
  primary = '#383E48',
  normal = '#70808E'

class Rejuvenation extends Component {
  static propTypes = {}

  getNormalSvg = (text, color, widthOffset) => {
    return (
      <SvgText
        text={text}
        fill={color || primary}
        height={33}
        fontSize={30}
        width={
          calculateElementWidth('span', text, '30px').width + (widthOffset || 5)
        }
        textAttr={{
          dominantBaseline: 'mathematical',
          textAnchor: 'start',
          x: 0
        }}
      />
    )
  }

  getUnitSvg = (text, color) => {
    return (
      <SvgText
        text={text}
        fill={color || primary}
        height={24}
        fontSize={18}
        width={calculateElementWidth('span', text, '18px').width + 8}
        textAttr={{
          dominantBaseline: 'mathematical',
          textAnchor: 'start',
          x: 0
        }}
      />
    )
  }

  formatSleepDuration(inbedTime, useForChange) {
    const { hours, minutes } = timesFormat(inbedTime)
    if (useForChange) {
      return hours && hours * 60 + minutes
    }
    if (hours)
      return `${hours > 0 ? hours : ''}h${minutes ? minutes + 'm' : ''}`
  }

  formatSleepTime = (value, color) => {
    if (value) {
      const { hours, minutes } = timesFormat(value)
      if (minutes === 0) {
        return (
          <>
            {this.getNormalSvg(hours, color, 2)}
            {this.getUnitSvg('h', color)}
          </>
        )
      } else {
        return (
          <>
            {this.getNormalSvg(hours, color, 2)}
            {this.getUnitSvg('h', color)}
            {this.getNormalSvg(minutes, color, 2)}
            {this.getUnitSvg('m', color)}
          </>
        )
      }
    } else {
      return this.getNormalSvg('--')
    }
  }

  formatBefore11PM = (text, color) => {
    if (text || text === 0) {
      return this.getNormalSvg(text, color)
    } else {
      return this.getNormalSvg('--')
    }
  }

  processSleepDurationChart(startDate, endDate, sleep) {
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    if (tickValuesX) {
      let weekAvg = sleep && sleep.average && sleep.average.avg_inbed_time
      let monthAvg =
        sleep &&
        sleep.previous_weeks_average &&
        sleep.previous_weeks_average.avg_inbed_time
      return {
        weekAvg: weekAvg,
        monthAvg: monthAvg,
        entries: tickValuesX.map((date) => {
          let specificDay =
            sleep &&
            sleep.details &&
            sleep.details.find((detail) => {
              return date.format('YYYY-MM-DD') === detail.summary_date
            })
          if (specificDay) {
            return specificDay.inbed_time
          } else {
            return 0
          }
        })
      }
    }
  }

  processSleepHRVAverage(sleep) {
    const weekAvg = sleep && sleep.average && sleep.average.avg_hrv
    const monthAvg =
      sleep &&
      sleep.previous_weeks_average &&
      sleep.previous_weeks_average.avg_hrv
    return { weekAvg, monthAvg }
  }

  processSleepResting(sleep) {
    const weekAvg = sleep && sleep.average && sleep.average.avg_resting_hr
    const monthAvg =
      sleep &&
      sleep.previous_weeks_average &&
      sleep.previous_weeks_average.avg_resting_hr
    return { weekAvg, monthAvg }
  }

  getGoals = (sleep, rejuvenation, startDate, endDate) => {
    sleep.average = sleep.average || {}
    sleep.previous_weeks_average = sleep.previous_weeks_average || {}
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []

    const sleepEntries =
      tickValuesX.map((date) => {
        const matchEntry = sleep.details.find(
          (entry) => entry.summary_date === date.format('YYYY-MM-DD')
        )
        return (matchEntry && matchEntry.asleep_time) || null
      }) || []
    let sleepHoursAvg
    const t = sleepEntries.filter((hours) => hours)
    if (t.length > 0) {
      sleepHoursAvg = Number(getFloat(t.reduce(sumReduce) / t.length, 1))
    }

    const sleepDuration = this.processSleepDurationChart(
      startDate,
      endDate,
      sleep
    )
    const sleepHRVAverage = this.processSleepHRVAverage(sleep)
    const sleepResting = this.processSleepResting(sleep)
    const goals = [
      {
        id: 3,
        description: 'Time to bed', //overview -> bed time
        totalSubTip: '7 DAY AVG',
        total: (
          <>
            <SvgText
              text={
                sleep.average.avg_bedtime_start
                  ? sleep.average.avg_bedtime_start.split(' ')[0]
                  : '--'
              }
              height={33}
              fontSize={30}
              width={
                calculateElementWidth(
                  'span',
                  sleep.average.avg_bedtime_start
                    ? sleep.average.avg_bedtime_start.split(' ')[0]
                    : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleep.average.avg_bedtime_start && (
              <SvgText
                text={sleep.average.avg_bedtime_start.split(' ')[1]}
                height={24}
                fontSize={18}
                width={
                  calculateElementWidth(
                    'span',
                    sleep.average.avg_bedtime_start.split(' ')[1],
                    '18px'
                  ).width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        previous_weeks_average: (
          <>
            <SvgText
              text={
                sleep.previous_weeks_average.avg_bedtime_start
                  ? sleep.previous_weeks_average.avg_bedtime_start.split(' ')[0]
                  : '--'
              }
              height={33}
              fontSize={30}
              fill={gray}
              width={
                calculateElementWidth(
                  'span',
                  sleep.previous_weeks_average.avg_bedtime_start
                    ? sleep.previous_weeks_average.avg_bedtime_start.split(
                        ' '
                      )[0]
                    : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleep.previous_weeks_average.avg_bedtime_start && (
              <SvgText
                text={
                  sleep.previous_weeks_average.avg_bedtime_start.split(' ')[1]
                }
                height={24}
                fontSize={18}
                fill={gray}
                width={
                  calculateElementWidth(
                    'span',
                    sleep.previous_weeks_average.avg_bedtime_start.split(
                      ' '
                    )[1],
                    '18px'
                  ).width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        chart: (
          <BedTimeBar
            {...{
              startDate,
              endDate,
              details: sleep.details
            }}
          />
        )
      },
      {
        id: 1,
        description: 'Hours of sleep per week', //'Total hours of sleep',
        totalSubTip: '7 DAY TOTAL',
        total: this.formatSleepTime(
          sleep.average && sleep.average.avg_asleep_time
        ),
        previous_weeks_average: this.formatSleepTime(
          sleep.previous_weeks_average &&
            sleep.previous_weeks_average.avg_asleep_time,
          gray
        ),
        chart: (
          <NormalBar
            barWidth={16}
            showXLabel={true}
            {...{
              startDate,
              endDate,
              entries: sleepEntries,
              barColor: '#5C00D1',
              extraAxisText: 'Avg',
              target: sleepHoursAvg,
              // min: null,
              // max: null,
              tickFormat: (t) => {
                return this.formatSleepDuration(t)
              }
            }}
          />
        )
      },
      {
        id: 4,
        description: 'Hours in bed', // overview -> SLEEP DURATION
        totalSubTip: '7 DAY AVG',
        total: this.formatSleepTime(sleepDuration.weekAvg),
        previous_weeks_average: this.formatSleepTime(
          sleepDuration.monthAvg,
          gray
        ),
        chart: (
          <NormalBar
            barWidth={16}
            {...{
              startDate,
              endDate,
              extraAxisText: 'Avg',
              target: (sleepDuration && sleepDuration.weekAvg) || 0,
              entries: sleepDuration && sleepDuration.entries,
              barColor: '#5C00D1',
              showXLabel: true,
              tickFormat: (t) => {
                return this.formatSleepDuration(t)
              }
            }}
          />
        )
      },
      {
        id: 5,
        description: 'Heart rate variability', // overview -> hrv
        totalSubTip: '7 DAY AVG',
        total: (
          <>
            <SvgText
              text={sleepHRVAverage.weekAvg ? sleepHRVAverage.weekAvg : '--'}
              height={33}
              fontSize={30}
              width={
                calculateElementWidth(
                  'span',
                  sleepHRVAverage.weekAvg ? sleepHRVAverage.weekAvg : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleepHRVAverage.weekAvg > 0 && (
              <SvgText
                text="ms"
                height={24}
                fontSize={18}
                width={calculateElementWidth('span', 'ms', '18px').width + 5}
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        previous_weeks_average: (
          <>
            <SvgText
              text={sleepHRVAverage.monthAvg ? sleepHRVAverage.monthAvg : '--'}
              height={33}
              fontSize={30}
              fill={gray}
              width={
                calculateElementWidth(
                  'span',
                  sleepHRVAverage.monthAvg ? sleepHRVAverage.monthAvg : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleepHRVAverage.monthAvg > 0 && (
              <SvgText
                text="ms"
                height={24}
                fontSize={18}
                fill={gray}
                width={calculateElementWidth('span', 'ms', '18px').width + 5}
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        chart: (
          <HRV
            ranges={[0, 100]}
            {...{ startDate, endDate, sleep, field: 'sleep_hrv', unit: 'ms' }}
          />
        )
      },
      {
        id: 6,
        description: 'Resting Heart rate ', // overview --> resting_hr
        totalSubTip: '7 DAY AVG',
        total: (
          <>
            <SvgText
              text={sleepResting.weekAvg ? sleepResting.weekAvg : '--'}
              height={33}
              fontSize={30}
              width={
                calculateElementWidth(
                  'span',
                  sleepResting.weekAvg ? sleepResting.weekAvg : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleepResting.weekAvg > 0 && (
              <SvgText
                text="bpm"
                height={24}
                fontSize={18}
                width={calculateElementWidth('span', 'bpm', '18px').width + 5}
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        previous_weeks_average: (
          <>
            <SvgText
              text={sleepResting.monthAvg ? sleepResting.monthAvg : '--'}
              height={33}
              fontSize={30}
              fill={gray}
              width={
                calculateElementWidth(
                  'span',
                  sleepResting.monthAvg ? sleepResting.monthAvg : '--',
                  '30px'
                ).width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
            {sleepResting.monthAvg > 0 && (
              <SvgText
                text="bpm"
                height={24}
                fontSize={18}
                fill={gray}
                width={calculateElementWidth('span', 'bpm', '18px').width + 5}
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            )}
          </>
        ),
        chart: (
          <HRV
            ranges={[20, 100]}
            {...{ startDate, endDate, sleep, field: 'resting_hr', unit: 'bpm' }}
          />
        )
      }
    ]

    if (rejuvenation) {
      for (const item of rejuvenation) {
        const {
          id,
          description,
          previous_weeks_average,
          unit,
          input_type
        } = item
        item.barProps = this.props.processNormalGoal(
          startDate,
          endDate,
          item,
          '#5C00D1'
        )
        const SevenDayText =
          item.average || item.average === 0
            ? item.input_type === 'binary'
              ? Math.round(item.average * 100) + '%'
              : item.showLatestData
              ? item.showLatestData.value
              : item.average
            : '--'

        const PreviousWeeksText =
          previous_weeks_average || previous_weeks_average === 0
            ? input_type === 'binary'
              ? Math.round(previous_weeks_average * 100) + '%'
              : previous_weeks_average
            : '--'
        goals.push({
          id,
          description,
          unit,
          totalSubTip: item.showLatestData
            ? item.showLatestData.summary_date
              ? `${moment(item.showLatestData.summary_date).format(
                  'MMM DD, YYYY'
                )}`
              : '--'
            : '7 DAY AVG',
          total: (
            <SvgText
              text={SevenDayText}
              height={33}
              fontSize={30}
              width={
                calculateElementWidth('span', SevenDayText, '30px').width + 5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
          ),
          previous_weeks_average: (
            <SvgText
              text={PreviousWeeksText}
              height={33}
              fontSize={30}
              fill={gray}
              width={
                calculateElementWidth('span', PreviousWeeksText, '30px').width +
                5
              }
              textAttr={{
                dominantBaseline: 'mathematical',
                textAnchor: 'start',
                x: 0
              }}
            />
          ),
          chart:
            item.input_type === 'binary' ? (
              <CheckDocs
                size="large"
                showLabel={true}
                yesColor="#5C00D1"
                {...{ startDate, endDate, ...item.barProps }}
              />
            ) : (
              <NormalBar
                barWidth={16}
                showXLabel={true}
                showLabel={true}
                {...{ startDate, endDate, ...item.barProps }}
              />
            )
        })
      }
    }

    return goals
  }

  renderSleepGoals() {
    const {
      data,
      oprRejuvenation,
      submitData: { rejuvenation },
      startDate,
      endDate,
      isLocked
    } = this.props
    const isEditable = !isLocked && this.props.isEditable

    const { sleep } = data || { sleep: {} }
    sleep.details = sleep.details || []
    let sleepGoals = this.getGoals(
      sleep,
      data && data.rejuvenation,
      startDate,
      endDate
    )

    if (rejuvenation.sleep_goals) {
      sleepGoals.map((item) => {
        const target = rejuvenation.sleep_goals.find(
          (goal) => goal.id === item.id
        )
        if (target) {
          target.description = item.description
          return Object.assign(item, target)
        }
        return item
      })
    }

    if (rejuvenation.deleted_sleep_goal) {
      sleepGoals = sleepGoals.filter(
        (item) => !rejuvenation.deleted_sleep_goal.find((id) => id === item.id)
      )
    }

    return (
      <div className={nutritionStyle}>
        {sleepGoals.map((item, index) => (
          <div className="goal-item" key={index}>
            {isEditable && (
              <div
                className="delete"
                onClick={() =>
                  oprRejuvenation('delete', {
                    id: item.id,
                    section: `Rejuvenation - ${item.description}`
                  })
                }
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}

            <div className="name">{item.description}</div>
            <div
              className="data-content ref-container"
              ref={`rejuvenation.chart_url.${item.id}`}
            >
              <div>
                <div className="days">
                  {item.total}
                  {(item.formatChange || item.formatChange === 0) && (
                    <SvgText
                      text={`${item.formatChange > 0 ? '+' : ''}${
                        item.formatChange
                      }`}
                      fill={normal}
                      fontSize={12}
                      height={18}
                      fontFamily="Gilroy"
                      width={
                        calculateElementWidth(
                          'span',
                          `${item.formatChange > 0 ? '+' : ''}${
                            item.formatChange
                          }`,
                          '12px'
                        ).width + 5
                      }
                      textAttr={{
                        x: 0,
                        textAnchor: 'start',
                        dominantBaseline: 'mathematical',
                        fontWeight: 'normal'
                      }}
                    />
                  )}
                  <SubTip text={item.totalSubTip} unit={item.unit} />
                </div>
                <div className="weeks">
                  {item.previous_weeks_average}
                  <SubTip text="3 WEEK AVG" unit={item.unit} />
                </div>
              </div>
              <div className="chart">{item.chart}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      startDate,
      endDate,
      data,
      loginRole,
      setSubmitData,
      originInformation,
      deleteSections,
      templates,
      updateEditingCount,
      updateEmailAction,
      isLocked,
      submitData: { rejuvenation, deleted_section }
    } = this.props

    const isEditable = !isLocked && this.props.isEditable
    const status = isLocked
      ? 'Locked'
      : (originInformation && originInformation.status) || 'Draft'
    return (
      <>
        <Information
          updateEditingCount={updateEditingCount}
          updateEmailAction={updateEmailAction}
          title="Overview"
          showDone={loginRole !== 'User'}
          status={status}
          initialValue={rejuvenation.recommendation}
          doneStatus={rejuvenation.recommendation_status}
          templates={templates}
          direction={directions.rejuvenation}
          updateCommend={(value, status) =>
            setSubmitData({
              part: 'rejuvenation',
              field: 'recommendation',
              value,
              status,
              section: 'Recommendation of Rejuvenation'
            })
          }
        />
        {!deleted_section.rejuvenation_quality && (
          <div className="sub-section">
            {isEditable && (
              <div
                className="delete"
                onClick={() => deleteSections('rejuvenation_quality')}
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}

            <h4 className="sub-title">Quality of sleep</h4>
            <div
              className="big-chart ref-container"
              ref="rejuvenation.sleep_chart_url"
            >
              <SleepChart
                {...{ startDate, endDate, sleep: data ? data.sleep : {} }}
              />
            </div>
          </div>
        )}
        {this.renderSleepGoals()}
      </>
    )
  }
}

export default Rejuvenation
