import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { undoStyle } from './styles/undo.sass'

class Undo extends Component {
  static propTypes = {
    duration: PropTypes.number,
    position: PropTypes.string,
    undo: PropTypes.func,
    close: PropTypes.func
  }

  componentDidMount() {
    if (this.props.duration) {
      setTimeout(() => this.props.close(), (this.props.duration || 5) * 1000)
    }
  }

  render() {
    return (
      <div className={undoStyle}>
        <div>{this.props.position} has been removed.</div>
        <div onClick={this.props.undo}>UNDO</div>
      </div>
    )
  }
}

export default Undo
