import React, { useState, useEffect, useReducer } from 'react'
import _ from 'lodash'
import { Button, Modal } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { animateScroll as scroll } from 'react-scroll'
import { ErrorIcon } from '../../../components/icons/onboarding'
import PageLoading from '../../../components/UI/status/loading'
import Question from '../questionComponentsV2/question'
import CompletedMessage from './completed'
import { OnboardingHeader } from '../style'
import AnswerUpdater, {
  verifyAnswer,
  checkCompleted
} from '../questionComponentsV2/answerUpdater'
import { loadUser, logOutAction } from '../../../utils'

import {
  getSectionDetails,
  getSessionOrInit,
  updateProfileStatus,
  sendPreScreeningEmail
} from '../../../api'

const initialState = {
  section: null,
  answerInfo: { answers: [] },
  answerUpdater: null,
  loading: false,
  saveCount: 0
}

export default function PreScreening(props) {
  const { history, setLoginAccount, logOut } = props
  const person = loadUser()
  const [pageLoading, setPageLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [isCompleted, setIsCompleted] = useState(null)

  const [state, dispatch] = useReducer((state, action) => {
    if (action.type === 'update answers') {
      const { answerUpdater, answerInfo } = state
      setSaveLoading(answerUpdater.isLoading())
      const _answerInfo = answerUpdater._answers.find(
        (item) => item.section_original_id === answerInfo.section_original_id
      )
      return _.assignIn(state, { answerInfo: _answerInfo })
    } else if (action.type === 'check answers') {
      const answerInfo = state.answerInfo
      answerInfo.answers = action.answers
      return _.assign({}, state, { answerInfo })
    } else {
      return _.assign({}, state, action.payload)
    }
  }, initialState)

  const { section, answerInfo, answerUpdater } = state

  useEffect(() => {
    const initial = async () => {
      const [section] = await getSectionDetails(person.id, 'pre_medical')
      const answers = await getSessionOrInit(person.id, 'pre_medical')
      dispatch({
        type: 'initial',
        payload: {
          section,
          answerInfo: answers[0] || {},
          answerUpdater: new AnswerUpdater({
            answers,
            sections: [section],
            saveCallback: () => {
              dispatch({ type: 'update answers' })
            }
          })
        }
      })
      setPageLoading(false)
      if (person.profile.pre_screening_locked) {
        Modal.info({
          // title: 'This is a notification message',
          width: 530,
          content: (
            <div>
              <p style={{ lineHeight: '25px' }}>
                Due to your medical history, our Medical Director Dr. Dev Mishra
                will reach out to you for discussion prior to participation in
                our Apeiron Life program.
              </p>
            </div>
          ),
          okText: 'Log Out',
          onOk() {
            logOutAction()
          }
        })
      }
    }
    initial()
  }, [])

  return (
    <div className="main-container">
      <OnboardingHeader />
      {isCompleted ? (
        <CompletedMessage
          type={isCompleted}
          goNext={goNext}
          logOut={() => goNext('/logout')}
        />
      ) : (
        <>
          <div>
            <div className="step-title">
              <span>1</span>
              {section && section.name}
            </div>
          </div>

          <div className="main-content questionnaire">
            <h1 className="title">
              {section && section.name}
              <br />
              <span style={{ color: '#38435E', fontSize: 14 }}>
                {section && section.description}
              </span>
            </h1>

            {pageLoading && <PageLoading />}
            {showError && (
              <div className="error-message">
                <ErrorIcon />
                Error: Please answer all required questions.
              </div>
            )}

            {section &&
              section.questions.map((question) => (
                <Question
                  question={question}
                  key={question.original_id || question.id}
                  answers={(answerInfo && answerInfo.answers) || []}
                  updateAnswer={updateAnswer}
                  sessionId={answerInfo && answerInfo.id}
                />
              ))}
          </div>

          <footer>
            <span className="back" onClick={() => history.go(-1)}>
              <LeftOutlined /> back
            </span>
            <span
              className="invoiced-text"
              style={{
                width: 480,
                fontSize: 12,
                textAlign: 'right',
                position: 'absolute',
                right: 200
              }}
            >
              By clicking “Confirm” you acknowledge that this information will
              be shared with Apeiron Life for evaluation of your eligibility to
              become a member. For information on how we collect, use and share
              your information, please see our{' '}
              <a
                href="https://apeiron.life/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Privacy Policy
              </a>
            </span>

            <Button
              type="primary"
              loading={saveLoading}
              disabled={showError}
              onClick={onConfirm}
            >
              CONFIRM
            </Button>
          </footer>
        </>
      )}
    </div>
  )

  async function updateAnswer(answer, question) {
    if (showError) setShowError(false)
    const _answer = await verifyAnswer(answer, question)
    if (!_answer.verify) {
      setSaveLoading(true)
      answerUpdater.autoSave(_answer, section.original_id)
    }
  }

  async function onConfirm() {
    const { isCompleted, answers } = await checkCompleted(
      [answerInfo],
      [section]
    )
    if (!isCompleted) {
      setShowError(true)
      scroll.scrollToTop({ ignoreCancelEvents: true, smooth: true })
      dispatch({
        type: 'check answers',
        answers: answers[0].answers
      })
    } else {
      const _answers = answers[0].answers
      const answerValues = _answers.map((item) => item.answer.value)
      const anyYes = answerValues.find((isYes) => isYes)

      if (anyYes) {
        Modal.confirm({
          title: 'Are you sure you want to proceed?',
          content:
            'By selecting "Confirm," you confirm that the information provided is accurate and final. Please double-check your responses before proceeding. ',
          onOk() {
            sendPreScreeningEmail(person.id)
            updateProfileStatus(
              person.id,
              {
                pre_screening_completed: true,
                pre_screening_locked: true
              },
              (person) => setLoginAccount(person)
            )
            setIsCompleted('yes')
          },
          okText: 'Confirm',
          cancelText: 'Edit'
        })
      } else {
        setIsCompleted('no')
      }
    }
  }

  async function goNext(path) {
    const callback = (person) => setLoginAccount(person)
    if (path === '/logout') {
      logOut(history)
    } else {
      await updateProfileStatus(
        person.id,
        { pre_screening_completed: true },
        callback
      )
      history.push(path || '/onboarding')
    }
  }
}
