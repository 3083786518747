import React, { Component, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Modal, Checkbox, Button, AutoComplete, Input, Tooltip } from 'antd'
import EditInput from './input'
import TodayTag from '../../UI/todayTag'
import PageLoading from '../../UI/status/loading'
import DateRange from '../../UI/mgComponents/dateRange'
import healthkitIcon from '../../../asserts/icon/goals/goals_source_hk_icon.png'
import garminIcon from '../../../asserts/icon/goals/goals_source_garmin_icon.png'
import ouraIcon from '../../../asserts/icon/goals/goals_source_oura_icon.png'
import PolarLogo from '../../../asserts/icon/goals/goals_source_polar_icon.png'

import { modalFooter, modalStyle } from './styles/edit.sass'

class PillarEditModal extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    prevDate: PropTypes.func,
    nextDate: PropTypes.func,
    loading: PropTypes.bool,
    pageLoading: PropTypes.bool,
    overview: PropTypes.object,
    submitMetrics: PropTypes.func,
    person: PropTypes.object,
    activityNames: PropTypes.array
  }

  state = {
    workouts: [],
    editSleepData: [],
    clearCount: 0,
    exerciseGroup: [],
    originExerciseGroup: [],
    maxLength: 0,
    loading: true
  }

  clearAll = () => {
    const { clearCount, originExerciseGroup } = this.state
    this.setState({
      workouts: [],
      editSleepData: [],
      exerciseGroup: _.cloneDeep(originExerciseGroup),
      clearCount: clearCount + 1
    })
  }

  getEditData = () => {
    const { workouts } = this.state
    const editData = workouts.map((item) => {
      let result = {
        duration: item.details.duration,
        pillar: 'exercise',
        type: item.type || 'Aerobic',
        record_date: item.record_date,
        rpe_manual: item.rpe_manual,
        activity_type_shown: item.details.activity_type_shown
      }
      if (
        typeof item.details.duration !== 'number' &&
        typeof item.rpe_manual !== 'number'
      ) {
        if (typeof item.id === 'number') {
          result = { is_delete: true }
        } else {
          result = { is_empty: true }
        }
      }
      if (typeof item.id === 'number') {
        result.id = item.id
      }
      return result
    })
    return editData
  }

  submitValidate = () => {
    const { loading, pageLoading } = this.props
    const editData = this.getEditData().filter((data) => !data.is_delete)
    let hasError = editData.find((entry) => {
      const selectedWorkout =
        typeof entry.id === 'number' || entry.activity_type_shown
      const hasRpe = entry.rpe_manual || entry.rpe_auto
      const checkValue = [hasRpe, selectedWorkout, entry.duration].filter(
        (item) => item
      ).length

      if (
        checkValue > 0 &&
        checkValue <= 2 &&
        (!selectedWorkout || !entry.duration)
      ) {
        return entry
      }
      return false
    })

    return loading || pageLoading || hasError
  }

  submit = async () => {
    const {
      overview: { entries },
      submitMetrics,
      closeModal
    } = this.props
    const { editSleepData } = this.state
    const editData = this.getEditData().filter((item) => !item.is_empty)

    const editSleep = editSleepData
      .map((item) => {
        const origin = (entries || []).find(
          (entry) =>
            entry.record_date === item.record_date &&
            entry.pillar === 'rejuvenation'
        )
        if (origin) {
          if (
            origin.details.before_11pm === item.before_11pm &&
            item.asasleep_time <= 0
          ) {
            // lose efficacy
            return null
          } else {
            item.id = origin.id
          }
        }
        const details = item.details
        const { id, record_date } = item

        let result = {
          id,
          record_date,
          ...details,
          pillar: 'rejuvenation',
          type: 'sleep'
        }
        if (item.is_delete) {
          result = {
            is_delete: true,
            id
          }
        }
        return result
      })
      .filter((item) => item)

    const submitData = editData.concat(editSleep)

    try {
      if (submitData.length > 0) {
        await submitMetrics(submitData)
      }
      // this.clearAll()
      closeModal(true)
    } catch (err) {}
  }

  getDays = () => {
    const { startDate, endDate } = this.props
    if (!startDate || !endDate) return []
    const days = []
    let count = 1
    days.push(moment(startDate))
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      days.push(moment(startDate).add(count, 'days'))
      count++
    }
    days.push(moment(endDate))
    return days
  }

  updateWorkoutValue = (entry, type, value) => {
    const { exerciseGroup, workouts } = this.state
    const { activityNames } = this.props
    const group = exerciseGroup.find(
      (item) => item.record_date === entry.record_date
    )
    const target = group && group.entries.find((item) => item.id === entry.id)
    const editIndex = workouts.findIndex((item) => item.id === entry.id)
    entry =
      editIndex >= 0 ? workouts.find((item) => item.id === entry.id) : entry

    switch (type) {
      case 'type':
        const findedActivity = activityNames.find(
          (activity) => activity.activity_type_shown === value
        )
        entry.type =
          findedActivity && findedActivity.ativity_classification.value
        entry.details.activity_type_shown = value
        if (target) {
          target.type =
            findedActivity && findedActivity.ativity_classification.value
          target.details.activity_type_shown = value
        }
        break
      case 'duration':
        value = typeof value === 'number' ? value * 60 : null
        entry.details.duration = value
        target && (target.details.duration = value)
        break
      case 'rpe':
        entry.rpe_manual = value
        target && (target.rpe_manual = value)
        break
      default:
        break
    }

    if (editIndex >= 0) {
      workouts.splice(editIndex, 1, entry)
    } else {
      workouts.push(entry)
    }

    if (!target) {
      if (group) {
        group.entries.push(entry)
      } else {
        exerciseGroup.push({
          record_date: entry.record_date,
          entries: [entry]
        })
      }
    }

    this.setState({ workouts })
  }

  getIcon = (inputs) => {
    const autoInputs = inputs.filter((item) => !item.manual)
    autoInputs.sort((a, b) => {
      return moment(a.updated_at).isAfter(moment(b.updated_at)) ? 1 : -1
    })
    const source = autoInputs[0] && autoInputs[0].source
    switch (source) {
      case 'polar':
        return PolarLogo
      case 'ouraring':
        return ouraIcon
      case 'garmin':
        return garminIcon
      case 'healthkit':
        return healthkitIcon
      default:
        return null
    }
  }

  sleepHandle = (date, type, value) => {
    const { editSleepData } = this.state
    const input = this.getSleepInput(date)
    const disabled = (input && !input.manual) || moment().isBefore(date)
    if (disabled) return
    const record_date = date.format('YYYY-MM-DD')
    let record
    if (type === 'before_11pm') {
      record = Object.assign([], input, {
        record_date,
        manual: true,
        details: Object.assign([], input.details, {
          before_11pm: value
        })
      })
    } else {
      record = Object.assign([], input, {
        record_date,
        manual: true,
        details: Object.assign([], input.details, {
          asleep_time: value * 60 * 60
        })
      })
    }
    if (typeof value === 'number') {
      record.is_delete = false
    } else {
      record.is_delete = true
    }
    const index = editSleepData.findIndex(
      (item) => item.record_date === record_date
    )
    if (index < 0) {
      editSleepData.push(record)
    } else {
      editSleepData.splice(index, 1, record)
    }
    this.setState({ editSleepData })
  }

  changeTypeHandle = () => {}

  getSleepInput = (date) => {
    const { editSleepData } = this.state
    const {
      overview: { entries }
    } = this.props
    let input = (entries || []).find(
      (item) =>
        item.record_date === date.format('YYYY-MM-DD') &&
        item.pillar === 'rejuvenation'
    )
    const disabled = (input && !input.manual) || moment().isBefore(date)
    let editInput
    if (!disabled) {
      editInput = editSleepData.find(
        (item) => item.record_date === date.format('YYYY-MM-DD')
      )
    }

    return Object.assign(
      { manual: true, details: {} },
      editInput || input || {}
    )
  }

  getCheckSleep = (date) => {
    const input = this.getSleepInput(date)
    const disabled = (input && !input.manual) || moment().isBefore(date)
    const checked = input && input.details && input.details.before_11pm
    return (
      <Checkbox
        checked={checked}
        disabled={!!disabled}
        onChange={(e) =>
          this.sleepHandle(date, 'before_11pm', e.target.checked)
        }
      />
    )
  }

  getSleepTime = (date) => {
    const input = this.getSleepInput(date)
    const disabled = (input && !input.manual) || moment().isBefore(date)
    const value =
      input && input.details && typeof input.details.asleep_time === 'number'
        ? Math.round((input.details.asleep_time / 3600) * 10) / 10
        : null
    const icon = input && this.getIcon([input])
    return (
      <EditInput
        initValue={value}
        min={0}
        disabled={!!disabled}
        clearCount={this.state.clearCount}
        onChangeHandle={(value) => this.sleepHandle(date, 'hours', value)}
        icon={icon}
      />
    )
  }

  groupEntries = (entries) => {
    const dates = entries.map((item) => item.record_date)
    let group = Array.from(new Set(dates))
    group = group.map((item) => {
      return {
        record_date: item,
        entries: entries.filter((entry) => entry.record_date === item)
      }
    })
    return group
  }

  getExerciseInfo = (day, index, type) => {
    const { exerciseGroup, workoutNames } = this.state
    const group = exerciseGroup.find(
      (item) => item.record_date === day.format('YYYY-MM-DD')
    ) || { entries: [] }

    const entry = group.entries[index] || {
      details: {},
      manual: true,
      record_date: day.format('YYYY-MM-DD'),
      id: day.format('YYYY-MM-DD--') + (index + 1)
    }

    const selectedWorkout =
      entry.details.activity_type_shown || entry.hsd_activity_name
    const isFuture = moment().isBefore(day)
    let disabled = isFuture
    if (index !== 0) {
      const lastEntry = group.entries[index - 1]
      disabled = lastEntry ? disabled : true
    }
    disabled = !!disabled
    const hasRpe = entry.rpe_manual || entry.rpe_auto
    const checkValue = [hasRpe, selectedWorkout, entry.details.duration].filter(
      (item) => item
    ).length

    switch (type) {
      case 'type':
        return (
          <ExerciseTypeItem
            {...{
              selectedWorkout,
              workoutNames,
              disabled,
              entry,
              type,
              checkValue,
              updateWorkoutValue: this.updateWorkoutValue
            }}
          />
        )
      case 'duration':
        return (
          <EditInput
            disabled={disabled || !entry.manual}
            hasError={
              checkValue > 0 &&
              checkValue <= 2 &&
              hasRpe &&
              !entry.details.duration
            }
            initValue={
              (entry.details &&
                typeof entry.details.duration === 'number' &&
                Math.round(entry.details.duration / 60)) ||
              null
            }
            key={`duration_${index}_${entry.id}`}
            // min={min}
            clearCount={this.state.clearCount}
            onChangeHandle={(value) =>
              this.updateWorkoutValue(entry, type, value)
            }
            icon={this.getIcon([entry])}
          />
        )
      case 'rpe':
        return (
          <EditInput
            disabled={disabled}
            initValue={
              typeof entry.rpe_manual === 'number'
                ? entry.rpe_manual
                : entry.rpe_auto
            }
            min={1}
            max={10}
            key={`rpe_${index}_${entry.id}`}
            clearCount={this.state.clearCount}
            onChangeHandle={(value) =>
              this.updateWorkoutValue(entry, type, value)
            }
            icon={typeof entry.rpe_manual !== 'number' && this.getIcon([entry])}
          />
        )
      default:
        return <></>
    }
  }

  componentDidMount() {
    const { overview, activityNames, showRangeDate } = this.props
    const entries = (overview.entries || []).filter(
      (item) => item.pillar === 'exercise'
    )
    const workoutNames = (
      (activityNames &&
        activityNames.map((item) => item.activity_type_shown)) ||
      []
    ).map((value) => ({ value, label: value }))

    const exerciseGroup = this.groupEntries(entries) || []
    const maxLength = Math.max(
      ...exerciseGroup.map((item) => (item.entries ? item.entries.length : 0))
    )
    this.setState({
      showRangeDate,
      exerciseGroup,
      originExerciseGroup: _.cloneDeep(exerciseGroup),
      maxLength: maxLength > 0 ? maxLength : 1,
      workoutNames,
      loading: false
    })
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props
    if (
      !nextProps.pageLoading &&
      nextProps.showRangeDate !== this.state.showRangeDate
    ) {
      const entries = (nextProps.overview.entries || []).filter(
        (item) => item.pillar === 'exercise'
      )
      const exerciseGroup = this.groupEntries(entries)
      const maxLength = Math.max(
        ...exerciseGroup.map((item) => item.entries.length)
      )
      this.setState({
        showRangeDate: nextProps.showRangeDate,
        exerciseGroup,
        originExerciseGroup: _.cloneDeep(exerciseGroup),
        maxLength: maxLength > 0 ? maxLength : 1
        // loading: false
      })
    }
  }

  renderExercise(days) {
    const { maxLength } = this.state
    return (
      <div className="body-data divider">
        <div className="header">
          <div className="column">Exercise</div>
          <div className="data-list">
            {days.map((item, index) => (
              <div key={index}>{item.format('ddd')}</div>
            ))}
          </div>
        </div>
        {maxLength > 0 &&
          new Array(maxLength).fill(null).map((_, index) => {
            return (
              <div key={index} className="workout-container">
                <div className="flex-row">
                  <div className="column">Activity #{index + 1}</div>
                  <div className="data-list">
                    {days.map((day, _index) => (
                      <div key={_index}>
                        {this.getExerciseInfo(day, index, 'type')}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row sub-tip">
                  <div className="column"> Duration #{index + 1}</div>
                  <div className="data-list">
                    {days.map((day, _index) => (
                      <div key={_index}>
                        {this.getExerciseInfo(day, index, 'duration')}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row sub-tip">
                  <div className="column"> Perceived Exertion #{index + 1}</div>
                  <div className="data-list">
                    {days.map((day, _index) => (
                      <div key={_index}>
                        {this.getExerciseInfo(day, index, 'rpe')}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        <div className="add-workout">
          <div
            className="column"
            onClick={() => this.setState({ maxLength: maxLength + 1 })}
          >
            Add another Activity
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      prevDate,
      nextDate,
      showRangeDate,
      pageLoading,
      person
    } = this.props
    const { loading, clearAll } = this.state
    const days = this.getDays()

    return (
      <Modal
        className={modalStyle}
        width={1000}
        title={`Input ${(person.profile && person.profile.nickname) ||
          person.first_name}'s core metrics`}
        visible={true}
        onCancel={() => {
          this.props.closeModal()
        }}
        footer={
          <div className={modalFooter}>
            <Button type="none" onClick={this.clearAll}>
              CLEAR ALL
            </Button>
            <div>
              <Button type="none" onClick={() => this.props.closeModal()}>
                CANCEL
              </Button>
              <Button
                type="primary"
                onClick={this.submit}
                disabled={this.submitValidate()}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        }
      >
        <header className="body-header">
          <DateRange
            prevDate={prevDate}
            nextDate={nextDate}
            showDate={showRangeDate || this.props.showDate}
          />
          <TodayTag />
        </header>
        {loading || pageLoading || clearAll ? (
          <PageLoading />
        ) : (
          <>
            {this.renderExercise(days)}
            <div className="body-data">
              <div className="header">
                <div className="column">Rejuvenation</div>
                <div className="data-list">
                  {days.map((item, index) => (
                    <div key={index}>{item.format('ddd')}</div>
                  ))}
                </div>
              </div>
              <div>
                <div className="column">Go to bed before 11pm</div>
                <div className="data-list">
                  {days.map((item, index) => (
                    <div key={index}>
                      {this.getCheckSleep(item)}
                      {/* <Checkbox checked={true} /> */}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="column">Hours of sleep</div>
                <div className="data-list">
                  {days.map((item, index) => (
                    <div key={index}>{this.getSleepTime(item)}</div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </Modal>
    )
  }
}

export default PillarEditModal

function ExerciseTypeItem(props) {
  const {
    selectedWorkout,
    workoutNames,
    disabled,
    entry,
    type,
    checkValue,
    updateWorkoutValue
  } = props
  const [value, setValue] = useState(selectedWorkout)

  useEffect(() => {
    setValue(selectedWorkout)
  }, [selectedWorkout])
  return (
    <Tooltip title={selectedWorkout}>
      <AutoComplete
        options={workoutNames}
        dropdownMatchSelectWidth={false}
        style={{ width: 69 }}
        disabled={disabled || !entry.manual}
        filterOption={(inputValue, option) => {
          return option.value.toLowerCase().includes(inputValue.toLowerCase())
        }}
        onSelect={(value) => {
          updateWorkoutValue(entry, type, value)
        }}
        // defaultValue={selectedWorkout}
        value={value}
      >
        <Input
          placeholder="Activity"
          className={
            checkValue > 0 && checkValue <= 2 && !selectedWorkout
              ? 'has-error'
              : ''
          }
          onChange={(e) => setValue(e.target.value)}
        />
      </AutoComplete>
    </Tooltip>
  )
}
