import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryLabel,
  VictoryScatter
} from 'victory'
import moment from 'moment'

const strokeDasharray = '6, 3'
const axis = {
  strokeDasharray,
  stroke: 'rgba(0, 0, 0, 0.1)'
}

const green = '#7BAD2D'
const blue = '#264382'
const gray = '#9CA5B8'

function Dot(props) {
  const { stroke } = props

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="6"
        cy="6"
        r="5"
        fill="white"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

class ProgressMultiLine extends Component {
  static propTypes = {
    period: PropTypes.string
  }

  state = {
    dataArr: [],
    dataX: []
  }

  xAxisFormat = x => {
    const { period } = this.props
    if (period === 'weekly') {
      return moment(x).format('MMM D')
    } else {
      let result = ''
      if (moment(x).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        result = '●'
      }
      if (moment(x).date() % 5 === 0) {
        result = moment(x).format('M/D')
      }

      return result
    }
  }

  initialMetaData = dataArr => {
    const { startDate, endDate } = this.props
    const dataX = []
    let count = 1
    dataX.push(moment(startDate))
    while (
      moment(startDate)
        .add(count, 'days')
        .isBefore(endDate)
    ) {
      dataX.push(moment(startDate).add(count, 'days'))
      count++
    }
    dataX.push(moment(endDate))

    const entries = [].concat(...dataArr.map(item => item.entries))
    const maxValue = Math.max(...entries.map(item => item.y), 100)
    let dataY = []
    if (maxValue) {
      dataY = [0, 50, 100]
    }
    if (maxValue > 100) {
      dataY.push(maxValue)
    }
    this.setState({ dataX, dataY })
  }

  componentDidMount() {
    const {
      goal: { children }
    } = this.props
    const dataArr = []
    for (const child of children) {
      const {
        micro_goal: { micro_goal },
        achieving: [achiev]
      } = child
      const entries = achiev.entries.map(item => ({
        x: moment(item.created_at),
        y: Number(item.input_auto)
      }))
      if (micro_goal.includes('Max')) {
        dataArr.push({
          entries,
          type: 'Max',
          color: gray
        })
      } else if (micro_goal.includes('Min')) {
        dataArr.push({
          entries,
          type: 'Min',
          color: green
        })
      } else {
        dataArr.push({
          entries,
          type: 'Avg',
          color: blue
        })
      }
    }
    this.setState({ dataArr })
    this.initialMetaData(dataArr)
  }

  render() {
    const { period } = this.props
    const { dataArr, dataX, dataY } = this.state
    const gridStroken = t => {
      if (period === 'monthly' && moment(t).date() % 5 === 0) {
        return 'rgba(0,0,0,.5)'
      }
      return 'rgba(0,0,0,.1)'
    }
    return (
      <VictoryChart>
        <VictoryAxis
          style={{
            axis,
            grid: {
              strokeDasharray,
              stroke: gridStroken
            },
            tickLabels: {
              fontFamily: 'Lato',
              fontSize: '18px',
              fill: x => {
                if (
                  moment(x).format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                ) {
                  return '#3561C0'
                }
                return '#B0BAC9'
              },
              padding: x => {
                if (
                  period === 'monthly' &&
                  moment(x).format('ddd') !== 'Sun' &&
                  moment(x).format('YYYY-MM-DD') ===
                    moment().format('YYYY-MM-DD') &&
                  moment(x).date() % 5 !== 0
                ) {
                  return 0
                } else {
                  return 10
                }
              }
            }
          }}
          tickValues={dataX}
          tickLabelComponent={
            <VictoryLabel
              text={datum => {
                let result = this.xAxisFormat(datum)
                if (period === 'weekly') {
                  result = result.split(' ').join('\n')
                }
                return result
              }}
            />
          }
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: {
              strokeDasharray,
              stroke: 'rgba(0,0,0,.5)'
            },
            grid: {
              strokeDasharray,
              stroke: 'rgba(0,0,0,.1)'
            },
            tickLabels: {
              fontFamily: 'Lato',
              fontSize: '18px',
              fill: v => {
                if (v < 2e-9) {
                  return 'transparent'
                }
                return '#B0BAC9'
              }
            }
          }}
          tickValues={dataY}
        />
        {dataArr.map((data, index) => (
          <VictoryLine
            key={index}
            style={{
              data: {
                stroke: data.color
              },
              parent: { border: '1px solid #ccc' }
            }}
            data={data.entries}
          />
        ))}

        {dataArr.map((data, index) => {
          if (data.entries.length === 1) {
            return (
              <VictoryScatter
                key={index}
                data={data.entries}
                dataComponent={<Dot stroke={data.color} />}
              />
            )
          }
          return null
        })}
      </VictoryChart>
    )
  }
}

export default ProgressMultiLine
