import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { colors } from '../../../utils/constant'

import { Select } from 'antd'
const Option = Select.Option

class SelectColor extends Component {
  static propTypes = {}

  state = {
    value: 'yellow'
  }

  triggerChange = value => {
    this.setState({ value })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      value: value || 'yellow'
    })
  }

  render() {
    const { value } = this.state
    return (
      <div className="select-color-container" datacolor={value}>
        <Select onChange={this.triggerChange} value={this.state.value}>
          {colors.map((item, index) => (
            <Option
              key={index}
              value={item.key}
              style={{ background: item.value }}
            >
              {item.key}
            </Option>
          ))}
        </Select>
      </div>
    )
  }
}

export default SelectColor
