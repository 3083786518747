import React from 'react'
import { loadUser } from '../../utils/storage'
import SupportTeamContainer from '../../components/supportTeam/container'

export default function SupportTeamView(props) {
  const person = loadUser()
  return (
    <div style={{ paddingRight: 50 }}>
      <h1 className="title" style={{ paddingTop: 40 }}>
        Support Team
      </h1>
      {person && (
        <SupportTeamContainer
          person={person}
          chart={() => props.history.push('/app/message')}
        />
      )}
    </div>
  )
}
