import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import MarkdownIt from 'markdown-it'
// import MdEditor from 'react-markdown-editor-lite'
import 'react-markdown-editor-lite/lib/index.css'

// const mdParser = new MarkdownIt({
//   html: true,
//   linkify: true,
//   typographer: true
// })

class Markdown extends Component {
  static propTypes = {}

  handleEditorChange = ({ html, text }) => {
    // console.log('handleEditorChange', html, text)
  }

  componentDidMount() {
    const md = new MarkdownIt()
    // const result =
    md.render('# markdown-it rulezz!')
  }

  render() {
    return <div>1</div>
    // (
    //   <MdEditor
    //     value=""
    //     renderHTML={text => mdParser.render(text)}
    //     onChange={this.handleEditorChange}
    //   />
    // )
  }
}

export default Markdown
