import React, { useState } from 'react'
import styled from 'styled-components'
import { LoadingOutlined, FileImageOutlined } from '@ant-design/icons'
import { Upload, Modal, Button } from 'antd'
import { getToken } from '../../../utils/storage'
import { baseURL } from '../../../utils/constant'

const Container = styled.div`
  position: relative;
  border: 2px dashed #264382;
  box-sizing: border-box;
  border-radius: 8px;
  width: 212px;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #264382;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    img {
      opacity: 0.5;
    }
    .hover {
      display: block;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .hover {
    display: none;
    position: absolute;
    button {
      background: #ffffff !important;
    }
  }
`
export default function UploadImage(props) {
  const { containerStyle, imageStyle, replaceImage } = props
  const [uploading, setUploading] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [url, setUrl] = useState(props.url || props.placeholder)
  const action = `${baseURL}/files?person_id=${props.personId}`
  const onChange = ({ event, file }) => {
    if (file.status === 'uploading') {
      setUploading(false)
      return
    }
    if (file.status === 'done') {
      if (file.response) {
        setUrl(file.response.url)
        props.uploadSync(file.response.url)
      }
      setUploading(false)
    }
  }

  return (
    <>
      {url || replaceImage ? (
        <Container
          style={{ borderWidth: url ? 0 : 2, ...(containerStyle || {}) }}
        >
          <img
            src={url || replaceImage}
            style={imageStyle || {}}
            onClick={() => setPreviewVisible(true)}
            alt=""
          />
          {!props.onlyView && (
            <div className="hover" style={url ? null : { display: 'block' }}>
              <Upload
                accept="image/*"
                name="file"
                action={action}
                showUploadList={false}
                onChange={onChange}
                headers={{
                  Authorization: `Bearer ${getToken()}`
                }}
              >
                {uploading ? (
                  <LoadingOutlined />
                ) : (
                  <Button type="primary" ghost>
                    CHANGE IMAGE
                  </Button>
                )}
              </Upload>
            </div>
          )}
        </Container>
      ) : props.onlyView ? (
        <Container style={{ fontSize: 50, cursor: 'not-allowed' }}>
          <FileImageOutlined />
        </Container>
      ) : (
        <Upload
          accept="image/*"
          name="file"
          action={action}
          showUploadList={false}
          onChange={onChange}
          headers={{
            Authorization: `Bearer ${getToken()}`
          }}
        >
          <Container>
            {uploading ? (
              <LoadingOutlined />
            ) : (
              <span className="add-btn">ADD IMAGE</span>
            )}
          </Container>
        </Upload>
      )}

      <Modal
        open={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img style={{ width: '100%' }} src={url || replaceImage} alt="" />
      </Modal>
    </>
  )
}
