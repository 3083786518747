import styled from 'styled-components'

export const SectionStyle = styled.div`
  padding: 25px;
  padding-top: 5px;
  border-radius: 0 12px 12px;
  background: #ffffff;
  letter-spacing: 0.3px;

  .ant-tabs {
    padding-top: 0;

    .ant-tabs-top-bar {
      margin: 0;
      border: none;

      .ant-tabs-ink-bar {
        display: none !important;
      }

      .ant-tabs-nav {
        .ant-tabs-tab {
          min-width: auto;
          margin: 0;
          margin-bottom: 10px;
          height: auto;
          line-height: 23px;
          margin-right: 32px;
          padding: 2px 0;
          text-align: left;
          background: transparent;
          color: #a2a7ac;
          font-family: 'Gilroy-Bold';
          font-size: 14px;
          letter-spacing: 0.8px;

          &.ant-tabs-tab-active {
            background: transparent;
            color: #264382;
            border-bottom: 2px solid #264382;
          }
        }
      }
    }
  }

  strong {
    font-family: 'Gilroy-bold';
  }

  .ant-divider-horizontal.ant-divider-with-text-left {
    &:before {
      width: 0;
    }

    .ant-divider-inner-text {
      font-size: 24px;
      line-height: 33px;
      color: #29303e;
      padding: 0;
    }
  }

  .sub-title {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #38435e;
    font-weight: bold;
    margin-bottom: 12px;
  }

  .container {
    margin: 5px 0;
    color: #38435e;
    font-size: 14px;
  }

  .question,
  .answer {
    padding: 15px 20px;
  }

  .question {
    background: #f9fafb;
    display: flex;
    line-height: 150%;
    align-items: top;

    .note_title {
      font-size: 12px;
      color: #98a9bc;
    }

    .required {
      color: red;
      font-size: 26px;
      margin-right: 5px;
      height: 8px;
      padding-top: 6px;
      margin-left: -18px;
    }

    &:before {
      content: attr(data-index);
      width: 24px;
      height: 24px;
      min-width: 24px;
      text-align: center;
      line-height: 24px;
      display: inline-block;
      margin-right: 23px;
      background: #dee4ea;
      color: #98a9bc;
      border-radius: 100%;
    }

    &:not(&[data-index]):before {
      background: transparent;
    }

    span {
      max-width: calc(100% - 70px);
    }

    & > p {
      min-height: 20px;
    }
  }

  .answer {
    border: 1px solid #eaedf3;
    box-sizing: border-box;

    .sub-choice {
      display: block;
      margin-left: 2em;
    }

    .anticon {
      display: none;
      float: right;
      font-size: 32px;
      cursor: pointer;
    }

    &:hover {
      color: rgba(56, 67, 94, 0.5);

      .anticon {
        display: block;
        color: rgb(56, 67, 94);
      }
    }
  }
`
