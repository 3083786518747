import React, { Component } from 'react'
import Notes from '../../../components/notes'
import {
  getClientList,
  getClientDetail,
  getNotes
} from '../../../models/breadcrumb.model'

class NotesPage extends Component {
  static propTypes = {}

  state = {}

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    setClientPageTitle('Notes')
    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getNotes(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { person } = this.state
    return (
      (person && (
        <div style={{ marginTop: -122 }}>
          <Notes person={person} />
        </div>
      )) || <></>
    )
  }
}

export default NotesPage
