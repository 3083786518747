import React, { useEffect, useState } from 'react'
import Categories from '../categories'
import { connect } from 'react-redux'
import { setSubMenu } from '../../../redux/slideMenu/action'
import { getDbFields } from '../../../components/business/dataset/common'
import {
  getCategories,
  createCategory,
  editCategory,
  createMetric,
  editMetric,
  delCategory,
  delMetric
} from '../../../api/metric'
import { getSlideMenu } from '../../../api/dataset'

function Metrics(props) {
  const [dbFields, setDbFields] = useState(null)
  const {
    match: { params },
    slideMenu: { groups },
    slideMenu,
    updateSubMenu
  } = props

  const path = params.path || 'vitals'
  async function initial() {
    const result = await getSlideMenu()
    updateSubMenu(result.groups)
  }
  useEffect(() => {
    initial()
  }, [])
  useEffect(() => {
    setDbFields(null)
    setDbFields(getDbFields(path))
  }, [path])

  return (
    <div>
      {groups && dbFields && (
        <Categories
          path={path}
          type={dbFields.category_type}
          biomarkersFiled={dbFields.metricsField}
          history={props.history}
          {...{
            categoryGroups: groups[dbFields.groupType || dbFields.type] || {
              categories: []
            },
            group: dbFields.groupType || dbFields.type,
            slideMenu,
            updateSubMenu,
            getCategories,
            createCategory,
            editCategory,
            createMetric,
            editMetric,
            delCategory,
            delMetric
          }}
        />
      )}
    </div>
  )
}

export default connect(
  ({ slideMenu }) => ({ slideMenu }),
  (dispatch) => ({
    updateSubMenu: (subMenu) => dispatch(setSubMenu('groups', subMenu))
  })
)(Metrics)
