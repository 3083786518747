import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Alert, Divider } from 'antd'
import { connect } from 'react-redux'
// import { setEditingTextNumber } from '../../../redux/summaryEmail/action'
import {
  getClientList,
  getClientDetail,
  getWeeklySummary,
  getSummaryEmail
} from '../../../models/breadcrumb.model'
import PreviewEmail from '../../../components/summaryEmail/weeklySummary' //'../../../components/summaryEmail/v3/previewEmail'
import { getNotificationStatus } from '../../../api/people'
import { getRole } from '../../../utils/common'

class SummaryEmailPreview extends Component {
  static propTypes = {}
  state = { allow_emails: true }

  initial = async () => {
    const { getPerson, setClientPageTitle, setClientBreadcrumb } = this.props
    let { person } = this.state
    if (!person) {
      person = await getPerson()
      this.setState({ person })
    }
    // get notification status
    const { allow_emails } = await getNotificationStatus(person.id)
    this.setState({ allow_emails })

    const loginRole = getRole()

    setClientPageTitle(
      loginRole === 'Service_Provider' ? '' : 'Summary Email Detail'
    )

    setClientBreadcrumb &&
      setClientBreadcrumb([
        getClientList(),
        getClientDetail(person),
        getSummaryEmail(person),
        getWeeklySummary(person, true)
      ])
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const {
      match: { params, path },
      history
    } = this.props
    const { person, allow_emails } = this.state
    const loginRole = getRole()
    return person ? (
      <>
        {loginRole === 'Expert' && <Divider style={{ marginTop: 0 }} />}
        {!allow_emails && (
          <>
            <Alert
              message={`Email notifications have been paused for ${(person.profile &&
                person.profile.nickname) ||
                person.first_name} ${person.last_name}`}
              description="This client has requested email notifications to be turned OFF"
              type="warning"
              showIcon
            />
            <div style={{ marginBottom: 24 }} />
          </>
        )}
        <PreviewEmail
          person={person}
          allowEmailNotification={allow_emails}
          linkToDraft={(id) => history.replace(path.replace('preview/:id', id))}
          id={params.id}
          saveSync={() => history.replace(path.replace('preview/:id', ''))}
        />
      </>
    ) : (
      <></>
    )
  }
}

// export default SummaryEmailPreview

export default connect(
  ({ summaryEmail }) => ({ summaryEmail }),
  (dispatch) => ({
    // setEditingTextNumber: (number) => dispatch(setEditingTextNumber(number))
  })
)(SummaryEmailPreview)
