import React from 'react'
// import moment from 'moment'

import { ProgramPercentIcon } from '../../icons/program'
// import { CalendarIcon } from '../../icons/program' //DumbbellIcon
// import { Pluralize } from '../../../utils/common'
import style from '../style/programItem.module.sass'

export default function ProgramItem(props) {
  const { from, to, name, pillarItemCount } = props
  const { actual, target } = pillarItemCount || {}
  let program = { from, to, name }
  if (props.position !== 'clientView' && props.draft_data) {
    program = Object.assign(program, program.draft_data)
  }

  // const itemLength =
  //   props.action_item_count ||
  //   (props.items ? props.items.filter((item) => item.status === 1).length : 0)

  return (
    <div
      onClick={props.onClickHandle}
      className={`${style.container} ${props.extraElem ? '' : 'noItems'} ${
        props.status === 1 ? 'active' : ''
      }`}
    >
      <div
        className={`${style.contentBox} ${
          props.status === 1 ? style.activeBox : ''
        }`}
      >
        <div
          className={`${style.title} ${
            props.status === 1 ? style.activeTitle : ''
          }`}
        >
          {program.name}
        </div>
        {/* <div>
          <CalendarIcon />
          {moment(program.from).format('MMM D')} -{' '}
          {moment(program.to).format('MMM D, YYYY')}
        </div> */}
        {/* <div>
          <DumbbellIcon />
          {itemLength} program action{' '}
          {Pluralize('item', itemLength === 0 ? 1 : itemLength)} per week
        </div> */}
        {props.position !== 'clientView' && actual && target && (
          <div>
            <ProgramPercentIcon
              style={{ fontSize: 18, verticalAlign: 'middle' }}
            />
            Program: {target.join('-')} • Actual: {actual.join('-')}
          </div>
        )}
      </div>

      {props.extraElem}
    </div>
  )
}
