import { setLoginAccount } from '../../redux/account/action'
import { clearStorage } from '../../utils/storage'
import { logOut } from '../../api/people'

export default (dispatch) => ({
  setLoginAccount: (account) => dispatch(setLoginAccount(account)),
  logOut: (history) => {
    clearStorage()
    logOut()
    history.push('/sign-in')
  }
})
