import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  VictoryChart,
  VictoryPolarAxis,
  VictoryLabel,
  VictoryLine,
  VictoryArea,
  VictoryScatter,
  VictoryVoronoiContainer
} from 'victory'

const AXIS_DATAS = [
  {
    data: [{ x: 0, y: 20 }, { x: 1, y: 20 }, { x: 2, y: 20 }],
    borderColor: 'rgba(76,96,114,.6)'
  },
  {
    data: [{ x: 0, y: 50 }, { x: 1, y: 50 }, { x: 2, y: 50 }],
    borderColor: 'rgba(76,96,114,.6)'
  },
  {
    data: [{ x: 0, y: 100 }, { x: 1, y: 100 }, { x: 2, y: 100 }],
    borderColor: 'rgb(76,96,114)'
  }
]

const OFFSET_SORCE_Y = [-5, -5, 10]

const LABEL_TEXT_ANCHOR = ['start', 'end', 'middle']

const OFFSET_NAME_Y = [13, 13, 28]

const GraphContainer = styled.div`
  margin-top: -60px;
  margin-bottom: 40px;
`

function Dot(props) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x - 5}
      y={(props.y && props.y - 5) || 0}
    >
      <circle
        cx="5"
        cy="5"
        r="4"
        fill="white"
        stroke={props.stroke}
        strokeWidth="2"
      />
    </svg>
  )
}

class TriangleGraph extends Component {
  static propTypes = {
    compareData: PropTypes.array,
    list: PropTypes.array,
    mobile: PropTypes.bool
  }

  render() {
    const { exercise, nutrition, rejuvenation } = this.props
    const list = [
      {
        name: 'Nutrition',
        score: Math.round(nutrition)
      },
      {
        name: 'Exercise',
        score: Math.round(exercise)
      },
      {
        name: 'Rejuvenation',
        score: Math.round(rejuvenation)
      }
    ]
    const scoreArr = list.map((item) => item.score)

    return (
      <GraphContainer>
        <VictoryChart
          polar
          domain={{ y: [0, 100] }}
          startAngle={30}
          endAngle={270}
          containerComponent={
            <VictoryVoronoiContainer
              style={{
                touchAction: 'auto',
                height: '100%'
              }}
            />
          }
        >
          <VictoryArea
            data={[100, 100, 100]}
            style={{ data: { fill: 'rgba(186, 190, 199, 0.2)' } }}
          />
          <VictoryPolarAxis
            tickValues={[0, 1, 2]}
            labelPlacement="vertical"
            tickFormat={(t) =>
              typeof this.props[list[t].name.toLowerCase()] === 'number'
                ? list[t].score
                : '--'
            }
            labels
            tickLabelComponent={
              <VictoryLabel
                dy={(y) => OFFSET_SORCE_Y[y || 0]}
                // dx={(x) => 3}
                textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
              />
            }
            style={{
              axis: { stroke: 'transparent' },
              grid: {
                stroke: 'rgba(76,96,114,.6)',
                strokeDasharray: '6, 3',
                strokeWidth: 2
              },
              tickLabels: {
                fontSize: 20,
                fontFamily: 'Gilroy-Bold',
                fill: '#4C6072'
              }
            }}
          />
          <VictoryPolarAxis
            tickValues={[0, 1, 2]}
            tickFormat={(t) => list[t].name.toUpperCase()}
            tickLabelComponent={
              <VictoryLabel
                dy={(y) => OFFSET_NAME_Y[y || 0]}
                textAnchor={(t) => LABEL_TEXT_ANCHOR[t]}
              />
            }
            labelPlacement="vertical"
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: 'transparent' },
              tickLabels: {
                fontSize: 8,
                fill: 'rgba(76, 96, 114, 0.6)'
              }
            }}
          />
          {AXIS_DATAS.map((item, index) => (
            <VictoryLine
              key={index}
              data={item.data}
              style={{
                data: {
                  stroke: item.borderColor,
                  strokeWidth: 1
                }
              }}
            />
          ))}

          <VictoryLine
            data={scoreArr}
            style={{
              data: {
                stroke: '#7FB800',
                strokeWidth: 3
              }
            }}
          />

          <VictoryArea
            data={scoreArr}
            style={{ data: { fill: 'rgba(127, 184, 0, 0.2)' } }}
          />

          <VictoryScatter
            data={scoreArr}
            dataComponent={<Dot stroke="#7FB800" />}
          />
        </VictoryChart>
      </GraphContainer>
    )
  }
}

export default TriangleGraph
