import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from 'antd'
import WorkoutChart from './comp/workoutChart'
import ACSMHeartTarget from './comp/acsmHeartTarget'
import AcsmBreakDownContainer from './comp/acsmBreakDownContainer'
import Information from '../UI/extraInformation'
import SubTip from './comp/subTip'
import { SvgText } from '../icons/weeklySummary'
import { calculateElementWidth } from './common'
import HeartRateBarsChart from '../client/comp/heartRateBars'
// import Modality from '../client/comp/modality'
import TrainingLoad from '../client/comp/trainingLoad'
import { getSum } from '../../utils/common'
import { fontFamily } from '../../utils/constant'

import {
  exercise_workout,
  exercise_target
  // exercise_session
} from './directions.json'

import { nutritionStyle } from './styles/nutrition.sass'

import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const normal = '#70808E',
  primary = '#383E48',
  gray = '#b7bfc6'

class Exercise extends Component {
  static propTypes = {}

  getGoals = (startDate, endDate) => {
    const {
      data: {
        overview,
        exercise: { extra }
      }
    } = this.props
    const { summary } = overview
    const aerobicDuration = summary.Aerobic.duration || 0
    const resistanceDuration = summary.Resistance.duration || 0
    const totelMinutes =
      Math.round(aerobicDuration / 60) +
      Math.round(resistanceDuration / 60) +
      Math.round((summary.Activity.duration || 0) / 60) +
      Math.round((summary['Movement Quality'].duration || 0) / 60) //type
    // const totalExercise = summary.Aerobic.times + summary.Resistance.times

    const { heart_rate } = overview
    const entries = (heart_rate && heart_rate.achieving) || []
    let totalHeartRate = [
      { name: 'Z1', color: '#C9CDD2' },
      { name: 'Z2', color: '#F7AD69' },
      { name: 'Z3', color: '#FB9043' },
      { name: 'Z4', color: '#FC7558' },
      { name: 'Z5', color: '#FF501A' }
    ]
    totalHeartRate = totalHeartRate.map((item, index) => {
      const value = getSum(
        entries.map((item) => Math.round(item.heart_rate_zone[index] / 60))
      )
      return {
        ...item,
        value: value && value + 'm'
      }
    })

    totalHeartRate.shift()
    totalHeartRate = totalHeartRate.reverse()

    const trainingLoadEntry = extra.training_load.entries.map(
      (item) => item.training_load
    )
    const totalTrainingLoad =
      trainingLoadEntry.length > 0
        ? getSum(extra.training_load.entries.map((item) => item.training_load))
        : '--'

    const xAxisStyle = {
      axis: { stroke: 'transparent' },
      tickLabels: {
        fontFamily: `"Gilroy",${fontFamily}`,
        fontSize: '10px',
        fill: '#70808E'
      }
    }

    return [
      // {
      //   id: 1,
      //   description: 'Sessions overview',
      //   total: totalExercise,
      //   previous_weeks_average: summary.exercise.previous_weeks_times,
      //   unit: 'sessions',
      //   // formatChange:
      //   //   isNumber(totalExercise) &&
      //   //   isNumber(summary.exercise.previous_weeks_times) &&
      //   //   totalExercise - summary.exercise.previous_weeks_times,
      //   chart: (
      //     <Modality
      //       extraClass="email"
      //       barWidth={16}
      //       paddingLeft={20}
      //       xAxisFormat={(x) =>
      //         moment(x)
      //           .format('ddd')
      //           .toUpperCase()
      //       }
      //       xAxisStyle={xAxisStyle}
      //       {...{
      //         startDate,
      //         endDate,
      //         totalHeartRate,
      //         overview,
      //         pillar: 'Exercise'
      //       }}
      //     />
      //   )
      // },
      {
        id: 2,
        description: 'Activity overview',
        total: totelMinutes,
        previous_weeks_average: Math.round(
          (summary.exercise.previous_weeks_duration || 0) / 60
        ),
        unit: 'mins',
        // formatChange:
        //   isNumber(totelMinutes) &&
        //   isNumber(summary.exercise.previous_weeks_times) &&
        //   totelMinutes -
        //     Math.round(summary.exercise.previous_weeks_duration / 60),
        chart: (
          <HeartRateBarsChart
            extraClass="email"
            barWidth={16}
            paddingLeft={20}
            xAxisFormat={(x) =>
              moment(x)
                .format('ddd')
                .toUpperCase()
            }
            xAxisStyle={xAxisStyle}
            {...{
              startDate,
              endDate,
              totalHeartRate,
              overview,
              pillar: 'Exercise'
            }}
          />
        )
      },
      {
        id: 3,
        description: 'Training Load (RPES)',
        total: totalTrainingLoad,
        previous_weeks_average: extra.previous_weeks_tl_average, //'--',
        // formatChange:
        //   isNumber(totalTrainingLoad) &&
        //   isNumber(extra.previous_weeks_tl_average) &&
        //   totalTrainingLoad - extra.previous_weeks_tl_average,
        chart: (
          <div style={{ padding: '0 10px' }}>
            <TrainingLoad
              barWidth={16}
              paddingLeft={50}
              paddingRight={50}
              xAxisFormat={(x) =>
                moment(x)
                  .format('ddd')
                  .toUpperCase()
              }
              xAxisStyle={xAxisStyle}
              {...{
                startDate,
                endDate,
                trainingLoad: extra.training_load,
                extraAxisText: 'AVG'
              }}
            />
          </div>
        )
      }
    ]
  }

  renderGoals() {
    const {
      startDate,
      endDate,
      loginRole,
      originInformation,
      submitData: { exercise, deleted_section },
      oprExercise,
      templates,
      updateEditingCount
    } = this.props

    let goals = this.getGoals(startDate, endDate)

    if (exercise.activity_goals) {
      goals.map((item) => {
        const target = exercise.activity_goals.find(
          (goal) => goal.id === item.id
        )
        if (target) {
          target.description = item.description
          return Object.assign(item, target)
        }
        return item
      })
    }

    if (exercise.deleted_activity_goal) {
      goals = goals.filter(
        (item) => !exercise.deleted_activity_goal.find((id) => id === item.id)
      )
    }

    return (
      <div className={nutritionStyle} style={{ paddingTop: 0 }}>
        {goals.length > 0 && !deleted_section.exercise_workout && <Divider />}
        {goals.map((item, index) => (
          <div className="goal-item" key={index}>
            <div
              className="delete"
              onClick={() =>
                oprExercise('delete', {
                  id: item.id,
                  position: `Exercise - ${item.description}`
                })
              }
            >
              <img className="delete-icon" src={DelIcon} alt="del" />
            </div>

            <div className="name">{item.description}</div>
            <Information
              updateEditingCount={updateEditingCount}
              status={originInformation && originInformation.status}
              title="Custom Text"
              showDone={loginRole !== 'User'}
              initialValue={item.custom_text}
              templates={templates}
              doneStatus={item.custom_text_status}
              updateCommend={(value, status) =>
                oprExercise('update', {
                  id: item.id,
                  value,
                  status,
                  field: 'custom_text',
                  position: `Exercise - ${item.description}`
                })
              }
            />

            <div
              className="data-content ref-container"
              ref={`exercise.chart_url.${item.id}`}
            >
              <div>
                <div className="days">
                  <SvgText
                    text={
                      item.total ||
                      (item.id === 3 && typeof item.total === 'number')
                        ? item.total
                        : '--'
                    }
                    height={33}
                    fontSize={30}
                    width={
                      calculateElementWidth(
                        'span',
                        item.total ||
                          (item.id === 3 && typeof item.total === 'number')
                          ? item.total
                          : '--',
                        '30px'
                      ).width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                  {item.total > 0 &&
                    (item.formatChange || item.formatChange === 0) && (
                      <SvgText
                        text={`${item.formatChange > 0 ? '+' : ''}${
                          item.formatChange
                        }`}
                        fill={normal}
                        fontSize={12}
                        height={18}
                        fontFamily="Gilroy"
                        width={
                          calculateElementWidth(
                            'span',
                            `${item.formatChange > 0 ? '+' : ''}${
                              item.formatChange
                            }`,
                            '12px'
                          ).width + 5
                        }
                        textAttr={{
                          x: 0,
                          textAnchor: 'start',
                          dominantBaseline: 'mathematical',
                          fontWeight: 'normal'
                        }}
                      />
                    )}
                  <SubTip text="7 DAY TOTAL" unit={item.unit} />
                </div>

                <div className="weeks">
                  <SvgText
                    text={
                      item.previous_weeks_average
                        ? item.previous_weeks_average
                        : '--'
                    }
                    fill={item.previous_weeks_average ? gray : primary}
                    height={33}
                    fontSize={30}
                    width={
                      calculateElementWidth(
                        'span',
                        item.previous_weeks_average
                          ? item.previous_weeks_average
                          : '--',
                        '30px'
                      ).width + 5
                    }
                    textAttr={{
                      dominantBaseline: 'mathematical',
                      textAnchor: 'start',
                      x: 0
                    }}
                  />
                  <SubTip text="3 WEEK AVG" unit={item.unit} />
                </div>
              </div>
              <div>
                {/* style={{ width: '50%'}} */}
                {item.chart}
              </div>
            </div>
            {index !== goals.length - 1 && <Divider />}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      startDate,
      endDate,
      loginRole,
      data,
      setSubmitData,
      originInformation,
      deleteSections,
      templates,
      updateEditingCount,
      submitData: { exercise, deleted_section }
    } = this.props

    const isEditable =
      !originInformation ||
      (originInformation.status !== 'Sent' &&
        originInformation.status !== 'Opened')

    return (
      <>
        <div className="sub-section">
          {(!deleted_section.exercise_target && (
            <>
              {isEditable && (
                <div
                  className="delete"
                  onClick={() => deleteSections('exercise_target')}
                >
                  <img className="delete-icon" src={DelIcon} alt="del" />
                </div>
              )}
              <h4 className="sub-title">Apeiron Life Training Target</h4>
              <Information
                direction={exercise_target}
                updateEditingCount={updateEditingCount}
                status={originInformation && originInformation.status}
                title="Overview"
                templates={templates}
                showDone={loginRole !== 'User'}
                initialValue={exercise.heart_recommendation}
                doneStatus={exercise.heart_recommendation_status}
                updateCommend={(value, status) =>
                  setSubmitData({
                    part: 'exercise',
                    field: 'heart_recommendation',
                    value,
                    status,
                    position:
                      'Recommendation of Exercise -  Apeiron Life Training Target'
                  })
                }
              />
              <div ref="exercise.heart_chart_url" className="ref-container">
                {!deleted_section.exercise_training_target && (
                  <div className="deletable-part">
                    {isEditable && (
                      <div
                        className="deletable-part-opr"
                        onClick={() =>
                          deleteSections('exercise_training_target')
                        }
                      >
                        <img className="delete-icon" src={DelIcon} alt="del" />
                      </div>
                    )}
                    <div className="big-chart">
                      <ACSMHeartTarget
                        {...{
                          exercise: data && data.exercise,
                          previousWeek: false,
                          startDate,
                          endDate,
                          containerStyle: { margin: 0 }
                        }}
                      />
                      <ACSMHeartTarget
                        {...{
                          exercise: data && data.exercise,
                          previousWeek: true,
                          containerStyle: { margin: 0 }
                        }}
                      />
                    </div>
                  </div>
                )}

                {!deleted_section.exercise_target_breakdown && (
                  <div className="deletable-part">
                    {isEditable && (
                      <div
                        className="deletable-part-opr"
                        onClick={() =>
                          deleteSections('exercise_target_breakdown')
                        }
                      >
                        <img className="delete-icon" src={DelIcon} alt="del" />
                      </div>
                    )}
                    <AcsmBreakDownContainer
                      {...{ exercise: data && data.exercise }}
                    />
                  </div>
                )}
              </div>
              <div className="extra-information">
                <h5>What is my Aerobic Training Target?</h5>
                <p>
                  For optimal health benefits, we recommend you aim for at least
                  150 minutes per week of moderate-intensity activity (zone 2 -
                  zone 3; 60-75% max heart rate). This includes running,
                  cycling, swimming, and hiking.
                </p>
              </div>
            </>
          )) || <div style={{ height: 30 }}> </div>}
          <Information
            updateEditingCount={updateEditingCount}
            status={originInformation && originInformation.status}
            title="Custom Text"
            templates={templates}
            showDone={loginRole !== 'User'}
            initialValue={exercise.heart_custom_text}
            doneStatus={exercise.heart_custom_text_status}
            updateCommend={(value, status) =>
              setSubmitData({
                part: 'exercise',
                field: 'heart_custom_text',
                value,
                status,
                position:
                  'Custom Text of Exercise - Apeiron Life Training Target'
              })
            }
          />
          <Divider />
        </div>
        {!deleted_section.exercise_workout && (
          <div className="sub-section">
            {isEditable && (
              <div
                className="delete"
                onClick={() => deleteSections('exercise_workout')}
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}

            <h4 className="sub-title">Workout</h4>
            <Information
              direction={exercise_workout}
              updateEditingCount={updateEditingCount}
              title="Overview"
              templates={templates}
              showDone={loginRole !== 'User'}
              status={originInformation && originInformation.status}
              initialValue={exercise.workout_recommendation}
              doneStatus={exercise.workout_recommendation_status}
              updateCommend={(value, status) =>
                setSubmitData({
                  part: 'exercise',
                  field: 'workout_recommendation',
                  value,
                  status,
                  position: 'Recommendation of Exercise - Workout'
                })
              }
            />
            <div
              className="big-chart ref-container"
              ref="exercise.workout_chart_url"
            >
              <WorkoutChart
                {...{ startDate, endDate, exercise: data ? data.exercise : {} }}
              />
            </div>
            <Information
              updateEditingCount={updateEditingCount}
              status={originInformation && originInformation.status}
              title="Custom Text"
              templates={templates}
              showDone={loginRole !== 'User'}
              initialValue={exercise.workout_custom_text}
              doneStatus={exercise.workout_custom_text_status}
              updateCommend={(value, status) =>
                setSubmitData({
                  part: 'exercise',
                  field: 'workout_custom_text',
                  value,
                  status,
                  position: 'Custom Text of Exercise - Workout'
                })
              }
            />
          </div>
        )}

        {this.renderGoals()}
      </>
    )
  }
}

export default Exercise
