import React from 'react'
import EmailReportContainer from '../../components/commonTools/emailReport'

function EmailReport(props) {
  return (
    <>
      <div className="page-title">Summary Email Report</div>
      <EmailReportContainer turnTo={(path) => props.history.push(path)} />
    </>
  )
}

export default EmailReport
