import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getAxisX } from '../common'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { VictoryChart, VictoryBar, VictoryAxis } from 'victory'
import { getSum } from '../../../utils/common'

class HRZChart extends Component {
  static propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    entries: PropTypes.array
  }

  state = {}

  getMaxInputByBarData = (barDatas) => {
    const arr = barDatas.map((bar) => bar.dataArr.map((item) => item.y))
    const nunmberArr = [].concat.call(...arr)
    return Math.max(...nunmberArr)
  }

  dataHandle(startDate, endDate) {
    const entries = new Array(...this.props.entries)
    const dataX = startDate && endDate && getAxisX(startDate, endDate)
    function getdataArr(zoneIndex) {
      let dataArr = dataX.map((item) => {
        const data = (
          entries.find(
            (entry) => entry.summary_date === item.format('YYYY-MM-DD')
          ) || {}
        ).heart_rate_zones

        const y = data ? data[zoneIndex] : 0
        return {
          x: item,
          y: y
        }
      })
      return dataArr
    }

    const barDatas = [
      // {
      //   dataArr: getdataArr(0),
      //   barColor: '#F5CA5C',
      //   name: 'Z1'
      // },
      {
        dataArr: getdataArr(1),
        barColor: '#F7AD69',
        name: 'Z2'
      },
      {
        dataArr: getdataArr(2),
        barColor: '#FB9043',
        name: 'Z3'
      },
      {
        dataArr: getdataArr(3),
        barColor: '#FC7558',
        name: 'Z4'
      },
      {
        dataArr: getdataArr(4),
        barColor: '#FF501A',
        name: 'Z5'
      }
    ]

    const maxTotal = Math.max(
      ...entries.map(({ heart_rate_zones }) => {
        heart_rate_zones = new Array(heart_rate_zones || [])
        return getSum(
          heart_rate_zones.shift() !== undefined && heart_rate_zones
        )
      })
    )

    const setMinValue = maxTotal * 0.1
    const maxInput = this.getMaxInputByBarData(barDatas)

    function dataArrHandle(index) {
      barDatas[index].dataArr = barDatas[index].dataArr.map((item, idx) => {
        const data = barDatas[index - 1]
          ? barDatas[index - 1].dataArr[idx]
          : { y: 0 }
        const value = item.y //|| null
        return {
          value,
          x: item.x,
          y:
            value === 0
              ? 0
              : data.y + (value < setMinValue ? setMinValue : value || null),
          topRadius: 0,
          bottomRadius: 0
        }
      })
    }
    for (let i = 0; i < barDatas.length; i++) {
      dataArrHandle(i)
    }
    return {
      dataX,
      barDatas: barDatas.reverse(),
      maxInput,
      maxTotal,
      gridColor: '#FF501A'
    }
  }

  renderPlaceholderBar(data) {
    const { barDatas, dataX, maxInput, maxTotal } = data

    const dataArr = dataX.map((item) => {
      let arr = barDatas.map((bar) => {
        const result = bar.dataArr.find(
          (data) => item.format('YYYY_MM_DD') === data.x.format('YYYY_MM_DD')
        )
        return result && result.y
      })
      const max = Math.max(...arr)
      const noData = max > 0 ? false : true
      const cornerRadius =
        (max / maxTotal) * 100 < 4 ? (max / maxTotal) * 100 : 4
      return {
        x: item,
        y: noData
          ? (item.y = ((maxInput < 100 ? 100 : maxInput) / 100) * 3 || 3)
          : max,
        noData: noData,
        cornerRadius
      }
    })
    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: (datum) => (!datum.noData ? 'transparent' : '#DAE1EA'),
            stroke: '#fff',
            strokeWidth: (datum) => (!datum.noData ? 3 : 0)
          }
        }}
        barWidth={(datum) => (!datum.noData ? 15 : 12)}
        data={dataArr}
        cornerRadius={{
          top: (datum) => (!datum.noData ? datum.cornerRadius : 0),
          bottom: (datum) => (!datum.noData ? datum.cornerRadius : 0)
        }}
      />
    )
  }

  renderBar(barData, index) {
    const { name } = this.props
    const dataStyle = {
      fill: barData.barColor || '#264382',
      stroke: '#fff',
      strokeWidth: 1
    }

    return (
      <VictoryBar
        key={name + index}
        offsetX={10}
        style={{
          data: dataStyle
        }}
        barWidth={12}
        data={barData.dataArr}
      />
    )
  }

  render() {
    const { startDate, endDate, entries } = this.props
    if (startDate && endDate && entries) {
      const data = this.dataHandle(startDate, endDate)
      const { barDatas, dataX, middleValue, gridColor, maxTotal } = data
      return (
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart width={width} height={60}>
              {barDatas &&
                barDatas.map((barData, index) =>
                  this.renderBar(barData, index)
                )}
              {dataX && this.renderPlaceholderBar(data)}
              {barDatas && (
                <VictoryAxis
                  invertAxis={true}
                  dependentAxis
                  tickValues={[0, maxTotal || 100]}
                  style={{
                    axis: { stroke: 'transparent' },
                    grid: {
                      stroke: (t) =>
                        t === middleValue ? gridColor : 'transparent',
                      strokeWidth: 2,
                      strokeDasharray: '6, 3'
                    },
                    tickLabels: {
                      fill: 'transparent'
                    }
                  }}
                />
              )}
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      )
    } else {
      return <></>
    }
  }
}

export default HRZChart
