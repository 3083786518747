import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Divider } from 'antd'
import SleepChart from './comp/sleep'
import Information from '../UI/extraInformation'
import CheckDocs from './comp/checkDots'
import NormalBar from './comp/normalBar'
import SubTip from './comp/subTip'
import { calculateElementWidth } from './common'
import { SvgText } from '../icons/weeklySummary'
import { sumReduce, getFloat, getAxisX } from './common'
import { timesFormat } from '../../utils/common'
import directions from './directions.json'

import { nutritionStyle } from './styles/nutrition.sass'
import DelIcon from '../../asserts/icon/weekly-summary/delete.svg'

const gray = '#b7bfc6',
  primary = '#383E48',
  normal = '#70808E'

class Rejuvenation extends Component {
  static propTypes = {}

  getNormalSvg = (text, color, widthOffset) => {
    return (
      <SvgText
        text={text}
        fill={color || primary}
        height={33}
        fontSize={30}
        width={
          calculateElementWidth('span', text, '30px').width + (widthOffset || 5)
        }
        textAttr={{
          dominantBaseline: 'mathematical',
          textAnchor: 'start',
          x: 0
        }}
      />
    )
  }

  getUnitSvg = (text, color) => {
    return (
      <SvgText
        text={text}
        fill={color || primary}
        height={24}
        fontSize={18}
        width={calculateElementWidth('span', text, '18px').width + 8}
        textAttr={{
          dominantBaseline: 'mathematical',
          textAnchor: 'start',
          x: 0
        }}
      />
    )
  }

  formatSleepTime = (value, color) => {
    if (value) {
      const { hours, minutes } = timesFormat(value)
      if (minutes === 0) {
        return (
          <>
            {this.getNormalSvg(hours, color, 2)}
            {this.getUnitSvg('h', color)}
          </>
        )
      } else {
        return (
          <>
            {this.getNormalSvg(hours, color, 2)}
            {this.getUnitSvg('h', color)}
            {this.getNormalSvg(minutes, color, 2)}
            {this.getUnitSvg('m', color)}
          </>
        )
      }
    } else {
      return this.getNormalSvg('--')
    }
  }

  formatBefore11PM = (text, color) => {
    if (text || text === 0) {
      return this.getNormalSvg(text, color)
    } else {
      return this.getNormalSvg('--')
    }
  }

  getGoals = (overview, sleep, startDate, endDate) => {
    const entries = overview.entries.filter((entry) => {
      return entry.pillar === 'rejuvenation'
    })
    const tickValuesX =
      (startDate && endDate && getAxisX(startDate, endDate)) || []
    const nightsBefore11pm =
      overview.summary.rejuvenation &&
      overview.summary.rejuvenation.avg_asleep_time > 0
        ? overview.summary.rejuvenation.nights_before_11pm
        : null
    const previousWeeksNightsBefore11pm =
      overview.summary.rejuvenation &&
      overview.summary.rejuvenation.previous_weeks_asleep_time > 0
        ? overview.summary.rejuvenation.previous_weeks_nights_before_11pm
        : null

    const sleepEntries =
      tickValuesX.map((date) => {
        const matchEntry = sleep.details.find(
          (entry) => entry.summary_date === date.format('YYYY-MM-DD')
        )
        return (
          (matchEntry &&
            (matchEntry.inbed_time && matchEntry.inbed_time / 3600)) ||
          null
        )
      }) || []
    let sleepHoursAvg
    const t = sleepEntries.filter((hours) => hours)
    if (t.length > 0) {
      sleepHoursAvg = Number(getFloat(t.reduce(sumReduce) / t.length, 1))
    }

    return [
      {
        id: 1,
        description: 'Total hours of sleep',
        total: this.formatSleepTime(
          // getSum(sleep.details.map((item) => item.inbed_time))
          sleep.average && sleep.average.avg_asleep_time
        ),
        previous_weeks_average: this.formatSleepTime(
          sleep.previous_weeks_average &&
            sleep.previous_weeks_average.avg_asleep_time,
          gray
        ),
        input_type: 'bar',
        barProps: {
          entries: sleepEntries,
          barColor: '#5C00D1',
          extraAxisText: 'Avg',
          target: sleepHoursAvg,
          min: null,
          max: null
        }
      },
      {
        id: 2,
        description: 'Night to bed before 11 PM',
        unit: 'TIMES',
        total: this.formatBefore11PM(nightsBefore11pm),
        previous_weeks_average: this.formatBefore11PM(
          previousWeeksNightsBefore11pm,
          gray
        ),
        input_type: 'binary',
        barProps: {
          entries: tickValuesX.map((date) => {
            const matchEntry = entries.find(
              (entry) => entry.record_date === date.format('YYYY-MM-DD')
            )
            return (
              (matchEntry &&
                (matchEntry.details.before_11pm ? 'yes' : 'false')) ||
              null
            )
          })
        }
      }
    ]
  }

  renderSleepGoals() {
    const {
      data,
      originInformation,
      oprRejuvenation,
      submitData: { rejuvenation },
      startDate,
      endDate,
      loginRole,
      templates,
      updateEditingCount
    } = this.props

    const { overview, sleep } = data || {}
    sleep.details = sleep.details || []
    let sleepGoals = this.getGoals(overview, sleep, startDate, endDate)

    if (rejuvenation.sleep_goals) {
      sleepGoals.map((item) => {
        const target = rejuvenation.sleep_goals.find(
          (goal) => goal.id === item.id
        )
        if (target) {
          target.description = item.description
          return Object.assign(item, target)
        }
        return item
      })
    }

    if (rejuvenation.deleted_sleep_goal) {
      sleepGoals = sleepGoals.filter(
        (item) => !rejuvenation.deleted_sleep_goal.find((id) => id === item.id)
      )
    }

    return (
      <div className={nutritionStyle}>
        {sleepGoals.map((item, index) => (
          <div className="goal-item" key={index}>
            <div
              className="delete"
              onClick={() =>
                oprRejuvenation('delete', {
                  id: item.id,
                  position: `Rejuvenation - ${item.description}`
                })
              }
            >
              <img className="delete-icon" src={DelIcon} alt="del" />
            </div>

            <div className="name">{item.description}</div>
            <Information
              updateEditingCount={updateEditingCount}
              status={originInformation && originInformation.status}
              title="Custom Text"
              initialValue={item.custom_text}
              doneStatus={item.custom_text_status}
              showDone={loginRole !== 'User'}
              templates={templates}
              updateCommend={(value, status) =>
                oprRejuvenation('update', {
                  id: item.id,
                  value,
                  status,
                  field: 'custom_text',
                  position: `Rejuvenation - ${item.description}`
                })
              }
            />
            <div
              className="data-content ref-container"
              ref={`rejuvenation.chart_url.${item.id}`}
            >
              <div>
                <div className="days">
                  {item.total}
                  {(item.formatChange || item.formatChange === 0) && (
                    <SvgText
                      text={`${item.formatChange > 0 ? '+' : ''}${
                        item.formatChange
                      }`}
                      fill={normal}
                      fontSize={12}
                      height={18}
                      fontFamily="Gilroy"
                      width={
                        calculateElementWidth(
                          'span',
                          `${item.formatChange > 0 ? '+' : ''}${
                            item.formatChange
                          }`,
                          '12px'
                        ).width + 5
                      }
                      textAttr={{
                        x: 0,
                        textAnchor: 'start',
                        dominantBaseline: 'mathematical',
                        fontWeight: 'normal'
                      }}
                    />
                  )}
                  <SubTip text="7 DAY TOTAL" unit={item.unit} />
                </div>
                <div className="weeks">
                  {item.previous_weeks_average}
                  <SubTip text="3 WEEK AVG" unit={item.unit} />
                </div>
              </div>
              <div className="chart">
                {item.input_type === 'binary' ? (
                  <CheckDocs
                    size="large"
                    showLabel={true}
                    {...{ startDate, endDate, ...item.barProps }}
                  />
                ) : (
                  <NormalBar
                    barWidth={16}
                    showXLabel={true}
                    {...{ startDate, endDate, ...item.barProps }}
                  />
                )}
              </div>
            </div>

            {index !== sleepGoals.length - 1 && <Divider />}
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      startDate,
      endDate,
      data,
      loginRole,
      setSubmitData,
      originInformation,
      deleteSections,
      templates,
      updateEditingCount,
      submitData: { rejuvenation, deleted_section }
    } = this.props

    const isEditable =
      !originInformation ||
      (originInformation.status !== 'Sent' &&
        originInformation.status !== 'Opened')

    return (
      <>
        {!deleted_section.rejuvenation_quality && (
          <div className="sub-section">
            {isEditable && (
              <div
                className="delete"
                onClick={() => deleteSections('rejuvenation_quality')}
              >
                <img className="delete-icon" src={DelIcon} alt="del" />
              </div>
            )}
            <h4 className="sub-title">Quality of sleep</h4>
            <Information
              updateEditingCount={updateEditingCount}
              title="Overview"
              showDone={loginRole !== 'User'}
              status={originInformation && originInformation.status}
              initialValue={rejuvenation.recommendation}
              doneStatus={rejuvenation.recommendation_status}
              templates={templates}
              direction={directions.rejuvenation}
              updateCommend={(value, status) =>
                setSubmitData({
                  part: 'rejuvenation',
                  field: 'recommendation',
                  value,
                  status,
                  position: 'Recommendation of Rejuvenation Quality of Sleep'
                })
              }
            />
            <div
              className="big-chart ref-container"
              ref="rejuvenation.sleep_chart_url"
            >
              <SleepChart
                {...{ startDate, endDate, sleep: data ? data.sleep : {} }}
              />
            </div>
            <div className="extra-information">
              <h5>Analyzing Your Sleep Data</h5>
              <p>
                To determine how well you slept week after week, we take into
                account your average heart rate variability (HRV), sleep
                duration, and bedtime.
              </p>
            </div>
            <Information
              updateEditingCount={updateEditingCount}
              status={originInformation && originInformation.status}
              title="Custom Text"
              templates={templates}
              showDone={loginRole !== 'User'}
              initialValue={rejuvenation.custom_text}
              doneStatus={rejuvenation.custom_text_status}
              updateCommend={(value, status) =>
                setSubmitData({
                  part: 'rejuvenation',
                  field: 'custom_text',
                  value,
                  status,
                  position: 'Custom Text of Rejuvenation - Quality of Sleep'
                })
              }
            />
          </div>
        )}
        {this.renderSleepGoals()}
      </>
    )
  }
}

export default Rejuvenation
