import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from 'antd'
import { confirmStyle } from './styles/confirm.sass'

class ConfirmPopUp extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    title: PropTypes.string,
    content: PropTypes.any,
    concelText: PropTypes.string,
    okText: PropTypes.string,
    handleOk: PropTypes.func,
    handleCancel: PropTypes.func
  }

  render() {
    const {
      title,
      content,
      concelText,
      okText,
      handleCancel,
      handleOk,
      visible
    } = this.props
    return (
      <Modal
        className={confirmStyle}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <h3>{title}</h3>
        <p>{content}</p>
        <footer>
          <Button ghost onClick={handleCancel}>
            {concelText || 'CANCEL'}
          </Button>
          <Button type="primary" onClick={handleOk}>
            {okText || 'CONFIRM'}
          </Button>
        </footer>
      </Modal>
    )
  }
}

export default ConfirmPopUp
