import axios, { hashRequest } from './axios'
import { questionnaireConfig } from '../utils/constant'

export const getScore = async (personId, hash) => {
  const url = `nutrition_score?person_id=${personId}`
  if (hash) {
    const result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    return result.data
  }
  const result = await axios.get(url)
  return result
}

export const saveScoreRecommendation = (
  personId,
  date,
  params,
  sectionVersion
) =>
  axios.postJson(
    `/nutrition_score/recommendation?person_id=${personId}&survey_id=${questionnaireConfig.surveyId}&record_date=${date}&section_version=${sectionVersion}`,
    params
  )

export const sendNewSession = (personId, date) =>
  axios.postJson(
    `/nutrition_score/new_session?person_id=${personId}&survey_id=${questionnaireConfig.surveyId}&record_date=${date}`,
    {}
  )

export const getNutritionAnswer = (personId) =>
  axios.get(`/nutrition_score/answer?person_id=${personId}`)

export const completedCallback = (personId, date) =>
  axios.get(
    `/nutrition_score/expert_notification_email?person_id=${personId}&record_date=${date}`
  )

export const getScoreDetail = async ({
  personId,
  questionId,
  questionOriginalId,
  hash,
  sectionVersion
}) => {
  let url = `/nutrition_score/detail?person_id=${personId}&section_version=${sectionVersion}`
  if (questionOriginalId) {
    url += `&question_original_id=${questionOriginalId}`
  }
  if (typeof questionId === 'string') {
    url += `&question_subject=${questionId}`
  } else {
    url += `&question_id=${questionId}`
  }
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: url
    })
    result = result.data
  } else {
    result = await axios.get(url)
  }
  return result
}

export const getQQTDetails = async (personId, startDate, endDate) => {
  let url = `nutrition_score/details?person_id=${personId}`
  if (startDate) {
    url += `&start_date=${startDate}`
  }
  if (endDate) {
    url += `&end_date=${endDate}`
  }
  const result = await axios.get(url)
  return result
}

export const getHomeNutritionScore = (from, to, personId) =>
  axios.get(
    `/nutrition_score/nutrition-card?from=${from}&to=${to}&person_id=${personId}`
  )
