import React, { Component, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Row, Col, DatePicker, Table } from 'antd'
// import DataTable from '../../components/UI/table'
import {
  getDataAggregationV2,
  getDataAggregationConditions
} from '../../api/dataset'
import PageLoading from '../../components/UI/status/loading'

const { RangePicker } = DatePicker

const Summary = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
  background: #ffffff;
  border: 1px solid #dee2ec;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 20px 0;
  font-size: 12px;
  color: #60656d;
  font-family: Gilroy;
  line-height: 150%;
  text-align: center;
  .large-number {
    font-family: Gilroy-Bold;
    color: #383e48;
    letter-spacing: 0.3px;
    font-size: 40px;
    line-height: 124%;
  }
  .ant-col-6 {
    padding: 0 40px;
  }
`

export const TableContainer = styled.div`
  padding-bottom: 30px;
  .ant-table-wrapper {
    .ant-table-thead {
      tr > th {
        padding-left: 24px;
      }
      tr:first-child th {
        background: #e8ecf0;
        color: #264382;
        border: 1px solid #dee2ec;
      }
    }
    .ant-table-tbody {
      & > tr > td {
        color: #4c6072;
        font-size: 16px;
        vertical-align: top;
        padding: 12px 24px;
        &:first-child {
          color: #383e48;
          font-weight: 600;
        }
        .lighter {
          font-size: 12px;
          opacity: 0.7;
        }
        &:first-child,
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(7) {
          border-right: none !important;
        }
      }
    }
  }
  .right-align {
    text-align: center;
  }
  .ant-table-thead > tr > th {
    background: transparent;
    text-transform: uppercase;
    color: #5c626a;
    opacity: 0.8;
  }
  .ant-table-thead > tr,
  .ant-table-tbody > tr {
    padding: 0 20px;
  }
  .ant-table-tbody > tr > td {
    border-color: rgb(244, 246, 248);
    background: #ffffff;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Gilroy;
  line-height: 120%;
  font-size: 32px;
  color: #4c6072;
  padding-bottom: 20px;
  .ant-picker-range {
    border-radius: 6px;
  }
  .as-date {
    margin-left: 20px;
    font-size: 12px;
    color: #60656d;
    opacity: 0.77;
  }
`

class DataAggregation extends Component {
  static propTypes = {}

  state = {
    loading: true,
    list: [],
    summary: {}
  }

  onDateChange = (date) => {
    const from = date[0].format('YYYY-MM-DD'),
      to = date[1].format('YYYY-MM-DD')
    this.fetchData(from, to)
    this.setState({ from, to })
  }

  initial = async () => {
    const {
      first_test_date,
      last_test_date
    } = await getDataAggregationConditions()
    const from = first_test_date,
      to = moment().format('YYYY-MM-DD')
    this.fetchData(from, to)
    this.setState({
      from,
      to,
      lastTestDate: last_test_date
    })
  }

  fetchData = async (from, to) => {
    this.setState({ loading: true })
    const data = await getDataAggregationV2(from, to)
    this.setState({ ...data, loading: false })
  }

  componentDidMount() {
    this.initial()
  }

  render() {
    const { summary, list, loading, from, to, lastTestDate } = this.state
    return (
      <>
        <Header>
          <div className="pag-title">
            Data Aggregation
            {lastTestDate && (
              <span className="as-date">
                As of {moment(lastTestDate).format('MMM, D YYYY')}
              </span>
            )}
          </div>
          {from && to && (
            <DateRange {...{ from, to, onDateChange: this.onDateChange }} />
          )}
        </Header>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <Summary>
              <Row type="flex">
                <Col span={8}>
                  <div className="large-number">
                    {summary.total_sessions || '--'}
                  </div>
                  Total Testing Sessions
                </Col>
                <Col span={8}>
                  <div className="large-number">
                    {summary.tested_clients || '--'}
                  </div>
                  Clients Tested
                </Col>
                <Col span={8}>
                  <div className="large-number">
                    {summary.repeat_tested_clients || '--'}
                  </div>
                  Members tested multiple times
                </Col>
              </Row>
            </Summary>
            <TableContainer>
              <DataTable dataSource={list} />
            </TableContainer>
          </>
        )}
      </>
    )
  }
}

export default DataAggregation

function DateRange(props) {
  const { from, to, onDateChange } = props
  const defaultValue = [moment(from), moment(to)]
  const [dates, setDates] = useState(defaultValue)
  const [hackValue, setHackValue] = useState(null)
  const [value, setValue] = useState(defaultValue)

  const disabledDate = (current) => {
    return (dates && current.isBefore(dates[0])) || current.isAfter(moment())
  }

  const onOpenChange = (open) => {
    if (open) {
      setHackValue([null, null])
      setDates([null, null])
    } else {
      setHackValue(null)
    }
  }

  return (
    <RangePicker
      size="large"
      format="M-D-YYYY"
      value={hackValue || value}
      disabledDate={disabledDate}
      onCalendarChange={(val) => setDates(val)}
      onChange={(val) => {
        setValue(val)
        onDateChange(val)
      }}
      onOpenChange={onOpenChange}
    />
  )
}

function DataTable(props) {
  const columns = [
    {
      title: 'Parameter',
      fixed: 'left',
      children: [
        {
          width: 200,
          key: 'category_p',
          title: 'Category',
          dataIndex: 'category',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              a.category.toLowerCase().charCodeAt() -
              b.category.toLowerCase().charCodeAt()
            )
          }
        },
        {
          width: 200,
          key: 'metric_p',
          title: 'Metric',
          dataIndex: 'metric',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              a.metric.toLowerCase().charCodeAt() -
              b.metric.toLowerCase().charCodeAt()
            )
          }
        }
      ]
    },
    {
      title: 'All Clients',
      children: [
        {
          width: 150,
          title: 'Total with repeat testing',
          key: 'all-total',
          dataIndex: 'all_repeat',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return Number(a.all_repeat) - Number(b.all_repeat)
          }
        },
        {
          width: 150,
          title: 'Improved',
          key: 'all_improved',
          dataIndex: 'all_improved',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              Number(extractN(a.all_improved)) -
              Number(extractN(b.all_improved))
            )
          },
          render: (text) => {
            return (
              <>
                {extractN(text)}
                <div className="lighter">{text}</div>
              </>
            )
          }
        },
        {
          width: 150,
          title: 'Improved (%)',
          key: 'all_improved_percent',
          dataIndex: 'all_improved_percent',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              parseInt(a.all_improved_percent) -
              parseInt(b.all_improved_percent)
            )
          }
        }
      ]
    },
    {
      title: 'At Risk Clients',
      children: [
        {
          width: 200,
          title: 'Total value outside of “normal” range',
          key: 'all_risk_total',
          dataIndex: 'risk_repeat',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              Number(extractN(a.risk_repeat)) - Number(extractN(b.risk_repeat))
            )
          },
          render: (text) => {
            return (
              <>
                {extractN(text)}
                <div className="lighter">{text}</div>
              </>
            )
          }
        },
        {
          width: 150,
          title: 'Improved',
          key: 'all_risk_improved',
          dataIndex: 'risk_improved',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              Number(extractN(a.risk_improved)) -
              Number(extractN(b.risk_improved))
            )
          },
          render: (text) => {
            return (
              <>
                {extractN(text)}
                <div className="lighter">{text}</div>
              </>
            )
          }
        },
        {
          width: 150,
          title: 'Improved (%)',
          key: 'all_risk_improved_percent',
          dataIndex: 'risk_improved_percent',
          sortDirections: ['descend', 'ascend'],
          sorter: (a, b) => {
            return (
              parseInt(a.risk_improved_percent) -
              parseInt(b.risk_improved_percent)
            )
          }
        }
      ]
    }
  ]
  return (
    <Table
      columns={columns}
      dataSource={props.dataSource || []}
      bordered
      size="middle"
      pagination={false}
      scroll={{
        x: 'calc(100% + 50%)'
        // y: 'calc(100vh - 532px)' //240
      }}
      rowKey={(record) => `${record.category}_${record.metric}`}
    />
  )
}

function extractN(str) {
  const target = str.match(/=[0-9]+\(/)
  if (target) {
    return target[0].replace('=', '').replace('(', '')
  } else {
    return '--'
  }
}
