import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { Empty } from 'antd'
import * as Icons from '../../components/icons'
import * as Onboarding from '../../components/icons/onboarding'
import * as Status from '../../components/icons/statusIcon'
import * as Facilities from '../../components/icons/facility'
import * as SummaryEmail from '../../components/icons/weeklySummary'
import * as Program from '../../components/icons/program'

class PrivacyView extends Component {
  static propTypes = {}

  render() {
    return (
      <div>
        <h1 style={{ textAlign: 'center' }}>phs privacy</h1>
        <div style={{ background: '#fff', textAlign: 'center' }}>
          {Object.keys(Icons).map((item, index) => {
            const Icon = Icons[item]
            return <Icon key={index} />
          })}
          <br />
          {Object.keys(Onboarding).map((item, index) => {
            const Icon = Onboarding[item]
            return <Icon key={index} />
          })}
          <br />
          {Object.keys(Status).map((item, index) => {
            const Icon = Status[item]
            return <Icon key={index} />
          })}
          <br />
          {Object.keys(Facilities).map((item, index) => {
            const Icon = Facilities[item]
            return <Icon key={index} />
          })}
          <br />
          {Object.keys(SummaryEmail).map((item, index) => {
            const Icon = SummaryEmail[item]
            return <Icon key={index} />
          })}
          <br />
          {Object.keys(Program).map((item, index) => {
            const Icon = Program[item]
            return <Icon key={index} />
          })}
        </div>
        <Empty />
      </div>
    )
  }
}

export default PrivacyView
