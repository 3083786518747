import React, { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
// import PropTypes from 'prop-types'
import { Button } from 'antd'
import ContainerWrapper from './containerWrapper'
import FileManager from '../files/management'
import Container from './container/index'
import { containerStyle } from './container.sass'

class DatasetContainer extends Component {
  static propTypes = {}

  render() {
    const {
      person,
      dbField,
      dataLoading,
      createDataset,
      // getShareLink,
      fileUploadAsync
      // showDataset,
    } = this.props
    return (
      (person && (
        <div className={containerStyle}>
          {/* <Button
            className="btn-generate-report"
            type="primary"
            onClick={() =>
              getShareLink(person.id, dbField.type.replace(/_/g, '-'))
            }
            ghost
          >
            generate share link
          </Button> */}
          {dbField && (
            <FileManager
              title="Assessment files"
              type={dbField.fileType || dbField.type}
              person={person}
              editable={true}
              fileUploadAsync={fileUploadAsync}
            />
          )}
          {!dataLoading && (
            <div className="btn-date-set">
              <Button type="primary" ghost onClick={createDataset}>
                <EditOutlined />
                create data set
              </Button>
            </div>
          )}
          <Container {...this.props} />
        </div>
      )) || <div />
    )
  }
}

export default ContainerWrapper(DatasetContainer)
