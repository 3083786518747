import axios, { formDataRequest } from './axios'

export const addProgram = (params, personId, querry) => {
  let url = `/program?person_id=${personId}`
  if (querry && querry.fork_id) {
    url += `&fork_id=${querry.fork_id}&status=${querry.status}`
  }
  return axios.postJson(url, params)
}

export const editProgram = (params, personId, id) =>
  axios.putJson(`/program/${id}?person_id=${personId}`, params)

export const deleteProgram = (id) => axios.delete(`/program/${id}`)

export const publishProgram = (id) => axios.post(`/program/publish/${id}`)

export const getAllProgram = (personId, from, to) =>
  axios.get(`/program?person_id=${personId}&from=${from}&to=${to}`)

export const getLiveProgram = (personId, from, to) =>
  axios.get(`/program/live?person_id=${personId}&from=${from}&to=${to}`)

export const getProgramDetail = (id, from, to, person_id) => {
  let url = `/program/${id}?from=${from}&to=${to}`
  if (person_id) {
    url += `&person_id=${person_id}`
  }
  return axios.get(url)
}

export const addProgramItem = (params, programsId) =>
  axios.postJson(`/program/item?program_id=${programsId}`, params)

export const editProgramItem = (params, programsId, id) =>
  axios.putJson(`/program/item/${id}?program_id=${programsId}`, params)

export const deleteProgramItem = (id) => axios.delete(`/program/item/${id}`)

export const deleteWorkout = (id) =>
  axios.delete(`/record_summary/${id}/manual-delete`)

export const editWorkout = (id, params) =>
  axios.put(`/record_summary/${id}/manual-details`, params)

export const attachFile = ({
  personId,
  itemId,
  files,
  source,
  extraInfo,
  progressCallback
}) => {
  const path = `/attachments/program_item.attachments/${itemId}?person_id=${personId}`
  const formData = new FormData()
  formData.append('file', files[0])
  if (extraInfo) {
    for (const attr in extraInfo) {
      formData.set(attr, extraInfo[attr])
    }
  }
  return formDataRequest({
    method: 'post',
    path,
    data: formData,
    onUploadProgress: (process) => {
      const percent = (process.loaded / process.total).toFixed(2) * 100
      return progressCallback && progressCallback(percent)
    },
    cancelSource: source
  })
}

export const attachLink = (itemId, personId, url) =>
  axios.postJson(
    `/attachments/url/program_item.attachments/${itemId}?person_id=${personId}`,
    { url }
  )

export const removeAttachment = (attachId, personId) =>
  axios.delete(`/attachments/${attachId}?person_id=${personId}`)

export const getWorkoutDetail = (id) => axios.get(`/program/workout?id=${id}`)

export const changeExpertReleation = (params) =>
  params.id
    ? axios.putJson('/program/releation', params)
    : axios.postJson('/program/releation', params)

export const getProgramPercent = (id, from, to) =>
  axios.get(`/program/percent/${id}?&from=${from}&to=${to}`)

export const getProgramItemDurationMapping = () =>
  axios.get('/program/item/duration-mapping')

export const getProgramItemIntensityRange = () =>
  axios.get('/program/item/intensity-range')

export const getWorkoutTypes = () => axios.get('/program/item/workout-types')

export const notifyClient = (id, message) => {
  let params = {}
  if (message) {
    params.message = message
  }
  return axios.postJson(`/program/notify/${id}`, params)
}
