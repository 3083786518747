import React from 'react'
import EmailTemplateContainer from '../../components/commonTools/emailTemplate'

function EmailTemplate() {
  return (
    <>
      <div className="page-title">Summary Email Template</div>
      <EmailTemplateContainer />
    </>
  )
}

export default EmailTemplate
