const DEFAULT_TITLE = 'Let’s take a look at your progress'

const EMAIL_ACTION = {
  create: 'create',
  // update: 'update',
  sent: 'sent',
  undo: 'undo',
  mark_as_done: 'mark_as_done',
  save_as_draft: 'save_as_draft',
  delete: 'delete',
  send_test_email: 'send_test_email'
}

const SLEEP_GOALS = [
  { id: 3, description: 'Time to bed' },
  {
    id: 1,
    description: 'Hours of sleep per week'
  },
  {
    id: 4,
    description: 'Hours in bed'
  },
  {
    id: 5,
    description: 'Heart rate variability'
  },
  {
    id: 6,
    description: 'Resting Heart rate '
  }
]

const ACTIVITY_GOALS = [
  {
    id: 3,
    description: 'Training Load (RPES)'
  }
]

const INIT_DATA = {
  email_subject: 'Apeiron Life Weekly Insights',
  section_show: {
    rejuvenation: true,
    exercise: true,
    weight: true,
    nutrition: true
  },
  overview: {
    title: DEFAULT_TITLE,
    title_status: '',
    summary_introduction: '',
    summary_introduction_status: '',
    /* new field start */
    areas_of_focus: '',
    long_term_aspirations: '',
    /* new field end */
    deleted_weight: []
  },
  rejuvenation: {
    sleep_chart_url: '',
    recommendation: '',
    recommendation_status: '',
    sleep_goals: SLEEP_GOALS,
    deleted_sleep_goal: []
  },
  exercise: {
    /* new field start */
    recommendation: '',
    recommendation_status: '',
    /* new field end */
    workout_chart_url: '',
    heart_chart_url: '',
    activity_goals: [
      // {
      //   id: 1,
      //   description:'',
      //   chart_url: '',
      //   custom_text: '',
      //   custom_text_status: ''
      // }
    ],
    deleted_activity_goal: []
  },
  nutrition: [
    // {
    //   id: 1,
    //   description:'',
    //   unit: '',
    //   chart_url: '',
    //   recommendation: '',
    // recommendation_status: '',
    //   custom_text: '',
    // custom_text_status: ''
    // }
  ],
  nutrition_recommendation: '',
  nutrition_recommendation_status: '',
  /* new field start */
  weight: [],
  weight_recommendation: '',
  weight_recommendation_status: '',
  /* new field end */
  deleted_nutritions: [],
  date_range: '',
  deleted_section: {
    rejuvenation: false,
    rejuvenation_quality: false,
    exercise: false,
    exercise_workout: false,
    exercise_target: false,
    exercise_training_target: false,
    exercise_target_breakdown: false,
    nutrition: false,
    /* new field start */
    weight: false
    /* new field end */
  }
}

export { DEFAULT_TITLE, EMAIL_ACTION, SLEEP_GOALS, INIT_DATA, ACTIVITY_GOALS }
