import React from 'react'
// import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import {
  getCategories,
  createGoal,
  updateGoal,
  deleteGoal,
  getMetaData,
  getStatistics,
  saveEntry,
  deleteEntry,
  getarchivedGoals,
  removeAttachment,
  editAttachment,
  creatGoalBulk,
  editGoalBulk,
  deleteGoalBulk
} from '../../api/microGoals'
import { saveRPE } from '../../api/summaryEmail'

import { getRole } from '../../utils/common'
import { staticTrackingOuraMetric } from '../../utils/constant'

// import { ArchivedIcon } from '../../components/icons'
import NutritionIcon from '../../asserts/images/nutrition.png'
import ExerciseIcon from '../../asserts/images/exercise.png'
import RejuvenationIcon from '../../asserts/images/rejuvenation.png'

const pillars = [
  { title: 'Nutrition', name: 'nutrition', icon: NutritionIcon },
  { title: 'Exercise', name: 'exercise', icon: ExerciseIcon },
  { title: 'Rejuvenation', name: 'rejuvenation', icon: RejuvenationIcon }
  // { title: 'Mindset', name: 'mindset', icon: MindsetIcon },
  // { title: 'Functional Health', name: 'functional_health', icon: PatchIcon }
]

export default (WrappedComponent) =>
  (class GoalsWrapper extends React.Component {
    static propTypes = {}
    state = {
      loading: true,
      categories: null,
      editGoal: null,
      goals: [],
      period: this.props.period || 'monthly',
      showDate: '',
      statistics: {},
      visible: 0
    }

    groupArray = (arr, field) => {
      let result = []
      for (let item of arr) {
        if (item[field] && !result.find((node) => node === item[field])) {
          result.push(item[field])
        }
      }
      result = result.map((item) => {
        let children = arr.filter((child) => child[field] === item)
        children = children.map((child) => ({
          title: child.name,
          value: child.name,
          key: child.name //`${item}-${child.name}`
        }))
        return {
          title: item,
          value: `origin-${item}`,
          key: `origin-${item}`,
          children
        }
      })
      return result
    }

    getStatistics = async (notFirstTime, type) => {
      const { person, targetPillar } = this.props
      const { startDate, endDate } = this.state
      type = type || this.props.type
      if (!startDate || !endDate) return
      this.setState({ loading: true })
      const result = await getStatistics(
        startDate.format('YYYY-MM-DD'),
        endDate.format('YYYY-MM-DD'),
        person.id,
        type === 'archived',
        targetPillar
      )
      if (result.exercise && result.extra && result.extra.training_load) {
        const { entries, aggregation } = result.extra.training_load
        const trainingLoad = {
          aggregation,
          micro_goal: {
            input_type: 'number',
            micro_goal: 'Training load (RPEs)',
            chart_type: 'bar',
            id: 'Training load (RPEs)',
            source: 'manual'
            // fixed_target: result.extra.max_heart_rate,
            // unit: 'bpm'
          },
          achieving: [
            {
              entries: entries.map((entry) => {
                const input = entry.training_load
                entry = Object.assign(entry, {
                  input,
                  created_at: entry.record_date
                })
                return entry
              })
            }
          ]
        }
        result.exercise.unshift(trainingLoad)
      }
      const { aggregation } = result
      // return result
      this.setState({ statistics: result, aggregation, loading: false })
      if (notFirstTime) {
        return result
      }
    }

    getMetaData = async () => {
      const result = await getMetaData()
      let { healthkit, garmin, activity, ouraring, polar, withings } = result
      let healthkitMetric = [],
        garminMetric = [],
        ouraMetric = [],
        polarMetric = [],
        withingsMetric = [],
        healthkitActivities = [],
        garminActivities = [],
        polarActivities = [],
        trackingMetric = [],
        ouraActivities = [],
        trackingOuraMetric = []

      for (const item in healthkit) {
        healthkitMetric.push({
          label: item,
          value: item,
          pillar: healthkit[item].pillar,
          unit: healthkit[item].unit
        })
      }
      for (const item in garmin) {
        garminMetric.push({
          label: item,
          value: item,
          pillar: garmin[item].pillar,
          unit: garmin[item].unit
        })
      }

      for (const item in ouraring) {
        const itemObj = {
          label: item,
          value: item
        }
        switch (ouraring[item].show_property) {
          case 0:
            ouraMetric.push(itemObj)
            trackingOuraMetric.push({ ...ouraring[item], name: item })
            break
          case 1:
            ouraMetric.push(itemObj)
            break
          case 2:
            trackingOuraMetric.push({ ...ouraring[item], name: item })
            break
          case 3:
            ouraActivities.push(itemObj)
            break
          default:
            ouraMetric.push(itemObj)
            break
        }
      }

      for (const item in polar) {
        polarMetric.push({
          label: item,
          value: item,
          pillar: polar[item].pillar,
          unit: polar[item].unit
        })
      }

      for (const item in withings) {
        withingsMetric.push({
          label: item,
          value: item,
          pillar: withings[item].pillar,
          unit: withings[item].unit
        })
      }

      for (const item of activity.healthkit) {
        healthkitActivities.push({
          label: item,
          value: item
        })
      }

      for (const item of activity.garmin) {
        garminActivities.push({
          label: item,
          value: item
        })
      }

      for (const item of activity.polar) {
        polarActivities.push({
          label: item,
          value: item
        })
      }

      for (const item in activity.trackingMetric) {
        trackingMetric.push({
          label: activity.trackingMetric[item],
          value: item
        })
      }

      const ouraSleepMetric = trackingOuraMetric
      trackingOuraMetric = this.groupArray(trackingOuraMetric, 'group_name')

      this.setState({
        healthkit,
        garmin,
        ouraring,
        polar,
        withings,
        healthkitMetric,
        healthkitActivities,
        garminMetric,
        garminActivities,
        ouraMetric,
        polarMetric,
        polarActivities,
        trackingMetric,
        ouraActivities,
        ouraSleepMetric,
        trackingOuraMetric,
        withingsMetric
      })
    }

    getarchivedGoals = async (notFirstTime) => {
      const { person } = this.props
      const result = await getarchivedGoals(person.id)
      let length = 0
      for (const pillar of pillars) {
        length += result[pillar.name].length
      }
      const archivedGoals = {
        goals: result,
        length
      }
      if (notFirstTime) {
        return archivedGoals
      } else {
        this.setState({
          archivedGoals
        })
      }
    }

    getCategories = async () => {
      const result = await getCategories()
      this.setState({ categories: result.categories })
    }

    getWeeklyDate = async () => {
      let startDate = moment().day('Monday')
      if (
        moment()
          .weekday(0)
          .format('dddd') === 'Sunday'
      ) {
        if (moment().format('dddd') === 'Sunday') {
          startDate = moment()
            .subtract(1, 'days')
            .day('Monday')
        }
      }
      const endDate = startDate.clone().day(7)
      await this.setState({ startDate, endDate })
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`
    }

    getMonthlyDate = async () => {
      const showDate = moment().format('MM-YYYY')
      const startDate = moment(`01-${showDate}`, 'DD-MM-YYYY')
      const days = moment(showDate, 'MM-YYYY').daysInMonth()
      const endDate = moment(`${days}-${showDate}`, 'DD-MM-YYYY')
      await this.setState({ startDate, endDate })
      return moment().format('MMM YYYY')
    }

    prevDate = async () => {
      if (this.state.loading) return
      this.setState({ loading: true })
      let { period, startDate, endDate } = this.state
      let showDate
      if (period === 'weekly') {
        startDate = startDate.day(-6)
        endDate = endDate.day(-7)
        showDate = `${startDate.format('MMM D')} - ${endDate.format(
          'MMM D, YYYY'
        )}`
      } else {
        startDate.subtract(1, 'months')
        const days = startDate.daysInMonth()
        endDate = moment(startDate).add(days - 1, 'days')
        showDate = startDate.format('MMM YYYY')
      }
      await this.setState({ startDate, endDate, showDate })
      await this.getStatistics()
      this.setState({ loading: false })
    }

    nextDate = async () => {
      if (this.state.loading) return
      this.setState({ loading: true })
      let { period, startDate, endDate } = this.state
      let showDate
      if (period === 'weekly') {
        startDate = startDate.day(8)
        endDate = endDate.day(7)
        showDate = `${startDate.format('MMM D')} - ${endDate.format(
          'MMM D, YYYY'
        )}`
      } else {
        startDate = startDate.add(1, 'months')
        const days = startDate.daysInMonth()
        endDate = moment(startDate).add(days - 1, 'days')
        showDate = startDate.format('MMM YYYY')
      }
      await this.setState({ startDate, endDate, showDate })
      await this.getStatistics()
      this.setState({ loading: false })
    }

    saveEntry = async (goal, value, date, period) => {
      const { person } = this.props
      period = period || this.state.period.replace('ly', '')
      // midpoint input handle
      if (goal.unit === 'Time') {
        if (value > 24) {
          value = value % 24
        }
        const hour = parseInt(value)
        const minute = Math.round((value % 1) * 60)

        const timeStemp = new Date(
          moment().format(`YYYY-MM-DD ${hour}:${minute}`)
        ).getTime()
        value = (timeStemp / 1000).toString()
      }
      const params = {
        input: value,
        created_at: date,
        unit: period
      }
      const { achieving, micro_goal } = await saveEntry(
        goal.id,
        params,
        person.id
      )
      this.updateAchieving(achieving, micro_goal)
    }

    saveManualRPE = async (micro_goal, id, rpe) => {
      const { statistics } = this.state
      try {
        document.body.style.cursor = 'wait !important'
        const result = await saveRPE(id, rpe)
        const index = statistics[micro_goal.category.pillar].findIndex(
          (item) => item.micro_goal.id === micro_goal.id
        )
        const target = statistics[micro_goal.category.pillar][index].achieving
        const targetAchieving = target.find(
          (item) =>
            item.entries.length > 0 &&
            item.entries.find((entry) =>
              entry.activity_details.find((detail) => detail.record_id === id)
            )
        )
        const targetEntry = targetAchieving.entries.find((entry) =>
          entry.activity_details.find((detail) => detail.record_id === id)
        )
        const targetDetail = targetEntry.activity_details.find(
          (detail) => detail.record_id === id
        )
        if (targetDetail) {
          targetDetail.rpe_manual = result.rpe_manual
          targetDetail.training_load = result.training_load
        }
        document.body.style.cursor = 'auto'
        return result
      } catch (err) {
        document.body.style.cursor = 'auto'
      }
    }

    updateAchieving = (achieving, micro_goal) => {
      const { statistics } = this.state
      const entry = achieving[0]
      const index = statistics[micro_goal.category.pillar].findIndex(
        (item) => item.micro_goal.id === micro_goal.id
      )
      const target = statistics[micro_goal.category.pillar][index]
      target.achieving = target.achieving.map((item) => {
        if (item.from === entry.from && item.to === entry.to) {
          return achieving[0]
        } else {
          return item
        }
      })
      statistics[micro_goal.category.pillar][index] = target
      this.setState({ statistics: _.cloneDeep(statistics) })
    }

    requestGoal = async (params, pillar) => {
      params.is_expert_only = !!params.is_expert_only
      const { person } = this.props
      const { categories, editGoal, startDate, endDate } = this.state
      let { statistics } = this.state
      const category = categories.find(
        (item) =>
          item.category === params.category &&
          item.sub_category === params.sub_category
      )
      if (category) {
        params.category_id = category.id
      } else {
        params.category_id = categories.find(
          (item) => item.category === 'Default' && item.pillar === pillar
        ).id
      }

      delete params.category
      delete params.sub_category
      switch (params.goal_period) {
        case '0': // daily
          params.cron_day_week = '*'
          params.cron_day_month = '*'
          break
        case '1': // weekly
          params.cron_day_month = '*'
          params.cron_day_week = '1'
          params.repeat_num = params.repeat_num || 1
          break
        case '2':
          params.cron_day_week = params.days.sort((a, b) => a - b).join(',')
          params.cron_day_month = '*'
          delete params.days
          break
        case '3': // monthly
          params.cron_day_month = '1'
          params.cron_day_week = '*'
          params.repeat_num = params.repeat_num || 1
          break
        default:
          break
      }
      delete params.goal_period
      if (params.input_type === 'binary' && params.auto_activity === 'Sleep') {
        return this.requestGoalsBulk(params)
      }
      if (
        !params.auto_activity &&
        ((params.source !== 'manual' &&
          params.auto_metric === 'Heart Rate Zones') ||
          (editGoal && editGoal.goal_type === 'heartRateMetrics'))
      ) {
        return this.requestHartRateGoal(params)
      }
      try {
        let result, microGoal
        if (editGoal && editGoal.id) {
          await updateGoal(params, editGoal.id)
        } else {
          params.person_id = person.id
          result = await createGoal(params)
          microGoal = result.micro_goal

          if (!microGoal.category) {
            microGoal.category = categories.find(
              (item) => item.id === microGoal.category_id
            )
          }
          const pillar = microGoal.category && microGoal.category.pillar
          if (!statistics[pillar]) {
            statistics[pillar] = []
          }
          statistics[pillar].push({
            micro_goal: microGoal,
            achieving: [
              {
                entries: [],
                from: startDate.format('YYYY-MM-DD'),
                to: endDate.format('YYYY-MM-DD'),
                achieved: 0,
                missed: 0,
                no_data: 0,
                expected: 1
              }
            ]
          })
        }

        await this.getStatistics(true)
        this.setState({
          editGoal: (result && result.micro_goal) || {}
        })
      } catch (err) {
        console.error(err)
      }
    }

    requestHartRateGoal = async (params) => {
      const { person } = this.props
      const { garminMetric, healthkitMetric, editGoal } = this.state
      params.person_id = person.id
      let metrics = []
      if (params.source === 'healthkit') {
        metrics = healthkitMetric
          .filter((item) => item.value.includes('HeartRate'))
          .map((item) => item.value)
      } else if (params.source === 'garmin') {
        metrics = garminMetric
          .filter((item) => item.value.includes('HeartRate'))
          .map((item) => item.value)
      } else if (params.source === 'any') {
        metrics = []
          .concat(
            healthkitMetric.filter((item) => item.value.includes('HeartRate'))
          )
          .concat(
            garminMetric.filter((item) => item.value.includes('HeartRate'))
          )
          .map((item) => item.value)
        metrics = Array.from(new Set(metrics))
      }

      metrics = metrics.filter(
        (metric) =>
          ![
            'HeartRateZone1',
            'HeartRateZone2',
            'HeartRateZone3',
            'HeartRateZone4',
            'HeartRateZone5'
          ].find((item) => metric === item)
      )
      const requestArr = metrics.map((metric) => {
        const result = Object.assign({}, params)
        result.auto_metric = metric
        result.micro_goal = metric
        result.is_parent = false
        return result
      })
      params.is_parent = true
      params.goal_type = 'heartRateMetrics'
      params.auto_metric = 'Heart Rate Zones'

      requestArr.unshift(params)

      try {
        if (editGoal && editGoal.id) {
          await editGoalBulk(requestArr, editGoal.id)
        } else {
          const { micro_goals } = await creatGoalBulk(requestArr)
          const editGoal = micro_goals.find(
            (item) => !item.parent_micro_goal_id
          )
          this.setState({ editGoal })
        }
        await this.getStatistics(true)
      } catch (err) {
        console.error(err)
      }
    }

    requestGoalsBulk = async (params) => {
      const { person } = this.props
      const { trackingOuraMetric, ouraSleepMetric, editGoal } = this.state
      const { tracking_activity_metric, category_id } = params
      const requestArr = []
      const metrics = tracking_activity_metric.split(',')
      const origins = metrics.filter((item) => item.match('origin-'))
      let requestMetrics = metrics.filter((item) => !item.match('origin-'))
      for (const origin of origins) {
        const originObj = trackingOuraMetric.find(
          (item) => item.value === origin
        )
        requestMetrics = requestMetrics.concat(
          originObj.children.map((item) => item.value)
        )
      }
      const tempGoal = {
        is_parent: false,
        category_id,
        chart_type: 'line',
        cron_day_month: params.cron_day_month,
        cron_day_week: params.cron_day_week,
        input_type: 'number',
        person_id: person.id,
        source: params.source, //'ouraring',
        repeat_num: params.repeat_num,
        unit: params.unit
      }
      for (const metric of requestMetrics) {
        const task = Object.assign({}, tempGoal)
        task.auto_metric = task.micro_goal = metric
        task.group_name = ouraSleepMetric.find(
          (item) => item.name === metric
        ).group_name
        requestArr.push(task)
      }
      params.tracking_activity_metric = requestMetrics.join(',')
      params.person_id = person.id
      params.is_parent = true
      params.auto_metric = params.auto_activity
      params.goal_type = 'sleep'
      delete params.auto_activity
      requestArr.unshift(params)
      try {
        if (editGoal && editGoal.id) {
          await editGoalBulk(requestArr, editGoal.id)
        } else {
          const { micro_goals } = await creatGoalBulk(requestArr)
          const editGoal = micro_goals.find(
            (item) => !item.parent_micro_goal_id
          )
          this.setState({ editGoal })
        }
        await this.getStatistics(true)
      } catch (err) {
        console.error(err)
      }
    }

    addAttachSync = (attach, editGoal) => {
      const { statistics } = this.state
      const targetGoal = statistics[editGoal.category.pillar].find(
        (item) => item.micro_goal.id === editGoal.id
      )
      targetGoal.micro_goal.attachments
        ? targetGoal.micro_goal.attachments.push(attach)
        : (targetGoal.micro_goal.attachments = [attach])
      editGoal = targetGoal.micro_goal
      this.setState({ statistics, editGoal })
    }

    removeAttach = async (attach) => {
      const { person } = this.props
      const { statistics, editGoal } = this.state
      const targetGoal = statistics[editGoal.category.pillar].find(
        (item) => item.micro_goal.id === editGoal.id
      )
      try {
        await removeAttachment(attach.id, person.id)
        targetGoal.micro_goal.attachments = targetGoal.micro_goal.attachments.filter(
          (item) => item.id !== attach.id
        )
        this.setState({ statistics })
      } catch (err) {
        console.error(err)
      }
    }

    editAttachTitle = async (attach, title) => {
      const { person } = this.props
      const { statistics, editGoal } = this.state
      const targetGoal = statistics[editGoal.category.pillar].find(
        (item) => item.micro_goal.id === editGoal.id
      )
      try {
        const result = await editAttachment(attach.id, person.id, { title })
        for (let index in targetGoal.micro_goal.attachments) {
          const item = targetGoal.micro_goal.attachments[index]
          if (item.id === attach.id) {
            targetGoal.micro_goal.attachments[index] = result.attachment
          }
        }
        this.setState({ statistics })
      } catch (err) {
        console.error(err)
      }
    }

    deleteGoal = async (goal) => {
      try {
        if (goal.goal_type) {
          await deleteGoalBulk(goal.id, 0)
        } else {
          await deleteGoal(goal.id, 0)
        }

        await this.getStatistics(true)
      } catch (err) {
        console.error(err)
      }
    }

    archivedGoal = async (goal, archived) => {
      // let { statistics, archivedGoals } = this.state
      try {
        await updateGoal({ archived }, goal.id)
        await this.getStatistics(true)
        // this.setState({ statistics, archivedGoals })
      } catch (err) {
        console.error(err)
      }
    }

    deleteEntry = async (id, date) => {
      const { person } = this.props
      const { statistics } = this.state
      const { achieving } = await deleteEntry(id, date, person.id)
      let isDone = false
      for (const pillar in statistics) {
        for (const item of statistics[pillar]) {
          if (item.micro_goal.id === id) {
            this.updateAchieving(achieving, item.micro_goal)
            isDone = true
          }
        }
        if (isDone) break
      }
    }

    initial = async (type) => {
      const { loginRole } = this.props
      let { showDate, period } = this.state
      if (!showDate) {
        if (period === 'weekly') {
          showDate = await this.getWeeklyDate()
        } else {
          showDate = await this.getMonthlyDate()
        }

        this.setState({
          showDate
        })
      }
      await this.getCategories()
      await this.getarchivedGoals()
      if (loginRole === 'Expert' || loginRole === 'InternalAdmin') {
        this.getMetaData()
      } else {
        this.setState({
          trackingOuraMetric: staticTrackingOuraMetric
        })
      }
      await this.getStatistics(false, type)
      this.setState({ loading: false })
    }

    componentDidMount() {
      const { person, loginRole, type } = this.props
      const role = loginRole || getRole(person.role)
      this.setState({ role })
      this.initial(type)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { type } = this.props
      if (type !== nextProps.type) {
        this.setState({ loading: true })
        this.initial(nextProps.type)
      }
    }

    render() {
      const childProps = {
        pillars,
        ...this.state,
        // switchPeriod: this.switchPeriod,
        prevDate: this.prevDate,
        nextDate: this.nextDate,
        setGlobalState: (state) => this.setState({ ...state }),
        saveEntry: this.saveEntry,
        saveManualRPE: this.saveManualRPE,
        deleteEntry: this.deleteEntry,
        deleteGoal: this.deleteGoal,
        getMetaData: this.getMetaData,
        requestGoal: this.requestGoal,
        archivedGoal: this.archivedGoal,
        addAttachSync: this.addAttachSync,
        removeAttach: this.removeAttach,
        editAttachTitle: this.editAttachTitle
      }

      return <WrappedComponent {...childProps} {...this.props} />
    }
  })
