import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../../../utils/constant'
// import { matchRangeByType } from '../../../../utils/common'
import { Typography } from 'antd'
import HistoryChart from './chat'

import { metricStyle } from './index.sass'

const { Paragraph } = Typography

class MetricCard extends Component {
  static propTypes = {
    metric: PropTypes.object,
    turnTo: PropTypes.func,
    type: PropTypes.string,
    person: PropTypes.object
  }

  getNormalColor = (personValue) => {
    const currentRange = personValue && personValue.output_range
    const color = (currentRange && currentRange.color) || 'rgb(201, 208, 224)'
    const colorObj = colors.find((item) => item.key === color)
    const colorValue = (colorObj && colorObj.value) || color
    return { currentRange, color: colorValue }
  }

  getBiomarkerValue = () => {
    const { person, metric, testDate } = this.props
    const personalBioMarker = metric.personal_data
    if (!personalBioMarker) {
      return {}
    }
    const profile = person && person.profile
    profile.testDate = testDate
    let range
    const type =
      (personalBioMarker.prefer_conventional && 'conventional') || 'functional'
    range = personalBioMarker[`${type}_output_range`] || {}
    const optimalRange = personalBioMarker[`${type}_optimal_range`] || []
    range.min = optimalRange[0]
    range.max = optimalRange[1]

    let colorObj, colorValue
    if (range) {
      colorObj = colors.find((item) => item.key === range.color)
      colorValue = (colorObj && colorObj.value) || range.color
    }
    return { currentRange: range, color: colorValue || 'rgb(201, 208, 224)' }
  }

  render() {
    const { metric, turnTo, ageScopes, person } = this.props
    const { person_value: personValue } = metric
    let result
    if (personValue) {
      result = this.getNormalColor(personValue)
    } else {
      result = this.getBiomarkerValue()
    }
    const style = {
      background: result.color,
      marginBottom: 0
    }
    const valueObj = metric.person_value || metric.personal_data
    const isMobileView = window.location.href.includes('ios')
    return (
      <div
        className={`${metricStyle} metric-container`}
        onClick={() => turnTo(metric.id)}
      >
        <Paragraph className="top-tip" style={style} ellipsis>
          {(result.currentRange && result.currentRange.name) || 'N/A'}
        </Paragraph>

        <div className="name">{metric.name}</div>
        {metric.summary && <div className="summary">{metric.summary}</div>}
        <div className="personal-value">
          {(valueObj && (
            <div>
              <span>
                {metric.options ? valueObj.value : Number(valueObj.value)}
              </span>
              {!metric.options && (
                <span className="unit">
                  {!!metric.unit && metric.unit !== 'N/A' ? metric.unit : ''}
                </span>
              )}
            </div>
          )) ||
            'n/a'}
        </div>

        {ageScopes && isMobileView && (
          <div style={{ marginLeft: -20, padding: 0 }}>
            <HistoryChart
              biomarker={metric}
              person={person}
              ageScopes={ageScopes}
            />
          </div>
        )}
      </div>
    )
  }
}

export default MetricCard
