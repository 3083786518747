import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel } from 'victory'
import ContainerWidthSizer from '../../UI/ContainerWidthSizer'
import { getSum } from '../../../utils/common'
import { fontFamily } from '../../../utils/constant'
import { getAxisX } from '../../summaryEmail/common'

class BarChart extends Component {
  static propTypes = {
    weightMG: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    barColor: PropTypes.string,
    pillar: PropTypes.string,
    overview: PropTypes.object,
    totalHeartRate: PropTypes.array,
    barWidth: PropTypes.number
  }

  state = {
    barDatas: []
  }

  xAxisFormat = (x) => {
    const Weeks = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    return Weeks[moment(x).format('d')]
  }

  getMaxInputByBarData = (barDatas) => {
    const arr = barDatas.map((bar) => bar.dataArr.map((item) => item.y))
    const nunmberArr = [].concat.call(...arr)
    return Math.max(...nunmberArr)
  }

  initWeight(props) {
    const { weightMG, startDate, endDate } = props || this.props
    const {
      achieving: [{ entries }]
    } = weightMG
    const dataX = getAxisX(startDate, endDate)
    let dataArr = dataX.map((item) => {
      const data = entries.find(
        (entry) => entry.created_at === item.format('YYYY-MM-DD')
      )
      return {
        x: item,
        y: (data && Number(data.input || data.input_auto)) || 0
      }
    })
    const Max = Math.max(...dataArr.map((item) => item.y))
    dataArr = dataArr.map((item) => {
      if (item.y === 0) {
        item.y = Max / 100 || 1
        item.noData = true
      }
      return item
    })

    const totals = dataArr.filter((item) => !item.noData).map((item) => item.y)

    let middleValue = totals.length > 0 ? getSum(totals) / totals.length : 0
    // this.setState()
    return {
      dataX,
      dataArr,
      maxInput: Max,
      middleValue,
      gridColor: '#7BAD2D'
    }
  }

  initExercise() {
    let {
      overview: { heart_rate },
      startDate,
      endDate
    } = this.props
    const entries = (heart_rate && heart_rate.achieving) || []

    const dataX = getAxisX(startDate, endDate)

    function getdataArr(zoneIndex) {
      let dataArr = dataX.map((item) => {
        const datas = entries
          .filter((entry) => entry.created_at === item.format('YYYY-MM-DD'))
          .map((entry) => entry.heart_rate_zone || [])

        const y = getSum(datas.map((data) => data[zoneIndex] || 0))
        return {
          x: item,
          y
        }
      })
      return dataArr
    }

    const barDatas = [
      // {
      //   dataArr: getdataArr(0),
      //   barColor: '#F5CA5C',
      //   name: 'Z1'
      // },
      {
        dataArr: getdataArr(1),
        barColor: '#F7AD69',
        name: 'Z2'
      },
      {
        dataArr: getdataArr(2),
        barColor: '#FB9043',
        name: 'Z3'
      },
      {
        dataArr: getdataArr(3),
        barColor: '#FC7558',
        name: 'Z4'
      },
      {
        dataArr: getdataArr(4),
        barColor: '#FF501A',
        name: 'Z5'
      }
    ]

    const maxTotal = Math.max(
      ...entries.map(({ heart_rate_zone }) => {
        const heartRateZone = new Array(...heart_rate_zone)
        return getSum(heartRateZone.shift() !== undefined && heartRateZone)
      })
    )

    const setMinValue = maxTotal * 0.05

    const maxInput = this.getMaxInputByBarData(barDatas)
    function dataArrHandle(index) {
      barDatas[index].dataArr = barDatas[index].dataArr.map((item, idx) => {
        const data = barDatas[index - 1]
          ? barDatas[index - 1].dataArr[idx]
          : { y: 0 }
        return {
          x: item.x,
          y:
            item.y === 0
              ? 0
              : data.y + (item.y < setMinValue ? setMinValue : item.y),
          topRadius: 0,
          bottomRadius: 0
        }
      })
    }
    for (let i = 0; i < barDatas.length; i++) {
      dataArrHandle(i)
    }

    // const totalHeartRateValue = getSum(totalHeartRate.map(item => item.value))
    // let haveDataDays = entries
    //   .filter(item => getSum(item.heart_rate_zone || []))
    //   .map(item => item.created_at)
    // haveDataDays = Array.from(new Set(haveDataDays))
    // const middleValue =
    //   haveDataDays.length > 0 ? totalHeartRateValue / haveDataDays.length : 0

    this.setState({
      dataX,
      barDatas: barDatas.reverse(),
      maxInput,
      maxTotal,
      // middleValue,
      gridColor: '#FF501A'
    })
  }

  initRejuvenation() {
    let {
      overview: { entries },
      startDate,
      endDate,
      pillar
    } = this.props
    entries = entries
      ? entries.filter((item) => item.pillar === 'rejuvenation' && !item.manual)
      : []
    
    function getdataArr(name) {
      let dataArr = dataX.map((item) => {
        const data = entries.find(
          (entry) => entry.record_date === item.format('YYYY-MM-DD')
        )
        return {
          x: item,
          y: (data && data.details[`${name}_time`]) || 0,
          topRadius: 4,
          bottomRadius: 4
        }
      })
      return dataArr
    }
    const dataX = getAxisX(startDate, endDate)
    let barDatas = []
    let middleValue
    if(pillar === 'Rejuvenation'){
      barDatas = [
        {
          dataArr: getdataArr('inbed'),
          barColor: '#BAC3CE',
          name: 'Inbed'
        },
        {
          dataArr: getdataArr('asleep'),
          barColor: '#5C00D1',
          name: 'Asleep'
        }
      ]
      middleValue = Math.round(
        getSum(
          entries.map((item) => (item.details ? item.details.asleep_time : 0))
        ) / entries.length
      )
    }else if(pillar === 'Rejuvenation-inbed'){
      barDatas = [
        {
          dataArr: getdataArr('asleep'),
          barColor: '#BAC3CE',
          name: 'Asleep'
        },
        {
          dataArr: getdataArr('inbed'),
          barColor: '#5C00D1',
          name: 'Inbed'
        }
      ]
      middleValue = Math.round(
        getSum(
          entries.map((item) => (item.details ? item.details.inbed_time : 0))
        ) / entries.length
      )
    }
    const maxInput = this.getMaxInputByBarData(barDatas)

    this.setState({
      dataX,
      barDatas,
      maxInput,
      middleValue,
      gridColor: '#5C00D1'
    })
  }

  componentDidMount() {
    const { weightMG, pillar } = this.props
    if (weightMG) {
      this.initWeight()
    } else if (pillar === 'Exercise') {
      this.initExercise()
    } else if (pillar === 'Rejuvenation') {
      this.initRejuvenation()
    }else if (pillar === 'Rejuvenation-inbed') {
      this.initRejuvenation()
    }
  }

  renderWeight(dataArr) {
    const { barColor, barWidth } = this.props
    // const { dataArr } = this.state
    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: (datum) => {
              if (datum.noData) {
                return '#DAE1EA'
              } else {
                return barColor || '#264382'
              }
            }
          }
          // axis
        }}
        cornerRadius={{
          top: (datum) => (datum.noData ? 0 : 4),
          bottom: (datum) => (datum.noData ? 0 : 4)
        }}
        barWidth={barWidth || 12}
        data={dataArr}
      />
    )
  }

  renderPlaceholderBar() {
    const { pillar, barWidth } = this.props
    const { barDatas, dataX, maxInput, maxTotal } = this.state

    const dataArr = dataX.map((item) => {
      let arr = barDatas.map((bar) => {
        const result = bar.dataArr.find(
          (data) => item.format('YYYY_MM_DD') === data.x.format('YYYY_MM_DD')
        )
        return result && result.y
      })
      const max = Math.max(...arr)
      const noData = max > 0 ? false : true
      const cornerRadius =
        (max / maxTotal) * 100 < 6 ? (max / maxTotal) * 100 : 6
      return {
        x: item,
        y: noData
          ? (item.y = maxInput / 100 || 1)
          : pillar === 'Exercise'
          ? max
          : 0,
        noData: noData,
        cornerRadius
      }
    })
    return (
      <VictoryBar
        offsetX={10}
        style={{
          data: {
            fill: (datum) =>
              !datum.noData && pillar === 'Exercise'
                ? 'transparent'
                : '#DAE1EA',
            stroke: '#fff',
            strokeWidth: (datum) =>
              !datum.noData && pillar === 'Exercise' ? 3 : 0
          }
        }}
        barWidth={(datum) =>
          barWidth
            ? barWidth + 3
            : !datum.noData && pillar === 'Exercise'
            ? 15
            : 12
        }
        data={dataArr}
        cornerRadius={{
          top: (datum) =>
            !datum.noData && pillar === 'Exercise' ? datum.cornerRadius : 0,
          bottom: (datum) =>
            !datum.noData && pillar === 'Exercise' ? datum.cornerRadius : 0
        }}
      />
    )
  }

  renderBar(barData, index) {
    const { pillar, barWidth } = this.props
    const dataStyle = {
      fill: barData.barColor || '#264382'
    }
    if (pillar === 'Exercise') {
      dataStyle.stroke = '#fff'
      dataStyle.strokeWidth = 1
    }
    return (
      <VictoryBar
        key={index}
        offsetX={10}
        style={{
          data: dataStyle
        }}
        cornerRadius={{
          top: (datum) => datum.topRadius || 0, //( ? 4 : 0),
          bottom: (datum) => datum.bottomRadius || 0 //( ? 4 : 0)
        }}
        barWidth={barWidth || 12}
        data={barData.dataArr}
      />
    )
  }

  render() {
    const { pillar, xAxisFormat, xAxisStyle, height } = this.props
    // const { barDatas, dataX, maxInput, middleValue, gridColor } = this.state
    let metaData = this.state
    if (pillar === 'Nutrition') {
      metaData = this.initWeight()
    }
    const {
      barDatas,
      dataX,
      dataArr,
      maxInput,
      middleValue,
      gridColor
    } = metaData
    // const gridColor = pillar === 'Rejuvenation' ? '#5C00D1' : '#FF501A'
    return (
      <div className="chart">
        <ContainerWidthSizer>
          {({ width }) => (
            <VictoryChart
              width={width}
              height={height || 100}
              padding={{
                top: 10,
                left: this.props.paddingLeft || 10,
                bottom: 20,
                right: 10
              }}
            >
              <VictoryAxis
                // padding={{ left: 10, right: 10 }}
                style={
                  xAxisStyle || {
                    axis: {
                      stroke: 'transparent'
                    },
                    tickLabels: {
                      fontFamily,
                      fontSize: '10px',
                      fill: '#B0BAC9'
                    }
                  }
                }
                tickValues={dataX}
                tickLabelComponent={
                  <VictoryLabel
                    text={(datum) => {
                      let result = xAxisFormat
                        ? xAxisFormat(datum)
                        : this.xAxisFormat(datum)
                      return result
                    }}
                  />
                }
              />
              {!maxInput && (
                <VictoryAxis
                  dependentAxis
                  tickValues={[0, 100]}
                  style={{
                    axis: { stroke: 'transparent' },
                    tickLabels: {
                      fill: 'transparent'
                    }
                  }}
                />
              )}

              {pillar === 'Nutrition' && this.renderWeight(dataArr)}
              {/* {pillar === 'Exercise' &&
                barDatas
                  .reverse()
                  .map((barData, index) => this.renderBar(barData, index))} */}
              {pillar !== 'Nutrition' &&
                barDatas.map((barData, index) =>
                  this.renderBar(barData, index)
                )}
              {pillar !== 'Nutrition' && dataX && this.renderPlaceholderBar()}
              {middleValue > 0 && maxInput > 0 && (
                <VictoryAxis
                  dependentAxis
                  tickValues={[0, middleValue, maxInput]}
                  style={{
                    axis: { stroke: 'transparent' },
                    grid: {
                      stroke: (t) =>
                        t === middleValue ? gridColor : 'transparent',
                      strokeWidth: 2,
                      strokeDasharray: '6, 3'
                    }
                  }}
                />
              )}
            </VictoryChart>
          )}
        </ContainerWidthSizer>
      </div>
    )
  }
}

export default BarChart
