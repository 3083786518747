import axios, { hashRequest } from './axios'

export const getSummaryEmail = (from, to, personId) => {
  return axios.get(`/summary_email?from=${from}&to=${to}&person_id=${personId}`)
}

export const updateScreenshort = (dataArr, personId) =>
  axios.postJson(`summary_email/chart_images/${personId}`, dataArr)

export const saveSummaryEmail = (options, params) => {
  const { personId, from, to, email_id, action, section } = options
  let url = `/summary_email?person_id=${personId}&from=${from}&to=${to}&action=${action}`
  if (section) {
    url += `&section=${section}`
  }
  if (email_id) {
    url += `&email_id=${email_id}`
  }

  return axios.postJson(url, params)
}

export const getEmailDetail = (personId, emailId) => {
  let url = `/summary_email/list?person_id=${personId}`
  if (emailId) {
    url = `/summary_email/details?id=${emailId}`
  }
  return axios.get(url)
}

export const getEmailDetailByHash = async (emailId, hash) => {
  const result = await hashRequest({
    hash,
    method: 'GET',
    path: `/summary_email/details?id=${emailId}`
  })
  return result.data
}

export const getLastComments = (personId, from, to) =>
  axios.get(
    `/summary_email/last_comments?from=${from}&to=${to}&person_id=${personId}`
  )

export const deleteEamilSummary = (emailId) =>
  axios.delete(`/summary_email/${emailId}`)

export const getSummaryEmailChangeLog = (id) => {
  return axios.get(`/summary_email/change_log?id=${id}`)
}

export const saveRPE = (id, rpe) =>
  axios.putJson(`/summary_card/rpe?id=${id}`, { rpe_manual: rpe })

export const getEmailReport = (from, to) =>
  axios.get(`/summary_email/report?from=${from}&to=${to}`)

export const markReportAsDone = (id, params) =>
  axios.postJson(`/summary_email/mark_as_done?email_id=${id}`, params)

export const getEmailTemplate = () =>
  axios.get('/summary_email/recommendation_template')

export const createEmailTemplate = (params) => {
  return axios.postJson('/summary_email/recommendation_template', params)
}

export const updateEmailTemplate = (id, params) => {
  return axios.putJson(`/summary_email/recommendation_template/${id}`, params)
}

export const deleteEmailTemplate = (id) => {
  return axios.delete(`/summary_email/recommendation_template/${id}`)
}

export const getStatisticsLog = (id) =>
  axios.get(`summary_email/statistics?person_id=${id}`)

export const getEmailNotes = (personId, from, to) =>
  axios.get(`summary_email/notes?person_id=${personId}&from=${from}&to=${to}`)

export const updateEmailNotes = ({ personId, from, to, params, id }) =>
  axios.putJson(
    `summary_email/notes/${id}?person_id=${personId}&from=${from}&to=${to}`,
    params
  )

export const submitNote = (personId, from, to, params) =>
  axios.postJson(
    `summary_email/notes?person_id=${personId}&from=${from}&to=${to}`,
    params
  )

/*
    action:
    0 -- editing
    1 -- edited
  */
export const updateEmailAction = (id, action) =>
  axios.post(`/summary_email/update_status/${id}?action=${action}`)

export const getEditStatus = (id) =>
  axios.get(`/summary_email/status?id=${id}`)
