import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'
import success from '../../../asserts/images/success.png'

const Container = styled.div`
  width: 500px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    color: #323f4a;
    font-size: 36px;
    margin-bottom: 36px;
  }
  img {
    margin-bottom: 36px;
  }
  .content {
    color: #52606c;
    font-size: 16px;
    // font-family: 'Heebo';
    letter-spacing: 0.3px;
    line-height: 150%;
  }
  button {
    width: 229px;
    height: 48px;
    margin-top: 20px;
  }
`

export default function MemberInfoDone(props) {
  const { goNext } = props
  return (
    <Container>
      <img src={success} alt="completed" />
      <div className="content">
        <p>
          Thank you for completing our forms. A member of our Concierge team
          will reach out to you shortly to schedule the rest of your Apeiron
          Life appointments.
        </p>
        <p>
          Prior to your coming in for your assessment, we'll need to gather some
          more information about your current lifestyle. This will help us
          personalize your assessment and tailor your results and
          recommendations to your goals and needs.
        </p>
        <Button size="large" type="primary" onClick={() => goNext()}>
          PROCEED
        </Button>
        {/* <br />
        <Button
          style={{ width: 229, padding: 0, marginTop: 16 }}
          size="large"
          type="primary"
          ghost
          onClick={logOut}
        >
          SAVE FOR LATER
        </Button> */}
      </div>
    </Container>
  )
}
