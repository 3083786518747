import React, { useState, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Button, Switch, Modal } from 'antd'
import DataTable from './table'
import AppointmentsEditor from './editor'
import Navigate from './navigate'
import ServicesUsed from './servicesUsed'
import DateGroupPicker from '../../../components/UI/dateGroupPicker'
import TodayTag from '../../../components/UI/todayTag'
import PageLoading from '../../../components/UI/status/loading'
import { getThisWeekRange, getThisYearRange } from '../../../utils/dateHandles'
import {
  getAppointmentsList,
  getSessionStatistics,
  getClientsStatistics,
  deleteAppointment,
  updateAppointment,
  createAppointment,
  exportAppointmentsToCSV
} from '../../../api/appointments'
import { deleteClientStyle } from '../../../components/client/profile.sass'

const confirm = Modal.confirm

const Container = styled.div`
  .load-more {
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    padding-bottom: 25px;
    &:hover {
      color: #2b4b8f;
    }
  }
  .top-opr {
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    color: #52606c;
    margin-top: -60px;
    align-items: center;
    .switch-btn {
      margin-right: 16px;
      display: flex;
      align-items: center;
      button {
        margin-right: 10px;
      }
    }
    .tool-bar .date-tips li {
      background: #ffffff;
      color: #7b8794;
      &.active {
        background: #e4e7eb;
        color: #3e4c59;
      }
    }
  }
  .add-row {
    margin-bottom: 15px;
    button {
      background: #ffffff;
    }
  }

  .ant-table-thead .ant-table-cell {
    text-transform: uppercase;
    color: #a5abb2;
    background: #ffffff;
    vertical-align: middle;
  }

  .ant-table-cell {
    vertical-align: top;
    border-right: none !important;
    &:nth-child(4) {
      position: relative;
      z-index: 1;
    }
    .capitalize {
      text-transform: capitalize;
    }
    .link-group span:first-child {
      margin-right: 5px;
      vertical-align: top;
    }
    .ant-tag {
      height: 28px;
      line-height: 28px;
      border-radius: 4px;
      border: none;
      font-size: 13px;
      margin-top: 10px;
      .ant-tag-default {
        color: #474747;
        background: #e6e9f2;
      }
      .ant-tag-red {
        color: #ff0000;
        background: #ffe4e4;
      }
    }
    .member-tag {
      white-space: nowrap;
      height: 26px;
      border: 1px solid;
      padding: 6px;
      text-transform: uppercase;
      border-radius: 4px;
      display: inline-block;
      margin-top: 10px;
      line-height: 14px;
      color: transparent;
      -webkit-background-clip: border-box;
      span {
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }
`

const dateRanges = [
  {
    name: 'Week',
    barWidth: 20,
    picker: 'week',
    formatX: 'ddd',
    formatTooltip: 'ddd D'
  },
  {
    name: 'Month',
    barWidth: 8,
    picker: 'month',
    formatX: 'MMM/D'
  },
  {
    name: 'Year',
    barWidth: 20,
    type: 'annual',
    picker: 'year',
    formatX: 'MMM'
  },
  {
    name: 'Custom',
    picker: 'date',
    type: 'custom'
    // formatX: ''
  }
]

export const serviceList = [
  'Training',
  'Yoga',
  'Massage',
  'Acupuncture',
  'Chiropractor',
  'Swim',
  'Mindfulness',
  'Pilates',
  'Injury evaluation',
  'PT',
  'Rehab',
  'Nutrition',
  'Cgm',
  'Gut health',
  'Sleep consult',
  'Stretching',
  'Microbiome Program',
  'Micronutrient Program'
]

export default function Appointments(props) {
  const [loading, setLoading] = useState(true)
  const [isExporting, setIsExporting] = useState(false)
  const [showEditor, setShowEditor] = useState(null)
  const [activeKey, setActiveKey] = useState('All')
  const [usedStatus, setUsedStatus] = useState(false)
  const [filterTypes, setFilterTypes] = useState([])
  const [filters, setFilters] = useState({})
  const [state, dispatch] = useReducer(reducer, {
    list: [],
    // afterCursor: null
    statistics: {},
    clientStatistics: {
      appointmentsServiceUsedPercent: {},
      appointments_client: []
    },
    membership: '',
    usedFrom: getThisYearRange().startDate.format('YYYY-MM-DD'),
    usedTo: getThisYearRange().endDate.format('YYYY-MM-DD')
  })

  useEffect(() => {
    const initial = async () => {
      const { startDate, endDate } = getThisWeekRange()
      dispatch({
        type: 'initial',
        payload: {
          from: startDate.format('YYYY-MM-DD'),
          to: endDate.format('YYYY-MM-DD')
        }
      })
    }

    initial()
  }, [])

  useEffect(() => {
    if (usedStatus && state.membership.length > 0) {
      const resetServiceUsed = async () => {
        setLoading(true)
        const payload = {
          membership: ''
        }
        payload.clientStatistics = await getClientsStatistics({
          start_date: state.usedFrom,
          end_date: state.usedTo
        })
        dispatch({
          type: 'update',
          payload
        })
        setLoading(false)
      }
      resetServiceUsed()
    }
  }, [usedStatus])

  const { from, to, usedFrom, usedTo } = state

  const {
    // originalList,
    list,
    afterCursor,
    statistics,
    clientStatistics
  } = state

  return (
    <Container>
      <div className="page-title">Appointments</div>
      <div className="top-opr">
        <div className="switch-btn">
          <Switch
            size="small"
            checked={usedStatus}
            onChange={(checked) => setUsedStatus(checked)}
          />
          Services used
        </div>
        <DateGroupPicker
          dateRanges={dateRanges}
          fetchData={(from, to) => {
            if (!loading) {
              // setFilterTypes([])
              setLoading(true)
              fetchDataWithoutClientsStatistics(from, to, filterTypes, filters)
            }
          }}
          defaultSelectedDate={0}
          from={from}
          to={to}
          display={!usedStatus}
        />
        <DateGroupPicker
          dateRanges={dateRanges}
          fetchData={(from, to) => {
            if (!loading) {
              // setFilterTypes([])
              setLoading(true)
              fetchClientsStatistics(from, to)
            }
          }}
          defaultSelectedDate={2}
          from={usedFrom}
          to={usedTo}
          display={usedStatus}
        />
        <TodayTag />
        <Button type="primary" disabled={isExporting} onClick={exportData}>
          EXPORT CSV
        </Button>
      </div>

      {loading && (
        <>
          <div className="ant-modal-mask" />
          <div
            className="ant-modal-wrap"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <PageLoading />
          </div>
        </>
      )}

      {usedStatus ? (
        <ServicesUsed
          clientStatistics={clientStatistics}
          onFiltersChange={async (filters) => {
            const membership = (_.get(filters, 'client') || []).join(',')
            const payload = {}
            if (membership !== state.membership) {
              setLoading(true)
              payload.membership = membership
              payload.clientStatistics = await getClientsStatistics({
                start_date: state.usedFrom,
                end_date: state.usedTo,
                membership: membership
              })
              setLoading(false)
            }
            dispatch({
              type: 'update',
              payload
            })
          }}
        />
      ) : (
        <>
          <Navigate
            {...{ activeKey, onActiveKeyChange, statistics, filterTypes }}
            onFilter={(values) => {
              setFilterTypes(values)
              setLoading(true)
              fetchDataWithoutClientsStatistics(from, to, values, filters)
            }}
          />
          <div className="add-row">
            <Button type="primary" ghost onClick={() => setShowEditor({})}>
              + ADD A ROW
            </Button>
          </div>

          <DataTable
            handleDeleteClient={handleDeleteClient}
            dataSource={list}
            edit={(appointment) => setShowEditor(appointment)}
            onFiltersChange={async (filters) => {
              setLoading(true)
              setFilters(filters)
              await fetchDataWithoutClientsStatistics(
                from,
                to,
                filterTypes,
                filters
              )
              setLoading(false)
            }}
            {...{ filters, filterTypes, setFilters, activeKey }}
          />
          {afterCursor && (
            <div className="load-more" onClick={loadMore}>
              load more
            </div>
          )}
        </>
      )}

      {showEditor && (
        <AppointmentsEditor
          editor={showEditor}
          cancel={() => setShowEditor(null)}
          submit={submit}
          delAction={handleDeleteClient}
        />
      )}
    </Container>
  )

  function reducer(state, action) {
    if (action.type === 'initial') {
      const { from, to } = action.payload
      fetchData(from, to)
    } else if (action.payload) {
      return _.assign({}, state, action.payload)
    }

    return state
  }

  async function onActiveKeyChange(key) {
    setLoading(true)
    const filterParams = extractFiltersParams(filters)
    const { data, afterCursor } = await fetchAppointments(
      from,
      to,
      filterTypes,
      {
        service_type: key !== 'All' ? key : null,
        afterCursor: null,
        ...filterParams
      }
    )

    dispatch({
      type: 'update',
      payload: {
        list: data,
        afterCursor
      }
    })
    setActiveKey(key)
    setLoading(false)
  }

  async function loadMore() {
    setLoading(true)
    const filterParams = extractFiltersParams(filters)
    const { data, afterCursor } = await fetchAppointments(
      from,
      to,
      filterTypes,
      {
        service_type: activeKey !== 'All' ? activeKey : null,
        afterCursor: state.afterCursor,
        ...filterParams
      }
    )

    dispatch({
      type: 'update',
      payload: {
        list: state.list.concat(data),
        afterCursor
      }
    })

    setLoading(false)
  }

  async function fetchAppointments(from, to, filterTypes, extraParams) {
    const params = {
      start_date: from,
      end_date: to
    }
    if (filterTypes) {
      params.membership = filterTypes.join(',')
    }
    const { data, afterCursor: _afterCursor } = await getAppointmentsList(
      _.assign({}, params, extraParams)
    )
    return { data, afterCursor: _afterCursor }
  }

  async function fetchData(from, to, filterTypes, filters) {
    const params = {
      start_date: from,
      end_date: to
    }
    if (filterTypes) {
      params.membership = filterTypes.join(',')
    }
    const filterParams = extractFiltersParams(filters)

    const { data, afterCursor: _afterCursor } = await getAppointmentsList(
      _.assign({}, params, {
        service_type: activeKey !== 'All' ? activeKey : null,
        ...filterParams
      })
    )
    const statistics = await getSessionStatistics(
      _.assign({}, params, filterParams)
    )

    const payload = {
      list: data,
      afterCursor: _afterCursor,
      statistics,
      // clientStatistics,
      from,
      to
    }

    payload.clientStatistics = await getClientsStatistics({
      start_date: state.usedFrom,
      end_date: state.usedTo
    })

    dispatch({
      type: 'update',
      payload
    })
    setLoading(false)
  }

  async function fetchDataWithoutClientsStatistics(
    from,
    to,
    filterTypes,
    filters
  ) {
    const params = {
      start_date: from,
      end_date: to
    }
    if (filterTypes) {
      params.membership = filterTypes.join(',')
    }
    const filterParams = extractFiltersParams(filters)

    const { data, afterCursor: _afterCursor } = await getAppointmentsList(
      _.assign({}, params, {
        service_type: activeKey !== 'All' ? activeKey : null,
        ...filterParams
      })
    )
    const statistics = await getSessionStatistics(
      _.assign({}, params, filterParams)
    )

    const payload = {
      list: data,
      afterCursor: _afterCursor,
      statistics,
      from,
      to
    }

    dispatch({
      type: 'update',
      payload
    })
    setLoading(false)
  }

  async function fetchClientsStatistics(usedFrom, usedTo) {
    const payload = {
      usedFrom,
      usedTo
    }
    let params = {
      start_date: usedFrom,
      end_date: usedTo
    }
    if (state.membership) {
      params.membership = state.membership
    }
    payload.clientStatistics = await getClientsStatistics(params)
    dispatch({
      type: 'update',
      payload
    })
    setLoading(false)
  }

  async function submit(params, id) {
    setShowEditor(null)
    setLoading(true)
    if (id) {
      await updateAppointment(id, params)
      await fetchData(from, to, filterTypes, filters)
    } else {
      await createAppointment(params)
      await fetchData(from, to)
    }
  }

  function handleDeleteClient(obj) {
    confirm({
      title: `Do you want to delete appointment: ${obj.summary}?`,
      icon: <></>,
      className: deleteClientStyle,
      width: '546px',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        return delAction(obj.id)
      },
      onCancel() {}
    })
  }

  async function delAction(id) {
    setShowEditor(null)
    setLoading(true)
    await deleteAppointment(id)
    await fetchData(from, to)
  }

  async function exportData() {
    setIsExporting(true)
    const params = { start_date: from, end_date: to }
    if (filterTypes) {
      params.membership = filterTypes.join(',')
    }
    const { url } = await exportAppointmentsToCSV(params)
    window.open(url)
    setIsExporting(false)
  }
}

function extractFiltersParams(filters) {
  let filterParams = {}
  if (filters) {
    for (const key in filters) {
      if (!filters[key] || !filters[key].length) {
        continue
      }
      switch (key) {
        case 'appointment':
          filterParams.summary = filters[key]
          break
        case 'trainer':
          filterParams.service_provider_name = filters[key]
          break
        case 'client':
          filterParams.membership = filters[key].join(',')
          // filterParams.client_name = filters[key]
          break
        case 'client1':
          filterParams.client_name = filters[key]
          break
        case 'service':
          filterParams.service_type = filters[key].join(',')
          break

        case 'duration':
          filterParams.duration = filters[key]
          break
        case 'location':
          filterParams.location = filters[key]
          break
        default:
          break
      }
    }
  }
  return filterParams
}
