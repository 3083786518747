import React from 'react'
import { PopIcon } from '../../../view/onboarding/setup/screen'
import BasicForm from '../../../components/formV4'
import _ from 'lodash'
import styled from 'styled-components'

const Container = styled.div`
  width: 378px;
  margin-top: 40px;
  .ant-form-item .ant-form-item-label {
    background: transparent;
    margin-top: -24px;
    label {
      background: transparent;
    }
  }
`

export default function BillingSelection(props) {
  const { plan, handleBillingPreference, user } = props
  // const [refForm, setRefForm] = useState(null)
  const billingPreferenceFormData = [
    {
      type: 'select',
      label: (
        <>
          <span style={{ marginRight: '5px' }}>BILLING PREFERENCE</span>
          <PopIcon description="You will be invoiced for the amount of the selected program." />
        </>
      ),
      options: [
        {
          value: 'quarterly',
          label: 'Quarterly',
          disabled: plan === 'evaluation2'
        },
        {
          value: 'annually',
          label: 'Annually'
        }
      ],
      dbField: 'billing_preference',
      colSpan: 24,
      placeholder: 'Select',
      rules: [
        { required: true, message: 'Please select a billing method to proceed' }
      ]
    }
  ]
  return (
    <Container className="price-tip select-form">
      <BasicForm
        wrappedComponentRef={(form) => {
          form && form.validateFields()
          // setRefForm(form)
        }}
        formData={billingPreferenceFormData}
        initialValues={{
          billing_preference:
            _.get(user, 'profile.billing_preference') || 'quarterly'
        }}
        onChange={({ billing_preference }) => {
          handleBillingPreference && handleBillingPreference(billing_preference)
        }}
      />
    </Container>
  )
}
