import axios from './axios'
// import { geTrendsDisplayMetrics, loadUser } from '../utils/storage'

export const getMetrics = (id) =>
  axios.get(`/trend_statistics/metrics?person_id=${id}`)

export const getStatistics = ({ personId, keys, from, to, type }) =>
  axios.get(
    `/trend_statistics?person_id=${personId}&metric_keys=${keys}&range_type=${type}&from=${from}&to=${to}`
  )

export const getMetricGroup = (personId) =>
  axios.get(`/trend_statistics/metric-templates?person_id=${personId}`)

export const createTemplate = (params) =>
  axios.postJson('/trend_statistics/metric-template', params)

export const editTemplate = (params, id) =>
  axios.putJson(`/trend_statistics/metric-template/${id}`, params)

export const deleteTemplate = (id) =>
  axios.delete(`/trend_statistics/metric-template/${id}`)

export const showToClient = (id) =>
  axios.put(`/trend_statistics/metric-template/${id}/show-to-client`)
