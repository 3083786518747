import React, { Component } from 'react'
import { connect } from 'react-redux'
import mapDispatchToProps from './dispatch'
import { Button } from 'antd'
import BasicForm from '../../components/formV4'
import { passwordForm } from '../../models/setting.form'
import {
  setTokenItem,
  saveUser,
  getCacheURL,
  removeCacheURL
} from '../../utils/storage'
import { passwordInitChange } from '../../api/people'
import success from '../../asserts/images/success.png'
import { ResetPwsContainer } from './style'
import phsLogo from '../../asserts/icon/latest-logo.svg'
import { getRole } from '../../utils/common'

class ChangePassword extends Component {
  static propTypes = {}

  state = {
    status: false,
    loading: false
  }

  submit = async () => {
    const { setLoginAccount } = this.props
    try {
      const { password } = await this.basicFormRef.handleSubmit()
      this.setState({ loading: true })
      const result = await passwordInitChange({ password })
      const { token, person } = result
      saveUser(person)
      setTokenItem(token)
      setLoginAccount(person)
      this.setState({
        loading: false,
        status: true
      })
    } catch (error) {
      console.error(error)
      this.setState({
        loading: false
      })
    }
  }

  componentDidMount() {
    const { account } = this.props
    if (account) {
      const {
        user: {
          profile: { password_reset_required }
        }
      } = account
      if (!password_reset_required) {
        this.setState({ status: true })
      }
    }
  }

  render() {
    const { status, loading } = this.state
    const { account, history } = this.props

    const LoginRole = getRole(account.user.role)

    return (
      <ResetPwsContainer>
        <img
          src={phsLogo}
          alt="logo"
          style={{
            position: 'fixed',
            left: '30px',
            top: '20px',
            width: '150px'
          }}
        />
        <div className="container">
          {status ? (
            <>
              <h1 className="title">Password updated!</h1>
              <img src={success} alt="success" />
              <p className="description short">
                You have successfully updated your password,
                <br />
                please use your new password when logging in
              </p>
              <Button
                type="primary"
                onClick={() => {
                  const path = getCacheURL()
                  if (LoginRole === 'Expert') {
                    history.push(path || '/client')
                  } else {
                    history.push(path ? `/2fa?callback=${path}` : '/2fa')
                  }
                  removeCacheURL()
                }}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              <h1 className="title">Enter a new password</h1>
              <p className="description">
                Please change your password on the first time login
              </p>
              <BasicForm
                normalLayout
                wrappedComponentRef={(inst) => (this.basicFormRef = inst)}
                formData={passwordForm}
                onEnter={this.submit}
              />
              <Button type="primary" onClick={this.submit} loading={loading}>
                Done
              </Button>
            </>
          )}
        </div>
      </ResetPwsContainer>
    )
  }
}

export default connect(
  ({ account }) => ({ account }),
  mapDispatchToProps
)(ChangePassword)
