import _ from 'lodash'
import React from 'react'
import { pattern } from '../utils/constant'

export const introductoryEmailInfo = ({ disabled, email, introductory }) => {
  const fixedElement = [
    {
      type: 'input',
      label: 'email',
      dbField: 'email',
      colSpan: 12,
      disabled,
      rules: [{ required: true, message: 'This field is required' }]
    }
  ]

  let schedulingEmails = _.get(introductory, 'has_scheduling_email', false)
    ? _.compact(_.get(introductory, 'scheduling_emails', []))
    : []

  let billingEmails = _.get(introductory, 'has_billing_email', false)
    ? _.compact(_.get(introductory, 'billing_emails', []))
    : []

  if (schedulingEmails.length < 1) {
    schedulingEmails = [[]]
  }
  if (billingEmails.length < 2) {
    billingEmails = _.concat(
      billingEmails,
      new Array(2 - billingEmails.length).fill([])
    )
  }

  const schedulingEmailElements = schedulingEmails.map((e, index) => {
    return {
      type: 'input',
      label: 'email for scheduling',
      dbField: `schedulingEmail_${index}`,
      disabled,
      colSpan: 12,
      rules: [{ pattern: pattern.email, message: 'Please input a valid email' }]
    }
  })

  const billingEmailElements = billingEmails.map((e, index) => {
    return {
      type: 'input',
      label: 'email for billing',
      dbField: `billingEmail_${index}`,
      colSpan: 12,
      disabled,
      rules: [{ pattern: pattern.email, message: 'Please input a valid email' }]
    }
  })

  const formElement = _.concat(
    fixedElement,
    schedulingEmailElements,
    billingEmailElements
  )

  const emailValue = { email }
  _.concat(
    schedulingEmails.map((e, index) => {
      const key = 'schedulingEmail_' + index
      return {
        [key]: e.email || ''
      }
    }),
    billingEmails.map((e, index) => {
      const key = 'billingEmail_' + index
      return {
        [key]: e.email || ''
      }
    })
  ).forEach((element) => {
    _.assign(emailValue, element)
  })

  return {
    emailFormData: formElement,
    emailValue
  }
}

export const introductoryAddressInfo = ({ disabled }) => {
  return {
    addressFormData: [
      {
        type: 'input',
        label: 'address',
        dbField: ['residence_address', 'address'],
        colSpan: 12,
        disabled
      },
      {
        type: 'input',
        label: 'Special instructions',
        dbField: ['residence_address', 'address_detail'],
        colSpan: 8,
        disabled
      },
      {
        type: 'input',
        label: 'zip code',
        dbField: ['residence_address', 'code'],
        colSpan: 4,
        disabled
      },
      {
        type: 'input',
        label: 'addtional residence',
        dbField: ['additional_residence', 'address'],
        colSpan: 12,
        disabled
      },
      {
        type: 'input',
        label: 'Special instructions',
        dbField: ['additional_residence', 'address_detail'],
        colSpan: 8,
        disabled
      },
      {
        type: 'input',
        label: 'zip code',
        dbField: ['additional_residence', 'code'],
        colSpan: 4,
        disabled
      },
      {
        type: 'input',
        label: 'mailing address',
        dbField: ['mailing_residence', 'address'],
        colSpan: 12,
        disabled
      },
      {
        type: 'input',
        label: 'Special instructions',
        dbField: ['mailing_residence', 'address_detail'],
        colSpan: 8,
        disabled
      },
      {
        type: 'input',
        label: 'zip code',
        dbField: ['mailing_residence', 'code'],
        colSpan: 4,
        disabled
      }
    ]
  }
}

export const introductoryEmergencyInfo = ({ disabled }) => {
  const elements = [
    {
      type: 'input',
      label: 'Emergency contact',
      dbField: 'emergency_contact_name',
      placeholder: 'Name Filled',
      colSpan: 12,
      disabled
    },
    {
      type: 'input',
      label: <div />,
      hideColon: true,
      dbField: 'emergency_contact_email',
      colSpan: 12,
      placeholder: 'sos1@email.com',
      disabled
    },
    {
      type: 'select',
      label: '',
      options: [
        {
          value: 'spouse',
          label: 'Spouse'
        },
        {
          value: 'relative',
          label: 'Relative'
        },
        {
          value: 'other',
          label: 'Other'
        }
      ],
      dbField: 'emergency_contact_relation',
      colSpan: 12,
      placeholder: 'Relationship',
      disabled
    },
    {
      type: 'input',
      label: '',
      dbField: 'emergency_contact_phone',
      colSpan: 12,
      placeholder: '(555) - 555 5555',
      disabled
    }
  ]
  return {
    emergencyFormData: elements
  }
}

export const introductoryInsuranceInfo = (editing) => [
  {
    type: 'input',
    label: 'Insurance Carrier Name',
    dbField: 'insurance_carrier_name',
    colSpan: 12,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Member ID',
    dbField: 'insurance_member_id',
    colSpan: 12,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'Group number',
    dbField: 'insurance_group_number',
    colSpan: 12,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'input',
    label: 'patient name',
    dbField: 'insurance_patient_type',
    colSpan: 12,
    disabled: !editing,
    rules: [{ required: true, message: 'This field is required' }]
  }
]
