import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Radio } from 'antd'
import { formRadioStyle } from './styles/radios.sass'

class Radios extends Component {
  static propTypes = {
    options: PropTypes.array,
    initialValue: PropTypes.any,
    buttonStyle: PropTypes.string
  }

  triggerChange = (value) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { options, value, initialValue, buttonStyle } = this.props
    const RadioComp = buttonStyle !== undefined ? Radio.Button : Radio
    return (
      <Radio.Group
        className={formRadioStyle}
        onChange={this.triggerChange}
        value={value || initialValue}
        buttonStyle={buttonStyle}
      >
        {options.map((item, index) => (
          <RadioComp key={index} value={item.value}>
            {item.label}
          </RadioComp>
        ))}
      </Radio.Group>
    )
  }
}

export default Radios
