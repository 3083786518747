import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import _ from 'lodash'

export default function YesOrNo(props) {
  const { answer, update, question } = props
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (answer) {
      setValue(answer.answer.value)
    }
  }, [])

  return (
    <div className="q-form-group">
      <div className={`q-form-item ${value === false ? 'valid' : ''}`}>
        <Radio onClick={updateAnswer} value="no" checked={value === false}>
          No
        </Radio>
      </div>
      <div className={`q-form-item ${value === true ? 'valid' : ''}`}>
        <Radio onClick={updateAnswer} value="yes" checked={value === true}>
          Yes
        </Radio>
      </div>

      {answer && answer.verify && (
        <p className="invalid">
          {answer.verify.message || 'Please select at least 1 option'}
        </p>
      )}
    </div>
  )

  function updateAnswer(e) {
    const value = e.target.value === 'yes' ? true : false
    setValue(value)
    const newAnswer = _.assignIn(answer, { answer: { value } })
    update(newAnswer, question)
  }
}
