import React from 'react'
import { BackIcon } from '../../../components/icons'
// import Logo from '../../../asserts/icon/latest-logo.svg'
import SquareLogo from '../../../asserts/images/square-logo.svg'
import TextLogo from '../../../asserts/icon/text-logo.svg'

export default function(props) {
  return (
    <header>
      <BackIcon onClick={() => props.history.go(-1)} className="return-icon" />
      <div className="center">
        <img src={SquareLogo} alt="logo" className="square-logo" />
        <img src={TextLogo} alt="text" />
      </div>
    </header>
  )
}
