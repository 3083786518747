import HealthkitLogo from '../asserts/icon/goals/hk-large.png'
import GarminLogo from '../asserts/icon/goals/garmin-large.png'
import OuraLogo from '../asserts/icon/goals/oura-large.png'
import PolarLogo from '../asserts/icon/goals/polar-large.png'
import WithingsLogo from '../asserts/icon/goals/withings-large.png'

const autoSource = [
  {
    label: 'Healthkit - Auto',
    value: 'healthkit',
    leftExtra: HealthkitLogo,
    name: 'HealthKit',
    service: ''
  },
  {
    label: 'Garmin - Auto',
    value: 'garmin',
    leftExtra: GarminLogo,
    name: 'Garmin',
    service: 'garmin'
  },

  {
    label: 'Oura - Auto',
    value: 'ouraring',
    leftExtra: OuraLogo,
    name: 'Oura',
    service: 'ouraring'
  },
  {
    label: 'Polar - Auto',
    value: 'polar',
    leftExtra: PolarLogo,
    name: 'Polar',
    service: 'polar'
  },
  {
    label: 'Withings - Auto',
    value: 'withings',
    leftExtra: WithingsLogo,
    name: 'Withings',
    service: 'withings'
  }
]

export { autoSource }

const filterSource = (pillar) => {
  let source = [
    { label: 'Manual', value: 'manual' },
    { label: 'Any', value: 'any' }
  ].concat(autoSource)

  switch (pillar) {
    case 'exercise':
      source = source.filter(
        (item) => item.value !== 'ouraring' && item.value !== 'withings'
      )
      break
    case 'nutrition':
      source = source.filter(
        (item) =>
          !['any', 'ouraring', 'polar'].find((value) => value === item.value)
      )
      break
    case 'rejuvenation':
      source = source.filter(
        (item) => item.value !== 'polar' && item.value !== 'withings'
      )
      break
    default:
      break
  }

  return source
}

const filterActivityType = (pillar, activities) => {
  if (pillar === 'exercise') {
    activities = activities.filter((activity) => activity.value !== 'Sleep')
  } else if (pillar === 'rejuvenation') {
    activities = activities.filter(
      (activity) => activity.value === 'Sleep' || activity.value === 'Any'
    )
  }
  return activities
}

// const binarySource = [
//   { label: 'Manual', value: 'manual' },
//   // { label: 'Auto', value: 'auto' },
//   { label: 'Healthkit - Auto', value: 'healthkit', leftExtra: HealthkitLogo },
//   { label: 'Garmin - Auto', value: 'garmin', leftExtra: GarminLogo },
//   { label: 'Polar - Auto', value: 'polar', leftExtra: PolarLogo }
// ]

const types = [
  { label: 'Number', value: 'number' },
  { label: 'Yes/No', value: 'binary' },
  { label: 'Text', value: 'text' }
]

const periods = [
  { label: 'Daily', value: '0' },
  { label: 'Weekly', value: '1' },
  { label: 'On specific days per week', value: '2' },
  { label: 'Monthly', value: '3' }
]

const targetGoals = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Range', value: 'range' },
  { label: 'Greater than or equal to', value: 'greater' },
  { label: 'Lesser than or equal to', value: 'lesser' },
  { label: 'Tracking only', value: 'tracking' }
]

export const microGoalsModel = ({
  isEdit,
  trackingOnly,
  isBinary,
  anyActivities,
  healthkitMetric,
  garminMetric,
  ouraMetric,
  polarMetric,
  withingsMetric,
  polarActivities,
  ouraActivities,
  trackingOuraMetric,
  healthkitActivities,
  garminActivities,
  trackingMetric,
  pillar,
  selectSource,
  showEmailTitle
}) => {
  return [
    {
      label: 'TRACKING SOURCE',
      dbField: 'source',
      type: 'select',
      initialValue: isBinary && pillar !== 'nutrition' ? 'any' : 'manual',
      disabled: isEdit || (isBinary && pillar === 'nutrition'),
      options: filterSource(pillar), //pillar=== 'exercise'?source,
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      options: [
        {
          label: 'Heart Rate Zones',
          value: 'Heart Rate Zones'
        }
      ],
      disabled: isEdit,
      colSpan: 12,
      initialValue: 'Heart Rate Zones',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'any'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      options: healthkitMetric.filter((item) => item.pillar === pillar),
      disabled: isEdit,
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'healthkit'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: garminMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'garmin'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: ouraMetric.filter((item) => item.value.includes('Sleep')),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'ouraring'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: polarMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'polar'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: withingsMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'withings'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'INPUT TYPE',
      dbField: 'input_type',
      options: types,
      initialValue: 'number',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        key: 'source',
        value: 'manual'
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'INPUT TYPE',
      dbField: 'input_type',
      options: types.filter((item) => item.value !== 'text'),
      initialValue: 'number',
      disabled: isEdit || selectSource === 'withings' || pillar === 'nutrition',
      hideField: true,
      unless: {
        key: 'source',
        value: 'manual',
        not: true
      },
      type: 'select',
      colSpan: 12,
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    // add on sprint 17 8/20
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, anyActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'any'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, healthkitActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'healthkit'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, garminActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'garmin'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: ouraActivities || [], //polarActivities,
      initialValue: 'Sleep',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'ouraring'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: polarActivities,
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'polar'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      extraAttr: {
        mode: 'multiple'
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'polar'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      colSpan: 12,
      hideField: true,
      extraAttr: {
        mode: 'multiple'
      },
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'healthkit'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      extraAttr: {
        mode: 'multiple'
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'garmin'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      treeData: trackingOuraMetric,
      type: 'treeSelect',
      extraAttr: {
        treeCheckable: true
        // treeDefaultExpandAll: true
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'ouraring'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      treeData: trackingOuraMetric,
      type: 'treeSelect',
      extraAttr: {
        treeCheckable: true
        // treeDefaultExpandAll: true
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'any'
          },
          {
            key: 'auto_activity',
            value: 'Sleep'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },

    // end add on sprint 17 8/20
    {
      label: 'GRAPH',
      dbField: 'chart_type',
      options: [
        { label: 'Trend', value: 'line' },
        { label: 'Graph', value: 'bar' }
      ],
      // initialValue: 'line',
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'auto_metric',
            not: true,
            value: 'SleepMidpointTime'
          }
        ]
      }
    },
    {
      label: 'GRAPH',
      dbField: 'chart_type',
      options: [{ label: 'Trend', value: 'line' }],
      initialValue: 'line',
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'auto_metric',
            value: 'SleepMidpointTime'
          }
        ]
      }
    },
    {
      label: 'UNIT',
      dbField: 'unit',
      type: 'input',
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'source',
            value: 'manual'
          }
        ]
      },
      colSpan: 12,
      rules: [{ required: true, message: 'Unit must be defined' }]
    },
    {
      label: 'TARGET metric',
      type: 'select',
      dbField: 'target_goal',
      options: targetGoals,
      initialValue: 'tracking',
      disabled: trackingOnly,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          }
        ]
      },
      colSpan: 8,
      rules: [{ required: true, message: 'Target metric must be selected' }]
    },
    {
      type: 'input',
      dbField: 'range1',
      colSpan: 4,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'tracking',
            not: true
          }
        ]
      },
      rules: [
        { required: true, message: 'This field is required' },
        {
          pattern: /^\d+((\.\d{1,2})?|\.)$/,
          message: 'Invalid number'
        }
      ]
    },
    {
      type: 'text',
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      },
      text: '-',
      align: 'center',
      colSpan: 1
    },
    {
      type: 'input',
      dbField: 'range2',
      colSpan: 4,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      },
      rules: [
        { required: true, message: 'This field is required' },
        {
          pattern: /^\d+((\.\d{1,2})?|\.)$/,
          message: 'Invalid number'
        }
      ]
    },
    {
      type: 'text',
      dbField: 'unit',
      // text: 'STEPS',
      align: 'left',
      colSpan: 6,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      }
    },
    {
      type: 'text',
      dbField: 'unit',
      align: 'left',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: ['fixed', 'greater', 'lesser']
          }
        ]
      }
    },
    {
      colSpan: 5,
      hideField: true,
      unless: {
        key: 'input_type',
        value: ['text']
      }
    },
    {
      label: 'SUMMARY EMAIL TITLE',
      dbField: 'summary_email_title',
      type: 'input',
      colSpan: 12,
      hideField: !showEmailTitle,
      rules: [{ required: true, message: 'Request must be defined' }]
    },
    {
      label: 'REQUEST',
      dbField: 'micro_goal',
      type: 'textarea',
      rules: [{ required: true, message: 'Request must be defined' }]
    },
    {
      label: 'DESCRIPTION',
      dbField: 'additional_information',
      type: 'textarea',
      rules: [
        { max: 500, message: 'Description can not be over 500 characters' }
      ]
    },
    {
      label: 'Metric PERIOD',
      type: 'select',
      dbField: 'goal_period',
      colSpan: 12,
      options: periods,
      initialValue: '0',
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'REPEAT',
      dbField: 'repeat_num',
      type: 'input',
      inputType: 'number',
      unit: 'time',
      numberType: 'int',
      hideField: true,
      unless: {
        key: 'goal_period',
        value: ['1', '3']
        // and: [
        //   {
        //     key: 'frequency',
        //     value: 'each day'
        //   },
        //   {
        //     key: 'goal_period',
        //     value: ['1', '3']
        //   }
        // ]
      },
      colSpan: 12,
      rules: [
        { required: true, message: 'required! (only support Number)' },
        {
          pattern: /^[0-9]*[1-9][0-9]*$/,
          message: 'Only positive integers are supported'
        }
      ]
    },
    {
      label: 'DAYS OF THE WEEK',
      dbField: 'days',
      // type: 'input',
      type: 'daysOfWeek',
      hideField: true,
      unless: {
        key: 'goal_period',
        value: '2'
      },
      colSpan: 12,
      rules: [{ required: true, message: 'Please specify days of the week' }]
    }
  ]
}

export const programActionModel = ({
  // categories,
  isEdit,
  trackingOnly,
  isBinary,
  anyActivities,
  healthkitMetric,
  garminMetric,
  ouraMetric,
  polarMetric,
  withingsMetric,
  polarActivities,
  ouraActivities,
  trackingOuraMetric,
  healthkitActivities,
  garminActivities,
  trackingMetric,
  pillar,
  selectSource
}) => {
  return [
    {
      label: 'TRACKING SOURCE',
      dbField: 'source',
      type: 'select',
      // initialValue: isBinary && pillar !== 'nutrition' ? 'any' : 'manual',
      disabled: isEdit || (isBinary && pillar === 'nutrition'),
      options: filterSource(pillar),
      colSpan: 12,
      rules: [{ required: true, message: 'This field is required' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      options: [
        {
          label: 'Heart Rate Zones',
          value: 'Heart Rate Zones'
        }
      ],
      disabled: isEdit,
      colSpan: 12,
      initialValue: 'Heart Rate Zones',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'any'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      options: healthkitMetric.filter((item) => item.pillar === pillar),
      disabled: isEdit,
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'healthkit'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: garminMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'garmin'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: ouraMetric,
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'ouraring'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: polarMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'polar'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'METRIC',
      dbField: 'auto_metric',
      disabled: isEdit,
      options: withingsMetric.filter((item) => item.pillar === pillar),
      colSpan: 12,
      initialValue: 'StepCount',
      type: 'select',
      hideField: true,
      unless: {
        and: [
          {
            key: 'source',
            value: 'withings'
          },
          {
            key: 'input_type',
            value: ['number', 'text']
          }
        ]
      },

      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'INPUT TYPE',
      dbField: 'input_type',
      options: types,
      initialValue: 'number',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        key: 'source',
        value: 'manual'
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'INPUT TYPE',
      dbField: 'input_type',
      options: types.filter((item) => item.value !== 'text'),
      // initialValue: 'number',
      disabled: isEdit || selectSource === 'withings' || pillar === 'nutrition',
      hideField: true,
      unless: {
        key: 'source',
        value: 'manual',
        not: true
      },
      type: 'select',
      colSpan: 12,
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    // add on sprint 17 8/20
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, anyActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'any'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, healthkitActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'healthkit'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: filterActivityType(pillar, garminActivities),
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'garmin'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: ouraActivities || [], //polarActivities,
      initialValue: 'Sleep',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'ouraring'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'ACtivity Type',
      dbField: 'auto_activity',
      options: polarActivities,
      initialValue: 'Any',
      disabled: isEdit,
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'polar'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      extraAttr: {
        mode: 'multiple'
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'polar'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      colSpan: 12,
      hideField: true,
      extraAttr: {
        mode: 'multiple'
      },
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'healthkit'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      options: trackingMetric,
      type: 'select',
      extraAttr: {
        mode: 'multiple'
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'garmin'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      treeData: trackingOuraMetric,
      type: 'treeSelect',
      extraAttr: {
        treeCheckable: true
        // treeDefaultExpandAll: true
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'ouraring'
          },
          {
            key: 'auto_activity',
            value: 'Any',
            not: true
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },
    {
      label: 'TRacking METRICS',
      dbField: 'tracking_activity_metric',
      treeData: trackingOuraMetric,
      type: 'treeSelect',
      extraAttr: {
        treeCheckable: true
        // treeDefaultExpandAll: true
      },
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary'
          },
          {
            key: 'source',
            value: 'any'
          },
          {
            key: 'auto_activity',
            value: 'Sleep'
          }
        ]
      },
      rules: [{ required: true, message: 'Input type must be selected' }]
    },

    // end add on sprint 17 8/20
    {
      label: 'GRAPH',
      dbField: 'chart_type',
      options: [
        { label: 'Trend', value: 'line' },
        { label: 'Graph', value: 'bar' }
      ],
      initialValue: 'line',
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'auto_metric',
            not: true,
            value: 'SleepMidpointTime'
          }
        ]
      }
    },
    {
      label: 'GRAPH',
      dbField: 'chart_type',
      options: [{ label: 'Trend', value: 'line' }],
      initialValue: 'line',
      type: 'select',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'auto_metric',
            value: 'SleepMidpointTime'
          }
        ]
      }
    },
    {
      label: 'UNIT',
      dbField: 'unit',
      type: 'input',
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'number'
          },
          {
            key: 'source',
            value: 'manual'
          }
        ]
      },
      colSpan: 12,
      rules: [{ required: true, message: 'Unit must be defined' }]
    },
    {
      label: 'TARGET',
      type: 'select',
      dbField: 'target_goal',
      options: targetGoals,
      initialValue: 'tracking',
      disabled: trackingOnly,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          }
        ]
      },
      colSpan: 8,
      rules: [{ required: true, message: 'Target goal must be selected' }]
    },
    {
      type: 'input',
      dbField: 'range1',
      colSpan: 4,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'tracking',
            not: true
          }
        ]
      },
      rules: [
        { required: true, message: 'This field is required' },
        {
          pattern: /^\d+((\.\d{1,2})?|\.)$/,
          message: 'Invalid number'
        }
      ]
    },
    {
      type: 'text',
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      },
      text: '-',
      align: 'center',
      colSpan: 1
    },
    {
      type: 'input',
      dbField: 'range2',
      colSpan: 4,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      },
      rules: [
        { required: true, message: 'This field is required' },
        {
          pattern: /^\d+((\.\d{1,2})?|\.)$/,
          message: 'Invalid number'
        }
      ]
    },
    {
      type: 'text',
      dbField: 'unit',
      // text: 'STEPS',
      align: 'left',
      colSpan: 6,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: 'range'
          }
        ]
      }
    },
    {
      type: 'text',
      dbField: 'unit',
      align: 'left',
      colSpan: 12,
      hideField: true,
      unless: {
        and: [
          {
            key: 'input_type',
            value: 'binary',
            not: true
          },
          {
            key: 'auto_metric',
            value: 'Heart Rate Zones',
            not: true
          },
          {
            key: 'target_goal',
            value: ['fixed', 'greater', 'lesser']
          }
        ]
      }
    },
    {
      colSpan: 5,
      hideField: true,
      unless: {
        key: 'input_type',
        value: ['text']
      }
    },
    {
      label: 'Short Title ',
      dbField: 'summary_email_title',
      type: 'input',
      colSpan: 12,
      hideField: pillar !== 'nutrition',
      rules: [{ required: true, message: 'Request must be defined' }]
    },
    {
      label: 'NAME',
      dbField: 'micro_goal',
      type: 'textarea',
      rules: [{ required: true, message: 'Request must be defined' }]
    },
    {
      label: 'DESCRIPTION',
      dbField: 'additional_information',
      type: 'textarea',
      rules: [
        { max: 500, message: 'Description can not be over 500 characters' }
      ]
    }
    // {
    //   label: 'GOAL PERIOD',
    //   type: 'select',
    //   dbField: 'goal_period',
    //   colSpan: 12,
    //   options: periods,
    //   initialValue: '0',
    //   rules: [{ required: true, message: 'This field is required' }]
    // },
    // {
    //   label: 'REPEAT',
    //   dbField: 'repeat_num',
    //   type: 'input',
    //   inputType: 'number',
    //   unit: 'time',
    //   numberType: 'int',
    //   hideField: true,
    //   unless: {
    //     key: 'goal_period',
    //     value: ['1', '3']
    //     // and: [
    //     //   {
    //     //     key: 'frequency',
    //     //     value: 'each day'
    //     //   },
    //     //   {
    //     //     key: 'goal_period',
    //     //     value: ['1', '3']
    //     //   }
    //     // ]
    //   },
    //   colSpan: 12,
    //   rules: [
    //     { required: true, message: 'required! (only support Number)' },
    //     {
    //       pattern: /^[0-9]*[1-9][0-9]*$/,
    //       message: 'Only positive integers are supported'
    //     }
    //   ]
    // },
    // {
    //   label: 'DAYS OF THE WEEK',
    //   dbField: 'days',
    //   // type: 'input',
    //   type: 'daysOfWeek',
    //   hideField: true,
    //   unless: {
    //     key: 'goal_period',
    //     value: '2'
    //   },
    //   colSpan: 12,
    //   rules: [{ required: true, message: 'Please specify days of the week' }]
    // }
  ]
}

export const createCategory = [
  {
    type: 'input',
    label: 'CATEGORY NAME',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'textarea',
    label: 'DESCRIPTION',
    dbField: 'description',
    rules: [{ required: true, message: 'This field is required' }]
  }
]

export const createSubCategory = [
  {
    type: 'input',
    label: 'SUB-CATEGORY NAME',
    dbField: 'name',
    rules: [{ required: true, message: 'This field is required' }]
  },
  {
    type: 'textarea',
    label: 'DESCRIPTION',
    dbField: 'description',
    rules: [{ required: true, message: 'This field is required' }]
  }
]
