import React, { Component } from 'react'
import { Tag, Input, Select } from 'antd'
import PropTypes from 'prop-types'
import {
  multiTagInputStyle,
  selectOptionContainer
} from './styles/multiTag.sass'

class MultiTagInput extends Component {
  static propTypes = {
    closable: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      tags: [],
      value: null
    }
    this.inputRef = React.createRef()
  }

  closeHandle = (removedTag) => {
    if (this.props.disabled) return
    const tags = this.state.tags.filter((tag) => tag !== removedTag)
    this.setState({ tags })
    this.triggerChange(tags, this.state.value)
  }

  // selectEnterHandle = (e) => {
  //   const { tags, value } = this.state
  //   if (e.keyCode === 13) {
  //     // enter
  //     // console.log('selectEnterHandle', e)
  //     console.log('value', value)
  //   }
  // }

  inputEnterHandle = (e) => {
    const { tags, value } = this.state
    const { textRules } = this.props
    if (e.keyCode === 13) {
      // enter
      this.setState({ isEntering: true })
      let isValid = true,
        message = ''
      if (textRules) {
        for (let rule of textRules) {
          if (rule.pattern) {
            isValid = rule.pattern.test(value)
            message = rule.message
          }
        }
      }

      if (tags.find((tag) => tag === value)) {
        isValid = false
      }

      if (isValid) {
        tags.push(value)
        this.setState({ tags, value: null, isEntering: false })
        this.triggerChange(tags, null)
      } else {
        this.setState({ hasError: true, message })
      }
    }
  }

  triggerChange = (tags, value) => {
    const { onChange } = this.props
    if (onChange) {
      onChange({ tags, editValue: value })
    }
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({
      tags: value.tags || []
    })
  }

  render() {
    const { closable, disabled, options } = this.props
    const { tags, value, hasError, message } = this.state
    if (options) {
      return (
        <div className={selectOptionContainer}>
          <Select
            showSearch
            placeholder="Please Enter to confirm"
            optionFilterProp="children"
            bordered={false}
            tokenSeparators={[',', ' ', ';']}
            filterOption={(input, option) => {
              return (
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              )
            }}
            // tagRender={(props) => {
            //   const { label, value, closable, onClose } = props
            //   const onPreventMouseDown = (event) => {
            //     event.preventDefault()
            //     event.stopPropagation()
            //   }
            //   let isValid
            //   for (let rule of textRules) {
            //     if (rule.pattern) {
            //       isValid = rule.pattern.test(value)
            //       // message = rule.message
            //     }
            //   }
            //   return (
            //     <Tag
            //       // color={isValid ? 'lightcoral' : null}
            //       color="red"
            //       onMouseDown={onPreventMouseDown}
            //       closable={closable}
            //       onClose={onClose}
            //       style={{ marginRight: 3 }}
            //     >
            //       {label}
            //     </Tag>
            //   )
            // }}
            value={tags}
            mode="tags"
            onChange={(value) => {
              this.setState({ tags: value })
              this.triggerChange(value)
            }}
          >
            {options.map((option, index) => (
              <Select.Option value={option.value} key={index}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      )
    }
    return (
      <>
        <div
          className={`${multiTagInputStyle} ${
            disabled ? 'disabled' : ''
          } multi-tag-wapper`}
          onClick={() => {
            this.inputRef.current && this.inputRef.current.focus()
          }}
        >
          {tags.map((tag, index) => (
            <Tag
              key={tag + '-' + index}
              closable={closable}
              onClose={() => this.closeHandle(tag)}
            >
              {tag}
            </Tag>
          ))}
          {
            !disabled(
              <Input
                ref={this.inputRef}
                onKeyUp={this.inputEnterHandle}
                placeholder="Please Enter to confirm"
                onChange={(e) => {
                  this.setState({ value: e.target.value, hasError: false })
                  this.triggerChange(tags, e.target.value)
                }}
                value={value}
              />
            )
          }
        </div>
        {hasError && message && (
          <div className="ant-form-explain" style={{ color: ' #f5222d' }}>
            {message}
          </div>
        )}
      </>
    )
  }
}

export default MultiTagInput
