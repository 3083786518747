import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Row, Col, Divider, Tabs } from 'antd'
// import { EditCircleIcon } from '../icons/weeklySummary'

import { SectionStyle } from './styles/sectionStyle'

const { TabPane } = Tabs

class Section extends Component {
  static propTypes = {
    section: PropTypes.object,
    answers: PropTypes.object,
    person: PropTypes.object,
    earlierSections: PropTypes.array
  }

  state = {
    showHRZIndex: 0
  }

  filterQuestions(questions) {
    const { section, answers, person } = this.props
    return questions.filter((item) => {
      if (
        typeof item === 'string' ||
        item.type === 'sub_title' ||
        item.sub_question ||
        item.type === 'banner'
      ) {
        return false
      }
      if (item.if) {
        for (const condition of item.if) {
          let answer =
            answers && answers[section.id] && answers[section.id][condition.id]
          if (!answer && answer !== false) {
            answer = {}
          }
          if (
            condition.answer === answer ||
            (typeof answer === 'object' &&
              Object.keys(answer).indexOf(condition.answer.toString()) !== -1)
          ) {
            return true
          }
        }
        return false
      }
      if (item.any) {
        for (const condition of item.any) {
          let answer =
            (answers &&
              answers[section.id] &&
              answers[section.id][condition.id]) ||
            {}
          if (answer[condition.answer]) return true
        }
        return false
      }
      if (item.condition) {
        const condition = JSON.parse(item.condition)
        if (condition.gender && condition.gender !== person.profile.gender)
          return false
      }
      return true
    })
  }

  renderAnswerGroup() {
    const { section, answers, earlierSections } = this.props
    const answerArr = answers[section.id]
    const sectionGroup = []
      .concat([section])
      .concat(earlierSections.filter((item) => item.id === section.id))

    return (
      <SectionStyle>
        <Divider orientation="left">{section.name}</Divider>
        <Tabs
          defaultActiveKey={'0'}
          tabPosition="top"
          onChange={(activeKey) => {
            // onTabChange && onTabChange(activeKey, zoneTypes[activeKey])
            this.setState({ answerGroupIndex: activeKey })
          }}
        >
          {answerArr.map((answer, _index) => {
            const targetSection = sectionGroup.find(
              (item) => item.version === answer.section_version
            )
            if (!targetSection) return null
            const questions = this.filterQuestions(targetSection.questions)
            return (
              <TabPane
                tab={moment(answer.completion_date || answer.date).format(
                  'MMM DD,YYYY'
                )}
                key={_index}
              >
                {questions.map((item, index) => (
                  <Row type="flex" className="container" key={index}>
                    <Col span={12} className="question" data-index={item.id}>
                      {item.required && <i className="required">*</i>}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: item.question_html || item.question
                        }}
                      />
                    </Col>
                    <Col span={12} className="answer">
                      {/* <EditCircleIcon
                        onClick={() =>
                          this.props.editAnswer(item, section.id, answer.date)
                        }
                      /> */}
                      {answers &&
                        this.props.getAnswer(
                          item,
                          section.id,
                          (answer.detail && answer.detail[section.id]) || {},
                          answer.date
                        )}
                    </Col>
                  </Row>
                ))}
              </TabPane>
            )
          })}
        </Tabs>
      </SectionStyle>
    )
  }

  render() {
    const { section, answers } = this.props
    if (answers[section.id] instanceof Array) {
      return this.renderAnswerGroup()
    }

    const questions = this.filterQuestions(section.questions)

    return (
      <SectionStyle>
        <Divider orientation="left">{section.name}</Divider>

        <Row type="flex" className="sub-title">
          <Col span={12}>Questions</Col>
          <Col span={12}>Answers</Col>
        </Row>

        {questions.map((item, index) => (
          <Row type="flex" className="container" key={index}>
            <Col span={12} className="question" data-index={item.id}>
              {item.required && <i className="required">*</i>}
              <p dangerouslySetInnerHTML={{ __html: item.question }} />
            </Col>
            <Col span={12} className="answer">
              {/* <EditCircleIcon
                onClick={() => this.props.editAnswer(item, section.id)}
              /> */}
              {answers && this.props.getAnswer(item, section.id)}
            </Col>
          </Row>
        ))}
      </SectionStyle>
    )
  }
}

export default Section
