import React from 'react'
import RichText from '../../richText'

export default function RichTextItem(props) {
  const { value, onChange, extraAttr } = props
  return (
    <RichText
      value={value}
      {...extraAttr}
      onChange={(changeValue) => {
        onChange(changeValue)
      }}
    />
  )
}
