import React from 'react'
import { Drawer, Space } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

const drawerCommonProps = {
  placement: 'right',
  width: 716,
  destroyOnClose: true,
  headerStyle: {
    padding: '28px 24px',
    fontSize: 18
  },
  closable: false
}

export default function RightDrawer(props) {
  return (
    <Drawer
      title={props.title}
      onClose={props.closeModal}
      open={props.visible}
      extra={
        <Space>
          <CloseOutlined onClick={props.closeModal} />
        </Space>
      }
      {...drawerCommonProps}
      width={props.width ? props.width : drawerCommonProps.width}
    >
      {props.children}
    </Drawer>
  )
}
