import React from 'react'
import { SvgText } from '../../icons/weeklySummary'
import { calculateElementWidth } from '../../summaryEmail/common'
import { heartRateTipsStyle } from './styles/heartRateTips.sass'

export default function HeartRateTips(props) {
  return (
    <div className={heartRateTipsStyle}>
      {props.tips.map((item, index) => (
        <div key={index} className="zoon-container">
          <div className="zoon">
            <div>
              <span
                className="circle"
                style={{
                  background: item.color
                }}
              />

              <SvgText
                text={item.name}
                height={16}
                fontSize={12}
                fill="#8596A4"
                fontFamily="Gilroy"
                width={
                  calculateElementWidth('span', item.name, '12px').width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            </div>
            <div>
              <SvgText
                text={item.value}
                height={16}
                fontSize={12}
                fill="#383E48"
                width={
                  calculateElementWidth('span', item.value, '12px').width + 5
                }
                textAttr={{
                  dominantBaseline: 'mathematical',
                  textAnchor: 'start',
                  x: 0
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
