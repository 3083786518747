import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { getAll } from '../../api/timeline'
import Loading from '../UI/status/loading'

import { timelineMiniStyle } from './timeline.sass'

export default function TimelineMini(props) {
  const { person } = props
  const [timeline, setTimeline] = useState({})
  const [position, setPosition] = useState({ left: 0 })

  useEffect(() => {
    const initial = async () => {
      const { timelines } = await getAll(person.id)
      setTimeline({
        allData: timelines
      })
    }
    initial()
  }, [person.id])

  const ref = React.createRef()

  const slide = (direction) => {
    const element = ref.current
    const { clientWidth, scrollWidth } = element
    const { left } = position

    let newPosition = {}
    if (direction === 'right') {
      if (scrollWidth + (left - clientWidth) <= clientWidth + 52) {
        // 52 is parent padding
        newPosition = {
          right: 0,
          left: clientWidth - scrollWidth
        }
      } else {
        newPosition = { left: left - clientWidth }
      }
    } else {
      // scroll left
      newPosition = {
        left: left + clientWidth > 0 ? 0 : left + clientWidth
      }
    }
    setPosition(newPosition)
  }

  // const setPosition = () => {
  //   const element = ref.current
  //   const activeIndex = timeline.allData && timeline.allData.findIndex((data, index) => {
  //     if (!data.is_done &&
  //       (index === 0 || timeline.allData[index - 1].is_done)) {
  //       return true
  //     }
  //     return false
  //   })
  //   if (element && activeIndex > 5) {
  //     const targetNode = element.childNodes[activeIndex]
  //     if (targetNode) {
  //       element.parentElement.scrollTo({
  //         left: targetNode.offsetLeft,
  //         behavior: 'smooth'
  //       })
  //     }
  //   }
  // }

  // setTimeout(() => {
  //   setPosition()
  // }, 1000)

  return (
    <div className={timelineMiniStyle}>
      {timeline.allData ? (
        <>
          {position.left !== 0 && (
            <div className="arrow arrow-left">
              <span onClick={() => slide('left')}>
                <LeftOutlined />
              </span>
            </div>
          )}
          <div className="timeline-container" ref={ref} style={position}>
            {timeline.allData.map((data, index) => {
              const { done, future, next } = data.icon
              let src = data.is_done ? done : future
              let isActive = data.is_done
              if (
                !data.is_done &&
                (index === 0 || timeline.allData[index - 1].is_done)
              ) {
                src = next
                isActive = true
              }
              return (
                <div
                  className={`time-line-item ${isActive ? 'active' : ''} ${data.is_done ? 'done' : ''}`}
                  key={index}
                >
                  <div className="icon">
                    <img src={src} alt="icon" />
                  </div>
                  <div className="name">{data.name}</div>
                  <div className="date">
                    {data.planOrCompletedDate
                      ? moment(data.planOrCompletedDate).format('MMM DD')
                      : ''}
                  </div>
                </div>
              )
            })}
          </div>
          {position.right !== 0 && (
            <div className="arrow arrow-right">
              <span onClick={() => slide('right')}>
                <RightOutlined />
              </span>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}
