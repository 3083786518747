import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Button } from 'antd'
import { CollapsePlusIcon } from '../../../components/icons'
// import RateInfo from '../progress/rateInfo'
import GoalsList from '../progress/goalsList'
import RatePie from '../progress/ratePie'

class GoalsCategory extends Component {
  static propTypes = {
    tabPillar: PropTypes.object,
    goals: PropTypes.object,
    deleteGoal: PropTypes.func,
    openModal: PropTypes.func,
    period: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    loginRole: PropTypes.string,
    statistics: PropTypes.object
  }

  getAchievingData = (goal) => {
    const { achieving, micro_goal: microGoal } = goal
    const { period, startDate, endDate } = this.props
    let achievingData = {
      achieved: 0,
      expected: 0,
      missed: 0,
      no_data: 0
    }
    if (period === 'weekly') {
      achievingData = achieving.find(
        (item) =>
          (item.from === startDate.format('YYYY-MM-DD') &&
            item.to === endDate.format('YYYY-MM-DD')) ||
          (moment(item.from).isSameOrAfter(moment(startDate)) &&
            moment(item.to).isSameOrBefore(moment(endDate)))
      )
      if (microGoal.cron_day_month !== '*' && achievingData) {
        achievingData.entries = achievingData.entries.filter(
          (item) =>
            moment(item.created_at).isSameOrAfter(startDate) &&
            moment(item.created_at).isSameOrBefore(endDate)
        )
      }
    } else {
      for (const item of achieving) {
        for (const key in achievingData) {
          achievingData[key] += item[key]
        }
      }
    }
    return achievingData
  }

  getAverageRate = () => {
    const { aggregation, tabPillar } = this.props
    const averageRate = aggregation && aggregation[tabPillar.name]
    return averageRate
  }

  getAchievingSum = (list) => {
    const { period, startDate, endDate } = this.props
    const achieving = {
      achieved: 0,
      expected: 0,
      missed: 0,
      no_data: 0
    }

    list = list.filter((item) => item.micro_goal.achievable)
    if (!list.length) return
    for (const item of list) {
      if (item.micro_goal.input_type === 'text') {
        continue
      }
      if (period === 'weekly') {
        const data = item.achieving.find(
          (item) =>
            item.from === startDate.format('YYYY-MM-DD') &&
            item.to === endDate.format('YYYY-MM-DD')
        )
        if (data) {
          for (const key in achieving) {
            achieving[key] += data[key]
          }
        }
      } else {
        for (const data of item.achieving) {
          for (const key in achieving) {
            achieving[key] += data[key]
          }
        }
      }
    }
    return achieving
  }

  render() {
    const {
      tabPillar,
      goals,
      openModal,
      period,
      startDate,
      endDate,
      loginRole,
      setGlobalState,
      type,
      archivedGoal,
      deleteGoal,
      trackingOuraMetric,
      statistics
    } = this.props
    const goalsList = (goals && goals[tabPillar.name]) || []
    let filteredGoals = []
    if (type === 'active') {
      filteredGoals = goalsList.filter(
        (item) => !item.micro_goal.parent_micro_goal_id
      )
    }

    const childProps = {
      period,
      startDate,
      endDate,
      openModal,
      setGlobalState,
      type,
      loginRole,
      archivedGoal,
      deleteGoal,
      trackingOuraMetric,
      extra: statistics.extra
    }

    const averageRate = null
    // loginRole === 'Expert' &&
    // filteredGoals.length > 0 &&
    // this.getAverageRate(goalsList)
    return (
      <div className="goals-category-container">
        <div className="title-container">
          {averageRate && type === 'active' ? (
            <RatePie
              icon={tabPillar.icon}
              averageRate={averageRate}
              key={tabPillar.name}
            />
          ) : (
            <img src={tabPillar.icon} alt="icon" style={{ width: 50 }} />
          )}
          <div>
            <span className="title">{tabPillar.title}</span>
            {/* {filteredGoals.length > 0 ? (
              type === 'active' && loginRole === 'Expert' ? (
                <RateInfo achieving={averageRate} />
              ) : (
                <div />
              )
            ) : ( */}
            {filteredGoals.length === 0 && (
              <div className="empty">
                {/* <img src={holdIcon} alt="icon" /> */}
                There’s no {tabPillar.name} goal, create now
              </div>
            )}
            {/* )} */}
          </div>
          {type === 'active' && (
            <Button
              className="add-goal-button"
              type="primary"
              size="large"
              style={{
                visibility: loginRole !== 'user' ? 'visible' : 'hidden'
              }}
              onClick={() => {
                openModal({
                  visible: 1
                })
                setGlobalState({ pillar: tabPillar.name, editGoal: null })
              }}
            >
              <CollapsePlusIcon /> ADD METRIC
            </Button>
          )}
        </div>
        <div className="category-body ">
          {goalsList.length > 0 && (
            <div className="pillars-container">
              <GoalsList list={goalsList} {...childProps} />
            </div>
          )}
          {tabPillar.title !== 'Rejuvenation' && (
            <div className="separator-line fix-separator-line" />
          )}
        </div>
      </div>
    )
  }
}

export default GoalsCategory
