import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CloseOutlined } from '@ant-design/icons'
import { Popover, Row, Col, Typography } from 'antd'
import UploadModal from './upload'
import LinkModal from './link'
import AttachThumb from './thumb'
import Confirm from '../confirmModal'
import { VectorPlusIcon, LinkIcon, FileIcon } from '../../icons'
import { attachStyle } from './index.sass'

const { Paragraph } = Typography

class Attachments extends Component {
  static propTypes = {
    prefixUpload: PropTypes.func,
    attachments: PropTypes.array,
    removeAttach: PropTypes.func,
    cancelUpload: PropTypes.func,
    uploadFile: PropTypes.func,
    saveLink: PropTypes.func,
    targetId: PropTypes.number
  }

  state = {
    visible: false,
    prefixConfirmVisible: false,
    prefixConfirm: false,
    isConfirming: false
  }

  openModal = async (type) => {
    const { targetId } = this.props
    if (targetId) {
      this.setState({ visible: type })
    } else {
      this.setState({ prefixConfirmVisible: type })
    }
  }

  popoverContent() {
    return (
      <div className="popove-tool">
        <div onClick={() => this.openModal('upload')}>
          <FileIcon /> Upload media (doc, img, vid)
        </div>
        <div onClick={() => this.openModal('link')}>
          <LinkIcon />
          Attach link
        </div>
      </div>
    )
  }

  render() {
    const { visible, prefixConfirmVisible } = this.state
    const {
      attachments,
      removeAttach,
      description,
      prefixUpload,
      confirmObj
    } = this.props
    return (
      <div className={attachStyle}>
        {attachments && (
          <Row gutter={10} className="attach-list">
            {attachments.map((item, index) => (
              <Col key={index} span={8}>
                <div className="attach-item">
                  <AttachThumb attach={item} />
                  <div className="name">
                    <div className="title">
                      <Paragraph ellipsis>{item.title}</Paragraph>
                    </div>
                    <Paragraph ellipsis>
                      {item.type === 'url' ? item.url : item.name}
                    </Paragraph>
                  </div>
                  <div className="close" onClick={() => removeAttach(item)}>
                    <CloseOutlined />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        )}
        <Popover content={this.popoverContent()} placement="bottomLeft">
          <div className="add-icon">
            <VectorPlusIcon />
            <div>{description ? description : 'Add media'}</div>
          </div>
        </Popover>
        <UploadModal
          visible={visible === 'upload'}
          {...this.props}
          closeModal={() => this.setState({ visible: false })}
        />
        <LinkModal
          visible={visible === 'link'}
          {...this.props}
          closeModal={() => this.setState({ visible: false })}
        />

        {prefixConfirmVisible && (
          <Confirm
            modalWidth={600}
            onCancel={() => this.setState({ prefixConfirmVisible: null })}
            onConfirm={async () => {
              const { isConfirming } = this.state
              if (isConfirming) {
                return
              }
              try {
                this.setState({ isConfirming: true })
                if (prefixUpload) {
                  await prefixUpload()
                }
                this.setState({
                  visible: prefixConfirmVisible,
                  prefixConfirmVisible: null,
                  isConfirming: false
                })
              } catch (err) {
                this.setState({
                  prefixConfirmVisible: null,
                  isConfirming: false
                })
              }
            }}
            title={confirmObj.title}
            description={confirmObj.description}
          />
        )}
      </div>
    )
  }
}

export default Attachments
