import axios, { hashRequest } from './axios'

const getUrlPath = (type, isPlural) => {
  switch (type) {
    case 'bio_marker':
    case 'blood-test':
      return isPlural ? 'bio_markers' : 'bio_marker'
    case 'body-comp':
    case 'body_comp':
    case 'body_comps':
    case 'vitals':
    case 'bone-density':
      return isPlural ? 'body_comps' : 'body_comp'
    case 'life-style':
    case 'lifestyle_assessment':
      return isPlural ? 'lifestyle_assessments' : 'lifestyle_assessment'
    case 'physical':
    case 'physical_assessment':
    case 'movement-quality':
    case 'muscle-fitness':
      return isPlural ? 'physical_assessments' : 'physical_assessment'
    case 'cardiorespiratory':
    case 'aerobic-fitness':
      return isPlural ? 'cardiorespiratories' : 'cardiorespiratory'
    case 'cognitive-health':
    case 'cognitive_health':
      return isPlural ? 'cognitive_healths' : 'cognitive_health'
    case 'movement-efficiency':
      return isPlural ? 'movement_efficiencies' : 'movement_efficiency'
    case 'vital':
      return isPlural ? 'vitals' : 'vital'
    case 'nutrition':
      return 'nutrition'
    case 'range-of-motions':
      return isPlural ? 'range_of_motions' : 'range_of_motion'
    case 'force-plate':
      return isPlural ? 'force_plates' : 'force_plate'
    case 'strength':
      return isPlural ? 'strengths' : 'strength'
    default:
      return
  }
}

const requestResult = async (path, hash) =>
  await hashRequest({
    hash,
    method: 'get',
    path: path
  })

export const createDataset = async (type, personId, params, group) => {
  let url = `/datasets/${type}?person_id=${personId}`
  if (group) {
    url += '&group=' + group
  }
  return await axios.postJson(url, params)
}

// todo remove getAllDataset
export const getAllDataset = (type, personId) =>
  axios.get(`/datasets/${type}?person_id=${personId}`)

export const getDatasetById = async (type, id, personId, hash) => {
  let result
  if (hash) {
    result = await hashRequest({
      hash,
      method: 'GET',
      path: `/datasets/${type}/${id}?person_id=${personId}`
    })
    result = result.data
  } else {
    result = await axios.get(`/datasets/${type}/${id}?person_id=${personId}`)
  }
  return result
}

export const delDataset = async (id, group) => {
  let url = `/datasets/${id}`
  if (group) {
    url += '?group=' + group
  }
  return await axios.delete(url)
}

export const updateHistoryDataset = (id, params) =>
  axios.putJson(`/datasets/${id}`, params)

export const getCategories = async ({ type, hash, personId, ids }) => {
  const urlPath = getUrlPath(type, true)
  let url = `/${urlPath}/${
    type === 'blood-test' || type === 'bio_marker'
      ? 'root_categories'
      : 'categories'
  }?history=true`
  if (personId) {
    url += '&person_id=' + personId
  }
  if (ids && ids.length > 0) {
    url += '&category_ids=' + ids.join(',')
  }
  let categories
  if (categories) return categories
  if (hash) {
    const result = await requestResult(url, hash)
    categories = result.data.categories || result.data.root_categories
  } else {
    const result = await axios.get(url)
    categories = result.categories || result.root_categories
  }
  return categories
}

export const getDataset = async ({ type, hash, personId, group }) => {
  const urlPath = getUrlPath(type)
  let url = `/datasets/${urlPath}`
  if (personId) {
    url += '?person_id=' + personId
    if (group && group !== 'blood_test') {
      url += '&group=' + group
    }
  } else if (group && group !== 'blood_test') {
    url += '?group=' + group
  }
  if (hash) {
    const result = await requestResult(url, hash)
    return result.data.datasets
  } else {
    const result = await axios.get(url)
    return result.datasets
  }
}

export const getSlideMenu = async (hash) => {
  // return { groups }
  const url = '/category/tree/v2' //'/category/tree'
  if (hash) {
    const result = await requestResult(url, hash)
    return result.data
  } else {
    return await axios.get(url)
  }
}

export const getDatasetsCount = () => axios.get('/datasets/count')

export const getFileAvailableTypes = (personId) =>
  axios.get(`/files/available_types?person_id=${personId}`)

export const getDataAggregation = () => axios.get('/data_aggregation')

export const getDataAggregationV2 = (from, to) =>
  axios.get(`/data_aggregation/v2?from=${from}&to=${to}`)

export const getDataAggregationConditions = () =>
  axios.get('/data_aggregation/conditions')
