import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import TimeLineGroup from './timeLine'
import { getSum } from '../../../utils/common'
import Information from '../../UI/extraInformation'
// import { chartContainerStyle } from '../styles/chartContainer.sass'
import BGImage from '../../../asserts/images/nutrition-score-bg.png'
import QRCode from '../../../asserts/icon/qrcode.png'
import { Modal } from 'antd'
import { useRecommendation } from '../hooks'

const items = [
  { name: 'Timing' },
  { name: 'Quality' },
  { name: 'Quantity' },
  { name: 'Total' }
]

const itemRatio = {
  quantity: 60,
  quality: 20,
  timing: 20
}

const Container = styled.div`
  position: relative;
  font-family: Gilroy;
  letter-spacing: 0.3px;
  .background {
    position: absolute;
    width: 100%;
  }
  & > div {
    background: linear-gradient(
      180deg,
      rgba(11, 28, 51, 0.85) 0%,
      #0b1c33 100%
    );
    min-height: 500px;
    position: relative;
    z-index: 1;
  }
  .total-score {
    padding: 0;
    padding-top: 24px;
    color: #ffffff;
    font-size: 14px;
    text-transform: capitalize;
    text-align: center;
    .value {
      line-height: 1.2;
      font-family: 'Gilroy-Bold';
      font-size: 64px;
      color: #40c47c;
      span {
        font-size: 20px;
        vertical-align: super;
      }
    }
  }
  .chart-container {
    position: relative;
    display: inline-block;
    width: calc(50% + 100px);
    margin-left: -50px;
    margin-top: -50px;
  }
  .label-list {
    position: absolute;
    right: -80px;
    top: 0;
    margin-top: 65px;
    .label-item {
      position: relative;
      margin: 24px 0;
      padding: 6px 8px;
      width: 113px;
      font-family: 'Gilroy-Bold';
      font-size: 12px;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      border-radius: 2px;
      background: #38414e;
      color: #f4f6fa;
      &:before {
        content: '';
        display: inline-block;
        border-color: transparent #38414e transparent transparent;
        border-width: 6px;
        border-style: solid;
        height: 8px;
        margin-left: -20px;
        margin-top: 4px;
      }
      & > div {
        position: absolute;
        top: 6px;
        width: 100px;
        height: 26px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .summary-container {
    padding: 40px;
    padding-top: 0;
    margin-top: -30px;
    & > div {
      border-radius: 8px;
      background: #45505e;
      padding: 20px;
      .summary {
        color: #f4f6fa;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
      .desc {
        color: #e7e9ec;
        font-size: 13px;
        margin-top: 12px;
        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
    .information-container {
      color: #ffffff !important;
      border-color: #ffffff !important;
      background: linear-gradient(0deg, #454f5d, #454f5d);
    }
    .information-editor {
      background: linear-gradient(0deg, #454f5d, #454f5d);
      textarea {
        background: transparent;
        color: rgba(231, 233, 236, 0.65);
      }
    }
    .information-editor .mark-down-support span,
    .information-editor .editor-footer .buttons button {
      color: #ffffff;
    }
    .mark-down-support,
    .editor-footer {
      border-color: #687486;
      padding: 14px 20px;
      background: transparent;
      border: none;
      border-top: 1px solid #e8ecf0;
      .buttons button {
        height: auto;
        line-height: 25px;
      }
    }
    .mark-down-support {
      float: left;
    }
  }
`

export default function ChartContainer(props) {
  const { loginRole, targetScore } = props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showScore, setShowScore] = useState(
    targetScore || props.score[0] || {}
  )

  // const [showScore, setShowScore] = useState(
  //   props.targetScore || props.score[0]
  // )
  const [saveRecommendation, updateEditingCount] = useRecommendation(
    props.person,
    props.score
  )

  useEffect(() => {
    if (targetScore) {
      setShowScore(targetScore)
    }
  }, [targetScore])

  // const showScore = targetScore || props.score[0] || {}
  const isQuestionnaire = showScore.type === 'nutrition_questionnaire'

  const score = props.score.map((listItem) => {
    for (const item of items) {
      if (item.name === 'Total') {
        listItem[item.name] = Math.round(
          getSum(
            listItem.answers.map(
              (answer) =>
                (answer.percentage_score || 0) * (itemRatio[answer.type] || 1)
            )
          ) / 100
        )
      } else {
        listItem[item.name] =
          listItem.answers.find(
            (answer) => answer.type === item.name.toLowerCase()
          ).percentage_score || 0
      }
    }
    return listItem
  })

  const getLabelColor = (record) => {
    if (record >= 80) {
      return '#40C47C'
    } else if (record >= 50) {
      return '#F5BA40'
    } else {
      return '#FF6B00'
    }
  }

  const showQRCode = () => {
    setIsModalOpen(true)
  }

  const selectScore = (targetScore) => {
    if (props.onChange) {
      props.onChange(targetScore)
    }
    setShowScore(targetScore)
  }

  return (
    <Container>
      <img src={BGImage} alt="bg" className="background" />
      <div>
        {score.length > 0 && (
          <>
            <div className="total-score">
              <div>Total Score &nbsp; &nbsp; &nbsp; &nbsp; </div>
              <div
                className="value"
                style={{
                  color: getLabelColor(showScore.Total)
                }}
              >
                {showScore.Total} <span>%</span>
              </div>
            </div>

            <div className="chart-container">
              <TimeLineGroup
                defaultTargetIndex={props.defaultTargetIndex}
                score={score}
                items={items}
                getLabelColor={getLabelColor}
                selectScore={selectScore}
              />
            </div>
          </>
        )}

        <div className="summary-container">
          {props.hiddenSummary ? (
            <></>
          ) : isQuestionnaire ? (
            ((loginRole !== 'User' ||
              (showScore.recommendation &&
                showScore.recommendation.summary)) && (
              <Information
                disMarkDone={true}
                title="EXECUTIVE SUMMARY"
                initialValue={
                  showScore.recommendation
                    ? showScore.recommendation.summary
                    : ''
                }
                updateEditingCount={(count) => {
                  updateEditingCount(count)
                }}
                status={loginRole === 'User' ? 'Opened' : 'Draft'}
                updateCommend={(value, status) => {
                  saveRecommendation(
                    showScore.date,
                    { summary: value },
                    showScore.section_version
                  )
                }}
              />
            )) ||
            null
          ) : (
            <div>
              <div className="summary">Executive Summary</div>
              <div className="desc">
                To optimize your score, download the{' '}
                <span
                  onClick={showQRCode}
                  style={{ textDecoration: 'underline' }}
                >
                  Apeiron Life app
                </span>{' '}
                where you can view your Nutrition Signals and personalized
                program
              </div>
            </div>
          )}
          <Modal
            title=""
            width={465}
            open={isModalOpen}
            onCancel={(e) => {
              setIsModalOpen(false)
            }}
            footer={null}
          >
            <img src={QRCode} alt="" />
          </Modal>
        </div>
      </div>
    </Container>
  )
}
