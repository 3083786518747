import React from 'react'
import Question from '../questionComponentsV2/question'
import _ from 'lodash'

export default function Questions(props) {
  const {
    original_id,
    questions,
    answerInfo,
    updateAnswer,
    setConfirmBtnDisabled
  } = props

  return (
    <>
      {questions.map((question, index) => {
        let skip = false
        if (question.if || question.any) {
          skip = checkSkip(question)
        }
        return (
          !skip && (
            <Question
              question={question}
              key={question.original_id || question.id || index}
              answers={(answerInfo && answerInfo.answers) || []}
              setConfirmBtnDisabled={setConfirmBtnDisabled}
              updateAnswer={(answer, question) =>
                updateAnswer(answer, original_id, question)
              }
              sessionId={answerInfo && answerInfo.id}
            />
          )
        )
      })}
    </>
  )

  function checkSkip(question) {
    if (question.if) {
      for (const item of question.if) {
        let answer = answerInfo.answers.find(
          (an) => an.question_original_id === item.original_id
        )
        if (_.get(answer || {}, 'answer.value') !== item.answer) {
          return true
        }
      }
    } else if (question.any) {
      return !question.any.find((item) => {
        let answer = answerInfo.answers.find(
          (an) => an.question_original_id === item.original_id
        )
        if (_.get(answer || {}, 'answer.value') === item.answer) {
          return true
        }
        return false
      })
    }
  }
}
