import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './redux/store'
import { version } from '../package.json'

// import bugsnag from '@bugsnag/js'
// import bugsnagReact from '@bugsnag/plugin-react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

const realError = console.error
console.error = (...x) => {
  // debugger;
  if (
    x[0] ===
    'Warning: The tag <g> is unrecognized in this browser. If you meant to render a React component, start its name with an uppercase letter.'
  ) {
    return
  }
  realError(...x)
}

console.info(`
  ====== welcom to Apeiron Life ======
  current version is ** ${version} **
`)
if (process.env.REACT_APP_WORKFLOW === 'develop') {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
} else {
  console.log = function() {}
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    releaseStage: process.env.REACT_APP_WORKFLOW,
    plugins: [new BugsnagPluginReact()]
  })

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  // const bugsnagClient = bugsnag({
  //   apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  //   releaseStage: process.env.REACT_APP_WORKFLOW
  // })
  // bugsnagClient.use(bugsnagReact, React)

  // const ErrorBoundary = bugsnagClient.getPlugin('react')

  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
