import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from 'antd'

class EditInput extends Component {
  static propTypes = {
    initValue: PropTypes.number,
    onChangeHandle: PropTypes.func,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    clearCount: PropTypes.number,
    icon: PropTypes.any
  }

  state = {}

  componentDidMount() {
    const { initValue } = this.props
    if (typeof initValue === 'number') {
      this.setState({ value: initValue })
    }
  }

  componentDidUpdate(prevProps) {
    const { initValue, clearCount } = this.props
    if (
      prevProps.clearCount !== clearCount ||
      initValue !== prevProps.initValue
    ) {
      if (typeof initValue === 'number') {
        this.setState({ value: initValue })
      } else {
        this.setState({ value: null })
      }
    }
  }

  render() {
    const { onChangeHandle, min, max, disabled, icon, hasError } = this.props
    const { value } = this.state
    return (
      <div
        className={`${
          value < min || value > max || hasError ? 'has-error' : ''
        } ${icon ? 'prefix-container' : ''}`}
      >
        {icon && <img src={icon} alt="icon" />}
        <InputNumber
          value={value}
          onChange={(value) => {
            this.setState({ value })
            onChangeHandle(value)
          }}
          disabled={disabled}
          min={min}
          max={max}
        />
      </div>
    )
  }
}

export default EditInput
