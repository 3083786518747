import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Modal, Empty } from 'antd'
import TrashIcon from '../../../asserts/icon/goals/trash-b.svg'
import TimeBack from '../../../asserts/icon/goals/time-back.svg'

import { archivedGoalsStyle } from './goals.sass'

const confirm = Modal.confirm

class ArchivedGoals extends Component {
  static propTypes = {
    goals: PropTypes.object,
    loginRole: PropTypes.string,
    deleteGoal: PropTypes.func,
    archivedGoal: PropTypes.func
  }

  deleteConfirm = (goal) => {
    const { deleteGoal } = this.props
    confirm({
      title: 'Do you want to delete this goal?',
      onOk() {
        deleteGoal(goal, 'archived')
      }
    })
  }

  isEmpty = () => {
    const { goals, pillars } = this.props
    for (const pillar of pillars) {
      if (goals[pillar.name] && goals[pillar.name].length > 0) {
        return false
      }
    }
    return true
  }
  renderGoalsCategory(pillar) {
    const { goals, archivedGoal, loginRole } = this.props
    const showGoals = goals[pillar.name]
    if (!showGoals.length) return <div />
    return (
      <div className="goals-category-container">
        <div className="title-container">
          <div className="icon-img">
            <img src={pillar.icon} alt="icon" style={{ width: 50 }} />
          </div>
          <div className="title">{pillar.title}</div>
        </div>
        <div className="category-body ">
          {showGoals.map((item, index) => (
            <div className="card" key={index}>
              <div className="title">{item.micro_goal.micro_goal}</div>
              <div className="date">
                Archived since:
                <br />
                {item.micro_goal.archived_at &&
                  moment(item.micro_goal.archived_at).format('Do MMMM, YYYY')}
              </div>
              <div className="opr">
                {(loginRole === 'Expert' || loginRole === 'InternalAdmin') && (
                  <>
                    <span onClick={() => archivedGoal(item.micro_goal, false)}>
                      <img src={TimeBack} alt="colok" />
                      Unarchive
                    </span>
                    <span onClick={() => this.deleteConfirm(item.micro_goal)}>
                      <img src={TrashIcon} alt="trash" />
                      Delete
                    </span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  render() {
    const { pillars } = this.props
    const isEmpty = this.isEmpty()
    return (
      <div className={archivedGoalsStyle}>
        {isEmpty ? (
          <div style={{ textAlign: 'center', paddingTop: 40 }}>
            <Empty />
          </div>
        ) : (
          pillars.map((item, index) => (
            <div key={index}>{this.renderGoalsCategory(item)}</div>
          ))
        )}
      </div>
    )
  }
}

export default ArchivedGoals
