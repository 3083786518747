import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

class InputNumber extends Component {
  static propTypes = {
    disabled: PropTypes.bool
  }

  state = {
    value: null
  }

  componentDidMount() {
    const { value } = this.props
    this.setState({ value })
  }

  triggerChange = (changedValue) => {
    const { onChange } = this.props
    if (onChange) {
      onChange(changedValue)
    }
  }

  valueChange = (value) => {
    value = parseFloat(value)
    if (isNaN(value)) {
      value = 0
    }
    value = value.toString()
    this.setState({ value })
    this.triggerChange(value)
  }

  render() {
    // const { disabled } = this.props
    return (
      <div style={{ position: 'relative', background: '#ffffff' }}>
        <Input
          type="number"
          onChange={(e) => this.valueChange(e.target.value)}
          value={this.state.value}
          {...this.props}
        />
        <span
          style={{
            position: 'absolute',
            width: 15,
            height: 30,
            display: 'block',
            background: 'inherit',
            top: 5,
            right: 10
          }}
        />
      </div>
    )
  }
}

export default InputNumber
