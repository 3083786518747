import React from 'react'
import { connect } from 'react-redux'
import ClientContainer from '../../../components/business/dataset/clientcontainer'
import { setCurrentCategory } from '../../../redux/slideMenu/action'
import { getTitleByType } from '../../../components/business/dataset/common'
import LockStatus from '../../../components/UI/lockStatus'
import { loadUser } from '../../../utils/storage'
import { userType } from '../../../utils/constant'

function Dataset(props) {
  const {
    match: {
      params: { type, category },
      url
    },
    history,
    setSubMenu,
    slideMenu,
    client,
    setCurrentCategory
  } = props
  const person = loadUser()
  const isPartner = person.profile.account_type === userType.Partners
  return isPartner ? (
    <>
      <h1 className="page-title">{getTitleByType(type)}</h1>
      <LockStatus />
    </>
  ) : (
    <div style={{ paddingRight: 63, paddingTop: 40, paddingBottom: 60 }}>
      {slideMenu && (
        <ClientContainer
          {...{
            category,
            setSubMenu,
            url,
            history,
            type,
            slideMenu,
            client,
            setCurrentCategory
          }}
        />
      )}
    </div>
  )
}

export default connect(
  ({ slideMenu, client }) => ({ slideMenu, client }),
  (dispatch) => ({
    setCurrentCategory: (category) => dispatch(setCurrentCategory(category))
  })
)(Dataset)
