import axios from './axios'

export const getChallenges = () => axios.get('/challenge')

export const createChallenge = (params) => axios.postJson('/challenge', params)

export const updateChallenge = (params, id) =>
  axios.putJson(`/challenge/${id}`, params)

export const deleteChallenge = (id) => axios.delete(`/challenge/${id}`)

export const getChallengeDetail = (id) => axios.get(`/challenge/${id}`)

export const getChallengeMetrics = () => axios.get('/challenge/metrics')
